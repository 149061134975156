<template>
  <label
    class="block my-3"
  >
    {{ t(configs.label) }}
  </label>
  <div class="p-fluid flex">
    <BaseInputText
      v-model="inputValue"
      :display-emojis="configs.options.displayEmojis"
      :display-variables="configs.options.displayVariables"
      :variables-list="configs.options.variablesList"
      class="w-full"
      @update:model-value="handleValueChange"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { FieldConfig, ParserFieldObject, Property } from '@/types';
import BaseInputText from '@/components/fields/BaseInputText.vue';

export default defineComponent({
  name: 'InputVariables',
  components: {
    BaseInputText,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const inputValue: Ref<string> = ref(properties.value[0].value ?? props.configs.options.defaultValue);

    const handleValueChange = () => {
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    watch(() => properties.value[0].value, (values, oldValues) => {
      if (values !== oldValues) {
        inputValue.value = properties.value[0].value;
      }
    }, { immediate: true });

    return {
      t,
      inputValue,
      handleValueChange,
    };
  },
});
</script>

<style scoped lang="scss">
.with-emoji {
  & .input-text {
    border-right: none;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.with-variables {
  & .input-text {
    border-bottom: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.input-text {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}

:deep() .p-togglebutton.p-button.emoji-button {
  background: $white;
  margin: 0 !important;
  border: solid 1px $solitude;
  border-left: none !important;
  border-radius: 0 0.3rem 0.3rem 0;
  padding: 0 10px;
  width: auto;

  & .p-button-icon-left {
    margin-right: 0;
  }

  & .p-button-label {
    display: none;
  }

  & .p-button-icon {
    color: $heather !important;
  }

  &:hover {
    border-color: $solitude !important;
  }
}

.with-variables:deep() {
  & .emoji-button {
    border-bottom: none !important;
    border-bottom-right-radius: 0 !important;
  }

  & .variable-dropdown {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    & .p-inputtext {
      border-top-left-radius: 0 !important;
      color: $heather;
    }

    & .p-dropdown-trigger {
      border-top-right-radius: 0 !important;
    }
  }
}
</style>
