
import {
  computed, ComputedRef, defineComponent, onMounted,
} from 'vue';
import EditorModal from '@/components/modals/EditorModal.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { DataViewerElement } from '@/types/data-viewer-types';
import DataViewerPreviewSentTemplate from '@/components/data-viewer/DataViewerPreviewSentTemplate.vue';
import { PrimvueMenuModel, ToolbarItemsModel } from '@/types';

export default defineComponent({
  name: 'DataViewer',

  components: {
    EditorModal,
    DataViewerPreviewSentTemplate,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const elements = computed(() => store.getters['dataViewer/getElements']);
    const activeElement: ComputedRef<DataViewerElement | null> = computed(() => store.getters['dataViewer/getActiveElement']);
    const activeIndex: ComputedRef<number> = computed(() => store.getters['dataViewer/getActiveIndex']);

    // Executed when content of dynamic component is loaded
    const isLoaded = () => {
      store.commit('general/hideTheSpinner');
    };

    // EditorModal close action
    const closeDialog = () => {
      store.commit('dataViewer/setIsVisible', false);
    };

    const contentHeaderStartToolbar: ComputedRef<ToolbarItemsModel[]> = computed(() => {
      if (activeElement.value?.component === 'DataViewerPreviewSentTemplate') {
        return [
          {
            multipleType: 'buttons',
            items: [
              {
                icon: 'far fa-fw fa-bullseye',
                tooltip: t('dataViewer.dataViewerSentTemplate.clickmap'),
                class: () => `${activeElement.value?.configuration.displayHeatMap && 'active'}`,
                command: () => {
                  if (activeElement.value) {
                    store.commit('dataViewer/setConfiguration', { name: 'displayHeatMap', value: !activeElement.value.configuration.displayHeatMap });
                  }
                },
              },
              {
                icon: 'far fa-fw fa-chart-mixed',
                tooltip: t('dataViewer.dataViewerSentTemplate.statistics'),
                class: () => `${activeElement.value?.configuration.displayStatistics && 'active'}`,
                command: () => {
                  if (activeElement.value) {
                    store.commit('dataViewer/setConfiguration', { name: 'displayStatistics', value: !activeElement.value.configuration.displayStatistics });
                  }
                },
              },
            ],
          },
        ];
      }

      return [];
    });

    const toolbarRightButtons: ToolbarItemsModel[] = [
      {
        icon: 'far fa-fw fa-times-circle',
        tooltip: t('close'),
        command: () => closeDialog(),
      },
    ];

    const tabs = computed(() => elements.value.map(
      (s: DataViewerElement, index: number): PrimvueMenuModel => {
        const isActiveElement = activeIndex.value === index;

        return {
          label: s.title,
          class: `${isActiveElement ? 'p-button-success' : 'p-button-secondary'} footer-btn overflow-ellipsis`,
          command: !isActiveElement ? () => {
            store.commit('dataViewer/setActiveElement', index);
          } : undefined,
          items: [
            {
              label: t('templateBuilder.close'),
              command: () => {
                store.commit('dataViewer/closeActiveElement');

                if (elements.value.length === 0) {
                  // Close dialog if no elements left in state
                  closeDialog();
                }
              },
            },
          ],
        };
      },
    ));

    onMounted(() => store.commit('general/showTheSpinner'));

    return {
      t,
      contentHeaderStartToolbar,
      toolbarRightButtons,
      tabs,
      activeElement,
      isLoaded,
    };
  },
});
