import { ComponentNameEnum, SpmTableFilter, StringMap } from '@/types';

const getItemListFieldConfig = (defaultValue = [], name: string, fields: StringMap, buttonLabel: string, defaultFilters: SpmTableFilter[] = []) => ({
  componentName: ComponentNameEnum.ITEM_LIST,
  options: {
    defaultValue,
    name,
    fields,
    buttonLabel,
    defaultFilters,
  },
});

export default getItemListFieldConfig;
