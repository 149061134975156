
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
} from 'vue';
import BaseInformation from '@/components/fields/BaseInformation.vue';
import { VCodeMirror } from 'vue3-code-mirror';
import { isDisplayTemplate } from '@/components/template-builder/utils/helpers';
import { hideLeftToolbar, TemplateEditorState, setChangeInLeftToolbar } from '@/composables/template-editor/TemplateEditor';
import {
  getCssStyles,
  setCssStyles,
} from '@/components/template-builder/setters';
import { useI18n } from 'vue-i18n';
import Panel from 'primevue/panel';
import {
  CUSTOM_WIDGET_CSS_PREFIX,
  TEMPLATE_WIDGET_IDENTIFIER_PREFIX,
} from '@/components/template-builder/utils/constants';

export default defineComponent({
  name: 'CssPanel',

  components: {
    BaseInformation,
    VCodeMirror,
    Panel,
  },

  props: {
    widgetId: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();

    const cmOptions = {
      mode: 'css', // Language mode
      theme: 'default', // Theme
      lineNumbers: true, // Show line number
      smartIndent: true, // Smart indent
      indentUnit: 4, // The smart indent unit is 2 spaces in length
      foldGutter: true, // Code folding
      styleActiveLine: true, // Display the style of the selected row
    };

    const inputValue: Ref<string> = ref('');
    const codeMirrorEditorRef = ref();
    const message: Ref<string> = ref('');
    const cssType = ref('static');

    const handleValueChange = () => {
      // Insert new value in iframe (optional : create the style tag if doesn't exist)
      let sectionId = null;
      if (isDisplayTemplate(TemplateEditorState.template?.type)) {
        sectionId = TemplateEditorState.template?.sections[0].id_template_elements.toString();
      } else {
        sectionId = TemplateEditorState.template?.content.design.toString();
      }
      setCssStyles(cssType.value, inputValue.value, sectionId);
      setChangeInLeftToolbar();
    };

    onMounted(() => {
      message.value = isDisplayTemplate(TemplateEditorState.template?.type) ? 'templateBuilder.informations.cssPanelDisplay' : 'templateBuilder.informations.cssPanelEmail';
      if (TemplateEditorState.template && TemplateEditorState.template.informations.imported) {
        cssType.value = 'dynamic';
      }
      if (props.widgetId) {
        cssType.value = `${CUSTOM_WIDGET_CSS_PREFIX}${props.widgetId.split(TEMPLATE_WIDGET_IDENTIFIER_PREFIX)[1]}`;
      }
      inputValue.value = getCssStyles(cssType.value, true);
    });

    return {
      t,
      cmOptions,
      inputValue,
      codeMirrorEditorRef,
      handleValueChange,
      message,
      hideLeftToolbar,
    };
  },
});
