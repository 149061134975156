<template>
  <ConfirmDialog
    :style="{ width: '45vw' }"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
  />
  <div class="steps-config">
    <div class="flex justify-content-between steps-config-row">
      <div
        class="flex flex-column align-items-center justify-content-center steps-config-step"
        :class="{ 'active': !showMappings }"
      >
        <div class="flex align-items-center justify-content-center steps-config-step-number">
          1
        </div>
        <div class="flex steps-config-step-label">
          Saisie des données d'import
        </div>
      </div>
      <div
        class="flex flex-column align-items-center justify-content-center steps-config-step"
        :class="{ 'active': showMappings }"
      >
        <div class="flex align-items-center justify-content-center steps-config-step-number">
          2
        </div>
        <div class="flex steps-config-step-label">
          Assignation des champs de votre données
        </div>
      </div>
    </div>
  </div>
  <Transition v-if="!showMappings">
    <div class="flex flex-wrap mb-3">
      <div
        class="flex flex-column field-wrapper"
        style="width: 100%;"
      >
        <label class="block mb-2">
          {{ t('myLists.importContacts.files.label') }}
          <i
            v-tooltip="{ value: t('myLists.importContacts.files.labelTips'), escape: true, class: 'tooltip-icons-outside' }"
            class="fas fa-question-circle"
            data-html="true"
            data-toggle="tooltip"
            aria-hidden="true"
          />
        </label>
        <FileUpload
          accept=".csv,.txt,.xls,.xlsx"
          :max-file-size="70000000"
          :auto="true"
          :multiple="false"
          :custom-upload="true"
          @select="(event) => onSelectedFiles(event)"
          @uploader="(event) => onTemplatedUpload(event)"
        >
          <template #header="{ chooseCallback }">
            <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
              <div class="flex gap-2">
                <Button
                  :label="t('myLists.importContacts.files.uploadFile')"
                  class="p-button-secondary"
                  icon="fa-light fa-cloud-arrow-up"
                  @click="chooseCallback"
                />
                <Button
                  v-if="files"
                  :label="t('Supprimer le fichier')"
                  class="p-button-danger"
                  icon="fad fa-times"
                  @click="onRemoveTemplatingFile"
                />
              </div>
            </div>
          </template>
          <template #content>
            <div
              v-if="files"
              class="flex align-items-center justify-content-center flex-column"
            >
              <div
                class="flex flex-wrap"
                style="width: 100%;"
              >
                <div
                  class="flex flex-column field-wrapper"
                  style="width: 100%;"
                >
                  <label class="block mb-2">
                    <span class="font-semibold">File name : {{ files.name }} </span>
                    <div>{{ formatSize(files.size) }} <Badge
                      value="Completed"
                      class="mt-3"
                      severity="success"
                    /></div>
                  </label>
                  <Textarea
                    v-model="fileStr"
                    disabled
                    style="width: 100%; min-height: 10rem; resize: vertical;"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <div class="flex align-items-center justify-content-center flex-column">
                <i class="fa-light fa-cloud-arrow-up border-2 border-circle p-5 text-8xl text-400 border-400" />
                <p class="mt-4 mb-0">
                  {{ t('myLists.importContacts.files.emptyFile') }}
                </p>
              </div>
            </div>
          </template>
        </FileUpload>
      </div>
    </div>
  </Transition>
  <Transition v-if="showMappings">
    <ColumnMappings
      data-string=""
      :data-array="fileData"
    />
  </Transition>
  <div
    class="flex justify-content-between field-wrapper"
    style="width: 100%;"
  >
    <div class="flex align-items-center justify-content-center">
      <SpmButton
        v-if="showMappings"
        :label="t('Précedent')"
        icon="fa-regular fa-arrow-left"
        class-style="p-button p-button-success"
        @click="showMappings= false"
      />
    </div>
    <div class="flex align-items-center justify-content-center">
      <SpmButton
        v-if="!showMappings"
        :label="t('Suivant')"
        icon="fa-regular fa-arrow-right"
        class-style="p-button p-button-success"
        @click="validateFileInput"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  Ref,
  ref,
} from 'vue';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import { isValidEmail } from '@/helpers/Email';
import { useToast } from 'primevue/usetoast';
import ColumnMappings from '@/views/mes-listes/customers/ImportCustomers/ColumnMappings.vue';
import Textarea from 'primevue/textarea';
import Badge from 'primevue/badge';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { store } from '@/store';
import { ManualListImportConfigData } from '@/types/lists';
import Tooltip from 'primevue/tooltip';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';

import { parse } from 'papaparse';
import chardet from 'chardet';
import iconv from 'iconv-lite';
import { read, utils } from 'xlsx';

export default defineComponent({
  name: 'ConfigManualFile',
  components: {
    SpmButton,
    Button,
    FileUpload,
    ColumnMappings,
    Textarea,
    Badge,
    ConfirmDialog,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const showMappings = ref(false);
    const fileStr = ref((store.getters['listImportEditor/getConfigData'] as ManualListImportConfigData).dataString);
    const confirm = useConfirm();

    const fileData = ref<any>([]);

    const files: Ref<File | undefined> = ref();

    const formatSize = (bytes: number) => {
      if (bytes === 0) {
        return '0 B';
      }

      const k = 1000;
      const dm = 3;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    };
    const onSelectedFiles = (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: t('myLists.importContacts.errors.maxSizeError', ['700000000']),
          life: 3000,
        });
      }
    };

    const parseData = (data: any) => new Promise((resolve) => {
      parse(data, {
        complete: (results: any) => {
          resolve(results.data);
        },
      });
    });

    const onTemplatedUpload = async (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: 'Upload error',
          life: 3000,
        });
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      files.value = event.files[0];

      let buffer = await event.files[0].arrayBuffer();
      buffer = Buffer.from(buffer);

      const detectedEncoding = chardet.detect(Buffer.from(buffer));
      let encodingName = 'UTF-8';
      if (detectedEncoding) {
        encodingName = detectedEncoding;
      }

      const extension = event.files[0].name.slice(event.files[0].name.lastIndexOf('.') + 1);
      if (extension === 'xls' || extension === 'xlsx') {
        const workbook = read(buffer);
        buffer = utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
        encodingName = 'UTF-8';
      }

      const decoded = iconv.decode(Buffer.from(buffer), encodingName);

      fileStr.value = iconv.encode(decoded, 'UTF-8').toString();

      fileData.value = await parseData(fileStr.value);

      store.commit('listImportEditor/setConfigData', {
        ...store.getters['listImportEditor/getConfigData'],
        dataArray: fileData.value,
      });
    };

    const onRemoveTemplatingFile = (event: any) => {
      files.value = undefined;
      fileStr.value = '';
    };

    const isValidatePhone = (phone: string) => {
      const re = new RegExp('^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$');
      return re.test(phone);
    };

    const checkIfValidEmailOrPhoneNoExists = () => fileData.value.some((row: any[]) => {
      const result = row.filter((field) => isValidEmail(field) || isValidatePhone(field));
      return result.length !== 0;
    });

    const validateFileInput = () => {
      confirm.require({
        message: t('myLists.importContacts.assignment.certification'),
        header: t('confirmation'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('Yes'),
        rejectLabel: t('No'),
        accept: async () => {
          const emailSuccess = checkIfValidEmailOrPhoneNoExists();

          if (!emailSuccess) {
            toast.add({
              severity: 'error', summary: 'Error', detail: t('myLists.importContacts.assignment.dataValidationError'), life: 3000,
            });
            return;
          }
          showMappings.value = true;
        },
        reject: () => {
          // do not go to next step
          showMappings.value = false;
        },
      });
    };

    return {
      t,
      showMappings,
      files,
      fileStr,
      fileData,

      onRemoveTemplatingFile,
      formatSize,
      onTemplatedUpload,
      onSelectedFiles,
      validateFileInput,
    };
  },
});
</script>

<style lang="scss">
.p-fileupload .p-fileupload-content {
  padding: 1rem !important;
}
</style>

<style lang="scss">
.steps-config {
  height: 8rem;
  position: relative;
  margin-bottom: 2rem;
  margin-top: -2rem;

  .steps-config-row {
    &:before {
      top: 50%;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 1px;
      background-color: #94c840;
    }
    .steps-config-step {
      width: 100%;
      margin-top: 2.5rem;

      .steps-config-step-number {
        color: #495057;
        border: 1px solid #94c840;
        background: #ffffff;
        width: 3rem;
        height: 3rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 50%;
      }

      .steps-config-step-label {
        margin-top: 0.5rem;
        color: #6c757d;
      }

      &.active {
        .steps-config-step-number {
          background-color: $extra-light-grey;
        }

        .steps-config-step-label {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
