import {
  ActionTypeEnum,
  FacebookMessengerButton,
  FieldConfig,
} from '@/types';
import { PERCENTAGE_WIDTH_ATTRIBUTE } from '@/components/template-builder/utils/constants';
// eslint-disable-next-line import/no-cycle
import { getSectionIdsFromIframe } from '@/composables/template-editor/TemplateEditor';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';
// eslint-disable-next-line import/no-cycle
import { getDynamicStylesForSelector, getDynamicStylesForActiveSection } from '../utils/parser';
// eslint-disable-next-line import/no-cycle
import { sanitizeTranslationMarkup } from '../utils/translate';

// eslint-disable-next-line import/no-cycle
export * from './social-networks';
// eslint-disable-next-line import/no-cycle
export * from './link';
// eslint-disable-next-line import/no-cycle
export * from './smart-product-list';
// eslint-disable-next-line import/no-cycle
export * from './form';

export const getAttributeByName = (selector: string, attribute: string): string|undefined => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element) {
    return element.getAttribute(attribute) ?? undefined;
  }
  return undefined;
};

export const getDecodedDataRedirect = (selector: string, attribute: string): string => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element) {
    const value = element.getAttribute(attribute) ?? '';
    let returnValue;
    try {
      returnValue = atob(value.replace('base64:', '').replace('#redirect_', '') ?? '');
    } catch (err) {
      returnValue = value;
    }
    return returnValue;
  }
  return '';
};

export const getCssPropertyByName = (selector: string, name: string, onlyValue = true): { type: string; value: any } | string | undefined => {
  const dynamicStyles = getDynamicStylesForSelector(selector);
  const element = getTemplateIframeDocument().querySelectorAll(selector)[0];
  let returnValue;
  let value;
  if (Object.prototype.hasOwnProperty.call(dynamicStyles, name) && dynamicStyles[name]) {
    value = dynamicStyles[name];
  } else if (element) {
    if (name === 'background' && Object.prototype.hasOwnProperty.call(dynamicStyles, 'background-color')
      && dynamicStyles['background-color'] && Object.prototype.hasOwnProperty.call(dynamicStyles, 'background-image') && !dynamicStyles['background-image'].match('gradient')) {
      // eslint-disable-next-line no-param-reassign
      name = 'background-color';
      value = dynamicStyles['background-color'];
    } else {
      const css = window.getComputedStyle(element);
      value = css.getPropertyValue(name);
    }
  }

  if (element && name === 'max-width') {
    if (element.hasAttribute(PERCENTAGE_WIDTH_ATTRIBUTE)) {
      value = `${element.getAttribute(PERCENTAGE_WIDTH_ATTRIBUTE)}%`;
    } else if (element.tagName === 'IMG' && value && value.match('px$')) {
      const currentImgWidthAttr = element.getAttribute('width');
      if (currentImgWidthAttr && parseInt(currentImgWidthAttr, 10) < parseInt(value, 10)) {
        value = `${currentImgWidthAttr}px`;
      }
    }
  }

  // Avoid return false value of background color (default is rgba(0, 0, 0, 0) is no background color set)
  if (value && name === 'background-color' && value === 'rgba(0, 0, 0, 0)') {
    value = '';
  }
  if (value) {
    returnValue = onlyValue ? value : { type: dynamicStyles[name] ? 'element' : 'parent', value };
  }

  // Define the CSS properties that need special handling for comma separation
  const backgroundProps = ['background-repeat', 'background-position-x', 'background-position-y', 'background-size'];
  const isBackgroundProp = backgroundProps.includes(name);
  const isBackground = name === 'background';

  if (returnValue) {
    // Utility function to remove everything after the first comma
    const replaceComma = (value2: string) => value2.replace(/,.*$/, '');

    // Utility function to extract gradient information
    const handleGradient = (value2: string) => value2.replace(/.*((linear|radial)-gradient.*\))$/, '$1');

    if (typeof returnValue === 'string') {
      if (isBackgroundProp) {
        // Remove everything after the first comma for specific background properties
        returnValue = replaceComma(returnValue);
      } else if (isBackground) {
        if (returnValue.match(/(linear|radial)-gradient/)) {
          // Extract gradient information for background property
          returnValue = handleGradient(returnValue);
        } else {
          // Replace with background-color if no gradient is found
          const returnValuString = getCssPropertyByName(selector, 'background-color', true);
          if (typeof returnValuString === 'string') {
            returnValue = returnValuString;
          }
        }
      }
    } else if (typeof returnValue === 'object' && typeof returnValue.value === 'string') {
      if (isBackgroundProp) {
        // Remove everything after the first comma for specific background properties
        returnValue.value = replaceComma(returnValue.value);
      } else if (isBackground) {
        if (returnValue.value.match(/(linear|radial)-gradient/)) {
          // Extract gradient information for background property
          returnValue.value = handleGradient(returnValue.value);
        } else {
          // Replace with background-color if no gradient is found
          const returnValuString = getCssPropertyByName(selector, 'background-color', true);
          if (typeof returnValuString === 'string') {
            returnValue.value = returnValuString;
          }
        }
      }
    }
  }
  return returnValue;
};

export const getBackgroundCssProperty = (selector: string, name: string, onlyValue = true): { type: string; value: any } | string | undefined => {
  let returnValue;
  returnValue = getCssPropertyByName(selector, name, onlyValue);
  if (typeof returnValue === 'string') {
    if (returnValue.match(/(linear|radial)-gradient/)) {
      returnValue = returnValue.replace(/.*((linear|radial)-gradient.*\))$/, '$1');
    } else {
      returnValue = getCssPropertyByName(selector, 'background-color', true);
    }
    if (typeof returnValue === 'string') {
      returnValue = returnValue.replace('!important', '').trim();
    }
  }
  return returnValue;
};

export const getContent = (selector: string) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);

  if (element) {
    // This getter can be involved by translation
    return sanitizeTranslationMarkup(element).innerHTML;
  }
  return undefined;
};

export const getClassByName = (selector: string, name: string): string|undefined => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element) {
    const classes = element.getAttribute('class')?.split(' ');
    const currentClass = classes?.filter((className: string) => className.startsWith(name))[0];
    return currentClass?.replace(name, '');
  }
  return undefined;
};

export const hasClass = (selector: string, name: string): boolean => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  return element?.classList.contains(name) ?? false;
};

export const hasClassElementAndChild = (selector: string, name: string): boolean => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  const childElement: HTMLElement|null = template?.querySelector(`${selector} .${name}`);
  return (element?.classList.contains(name) || childElement !== null) ?? false;
};

export const getTagType = (selector: string): string|null => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element) {
    return element.tagName.toLowerCase();
  }
  return null;
};

export const getFacebookButtons = (selector: string): Array<object> => {
  const template = getTemplateIframeDocument();
  const buttonsContainer: HTMLElement|null = template?.querySelector(selector);
  const buttonsConfiguration: Array<object> = [];

  if (buttonsContainer) {
    const buttons: Array<HTMLElement> = Array.from(buttonsContainer.querySelectorAll('.button'));

    buttons.forEach((button: HTMLElement) => {
      const dataRedirect = button.getAttribute('data-redirect') ?? '';
      const text = sanitizeTranslationMarkup(button).innerHTML;

      const newButton: FacebookMessengerButton = {
        action: dataRedirect.match(new RegExp('#redirect_')) ? ActionTypeEnum.DISPLAY_TEMPLATE : ActionTypeEnum.REDIRECT,
        dataRedirect: dataRedirect.match(new RegExp('(#redirect_)|(base64:)'))
          ? atob(dataRedirect.replace('base64:', '').replace('#redirect_', ''))
          : dataRedirect,
        text,
        selectedTemplate: null,
      };

      buttonsConfiguration.push(newButton);
    });
  }

  return buttonsConfiguration;
};

export const isVisible = (items: Array<FieldConfig>, targetId: string) => {
  const targetField = items.find((item) => item.fieldId === targetId);
  if (targetField) {
    const option = targetField.properties[0].value !== undefined ? targetField.properties[0].value.toString() : Object.values(targetField.options).pop()?.defaultValue ?? '';
    return option.toString() === targetField.enableValue;
  }
  return false;
};

export const getBackgroundImageProperties = (selector: string, name: string): { type: string; value: any } | string | undefined => {
  // We check if selector is dedicated to a widget or not
  const idWidget = selector.match(new RegExp('#spm_widget_([a-zA-Z0-9]+)'));
  const isWidget = Array.isArray(idWidget) && idWidget.length > 0;

  let currentSelector = selector;

  getSectionIdsFromIframe(!isWidget).forEach((sectionId) => {
    // eslint-disable-next-line radix
    if (sectionId) {
      const dynamicStyles = getDynamicStylesForActiveSection(sectionId);
      if (dynamicStyles) {
        const { sheet } = dynamicStyles;
        if (sheet) {
          Object.values(sheet.cssRules).forEach((styleRule: any) => {
            if (
              styleRule.selectorText.match(new RegExp(selector))
              && styleRule.style['backgroundImage' as any]
            ) {
              currentSelector = styleRule.selectorText;
            }
          });
        }
      }
    }
  });

  return currentSelector;
};

export const getBackgroundImageValue = (selector: string, name: string): { type: string; value: any } | string | undefined => {
  // We check if selector is dedicated to a widget or not
  const idWidget = selector.match(new RegExp('#spm_widget_([a-zA-Z0-9]+)'));
  const isWidget = Array.isArray(idWidget) && idWidget.length > 0;

  let value = '';

  getSectionIdsFromIframe(!isWidget).forEach((sectionId) => {
    // eslint-disable-next-line radix
    if (sectionId) {
      const dynamicStyles = getDynamicStylesForActiveSection(sectionId);
      if (dynamicStyles) {
        const { sheet } = dynamicStyles;
        if (sheet) {
          Object.values(sheet.cssRules).forEach((styleRule: any) => {
            if (
              typeof styleRule.selectorText === 'string'
              && styleRule.selectorText.match(new RegExp(selector))
              && styleRule.style['backgroundImage' as any]
            ) {
              value = styleRule.style.backgroundImage;
            }
          });
        }
      }
    }
  });

  return value;
};
