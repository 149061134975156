
import { defineComponent } from 'vue';
import LoadSection from '@/components/template-builder/LoadSection.vue';
import { LoadSectionPayload } from '@/types';
import { useI18n } from 'vue-i18n';
import { hideLeftToolbar } from '@/composables/template-editor/TemplateEditor';
import Panel from 'primevue/panel';

export default defineComponent({
  name: 'LoadSectionPanel',

  components: {
    LoadSection,
    Panel,
  },

  setup() {
    const { t } = useI18n();
    const payload: LoadSectionPayload = { position: 'top', selector: '#spm_body .spm_section:first-child' };

    return {
      t,
      payload,
      hideLeftToolbar,
    };
  },
});
