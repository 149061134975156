<template>
  <div
    class="flex align-items-center"
    style="padding: 0.5rem !important;"
    @click="handleCallback"
  >
    <ProgressSpinner
      v-if="showLoading"
      style="width: 1.25em; height: 1.25em"
      stroke-width="1"
      class="mr-2"
      unstyled
    />
    <i
      v-if="item.icon && !showLoading"
      class="mr-2 fa-fw menu-icon"
      :class="item.icon"
      :title="item.label"
    />
    <span
      v-if="item.logo"
      class="menu-logo"
    >
      <img
        :src="item.logo"
        :alt="item.label"
      >
    </span>
    <span
      class="menu-label"
      :class="{ 'with-logo': item.logo, 'with-legend': item.legend }"
    >
      {{ item.label }}
      <span
        v-if="item.legend"
        class="menu-legend">
        {{ item.legend }}
      </span>
    </span>
    <i
      v-if="item.minimumPlan"
      class="fas fa-star ml-auto border-1 border-circle p-1 text-sm"
      :class="`menu-label-star--${item.minimumPlan}`"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, PropType,
} from 'vue';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'SpmPanelMenuItemCommand',

  components: {
    ProgressSpinner,
  },

  props: {
    item: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props) {
    const showLoading = ref(false);

    const handleCallback = async (event: any) => {
      showLoading.value = true;
      if (props.item.callback) {
        const data = await props.item.callback(props.data, event);
        showLoading.value = false;
        return data;
      }
      showLoading.value = false;
      return {};
    };

    return {
      showLoading,
      handleCallback,
    };
  },
});
</script>
