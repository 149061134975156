
import { StringMap } from '@/types';
import { useI18n } from 'vue-i18n';
import {
  defineComponent, onMounted, PropType, ref,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import { asInt } from '@/helpers';

export default defineComponent({
  name: 'SegmentDropdown',

  components: {
    Dropdown,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },

    options: {
      type: Function,
      required: true,
    },

    optionValue: {
      type: String,
      required: false,
      default: 'value',
    },

    optionLabel: {
      type: String,
      required: false,
      default: 'label',
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: Number; options: Function; optionValue: string; optionLabel: string }, context) {
    const { t } = useI18n();
    const dropdownOptions = ref<any>([]);
    const currentValue = ref(props.modelValue);

    const emitChangedValue = () => {
      context.emit('update:modelValue', currentValue.value);
    };

    onMounted(async () => {
      dropdownOptions.value = await props.options();

      if (dropdownOptions.value) {
        dropdownOptions.value = dropdownOptions.value.map((item: any) => ({
          ...item,
          [props.optionLabel]: t(item[props.optionLabel]),
        }));
      }
    });

    return {
      dropdownOptions,
      currentValue,
      emitChangedValue,
    };
  },
});
