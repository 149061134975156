<template>
  <div class="ListsManage">
    <SpmTable
      ref="childComponentRef"
      name="ShopsCustomersShopsLists"
      index="id_shop_customer"
      :table-columns="columns"
      :persistent-filters="filters"
      :grouped-actions-options="actionList"
      :custom-selector="true"
      grouped-actions-key="id_shop_customer"
      :id-shop="idShop"
      :show-export-menu-item="true"
      :export-file-name="tableTitle"
    >
      <template #newsletter="slotProps">
        <div
          v-if="((slotProps.data.unsub_type & 1) === 1 || (slotProps.data.unsub_type & 4) === 4 ||
            (slotProps.data.unsub_type & 16) === 16)"
        >
          <i
            v-tooltip.left="t('myLists.searchComponent.unsubscribed.toolTip')"
            class="far fa-ban"
            data-html="true"
            data-toggle="tooltip"
            aria-hidden="true"
          />
        </div>
        <div v-else>
          <div v-if="slotProps.data.newsletter === '0'">
            <i
              v-tooltip.left="t('myLists.searchComponent.notRegistered.toolTip')"
              class="far fa-circle-exclamation newsletter-0"
              data-html="true"
              data-toggle="tooltip"
              aria-hidden="true"
            />
          </div>
          <div v-else-if="slotProps.data.newsletter === '1'">
            <i
              v-tooltip.left="t('myLists.searchComponent.optIn.toolTip')"
              class="far fa-check newsletter-1"
              data-html="true"
              data-toggle="tooltip"
              aria-hidden="true"
            />
          </div>
          <div v-else-if="slotProps.data.newsletter === '2'">
            <i
              v-tooltip.left="t('myLists.searchComponent.doubleOptIn.toolTip')"
              class="far fa-check-double newsletter-2"
              data-html="true"
              data-toggle="tooltip"
              aria-hidden="true"
            />
          </div>
        </div>
      </template>

      <template #actions="slotProps">
        <ListsManageActions
          :key="slotProps.data.id_shop_customer"
          :data="slotProps.data"
          :func="toggleDialogFunc"
          :id-shop="idShop"
          :type="slotProps.data.typeList"
          :id-customer="slotProps.data.id_shop_customer"
          :search-window="true"
        />
      </template>
    </SpmTable>
  </div>
</template>

<script lang="ts">
import SpmTable from '@/components/table/SpmTable.vue';
import { DataExplorerDataType, SpmTableColumns, SpmTableFilter, SpmTableFilterOption } from '@/types';
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import ListsManageActions from '@/components/mes-lists/ListsManageActions.vue';
import { getShopCustomerLanguages } from '@/types/country-language-options';
import { newsletterTypeEnum } from '@/composables/shop/ShopsLists';
import { MenuItem, MenuItemCommandEvent } from 'primevue/menuitem';

export default defineComponent({
  name: 'ListManageCustomers',
  components: {
    SpmTable,
    ListsManageActions,
  },

  directives: {
    Tooltip,
  },

  props: {
    idShop: {
      type: Number,
      required: true,
    },

    translation: {
      type: Function,
      required: true,
    },

    persistentFilters: {
      type: Array as PropType<SpmTableFilter[]>,
      required: true,
      default: () => [],
    },

    locale: {
      type: String,
      required: true,
      default: () => 'fr',
    },

    tableTitle: {
      type: String,
      required: true,
    },

  },

  setup(props: { idShop: number; persistentFilters: SpmTableFilter[]; translation: Function; locale: string;
    tableTitle: string;}) {
    const { t } = useI18n();
    const childComponentRef = ref();

    const filters = ref<SpmTableFilter[]>(props.persistentFilters);

    const actionList: MenuItem[] = [
      {
        code: 'copy',
        label: props.translation('myLists.manage.bulkActions.copy.menuItem'),
        command: (event: MenuItemCommandEvent) => {
          childComponentRef.value.toggleDialog(
            '',
            props.translation('myLists.manage.bulkActions.copy.text'),
            props.translation('myLists.manage.bulkActions.copy.formTitle'),
            'fa fa-level-down',
            props.translation('myLists.manage.bulkActions.copy.acceptLabel'),
            props.translation('myLists.manage.bulkActions.copy.rejectLabel'),
            'copy',
            null,
          );
        },
      },
      {
        code: 'create',
        label: props.translation('myLists.manage.bulkActions.create.menuItem'),

        command: (event: MenuItemCommandEvent) => {
          childComponentRef.value.toggleDialog(
            '',
            props.translation('myLists.manage.bulkActions.create.text'),
            props.translation('myLists.manage.bulkActions.create.formTitle'),
            'far fa-info-circle',
            props.translation('myLists.manage.bulkActions.create.acceptLabel'),
            props.translation('myLists.manage.bulkActions.create.rejectLabel'),
            'newlist',
            null,
          );
        },
      },
      {
        code: 'unsub',
        label: props.translation('myLists.manage.bulkActions.unsub.menuItem'),
        command: (event: MenuItemCommandEvent) => {
          childComponentRef.value.toggleDialog(
            '',
            props.translation('myLists.manage.bulkActions.unsub.text'),
            props.translation('myLists.manage.bulkActions.unsub.formTitle'),
            'far fa-info-circle',
            props.translation('myLists.manage.bulkActions.unsub.acceptLabel'),
            props.translation('myLists.manage.bulkActions.unsub.rejectLabel'),
            'unsub_newsletter',
            null,
          );
        },
      },
      {
        code: 'deleteAll',
        label: t('myLists.manage.bulkActions.deleteFromAll.menuItem'),
        command: (event: MenuItemCommandEvent) => {
          childComponentRef.value.toggleDialog(
            '',
            t('myLists.manage.bulkActions.deleteFromAll.text'),
            t('myLists.manage.bulkActions.deleteFromAll.formTitle'),
            'far fa-info-circle',
            t('myLists.manage.bulkActions.deleteFromAll.acceptLabel'),
            t('myLists.manage.bulkActions.deleteFromAll.rejectLabel'),
            'delete_from_all_lists',
            null,
          );
        },
      },
    ];

    const toggleDialogFunc = (listId: string, message: string, header: string, icon: string, acceptLabel: string,
      rejectLabel: string, actionType: string, customerId: number|null) => {
      childComponentRef.value.toggleDialog(
        listId,
        message,
        header,
        icon,
        acceptLabel,
        rejectLabel,
        actionType,
        customerId,
      );
    };

    const newsletterOptions: SpmTableFilterOption[] = Object.values(newsletterTypeEnum)
      .map((key: string) => ({ value: key, label: t(`myLists.manage.customers.optin.${key}`) }));

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
        prefix: 'sc',
      },
      {
        field: 'date_modification',
        header: props.translation('myLists.manage.customers.headers.modifDate'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: props.translation('myLists.manage.customers.headers.firstname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'last_name',
        header: props.translation('myLists.manage.customers.headers.lastname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: props.translation('myLists.manage.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'data-explorer-link',
        prefix: 'sc',
        dataExplorerOptions: {
          id: 'id_shop_customer',
          type: DataExplorerDataType.CONTACT_FILE
        },
      },
      {
        field: 'country',
        header: props.translation('myLists.manage.customers.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'flag',
        prefix: 'sc',
        filterSettings: { type: 'country', hideFilterMenu: true },
      },
      {
        field: 'lang',
        header: props.translation('myLists.manage.customers.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'language',
        prefix: 'sc',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'newsletter',
        header: props.translation('myLists.manage.customers.headers.newsletter'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'unsub_type',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
        prefix: 'sc',
        hidden: true,
      },
      {
        field: 'actions',
        header: props.translation('myLists.manage.actions.placeholder'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        custom: true,
      },
    ];

    watch(props, () => {
      filters.value = props.persistentFilters;
    }, { immediate: true });

    return {
      t,
      columns,
      filters,
      actionList,
      childComponentRef,
      toggleDialogFunc,
    };
  },
});
</script>

<style scoped lang="scss">
.newsletter-1 {
  color: $brand-orange;
}
.newsletter-2 {
  color: $brand-color-primary;
}
</style>
