<template>
  <Dialog
    v-model:visible="displayModal"
    :header="t('templateBuilder.chooseYourTemplate')"
    style="width: 70rem;"
    @show="handleScroll"
    @update:visible="handleClose"
  >
    <div
      class="grid"
      style="height: 30rem; text-align: center;"
    >
      <div
        v-for="template of templates"
        :key="template.id_template"
        class="col-3 mb-5"
      >
        <label class="mb-2 template-label">
          {{ template.label }}
        </label>
        <div class="preview-container">
          <img
            :src="imageUrl"
            alt="alt"
            class="template-image"
          >
          <Button
            :label="t('templateBuilder.choose')"
            icon="far fa-check"
            class="p-button-primary"
            @click="handleChooseTemplate(template)"
          />
        </div>
      </div>
      <div ref="endListElementRef">
        <TheSpinner v-if="displaySpinner" />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import TheSpinner from '@/components/TheSpinner.vue';
import {
  computed, defineComponent, ref, Ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import useListQuery from '@/composables/useListQuery';
import { SpmTableFilter, SpmTableSort } from '@/types';
import { store } from '@/store';

export default defineComponent({
  name: 'ChooseTemplateModal',

  components: {
    Dialog,
    Button,
    TheSpinner,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-template': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const imageUrl = 'https://media.shopimind.io/img/templates_preview_v3/35b251d8f5dac17f80b3a2b34b9aae1abe24190b.png';
    const itemsToLoad = 12;

    const displayModal = computed(() => props.displayDialog);
    const displaySpinner = ref(false);

    const endListElementRef = ref();

    const templates: Ref<any[]> = ref([]);

    const offset = ref(0);
    const settings: {
      limit: Ref<number>;
      offset: Ref<number>;
      order: Ref<SpmTableSort[]>;
      filter: Ref<SpmTableFilter[]>;
    } = {
      limit: ref(itemsToLoad),
      offset: ref(offset.value),
      order: ref([]),
      filter: ref([{ field: 'type', value: 'popup', operator: 'CONTAINS' }]),
    };

    const result = useListQuery(
      {
        name: 'TemplatesList',
        settings,
        fields: ['id_template', 'label'],
      },
    );

    const handleScroll = () => {
      const observer = new IntersectionObserver((entries) => {
        const endListElement = entries[0];
        if (endListElement.isIntersecting) {
          displaySpinner.value = true;
          settings.offset.value += itemsToLoad;
        }
      });
      observer.observe(endListElementRef.value);
    };

    const handleClose = () => {
      context.emit('on-close-dialog');
    };

    const handleChooseTemplate = (template: any) => {
      context.emit('on-choose-template', template);
      handleClose();
      store.commit('general/hideTheSpinner');
    };

    watch(result.data, () => {
      if (result.data.value?.TemplatesList?.items) {
        templates.value.push(...result.data.value.TemplatesList.items);
        displaySpinner.value = false;
      }
    });

    return {
      t,
      imageUrl,
      endListElementRef,
      templates,
      displayModal,
      displaySpinner,
      handleClose,
      handleScroll,
      handleChooseTemplate,
    };
  },
});
</script>

<style scoped lang="scss">
$img-size: 12rem;

.template-image {
  width: $img-size;
  height: $img-size;
  object-fit: cover;
  object-position: bottom;
}

.preview-container {
  position: relative;
  width: $img-size;
  height: $img-size;
  margin: 1rem auto !important;
  &:hover {
    &::after {
      content: '';
      background: rgba(0, 0, 0, .3);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .p-button {
      display: block;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
  .p-button {
    display: none;
  }
}
</style>
