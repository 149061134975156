<template>
  <div class="grid pt-3 pb-2">
    <div class="col-6">
      <h3 style="padding-left:1rem">
        {{ t('user.subUser.title') }}
      </h3>
    </div>
    <div class="col-6 text-right">
      <router-link :to="{ name: 'user.sub-user-list' }">
        <Button
          :label="t('return')"
          type="button"
          icon="fa fa-arrow-left"
          class="p-button p-button-secondary"
        />
      </router-link>
    </div>
  </div>
  <div class="grid pb-3">
    <div class="col-12">
      <Card>
        <template #title>
          {{ t('profile.myInformation.subUser.title') }}
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12">
              <div class="field">
                <label>{{ t('profile.myInformation.fields.lastName') }}</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fas fa-font" />
                  </span>
                  <InputText
                    v-model="lastName"
                    :placeholder="t('profile.myInformation.fields.lastName')"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="error"
                  field="lastName"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label>{{ t('profile.myInformation.fields.firstName') }}</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fas fa-font" />
                  </span>
                  <InputText
                    v-model="firstName"
                    :placeholder="t('profile.myInformation.fields.firstName')"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="error"
                  field="firstName"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label>{{ t('profile.myInformation.fields.email') }}</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fas fa-at" />
                  </span>
                  <InputText
                    v-model="email"
                    type="email"
                    :placeholder="t('profile.myInformation.fields.email')"
                    :class="emailError ? 'p-invalid' : ''"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="error"
                  field="email"
                />
                <InlineMessage v-if="emailError">
                  {{ t('profile.myInformation.errors.email') }}
                </InlineMessage>
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label>{{ t('myLists.manage.customers.headers.lang') }}</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fas fa-language" />
                  </span>
                  <Dropdown
                    v-model="subUserLang"
                    :placeholder="t('myLists.manage.customers.headers.lang')"
                    :options="availableLanguages"
                    style="width:100%"
                    option-label="label"
                    option-value="value"
                    loading-icon="fas fa-language"
                    filter
                  />
                </div>
              </div>
            </div>
            <div
              v-if="!userId"
              class="col-12"
            >
              <div class="field">
                <label>{{ t('automatedScenarios.fields.duration') }}</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fa-solid fa-timer" />
                  </span>
                  <PeriodSelector
                    v-model="period"
                    :units="units"
                    title=""
                    :min-value="0"
                    :disabled="noExpDate"
                    selector-grouped=""
                  />

                  <div class="flex align-items-center ml-2">
                    <Checkbox
                      v-model="noExpDate"
                      input-id="noExpDate"
                      :binary="true"
                    />
                    <label
                      for="noExpDate"
                      class="ml-2"
                    >{{ t('profile.myInformation.subUser.noExpiryDate') }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="field">
                <label>{{ t('profile.myInformation.subUser.enabledShop') }}</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                    <i class="fa-regular fa-store" />
                  </span>
                  <MultiSelect
                    v-model="selectedShops"
                    :options="options"
                    :show-toggle-all="false"
                    :filter="true"
                    option-value="code"
                    option-label="label"
                    data-key="code"
                    :placeholder="t('profile.myInformation.subUser.placeholder')"
                  />
                </div>
                <FieldErrors
                  :key="componentFieldErrorsKey"
                  :errors="error"
                  field="shops"
                />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>

  <div
    v-if="dashboardsList.length || statsPagesList.length || emailsPagesList.length"
    class="grid pb-3"
  >
    <div class="col-12">
      <Loader v-if="isStatsLoading" />
      <Card>
        <template #title>
          {{ t('user.subUser.reports.title') }}
        </template>
        <template #content>
          <div class="grid">
            <div
              v-if="dashboardsList.length"
              class="col-12"
            >
              <div class="field">
                <label>{{ t('user.subUser.reports.chooseDashboard') }}</label>
                <div class="p-inputgroup">
                  <Dropdown
                    v-model="dashboard"
                    :options="dashboardsList"
                    option-label="label"
                    option-value="value"
                    class="value-select"
                    @change="(event) => selectDashboard(event.value)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="statsPagesList.length"
              class="col-12"
            >
              <div class="field">
                <label>{{ t('user.subUser.reports.chooseStats') }}</label>
                <div class="p-inputgroup">
                  <MultiSelect
                    v-model="statsPages"
                    :options="statsPagesList"
                    option-label="label"
                    :placeholder="t('user.subUser.reports.chooseStats')"
                    :filter="true"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="emailsPagesList.length"
              class="col-12"
            >
              <div class="field">
                <label>{{ t('user.subUser.reports.chooseEmails') }}</label>
                <div class="p-inputgroup">
                  <MultiSelect
                    v-model="emailsPages"
                    :options="emailsPagesList"
                    option-label="label"
                    :placeholder="t('user.subUser.reports.chooseEmails')"
                    :filter="true"
                    display="chip"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer />
      </Card>
    </div>
  </div>

  <div class="grid pb-3">
    <div class="col-12">
      <Loader v-if="isLoading" />
      <Card>
        <template #title>
          {{ t('profile.myInformation.subUser.permissionsTitle') }}
        </template>
        <template #content>
          <Permissions
            :label="permissions.label"
            :nodes="permissions.nodes"
            :value="permissions.value"
            :type="permissions.type"
            :group="permissions.group"
            :depth="0"
            :hidden="permissions.hidden"
          />
        </template>
      </Card>
    </div>
  </div>

  <div class="grid pb-3">
    <div class="col-12 text-center">
      <Button
        :label="t('user.subUser.validate')"
        class="p-button p-button-primary"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Permissions from '@/views/user/Permissions.vue';
import permissionsArray, { groupElementArr, permissionsArr, subGroupElementArr } from '@/configs/permissions';
import {
  computed, defineComponent, onMounted, Ref, ref,
} from 'vue';
import { Me, refreshTokenLogin, UserState } from '@/composables/User';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Loader from '@/components/layout/Loader.vue';
import {
  OperatorType,
  ShopsUsers,
  ShopsUsersInputItem,
  ShopsUsersUpdateInputItem,
  UsersPagesConfiguration,
  UsersPagesSubscriptionsDeleteInput,
  UsersPagesSubscriptionsInputItem,
  UsersPermissions,
  UsersPermissionsInputItem,
  UsersPermissionsUpdateInputItem, UsersPermissionsUpsertInputItem,
} from '@/types/generated-types/graphql';
import moment, { unitOfTime } from 'moment';
import InsertShopsUsers, {
  getShopsUser,
  InsertUsersPermissions,
  shopUserList,
  UpdateShopsUsers,
  UpsertUsersPermissions,
} from '@/composables/user/ShopsUsers';
import { showToastError, showToastSuccess } from '@/helpers';
import { useI18n } from 'vue-i18n';
import Card from 'primevue/card';
import { isValidEmail } from '@/helpers/Email';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import * as lzstring from 'lz-string';
import InlineMessage from 'primevue/inlinemessage';
import {
  DropdownOption, StatsType, TimeUnits, UserTypeEnum,
} from '@/types';
import {
  DeleteUsersPagesSubscriptions,
  getUserStatsPages,
  InsertUsersPagesSubscriptions,
} from '@/composables/Statistics/usersPagesSubscriptions';
import { List } from '@/composables/GraphQL';
import { getUsersPagesConfiguration } from '@/composables/Statistics/usersPagesConfiguration';
import { ErrorConfigForm, Period } from '@/types/automated-scenarios';
import Checkbox from 'primevue/checkbox';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import useVuelidate from '@vuelidate/core';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { required, email, helpers } from '@vuelidate/validators';
import { useStore } from '@/store';

export default defineComponent({
  name: 'PermissionsByShop',

  components: {
    FieldErrors,
    Permissions,
    Button,
    InputText,
    Card,
    InlineMessage,
    Dropdown,
    MultiSelect,
    Loader,
    PeriodSelector,
    Checkbox,
  },

  props: {
    userId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },

  setup(props: { userId: number | string }) {
    const store = useStore();
    const { t, locale } = useI18n();
    const componentFieldErrorsKey = ref(0);
    const error = ref();
    const permissions = ref(permissionsArray);
    const { shops } = UserState.user;
    const emailUser = ref();
    const firstName = ref();
    const isLoading = ref(true);
    const isStatsLoading = ref(true);
    const lastName = ref();
    const dashboard: Ref<number> = ref(0);
    const statsPages: Ref<DropdownOption[]> = ref([]);
    const emailsPages: Ref<DropdownOption[]> = ref([]);
    const dashboardsList: Ref<DropdownOption[]> = ref([{ label: t('user.subUser.reports.none'), value: '' }]);
    const allStatsPagesList: Ref<DropdownOption[]> = ref([]);
    const statsPagesList: Ref<DropdownOption[]> = ref([]);
    const emailsPagesList: Ref<DropdownOption[]> = ref([]);
    const oldUserStatsPagesList: Ref<number[]> = ref([]);
    const oldUserEmailsPagesList: Ref<number[]> = ref([]);
    const emailError = ref();
    const selectedShops = ref<number[]>([]);
    let errorOccurred = 0;
    const period = ref<Period>({
      unit: TimeUnits.DAY,
      value: 1,
    });
    const shopsUserInput = ref<ShopsUsers[]>([]);
    const shopsUserUpdateInput = ref<ShopsUsersUpdateInputItem[]>([]);
    const UsersPermissionsInput = ref<UsersPermissions[]>([]);
    const UsersPermissionsUpdateInput = ref<UsersPermissionsUpdateInputItem[]>([]);
    const noExpDate = ref<boolean>(true);
    const subUserLang: Ref<any> = ref(UserState.user.lang);
    const availableLanguages = ref([
      { value: 'fr', label: 'Français' },
      { value: 'en', label: 'English' },
    ]);

    const units = [
      { value: TimeUnits.DAY, label: t('automatedScenarios.fields.durations.day(s)') },
      { value: TimeUnits.MONTH, label: t('automatedScenarios.fields.durations.month(s)') },
    ];

    const options = ref<{code: number; label: string}[]>([]);

    shops.forEach((shop) => {
      options.value.push({
        code: shop.id,
        label: shop.name,
      });
    });

    const formData = computed(() => ({
      lastName: lastName.value,
      firstName: firstName.value,
      email: emailUser.value,
      shops: selectedShops.value,
      lang: subUserLang.value,
    }));

    const insertShopsUsers = async (groupElements: NodeListOf<Element>, subGroupElements: NodeListOf<Element>, elements: NodeListOf<Element>) => {
      isLoading.value = true;
      const shopPeriod: Period = period.value;
      let unit: unitOfTime.DurationConstructor = 'd';
      if (period.value.unit === TimeUnits.MONTH) {
        unit = 'm';
      }
      const expiryDate: string | null = noExpDate.value === false ? moment(new Date()).add(period.value.value, unit).format('YYYY-MM-DD, HH:mm:ss') : null;

      const permArray: string[] = [];
      const subGroupArray: string[] = [];
      const groupArray: string[] = [];

      Object.values(elements).forEach((element) => {
        permArray.push(element.id);
      });
      Object.values(subGroupElements).forEach((element) => {
        subGroupArray.push(element.id);
      });
      Object.values(groupElements).forEach((element) => {
        groupArray.push(element.id);
      });

      if (permArray.length === 0) {
        return;
      }

      const compressedIds = lzstring.compressToBase64(JSON.stringify({ perms: permArray, groups: groupArray, subGroups: subGroupArray })) ?? '';

      if (!compressedIds) {
        await showToastError('GENERIC_ERROR');
        return;
      }

      // Check if there is no existing user with the same email and same id_ps_customer
      const check = await shopUserList(emailUser.value.toLowerCase());

      if (check && check.total > 0) {
        // User already exists for this account
        errorOccurred += 1;
        isLoading.value = false;
        await showToastError(t('user.subUser.alreadyExists'));
        return;
      }

      // insert shops users
      const request: ShopsUsersInputItem = {
        id_ps_customer: UserState.user.idPsCustomer,
        first_name: firstName.value,
        last_name: lastName.value,
        lang: subUserLang.value,
        email: emailUser.value.toLowerCase(),
        active: true,
        date_creation: moment().format('YYYY-MM-DD, HH:mm:ss'),
        date_modification: moment().format('YYYY-MM-DD, HH:mm:ss'),
        shops: selectedShops.value,
      };
      shopsUserInput.value.push(request);

      const result = await InsertShopsUsers(shopsUserInput.value);

      shopsUserInput.value = [];

      if (result.err !== '') {
        await showToastError('GENERIC_ERROR');
        return;
      }

      // Add dashboard and stats pages
      const pagesToLink: UsersPagesSubscriptionsInputItem[] = [];

      let priority = 0;
      if (dashboard.value && dashboard.value !== 0) {
        const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: dashboard.value,
          id_user: parseInt(result.id.toString(), 10),
          user_type: UserTypeEnum.USER,
          type: StatsType.DASHBOARD,
          priority,
        };

        pagesToLink.push(shopsUsersPageInput);
      }

      if (statsPages.value && statsPages.value.length) {
        statsPages.value.forEach((item: DropdownOption) => {
          priority += 1;

          const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
            id_user: parseInt(result.id.toString(), 10),
            id_users_pages_configuration: parseInt(item.value.toString(), 10),
            user_type: UserTypeEnum.USER,
            type: StatsType.STATS,
            priority,
          };

          pagesToLink.push(shopsUsersPageInput);
        });
      }

      if (pagesToLink.length) {
        await InsertUsersPagesSubscriptions(pagesToLink);
      }

      if (elements.length === 0) {
        await showToastSuccess('savedSuccessful');
      }

      // Add permissions
      selectedShops.value.forEach((idShop) => {
        const UsersPermissionsEntity: UsersPermissionsInputItem = {
          id_shop: idShop,
          id_shops_user: result.id,
          date_creation: moment().format('YYYY-MM-DD, HH:mm:ss'),
          active: 1,
          id_ps_customer: UserState.user.idPsCustomer,
          permissions: compressedIds,
          period: JSON.stringify(shopPeriod),
          expiry_date: expiryDate,
        };
        UsersPermissionsInput.value.push(UsersPermissionsEntity);
      });

      const {
        err,
      } = await InsertUsersPermissions(UsersPermissionsInput.value);

      UsersPermissionsInput.value = [];
      if (err !== '') {
        await showToastError('GENERIC_ERROR');
        return;
      }

      isLoading.value = false;

      await showToastSuccess('savedSuccessful');

      // TODO Reactivate when symfony will be published
      // const res = await refreshTokenLogin(UserState.activeShop?.id ?? 0);
      //
      // if (res && res.success) {
      //   const { idShop } = res;
      //   await Me(true, Number(idShop));
      //   store.commit('liveEditor/resetWebsiteContents');
      //   await router.push({ name: 'user.sub-user-list' });
      // }
    };

    const updateShopsUsers = async (groupElements: NodeListOf<Element>, subGroupElements: NodeListOf<Element>, elements: NodeListOf<Element>) => {
      isLoading.value = true;

      const permArray: string[] = [];
      const subGroupArray: string[] = [];
      const groupArray: string[] = [];

      Object.values(elements).forEach((element) => {
        permArray.push(element.id);
      });
      Object.values(subGroupElements).forEach((element) => {
        subGroupArray.push(element.id);
      });
      Object.values(groupElements).forEach((element) => {
        groupArray.push(element.id);
      });

      let compressedIds = '';
      if (permArray.length > 0) {
        compressedIds = lzstring.compressToBase64(JSON.stringify({ perms: permArray, groups: groupArray, subGroups: subGroupArray })) ?? '';
      }

      if (!compressedIds) {
        await showToastError('GENERIC_ERROR');
        return;
      }

      // update shops users
      const request: ShopsUsersUpdateInputItem = {
        id_shops_user: parseInt(props.userId.toString(), 10),
        first_name: firstName.value,
        last_name: lastName.value,
        lang: subUserLang.value,
        email: emailUser.value,
        date_modification: moment()
          .format('YYYY-MM-DD, HH:mm:ss'),
        shops: selectedShops.value,
      };
      shopsUserUpdateInput.value.push(request);

      const result = await UpdateShopsUsers(shopsUserUpdateInput.value);

      shopsUserUpdateInput.value = [];

      if (result.err !== '') {
        await showToastError('GENERIC_ERROR');
        return;
      }

      const shopPeriod: Period = period.value;
      let unit: unitOfTime.DurationConstructor = 'd';
      if (period.value.unit === TimeUnits.MONTH) {
        unit = 'm';
      }
      const expiryDate: string | null = noExpDate.value === false ? moment(new Date()).add(period.value.value, unit).format('YYYY-MM-DD, HH:mm:ss') : null;

      const UsersPermissionsEntity: UsersPermissionsUpsertInputItem = {
        shop_ids: selectedShops.value,
        id_user: result.id,
        id_ps_customer: UserState.user.idPsCustomer,
        permissions: compressedIds,
        period: JSON.stringify(shopPeriod),
        expiry_date: expiryDate,
      };

      const {
        err,
      } = await UpsertUsersPermissions([UsersPermissionsEntity]);

      UsersPermissionsUpdateInput.value = [];
      if (err !== '') {
        await showToastError('GENERIC_ERROR');
        return;
      }

      // Add dashboard and stats pages
      const pagesToLink: UsersPagesSubscriptionsInputItem[] = [];

      let priority = 0;
      if (dashboard.value && dashboard.value !== 0) {
        const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
          id_users_pages_configuration: dashboard.value,
          id_user: parseInt(result.id.toString(), 10),
          user_type: UserTypeEnum.USER,
          type: StatsType.DASHBOARD,
          priority,
        };

        pagesToLink.push(shopsUsersPageInput);
      }

      if (statsPages.value && statsPages.value.length) {
        statsPages.value.forEach((item: DropdownOption) => {
          priority += 1;

          const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
            id_users_pages_configuration: parseInt(item.value.toString(), 10),
            id_user: parseInt(result.id.toString(), 10),
            user_type: UserTypeEnum.USER,
            type: StatsType.STATS,
            priority,
          };

          pagesToLink.push(shopsUsersPageInput);
        });
      }

      if (emailsPages.value && emailsPages.value.length) {
        emailsPages.value.forEach((item: DropdownOption) => {
          const shopsUsersPageInput: UsersPagesSubscriptionsInputItem = {
            id_users_pages_configuration: parseInt(item.value.toString(), 10),
            id_user: parseInt(result.id.toString(), 10),
            user_type: UserTypeEnum.USER,
            type: StatsType.EMAIL,
            priority: 0,
          };

          pagesToLink.push(shopsUsersPageInput);
        });
      }

      if (pagesToLink.length) {
        // We delete old pages links
        const usersPagesSubscriptionsDelete: UsersPagesSubscriptionsDeleteInput[] = [];

        oldUserStatsPagesList.value.forEach((page) => {
          usersPagesSubscriptionsDelete.push({
            id_users_pages_configuration: page,
            id_user: parseInt(props.userId.toString(), 10),
            user_type: UserTypeEnum.USER,
          });
        });

        oldUserEmailsPagesList.value.forEach((page) => {
          usersPagesSubscriptionsDelete.push({
            id_users_pages_configuration: page,
            id_user: parseInt(props.userId.toString(), 10),
            user_type: UserTypeEnum.USER,
          });
        });

        if (usersPagesSubscriptionsDelete.length > 0) {
          DeleteUsersPagesSubscriptions(usersPagesSubscriptionsDelete)
            .then(async () => {
              await InsertUsersPagesSubscriptions(pagesToLink);
            })
            .catch((err1: any) => {
              //console.log(err1);
            });
        } else {
          await InsertUsersPagesSubscriptions(pagesToLink);
        }
      }
      isLoading.value = false;
      await showToastSuccess('savedSuccessful');
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      const hasShops = (value: number[]) => value.length > 0;

      const rules = {
        lastName: {
          required,
        },

        firstName: {
          required,
        },

        email: {
          required,
          email,
        },

        shops: {
          hasShops: helpers.withMessage(t('vuelidate.hasShops'), hasShops),
        },
      };

      const v$ = useVuelidate(rules, formData.value);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleClick = async (event: Event): Promise<any> => {
      // Add vuelidate
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return;
      }

      const elements: NodeListOf<Element> = document.querySelectorAll('div[class*="p-checkbox-checked"][class$="perms"]');
      const subGroupElements: NodeListOf<Element> = document.querySelectorAll('div[class*="p-checkbox-checked"][class$="sub-group"]');
      const groupElements: NodeListOf<Element> = document.querySelectorAll('div[class*="p-checkbox-checked"][class$="groups"]');

      if (props.userId) {
        await updateShopsUsers(groupElements, subGroupElements, elements);
      } else {
        await insertShopsUsers(groupElements, subGroupElements, elements);
      }

      if (errorOccurred > 0) {
        errorOccurred = 0;
        return;
      }

      await router.push({ name: 'user.sub-user-list' });
    };

    const selectDashboard = (dashboardSelected: number) => {
      statsPagesList.value = allStatsPagesList.value.filter((page: DropdownOption) => page.value !== dashboardSelected);
    };

    onMounted(async () => {
      // Get all stats pages
      const userStatsPages = await getUsersPagesConfiguration(StatsType.STATS, UserState.user.id.toString());
      if (userStatsPages && userStatsPages.items && userStatsPages.items.length) {
        userStatsPages.items.forEach((item) => {
          let pageName = item.page_name ?? '';
          try {
            const parsedPageName = JSON.parse(pageName);
            if (locale.value in parsedPageName) {
              pageName = parsedPageName[locale.value];
            } else {
              pageName = item.page_name ?? '';
            }
          } catch (err) {
            pageName = item.page_name ?? '';
          }
          const page: DropdownOption = { label: pageName, value: item.id_users_pages_configuration };
          dashboardsList.value.push(page);
          allStatsPagesList.value.push(page);
          statsPagesList.value.push(page);
        });
      }

      // Get all emails pages
      const userEmailsPages = await getUsersPagesConfiguration(StatsType.EMAIL, UserState.user.id.toString());
      if (userEmailsPages && userEmailsPages.items && userEmailsPages.items.length) {
        userEmailsPages.items.forEach((item) => {
          let pageName = item.page_name ?? '';
          try {
            const parsedPageName = JSON.parse(pageName);
            if (locale.value in parsedPageName) {
              pageName = parsedPageName[locale.value];
            } else {
              pageName = item.page_name ?? '';
            }
          } catch (err) {
            pageName = item.page_name ?? '';
          }
          const page: DropdownOption = { label: pageName, value: item.id_users_pages_configuration };
          emailsPagesList.value.push(page);
        });
      }

      if (props.userId) {
        const result = await getShopsUser(parseInt(props.userId.toString(), 10));
        firstName.value = result.item?.first_name;
        lastName.value = result.item?.last_name;
        emailUser.value = result.item?.email;
        if (result && result.item && result.item.lang && typeof result.item.lang === 'string') {
          subUserLang.value = result.item?.lang;
        }
        let fullArr;
        if (result.item && result.item.user_permissions && result.item.user_permissions.length > 0) {
          let permissionsIndex: number|null = null;
          result.item.user_permissions.forEach((shopsPermission, index) => {
            if (shopsPermission && UserState.user.shops.find((s) => s.id === shopsPermission.id_shop)) {
              selectedShops.value.push(shopsPermission.id_shop);

              if (!permissionsIndex) {
                permissionsIndex = index;
              }
            }
          });

          if (permissionsIndex !== null) {
            period.value = JSON.parse(result.item.user_permissions[permissionsIndex]?.period ?? '{}');
            const permissionsToDecode = result.item.user_permissions[permissionsIndex]?.permissions;

            if (permissionsToDecode) {
              const base64String = lzstring.decompressFromBase64(permissionsToDecode);
              fullArr = base64String ? JSON.parse(base64String) : {};
            }
          }
        }
        permissionsArr.value = fullArr ? fullArr.perms : [];
        groupElementArr.value = fullArr ? fullArr.groups : [];
        subGroupElementArr.value = fullArr ? fullArr.subGroups : [];

        // If we are editing an user, we get his current dashboard and stats pages
        const dashboards = await getUserStatsPages(parseInt(props.userId.toString(), 10), UserTypeEnum.USER, StatsType.DASHBOARD);

        if (dashboards && dashboards.items.length > 0) {
          // We get the dashboard of the user according to current stats pages available
          let currentDashboard = null;
          dashboards.items.every((item) => {
            if (allStatsPagesList.value.filter((allPage) => allPage.value === item.id_users_pages_configuration).length > 0) {
              currentDashboard = item.id_users_pages_configuration;
              oldUserStatsPagesList.value.push(item.id_users_pages_configuration);
              return false;
            }

            return true;
          });

          if (currentDashboard) {
            dashboard.value = currentDashboard;
          }
        }

        const oldStatsPages = await getUserStatsPages(parseInt(props.userId.toString(), 10), UserTypeEnum.USER, StatsType.STATS);

        if (oldStatsPages && oldStatsPages.items && oldStatsPages.items.length) {
          const oldPagesIds: number[] = [];
          oldStatsPages.items.forEach((page) => {
            if (allStatsPagesList.value.filter((allPage) => allPage.value === page.id_users_pages_configuration).length > 0) {
              oldUserStatsPagesList.value.push(page.id_users_pages_configuration);
              oldPagesIds.push(page.id_users_pages_configuration);
            }
          });

          if (oldPagesIds.length) {
            // Get all pages
            const { items } = await List<UsersPagesConfiguration>({
              name: 'UsersPagesConfiguration',
              settings: {
                filter: [
                  {
                    field: 'id_users_pages_configuration',
                    operator: OperatorType.In,
                    value: oldPagesIds.join(','),
                  },
                ],
                order: [
                  {
                    field: 'id_users_pages_configuration',
                    type: 'ASC',
                  },
                ],
                limit: 0,
                offset: 0,
              },
              fields: ['id_users_pages_configuration', 'page_name'],
            });

            if (items && items.length) {
              items.forEach((page) => {
                let pageName = page.page_name;
                try {
                  const parsedPageName = JSON.parse(pageName);
                  if (locale.value in parsedPageName) {
                    pageName = parsedPageName[locale.value];
                  } else {
                    pageName = page.page_name;
                  }
                } catch (err) {
                  pageName = page.page_name;
                }
                const currentStatsPageOption: DropdownOption = { label: pageName, value: page.id_users_pages_configuration };
                statsPages.value.push(currentStatsPageOption);
              });
            }
          }
        }

        const oldEmailsPages = await getUserStatsPages(parseInt(props.userId.toString(), 10), UserTypeEnum.USER, StatsType.EMAIL);

        if (oldEmailsPages && oldEmailsPages.items && oldEmailsPages.items.length) {
          const oldEmailsPagesIds: number[] = [];
          oldEmailsPages.items.forEach((page) => {
            if (emailsPagesList.value.filter((allPage) => allPage.value === page.id_users_pages_configuration).length > 0) {
              oldUserEmailsPagesList.value.push(page.id_users_pages_configuration);
              oldEmailsPagesIds.push(page.id_users_pages_configuration);
            }
          });

          if (oldEmailsPagesIds.length) {
            // Get all pages
            const { items } = await List<UsersPagesConfiguration>({
              name: 'UsersPagesConfiguration',
              settings: {
                filter: [
                  {
                    field: 'id_users_pages_configuration',
                    operator: OperatorType.In,
                    value: oldEmailsPagesIds.join(','),
                  },
                ],
                order: [
                  {
                    field: 'id_users_pages_configuration',
                    type: 'ASC',
                  },
                ],
                limit: 0,
                offset: 0,
              },
              fields: ['id_users_pages_configuration', 'page_name'],
            });

            if (items && items.length) {
              items.forEach((page) => {
                let pageName = page.page_name;
                try {
                  const parsedPageName = JSON.parse(pageName);
                  if (locale.value in parsedPageName) {
                    pageName = parsedPageName[locale.value];
                  } else {
                    pageName = page.page_name;
                  }
                } catch (err) {
                  pageName = page.page_name;
                }
                const currentEmailsPageOption: DropdownOption = { label: pageName, value: page.id_users_pages_configuration };
                emailsPages.value.push(currentEmailsPageOption);
              });
            }
          }
        }
        isStatsLoading.value = false;
      } else {
        // assign all groups
        const groupElements = document.querySelectorAll('[data-group^=root]');
        Object.values(groupElements)
          .forEach((groupElement) => {
            groupElementArr.value.push(groupElement.id);
          });

        // assign all subGroups
        const subGroupElements = document.querySelectorAll('div[class$=sub-group]');
        Object.values(subGroupElements)
          .forEach((subGroupElement) => {
            subGroupElementArr.value.push(subGroupElement.id);
          });

        // assign all permissions
        const elements: HTMLCollection = document.getElementsByClassName('perms');
        Object.values(elements)
          .forEach((element) => {
            permissionsArr.value.push(element.id);
          });

        // If we are creating a new user, we get admin user's dashboard and stats pages
        const dashboards = await getUserStatsPages(UserState.user.id, UserTypeEnum.ADMIN, StatsType.DASHBOARD);

        if (dashboards && dashboards.items.length > 0) {
          dashboard.value = dashboards.items[0].id_users_pages_configuration;
        }

        const oldStatsPages = await getUserStatsPages(UserState.user.id, UserTypeEnum.ADMIN, StatsType.STATS);

        if (oldStatsPages && oldStatsPages.items && oldStatsPages.items.length) {
          const oldPagesIds: number[] = [];
          oldStatsPages.items.forEach((page) => {
            oldPagesIds.push(page.id_users_pages_configuration);
          });

          if (oldPagesIds.length) {
            // Get all pages
            const { items } = await List<UsersPagesConfiguration>({
              name: 'UsersPagesConfiguration',
              settings: {
                filter: [
                  {
                    field: 'id_users_pages_configuration',
                    operator: OperatorType.In,
                    value: oldPagesIds.join(','),
                  },
                ],
                order: [
                  {
                    field: 'id_users_pages_configuration',
                    type: 'ASC',
                  },
                ],
                limit: 0,
                offset: 0,
              },
              fields: ['id_users_pages_configuration', 'page_name'],
            });

            if (items && items.length) {
              items.forEach((page) => {
                let pageName = page.page_name;
                try {
                  const parsedPageName = JSON.parse(pageName);
                  if (locale.value in parsedPageName) {
                    pageName = parsedPageName[locale.value];
                  } else {
                    pageName = page.page_name;
                  }
                } catch (err) {
                  pageName = page.page_name;
                }
                const currentStatsPageOption: DropdownOption = { label: pageName, value: page.id_users_pages_configuration };
                statsPages.value.push(currentStatsPageOption);
              });
            }
          }
        }

        const oldEmailsPages = await getUserStatsPages(UserState.user.id, UserTypeEnum.ADMIN, StatsType.EMAIL);

        if (oldEmailsPages && oldEmailsPages.items && oldEmailsPages.items.length) {
          const oldEmailsPagesIds: number[] = [];
          oldEmailsPages.items.forEach((page) => {
            oldEmailsPagesIds.push(page.id_users_pages_configuration);
          });

          if (oldEmailsPagesIds.length) {
            // Get all pages
            const { items } = await List<UsersPagesConfiguration>({
              name: 'UsersPagesConfiguration',
              settings: {
                filter: [
                  {
                    field: 'id_users_pages_configuration',
                    operator: OperatorType.In,
                    value: oldEmailsPagesIds.join(','),
                  },
                ],
                order: [
                  {
                    field: 'id_users_pages_configuration',
                    type: 'ASC',
                  },
                ],
                limit: 0,
                offset: 0,
              },
              fields: ['id_users_pages_configuration', 'page_name'],
            });

            if (items && items.length) {
              items.forEach((page) => {
                let pageName = page.page_name;
                try {
                  const parsedPageName = JSON.parse(pageName);
                  if (locale.value in parsedPageName) {
                    pageName = parsedPageName[locale.value];
                  } else {
                    pageName = page.page_name;
                  }
                } catch (err) {
                  pageName = page.page_name;
                }
                const currentEmailsPageOption: DropdownOption = { label: pageName, value: page.id_users_pages_configuration };
                emailsPages.value.push(currentEmailsPageOption);
              });
            }
          }
        }
      }

      isLoading.value = false;
      isStatsLoading.value = false;
    });

    return {
      permissions,
      handleClick,
      email: emailUser,
      firstName,
      lastName,
      emailError,
      dashboard,
      dashboardsList,
      statsPages,
      statsPagesList,
      emailsPages,
      emailsPagesList,
      t,
      selectDashboard,
      isLoading,
      units,
      period,
      noExpDate,
      options,
      selectedShops,
      isStatsLoading,
      error,
      componentFieldErrorsKey,
      subUserLang,
      availableLanguages,
    };
  },
});
</script>

<style lang="scss" scoped>
:deep() .p-card {
  & .p-card-title {
    font-size: 1.2rem;
    font-weight: normal;
    border-bottom: solid 1px $brand-color-primary;
    padding: 0 0 10px;
  }
}
</style>
