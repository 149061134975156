<template>
  <SpmOverlayPanel
    class-trigger="flex text-center profile-btn"
    class-header="flex align-items-center p-3 justify-content-left"
    class-content="flex flex-column justify-content-center spm-overlay-panel-content py-2"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-content"
    :close-panel="closePanel"
    @onclose-panel="closePanel = false"
  >
    <template #trigger>
      <SpmButton
        :label="t('myLists.manage.actions.listCreate')"
        icon="far fa-plus-circle"
        class-style="p-button p-button-success"
      />
    </template>
    <template #header>
      <div class="flex align-items-center justify-content-center ml-2">
        <h4> {{ t('myLists.manage.addList') }} </h4>
      </div>
    </template>
    <div class="flex mb-2">
      <BaseTextInput
        v-model="listName"
        input-type="text"
        field-id="listName"
        label="myLists.manage.nameList"
        input-style="width: 100%;"
        :errors="formValidation"
      />
    </div>
    <div class="flex">
      <div class="field-wrapper">
        <label
          class="block field-label mb-2"
        >
          Choisissez un type d'import
        </label>
        <BaseButtonGroup
          v-model="typeImport"
          :options="optionsImport"
        />
        <FieldErrors
          :errors="formValidation"
          field="typeImport"
        />
      </div>
    </div>

    <template #footer>
      <SpmButton
        :label="t('myLists.manage.createList')"
        :loading="loading"
        class-style="p-button p-button-success"
        @click="createList"
      />
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  defineComponent, reactive, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import BaseButtonGroup from '@/components/fields/BaseButtonGroup.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { useStore } from '@/store';
import { ListType } from '@/types';

export default defineComponent({
  name: 'ListCreatePanel',
  components: {
    SpmOverlayPanel,
    SpmButton,
    BaseTextInput,
    BaseButtonGroup,
    FieldErrors,
  },

  props: {
    enableNoImport: {
      type: Boolean,
      required: false,
      default: false,
    },

    idShop: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const loading = ref(false);
    const formValidation = ref<any>({});
    const listName = ref('');
    const typeImport = ref('file');
    const closePanel = ref(false);
    const optionsImport = [
      { value: 'file', label: t('myLists.importContacts.files.option') },
      { value: 'copyPaste', label: t('myLists.importContacts.copyPaste.option') },
      { value: 'segmentation', label: t('myLists.importContacts.segmentation.option'), disabled: false }, // Disable segmentation
    ];

    if (props.enableNoImport) {
      optionsImport.unshift({ value: 'noImport', label: t('myLists.importContacts.noImport') });
      typeImport.value = 'noImport';
    }

    const createList = async () => {
      loading.value = true;
      const data = reactive({
        listName: listName.value,
        typeImport: typeImport.value,
      });
      const rules = {
        listName: {
          required,
        },

        typeImport: {
          required,
        },
      };
      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      if (!success) {
        formValidation.value = v$;
        loading.value = false;
        return;
      }
      if (typeImport.value === 'noImport') {
        await store.dispatch('listImportEditor/saveNoImportAction',
          { idShop: props.idShop, listName: listName.value });
      } else {
        await store.dispatch('listImportEditor/importCustomersAction',
          {
            idShopList: 0,
            typeImport: typeImport.value,
            listName: listName.value,
            listType: typeImport.value === 'segmentation' ? ListType.SEGMENT : ListType.LIST
          });
      }
      loading.value = false;
      listName.value = '';
      typeImport.value = 'noImport';
      closePanel.value = true;
    };

    return {
      t,
      formValidation,
      listName,
      typeImport,
      optionsImport,
      createList,
      loading,
      closePanel,
    };
  },
});
</script>

<style lang="scss">

</style>
