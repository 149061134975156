
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  onMounted,
} from 'vue';

import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Loader from '@/components/layout/Loader.vue';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { MultiLangInput } from '@/types/store-parameters-types';
import { showToastError, showToastSuccess } from '@/helpers';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import {
  ShopsConfigurationInput,
  ShopsConfigurationInputItem,
  ShopsLang,
  ShopsLangUpdateInputItem,
} from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';
import fetchShopInformation, { ShopsLangCustom } from '@/composables/shop/Shops';
import updateShopsLang from '@/composables/shop/ShopsLang';

import { useI18n } from 'vue-i18n';

interface ShopsLangState {
  url: Record<string, any>;
  url_cart: Record<string, any>;
}

export default defineComponent({
  name: 'ImportantLinksServiceParameter',

  components: {
    LocalizedTextInput,
    FieldErrors,
    SpmButton,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const isLoading = ref(false);
    const saveLoading = ref(false);

    const field = reactive<Record<string, any>>({
      shopRequiredDataRgpd_privacyPolicyUrl: {},
    });

    const urlInput = ref<MultiLangInput>({});
    const urlCart = ref<MultiLangInput>({});
    const shopsLangState = reactive<ShopsLangState>({
      url: {},
      url_cart: {},
    });
    const shopsLangData = ref<ShopsLang[]>([]);

    const componentFieldErrorsKey = ref(0);
    const error = ref();
    const shopsLangError = ref();

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules: Record<string, any> = {
        shopRequiredDataRgpd_privacyPolicyUrl: {
          localizedTextInputValidator: localizedTextInputValidator('url'),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const validateShopsLangInfo = async (): Promise<ErrorConfigForm> => {
      const rules = {
        url: {
          localizedTextInputValidator: localizedTextInputValidator('url'),
        },

        url_cart: {
          localizedTextInputValidator: localizedTextInputValidator('url'),
        },
      };

      const v$ = useVuelidate(rules, shopsLangState);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const getShopsLangData = () => {
      const shopsLangsArray: ShopsLangUpdateInputItem[] = [];
      Object.entries(shopsLangState.url).forEach((urlRecord: Record<string, any>) => {
        const shopsLangRecord: ShopsLangUpdateInputItem = {
          id_shop: props.shopId,
          lang: urlRecord[0],
          url: urlRecord[1],
        };
        shopsLangsArray.push(shopsLangRecord);
      });

      shopsLangsArray.forEach((record: ShopsLangUpdateInputItem) => {
        if (record.lang) {
          // eslint-disable-next-line no-param-reassign
          record.url_cart = shopsLangState.url_cart[record.lang];
        }
      });

      return shopsLangsArray;
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();

      let validationErr = 0;

      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        validationErr += validation.validate.value.$errors.length;
      }

      const shopsLangValidations = await validateShopsLangInfo();

      if (!shopsLangValidations.success) {
        shopsLangError.value = shopsLangValidations.validate;
        componentFieldErrorsKey.value += 1;

        validationErr += shopsLangValidations.validate.value.$errors.length;
      }

      if (validationErr > 0) {
        return {
          err: validationErr,
          configs: [],
        };
      }

      const rgpdState = {
        ...field,
      };
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];
      let key = '';
      let value = '';
      Object.entries(rgpdState).forEach((keyValuePair: any) => {
        [key, value] = [...keyValuePair];
        const shopsConfigRecord: ShopsConfigurationInputItem = {
          key: '',
          value: '',
          lang: '',
        };

        if (typeof value === 'object' && !Array.isArray(value)) {
          let language = '';
          let inputValue = '';
          Object.entries(value).forEach((entry: any) => {
            [language, inputValue] = [...entry];

            const multiRecord: ShopsConfigurationInputItem = {
              key,
              value: inputValue,
              lang: language,
            };
            shopsConfigArray.push(multiRecord);
          });
        } else {
          shopsConfigRecord.key = key;
          shopsConfigRecord.value = value;
          shopsConfigRecord.lang = '';

          shopsConfigArray.push(shopsConfigRecord);
        }
      });

      const saveShopsLang = async () => {
        const shopsLangRecords: ShopsLangUpdateInputItem[] = getShopsLangData();
        await updateShopsLang(shopsLangRecords);
      };

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
          additionnalSave: saveShopsLang,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        await saveShopsLang();
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    async function retrieveShopInfo() {
      const info = await fetchShopInformation({ shopId: props.shopId });

      if (info === null) {
        return;
      }

      info.langs.forEach((shopsLang: ShopsLangCustom) => {
        shopsLangData.value.push({
          id_shop: props.shopId,
          lang: shopsLang.lang,
          url: shopsLang.url,
          url_cart: shopsLang.urlCart,
          default: shopsLang.default,
        });
      });

      if (info.langs) {
        urlInput.value = info.langs.reduce((p: any, current: { lang: string; url: string }) => ({
          ...p,
          [current.lang]: current.url,
        }), {});
        urlCart.value = info.langs.reduce((p: any, current: { lang: string; urlCart: string }) => ({
          ...p,
          [current.lang]: current.urlCart,
        }), {});
      }

      shopsLangState.url = {};
      shopsLangState.url_cart = {};
      shopsLangState.url = Object.assign(shopsLangState.url, urlInput.value);
      shopsLangState.url_cart = Object.assign(shopsLangState.url_cart, urlCart.value);
    }

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);
        await retrieveShopInfo();
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      isLoading,
      saveLoading,
      field,
      componentFieldErrorsKey,
      error,
      shopsLangState,
      shopsLangError,
      handleSave,
    };
  },
});
