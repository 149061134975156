<template>
  <div class="steps-config">
    <div class="flex justify-content-between steps-config-row">
      <div class="flex flex-column align-items-center justify-content-center steps-config-step"
           :class="{ 'active': !showMappings }"
      >
        <div class="flex align-items-center justify-content-center steps-config-step-number">
          1
        </div>
        <div class="flex steps-config-step-label">
          Saisie des données d'import
        </div>
      </div>
      <div class="flex flex-column align-items-center justify-content-center steps-config-step"
           :class="{ 'active': showMappings }"
      >
        <div class="flex align-items-center justify-content-center steps-config-step-number">
          2
        </div>
        <div class="flex steps-config-step-label">
          Assignation des champs de votre données
        </div>
      </div>
    </div>
  </div>
  <Transition v-if="!showMappings">
    <div class="flex flex-wrap mb-3">
      <div
        class="flex flex-column field-wrapper"
        style="width: 100%;"
      >
        <label class="block mb-2">
          {{ t('myLists.importContacts.copyPaste.label') }}
          <i
            v-tooltip="{ value: t('myLists.importContacts.copyPaste.labelTips'), escape: true, class: 'tooltip-icons-outside' }"
            class="fas fa-question-circle"
            data-html="true"
            data-toggle="tooltip"
            aria-hidden="true"
          />
        </label>
        <Textarea
          v-model="emailsStr"
          style="width: 100%; min-height: 10rem; resize: vertical;"
        />
      </div>
    </div>
  </Transition>
  <Transition v-if="showMappings">
    <ColumnMappings :data-string="emailsStr" />
  </Transition>
  <div
    class="flex justify-content-between field-wrapper"
    style="width: 100%;"
  >
    <div class="flex align-items-center justify-content-center">
      <SpmButton
        v-if="showMappings"
        :label="t('Précedent')"
        icon="fa-regular fa-arrow-left"
        class-style="p-button p-button-success"
        @click="showMappings= false"
      />
    </div>
    <div class="flex align-items-center justify-content-center">
      <SpmButton
        v-if="!showMappings"
        :label="t('Suivant')"
        icon="fa-regular fa-arrow-right"
        class-style="p-button p-button-success"
        @click="validateCopyPasteInput"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import Textarea from 'primevue/textarea';
import { isValidEmail } from '@/helpers/Email';
import { useToast } from 'primevue/usetoast';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import ColumnMappings from '@/views/mes-listes/customers/ImportCustomers/ColumnMappings.vue';
import { store } from '@/store';
import { ManualListImportConfigData } from '@/types/lists';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'ConfigManualCopyPaste',

  components: {
    ColumnMappings,
    Textarea,
    SpmButton,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const showMappings = ref(false);
    const emailsStr = ref((store.getters['listImportEditor/getConfigData'] as ManualListImportConfigData).dataString);

    watch(() => emailsStr.value, () => {
      store.commit('listImportEditor/setConfigData', {
        ...store.getters['listImportEditor/getConfigData'],
        dataString: emailsStr.value,
      });
    });

    const validateCopyPasteInput = () => {
      const lines = emailsStr.value.trim().split('\n');
      lines.filter((line) => line !== '');

      const error = lines.some((line) => {
        const fields = line.split(',');
        return fields.filter((field) => isValidEmail(field)).length === 0;
      });

      if (error) {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Emails not valid',
          life: 3000,
        });
        return;
      }

      showMappings.value = true;
    };

    return {
      t,
      showMappings,
      emailsStr,
      validateCopyPasteInput,
    };
  },
});
</script>

<style lang="scss">
.steps-config {
  height: 8rem;
  position: relative;
  margin-bottom: 2rem;
  margin-top: -2rem;

  .steps-config-row {
    &:before {
      top: 50%;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 100%;
      height: 1px;
      background-color: #94c840;
    }
    .steps-config-step {
      width: 100%;
      margin-top: 2.5rem;

      .steps-config-step-number {
        color: #495057;
        border: 1px solid #94c840;
        background: #ffffff;
        width: 3rem;
        height: 3rem;
        line-height: 2rem;
        font-size: 1.143rem;
        z-index: 1;
        border-radius: 50%;
      }

      .steps-config-step-label {
        margin-top: 0.5rem;
        color: #6c757d;
      }

      &.active {
        .steps-config-step-number {
          background-color: $extra-light-grey;
        }

        .steps-config-step-label {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
