
import {
  defineComponent,
  onMounted,
  PropType, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Timepicker from '@/components/fields/Timepicker.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import DeclencheurPeriodicLaunchingMetaData, {
  DeclencheurPeriodicLaunchingData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurPeriodicLaunchingMetadata';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'DeclencheurPeriodicLaunching',
  components: {
    FieldErrors,
    Timepicker,
    ButtonGroup,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurPeriodicLaunchingData>,
      required: true,
      default() {
        return DeclencheurPeriodicLaunchingMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurPeriodicLaunchingData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const type = ref('');
    const days = ref<Array<string>>([]);
    const time = ref('');
    const tzclients = ref('');

    // eslint-disable-next-line vue/no-setup-props-destructure
    type.value = props.modelValue.multiplebox_trigger_type;
    days.value = Object.values(props.modelValue.multiplebox_trigger_days ?? {});
    // eslint-disable-next-line vue/no-setup-props-destructure
    time.value = props.modelValue.time;
    // eslint-disable-next-line vue/no-setup-props-destructure
    tzclients.value = props.modelValue.multiplebox_trigger_timezone_clients;

    const triggerTypes = [
      { value: '0', label: t('automatedScenarios.fields.launches.everyDay') },
      { value: '1', label: t('automatedScenarios.fields.launches.everyWeek') },
    ];

    const triggerDays = [
      { value: 'mon', label: t('automatedScenarios.fields.launchDays.mon') },
      { value: 'tue', label: t('automatedScenarios.fields.launchDays.tue') },
      { value: 'wed', label: t('automatedScenarios.fields.launchDays.wed') },
      { value: 'thu', label: t('automatedScenarios.fields.launchDays.thu') },
      { value: 'fri', label: t('automatedScenarios.fields.launchDays.fri') },
      { value: 'sat', label: t('automatedScenarios.fields.launchDays.sat') },
      { value: 'sun', label: t('automatedScenarios.fields.launchDays.sun') },
    ];

    const yesno = [
      { value: '0', label: t('no') },
      { value: '1', label: t('yes') },
    ];

    const update = () => {
      const d = days.value.reduce((prev, c) => {
        let k: number;

        switch (c) {
          case 'mon':
            k = 0;
            break;
          case 'tue':
            k = 1;
            break;
          case 'wed':
            k = 2;
            break;
          case 'thu':
            k = 3;
            break;
          case 'fri':
            k = 4;
            break;
          case 'sat':
            k = 5;
            break;
          case 'sun':
            k = 6;
            break;
          default:
            return prev;
        }

        return {
          ...prev,
          [k]: c,
        };
      }, {});

      context.emit('update:modelValue', DeclencheurPeriodicLaunchingMetaData.Create({
        multiplebox_trigger_type: type.value,
        multiplebox_trigger_days: d,
        time: time.value,
        multiplebox_trigger_timezone_clients: tzclients.value,
      }));
    };

    watch([type, days, time, tzclients], update);
    onMounted(update);

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      type,
      days,
      time,
      tzclients,
      triggerTypes,
      triggerDays,
      yesno,
    };
  },
});
