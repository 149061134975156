<template>
  <div class="mt-2">
    <h3 class="mb-4">
      {{ t('myStoreMenu.myNotifications') }}
    </h3>
    <div class="grid">
      <div class="col-12">
        <Message
          v-if="!notifications.length"
          severity="info"
          :closable="false"
        >
          {{ t('noRecords') }}
        </Message>
        <div v-if="!notifications">
          <p class="text-center">
            {{ t('noRecords') }}
          </p>
        </div>
        <div v-else>
          <div
            v-for="notification of notifications"
            :key="notification.id_shop_notification"
          >
            <Card class="timeline-card">
              <template #title>
                <span v-if="notification.type === 'low'">
                  🟢
                </span>
                <span v-else-if="notification.type === 'normal'">
                  🟠
                </span>
                <span v-else>
                  🔴
                </span>
                <span v-html="notification.title" />
              </template>
              <template #subtitle>
                {{ notification.date }}
              </template>
              <template #content>
                <span
                  class="content"
                  v-html="notification.message"
                />
              </template>
            </Card>
          </div>
        </div>
        <div style="float:right">
          <Button
            v-if="hasMore"
            :loading="loadingHasMore"
            :label="t('shop.more_notifs')"
            icon="far fa-angle-double-right"
            icon-pos="right"
            @click="fetchMore"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Message from 'primevue/message';
import {
  defineComponent, ref, onMounted,
} from 'vue';
import {
  fetchNotifications, markNotificationsAsRead,
} from '@/composables/shop/Notifications';
import { UserState } from '@/composables/User';
import {
  ShopsNotifications,
  ShopsNotificationsReadInputItem,
} from '@/types/generated-types/graphql';

export default defineComponent({
  name: 'MyNotifications',
  components: {
    Card,
    Button,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const notifications: any = ref<ShopsNotifications[]>([]);
    const shopId = UserState?.activeShop?.id ?? 0;
    const paging = ref<number>(0);
    const hasMore = ref(true);
    const loadingHasMore = ref(false);

    const getNotifications = async () => {
      loadingHasMore.value = true;
      const getNotificationsItems = await fetchNotifications({ shopId, paging: paging.value });
      loadingHasMore.value = false;

      if (getNotificationsItems && getNotificationsItems.length === 0) {
        hasMore.value = false;
        return;
      }

      notifications.value = notifications.value.concat(getNotificationsItems);
      //console.log(notifications.value);
    };

    const fetchMore = async () => {
      paging.value += 1;
      await getNotifications();
    };

    const markAllAsRead = async () => {
      const notificationsReadRecords: ShopsNotificationsReadInputItem[] = [];
      notifications.value.forEach((item: ShopsNotifications) => {
        if (item.shopsNotificationsRead?.length === 0) {
          notificationsReadRecords.push({
            id_shop_notification: item.id_shop_notification,
            id_shop: shopId,
            date: new Date(),
          });
        }
      });

      if (notificationsReadRecords.length > 0) {
        await markNotificationsAsRead(notificationsReadRecords);
      }
    };

    onMounted(async () => {
      await getNotifications();
      await markAllAsRead();
    });

    return {
      t,
      notifications,
      fetchMore,
      hasMore,
      loadingHasMore,
    };
  },
});
</script>

<style lang="scss" scoped>
.xx-large {
  font-size: xx-large;
}

.timeline-card {
  margin: 20px 0;
}

::v-deep(.p-timeline-event) {
  .content {
    a {
      color: #94c840 !important;
      &:hover {
        color: #94c840 !important;
      }
    }
  }
}
</style>
