<template>
  <footer class="footer items-center py-3">
    <div class="container-fluid mx-auto">
      <div class="grid grid-nogutter px-5">
        <div
          class="col-6 text-left"
          v-html="t('footer.copyright', { year: new Date().getFullYear() })"
        />
        <div class="col-6 text-right">
          {{ t('footer.cgu') }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Footer',

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.footer {
  background-color: $white;
  height: 51px;
  z-index: 1;

  &:deep() a {
    color: $brand-color-primary !important;
  }
}
</style>
