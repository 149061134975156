
import {
  defineComponent,
  Ref,
  ref,
  watch,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'UrlNavBar',

  components: {
    InputText,
    Button,
  },

  props: {
    homeUrl: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },
  },

  emits: {
    'change-url': String,
  },

  setup(props, context) {
    const currentUrl: Ref<string> = ref(props.url);

    const handleChangeUrl = async (event: any) => {
      if (!event || event.keyCode === 13) {
        context.emit('change-url', currentUrl.value);
      }
    };

    watch(() => props.url, (newValue) => {
      currentUrl.value = newValue;
    });

    return {
      currentUrl,
      handleChangeUrl,
    };
  },

});
