<template>
  <div
    class="field-group pt-2"
  >
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <BaseTextInput
            v-model="filters.custom_title"
            field-id="custom_title"
            label="automatedScenarios.fields.filterName"
            input-style="width:100%;"
            :errors="formValidationErrors"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="field-group pt-3"
  >
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <BaseTextInput
            v-model="filters.custom_description"
            field-id="custom_description"
            label="automatedScenarios.fields.filterDescription"
            input-style="width:100%;"
            :errors="formValidationErrors"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="field-wrapper pt-3">
    <label class="block mb-2">
      {{ t('automatedScenarios.fields.filterExitNumber') }}
    </label>

    <div
      class="flex"
      style="margin-bottom: 0.5rem;"
    >
      <div
        class="display-box-container-filter-output align-self-start"
        :class="{ 'selected': filters.outputs_number === 1 }"
        @click="selectNumberOutputs(1)"
      >
        <div class="db-cfo-icon">
          <i class="far fa-cogs" />
        </div>
        <div class="db-cfo-label">
          <span>{{ t('automatedScenarios.filters.field_outputs.one_output') }}</span>
        </div>
        <div class="db-cfo-outputs">
          <div style="left: 24px;">
            <i style="color: #93c840;">{{ t('automatedScenarios.filters.field_outputs.output_yes') }}</i>
          </div>
        </div>
      </div>
      <div
        class="display-box-container-filter-output align-self-end"
        :class="{ 'selected': filters.outputs_number === 2 }"
        @click="selectNumberOutputs(2)"
      >
        <div class="db-cfo-icon">
          <i class="far fa-cogs" />
        </div>
        <div class="db-cfo-label">
          <span>{{ t('automatedScenarios.filters.field_outputs.two_output') }}</span>
        </div>
        <div class="db-cfo-outputs">
          <div style="left: -1px;">
            <i style="color: #93c840;">{{ t('automatedScenarios.filters.field_outputs.output_yes') }}</i>
          </div>
          <div style="left: 49px;">
            <i style="color: #D8000C;">{{ t('automatedScenarios.filters.field_outputs.output_no') }}</i>
          </div>
        </div>
      </div>
    </div>

    <span
      v-if="decreasingNumberOfBranches"
      class="field-wrapper-error"
    >
      {{ t('automatedScenarios.filters.field_outputs.branch_reduction_warning') }}
    </span>
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      field="outputs_number"
    />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, PropType, reactive, ref, Ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { asInt } from '@/helpers';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';

export interface FilterGlobalInformations {
    custom_title: string;
    custom_description: string;
    outputs_number: number;
    initial_number_outputs?: null|number;
}

export default defineComponent({
  name: 'FilterGlobalInformations',

  components: {
    BaseTextInput,
    FieldErrors,
  },

  props: {
    modelValue: {
      type: Object as PropType<FilterGlobalInformations>,
      required: true,
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FilterGlobalInformations; formValidation: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const isEdit: Ref<boolean> = ref(state.selectedOperator.operatorAddMethod === null && state.selectedOperator.operatorId !== '');

    const initialNumberOutput = asInt(props.modelValue.outputs_number);
    const filters = reactive<FilterGlobalInformations>({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: asInt(props.modelValue.outputs_number),
      initial_number_outputs: isEdit.value ? initialNumberOutput : null,
    });

    const decreasingNumberOfBranches = computed(() => isEdit.value && (filters.outputs_number < initialNumberOutput));

    watch(filters, () => {
      emit('update:modelValue', filters);
    });

    const selectNumberOutputs = (nbrOutputs: number): void => {
      filters.outputs_number = asInt(nbrOutputs);
    };

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      filters,
      selectNumberOutputs,
      decreasingNumberOfBranches,
    };
  },
});
</script>

<style scoped lang="scss">
.display-box-container-filter-output {
  position: relative;
  height: 50px;
  border-radius: 0.4em;
  display: flex;
  cursor: pointer;
  border: solid 1px #BABFC3;
  background-color: white;
  margin-right: 1rem;
  color: $tuna !important;

  &:hover {
    background-color: #f2f2f2;
  }

  &.selected {
    background-color: $extra-light-grey;
  }

  .db-cfo-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-radius: 0 0.3em 0.3em 0;
    border-right: solid 1px #BABFC3;
    padding: 0 10px;
  }

  .db-cfo-label {
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: 0.4em;
    width: 160px;
    font-size: 12px;
    padding: 0 10px;
    background-color: #fff;
    color: #000;
  }

  .db-cfo-outputs {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;

    > div {
      position: absolute;
      cursor: pointer;
      top: 49px;
      transform: translate(-50%, -50%);
      background-color: white;
      padding: 0;
      width: 14px;
      height: 14px;
      border-radius: 25px;
      border: 1px solid #cecece;

      > i {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #93c840;
        font-size: 10px;
        font-weight: bold;
        font-style: unset;
      }
    }
  }
}

.field-wrapper {
  .field-wrapper-error {
    color: red;
    font-size: 0.7rem;
  }
}
</style>
