
import { useI18n } from 'vue-i18n';
import {
  defineComponent, reactive, Ref, ref, watch,
} from 'vue';
import ButtonGroup from '@/components/mes-lists/fields/ButtonGroup.vue';
import { ManualListImportSettingsData } from '@/types/lists';
import { store } from '@/store';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';

export default defineComponent({
  name: 'SettingsPanelManual',
  components: { BaseTextInput, ButtonGroup, ConfirmDialog },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: {settingPanelValidationErrors: any }) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const listId: Ref<string> = ref(store.getters['listImportEditor/getIdShopList']);

    const refreshIsDoubleOpTin = ref(0);

    /* Fields options */
    const typeOptInOptions = [
      {
        label: t('myLists.importContacts.assignment.opt_in'),
        value: false,
      },
      {
        label: t('myLists.importContacts.assignment.doubleOptIn'),
        value: true,
      },
    ];

    const yesNoOptions = [
      {
        label: t('yes'),
        value: true,
      },
      {
        label: t('no'),
        value: false,
      },
    ];

    /* Fields values */
    const listName: Ref<string> = ref(store.getters['listImportEditor/getListName']);
    const settingsFields: ManualListImportSettingsData = reactive(store.getters['listImportEditor/getSettingsData']);

    watch([listName], () => {
      store.commit('listImportEditor/setListName', listName.value);
    });

    watch(() => settingsFields.isDoubleOptIn, (newValue) => {
      if (settingsFields.isDoubleOptIn) {
        confirm.require({
          message: t('myLists.importContacts.files.certifyText'),
          header: 'Confirmation',
          group: 'settingsPanelManual',
          icon: 'far fa-exclamation-triangle',
          acceptLabel: t('myLists.importContacts.files.certifyOption'),
          rejectLabel: t('myLists.importContacts.files.cancel'),
          acceptClass: 'p-button-primary',
          rejectClass: 'p-button-secondary',
          reject: () => {
            settingsFields.isDoubleOptIn = false;
            refreshIsDoubleOpTin.value += 1;
          },
          onHide: () => {
            settingsFields.isDoubleOptIn = false;
            refreshIsDoubleOpTin.value += 1;
          },
        });
      }
    });

    return {
      listName,
      formValidationErrors,
      refreshIsDoubleOpTin,
      settingsFields,
      typeOptInOptions,
      yesNoOptions,
      listId,
      t,
    };
  },
});
