
import Galleria from 'primevue/galleria';
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';

export default defineComponent({
  name: 'PreviewModal',
  components: {
    Galleria,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    images: {
      type: Object,
      required: true,
    },
  },

  emits: ['on-close-preview-modal'],

  setup(props: {visible: boolean; images: object}, context: SetupContext) {
    const responsiveOptions = [
      {
        breakpoint: '1500px',
        numVisible: 5,
      },
      {
        breakpoint: '1024px',
        numVisible: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
      },
    ];

    const visibility = ref(props.visible);
    const listImages = ref(props.images);
    watch(props, () => {
      visibility.value = props.visible;
      listImages.value = props.images;
    });

    watch(visibility, () => {
      if (!visibility.value) {
        context.emit('on-close-preview-modal');
      }
    });

    return {
      visibility,
      listImages,
      responsiveOptions,
    };
  },
});
