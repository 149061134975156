import { ComponentNameEnum, LinkTypeEnum } from '@/types';
import { Variable } from '../../utils/variables-list';

const getLinkFieldConfig = (defaultValue = LinkTypeEnum.NONE, list: LinkTypeEnum[], variablesList: Variable[]) => ({
  componentName: ComponentNameEnum.LINK,
  options: {
    defaultValue,
    list,
    variablesList,
  },
});

export default getLinkFieldConfig;
