<template>
  <div
    class="input-variables-block"
    :class="{ 'with-emoji': displayEmojis, 'with-translate': displayTranslate, 'with-variables': displayVariables }"
  >
    <div ref="parentElementRef">
      <div class="flex flex-column gap-2">
        <label v-if="translation">
          {{ getTranslationLabel(t, translation) }}
        </label>
        <div class="p-fluid flex">
          <InputText
            :id="inputUniqueId"
            ref="inputTextRef"
            v-model="inputValue"
            class="input-text"
            @blur="handleBlur"
            @focus="handleFocus"
          />
          <AIButton
            v-if="selectedLanguage==defaultLanguage && displayAiButton"
            type="button"
            class="input-button-ai"
            icon="fa-light fa-microchip-ai"
            :ai-selector="'#' + inputUniqueId"
            :ai-params="aiParams"
            ai-content-type="2"
          />
          <Button
            v-if="((displayTranslate && selectedLanguage!=defaultLanguage) || displayEmojis || displayVariables)"
            ref="buttonDisplayInputDialogOptions"
            v-model="displayInputDialogOptions"
            type="button"
            class="input-dropdown-options-button"
            icon="fal fa-ellipsis-vertical"
            aria-haspopup="true"
            aria-controls="overlay_tmenu"
            @click="toggle"
          />
          <TieredMenu
            id="input_dropdown_menu"
            ref="tieredMenuRef"
            class="input-dropdown-menu"
            :model="dataMenu"
            popup
            @focus="() => tieredMenuRef.focusedItemInfo = -1"
          />
          <Emoji
            v-if="displayEmojis"
            :data-menu="dataMenu"
            :parent-ref="parentElementRef"
            @on-choose-emoji="insertContent"
            @add-data-menu="addDataMenu"
          />
          <Translate
            v-if="displayTranslate && selectedLanguage!=defaultLanguage"
            :all-multi-lang-input="allMultiLangInput"
            :selected-language="selectedLanguage"
            @on-translate="replaceContent"
            @add-data-menu="addDataMenu"
          />
          <Variables
            v-if="displayVariables"
            :variables-list="variablesList"
            :parent-ref="parentElementRef"
            @on-choose-variable="insertContent"
            @add-data-menu="addDataMenu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
  onUnmounted,
  onMounted,
} from 'vue';

import { useI18n } from 'vue-i18n';

import ToggleButton from 'primevue/togglebutton';
import InputText from 'primevue/inputtext';
import Emoji from '@/components/fields/partials/Emojis.vue';
import Translate from '@/components/fields/partials/Translate.vue';
import Variables from '@/components/fields/partials/Variables.vue';
import { MultiLangInput } from '@/types/store-parameters-types';
import {
  Translation,
} from '@/types';
import Button from 'primevue/button';
import TieredMenu from 'primevue/tieredmenu';
import AIButton from '@/components/fields/AIButton.vue';

// eslint-disable-next-line import/no-cycle
import {
  getTranslationLabel,
} from '@/components/template-builder/utils/helpers';

import { trimExcessSpaces } from '@/helpers';

import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseInputText',
  components: {
    InputText,
    Variables,
    Emoji,
    Translate,
    AIButton,
    TieredMenu,
    Button,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    displayEmojis: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayTranslate: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayAiButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    aiParams: {
      type: Object,
      required: false,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },

    displayVariables: {
      type: Boolean,
      required: false,
      default: false,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    allMultiLangInput: {
      type: Object as PropType<MultiLangInput>,
      required: false,
    },

    selectedLanguage: {
      type: String,
      required: false,
    },

    defaultLanguage: {
      type: String,
      required: false,
    },

    parentRendered: {
      type: Boolean,
      required: false,
      default: false,
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },
  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayInputDialogOptions = ref(false);
    const inputValue: Ref<string> = ref(props.modelValue);
    const cursorPosition = ref(0);
    const tieredMenuRef = ref();
    const parentElementRef = ref(null);
    const toggle = (event: Event) => tieredMenuRef.value.toggle(event);
    const timeoutId = ref();

    const insertContent = (value: string|number|undefined) => {
      inputValue.value = inputValue.value.substring(0, cursorPosition.value)
        + value
        + inputValue.value.substring(cursorPosition.value, inputValue.value.length);
      cursorPosition.value += value ? (value.toString().length) : 0;
    };

    const replaceContent = (value: string) => {
      if (value !== undefined) {
        inputValue.value = value;
      }
    };

    watch(() => props.modelValue, (newValue) => {
      if (newValue !== undefined) {
        inputValue.value = newValue;
      }
    });

    watch(inputValue, () => {
      // Implements a debounce to prevent some list to not be refreshed
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
      timeoutId.value = setTimeout(() => {
        emit('update:modelValue', inputValue.value);
      }, 500);
    });

    onUnmounted(() => {
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    });

    onMounted(() => {
      inputValue.value = trimExcessSpaces(inputValue.value);
    });

    const dataMenu: any[] = [
    ];

    const addDataMenu = (value: any) => {
      // Utilisation de l'opérateur push pour ajouter une nouvelle entrée à dataMenu
      dataMenu.push(value);
    };

    const createUniqueString = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36);

    const inputTextRef = ref();

    // Used when selecting widget from the editor
    const focus = () => {
      if (inputTextRef.value) {
        inputTextRef.value.$el.blur();
        inputTextRef.value.$el.focus();
      }
    };

    const handleFocus = () => {
      if (props.translation) {
        emit('activate-group', props.translation.groupKey);
      }
    };

    const handleBlur = (event: any) => {
      cursorPosition.value = event.target.selectionStart;
      if (props.translation) {
        emit('deactivate-group', props.translation.groupKey);
      }
    };

    return {
      t,
      inputValue,
      insertContent,
      replaceContent,
      displayInputDialogOptions,
      toggle,
      tieredMenuRef,
      dataMenu,
      addDataMenu,
      parentElementRef,
      inputTextRef,
      getTranslationLabel,
      handleFocus,
      handleBlur,
      inputUniqueId: `unique-input-${createUniqueString()}`,
      focus,
    };
  },
});
</script>

<style>
.input-dropdown-menu {
  width:15rem!important;
  font-size:0.90rem!important;
}
.input-dropdown-menu .p-menuitem-icon {
  width:20px;
}
</style>

<style scoped lang="scss">
:deep() .p-togglebutton.p-button.emoji-button {
  padding: 0.2rem 0.3rem;
  width: 2rem;
}

.with-emoji {
  & .input-text {
    border-right: none;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.with-variables {
  & .input-text {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none;
  }
}

:deep() .p-togglebutton.p-button.emoji-button {
  background: $white !important;
  margin: 0 !important;
  border: solid 1px $solitude !important;
  border-left: none !important;
  border-radius: 0 0.3rem 0.3rem 0;
  padding: 0 10px !important;
  width: auto;

  & .p-button-icon-left {
    margin-right: 0;
  }

  & .p-button-label {
    display: none;
  }

  & .p-button-icon {
    color: $heather !important;
  }

  &:hover {
    border-color: $solitude !important;
  }
}

.with-variables:deep() {
  & .p-togglebutton.p-button.emoji-button {
    border-bottom: none !important;
    border-bottom-right-radius: 0 !important;
  }

  & .variable-dropdown {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    & .p-inputtext {
      border-top-left-radius: 0 !important;
      color: $heather;
    }

    & .p-dropdown-trigger {
      border-top-right-radius: 0 !important;
    }
  }
}
.p-button.input-dropdown-options-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 2rem;
  font-size: 1.5rem;
  box-shadow: none;
  height: 2.5rem;
  border-radius:0;
  color: $heather;
  border-left: none;
  border-top: 1px solid $solitude;
  border-bottom: 1px solid $solitude;

  &:hover {
    background: none;
    border-top: 1px solid $solitude;
    border-bottom: 1px solid $solitude;
  }

  &:last-child {
    border-right: 1px solid $solitude;
  }

  :deep() .p-button-icon-only {
    color: $shuttle-grey;
  }
}
</style>
