
import {defineComponent, onMounted, PropType, SetupContext} from 'vue';
import DeclencheurCollectAnonymousVisitorMetaData, {
  DeclencheurCollectAnonymousVisitorData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurCollectAnonymousVisitorMetadata';

export default defineComponent({
  name: 'DeclencheurCollectAnonymousVisitor',
  components: {},

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurCollectAnonymousVisitorData>,
      required: true,
      default() {
        return DeclencheurCollectAnonymousVisitorMetaData.Create();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(_: any, context: SetupContext) {
    const update = () => {
      context.emit('update:modelValue', DeclencheurCollectAnonymousVisitorMetaData.Create());
    };

    onMounted(update);

    return {};
  },
});
