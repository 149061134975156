<template>
  <div>
    <div class="box-shadow-block">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex justify-content-center">
          <label class="block my-3">
            {{ t(configs.label) }}
          </label>
        </div>
        <div class="flex justify-content-center align-items-center">
          <ToggleDisplaySettings
            v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
            :configs="configs"
            :parser-values="parserValues"
            @display-settings="(value) => displayProperties = value"
          />
        </div>
      </div>
      <div
        class="align-items-center"
        :class="{ 'hidden': !displayProperties, 'flex': displayProperties }"
      >
        <BaseDropdown
          v-model="selectedBoxShadowStyle"
          :options="boxShadowList"
          class="style-dropdown"
          data-test-id="box-shadow-dropdown"
          @update:model-value="emitValuesChanges"
        />
        <div
          v-if="displayField"
          class="p-inputgroup"
        >
          <InputText
            v-model="selectedColor"
            class="p-inputtext-sm box-shadow-color-input"
            @change="filterInputColor"
            @keyup="filterInputColor"
          />
          <span
            class="p-inputgroup-addon button-block"
            @click="toggleColorPicker"
          >
            <Button
              class="p-inputgroup-addon p-button-sm box-shadow-color-button"
              :style="{ 'background-color': selectedColor }"
            />
            <em
              class="far fa-arrow-down p-inputgroup-addon text-center button-icon"
            />
          </span>
        </div>
        <OverlayPanel
          ref="overlayPanelRef"
        >
          <CustomColorPicker
            data-test-id="color-picker"
            :color="selectedColor"
            :color-format="colorFormat"
            title="Color Picker"
            @color-changed="changeColor"
          />
        </OverlayPanel>
        <Slider
          v-if="displayField"
          v-model="selectedBlur"
          :step="sliderStep"
          :min="minValue"
          :max="maxValue"
          class="slider"
          @change="emitValuesChanges"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, Ref, ref, watchEffect,
} from 'vue';
import { useI18n } from 'vue-i18n';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Slider from 'primevue/slider';
import {
  BoxShadowEnum, ColorFormatEnum, FieldConfig, ParserFieldObject, Property,
} from '@/types';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'BoxShadow',
  components: {
    ToggleDisplaySettings,
    BaseDropdown,
    Button,
    CustomColorPicker,
    InputText,
    OverlayPanel,
    Slider,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const boxShadowList = ref(Object.entries(BoxShadowEnum).map((boxShadow) => ({
      label: t(`templateBuilder.fields.shadows.${boxShadow[0].toLowerCase()}`), value: boxShadow[1],
    })));
    const overlayPanelRef = ref();
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const VALID_COLOR_REGEX_RULE = /^(#([\da-f]{3}){1,2}|(rgb|rgba)\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|rgba)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))$/i;
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const colorFormat: Ref<ColorFormatEnum> = ref(ColorFormatEnum.HEX);
    const selectedBoxShadowStyle = ref(props.configs.options.defaultBoxShadowStyle);
    const selectedColor: Ref<string> = ref(props.configs.options.defaultColor);
    const selectedBlur: Ref<number> = ref(props.configs.options.defaultBlur);
    const minValue = ref(0);
    const maxValue = ref(100);
    const sliderStep = ref(1);

    const displayField = computed(() => selectedBoxShadowStyle.value !== BoxShadowEnum.NONE);

    watchEffect(() => {
      if (property.value.value) {
        const matchedGroups = /^(rgb\(.+\)) (.+px .+px) (.+)px 0px$/g.exec(property.value.value);
        if (matchedGroups && matchedGroups[1] && matchedGroups[2] && matchedGroups[3]) {
          // eslint-disable-next-line
          selectedColor.value = matchedGroups[1];
          // eslint-disable-next-line
          selectedBoxShadowStyle.value = matchedGroups[2];
          // eslint-disable-next-line
          selectedBlur.value = Number(matchedGroups[3]);
        }
      }
    });

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };
    const emitValuesChanges = () => {
      property.value.value = `${selectedBoxShadowStyle.value} ${selectedBlur.value}px 0px ${selectedColor.value}`;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };
    const changeColor = (color: string) => {
      selectedColor.value = color;
      emitValuesChanges();
    };
    const isValidColor = (color: string) => VALID_COLOR_REGEX_RULE.test(color);
    const filterInputColor = () => {
      if (isValidColor(selectedColor.value)) {
        emitValuesChanges();
      }
    };

    return {
      displayProperties,
      boxShadowList,
      selectedBoxShadowStyle,
      selectedColor,
      overlayPanelRef,
      colorFormat,
      selectedBlur,
      toggleColorPicker,
      changeColor,
      filterInputColor,
      emitValuesChanges,
      minValue,
      maxValue,
      sliderStep,
      displayField,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.box-shadow-block {
  .box-shadow-color-input {
    border-color: $montana;
    font-size: 1rem;
    width: 4rem;
    &:hover {
      border-color: $brand-color-primary;
    }
    &:enabled:focus,
    &:enabled:active {
      border: 1px solid $brand-color-primary;
      box-shadow: none;
    }
  }

  .style-dropdown {
    width: 100%;

    :deep() .select {
      font-size: .9rem;
    }
  }

  .button-block {
    background-color: $white;
    border-color: $heather;
    padding: 0;
    margin-right: .5rem;
    &:last-child {
      border-color: $heather;
      border-top-right-radius: $field-border-radius;
      border-bottom-right-radius: $field-border-radius;
    }

    .box-shadow-color-button {
      margin: 0.25rem 0 0.25rem 0.25rem;
      min-width: 1rem;
      width: 1rem;
      height: 1rem;
      padding: 0;
      &:enabled:focus,
      &:enabled:active {
        border: 1px solid $brand-color-primary;
        box-shadow: none;
      }
    }

    .button-icon {
      background-color: $white;
      border: none;
      padding: .25rem;
      color: $heather;
      cursor: pointer;
    }

    &:hover {
      border-color: $brand-color-primary;
    }
  }

  .slider {
    min-width: 6rem;
    flex-grow: 1;
    margin-left: 2px;

    &.p-slider.p-slider-horizontal :deep() {
      .p-slider-handle {
        $dimension: .7rem;
        $margin: calc((#{$dimension} / 2) * -1);

        width: $dimension;
        height: $dimension;
        margin-left: $margin;
        margin-top: $margin;
        background-color: $brand-color-primary;
        border-color: $brand-color-primary;
      }

      .p-slider-range {
        background-color: $brand-color-primary;
      }
    }
  }
}
</style>
