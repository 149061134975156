
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import {
  ColorFieldConfig, ColorFormatEnum, ParserFieldObject, Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import { rgbToHex } from '@/helpers';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'Color',
  components: {
    ToggleDisplaySettings,
    CustomColorPicker,
    OverlayPanel,
    InputText,
    Button,
  },

  props: {
    configs: {
      type: Object as PropType<ColorFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const VALID_COLOR_REGEX_RULE = /^(#([\da-f]{3}){1,2}|(rgb|rgba)\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|rgba)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))$/i;
    const overlayPanelRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);
    const property: Ref<Property> = ref(props.configs.properties && props.configs.properties[0]);
    const selectedColor: Ref<string> = ref(props.configs.options.defaultValue);

    watch(() => props.parserValues, (values, oldValues) => {
      if (values.selector !== oldValues?.selector) {
        if (property.value) {
          selectedColor.value = property.value.value || props.configs.options.defaultValue;
        }
      }
    }, { immediate: true });

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };
    const emitValuesChanges = () => {
      if (property.value.value !== selectedColor.value) {
        property.value.value = selectedColor.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };
    const changeColor = (color: string) => {
      selectedColor.value = color;
      emitValuesChanges();
    };
    const isValidColor = (color: string) => VALID_COLOR_REGEX_RULE.test(color);
    const filterInputColor = () => {
      if (isValidColor(selectedColor.value)) {
        emitValuesChanges();
      }
    };

    return {
      displayProperties,
      selectedColor,
      overlayPanelRef,
      colorFormat,
      toggleColorPicker,
      changeColor,
      filterInputColor,
      configs,
      t,
    };
  },
});
