
import {
  computed, defineComponent,
  Ref,
  ref, SetupContext,
} from 'vue';
import { store } from '@/store';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import { StatsBuilderSelectedWidget } from '@/types/stats-editor-types';
import StatsBuilderSubCol from '@/components/stats-builder/StatsBuilderSubCol.vue';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';

export default defineComponent({
  name: 'StatsBuilderSubRow',

  components: {
    StatsBuilderSubCol,
    ConfirmDialog,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowParentId: {
      type: String,
      required: true,
    },

    columnParentId: {
      type: String,
      required: true,
    },

    panelId: {
      type: String,
      required: true,
    },

    rowData: {
      type: Object,
      required: false,
    },
  },

  setup(props: { rowParentId: string; columnParentId: string; panelId: string; rowData: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const confirm = useConfirm();

    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);

    const rowHasColumnWithMultipleWidgets = computed(() => {
      if (props.rowData.children) {
        return props.rowData.children.some((column: any) => {
          if (column.widgets && column.widgets.length > 1) {
            return true;
          }
          return false;
        });
      }
      return false;
    });

    const timeoutId = ref<any>(null);

    const hoverRow = ref(false);
    const hoverCol = ref(false);
    const mouseoverRow = (e: any) => {
      hoverRow.value = true;
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    };
    const mouseleaveRow = (e: any) => {
      timeoutId.value = setTimeout(() => {
        hoverRow.value = false;
      }, 200);
    };

    const deleteRow = () => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.removeRow'),
        header: 'Confirmation',
        group: `removeRow_${props.panelId}_${props.rowData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/removeRow', {
            location: {
              rowId: props.rowParentId,
              columnId: props.columnParentId,
              panelData: {
                panelId: props.panelId,
                subRowId: props.rowData.id,
              },
            },
          });
        },
      });
    };

    const duplicateRow = () => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.duplicateRow'),
        header: 'Confirmation',
        group: `removeRow_${props.panelId}_${props.rowData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/duplicateRow', {
            location: {
              rowId: props.rowParentId,
              columnId: props.columnParentId,
              panelData: {
                panelId: props.panelId,
                subRowId: props.rowData.id,
              },
            },
          });
        },
      });
    };

    return {
      t,
      selectedWidgetState,
      rowHasColumnWithMultipleWidgets,
      hoverRow,
      hoverCol,

      mouseoverRow,
      mouseleaveRow,
      deleteRow,
      duplicateRow,
    };
  },
});
