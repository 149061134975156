<template>
  <div v-if="!selectedSegment">
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t(segmentLabel) }}
      </label>
      <Dropdown
        v-model="selectedValue"
        :options="options"
        class="unit-input select-segment"
        option-label="label"
        :placeholder="t(placeholderSelectSegments)"
        @update:model-value="loadSegment"
      />
    </div>
    <div
      v-if="!selectedSegment && hasActiveSegment()"
      class="field-wrapper"
    >
      <label class="block mb-2">
        {{ t('automatedScenarios.fields.activeSegments') }}
      </label>
      <div
        v-for="(current) in activeSegments"
        :key="current"
      >
        <div class="flex">
          <div class="col-9 p-0 m-auto align-items-center" style="font-size: 14px;">
            {{ t(current.label) }}
          </div>
          <div class="col-3 p-0 m-auto flex justify-items-end">
            <Button
              v-if="current.show"
              v-tooltip="{ value: showTooltip(current.name, current.number), class: 'segment-summary' }"
              icon="far fa-eye"
              class="p-segment-icon p-button-rounded p-button-text p-button-info"
            />
            <Button
              v-if="current.show"
              icon="far fa-pencil"
              class="p-segment-icon p-button-rounded p-button-text p-button-success"
              @click="() => editSegment(current.name, current.number)"
            />
            <Button
              icon="far fa-times"
              class="p-segment-icon p-button-rounded p-button-text p-button-danger"
              @click="() => handleRemoveSegment(current.name, current.number)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      v-for="(field, index) in editableFields"
      :key="index"
      class="field-wrapper"
    >
      <component
        :is="field.component"
        v-if="!('show' in field) || field.show(selectedSegment.fields)"
        v-model="field.data"
        v-bind="field.props"
        :segment-id="selectedSegment.id"
        :new-list-id="newListId"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidation"
        :field="field.id"
        silent="false"
      />
    </div>
    <div class="flex">
      <div class="flex m-auto">
        <span class="p-buttonset">
          <SpmButton
            :label="t('automatedScenarios.cancel')"
            icon="fa-regular fa-xmark"
            class-style="p-button p-button-secondary"
            @click="resetAll"
          />
          <SpmButton
            :label="t('automatedScenarios.save')"
            icon="fa-regular  fa-floppy-disk"
            class-style="p-button p-button-primary ml-2"
            @click="handleSaveSegment"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, Ref, ref, SetupContext, toRef, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import tooltip from '@/components/automated-scenarios/forms/filtres/segments/tooltips';
import ItemList from '@/components/automated-scenarios/fields/ItemList.vue';
import NumberRange from '@/components/automated-scenarios/fields/NumberRange.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import InputList from '@/components/automated-scenarios/fields/InputList.vue';
import MultiDropdown from '@/components/automated-scenarios/fields/MultiDropdown.vue';
import EditAutocomplete from '@/components/automated-scenarios/fields/EditAutocomplete.vue';
import InputNumber from '@/components/automated-scenarios/fields/InputNumber.vue';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import Input from '@/components/automated-scenarios/fields/Input.vue';
import Location from '@/components/automated-scenarios/fields/Location.vue';
import { FilterField, FilterSegment } from '@/types/automated-scenarios';
import { ActiveSegment } from '@/composables/AutomatedScenarioSegments';
import { AutomatedScenarioState, setConfiguringSegment } from '@/composables/AutomatedScenarios';
import { getAvailableSegments } from '@/composables/automated-scenarios/OperatorsCompatibilitesAndDependencies';
import { asInt, showToastError } from '@/helpers';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { List } from '@/composables/GraphQL';
import { UserState } from '@/composables/User';
import { myListsState } from '@/composables/mes-listes/ListsManage';
import { OperatorType, ShopsLists } from '@/types/generated-types/graphql';

export default defineComponent({
  name: 'FilterSegmentForm',

  components: {
    SpmButton,
    FieldErrors,
    BaseDropdown,
    Button,
    ItemList,
    NumberRange,
    ButtonGroup,
    InputNumber,
    Input,
    Location,
    PeriodSelector,
    InputList,
    Dropdown,
    MultiDropdown,
    EditAutocomplete,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    // eslint-disable-next-line vue/no-unused-properties
    segments: {
      type: Array as PropType<FilterSegment<unknown>[]>,
      required: true,
    },

    activeSegments: {
      type: Array as PropType<ActiveSegment[]>,
      required: true,
    },

    items: {
      type: Object,
      required: true,
    },

    segmentLabel: {
      type: String,
      required: false,
      default: 'automatedScenarios.fields.addSegment',
    },

    placeholderSelectSegments: {
      type: String,
      required: false,
      default: 'automatedScenarios.fields.segments.chooseSegment',
    },
  },

  emits: ['add:segment', 'remove:segment'],

  setup(props: {
    segments: FilterSegment<unknown>[];
    activeSegments: ActiveSegment[];
    items: any;
  }, { emit }: SetupContext) {
    const { t } = useI18n();
    const formValidation = ref<any>({});
    const componentFieldErrorsKey = ref(0);

    const segments = toRef(props, 'segments');
    const selectedValue = ref('');
    const selectedSegment: Ref<FilterSegment<unknown>|undefined> = ref();
    const selectedSegmentNumber: Ref<number|null> = ref(null);
    const editableFields = computed(() => selectedSegment.value?.fields.filter((field: FilterField) => !field.hidden));

    const newListId = ref();

    const options = computed(() => getAvailableSegments<unknown>(
      AutomatedScenarioState.scenario.type,
      segments.value,
      props.activeSegments.map((activeSegment) => activeSegment.name),
    )
      .filter((segment: FilterSegment<unknown>) => ((segment.multiple
        || !props.activeSegments.reduce((acc: string[], current: ActiveSegment) => ([...acc, current.name]), []).includes(segment.id))))
      .map((segment: FilterSegment<unknown>) => segment.option));

    const resetAll = (resetConfiguringSegment = true) => {
      if (resetConfiguringSegment) {
        setConfiguringSegment(false);
      }
      selectedSegment.value = undefined;
      selectedValue.value = '';
      selectedSegmentNumber.value = null;
    };

    const hasActiveSegment = () => props.activeSegments.length > 0;

    const handleRemoveSegment = (segmentName: string, number: number) => {
      // eslint-disable-next-line vue/custom-event-name-casing
      emit('remove:segment', { segmentName, number });
    };

    const handleSaveSegment = async () => {
      formValidation.value = {};
      componentFieldErrorsKey.value += 1;
      if (selectedSegment?.value?.validate) {
        // transformer un tableau d'objets en un objet {idField1: data1, idFIeld2: data2...} pour faire la validation
        const data = selectedSegment.value?.fields.reduce((acc: {[key: number]: string}, cur: any) => {
          acc[cur.id] = cur.data;
          return acc;
        }, {});
        const validation = await selectedSegment.value.validate(data);
        if (!validation.success) {
          formValidation.value = validation.validate;
          componentFieldErrorsKey.value += 1;
          await showToastError(t('errorMessages.FORM_ERROR'));
          return;
        }
      }

      setConfiguringSegment(false);
      // If it is an update, remove segment first
      if (selectedSegmentNumber.value !== null && selectedSegment.value) {
        handleRemoveSegment(selectedSegment.value.id, selectedSegmentNumber.value);
      }
      // Then recreate
      if (selectedSegment.value) {
        // eslint-disable-next-line vue/custom-event-name-casing
        emit('add:segment', selectedSegment.value);
      }
      resetAll();
    };

    const loadSegment = (segment: string|any) => {
      formValidation.value = {};
      componentFieldErrorsKey.value += 1;

      selectedValue.value = typeof (segment) === 'string' ? segment : segment.value;

      // eslint-disable-next-line prefer-destructuring
      selectedSegment.value = segments.value.filter((current: FilterSegment<unknown>) => current.option.value === selectedValue.value)[0];

      if (editableFields.value?.length === 0) {
        handleSaveSegment();
      } else {
        setConfiguringSegment(true);
      }
    };

    const editSegment = (segmentName: string, number?: number) => {
      selectedSegmentNumber.value = number !== undefined ? number : null;
      loadSegment(segmentName);

      // populate fields with corresponding values
      // eslint-disable-next-line no-unused-expressions
      selectedSegment.value?.fields.forEach((field: FilterField) => {
        // eslint-disable-next-line no-param-reassign
        field.data = props.items[segmentName][field.id];

        if (number !== undefined) {
          // eslint-disable-next-line no-param-reassign
          field.data = props.items[segmentName][field.id][number];
        }

        if (field.format) {
          // eslint-disable-next-line no-param-reassign
          field.data = field.format(field.data);
        }
      });
    };

    const showTooltip = (segmentName: string, number: number) => {
      const segment = segments.value.filter((current: FilterSegment<unknown>) => current.option.value === segmentName)[0];
      return tooltip(segment, props.items[segmentName], number);
    };

    watch(() => AutomatedScenarioState.configuringSegment, () => {
      // if we set configuringSegment to false from outside the component, we reset everything
      if (!AutomatedScenarioState.configuringSegment) {
        resetAll(false);
      }
    });

    const fetchLists = async () => {
      const { items, err } = await List<ShopsLists>({
        name: 'ShopsLists',
        settings: {
          filter: [
            {
              field: 'id_shop',
              operator: OperatorType.Equals,
              value: UserState.activeShop ? UserState.activeShop.id : 0,
            },
          ],
          order: [
            { field: 'id_shop_list', type: 'ASC' },
          ],
          limit: 0,
          offset: 0,
        },
        fields: ['id_shop_list', 'name'],
      });

      if (err === '') {
        return items.map((item) => ({
          name: item.name,
          code: item.id_shop_list,
        }));
      }

      return [];
    };

    watch(() => myListsState.refreshComponent, async () => {
      if (myListsState.refreshComponent !== 0 && selectedSegment.value && selectedSegment.value.id === 'filtermemberoflist') {
        newListId.value = myListsState.refreshComponent;
        const newLists = await fetchLists();

        const matchingCurrentLists = newLists.find((item: any) => item.code === asInt(newListId.value));
        if (matchingCurrentLists) {
          selectedSegment.value.fields[0].props.options = newLists.map((list) => ({ value: list.code, label: list.name }));
        }

        myListsState.refreshComponent = 0;
      }
    });

    return {
      t,
      formValidation,
      componentFieldErrorsKey,
      selectedValue,
      selectedSegment,
      options,
      editableFields,
      newListId,

      showTooltip,
      loadSegment,
      editSegment,
      handleSaveSegment,
      handleRemoveSegment,
      hasActiveSegment,
      resetAll,
    };
  },

});
</script>

<style lang="scss" scoped>
.select-segment {
  width: 100%;
}
.field-group-content {
  .configuration-actions-segment-container {
    margin-top: 2rem !important;
  }
}

.p-segment-icon {
  width: 33% !important;
  height: 1.7rem;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
  }
}
</style>

<style>
.segment-summary .p-tooltip-text {
    min-width: 20rem;
}
</style>
