
import {
  computed,
  defineComponent, onBeforeMount, reactive, Ref,
  ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { StatsPageFiltersMetaData } from '@/components/stats-builder/metadata/filters';
import { store } from '@/store';
import MultiSelect from 'primevue/multiselect';
import CustomDatePicker from '@/components/fields/CustomDatePicker.vue';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';
import { IntervalDateEnum } from '@/types';
import deepcopy from 'deepcopy';
import moment from 'moment';

export default defineComponent({
  name: 'StatsBuilderFilters',

  components: {
    InputSwitch,
    CustomDatePicker,
    MultiSelect,
    InlineMessage,
    Dropdown,
    ConfirmDialog,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    defaultFilters: {
      type: Object,
      required: false,
    },

    hideVisibleIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['filters-changed'],

  setup(props: {defaultFilters: any}, { emit }) {
    const { t } = useI18n();
    const confirm = useConfirm();

    /* contains asynchronous options of some filters which need to be loaded from the API */
    const optionsOfFilters: Ref<any[]> = ref([]);

    const defaultFiltersData: any = reactive(props.defaultFilters ?? store.getters['statsEditor/getPageFilters']);
    let appliedFilters = deepcopy(defaultFiltersData); // Keep a copy (without reference) of currently applied filters
    let resetFilters = false; // Boolean to prevent triggering the "watch" on defaultFiltersData if we need to reset some filters
    const displayOnPageView = ref(!!props.defaultFilters);

    const selectedNewFilter = ref();
    const listOfAvailableFilters = computed(() => Object.keys(StatsPageFiltersMetaData)
      .filter((item: any) => !(item === 'date_interval'))
      .map((item: any) => ({
        name: t(StatsPageFiltersMetaData[item].label),
        code: item,
      })));

    const getListOfNotAvailableFilters = (option: any) => Object.keys(defaultFiltersData).includes(option.code);

    const removeFilter = (e: any, filterId: string) => {
      e.stopPropagation();
      confirm.require({
        group: 'deleteFilter',
        message: t('statsEditor.sidebarPanel.filters.removeFilterConfirmation'),
        header: 'Confirmation',
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: async () => {
          delete defaultFiltersData[filterId];
          if (filterId === 'id_campaign_bulk') {
            updateDateMessage.value = '';
          }
        },
      });
    };

    const loadFiltersOptions = async (triggerEmit = true) => {
      // Reset available filters options
      optionsOfFilters.value = [];

      /* load options of filters which need to be loaded from the API */
      const optionPromises = Object.keys(StatsPageFiltersMetaData)
        .filter((itemKey: any) => StatsPageFiltersMetaData[itemKey].getOptions)
        .map(async (itemKey: any) => ({
          id: itemKey,
          options: await StatsPageFiltersMetaData[itemKey].getOptions(defaultFiltersData),
        }));

      optionsOfFilters.value = await Promise.all(optionPromises);

      // Emit filters-changed to reload stats widgets after options are loaded
      if (triggerEmit) {
        emit('filters-changed');
      }
    };

    /* Watch selectedNewFilter to add new filter */
    watch(() => selectedNewFilter.value, () => {
      if (selectedNewFilter.value !== null) {
        defaultFiltersData[selectedNewFilter.value.code] = StatsPageFiltersMetaData[selectedNewFilter.value.code].defaultData;
        selectedNewFilter.value = null;
      }
    });

    watch(() => defaultFiltersData.date_interval.value.interval, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        // Clear values of other filters
        resetFilters = true;

        await loadFiltersOptions();
        appliedFilters = deepcopy(defaultFiltersData);
        resetFilters = false;
      }
    });

    watch(defaultFiltersData, (newValue) => {
      if (!resetFilters && newValue.date_interval.value.interval === appliedFilters.date_interval.value.interval) {
        // Trigger emit only if the date interval has not been changed and if we are not resetting some filters (in previous watch).
        // If it has been changed, the "emit" will be done by the loadFiltersOptions function.
        emit('filters-changed');
      }

      appliedFilters = deepcopy(defaultFiltersData);
    }, { deep: true });

    const updateDateInterval = ref(false);
    const updateDateMessage = ref('');

    const updateFilterDate = () => {
      let campaignValue = defaultFiltersData.id_campaign_bulk.value;
      if (Array.isArray(defaultFiltersData.id_campaign_bulk.value) && defaultFiltersData.id_campaign_bulk.value.length) {
        campaignValue = defaultFiltersData.id_campaign_bulk.value[0];
      }
      const campaign = optionsOfFilters.value.find((option) => option.id === 'id_campaign_bulk');
      if (campaign) {
        const selectedCampaign = campaign.options.find((item: any) => item.id === campaignValue);
        if (selectedCampaign) {
          defaultFiltersData.date_interval.value.interval = IntervalDateEnum.CUSTOM_DATE_RANGE;
          const startDate = moment(selectedCampaign.dateSend, 'YYYYMMDDHHmmss');
          defaultFiltersData.date_interval.value.customDateRange = [startDate.toDate(), startDate.clone().add(30, 'days').toDate()];
          updateDateInterval.value = true;
          updateDateMessage.value = t('statsEditor.sidebarPanel.filters.dateFilterAdjustmentMessage');
        }
      }
    }

    const handleFilterChange = (itemId: string) => {
      if (itemId === 'id_campaign_bulk') {
        if (defaultFiltersData.id_campaign_bulk && defaultFiltersData.id_campaign_bulk.visibleOnView && defaultFiltersData.id_campaign_bulk.value) {
          updateFilterDate();
        } else {
          defaultFiltersData.date_interval.value.interval = IntervalDateEnum.LAST_30_DAYS;
          delete defaultFiltersData.date_interval.value.customDateRange;
          defaultFiltersData.id_campaign_bulk.value = '';
          updateDateInterval.value = true;
          updateDateMessage.value = '';
        }
      }
    };

    onBeforeMount(async () => {
      await loadFiltersOptions(false);
      if (defaultFiltersData.id_campaign_bulk
        && defaultFiltersData.id_campaign_bulk.visibleOnView
        && defaultFiltersData.id_campaign_bulk.value
        && defaultFiltersData.date_interval.value.interval !== IntervalDateEnum.CUSTOM_DATE_RANGE
      ) {
        updateFilterDate();
      }
    });

    return {
      t,
      StatsPageFiltersMetaData,
      optionsOfFilters,
      displayOnPageView,
      defaultFiltersData,
      selectedNewFilter,
      listOfAvailableFilters,
      updateDateInterval,
      updateDateMessage,
      getListOfNotAvailableFilters,
      removeFilter,
      handleFilterChange,
    };
  },
});
