import {
  CustomSingleMutation, Delete, Insert, List, Request, RequestResult,
} from '@/composables/GraphQL';
import {
  OperatorType,
  ShopsSendDomains,
  ShopsSendDomainsInputItem, ShopsSendDomainsTokenInput, ShopsSendDomainsUpdateInput,
  ShopsSendDomainsUpdateInputItem,
} from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import { showToastError } from '@/helpers';
import { nestPost } from '@/composables/nestApi';

export interface DefaultDomainInput {
  idShop: number;
  idDomain: number;
  alignmentDomain?: string;
  trackingDomain?: string;
}

export default async function getShopsDomains(limit = 10, filters: any = [], additionalFields: any = []): Promise<ShopsSendDomains[]> {
  const defaultFilters = [
    {
      field: 'id_shop',
      operator: OperatorType.Equals,
      value: UserState.activeShop?.id ?? 0,
    },
  ];

  const defaultFields = [
    'id_domain',
    'domain',
  ];

  const mergedFilters = filters.length > 0 ? [...defaultFilters, ...filters] : defaultFilters;
  const fields = [...defaultFields, ...additionalFields];

  const domains = await List<ShopsSendDomains>({
    name: 'ShopsSendDomains',
    settings: {
      filter: mergedFilters,
      order: [
        { field: 'id_domain', type: 'ASC' },
      ],
      offset: 0,
      limit,
    },
    fields,
  });

  if (domains.err !== '') {
    await showToastError(domains.err);
    return [];
  }

  return domains.items;
}

export const CheckDomainValidForAlignment = async (idShop: number, idDomain: number, domain: string): Promise<RequestResult<boolean>> => {
  const query = 'query( $idShop: Int, $idDomain: Int, $domain: String)'
    + '{ CheckDomainValidForAlignment(id_shop: $idShop, id_domain: $idDomain, domain: $domain) }';

  const variables = {
    idShop,
    idDomain,
    domain,
  };

  return Request<boolean>({
    name: 'CheckDomainValidForAlignment',
    query,
    variables,
  });
};

export const CheckDomainValidForTracking = async (idShop: number, idDomain: number, domain: string): Promise<RequestResult<boolean>> => {
  const query = 'query( $idShop: Int, $idDomain: Int, $domain: String)'
    + '{ CheckDomainValidForTracking(id_shop: $idShop, id_domain: $idDomain, domain: $domain) }';

  const variables = {
    idShop,
    idDomain,
    domain,
  };

  return Request<boolean>({
    name: 'CheckDomainValidForTracking',
    query,
    variables,
  });
};

export const MakeDomainDefault = async (shopsSendDomainsInput: ShopsSendDomainsUpdateInputItem[]) => CustomSingleMutation({
  name: 'MakeDomainDefault',
  input: shopsSendDomainsInput,
  type: 'ShopsSendDomainsUpdateInput',
});

export const getShopsDomainsCount = async (idShop: number): Promise<number> => {
  const domains = await List<ShopsSendDomains>({
    name: 'ShopsSendDomains',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: idShop,
        },
      ],
      order: [
        { field: 'id_domain', type: 'ASC' },
      ],
      offset: 0,
      limit: 0,
    },
    fields: [
      'id_domain',
    ],
  });

  if (domains.err !== '') {
    await showToastError(domains.err);
    return 0;
  }

  return domains.total;
};

export const InsertShopsSendDomains = async (shopsSendDomains: ShopsSendDomainsInputItem[]) => Insert<ShopsSendDomainsInputItem>({
  name: 'ShopsSendDomains',
  input: shopsSendDomains,
  type: 'ShopsSendDomainsInput',
});

export const DeleteShopsSendDomains = async (shopsSendDomains: ShopsSendDomainsUpdateInputItem[]) => Delete<ShopsSendDomainsUpdateInputItem>({
  name: 'ShopsSendDomains',
  input: shopsSendDomains,
  type: 'ShopsSendDomainsUpdateInput',
});

export const VerifyDomain = async (idDomain: number): Promise<RequestResult<ShopsSendDomains>> => {
  const query = 'query( $idDomain: Int)'
    + '{ GetDomainsOfCurrentShop(id_domain: $idDomain){id_domain}}';

  const variables = {
    idDomain,
  };

  return Request<ShopsSendDomains>({
    name: 'GetDomainsOfCurrentShop',
    query,
    variables,
  });
};

export const ValidateDomainByEmail = async (idShop: number, idDomain: number, mailWithoutDomain: string) => nestPost('v4', '/send-domain/email-confirmation', {}, {
  idShop,
  idDomain,
  mailWithoutDomain,
});

export const ValidateShopDomainByToken = async (token: string, idShop: number) => CustomSingleMutation({
  name: 'ValidateShopDomainByToken',
  input: { token, id_shop: idShop },
  type: 'ShopsSendDomainsTokenInput',
});
