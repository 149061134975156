<template>
  <div class="page-title-box" />
  <div class="grid">
    <div class="col-6 col-offset-3">
      <Card>
        <template #content>
          <div
            class="ex-page-content"
            style="text-align: center"
          >
            <h1 class="">
              404!
            </h1>
            <h2>{{ t('notFound') }}</h2>
            <br><br>
            <div class="pb-5">
              <router-link to="/dashboard">
                <div class="">
                  <Button class="p-button p-component p-button-raised p-button-secondary p-button-text">
                    {{ t('goToHome') }}
                  </Button>
                </div>
              </router-link>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import Card from 'primevue/card';
import Button from 'primevue/button';
import {defineComponent} from "vue";

export default defineComponent({
  name: 'NotFoundComponent404',
  components: {
    Card,
    Button,
  },

  setup() {
    const { t } = useI18n();
    return { t };
  },
});
</script>

<style scoped>
.page-title-box {
  padding: 22px 0;
  min-height: 70px;
}

.ex-page-content h1 {
  font-size: 98px;
  font-weight: 700;
  line-height: 150px;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>
