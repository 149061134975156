import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ComponentNameEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
// eslint-disable-next-line import/no-cycle
import {
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setMediaDisplay,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getClassByName,
  getCssPropertyByName,
} from '@/components/template-builder/getters';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';

// eslint-disable-next-line import/no-cycle
import getContent from '@/components/template-builder/getters/code';

// eslint-disable-next-line import/no-cycle
import setContent from '@/components/template-builder/setters/code';

import getColorFieldConfig from '../fields/color-config';
import bordersFieldConfig from '../fields/borders-config';
import getCodeFieldConfig from '../fields/code-config';

const widgetCodeConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.code',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.htmlCode',
            selector: '{SELECTOR}.spm_widget_code > tbody > tr > th, {SELECTOR}.spm_widget_display_code',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getCodeFieldConfig('', false),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR}',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetCodeConfig;
