
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  onMounted,
} from 'vue';

import InputNumber from 'primevue/inputnumber';

import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Loader from '@/components/layout/Loader.vue';

import useVuelidate from '@vuelidate/core';
import { voucherInputValidator } from '@/helpers/CustomValidator';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { showToastError, showToastSuccess } from '@/helpers';
import { ShopsConfigurationInput, ShopsConfigurationInputItem } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'VoucherServiceParameter',

  components: {
    InputNumber,
    FieldErrors,
    SpmButton,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const isLoading = ref(false);
    const saveLoading = ref(false);

    const field = reactive<Record<string, any>>({
      shopRequiredDataCouponing_max_percentage_of_reduction: 0,
      shopRequiredDataCouponing_min_purchase_amount_for_discount: 0,
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules: Record<string, any> = {
        shopRequiredDataCouponing_max_percentage_of_reduction: {
          voucherInputValidator: voucherInputValidator(),
        },

        shopRequiredDataCouponing_min_purchase_amount_for_discount: {
          voucherInputValidator: voucherInputValidator(),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      const shopsConfigArray: ShopsConfigurationInputItem[] = [];
      let key = '';
      let value = '';
      Object.entries(field).forEach((keyValuePair: any) => {
        [key, value] = [...keyValuePair];
        shopsConfigArray.push({
          key,
          value,
          lang: '',
        });
      });
      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      isLoading,
      saveLoading,
      field,
      componentFieldErrorsKey,
      error,
      handleSave,
    };
  },
});
