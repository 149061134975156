<template>
  <div class="select-button-container">
    <ToggleButton
      v-model="checked"
      on-icon="far fa-check"
      off-icon="far fa-times"
      @change="handleChange"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import ToggleButton from 'primevue/togglebutton';
import { AutomatedScenarioState as state, linkOperator, unlinkOperator } from '@/composables/AutomatedScenarios';

export default defineComponent({
  name: 'OperatorSelectForm',

  components: {
    ToggleButton,
  },

  props: {
    operatorId: {
      type: String,
      required: true,
    },
  },

  setup(props: {operatorId: string}) {
    const checked = ref(state.linkedOperators.includes(props.operatorId));

    const handleChange = () => {
      if (checked.value) {
        linkOperator(props.operatorId);
      } else {
        unlinkOperator(props.operatorId);
      }
    };

    watch(state.linkedOperators, () => {
      checked.value = state.linkedOperators.includes(props.operatorId);
    });

    return {
      checked,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.select-button-container {
  display: flex;
  width: 100%;
  height: 100%;
  > :deep() .p-togglebutton {
    width: 50px;
    height: 100%;
    z-index: 2;
    box-shadow: none;
    outline: none;
    text-align: center;
    justify-content: center;

    & .p-button-label {
      display: none;
    }

    & .p-button-icon-left {
      margin-right: 0;
    }

    &:enabled:focus {
      box-shadow: none;
    }
    &:hover,
    &.p-highlight {
      background-color: #009CFF !important;
      border-color: #009CFF !important;
      span {
        color: #fff !important;
      }
    }
  }
}
</style>
