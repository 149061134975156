import { useQuery, UseQueryResponse } from '@urql/vue';

import { Query } from '@/types/generated-types/graphql';
import { SpmTableFilter, SpmTableSort } from '@/types/table-types';
import { Ref } from 'vue';

interface ListSettings {
  limit: Ref<number>;
  offset: Ref<number>;
  order: Ref<SpmTableSort[]>;
  filter: Ref<SpmTableFilter[]>;
}

interface ListParameters {
  name: string;
  settings: ListSettings;
  fields: Array<string>;
}

export default function useListQuery<T = Query>(
  {
    name,
    settings,
    fields,
  }: ListParameters,
): UseQueryResponse<T, ListSettings> {
  return useQuery({
    query: `
      query ($limit: Int!, $offset: Int!, $order: [OrderParameters], $filter: [FilterParameters]) {
        ${name} (params: {limit: $limit, offset: $offset, order: $order, filter: $filter}) {
          total
          items {
            ${fields.reduce((acc: string, current: string) => `${acc} ${current}`)}
          }
        }
      }
    `,
    variables: {
      ...settings,
    },
  });
}
