import moment from 'moment-timezone';

const langFormatMapping: any = {
  en: 'MMM D YYYY h:mm A',
  fr: 'D MMM YYYY HH:mm',
  de: 'D. MMM. YYYY HH:mm',
  default: 'MMM D YYYY h:mm A',
};

export const formatDateToLocale = (locale: any, date: string) => {
  if (!date || date === '') {
    return null;
  }

  const dateSplit: string[] = date.split('.');
  const datetime: string[] = dateSplit[0].split(' ');
  const dateParts: string[] = datetime[0].split('-');
  const timeParts: string[] = datetime[1].split(':');

  const dateFormatted = new Date(
    parseInt(dateParts[0], 10),
    parseInt(dateParts[1], 10) - 1,
    parseInt(dateParts[2], 10),
    parseInt(timeParts[0], 10),
    parseInt(timeParts[1], 10),
    parseInt(timeParts[2], 10),
  );

  return `${dateFormatted.toLocaleString(locale)}`;
};

export const formatMysqlDateToLocale = (locale: any, date: string) => {
  if (!date || date === '') {
    return null;
  }

  const jsDate = new Date(Date.parse(date.replace(/-/g, '/')));
  return `${jsDate.toLocaleString(locale)}`;
};

export const getNowFormattedByTimezone = () => {
  const userLang = navigator.language;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateFormat = langFormatMapping[userLang] || langFormatMapping.default;
  const now = moment().toDate();
  return moment.tz(now, userTimezone).format(dateFormat);
};

export const formatDate = (date: string, startFormat: string, endFormat: string, useUserTimezone = true) => {
  const dateMoment = moment(date, startFormat);
  let returnValue = null;

  if (useUserTimezone) {
    const userLang = navigator.language;
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    returnValue = moment.tz(dateMoment.toDate(), userTimezone).format(endFormat);
  } else {
    returnValue = dateMoment.format(endFormat);
  }

  return returnValue;
};
