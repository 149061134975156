import { BaseFieldConfig, ComponentNameEnum } from '@/types';

const imageFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.IMAGE,
  options: {
    defaultImageUrl: 'http://media.shopimind.io/img/v4/placeholders/image_placeholder.png',
  },
};

export default imageFieldConfig;
