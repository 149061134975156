
import {
  computed, defineComponent,
  Ref,
  ref, SetupContext,
} from 'vue';
import { StatsBuilderColumnLayoutEnum, StatsEditorPanelEnum, StatsWidgets } from '@/types';
import StatsWidgetMetadata, { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';
import { StatsBuilderSelectedWidget } from '@/types/stats-editor-types';
import { useStore } from '@/store';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import draggable from 'vuedraggable';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import StatsBuilderColItem from '@/components/stats-builder/StatsBuilderColItem.vue';
import { removePlaceholderClass} from '@/helpers';

export default defineComponent({
  name: 'StatsBuilderCol',

  components: {
    draggable,
    ConfirmDialog,
    StatsBuilderColItem,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowId: {
      type: String,
      required: true,
    },

    columnData: {
      type: Object,
      required: true,
    },

    rowHasColumnWithMultipleWidgets: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['hover:col'],

  setup(props: { rowId: string; columnData: any}, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const confirm = useConfirm();

    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);

    const pageTypeState = computed(() => store.getters['statsEditor/getPageType']);

    const availableElements: any = computed(() => Object.values(StatsWidgetMetadata)
      .filter((item: any) => item.StatsWidgetMeta.availability.includes(pageTypeState.value))
      .map((item: any) => ({
        type: 'placeholder',
        widgetType: item.StatsWidgetMeta.component,
        iconClass: item.StatsWidgetMeta.icon,
        label: item.StatsWidgetMeta.label,
        group: 'widget',
        minColumnsRequired: item.StatsWidgetMeta.min_columns_required,
      })));

    const dragOnEmptyColumnOptions = ref({
      animation: 200,
      sort: true,
      group: { name: 'empty_column', pull: false, put: ['widget_element', 'statsWidget'] },
      disabled: false,
      onAdd: (evt: any) => {
        evt.preventDefault();
        if (evt.from.getAttribute('data-from-widget')) {
          const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
          const dataWidgetLocation = JSON.parse(evt.clone.getAttribute('data-widget-location'));
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          const dataColumnLayout = JSON.parse(evt.to.getAttribute('data-column-layout'));

          const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);
          if (parseInt(widgetType.minColumnsRequired, 10) > dataColumnLayout) {
            store.dispatch('statsEditor/undoMoveWidget', {
              dataWidget,
              dataWidgetLocation,
            });
          } else {
            store.dispatch('statsEditor/addWidgetToEmptyCol', {
              dataWidget,
              dataLocation,
            });
          }
        } else if (evt.from.getAttribute('data-from-subwidget')) {
          const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          const dataLastLocation = JSON.parse(evt.clone.getAttribute('data-location'));

          store.dispatch('statsEditor/addWidgetToEmptyCol', {
            dataWidget,
            dataLocation,
            dataLastLocation,
          });
        }
      },
    });

    const editWidget = (index: number) => {
      const meta = getComponentStatsWidgetMetadata(props.columnData?.widgets[index]?.type);
      if (!meta) {
        return;
      }

      const selectedWidget: StatsBuilderSelectedWidget = {
        component: props.columnData?.widgets[index]?.type,
        data: JSON.parse(JSON.stringify(props.columnData?.widgets[index])),
        location: {
          rowId: props.rowId,
          columnId: props.columnData.id,
          panelData: null,
          columnLayout: props.columnData.layout,
          widgetIndex: index,
        },
      };

      store.commit('statsEditor/setSelectedWidget', selectedWidget);
      store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
    };

    const removeWidget = (index: number) => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.removeWidget'),
        header: 'Confirmation',
        group: `removeWidget_${props.rowId}_${props.columnData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/removeWidget', {
            location: {
              rowId: props.rowId,
              columnId: props.columnData.id,
              widgetIndex: index,
            },
          });
        },
      });
    };

    // Prevent dropping widget into col with menu widgets
    const hasMenu = computed(() => props.columnData.widgets.some((widget: any) => [StatsWidgets.MENU, StatsWidgets.TABS].includes(widget.type)));

    const dragElementsOptions = computed(() => ({
      animation: 200,
      sort: true,
      group: { name: 'statsWidget', pull: true, put: true },
      disabled: false,
      onAdd: (evt: any) => {
        if (evt.from.getAttribute('data-from-subwidget')) {
          const dataLastLocation = JSON.parse(evt.clone.getAttribute('data-location'));
          store.dispatch('statsEditor/removeWidgetFromSubCol', {
            dataLastLocation,
          });
        }
        const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
        const dataWidgetLocation = JSON.parse(evt.clone.getAttribute('data-widget-location')) || JSON.parse(evt.clone.getAttribute('data-location'));
        let location = evt.to.getAttribute('data-location');

        if (location && dataWidget) {
          location = JSON.parse(location);
          const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);
          const destinationHasMenu = evt.to.getAttribute('data-has-menu');

          if (parseInt(widgetType.minColumnsRequired, 10) > location.columnLayout || destinationHasMenu === 'true') {
            store.dispatch('statsEditor/undoMoveWidget', {
              dataWidget,
              dataWidgetLocation,
            });
            store.dispatch('statsEditor/removeWidget', {
              location: {
                rowId: location.rowId,
                columnId: location.columnId,
                widgetIndex: evt.newIndex,
              },
            });
          }
        }
      },
      onMove: (evt: any) => {
        // On move function for widgets inside column and sub column
        evt.preventDefault();
        if (evt.dragged.getAttribute('data-widget-location')) {
          const dataLocation = JSON.parse(evt.dragged.getAttribute('data-widget-location'));
          const dataWidget = JSON.parse(evt.dragged.getAttribute('data-widget'));

          const choosedWidgetForDrag: Record<string, any> = {
            rowId: dataLocation.rowId,
            columnId: dataLocation.columnId,
            index: dataLocation?.widgetIndex,
          };
          if (dataLocation.panelData) {
            choosedWidgetForDrag.index = dataLocation.panelData.widgetIndex;
            choosedWidgetForDrag.panelData = {
              panelId: dataLocation.panelData.panelId,
              subRowId: dataLocation.panelData.subRowId,
              subColumnId: dataLocation.panelData.subColumnId,
            };
          }

          store.commit('statsEditor/setChoosedWidgetForDrag', choosedWidgetForDrag);

          removePlaceholderClass(evt.dragged);

          if (evt.from !== evt.to) {
            const columnDestinationLayout = evt.to.getAttribute('data-column-layout');
            const destination = evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget');

            const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);

            // Check if destination is empty column
            if (columnDestinationLayout) {
              const isOnPanel = evt.to.getAttribute('data-is-on-panel');

              if ((parseInt(widgetType.minColumnsRequired, 10) > parseInt(columnDestinationLayout, 10))
                || (isOnPanel && [StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type))) {
                evt.to.classList.add('notCompatibleSlot');
              } else {
                evt.to.classList.add('chosenSlot');
              }
            } else if (destination) {
              // Check if destination is inside column with widgets
              const location = evt.to.getAttribute('data-location');
              const dataHasMenu = evt.to.getAttribute('data-has-menu');

              if (location) {
                const locationObject = JSON.parse(location);
                if ((parseInt(widgetType.minColumnsRequired, 10) > parseInt(locationObject.columnLayout, 10))
                  || ([StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type) && locationObject.panelData) || dataHasMenu === 'true') {
                  evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--disallowed', 'm-4');
                } else {
                  evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
                }
              }
            }
          } else {
            evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
          }
        }
        return true;
      },
      onUnchoose: (evt: any) => {
        removePlaceholderClass(evt.item);

        store.commit('statsEditor/resetChoosedWidgetForDrag');
      },
    }));

    return {
      dragOnEmptyColumnOptions,
      selectedWidgetState,
      StatsBuilderColumnLayoutEnum,
      dragElementsOptions,
      hasMenu,

      editWidget,
      removeWidget,
    };
  },
});
