
import {
  defineComponent, onBeforeMount, PropType, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { FieldConfig, ParserFieldObject, Property } from '@/types';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'SocialIconsStyle',

  components: {
    ToggleDisplaySettings,
    Dropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const selectedValue = ref(property.value.value ?? props.configs.options.defaultValue);
    const options = ref([]);

    const handleSelected = () => {
      if (property.value) {
        property.value.value = selectedValue.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };

    onBeforeMount(async () => {
      try {
        options.value = await props.configs.options.list;
      } catch (error) {
        options.value = [];
      }
    });

    return {
      t,
      displayProperties,
      selectedValue,
      options,
      handleSelected,
    };
  },

});
