
import {
  defineComponent,
  PropType,
} from 'vue';

import { Folder } from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'FolderItem',

  components: {},

  props: {
    folder: {
      type: Object as PropType<Folder>,
      required: true,
    },
  },

  emits: {
    'on-open-folder': Object,
  },

  setup(props, context) {
    const { t } = useI18n();

    const openFolder = () => {
      context.emit('on-open-folder', props.folder);
    };

    return {
      t,

      openFolder,
    };
  },
});
