import { TemplateParentTypeEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import * as email from '@/components/template-builder/config/templates/email/raw-html-config';
// eslint-disable-next-line import/no-cycle
import * as popup from '@/components/template-builder/config/templates/popup/raw-html-config';
// eslint-disable-next-line import/no-cycle
import * as embed from '@/components/template-builder/config/templates/embed/raw-html-config';
// eslint-disable-next-line import/no-cycle
import * as facebookMessenger from '@/components/template-builder/config/templates/facebookmessenger/raw-html-config';

interface RawHtmlMap {
  [key: string]: () => string;
}

interface RawWidgetStructureMap {
  [key: string]: RawHtmlMap;
}

interface RawColumnsLayoutWidgetStructureMap {
  [key: string]: Function;
}

// structures
export const RAW_COLUMN_STRUCTURE_MAP: RawHtmlMap = {
  [TemplateParentTypeEnum.EMAIL]: email.rawColumn,
  [TemplateParentTypeEnum.POPUP]: popup.rawColumn,
  [TemplateParentTypeEnum.EMBED]: embed.rawColumn,
};

export const RAW_LINE_STRUCTURE_MAP: RawHtmlMap = {
  [TemplateParentTypeEnum.EMAIL]: email.rawLine,
  [TemplateParentTypeEnum.POPUP]: popup.rawLine,
  [TemplateParentTypeEnum.EMBED]: embed.rawLine,
};

export const RAW_SECTION_STRUCTURE_MAP: RawHtmlMap = {
  [TemplateParentTypeEnum.EMAIL]: email.rawSection,
};

export const RAW_PLACEHOLDER_STRUCTURE_MAP: RawHtmlMap = {
  [TemplateParentTypeEnum.EMAIL]: email.rawWidgetPlaceholder,
  [TemplateParentTypeEnum.POPUP]: popup.rawWidgetPlaceholder,
  [TemplateParentTypeEnum.EMBED]: embed.rawWidgetPlaceholder,
};

export const getWidgetStructures = (t: Function, additionalConfiguration: Record<string, any>): RawWidgetStructureMap => ({
  [TemplateParentTypeEnum.EMAIL]: email.rawWidgets(t, additionalConfiguration),
  [TemplateParentTypeEnum.POPUP]: popup.rawWidgets(t, additionalConfiguration),
  [TemplateParentTypeEnum.EMBED]: embed.rawWidgets(t, additionalConfiguration),
  [TemplateParentTypeEnum.FACEBOOKMESSENGER]: facebookMessenger.rawWidgets(t),
});

export const getColumnsWidgetLayouts = (): RawColumnsLayoutWidgetStructureMap => ({
  [TemplateParentTypeEnum.EMAIL]: email.rawLineWithColumns,
  [TemplateParentTypeEnum.POPUP]: popup.rawLineWithColumns,
  [TemplateParentTypeEnum.EMBED]: embed.rawLineWithColumns,
});
