import { BaseFieldConfig, ComponentNameEnum } from '@/types';

const getColorFieldConfig = (defaultValue = '#ffffff', showAlpha = false, showGradient = false): BaseFieldConfig => ({
  componentName: ComponentNameEnum.COLOR,
  options: {
    defaultValue,
    showAlpha,
    showGradient,
  },
});
export default getColorFieldConfig;
