<template>
  <div class="url-navbar">
    <div class="url-nav-bar-content">
      <div class="inline-block url-nav-bar-icon">
        <Button
          icon="fa fa-home"
          class="p-button-secondary"
          @click="currentUrl = homeUrl; handleChangeUrl(null)"
        />
      </div>
      <div class="inline-block url-nav-bar-input">
        <InputText
          v-model="currentUrl"
          class="input-text"
          @keyup="(event) => handleChangeUrl(event)"
        />
      </div>
      <div class="inline-block url-nav-bar-icon">
        <Button
          icon="fa fa-arrow-right"
          class="p-button-secondary"
          @click="handleChangeUrl(null)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  Ref,
  ref,
  watch,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'UrlNavBar',

  components: {
    InputText,
    Button,
  },

  props: {
    homeUrl: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },
  },

  emits: {
    'change-url': String,
  },

  setup(props, context) {
    const currentUrl: Ref<string> = ref(props.url);

    const handleChangeUrl = async (event: any) => {
      if (!event || event.keyCode === 13) {
        context.emit('change-url', currentUrl.value);
      }
    };

    watch(() => props.url, (newValue) => {
      currentUrl.value = newValue;
    });

    return {
      currentUrl,
      handleChangeUrl,
    };
  },

});
</script>

<style scoped lang="scss">
.url-navbar {
  padding: 5px 10px;
  background: $white;

  & > .url-nav-bar-content {
    border: 1px solid $solitude;
    border-radius: 3px;
    height: 40px;
    box-sizing: content-box;

    & > div {
      vertical-align: top;
      height: 100%;

      &.url-nav-bar-icon {
        width: 40px;

        & > .p-button {
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      &.url-nav-bar-input {
        width: calc(100% - 40px - 40px);

        & > .p-inputtext {
          width: 100%;
          height: 100%;
          border: none;
        }
      }
    }
  }
}
</style>
