const validateSegmentProperty = async (propertyConfig: any, propertyDefinition: any) => {
  const { operator, id, data, groupOperator } = propertyConfig;
  let logicalOperators: Record<string, any> = {};
  if (Array.isArray(propertyDefinition.logical_operators)) {
    const ope = propertyDefinition.logical_operators.find((item: any) => item.type === groupOperator);
    if (ope) {
      logicalOperators = ope.data;
    }
  } else {
    logicalOperators = propertyDefinition.logical_operators;
  }
  if (logicalOperators && 'validate' in logicalOperators[operator]) {
    const validationObject = await logicalOperators[operator].validate({ [id]: data });
    return validationObject;
  }
  return null;
};

export default validateSegmentProperty;