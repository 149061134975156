import axios from 'axios';

export const nestPost = async (
  typeApi: string,
  path: string,
  headers: any,
  postData: any,
  query = '',
) => {
  let result = null;
  let prefix;

  switch (typeApi) {
    case 'payments':
      prefix = process.env.VUE_APP_NESTJS_PAYMENT_API_URL;
      break;
    case 'esr':
      prefix = process.env.VUE_APP_NESTJS_ESR_API_URL;
      break;
    default:
      prefix = process.env.VUE_APP_NESTJS_API_URL;
      break;
  }

  const url = `${prefix}${path}${query}`;
  const formattedHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };

  try {
    const process = await axios.post(
      url,
      postData, { headers: formattedHeaders },
    );

    if (process && process.data) {
      result = process.data;
    }
  } catch (error) {
    result = null;
  }

  return result;
};

export const nestGet = async (
  typeApi: string,
  path: string,
  headers: any,
  query = '',
) => {
  let result = null;
  const prefix = typeApi === 'payments' ? process.env.VUE_APP_NESTJS_PAYMENT_API_URL : process.env.VUE_APP_NESTJS_API_URL;
  const url = `${prefix}${path}${query}`;

  const formattedHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };

  try {
    const process = await axios.get(
      url,
      { headers: formattedHeaders },
    );

    if (process && process.data) {
      result = process.data;
    }
  } catch (error) {
    result = null;
  }

  return result;
};
