import {
  AlignValueEnum, ComponentNameEnum, ConfigurationPanelStructureItemEnum, FieldsGroup, SocialNetworkIconStyleEnum, StepperSignEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
// eslint-disable-next-line import/no-cycle
import {
  resetCssPropertyWithoutPseudoClasses,
  setActiveFollowSocialNetworksForDisplay,
  setAttribute,
  setClass,
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setMediaDisplay,
  setSocialNetworkIconStyle,
  setSocialNetworkIconWidth,
  setSocialNetworkLinks,
  sortSocialNetworks,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getActiveSocialNetworks, getAttributeByName, getClassByName, getCssPropertyByName, getSocialNetworkIconStyle, getSocialNetworksLinks,
} from '@/components/template-builder/getters';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import iconLayoutFieldConfig from '@/components/template-builder/config/fields/icon-layout-config';
import activeSocialNetworksFieldConfig from '@/components/template-builder/config/fields/active-social-networks-config';
import inputGroupFieldConfig from '@/components/template-builder/config/fields/input-group-config';
import getSpmIconsFieldConfig from '@/components/template-builder/config/fields/spm-icons-config';
import iconSizeFieldConfig from '@/components/template-builder/config/fields/icon-size-config';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
// eslint-disable-next-line import/no-cycle
import { removeDynamicStyleWithoutPseudoClasses } from '@/components/template-builder/utils/parser';
import getSpmSocialIconsFieldConfig from '@/components/template-builder/config/fields/spm-social-icons-config';

const widgetSocialMediaFieldsConfig: Array<FieldsGroup> = [
  {
    type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
    label: 'templateBuilder.configs.widgetConfiguration',
    selector: 'body #spm_body #{ID}',
    items: [
      {
        label: 'templateBuilder.fields.iconLayout',
        selector: '{SELECTOR} .spm_social_icons',
        properties: [{
          name: 'spm_social_icons_', getter: getClassByName, setters: [setClass],
        }],
        ...iconLayoutFieldConfig,
      },
      {
        label: 'templateBuilder.fields.iconSize.label',
        selector: '{SELECTOR} img',
        displayToggle: false,
        properties: [{
          name: 'width', getter: getAttributeByName, setters: [setAttribute, setSocialNetworkIconWidth],
        }],
        ...iconSizeFieldConfig,
      },
      {
        label: 'templateBuilder.fields.iconStyle',
        selector: '{SELECTOR} img',
        properties: [{ name: 'iconStyle', getter: getSocialNetworkIconStyle, setters: [setSocialNetworkIconStyle] }],
        ...getSpmSocialIconsFieldConfig(
          Object.values(SocialNetworkIconStyleEnum),
          SocialNetworkIconStyleEnum.STYLE_1,
        ),
      },
      {
        label: 'templateBuilder.fields.activeSocialNetworks',
        selector: '{SELECTOR} .spm_social_icons',
        properties: [{
          name: 'activeNetworks', getter: getActiveSocialNetworks, setters: [setActiveFollowSocialNetworksForDisplay], refreshWidgetFields: true,
        }],
        ...activeSocialNetworksFieldConfig,
      },
      {
        label: 'templateBuilder.fields.socialNetworksLinks',
        selector: '{SELECTOR} .spm_social_icons',
        properties: [{ name: 'socialNetworksLinks', getter: getSocialNetworksLinks, setters: [setSocialNetworkLinks, sortSocialNetworks] }],
        ...inputGroupFieldConfig,
        options: {
          draggable: true,
        },
      },
    ],
  },
  {
    type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
    label: 'templateBuilder.configs.widgetStyles',
    selector: 'body #spm_body #{ID}',
    items: [
      {
        label: 'templateBuilder.fields.contentAlignment',
        selector: '{SELECTOR}',
        properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
        ...getElementAlignFieldConfig('text-align', AlignValueEnum.CENTER),
      },
      {
        label: 'templateBuilder.fields.backgroundColor',
        selector: '{SELECTOR}',
        properties: [{
          name: 'background',
          getter: getCssPropertyByName,
          setters: [setCssProperty],
        }],
        ...getColorFieldConfig(undefined, false, true),
      },
      {
        label: 'templateBuilder.fields.iconInnerMargins',
        selector: '{SELECTOR} span',
        properties: [
          {
            name: 'padding-right',
            getter: getCssPropertyByName,
            setters: [setCssProperty],
          },
          { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
        ],
        componentName: ComponentNameEnum.SPACES,
        options: {
          ...getVerticalSpacesFieldConfig('padding').options,
          ...getHorizontalSpacesFieldConfig('padding').options,
        },
      },
      {
        label: 'templateBuilder.fields.marginVertical',
        selector: '{SELECTOR}',
        properties: [
          { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
        ],
        ...getVerticalSpacesFieldConfig('margin'),
      },
      {
        label: 'templateBuilder.fields.marginHorizontal',
        selector: '{SELECTOR}',
        properties: [
          { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
        ],
        ...getHorizontalSpacesFieldConfig('margin'),
      },
      {
        label: 'templateBuilder.fields.borders',
        selector: '{SELECTOR}',
        properties: [
          { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'border-style', getter: getCssPropertyByName, setters: [] },
          { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
          { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
          { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
          { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
          { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
          { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
          { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
          { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
          { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
          { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
        ],
        ...bordersFieldConfig,
      },
      {
        label: 'templateBuilder.fields.mediaDisplay',
        selector: '{SELECTOR}',
        properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
        ...mediaDisplayFieldConfig,
      },
    ],
  },
];

export default widgetSocialMediaFieldsConfig;
