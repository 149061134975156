<template>
  <ConfirmDialog />
  <DomainInstructions
    v-if="displayDialog"
    :display-dialog="displayDialog"
    :data="sendDomainsData"
    :type="type"
    :locale="locale"
    @on-close-dialog="closeDialog"
    @hide-dialog="displayDialog =false"
    @refresh-data-table="dataTableKey+=1"
  />
  <div class="mt-2 my-domains">
    <div class="flex">
      <div class="flex flex-wrap mb-4">
        <h3>
          {{ t('shop.menu.send_domains') }}
        </h3>
      </div>
      <div
        v-if="findPermission('my_domains.create')"
        class="flex flex-wrap mb-4"
        style="margin-left: auto;"
      >
        <CreateDomain @refresh-data-table="dataTableKey+=1" />
      </div>
    </div>
    <div
      v-if="showMessage"
      class="flex-1"
    >
      <div v-if="domainValid">
        <Message severity="success">
          {{ t('shop.my_domains.validate_success') }}
        </Message>
      </div>
      <div v-else>
        <Message severity="error">
          {{ t('shop.my_domains.validate_failure') }}
        </Message>
      </div>
    </div>
    <div class="grid">
      <div class="col-12">
        <SpmTable
          :key="dataTableKey"
          ref="childComponentRef"
          name="ShopsSendDomains"
          index="id_domain"
          :table-columns="columns"
          :persistent-filters="persistentFilters"
        >
          <template #summary>
            <div class="mb-2">
              <Tag icon="far fa-info-circle">
                {{ t('shop.my_domains.headers.verification.title') }}
              </Tag>
              &nbsp;{{ t('shop.my_domains.headers.verification.desc') }}
            </div>
            <div class="mb-2">
              <Tag icon="far fa-info-circle">
                {{ t('shop.my_domains.headers.authentication.title') }}
              </Tag>
              &nbsp;{{ t('shop.my_domains.headers.authentication.desc') }}
            </div>
            <div class="mb-2">
              <Tag icon="far fa-info-circle">
                {{ t('shop.my_domains.headers.alignment.title') }}
              </Tag>
              &nbsp;{{ t('shop.my_domains.headers.alignment.desc') }}
            </div>
            <div>
              <Tag icon="far fa-info-circle">
                {{ t('shop.my_domains.headers.tracking.title') }}
              </Tag>
              &nbsp;{{ t('shop.my_domains.headers.tracking.desc') }}
            </div>
          </template>
          <template #domain_verified="slotProps">
            <template v-if="slotProps.data.domain_verified">
              <Avatar
                shape="circle"
                icon="far fa-thumbs-up"
                style="color:whitesmoke;background-color:#94c840"
              />
            </template>
            <template v-else>
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-exclamation-circle"
                  style="color:whitesmoke;background-color:#efac06"
                />
                <div class="flex flex-column gap-2">
                  <Button
                    class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                    :label="t('shop.my_domains.validate_by_email')"
                    @click="showDialog(slotProps.data, 'domainValidationForm' )"
                  />
                  <Button
                    class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                    :label="t('shop.my_domains.validate_by_dns')"
                    @click="showDialog(slotProps.data, 'domainValidationDnsForm' )"
                  />
                </div>
              </div>
            </template>
          </template>
          <template #authentication_verified="slotProps">
            <template v-if="slotProps.data.authentication_verified">
              <Avatar
                shape="circle"
                icon="far fa-thumbs-up"
                style="color:whitesmoke;background-color:#94c840"
              />
            </template>
            <template v-else-if="slotProps.data.dkim_verified && slotProps.data.public_key !== null">
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-exclamation-circle"
                  style="color:whitesmoke;background-color:#efac06"
                />
                <Button
                  class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                  :label="t('shop.my_domains.instructions.title')"
                  @click="showDialog(slotProps.data, 'authenticationInstructionNewMethod' )"
                />
              </div>
            </template>
            <template v-else>
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-times"
                  style="color:whitesmoke;background-color:#d32f2f"
                />
                <Button
                  class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                  :label="t('shop.my_domains.alignment.title')"
                  @click="showDialog(slotProps.data, 'authenticationInstruction')"
                />
              </div>
            </template>
          </template>
          <template #alignment_verified="slotProps">
            <template v-if="slotProps.data.alignment_verified">
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-thumbs-up"
                  style="color:whitesmoke;background-color:#94c840"
                />
                <Button
                  class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                  :label="t('shop.my_domains.modify')"
                  @click="showDialog(slotProps.data, 'alignmentInstruction')"
                />
              </div>
            </template>
            <template v-else>
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-times"
                  style="color:whitesmoke;background-color:#d32f2f"
                />
                <Button
                  class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                  :label="t('shop.my_domains.alignment.title')"
                  @click="showDialog(slotProps.data, 'alignmentInstruction')"
                />
              </div>
            </template>
          </template>
          <template #tracking_domain_verified="slotProps">
            <template v-if="slotProps.data.tracking_domain_verified">
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-thumbs-up"
                  style="color:whitesmoke;background-color:#94c840"
                />
                <Button
                  class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                  :label="t('shop.my_domains.modify')"
                  @click="showDialog(slotProps.data, 'trackingDomainInstruction')"
                />
              </div>
            </template>
            <template v-else>
              <div class="flex flex-column gap-2 justify-content-center align-items-center">
                <Avatar
                  shape="circle"
                  icon="far fa-times"
                  style="color:whitesmoke;background-color:#d32f2f"
                />
                <Button
                  class="instruction-btn-wrapper p-button p-button-secondary p-button-text underline text-sm p-1 pl-3 pr-3"
                  :label="t('shop.my_domains.alignment.title')"
                  @click="showDialog(slotProps.data, 'trackingDomainInstruction')"
                />
              </div>
            </template>
          </template>
        </SpmTable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { defineComponent, onMounted, ref } from 'vue';

import Avatar from 'primevue/avatar';
import Tag from 'primevue/tag';
import Button from 'primevue/button';

import SpmTable from '@/components/table/SpmTable.vue';
import { SpmTableAction, SpmTableColumns, SpmTableFilter } from '@/types';
import { OperatorType, ShopsSendDomains } from '@/types/generated-types/graphql';
import { findPermission, UserState } from '@/composables/User';
import DomainInstructions from '@/views/shop/DomainInstructions.vue';
import CreateDomain from '@/components/shop/my-domains/CreateDomain.vue';
import { useConfirm } from 'primevue/useconfirm';
import { DeleteShopsSendDomains, ValidateShopDomainByToken, VerifyDomain } from '@/composables/shop/ShopsSendDomains';
import { showToastError, showToastSuccess } from '@/helpers';
import ConfirmDialog from 'primevue/confirmdialog';
import Message from 'primevue/message';

// @see class Menu in node_modules/primevue/menu/Menu.d.ts
interface MenuInterface {
  toggle(event: Event): void;
}

export default defineComponent({
  name: 'MyDomains',
  components: {
    DomainInstructions,
    SpmTable,
    Avatar,
    Tag,
    CreateDomain,
    ConfirmDialog,
    Message,
    Button,
  },

  props: {
    token: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const dataTableKey = ref(0);
    const confirm = useConfirm();
    const idShop = UserState.activeShop?.id;
    const locale = UserState.user.lang;
    const domainValid = ref(false);
    const showMessage = ref(false);
    const childComponentRef = ref();
    const shopId = UserState?.activeShop?.id ?? 0; // todo: to do something for invalid shop
    const actions: SpmTableAction[] = [
      {
        label: t('shop.my_domains.verify_dns'),
        icon: 'far fa-sync',
        callback: async (data: ShopsSendDomains, event: any) => {
          if (!event) return;
          const result = await VerifyDomain(data.id_domain);

          if (result.err || result.data?.id_domain || result.data?.id_domain === 0) {
            childComponentRef.value.reloadData();
          }
        },

        show: () => findPermission('my_domains.verify'),
      },
      {
        label: t('shop.my_domains.delete.actionText'),
        icon: 'far fa-trash',
        callback: (data: ShopsSendDomains, event: any) => {
          if (!event) return;
          confirm.require({
            message: t('shop.my_domains.delete.confirmText'),
            header: t('shop.my_domains.delete.confirmHeader'),
            icon: 'fa-regular fa-square-info',
            acceptClass: 'p-button-danger',
            accept: async () => {
              const result = await DeleteShopsSendDomains([{ id_domain: data.id_domain }]);

              if (result.status) {
                await showToastSuccess('shop.my_domains.delete.success');
                dataTableKey.value += 1;
              } else {
                await showToastError('shop.my_domains.delete.error');
              }
            },
          });
        },

        show: () => findPermission('my_domains.delete'),
      },
    ];
    const columns: SpmTableColumns[] = [
      {
        field: 'domain',
        header: t('shop.my_domains.headers.domain.title'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'domain_verified',
        header: t('shop.my_domains.headers.verification.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.verification.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'authentication_verified',
        header: t('shop.my_domains.headers.authentication.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.authentication.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'alignment_verified',
        header: t('shop.my_domains.headers.alignment.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.alignment.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'tracking_domain_verified',
        header: t('shop.my_domains.headers.tracking.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;',
        type: 'text',
        tooltip: t('shop.my_domains.headers.tracking.desc'),
        tooltipIcon: 'fa-solid fa-question fa-xs',
      },
      {
        field: 'id_domain',
        header: t('shop.my_domains.headers.options.title'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align:center;width:10%',
        type: 'action',
        actions,
      },
      {
        field: 'dkim_verified',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'public_key',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'id_shop',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'tracking_domain',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'tracking_domain_default_medias',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'dns_verification_key',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'alignment_domain',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
    ];
    const persistentFilters: SpmTableFilter[] = [{ field: 'id_shop', value: shopId, operator: OperatorType.Equals }];

    const displayDialog = ref<boolean>(false);
    const sendDomainsData = ref<ShopsSendDomains>();
    const type = ref('');

    const closeDialog = (refresh: boolean) => {
      displayDialog.value = false;
      if (refresh) childComponentRef.value.reloadData();
    };

    const showDialog = (data: ShopsSendDomains, dialogType: string) => {
      displayDialog.value = true;
      sendDomainsData.value = data;
      type.value = dialogType;
    };

    onMounted(async () => {
      if (props.token && props.token !== '') {
        const result = await ValidateShopDomainByToken(props.token, idShop ?? 0);

        if (result.status && result.id) {
          domainValid.value = true;
          dataTableKey.value += 1;
        }

        showMessage.value = true;
        setTimeout(() => {
          showMessage.value = false;
        }, 5000);
      }
    });

    return {
      t,
      columns,
      persistentFilters,
      findPermission,
      showDialog,
      sendDomainsData,
      displayDialog,
      type,
      dataTableKey,
      closeDialog,
      domainValid,
      showMessage,
      locale,
      childComponentRef,
    };
  },
});
</script>

<style scoped lang="scss">
.w100 {
  width:100%;
}
.my-domains {
  .p-column-header-content {
    vertical-align: top;
    text-align: center;
  }
}
</style>

<style lang="scss">
.instruction-btn-wrapper {
  .p-button-icon-left {
    margin-right: 0.3rem !important;
  }
}
</style>
