
import {
  defineComponent, ref, PropType,
} from 'vue';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'SpmPanelMenuItemCommand',

  components: {
    ProgressSpinner,
  },

  props: {
    item: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props) {
    const showLoading = ref(false);

    const handleCallback = async (event: any) => {
      showLoading.value = true;
      if (props.item.callback) {
        const data = await props.item.callback(props.data, event);
        showLoading.value = false;
        return data;
      }
      showLoading.value = false;
      return {};
    };

    return {
      showLoading,
      handleCallback,
    };
  },
});
