import { GleapCustomActionName } from '@/types/enums';

const gleapActionForm: Record<string, any> = {
  [GleapCustomActionName.GLEAP_RDV_CUSTOMER_SUCCESS]: {
    isLink: true,
    value: process.env.VUE_APP_GLEAP_RDV_CUSTOMER_SUCCESS_LINK || 'https://calendly.com/shopimind-customersuccess/rendez-vous-cs-shopimind?hide_gdpr_banner=1primary_color=94c840',
  },
  [GleapCustomActionName.GLEAP_TELEPHONE_SUPPORT]: {
    isPhone: true,
    value: process.env.VUE_APP_GLEAP_TELEPHONE_SUPPORT || '+33486684040',
  },
};

export default gleapActionForm;
