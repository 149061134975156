<template>
  <template v-if="silent && fieldErrors && fieldErrors.$silentErrors && fieldErrors.$silentErrors.length">
    <div
      v-for="error of fieldErrors.$silentErrors"
      :key="error.$message"
    >
      <small
        v-if="error.$propertyPath === field"
        class="p-error"
      >{{ error.$message }}</small>
    </div>
  </template>
  <template v-if="!silent && fieldErrors && fieldErrors.$errors && fieldErrors.$errors.length">
    <div
      v-for="error of fieldErrors.$errors"
      :key="error.$message"
    >
      <small
        v-if="error.$propertyPath === field"
        class="p-error"
      >{{ error.$message }}</small>
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'FieldErrors',

  props: {
    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    field: {
      type: String,
      required: true,
      default: '',
    },

    silent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props: {errors: any; field: string; silent: boolean}) {
    const fieldErrors: any = ref(null);

    const getFieldFromObject = () => {
      const fieldNames = props.field.split('.');
      let result = props.errors ?? {};

      for (let i = 0; i < fieldNames.length; i++) {
        const fn = fieldNames[i];
        if (Object.prototype.hasOwnProperty.call(result, fn)) {
          result = result[fn];
        } else {
          return null;
        }
      }

      return result;
    };

    onMounted(() => {
      fieldErrors.value = getFieldFromObject();
    });

    return {
      fieldErrors,
    };
  },
});
</script>
