<template>
  <label class="block mb-2">
    {{ t(title) }}
  </label>
  <div class="flex align-items-center">
    <BaseInputNumber
      v-model="value"
      :min="min"
      :max="max"
      :suffix="suffix"
      class="value-input"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, SetupContext, watch} from 'vue';
import { useI18n } from 'vue-i18n';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';

export default defineComponent({
  name: 'InputNumber',

  components: {
    BaseInputNumber,
  },

  props: {
    modelValue: {
      type: Number,
      required: true,
    },

    min: {
      type: Number,
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    suffix: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: number }, { emit }: SetupContext) {
    const { t } = useI18n();
    const value = ref(props.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    return {
      t,
      value,
    };
  },
});
</script>

<style lang="scss" scoped>
.value-input :deep() .p-inputnumber-input.p-inputtext {
  width: 7rem;
}
</style>
