
import { defineComponent, PropType } from 'vue';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import {
  Order,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderItem',

  components: {
    SpmButton,
  },

  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },

    keyName: {
      type: String,
      required: true,
    }
  },

  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const openOrderFile = () => {
      store.dispatch('dataExplorer/openOrderFile', { idOrder: props.order.idOrder, keyName: props.keyName });
    };

    return {
      t,

      formatDate,
      formatNumberToCurrency,
      openOrderFile,
    };
  }
})
