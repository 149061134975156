<template>
  <Dialog
    v-model:visible="displayBasic"
    position="center"
    :header="t('statsEditor.addRowWidget.header')"
    :modal="true"
    :breakpoints="{ '960px': '60vw', '640px': '100vw' }"
    :style="{ width: '40vw' }"
    :dismissable-mask="true"
    @hide="onHideEvent"
  >
    <div class="grid text-left">
      <div class="col-12">
        {{ t('statsEditor.addRowWidget.columnsNumber') }}
      </div>
      <div class="col-12">
        <SelectButton
          v-model="chosenColumns"
          :options="availableColumns"
        />
      </div>
      <div class="col-12">
        {{ t('statsEditor.addRowWidget.columnsStructure') }}
      </div>
      <div class="col-12">
        <div class="grid">
          <div
            v-for="(columnLayout, index) in columnsLayout"
            :key="index"
            class="col-4 text-center"
          >
            <div
              class="layout"
              :class="{ 'active': layoutIndex === index }"
              @click="layoutIndex = index;"
            >
              <div class="grid">
                <div class="col-8 flex column-structure">
                  <div
                    v-for="(size, gridIndex) in columnLayout.grid"
                    :key="gridIndex"
                    class="mb-2 column-structure-drawing"
                    :class="`${size} ${(gridIndex < columnLayout.grid.length) ? 'mr-1' : ''}`"
                  />
                </div>
              </div>
              {{ columnLayout.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        data-test-id="input-text-button-ok"
        :label="t('templateBuilder.modals.validate')"
        icon="far fa-check"
        class="p-button-primary"
        @click="handleValidation"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  computed, ComputedRef, defineComponent, Ref, ref, SetupContext, watch,
} from 'vue';
import { StatsBuilderColumnLayoutEnum, StatsBuilderRowLayoutEnum, StatsType } from '@/types';
import { StatsBuilderColumnConfig } from '@/types/stats-editor-types';
import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { uniqueIdS6Generator } from '@/store/modules/stats-editor';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';

export default defineComponent({
  name: 'StatsBuilderAddRowModal',

  components: {
    Dialog,
    SelectButton,
    Button,
  },

  emits: ['on-hide-modal'],

  setup(props: { }, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();

    const pageTypeState = computed(() => store.getters['statsEditor/getPageType']);

    const availableColumns = computed<number[]>(() => {
      if (pageTypeState.value === StatsType.EMAIL) {
        return [1, 2];
      }
      return [1, 2, 3, 4];
    });
    const chosenColumns: Ref<number> = ref(1);
    const displayBasic = ref(true);

    // Columns layouts buttons
    const availableColumnsLayouts: any[][] = [
      [
        {
          type: StatsBuilderRowLayoutEnum.TWELVE, name: t('statsEditor.addRowWidget.fullWidth'), percentages: [100], grid: ['csd-four'],
        },
      ],
      [
        {
          type: StatsBuilderRowLayoutEnum.SIX_SIX, name: '50% / 50%', percentages: [50, 50], grid: ['csd-two', 'csd-two'],
        },
        {
          type: StatsBuilderRowLayoutEnum.NINE_THREE, name: '75% / 25%', percentages: [75, 25], grid: ['csd-three', 'csd-one'],
        },
        {
          type: StatsBuilderRowLayoutEnum.THREE_NINE, name: '25% / 75%', percentages: [25, 75], grid: ['csd-one', 'csd-three'],
        },
      ],
      [
        {
          type: StatsBuilderRowLayoutEnum.SIX_THREE_THREE, name: '50% / 25% / 25%', percentages: [50, 25, 25], grid: ['csd-two', 'csd-one', 'csd-one'],
        },
        {
          type: StatsBuilderRowLayoutEnum.THREE_SIX_THREE, name: '25% / 50% / 25%', percentages: [25, 50, 25], grid: ['csd-one', 'csd-two', 'csd-one'],
        },
        {
          type: StatsBuilderRowLayoutEnum.THREE_THREE_SIX, name: '25% / 25% / 50%', percentages: [25, 25, 50], grid: ['csd-one', 'csd-one', 'csd-two'],
        },
        {
          type: StatsBuilderRowLayoutEnum.FOUR_FOUR_FOUR, name: '33% / 33% / 33%', percentages: [33, 33, 33], grid: ['csd-one-big', 'csd-one-big', 'csd-one-big'],
        },
      ],
      [
        {
          type: StatsBuilderRowLayoutEnum.THREE_THREE_THREE_THREE,
          name: t('statsEditor.addRowWidget.equalWidth'),
          percentages: [25, 25, 25, 25],
          grid: ['csd-one', 'csd-one', 'csd-one', 'csd-one'],
        },
      ],
    ];
    const columnsLayout: ComputedRef<any[]> = computed(() => availableColumnsLayouts[chosenColumns.value - 1]);
    const layoutIndex: Ref<number> = ref(0);
    const chosenLayout: ComputedRef<any> = computed(() => availableColumnsLayouts[chosenColumns.value - 1][layoutIndex.value]);

    const onHideEvent = () => {
      // Reset values
      chosenColumns.value = 1;
      layoutIndex.value = 0;
      emit('on-hide-modal');
    };

    const handleValidation = () => {
      const columnsLayoutArray = chosenLayout.value.type.split('-');
      const children: StatsBuilderColumnConfig[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const item of columnsLayoutArray) {
        const columnConfig: StatsBuilderColumnConfig = {
          id: `column_${uniqueIdS6Generator()}`,
          type: 'StatsWidgetColumn',
          layout: StatsBuilderColumnLayoutEnum.TWELVE,
          widgets: [],
        };

        switch (item) {
          case '3':
            columnConfig.layout = StatsBuilderColumnLayoutEnum.THREE;
            break;
          case '4':
            columnConfig.layout = StatsBuilderColumnLayoutEnum.FOUR;
            break;
          case '6':
            columnConfig.layout = StatsBuilderColumnLayoutEnum.SIX;
            break;
          case '9':
            columnConfig.layout = StatsBuilderColumnLayoutEnum.NINE;
            break;
          case '12':
            columnConfig.layout = StatsBuilderColumnLayoutEnum.TWELVE;
            break;
          default:
            columnConfig.layout = StatsBuilderColumnLayoutEnum.TWELVE;
        }
        children.push(columnConfig);
      }

      // store.dispatch('statsEditor/removePlaceholderRows');
      store.dispatch('statsEditor/addRow', {
        rowData: {
          id: `row_${uniqueIdS6Generator()}`,
          type: 'StatsWidgetRow',
          layout: chosenLayout.value.type,
          children,
        },
      });

      onHideEvent();
    };

    watch(chosenColumns, () => {
      // Reset layout on columns number change
      layoutIndex.value = 0;
    });

    return {
      t,
      availableColumns,
      chosenColumns,
      columnsLayout,
      layoutIndex,
      displayBasic,
      onHideEvent,
      handleValidation,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-selectbutton:deep() {
  .p-button.p-highlight,
  .p-button.p-highlight:hover {
    background: $brand-color-primary;
    border-color: $brand-color-primary;
  }
}

.layout {
  width: 100%;
  border: $columnsWidgetLayoutBorder;
  color: $columnsWidgetLayoutColor;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: $columnsWidgetLayoutHoverBackground;

    & .column-structure {
      & .column-structure-drawing {
        background: $columnsWidgetLayoutIconBackgroundHover;
      }
    }
  }

  & .column-structure {
    margin: 0 auto;

    & .column-structure-drawing {
      height: 40px;
      background: $columnsWidgetLayoutIconBackground;
    }
  }

  &.active {
    border-color: $brand-color-primary;
    background-color: $brand-color-primary;
    color: $white;

    & .column-structure {
      & .column-structure-drawing {
        background: $white;
      }
    }
  }
}
</style>
