
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  name: 'FieldErrors',

  props: {
    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    field: {
      type: String,
      required: true,
      default: '',
    },

    silent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props: {errors: any; field: string; silent: boolean}) {
    const fieldErrors: any = ref(null);

    const getFieldFromObject = () => {
      const fieldNames = props.field.split('.');
      let result = props.errors && props.errors.value ? props.errors.value : {};

      for (let i = 0; i < fieldNames.length; i++) {
        const fn = fieldNames[i];
        if (Object.prototype.hasOwnProperty.call(result, fn)) {
          result = result[fn];
        } else {
          return null;
        }
      }

      return result;
    };

    onMounted(() => {
      fieldErrors.value = getFieldFromObject();
    });

    return {
      fieldErrors,
    };
  },
});
