
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { DataExplorerDataType, SpmTableColumns } from '@/types';
import { findPermission, UserState } from '@/composables/User';
import Synchronization from '@/components/header/Synchronization.vue';
import ShopsSyncsInProgress from '@/components/header/ShopsSyncsInProgress.vue';
import { ListResult } from '@/composables/GraphQL';
import { ShopsSyncs } from '@/types/generated-types/graphql';
import ShopsSyncsList from '@/composables/shop/ShopSyncs';
import Button from 'primevue/button';
import { getShopCustomerLanguages } from '@/types/country-language-options';
import { FilterMatchMode } from 'primevue/api';
import { currencies } from '@/configs/currencies';
import { getUserLocale } from '@/composables/user/Users';
import Tooltip from 'primevue/tooltip';
import DataExplorerLink from "@/components/data-explorer/fields/DataExplorerLink.vue";

export default defineComponent({
  name: 'Orders',
  components: {
    Synchronization,
    SpmTable,
    ShopsSyncsInProgress,
    Button,
    DataExplorerLink,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const synchroData = ref<ListResult<ShopsSyncs>>();
    const idShop = UserState.activeShop?.id ?? 0;
    const idUser = UserState.user?.id;
    const userType = UserState.user?.userType;
    const launchSynchro = ref(false);
    const currencyList = ref<{ value: string; label: string }[]>([]);
    const userLocale = ref();

    const amtMatchModes = [{ value: FilterMatchMode.EQUALS, label: t('filters.equals') },
      { value: FilterMatchMode.NOT_EQUALS, label: t('filters.notEquals') },
      { value: FilterMatchMode.GREATER_THAN, label: t('filters.greaterThan') },
      { value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO, label: t('filters.greaterThanOrEquals') },
      { value: FilterMatchMode.LESS_THAN, label: t('filters.lessThan') },
      { value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO, label: t('filters.lessThanOrEquals') },
    ];

    currencyList.value = currencies.map((currency: {code: string; label: string}) => ({
      value: currency.code,
      label: currency.label,
    }));

    const formatAmount = (amount: number, currency: string) => amount.toLocaleString(userLocale.value, {
      style: 'currency',
      currency,
    });

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_order',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
        prefix: 'so',
      },
      {
        field: 'date',
        header: t('myShopData.orders.headers.date'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'so',
      },
      {
        field: 'date_creation',
        header: t('myShopData.orders.headers.date_creation'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'so',
      },
      {
        field: 'date_modification',
        header: t('myShopData.orders.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'so',
      },
      {
        field: 'id_order',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'data-explorer-link',
        prefix: 'so',
        dataExplorerOptions: {
          id: 'id_order',
          type: DataExplorerDataType.ORDER_FILE,
          customKey: 'id_order'
        },
      },
      {
        field: 'order_reference',
        header: t('myShopData.orders.headers.order_reference'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'so',
      },
      {
        field: 'amount',
        header: t('myShopData.orders.headers.amount'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        matchModes: amtMatchModes,
        prefix: 'so',
      },
      {
        field: 'amount_without_tax',
        header: t('myShopData.orders.headers.amount_without_tax'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        matchModes: amtMatchModes,
        prefix: 'so',
      },
      {
        field: 'currency',
        header: t('myShopData.orders.headers.currency'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: currencyList.value, hideFilterMenu: true },
        prefix: 'so',
      },
      {
        field: 'first_name',
        header: t('myShopData.orders.headers.first_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'last_name',
        header: t('myShopData.orders.headers.last_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myShopData.orders.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'data-explorer-link',
        prefix: 'sc',
        dataExplorerOptions: {
          id: 'id_shop_customer',
          type: DataExplorerDataType.CONTACT_FILE,
        }
      },
      {
        field: 'lang',
        header: t('myShopData.orders.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
        prefix: 'so',
      },
      {
        field: 'is_valid',
        header: t('myShopData.orders.headers.is_valid'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'active-status-icon',
        filterSettings: {
          type: 'multiSelect',
          options: [{ value: 1, label: t('active') }, { value: 0, label: t('inactive') }],
          hideFilterMenu: true,
          showFilterInput: false,
        },

        prefix: 'so',
      },
      {
        field: 'deleted',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'so',
        hidden: true,
      },
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'so',
        hidden: true,
      },
    ];

    onMounted(async () => {
      synchroData.value = await ShopsSyncsList('orders,all', idShop, 'user');

      userLocale.value = getUserLocale(idUser, userType);
    });

    const synchroAction = (launch: boolean) => {
      launchSynchro.value = launch;
    };

    return {
      t,
      columns,
      findPermission,
      synchroData,
      idShop,
      launchSynchro,
      synchroAction,
      formatAmount,
    };
  },
});
