
import {
  defineComponent, Ref, ref, SetupContext, PropType,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import FileManager from '@/components/file-manager/FileManager.vue';
import EditImageModal from '@/components/modals/EditImageModal.vue';
import {
  File,
  Translation,
} from '@/types';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BaseImage',

  components: {
    FileManager,
    EditImageModal,
    Button,
    InputText,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },
  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayImageModal = ref(false);
    const editImageModal = ref(false);
    const selectedImage: Ref<string> = ref(props.modelValue);

    const handleChange = () => {
      emit('update:modelValue', selectedImage.value);
    };

    const onChooseImage = (image: File) => {
      selectedImage.value = image.url;
      handleChange();
    };

    const onSaveImage = (imageUrl: string) => {
      selectedImage.value = imageUrl;
      handleChange();
    };

    const inputTextRef = ref();

    const focus = () => {
      if (inputTextRef.value) {
        inputTextRef.value.$el.focus();
      }
    };

    const handleFocus = () => {
      if (props.translation) {
        emit('activate-group', props.translation.groupKey);
      }
    };

    const handleBlur = () => {
      if (props.translation) {
        emit('deactivate-group', props.translation.groupKey);
      }
    };

    return {
      t,
      displayImageModal,
      editImageModal,
      onChooseImage,
      onSaveImage,
      handleChange,
      selectedImage,
      inputTextRef,
      focus,
      handleFocus,
      handleBlur,
    };
  },
});
