
import {
  defineComponent, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { checkIfDbCreated } from '@/configs/complete';

export default defineComponent({
  name: 'Welcome',

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup() {
    const { t } = useI18n();

    onMounted(async () => {
      const interval = setInterval(async () => {
        try {
          const dbCreated = await checkIfDbCreated();
          if (dbCreated) {
            clearInterval(interval);
            window.location.reload();
          }
          // eslint-disable-next-line no-empty
        } catch (error) {
        }
      }, 5000);
    });

    return {
      t,
    };
  },

});
