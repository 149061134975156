
import { defineComponent } from 'vue';
import 'vue3-lottie/dist/style.css';
import LoaderSpm from '@/assets/lottie/Animation.json';
import { Vue3Lottie } from 'vue3-lottie';

export default defineComponent({
  name: 'TheSpinner',

  components: {
    Vue3Lottie,
  },

  data() {
    return {
      LoaderSpm,
    };
  },
});
