
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';

import { FieldConfig, ParserFieldObject, Property } from '@/types';
import BaseInputText from '@/components/fields/BaseInputText.vue';

export default defineComponent({
  name: 'InputVariables',
  components: {
    BaseInputText,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);
    const inputValue: Ref<string> = ref(properties.value[0].value ?? props.configs.options.defaultValue);

    const handleValueChange = () => {
      const updatedProperties: Array<Property> = properties.value.map((prop: Property) => {
        const updatedProperty = prop;
        updatedProperty.value = inputValue.value;
        return updatedProperty;
      });
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: updatedProperties,
      });
    };

    watch(() => properties.value[0].value, (values, oldValues) => {
      if (values !== oldValues) {
        inputValue.value = properties.value[0].value;
      }
    }, { immediate: true });

    return {
      t,
      inputValue,
      handleValueChange,
    };
  },
});
