<template>
  <Dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :draggable="false"
    :closable="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    style="width: 60vw"
    @update:visible="updateDialogVisibility"
  >
    <template #header>
      <div>
        <h3>{{ translation('storeParameters.services.productRecommendations.modalCategoriesTitle') }}</h3>
      </div>
    </template>
    <div class="container">
      <div class="p-fluid flex">
        <span class="p-input-icon-left">
          <i class="fa-thin fa-magnifying-glass" />
          <InputText
            v-model="category"
            placeholder="Search"
            @input="handleSearch"
          />
        </span>
      </div>
      <div class="flex pane-container">
        <div
          class="pane"
          style="width: 45%; overflow: hidden;"
        >
          <Listbox
            v-model="availableProductsCategoriesList"
            :options="productsCategoriesAvailableList"
            :virtual-scroller-options="{ itemSize: 38, showLoader: true, delay: 150 }"
            list-style="height:200px"
            multiple
          >
            <template #option="slotProps">
              <div style="text-align:left">
                {{ `#(${slotProps.option.id_category})` }}
                &nbsp;
                {{ slotProps.option.name }}
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </listbox>
        </div>
        <div
          class="mt-4"
          style="width: 5%"
        >
          <Button
            icon="fa-solid fa-chevrons-left"
            @click="moveItemsToAvailable"
          />
          <Button
            icon="fa-solid fa-chevrons-right"
            class="mb-3"
            @click="moveItemsToChosen"
          />
        </div>
        <div
          class="pane"
          style="width: 50%; overflow: hidden;"
        >
          <Listbox
            v-model="chosenProductsCategoriesList"
            :options="productsCategoriesChosenList"
            :virtual-scroller-options="{ itemSize: 38, showLoader: true, delay: 150 }"
            list-style="height:200px"
            multiple
          >
            <template #option="slotProps">
              <div style="text-align:left">
                {{ `#(${slotProps.option.id_category})` }}
                &nbsp;
                {{ slotProps.option.name }}
              </div>
            </template>
            <template #loader="{ options }">
              <div
                :class="['flex items-center p-2', { 'bg-surface-100 dark:bg-surface-700': options.odd }]"
                style="height: 50px"
              >
                <Skeleton
                  :width="options.even ? '60%' : '50%'"
                  height="1.3rem"
                />
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-content-end">
        <Button @click="updateDialogVisibility">
          {{ translation('storeParameters.services.productRecommendations.validate') }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ShopsProductsCategoriesList } from '@/types/generated-types/graphql';
import Listbox from 'primevue/listbox';
import InputText from 'primevue/inputtext';
import ShopsProductsCategoriesListList from '@/composables/shops-products-categories-list/ShopsProductsCategories';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'CategorySelection',

  components: {
    Dialog,
    Button,
    Listbox,
    InputText,
    Skeleton,
  },

  props: {
    translation: {
      type: Function,
      require: true,
      default: () => ({}),
    },

    defaultLang: {
      type: String,
      required: true,
    },

    availableCategoriesArr: {
      type: Array as PropType<ShopsProductsCategoriesList[]>,
      required: true,
    },

    chosenCategoriesArr: {
      type: Array as PropType<ShopsProductsCategoriesList[]>,
      required: true,
    },

    categorySearchStr: {
      type: String,
      required: true,
    },
  },

  emits: ['close-action-modal'],

  setup(props, context) {
    const dialogVisible = ref(true);
    const category = ref(props.categorySearchStr);
    const productsCategoriesAvailableList = ref<ShopsProductsCategoriesList[]>(props.availableCategoriesArr);
    const productsCategoriesChosenList = ref<ShopsProductsCategoriesList[]>(props.chosenCategoriesArr);
    const chosenProductsCategoriesList = ref<ShopsProductsCategoriesList[]>([]);
    const availableProductsCategoriesList = ref<ShopsProductsCategoriesList[]>([]);
    let timer: any = null;

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', productsCategoriesChosenList.value, productsCategoriesAvailableList.value, category);
    };

    const handleSearch = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await ShopsProductsCategoriesListList(category.value, props.defaultLang, 10000);
        if (result !== null) {
          availableProductsCategoriesList.value = [];
          productsCategoriesAvailableList.value = result;
        }
      }, 1000);
    };

    const moveItemsToChosen = () => {
      productsCategoriesChosenList.value = chosenProductsCategoriesList.value.concat(productsCategoriesChosenList.value);

      productsCategoriesChosenList.value.forEach((chosenCategory: ShopsProductsCategoriesList) => {
        availableProductsCategoriesList.value = availableProductsCategoriesList.value.filter(
          (productCategoryAvailable: ShopsProductsCategoriesList) => productCategoryAvailable.id_shop_product_category_list !== chosenCategory.id_shop_product_category_list,
        );
      });

      productsCategoriesChosenList.value = productsCategoriesChosenList.value.concat(availableProductsCategoriesList.value);

      productsCategoriesChosenList.value.forEach((shopsProductsCat: ShopsProductsCategoriesList) => {
        productsCategoriesAvailableList.value = productsCategoriesAvailableList.value.filter(
          (shopsProductsAvailable: ShopsProductsCategoriesList) => shopsProductsAvailable.id_shop_product_category_list !== shopsProductsCat.id_shop_product_category_list,
        );
      });

      availableProductsCategoriesList.value = [];
      chosenProductsCategoriesList.value = [];
    };

    const moveItemsToAvailable = () => {
      productsCategoriesAvailableList.value = productsCategoriesAvailableList.value.concat(chosenProductsCategoriesList.value);

      chosenProductsCategoriesList.value.forEach((shopsProductsCategory: ShopsProductsCategoriesList) => {
        productsCategoriesChosenList.value = productsCategoriesChosenList.value.filter(
          (listShopsProducts: ShopsProductsCategoriesList) => listShopsProducts.id_shop_product_category_list !== shopsProductsCategory.id_shop_product_category_list,
        );
      });

      chosenProductsCategoriesList.value = [];
    };

    return {
      dialogVisible,
      updateDialogVisibility,
      category,
      handleSearch,
      productsCategoriesAvailableList,
      productsCategoriesChosenList,
      chosenProductsCategoriesList,
      availableProductsCategoriesList,
      moveItemsToChosen,
      moveItemsToAvailable,
    };
  },
});
</script>

<style scoped lang="scss">
.container{
  padding: 1rem;
}

.pane-container {
  height: 200px;
}

.pane {
  margin: 5px 5px 0 0;
  height: 100%;
  width:50%;
  overflow: auto;
  border: 1px solid #cccccc;
}
</style>
