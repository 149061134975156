import {
  Insert, List, Request, Update,
} from '@/composables/GraphQL';
import { showToastError } from '@/helpers';
import {
  StringMap, TypeCampaignEnum,
} from '@/types';
import {
  FilterField,
} from '@/types/automated-scenarios';
import {
  Maybe,
  OperatorType,
  Scalars,
  ShopsCustomers,
  ShopsLists, ShopsListsImport,
  ShopsListsImportInputItem, ShopsListsImportUpdateInputItem,

} from '@/types/generated-types/graphql';
import { ref, Ref } from 'vue';
import { ListResponse } from '@/composables/shop/ShopsListsImport';
import getCustomerSegments from '@/components/mes-lists/segments/FiltreCustomerSegments';
import getPurchaseHistorySegment from '@/components/mes-lists/segments/FiltrePurchaseHistorySegments';
import deepcopy from 'deepcopy';
// eslint-disable-next-line import/no-unresolved,import/extensions
import { MenuItem } from 'primevue/menuitem';

export type ShopsCustomersCustom = {
  __typename?: 'ShopsCustomers';
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Maybe<Scalars['String']>;
  /** Gender */
  gender: Maybe<Scalars['String']>;
  /** Birthday */
  birthday?: Maybe<Scalars['DateTime']>;
  /** OptIn */
  opt_in: Maybe<Scalars['String']>;
  /** Newsletter */
  newsletter: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active: Maybe<Scalars['String']>;
  /** Type */
  type: Maybe<Scalars['Int']>;
  /** LastCheckBounce */
  last_check_bounce: Maybe<Scalars['DateTime']>;
  /** MasterRejectType */
  master_reject_type: Maybe<Scalars['String']>;
  /** LastDateReject */
  last_date_reject: Maybe<Scalars['DateTime']>;
  /** LastView */
  last_view: Maybe<Scalars['DateTime']>;
  /** LastClick */
  last_click: Maybe<Scalars['DateTime']>;
  /** LastOrder */
  last_order: Maybe<Scalars['DateTime']>;
  /** Custom1 */
  custom1?: Maybe<Scalars['String']>;
  /** Custom2 */
  custom2?: Maybe<Scalars['String']>;
  /** Custom3 */
  custom3?: Maybe<Scalars['String']>;
  /** Custom4 */
  custom4?: Maybe<Scalars['String']>;
  /** RatingInitial */
  rating_initial?: Maybe<Scalars['Int']>;
  /** RatingDynamic */
  rating_dynamic?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** UnsubType */
  unsub_type?: Maybe<Scalars['Int']>;
  exclude?: Maybe<Scalars['String']>;
};

export const mappingsData = ref<{ columnIds: string[]; optin: boolean; autoUpdate: number; certification: boolean }>({
  columnIds: [],
  optin: false,
  autoUpdate: 0,
  certification: false,
});

export const insertShopsListsImport = async (shopsListImport: ShopsListsImportInputItem[]) => Insert<ShopsListsImportInputItem>({
  name: 'ShopsListsImport',
  input: shopsListImport,
  type: 'ShopsListsImportInput',
});

export const updateShopsListsImport = async (shopsListImportUpdate: ShopsListsImportUpdateInputItem[]) => Update<ShopsListsImportUpdateInputItem>({
  name: 'ShopsListsImport',
  input: shopsListImportUpdate,
  type: 'ShopsListsImportUpdateInput',
});

// eslint-disable-next-line import/no-mutable-exports
export let fullSegment: CustomerSegment[] = [];

export function addToFullSegment(fSegment: CustomerSegment[]) {
  fullSegment = fSegment;
}

// eslint-disable-next-line import/no-mutable-exports
export let fullOptions: MenuItem[] = [];

export function addToFullOptions(fOptions: MenuItem[]) {
  fullOptions = fOptions;
}

export const activeSegments = ref<MenuItem[]>([]);

export async function getShopsListsList(idShop: number): Promise<ListResponse<ShopsLists>> {
  return List<ShopsLists>({
    name: 'ShopsLists',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: idShop,
        },
        {
          field: 'state',
          operator: OperatorType.In,
          value: 'processed,report',
        },
        {
          field: 'date',
          operator: OperatorType.IsNotNull,
          value: 'null',
        },
      ],
      order: [
        {
          field: 'id_shop_list',
          type: 'ASC',
        },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['id_shop_list_import', 'nb_customers', 'nb_imported_customers', 'nb_partially_imported_customers', 'nb_non_imported_customers', 'date',
      'path_report_folder', 'shopsLists{name}'],
  });
}

export interface CustomerSegment {
  id: string;
  option: MenuItem;
  fields: FilterField[];
  multiple?: true;
  validate?: Function;
  availableInCampaign: TypeCampaignEnum[];
  group?: string;
}

interface MinimizedSegment {
  id: string;
  fields: Record<string, any>;
}

export interface SavedSegment {
  newsletterLang: string[];
  listSelect: number[];
  segments: Array<{ [key: string]: string }[]>;
}

export const blankSegment = {
  id: 'none',
  option: {
    label: '',
    value: '',
  },
  fields: [],
  availableInCampaign: [TypeCampaignEnum.CUSTOMER_IMPORT],
};

export const segmentList: Ref<Array<CustomerSegment[]>> = ref([]);

const jsonData: Ref<Array<Record<string, string>[]>> = ref([]);

export const deletedSegments: Ref<CustomerSegment[]> = ref([]);

export const clearVariables = () => {
  segmentList.value = [];

  deletedSegments.value = [];

  activeSegments.value = [];

  jsonData.value = [];
};

export const errorsArr: Ref<{ id: string; error: object }[]> = ref([]);

export const validateSegments = async () => {
  let errorsOccurred = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const frames of segmentList.value) {
    // eslint-disable-next-line consistent-return,no-restricted-syntax
    for (const segment of frames) {
      if (segment?.validate) {
        const data = segment.fields.reduce((acc: { [key: number]: string }, cur: any) => {
          acc[cur.id] = cur.data;
          return acc;
        }, {});

        // eslint-disable-next-line no-await-in-loop
        const validation = await segment?.validate(data);
        if (!validation.success) {
          errorsOccurred = true;
          errorsArr.value.push({
            id: segment.id,
            error: validation.validate,
          });
        } else {
          errorsOccurred = false;
        }
      }
    }
  }

  return errorsOccurred;
};

export const setOnlyKeys = (field: FilterField) => Object.keys(field.data)
  .map((key) => key);

export const setKeyValue = (field: FilterField) => {
  if (field.data[0] === undefined) {
    return [];
  }
  return field.data.reduce((acc: StringMap, value: { id_shop_tag: string; tag: string }) => ({
    ...acc,
    [value.id_shop_tag]: value.tag,
  }));
};

const objectWithoutProperties = (obj: CustomerSegment, keys: string[]) => {
  const target: Record<string, string> = {};
  Object.entries(obj)
    .forEach(([key, value]) => {
      if (keys.indexOf(key) >= 0) return;
      if (!Object.prototype.hasOwnProperty.call(obj, key)) return;
      target[key] = value;
    });

  return target;
};

const getMinifiedVersion = (obj: CustomerSegment) => {
  const target: MinimizedSegment = {
    id: '',
    fields: {},
  };

  Object.entries(obj)
    .forEach(([key, value]) => {
      if (key === 'id') {
        // eslint-disable-next-line prefer-destructuring
        target.id = value.split('-')[0];
      } else if (key === 'fields') {
        obj.fields.forEach((field) => {
          target.fields = {
            ...target.fields,
            [field.id]: field.data,
          };
        });
      }
    });

  return {
    ...target.fields,
    id: target.id,
  };
};

export const transformPayload = (langs: string[], chosenLists: number[]) => {
  jsonData.value = [];

  segmentList.value.forEach((frames, frameIndex) => {
    frames.forEach((segment) => {
      if (segment.id === 'none') return;

      const data: Record<string, any> = getMinifiedVersion(segment);

      if (jsonData.value.length <= frameIndex) {
        jsonData.value.push([data]);
      } else {
        jsonData.value[frameIndex].push(data);
      }
    });
  });

  return {
    segments: jsonData.value,
    newsletterLang: langs,
    listSelect: chosenLists,
  };
};

export const updateInArraySegment = (segment: CustomerSegment) => {
  let updatedSegment: CustomerSegment = { ...segment };
  if (segment.fields) {
    segment.fields.forEach((field: FilterField) => {
      updatedSegment = {
        ...updatedSegment,
        [field.id]: [field.data],
      };
    });
  }

  return updatedSegment;
};

export const removeFromSegmentArray = (segment: CustomerSegment, frameIndex: number) => {
  if (!segment.multiple) {
    // remove from deletedSegments
    const found = deletedSegments.value.findIndex((deletedSegment) => deletedSegment.id === segment.id);

    if (found === -1) {
      deletedSegments.value = deletedSegments.value.filter((restoreSegment) => restoreSegment.id !== segment.id);
    }

    if (activeSegments.value.length === 1) {
      activeSegments.value = fullOptions;
    } else {
      // restore to activeSegments
      activeSegments.value = fullOptions.filter((fullOption) => deletedSegments.value.filter((deletedSegment) => fullOption.value !== deletedSegment.id));
    }
  }

  // remove from segment list
  segmentList.value[frameIndex] = segmentList.value[frameIndex].filter((delSegment) => delSegment.id !== segment.id);

  if (segmentList.value[frameIndex].length === 0) {
    segmentList.value.splice(frameIndex);

    if (frameIndex === 0) {
      segmentList.value.push([blankSegment]);
    }
  }
};

const addOrUpdateSegmentFilter = (segment: CustomerSegment, frameIndex: number, segmentIndex: number) => {
  segmentList.value[frameIndex] = segmentList.value[frameIndex].filter((filterSegment) => filterSegment.id !== 'none');
  if (segmentList.value[frameIndex]) {
    if (segmentList.value[frameIndex][segmentIndex]) {
      if (segment.fields) {
        segmentList.value[frameIndex][segmentIndex] = {
          ...segment,
        };
      }
    } else {
      segmentList.value[frameIndex].push(segment);
    }
  } else {
    segmentList.value.push([segment]);
  }
};

export const addToDeletedSegments = (segment: CustomerSegment) => {
  const found = deletedSegments.value.findIndex((deletedSegment) => deletedSegment.id === segment.id);

  if (found === -1) {
    if (!segment.multiple) {
      deletedSegments.value.push(segment);
    }
  }
};

export const addSegmentToFilter = (segment: CustomerSegment, frameIndex: number, segmentIndex: number) => {
  const segmentId = segment.id.split('-')[0];

  switch (segmentId) {
    case 'filtercanreceivesms':
    case 'filtercanreceivefbmessenger':
    case 'filtercanreceivepushnotifications':
    case 'filtercustomersubscription':
    case 'filtergender':
      addOrUpdateSegmentFilter(segment, frameIndex, segmentIndex);
      break;
    case 'filterareas':
      // eslint-disable-next-line no-param-reassign
      segment.fields[0].data = { 0: (segment.fields[0].data) };
      addOrUpdateSegmentFilter(segment, frameIndex, segmentIndex);
      break;
    case 'filterhascustomertag':
      // eslint-disable-next-line no-param-reassign
      segment.fields[0].data = setKeyValue(segment.fields[0]);
      addOrUpdateSegmentFilter(segment, frameIndex, segmentIndex);
      break;
    case 'filtergroup':
    case 'filternewslettersubscription':

      // eslint-disable-next-line no-param-reassign
      segment.fields[0].data = setOnlyKeys(segment.fields[0]);

      addOrUpdateSegmentFilter(segment, frameIndex, segmentIndex);
      break;
    case 'filterdateordercategorycount':
    case 'filterdateordermanufacturercounttime':
      if (segment.fields[0].data.selection.selected.length > 0) {
        // eslint-disable-next-line no-param-reassign
        delete segment.fields[0].data.selection.selected[0].combinations;
      }
      addOrUpdateSegmentFilter(segment, frameIndex, segmentIndex);
      break;
    default:
      addOrUpdateSegmentFilter(segment, frameIndex, segmentIndex);
      break;
  }
};

export const getAllCustomerSegments = async (): Promise<CustomerSegment[]> => {
  let customerFilterSegment = await getCustomerSegments();

  customerFilterSegment = customerFilterSegment.filter((segmentRec: CustomerSegment) => (segmentRec.availableInCampaign.includes(TypeCampaignEnum.CUSTOMER_IMPORT)));
  const customerSegment: CustomerSegment[] = customerFilterSegment.map((segmentRec: CustomerSegment) => ({
    id: segmentRec.id,
    option: segmentRec.option,
    fields: segmentRec.fields,
    multiple: segmentRec.multiple,
    validate: segmentRec.validate,
    availableInCampaign: segmentRec.availableInCampaign,
    group: segmentRec.group,
  }));

  const purchaseHistorySegment = getPurchaseHistorySegment().filter((segmentRec: CustomerSegment) => (segmentRec.availableInCampaign.includes(TypeCampaignEnum.CUSTOMER_IMPORT)))
    .map((segmentRec: CustomerSegment) => ({
      id: segmentRec.id,
      option: segmentRec.option,
      fields: segmentRec.fields,
      multiple: segmentRec.multiple,
      validate: segmentRec.validate,
      availableInCampaign: segmentRec.availableInCampaign,
      group: segmentRec.group,
    }));

  return [...customerSegment, ...purchaseHistorySegment];
};

export const getCustomersFromFields = async (customerFrames: Array<CustomerSegment[]>) => {
  const finalArray: Array<Record<string, any>[]> = [];

  if (customerFrames.length === 1) {
    if (customerFrames[0][0].id === 'none') {
      return [];
    }
  }
  customerFrames.forEach((customerSegments) => {
    const queryParams: Record<string, any>[] = [];
    customerSegments.forEach((segment) => {
      let params: Record<string, any> = [];
      segment.fields.forEach((fields) => {
        params = {
          [fields.id]: fields.data,
        };
      });
      const segmentParts = segment.id.split('-');
      queryParams.push({ [segmentParts[0]]: [params] });
    });
    finalArray.push(queryParams);
  });
  return finalArray;
};

export const handleAddSegment = (segment: CustomerSegment, frameIndex: number, orIndex: number) => {
  addSegmentToFilter(segment, frameIndex, orIndex);

  const groups = ref<MenuItem[]>([
    {
      label: 'Customer',
      value: 'customer',
      items: [],
    },
    {
      label: 'Newsletter',
      value: 'newsletter',
      items: [],
    },
    {
      label: 'Order',
      value: 'order',
      items: [],
    },
    {
      label: 'Purchase',
      value: 'purchase',
      items: [],
    }]);

  fullSegment
    .forEach((segmentRec: CustomerSegment) => {
      groups.value.forEach((record, groupIndex) => {
        if (segmentRec.group === record.value) {
          if ((segment.id !== segmentRec.id) || segment.multiple) {
            // eslint-disable-next-line no-unused-expressions
            groups.value[groupIndex].items?.push(segmentRec.option);
          }
        }
      });
    });
  activeSegments.value = groups.value;

  addToDeletedSegments(segment);
};

export const getCustomersFromSegments = async (segmentData: Array<Record<string, any>[]>) => {
  const query = `query ( $data: SegmentData) {
    GetCustomersFromSegments(params: $data)
      {
        items
          {
            id_shop_customer
            date_modification
            first_name
            last_name
            country
            email
            lang
            newsletter
          },
          total
      }
    }`;
  const variables = { data: { segmentData } };

  const {
    data,
    err,
  } = await Request<ListResponse<ShopsCustomers>>({
    name: 'GetCustomersFromSegments',
    query,
    variables,
  });

  const res: ListResponse<ShopsCustomers> = data ?? {
    items: [],
    total: 0,
    err: '',
  };

  if (err) {
    await showToastError('CLIENT_WEBSITE_CONNECTION_ERROR');
    return {
      items: [],
      total: 0,
      err: '',
    };
  }
  return res;
};

export async function getShopsListsImportList(idShop: number, idShopList: number): Promise<ListResponse<ShopsListsImport>> {
  return List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: idShop,
        },
        {
          field: 'state',
          operator: OperatorType.Equals,
          value: 'queued',
        },
        {
          field: 'id_shop_list',
          operator: OperatorType.Equals,
          value: idShopList,
        },
        {
          field: 'params',
          operator: OperatorType.Equals,
          value: 'segmentation',
        },
      ],
      order: [
        {
          field: 'id_shop_list',
          type: 'ASC',
        },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['id_shop_list_import', 'segments_config'],
  });
}

export const transformJsonToSegments = (savedSegments: SavedSegment) => {
  if (savedSegments.segments.length === 0) {
    return false;
  }
  savedSegments.segments.forEach((savedFrame, frameIndex) => {
    savedFrame.forEach((savedSegment, segmentIndex) => {
      fullSegment.forEach((segment) => {
        const sSegmentId = savedSegment.id.split('-');

        if (segment.id === sSegmentId[0]) {
          if (frameIndex > segmentList.value.length - 1) {
            segmentList.value.push([]);
          }

          const cSegment = deepcopy(segment);
          cSegment.id = `${segment.id}-${frameIndex}-${segmentIndex}`;
          segmentList.value[frameIndex].push(cSegment);
          // segmentList.value[frameIndex][segmentIndex].id = `${segment.id}-${frameIndex}-${segmentIndex}`;
          segment.fields.forEach((field: FilterField, index) => {
            // id to compare to saved segment
            const objId = segmentList.value[frameIndex][segmentIndex].fields[index].id;
            segmentList.value[frameIndex][segmentIndex].fields[index].data = savedSegment[objId];
          });
        }
      });
    });
  });
  return true;
};
// <option value="6">Historique d'achat sur les derniers mois</option>
// <option value="7">Nombre de commandes sur les derniers mois</option>
// <option value="8">Montant cumulé des commandes sur les derniers mois</option>
// <option value="13">Commande non valide sur une période</option>
// <option value="14">Erreur de paiement sur une période</option>
// <option value="16">Ont ouvert au moins une des X dernières newsletters</option>
