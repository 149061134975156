import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ActionTypeEnum,
} from '@/types';
import imageFieldConfig from '@/components/template-builder/config/fields/image-config';
// eslint-disable-next-line import/no-cycle
import {
  setAttribute,
  setFacebookButtons,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName,
  getFacebookButtons,
} from '@/components/template-builder/getters';
import getFacebookButtonsFieldConfig from '@/components/template-builder/config/fields/facebook-buttons-config';

const redirectTypes = [ActionTypeEnum.REDIRECT, ActionTypeEnum.DISPLAY_TEMPLATE];

const facebookWidgetImageConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.fbImageButtons',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.image',
            selector: '{SELECTOR}.spm_widget_image > .image_widget > img',
            properties: [
              { name: 'src', getter: getAttributeByName, setters: [setAttribute] },
            ],
            ...imageFieldConfig,
            options: {
              ...imageFieldConfig.options,
              description: false,
            },
          },
          {
            label: 'templateBuilder.fields.fbButtons.header',
            selector: '{SELECTOR}.spm_widget_image > .buttons',
            properties: [
              { name: 'buttons', getter: getFacebookButtons, setters: [setFacebookButtons] },
            ],
            ...getFacebookButtonsFieldConfig(redirectTypes),
          },
        ],
      },
    ],
  },
};

export default facebookWidgetImageConfig;
