<template>
  <TheSpinner v-if="isTheSpinnerVisible" />
  <Toast
    position="bottom-right"
    :base-z-index="1"
  />
  <FeatureUnavailableModal />
  <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view>
  <AIModal />
</template>

<script lang="ts">
import { provide, defineComponent, computed, ref } from 'vue';
import Toast from 'primevue/toast';

import { TenantAttributes } from '@/types';
import { useStore } from '@/store';
import TheSpinner from '@/components/TheSpinner.vue';
import FeatureUnavailableModal from '@/components/modals/FeatureUnavailableModal.vue';
import AIModal from '@/components/modals/AIModal.vue';

export default defineComponent({
  name: 'App',
  components: {
    AIModal,
    TheSpinner,
    Toast,
    FeatureUnavailableModal,
  },

  setup() {
    const tenantAttributes: TenantAttributes = {
      brandColors: null,
      logo: '',
    };
    provide('tenantAttributes', tenantAttributes);
    const store = useStore();
    const isTheSpinnerVisible = computed(() => store.state.general.isTheSpinnerVisible);

    return {
      isTheSpinnerVisible,
    };
  },
});
</script>
