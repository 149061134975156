<template>
  <Loader
    v-if="isLoading"
    style="z-index: 9999;"
  />
  <DuplicateModal
    v-if="showDuplicationModal"
    :translation="t"
    :list-name="newListName"
    icon="far fa-exclamation-triangle"
    :message="t('campaigns.automation.list.actions.duplicate.message')"
    @on-close-modal="closeDuplicationModal"
    @on-execute="confirmDuplicateCampaign"
  />
  <div
    v-if="idTemplateEditingFromCampaign !== null"
    class="blocked"
  >
    <Card>
      <template #title>
        <p>{{ t('automatedScenarios.blockedUICard.title') }}</p>
      </template>
      <template #content>
        <p>{{ t('automatedScenarios.blockedUICard.content') }}</p>
      </template>
      <template #footer>
        <Button
          icon="far fa-check"
          :label="t('automatedScenarios.blockedUICard.buttons.continue')"
          @click="continueEditingTemplateAction(idTemplateEditingFromCampaign)"
        />
        <Button
          icon="far fa-times"
          :label="t('automatedScenarios.blockedUICard.buttons.cancel')"
          class="p-button-secondary"
          style="margin-left: .5em"
          @click="cancelEditingTemplateAction(idTemplateEditingFromCampaign)"
        />
      </template>
    </Card>
  </div>
  <EditorModal
    :toolbar-right-buttons="toolbarRightButtons"
    :content-header-toolbar-start="contentHeaderStartToolbar"
    :content-header-toolbar-end="contentHeaderEndToolbar"
    :title="state.scenario.settingsData.name"
    :tabs="tabs"
    :class="{ 'configuring-box-sidebar': state.leftToolbar.show.operatorEditForm
      || (state.leftToolbar.show.operatorSelector && state.leftToolbar.operatorSelectorStep === StepsOperatorSelector.CONFIGURE) }"
  >
    <template
      v-if="(state.leftToolbar.show.operatorEditForm ||
        state.leftToolbar.show.operatorSelector ||
        state.leftToolbar.show.settingsPanel ||
        state.leftToolbar.show.historyPanel) && !state.scenario.visualisationMode"
      #sidebar
    >
      <Panel
        v-if="state.leftToolbar.show.operatorEditForm"
        class="panel-sidebar-automated-scenario"
      >
        <template
          #header
        >
          <div class="panel-header-content">
            <i
              size="xs"
              class="fas fa-cogs mr-2"
            />
            <h4>{{ t(state.selectedOperator.meta.label) }}</h4>
          </div>
        </template>
        <OperatorEditForm
          :key="state.selectedOperator.operatorId"
          :signal-save="signalSaveOperatorEditFormComponent"
          :action-after-save="actionAfterSaveOperatorEditFormComponent"
          @reset-action-after-save="actionAfterSaveOperatorEditFormComponent = {}"
        />
      </Panel>
      <Panel
        v-if="state.leftToolbar.show.operatorSelector"
        class="panel-sidebar-automated-scenario"
      >
        <template #header>
          <div class="panel-header-content">
            <h4>
              {{ state.leftToolbar.operatorSelectorStep === StepsOperatorSelector.SELECT
                ? t('automatedScenarios.panels.boxesSelector.addStep')
                : t('automatedScenarios.panels.boxesSelector.configureAStep') }}
            </h4>
          </div>
        </template>

        <template #icons>
          <i
            v-if="state.leftToolbar.operatorSelectorStep === StepsOperatorSelector.SELECT"
            class="far fa-times icons-header-panel"
            @click="onBackOperatorSelectorStep(false)"
          />
        </template>

        <OperatorSelector
          :signal-save="signalSaveOperatorEditFormComponent"
          :action-after-save="actionAfterSaveOperatorEditFormComponent"
          @reset-action-after-save="actionAfterSaveOperatorEditFormComponent = {}"
        />
      </Panel>
      <Panel
        v-if="state.leftToolbar.show.settingsPanel"
        class="panel-sidebar-automated-scenario"
      >
        <template #header>
          <div class="panel-header-content">
            <h4>
              {{ t('automatedScenarios.panels.globalConfiguration') }}
            </h4>
          </div>
        </template>

        <template #icons>
          <i
            class="far fa-times icons-header-panel"
            @click="onBackOperatorSelectorStep(false)"
          />
        </template>

        <div>
          <SettingsPanelAutomation
            v-if="state.scenario.type === TypeCampaignEnum.AUTOMATION"
            v-model:setting-panel-validation-errors="settingPanelValidationErrors"
          />
          <SettingsPanelBulk
            v-if="state.scenario.type === TypeCampaignEnum.BULK"
            v-model:setting-panel-validation-errors="settingPanelValidationErrors"
          />
        </div>
      </Panel>
      <Panel v-if="state.leftToolbar.show.historyPanel">
        <template #header>
          <i
            size="xs"
            class="far fa-clock"
          />
          {{ t('automatedScenarios.history.panelHeader') }}
        </template>
        <div>
          <HistoryPanel />
        </div>
      </Panel>
    </template>
    <template #content>
      <div
        ref="flowchart"
        class="flowcharter"
      />
      <div
        v-if="state.flowchartTick !== '0' && state.operators && state.operators.length"
        :key="state.flowchartTick"
      >
        <div
          v-for="op in state.operators"
          :key="`${op.operatorId}-${state.scenario.id}`"
        >
          <teleport :to="op.targetDiv">
            <div class="display-box-container-output-control-box">
              <OperatorDisplayBox
                :operator-id="op.operatorId"
                :label="op.label"
                :id="op.id"
                :icon="op.icon"
                :has-configuration="op.hasConfiguration"
                :kind="op.kind"
                :input="state.input[op.operatorId] ?? 0"
                :outputs="state.nbOutputs[op.operatorId] ?? 0"
                :grayscaled="op.grayscaled"
                :disabled="op.disabled"
                :selectable="op.selectable"
                :analytics-mode="state.scenario.analyticsMode"
                :visualisation-mode="state.scenario.visualisationMode"
                :select-loading="selectLoading[op.operatorId]"
                @edit-operator="onOperatorSelect(op.operatorId)"
                @replace-operator="(_) => onReplaceClickedParent(op.operatorId)"
                @merge-connector="(_, type, connector) => onConnectorMerge(op.operatorId, type, connector)"
                @input-clicked="(_) => onInputClickedParent(op.operatorId)"
                @output-clicked="(_, i) => onOutputClickedParent(op.operatorId, i)"
                @action-add-operator-clicked="(_, type) => onActionAddOperatorBtnClicked(op.operatorId, type)"
                @action-branch-merge-clicked="(_, type) => onActionBranchMergeBtnClicked(op.operatorId, type)"
                @action-branch-closure-clicked="(_, type) => onActionBranchClosureBtnClicked(op.operatorId, type)"
                @action-remove-operator-clicked="(_, type) => onActionRemoveOperatorBtnClickedParent(op.operatorId, type)"
                @exchange-clicked="(_, i) => onExchangeClicked(op.operatorId, i)"
                @highlighted="highlightOperator(op.operatorId)"
                @unhighlighted="unhighlightOperators"
                @send-test-message="handleSendTestMessage"
              />
            </div>
          </teleport>
        </div>
      </div>
      <div
        v-else
        class="start-automated-scenario"
      >
        <Button
          :label="labelStartButton"
          icon="far fa-lg fa-play"
          @click="onStartButtonClicked"
        />
      </div>
    </template>
  </EditorModal>
  <ModalScheduledBulkCampaign
    v-if="(state.scenario.type === TypeCampaignEnum.BULK) && showModalScheduledBulkCampaign"
    v-model:setting-panel-validation-errors="settingPanelValidationErrors"
    @on-hide-modal="onHideModalScheduledBulkCampaign"
    @on-save-scheduled="onSaveModalScheduledBulkCampaign"
  />
  <ConfirmDialog group="operatorEditForm" />
  <ConfirmDialog group="noSufficientOffer" />
  <SendTestModal
    v-if="showSendTestModal"
    :type-of-input="selectedTemplateType"
    @on-validate="(emitParameters) => sendTestTemplate(emitParameters)"
  />
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUpdated,
  Ref,
  ref,
  toRaw,
} from 'vue';
import EditorModal from '@/components/modals/EditorModal.vue';
import {
  activateCampaign,
  AutomatedScenarioOperator,
  AutomatedScenarioState as state,
  availableFlowchartZoom,
  backOperatorSelect,
  centerZoom,
  coreDrawUI,
  deactivateCampaign,
  drawingTreeConfig,
  flowchartTick,
  getEditingTemplateFromCampaign,
  highlightOperator,
  mergeConnector,
  minimizeEditorCampaign,
  onActionAddOperatorBtnClicked,
  onActionBranchClosureBtnClicked,
  onActionBranchMergeBtnClicked,
  onActionRemoveOperatorBtnClicked,
  onExchangeClicked,
  onInputClicked,
  onOutputClicked,
  onReplaceClicked,
  onStartButtonClicked,
  OperatorInput,
  OperatorOutput,
  resetLeftToolbar,
  saveCampaignsInLocalStorage,
  selectOperator,
  set$Flowchart,
  setActiveHistory,
  updateAnalyticsDateInterval,
  setAnalyticsMode,
  setEditingTemplateFromCampaign,
  setGlobalSettingsData,
  setRefreshScenariosList,
  setScenarioCurrentZoom,
  showToolTab,
  StepsOperatorSelector,
  ToolTab,
  unhighlightOperators,
  updateCampaignBulk,
  checkIfCampaignContainsMediaBoxes,
  checkIfCampaignContainsVouchers,
  checkBackupStatusOfTheCurrentOperatorConfig,
  ActionsDialogRemoveOperator,
  resetSelectedOperator,
  resetDefaultDisplayUrl,
  updateDateModificationOfCurrentScenario,
  onEditorUnload, cleanOperators, AutomatedScenarioAnalyticsDateInterval,
  addHistory,
  deleteLink,
} from '@/composables/AutomatedScenarios';
import {
  CampaignsBulkStatusEnum,
  MarketingWorkflowStatusEnum,
  MarketingWorkflowTypeEnum,
  PrimvueMenuModel,
  TypeCampaignEnum,
  IntervalNextDateEnum, IntervalDateEnum,
} from '@/types';
import OperatorDisplayBox from '@/components/automated-scenarios/OperatorDisplayBox.vue';
import Panel from 'primevue/panel';
import OperatorEditForm from '@/components/automated-scenarios/OperatorEditForm.vue';
import OperatorSelector from '@/components/automated-scenarios/OperatorSelector.vue';
import HistoryPanel from '@/components/automated-scenarios/HistoryPanel.vue';
import { useI18n } from 'vue-i18n';
import { UserState, hasAccessToFeatures } from '@/composables/User';
import Button from 'primevue/button';
import useVuelidate from '@vuelidate/core';
import {
  between, integer, maxLength, minLength, required,
} from '@vuelidate/validators';
import { includesValuesValidator, verifyCommercialUseCampaign } from '@/helpers/CustomValidator';
import { showToastError, showToastSuccess } from '@/helpers';
import { duplicateWorkflow, saveWorkflow } from '@/composables/workflows/Workflow';
import {
  Bundles,
  CampaignsBulkDuplicateInputItem,
  CampaignsBulkSaveInputItem, OperatorType,
  ShopMarketingWorkflowsDuplicateInputItem,
  ShopMarketingWorkflowsSaveInputItem,
} from '@/types/generated-types/graphql';
import deepcopy from 'deepcopy';
import Card from 'primevue/card';
import {
  activate as activateTemplate,
  deactivate as deactivateTemplate,
} from '@/composables/template-editor/TemplateEditor';
import { GlobalBulkSettingsData, GlobalSettingsData } from '@/types/automated-scenarios';
import SettingsPanelAutomation from '@/components/automated-scenarios/SettingsPanelAutomation.vue';
import SettingsPanelBulk from '@/components/automated-scenarios/SettingsPanelBulk.vue';
import { duplicateBulkCampaign, getBulkCampaignStatus, saveBulkCampaign } from '@/composables/workflows/CampaignBulk';
import ModalScheduledBulkCampaign from '@/components/automated-scenarios/ModalScheduledBulkCampaign.vue';
import moment from 'moment/moment';
import { store } from '@/store';
import { setWorkflowAnalyticsData } from '@/composables/automated-scenarios/AutomatedScenariosAnalytics';
import Loader from '@/components/layout/Loader.vue';
import DuplicateModal from '@/components/modals/DuplicateModal.vue';
import { useConfirm } from 'primevue/useconfirm';
import { getNowFormattedByTimezone } from '@/helpers/Date';
import ConfirmDialog from 'primevue/confirmdialog';
import { checkIfServiceNeedToBeVerified, getNotConfiguredServices } from '@/composables/shop/MyShopParameters';
import { getServicesToVerify } from '@/configs/services-parameters';
import { useRouter } from 'vue-router';
import { getShopsCredits } from '@/composables/credits/Credits';
import { List } from '@/composables/GraphQL';
import SendTestModal, { Suggestion } from '@/components/modals/SendTestModal.vue';
import { nestGet } from '@/composables/nestApi';
import { TemplateTypeEnum } from '@/composables/shop/Templates';

export default defineComponent({
  name: 'AutomatedScenarioEditor',

  components: {
    Loader,
    ModalScheduledBulkCampaign,
    SettingsPanelBulk,
    SettingsPanelAutomation,
    OperatorEditForm,
    OperatorSelector,
    OperatorDisplayBox,
    HistoryPanel,
    EditorModal,
    DuplicateModal,
    Card,
    Button,
    Panel,
    ConfirmDialog,
    SendTestModal,
  },

  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const signalSaveOperatorEditFormComponent = ref(false);
    const actionAfterSaveOperatorEditFormComponent = ref({});

    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;
    const isLoading = ref(false);
    const settingPanelValidationErrors = ref({});
    const showModalScheduledBulkCampaign = ref(false);
    const flowchart = ref<HTMLDivElement>(document.createElement('div'));
    const containerSize = computed(() => ({
      width: Object.values(state.scenario.data.operators)
        .reduce((acc: number, operator: AutomatedScenarioOperator) => (acc < operator.left ? (operator.left + drawingTreeConfig.boxSizeWidth) : acc), 0),
      height: Object.values(state.scenario.data.operators)
        .reduce((acc: number, operator: AutomatedScenarioOperator) => (acc < operator.top ? (operator.top + drawingTreeConfig.boxSizeHeight) : acc), 0),
    }));
    const confirm = useConfirm();
    const newListName = ref(state.scenario.settingsData.name);
    const showDuplicationModal = ref(false);
    const scenarioType = ref('');
    const scenarioId = ref();

    const selectLoading: Ref<Record<string, boolean>> = ref({});

    const currentHistoryIndex = computed(() => state.scenario.currentHistoryIndex);
    const maxHistoryIndex = computed(() => state.scenario.maxHistoryIndex);

    const labelStartButton = computed(() => (state.scenario.type === TypeCampaignEnum.AUTOMATION
      ? t('campaigns.automation.editor.start_button') : t('campaigns.bulk.editor.start_button')));

    const clickedLinkId: Ref<string | null> = ref(null);
    const clickedLinkCount: Ref<number> = ref(0);

    const idTemplateEditingFromCampaign: Ref<null|number> = ref(null);
    const cancelEditingTemplateAction = (idTemplate: number) => {
      deactivateTemplate(idTemplate);
      idTemplateEditingFromCampaign.value = null;
      setEditingTemplateFromCampaign(null);
    };

    const continueEditingTemplateAction = async (idTemplate: number) => {
      await activateTemplate(idTemplate, '', '').then(() => {
        idTemplateEditingFromCampaign.value = null;
      }).catch((error) => {
        // todo: decide what action to take
      });
    };

    const adjustContainerSize = () => {
      if (flowchart.value.children && (flowchart.value.children as HTMLCollectionOf<HTMLElement>)[0]) {
        (flowchart.value.children as HTMLCollectionOf<HTMLElement>)[0].style.height = `${(containerSize.value.height)}px`;
        (flowchart.value.children as HTMLCollectionOf<HTMLElement>)[0].style.width = `${(containerSize.value.width)}px`;
      }
    };

    const display = async () => {
      await nextTick();
      let operatorsCount = 0;
      const opts = {
        canUserEditLinks: false,
        multipleLinksOnInput: true,
        canUserMoveOperators: true,
        data: state.scenario.data,
        defaultLinkColor: '#DEDEDE',
        defaultSelectedLinkColor: '#078bde',
        linkWidth: 2,
        distanceFromArrow: 7,

        onOperatorCreate: (
          id: string,
          data: object,
          element: { operator: HTMLDivElement[] },
        ) => {
          if (element.operator.length) {
            element.operator[0].setAttribute('id', `flowchart_operator_${id}`);
            operatorsCount += 1;
          }

          if (state.operators.length !== 0 && operatorsCount === state.operators.length) {
            flowchartTick();
          }
          return true;
        },

        onLinkSelect: (linkId: any) => {
          if (clickedLinkId.value && clickedLinkId.value !== linkId) {
            // On link change, reset click counter and change current clicked link ID
            clickedLinkId.value = linkId;
            clickedLinkCount.value = 0;
          } else {
            clickedLinkCount.value += 1;
          }

          if (clickedLinkCount.value === 2) {
            deleteLink(linkId);

            // Reset click counter
            clickedLinkCount.value = 0;
            return false;
          }

          return false;
        },
      };

      if (!flowchart.value) {
        return;
      }

      if (flowchart.value.hasChildNodes()) {
        flowchart.value.childNodes.forEach((c) => flowchart.value.removeChild(c));
      }

      const container = document.createElement('div');
      flowchart.value.appendChild(container);
      // eslint-disable-next-line
      const $jq = $(container) as any;
      operatorsCount = 0;
      const $flowchart = $jq.flowchart(opts);
      set$Flowchart($flowchart);
      coreDrawUI();

      // Panzoom initialization...
      $flowchart.panzoom();
      adjustContainerSize();
    };

    const handleBackupOperatorConfigStatus = (continueCallback: Function, showModal = false) => {
      const backupStatusConfig = checkBackupStatusOfTheCurrentOperatorConfig();
      if (!backupStatusConfig) {
        store.commit('general/hideTheSpinner');
        if (showModal) {
          confirm.require({
            group: 'operatorEditForm',
            message: t('automatedScenarios.operatorEditForm.message'),
            header: 'Confirmation',
            icon: 'far fa-exclamation-triangle',
            acceptLabel: t('yes'),
            rejectLabel: t('no'),
            accept: () => {
              actionAfterSaveOperatorEditFormComponent.value = {
                action: continueCallback,
              };
              signalSaveOperatorEditFormComponent.value = !signalSaveOperatorEditFormComponent.value;
            },
            onHide: () => {
              continueCallback();
            },
            reject: () => {
              continueCallback();
            },
          });
        } else {
          actionAfterSaveOperatorEditFormComponent.value = {
            action: continueCallback,
          };
          signalSaveOperatorEditFormComponent.value = !signalSaveOperatorEditFormComponent.value;
        }
      } else {
        continueCallback();
      }
    };

    const handleLeaving = (continueCallback: Function) => {
      const backupStatusConfig = checkBackupStatusOfTheCurrentOperatorConfig();
      if (!backupStatusConfig) {
        confirm.require({
          group: 'operatorEditForm',
          message: t('automatedScenarios.operatorEditForm.leaveMessage'),
          header: 'Confirmation',
          icon: 'far fa-exclamation-triangle',
          acceptLabel: t('yes'),
          acceptClass: 'p-button-danger',
          rejectLabel: t('no'),
          rejectClass: 'p-button-secondary',
          accept: () => {
            continueCallback();
          },
          onHide: () => {},
          reject: () => {},
        });
      } else {
        continueCallback();
      }
    };

    const onInputClickedParent = (operatorId: string) => {
      handleBackupOperatorConfigStatus(() => {
        onInputClicked(operatorId);
      });
    };

    const onOutputClickedParent = (operatorId: string, outputId: string) => {
      handleBackupOperatorConfigStatus(() => {
        onOutputClicked(operatorId, outputId);
      });
    };

    const onReplaceClickedParent = (id: string) => {
      handleBackupOperatorConfigStatus(() => {
        onReplaceClicked(id);
      });
    };

    const onOperatorSelect = async (id: string) => {
      selectLoading.value[id] = true;

      const serviceParameterSuccessAction = () => {
        handleBackupOperatorConfigStatus(() => {
          selectOperator(id, true);
        });
      };

      const meta = selectOperator(id, true, true);

      const callbackAction = async () => {
        if (checkIfServiceNeedToBeVerified(meta.Meta.id)) {
          const notConfiguredServices = await getNotConfiguredServices(idShop, getServicesToVerify(meta, state.operators));
          if (notConfiguredServices.length) {
            store.commit('general/setNotConfiguredServices', notConfiguredServices);
            store.commit('general/setIsServicesParametersModalVisible', true);
            store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
          } else {
            serviceParameterSuccessAction();
          }
        } else {
          serviceParameterSuccessAction();
        }
      };
      
      if (state.scenario.type === TypeCampaignEnum.BULK) {
        const featurePermission = hasAccessToFeatures(meta.Meta.id);
        if (featurePermission && !featurePermission.access) {
          store.commit('general/setIsFeatureUnavailableModalVisible', true);
          store.commit('general/setFeatureUnavailableMinPlan', featurePermission.minimumPlan);
          store.commit('general/setOnAuthorizedClickFunction', callbackAction);
        } else {
          await callbackAction();
        }
      } else {
        await callbackAction();
      }

      selectLoading.value[id] = false;
    };

    const onActionRemoveOperatorBtnClickedParent = (operatorId: string, type: ActionsDialogRemoveOperator) => {
      handleBackupOperatorConfigStatus(() => {
        onActionRemoveOperatorBtnClicked(operatorId, type);
      });
    };

    const onConnectorMerge = (id: string, type: string, connector: OperatorInput|OperatorOutput) => {
      mergeConnector(id, type, connector);
    };

    const onBackOperatorSelectorStep = (operatorEditForm: boolean) => {
      backOperatorSelect(operatorEditForm);
    };

    // Affichage du panneau d'historique sur la gauche
    const showHistoryPanel = () => {
      if (state.leftToolbar.show.historyPanel) {
        resetLeftToolbar();
      } else {
        handleBackupOperatorConfigStatus(() => {
          resetSelectedOperator();
          resetDefaultDisplayUrl();
          showToolTab(ToolTab.HISTORY_PANEL);
        });
      }
    };

    const showSettingsPanel = () => {
      if (state.leftToolbar.show.settingsPanel) {
        resetLeftToolbar();
      } else {
        handleBackupOperatorConfigStatus(() => {
          resetSelectedOperator();
          resetDefaultDisplayUrl();
          showToolTab(ToolTab.SETTINGS_PANEL);
        });
      }
    };

    // Undo sur l'historique
    const previousHistory = () => {
      if (currentHistoryIndex.value < maxHistoryIndex.value) {
        setActiveHistory(currentHistoryIndex.value + 1);
      }
    };

    // Redo sur l'historique
    const nextHistory = () => {
      if (currentHistoryIndex.value > 0) {
        setActiveHistory(currentHistoryIndex.value - 1);
      }
    };

    const labelCurrentZoom = computed(() => state.scenario.currentZoom);

    const zoomOut = () => {
      const indexCurrentZoom = availableFlowchartZoom.indexOf(state.scenario.currentZoom);
      const newZoom = availableFlowchartZoom[indexCurrentZoom - 1] ?? availableFlowchartZoom[indexCurrentZoom];
      state.$flowchart.flowchart('setPositionRatio', newZoom);

      state.$flowchart.panzoom('zoom', newZoom, {
        animate: false,
        contain: false,
      });

      setScenarioCurrentZoom(newZoom);
    };

    const zoomIn = () => {
      const indexCurrentZoom = availableFlowchartZoom.indexOf(state.scenario.currentZoom);
      const newZoom = availableFlowchartZoom[indexCurrentZoom + 1] ?? availableFlowchartZoom[indexCurrentZoom];
      state.$flowchart.flowchart('setPositionRatio', newZoom);

      state.$flowchart.panzoom('zoom', newZoom, {
        animate: false,
        contain: false,
      });

      setScenarioCurrentZoom(newZoom);
    };

    const zoomInit = () => {
      const newZoom = 1;
      state.$flowchart.flowchart('setPositionRatio', newZoom);

      state.$flowchart.panzoom('zoom', newZoom, {
        animate: false,
        contain: false,
      });

      setScenarioCurrentZoom(newZoom);
    };

    const zoomCenter = () => {
      centerZoom();
    };

    const checkIfScenarioContainsInconsistency = (): boolean => {
      let containWarning = false;
      state.operators.forEach((operator) => {
        /* Check each operator of the scenario if it contains an inconsistency. */
        const warningOp = document.querySelectorAll(`${operator.targetDiv} .display-box-container-icon-outside
            .icon-warning`);
        if (warningOp.length) {
          containWarning = true;
          return false;
        }
        return true;
      });
      return containWarning;
    };

    const handleEditorUnload = async (event: any) => {
      if (!checkBackupStatusOfTheCurrentOperatorConfig()) {
        event.preventDefault();

        event.returnValue = '';
        return null;
      }
    }

    /*
    * START
    * Save & Duplicate campaign
    * */

    const saveCampaignAutomation = (rawFlowchartData: any, quitCampaign: boolean, newStatus: MarketingWorkflowStatusEnum|CampaignsBulkStatusEnum|null) => {
      const workflowSaveInput: ShopMarketingWorkflowsSaveInputItem = {
        id_marketing_workflow: state.scenario.id,
        id_shop: idShop,
        name: (state.scenario.settingsData as GlobalSettingsData).name,
        status: newStatus ?? '',
        data_workflow: JSON.stringify(rawFlowchartData),
        type_workflow: (state.scenario.settingsData as GlobalSettingsData).type_workflow,
        unique: (state.scenario.settingsData as GlobalSettingsData).unique,
        type_unique: (state.scenario.settingsData as GlobalSettingsData).type_unique,
        type_retrigger: (state.scenario.settingsData as GlobalSettingsData).type_retrigger,
        trigger_unit: (state.scenario.settingsData as GlobalSettingsData).time_unit_select.unit,
        trigger_time: (state.scenario.settingsData as GlobalSettingsData).time_unit_select.value,
        programmed_date_range: (state.scenario.settingsData as GlobalSettingsData).program_date_ranges ?? 0,
        programmed_date_range_value: (state.scenario.settingsData as GlobalSettingsData).date_ranges ?? '[]',
      };

      saveWorkflow(workflowSaveInput).then((result) => {
        if (result.success && result.id) {
          showToastSuccess(t('campaigns.common.saveDialog.successSave'));
          setRefreshScenariosList(true);
          if (quitCampaign) {
            window.removeEventListener('beforeunload', handleEditorUnload);
            window.removeEventListener('unload', onEditorUnload);
            deactivateCampaign(state.scenario.id);
          }
        } else {
          showToastError(t('campaigns.common.saveDialog.errorSave'));
        }
        store.commit('general/hideTheSpinner');
      }).catch(() => {
        showToastError(t('campaigns.common.saveDialog.errorSave'));
        store.commit('general/hideTheSpinner');
      });
    };

    const saveCampaignBulk = (rawFlowchartData: any, quitCampaign: boolean, newStatus: MarketingWorkflowStatusEnum|CampaignsBulkStatusEnum|null) => {
      // get the time sending if set
      const timeSending = (state.scenario.settingsData as GlobalBulkSettingsData).time_sending;

      const dateSend = ((newStatus as CampaignsBulkStatusEnum) === CampaignsBulkStatusEnum.SCHEDULED)
        ? timeSending : null;

      const perfectTiming = (state.scenario.settingsData as GlobalBulkSettingsData).perfect_timing;
      const { slowdown } = state.scenario.settingsData as GlobalBulkSettingsData;

      const dataCampaign: any = {
        perfect_timing: perfectTiming,
        slowdown: perfectTiming === 0 ? (state.scenario.settingsData as GlobalBulkSettingsData).slowdown : 0,
        commercial_campaign: (state.scenario.settingsData as GlobalBulkSettingsData).commercial_campaign ?? 1,
      };

      dataCampaign.interval_between_sends = (slowdown === 1)
        ? (state.scenario.settingsData as GlobalBulkSettingsData).interval_between_sends : '15';

      const campaignBulkSaveInput: CampaignsBulkSaveInputItem = {
        id_campaign_bulk: state.scenario.id,
        id_shop: idShop,
        name: state.scenario.settingsData.name ?? newListName.value,
        status: newStatus ?? CampaignsBulkStatusEnum.DRAFT,
        data_campaign: JSON.stringify(dataCampaign),
        data_flowchart: JSON.stringify(rawFlowchartData),
        date_send: dateSend,
      };

      saveBulkCampaign(campaignBulkSaveInput).then((result) => {
        if (result.success && result.id) {
          showToastSuccess(t('campaigns.common.saveDialog.successSave'));
          setRefreshScenariosList(true);
          if (quitCampaign) {
            window.removeEventListener('beforeunload', handleEditorUnload);
            window.removeEventListener('unload', onEditorUnload);
            deactivateCampaign(state.scenario.id);
          }
        } else {
          showToastError(t('campaigns.common.saveDialog.errorSave'));
        }
        store.commit('general/hideTheSpinner');
      }).catch(() => {
        showToastError(t('campaigns.common.saveDialog.errorSave'));
        store.commit('general/hideTheSpinner');
      });
    };

    const confirmSaveCampaign = (quitCampaign: boolean, newStatus: MarketingWorkflowStatusEnum|CampaignsBulkStatusEnum|null) => {
      store.commit('general/showTheSpinner');
      /* Prepare scenario data for stringify */
      const rawFlowchartData: any = deepcopy(toRaw(state.scenario.data));
      cleanOperators(rawFlowchartData);

      if (state.scenario.type === TypeCampaignEnum.AUTOMATION) {
        saveCampaignAutomation(rawFlowchartData, quitCampaign, newStatus);
      } else if (state.scenario.type === TypeCampaignEnum.BULK) {
        saveCampaignBulk(rawFlowchartData, quitCampaign, newStatus);
        showModalScheduledBulkCampaign.value = false;
      }
    };

    const validateSettingsFormData = async (newStatus: any = null) => {
      let v$;
      let success;
      if (state.scenario.type === TypeCampaignEnum.AUTOMATION) {
        const rulesAutomation = {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(300),
          },

          type_workflow: {
            includesValuesValidator: includesValuesValidator([MarketingWorkflowTypeEnum.MARKETING, MarketingWorkflowTypeEnum.TRANSACTIONAL]),
          },

          unique: {
            includesValuesValidator: includesValuesValidator([0, 1]),
          },

          type_unique: {
            includesValuesValidator: includesValuesValidator([0, 1]),
          },

          type_retrigger: {
            includesValuesValidator: includesValuesValidator([0, 1]),
          },

          time_unit_select: {
            value: {
              required,
              integer,
              between: between(1, 1000),
            },

            unit: {
              required,
              includesValuesValidator: includesValuesValidator([
                'MINUTE',
                'HOUR',
                'DAY',
                'MONTH',
              ]),
            },
          },

          program_date_ranges: {
            includesValuesValidator: includesValuesValidator([0, 1]),
          },
        };
        v$ = useVuelidate(rulesAutomation, (state.scenario.settingsData as GlobalSettingsData));
      } else if (state.scenario.type === TypeCampaignEnum.BULK) {
        const campaignContainMediaWithVoucher = await checkIfCampaignContainsVouchers();

        const rulesBulk: any = {
          name: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(300),
          },

          commercial_campaign: {
            verifyCommercialUseCampaign: verifyCommercialUseCampaign(campaignContainMediaWithVoucher),
          },
        };

        if ((newStatus as CampaignsBulkStatusEnum) === CampaignsBulkStatusEnum.SCHEDULED) {
          rulesBulk.time_sending = {
            required,
          };
          rulesBulk.perfect_timing = {
            includesValuesValidator: includesValuesValidator([0, 1]),
          };
          rulesBulk.slowdown = {
            includesValuesValidator: includesValuesValidator([0, 1]),
          };

          if ((state.scenario.settingsData as GlobalBulkSettingsData).perfect_timing === 0
            && (state.scenario.settingsData as GlobalBulkSettingsData).slowdown === 1) {
            rulesBulk.interval_between_sends = {
              required,
              includesValuesValidator: includesValuesValidator([
                '15',
                '30',
                '60',
                '90',
                '120',
                '150',
                '180',
                '210',
                '240',
                '270',
                '300',
              ]),
            };
          }
        }

        /* Verification of scenario parameters */
        v$ = useVuelidate(rulesBulk, (state.scenario.settingsData as GlobalBulkSettingsData));
      }

      if (typeof v$ !== 'undefined') {
        success = await v$.value.$validate();
      }

      return {
        success,
        v$,
      };
    };

    const saveCampaignConfirmationDialog = async (quitCampaign: boolean, newStatus: MarketingWorkflowStatusEnum|CampaignsBulkStatusEnum|null) => {
      const { success, v$ } = await validateSettingsFormData(newStatus);
      if (!success && typeof v$ !== 'undefined') {
        /* If the scenario parameters form contains errors, we return them */
        settingPanelValidationErrors.value = v$;
        await showToastError(t('campaigns.common.saveDialog.errorFormSettings'));
        if (!state.leftToolbar.show.settingsPanel) {
          /* If the parameter panel is closed, we will open it */
          showSettingsPanel();
        }
      } else if (checkIfScenarioContainsInconsistency()) {
        confirm.require({
          group: 'operatorEditForm',
          message: t('campaigns.common.saveDialog.inconsistencies.message'),
          header: 'Confirmation',
          icon: 'far fa-exclamation-triangle',
          acceptLabel: t('campaigns.common.saveDialog.inconsistencies.saveDraft'),
          rejectLabel: t('campaigns.common.saveDialog.inconsistencies.fixProblems'),
          accept: () => {
            confirmSaveCampaign(quitCampaign, MarketingWorkflowStatusEnum.DRAFT);
          },
        });
      } else {
        confirmSaveCampaign(quitCampaign, newStatus);
      }
    };

    const closeDuplicationModal = () => {
      showDuplicationModal.value = false;
    };

    const confirmDuplicateCampaign = async (name: string) => {
      showDuplicationModal.value = false;
      newListName.value = name;
      if (scenarioType.value === TypeCampaignEnum.AUTOMATION) {
        const workflowToDuplicateInput: ShopMarketingWorkflowsDuplicateInputItem = {
          id_marketing_workflow: scenarioId.value,
          id_shop_destination: idShop,
          name,
        };
        duplicateWorkflow(workflowToDuplicateInput).then((result) => {
          if (result.success && result.id) {
            showToastSuccess(t('campaigns.automation.list.actions.duplicate.success'));
            setRefreshScenariosList(true);
            isLoading.value = true;
            minimizeEditorCampaign();
            display();
            activateCampaign(result.id, TypeCampaignEnum.AUTOMATION).then(() => {
              display();
            }).catch(() => {
              showToastError(t('errorMessages.GENERIC_ERROR'));
            }).finally(() => {
              isLoading.value = false;
            });
          } else {
            showToastError(t('campaigns.automation.list.actions.duplicate.error'));
          }
        }).catch(() => {
          showToastError(t('campaigns.automation.list.actions.duplicate.error'));
        });
      } else if (scenarioType.value === TypeCampaignEnum.BULK) {
        const campaignBulkDuplicateInput: CampaignsBulkDuplicateInputItem = {
          id_campaign_bulk: scenarioId.value,
          id_shop_destination: idShop,
          base_campaign: 0,
          name,
        };
        duplicateBulkCampaign(campaignBulkDuplicateInput)
          .then((result) => {
            if (result.success && result.id) {
              showToastSuccess(t('campaigns.bulk.list.actions.duplicate.success'));
              setRefreshScenariosList(true);
              isLoading.value = true;
              minimizeEditorCampaign();
              display();
              activateCampaign(result.id, TypeCampaignEnum.BULK)
                .then(() => {
                  display();
                })
                .catch(() => {
                  showToastError(t('errorMessages.GENERIC_ERROR'));
                })
                .finally(() => {
                  isLoading.value = false;
                });
            } else {
              showToastError(t('campaigns.bulk.list.actions.duplicate.error'));
            }
          })
          .catch(() => {
            showToastError(t('campaigns.bulk.list.actions.duplicate.error'));
          });
      }
    };

    /*
    * END
    * Save & Duplicate campaign
    * */

    const toolbarRightButtons = computed(() => {
      let itemsBtnSave: PrimvueMenuModel[] = [];
      if (state.scenario.type === TypeCampaignEnum.AUTOMATION) {
        itemsBtnSave = [
          {
            label: t('automatedScenarios.headerSaveButton.saveAndLeave'),
            command: () => {
              handleBackupOperatorConfigStatus(() => {
                store.commit('general/showTheSpinner');
                saveCampaignConfirmationDialog(true, null);
                store.commit('general/hideTheSpinner');
              }, false);
            },
          },
          {
            label: t('automatedScenarios.headerSaveButton.save'),
            command: () => {
              handleBackupOperatorConfigStatus(() => {
                store.commit('general/showTheSpinner');
                saveCampaignConfirmationDialog(false, null);
                store.commit('general/hideTheSpinner');
              }, false);
            },
          },
          {
            label: t('automatedScenarios.headerSaveButton.minimize'),
            command: () => handleLeaving(() => minimizeEditorCampaign(true)),
          },
          {
            label: t('automatedScenarios.headerSaveButton.leaveWithoutSaving'),
            command: () => {
              handleLeaving(() => {
                const id = state.scenario?.id ?? 0;
                if (id !== 0) {
                  deactivateCampaign(id);
                  if (state.automationCampaigns.length) {
                    const indexNextCampaign = state.automationCampaigns.length - 1;
                    isLoading.value = true;
                    activateCampaign(state.automationCampaigns[indexNextCampaign].scenario.id, TypeCampaignEnum.AUTOMATION).then(() => {
                      display();
                    }).catch(() => {
                      showToastError(t('errorMessages.GENERIC_ERROR'));
                    }).finally(() => {
                      isLoading.value = false;
                    });
                  }
                }
              });
            },
          },
        ];
      } else if ((state.scenario.type === TypeCampaignEnum.BULK)) {
        itemsBtnSave = [
          {
            label: t('automatedScenarios.headerSaveButton.saveAndLeave'),
            command: () => {
              store.commit('general/showTheSpinner');
              getBulkCampaignStatus(state.scenario.id).then(async (currentStatus: any) => {
                // protect the campaign from being saved if it is already sent or sending
                if (currentStatus && [CampaignsBulkStatusEnum.SENT, CampaignsBulkStatusEnum.SENDING].includes(currentStatus)) {
                  showToastError(t('errorMessages.GENERIC_ERROR'));
                  store.commit('general/hideTheSpinner');
                  return;
                }
                handleBackupOperatorConfigStatus(() => {
                  store.commit('general/showTheSpinner');
                  saveCampaignConfirmationDialog(true, CampaignsBulkStatusEnum.DRAFT);
                  store.commit('general/hideTheSpinner');
                }, false);
              });
            },
          },
          {
            label: t('automatedScenarios.headerSaveButton.minimize'),
            command: () => handleLeaving(() => minimizeEditorCampaign(true)),
          },
          {
            label: t('automatedScenarios.headerSaveButton.leaveWithoutSaving'),
            command: async () => {
              handleLeaving(async () => {
                store.commit('general/showTheSpinner');
                getBulkCampaignStatus(state.scenario.id).then(async (currentStatus: any) => {
                  // protect the campaign from being saved if it is already sent or sending
                  if (currentStatus && [CampaignsBulkStatusEnum.SENT, CampaignsBulkStatusEnum.SENDING].includes(currentStatus)) {
                    showToastError(t('errorMessages.GENERIC_ERROR'));
                    store.commit('general/hideTheSpinner');
                    return;
                  }
                  await updateCampaignBulk([{
                    id_campaign_bulk: state.scenario.id,
                    id_shop: UserState.activeShop?.id ?? 0,
                    status: CampaignsBulkStatusEnum.DRAFT,
                    date_send: null,
                    date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
                  }]).then(() => {
                    setRefreshScenariosList(true);
                    deactivateCampaign(state.scenario.id);
                    store.commit('general/hideTheSpinner');
                  }).catch(() => {
                    showToastError(t('errorMessages.GENERIC_ERROR'));
                    store.commit('general/hideTheSpinner');
                  });
                });
              });
            },
          },
          {
            separator: true,
          },
          {
            label: t('automatedScenarios.headerSaveButton.scheduleCampaign'),
            command: () => {
              store.commit('general/showTheSpinner');
              getBulkCampaignStatus(state.scenario.id).then(async (currentStatus: any) => {
                // protect the campaign from being scheduled if it is already sent or sending
                if (currentStatus && [CampaignsBulkStatusEnum.SENT, CampaignsBulkStatusEnum.SENDING].includes(currentStatus)) {
                  showToastError(t('errorMessages.GENERIC_ERROR'));
                  store.commit('general/hideTheSpinner');
                  return;
                }
                const continueSchedule = () => {
                  const doesCampaignContainMediaBoxes = checkIfCampaignContainsMediaBoxes();
                  if (!doesCampaignContainMediaBoxes) {
                    showToastError(t('campaigns.bulk.messages.noMediaBoxes'), 8000);
                    return;
                  }
                  const settingsFields: GlobalBulkSettingsData = {
                    ...(state.scenario.settingsData as GlobalBulkSettingsData),
                    time_sending: '',
                  };
                  setGlobalSettingsData(settingsFields);
                  showModalScheduledBulkCampaign.value = true;
                };
                handleBackupOperatorConfigStatus(async () => {
                  // Check if shop has old bundle with no newsletter bundle in case of boxsendemail or boxsendpushnotification
                  const hasBoxSendEmail = checkIfCampaignContainsMediaBoxes('boxsendemail');
                  const hasBoxSendPush = checkIfCampaignContainsMediaBoxes('boxsendpushnotification');
                  const hasBoxSendSms = checkIfCampaignContainsMediaBoxes('boxsendsms');

                  let insufficientSms = false;
                  if (hasBoxSendSms) {
                    let smsCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'plan_sms', true);
                    if (!smsCredits || smsCredits === 0) {
                      smsCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'smsV4', true);
                    }
                    if (!smsCredits || smsCredits === 0) {
                      smsCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'sms', true);
                    }
                    insufficientSms = smsCredits === 0;
                  }

                  let insufficientEmail = false;
                  if (hasBoxSendEmail) {
                    if (!UserState.activeOffer) {
                      if (UserState.hasOfferV3 && UserState.offerV3) {
                        if (!['2', '3'].includes(UserState.offerV3.bundle_options)) {
                          insufficientEmail = true;
                        } else {
                          let maxNewsletter = UserState.offerV3.max_newsletter;
                          if (!maxNewsletter) {
                            const { items } = await List<Bundles>({
                              name: 'Bundles',
                              settings: {
                                offset: 0,
                                limit: 1,
                                order: [],
                                filter: [
                                  { field: 'id_bundle', value: UserState.offerV3.id_bundle_newsletter, operator: OperatorType.Equals },
                                ],
                              },
                              fields: ['number_email_newsletter_max'],
                            });
                            if (items && items.length) {
                              maxNewsletter = items[0].number_email_newsletter_max;
                            }
                          }

                          if (maxNewsletter && UserState.offerV3.nb_sends_newsletter >= maxNewsletter) {
                            insufficientEmail = true;
                          }
                        }
                      } else {
                        insufficientEmail = true;
                      }
                    }

                    if (insufficientEmail) {
                      const newsletterCredits = await getShopsCredits(UserState.activeShop?.id ?? 0, 'newsletter', true);
                      if (newsletterCredits && newsletterCredits > 0) {
                        insufficientEmail = false;
                      }
                    }
                  }

                  // Block scheduling if not sufficient offer/credits
                  if (insufficientEmail || insufficientSms) {
                    confirm.require({
                      group: 'noSufficientOffer',
                      message: `${t('automatedScenarios.insufficientOffer')}${insufficientEmail
                        ? t('automatedScenarios.insufficientOfferEmail') : ''}${insufficientSms ? t('automatedScenarios.insufficientOfferSms') : ''}`,
                      header: t('attention'),
                      icon: 'far fa-exclamation-triangle',
                      acceptLabel: t('offers.updatePlanButton'),
                      rejectLabel: t('cancel'),
                      accept: async () => {
                        confirmSaveCampaign(true, MarketingWorkflowStatusEnum.DRAFT);
                        await router.push({ name: 'shop.MyOffer' });
                      },
                    });
                  } else {
                    continueSchedule();
                  }

                  store.commit('general/hideTheSpinner');
                }, false);
              });
            },
          },
        ];
      }

      let itemBtnSave: PrimvueMenuModel = {
        label: t('automatedScenarios.headerSaveButton.save'),
        class: 'p-button-secondary',
        items: itemsBtnSave,
      };

      if (state.scenario.type === TypeCampaignEnum.BULK && state.scenario.visualisationMode) {
        itemBtnSave = {
          label: t('Fermer'),
          class: 'p-button-secondary',
          command: () => {
            deactivateCampaign(state.scenario.id);
          },
        };
      }
      return [itemBtnSave];
    });

    /* Display of quick navigation buttons depending on the type of campaign being edited. */
    const tabs = computed(() => {
      if (state.scenario.type === TypeCampaignEnum.AUTOMATION) {
        return state.automationCampaigns.map(
          (s): PrimvueMenuModel => {
            const isActiveCampaign = (s.scenario.id === state.scenario?.id);
            return {
              tooltip: s.scenario.settingsData.name,
              label: s.scenario.settingsData.name,
              class: `${isActiveCampaign ? 'p-button-success' : 'p-button-secondary'} footer-btn overflow-ellipsis`,
              command: !isActiveCampaign ? () => {
                isLoading.value = true;
                minimizeEditorCampaign();
                activateCampaign(s.scenario.id, TypeCampaignEnum.AUTOMATION).then(() => {
                  display();
                }).catch(() => {
                  showToastError(t('errorMessages.GENERIC_ERROR'));
                }).finally(() => {
                  isLoading.value = false;
                });
              } : undefined,
              items: [
                {
                  label: t('templateBuilder.duplicate'),
                  command: () => {
                    if (s.scenario.id !== 0) {
                      scenarioId.value = s.scenario.id;
                      scenarioType.value = TypeCampaignEnum.AUTOMATION;
                      newListName.value = t('myLists.manage.actions.cloneText') + state.scenario.settingsData.name;
                      showDuplicationModal.value = true;
                    }
                  },
                },
                {
                  label: t('templateBuilder.close'),
                  command: () => {
                    const id = state.scenario?.id ?? 0;
                    if (id !== 0) {
                      deactivateCampaign(id);
                      if (state.automationCampaigns.length) {
                        const indexNextCampaign = state.automationCampaigns.length - 1;
                        isLoading.value = true;
                        activateCampaign(state.automationCampaigns[indexNextCampaign].scenario.id, TypeCampaignEnum.AUTOMATION).then(() => {
                          display();
                        }).catch(() => {
                          showToastError(t('errorMessages.GENERIC_ERROR'));
                        }).finally(() => {
                          isLoading.value = false;
                        });
                      }
                    }
                  },
                },
              ],
            };
          },
        );
      }

      return state.bulkCampaigns.map(
        (s): PrimvueMenuModel => {
          const isActiveCampaign = (s.scenario.id === state.scenario?.id);
          return {
            label: s.scenario.settingsData.name,
            class: `${isActiveCampaign ? 'p-button-primary' : 'p-button-secondary'} footer-btn`,
            command: !isActiveCampaign ? () => {
              isLoading.value = true;
              minimizeEditorCampaign();
              display();
              activateCampaign(s.scenario.id, TypeCampaignEnum.BULK).then(() => {
                display();
              }).catch(() => {
                showToastError(t('errorMessages.GENERIC_ERROR'));
              }).finally(() => {
                isLoading.value = false;
              });
            } : undefined,
            items: [
              {
                label: t('templateBuilder.duplicate'),
                command: () => {
                  if (s.scenario.id !== 0) {
                    scenarioId.value = s.scenario.id;
                    scenarioType.value = TypeCampaignEnum.BULK;
                    newListName.value = `${t('campaignPrefix')} - ${getNowFormattedByTimezone()}`;
                    showDuplicationModal.value = true;
                  }
                },
              },
              {
                label: t('templateBuilder.close'),
                command: async () => {
                  const id = state.scenario?.id ?? 0;
                  const currentStatus = await getBulkCampaignStatus(id);

                  if (id !== 0) {
                    if (currentStatus !== CampaignsBulkStatusEnum.SENT
                      && currentStatus !== CampaignsBulkStatusEnum.SENDING) {
                      await updateCampaignBulk([{
                        id_campaign_bulk: state.scenario.id,
                        id_shop: UserState.activeShop?.id ?? 0,
                        status: CampaignsBulkStatusEnum.DRAFT,
                        date_send: null,
                        date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
                      }]).then(() => {
                        setRefreshScenariosList(true);
                        deactivateCampaign(id);
                        if (state.bulkCampaigns.length) {
                          const indexNextCampaign = state.bulkCampaigns.length - 1;
                          activateCampaign(state.bulkCampaigns[indexNextCampaign].scenario.id, TypeCampaignEnum.BULK);
                          display();
                        }
                      }).catch(() => {
                        showToastError(t('errorMessages.GENERIC_ERROR'));
                      });
                    } else {
                      deactivateCampaign(id);
                    }
                  }
                },
              },
            ],
          };
        },
      );
    });

    const contentHeaderStartToolbar = computed(() => ([
      {
        icon: 'far fa-fw fa-cog',
        label: t('automatedScenarios.toolbar.label.settings'),
        tooltip: t('automatedScenarios.toolbar.tooltip.settings'),
        class: () => (state.leftToolbar.show.settingsPanel ? 'active' : ''),
        command: () => showSettingsPanel(),
        hide: state.scenario.visualisationMode,
      },
      {
        multipleType: 'buttons',
        items: [
          {
            icon: 'far fa-fw fa-clock',
            tooltip: t('automatedScenarios.history.showPanel'),
            class: () => (state.leftToolbar.show.historyPanel ? 'active' : ''),
            command: () => showHistoryPanel(),
          },
          {
            icon: 'far fa-fw fa-undo',
            tooltip: t('automatedScenarios.history.undo'),
            class: () => (currentHistoryIndex.value === maxHistoryIndex.value ? 'disabled' : ''),
            command: () => previousHistory(),
          },
          {
            icon: 'far fa-fw fa-redo',
            tooltip: t('automatedScenarios.history.redo'),
            class: () => (currentHistoryIndex.value <= 0 ? 'disabled' : ''),
            command: () => nextHistory(),
          },
        ],
        hide: state.scenario.visualisationMode,
      },
      {
        multipleType: 'buttons',
        items: [
          {
            icon: 'far fa-fw fa-search-minus',
            tooltip: t('automatedScenarios.toolbar.tooltip.zoomOut'),
            class: () => (state.scenario.currentZoom <= availableFlowchartZoom[0] ? 'disabled' : ''),
            command: () => zoomOut(),
          },
          {
            label: `${labelCurrentZoom.value * 100}%`,
            class: 'disabled',
          },
          {
            icon: 'far fa-fw fa-search-plus',
            tooltip: t('automatedScenarios.toolbar.tooltip.zoomIn'),
            class: () => (state.scenario.currentZoom >= availableFlowchartZoom[availableFlowchartZoom.length - 1] ? 'disabled' : ''),
            command: () => zoomIn(),
          },
          {
            icon: 'far fa-fw fa-sync-alt',
            tooltip: t('automatedScenarios.toolbar.tooltip.zoomInit'),
            command: () => zoomInit(),
          },
          {
            icon: 'far fa-fw fa-crosshairs',
            tooltip: t('automatedScenarios.toolbar.tooltip.zoomCenter'),
            command: () => zoomCenter(),
          },
        ],
      },
    ]));

    const contentHeaderEndToolbar = computed(() => ([
      {
        icon: 'fa-regular fa-calendar',
        btnType: 'calendar',
        label: t(`customDatePicker.${state.scenario.analyticsMode.dateInterval}`),
        data: '',
        command: (data: AutomatedScenarioAnalyticsDateInterval) => {
          updateAnalyticsDateInterval(data).then(() => {
            if (state.scenario.analyticsMode.show && (state.scenario.analyticsMode.data === null || Object.keys(state.scenario.analyticsMode.data).length === 0)) {
              // display toasts message error
              showToastError(t('campaigns.common.operatorAnalytics.noAnalyticsForThisInterval'));
            }
          });
        },
        hide: !state.scenario.analyticsMode.show || (state.scenario.type === TypeCampaignEnum.BULK && !state.scenario.visualisationMode),
        defaultValue: (state.scenario.type === TypeCampaignEnum.BULK ? IntervalNextDateEnum.NEXT_30_DAYS : IntervalDateEnum.LAST_30_DAYS),
        customOptions: Object.values((state.scenario.type === TypeCampaignEnum.BULK ? IntervalNextDateEnum : IntervalDateEnum)),
      },
      {
        icon: 'fa-regular fa-chart-pie-simple',
        label: t('campaigns.common.editForm.analytics'),
        class: () => (state.scenario.analyticsMode.show ? 'active' : ''),
        command: () => {
          setAnalyticsMode(!state.scenario.analyticsMode.show).then(() => {
            if (state.scenario.analyticsMode.show && (state.scenario.analyticsMode.data === null || Object.keys(state.scenario.analyticsMode.data).length === 0)) {
              // display toasts message error
              showToastError(t('campaigns.common.operatorAnalytics.noAnalyticsForThisInterval'));
            }
          });
        },
        hide: (state.scenario.type === TypeCampaignEnum.BULK && !state.scenario.visualisationMode),
      },
    ]));

    const onHideModalScheduledBulkCampaign = () => {
      showModalScheduledBulkCampaign.value = false;
    };

    const onSaveModalScheduledBulkCampaign = async () => {
      await saveCampaignConfirmationDialog(true, CampaignsBulkStatusEnum.SCHEDULED);
    };

    const selectedTemplateType = ref('');
    const operatorIdToSendTestMessage = ref('');
    const showSendTestModal = ref(false);

    const handleSendTestMessage = (operatorId: string, templateType: string) => {
      selectedTemplateType.value = templateType;
      operatorIdToSendTestMessage.value = operatorId;
      showSendTestModal.value = true;
      store.commit('general/showSendTestModal');
    };

    const sendTestTemplate = async (params: Suggestion[]) => {
      if (selectedTemplateType.value && operatorIdToSendTestMessage.value) {
        store.commit('general/showTheSpinner');

        // Get operator data to send subject, and from information
        const operatorData = state.scenario.getOperatorData<any>(operatorIdToSendTestMessage.value);

        // Send query to NestJS
        const getData: Record<string, any> = {
          type: selectedTemplateType.value,
          params,
        };

        if (selectedTemplateType.value === TemplateTypeEnum.EMAIL || selectedTemplateType.value === TemplateTypeEnum.SMS) {
          getData.informations = {
            fromName: operatorData.template_message_expname,
          };

          if (selectedTemplateType.value === TemplateTypeEnum.EMAIL) {
            getData.informations.fromEmail = operatorData.template_message_expmail;
            getData.informations.subject = operatorData.template_message_subject;
          }
        }

        const dataQuery = btoa(encodeURIComponent(JSON.stringify(getData)));
        const result = await nestGet(
          'v4',
          `/template/test/${state.scenario.data.operators[operatorIdToSendTestMessage.value].custom.template_display_selector_id}/${dataQuery}`,
          {},
          '',
        );

        if (result && result.success) {
          if (selectedTemplateType.value === TemplateTypeEnum.EMAIL) {
            await showToastSuccess('emailSent');
          } else if (selectedTemplateType.value === TemplateTypeEnum.SMS) {
            await showToastSuccess('smsSent');
          }
        } else {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        store.commit('general/hideTheSpinner');
        selectedTemplateType.value = '';
        operatorIdToSendTestMessage.value = '';
        showSendTestModal.value = false;
      }
    };

    onUpdated(async () => {
      adjustContainerSize();
    });

    let intervalUpdate: number | null = null;
    let observer: any;
    onMounted(async () => {
      // Convert text inside tooltip to html
      observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node) => {
              if (node instanceof Element && /_tooltip/.test(node.getAttribute('id') ?? '')) {
                const tooltip = node.querySelector('.p-tooltip-text');
                if (tooltip && tooltip.textContent) {
                  tooltip.innerHTML = tooltip.textContent;
                }
              }
            });
          }
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      const editingTemplateFromThisCampaign = getEditingTemplateFromCampaign();
      if (editingTemplateFromThisCampaign !== null && editingTemplateFromThisCampaign.idTemplate) {
        idTemplateEditingFromCampaign.value = editingTemplateFromThisCampaign.idTemplate;
      } else {
        idTemplateEditingFromCampaign.value = null;
      }
      await display();

      // set analytics data for the first time if available
      await setWorkflowAnalyticsData(idShop, state.scenario.type, state.scenario.id);

      window.addEventListener('beforeunload', handleEditorUnload);
      window.addEventListener('unload', onEditorUnload);
      window.addEventListener('keyup', (event: KeyboardEvent) => {
        if (event.key.toLowerCase() === 'z' && event.ctrlKey) {
          previousHistory();
        } else if (event.key.toLowerCase() === 'y' && event.ctrlKey) {
          nextHistory();
        }
      });
      intervalUpdate = window.setInterval(() => {
        const visualisationMode = state.scenario.visualisationMode ?? false;
        // update the date modification of the campaign only if it is not in visualisation mode
        if (!visualisationMode && state.scenario.type === TypeCampaignEnum.BULK) {
          const currentId = { ...state.scenario }.id;

          if (currentId > 0) {
            const dateModification = moment()
              .format('YYYY-MM-DD HH:mm:ss');

            updateDateModificationOfCurrentScenario(dateModification);

            updateCampaignBulk([{
              id_campaign_bulk: currentId,
              id_shop: UserState.activeShop?.id ?? 0,
              date_modification: dateModification,
            }], true);
          }
        }
      }, 5000);

      if (state.scenario && !state.scenario.history && !state.scenario.historyId) {
        await addHistory('check_point', 'automatedScenarios.firstState');
      }
    });

    onBeforeUnmount(() => {
      if (observer) {
        observer.disconnect();
      }
      saveCampaignsInLocalStorage();
      window.removeEventListener('beforeunload', handleEditorUnload);
      window.removeEventListener('unload', onEditorUnload);

      if (intervalUpdate) {
        window.clearInterval(intervalUpdate);
        intervalUpdate = null;
      }
    });

    return {
      t,
      state,
      isLoading,
      contentHeaderEndToolbar,
      flowchart,
      settingPanelValidationErrors,
      toolbarRightButtons,
      tabs,
      onReplaceClickedParent,
      onActionAddOperatorBtnClicked,
      onActionBranchMergeBtnClicked,
      onActionBranchClosureBtnClicked,
      onExchangeClicked,
      onOperatorSelect,
      onConnectorMerge,
      onBackOperatorSelectorStep,
      highlightOperator,
      unhighlightOperators,
      StepsOperatorSelector,
      contentHeaderStartToolbar,
      labelStartButton,
      onStartButtonClicked,
      idTemplateEditingFromCampaign,
      cancelEditingTemplateAction,
      continueEditingTemplateAction,
      TypeCampaignEnum,
      showModalScheduledBulkCampaign,
      onHideModalScheduledBulkCampaign,
      onSaveModalScheduledBulkCampaign,
      newListName,
      showDuplicationModal,
      closeDuplicationModal,
      confirmDuplicateCampaign,
      signalSaveOperatorEditFormComponent,
      actionAfterSaveOperatorEditFormComponent,
      onInputClickedParent,
      onOutputClickedParent,
      onActionRemoveOperatorBtnClickedParent,
      selectLoading,
      handleSendTestMessage,
      selectedTemplateType,
      sendTestTemplate,
      showSendTestModal,
    };
  },
});
</script>

<style lang="scss">
.configuring-box-sidebar .content-sidebar {
  overflow-y: initial;

  .panel-sidebar-automated-scenario {
    height: 100%;

    .p-toggleable-content {
      height: calc(100% - 52.5px);

      .p-panel-content {
        height: 100%;
        padding-bottom: 0;

        .configuration-wrapper {
          height: 100%;
          .configuration-wrapper-content {
            height: calc(100% - 52.5px);
            overflow-y: auto;
            scrollbar-width: thin;
          }

          .configuration-action-container {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
}
.blocked {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.flowcharter {
  flex-grow: 1;
  width: 100%;
  height: 100%
}
.flowcharter > div {
  overflow: visible;
}

.start-automated-scenario {
  text-align: center;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-box-container-output-control-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
}

.content-sidebar {
  border-right: 1px solid #dee2e6 !important;
}

.display-box-close-button {
  position: absolute;
  left: 270px;
  color: red;
  cursor: pointer;
}

.panel-sidebar-automated-scenario {
  .p-panel-header {
    border: none !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-radius: 0 !important;

    .panel-header-content {
      display: flex;
      align-items: center;
    }
  }

  .icons-header-panel {
    cursor: pointer;
    color: #607D8B;
  }
}

.historyIcon.disabled {
  color: #EFEFEF !important;
}

.p-confirm-dialog-message {
  white-space: pre-line;
  text-align: left;
}
</style>
