<template>
  <div class="flex align-items-center toolbar-items-container">
    <Button
      v-if="!menuActions.button.hide"
      v-tooltip.bottom="menuActions.button.tooltip"
      :label="menuActions.button.label"
      :icon="menuActions.button.icon"
      class="toolbar-button-item toolbar-button-dropdown"
      :class="typeof menuActions.button.class === 'function' ? menuActions.button.class() : menuActions.button.class"
      aria-haspopup="true"
      aria-controls="split_menu"
      @click="(e) => { ('command' in menuActions.button && menuActions.button.command !== undefined) ? menuActions.button.command() : toggleMenu(e, data.ID) }"
    >
      <span
        v-if="menuActions.button.hasOwnProperty('icon') && menuActions.button.icon"
        class="p-button-icon"
        :class="`${menuActions.button.icon} p-button-icon-${menuActions.button.iconPos ?? 'right'}`"
      />
      <span
        class="p-button-label"
      >
        {{ menuActions.button.label ?? '&nbsp;' }}
      </span>
      <span
        class="p-button-icon p-button-icon-right caret fas fa-caret-down"
      />
    </Button>

    <template v-if="actions.menuItems && actions.menuItems.length">
      <div class="toolbar-dropdown-container">
        <Menu
          :ref="el => { if (el) menuRefs[data.ID] = el}"
          :model="menuActions.menuItems"
          class="toolbarbuttons-submenu"
          popup
        >
          <template #item="{ item }">
            <Button
              v-if="typeof item.hide === 'function' ? item.hide(data): !item.hide"
              class="toolbar-button-item"
              style="color: black"
              :class="typeof item.class === 'function' ? item.class() : item.class ?? 'p-button-text'"
              :icon="item.icon ?? ''"
              :label="item.label ?? '&nbsp;'"
              @click="item.command(data)"
            />
          </template>
        </Menu>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType, Ref, ref,
} from 'vue';
import { MenuItemsModel, ToolbarItemsModel } from '@/types';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import Menu from 'primevue/menu';

export default defineComponent({
  name: 'MenuRenderer',

  components: {
    Button,
    Menu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    actions: {
      type: Object as PropType<MenuItemsModel>,
      required: false,
      default: () => ({
        menuItems: [],
      }),
    },

    data: {
      type: Object,
      required: true,
    },
  },

  setup(props: { actions: MenuItemsModel; data: any }) {
    const menuActions = computed(() => props.actions);

    const menuRefs: Ref<any []> = ref([]);

    const toggleMenu = (event: Event, index: string) => {
      if (menuRefs.value) {
        menuRefs.value[index as keyof object].toggle(event);
      }
    };
    return {
      menuActions, menuRefs, toggleMenu,
    };
  },

});
</script>

<style lang="css" scoped>
.p-button.hidden {
  display: none;
}
</style>
