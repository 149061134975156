import {
  Property,
  StringMap,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  addSmartListOriginalCode,
  getSmartListHtmlELementFromState,
  restoreSmartListsOriginalCode,
} from '@/composables/template-editor/TemplateEditor';
import { Maybe } from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  sanitizeTranslationMarkup,
  setTranslationInState,
  generateTranslations,
} from '@/components/template-builder/utils/translate';
import { i18n } from '@/i18n';
import { TRANSLATION_MARKUP } from '@/components/template-builder/utils/constants';

// eslint-disable-next-line import/no-cycle
import {
  setHtmlContent,
} from '@/components/template-builder/setters';

// eslint-disable-next-line import/no-cycle
import { addOrUpdateDynamicStyle } from '@/components/template-builder/utils/parser';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

let translator: any;
(async () => {
  translator = await i18n;
})();

/**
 * Set specific smart list property from original HTML code
 * @param selector
 * @param property
 * @param widgetId
 */
export const setSmartListProperty = (selector: string, property: Property, widgetId: string) => {
  let element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  if (element) {
    const pattern = new RegExp(`s-${property.name}="([^"]*)"`, 'is');

    if (pattern.test(element)) {
      element = element.replace(pattern, `s-${property.name}="${property.value}"`);
    } else {
      // We add the property if it doesn't exist
      element = element.replace('s-engine=', `s-${property.name}="${property.value}" s-engine=`);
    }

    addSmartListOriginalCode(widgetId, element);
  }
};

export const setSmartListHtmlCode = (selector: string, property: Property, widgetId: string) => {
  setHtmlContent(selector, property, widgetId);
};

const layouts: StringMap = {
  horizontal_1: `<tbody>
  <tr>
    <th>
      <table class="row spm_title_wrapper spm_product_list_title_wrapper spm_responsive">
        <tbody>
          <tr>
            <th><h1 class="spm_product_list_title spm_inline_editing">{PRODUCT_LIST_TITLE}</h1></th>
          </tr>
        </tbody>
      </table>
      <table class="row spm_product_list spm_product_list_horizontal_1">
        <tr>
          <td>
            <table class="columns spm_product_list_element_container">
              <tr class="spm_product_list_element_row">
                <th class="column spm_product_list_element" style="width: 100%;"
                s-collection="products" s-item="product" s-grid="1" s-nb="4" s-engine="crossselling"
                s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
                  <h4 class="spm_product_list_element_title"><a href="{var=product.url}" title="{var=product.name}">{var=product.name truncate="50"}</a></h4>
                  <p class="spm_product_list_element_image_wrapper" style="text-align: center; margin: 0; padding: 0;">
                    <a href="{var=product.url}">
                      <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
                      width="200" class="spm_product_list_element_image" alt="{var=product.name}" title="{var=product.name}" />
                    </a>
                  </p>
                  <table class="spm_product_list_element_description spm_hide_on_builder" style="height: 70px;"
                  height="70" cellpadding="0" cellspacing="0" border="0" width="100%">
                    <tr>
                      <td><p>{var=product.description_short truncate="100"}</p></td>
                    </tr>
                  </table>
                  <table class="row">
                    <tr>
                      <td>
                        <table class="columns spm_product_list_element_prices">
                          <tr>
                            <th s-if="product.price_strike" s-condition="!=" s-value="product.price"
                            class="column" align="center" valign="middle"><p class="spm_product_list_element_price_strike">{var=product.price_strike}</p></th>
                            <th class="column" align="center" valign="middle"><p class="spm_product_list_element_price">{var=product.price}</p></th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table class="small-button button spm_product_list_element_button">
                    <tr>
                      <th><a href="{var=product.url}" title="{PRODUCT_BUTTON_LABEL}">{PRODUCT_BUTTON_LABEL_TRANSLATION}</a></th>
                    </tr>
                  </table>
                </th>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </th>
  </tr>
</tbody>`,

  horizontal_2: `<tbody>
  <tr>
    <th>
      <table class="row spm_title_wrapper spm_product_list_title_wrapper spm_responsive">
        <tbody>
          <tr>
            <th><h1 class="spm_product_list_title spm_inline_editing">{PRODUCT_LIST_TITLE}</h1></th>
          </tr>
        </tbody>
      </table>
      <table class="row spm_product_list spm_product_list_horizontal_2">
        <tr>
          <td>
            <table class="columns spm_product_list_element_container">
              <tr class="spm_product_list_element_row">
                <th class="column spm_product_list_element" style="width: 50%;"
                s-collection="products" s-item="product" s-grid="2" s-nb="4" s-engine="crossselling"
                s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
                  <h4 class="spm_product_list_element_title"><a href="{var=product.url}" title="{var=product.name}">{var=product.name truncate="40"}</a></h4>
                  <p class="spm_product_list_element_image_wrapper" style="text-align:center;margin:0;padding:0;">
                    <a href="{var=product.url}">
                      <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}" width="200"
                      class="spm_product_list_element_image" alt="{var=product.name}" title="{var=product.name}" />
                    </a>
                  </p>
                  <table class="spm_product_list_element_description spm_hide_on_builder" style="height:70px;" height="70" cellpadding="0" cellspacing="0" border="0" width="100%">
                    <tr>
                      <td>
                        <p>{var=product.description_short truncate="100"}</p>
                      </td>
                    </tr>
                  </table>
                  <table class="row">
                    <tr>
                      <td>
                        <table class="columns spm_product_list_element_prices">
                          <tr>
                            <th s-if="product.price_strike" s-condition="!=" s-value="product.price"
                            class="column" align="center" valign="middle"><p class="spm_product_list_element_price_strike">{var=product.price_strike}</p></th>
                            <th class="column" align="center" valign="middle"><p class="spm_product_list_element_price">{var=product.price}</p></th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table class="small-button button spm_product_list_element_button">
                    <tr>
                      <th><a href="{var=product.url}" title="{PRODUCT_BUTTON_LABEL}">{PRODUCT_BUTTON_LABEL_TRANSLATION}</a></th>
                    </tr>
                  </table>
                </th>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </th>
  </tr>
</tbody>`,

  horizontal_3: `<tbody>
  <tr>
    <th>
      <table class="row spm_title_wrapper spm_product_list_title_wrapper spm_responsive">
        <tbody>
          <tr>
            <th><h1 class="spm_product_list_title spm_inline_editing">{PRODUCT_LIST_TITLE}</h1></th>
          </tr>
        </tbody>
      </table>
      <table class="row spm_product_list spm_product_list_horizontal_3">
        <tr>
          <td>
            <table class="columns spm_product_list_element_container">
              <tr class="spm_product_list_element_row">
                  <th class="column spm_product_list_element" style="width: 33.33333%;"
                  s-collection="products" s-item="product" s-grid="3" s-nb="3" s-engine="crossselling"
                  s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
                  <h4 class="spm_product_list_element_title"><a href="{var=product.url}" title="{var=product.name}">{var=product.name truncate="40"}</a></h4>
                    <p class="spm_product_list_element_image_wrapper" style="text-align:center;margin:0;padding:0;">
                      <a href="{var=product.url}">
                        <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
                        width="150" class="spm_product_list_element_image" alt="{var=product.name}" title="{var=product.name}" />
                      </a>
                    </p>
                    <table class="spm_product_list_element_description spm_hide_on_builder" style="height:70px;"
                    height="70" cellpadding="0" cellspacing="0" border="0" width="100%">
                        <tr>
                          <td><p>{var=product.description_short truncate="80"}</p></td>
                        </tr>
                    </table>
                    <table class="row">
                        <tr>
                          <td>
                            <table class="columns spm_product_list_element_prices">
                              <tr>
                                <th s-if="product.price_strike" s-condition="!=" s-value="product.price" class="column" align="center" valign="middle">
                                  <p class="spm_product_list_element_price_strike">{var=product.price_strike}</p>
                                </th>
                                <th class="column" align="center" valign="middle">
                                  <p class="spm_product_list_element_price">{var=product.price}</p>
                                </th>
                              </tr>
                            </table>
                          </td>
                        </tr>
                    </table>
                    <table class="small-button button spm_product_list_element_button">
                      <tr>
                        <th><a href="{var=product.url}" title="{PRODUCT_BUTTON_LABEL}">{PRODUCT_BUTTON_LABEL_TRANSLATION}</a></th>
                      </tr>
                    </table>
                  </th>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </th>
  </tr>
</tbody>`,

  horizontal_4: `<tbody>
  <tr>
    <th>
      <table class="row spm_title_wrapper spm_product_list_title_wrapper spm_responsive">
        <tbody>
          <tr>
            <th><h1 class="spm_product_list_title spm_inline_editing">{PRODUCT_LIST_TITLE}</h1></th>
          </tr>
        </tbody>
      </table>
      <table class="row spm_product_list spm_product_list_horizontal_4">
        <tr>
          <td>
            <table class="columns spm_product_list_element_container">
              <tr class="spm_product_list_element_row">
                <th class="column spm_product_list_element" style="width: 25%;"
                s-collection="products" s-item="product" s-grid="4" s-nb="4" s-engine="crossselling"
                s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
                  <h4 class="spm_product_list_element_title"><a href="{var=product.url}" title="{var=product.name}">{var=product.name truncate="20"}</a></h4>
                  <p class="spm_product_list_element_image_wrapper" style="text-align:center;margin:0;padding:0;">
                    <a href="{var=product.url}">
                      <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
                      width="100" class="spm_product_list_element_image" alt="{var=product.name}" title="{var=product.name}" />
                    </a>
                  </p>
                  <table class="spm_product_list_element_description spm_hide_on_builder" style="height:70px;" height="70" cellpadding="0" cellspacing="0" border="0" width="100%">
                    <tr>
                      <td>
                          <p>{var=product.description_short truncate="60"}</p>
                      </td>
                    </tr>
                  </table>
                  <table class="row ">
                    <tr>
                      <td>
                        <table class="columns spm_product_list_element_prices">
                          <tr>
                              <th s-if="product.price_strike" s-condition="!=" s-value="product.price" class="column" align="center" valign="middle">
                                <p class="spm_product_list_element_price_strike">{var=product.price_strike}</p>
                              </th>
                              <th class="column" align="center" valign="middle">
                                <p class="spm_product_list_element_price">{var=product.price}</p>
                              </th>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <table class="small-button button spm_product_list_element_button">
                    <tr>
                      <th><a href="{var=product.url}" title="{PRODUCT_BUTTON_LABEL}">{PRODUCT_BUTTON_LABEL_TRANSLATION}</a></th>
                    </tr>
                  </table>
                </th>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </th>
  </tr>
</tbody>`,

  vertical_1: `<tbody>
  <tr>
    <th>
      <table class="row spm_title_wrapper spm_product_list_title_wrapper spm_responsive">
        <tbody>
          <tr>
            <th><h1 class="spm_product_list_title spm_inline_editing">{PRODUCT_LIST_TITLE}</h1></th>
          </tr>
        </tbody>
      </table>
      <table class="row spm_product_list spm_product_list_vertical_1">
        <tr>
          <td>
            <table class="columns spm_product_list_element_container">
              <tr class="spm_product_list_element_row">
                <th class="column spm_product_list_element" style="width: 100%;"
                s-collection="products" s-item="product" s-grid="1" s-nb="4" s-engine="crossselling"
                s-ids="" s-excluded_ids="" s-excluded_categories="" s-excluded_manufacturers="" s-nb_weeks="4">
                  <table width="100%" style="width: 100%">
                    <tr>
                      <th class="column" style="width: 30%;">
                        <a href="{var=product.url}">
                          <img src="{var=product.image_url resize=400x400 resizeType=fill-to-fit}"
                          width="150" class="spm_product_list_element_image" alt="{var=product.name}" title="{var=product.name}" />
                        </a>
                      </th>
                      <th class="column" style="padding: 5px; width: 70%;">
                        <h4 class="spm_product_list_element_title"><a href="{var=product.url}" title="{var=product.name}">{var=product.name truncate="50"}</a></h4>
                        <table class="spm_product_list_element_description spm_hide_on_builder" style="height:70px;" height="70"
                          cellpadding="0" cellspacing="0" border="0" width="100%">
                          <tr>
                            <td>
                              <p>{var=product.description_short truncate="100"}</p>
                            </td>
                          </tr>
                        </table>
                        <table class="columns spm_product_list_element_prices">
                          <tr>
                            <th s-if="product.price_strike" s-condition="!=" s-value="product.price" class="column" align="center" valign="middle">
                              <p class="spm_product_list_element_price_strike">{var=product.price_strike}</p>
                            </th>
                            <th class="column" align="center" valign="middle">
                              <p class="spm_product_list_element_price">{var=product.price}</p>
                            </th>
                          </tr>
                        </table>
                        <table class="small-button button spm_product_list_element_button">
                          <tr>
                            <th><a href="{var=product.url}" title="{PRODUCT_BUTTON_LABEL}">{PRODUCT_BUTTON_LABEL_TRANSLATION}</a></th>
                          </tr>
                        </table>
                      </th>
                    </tr>
                  </table>
                </th>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </th>
  </tr>
</tbody>`,
};

export const setListLayout = (selector: string, property: Property, widgetId: string) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement | null = template?.querySelector(selector);

  if (container) {
    let layout = layouts[property.value];
    // keep button text
    const button = container.querySelector('.spm_product_list_element_button > tbody > tr > th > a');
    if (button) {
      const buttonText = button.getAttribute('title');

      if (buttonText) {
        layout = layout.replaceAll('{PRODUCT_BUTTON_LABEL}', buttonText);
      } else {
        // Set default value
        layout = layout.replaceAll('{PRODUCT_BUTTON_LABEL}', translator.global.t('templateBuilder.smartProductListDefaultButton'));
      }

      // Check if translation is set
      const translationValue = button.querySelector(TRANSLATION_MARKUP);

      if (!translationValue) {
        const { newHtml } = generateTranslations(translator.global.t('templateBuilder.smartProductListDefaultButton'), { newElement: true });
        button.innerHTML = `${newHtml}`;
      } else if (translationValue && translationValue.innerHTML === '') {
        translationValue.innerHTML = translator.global.t('templateBuilder.smartProductListDefaultButton');
      }

      layout = layout.replaceAll('{PRODUCT_BUTTON_LABEL_TRANSLATION}', button.innerHTML);
    } else {
      // Button doesn't exist, we create all translations
      layout = layout.replaceAll('{PRODUCT_BUTTON_LABEL}', translator.global.t('templateBuilder.smartProductListDefaultButton'));
      const { newHtml } = generateTranslations(translator.global.t('templateBuilder.smartProductListDefaultButton'));
      layout = layout.replaceAll('{PRODUCT_BUTTON_LABEL_TRANSLATION}', `${newHtml}`);
    }

    const listTitle = container.querySelector('.spm_product_list_title');

    if (listTitle) {
      // Check if translation is set
      const titleTranslationValue = listTitle.querySelector(TRANSLATION_MARKUP);

      if (titleTranslationValue && titleTranslationValue.innerHTML === '') {
        titleTranslationValue.innerHTML = translator.global.t('templateBuilder.smartProductListDefaultTitle');
      }

      layout = layout.replace('{PRODUCT_LIST_TITLE}', listTitle.innerHTML);
    } else {
      // Set default value
      const { newHtml } = generateTranslations(translator.global.t('templateBuilder.smartProductListDefaultTitle'));
      layout = layout.replace('{PRODUCT_LIST_TITLE}', `${newHtml}`);
    }

    addSmartListOriginalCode(widgetId, layout);
  }
};

export const setTruncateLimit = (selector: string, property: Property, widgetId: string) => {
  let element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  if (element) {
    const regExp = new RegExp(`(\\{var="?${selector}"?[^}]*truncate="?)([0-9]+)("?[^}]*})`, 'gis');
    const regExp2 = new RegExp(`(\\{var="?${selector}"?[^}]*)(})`, 'gis');
    if (!element.match(regExp)) {
      element = element.replace(regExp2, `$1 truncate=${property.value.trim()}$2`);
    } else {
      element = element.replace(regExp, `$1${property.value}$3`);
    }
    addSmartListOriginalCode(widgetId, element);
  }
};

export const setDisplayedElements = (selector: string, property: Property, widgetId: string) => {
  let element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  // Elements are hidden by adding "spm_hide_on_builder" class
  const allElements = [
    'spm_product_list_element_button',
    'spm_product_list_element_description',
    'spm_product_list_element_price',
    'spm_product_list_element_price_strike',
    'spm_product_list_element_image',
    'spm_product_list_element_title',
    'spm_product_list_title',
  ];

  if (element) {
    allElements.forEach((displayableElement) => {
      // We check if class exists or not
      const regExp = new RegExp(`"([^"]*${displayableElement}(?:\\s[^"]+)*)"`, 'is');

      if (element) {
        const matches = element.match(regExp);

        if (matches) {
          const [, match] = matches;
          const classList = match.split(' ');
          let newClassList = match;

          if (property.value.includes(displayableElement) && classList.includes('spm_hide_on_builder')) {
            // If we need to display the item, we need to remove the class
            newClassList = classList.filter((className) => className !== 'spm_hide_on_builder').join(' ');
          } else if (!property.value.includes(displayableElement) && !classList.includes('spm_hide_on_builder')) {
            // If we need to hide the item, we need to add the class
            newClassList = [...classList, 'spm_hide_on_builder'].join(' ');
          }

          // We replace the match by the new class list (containing or not the hidden class)
          element = element.replace(match, newClassList);
        }
      }
    });

    addSmartListOriginalCode(widgetId, element);
  }
};

export const setSmartListTitle = (selector: string, property: Property, widgetId: string) => {
  // We restore original HTML code for that widget
  restoreSmartListsOriginalCode(widgetId);

  // We get the element inside the template
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);

  if (element && undefined !== property.value) {
    // We set the new value
    sanitizeTranslationMarkup(element).innerHTML = property.value;
    setTranslationInState(element, 'markup');

    // We get the complete widget element, and we store the original HTML code in the state
    const widget: HTMLElement | null = template?.querySelector(`#${widgetId}`);
    if (widget) {
      addSmartListOriginalCode(widgetId, widget.innerHTML);
    }
  }
};

export const setSmartListButtonTitle = (selector: string, property: Property, widgetId: string) => {
  // We restore original HTML code for that widget
  restoreSmartListsOriginalCode(widgetId);

  // We get widget inside the template
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);

  if (element && undefined !== property.value) {
    // We set the new value
    sanitizeTranslationMarkup(element).innerHTML = property.value;
    setTranslationInState(element, 'markup', '', true);

    // We get the complete widget element, and we store the original HTML code in the state
    const widget: HTMLElement | null = template?.querySelector(`#${widgetId}`);
    if (widget) {
      addSmartListOriginalCode(widgetId, widget.innerHTML);
    }
  }
};

export const setSmartListButtonAlignment = (selector: string, property: Property, widgetId: string) => {
  // We restore original HTML code for that widget
  restoreSmartListsOriginalCode(widgetId);

  // We get widget inside the template
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);

  if (element && undefined !== property.value) {
    // We set the new value
    element.setAttribute(property.name, property.value);

    // We get the complete widget element, and we store the original HTML code in the state
    const widget: HTMLElement | null = template?.querySelector(`#${widgetId}`);
    if (widget) {
      addSmartListOriginalCode(widgetId, widget.innerHTML);
    }
  }
};

export const setSmartListImageWidth = (selector: string, property: Property, widgetId: string) => {
  // We restore original HTML code for that widget
  restoreSmartListsOriginalCode(widgetId);

  // We get widget inside the template
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);

  if (element && undefined !== property.value) {
    // We set the new value
    if (!(new RegExp('%$')).test(property.value)) {
      element.setAttribute('width', property.value.replace('px', ''));
    } else {
      element.setAttribute('width', property.value);
    }

    addOrUpdateDynamicStyle(selector, { 'max-width': property.value });
    // We get the complete widget element, and we store the original HTML code in the state
    const widget: HTMLElement | null = template?.querySelector(`#${widgetId}`);
    if (widget) {
      addSmartListOriginalCode(widgetId, widget.innerHTML);
    }
  }
};
