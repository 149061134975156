<template>
  <div
    class="flex custom_datepicker_spm"
    :class=" { 'flex-column': calendarPosition === 'bottom', 'flex-row': calendarPosition === 'right', 'custom_range': chosenInterval === IntervalDateEnum.CUSTOM_DATE_RANGE }"
  >
    <div class="flex">
      <SpmOverlayPanel
        :class-trigger="'flex justify-content-between flex-wrap custom_datepicker_input ' +
          (chosenInterval === IntervalDateEnum.CUSTOM_DATE_RANGE ? IntervalDateEnum.CUSTOM_DATE_RANGE : '')"
        class-header="flex align-items-center profile-info justify-content-left"
        class-content="flex justify-content-center spm-overlay-panel-content"
        class-footer="flex align-items-center justify-content-center spm-overlay-panel-content"
        style-overlay-panel="margin-top:0px;"
        :close-panel="closePanel"
        @onclose-panel="closePanel = false"
      >
        <template #trigger>
          <div class="flex align-items-center justify-content-center">
            <i class="far fa-calendar-alt mr-2" />
            <span>{{ t("customDatePicker."+chosenInterval) }}</span>
          </div>
          <div class="flex align-items-center justify-content-center">
            <i class="far fa-chevron-down" />
          </div>
        </template>
        <SpmPanelMenu
          :items="optionsItems"
        />
      </SpmOverlayPanel>
    </div>
    <div class="flex">
      <Calendar
        v-if="chosenInterval === IntervalDateEnum.CUSTOM_DATE_RANGE"
        v-model="customDate"
        date-format="dd/mm/yy"
        selection-mode="range"
        :hide-on-range-selection="true"
        :manual-input="false"
        :min-date="minDate ?? undefined"
        :max-date="maxDate ?? undefined"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import Calendar from 'primevue/calendar';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import { IntervalDateEnum, PrimvueMenuModel } from '@/types';
import { useI18n } from 'vue-i18n';
import moment from 'moment';

export default defineComponent({
  name: 'CustomDatePicker',

  components: {
    Calendar,
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  props: {
    modelValue: {
      type: Object,
      default: () => Object,
    },

    calendarPosition: {
      type: String,
      default: 'bottom',
    },

    noLimit: {
      type: Boolean,
      required: false,
      default: false,
    },

    customMinDate: {
      type: Number,
      required: false,
      default: 0,
    },

    customOptionsItems: {
      type: Array as PropType<any[]>,
      required: false,
      default: () => ([]),
    },

    updateValue: {
      type: Boolean,
      required: false,
      default: false,
    },

    defaultValue: {
      type: String,
      required: false,
      default: '',
    }
  },

  emits: ['update:modelValue', 'reset-update-value', 'value-choosed'],

  setup(props: { modelValue: any; customMinDate: number; noLimit: boolean; customOptionsItems: IntervalDateEnum[]; updateValue: boolean; defaultValue: string; }, { emit }: SetupContext) {
    const { t } = useI18n();

    const closePanel = ref(false);
    const chosenInterval: Ref<IntervalDateEnum|'empty'|string> = ref('empty');

    const customDate = ref(props.modelValue.customDateRange && props.modelValue.customDateRange.length > 0
      ? props.modelValue.customDateRange.map((date: string) => moment(date).toDate())
      : [moment().subtract(30, 'days').toDate(), moment().toDate()]);

    if (props.modelValue.interval) {
      chosenInterval.value = props.modelValue.interval;
    }
    if (props.defaultValue) {
      chosenInterval.value = props.defaultValue;
    }

    // Limit the date range to a maximum of one year apart
    const maxDate = ref(props.noLimit ? null : moment().toDate());
    const minDate = ref(!props.customMinDate ? null : moment().subtract(props.customMinDate, 'years').toDate());
    const optionsItems = ref<any[]>([]);

    if (props.customOptionsItems.length > 0) {
      optionsItems.value = props.customOptionsItems.map((key) => ({
          label: t(`customDatePicker.${key}`),
          command: () => {
            chosenInterval.value = key;
            closePanel.value = true;
            emit('value-choosed');
          },
          class: computed(() => (chosenInterval.value === key ? 'activeMenuItem' : '')),
        }));
    } else {
      optionsItems.value = Object.values(IntervalDateEnum).map((key) => ({
        label: t(`customDatePicker.${key}`),
        command: () => {
          chosenInterval.value = key;
          closePanel.value = true;
          emit('value-choosed');
        },
        class: computed(() => (chosenInterval.value === key ? 'activeMenuItem' : '')),
      }));
    }

    // add empty value to the optionsItems
    optionsItems.value.unshift({
      label: t('customDatePicker.empty'),
      command: () => {
        chosenInterval.value = 'empty';
        closePanel.value = true;
        emit('value-choosed');
      },
      class: computed(() => (chosenInterval.value === 'empty' ? 'activeMenuItem' : '')),
    });

    watch([chosenInterval, customDate], (newVal) => {
      if (chosenInterval.value === 'customDateRange') {
        if (customDate.value[0] && !customDate.value[1]) {
          if (moment(customDate.value[0]).add(1, 'years').toDate() > moment().toDate()) {
            maxDate.value = moment().toDate();
          } else {
            maxDate.value = moment(customDate.value[0]).add(1, 'years').toDate();
          }
        } else if (customDate.value[0] && customDate.value[1]) {
          maxDate.value = moment().toDate();
          emit('update:modelValue', {
            interval: chosenInterval.value,
            customDateRange: customDate.value,
          });
        }
      } else {
        emit('update:modelValue', {
          interval: chosenInterval.value,
        });
      }
      if (props.updateValue) {
        emit('reset-update-value');
      }
    }, { deep: true });

    watch(() => props.updateValue, () => {
      if (props.updateValue) {
        customDate.value = props.modelValue.customDateRange && props.modelValue.customDateRange.length > 0
        ? props.modelValue.customDateRange.map((date: string) => moment(date).toDate())
        : [moment().subtract(30, 'days').toDate(), moment().toDate()];
        if (props.modelValue.interval) {
          chosenInterval.value = props.modelValue.interval;
        }
      }
    }, { deep: true });

    onMounted(() => {
      if (chosenInterval.value === 'customDateRange') {
        if (customDate.value[0] && customDate.value[1]) {
          emit('update:modelValue', {
            interval: chosenInterval.value,
            customDateRange: customDate.value,
          });
        }
      } else {
        // on load: update the value for the parent
        emit('update:modelValue', {
          interval: chosenInterval.value,
        });
      }
    });

    return {
      t,
      IntervalDateEnum,
      chosenInterval,
      optionsItems,
      minDate,
      maxDate,
      customDate,
      closePanel,
    };
  },

});
</script>

<style lang="scss">
.custom_datepicker_spm {
  .custom_datepicker_input {
    padding: 0.5rem 0.5rem;
    border: 1px solid #ced4da;
    cursor: pointer;
    border-radius: 0.3rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    color: #6c757d;
    width: 100%;
    background:$white;
    font-weight: normal;

    .flex:first-child {
      margin-right: 0.5rem;
    }
    .flex:last-child {
      margin-right: 0.3rem;
    }
  }

  .p-calendar {
    width: 100%;
    input {
      width: 100% !important;
      background-color: #f8f9fa !important;
    }
  }

  &.flex-row.custom_range {
    .custom_datepicker_input {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: none;
    }
    .p-calendar input {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background:$white;
    }
  }

  &.flex-column.custom_range {
    .custom_datepicker_input {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .p-calendar input {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: none;
      background:$white;
    }
  }

  .p-menu-list {
    .p-menuitem {
      padding: 0.1rem 0.2rem;

      &.activeMenuItem {
        .p-menuitem-content {
          background-color: #e9ecef;
        }
      }

      .p-menuitem-content {
        border-radius: 0.3rem;
        font-size: 0.875rem;
        font-weight: 600;
        color: $tuna;
      }
    }
  }
}
</style>
