
import {
  defineComponent, PropType,
  ref,
} from 'vue';
import InputText from 'primevue/inputtext';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import UserInfosMetadata, { UserInfosData } from '@/components/complete/metadata/UserInfosMetadata';
import { useI18n } from 'vue-i18n';
import { saveMyProfile } from '@/composables/user/Profile';
import { showToastError } from '@/helpers';
import Loader from '@/components/layout/Loader.vue';
import { UsersUpdateInputItem } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import getCountries from '@/configs/countries';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'UserInfos',

  components: {
    Loader,
    InputText,
    SpmButton,
    FieldErrors,
    Dropdown,
  },

  props: {
    data: {
      type: Object as PropType<UserInfosData>,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['next'],

  setup(props: { data: any }, { emit }) {
    const { t } = useI18n();
    const isLoading = ref(false);
    const userInfosFields = ref<UserInfosData>(UserInfosMetadata.Create(props.data));
    const countries = ref(getCountries(t));

    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    const handleNext = async () => {
      const validation = await UserInfosMetadata.Validate(userInfosFields.value);
      if (!validation.success) {
        formValidationErrors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
      } else {
        isLoading.value = true;
        const userDataToSave: UsersUpdateInputItem = {
          id_user: UserState.user.id,
          first_name: userInfosFields.value.firstName,
          last_name: userInfosFields.value.lastName,
          societe: userInfosFields.value.societe,
          vat_number: userInfosFields.value.vatNumber,
          rue: userInfosFields.value.rue,
          complement: userInfosFields.value.complement,
          zip_code: userInfosFields.value.zipCode,
          city: userInfosFields.value.city,
          country: userInfosFields.value.country,
          phone_mobile: userInfosFields.value.phoneMobile,
          phone_fixe: userInfosFields.value.phoneFixe,
        };
        saveMyProfile(userDataToSave).then(() => {
          isLoading.value = false;
          emit('next');
        }).catch((error: any) => {
          isLoading.value = false;
          showToastError('GENERIC_ERROR');
        });
      }
    };

    return {
      t,
      isLoading,
      userInfosFields,
      countries,
      formValidationErrors,
      componentFieldErrorsKey,
      handleNext,
    };
  },

});
