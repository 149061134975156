import { BaseFieldConfig, BoxShadowEnum, ComponentNameEnum } from '@/types';

const boxShadowFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.BOX_SHADOW,
  options: {
    defaultBoxShadowStyle: BoxShadowEnum.NONE,
    defaultColor: '#808080',
    defaultBlur: 50,
  },
};

export default boxShadowFieldConfig;
