/* eslint-disable no-param-reassign */
import {
  Section, StringMap, TemplateParentTypeEnum, TemplateStructureEnum, Translation, WidgetTypeEnum,
} from '@/types';
import { br2nl, nl2br } from '@/helpers';
import { getShopDefaultLang } from '@/composables/User';
// eslint-disable-next-line import/no-cycle
import {
  TemplateEditorState,
  updateSectionsInState,
  updateTranslationPropertyInStateAllLanguages,
} from '@/composables/template-editor/TemplateEditor';
import { Maybe } from '@/types/generated-types/graphql';

import * as cheerio from 'cheerio';

import { borderedItemResetStyles, boxShadowedItemResetStyles } from './elements-style-definitions';
import {
  ACTIONS_BUTTON_GROUP,
  BUILDER_ELEMENT_ACTIVE_CLASS,
  TEMPLATE_STRUCTURE_MAP,
  TRANSLATION_ATTRIBUTE,
  TRANSLATION_MARKUP,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
} from './constants';

interface ElementConfig {
  id: string;
  children: string;
  styles?: StringMap;
  identifier?: string;
  title?: string;
  onClick?: (event: Event) => void;
}

export const decorateElementWithStyles = (element: HTMLElement, styles: StringMap) => {
  Object.keys(styles).forEach((property) => {
    element.style[property as any] = styles[property];
  });
};

export const createCustomButton = (elementConfig: ElementConfig): HTMLDivElement => {
  const {
    id,
    title,
    children,
    styles,
    onClick,
  } = elementConfig;
  const element = document.createElement('div');
  element.id = id;
  element.innerHTML = children;

  if (styles) {
    decorateElementWithStyles(element, styles);
  }

  if (title) {
    element.title = title;
  }

  if (onClick) {
    element.addEventListener('click', onClick);
  }

  return element;
};

export const getTemplateIframeDocument = () => (document.querySelector('iframe.template-wrapper') as HTMLIFrameElement)?.contentDocument as Document;

export const getTemplateIframe = () => (document.querySelector('iframe.template-wrapper') as HTMLIFrameElement);

export const replaceSelectors = (value: string, selector: string, key: string) => value.replaceAll(selector, key);

export const decorateActiveItemElementWithStyles = (selector: string, type = 'add'): HTMLElement|null => {
  const template = getTemplateIframeDocument();

  if (!template) {
    return null;
  }
  const activeItemElement: HTMLElement | null = template.querySelector(selector);

  if (activeItemElement) {
    if (type === 'add') {
      activeItemElement.classList.add(BUILDER_ELEMENT_ACTIVE_CLASS);
    } else {
      activeItemElement.classList.remove(BUILDER_ELEMENT_ACTIVE_CLASS);
    }
  }

  return activeItemElement;
};

export const getElementResetStylesByType = (itemType: TemplateStructureEnum) => {
  if (itemType === TemplateStructureEnum.SECTION) {
    return borderedItemResetStyles;
  }

  return boxShadowedItemResetStyles;
};

export const generateUniqStructureId = () => Math.floor(Math.random() * Math.floor(Math.random() * Date.now())).toString(16)
  + Math.floor(Math.random() * Math.floor(Math.random() * Date.now())).toString(16);

export const getElementFromIframe = (selector: string) => {
  const template = getTemplateIframeDocument();
  return template.querySelector(selector) as HTMLElement;
};

export const getWidgetType = (attribute: string|null): WidgetTypeEnum|null => {
  switch (attribute) {
    case 'spm_widget_text': return WidgetTypeEnum.TEXT;
    case 'spm_widget_display_text': return WidgetTypeEnum.TEXT;
    case 'spm_widget_code': return WidgetTypeEnum.CODE;
    case 'spm_widget_display_code': return WidgetTypeEnum.CODE;
    case 'spm_widget_image': return WidgetTypeEnum.IMAGE;
    case 'spm_widget_display_image': return WidgetTypeEnum.IMAGE;
    case 'spm_separator': return WidgetTypeEnum.SEPARATOR;
    case 'spm_widget_separator': return WidgetTypeEnum.SEPARATOR;
    case 'spm_widget_display_separator': return WidgetTypeEnum.SEPARATOR;
    case 'spm_widget_title': return WidgetTypeEnum.TITLE;
    case 'spm_widget_display_title': return WidgetTypeEnum.TITLE;
    case 'spm_call_to_action': return WidgetTypeEnum.BUTTON;
    case 'spm_call_to_action_rgpd': return WidgetTypeEnum.GDPR_BUTTON;
    case 'spm_display_call_to_action': return WidgetTypeEnum.BUTTON;
    case 'spm_smart_products_list': return WidgetTypeEnum.SMART_PRODUCT_LIST;
    case 'spm_widget_share': return WidgetTypeEnum.SHARE_SOCIAL_MEDIA;
    case 'spm_widget_follow': return WidgetTypeEnum.SOCIAL_MEDIA;
    case 'spm_widget_display_follow': return WidgetTypeEnum.SOCIAL_MEDIA;
    case 'spm_widget_display_voucher': return WidgetTypeEnum.VOUCHER;
    case 'spm_form_registration': return WidgetTypeEnum.CREATE_ACCOUNT;
    case 'spm_widget_display_form_registration': return WidgetTypeEnum.CREATE_ACCOUNT;
    case 'spm_form_newsletter': return WidgetTypeEnum.NEWSLETTER_SUBSCRIPTION;
    case 'spm_widget_display_form_newsletter': return WidgetTypeEnum.NEWSLETTER_SUBSCRIPTION;
    case 'spm_form_registration_unsubscribe': return WidgetTypeEnum.NEWSLETTER_UNSUBSCRIPTION;
    case 'spm_widget_display_form_newsletter_unsubscribe': return WidgetTypeEnum.NEWSLETTER_UNSUBSCRIPTION;
    case 'spm_facebook_messenger': return WidgetTypeEnum.FACEBOOK_MESSENGER;
    case 'spm_widget_fb_text': return WidgetTypeEnum.TEXT;
    case 'spm_fb_productslist': return WidgetTypeEnum.SMART_PRODUCT_LIST;
    case 'spm_widget_import_zip': return WidgetTypeEnum.IMPORT_ZIP;
    default: return null;
  }
};

export const getTemplateParentTypeByType = (type: string|undefined): TemplateParentTypeEnum => {
  switch (type) {
    case 'embed':
    case 'embedNlForm':
    case 'xSellsEmbed':
      return TemplateParentTypeEnum.EMBED;
    case 'display':
    case 'popup':
    case 'popupNlForm':
    case 'popupVoucher':
      return TemplateParentTypeEnum.POPUP;
    case 'pushnotifications':
      return TemplateParentTypeEnum.PUSHNOTIFICATIONS;
    case 'sms':
      return TemplateParentTypeEnum.SMS;
    case 'facebookmessenger':
      return TemplateParentTypeEnum.FACEBOOKMESSENGER;
    case 'email':
      return TemplateParentTypeEnum.EMAIL;
    default:
      return TemplateParentTypeEnum.NONE;
  }
};

export const isEmailTemplate = (type: string | undefined): boolean => getTemplateParentTypeByType(type) === TemplateParentTypeEnum.EMAIL;
export const isDisplayTemplate = (type: string | undefined): boolean => [
  TemplateParentTypeEnum.EMBED,
  TemplateParentTypeEnum.POPUP,
  TemplateParentTypeEnum.DISPLAY,
].includes(getTemplateParentTypeByType(type));
export const isSmsTemplate = (type: string | undefined): boolean => getTemplateParentTypeByType(type) === TemplateParentTypeEnum.SMS;
export const isPushTemplate = (type: string | undefined): boolean => getTemplateParentTypeByType(type) === TemplateParentTypeEnum.PUSHNOTIFICATIONS;
export const isFacebookTemplate = (type: string | undefined): boolean => getTemplateParentTypeByType(type) === TemplateParentTypeEnum.FACEBOOKMESSENGER;

export const highlightWidgetOnTranslationClicked = (translation: Translation) => {
  const template = getTemplateIframeDocument();
  const keys = translation.key.split('.');
  let attributeIdentifier = `data-spmtranslationid${keys.length > 1 ? `-${keys[1]}` : ''}`;
  if (translation.fieldType === 'link' && keys.length === 1) {
    attributeIdentifier = 'data-spmtranslationid-href';
  }
  const element = template.querySelector(`spmtranslation[id="LANG_${translation.key}"], [${attributeIdentifier}="LANG_${translation.key}"]`);
  if (element) {
    const highlightElement = (elementToHighlight: HTMLElement) => {
      elementToHighlight.classList.add('spm-highlight');
      elementToHighlight.scrollIntoView();
      setTimeout(() => {
        elementToHighlight.classList.remove('spm-highlight');
      }, 1000);
    };

    const widget = (element.closest(TEMPLATE_STRUCTURE_MAP.widget) as HTMLElement);
    if (widget) {
      highlightElement(widget);
    } else if (!widget && TemplateEditorState.template?.informations.imported) {
      if (keys.length > 1) {
        highlightElement(element as HTMLElement);
      } else {
        const elementToHighlight = element.parentNode ? element.parentNode as HTMLElement : element;
        highlightElement(elementToHighlight as HTMLElement);
      }
    }
  }
};

export const sanitizeSection = (content: HTMLElement, cloneElement = false): HTMLElement => {
  const newContent = cloneElement ? content.cloneNode(true) as HTMLElement : content;

  if (newContent) {
    // remove action button group
    ((Array.from(newContent.querySelectorAll(`[class*="${ACTIONS_BUTTON_GROUP}"]`)) as HTMLElement[]) ?? [])
      .forEach((element: HTMLElement) => element.remove());
    // remove placeholders
    ((Array.from(newContent.querySelectorAll(`[class*="${WIDGETS_PLACEHOLDER_IDENTIFIER}"]`)) as HTMLElement[]) ?? [])
      .forEach((element: HTMLElement) => element.remove());
    // remove column widget's placeholders
    ((Array.from(newContent.querySelectorAll('.sortable-group-line.sortable-placeholder')) as HTMLElement[]) ?? [])
      .forEach((element: HTMLElement) => element.remove());
  }

  return newContent;
};

export const addSpmElementId = (section: Section): Section => {
  const parentSectionElement = document.createElement('section');
  parentSectionElement.innerHTML = section.html;
  const template = parentSectionElement.firstElementChild;
  if (template) {
    template.setAttribute('data-spmelementid', section.id_template_elements.toString());
  }
  // eslint-disable-next-line no-param-reassign
  section.html = template?.outerHTML ?? '';
  return section;
};

export const formatStylesForSection = (styles: Element[]) => JSON.stringify(
  styles.reduce((acc: StringMap, item: Element) => {
    const key = item.getAttribute('data-spm-styles') ?? '';
    const style = (item as HTMLStyleElement).innerText;

    if (!acc[key]) {
      acc[key] = style;
    } else {
      acc[key] += style;
    }

    return acc;
  }, {}),
);

export const formatTranslationsForSection = (translations: Translation[]) => {
  const emptyTranslationObject = translations
    .map((translation: Translation) => translation.language)
    .filter((value, index, self) => self.indexOf(value) === index)
    .reduce((langReducer, language: string) => ({ ...langReducer, [language]: {} }), {});
  // eslint-disable-next-line no-param-reassign
  return JSON.stringify(translations
    .reduce((acc: any, translation: Translation) => {
      const splittedKey = translation.key.split('.');
      const entry = splittedKey.slice()
        .reverse()
        .reduce((reducer: any, current: string) => ((Object.keys(reducer).length > 0) ? { [current]: reducer }
          : {
            [current]: { type: translation.fieldType, value: translation.callback(translation.value) },
          }), {});
      acc[translation.language][splittedKey[0]] = {
        ...acc[translation.language][splittedKey[0]],
        ...entry[splittedKey[0]],
      };
      return acc;
    }, emptyTranslationObject));
};

export const addTranslation = (t: any, key: string, section: string | number, language: string, templateType: string): Translation[]|undefined => {
  const lastKey = key.split('.').pop();
  if (!lastKey) return undefined;

  const translation = t[lastKey];
  if (!translation || typeof translation !== 'object') {
    return undefined;
  }
  if (Object.prototype.hasOwnProperty.call(translation, 'value')) {
    let callback = (value: string) => value;
    let translationValue = translation.value;
    if (translation.value.match(/^base64:/)) {
      callback = (value: string) => `base64:${btoa(value)}`;
      translationValue = atob(translation.value.replaceAll(/base64:/g, ''));
    }
    if (templateType === 'sms') {
      callback = (value: string) => nl2br(value, true);
      translationValue = br2nl(translation.value);
    }

    return [{
      section,
      language,
      fieldType: translation.type,
      key,
      value: translationValue,
      callback,
    }];
  }

  return (Object.keys(translation) ?? [])
    .reduce((acc: Translation[], k: string) => (
      [
        ...acc,
        ...(addTranslation(translation, `${key}.${k}`, section, language, templateType) ?? []),
      ]
    ), []);
};

export const isManagedByAttribute = (translation: Translation): boolean => {
  if (new RegExp('alt|href|src|data-redirect|title').test(translation.key)) return true;
  if (new RegExp('\\.text|\\.html').test(translation.key)) return false;

  // Hack, some images does not have src in key
  if (
    translation.fieldType === 'image'
    || translation.fieldType === 'data-redirect'
    || translation.fieldType === 'placeholder'
    || translation.fieldType === 'link'
  ) {
    return true;
  }

  // We check if it's an HTML string otherwise we consider it's a simple text
  let newAttribute: string | null = null;

  if (translation.fieldType === undefined) {
    newAttribute = (!new RegExp('\\.').test(translation.key) && new RegExp('<[a-z][^>]*>', 'i').test(translation.value))
      ? 'html'
      : 'text';

    // In case attribute is wrong in translation, and we found a new attribute value, we update translations in state
    updateTranslationPropertyInStateAllLanguages(translation.key, 'fieldType', newAttribute);
  }

  // Check if translation is in an attribute in section's HTML
  const section = TemplateEditorState.template?.sections
    .filter((sectionOfTemplate) => sectionOfTemplate.id_template_elements.toString() === translation.section.toString());

  if (section && section.length > 0) {
    // We check if the translation is inside an attribute
    const attributeRegExp = new RegExp(` ([a-z-]*)="{LANG_${translation.key}}"`);
    const matches = attributeRegExp.exec(section[0].html);

    if (matches && matches.length > 0) {
      // Translation is inside an attribute
      const attribute = matches[1];

      if (['data-redirect', 'placeholder'].includes(attribute)) {
        newAttribute = attribute;
      } else if (attribute === 'src') {
        newAttribute = 'image';
      } else if (attribute === 'href') {
        newAttribute = 'link';
      }

      if (newAttribute) {
        // In case attribute is wrong in translation, and we found a new attribute value, we update translations in state
        updateTranslationPropertyInStateAllLanguages(translation.key, 'fieldType', newAttribute);
      }

      return true;
    }
  }

  if (['html', 'text'].includes(translation.fieldType)) {
    // In case we have detected an HTML or text string, we consider it's not an attribute
    return false;
  }

  return false;
};

export const computeAttribute = (key: string, type: string): string => {
  const splittedKey = key.split('.');
  if (splittedKey[1]) return splittedKey[1];
  if (type === 'link') return 'href';
  if (type === 'image') return 'src';
  if (type === 'placeholder') return 'placeholder';
  if (type === 'data-redirect' || type === undefined) return 'data-redirect';
  return '';
};

export const replaceTranslationsByKey = (html: string, translations: Translation[]): string => {
  const container = document.createElement('div');
  container.innerHTML = html;
  translations.filter((translation: Translation) => translation.language === getShopDefaultLang())
    .forEach((translation: Translation) => {
      if (isManagedByAttribute(translation)) { // replace attribute
        const attr = computeAttribute(translation.key, translation.fieldType);
        const item = container.querySelector(`[${TRANSLATION_ATTRIBUTE}-${attr}='LANG_${translation.key}']`);
        if (item) {
          item.removeAttribute(`${TRANSLATION_ATTRIBUTE}-${attr}`);
          item.setAttribute(`${attr}`, `{LANG_${translation.key}}`);
        }
      } else { // replace markup
        const item = container.querySelector(`[id='LANG_${translation.key}']`);
        if (item) item.outerHTML = `{LANG_${translation.key}}`;
      }
    });
  return container.innerHTML;
};

/**
 * Create a new type of stylesheet for section
 * @param sectionId
 * @param type
 */
export const createNewStylesheet = (type: string, sectionId: Maybe<string> = null): HTMLStyleElement => {
  const template = getTemplateIframeDocument();
  const dynamicStylesheet = document.createElement('style');
  dynamicStylesheet.setAttribute('data-spm-styles', type);
  dynamicStylesheet.setAttribute('type', 'text/css');

  if (sectionId) {
    dynamicStylesheet.setAttribute('data-spm-section', sectionId);
  }

  if (template) {
    const head = template.querySelector('head');

    if (head) {
      head.append(dynamicStylesheet);
    }
  }

  return dynamicStylesheet;
};

/**
 * Return a cross-mail compatible CSS rule (as string)
 * @param rule
 */
const getCrossMailClientRule = (rule: string): string => {
  // Replace old body id selector
  rule = rule.replace(/table\[id="spm_body"\]/i, '#spm_body');

  // Remove empty rule
  if (rule.match(/\{\s*\}/i)) {
    rule = '';
  }

  // Replace padding property by 'padding-top', 'padding-left', 'padding-bottom', 'padding-right' properties
  if (rule.match(/padding\s*?:/i)) {
    const rulePropertiesIndependent = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'];
    const regexpValue = new RegExp('/(?:.*\\{.*?padding\\s*?:\\s?)([a-z0-9 ]*)(.*)?(?:;)/');
    const matches = regexpValue.exec(rule);
    const ruleValue = matches ? matches[1].split(' ') : [];

    let finalRuleValue = '';
    rulePropertiesIndependent.forEach((ruleCSSProperty, key) => {
      let currentRuleValue;

      if (ruleValue.length === 1) {
        [currentRuleValue] = ruleValue;
      } else if (ruleValue.length === 2) {
        if (ruleCSSProperty === 'padding-top' || ruleCSSProperty === 'padding-bottom') {
          [currentRuleValue] = ruleValue;
        } else {
          [, currentRuleValue] = ruleValue;
        }
      } else if (ruleValue.length === 3) {
        if (ruleCSSProperty === 'padding-top') {
          [currentRuleValue] = ruleValue;
        }
        if (ruleCSSProperty === 'padding-bottom') {
          [, , currentRuleValue] = ruleValue;
        } else {
          [, currentRuleValue] = ruleValue;
        }
      } else if (ruleValue.length === 4) {
        currentRuleValue = ruleValue[key];
      }

      finalRuleValue += `${ruleCSSProperty}:${currentRuleValue};`;
    });

    rule = rule.replace(/padding\s*?:\s?([a-z0-9 ]*)?(?:;)/, finalRuleValue);
  }

  // Remove space before important
  rule = rule.replace(/\s!important/, '!important');

  // Replace 0px by 0
  rule = rule.replace(/([^A-Z0-9])0px/ig, '$10');

  return rule;
};

/**
 * Return a CSS rule for increasing font size in media queries
 * @param rule
 */
const getCrossMailClientRuleMediaQueries = (rule: CSSStyleRule): Maybe<string> => {
  const template = getTemplateIframeDocument();

  if (!template) {
    return null;
  }

  const spmBodyElement = template.querySelector('#spm_body');

  if (!spmBodyElement) {
    return null;
  }

  let newRule = '';

  // If template body doesn't have the font size increase attribute, we add it
  if (!spmBodyElement.hasAttribute('data-mobilefontsizeincrease')) {
    spmBodyElement.setAttribute('data-mobilefontsizeincrease', '1px');
  }

  if ((rule.cssText.match(/font-size:\s?[0-9]*px?/i) && spmBodyElement.getAttribute('data-mobilefontsizeincrease') !== '0px')
    || (rule.cssText.match(/max-width:\s?[0-9]*px?/i) && rule.cssText.match(/#spm_widget_/i))) {
    // Minify the selector
    let currentSelectorText = rule.selectorText;
    currentSelectorText = currentSelectorText.replace(/table#/g, '#');
    currentSelectorText = currentSelectorText.replace(/body /g, '');
    currentSelectorText = currentSelectorText.replace(/ ?> ?tbody ?> ?tr ?> ?/g, ' ');

    // Test each selector and keep only available elements
    if (currentSelectorText.match(',')) {
      const currentSelectorsText = currentSelectorText.split(',');
      const finalSelectorsText: string[] = [];
      // eslint-disable-next-line consistent-return
      currentSelectorsText.forEach((item) => {
        if (template.querySelector(item.trim()) && !finalSelectorsText.includes(item.trim())) {
          finalSelectorsText.push(item.trim());
        }
        if (!finalSelectorsText.length) {
          return null;
        }
        currentSelectorText = finalSelectorsText.join();
      });
    }

    if (!template.querySelector(currentSelectorText)) {
      return null;
    }
    // Increase font-size mobile version
    if (rule.cssText.match(/font-size:\s?[0-9]*px?/i) && spmBodyElement.getAttribute('data-mobilefontsizeincrease') !== '0px') {
      const match = rule.cssText.match(/font-size:\s?([0-9]*)px?/i);

      if (match !== null && typeof match === 'object' && typeof match[1] !== 'undefined') {
        const desktopFontSize = parseInt(match[1], 10);
        const mobileFontSizeIncrease = parseInt(spmBodyElement.getAttribute('data-mobilefontsizeincrease') ?? '1', 10);
        const mobileFontSize = desktopFontSize + mobileFontSizeIncrease;
        newRule = `${currentSelectorText}{font-size:${mobileFontSize}px!important}`;
      }
    } else if (rule.cssText.match(/max-width:\s?[0-9]*px?/i) && rule.cssText.match(/#spm_widget_/i)) {
      // Fix outlook/iOS : we add max-width rules for responsive
      const match = rule.cssText.match(/max-width:\s?([0-9]*)px?/i);

      if (match !== null && typeof match === 'object' && typeof match[1] !== 'undefined') {
        // If image size is large enough, we force 100% width
        if (parseInt(match[1], 10) > 480) {
          // eslint-disable-next-line max-len
          newRule = `${(!currentSelectorText.match('#spm_body ') ? '#spm_body ' : '') + currentSelectorText}{max-width: 100%!important;min-width: 100%!important;width: 100%!important;}`;
        } else {
          newRule = `${(!currentSelectorText.match('#spm_body ') ? '#spm_body ' : '') + currentSelectorText}{max-width:${match[1]}px!important}`;
        }
      }
    }
  }

  return newRule;
};

/**
 * Add or remove dynamic_persistent_mobile stylesheet
 */
export const reconstructDynamicPersistentMobileCss = () => {
  const template = getTemplateIframeDocument();

  if (template) {
    let finalDynamiquesStylesMediaQueries = '';

    if (template && typeof template === 'object' && template.getElementById('spm_custom_mediaqueriesfontsize')) {
      const element = template.getElementById('spm_custom_mediaqueriesfontsize');
      if (element) {
        element.remove();
      }
    }

    if (template.styleSheets.length) {
      Array.from(template.styleSheets).forEach((stylesheet: CSSStyleSheet) => {
        if (typeof stylesheet.href === 'undefined' || !stylesheet.href) {
          Array.from((stylesheet.cssRules || stylesheet.rules)).forEach((rule: CSSRule) => {
            const styleRule = rule as CSSStyleRule;

            if (typeof styleRule === 'object' && typeof styleRule.cssText !== 'undefined' && typeof styleRule.selectorText !== 'undefined') {
              const currentMediaQueries = getCrossMailClientRuleMediaQueries(styleRule);

              if (currentMediaQueries) {
                finalDynamiquesStylesMediaQueries += `${currentMediaQueries}\n`;
              }
            }
          });
        }
      });
    }

    // Check inline styles ckeditor for font-size
    const spmBodyElement = template.querySelector('#spm_body');

    if (spmBodyElement && spmBodyElement.hasAttribute('data-mobilefontsizeincrease') && spmBodyElement.getAttribute('data-mobilefontsizeincrease') !== '0px') {
      const ckEditorElements = template.querySelectorAll('.spm_rich_editor span[style*="font-size"]');
      (ckEditorElements ?? []).forEach((element) => {
        const currentElement = element as HTMLElement;
        let currentSpmClass = currentElement.getAttribute('class');
        const currentStyle = currentElement.getAttribute('style');
        const currentId = currentElement.closest('.spm_draggable_widget')?.id;

        if (currentStyle && currentStyle.match(/font-size:\s?[0-9]*px?/i)) {
          if (!currentSpmClass || !currentSpmClass.match(/spm_inline_widdget/)) {
            currentSpmClass = `spm_inline_widdget_${generateUniqStructureId()}`;
          } else {
            const matchRule = currentSpmClass.match(/spm_inline_widdget_\w*/);
            if (matchRule && typeof matchRule[0] === 'string') {
              [currentSpmClass] = matchRule;
            } else {
              currentSpmClass = `spm_inline_widdget_${generateUniqStructureId()}`;
            }
          }

          // Add class to the element
          currentElement.classList.add(currentSpmClass);

          const matchFontSize = currentStyle.match(/font-size:\s?([0-9]*)px?/i);

          if (matchFontSize && typeof matchFontSize[1] !== 'undefined') {
            const desktopFontSize = parseInt(matchFontSize[1], 10);
            const mobileFontSizeIncrease = parseInt(spmBodyElement.getAttribute('data-mobilefontsizeincrease') ?? '1', 10);
            const mobileFontSize = desktopFontSize + mobileFontSizeIncrease;
            finalDynamiquesStylesMediaQueries += `#${currentId} .${currentSpmClass} {font-size:${mobileFontSize}px!important}\n`;
          }
        }
      });

      // force create new stylesheet
      template.querySelectorAll("style[data-spm-styles='dynamic_persistent_mobile']").forEach((e) => e.remove());

      // Update CSS media queries stylesheet to add font-size rules
      if (finalDynamiquesStylesMediaQueries !== '') {
        const stylesheet = createNewStylesheet('dynamic_persistent_mobile', TemplateEditorState.template?.content.design.toString());
        finalDynamiquesStylesMediaQueries = finalDynamiquesStylesMediaQueries.replace(/table\[id="spm_body"]/ig, '#spm_body');
        finalDynamiquesStylesMediaQueries = finalDynamiquesStylesMediaQueries.replace(/.row:not\(\.spm_no_responsive\)/ig, '.row.spm_responsive');
        if (finalDynamiquesStylesMediaQueries.match(/#spm_body \.row\.spm_responsive\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*>\s*\.columns,[^}]*display:\s*block\s*!important;/ig)) {
          // eslint-disable-next-line max-len
          finalDynamiquesStylesMediaQueries = finalDynamiquesStylesMediaQueries.replace(/(#spm_body \.row\.spm_responsive\s*>\s*tbody\s*>\s*tr\s*>\s*td\s*>\s*\.columns,[^{]*)([^}]*)(display:\s*block\s*!important;)/ig, '$1, #spm_body .row.spm_responsive > tbody > tr > td > .columns > tbody > tr > .column table $2 display: table !important;');
        }
        if (finalDynamiquesStylesMediaQueries.match(/#spm_body\s*\.hide-for-small,\s*#spm_body\s*\.show-for-desktop\s*\{/ig)) {
          // eslint-disable-next-line max-len
          finalDynamiquesStylesMediaQueries = finalDynamiquesStylesMediaQueries.replace(/(#spm_body\s*\.hide-for-small,\s*#spm_body\s*\.show-for-desktop)\s*\{/ig, '$1, #spm_body .row.spm_responsive > tbody > tr > td > .columns > tbody > tr > .column table.hide-for-small, #spm_body .row.spm_responsive > tbody > tr > td > .columns > tbody > tr > .column table.show-for-desktop {');
        }

        finalDynamiquesStylesMediaQueries = `\n@media only screen and (max-width: 599px) {\n${finalDynamiquesStylesMediaQueries}\n}\n`;
        stylesheet.innerHTML = finalDynamiquesStylesMediaQueries;
      }

      // Update sections in state to save the new CSS rules for design section
      updateSectionsInState();
    }
  }
};

/**
 * Return the complete CSS rules for an element (as string)
 * @param elementId
 * @param type
 * @param deleteOld
 * @param excludeId
 */
export const getCssOfElement = (elementId: string, type = 'dynamic', deleteOld = false, excludeId = null): string => {
  const template = getTemplateIframeDocument();

  const reg = new RegExp(`[.#]${elementId}([ ,>{.](\\.[a-zA-Z0-9_,-]*)?)`, 'gm');
  let css = '';

  if (template.styleSheets.length) {
    Array.from(template.styleSheets).forEach((stylesheet: CSSStyleSheet) => {
      const owner = stylesheet.ownerNode as HTMLElement;

      if (owner && owner.getAttribute('data-spm-styles') === type) {
        const rules = stylesheet.cssRules;
        let updateDom = false;
        let currentStyleSheet = '';
        let reconstructDom = false;

        if (rules.length) {
          Array.from(rules).forEach((rule) => {
            if (rule.constructor.name === 'CSSStyleRule' && typeof (rule as CSSStyleRule).selectorText !== 'undefined') {
              const currentSelector = `${(rule as CSSStyleRule).selectorText
                .replace(/(?:\r\n|\r|\n)/g, ' ')
                .replace(/(?:,)/g, ', ')
                .replace(/(?:, {2,})/g, ', ')
                .replace(/(?: {2,})/g, ' ')} `;

              if (currentSelector.match(reg)) {
                // Check if we need to exclude the rule
                if (excludeId && typeof excludeId === 'string') {
                  const regExclude = new RegExp(`[.#]${excludeId}([ ,>{.](\\.[a-zA-Z0-9_,-]*)?)`, 'gm');
                  if (currentSelector.match(regExclude)) {
                    return;
                  }
                }

                const style = rule.cssText;
                css += `${getCrossMailClientRule(style)}\n`;

                if (typeof deleteOld !== 'undefined' && deleteOld) {
                  if (rule.cssText.match(/font-size|max-width/)) {
                    reconstructDom = true;
                  }

                  updateDom = true;
                }
              } else {
                currentStyleSheet += `${rule.cssText}\n`;
              }
            } else if (rule.constructor.name === 'CSSMediaRule' && typeof (rule as CSSMediaRule).cssRules === 'object' && (rule as CSSMediaRule).cssText.match(/@media /)) {
              currentStyleSheet += `@media ${(rule as CSSMediaRule).conditionText} {\n`;

              if ((rule as CSSMediaRule).cssRules.length) {
                Array.from((rule as CSSMediaRule).cssRules).forEach((mediaQueryRule) => {
                  if (mediaQueryRule.constructor.name === 'CSSStyleRule' && typeof (mediaQueryRule as CSSStyleRule).selectorText !== 'undefined') {
                    const currentSelector = `${(mediaQueryRule as CSSStyleRule).selectorText
                      .replace(/(?:\r\n|\r|\n)/g, ' ')
                      .replace(/(?:,)/g, ', ')
                      .replace(/(?:, {2,})/g, ', ')
                      .replace(/(?: {2,})/g, ' ')} `;

                    if (currentSelector.match(reg)) {
                      // Check if we need to exclude the rule
                      if (excludeId && typeof excludeId === 'string') {
                        const regExclude = new RegExp(`[.#]${excludeId}([ ,>{.](\\.[a-zA-Z0-9_,-]*)?)`, 'gm');
                        if (currentSelector.match(regExclude)) {
                          return;
                        }
                      }

                      const style = rule.cssText;
                      css += `${getCrossMailClientRule(style)}\n`;

                      if (typeof deleteOld !== 'undefined' && deleteOld) {
                        if (rule.cssText.match(/font-size|max-width/)) {
                          reconstructDom = true;
                        }

                        updateDom = true;
                      }
                    } else {
                      currentStyleSheet += `${rule.cssText}\n`;
                    }
                  }
                });
              }

              currentStyleSheet += '}\n';
            }
          });

          if (updateDom) {
            // eslint-disable-next-line max-len
            const styleElement: HTMLElement | null = template.querySelector(`style[data-spm-section='${owner.getAttribute('data-spm-section')}'][data-spm-styles='${owner.getAttribute('data-spm-styles')}']`);

            if (styleElement) {
              styleElement.innerHTML = currentStyleSheet.replace(/\n$/, '');
            }

            if (isEmailTemplate(TemplateEditorState.template?.type) && reconstructDom) {
              reconstructDynamicPersistentMobileCss();
            }
          }
        }
      }
    });
  }

  return css;
};

export const addSelectorToRules = (cssString: string, selector: string): string => {
  const processElement = (cssRules: string) => {
    // Split the CSS string by '}' to separate individual rules
    const rules = cssRules.split('}');

    // Add the selector to each rule
    const formattedRules = rules.map((rule: string) => {
      // Trim the rule and remove leading/trailing whitespace
      rule = rule.trim();
      if (rule) {
        const currentSelector = rule.match(/^.{1,}\{/g);
        if (currentSelector && currentSelector.length) {
          const regex = /(?<![^|\s|,|>,|+|~])body(?=[$|\s|{|,])/g;
          const matched = currentSelector[0].match(regex);
          if (matched && matched.length) {
            return `${currentSelector[0].replace('{', '')}, ${currentSelector[0].replace(/(?<![^|\s|,|>,|+|~])body(?=[$|\s|{|,])/g, selector)} ${rule.replace(/^.{1,}\{/g, '')}}`;
          }
        }
        // Add the selector before the rule
        return `${selector} ${rule}}`;
      }
      return '';
    });

    // Join the formatted rules back together with '}' and return
    return formattedRules.join('').trim();
  };

  const mediaQueryRegex = /@media[^{]+{([^{}]*({[^{}]*}[^{}]*)*)}/g;

  if (cssString.match(mediaQueryRegex)) {
    let mqMatch;
    let newCssString = '';
    // eslint-disable-next-line no-cond-assign
    while ((mqMatch = mediaQueryRegex.exec(cssString)) !== null) {
      const rules = mqMatch[1].trim().replace(/\s\s+/g, ' ').replace(/\s*([{}])\s*/g, '$1');
      if (!newCssString) {
        newCssString = '';
      }
      const newRules = processElement(rules);
      const media = mqMatch[0].match(/@media\s*\([^)]*\)/g);
      if (media && Array.isArray(media) && media.length) {
        newCssString += `${media[0]}{${newRules}}`.trim();
      }
    }

    return newCssString;
  }

  return processElement(cssString);
};

export const getMimeTypeFromName = (name: string): string => {
  const extension = name.split('.').pop()?.toLowerCase();
  if (!extension) return 'image/jpeg';

  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    case 'bmp':
      return 'image/bmp';
    case 'svg':
      return 'image/svg+xml';
    default:
      return 'image/jpeg';
  }
};

export const getBase64FromUrl = (url: string): Promise<string> => new Promise((resolve, reject) => {
  const img = new Image();
  img.setAttribute('crossOrigin', 'anonymous');
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Failed to get canvas 2d context'));
      return;
    }

    ctx.drawImage(img, 0, 0);
    const mimetype = getMimeTypeFromName(url);
    const base64String = canvas.toDataURL(mimetype);
    resolve(base64String);
  };
  img.onerror = () => {
    reject(new Error('Failed to load image'));
  };
  img.src = url;
});

export const getTranslationIdFromWidget = (widget: Element) => {
  const $ = cheerio.load(widget.innerHTML);

  const translations = $('body').find(TRANSLATION_MARKUP);

  if (translations && translations.length) {
    const translation = translations.eq(0);
    const translationId = translation.attr('id');
    if (translationId) {
      return translationId.replace('LANG_', '');
    }
  }
  const attributeRegex = new RegExp(`(${TRANSLATION_ATTRIBUTE}-[^=]*)=`, 'gm');
  const matches = $('body').html()?.match(attributeRegex);
  if (matches && matches.length) {
    const attribute = matches[0].replace('=', '');
    const elements = $('body').find(`[${attribute}]`);
    if (elements && elements.length) {
      const translation = elements.eq(0);
      const translationId = translation.attr(attribute);
      if (translationId) {
        return translationId.replace('LANG_', '');
      }
    }
  }
  return '';
};

export const getTranslationLabel = (t: Function, translation: Translation) => {
  if (translation.fieldType === 'text') {
    const keys = translation.key.split('.');
    if ((keys.length > 1 && keys[1] === 'text') || keys.length === 1) {
      return t('templateBuilder.fields.text');
    }
    if (keys.length > 1 && keys[1] !== 'test') {
      return `${t('templateBuilder.fields.attribute')} ${translation.key.split('.')[1]}`;
    }
  }
  if (translation.fieldType === 'textarea') {
    return t('templateBuilder.fields.text');
  }
  if (translation.fieldType === 'link') {
    return t('templateBuilder.fields.linkUrl');
  }
  if (translation.fieldType === 'html') {
    return t('templateBuilder.fields.html');
  }
  if (translation.fieldType === 'image') {
    return t('templateBuilder.fields.imageUrl');
  }
  if (translation.fieldType === 'placeholder') {
    return t('templateBuilder.fields.placeholder');
  }
  if (translation.fieldType === 'data-redirect') {
    return t('templateBuilder.fields.dataRedirect');
  }
  if (translation.fieldType === 'attribute' && translation.attributeName) {
    return `${t('templateBuilder.fields.attribute')} ${translation.attributeName}`;
  }
  return `${t('templateBuilder.fields.attribute')} ${translation.key.split('.')[1]}`;
};

export const isElementVisible = (htmlElement: Element) => {
  if (!htmlElement) {
    return false;
  }

  let isSpmRegistrationPassword = htmlElement.id === 'spm_form_registration_password';
  let elementParent = htmlElement.parentElement;
  if (!isSpmRegistrationPassword) {
    while (elementParent) {
      if (elementParent.classList.contains('spm_form_password')) {
        isSpmRegistrationPassword = true;
        break;
      }
      elementParent = elementParent.parentElement;
    }
  }

  if (!isSpmRegistrationPassword) {
    const style = window.getComputedStyle(htmlElement);
    if (style.visibility === 'hidden' || style.display === 'none') {
      return false;
    }

    let parent = htmlElement.parentElement;
    while (parent) {
      const parentStyle = window.getComputedStyle(parent);
      if (parentStyle.visibility === 'hidden' || parentStyle.display === 'none') {
        return false;
      }
      parent = parent.parentElement;
      if (parent && (parent.classList.contains('spm_content_wrapper') || parent.id === 'spm_body')) {
        return true;
      }
    }
  }

  return true;
};
