// eslint-disable-next-line import/no-cycle
import { List, SaveKeyPair } from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  ShopsConfigurationInputItem, ShopsConfigurationInput, OperatorType, ShopsConfiguration, Maybe,
} from '@/types/generated-types/graphql';
import axios from 'axios';

interface ShopsConfigArgs {
  shopId: number;
  limit: number;
  offset: number;
  key: string;
  fields: string;
  lang?: Maybe<string>;
}

export async function SaveShopsConfiguration(shopConfiguration: ShopsConfigurationInput) {
  const {
    id, messages, err,
  } = await SaveKeyPair<ShopsConfigurationInputItem>({
    name: 'ShopsConfiguration',
    input: shopConfiguration,
    type: 'ShopsConfigurationInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
}

export const GetShopsConfigurationList = async ({
  shopId, key, fields, limit, offset, lang = null,
}: ShopsConfigArgs): Promise<Array<ShopsConfiguration>|null> => {
  const filters = [
    {
      field: 'id_shop',
      operator: OperatorType.Equals,
      value: shopId,
    },
    {
      field: 'key',
      operator: OperatorType.Like,
      value: key,
    },
  ];

  if (lang) {
    filters.push({
      field: 'lang',
      operator: OperatorType.Equals,
      value: lang,
    });
  }

  const { items, err } = await List<ShopsConfiguration>({
    name: 'ShopsConfiguration',
    settings: {
      filter: filters,
      order: [
        { field: 'id_shop_configuration', type: 'ASC' },
      ],
      limit,
      offset,
    },
    fields: [fields],
  });

  if (err === '') {
    return items;
  }
  return null;
};

export const GetProductSellingsList = async (shopId: number, key: string, fields: string): Promise<Array<ShopsConfiguration>|null> => {
  const filters = [
    {
      field: 'id_shop',
      operator: OperatorType.Equals,
      value: shopId,
    },
    {
      field: 'key',
      operator: OperatorType.In,
      value: key,
    },
  ];

  const { items, err } = await List<ShopsConfiguration>({
    name: 'ShopsConfiguration',
    settings: {
      filter: filters,
      order: [
        { field: 'id_shop_configuration', type: 'ASC' },
      ],
      limit: 0,
      offset: 0,
    },
    fields: [fields],
  });

  if (err === '') {
    return items;
  }
  return null;
};

export async function saveFacebookParamsOnRedis(idShop: number, type: string): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/page/facebook/redis', {
    id_shop: idShop,
    type,
  });
}

export async function saveExclusions(exclusionObject: any, idShop: number): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/smart-list/exclusions', {
    exclusions_object: exclusionObject,
    id_shop: idShop,
  });
}

export async function saveShopParamsOnRedis(idShop: number): Promise<any|null> {
  return axios.create({ baseURL: '/api' }).post<any>('/shop_params/save_redis', {
    id_shop: idShop,
  });
}
