// eslint-disable-next-line import/no-cycle
import {
  addTranslationsToState,
  replaceTranslationInState,
  replaceTranslationInStateAllLanguages,
  removeTranslation,
  TemplateEditorState as state,
  fixMissingTranslationsForShopLanguages,
  cleanUpTranslations,
} from '@/composables/template-editor/TemplateEditor';
import {
  Lang,
  UserState,
  getShopDefaultLang,
} from '@/composables/User';
import { Translation, Property, ActionTypeEnum } from '@/types';
import axios from 'axios';
import { br2nl, getParents, nl2br } from '@/helpers';

import * as cheerio from 'cheerio';

// eslint-disable-next-line import/no-cycle
import {
  computeAttribute,
  getTemplateIframeDocument,
  isManagedByAttribute,
} from './helpers';
import {
  TEMPLATE_SECTION_CLASS,
  TRANSLATION_ATTRIBUTE,
  TRANSLATION_MARKUP,
  TEMPLATE_TRANSLATE_SPECIFIC,
  TEMPLATE_TRANSLATE_SPECIFIC_ITEMS,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE,
  TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT,
  TEMPLATE_TRANSLATE_SPECIFIC_HTML_CONTENT,
  TRANSLATION_EXCLUDE_IDS,
} from './constants';

export const updateIframeTranslation = (translation: Translation) => {
  const template = getTemplateIframeDocument();
  if (template) {
    if (isManagedByAttribute(translation)) { // translate attribute
      const attr = computeAttribute(translation.key, translation.fieldType);
      (Array.from(template.body.querySelectorAll(`[${TRANSLATION_ATTRIBUTE}-${attr}='LANG_${translation.key}']`)) as HTMLElement[])
        .forEach((item: HTMLElement) => {
          item.setAttribute(`${attr}`, `${translation.value}`);
          // Check for data-redirect
          if (attr === 'data-redirect') {
            let hasClassRedirect = false;
            const actionTypes = Object.values(ActionTypeEnum).map((actionType) => `spm_action_${actionType}`);
            // eslint-disable-next-line no-restricted-syntax
            for (const actionType of actionTypes) {
              if (item.classList.contains(actionType)) {
                hasClassRedirect = true;
                item.classList.remove(actionType);
                break;
              }
            }
            if (hasClassRedirect && translation.value.match(new RegExp('#redirect_'))) {
              item.classList.add(`spm_action_${ActionTypeEnum.DISPLAY_TEMPLATE}`);
            } else if (hasClassRedirect && !translation.value.match(new RegExp('#redirect_'))) {
              item.classList.add(`spm_action_${ActionTypeEnum.REDIRECT}`);
            }
          }
        });
    } else { // translate content
      (Array.from(template.body.querySelectorAll(`${TRANSLATION_MARKUP}[id='LANG_${translation.key}']`)) as HTMLElement[])
        // eslint-disable-next-line no-param-reassign
        .forEach((item: HTMLElement) => { item.innerHTML = translation.value; });
    }
  }
};

export const updateIframeTranslations = (translations: Translation[]) => {
  translations
    .forEach((translation: Translation) => updateIframeTranslation(translation));
};

/**
 * Search for translations in element that are not already in the state
 * @param element
 */
export const searchMissingTranslations = (element: HTMLElement): Translation[] => {
  // We search for translations in HTML not in translations object
  let translationToAddToState: Translation[] = [];
  const regExpMissingTranslationsAttributes = new RegExp(`${TRANSLATION_ATTRIBUTE}-([^=]+)="LANG_([^"]+)"`, 'g');
  const regExpMissingTranslationsMarkups = new RegExp(`<${TRANSLATION_MARKUP} id="LANG_([^"]+)">([^<]+)</${TRANSLATION_MARKUP}>`, 'g');
  const matchesTranslationsAsAttributes = element.innerHTML.matchAll(regExpMissingTranslationsAttributes);
  const matchesTranslationsAsMarkups = element.innerHTML.matchAll(regExpMissingTranslationsMarkups);

  Array.from(matchesTranslationsAsMarkups).forEach((match) => {
    const key = match[1];
    const valueOfTranslation = match[2];
    const found = translationToAddToState.filter((translation) => translation.key === key);

    if (found.length === 0) {
      // Key is not in the array of translations to add to state
      const elementWithTranslation = element.querySelector(`${TRANSLATION_MARKUP}[id="LANG_${key}"]`);

      let callback = (value: string) => value;
      let translationValue = valueOfTranslation;
      if (state.template?.type === 'sms') {
        callback = (value: string) => nl2br(value, true);
        translationValue = br2nl(valueOfTranslation);
      }

      let sectionId = 0;
      const section = elementWithTranslation?.closest('[data-spmelementid]');

      if (section) {
        sectionId = Number(section.getAttribute('data-spmelementid'));
      }

      // Create translations for each language of current shop
      const translationsToAdd: Translation[] = UserState.activeShop?.langs.map((language: Lang) => ({
        section: sectionId ?? 0,
        language: language.id,
        fieldType: 'text',
        key,
        value: translationValue,
        callback,
      })) ?? [];

      // Merge with translations to add
      translationToAddToState = [...translationToAddToState, ...translationsToAdd];
    }
  });

  // Loop on matched translations as attributes
  Array.from(matchesTranslationsAsAttributes).forEach((match) => {
    const attribute = match[1];
    const key = match[2];
    const found = translationToAddToState.filter((translation) => translation.key === key);

    if (found.length === 0) {
      // Key is not in the array of translations to add to state
      const elementWithTranslation = element.querySelector(`[${TRANSLATION_ATTRIBUTE}-${match[1]}="LANG_${key}"]`);
      let valueOfTranslation = '';

      if (elementWithTranslation && elementWithTranslation.hasAttribute(`${match[1]}`)) {
        valueOfTranslation = elementWithTranslation.getAttribute(`${match[1]}`) ?? '';
      }

      let fieldType = 'text';

      switch (attribute) {
        case 'href':
          fieldType = 'link';
          break;
        case 'src':
          fieldType = 'image';
          break;
        case 'data-redirect':
        case 'placeholder':
          fieldType = attribute;
          break;
        default:
          break;
      }

      let callback = (value: string) => value;
      let translationValue = valueOfTranslation;
      if (valueOfTranslation.match(/^base64:/)) {
        callback = (value: string) => `base64:${btoa(value)}`;
        translationValue = atob(valueOfTranslation.replaceAll(/base64:/g, ''));
      }
      if (state.template?.type === 'sms') {
        callback = (value: string) => nl2br(value, true);
        translationValue = br2nl(valueOfTranslation);
      }

      let sectionId = 0;
      const section = getParents(elementWithTranslation, `.${TEMPLATE_SECTION_CLASS}`);

      if (section && section.length > 0) {
        sectionId = section[0].getAttribute('data-spmelementid');
      }

      if (sectionId > 0) {
        // Create translations for each language of current shop
        const translationsToAdd: Translation[] = UserState.activeShop?.langs.map((language: Lang) => ({
          section: sectionId ?? 0,
          language: language.id,
          fieldType,
          key,
          value: translationValue,
          callback,
        })) ?? [];

        // Merge with translations to add
        translationToAddToState = [...translationToAddToState, ...translationsToAdd];
      }
    }
  });

  return translationToAddToState.filter(
    (translation: any) => (state.template && !state.template.translations.find((stateTranslation) => stateTranslation.key === translation.key)) || (!state.template && true),
  );
};

export const translateDocument = (language: string, element: HTMLElement | null = null) => {
  let currentElement: HTMLElement;
  if (element === null) {
    const template = getTemplateIframeDocument();
    currentElement = template.body;
  } else {
    currentElement = element;
  }

  const missingTranslations = searchMissingTranslations(currentElement);

  (state.template?.translations ?? [])
    .filter((translation: Translation) => translation.language === language)
    .forEach((translation: Translation) => {
      if (isManagedByAttribute(translation)) { // insert attribute
        const attr = computeAttribute(translation.key, translation.fieldType);
        const item = currentElement.querySelector(`[${attr}='{LANG_${translation.key}}']`);
        if (item) {
          item.setAttribute(`${TRANSLATION_ATTRIBUTE}-${attr}`, `LANG_${translation.key}`);
          item.setAttribute(`${attr}`, translation.value);
        }
      } else { // insert markup
        // eslint-disable-next-line no-lonely-if
        if (currentElement.innerHTML.match(`{LANG_${translation.key}}`)) {
          currentElement.innerHTML = currentElement.innerHTML
            .replace(`{LANG_${translation.key}}`,
              `<${TRANSLATION_MARKUP} id="LANG_${translation.key}">${translation.value}</${TRANSLATION_MARKUP}>`);
        }
      }
    });

  if (missingTranslations.length) {
    addTranslationsToState(missingTranslations);
  }
};

export const createTranslationIdentifier = (suffix = '') => {
  let identifier = Math.floor(Math.random() * Math.floor(Math.random() * Date.now())).toString(16)
    + Math.floor(Math.random() * Math.floor(Math.random() * Date.now())).toString(16);

  if (suffix) {
    identifier += `.${suffix}`;
  }

  return identifier;
};

interface TranslationCreationResult {
  attribute?: string;
  markup?: string;
  id?: string;
  value: string;
}

export const createTranslation = (suffix = '', type = 'text', defaultValue = '', returnObject = false): TranslationCreationResult | string => {
  // generate unique key
  const identifier = createTranslationIdentifier(suffix);
  // create Translation object for each language
  const translations: Translation[] = UserState.activeShop?.langs.map((language: Lang) => ({
    section: state.template?.activeSection ?? 0,
    language: language.id,
    fieldType: type,
    key: identifier,
    value: defaultValue,
    callback: (value: string) => value,
  })) ?? [];

  addTranslationsToState(translations);
  if (isManagedByAttribute(translations[0])) { // return attribute
    const attr = computeAttribute(translations[0].key, translations[0].fieldType);
    return returnObject ? {
      attribute: `${TRANSLATION_ATTRIBUTE}-${attr}`,
      value: `LANG_${identifier}`,
    } : `${TRANSLATION_ATTRIBUTE}-${attr}="LANG_${identifier}"`;
  }
  // return markup
  return returnObject ? {
    markup: TRANSLATION_MARKUP,
    id: `LANG_${identifier}`,
    value: defaultValue,
  } : `<${TRANSLATION_MARKUP} id="LANG_${identifier}">${defaultValue}</${TRANSLATION_MARKUP}>`;
};

// Remove translation markup if exists as a direct child of the element, otherwise return the element itself
export const sanitizeTranslationMarkup = (element: HTMLElement): HTMLElement => {
  const children = element.querySelectorAll(`:scope ${TRANSLATION_MARKUP}`);

  if (children && children.length) {
    return children[0] as HTMLElement;
  }

  return element as HTMLElement;
};

/**
 * Translate a string from a language to another
 * @param translationValue
 * @param toLanguage
 */
export const translateFromGoogle = async (translationValue: string, toLanguage: string): Promise<string> => {
  const newLanguage = toLanguage === 'gb' ? 'en' : toLanguage;
  // Translate only if not empty and not just containing a variable
  if (translationValue && translationValue.trim() !== '' && !/^\{[A-Z_]+}$/.test(translationValue)) {
    const data = {
      key: 'AIzaSyCCeaBJUffKPh1-Qak2xC-yd38f2TPCDOA',
      q: translationValue,
      target: newLanguage,
      format: 'html',
    };

    const params = new URLSearchParams(data);

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    let result;
    try {
      result = await axios.get(`https://www.googleapis.com/language/translate/v2/?${params}`, { headers });
    } catch (e) {
      // Do nothing
    }

    if (result) {
      return result.data.data.translations[0].translatedText;
    }
  }

  return translationValue;
};

export const updateTranslation = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const elements = template.body.querySelectorAll(selector);

  const translationKeys: Record<string, any> = {};

  Array.from(elements).forEach((element) => {
    if (element.childNodes) {
      const childNodes = Array.from(element.childNodes);
      const childNode = childNodes.find((item) => {
        const child = item as Element;
        if (child.attributes && 'data-spmtranslationid-placeholder' in child.attributes) {
          return true;
        }
        return false;
      });
      if (childNode) {
        const childNodeId = (childNode as Element).getAttribute('id');
        const field = property.value.find((item: any) => item.key === childNodeId);
        const attribute = (childNode as Element).getAttribute('data-spmtranslationid-placeholder');
        if (attribute && field) {
          if (!translationKeys[attribute.replace('LANG_', '')]) {
            translationKeys[attribute.replace('LANG_', '')] = {};
          }
          translationKeys[attribute.replace('LANG_', '')].required = field.required;
          const attributeValue = (childNode as Element).getAttribute('placeholder');
          if (attributeValue) {
            translationKeys[attribute.replace('LANG_', '')].value = attributeValue;
          }
        }
      }
    }
  });

  Object.keys(translationKeys).forEach((translationKey) => {
    if (state.template) {
      const translations = state.template.translations.filter((item) => item.key === translationKey);
      translations.forEach((translation) => {
        const isRequired = translationKeys[translationKey].required;
        let translationValue = translationKeys[translationKey].value || translation.value;
        if (isRequired && translationValue.charAt(translationValue.length - 1) !== '*') {
          translationValue = `${translationValue}*`;
        } else if (!isRequired && translationValue.charAt(translationValue.length - 1) === '*') {
          translationValue = translationValue.slice(0, -1);
        }
        replaceTranslationInState(translationKey, translation.language, translationValue);
      });
    }
  });
};

function insertAttributeTranslation(currentElement: cheerio.Cheerio<cheerio.Node>, translation: Translation) {
  const attr = computeAttribute(translation.key, translation.fieldType);
  const item = currentElement.find(`[${attr}='{LANG_${translation.key}}']`);
  if (item.length > 0) {
    item.attr(`${TRANSLATION_ATTRIBUTE}-${attr}`, `LANG_${translation.key}`);
    item.attr(`${attr}`, translation.value);
  }
}

function insertMarkupTranslation(currentElement: cheerio.Cheerio<cheerio.Node>, translation: Translation) {
  const langKeyRegex = new RegExp(`{LANG_${translation.key}}`, 'g');
  const currentHTML = currentElement.html() || '';
  if (currentHTML.match(langKeyRegex)) {
    const markup = `<${TRANSLATION_MARKUP} id="LANG_${translation.key}">${translation.value}</${TRANSLATION_MARKUP}>`;
    const newHTML = currentHTML.replace(langKeyRegex, markup);
    currentElement.html(newHTML);
  }
}

export const translateElement = (element: cheerio.Cheerio<cheerio.Node>, translations: Translation[], defaultLanguage: string) => {
  translations.filter((translation: Translation) => translation.language === defaultLanguage)
    .forEach((translation: Translation) => {
      if (isManagedByAttribute(translation)) {
        insertAttributeTranslation(element, translation);
      } else {
        insertMarkupTranslation(element, translation);
      }
    });
};

interface ProcessOptions {
  ignoreTable?: boolean;
  onlyContent?: boolean;
  onlyAttributes?: boolean;
}

interface ElementAttribute {
  name: string;
  type: string;
  data: Function;
}

const elementAttributes: ElementAttribute[] = [
  {
    name: 'src',
    type: 'image',
    data: (value: any) => ({
      src: {
        type: 'image',
        value,
      },
    }),
  },
  {
    name: 'alt',
    type: 'text',
    data: (value: any) => ({
      alt: {
        type: 'text',
        value,
      },
    }),
  },
  {
    name: 'placeholder',
    type: 'placeholder',
    data: (value: any) => (
      {
        type: 'placeholder',
        value,
      }
    ),
  },
  {
    name: 'href',
    type: 'link',
    data: (value: any) => ({
      href: {
        type: 'link',
        value,
      },
    }),
  },
  {
    name: 'title',
    type: 'text',
    data: (value: any) => ({
      title: {
        type: 'text',
        value,
      },
    }),
  },
  {
    name: 'data-redirect',
    type: 'data-redirect',
    data: (value: any) => (
      {
        type: 'data-redirect',
        value,
      }
    ),
  },
  {
    name: 'srcset',
    type: 'image',
    data: (value: any) => ({
      srcset: {
        type: 'image',
        value,
      },
    }),
  },
];

interface GenerateTranslationsOptions {
  newElement?: boolean;
  fullHtml?: boolean;
  translateVariables?: boolean;
  globalTranslate?: boolean;
  ignoreSpecificItems?: boolean;
  checkDiv?: boolean;
}

export const generateTranslations = (
  html: string,
  {
    newElement = false,
    fullHtml = false,
    translateVariables = true,
    globalTranslate = false,
    ignoreSpecificItems = false,
    checkDiv = true,
  }: GenerateTranslationsOptions = {},
) => {
  const $ = cheerio.load(html);

  let langs = ['fr'];
  if (UserState.activeShop && Array.isArray(UserState.activeShop.langs) && UserState.activeShop.langs.length) {
    langs = UserState.activeShop.langs.map((lang) => lang.id);
  }

  let translations: any[] = [];
  const sectionIds: Record<string, any> = {};

  const addSectionId = (htmlElement: cheerio.Cheerio<cheerio.Node>, key: string) => {
    const section = htmlElement.closest('[data-spmelementid]');
    if (section) {
      const sectionId = section.attr('data-spmelementid');
      if (sectionId) {
        sectionIds[key] = parseInt(sectionId, 10);
      }
    }
  };

  const addToTranslations = (key: string, normalizedKey: string, fieldType: string, fieldValue: any, value: any) => {
    translations.push({
      key,
      normalizedKey,
      fieldType,
      fieldValue,
      value,
    });
  };

  const checkVariableRegex = /^\{var=[^}]+\}$/g;

  const processElement = (
    element: cheerio.Node,
    {
      ignoreTable = false,
      onlyContent = false,
      onlyAttributes = false,
    }: ProcessOptions = {},
  ) => {
    const htmlElement = $(element);

    const elementType = (element as unknown as cheerio.Element).tagName?.toLowerCase();

    if (!TRANSLATION_EXCLUDE_IDS.includes(htmlElement.attr('id') || '')) {
      // This condition prevent translated element to be translated twice
      if (elementType !== TRANSLATION_MARKUP) {
        if (globalTranslate) {
          const key = createTranslationIdentifier();
          addToTranslations(
            key,
            `${key}`,
            'html',
            htmlElement.html(),
            {
              type: 'html',
              value: htmlElement.html(),
            },
          );
          htmlElement.html(`{LANG_${key}}`);
          addSectionId(htmlElement, key);
        } else {
          // eslint-disable-next-line no-lonely-if
          if (element.nodeType === Node.TEXT_NODE && htmlElement.text().replace(/&nbsp;/g, ' ').trim()) {
            const doTextTranslation = () => {
              const key = createTranslationIdentifier();
              addToTranslations(
                key,
                `${key}.text`,
                'text',
                htmlElement.text(),
                {
                  text: {
                    type: 'text',
                    value: htmlElement.text(),
                  },
                },
              );
              htmlElement.replaceWith(`{LANG_${key}.text}`);
              addSectionId(htmlElement, `${key}.text`);
            };
            if (translateVariables) {
              doTextTranslation();
            } else if (!translateVariables && !htmlElement.text().trim().match(checkVariableRegex)) {
              doTextTranslation();
            }
          } else if (element.nodeType === Node.ELEMENT_NODE && !htmlElement.hasClass('spm_hidden')) {
            // Translate attributes
            const translateAttributes = () => {
              // eslint-disable-next-line no-restricted-syntax
              for (const elementAttribute of elementAttributes) {
                const attr = htmlElement.attr(elementAttribute.name);
                if (attr !== undefined && htmlElement.attr(`${TRANSLATION_ATTRIBUTE}-${elementAttribute.name}`) === undefined && !attr.match(/{LANG_([a-z0-9]+)\.?\w*}/g)) {
                  const doAttributeTranslation = () => {
                    const key = createTranslationIdentifier();
                    addToTranslations(
                      key,
                      elementAttribute.name !== 'data-redirect' ? `${key}.${elementAttribute.name}` : key,
                      elementAttribute.type,
                      htmlElement.attr(elementAttribute.name),
                      elementAttribute.data(htmlElement.attr(elementAttribute.name)),
                    );
                    htmlElement.attr(elementAttribute.name, `{LANG_${key}.${elementAttribute.name}}`);
                    addSectionId(htmlElement, elementAttribute.name !== 'data-redirect' ? `${key}.${elementAttribute.name}` : key);
                  };
                  if (translateVariables) {
                    doAttributeTranslation();
                  } else if (!translateVariables && !htmlElement.attr(elementAttribute.name)?.match(checkVariableRegex)) {
                    doAttributeTranslation();
                  }
                }
              }
            };

            // Translate contents
            const translateContent = () => {
              if (htmlElement.hasClass(TEMPLATE_TRANSLATE_SPECIFIC_HTML_CONTENT)) {
                const key = createTranslationIdentifier();
                addToTranslations(
                  key,
                  `${key}`,
                  'html',
                  htmlElement.html(),
                  {
                    type: 'html',
                    value: htmlElement.html(),
                  },
                );
                htmlElement.html(`{LANG_${key}}`);
                addSectionId(htmlElement, key);
              } else {
                htmlElement.contents().each((index, child) => {
                  const childElement = $(child);
                  if (child.nodeType === Node.TEXT_NODE && childElement.text().replace(/&nbsp;/g, ' ').trim()) {
                    processElement(child, { ignoreTable, onlyContent, onlyAttributes });
                  } else if (child.nodeType === Node.ELEMENT_NODE) {
                    processElement(child, { ignoreTable, onlyContent, onlyAttributes });
                  }
                });
              }
            };

            const handleElementTranslationTypeHtml = (htmlElementToHandle: cheerio.Cheerio<cheerio.Node>, ignoreTableTag = false, checkOnlyText = false) => {
              let childrenCount = 0;
              let textCount = 0;

              htmlElementToHandle.contents().each((index, child) => {
                const childElement = $(child);
                const childType = (child as unknown as cheerio.Element).tagName?.toLowerCase();

                if (childType !== 'br' && childType !== 'table' && childType !== 'tbody') {
                  if (child.nodeType === Node.TEXT_NODE && childElement.text().replace(/&nbsp;/g, ' ').trim()) {
                    childrenCount += 1;
                    textCount += 1;
                  } else if (child.nodeType === Node.ELEMENT_NODE && !checkOnlyText) {
                    childrenCount += 1;
                    if (['a', 'label', 'span', 'p', 'strong'].includes(childType) || childType.match(/^h[1-6]$/)) {
                      textCount += 1;
                    }
                  }
                }
              });

              if (childrenCount === 1 || (childrenCount > 1 && textCount === 0)) {
                htmlElementToHandle.contents().each((index, child) => {
                  const childElement = $(child);
                  if (child.nodeType === Node.TEXT_NODE && childElement.text().replace(/&nbsp;/g, ' ').trim()) {
                    processElement(child, { ignoreTable: ignoreTableTag, onlyAttributes, onlyContent });
                  } else if (child.nodeType === Node.ELEMENT_NODE) {
                    processElement(child, { ignoreTable: ignoreTableTag, onlyAttributes, onlyContent });
                  }
                });
              } else if (textCount > 0) {
                if (!htmlElement.html()?.match(RegExp(`<${TRANSLATION_MARKUP}\\b[^>]*>`, 'gi'))) {
                  const key = createTranslationIdentifier();
                  addToTranslations(
                    key,
                    `${key}`,
                    'html',
                    htmlElementToHandle.html(),
                    {
                      type: 'html',
                      value: htmlElementToHandle.html(),
                    },
                  );
                  htmlElementToHandle.html(`{LANG_${key}}`);
                  addSectionId(htmlElement, key);
                }
              }
            };

            if (elementType === 'p' || elementType === 'blockquote') {
              // Prevent element to be translated twice
              if (!htmlElement.html()?.match(RegExp(`<${TRANSLATION_MARKUP}\\b[^>]*>`, 'gi'))) {
                const doHtmlTranslation = () => {
                  const key = createTranslationIdentifier();
                  addToTranslations(
                    key,
                    `${key}`,
                    'html',
                    htmlElement.html(),
                    {
                      type: 'html',
                      value: htmlElement.html(),
                    },
                  );
                  htmlElement.html(`{LANG_${key}}`);
                  addSectionId(htmlElement, key);
                };

                // Check if no text child
                let hasText = false;
                htmlElement.contents().each((indexChild, child) => {
                  const childElement = $(child);
                  if (child.nodeType === Node.TEXT_NODE && childElement.text().replace(/&nbsp;/g, ' ').trim()) {
                    hasText = true;
                  }
                });

                if (!hasText) {
                  htmlElement.contents().each((indexChild, child) => {
                    processElement(child);
                  });
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (translateVariables) {
                    doHtmlTranslation();
                  } else if (!translateVariables && !htmlElement.text().trim().match(checkVariableRegex)) {
                    doHtmlTranslation();
                  }
                }
              }
            } else if (elementType === 'div' && !htmlElement.hasClass('fb_profile_picture')) { // Ignore fb profile picture
              if (htmlElement.attr('data-redirect') !== undefined) {
                const attr = htmlElement.attr('data-redirect');
                if (htmlElement.attr(`${TRANSLATION_ATTRIBUTE}-data-redirect`) === undefined || (attr && !attr.match(/{LANG_([a-z0-9]+)\.?\w*}/g))) {
                  const key = createTranslationIdentifier();
                  addToTranslations(
                    key,
                    `${key}.data-redirect`,
                    'data-redirect',
                    htmlElement.attr('data-redirect'),
                    {
                      type: 'data-redirect',
                      value: htmlElement.attr('data-redirect'),
                    },
                  );
                  htmlElement.attr('data-redirect', `{LANG_${key}.data-redirect}`);
                  addSectionId(htmlElement, `${key}.data-redirect`);
                }
              } else if (checkDiv && htmlElement.hasClass('spm_rich_editor') && !htmlElement.html()?.match(RegExp(`<${TRANSLATION_MARKUP}\\b[^>]*>`, 'gi'))) {
                const key = createTranslationIdentifier();
                addToTranslations(
                  key,
                  `${key}`,
                  'html',
                  htmlElement.html(),
                  {
                    type: 'html',
                    value: htmlElement.html(),
                  },
                );
                htmlElement.html(`{LANG_${key}}`);
                addSectionId(htmlElement, key);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (!checkDiv) {
                  handleElementTranslationTypeHtml(htmlElement, false, true);
                } else {
                  // eslint-disable-next-line no-lonely-if
                  if (!onlyContent && !onlyAttributes) {
                    translateAttributes();
                    translateContent();
                  } else if (onlyContent) {
                    translateContent();
                  } else if (onlyAttributes) {
                    translateAttributes();
                  }
                }
              }
            } else if (elementType === 'table' && !ignoreTable) {
              const thElements = htmlElement.find('th');
              const tdElements = htmlElement.find('td');
              const captionElements = htmlElement.find('caption');
              captionElements.each((index, captionElement) => {
                handleElementTranslationTypeHtml($(captionElement));
              });

              thElements.each((index, thElement) => {
                handleElementTranslationTypeHtml($(thElement), true);
              });

              tdElements.each((index, tdElement) => {
                handleElementTranslationTypeHtml($(tdElement), true);
              });
            } else if (elementType === 'li') {
              handleElementTranslationTypeHtml(htmlElement);
            } else if (elementType === 'ul' || elementType === 'ol') {
              const liElements = htmlElement.find('li');
              liElements.each((index, liElement) => {
                handleElementTranslationTypeHtml($(liElement));
              });
            } else if (elementType === 'button' || elementType === 'label') {
              // Prevent element to be translated twice
              if (!htmlElement.html()?.match(RegExp(`<${TRANSLATION_MARKUP}\\b[^>]*>`, 'gi'))) {
                const doTranslation = () => {
                  const key = createTranslationIdentifier();
                  addToTranslations(
                    key,
                    `${key}.text`,
                    'text',
                    htmlElement.text(),
                    {
                      text: {
                        type: 'text',
                        value: htmlElement.text(),
                      },
                    },
                  );
                  htmlElement.text(`{LANG_${key}.text}`);
                  addSectionId(htmlElement, `${key}.text`);
                };
                if (translateVariables) {
                  doTranslation();
                } else if (!translateVariables
                    && htmlElement.text().replace(/&nbsp;/g, ' ').trim()
                    && !htmlElement.text().replace(/&nbsp;/g, ' ').trim().match(checkVariableRegex)) {
                  doTranslation();
                }
              }
            } else if (elementType.match(/^h[1-6]$/) || elementType === 'span') {
              let onlyText = true;
              let childCount = 0;
              let textCount = 0;
              htmlElement.contents().each((index, child) => {
                const childElement = $(child);
                const childType = (child as unknown as cheerio.Element).tagName?.toLowerCase();

                if (child.nodeType === Node.TEXT_NODE && childElement.text().replace(/&nbsp;/g, ' ').trim()) {
                  textCount += 1;
                  childCount += 1;
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                  if (childType !== 'strong') {
                    onlyText = false;
                  }
                  childCount += 1;
                }
              });
              if (onlyText && childCount === 1 && textCount > 0 && !htmlElement.text().trim().match(checkVariableRegex)) {
                const key = createTranslationIdentifier();
                addToTranslations(
                  key,
                  `${key}.text`,
                  'text',
                  htmlElement.text(),
                  {
                    text: {
                      type: 'text',
                      value: htmlElement.text(),
                    },
                  },
                );
                htmlElement.text(`{LANG_${key}.text}`);
                addSectionId(htmlElement, `${key}.text`);
              } else if (onlyText && childCount > 1) {
                const key = createTranslationIdentifier();
                addToTranslations(
                  key,
                  `${key}`,
                  'html',
                  htmlElement.html(),
                  {
                    type: 'html',
                    value: htmlElement.html(),
                  },
                );
                htmlElement.html(`{LANG_${key}}`);
                addSectionId(htmlElement, key);
              } else {
                htmlElement.contents().each((index, child) => {
                  const childElement = $(child);
                  if (child.nodeType === Node.TEXT_NODE && childElement.text().replace(/&nbsp;/g, ' ').trim()) {
                    processElement(child, { ignoreTable, onlyAttributes, onlyContent });
                  } else if (child.nodeType === Node.ELEMENT_NODE) {
                    processElement(child, { ignoreTable, onlyAttributes, onlyContent });
                  }
                });
              }
            } else if (elementType !== 'tbody') { // We exclude the tbody tag because it's already taken into account in table verification
              // eslint-disable-next-line no-lonely-if
              if (!onlyContent && !onlyAttributes) {
                translateAttributes();
                translateContent();
              } else if (onlyContent) {
                translateContent();
              } else if (onlyAttributes) {
                translateAttributes();
              }
            }
          }
        }
      }
    }
  };

  if (globalTranslate) {
    processElement($('body').get(0));
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!ignoreSpecificItems && ($('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC}`).length
      || $('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}`).length
      || $('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT}`).length
      || $('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE}`).length
    )) {
      const specificItemsElements = $('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC_ITEMS}`);
      specificItemsElements.each((index, element) => {
        processElement(element);
      });

      const specificItemsOnlyContentElements = $('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_CONTENT}`);
      specificItemsOnlyContentElements.each((index, element) => {
        processElement(element, { onlyContent: true });
      });

      const specificItemsOnlyAttributesElements = $('body').find(`.${TEMPLATE_TRANSLATE_SPECIFIC_ONLY_ATTRIBUTE}`);
      specificItemsOnlyAttributesElements.each((index, element) => {
        processElement(element, { onlyAttributes: true });
      });
    } else {
      $('body').contents().each((index, element) => {
        processElement(element);
      });
    }
  }

  const translationsObject: Record<string, any> = {};

  langs.forEach((lang) => {
    translationsObject[lang] = {};
  });

  // Sort the translations array to match the position in the html
  const matches = $.html().match(/{LANG_([a-z0-9]+)\.?\w*}/g);
  if (matches && matches.length) {
    const extractedKeys = matches.map((str) => {
      const match = str.match(/{LANG_([a-zA-Z0-9]+)\b/);
      return match ? match[1] : null;
    }).filter(Boolean) as string[];

    translations = translations.sort((a, b) => extractedKeys.indexOf(a.key) - extractedKeys.indexOf(b.key));
  }

  const translationsArray: Translation[] = [];

  translations.forEach((translation) => {
    Object.keys(translationsObject).forEach((translationKey) => {
      translationsObject[translationKey][translation.key] = translation.value;
    });
    langs.forEach((lang) => {
      let sectionId = sectionIds[translation.normalizedKey];
      if (!sectionId) {
        sectionId = state.template?.activeSection;
      }
      translationsArray.push({
        section: sectionId,
        language: lang,
        fieldType: translation.fieldType,
        key: translation.normalizedKey,
        value: translation.fieldValue,
        callback: (value: string) => value,
      });
    });
  });

  if (newElement || globalTranslate) {
    translateElement($('body'), translationsArray, getShopDefaultLang());
    addTranslationsToState(translationsArray);
  }

  if (globalTranslate) {
    return {
      newHtml: $('body').html() as string,
      translations: translationsObject,
    };
  }

  return {
    newHtml: !newElement || fullHtml ? $.html() as string : $('body').html() as string,
    translations: translationsObject,
  };
};

export const removeTranslationInState = (key: string) => {
  removeTranslation(key);
};

export const setTranslationInState = (element: HTMLElement, type: string, attributeName = '', replaceOuterHtml = false) => {
  // get translation key from element
  let key;
  let value;
  if (type === 'markup') {
    const translationElement = element.querySelector(TRANSLATION_MARKUP);
    key = translationElement?.getAttribute('id')?.replace('LANG_', '');
    value = translationElement?.innerHTML;
  } else {
    const translationAttribute = element?.getAttribute(`${TRANSLATION_ATTRIBUTE}${attributeName ? `-${attributeName}` : ''}`);

    if (!translationAttribute) {
      // Element doesn't have the translation attribute (element has just been created with attribute `attributeName` (e.g. new link on element)),
      // we create the translation attribute
      const translationIdentifier = createTranslationIdentifier();
      element.setAttribute(`${TRANSLATION_ATTRIBUTE}${attributeName ? `-${attributeName}` : ''}`, `LANG_${translationIdentifier}`);

      // Create Translation object for each language
      const translations: Translation[] = UserState.activeShop?.langs.map((language: Lang) => ({
        section: state.template?.activeSection ?? 0,
        language: language.id,
        fieldType: type,
        key: translationIdentifier,
        value: '',
        callback: (translationValue: string) => translationValue,
      })) ?? [];

      // Add translations to state
      addTranslationsToState(translations);
    }

    // Get translation key (created above or already existing on element) and get value of attribute
    key = element?.getAttribute(`${TRANSLATION_ATTRIBUTE}${attributeName ? `-${attributeName}` : ''}`)?.replace('LANG_', '');
    value = element?.getAttribute(attributeName);
  }
  if (key) {
    if (attributeName === 'href') {
      // In case of a link, we replace the link (or the redirection) in every language
      replaceTranslationInStateAllLanguages(key, value ?? '');
    } else {
      const defaultLanguage: string = UserState.activeShop?.langs.filter((lang) => lang.default).map((lang) => lang.id)[0] ?? 'fr';
      replaceTranslationInState(key, defaultLanguage, value ?? '');
    }
  } else {
    const { newHtml } = generateTranslations(element.outerHTML, { newElement: true });
    if (!replaceOuterHtml) {
      // eslint-disable-next-line no-param-reassign
      element.innerHTML = newHtml;
    } else {
      // eslint-disable-next-line no-param-reassign
      element.outerHTML = newHtml;
    }
  }
};

export const fixMissingTranslations = () => {
  const template = getTemplateIframeDocument();
  if (template) {
    const spmTemplate = template.body.querySelector('spm-template');
    if (spmTemplate) {
      // eslint-disable-next-line no-param-reassign
      spmTemplate.innerHTML = spmTemplate.innerHTML.replace(/{LANG_([a-z0-9]+)\.?\w*}/g, '');
      const { newHtml } = generateTranslations(spmTemplate.innerHTML, { newElement: true, ignoreSpecificItems: true, translateVariables: false });
      // eslint-disable-next-line no-param-reassign
      spmTemplate.innerHTML = newHtml;

      // Remove excluded ids from translations
      TRANSLATION_EXCLUDE_IDS.forEach((excludedId) => {
        const elements = spmTemplate.querySelectorAll(`#${excludedId}`);
        Array.from(elements).forEach((element) => {
          if (element.innerHTML.match(RegExp(`<${TRANSLATION_MARKUP}\\b[^>]*>`, 'gi'))) {
            const key = element.children[0].getAttribute('id');
            if (key) {
              removeTranslationInState(key.replace('LANG_', ''));
            }
            // eslint-disable-next-line no-param-reassign
            element.innerHTML = element.children[0].innerHTML;
          }
        });
      });

      // Remove translation from data-redirect
      const elements = spmTemplate.querySelectorAll('div[data-redirect]');
      Array.from(elements).forEach((element) => {
        if (element.children.length === 1 && element.innerHTML.match(RegExp(`<${TRANSLATION_MARKUP}\\b[^>]*>`, 'gi'))) {
          const key = element.children[0].getAttribute('id');
          if (key) {
            removeTranslationInState(key.replace('LANG_', ''));
          }
          // eslint-disable-next-line no-param-reassign
          element.innerHTML = element.children[0].innerHTML;
        }
        if (!element.getAttribute('data-redirect') && element.getAttribute(`${TRANSLATION_ATTRIBUTE}-data-redirect`)) {
          const key = element.getAttribute(`${TRANSLATION_ATTRIBUTE}-data-redirect`);
          if (key) {
            removeTranslationInState(key.replace('LANG_', ''));
            element.removeAttribute(`${TRANSLATION_ATTRIBUTE}-data-redirect`);
          }
        }
        // Remove translation if element data-redirect has spmaction executeJS
        if (element.getAttribute('data-redirect') && element.classList.contains(`spm_action_${ActionTypeEnum.EXECUTE_JS}`)) {
          const key = element.getAttribute(`${TRANSLATION_ATTRIBUTE}-data-redirect`);
          if (key) {
            removeTranslationInState(key.replace('LANG_', ''));
            element.removeAttribute(`${TRANSLATION_ATTRIBUTE}-data-redirect`);
          }
        }
      });

      // Remove translation from fb profile picture
      const profilePictureElements = spmTemplate.querySelectorAll('div.fb_profile_picture');
      Array.from(profilePictureElements).forEach((element) => {
        if (element.children.length === 1 && element.children[0].tagName.toLowerCase() === 'img') {
          const attributeName = `${TRANSLATION_ATTRIBUTE}-src`;
          const attributeKey = element.children[0].getAttribute(attributeName);
          if (attributeKey) {
            removeTranslationInState(attributeKey.replace('LANG_', ''));
            element.children[0].removeAttribute(`${TRANSLATION_ATTRIBUTE}-src`);
          }
        }
      });

      // Fix missing translations for different languages
      fixMissingTranslationsForShopLanguages();

      cleanUpTranslations();
    }
  }
};
