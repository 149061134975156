
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default defineComponent({
  name: 'PreviewRenderer',
  components: {
    Button,
    Dialog,
  },

  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const isDialogShown = ref(false);

    return {
      t,
      isDialogShown,
    };
  },

});
