
import {
  defineComponent,
  onMounted, PropType, SetupContext, watch,
} from 'vue';
import DeclencheurRegisterOnSiteMetaData, {
  DeclencheurRegisterOnSiteData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurRegisterOnSiteMetadata';

export default defineComponent({
  name: 'DeclencheurRegisterOnSite',
  components: {},

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurRegisterOnSiteData>,
      required: true,
      default() {
        return DeclencheurRegisterOnSiteMetaData.Create();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurRegisterOnSiteData }, context: SetupContext) {
    const update = () => context.emit('update:modelValue', DeclencheurRegisterOnSiteMetaData.Create());

    watch([], update);
    onMounted(update);

    return {};
  },
});
