
import { defineComponent, PropType } from 'vue';
import Chip from 'primevue/chip';
import { SpmTableChip } from '@/types';

export default defineComponent({
  name: 'ChipRenderer',
  components: {
    Chip,
  },

  props: {
    data: {
      type: Object as PropType<SpmTableChip>,
      required: true,
    },
  },
});
