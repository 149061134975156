import { ComponentNameEnum, BaseFieldConfig } from '@/types';

const inputGroupFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.INPUT_GROUP,
  options: {
    defaultValue: '',
    draggable: false,
  },
};

export default inputGroupFieldConfig;
