
import {
  computed,
  defineComponent, onBeforeMount, Ref, ref, watch,
} from 'vue';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import defaultLogo from '@/assets/images/logo-tc-black.svg';
import defaultMinLogo from '@/assets/images/logo.png';
import { findIfNoPermissionsGiven, findPermission, UserState } from '@/composables/User';
import ImageWithFallback from '@/components/ImageWithFallback.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import { getLocalStorageElement, setLocalStorageElement } from '@/helpers/LocalStorage';
import { getUserStatsPages } from '@/composables/Statistics/usersPagesSubscriptions';
import { StatsType } from '@/types';
import Menu from 'primevue/menu';

export default defineComponent({
  name: 'AppSidebar',

  components: {
    SpmPanelMenu,
    ImageWithFallback,
    Menu,
  },

  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const { t, locale } = useI18n();
    const currentRoute = computed(() => useRoute());
    const isMobile = ref(false);
    // eslint-disable-next-line no-restricted-globals
    isMobile.value = screen.width < 768;

    const expandedKeys: Ref<any> = ref({
      1: true,
      2: true,
      3: true,
      4: true,
    });
    const store = useStore();
    let shouldSidebarBeExpanded = false;
    if (!props.isMobile) {
      shouldSidebarBeExpanded = Boolean(Number(getLocalStorageElement('spm_is_sidebar_expanded') ?? 1));
    }
    store.commit('general/setIsSidebarExpanded', shouldSidebarBeExpanded);

    const isSidebarExpanded = computed(() => store.state.general.isSidebarExpanded);

    const isMenuItemActive = (routes: string | string[], param: string | null = null): string | null => {
      const activeClass = 'activeMenuItem';
      const currentRouteName = currentRoute.value.name;

      if (typeof routes === 'string') {
        if (param) {
          return currentRouteName === routes && Object.values(currentRoute.value.params).includes(param) ? activeClass : null;
        }
        return currentRouteName === routes ? activeClass : null;
      }
      if (routes.length) {
        for (let i = 0; i < routes.length; i++) {
          if (currentRouteName === routes[i]) {
            return activeClass;
          }
        }
      }
      return null;
    };

    const isAtLeastOneCampaignMenuVisible = findIfNoPermissionsGiven('automated_scenarios.view,bulk_campaigns.view');
    const isAtLeastOneListMenuVisible = findIfNoPermissionsGiven('lists-segments.mailing_lists_view,my_push_notif.view,my_unsubscribed.view,'
      + 'my_bounces.view,my_spams.view');
    const isAtLeastOneContentDataMenuVisible = findIfNoPermissionsGiven('my_templates.view,my_data.view');

    const itemsProducts: Ref<any> = ref([
      {
        key: '1_0',
        label: t('navbar.autoScenarios'),
        to: { name: 'workflow' },
        class: computed(() => isMenuItemActive('workflow')),
        visible: () => isAtLeastOneCampaignMenuVisible && findPermission('automated_scenarios.view'),
      },
      {
        key: '1_1',
        label: t('navbar.campaignsBulk'),
        to: { name: 'campaigns-bulk' },
        class: computed(() => isMenuItemActive('campaigns-bulk')),
        visible: () => isAtLeastOneCampaignMenuVisible && findPermission('bulk_campaigns.view'),
      },
    ]);
    const itemsLists: Ref<any> = ref([
      {
        key: '2_0',
        label: t('navbar.mailingLists'),
        to: { name: 'lists-manage' },
        class: computed(() => isMenuItemActive('lists-manage')),
        visible: () => isAtLeastOneListMenuVisible && findPermission('lists-segments.mailing_lists_view'),
      },
      {
        key: '2_1',
        label: t('navbar.pushNotifications'),
        to: { name: 'lists.push-tokens' },
        class: computed(() => isMenuItemActive('lists.push-tokens')),
        visible: () => isAtLeastOneListMenuVisible && findPermission('my_push_notif.view'),
      },
      {
        key: '2_2',
        label: t('navbar.unsubscribed'),
        to: { name: 'lists.manage-customers-unsub' },
        class: computed(() => isMenuItemActive('lists.manage-customers-unsub')),
        visible: () => isAtLeastOneListMenuVisible && findPermission('my_unsubscribed.view'),
      },
      {
        key: '2_3',
        label: t('navbar.bounces'),
        to: { name: 'lists.manage-customers-bounces' },
        class: computed(() => isMenuItemActive('lists.manage-customers-bounces')),
        visible: () => isAtLeastOneListMenuVisible && findPermission('my_bounces.view'),
      },
      {
        key: '2_4',
        label: t('navbar.spams'),
        to: { name: 'lists.manage-customers-spams' },
        class: computed(() => isMenuItemActive('lists.manage-customers-spams')),
        visible: () => isAtLeastOneListMenuVisible && findPermission('my_spams.view'),
      },
    ]);
    const itemsContentData: Ref<any> = ref([
      {
        key: '4_0',
        label: t('navbar.myTemplates'),
        to: { name: 'template-manage' },
        class: computed(() => isMenuItemActive('template-manage')),
        visible: () => isAtLeastOneContentDataMenuVisible && findPermission('my_templates.view'),
      },
      {
        key: '4_1',
        label: t('navbar.myData'),
        to: { name: 'shop.data' },
        class: computed(() => isMenuItemActive('shop.data')),
        visible: () => isAtLeastOneContentDataMenuVisible && findPermission('my_data.view'),
      },
    ]);
    const itemsStats: Ref<any> = ref([]);

    const topMenuCss = ref(0);
    const menuProducts = ref();
    const menuLists = ref();
    const menuContentData = ref();
    const menuStats = ref();

    const hideAllMenus = () => {
      menuProducts.value.hide();
      menuLists.value.hide();
      menuContentData.value.hide();
      menuStats.value.hide();
    };

    const items = ref([
      {
        key: '0',
        label: t('navbar.dashboard'),
        icon: 'fa-regular fa-house',
        to: { name: 'home' },
        class: computed(() => isMenuItemActive('home')),
      },
      {
        key: '1',
        label: t('navbar.products'),
        icon: 'fa-regular fa-share-nodes',
        items: computed(() => {
          if (!isSidebarExpanded.value) {
            return false;
          }
          return itemsProducts.value;
        }),
        class: computed(() => isMenuItemActive(['workflow', 'campaigns-bulk'])),
        isExpanded: computed(() => isMenuItemActive(['workflow', 'campaigns-bulk'])),
        commandRoot: (event: any) => {
          if (!isSidebarExpanded.value) {
            event.stopPropagation();
            const target = event.originalTarget ?? event.target;
            hideAllMenus();
            topMenuCss.value = target.offsetTop;
            menuProducts.value.toggle(event);
          }
        },
        visible: () => isAtLeastOneCampaignMenuVisible,
      },
      {
        key: '2',
        label: t('navbar.lists'),
        icon: 'fa-regular fa-address-book',
        items: computed(() => {
          if (!isSidebarExpanded.value) {
            return false;
          }
          return itemsLists.value;
        }),
        class: computed(() => isMenuItemActive([
          'lists-manage',
          'lists.push-tokens',
          'lists.manage-customers-unsub',
          'lists.manage-customers-bounces',
          'lists.manage-customers-spams',
        ])),
        isExpanded: computed(() => isMenuItemActive(['workflow', 'campaigns-bulk'])),
        commandRoot: (event: any) => {
          if (!isSidebarExpanded.value) {
            event.stopPropagation();
            const target = event.originalTarget ?? event.target;
            hideAllMenus();
            topMenuCss.value = target.offsetTop;
            menuLists.value.toggle(event);
          }
        },
        visible: () => isAtLeastOneListMenuVisible,
      },
      {
        key: '3',
        label: t('navbar.contentData'),
        icon: 'fa-regular fa-database',
        items: computed(() => {
          if (!isSidebarExpanded.value) {
            return false;
          }
          return itemsContentData.value;
        }),
        class: computed(() => isMenuItemActive(['shop.data', 'template-manage'])),
        isExpanded: computed(() => isMenuItemActive(['shop.data', 'template-manage'])),
        commandRoot: (event: any) => {
          if (!isSidebarExpanded.value) {
            event.stopPropagation();
            const target = event.originalTarget ?? event.target;
            hideAllMenus();
            topMenuCss.value = target.offsetTop;
            menuContentData.value.toggle(event);
          }
        },
        visible: () => isAtLeastOneContentDataMenuVisible,
      },
      {
        key: '4',
        label: t('navbar.statistics'),
        icon: 'fa-regular fa-chart-mixed',
        items: computed(() => {
          if (!isSidebarExpanded.value) {
            return false;
          }
          return itemsStats.value;
        }),
        isExpanded: computed(() => isMenuItemActive(['custom_stats'])),
        commandRoot: (event: any) => {
          if (!isSidebarExpanded.value) {
            event.stopPropagation();
            const target = event.originalTarget ?? event.target;
            hideAllMenus();
            topMenuCss.value = target.offsetTop;
            menuStats.value.toggle(event);
          }
        },
      },
    ]);

    const expandNode = (node: any) => {
      if (node.items && node.items.length) {
        expandedKeys.value[node.key] = true;

        // eslint-disable-next-line no-restricted-syntax
        for (const child of node.items) {
          expandNode(child);
        }
      }
    };

    const activeRightMenu = (): void => {
      if (currentRoute.value.name) {
        const routeName = currentRoute.value.name;
        items.value.forEach((item: any) => {
          if (item.to && item.to.name === routeName) {
            expandNode(items.value[parseInt(item.key.split('_')[0], 10)]);
          } else if (item.items) {
            item.items.forEach((subItem: any) => {
              if (subItem.to.name === routeName) {
                expandNode(items.value[parseInt(subItem.key.split('_')[0], 10)]);
              }
            });
          }
        });
      }
    };

    const setListOfUsersStatsPages = async () => {
      const result = await getUserStatsPages(UserState.user.id, UserState.user.userType, StatsType.STATS, UserState.activeShop?.idUser);
      itemsStats.value = [];
      if (result && result.items && result.items.length) {
        result.items.forEach((route: any, index) => {
          let navigationName = route.page?.navigation_name ?? route.page?.page_name;
          try {
            navigationName = JSON.parse(navigationName);
            if (locale.value in navigationName) {
              navigationName = navigationName[locale.value];
            } else {
              navigationName = route.page?.navigation_name ?? route.page?.page_name;
            }
          } catch (error) {
            navigationName = route.page?.navigation_name ?? route.page?.page_name;
          }
          itemsStats.value.push({
            key: `4_${index}`,
            label: navigationName,
            to: { name: 'custom_stats', params: { pageId: route.id_users_pages_configuration } },
            class: computed(() => isMenuItemActive('custom_stats', route.id_users_pages_configuration.toString())),
          });
        });
      }
    };

    onBeforeMount(async () => {
      store.commit('statsEditor/setRefreshNavigationBar', false);
      await setListOfUsersStatsPages();

      const expandedSidebarKeys = getLocalStorageElement('expandedSidebarKeys');
      if (expandedSidebarKeys) {
        expandedKeys.value = JSON.parse(expandedSidebarKeys);
      } else {
        setLocalStorageElement('expandedSidebarKeys', JSON.stringify(expandedKeys.value));
      }

      activeRightMenu();
    });

    const expandedKeysUpdated = (newExpandedKeys: any) => {
      setLocalStorageElement('expandedSidebarKeys', JSON.stringify(newExpandedKeys));
    };

    watch(currentRoute.value, async () => {
      activeRightMenu();
    }, { deep: true });

    watch(() => store.getters['statsEditor/getRefreshNavigationBar'], async () => {
      if (store.getters['statsEditor/getRefreshNavigationBar']) {
        await setListOfUsersStatsPages();
        store.commit('statsEditor/setRefreshNavigationBar', false);
        activeRightMenu();
      }
    });

    return {
      defaultLogo,
      UserState,
      isSidebarExpanded,
      items,
      defaultMinLogo,
      expandedKeys,
      expandedKeysUpdated,
      itemsProducts,
      menuProducts,
      itemsLists,
      menuLists,
      itemsContentData,
      menuContentData,
      itemsStats,
      menuStats,
      topMenuCss,
    };
  },

});
