
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import BaseItemList from '@/components/fields/BaseItemList.vue';
import {
  FieldConfig, ParserFieldObject, Property, StringMap,
} from '@/types';

export default defineComponent({
  name: 'ItemList',

  components: {
    BaseItemList,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const property: Ref<Property> = ref(props.parserValues.properties[0]);
    const items = ref([]);

    if (property.value.value) {
      items.value = property.value.value.split(',');
    }
    watch(items, () => {
      const itemIds = items.value.map((item: StringMap) => item[props.configs.options.fields.id]).join(',');
      property.value.value = itemIds;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    });

    return {
      property,
      items,
    };
  },

});
