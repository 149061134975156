import { ComponentNameEnum } from '@/types';

const getTriggerButtonFieldConfig = (activateLabel: string, disableLabel: string, callback: Function, restore: Function) => ({
  componentName: ComponentNameEnum.TRIGGER_BUTTON,
  options: {
    activateLabel,
    disableLabel,
    callback,
    restore,
  },
});

export default getTriggerButtonFieldConfig;
