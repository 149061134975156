<template>
  <div
    class="card__cell card__cell--header card__cell__plan relative pt-4"
    :class="`card__cell--${t(`offers.plans.${plan.name}`)}`"
  >
    <div class="card__cell__plan__label text-xl font-medium flex align-items-center gap-1">
      <i
        v-if="planLevel > 0"
        class="fas fa-star text-xl"
        :class="`star-${plan.name}`"
      />
      {{ t(`offers.plans.${plan.name}`) }}
    </div>
    <div class="card__cell__plan__description font-semibold text-xl mt-3">
      {{ pricing }}
    </div>
    <div
      v-if="originalPricing !== pricing"
      class="card__cell__plan__description text-sm mt-1"
    >
      {{ t('insteadOf') }} {{ originalPricing }}
    </div>
    <div
      v-if="showMostPopularBanner"
      class="most-popular-banner absolute text-white font-semibold flex justify-content-center align-items-center px-2 py-1"
    >
      {{ t('offers.mostPopular') }}
    </div>
  </div>
  <div
    v-for="(feature, featureName) in plan.features"
    :key="featureName"
    class="card__cell card__cell--value"
    :class="{
      'card__cell--price': feature.smsPrice,
      'hidden': feature.hidden,
      'flex': !feature.hidden
    }"
  >
    <div v-if="feature.value === true">
      <i
        class="card__cell--value--icon card__cell--value--icon--true fa-light fa-circle-check"
      />
      <span class="lg:hidden sm:inline sm:ml-1">{{ t(featureName) }}</span>
    </div>
    <div v-else-if="feature.value === false">
      <i
        class="card__cell--value--icon card__cell--value--icon--false fa-light fa-circle-xmark"
      />
      <span class="lg:hidden sm:inline sm:ml-1">{{ t(featureName) }}</span>
    </div>
    <div
      v-else
    >
      <div
        v-if="!feature.smsPrice && typeof feature.value === 'number'"
        class="flex align-items-center"
      >
        <span>{{ formatNumber(feature.value) }}</span>
        <span class="lg:hidden sm:inline sm:ml-1">{{ t(featureName) }}</span>
      </div>
      <div
        v-else-if="!feature.smsPrice && feature.planSmsPrice"
        class="flex align-items-center"
      >
        <span>{{ planSmsPrice }}</span>
        <span class="lg:hidden sm:inline sm:ml-1">{{ t(featureName) }}</span>
      </div>
      <div
        v-else
        class="flex align-items-center"
      >
        <span>{{ smsPrice }}</span>
        <span class="lg:hidden sm:inline sm:ml-1">{{ t(featureName) }}</span>
        <div class="country-dropdown__wrapper">
          <div class="col-12">
            <div class="field">
              <CountryDropdown
                v-model="selectedCountry"
                class="lg:hidden sm:inline-block"
                :configuration="countryDropdownConfiguration"
                :error="{ country: false, state: false }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card__cell card__cell--action flex">
    <div
      v-if="selectedPlan.plan === plan.name"
    >
      <SpmButton
        :label="t('shop.menu.myOffer.selectedOffer')"
        class="p-button-primary"
      />
    </div>
    <div v-else>
      <SpmButton
        :label="t('shop.menu.myOffer.selectOfferButton')"
        class="p-button-secondary"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { PricingPlan, SelectedPlanData, SmsPricing } from '@/types/offer-types';
import {
  yearlyPriceReduction,
} from '@/configs/offers';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import {
  CountryAndStateValues,
  CountryAndStateConfig,
} from '@/types/country-state-types';
import { formatNumberToCurrency } from '@/helpers/Number';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'PlanFeature',

  components: {
    CountryDropdown,
    SpmButton,
  },

  props: {
    plan: {
      type: Object as PropType<PricingPlan>,
      required: true,
    },

    selectedPlan: {
      type: Object as PropType<SelectedPlanData>,
      required: true,
    },

    monthlyPagesViewed: {
      type: Number || null,
      required: true,
    },

    smsPricing: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    pricingPlans: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    discount: {
      type: Number || null,
      required: false,
      default: null,
    },

    modelValue: {
      type: Object as PropType<CountryAndStateValues>,
      required: true,
    },

    countryDropdownConfiguration: {
      type: Object as PropType<CountryAndStateConfig>,
      required: true,
    },

    planLevel: {
      type: Number,
      required: true,
    },

    showMostPopularBanner: {
      type: Boolean,
      default: false,
    },

    planSmsPricing: {
      type: Object as PropType<SmsPricing[]>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    const pricing = computed(() => {
      const plan = props.pricingPlans[props.plan.name];
      if (props.monthlyPagesViewed !== null) {
        const monthlyPagesViewedValue = props.monthlyPagesViewed;
        const exactPlan = plan.find((item: { monthlyPagesViewed: number }) => monthlyPagesViewedValue <= item.monthlyPagesViewed);
        if (exactPlan) {
          const priceOfPlan = props.discount && props.discount > 0 ? exactPlan.price - (exactPlan.price * (props.discount / 100)) : exactPlan.price;

          if (props.selectedPlan.isYearly) {
            const price = priceOfPlan * 12 * (1 - yearlyPriceReduction);
            return `${formatNumberToCurrency(Math.round(price * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perYear')}`;
          }
          return `${formatNumberToCurrency(Math.round(priceOfPlan * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perMonth')}`;
        }
      }
      return '';
    });

    const originalPricing = computed(() => {
      const plan = props.pricingPlans[props.plan.name];
      if (props.monthlyPagesViewed !== null) {
        const monthlyPagesViewedValue = props.monthlyPagesViewed;
        const exactPlan = plan.find((item: { monthlyPagesViewed: number }) => monthlyPagesViewedValue <= item.monthlyPagesViewed);
        if (exactPlan) {
          if (props.selectedPlan.isYearly) {
            const price = exactPlan.price * 12 * (1 - yearlyPriceReduction);
            return `${formatNumberToCurrency(Math.round(price * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perYear')}`;
          }
          return `${formatNumberToCurrency(Math.round(exactPlan.price * 100) / 100, currency.value)} ${t('shop.menu.myOffer.perMonth')}`;
        }
      }
      return '';
    });

    const smsPrice = computed(() => {
      const price = props.smsPricing[props.plan.name].find((item: { value: string }) => item.value === props.modelValue.country);
      if (price) {
        return formatNumberToCurrency(price.smsPrice, currency.value, 3);
      }
      return '--';
    });

    const planSmsPrice = computed(() => {
      const price = props.planSmsPricing.find((item: { value: string }) => item.value === props.modelValue.country);
      if (price) {
        return formatNumberToCurrency(price.smsPrice, currency.value, 3);
      }
      return '--';
    });

    const selectedCountry = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    const formatNumber = (number: number) => formatNumberToCurrency(number, currency.value, 3);

    return {
      t,
      originalPricing,
      pricing,
      smsPrice,
      planSmsPrice,
      selectedCountry,

      formatNumber,
    };
  },
});
</script>

<style lang="scss" scoped>
.most-popular-banner {
  transform: translateX(-50%);
  left: 50%;
  top: -15px;
  min-width: 10rem;
  background-color: $brand-color-primary;
}
</style>
