
import { defineComponent, onMounted, ref } from 'vue';

import Chip from 'primevue/chip';
import Skeleton from 'primevue/skeleton';
import VirtualScroller from 'primevue/virtualscroller';
import Paginator from 'primevue/paginator';


import SectionTitle from '@/components/data-explorer/fields/SectionTitle.vue';
import LIST_ROWS from '@/components/data-explorer/utils/constants';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'List',

  components: {
    Chip,
    Skeleton,
    VirtualScroller,
    SectionTitle,
    Paginator,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    list: {
      type: Array,
      required: true
    },

    keyName: {
      type: String,
      required: true
    },

    useVirtualScroller: {
      type: Boolean,
      required: false,
      default: false,
    },

    noColumn: {
      type: Boolean,
      required: false,
      default: false,
    },

    retrieveData: {
      type: Function,
      required: true,
    },

    retrieveDataArguments: {
      type: Array,
      required: true,
    },

    dataLoaded: {
      type: Boolean,
      required: true,
    },

    total: {
      type: Number,
      required: false,
      default: 0,
    },

    currentPage: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  emits: ['loaded', 'updatePage'],

  setup (props, { emit }) {
    const { t } = useI18n();

    const firstPage = ref(props.currentPage);

    const records = ref(props.list);

    const totalRecords = ref(props.total);

    const pageLoading = ref(false);

    const retrieve = async (offset = 0, emitNextPage = false) => {
      try {
        pageLoading.value = true;
        const { total, lists } = await props.retrieveData(...props.retrieveDataArguments, offset);
        records.value = lists;
        totalRecords.value = total;
        emit('loaded', lists, total);
        if (emitNextPage) {
          emit('updatePage', firstPage.value);
        }
      } catch (error) {
        records.value = [];
        emit('loaded', [], 0);
      } finally {
        pageLoading.value = false;
      }
    }

    const handleNextPage = async () => {
      await retrieve(firstPage.value, true);
    };

    onMounted(async () => {
      if (!props.dataLoaded) {
        await retrieve();
      }
    });

    return {
      t,
      firstPage,
      records,
      totalRecords,
      LIST_ROWS,
      pageLoading,

      handleNextPage
    };
  }
})
