
import {
  defineComponent,
  ref,
} from 'vue';

import Dialog from 'primevue/dialog';

import ComponentsServiceParameters from '@/views/shop/tabs/ServicesParameters/services';

import services from '@/configs/services-parameters';
import { ServiceParameter } from '@/types/store-parameters-types';

import { fetchStoreParamsConfigurations } from '@/composables/shop/MyShopParameters';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'NewServicesParameters',

  components: {
    Dialog,
    ...ComponentsServiceParameters,
  },

  props: {
    shopId: {
      type: Number,
      required: true,
    },

    shopUrl: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const displayServiceModal = ref(false);
    const selectedServiceParameter = ref<null | ServiceParameter>(null);

    const handleConfigure = (serviceName: string) => {
      const service = services.find((item) => item.name === serviceName);
      if (service) {
        selectedServiceParameter.value = service;
        displayServiceModal.value = true;
      }
    };

    const handleClose = () => {
      displayServiceModal.value = false;
      selectedServiceParameter.value = null;
    };

    const retrieveServicesData = async (field: any) => {
      const {
        data,
        err,
      } = await fetchStoreParamsConfigurations(props.shopId);
      const defaultValuesKeys = Object.keys(field);
      const exclusions = ['shopRequiredDataSocialNetworks_facebook',
        'shopRequiredDataSocialNetworks_googleplus',
        'shopRequiredDataSocialNetworks_instagram',
        'shopRequiredDataSocialNetworks_pinterest',
        'shopRequiredDataSocialNetworks_snapchat',
        'shopRequiredDataSocialNetworks_tumblr',
        'shopRequiredDataSocialNetworks_twitter',
        'shopRequiredDataSocialNetworks_whatsapp',
        'shopRequiredDataSocialNetworks_youtube'];

      if (data === null || data.length === 0) {
        return;
      }

      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        for (let j = 0; j < defaultValuesKeys.length; j++) {
          if (record.key === defaultValuesKeys[j]) {
            if (record.lang && typeof field[defaultValuesKeys[j]] === 'object') {
              let objectValue: any = {};
              objectValue = field[defaultValuesKeys[j]];
              objectValue[record.lang] = record.value;
              // eslint-disable-next-line no-param-reassign
              field[defaultValuesKeys[j]] = objectValue;
            } else if (!exclusions.includes(record.key)) {
              // eslint-disable-next-line no-param-reassign
              field[defaultValuesKeys[j]] = record.value;
            }
          }
        }
      }
    };

    return {
      t,
      services,
      selectedServiceParameter,
      displayServiceModal,
      handleConfigure,
      handleClose,
      retrieveServicesData,
    };
  },
});
