import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  ComponentNameEnum,
  SocialNetworkIconStyleEnum,
  StepperSignEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
// eslint-disable-next-line import/no-cycle
import {
  setSocialNetworkOrientation,
  setSocialNetworkIconWidth,
  setSocialNetworkIconStyle,
  setActiveSharingSocialNetworks,
  setAttribute,
  setClass,
  setCssProperty,
  setMediaDisplay,
  resetCssPropertyWithoutPseudoClasses,
  setCssPropertyAndRemoveIfZero,
  sortSocialNetworks,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getActiveSocialNetworks,
  getAttributeByName,
  getClassByName,
  getCssPropertyByName,
  getSharingType,
  getSocialNetworkIconStyle,
  getSocialNetworksLinks,
} from '@/components/template-builder/getters';
import iconSizeFieldConfig from '@/components/template-builder/config/fields/icon-size-config';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
// eslint-disable-next-line import/no-cycle
import { removeDynamicStyleWithoutPseudoClasses } from '@/components/template-builder/utils/parser';
import inputGroupFieldConfig from '@/components/template-builder/config/fields/input-group-config';
import getSpmSocialIconsFieldConfig from '@/components/template-builder/config/fields/spm-social-icons-config';
import bordersFieldConfig from '../fields/borders-config';
import mediaDisplayFieldConfig from '../fields/media-display-config';
import getElementAlignFieldConfig from '../fields/element-align-config';
import iconLayoutFieldConfig from '../fields/icon-layout-config';
// eslint-disable-next-line import/no-cycle
import getSharingTypeFieldConfig from '../fields/sharing-type-config';
import getColorFieldConfig from '../fields/color-config';
import activeSharingSocialNetworksFieldConfig from '../fields/active-sharing-social-networks-config';
import getSpmIconsFieldConfig from '../fields/spm-icons-config';

const widgetShareSocialMediaConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.shareSocialMedia',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.iconLayout',
            selector: '{SELECTOR} > tbody > tr > th > table',
            properties: [{
              name: 'spm_social_icons_', getter: getClassByName, setters: [setClass, setSocialNetworkOrientation],
            }],
            ...iconLayoutFieldConfig,
          },
          {
            label: 'templateBuilder.fields.iconSize.label',
            selector: '{SELECTOR} > tbody > tr > th > table img',
            displayToggle: false,
            properties: [
              {
                name: 'width', getter: getAttributeByName, setters: [setAttribute, setSocialNetworkIconWidth],
              },
            ],
            ...iconSizeFieldConfig,
          },
          {
            label: 'templateBuilder.fields.iconStyle',
            selector: '{SELECTOR} > tbody > tr > th > table img',
            properties: [{ name: 'iconStyle', getter: getSocialNetworkIconStyle, setters: [setSocialNetworkIconStyle] }],
            ...getSpmSocialIconsFieldConfig(
              Object.values(SocialNetworkIconStyleEnum),
              SocialNetworkIconStyleEnum.STYLE_1,
            ),
          },
          {
            label: 'templateBuilder.fields.activeSocialNetworks',
            selector: '{SELECTOR} > tbody > tr > th > table',
            properties: [{
              name: 'activeNetworks', getter: getActiveSocialNetworks, setters: [setActiveSharingSocialNetworks], refreshWidgetFields: true,
            }],
            ...activeSharingSocialNetworksFieldConfig,
          },
          {
            label: 'templateBuilder.fields.socialNetworksLinks',
            selector: '{SELECTOR} > tbody > tr > th > table',
            properties: [{ name: 'socialNetworksLinks', getter: getSocialNetworksLinks, setters: [sortSocialNetworks] }],
            ...inputGroupFieldConfig,
            options: {
              draggable: true,
              displayFields: false,
            },
          },
          {
            label: 'templateBuilder.fields.sharingType',
            selector: '{SELECTOR} > tbody > tr > th > table',
            properties: [{ name: 'sharingType', getter: getSharingType, setters: [] }],
            ...getSharingTypeFieldConfig(),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.contentAlignment',
            selector: '{SELECTOR} table',
            properties: [{ name: 'align', getter: getAttributeByName, setters: [setAttribute] }],
            ...getElementAlignFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, false, true),
          },
          {
            label: 'templateBuilder.fields.iconInnerMargins',
            selector: '{SELECTOR} th td',
            properties: [
              {
                name: 'padding-right',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.marginVertical',
            selector: '{SELECTOR} th, {SELECTOR} table',
            properties: [
              { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getVerticalSpacesFieldConfig('margin'),
          },
          {
            label: 'templateBuilder.fields.marginHorizontal',
            selector: '{SELECTOR} th, {SELECTOR} table',
            properties: [
              { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getHorizontalSpacesFieldConfig('margin'),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR}',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetShareSocialMediaConfig;
