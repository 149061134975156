export interface Variable {
  value: string;
  label: string;
}

// @TODO : mettre à jour le tableau des variables disponibles avec les dernières variables de prod
const allVariables: Variable[] = [
  { value: '{var=contact.first_name}', label: 'templateBuilder.variables.contactFirstname' },
  { value: '{var=contact.last_name}', label: 'templateBuilder.variables.contactLastname' },
  { value: '{var=contact.email}', label: 'templateBuilder.variables.contactEmail' },
  { value: '{var=contact.gender}', label: 'templateBuilder.variables.contactGender' },
  { value: '{var=shop.url https=false}', label: 'templateBuilder.variables.siteUrlWithoutHttp' },
  { value: '{var=shop.privacy_policy_url}', label: 'templateBuilder.variables.privacyPolicyUrl' },
  { value: '{var=shop.data_manager_email}', label: 'templateBuilder.variables.dataManagerEmail' },
  { value: '{var=shop.name}', label: 'templateBuilder.variables.siteName' },
  { value: '{var=shop.url}', label: 'templateBuilder.variables.siteUrl' },
  { value: '{var=shop.logo_url}', label: 'templateBuilder.variables.logoUrl' },
  { value: '{var=shop.address}', label: 'templateBuilder.variables.shopAddress' },
  { value: '{var=cart.id_cart}', label: 'templateBuilder.variables.cartId' },
  { value: '{var=cart.url}', label: 'templateBuilder.variables.cartUrl' },
  { value: '{var=cart.amount}', label: 'templateBuilder.variables.cartAmount' },
  { value: '{var=visitor.last_category_view}', label: 'templateBuilder.variables.lastCategoryView' },
  { value: '{var=visitor.last_product_view}', label: 'templateBuilder.variables.lastProductView' },
  { value: '{var=visitor.best_category_context}', label: 'templateBuilder.variables.bestCategoryContext' },
  { value: '{var=visitor.best_product_context}', label: 'templateBuilder.variables.bestProductContext' },
  { value: '{var=visitor.last_product_view_url}', label: 'templateBuilder.variables.lastProductViewUrl' },
  { value: '{var=shop.link_double_optin}', label: 'templateBuilder.variables.linkDoubleOptin' },
  { value: '{var=contact.seniority}', label: 'templateBuilder.variables.customerSeniority' },
  { value: '{var=contact.lastorder_time}', label: 'templateBuilder.variables.customerLastorderTime' },
  { value: '{var=voucher.code}', label: 'templateBuilder.variables.voucherNumber' },
  { value: '{var=voucher.description}', label: 'templateBuilder.variables.voucherDescription' },
  { value: '{var=voucher.amount}', label: 'templateBuilder.variables.voucherAmount' },
  { value: '{var=voucher.minimum_order}', label: 'templateBuilder.variables.voucherMinimumOrder' },
  { value: '{var=voucher.date_end}', label: 'templateBuilder.variables.voucherDateLimit' },
  { value: '{var=voucher.nb_day_validate}', label: 'templateBuilder.variables.voucherNbDayExpire' },
  { value: '{var=template.subject}', label: 'templateBuilder.variables.mailSubject' },
  { value: '{var=order.id_order}', label: 'templateBuilder.variables.orderId' },
  { value: '{var=order.amount}', label: 'templateBuilder.variables.orderAmount' },
  { value: '{var=order.reference}', label: 'templateBuilder.variables.orderReference' },
  { value: '{var=order.status.name}', label: 'templateBuilder.variables.orderStatus' },
  { value: '{var=campaign_type}', label: 'templateBuilder.variables.campaignType' },
  { value: '{var=campaign_name}', label: 'templateBuilder.variables.campaignName' },
  { value: '{var=contact.id_customer_shop}', label: 'templateBuilder.variables.campaignUserId' },
  { value: '{var=campaign_send_key}', label: 'templateBuilder.variables.campaignSendKey' },
  { value: '{var=campaign_link}', label: 'templateBuilder.variables.campaignLink' },
  { value: '{var=contact.custom_1}', label: 'templateBuilder.variables.custom1' },
  { value: '{var=contact.custom_2}', label: 'templateBuilder.variables.custom2' },
  { value: '{var=contact.custom_3}', label: 'templateBuilder.variables.custom3' },
  { value: '{var=contact.custom_4}', label: 'templateBuilder.variables.custom4' },
  { value: '{var=product.id_product}', label: 'templateBuilder.variables.productId' },
  { value: '{var=product.id_combination}', label: 'templateBuilder.variables.productCombination' },
  { value: '{var=product.name}', label: 'templateBuilder.variables.productTitle' },
  { value: '{var=product.url}', label: 'templateBuilder.variables.productUrl' },
  { value: '{var=product.image_url resize=400x400 resizeType=fill-to-fit}', label: 'templateBuilder.variables.productImageUrl' },
  { value: '{var=product.description_short}', label: 'templateBuilder.variables.productDescription' },
  { value: '{var=product.manufacturer.id}', label: 'templateBuilder.variables.productManufacturerId' },
  { value: '{var=product.manufacturer.name}', label: 'templateBuilder.variables.productManufacturer' },
  { value: '{var=product.price}', label: 'templateBuilder.variables.productPrice' },
  { value: '{var=product.price_strike}', label: 'templateBuilder.variables.productPriceStrike' },
  { value: '{var=product.price_discount}', label: 'templateBuilder.variables.productPriceDiscount' },
  { value: '{var=product.price_display}', label: 'templateBuilder.variables.productPriceDisplay' },
  { value: '{var=social.facebook_url}', label: 'templateBuilder.variables.socialFacebook' },
  { value: '{var=social.twitter_url}', label: 'templateBuilder.variables.socialTwitter' },
  { value: '{var=social.pinterest_url}', label: 'templateBuilder.variables.socialPinterest' },
  { value: '{var=social.tumblr_url}', label: 'templateBuilder.variables.socialTumblr' },
  { value: '{var=social.instagram_url}', label: 'templateBuilder.variables.socialInstagram' },
  { value: '{var=social.contact_url}', label: 'templateBuilder.variables.socialContact' },
  { value: '{var=social.youtube_url}', label: 'templateBuilder.variables.socialYoutube' },
  { value: '{var=social.whatsapp_url}', label: 'templateBuilder.variables.socialWhatsapp' },
  { value: '{var=social.snapchat_url}', label: 'templateBuilder.variables.socialSnapchat' },
  { value: '{var=social.phone}', label: 'templateBuilder.variables.socialPhone' },
  { value: '{var=social.email}', label: 'templateBuilder.variables.socialEmail' },
];

const aiVariables: Variable[] = [
  { value: '{var=contact.first_name}', label: 'templateBuilder.variables.contactFirstname' },
  { value: '{var=contact.last_name}', label: 'templateBuilder.variables.contactLastname' },
  { value: '{var=contact.email}', label: 'templateBuilder.variables.contactEmail' },
  { value: '{var=contact.gender}', label: 'templateBuilder.variables.contactGender' },
  { value: '{var=shop.name}', label: 'templateBuilder.variables.siteName' },
  { value: '{var=shop.url}', label: 'templateBuilder.variables.siteUrl' },
  { value: '{var=shop.address}', label: 'templateBuilder.variables.shopAddress' },
  { value: '{var=cart.amount}', label: 'templateBuilder.variables.cartAmount' },
  { value: '{var=visitor.last_category_view}', label: 'templateBuilder.variables.lastCategoryView' },
  { value: '{var=visitor.last_product_view}', label: 'templateBuilder.variables.lastProductView' },
  { value: '{var=visitor.best_category_context}', label: 'templateBuilder.variables.bestCategoryContext' },
  { value: '{var=visitor.best_product_context}', label: 'templateBuilder.variables.bestProductContext' },
  { value: '{var=visitor.last_product_view_url}', label: 'templateBuilder.variables.lastProductViewUrl' },
  { value: '{var=contact.seniority}', label: 'templateBuilder.variables.customerSeniority' },
  { value: '{var=contact.lastorder_time}', label: 'templateBuilder.variables.customerLastorderTime' },
  { value: '{var=voucher.code}', label: 'templateBuilder.variables.voucherNumber' },
  { value: '{var=voucher.description}', label: 'templateBuilder.variables.voucherDescription' },
  { value: '{var=voucher.amount}', label: 'templateBuilder.variables.voucherAmount' },
  { value: '{var=voucher.minimum_order}', label: 'templateBuilder.variables.voucherMinimumOrder' },
  { value: '{var=voucher.date_end}', label: 'templateBuilder.variables.voucherDateLimit' },
  { value: '{var=voucher.nb_day_validate}', label: 'templateBuilder.variables.voucherNbDayExpire' },
  { value: '{var=order.amount}', label: 'templateBuilder.variables.orderAmount' },
  { value: '{var=order.reference}', label: 'templateBuilder.variables.orderReference' },
  { value: '{var=order.status.name}', label: 'templateBuilder.variables.orderStatus' },
];

export default {
  allVariables,
  aiVariables,
};
