<template>
  <div class="flex justify-content-between align-items-center">
    <label data-test-id="structure-edit-label">{{ t(config.label) }}</label>
  </div>
  <div
    class="flex my-2"
    :class="config.options.direction === direction.VERTICAL ? 'flex-column' : 'flex-row'"
  >
    <draggable
      :list="itemsList"
      :item-key="(element) => element"
      @end="handleDragend"
    >
      <template #item="{ element }">
        <div
          :class="config.options.direction === direction.HORIZONTAL ? 'item-block-horizontal' : 'item-block-vertical'"
          @click="handleClick(element)"
        >
          <div class="item-container flex justify-content-center align-items-end">
            <span>{{ element + 1 }}</span>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { store } from '@/store';

import {
  StructureConfig,
  StructureEditDirectionEnum,
} from '@/types';
import {
  computed,
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import draggable from 'vuedraggable';

import { onSelectStructure, onStructureOrderChange } from '@/components/template-builder/utils/structure-edit-handlers';

export default defineComponent({
  name: 'StructureEdit',

  components: {
    draggable,
  },

  props: {
    structureConfig: {
      type: Object as PropType<StructureConfig>,
      required: true,
    },

    childrenCount: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const direction = StructureEditDirectionEnum;

    const itemsCount: Ref<number> = ref(props.childrenCount);
    const activeItemSelector = computed(() => store.getters['liveEditor/getActiveItemSelector']);
    const itemsList = computed(() => Array.from(Array(itemsCount.value).keys()));
    const config = computed(() => props.structureConfig);

    watch(() => props.childrenCount, (value: number) => {
      itemsCount.value = value;
    }, { immediate: true });

    const handleDragend = (event: any) => {
      onStructureOrderChange(activeItemSelector.value, config.value.type, event.oldIndex, event.newIndex);
    };

    const handleClick = (element: number) => {
      onSelectStructure(activeItemSelector.value, config.value.type, element);
    };

    return {
      handleDragend,
      handleClick,
      direction,
      config,
      itemsList,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
.item-container {
  background: $white;
  border: 2px solid $shuttle-grey;
  border-radius: $field-border-radius;
  cursor: pointer;
  min-height: 45px;
  min-width: 35px;
}

.item-block-horizontal {
  display: flex;
  align-items: center;
  color: $tuna;

  &:not(:last-child)::after {
    font-family: 'Font Awesome 6 Pro';
    content: '\f142 \f142'; // far fa-ellipsis-h
    font-size: 20px;
    letter-spacing: 3px;
    margin: 0 0.5rem;
  }
}

.item-block-vertical {
  &:not(:last-child)::after {
    font-family: 'Font Awesome 6 Pro';
    content: '\f141 \A \f141'; // far fa-ellipsis-v
    font-size: 20px;
    line-height: 10px;
    white-space: pre;
    display: block;
    text-align: center;
    margin: 0.5rem 0;
  }
}
</style>
