
import { useI18n } from 'vue-i18n';
import {
  computed, defineComponent,
  PropType,
  reactive,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';

import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import InputText from 'primevue/inputtext';
import { ActiveSegment } from '@/composables/AutomatedScenarioSegments';
import { FilterSegment } from '@/types/automated-scenarios';

import DeclencheurVisitPageMetaData, {
  DeclencheurVisitPageData, DeclencheurVisitPageFilters,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurVisitPageMetadata';
import getSegments from '@/components/automated-scenarios/forms/declencheurs/segments/FiltreVisitPage';
import FilterPanelContent from '@/components/automated-scenarios/FilterPanelContent.vue';
import FilterSegmentForm from '@/components/automated-scenarios/FilterSegmentForm.vue';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import { asInt } from '@/helpers';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'DeclencheurVisitPage',
  components: {
    FieldErrors,
    InputText,
    ButtonGroup,
    FilterSegmentForm,
    FilterPanelContent,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurVisitPageData>,
      required: true,
      default() {
        return DeclencheurVisitPageMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurVisitPageData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const commonFields = reactive({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      trigger_location: asInt(props.modelValue.trigger_location),
    });

    const trigger_location_options = [
      { value: 1, label: t('automatedScenarios.declencheur.trigger_location_tout_site') },
      { value: 0, label: t('automatedScenarios.declencheur.trigger_location_pages_specifique') },
    ];

    const filters: Ref<DeclencheurVisitPageFilters> = ref({
      products: props.modelValue.products,
      categories: props.modelValue.categories,
      manufacturers: props.modelValue.manufacturers,
      urls: props.modelValue.urls,
    });

    const segments: Ref<FilterSegment<DeclencheurVisitPageFilters>[]> = computed(() => getSegments());

    const activeSegments = computed(() => {
      const active: ActiveSegment[] = [];

      if (Object.keys(props.modelValue.products).length > 0) {
        active.push({ name: 'products', label: segments.value.filter((segment) => segment.id === 'products')[0].option.label, show: true });
      }
      if (Object.keys(props.modelValue.categories).length > 0) {
        active.push({ name: 'categories', label: segments.value.filter((segment) => segment.id === 'categories')[0].option.label, show: true });
      }
      if (Object.keys(props.modelValue.manufacturers).length > 0) {
        active.push({ name: 'manufacturers', label: segments.value.filter((segment) => segment.id === 'manufacturers')[0].option.label, show: true });
      }
      if (Object.keys(props.modelValue.urls).length > 0) {
        active.push({ name: 'urls', label: segments.value.filter((segment) => segment.id === 'urls')[0].option.label, show: true });
      }

      return active;
    });

    const configuringSegment = computed(() => state.configuringSegment);

    const activeItemsFilterPanel = computed(() => ({ browsing_criteria: activeSegments.value.length }));

    const update = () => {
      context.emit('update:modelValue', DeclencheurVisitPageMetaData.Create({
        ...commonFields,
        products: filters.value.products ?? {},
        categories: filters.value.categories ?? {},
        manufacturers: filters.value.manufacturers ?? {},
        urls: filters.value.urls ?? {},
        operatorBoxNav: 'or',
        outputs_number: 1,
      }));
    };

    const setPickerSegment = (segment: FilterSegment<DeclencheurVisitPageFilters>) => {
      const all = segment.fields[0];
      const picker = segment.fields[1];

      // segment.id === 'products'
      let title_picker_var = 'product_picker_product';
      let title_picker = t('automatedScenarios.fields.segments.productChoices');

      if (segment.id === 'categories') {
        title_picker_var = 'product_picker_category';
        title_picker = t('automatedScenarios.fields.segments.categoryChoices');
      }
      if (segment.id === 'manufacturers') {
        title_picker_var = 'product_picker_manufacturer';
        title_picker = t('automatedScenarios.fields.segments.manufacturerChoices');
      }

      return {
        [all.id]: all.data,
        [picker.id]: picker.data,
        litteral_title: {
          [title_picker_var]: title_picker,
          all: t('automatedScenarios.fields.segments.triggerAfterVisiting'),
        },
      };
    };

    const setUrlSegment = (segment: FilterSegment<DeclencheurVisitPageFilters>) => ({
      [segment.fields[0].id]: segment.fields[0].data,
      [segment.fields[1].id]: segment.fields[1].data,
    });

    const handleSaveSegment = (segment: FilterSegment<DeclencheurVisitPageFilters>) => {
      if (['products', 'categories', 'manufacturers'].includes(segment.id)) {
        filters.value[segment.id] = setPickerSegment(segment);
      } else {
        filters.value[segment.id] = setUrlSegment(segment);
      }
      update();
    };

    const handleRemoveSegment = (segmentName: keyof DeclencheurVisitPageFilters) => {
      filters.value[segmentName] = {};
      update();
    };

    watch([commonFields], update);

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      activeSegments,
      commonFields,
      filters,
      handleSaveSegment,
      handleRemoveSegment,
      segments,
      trigger_location_options,
      configuringSegment,
      activeItemsFilterPanel,
    };
  },
});
