<template>
  <SpmOverlayPanel
    v-if="items.length > 0"
    class-trigger="flex align-items-center justify-content-center action-renderer-trigger"
    class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
  >
    <template #trigger>
      <i class="far fa-ellipsis-v fa-2x" />
    </template>
    <SpmPanelMenu
      :items="items"
      :data="data"
    />
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  computed, defineComponent, PropType,
} from 'vue';
import { SpmTableAction } from '@/types';
import Tooltip from 'primevue/tooltip';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';

export default defineComponent({
  name: 'ActionRenderer',

  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    actions: {
      type: Array as PropType<Array<SpmTableAction>>,
      required: false,
      default: () => [],
    },

    data: {
      type: Object,
      required: true,
    },
  },

  setup(props: { actions: SpmTableAction[]; data: any }) {
    const items = computed(() => props.actions.filter((action: SpmTableAction) => action.show(props.data)));
    return { items };
  },

});
</script>

<style lang="css" scoped>
.p-menuitem-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
</style>

<style lang="scss">
.action-renderer-trigger {
  color: $navbar-gray-color;
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin: auto;
}
</style>
