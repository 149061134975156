<template>
  <h4 class="mb-3">
    {{ t(modelValue.label) }}
  </h4>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  onMounted, PropType, SetupContext, watch,
} from 'vue';
import DeclencheurStatusCommandMetaData, {
  DeclencheurStatusCommandData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurStatusCommandMetadata';

export default defineComponent({
  name: 'DeclencheurStatusCommand',
  components: {},

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurStatusCommandData>,
      required: true,
      default() {
        return DeclencheurStatusCommandMetaData.Create();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurStatusCommandData }, context: SetupContext) {
    const { t } = useI18n();

    const update = () => context.emit('update:modelValue', DeclencheurStatusCommandMetaData.Create()); // todo

    watch([], update);
    onMounted(update);

    return { t };
  },
});
</script>
