
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
  nextTick,
} from 'vue';
import EditorModal from '@/components/modals/EditorModal.vue';
import {
  PrimvueMenuModel, StatsEditorPanelEnum, StatsType,
} from '@/types';
import { useI18n } from 'vue-i18n';
import StatsBuilder from '@/components/stats-builder/StatsBuilder.vue';
// eslint-disable-next-line import/no-cycle
import { useStore } from '@/store';
import { useConfirm } from 'primevue/useconfirm';
import ComponentsStatsBuilderWidgetConfig from '@/components/stats-builder/widgets';
import {
  GlobalEmailSettingsData,
  StatsBuilderRowConfig,
  StatsBuilderSelectedWidget,
} from '@/types/stats-editor-types';
import Panel from 'primevue/panel';
import Button from 'primevue/button';
import { showToastError, showToastSuccess } from '@/helpers';
import Tooltip from 'primevue/tooltip';
import StatsSettingsPanel from '@/components/stats-builder/settingsPanel/StatsSettingsPanel.vue';
import EmailSettingsPanel from '@/components/stats-builder/settingsPanel/EmailSettingsPanel.vue';
import { pageSettingsValidate, UpdateUsersPagesConfiguration } from '@/composables/Statistics/usersPagesConfiguration';
import { UsersPagesConfigurationUpdateInputItem } from '@/types/generated-types/graphql';
import StatsBuilderFilters from '@/components/stats-builder/StatsBuilderFilters.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import StatsBuilderElementsPanel from '@/components/stats-builder/StatsBuilderElementsPanel.vue';
import axios from 'axios';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import SendTestModal, { Suggestion } from '@/components/modals/SendTestModal.vue';
import { nestPost } from '@/composables/nestApi';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'StatsEditor',

  components: {
    SendTestModal,
    StatsBuilderElementsPanel,
    ConfirmDialog,
    StatsBuilderFilters,
    EmailSettingsPanel,
    StatsSettingsPanel,
    StatsBuilder,
    EditorModal,
    Panel,
    Button,
    ...ComponentsStatsBuilderWidgetConfig,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const confirm = useConfirm();

    const pageSettingState = computed(() => {
      const pageSetting = store.getters['statsEditor/getPageSettings'];

      try {
        const name = JSON.parse(pageSetting.name);
        const navigationName = JSON.parse(pageSetting.navigationName);

        pageSetting.name = name;
        pageSetting.navigationName = navigationName;

        return pageSetting;
      } catch (error) {
        return pageSetting;
      }
    });

    const pageSettingStateName = computed(() => {
      if (typeof pageSettingState.value.name !== 'string') {
        if (locale.value in pageSettingState.value.name) {
          return pageSettingState.value.name[locale.value];
        }
        return pageSettingState.value.name.fr;
      }
      return pageSettingState.value.name;
    });

    const pageTypeState = computed(() => store.getters['statsEditor/getPageType']);
    const pageRowsState = computed({
      get() {
        return store.getters['statsEditor/getPageRows'];
      },
      set(newValue) {
        store.commit('statsEditor/setPageRows', newValue);
      },
    });
    const formValidation = ref<any>({});

    /* Filter configuration */
    const canConfigureFilters = computed(() => store.getters['statsEditor/getCanConfigureFilters']);

    /* Widget panel */
    const leftToolbarState = computed(() => store.getters['statsEditor/getLeftToolbar']);
    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);

    /* Widget Data Metrics */
    const groupedCategoriesPredefinedMetrics: any = ref([]);

    /* Temp Rows */
    const tempRow = computed(() => store.getters['statsEditor/getTempRow']);

    /* Remove temp row */
    const removeTempRow = async () => {
      if (tempRow.value) {
        await nextTick();
        store.dispatch('statsEditor/removeRow', {
          location: {
            rowId: tempRow.value.rowId,
            columnId: tempRow.value.columnId,
            panelData: tempRow.value.panelData,
          },
        });
      }
    };

    const closeWidgetEditorPanel = async () => {
      formValidation.value = {};
      store.commit('statsEditor/hideLeftToolbar');
      store.commit('statsEditor/resetSelectedWidget');
      store.dispatch('statsEditor/removePlaceholderRows');

      /** Remove temp row */
      await removeTempRow();
    };

    const leavePage = () => {
      store.commit('statsEditor/resetState');
      store.commit('statsEditor/setRefreshComponents', true);
      store.commit('statsEditor/setRefreshNavigationBar', true);
      store.commit('statsEditor/resetTempRow');
    };

    function removeColumn(row: any, idColumn: string) {
      if (row.type === 'StatsWidgetRow') {
        for (let j = 0; j < row.children.length; j++) {
          const column = row.children[j];
          if (column.type === 'StatsWidgetColumn' && column.id === idColumn) {
            const { layout } = column;
            row.children.splice(j, 1);
            if (row.children.length > 0) {
              // eslint-disable-next-line radix,no-param-reassign
              row.children[0].layout = (parseInt(row.children[0].layout) + parseInt(layout)).toString();

              const newRowLayout = [];
              for (let k = 0; k < row.children.length; k++) {
                newRowLayout.push(row.children[k].layout);
              }
              // eslint-disable-next-line no-param-reassign
              row.layout = newRowLayout.join('-');
            }
            return;
          }
        }
      }
    }

    const checkWidgetTabs = (tabs: any[], remove: boolean): boolean => {
      let check = true;

      for (let i = 0; i < tabs.length; i++) {
        if ('children' in tabs[i]) {
          if (!tabs[i].children.length) {
            if (remove) {
              tabs.splice(i, 1);
            }
            check = false;
          } else {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            check = checkRows(tabs[i].children, remove);
          }
        } else if ('items' in tabs[i]) {
          if (!tabs[i].items.length) {
            if (remove) {
              tabs.splice(i, 1);
              break;
            }
            check = false;
          } else {
            check = checkWidgetTabs(tabs[i].items, remove);
          }
        }
        if (!check && !remove) {
          break;
        }
      }
      return check;
    };

    const checkRows = (rows: StatsBuilderRowConfig[], remove = false): boolean => {
      let check = true;
      // disable check rows protection
      return check;
      if (rows.length) {
        for (let i = 0; i < rows.length; i++) {
          for (let j = 0; j < rows[i].children.length; j++) {
            const { widgets } = rows[i].children[j];
            if (widgets === null) {
              check = false;
              if (remove) {
                removeColumn(rows[i], rows[i].children[j].id);
                if (!rows[i].children.length) {
                  rows.splice(i, 1);
                  break;
                }
              }
            } else if (widgets.type === 'StatsWidgetTabs') {
              if (!widgets.tabs.length) {
                check = false;
                if (remove) {
                  removeColumn(rows[i], rows[i].children[j].id);
                  if (!rows[i].children.length) {
                    rows.splice(i, 1);
                    break;
                  }
                }
              } else {
                check = checkWidgetTabs(widgets.tabs, remove);
              }
            } else if (widgets.type === 'StatsWidgetMenu') {
              if (!widgets.toolbar.length) {
                check = false;
                if (remove) {
                  removeColumn(rows[i], rows[i].children[j].id);
                  if (!rows[i].children.length) {
                    rows.splice(i, 1);
                    break;
                  }
                }
              } else {
                check = checkWidgetTabs(widgets.toolbar, remove);
              }
            }
          }

          if (!check && !remove) {
            break;
          }
        }
      }

      return check;
    };

    const savePage = (leave: boolean) => {
      store.commit('general/showTheSpinner');

      let extra_data: any = null;
      if (pageTypeState.value === StatsType.EMAIL) {
        extra_data = {
          mail_subject: (pageSettingState.value as GlobalEmailSettingsData).mail_subject,
          mailing_frequency: (pageSettingState.value as GlobalEmailSettingsData).mailing_frequencies,
        };
      }

      if (extra_data) {
        extra_data.defaultFilters = store.getters['statsEditor/getPageFilters'];
      } else {
        extra_data = {
          defaultFilters: store.getters['statsEditor/getPageFilters'],
        };
      }

      if (extra_data) {
        extra_data = JSON.stringify(extra_data);
      }

      const usersPagesConfig: UsersPagesConfigurationUpdateInputItem[] = [{
        id_users_pages_configuration: store.getters['statsEditor/getPageId'],
        page_name: JSON.stringify(pageSettingState.value.name),
        navigation_name: Object.prototype.hasOwnProperty.call(pageSettingState.value, 'navigationName') ? JSON.stringify(pageSettingState.value.navigationName) : JSON.stringify(pageSettingState.value.name),
        configuration: JSON.stringify(pageRowsState.value),
        extra_data,
        date_modification: new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }),
      }];

      UpdateUsersPagesConfiguration(usersPagesConfig).then((result) => {
        if (result.status && result.id) {
          showToastSuccess(t('statsEditor.successSave'));
          if (leave) {
            leavePage();
          }
        } else {
          showToastError(t('statsEditor.errorSave'));
        }
        store.commit('general/hideTheSpinner');
      }).catch((error) => {
        showToastError(t('statsEditor.errorSave'));
        store.commit('general/hideTheSpinner');
      });
    };

    const savePageConfirmationDialog = async (leave: boolean) => {
      /** Remove temp row */
      await removeTempRow();

      const validation = await pageSettingsValidate(pageSettingState.value, pageTypeState.value, localizedTextInputValidator);
      formValidation.value = validation.validate;
      if (!validation.success) {
        if (!leftToolbarState.value.show || leftToolbarState.value.panel !== StatsEditorPanelEnum.SETTINGS_PANEL) {
          await closeWidgetEditorPanel();
          store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.SETTINGS_PANEL);
        }
        await showToastError(t('errorMessages.FORM_ERROR'));
      } else if (!checkRows(pageRowsState.value as StatsBuilderRowConfig[], false)) {
        confirm.require({
          group: 'statsEditor',
          message: t('statsEditor.checkBeforeSave.message'),
          header: t('statsEditor.checkBeforeSave.header'),
          acceptLabel: t('statsEditor.checkBeforeSave.acceptLabel'),
          rejectLabel: t('statsEditor.checkBeforeSave.rejectLabel'),
          accept: () => {
            const cloneRows = JSON.parse(JSON.stringify(pageRowsState.value));
            do {
              checkRows(cloneRows, true);
            } while (!checkRows(cloneRows, false));

            store.commit('statsEditor/setPageRows', cloneRows);
            savePage(leave);
          },
        });
      } else {
        savePage(leave);
      }
    };

    /* Header and second toolbar buttons */
    const toolbarHeaderRightButtons: PrimvueMenuModel[] = [
      {
        label: t('statsEditor.headerToolbar.save'),
        class: 'p-button-secondary',
        items: [
          {
            label: t('statsEditor.headerToolbar.saveAndLeave'),
            command: () => savePageConfirmationDialog(true),
          },
          {
            label: t('statsEditor.headerToolbar.save'),
            command: () => savePageConfirmationDialog(false),
          },
          {
            label: t('statsEditor.headerToolbar.leaveWithoutSaving'),
            command: () => leavePage(),
          },
        ],
      },
    ];
    const secondToolbarLeftButtons = computed(() => ([
      {
        icon: 'far fa-fw fa-cog',
        label: t('statsEditor.toolbar.label.settings'),
        tooltip: t('statsEditor.toolbar.tooltip.settings'),
        class: () => (leftToolbarState.value.show
        && leftToolbarState.value.panel === StatsEditorPanelEnum.SETTINGS_PANEL ? 'active' : ''),
        command: () => {
          if (leftToolbarState.value.show
            && leftToolbarState.value.panel === StatsEditorPanelEnum.SETTINGS_PANEL) {
            closeWidgetEditorPanel();
          } else {
            closeWidgetEditorPanel();
            store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.SETTINGS_PANEL);
          }
        },
      },
      {
        icon: 'far fa-fw fa-th-large',
        label: t('Elements'),
        tooltip: t('Elements'),
        class: () => (leftToolbarState.value.show
        && leftToolbarState.value.panel === StatsEditorPanelEnum.ELEMENTS_LIST_PANEL ? 'active' : ''),
        command: () => {
          if (leftToolbarState.value.show
            && leftToolbarState.value.panel === StatsEditorPanelEnum.ELEMENTS_LIST_PANEL) {
            closeWidgetEditorPanel();
          } else {
            closeWidgetEditorPanel();
            store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.ELEMENTS_LIST_PANEL);
          }
        },
      },
      {
        icon: 'far fa-filter',
        label: t('Filtres'),
        tooltip: t('Filtres'),
        class: () => (leftToolbarState.value.show
        && leftToolbarState.value.panel === StatsEditorPanelEnum.FILTERS_PANEL ? 'active' : ''),
        command: () => {
          if (leftToolbarState.value.show
            && leftToolbarState.value.panel === StatsEditorPanelEnum.FILTERS_PANEL) {
            closeWidgetEditorPanel();
          } else {
            closeWidgetEditorPanel();
            store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.FILTERS_PANEL);
          }
        },
        hide: !canConfigureFilters.value,
      },
      {
        icon: 'far fa-fw fa-paper-plane',
        label: null,
        tooltip: t('statsEditor.toolbar.label.sendTest'),
        command: () => {
          // Open modal to select mail addresses to send the reports
          store.commit('general/showSendTestModal');
        },
        hide: pageTypeState.value !== StatsType.EMAIL,
      }]));

    const saveWidget = async () => {
      // validate form then save widget
      store.dispatch('statsEditor/saveWidget').then(async (response) => {
        if (response.success) {
          await showToastSuccess(t('statsEditor.toastMessages.saveWidget.success'));
          store.commit('statsEditor/resetTempRow');
          await closeWidgetEditorPanel();
          await nextTick();
          await store.dispatch('statsEditor/removePlaceholderRows');
        } else {
          showToastError(t('errorMessages.FORM_ERROR')).then(() => {
            formValidation.value = response.validate;
          });
        }
      });
    };

    onMounted(async () => {
      // get predefined metrics from api
      let getPredefinedMetrics: any = await axios.create({ baseURL: '/api' }).get<any>('/predefinedStatsWidget');
      getPredefinedMetrics = getPredefinedMetrics.data;

      /* Get groups */
      Object.keys(getPredefinedMetrics).forEach((key: any) => {
        const group: any = {
          label: t(`statsEditor.widgets.widgetData.config.field.category.groups.${key}.label`),
          key,
          items: [],
        };

        /* Get categories  */
        Object.keys(getPredefinedMetrics[key]).forEach((categoryKey: any) => {
          const allMetrics: any = [];
          /* Format metric data */
          let allowMultiple = true;
          Object.keys(getPredefinedMetrics[key][categoryKey]).forEach((metricKey: any) => {
            const metric = getPredefinedMetrics[key][categoryKey][metricKey];

            Object.keys(metric).forEach((metricTypeKey: any) => {
              if (metricTypeKey === 'chart') {
                if (Object.prototype.hasOwnProperty.call(metric[metricTypeKey], 'allowMultiple') && !metric[metricTypeKey].allowMultiple) {
                  allowMultiple = false;
                }

                delete metric[metricTypeKey].chartOptions;
                delete metric[metricTypeKey].allowMultiple;
                Object.keys(metric[metricTypeKey]).forEach((channelTypeKey: any) => {
                  /* getting available values for filters */
                  const allFilters = metric[metricTypeKey][channelTypeKey].chartConfiguration.filters;

                  metric[metricTypeKey][channelTypeKey].availableCharts.forEach((chartTypeKey: any) => {
                    /* Format filters for current chart type */
                    const formattedFilters: any = {
                      channel: false,
                      type: false,
                    };
                    if (allFilters && allFilters.length) {
                      allFilters.forEach((filter: any) => {
                        if (filter.availableValues) {
                          const filterKey = filter.value.replace(':', '');
                          formattedFilters[filterKey.replace('_integers', '')] = filter.availableValues.map((itemFilter: any) => ({
                            name: t(`statsEditor.widgets.widgetData.config.field.filters.${filterKey}.${itemFilter}`),
                            code: itemFilter,
                          }));
                        }
                      });
                    }

                    metric[metricTypeKey][channelTypeKey][chartTypeKey] = formattedFilters;
                  });

                  delete metric[metricTypeKey][channelTypeKey].chartConfiguration;
                  delete metric[metricTypeKey][channelTypeKey].availableCharts;
                });
              } else {
                /* getting available values for filters */
                const allFilters = metric[metricTypeKey].filters;
                const formattedFilters: any = {
                  channel: false,
                  type: false,
                };

                if (allFilters && allFilters.length) {
                  allFilters.forEach((filter: any) => {
                    if (filter.availableValues) {
                      const filterKey = filter.value.replace(':', '');
                      formattedFilters[filterKey.replace('_integers', '')] = filter.availableValues.map((itemFilter: any) => ({
                        name: t(`statsEditor.widgets.widgetData.config.field.filters.${filterKey}.${itemFilter}`),
                        code: itemFilter,
                      }));
                    }
                  });
                }
                metric[metricTypeKey] = formattedFilters;
              }
            });

            const data: Record<string, any> = {
              childrenLabel: t(`statsEditor.widgets.widgetData.config.field.category.groups.${key}.categories.${categoryKey}.metrics.${metricKey}`),
              group: key,
              category: categoryKey,
              metric: metricKey,
              allowMultiple,
              value: metric,
            };

            allMetrics.push(data);
          });

          if (allMetrics.length > 0) {
            group.items.push({
              label: t(`statsEditor.widgets.widgetData.config.field.category.groups.${key}.categories.${categoryKey}.label`),
              groupKey: key,
              key: categoryKey,
              value: allMetrics,
            });
          }
        });

        if (group.items.length > 0) {
          groupedCategoriesPredefinedMetrics.value.push(group);
        }
      });
    });

    /**
     * Send test report by e-mail
     * @param params
     */
    const sendTestReport = async (params: Suggestion[]) => {
      let extraData: any = null;
      if (pageTypeState.value === StatsType.EMAIL) {
        extraData = {
          mail_subject: (pageSettingState.value as GlobalEmailSettingsData).mail_subject,
          mailing_frequency: (pageSettingState.value as GlobalEmailSettingsData).mailing_frequencies,
        };
      }

      if (extraData) {
        extraData.defaultFilters = store.getters['statsEditor/getPageFilters'];
      } else {
        extraData = {
          defaultFilters: store.getters['statsEditor/getPageFilters'],
        };
      }

      if (extraData) {
        extraData = JSON.stringify(extraData);
      }

      nestPost('esr', '/esr/send-test', {}, {
        data: {
          idShop: UserState.activeShop?.id ?? 0,
          idReport: parseInt(store.getters['statsEditor/getPageId'], 10),
          addresses: params,
          configuration: JSON.stringify(pageRowsState.value),
          extraData,
        },
      }).then(async (result) => {
        if (result && result.success) {
          await showToastSuccess('emailReportSent');
        } else {
          await showToastError('GENERIC_ERROR');
        }
      });
    };

    return {
      t,
      pageTypeState,
      pageRowsState,
      leftToolbarState,
      StatsType,
      StatsEditorPanelEnum,
      toolbarHeaderRightButtons,
      secondToolbarLeftButtons,
      selectedWidgetState,
      saveWidget,
      closeWidgetEditorPanel,
      formValidation,
      groupedCategoriesPredefinedMetrics,
      pageSettingStateName,
      sendTestReport,
    };
  },
});
