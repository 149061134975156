<template>
  <div class="card">
    <div class="field p-inputgroup">
      <Textarea
        id="token_script"
        v-model="script"
        rows="4"
        disabled
      />
      <Button
        :label="t('copy')"
        icon="far fa-copy"
        @click="copy"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import {
  defineComponent,
  onMounted, PropType, ref, SetupContext, watch,
} from 'vue';
import DeclencheurCustomJSMetaData, {
  DeclencheurCustomJSData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurCustomJSMetadata';

export default defineComponent({
  name: 'DeclencheurCustomJS',
  components: {
    Textarea,
    Button,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurCustomJSData>,
      required: true,
      default() {
        return DeclencheurCustomJSMetaData.Create();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurCustomJSData }, context: SetupContext) {
    const { t } = useI18n();

    const script = ref('');

    // eslint-disable-next-line vue/no-setup-props-destructure
    script.value = props.modelValue.token_script;

    const copy = () => {
      navigator.clipboard.writeText(script.value).then(() => null, () => null);
    };

    const update = () => context.emit(
      'update:modelValue',
      DeclencheurCustomJSMetaData.Create({
        token_script: script.value,
      }),
    );

    watch([script], update);
    onMounted(update);

    return {
      t,
      script,
      copy,
    };
  },
});
</script>

<style scoped>
#token_script {
  resize: none !important;
  min-height: 180px;
  max-height: 240px;
}
</style>
