
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import Textarea from 'primevue/textarea';
import { isValidEmail } from '@/helpers/Email';
import { useToast } from 'primevue/usetoast';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import ColumnMappings from '@/views/mes-listes/customers/ImportCustomers/ColumnMappings.vue';
import { store } from '@/store';
import { ManualListImportConfigData } from '@/types/lists';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'ConfigManualCopyPaste',

  components: {
    ColumnMappings,
    Textarea,
    SpmButton,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const showMappings = ref(false);
    const emailsStr = ref((store.getters['listImportEditor/getConfigData'] as ManualListImportConfigData).dataString);

    watch(() => emailsStr.value, () => {
      store.commit('listImportEditor/setConfigData', {
        ...store.getters['listImportEditor/getConfigData'],
        dataString: emailsStr.value,
      });
    });

    const validateCopyPasteInput = () => {
      const lines = emailsStr.value.trim().split('\n');
      lines.filter((line) => line !== '');

      const error = lines.some((line) => {
        const fields = line.split(',');
        return fields.filter((field) => isValidEmail(field)).length === 0;
      });

      if (error) {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Emails not valid',
          life: 3000,
        });
        return;
      }

      showMappings.value = true;
    };

    return {
      t,
      showMappings,
      emailsStr,
      validateCopyPasteInput,
    };
  },
});
