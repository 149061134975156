import {
  Insert, List, Request, RequestResult, SaveResult,
} from '@/composables/GraphQL';
import {
  OperatorType, ShopsCustomers, ShopsCustomersAddresses, ShopsCustomersShopsLists, ShopsListsActionsInputItem, ShopsListsImport, UsersPagesConfiguration,
} from '@/types/generated-types/graphql';
import { reactive } from 'vue';

import moment from 'moment';
import { SpmTableFilter } from '@/types';
import { UserState } from '@/composables/User';

interface ListResponse<T> {
  items: Array<T>;
  total: number;
  err: string;
}

interface CustomerState {
  listName: string;
  listAutoUpdated: boolean;
  countRequestListImport: number;
}

export interface CustomerLists {
  list: ListResponse<ShopsCustomers>;
  listAutoUpdate: boolean;
  countRequestListImport: number;
}

export const getShopsListsImportList = async (idShopList: number): Promise<Array<ShopsListsImport>|null> => {
  const { items, err } = await List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      filter: [
        {
          field: 'id_shop_list',
          operator: OperatorType.Equals,
          value: idShopList,
        },
        {
          field: 'params',
          operator: OperatorType.Equals,
          value: 'segmentation',
        },
        {
          field: 'stay_updated',
          operator: OperatorType.Equals,
          value: 1,
        },
      ],
      order: [
        { field: 'id_shop_list', type: 'ASC' },
      ],
      limit: 100,
      offset: 0,
    },
    fields: ['id_shop_list'],
  });

  if (err === '') {
    return items;
  }
  return null;
};

export const getShopsListsQueuedLists = async (idShopList: number): Promise<Array<ShopsListsImport>|null> => {
  const { items, err } = await List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      filter: [
        {
          field: 'id_shop_list',
          operator: OperatorType.Equals,
          value: idShopList,
        },
        {
          field: 'state',
          operator: OperatorType.In,
          value: 'queued,pending',
        },
      ],
      order: [
        { field: 'id_shop_list', type: 'ASC' },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['id_shop_list'],
  });

  if (err === '') {
    return items;
  }
  return null;
};

export async function getShopsCustomers(filters: SpmTableFilter[], fields: string[]) {
  return List<ShopsCustomers>({
    name: 'ShopsCustomers',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: filters,
    },
    fields,
  });
}

export default async function getShopCustomersDetails(idShop: number, idShopList: number): Promise<CustomerLists> {
  const filters: SpmTableFilter[] = [
    {
      field: 'id_shop',
      value: idShop,
      operator: OperatorType.Equals,
    },
    {
      field: 'deleted',
      value: 0,
      operator: OperatorType.Equals,
    },
  ];

  const fields: string[] = [
    'id_shop_customer', 'date_modification', 'last_name',
    'first_name', 'email', 'country', 'lang',
    'newsletter', 'unsub_type', 'deleted',
  ];

  const list = await getShopsCustomers(filters, fields);
  let listAutoUpdate = false;
  await getShopsListsImportList(idShopList).then((result) => {
    if (result) {
      listAutoUpdate = result?.length > 1;
    }
  });
  let countRequestListImport = 0;
  await getShopsListsQueuedLists(idShopList).then((result) => {
    if (result) {
      countRequestListImport = result.length;
    }
  });
  return {
    list,
    listAutoUpdate,
    countRequestListImport,
  };
}

export async function getShopCustomerShopsLists(idShop: number): Promise<ListResponse<ShopsCustomersShopsLists>|null> {
  return List<ShopsCustomersShopsLists>({
    name: 'ShopsCustomersShopsLists',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'sc.id_shop',
          value: idShop,
          operator: OperatorType.Equals,
        },
      ],
    },
    fields: [
      'id_shop_customer', 'date_modification', 'last_name',
      'first_name', 'email', 'country', 'lang',
      'newsletter', 'unsub_type', 'id_shop_lists', 'shop_lists_name',
    ],
  });
}

export async function insertShopsListsActions(
  groupActions: string| null, idShop: number, listName: string | null, type: string,
  customers_ids: number[], from: string| null, to: string | null, search_filters: string,
  params: string | null,
): Promise<SaveResult<ShopsListsActionsInputItem>> {
  const shopsListsActions: ShopsListsActionsInputItem = {
    id_shop: idShop,
    type,
    id_list_from: from,
    id_list_to: to,
    name_new_list: listName,
    grouped_actions_type: groupActions,
    search_filters: search_filters !== '' ? JSON.stringify(search_filters) : null,
    grouped_actions_selection: customers_ids.length > 0 ? customers_ids.join(',') : null,
    params,
    is_treated: 0,
    date_add: moment().format('YYYY-MM-DD HH:mm:ss'),
  };

  return Insert(
    {
      name: 'ShopsListsActions',
      input: [shopsListsActions],
      type: 'ShopsListsActionsInput',
    },
  );
}

export const getCustomerFromAddress = async (phone: string): Promise<RequestResult<ShopsCustomers>> => Request<ShopsCustomers>({
  name: 'ShopsCustomersAddressesGetCustom',
  variables: { phone },
  query: `
          query ($phone: String) {
            ShopsCustomersAddressesGetCustom(phone: $phone) {
              id_shop_customer,
              unsub_type
            }
          }
        `,
});
