
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { UserState } from '@/composables/User';
import { defineComponent, ref } from 'vue';
import { SpmTableColumns } from '@/types';
import { OperatorType } from '@/types/generated-types/graphql';
import Button from 'primevue/button';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import PanelMenu from 'primevue/panelmenu';

export default defineComponent({
  name: 'ListsImportReports',

  components: {
    SpmTable,
    Button,
    SpmOverlayPanel,
    PanelMenu,
  },

  setup() {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const navigate = (where: string) => {
      router.push({ path: where });
    };

    const persistentFilters = [
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: idShop,
      },
      {
        field: 'state',
        operator: OperatorType.In,
        value: 'processed,report',
      },
      {
        field: 'date',
        operator: OperatorType.IsNotNull,
        value: 'null',
      },
    ];

    const baseUrl = `http://${process.env.VUE_APP_DOMAIN_EXPORT_LIST}/`;

    const options = ref([
      { label: t('myLists.importReports.download.importedContacts') },
      { label: t('myLists.importReports.download.partially') },
      { label: t('myLists.importReports.download.notImported') },
    ]);

    const columns: SpmTableColumns[] = [
      {
        field: 'shopsLists{name},date',
        header: t('myLists.importReports.headers.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle; width: 20%',
        type: 'text',
      },
      {
        field: 'nb_customers',
        header: t('myLists.importReports.headers.noContacts'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'nb_imported_customers',
        header: t('myLists.importReports.headers.imported'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'nb_partially_imported_customers',
        header: t('myLists.importReports.headers.partiallyImported'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'nb_non_imported_customers',
        header: t('myLists.importReports.headers.unImported'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'Actions',
        header: t('myLists.importReports.headers.action'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
        custom: true,
      },
    ];

    return {
      t, navigate, columns, options, baseUrl, persistentFilters, idShop,
    };
  },
});
