import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { ABTestDecreaseOutputsValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface ActionABTestData {
  outputs_number_test: number;
  initial_number_outputs?: number|null;
  outputs_to_delete?: string[]|null;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const ActionABTestMetadata: ComponentMetadata<ActionABTestData> = {
  Meta: {
    kind: 'action',
    id: 'boxabtest',
    label: 'automatedScenarios.actions.abTest.shortLabel',
    icon: 'fa-code-branch',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxstatuscommand',
          'registeronsite', 'subscribetonewsletter',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxstatuscommand',
          'registeronsite', 'subscribetonewsletter', 'boxchoosebulklist',
        ],
      },
    },
    component: 'ActionABTest',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION], // TODO Reactive for bulk when available
  },
  Create(data: ActionABTestData = { outputs_number_test: 2 }): ComponentData<ActionABTestData> {
    return {
      id: 'boxabtest',
      form_name: 'boxabtest',
      logo_box: 'fa-code-fork',
      ...data,
    };
  },
  async Validate(data: ActionABTestData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      outputs_number_test: {
        required,
        integer,
        between: between(2, 6),
        ABTestDecreaseOutputsValidator: ABTestDecreaseOutputsValidator(data),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: ActionABTestData): ActionABTestData {
    const dataToReturn: ActionABTestData = data;
    const outputsToDelete = data.outputs_to_delete;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }
    if (typeof dataToReturn.outputs_to_delete !== 'undefined') {
      delete dataToReturn.outputs_to_delete;
    }

    if (outputsToDelete !== null && outputsToDelete !== undefined) {
      decreaseNumberOfBranchesOfAnOperator(outputsToDelete);
    }

    return dataToReturn;
  },
  Outputs(data: ActionABTestData): number {
    return data.outputs_number_test;
  },
  Label(data: ActionABTestData): string {
    return translation.global.t('automatedScenarios.actions.abTest.fullLabel', { number: data.outputs_number_test });
  },
};

export default ActionABTestMetadata;
