
import {
  defineComponent,
  Ref,
  ref,
  PropType,
  watch, computed,
} from 'vue';

import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import ConfirmPopup from 'primevue/confirmpopup';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import CountryDropdown from '@/components/fields/CountryDropdown.vue';
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import {
  findPermission,
  UserState,
} from '@/composables/User';
import GetPaymentMethods, {
  adyenConfig, MakeDetailsCall, MakePaymentCall,
} from '@/composables/user/AdyenConfig';
import { BuyableElement } from '@/composables/shop/shopsPlans';
import AdyenCheckout from '@adyen/adyen-web';
import { showToastError, showToastSuccess } from '@/helpers';
import { formatNumberToCurrency } from '@/helpers/Number';
import { CountryAndStateValues } from '@/types/country-state-types';
import { SmsPricing } from '@/types/offer-types';
import { PaymentData } from '@/types/payment-types';
import { useI18n } from 'vue-i18n';
import ProgressBar from 'primevue/progressbar';

export default defineComponent({
  name: 'BuyableElementV14',
  components: {
    CountryDropdown,
    SpmButton,
    Dropdown,
    InputSwitch,
    Dialog,
    BlockUI,
    ConfirmPopup,
    ProgressBar,
  },

  props: {
    buyableElement: {
      type: Object as PropType<BuyableElement>,
      required: true,
    },

    smsPricing: {
      type: Object as PropType<Record<string, SmsPricing[]>>,
      required: true,
    },

    shopifyPaymentState: {
      type: Object as PropType<any>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const showPaymentModal = ref(false);

    // User and shop settings
    const userId = UserState.user.id;
    const locale = UserState.user.lang;
    const countryCode = locale.toLocaleUpperCase();
    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    // eslint-disable-next-line no-param-reassign
    props.buyableElement.displayAutoRenew = UserState.activeShop?.solutionEcommerce !== 'shopify';

    const defaultCountry = ref({
      country: UserState.user.country ?? 'FR',
      state: '',
    });
    // User and shop settings

    // Selected values for buyable elements
    const selectedCountry: Ref<CountryAndStateValues> = ref(defaultCountry.value);
    const modelValues: Ref<any> = ref(0);
    const autoRenew: Ref<boolean> = ref(false);
    const pricePerElement: Ref<any> = ref('');
    // Selected values for buyable elements

    // Adyen configuration
    const adyenLocale = `${locale}_${countryCode}`;
    const adyenEnvironment: string = process.env.VUE_APP_ADYEN_ENVIRONMENT ?? 'live';

    const loadingBuyButton = ref(false);

    const shopifyUrlConfirmationRedirect: any = ref('');
    const isRedirectShopifyModalVisible = computed(() => shopifyUrlConfirmationRedirect.value !== '');

    watch(isRedirectShopifyModalVisible, (newVal) => {
      if (newVal) {
        // Redirect after 5 seconds
        setTimeout(() => {
          window.location.href = shopifyUrlConfirmationRedirect.value;
        }, 5000);
      }
    });

    /**
     * Buy an element
     */
    const buyElement = async () => {
      const dataQuery: PaymentData = {
        idShop: UserState.activeShop?.id ?? 0,
        idUser: userId,
        elements: [
          {
            type: props.buyableElement.type,
            name: props.buyableElement.name,
            productId: props.buyableElement.productId,
            productTitle: t(props.buyableElement.productTitle, {
              qty: modelValues.value,
              country: selectedCountry.value ? t(`countries.codes.${selectedCountry.value.country}`) : '',
            }),

            country: selectedCountry.value ?? null,
            selectedValue: modelValues.value,
            autoRenew: autoRenew.value ?? null,
            totalPrice: pricePerElement.value,
            productPrice: pricePerElement.value,
          },
        ],

        paymentDetails: null,
      };

      if (UserState.activeShop && UserState.activeShop.solutionEcommerce === 'shopify') {
        try {
          loadingBuyButton.value = true;

          MakePaymentCall(JSON.stringify(dataQuery)).then(async (response) => {
            if (response.success) {
              shopifyUrlConfirmationRedirect.value = response.data.confirmation_url;
            } else {
              showToastError(t('offers.errors.buyElement'));
            }
          }).catch((reason: any) => {
            loadingBuyButton.value = false;
            showToastError('GENERIC_ERROR');
          }).then(() => {
            loadingBuyButton.value = false;
          });
        } catch (error) {
          loadingBuyButton.value = false;
          showToastError('GENERIC_ERROR');
        }
      } else {
        showPaymentModal.value = true;
        const paymentMethods = await GetPaymentMethods({
          id_user: userId, currency: 'EUR', amount: pricePerElement.value, country_code: countryCode, locale: adyenLocale,
        });

        const configuration = {
          translations: {
            'fr-FR': {
              payButton: t('offers.pay'),
            },
          },

          paymentMethodsResponse: paymentMethods.data,
          allowPaymentMethods: ['scheme'],
          removePaymentMethods: ['paypal'], // Retrait temporaire avant de pouvoir générer des tokens
          clientKey: adyenConfig[adyenEnvironment]['client-key'],
          locale: adyenLocale,
          environment: adyenConfig[adyenEnvironment].environment,
          onSubmit: async (state: any, dropin: any) => {
            dropin.setStatus('loading');
            dataQuery.paymentDetails = state.data;

            // Your function calling your server to make the `/payments` request
            MakePaymentCall(JSON.stringify(dataQuery)).then(async (response) => {
              if (response.data.action) {
                // Drop-in handles the action object from the /payments response
                dropin.handleAction(response.data.action);
              } else if (response.data.resultCode === 'Authorised' || response.data.resultCode === 'Received') {
                // Your function to show the final result to the shopper
                dropin.setStatus('success', { message: t('offers.paymentAccepted') });
                showToastSuccess(t('offers.success.buyElement'));

                // Reset buyable element to default values
                modelValues.value = 0;
                autoRenew.value = false;
                selectedCountry.value = defaultCountry.value;
                pricePerElement.value = '';
              } else {
                dropin.setStatus('error', { message: response.data.message });
                showToastError(t('offers.errors.buyElement'));
              }
            }).catch((reason: any) => {
              dropin.setStatus('error', { message: reason.response.data.refusalReason });
            });
          },

          onAdditionalDetails: (state: any, dropin: any) => {
            // Your function calling your server to make a `/payments/details` request
            MakeDetailsCall(JSON.stringify(state.data))
              .then((response) => {
                if (response.data.action) {
                  // Drop-in handles the action object from the /payments response
                  dropin.handleAction(response.data.action);
                } else if (response.data.resultCode === 'Authorised') {
                  // Your function to show the final result to the shopper
                  dropin.setStatus('success', { message: t('offers.paymentAccepted') });
                  showToastSuccess(t('offers.success.buyElement'));

                  // Reset buyable element to default values
                  modelValues.value = 0;
                  autoRenew.value = false;
                  selectedCountry.value = defaultCountry.value;
                  pricePerElement.value = '';
                } else {
                  dropin.setStatus('error', { message: response.message });
                  showToastError(t('offers.errors.buyElement'));
                }
              })
              .catch((error) => {
                throw Error(error);
              });
          },

          onPaymentCompleted: (result: any, component: any) => {
            console.info(result, component);
          },

          onError: (error: any, component: any) => {
            console.error(error.name, error.message);
          },

          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              hideCVC: false,
            },

            threeDS2: {
              challengeWindowSize: '05',
            },
          },
        };

        const checkout = await AdyenCheckout(configuration);
        checkout.create('dropin', { showStoredPaymentMethods: true }).mount('#dropInComponent').setComponentRef('dropInComponent');
      }
    };

    const handleCountryDropdownUpdate = () => {
      if ('countryCallback' in props.buyableElement && props.buyableElement.countryCallback) {
        pricePerElement.value = props.buyableElement.countryCallback(props.buyableElement, selectedCountry.value, modelValues.value, props.smsPricing);
      }
    };

    const handleDropdownUpdate = () => {
      pricePerElement.value = props.buyableElement.callback(modelValues.value, selectedCountry.value, props.smsPricing);
    };

    const redirectToLinkShopify = (url: string) => {
      shopifyUrlConfirmationRedirect.value = url;
    };

    watch(() => props.smsPricing, () => {
      if ('countryCallback' in props.buyableElement && props.buyableElement.countryCallback) {
        pricePerElement.value = props.buyableElement.countryCallback(props.buyableElement, selectedCountry.value, modelValues.value, props.smsPricing);
      }
    }, { deep: true });

    return {
      t,
      selectedCountry,
      modelValues,
      autoRenew,
      pricePerElement,
      currency,
      showPaymentModal,
      loadingBuyButton,
      buyElement,
      handleCountryDropdownUpdate,
      handleDropdownUpdate,
      redirectToLinkShopify,
      isRedirectShopifyModalVisible,
    };
  },

  methods: { findPermission, formatNumberToCurrency },
});
