// eslint-disable-next-line import/no-cycle
import {
  replaceTranslationInState,
  updateSectionsInState,
  removeUnusedTranslations,
} from '@/composables/template-editor/TemplateEditor';

import { TRANSLATION_ATTRIBUTE } from '@/components/template-builder/utils/constants';

import {
  UserState,
} from '@/composables/User';

import {
  Property,
} from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  getTemplateIframeDocument,
} from '../utils/helpers';

// eslint-disable-next-line import/no-cycle
import {
  generateTranslations,
} from '../utils/translate';

const setContent = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element && undefined !== property.value) {
    const { newHtml } = generateTranslations(
      property.value,
      {
        globalTranslate: true,
      },
    );

    // This setter can be involved by translation
    element.innerHTML = newHtml;

    // Update translation in state
    const newDiv = document.createElement('div');
    newDiv.innerHTML = newHtml;

    const defaultLanguage: string = UserState.activeShop?.langs.filter((lang) => lang.default).map((lang) => lang.id)[0] ?? 'fr';
    const translationIds = property.value.match(/[a-zA-Z-]+="LANG_[a-zA-Z0-9.]+"/gm);
    if (translationIds && translationIds.length) {
      translationIds.forEach((translationId: string) => {
        const attributeRaw = translationId.replace(/"/gm, '');
        const [attributeName, attributeValue] = attributeRaw.split('=');
        if (attributeName && attributeValue) {
          const matchedElement = newDiv.querySelector(`[${attributeName}="${attributeValue}"]`);
          if (matchedElement) {
            if (attributeName === 'id') {
              replaceTranslationInState(attributeValue.replace('LANG_', ''), defaultLanguage, matchedElement.innerHTML);
            } else {
              const attributeToGet = attributeName.replace(`${TRANSLATION_ATTRIBUTE}-`, '');
              const newValue = matchedElement.getAttribute(attributeToGet);
              if (newValue !== undefined || newValue !== null) {
                replaceTranslationInState(attributeValue.replace('LANG_', ''), defaultLanguage, newValue as string);
              }
            }
          }
        }
      });
    }

    updateSectionsInState();
    removeUnusedTranslations();
  }
};

export default setContent;
