
import { defineComponent, computed, ref } from 'vue';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import { UserState, tryPlan } from '@/composables/User';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';
import gleapActionForm from '@/configs/gleap-actions';
import Gleap from 'gleap';

export default defineComponent({
  name: 'FeatureUnavailableModal',
  components: {
    Dialog,
    SpmButton,
    Checkbox,
    TermsAndConditions,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    const isNotAccessibleDialogVisible = computed(() => store.getters['general/getIsFeatureUnavailableModalVisible']);
    const lastRoute = computed(() => store.getters['general/getLastRoute']);
    const featureUnavailableMinPlan = computed(() => store.getters['general/getFeatureUnavailableMinPlan']);
    const onAuthorizedClickFunction = computed(() => store.getters['general/getOnAuthorizedClickFunction']);
    const gleapAction = computed(() => store.getters['general/getGleapAction']);

    const trialAvailable = computed(() => {
      if (UserState.activeOffer) {
        if (!UserState.activeOffer.trials) {
          return true;
        }
        if (!UserState.activeOffer.trials[featureUnavailableMinPlan.value]) {
          return true;
        }
        return false;
      }
      return false;
    });

    const acceptTTC = ref(false);
    const showTACModal = ref(false);

    const handleUpgradeButton = async () => {
      // Handle user who access directly from link
      if (lastRoute.value.from && lastRoute.value.from.name === undefined) {
        store.commit('general/showTheSpinner');
        await router.push({ name: 'home' });
        store.commit('general/hideTheSpinner');
      }
      store.commit('choosePlan/setIsVisible', true);
      store.commit('general/setIsFeatureUnavailableModalVisible', false);
      acceptTTC.value = false;
    };

    const handleHideDialog = async () => {
      store.commit('general/showTheSpinner');
      if (lastRoute.value.to) {
        const path = lastRoute.value.from ? { name: lastRoute.value.from.name } : { name: 'home' };
        await router.push(path);
      }
      store.commit('general/setIsFeatureUnavailableModalVisible', false);
      store.commit('general/setLastRoute', { to: null, from: null });
      store.commit('general/setFeatureUnavailableMinPlan', null);
      store.commit('general/setGleapAction', null);
      store.commit('general/hideTheSpinner');
      acceptTTC.value = false;
    };

    const handleTryPlanButton = async () => {
      // Handle user who access directly from link
      store.commit('general/showTheSpinner');
      if (lastRoute.value.from && lastRoute.value.from.name === undefined) {
        await router.push({ name: 'home' });
      }
      await tryPlan(featureUnavailableMinPlan.value);

      // Handle feature button type block
      store.commit('general/setIsFeatureUnavailableModalVisible', false);
      if (onAuthorizedClickFunction.value) {
        onAuthorizedClickFunction.value();
        store.commit('general/setOnAuthorizedClickFunction', null);
      }

      // Handle feature route type block
      if (lastRoute.value.to) {
        await router.push({ name: lastRoute.value.to.name });
        store.commit('general/setLastRoute', { to: null, from: null });
      }

      store.commit('general/hideTheSpinner');

      // Handle feature gleap type block
      if (gleapAction.value) {
        const gleapActions = gleapActionForm[gleapAction.value];
        if (gleapActions.isForm) {
          Gleap.startClassicForm(gleapActions.value, true);
        } else if (gleapActions.isLink) {
          window.open(gleapActions.value, '_blank');
        } else if (gleapActions.isPhone) {
          window.open(`tel:${gleapActions.value}`);
        }
        store.commit('general/setGleapAction', null);
      }
      acceptTTC.value = false;
    };

    return {
      t,
      isNotAccessibleDialogVisible,
      featureUnavailableMinPlan,
      trialAvailable,
      acceptTTC,
      showTACModal,
      handleUpgradeButton,
      handleHideDialog,
      handleTryPlanButton,
    };
  },
});
