import { ComponentNameEnum } from '@/types';

const listFields = [
  { label: 'templateBuilder.fields.lastname', value: 'spm_form_registration_last_name' },
  { label: 'templateBuilder.fields.firstname', value: 'spm_form_registration_first_name' },
  { label: 'templateBuilder.fields.email', value: 'spm_form_registration_email' },
  { label: 'templateBuilder.fields.password', value: 'spm_form_registration_password' },
  { label: 'templateBuilder.fields.birthday', value: 'spm_form_registration_birthday' },
];

const getFormFieldsFieldConfig = (defaultValue = [], mandatoryFields: string[] = []) => ({
  componentName: ComponentNameEnum.MULTIDROPDOWN,
  options: {
    list: listFields,
    defaultValue,
    mandatoryFields,
  },
});

export default getFormFieldsFieldConfig;
