import StatsWidgetDataConfig from '@/components/stats-builder/widgets/form-config/StatsWidgetDataConfig.vue';
import StatsWidgetTitleConfig from '@/components/stats-builder/widgets/form-config/StatsWidgetTitleConfig.vue';
import StatsWidgetMenuConfig from '@/components/stats-builder/widgets/form-config/StatsWidgetMenuConfig.vue';
import StatsWidgetTabsConfig from '@/components/stats-builder/widgets/form-config/StatsWidgetTabsConfig.vue';

const ComponentsStatsBuilderWidgetConfig = {
  StatsWidgetDataConfig,
  StatsWidgetTitleConfig,
  StatsWidgetMenuConfig,
  StatsWidgetTabsConfig,
};

export default ComponentsStatsBuilderWidgetConfig;
