import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import { StatsWidgetComponentData, StatsWidgetComponentMetadata } from '@/types/stats-editor-types';
// eslint-disable-next-line import/no-cycle
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { StatsBuilderColumnLayoutEnum, StatsType } from '@/types';
// eslint-disable-next-line import/no-cycle
import { uniqueIdS6Generator } from '@/store/modules/stats-editor';

export interface WidgetTabsData {
  tabs: any[];
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const formatTabs = (arr: any) => arr.reduce(
  async (a: any, item: any) => {
    await a;
    // eslint-disable-next-line no-param-reassign
    item.panelId = `panel_${uniqueIdS6Generator()}`;
  },
  Promise.resolve(),
);

const WidgetTabsMetadata: StatsWidgetComponentMetadata<WidgetTabsData> = {
  StatsWidgetMeta: {
    type: 'widgetTabs',
    label: 'Onglet',
    icon: 'far fa-bars',
    min_columns_required: StatsBuilderColumnLayoutEnum.SIX,
    component: 'StatsWidgetTabs',
    availability: [StatsType.STATS],
  },
  Create({ tabs }: WidgetTabsData = {
    tabs: [],
  }): StatsWidgetComponentData<WidgetTabsData> {
    return {
      tabs,
      type: 'StatsWidgetTabs',
    };
  },
  formatData({ tabs }: WidgetTabsData) {
    formatTabs(tabs);
  },
  async Validate(data: WidgetTabsData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      tabs: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
};

export default WidgetTabsMetadata;
