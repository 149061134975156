import {
  OperatorType, ProgramShopsSyncs, ShopsLists, ShopsSyncs, StatsResponse,
} from '@/types/generated-types/graphql';
import {
  Get, List, ListResult, Request,
} from '@/composables/GraphQL';
import { AutomatedScenarioHistoryRaw } from '@/composables/AutomatedScenarios';

interface ShopsSyncsArgs {
  type: string;
  id_shop: number;
  grouped_actions_selection: string;
  grouped_actions_type: string;
}

export default function ShopsSyncsList(type: string, idShop: number, owner: string): Promise<ListResult<ShopsSyncs>> {
  return List<ShopsSyncs>({
    name: 'ShopsSyncs',
    settings: {
      filter: [
        {
          field: 'id_shop',
          operator: OperatorType.Equals,
          value: idShop,
        },
        {
          field: 'type',
          operator: OperatorType.In,
          value: type,
        },
        {
          field: 'owner',
          operator: OperatorType.Equals,
          value: owner ?? 'system',
        },
        {
          field: 'state',
          operator: OperatorType.In,
          value: 'wait,pending',
        },
      ],
      order: [
        {
          field: 'id_shop',
          type: 'ASC',
        },
      ],
      limit: 0,
      offset: 0,
    },
    fields: ['date_creation', 'progress'],
  });
}

export const programSync = async (type: string, id_shop: number, grouped_actions_selection: string[]|null,
  grouped_actions_type: string, owner: string) => Request<ProgramShopsSyncs>({
    name: 'ProgramShopSync',
    variables: {
      type, id_shop, grouped_actions_selection, grouped_actions_type, owner,
    },
    query: `
          query ($type: String, $id_shop: Int, $grouped_actions_selection: [String], $grouped_actions_type: String, $owner: String)
          {
            ProgramShopSync(type: $type, id_shop: $id_shop, grouped_actions_selection: $grouped_actions_selection, grouped_actions_type: $grouped_actions_type, owner: $owner) {
            total,
            id_shop_syncs,
            error
            }
          }
        `,
  });
