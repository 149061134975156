import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface ActionWaitData {
  time_unit_select: {
    value: number;
    unit: string; // SECOND, MINUTE, HOUR, DAY
  };
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const ActionWaitMetadata: ComponentMetadata<ActionWaitData> = {
  Meta: {
    kind: 'action',
    id: 'boxwait',
    label: 'automatedScenarios.actions.wait.shortLabel',
    icon: 'fa-hourglass',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxwait', 'boxtag', 'boxlist',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxvisitpage',
          'boxdelaismax',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxwait', 'boxtag', 'boxlist',
          'boxdisplayfreepopup', 'boxdisplayfreemodel', 'boxvisitpage',
          'boxdelaismax', 'boxchoosebulklist',
        ],
      },
    },
    component: 'ActionWait',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create({ time_unit_select: { value, unit } }: ActionWaitData = {
    time_unit_select: {
      value: 10,
      unit: 'MINUTE', // SECOND, MINUTE, HOUR, DAY
    },
  }): ComponentData<ActionWaitData> {
    return {
      id: 'boxwait',
      form_name: 'boxwait',
      logo_box: 'fa-hourglass-start',
      time_unit_select: {
        value,
        unit,
      },
    };
  },
  async Validate(data: ActionWaitData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      time_unit_select: {
        value: {
          required,
          integer,
          between: between(1, 1000),
        },
        unit: {
          required,
          includesValuesValidator: includesValuesValidator([
            'SECOND',
            'MINUTE',
            'HOUR',
            'DAY',
            'MONTH',
          ]),
        },
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: ActionWaitData): number {
    return 1;
  },
  Label(data: ActionWaitData): string {
    const unit = translation.global.t(`automatedScenarios.fields.durations.${data.time_unit_select.unit.toLowerCase()}${data.time_unit_select.value > 1 ? 's' : ''}`);
    return translation.global.t('automatedScenarios.actions.wait.fullLabel', { duration: `${data.time_unit_select.value} ${unit}` });
  },
};

export default ActionWaitMetadata;
