<template>
  <div class="card flex">
    <Dialog
      v-model:visible="displayModal"
      :modal="true"
      :draggable="false"
      :closable="false"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
    >
      <template #header>
        <h3>
          <i
            size="xs"
            class="fa-regular fa-hexagon-exclamation mr-2"
          />{{ t('reloadNotice.header') }}
        </h3>
      </template>

      <div class="flex align-items-start">
        <p class="text-left mb-3">
          {{ t('reloadNotice.message') }}
        </p>
      </div>
      <div class="flex align-items-start">
        <p class=" text-left mb-2">
          {{ t('reloadNotice.message2') }}
        </p>
      </div>

      <template #footer>
        <SpmButton
          :label="t('reloadNotice.footerBtnLabel')"
          class-style="p-button p-button-success"
          @click="reloadPage"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Dialog from 'primevue/dialog';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

export default defineComponent({
  name: 'ReloadNotice',

  components: {
    Dialog,
    SpmButton,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const displayModal = computed(() => props.displayDialog);
    const reloadPage = () => {
      window.location.reload();
    };

    return {
      t,
      displayModal,
      reloadPage,
    };
  },
});
</script>

<style scoped lang="scss">

</style>
