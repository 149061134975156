
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { StringMap } from '@/types';

export default defineComponent({
  name: 'StatsWidgetTitle',

  props: {
    name: {
      type: String || Object as PropType<StringMap>,
      required: true,
    },
  },

  setup(props: { name: string | StringMap }) {
    const { locale } = useI18n();

    const widgetName = typeof props.name === 'string' ? props.name : ref(props.name[locale.value]);

    return {
      widgetName,
    };
  },
});
