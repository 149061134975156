
import {
  defineComponent,
} from 'vue';
import Button from 'primevue/button';

import { useI18n } from 'vue-i18n';

import { showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'SpmKeyCard',

  components: {
    Button,
  },

  props: {
    secretKey: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const copyToClipboard = () => {
      navigator.clipboard.writeText(props.secretKey);
      showToastSuccess(t('copiedToClipboard'));
    };

    return {
      copyToClipboard,
    };
  },
});
