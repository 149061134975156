<template>
  <div class="CountriesList">
    <label v-if="showLabel">{{ t('countries.label') }}</label>

    <MultiSelect
      v-model="country"
      :options="countries"
      :placeholder="t(placeholder)"
      option-label="label"
      option-value="value"
      loading-icon="fas fa-globe"
      :filter="filter"
      @change="onCountryChanged"
    >
      <template #option="slotProps">
        <div class="flex align-items-center">
          <Flag
            v-if="showFlags"
            class="mr-2"
            :country="slotProps.option.value"
          />
          <div>
            {{ slotProps.option.label }}
          </div>
        </div>
      </template>
    </MultiSelect>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';
import getCountries from '@/configs/countries';
import Flag from '@/components/table/renderers/Flag.vue';
import MultiSelect from 'primevue/multiselect';

export default defineComponent({
  name: 'CountryMultiDropdown',
  components: {
    Flag,
    MultiSelect,
  },

  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },

    showFlags: {
      type: Boolean,
      required: false,
      default: true,
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    placeholder: {
      type: String,
      required: false,
      default: 'countries.label',
    },

    filter: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, context) {
    const { t } = useI18n();

    const country = ref(props.modelValue ? props.modelValue.map((countryVal) => countryVal.toUpperCase()) : []);

    const countries = ref(getCountries(t));

    const onCountryChanged = (event: { value: string[] }) => {
      const transformedValue = event.value.map((countryVal) => countryVal.toLowerCase());
      context.emit('update:modelValue', transformedValue);
    };

    return {
      t,
      countries,
      country,
      onCountryChanged,
    };
  },
});
</script>

<style lang="scss" scoped>
.Flag {
  height: 24px;
}
</style>
