import { i18n } from '@/i18n';
import { DropdownOption, StringMap } from '@/types';
import {
  FilterField, FilterSegment, Period, PickerData, Range,
} from '@/types/automated-scenarios';
import { Location } from '@/components/automated-scenarios/metadata/filtres/FiltreCustomerMetadata';

let translation: any;
(async () => {
  translation = await i18n;
})();

const tooltip: { [key: string]: Function} = {
  ItemList: (field: FilterField, data: { selection: PickerData}) => `${field.litteral_title} ${(data && data.selection && data.selection.selected.length) ?? 0}`,
  InputList: (field: FilterField, data: string[]) => `${field.litteral_title} ${(data && data.length) ?? 0}`,
  Input: (field: FilterField, data: string) => `${field.litteral_title} ${data}`,
  NumberRange: (field: FilterField, data: Range) => `${field.litteral_title} ${((data.noMax && data.noMax === 'on')
    ? translation.global.t('automatedScenarios.tooltips.betwweenAndMore', { min: `${data.min} ${translation.global.t(field.props.suffix)}` })
    : translation.global.t('automatedScenarios.tooltips.betweenAnd', {
      min: `${data.min} ${translation.global.t(field.props.suffix)}`,
      max: `${data.max} ${translation.global.t(field.props.suffix)}`,
    }))}`,
  MultiDropdown: (field: FilterField, data: StringMap) => `${field.litteral_title} ${Object.keys(data)
    .reduce((acc: string[], item: string) => {
      const filteredOption = field.props.options.filter((option: DropdownOption) => String(option.value) === String(item));
      return filteredOption.length === 0 ? acc : [
        ...acc,
        filteredOption[0].label];
    }, []).join(', ')}`,
  Dropdown: (field: FilterField, data: string) => {
    const filteredOption = field.props.options.filter((option: DropdownOption) => option.value === data);
    const label = filteredOption.length === 0 ? '' : filteredOption.label;
    return `${field.litteral_title} ${label}`;
  },
  EditAutocomplete: (field: FilterField, data: StringMap) => `${field.litteral_title} ${Object.values(data).join(',')}`,
  InputNumber: (field: FilterField, data: number) => `${field.litteral_title} ${data}`,
  ButtonGroup: (field: FilterField, data: string[] | string | number) => {
    const filteredOption = field.props.options.filter((option: DropdownOption) => String(option.value) === String(data));
    const label = filteredOption.length === 0 ? '' : filteredOption[0].label;
    return `${field.litteral_title} ${label}`;
  },
  PeriodSelector: (field: FilterField, data: Period) => `${field.litteral_title} ${data.value} ${translation.global.t(field.props.units.filter(
    (option: DropdownOption) => option.value === data.unit,
  )[0].label)}`,
  Hidden: (field: FilterField) => `${field.litteral_title}`,
  Location: (field: FilterField, data: Location[]) => `${field.litteral_title} ${translation.global.t('automatedScenarios.tooltips.aroundAddress',
    {
      radius: data[0].radius,
      address: data[0].address,
    })}`,
};

export default (segment: FilterSegment<unknown>, datas: any, number: number|undefined) => segment.fields
  .filter((field: FilterField) => field.litteral_title !== undefined)
  .reduce((acc: string, field: FilterField) => {
    if (datas[field.id]) {
      return `${acc}${tooltip[field.component](field, ((number !== undefined) ? datas[field.id][number] : datas[field.id]))}
      `;
    }
    return acc;
  }, '');
