
import {defineComponent, PropType, Ref, ref} from 'vue';
import { VuemojiPicker, EmojiClickEventDetail } from 'vuemoji-picker';
import OverlayPanel from 'primevue/overlaypanel';
import {DropdownOption} from "@/types";

export default defineComponent({
  name: 'Emojis',

  components: {
    VuemojiPicker,
    OverlayPanel
  },

  props: {
    parentRef: {
      type: Object, // Ou tout autre type qui convient à votre référence parent
      required: true,
    },
  },

  emits: {
    'on-choose-emoji': Object,
    'add-data-menu': Object,
  },

  setup(props, context) {
    const displayEmojiPicker = ref();

    const handleEmojiClicked = (detail: EmojiClickEventDetail) => {
      context.emit('on-choose-emoji', detail.unicode);
      displayEmojiPicker.value.hide();
    };

    context.emit('add-data-menu', {
      label: 'Insérer une emoji',
      icon: 'fal fa-grin',
      command(event: any) {
        setTimeout(() => {
          displayEmojiPicker.value.show(event, props.parentRef.querySelector('button'));
        }, 0);
      },
    });
    return {
      displayEmojiPicker,
      handleEmojiClicked,
    };
  },

});
