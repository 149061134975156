<template>
  <Dialog
    v-model:visible="displayModal"
    data-test-id="AI-dialog"
    position="center"
    :header="t('templateBuilder.modals.AI.header')"
    :modal="true"
    :breakpoints="{ '960px': '60vw', '640px': '100vw' }"
    :style="{ width: '40vw', minWidth: '1024px' }"
    :dismissable-mask="true"
    @update:visible="handleClose"
  >
    <AIInterface @close="handleClose"></AIInterface>
    <template #footer>
      <Button
        data-test-id="input-text-button-cancel"
        :label="t('templateBuilder.modals.cancel')"
        icon="far fa-times"
        class="p-button-secondary"
        @click="handleClose"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  computed, ComputedRef, defineComponent, isProxy, Ref, ref, toRaw, watch,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';

import { useI18n } from 'vue-i18n';
import AIInterface from '@/components/spm-ai/AIInterface.vue';
import { hideAIModal, ShopimindAIState as state } from '@/composables/services/ShopimindAI';

interface Field {
  name: string;
  component: string;
}
interface AIContentType {
  name: string;
  tooltip: string;
  icon: string[];
  api_route: string;
  hidden_fields: Record<string, any>;
  fields: Record<string, any>;
  result_config: Record<string, any>;
}

export default defineComponent({
  name: 'AIModal',

  components: {
    Dialog,
    Button,
    AIInterface,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const AIInterfaceParams: ComputedRef = computed(() => state.AIInterfaceParams);
    const displayModal: ComputedRef = computed(() => AIInterfaceParams.value.isAIModalVisible);

    const handleClose = () => {
      hideAIModal();
    };

    return {
      t,
      displayModal,
      handleClose,
    };
  },
});
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>

</style>
