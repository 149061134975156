
import {
  defineComponent,
  Ref,
  ref,
} from 'vue';
import { formatDateToLocale } from '@/helpers/Date';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'DateTimeRenderer',

  props: {
    content: {
      type: [Object],
      required: false,
      default: null,
    },
  },

  setup(props) {
    const locale = useI18n().locale.value;
    const date: Ref<{ [key: string]: any } | null> = ref(props.content);
    let returnValue = '';

    if (date.value) {
      returnValue = formatDateToLocale(locale, Object.prototype.hasOwnProperty.call(date.value, 'date') ? date.value.date : date.value) ?? '';
    }

    return {
      returnValue,
    };
  },

});
