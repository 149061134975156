import {
  ComponentNameEnum, ConfigurationPanelStructureItemEnum, StepperSignEnum, TabItem, TemplateStructureEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
// eslint-disable-next-line import/no-cycle
import {
  recalculateLineHeightValue,
  setClass, setCssButtonWidthProperty,
  setCssProperty,
  setCssPropertyAndRemoveIfZero, setCssPropertyWithImportant,
  setMediaDisplay,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getClassByName, getCssPropertyByName } from '@/components/template-builder/getters';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import fontSizeFieldConfig from '@/components/template-builder/config/fields/font-size-config';
import textStylesFieldConfig from '@/components/template-builder/config/fields/text-styles-config';

const widgetButtonFieldsConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.GROUP,
  label: 'templateBuilder.configs.textAndButton',
  tabPanel: {
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.button',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.buttonBackgroundColor',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig('#ECECEC', false, true),
          },
          {
            label: 'templateBuilder.fields.buttonWidth',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [{
              name: 'width',
              getter: getCssPropertyByName,
              setters: [setCssButtonWidthProperty],
            }],
            ...getButtonWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.buttonAlignment',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [{
              name: 'spm_div_align_',
              getter: getClassByName,
              setters: [setClass],
            }],
            ...getElementAlignFieldConfig('spm_div_align_'),
          },
          {
            label: 'templateBuilder.fields.contours',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [{
              name: 'border-radius',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getBorderRadiusFieldConfig('5px'),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [
              {
                name: 'border-color',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-style',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-width',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'border-bottom-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-bottom-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-bottom-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-top-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-left-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-right-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-width',
                getter: getCssPropertyByName,
                setters: [],
              },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [
              {
                name: 'font-family',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'font-size',
                getter: getCssPropertyByName,
                setters: [recalculateLineHeightValue, setCssProperty],
              },
              {
                name: 'font-weight',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'font-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'text-decoration-line',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'text-transform',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'color',
                getter: getCssPropertyByName,
                setters: [setCssPropertyWithImportant],
              },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [{
              name: 'letter-spacing',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR} a.spm_button, {SELECTOR} a.spm_button:active, {SELECTOR} a.spm_button:visited, {SELECTOR} a.spm_button:hover',
            properties: [
              {
                name: 'padding-top',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-bottom',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-left',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-right',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{
              name: 'show-for-',
              getter: getClassByName,
              setters: [setMediaDisplay],
            }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.buttonHoverStyles',
        selector: 'body #spm_body #spm_content #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.buttonHoverBackgroundColor',
            selector: '{SELECTOR} a.spm_button:hover',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig('#ECECEC', true, true),
          },
          {
            label: 'templateBuilder.fields.buttonHoverTextColor',
            selector: '{SELECTOR} a.spm_button:hover',
            properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssPropertyWithImportant] }],
            ...getColorFieldConfig('#000000'),
          },
          {
            label: 'templateBuilder.fields.buttonHoverFontSize',
            selector: '{SELECTOR} a.spm_button:hover',
            properties: [{ name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] }],
            ...fontSizeFieldConfig,
            options: {
              ...fontSizeFieldConfig.options,
              px: {
                ...fontSizeFieldConfig.options.px,
                defaultValue: 21,
              },
            },
          },
          {
            label: 'templateBuilder.fields.buttonHoverTextStyles',
            selector: '{SELECTOR} a.spm_button:hover',
            properties: [
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...textStylesFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetButtonFieldsConfig;
