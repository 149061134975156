import { ComponentNameEnum, BaseFieldConfig } from '@/types';

const listItems = [
  { label: 'templateBuilder.fields.button', value: 'spm_product_list_element_button' },
  { label: 'templateBuilder.fields.description', value: 'spm_product_list_element_description' },
  { label: 'templateBuilder.fields.price', value: 'spm_product_list_element_price' },
  { label: 'templateBuilder.fields.crossedPrice', value: 'spm_product_list_element_price_strike' },
  { label: 'templateBuilder.fields.image', value: 'spm_product_list_element_image' },
  { label: 'templateBuilder.fields.productTitle', value: 'spm_product_list_element_title' },
  { label: 'templateBuilder.fields.listTitle', value: 'spm_product_list_title' },
];

const listItemsFieldConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.MULTIDROPDOWN,
  options: {
    list: listItems,
    defaultValue: [],
  },
};

export default listItemsFieldConfig;
