
import Button from 'primevue/button';
import {defineComponent, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref,} from 'vue';
import { useI18n } from 'vue-i18n';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import {
  ShopsConfiguration,
  ShopsConfigurationInput,
} from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import {
  SaveShopsConfiguration,
  GetShopsConfigurationList,
} from '@/composables/shop/ShopsConfiguration';
import { Period } from '@/types/automated-scenarios';
import { TimeUnits } from '@/types';
import { showToastError, showToastSuccess } from '@/helpers';
import SpmButton from "@/components/spm-primevue/SpmButton.vue";
import { store } from '@/store';

export default defineComponent({
  name: 'MarketingPressure',
  components: {
    SpmButton,
    PeriodSelector,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const isLoading = ref(false);
    const idShop = ref(UserState.activeShop?.id ?? 0);

    interface DelayData {
      email: Period;
      sms: Period;
      popUp: Period;
      push: Period;
      facebook: Period;
    }

    const delayData = reactive<DelayData>({
      email: { value: 0, unit: TimeUnits.MINUTE },
      sms: { value: 0, unit: TimeUnits.MINUTE },
      popUp: { value: 0, unit: TimeUnits.MINUTE },
      push: { value: 0, unit: TimeUnits.MINUTE },
      facebook: { value: 0, unit: TimeUnits.MINUTE },
    })

    const delayConfig = ref([
      {
        key: 'email',
        label: t('templateBuilder.fields.email'),
        value: delayData.email,
        icon: 'fa fa-envelope',
      },
      {
        key: 'sms',
        label: t('automatedScenarios.actions.marketingPressure.input.sms'),
        value: delayData.sms,
        icon: 'fa fa-mobile',
      },
      {
        key: 'popUp',
        label: t('automatedScenarios.actions.marketingPressure.input.display'),
        value: delayData.popUp,
        icon: 'fa fa-list-alt',
      },
      {
        key: 'push',
        label: t('automatedScenarios.actions.marketingPressure.scenarios.push'),
        value: delayData.push,
        icon: 'fa fa-bell',
      },
      {
        key: 'facebook',
        label: t('templateBuilder.fields.facebook'),
        value: delayData.facebook,
        icon: 'fab fa-facebook',
      },
    ])

    const delayUnits = [
      { value: TimeUnits.MINUTE, label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: TimeUnits.HOUR, label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: TimeUnits.DAY, label: t('automatedScenarios.fields.durations.day(s)') },
    ];


    const updateConfig = async() => {
      isLoading.value = true;
      const input = ref<ShopsConfigurationInput>({
        id_shop: idShop.value,
        configs: [
          {
            key: 'workflow_marketing_pressure_email_delay',
            value: JSON.stringify({ value: delayData.email.value, unit: delayData.email.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_facebook_delay',
            value: JSON.stringify({ value: delayData.facebook.value, unit: delayData.facebook.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_popup_delay',
            value: JSON.stringify({ value: delayData.popUp.value, unit: delayData.popUp.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_push_delay',
            value: JSON.stringify({ value: delayData.push.value, unit: delayData.push.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_sms_delay',
            value: JSON.stringify({ value: delayData.sms.value, unit: delayData.sms.unit}),
            lang:'',
          }
        ]
      })
      SaveShopsConfiguration(input.value).then(() => {
        showToastSuccess('savedSuccessful')
        isLoading.value = false;
      })
      .catch(() => {
        showToastError('noSave');
        isLoading.value = false;
      });
    }

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      const currentConfig = await GetShopsConfigurationList({
        shopId: idShop.value,
        key: '%_delay',
        fields: 'id_shop_configuration, key, value',
        limit: 0,
        offset: 0,
      });
      if (currentConfig) {
        // eslint-disable-next-line no-unused-expressions
        currentConfig?.forEach((record: ShopsConfiguration) => {
          const jsonValue = <Period>JSON.parse(record.value);
          switch (record.key) {
            case 'workflow_marketing_pressure_email_delay':
              delayData.email = jsonValue;
              break;
            case 'workflow_marketing_pressure_facebook_delay':
              delayData.facebook = jsonValue;
              break;
            case 'workflow_marketing_pressure_popup_delay':
              delayData.popUp = jsonValue;
              break;
            case 'workflow_marketing_pressure_push_delay':
              delayData.push = jsonValue;
              break;
            case 'workflow_marketing_pressure_sms_delay':
              delayData.sms = jsonValue;
              break;
            default:
              break;
          }
          return null;
        });
      }

      store.commit('general/hideTheSpinner');
    });

    return {
      t,
      isLoading,
      delayConfig,
      delayData,
      delayUnits,
      updateConfig,
    };
  },
});
