import { nestPost } from '@/composables/nestApi';

export async function getShopPendingSimpleCharges(idShop: number): Promise<any> {
  return nestPost('payments', '/shopify/shop-pending-simple-charges', {}, { idShop });
}

export async function getShopPendingRecurringCharges(idShop: number): Promise<any> {
  return nestPost('payments', '/shopify/shop-pending-recurring-charges', {}, { idShop });
}

export async function checkSpmTag(idShop: number): Promise<any> {
  return nestPost('v4', '/shopify/check-spm-tag', {}, { idShop });
}

export async function getTagInstallerLinkRedirect(idShop: number): Promise<any> {
  return nestPost('v4', '/shopify/get-tag-link-redirect', {}, { idShop });
}
