import {
  FieldsGroup,
  TemplateParentTypeEnum,
  WidgetTypeConfig,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import emailSocialMediaFieldsConfig from '@/components/template-builder/config/templates/email/widgets/widget-social-media-fields-config';
// eslint-disable-next-line import/no-cycle
import popupSocialMediaFieldsConfig from '@/components/template-builder/config/templates/popup/widgets/widget-social-media-fields-config';
// eslint-disable-next-line import/no-cycle
import embedSocialMediaFieldsConfig from '@/components/template-builder/config/templates/embed/widgets/widget-social-media-fields-config';

const getWidgetSocialMediaConfig = (templateType: TemplateParentTypeEnum): WidgetTypeConfig => {
  let fieldItems: Array<FieldsGroup> = [];
  switch (templateType) {
    case TemplateParentTypeEnum.POPUP:
      fieldItems = popupSocialMediaFieldsConfig;
      break;
    case TemplateParentTypeEnum.EMBED:
      fieldItems = embedSocialMediaFieldsConfig;
      break;
    default:
      fieldItems = emailSocialMediaFieldsConfig;
  }
  return {
    tabPanel: {
      label: 'templateBuilder.widgetsHeaders.socialMedia',
      items: fieldItems,
    },
  };
};

export default getWidgetSocialMediaConfig;
