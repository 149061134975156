<template>
  <Button
    :bind="$attrs"
    @click="prepareShowAIModal"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  SetupContext,
} from 'vue';
import { showAIModal } from '@/composables/services/ShopimindAI';
import Button from 'primevue/button';
import {TemplateEditorState as state} from '@/composables/template-editor/TemplateEditor';

export default defineComponent({
  name: 'AIButton',

  components: {
    Button,
  },

  props: {
    aiSelector: String,
    aiContentType: Number,
    aiParams: Object,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const prepareShowAIModal = (event: Event) => {
      const { aiContentType, aiSelector, aiParams } = props;
      let currentIdtemplate = aiParams?.idTemplate;
      if (!currentIdtemplate && state.template) {
        currentIdtemplate = state.template.id;
      }
      if (aiContentType && aiSelector) {
        const AIApplyContentParams = {
          idTemplate: (currentIdtemplate || 0),
          selector: aiSelector,
          type: 'value',
          target: '',
          attr: '',
          imageWidth: 0,
          imageHeight: 0,
          ...aiParams, // Merge aiParams into AIApplyContentParams
        };
        showAIModal(event, aiContentType, AIApplyContentParams);
      }
    };

    return {
      prepareShowAIModal,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-button.button-ai {
  cursor: pointer;
  border-radius:0;
  border: 1px solid $solitude;

  background:linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%)!important;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent;
  &:hover {
    background:linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%)!important;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent;
  }
}
.p-button.input-button-ai {
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  width: 2rem;
  font-size: 1.5rem;
  box-shadow: none;
  height: 2.5rem;
  border-radius:0;
  border-left: none;
  border-top: 1px solid $solitude;
  border-bottom: 1px solid $solitude;

  background:linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%)!important;
  -webkit-background-clip: text!important;
  -webkit-text-fill-color: transparent;
  &:hover {
    background:linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%)!important;
    border-top: 1px solid $solitude;
    border-bottom: 1px solid $solitude;
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent;
  }
}
</style>
