<template>
  <ul
    v-if="showedPanels.length > 1"
    class="list-config-panels"
  >
    <template
      v-for="(panel, panelIndex) of showedPanels"
      :key="panelIndex"
    >
      <li
        v-if="panel.show && (!('showIfConfiguringSegment' in panel) || panel.showIfConfiguringSegment)"
        class="field-group"
      >
        <button
          class="field-group-control field-group-control--collapsible flex justify-content-between align-items-center"
          @click="togglePanel(panelIndex)"
        >
          <span>
            <span style="vertical-align: middle;">{{ t(panel.label) }}</span>
          </span>
          <i
            class="far"
            :class="inactivePanelsIndex.includes(panelIndex) ? 'fa-chevron-down' : 'fa-chevron-up'"
          />
        </button>
        <div
          class="field-group-content"
          :class="{ 'hidden': inactivePanelsIndex.includes(panelIndex) }"
        >
          <slot :name="panel.name" />
        </div>
      </li>
    </template>
  </ul>
  <div v-else>
    <slot :name="showedPanels[0].name" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'SubPanelConfigContent',

  directives: {
    tooltip: Tooltip,
  },

  props: {
    panels: {
      type: Array as PropType<object[]>,
      required: true,
    },

    collapsed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    // Make all panels collapsed on init if property is defined
    const inactivePanelsIndex: Ref<number[]> = ref(props.collapsed ? [...props.panels.keys()] : []);

    const showedPanels = computed(() => props.panels.filter((panel: any) => panel.show));

    const togglePanel = (index: number) => {
      if (inactivePanelsIndex.value.includes(index)) {
        inactivePanelsIndex.value = inactivePanelsIndex.value.filter((i: number) => i !== index);
      } else {
        inactivePanelsIndex.value.push(index);
      }
    };

    return {
      t,
      inactivePanelsIndex,
      showedPanels,

      togglePanel,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-config-panels {
  list-style: none;
  margin-left: -1rem;
  margin-right: -1rem;
}
.tab-panel-label {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
  color: $montana;
  padding: 0.5rem 0.75rem 1.375rem 0.75rem;
}

.field-group:last-child {
  margin-bottom: 1rem;
}

.tooltip-active-items {
  background-color: $extra-light-grey;
  color: $tuna;
  border: 1px solid $heather;
}

.field-group-content {
  padding: 1rem;
}

.field-group-control {
  text-align: left;
  background-color: $shuttle-light-grey !important;

  &--collapsible:deep() {
    width: 100%;
    border: 0 none;
    cursor: pointer;
    background-color: #fbfbfb !important;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem;
    font-weight: normal;
  }

  & i.far.fa-chevron-down,
  & i.far.fa-chevron-up {
    color: #495057;
    font-size:20px;
  }
}
</style>
