import { ButtonFieldConfigOption, ComponentNameEnum } from '@/types';

const getSpmIconsFieldConfig = (iconList: string[] = [], pattern = '', property = 'spm_icon-', defaultValue = '') => ({
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: iconList.reduce<Record<string, ButtonFieldConfigOption>>((acc, current) => ({
    ...acc,
    [current]: {
      title: '',
      src: pattern.replace('{key}', current),
      value: '',
      defaultValue,
      property,
    },
  }), {}),
});

export default getSpmIconsFieldConfig;
