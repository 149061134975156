import { VisualEffect } from '@/types/store-parameters-types';

const effects: VisualEffect[] = [
  { name: 'none' },
  { name: 'bounce' },
  { name: 'flash' },
  { name: 'pulse' },
  { name: 'rubberBand' },
  { name: 'shake' },
  { name: 'headShake' },
  { name: 'swing' },
  { name: 'tada' },
  { name: 'wobble' },
  { name: 'jello' },
  { name: 'bounceIn' },
  { name: 'bounceInDown' },
  { name: 'bounceInLeft' },
  { name: 'bounceInRight' },
  { name: 'bounceInUp' },
  { name: 'fadeIn' },
  { name: 'fadeInDown' },
  { name: 'fadeInDownBig' },
  { name: 'fadeInLeft' },
  { name: 'fadeInLeftBig' },
  { name: 'fadeInRight' },
  { name: 'fadeInRightBig' },
  { name: 'fadeInUp' },
  { name: 'fadeInUpBig' },
  { name: 'flipInX' },
  { name: 'flipInY' },
  { name: 'lightSpeedIn' },
  { name: 'rotateIn' },
  { name: 'rotateInDownLeft' },
  { name: 'rotateInDownRight' },
  { name: 'rotateInUpLeft' },
  { name: 'rotateInUpRight' },
  { name: 'jackInTheBox' },
  { name: 'rollIn' },
  { name: 'zoomIn' },
  { name: 'zoomInDown' },
  { name: 'zoomInLeft' },
  { name: 'zoomInRight' },
  { name: 'zoomInUp' },
  { name: 'slideInDown' },
  { name: 'slideInLeft' },
  { name: 'slideInRight' },
  { name: 'slideInUp' },
  { name: 'heartBeat' },
];

export default effects;
