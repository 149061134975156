import {
  ACTIONS_BUTTON_GROUP,
  BUILDER_ELEMENT_ACTIVE_CLASS,
  BUILDER_ELEMENT_HOVER_CLASS,
  BUILDER_SORTABLE_CHOSEN_CLASS,
  BUILDER_SORTABLE_DRAG_CLASS,
  BUILDER_SORTABLE_GHOST_CLASS,
  BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS,
  COLUMN_WITH_PLACEHOLDER_CLASS,
  TRANSLATION_MARKUP,
  WIDGETS_PLACEHOLDER_IDENTIFIER,
} from '@/components/template-builder/utils/constants';
import juice from 'juice';
import { createDomFromString } from '@/helpers';
import { Maybe } from '@/types/generated-types/graphql';
import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
} from '@/i18n';
import {
  SpmLanguageConditions,
  TemplateParentTypeEnum,
} from '@/types';
import formatSmsMessage from '@/helpers/Sms';
// eslint-disable-next-line import/no-cycle
import { sanitizeTranslationMarkup } from '@/components/template-builder/utils/translate';
import { nestGet } from '@/composables/nestApi';
import striptags from 'striptags';

// List of all elements to remove
const TEMPLATE_ELEMENTS_TO_REMOVE: string[] = [
  `.${ACTIONS_BUTTON_GROUP}`,
  `.${WIDGETS_PLACEHOLDER_IDENTIFIER}`,
  'script',
  'link',
  '.qtip',
  '.remove',
  '.spm_draggable_widget_placeholder',
  '.spm_draggable_row_placeholder',
  '.spm_delete',
  '.dz-hidden-input',
  '*[data-cke-temp="1"]',
  '.cke',
  '.spm_area_name',
  '.spm_row_edit_panel',
  '.spm_widget_edit_panel',
  '.spm_column_edit_panel',
  '.spm_col_edit_panel',
  '.spm_add_element',
  '.spm_custom_tooltip',
  '.drop-marker',
  '.reserved-drop-marker',
  '.dnd-droppable-area',
  '.rc-handle-container',
  '.spm_highlight_overlay',
  '.cke_dialog_background_cover,.cke_dialog_background_cover',
  '*[style*="position: absolute"]',
  '*[style*=\'position: absolute\']',
  '*[style*="position:absolute"]',
  '*[style*=\'position:absolute\']',
  'style:not([title="spm_styles_to_keep"],[id^="spm_custom"],[data-spm-styles*="persistent"])',
  '.spm_hide_on_builder',
  '.spm_half_hide_on_builder',
  '.smart-product-list-preview',
  '.widget-btn-container',
  '[class*="sortable-"]',
];

// List of all strings to remove
const TEMPLATE_STRINGS_TO_REMOVE = {
  find: [
    '\\s+',
    // eslint-disable-next-line max-len
    '(<!--[if gte mso 9]><xml>\\s*\\t*<o:OfficeDocumentSettings>\\s*\\t*<o:AllowPNG></o:AllowPNG>\\s*\\t*<o:PixelsPerInch>96</o:PixelsPerInch>\\s*\\t*</o:OfficeDocumentSettings></xml><![endif]-->){2,}',
    // eslint-disable-next-line max-len
    '(<body style=\'padding: 0; margin: 0; font-family: "Helvetica", "Arial", sans-serif; width: 100%; min-width: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;\'>)',
    'spm_draggable_row',
    'spm_droppable_row',
    'spm_droppable_widget',
    'spm_draggable_widget',
    'hide-for-desktop',
    'show-for-small',
    'container',
    'column',
    'spm_no_responsive',
    'spm_body',
    'spm_section',
    'spm_row',
    'spm_column',
    'spm_widget',
    'spm_inline_widdget',
    '%7B',
    '%7D',
    ' class=" "',
    '-webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto;',
    '-ms-interpolation-mode: bicubic;',
    'position: relative;',
    'overflow-wrap:\\s?break-word;',
    'hyphens:\\s?auto;',
    'background-color: transparent;',
    '[\\t]+', // Multiple tabs
    '\\r?\\n', // Line breaks
    '</?spm-template>',
    '</?spm-sections>',
    `</?${TRANSLATION_MARKUP}[^>]*>`,
    ' style=""',
    ' class=""',
    '<style type="text/css"></style>',
    '<style>\\.cke{visibility:hidden;}</style>',
    ' aria[a-z0-9-]*="[a-z0-9é ,_-]*"',
    ' (role|tabindex|spellcheck)="[a-z0-9 ,]*"',
    ' title="[a-z0-9é ,]*editor[0-9]*"',
    ' ?style="position: relative;"',
    ' contenteditable="true"',
    ' ?style="border-collapse: ?collapse;"',
    '/\\*yfix\\*/ ?',
    'spm-avdprld-',
    '<!-- (Start|End)[^>]*>',
    '/\\*!i\\*/',
    ' id="spm_(?!section|body|row|column|inline_widdget|widget|")\\w*"',
    ' ?data-[a-z0-9-]*="[^"]*"',
    '<!-- (START|END) SPM TRANSLATION ID[^>]*>',
  ],
  replace: [
    ' ',
    '<!--[if gte mso 9]><xml> <o:OfficeDocumentSettings> <o:AllowPNG></o:AllowPNG> <o:PixelsPerInch>96</o:PixelsPerInch> </o:OfficeDocumentSettings></xml><![endif]-->',
    // eslint-disable-next-line max-len
    '<body style="padding: 0; margin: 0; font-family: \'Helvetica\', \'Arial\', sans-serif; width: 100%; min-width: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%;">',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    '{',
    '}',
  ],
};

// List of classes to remove from elements
const TEMPLATE_CLASSES_TO_REMOVE: string[] = [
  BUILDER_ELEMENT_HOVER_CLASS,
  BUILDER_ELEMENT_ACTIVE_CLASS,
  BUILDER_SORTABLE_DRAG_CLASS,
  BUILDER_SORTABLE_GHOST_CLASS,
  BUILDER_SORTABLE_CHOSEN_CLASS,
  COLUMN_WITH_PLACEHOLDER_CLASS,
  BUILDER_SORTABLE_PLACEHOLDER_HOVER_CLASS,
  'center',
  'deleteAfterSave',
  'cke_editable_inline',
  'cke_editable',
  'cke_contents_ltr',
  'cke_show_borders',
  'ui-droppable',
  'ui-sortable',
  'dz-clickable',
  'highlight_description',
  'spm_highlight_block_bg',
  'spm_highlight_block',
  'spm_draggable_widget_placeholder',
  'spm_row_droppable_placeholder',
  'spm_simulate_over_widget',
  'spm_simulate_over_row',
  'spm_row_droppable_placedrop',
  'spm_droppable_placedrop',
  'spm_widget_droppable_placedrop',
  'spm_widget_sortable_placeholder',
  'spm_row_sortable_placeholder',
  'spm_animation_done',
  'spm_rich_editor',
  'spm_widget_text',
  'spm_widget_text_rgpd',
  'spm_widget_display_text',
  'spm_widgets_on_builder',
];

/**
 * Sanitize template DOM
 * @param domElement
 */
export const sanitizeTemplate = (domElement: Document): string => {
  let dom = domElement;

  // We add the title markup if not exists
  if (!dom.querySelector('title')) {
    const head = dom.querySelector('head');

    if (head) {
      head.innerHTML += '<title>{var=template.subject}</title>';
    }
  }

  // Compute CSS styles (stylesheet to inline)
  dom = createDomFromString(juice(dom.children[0].outerHTML));

  // Remove useless elements
  ((Array.from(dom.querySelectorAll(TEMPLATE_ELEMENTS_TO_REMOVE.join(', '))) as HTMLElement[]) ?? [])
    .forEach((element: HTMLElement) => element.remove());

  // Remove useless classes
  TEMPLATE_CLASSES_TO_REMOVE.forEach((classToRemove: string) => {
    const elements = dom.querySelectorAll(`.${classToRemove}`);
    Array.from(elements).forEach((el) => {
      el.classList.remove(classToRemove);
    });
  });

  // Shorten long spm classes
  const matchedClasses: { [key: string]: string } = {};
  let html = dom.children[0].outerHTML;
  const originalHtml = dom.children[0].outerHTML;
  let i = 1;

  html = html.replace(new RegExp('spm_((?!product_list)(?!products_loop)(?!hide_on_builder)(?!half_hide_on_builder))\\w{5,}', 'g'), (match) => {
    let returnValue;
    if (Object.prototype.hasOwnProperty.call(matchedClasses, match)) {
      returnValue = matchedClasses[match];
    } else if (originalHtml.match(new RegExp(`\\.${match}`))) {
      returnValue = `spm${i}`;
      i += 1;
    } else {
      returnValue = '';
    }

    return returnValue;
  });

  html = html.replace(new RegExp('href=["\']?[^"\'>]+["\']?', 'g'), (value) => {
    const match = value.match(new RegExp('href=["\']?([^"\'>]+)["\']?'));

    if (match) {
      const replaceValue = match[1]
        .replace(new RegExp('.+((\\{var=template.unsubscribe_url\\})|(\\{var=template.view_in_browser_url\\})|(\\{var=template.private_archive_url\\}))'), '\\1');
      return `href="${replaceValue}"`;
    }

    return '';
  });

  // Remove useless strings (regexp)
  html = html.replaceArray(TEMPLATE_STRINGS_TO_REMOVE.find, TEMPLATE_STRINGS_TO_REMOVE.replace);

  /**
   * Add missing elements (doctype, ...)
    */
  // <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  const doctype = '"-//W3C//DTD XHTML 1.0 Transitional//EN"';
  const regExpDoctype = new RegExp(doctype, 'gi');

  if (!regExpDoctype.test(html)) {
    if (new RegExp('!doctype', 'i').test(html)) {
      // Doctype is declared but wrong
      html = html.replace(
        /<!doctype[^>]*>/im,
        '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">',
      );
    } else {
      // There is no doctype
      html = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">${html}`;
    }
  }

  // <html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  let headMeta = 'xmlns:o="urn:schemas-microsoft-com:office:office"';
  let regExpMeta = new RegExp(headMeta, 'gi');

  if (!regExpMeta.test(html)) {
    html = html.replace(
      /<html[^>]*>/im,
      '<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">',
    );
  }

  // [if mso]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]
  headMeta = '<o:PixelsPerInch>96';
  regExpMeta = new RegExp(headMeta, 'gi');

  if (!regExpMeta.test(html)) {
    html = html.replace(
      '</head>',
      '<!--[if mso]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]--></head>',
    );
  }

  // <meta name="viewport" content="width=device-width" />
  headMeta = 'content="width=device-width"';
  regExpMeta = new RegExp(headMeta, 'gi');

  if (!regExpMeta.test(html)) {
    html = html.replace(
      '</head>',
      '<meta name="viewport" content="width=device-width" /></head>',
    );
  }

  // <meta name="format-detection" content="telephone=no" />
  headMeta = 'content="telephone=no"';
  regExpMeta = new RegExp(headMeta, 'gi');

  if (!regExpMeta.test(html)) {
    html = html.replace(
      '</head>',
      '<meta name="format-detection" content="telephone=no" /></head>',
    );
  }

  return html;
};

/**
 * Format variable value according to type of data and modifiers
 * @param variableKey
 * @param value
 * @param modifiers
 * @param translations
 */
const formatVariableValue = (variableKey: string, value: string, modifiers: Maybe<string[][]>, translations: TemplateTranslations): string => {
  let returnValue = '';
  let sizeModifierExists = false;

  // We format the variable according to the key (if specific format needed)
  switch (variableKey) {
    case 'birthday':
      console.warn('TODO : implémenter un formatage spécifique pour toutes les clés de type datetime');
      returnValue = value;
      break;
    case 'gender':
      if (value === '1') {
        returnValue = translations.automatedScenarios.fields.gender.male;
      } else if (value === '2') {
        returnValue = translations.automatedScenarios.fields.gender.female;
      } else if (value === '3') {
        returnValue = translations.automatedScenarios.fields.gender.unknown;
      }
      break;
    case 'price_explode':
      console.warn('TODO : implémenter le modifier "price_explode" sur les variables');
      break;
    case 'image_url':
      // Check if size modifier exist
      if (modifiers) {
        modifiers.some((modifierData) => {
          const [, modifier] = modifierData;

          if (modifier === 'resize') {
            sizeModifierExists = true;
            return true;
          }

          return false;
        });
      }

      returnValue = sizeModifierExists ? `https://media.shopimind.io/resize/index.php?w=300&h=150&crop-to-fit&src=${value}` : value;
      break;
    default:
      returnValue = value;
      break;
  }

  // We apply modifiers if any
  if (modifiers) {
    modifiers.forEach((modifierData) => {
      const [, modifier] = modifierData;
      let [, , modifierValue] = modifierData;

      switch (modifier) {
        // Return n characters of the final value
        case 'truncate':
          if (parseInt(modifierValue, 10) > 0) {
            if (variableKey === 'description_short') {
              // Remove HTML tags except BR
              returnValue = striptags(returnValue, '<br>');
            }

            returnValue = returnValue.substring(0, parseInt(modifierValue, 10));
          }
          break;
        case 'http':
          if ((modifierValue.toLowerCase() === 'true' || modifierValue === '1') && !/^http/.test(returnValue)) {
            // Add http
            returnValue = `http://${returnValue}`;
          } else if ((modifierValue.toLowerCase() === 'false' || modifierValue === '0') && /^http/.test(returnValue)) {
            // Remove http(s)
            returnValue = returnValue.replace('http://', '').replace('https://', '');
          }
          break;
        case 'https':
          if ((modifierValue.toLowerCase() === 'true' || modifierValue === '1') && !/^http/.test(returnValue)) {
            // Add https
            returnValue = `https://${returnValue}`;
          } else if ((modifierValue.toLowerCase() === 'false' || modifierValue === '0') && /^https/.test(returnValue)) {
            // Remove http(s)
            returnValue = returnValue.replace('http://', '').replace('https://', '');
          }
          break;
        case 'resize':
          if (modifierValue !== '') {
            const imageSize = modifierValue.split('x');
            returnValue = returnValue.replace('w=300&h=150', `w=${imageSize[0]}&h=${imageSize[1]}`);
          }
          break;
        case 'resizeType':
          if (modifierValue !== '') {
            modifierValue = modifierValue === 'fill-to-fit' ? 'fill-to-fit=FFFFFF' : modifierValue;
            returnValue = returnValue.replace('crop-to-fit', modifierValue);
          }
          break;
        default:
          break;
      }
    });
  }

  return returnValue;
};

export interface TemplateContext {
  [key: string]: any;
}

interface PropertyOfTemplateContext {
  property: string;
  value: string;
}

interface TemplateTranslations {
  [key: string]: any;
}

/**
 * Returns the value of a property inside the context
 * @param context
 * @param property
 */
const findValueOfContextProperty = (context: TemplateContext = {}, property: string): Maybe<PropertyOfTemplateContext> => {
  // We split the variable to get the key inside context
  const split: string[] = property.split('.');
  let returnValue = null;

  if (split.length > 0) {
    // We get the key, and we remove it from the array
    const [contextKey] = split;
    split.shift();

    if (split.length > 0) {
      // There are more keys inside the property, we need to call this function again to get the next level in the context
      returnValue = findValueOfContextProperty(context[contextKey], split.join('.'));
    } else if (Object.prototype.hasOwnProperty.call(context, contextKey)) {
      // We have found the context key we need (last level), we return its value
      returnValue = { property: contextKey, value: context[contextKey] };
    }
  }

  return returnValue;
};

/**
 * Recursive function to replace a variable by its value inside context object
 * @param context
 * @param variable
 * @param modifiers
 * @param translations
 */
const replaceVariable = (context: TemplateContext, variable: string, modifiers: Maybe<string[][]>, translations: TemplateTranslations): string => {
  // We get the value in the context
  let returnValue: Maybe<PropertyOfTemplateContext> | string = findValueOfContextProperty(context, variable);

  // If value is found and is not NULL, we format the value and return the formatted string, else we replace it with an empty string
  returnValue = returnValue && returnValue.value ? formatVariableValue(returnValue.property, returnValue.value, modifiers, translations) : '';

  return returnValue;
};

/**
 * Function to replace all variables according to context keys (to only replace variables included in context)
 * @param html
 * @param context
 * @param lang
 */
export const replaceVariables = async (html: string, context: TemplateContext, lang: Maybe<string> = null): Promise<string> => {
  // Import of translations to replace specific variables values by their corresponding translation (e.g : gender)
  const languageToUse = lang && SUPPORTED_LOCALES.includes(lang) ? lang : DEFAULT_LOCALE;
  const translations = await import(`@/i18n/${languageToUse}.json`);

  // We get the list of keys in the context object to only replace variables starting with those keys
  const variablesToReplace = Object.keys(context);

  // We instantiate a string containing our HTML
  let htmlString = html;

  // Get all variables of the HTML in parameter matching the keys in context
  const variables = htmlString.matchAll(new RegExp(`\\{var="?(?<variable>(?:(${variablesToReplace.join(')|(')}))[^"\\s}]*)"?(?<modifiersList>\\s[^}]+)?\\}`, 'g'));

  // Replace matched variables based on context values
  Array.from(variables).forEach((match) => {
    const { groups } = match;

    if (groups) {
      const { variable, modifiersList } = groups;

      // We check if there are any modifiers
      const modifiers: Maybe<string[][]> = modifiersList && modifiersList !== '' ? Array.from(modifiersList.matchAll(new RegExp('([a-zA-Z_]+)="?([^"\\s}]+)"?', 'g'))) : null;

      // Replace the variable by its value
      const value = replaceVariable(context, variable, modifiers, translations);
      htmlString = htmlString.replace(match[0], value);
    }
  });

  return htmlString;
};

/**
 * Extract parameters of an element
 * @param element
 */
export const extractElementParameters = (element: Element): Record<string, any> => {
  const regExpShopimindParameter = new RegExp('^s-');

  return element.getAttributeNames().reduce((acc, name) => {
    let returnValue;

    if (regExpShopimindParameter.test(name)) {
      // We only get parameters starting with "s-"
      returnValue = { ...acc, [name.replace(regExpShopimindParameter, '')]: element.getAttribute(name) };

      // We remove the attribute from the final HTML
      element.removeAttribute(name);
    } else {
      returnValue = { ...acc };
    }

    return returnValue;
  }, {});
};

/**
 * Apply conditions in SPM Language according to context
 * @param template
 * @param context
 */
export const applySpmConditions = (template: Document| HTMLElement, context: TemplateContext = {}) => {
  // We get each condition inside the template
  const conditions = template.querySelectorAll('[s-if], [s-elseif]');

  if (conditions) {
    Array.from(conditions).forEach((conditionElement) => {
      let isVerifiedCondition = false; // Boolean to remove a condition if it's not verified

      // We extract condition parameters
      const conditionParameters = extractElementParameters(conditionElement);
      let property;
      if (Object.prototype.hasOwnProperty.call(conditionParameters, 'if')) {
        property = conditionParameters.if;
      } else if (Object.prototype.hasOwnProperty.call(conditionParameters, 'elseif')) {
        property = conditionParameters.elseif;
      }
      let { condition, value } = conditionParameters;

      // Extract parameters of condition
      condition = condition.trim().replace('&lt;', '<').replace('&gt;', '>');

      // We try to find the value of the needed property in the context object
      const propertyValue: Maybe<PropertyOfTemplateContext> = findValueOfContextProperty(context, property);

      // We check if value is a property of the context
      const comparisonValue: Maybe<PropertyOfTemplateContext> = findValueOfContextProperty(context, value);

      if (comparisonValue) {
        value = comparisonValue.value;
      }

      // We need to check if the property matches the condition (and value if defined)
      let arrayOfValues;
      switch (condition.toLowerCase()) {
        case SpmLanguageConditions.EQUALS:
          // eslint-disable-next-line eqeqeq
          if ((value === 'NULL' && (!propertyValue || !propertyValue.value)) || (value !== 'NULL' && propertyValue && propertyValue.value == value)) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.NOT_EQUALS:
          // eslint-disable-next-line eqeqeq
          if ((value === 'NULL' && propertyValue && propertyValue.value) || (value !== 'NULL' && propertyValue && propertyValue.value != value)) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.LESS_THAN:
          if (propertyValue && value && propertyValue.value < value) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.LESS_OR_EQUAL_THAN:
          if (propertyValue && value && propertyValue.value <= value) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.MORE_THAN:
          if (propertyValue && value && propertyValue.value > value) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.MORE_OR_EQUAL_THAN:
          if (propertyValue && value && propertyValue.value >= value) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.HAS_ANY:
          if (propertyValue && Array.isArray(propertyValue.value) && propertyValue.value.length) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.IS_EMPTY:
          if (propertyValue && (!Array.isArray(propertyValue.value) || propertyValue.value.length === 0)) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.IN:
          arrayOfValues = value.split(',');
          if (propertyValue && value && arrayOfValues.length && arrayOfValues.includes(propertyValue.value)) {
            isVerifiedCondition = true;
          }
          break;
        case SpmLanguageConditions.NOT_IN:
          arrayOfValues = value.split(',');
          if (propertyValue && value && arrayOfValues.length && !arrayOfValues.includes(propertyValue.value)) {
            isVerifiedCondition = true;
          }
          break;
        default:
          break;
      }

      if (!isVerifiedCondition) {
        // We check if there is an else to display it right after this element
        const nextElement = conditionElement.nextElementSibling;

        if (nextElement && nextElement.hasAttribute('s-else')) {
          nextElement.removeAttribute('s-else');
        }

        // Condition is not verified, we remove the element
        conditionElement.remove();
      } else {
        // We check if there are elseif or else elements after the current element to remove them
        let sibling = conditionElement.nextElementSibling;
        while (sibling) {
          if (sibling.hasAttribute('s-elseif') || sibling.hasAttribute('s-else')) {
            sibling.remove();
            sibling = conditionElement.nextElementSibling;
          } else {
            // If the next element doesn't have elseif or else condition, we stop the while loop
            sibling = null;
          }
        }
      }
    });
  }
};

/**
 * Get smart products list elements from database
 * @param idShop
 * @param lang
 * @param parameters
 */
const getSmartProductList = async (idShop: number, lang: string, parameters: Record<string, any>) => {
  const result = await nestGet('v4', `/template/smart-product-list/${idShop}/${lang}/${JSON.stringify(parameters)}`, {}, '');
  const data = (result && result.success && result?.data) ? JSON.parse(result.data) : [];
  return {
    data,
  };
};

/**
 * Calculate and replace specific collections (loops) in HTML string
 * @param template
 * @param context
 * @param lang
 * @param generic
 */
export const applySpmCollections = async (
  template: Document| HTMLElement,
  context: TemplateContext = {},
  lang: Maybe<string> = null,
  generic = true,
): Promise<Document| HTMLElement> => {
  // We get each collection inside the template
  const collections = template.querySelectorAll('[s-collection]');

  if (collections) {
    let finalHtml = '';
    let markupAfter = '';
    let markupBefore = '';

    await Array.from(collections).reduce(async (a, collection) => {
      await a; // Wait for the end of the previous Promise
      const currentCollectionParent = collection.parentElement as HTMLElement;

      // We get collection's parent's tag name
      const collectionParent = collection.parentElement?.tagName.toLowerCase();

      // We extract collection parameters from the element (only Shopimind's parameters)
      const elementAttributes = extractElementParameters(collection);

      if (Object.prototype.hasOwnProperty.call(elementAttributes, 'collection')) {
        // If we are not in generic mode, or if we are not on specific loops or if we are using manual_selection engine
        if (
          !generic
          || (
            generic
            && (
              (elementAttributes.engine && elementAttributes.engine === 'manual_selection')
              || !/^(products)|(customer)|(cart)|(order)/.test(elementAttributes.collection)
            )
          )
        ) {
          // We clone the current context to avoid modifications of original object
          const currentContext = JSON.parse(JSON.stringify(context));

          if (elementAttributes.collection === 'products') {
            // We need to get the products according to the parameters of the collection
            const { data } = await getSmartProductList(context.shop.id, lang ?? DEFAULT_LOCALE, elementAttributes);

            if (data) {
              currentContext[elementAttributes.collection] = data;
            }
          }

          // We get the value of the collection
          const propertyValue: Maybe<PropertyOfTemplateContext> = findValueOfContextProperty(currentContext, elementAttributes.collection);

          if (propertyValue && Array.isArray(propertyValue.value) && propertyValue.value.length) {
            // We get all attributes of the parent
            let parentAttributes = '';

            if (currentCollectionParent) {
              currentCollectionParent.getAttributeNames().forEach((name) => {
                parentAttributes += ` ${name}="${currentCollectionParent.getAttribute(name)}"`;
              });
            }

            if (collectionParent) {
              // Define start and end markup for the parent
              markupBefore = `<${collectionParent}${parentAttributes}>`;
              markupAfter = `</${collectionParent}>`;
            }

            // We add the parent markup to replace the entire HTML code
            finalHtml += markupBefore;

            let loopIncrement = 0;

            await Array.from(propertyValue.value).reduce(async (b, item) => {
              await b; // Wait for the end of the previous Promise
              const newDomElement = collection.cloneNode(true) as HTMLElement;

              if (newDomElement) {
                // We assign the current item as a property inside the context
                currentContext[elementAttributes.item] = item;

                // For each item in the collection, we will replace the loops inside the HTML, apply conditions and replace variables
                const newContext = JSON.parse(JSON.stringify(currentContext));
                await applySpmCollections(newDomElement, newContext, lang, generic);
                applySpmConditions(newDomElement, newContext);
                finalHtml += await replaceVariables(newDomElement.outerHTML, newContext, lang);

                // We remove the custom context key
                delete currentContext[elementAttributes.item];

                // We create a new row if the collection has more elements than the grid parameter, and we are using a table element
                if (
                  collectionParent
                  && collectionParent !== 'div'
                  && Object.prototype.hasOwnProperty.call(elementAttributes, 'grid')
                  && ((loopIncrement + 1) % parseInt(elementAttributes.grid, 10) === 0)
                  && (loopIncrement + 1) < currentContext[elementAttributes.collection].length
                ) {
                  finalHtml += `${markupAfter}${markupBefore}`;
                }
              }

              loopIncrement += 1;
            }, Promise.resolve());

            // We need to add more columns to match the required number of elements, so we get the start and end markup from the value of the loop
            if (elementAttributes.nb && loopIncrement <= elementAttributes.nb) {
              // We get all attributes of the collection item
              let collectionAttributes = '';
              collection.getAttributeNames().forEach((name) => {
                collectionAttributes += ` ${name}="${collection.getAttribute(name)}"`;
              });

              const startColumn = `<${collection.tagName.toLowerCase()}${collectionAttributes}>`;
              const endColumn = `</${collection.tagName.toLowerCase()}>`;
              for (let i = loopIncrement; i < elementAttributes.nb; i++) {
                finalHtml += `${startColumn}${endColumn}`;
              }
            }

            // We close the parent markup
            finalHtml += `</${collectionParent}>`;

            // We replace the content of the collection by the new HTML code
            if (currentCollectionParent) {
              currentCollectionParent.outerHTML = finalHtml;
            }
          } else if (currentCollectionParent) {
            // We remove the collection from its parent
            currentCollectionParent.remove();
          }
        }
      } else if (currentCollectionParent) {
        // We remove the collection from its parent
        currentCollectionParent.remove();
      }

      // Reset of variables
      finalHtml = '';
      markupAfter = '';
      markupBefore = '';
    }, Promise.resolve());
  }

  return template;
};

/**
 * Parse of the template to clean useless elements/classes, parse variables
 * @param templateType
 * @param context
 * @param template
 * @param lang
 * @param type
 */
export const formatTemplate = async (
  templateType: string,
  context: TemplateContext,
  template: Document | HTMLElement,
  lang: Maybe<string> = null,
  type = 'send',
): Promise<string> => {
  // In case of SMS template, we don't have collections
  if (templateType !== TemplateParentTypeEnum.SMS) {
    if (type === 'test') {
      // In case of a test, we replace generic and specific collections
      await applySpmCollections(template, context, lang, false);
    } else {
      // Otherwise, we replace only generic collections in HTML
      await applySpmCollections(template, context, lang);
    }
  }

  // Replace variables from context
  let domHtml = template.children[0].outerHTML;
  domHtml = await replaceVariables(domHtml, context, lang);

  // We get a DOM element from HTML string
  const domElement = createDomFromString(domHtml);

  // In case of SMS template, we don't have conditions
  if (templateType !== TemplateParentTypeEnum.SMS) {
    // We apply conditions
    applySpmConditions(domElement, context);
  }

  // In case of SMS template, we don't need to sanitize the template
  if (templateType === TemplateParentTypeEnum.SMS) {
    const bubble = sanitizeTranslationMarkup(domElement.querySelector('.sms-bubble') as HTMLElement);

    if (bubble) {
      domHtml = bubble.innerHTML;
    }

    // Replace line breaks, special characters, shorten links, ...
    domHtml = await formatSmsMessage(domHtml);
  } else if (type !== 'send') {
    // Then, we sanitize the template
    domHtml = sanitizeTemplate(domElement);
  }

  // Finally, we return the template as string
  return domHtml;
};
