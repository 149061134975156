<template>
  <div class="ShopSyncs">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyShopSyncs',
});
</script>
