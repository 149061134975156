// eslint-disable-next-line import/no-cycle
import {
  CampaignsBulkHistoryInputItem,
  CampaignsBulkHistoryUpdateInputItem,
  MarketingWorkflowsHistoryInputItem,
  MarketingWorkflowsHistoryUpdateInputItem,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import { AutomatedScenarioHistory, AutomatedScenarioHistoryRaw } from '@/composables/AutomatedScenarios';
import moment from 'moment/moment';
// eslint-disable-next-line import/no-cycle
import { Get, Insert, Update } from '../GraphQL';

/* Campaigns Automation */
const InsertAutomatedScenarioHistory = async (historyInput: MarketingWorkflowsHistoryInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Insert<MarketingWorkflowsHistoryInputItem>({
    name: 'MarketingWorkflowsHistory',
    input: historyInput,
    type: 'MarketingWorkflowsHistoryInput',
  });

  if (err === '') {
    return id;
  }

  throw new Error(err);
};

const UpdateAutomatedScenarioHistory = async (historyInput: MarketingWorkflowsHistoryUpdateInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Update<MarketingWorkflowsHistoryUpdateInputItem>({
    name: 'MarketingWorkflowsHistory',
    input: historyInput,
    type: 'MarketingWorkflowsHistoryUpdateInput',
  });

  if (err === '') {
    return id;
  }

  throw new Error(err);
};

/* Campaigns Bulk */
const InsertCampaignBulkHistory = async (historyInput: CampaignsBulkHistoryInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Insert<CampaignsBulkHistoryInputItem>({
    name: 'CampaignsBulkHistory',
    input: historyInput,
    type: 'CampaignsBulkHistoryInput',
  });

  if (err === '') {
    return id;
  }

  throw new Error(err);
};

const UpdateCampaignBulkHistory = async (historyInput: CampaignsBulkHistoryUpdateInputItem[]) => {
  const {
    id, status, messages, err,
  } = await Update<CampaignsBulkHistoryUpdateInputItem>({
    name: 'CampaignsBulkHistory',
    input: historyInput,
    type: 'CampaignsBulkHistoryUpdateInput',
  });

  if (err === '') {
    return id;
  }

  throw new Error(err);
};

export const insertCampaignHistory = async (
  typeCampaign: TypeCampaignEnum,
  idShop: number,
  campaignId: number,
  historyData: AutomatedScenarioHistory) => {
  let result = null;
  if (typeCampaign === TypeCampaignEnum.AUTOMATION) {
    const historyInput: MarketingWorkflowsHistoryInputItem[] = [];
    historyInput.push({
      id_shop: idShop,
      id_marketing_workflow: campaignId,
      history: historyData.history,
      date_creation: historyData.dateCreation,
      date_modification: historyData.dateCreation,
    });
    result = await InsertAutomatedScenarioHistory(historyInput);
  } else if (typeCampaign === TypeCampaignEnum.BULK) {
    const historyInput: CampaignsBulkHistoryInputItem[] = [];
    historyInput.push({
      id_shop: idShop,
      id_campaign_bulk: campaignId,
      history: historyData.history,
      date_creation: historyData.dateCreation,
      date_modification: historyData.dateCreation,
    });
    result = await InsertCampaignBulkHistory(historyInput);
  }
  return result;
};

export const updateCampaignHistory = async (
  typeCampaign: TypeCampaignEnum,
  idShop: number,
  idHistory: number,
  campaignId: number,
  historyData: AutomatedScenarioHistory) => {
  let result = null;
  if (typeCampaign === TypeCampaignEnum.AUTOMATION) {
    const historyInput: MarketingWorkflowsHistoryUpdateInputItem[] = [];
    historyInput.push({
      id_marketing_workflow_history: idHistory,
      id_shop: idShop,
      id_marketing_workflow: campaignId,
      history: historyData.history,
      date_creation: historyData.dateCreation,
      date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    result = await UpdateAutomatedScenarioHistory(historyInput);
  } else if (typeCampaign === TypeCampaignEnum.BULK) {
    const historyInput: CampaignsBulkHistoryUpdateInputItem[] = [];
    historyInput.push({
      id_campaign_bulk_history: idHistory,
      id_shop: idShop,
      id_campaign_bulk: campaignId,
      history: historyData.history,
      date_creation: historyData.dateCreation,
      date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
    });
    result = await UpdateCampaignBulkHistory(historyInput);
  }

  return result;
};

export const getHistoryByCampaign = async (
  typeCampaign: TypeCampaignEnum,
  campaignId: number,
) => {
  const queryParams = {
    name: typeCampaign === TypeCampaignEnum.AUTOMATION
      ? 'MarketingWorkflowsHistoryByWorkflow' : 'CampaignsBulkHistoryByCampaign',
    keyName: typeCampaign === TypeCampaignEnum.AUTOMATION
      ? 'id_marketing_workflow' : 'id_campaign_bulk',
    fields: typeCampaign === TypeCampaignEnum.AUTOMATION
      ? [
        'id_marketing_workflow_history',
        'history',
        'date_creation',
      ] : [
        'id_campaign_bulk_history',
        'history',
        'date_creation',
      ],
  };

  const campaignHistory = await Get<AutomatedScenarioHistoryRaw>({
    name: queryParams.name,
    id: campaignId,
    keyName: queryParams.keyName,
    fields: queryParams.fields,
  });

  const historyId = (typeCampaign === TypeCampaignEnum.AUTOMATION)
    ? campaignHistory.item?.id_marketing_workflow_history ?? null
    : campaignHistory.item?.id_campaign_bulk_history ?? null;

  const campaignHistoryJson = campaignHistory.item ? {
    history: campaignHistory.item.history,
    dateCreation: campaignHistory.item.date_creation,
  } as AutomatedScenarioHistory : null;

  if (campaignHistoryJson && typeof campaignHistoryJson?.history === 'string') {
    campaignHistoryJson.history = JSON.parse(campaignHistoryJson.history).history;
  }

  const campaignHistoryJsonLength: number = campaignHistoryJson !== null && !!Object.getOwnPropertyDescriptor(campaignHistoryJson, 'history') && campaignHistoryJson?.history !== ''
    ? JSON.parse(campaignHistoryJson?.history ?? '[]').length
    : 0;

  // We check if there is a save entry in history.
  let currentHistoryIndex;
  let savedHistoryIndex = -1;
  if (campaignHistoryJsonLength === 0 || !campaignHistoryJson) {
    // If there is no history, we set -1
    currentHistoryIndex = -1;
  } else {
    let currentHistory = campaignHistoryJson.history ? JSON.parse(campaignHistoryJson.history) : [];

    if (Object.prototype.hasOwnProperty.call(currentHistory, 'history') && currentHistory.history !== '') {
      currentHistory = JSON.parse(currentHistory.history);
    }

    currentHistoryIndex = currentHistory.reverse().findIndex((historyPoint: any) => historyPoint.type === 'save');

    // If we can't find a save point, we set the last history as the active history
    if (currentHistoryIndex === -1) {
      currentHistoryIndex = 0;
    } else {
      savedHistoryIndex = currentHistoryIndex;
    }
  }

  return {
    historyId,
    campaignHistoryJson,
    currentHistoryIndex,
    savedHistoryIndex,
    campaignHistoryJsonLength,
  };
};
