
import {
  defineComponent, reactive, computed,
} from 'vue';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SegmentationBlockOR from '@/components/segmentations/SegmentationBlockOR.vue';
import { store } from '@/store';
import { useI18n } from 'vue-i18n';
import validateSegmentProperty from '@/components/segmentations/utils/utils';

export default defineComponent({
  name: 'SegmentationBlockAND',
  components: {
    SegmentationBlockOR,
    SpmButton,
    Button,
    Tag,
  },

  props: {
    blockAnd: {
      type: Array,
      required: false,
      default: () => [],
    },

    indexBlockAnd: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const dataBlockAND = reactive(props.blockAnd);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const addBlockOR = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      const { indexBlockAnd } = props;
      store.commit('segmentationsEditor/addBlockOR', indexBlockAnd);
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
    };

    return {
      t,
      dataBlockAND,
      addBlockOR,
    };
  },
});
