import { InputGroupField, Property } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

export const setFields = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const fields: HTMLElement[] = Array.from(template?.querySelectorAll(selector));
  fields.forEach((field: HTMLElement) => {
    const id: string = field.querySelector('.spm_form_input')?.getAttribute('id') as string;
    if (id !== 'spm_form_registration_password') {
      if (property.value.includes(id)) field.classList.remove('spm_hidden');
      else field.classList.add('spm_hidden');
    } else if (id === 'spm_form_registration_password' && !field.classList.contains('spm_hidden')) {
      field.classList.add('spm_hidden');
    }
  });
};

const setLabel = (label: HTMLElement, value: string, required: boolean) => {
  // eslint-disable-next-line no-param-reassign
  label.innerHTML = '';
  const span = document.createElement('span');
  span.innerHTML = `${value} :`;
  label.appendChild(span);
  if (required) {
    const asterisk = document.createElement('i');
    asterisk.classList.add('required_asterisk');
    asterisk.innerHTML = '*';
    label.appendChild(asterisk);
  }
};

export const setFieldLabels = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const fields: HTMLElement[] = Array.from(template?.querySelectorAll(selector));
  property.value.forEach((update: InputGroupField) => {
    const fieldToUpdate = fields.filter((field: HTMLElement) => field.querySelector('.spm_form_input')?.getAttribute('id') === update.key)[0];
    const isFieldRequired: boolean = (fieldToUpdate.classList.contains('spm_form_field_base') || ('required' in update && update.required)) ?? false;
    // Change label
    const label = fieldToUpdate.querySelector('label') as HTMLElement;
    setLabel(label, update.value, isFieldRequired);
    // Change placeholder
    const input: HTMLElement = fieldToUpdate.querySelector('.spm_form_input') as HTMLElement;
    if (input) {
      input.setAttribute('placeholder', `${update.value}${isFieldRequired ? '*' : ''}`);

      if (isFieldRequired) {
        input.classList.add('spm_form_required');
      } else {
        input.classList.remove('spm_form_required');
      }
    }
  });
};

export const sortFields = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const fields: HTMLElement[] = Array.from(template?.querySelectorAll(selector));
  const form: HTMLElement = template?.querySelectorAll(selector)[0]?.parentNode as HTMLElement;
  const sortedIds = property.value.map((element: InputGroupField) => element.key);

  fields.sort((a: Element, b: Element) => { // Sort fields in updated order
    const firstIndex = a.querySelector('.spm_form_input')?.getAttribute('id');
    const secondIndex = b.querySelector('.spm_form_input')?.getAttribute('id');
    if (firstIndex && secondIndex) {
      if (sortedIds.indexOf(firstIndex) === -1 || sortedIds.indexOf(secondIndex) === -1) return 1;
      return sortedIds.indexOf(firstIndex) - sortedIds.indexOf(secondIndex);
    }
    return 1;
  })
  // We have to insert fields using "prepend" because form has other elements which have to stay at the end
  // so we have to reverse array before prepend fields
    .reverse()
    .forEach((field: Element) => {
      if (form) form.prepend(field);
    });
};

export const setMessage = (selector: string, property: Property) => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement = template?.querySelector(selector) as HTMLElement;
  const element = container.querySelector('.spm_step2');
  if (element) {
    element.innerHTML = property.value;
  }
};
