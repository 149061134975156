
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import TheSpinner from '@/components/TheSpinner.vue';
import {
  computed, defineComponent, ref, Ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import useListQuery from '@/composables/useListQuery';
import { SpmTableFilter, SpmTableSort } from '@/types';
import { store } from '@/store';

export default defineComponent({
  name: 'ChooseTemplateModal',

  components: {
    Dialog,
    Button,
    TheSpinner,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-template': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const imageUrl = 'https://media.shopimind.io/img/templates_preview_v3/35b251d8f5dac17f80b3a2b34b9aae1abe24190b.png';
    const itemsToLoad = 12;

    const displayModal = computed(() => props.displayDialog);
    const displaySpinner = ref(false);

    const endListElementRef = ref();

    const templates: Ref<any[]> = ref([]);

    const offset = ref(0);
    const settings: {
      limit: Ref<number>;
      offset: Ref<number>;
      order: Ref<SpmTableSort[]>;
      filter: Ref<SpmTableFilter[]>;
    } = {
      limit: ref(itemsToLoad),
      offset: ref(offset.value),
      order: ref([]),
      filter: ref([{ field: 'type', value: 'popup', operator: 'CONTAINS' }]),
    };

    const result = useListQuery(
      {
        name: 'TemplatesList',
        settings,
        fields: ['id_template', 'label'],
      },
    );

    const handleScroll = () => {
      const observer = new IntersectionObserver((entries) => {
        const endListElement = entries[0];
        if (endListElement.isIntersecting) {
          displaySpinner.value = true;
          settings.offset.value += itemsToLoad;
        }
      });
      observer.observe(endListElementRef.value);
    };

    const handleClose = () => {
      context.emit('on-close-dialog');
    };

    const handleChooseTemplate = (template: any) => {
      context.emit('on-choose-template', template);
      handleClose();
      store.commit('general/hideTheSpinner');
    };

    watch(result.data, () => {
      if (result.data.value?.TemplatesList?.items) {
        templates.value.push(...result.data.value.TemplatesList.items);
        displaySpinner.value = false;
      }
    });

    return {
      t,
      imageUrl,
      endListElementRef,
      templates,
      displayModal,
      displaySpinner,
      handleClose,
      handleScroll,
      handleChooseTemplate,
    };
  },
});
