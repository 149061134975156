<template>
  <div class="flex justify-content-between flex-wrap mb-3">
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t('myShopData.product_categories.title') }}
      </h3>
    </div>
    <div class="flex flex-row-reverse">
      <div
        v-if="findPermission('my_data.synchro.execute')"
        style="padding-left:10px"
        class="flex align-items-center justify-content-center"
      >
        <Synchronization
          synchro-type="productsCategories"
          @synchro-launched="synchroAction(true)"
        />
      </div>
      <div class="mr-2">
        <ShopsSyncsInProgress
          v-if="synchroData"
          :id-shop="idShop"
          :table-data="synchroData"
          :launch-fetch="launchSynchro"
          sync-type="productsCategories,all"
        />
      </div>
      <div class="mr-2">
        <router-link
          :to="{ name: 'shop.data' }"
        >
          <Button class="p-button p-button-secondary">
            {{ t('return') }}
          </Button>
        </router-link>
      </div>
    </div>
  </div>

  <div class="grid">
    <div class="col-12 col-fixed product_categories">
      <SpmTable
        name="ShopsProductsCategoriesList"
        index="id_shop_product_category_list"
        :table-columns="columns"
        :id-shop="idShop"
        :custom-selector="true"
        :show-export-menu-item="findPermission('my_data.export')"
        grouped-actions-key="id_shop_product_category_list"
        :export-file-name="t('myShopData.product_categories.title')"
      >
        <template #link="slotsProp">
          <a
            :key="slotsProp.data.id_shop_product_category_list"
            :href="slotsProp.data.link"
            target="_blank"
          >
            <i class="far fa-external-link green" />
          </a>
        </template>
      </SpmTable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { SpmTableColumns, SpmTableState } from '@/types';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { findPermission, UserState } from '@/composables/User';
import { ListResult } from '@/composables/GraphQL';
import { ShopsSyncs } from '@/types/generated-types/graphql';
import Synchronization from '@/components/header/Synchronization.vue';
import ShopsSyncsInProgress from '@/components/header/ShopsSyncsInProgress.vue';
import ShopsSyncsList from '@/composables/shop/ShopSyncs';
import Button from 'primevue/button';
import {getShopCustomerLanguages} from "@/types/country-language-options";

export default defineComponent({
  name: 'ProductCategories',
  components: {
    Synchronization,
    SpmTable,
    ShopsSyncsInProgress,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const synchroData = ref<ListResult<ShopsSyncs>>();
    const idShop = UserState.activeShop?.id ?? 0;
    const launchSynchro = ref(false);
    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_product_category_list',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date_modification',
        header: t('myShopData.product_categories.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'id_category',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
      },
      {
        field: 'name',
        header: t('myShopData.product_categories.headers.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'link',
        header: t('myShopData.product_categories.headers.link'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:10%;text-align:center',
        type: 'text',
      },
      {
        field: 'lang',
        header: t('myShopData.product_categories.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'active',
        header: t('myShopData.product_categories.headers.active'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'active-status-icon',
        filterSettings: {
          type: 'multiSelect',
          options: [{ value: 1, label: t('active') }, { value: 0, label: t('inactive') }],
          hideFilterMenu: true,
          showFilterInput: false,
        },
      },
    ];

    onMounted(async () => {
      synchroData.value = await ShopsSyncsList('productsCategories,all', idShop, 'user');
    });

    const synchroAction = (launch: boolean) => {
      launchSynchro.value = launch;
    };

    return {
      t,
      columns,
      findPermission,
      synchroData,
      idShop,
      launchSynchro,
      synchroAction,
    };
  },
});
</script>

<style>
.green { color:#94c840; }
</style>
