
import {
  computed, defineComponent, onBeforeMount, ref, SetupContext, toRefs, watch, nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import ConfigComponents from '@/components/automated-scenarios/forms';
import {
  addHistory,
  AutomatedScenarioState as state,
  backOperatorSelect,
  resetLeftToolbar, resetSelectedOperator, resetDefaultDisplayUrl,
  setAnalyticsDataToOperator,
  setCurrentConfigurationForm,
  setOperatorData,
  setPersistence,
  refreshFlowchart,
} from '@/composables/AutomatedScenarios';
import { getComponentMetadata } from '@/components/automated-scenarios/metadata';
import { showToastError, showToastSuccess } from '@/helpers';
import SubPanelConfigContent from '@/components/automated-scenarios/SubPanelConfigContent.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { formatNumbersInString } from '@/helpers/Number';

export default defineComponent({
  name: 'OperatorEditForm',

  components: {
    SpmButton,
    ...ConfigComponents,
    SubPanelConfigContent,
    Button,
  },

  props: {
    signalSave: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    actionAfterSave: {
      type: Object as any,
      required: false,
      default: () => ({}),
    },
  },

  emits: [
    'reset-action-after-save',
  ],

  setup(props, context: SetupContext) {
    const { signalSave } = toRefs(props);
    const { actionAfterSave } = toRefs(props);
    const { t } = useI18n();
    const formValidation = ref<any>({});

    const data = ref<any>({});
    const configuringSegment = computed(() => state.configuringSegment);
    data.value = state.scenario.getOperatorData<any>(state.selectedOperator.operatorId)
      ? JSON.parse(JSON.stringify(state.scenario.getOperatorData<any>(state.selectedOperator.operatorId)))
      : null;

    setCurrentConfigurationForm(data.value);
    const analyticsData = computed(() => {
      const res = state.operators.filter((o) => o.operatorId === state.selectedOperator.operatorId);
      return res.length > 0 ? res[0].analyticsData : null;
    });

    const panels = computed(() => ([
      {
        name: 'config_form',
        label: t('campaigns.common.editForm.configuration'),
        show: state.selectedOperator.meta.hasConfiguration,
        showIfConfiguringSegment: true,
      },
      {
        name: 'analytics_data',
        label: `${t('campaigns.common.editForm.analytics')} (${t(`customDatePicker.${state.scenario.analyticsMode.dateInterval.interval}`)})`,
        show: analyticsData.value !== null,
        showIfConfiguringSegment: false,
      },
    ]));

    const save = async () => {
      const { meta: { component } } = state.selectedOperator;
      if (!component) {
        return;
      }

      const meta = getComponentMetadata(component);
      if (!meta) {
        return;
      }

      if (meta.Validate) {
        try {
          const validation = await meta.Validate(data.value);
          if (!validation.success) {
            formValidation.value = validation.validate;
            await showToastError(t('errorMessages.FORM_ERROR'));
            return;
          }
        } catch (error) {
          console.error(error);
          return;
        }
      }

      if (meta.BeforeInsertData) {
        data.value = meta.BeforeInsertData(data.value);
      }

      setOperatorData(state.selectedOperator.operatorId, data.value);
      setPersistence(state.selectedOperator.operatorId);
      await nextTick();
      refreshFlowchart();
      showToastSuccess('savedSuccessful');
      resetSelectedOperator();
      resetDefaultDisplayUrl();
      resetLeftToolbar();
      addHistory('update_box', `${meta.Meta.label}`).then(() => {
        //console.log('addHistory');
      });

      if (meta.Callback) {
        meta.Callback(data.value);
      }

      if (actionAfterSave.value && actionAfterSave.value.action) {
        actionAfterSave.value.action();
        context.emit('reset-action-after-save');
      }
    };

    const cancel = () => {
      backOperatorSelect(true);
    };

    watch(() => data.value, () => {
      setCurrentConfigurationForm(data.value);
    }, { deep: true });

    watch(signalSave, async () => {
      await save();
    });

    onBeforeMount(async () => {
      if (state.selectedOperator.currentConfigurationForm !== null) {
        data.value = state.selectedOperator.currentConfigurationForm;
      }

      // Si les données analytics de cet operator sont null et que le mode analytics est activé, on les récupère
      if (analyticsData.value === null) {
        await setAnalyticsDataToOperator(state.selectedOperator.operatorId);
      }
    });

    return {
      t,
      panels,
      analyticsData,
      data,
      save,
      cancel,
      state,
      configuringSegment,
      formValidation,
    };
  },

  methods: { formatNumbersInString },
});
