<template>
  <div
    class="Flag"
    style="text-align: center"
  >
    <div
      v-if="flag"
      class="flex"
      style="justify-content: center !important; align-items: center !important;"
    >
      <img
        :alt="altText"
        :src="'/images/flags/' + flag + '.png'"
      >
    </div>
    <div v-else>
      <i class="fa-solid fa-question" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Flag',

  props: {
    country: {
      type: String,
      required: false,
      default: '',
    },

    alt: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props: { country: string; alt: string}) {
    const { country } = props;

    let flag = '';
    if (props.country && props.country !== '' && props.country !== '**') {
      flag = country.toLowerCase();
    }

    return {
      flag,
      altText: props.alt ?? props.country,
    };
  },
});
</script>
