import { POPIN_AUTO_CLOSE_ATTRIBUTE } from '@/components/template-builder/utils/constants';

const cssPropertiesList = [
  {
    property: '--*',
    url: 'https://drafts.csswg.org/css-variables/#propdef-',
    status: 'ED',
    title: 'CSS Custom Properties for Cascading Variables Module Level 1',
  },
  {
    property: '--*',
    url: 'https://www.w3.org/TR/2022/CR-css-variables-1-20220616/#propdef-',
    status: 'CR',
    title: 'CSS Custom Properties for Cascading Variables Module Level 1',
  },
  {
    property: '-webkit-line-clamp',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef--webkit-line-clamp',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: '-webkit-line-clamp',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef--webkit-line-clamp',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'accent-color',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-accent-color',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'accent-color',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-accent-color',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'align-content',
    url: 'https://drafts.csswg.org/css-align/#propdef-align-content',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'align-content',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-align-content',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'align-content',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-align-content',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'align-content',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-align-content',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'align-items',
    url: 'https://drafts.csswg.org/css-align/#propdef-align-items',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'align-items',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-align-items',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'align-items',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-align-items',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'align-items',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-align-items',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'align-self',
    url: 'https://drafts.csswg.org/css-align/#propdef-align-self',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'align-self',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-align-self',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'align-self',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-align-self',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'align-self',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-align-self',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'alignment-baseline',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-alignment-baseline',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'alignment-baseline',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-alignment-baseline',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'all',
    url: 'https://drafts.csswg.org/css-cascade-3/#propdef-all',
    status: 'ED',
    title: 'CSS Cascading and Inheritance Level 3',
  },
  {
    property: 'all',
    url: 'https://drafts.csswg.org/css-cascade-4/#propdef-all',
    status: 'ED',
    title: 'CSS Cascading and Inheritance Level 4',
  },
  {
    property: 'all',
    url: 'https://drafts.csswg.org/css-cascade-5/#propdef-all',
    status: 'ED',
    title: 'CSS Cascading and Inheritance Level 5',
  },
  {
    property: 'all',
    url: 'https://www.w3.org/TR/2021/REC-css-cascade-3-20210211/#propdef-all',
    status: 'REC',
    title: 'CSS Cascading and Inheritance Level 3',
  },
  {
    property: 'all',
    url: 'https://www.w3.org/TR/2022/CR-css-cascade-4-20220113/#propdef-all',
    status: 'CR',
    title: 'CSS Cascading and Inheritance Level 4',
  },
  {
    property: 'all',
    url: 'https://www.w3.org/TR/2022/CR-css-cascade-5-20220113/#propdef-all',
    status: 'CR',
    title: 'CSS Cascading and Inheritance Level 5',
  },
  {
    property: 'animation',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-composition',
    url: 'https://drafts.csswg.org/css-animations-2/#propdef-animation-composition',
    status: 'ED',
    title: 'CSS Animations Level 2',
  },
  {
    property: 'animation-composition',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-2-20230302/#propdef-animation-composition',
    status: 'FPWD',
    title: 'CSS Animations Level 2',
  },
  {
    property: 'animation-delay',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-delay',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-delay',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-animation-delay',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-delay',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-delay',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-delay-end',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-animation-delay-end',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-delay-start',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-animation-delay-start',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-direction',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-direction',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-direction',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-direction',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-duration',
    url: 'https://drafts.csswg.org/css-animations-2/#propdef-animation-duration',
    status: 'ED',
    title: 'CSS Animations Level 2',
  },
  {
    property: 'animation-duration',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-duration',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-duration',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-duration',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-fill-mode',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-fill-mode',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-fill-mode',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-fill-mode',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-iteration-count',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-iteration-count',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-iteration-count',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-iteration-count',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-name',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-name',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-name',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-name',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-play-state',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-play-state',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-play-state',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-play-state',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-range',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-animation-range',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-range',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-animation-range',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-range-end',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-animation-range-end',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-range-start',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-animation-range-start',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'animation-timeline',
    url: 'https://drafts.csswg.org/css-animations-2/#propdef-animation-timeline',
    status: 'ED',
    title: 'CSS Animations Level 2',
  },
  {
    property: 'animation-timeline',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-2-20230302/#propdef-animation-timeline',
    status: 'FPWD',
    title: 'CSS Animations Level 2',
  },
  {
    property: 'animation-timing-function',
    url: 'https://drafts.csswg.org/css-animations/#propdef-animation-timing-function',
    status: 'ED',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'animation-timing-function',
    url: 'https://www.w3.org/TR/2023/WD-css-animations-1-20230302/#propdef-animation-timing-function',
    status: 'WD',
    title: 'CSS Animations Level 1',
  },
  {
    property: 'appearance',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-appearance',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'appearance',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-appearance',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'aspect-ratio',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-aspect-ratio',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'aspect-ratio',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-aspect-ratio',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'azimuth',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-azimuth',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'backface-visibility',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-backface-visibility',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'backface-visibility',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-backface-visibility',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'background',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-background',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'background',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background',
    url: 'https://drafts.csswg.org/css2/#propdef-background',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'background',
    url: 'https://drafts.csswg.org/css2/#propdef-background',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'background',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-attachment',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-background-attachment',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'background-attachment',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-attachment',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-attachment',
    url: 'https://drafts.csswg.org/css2/#propdef-background-attachment',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'background-attachment',
    url: 'https://drafts.csswg.org/css2/#propdef-background-attachment',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'background-attachment',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-attachment',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-blend-mode',
    url: 'https://drafts.fxtf.org/compositing-1/#propdef-background-blend-mode',
    status: 'ED',
    title: 'Compositing and Blending Level 1',
  },
  {
    property: 'background-blend-mode',
    url: 'https://www.w3.org/TR/2015/CR-compositing-1-20150113/#propdef-background-blend-mode',
    status: 'CR',
    title: 'Compositing and Blending Level 1',
  },
  {
    property: 'background-clip',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-clip',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-clip',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-clip',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-background-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'background-color',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-color',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-color',
    url: 'https://drafts.csswg.org/css2/#propdef-background-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'background-color',
    url: 'https://drafts.csswg.org/css2/#propdef-background-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'background-color',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-color',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-image',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-background-image',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'background-image',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-image',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-image',
    url: 'https://drafts.csswg.org/css2/#propdef-background-image',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'background-image',
    url: 'https://drafts.csswg.org/css2/#propdef-background-image',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'background-image',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-image',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-origin',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-origin',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-origin',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-origin',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-position',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-background-position',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'background-position',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-position',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-position',
    url: 'https://drafts.csswg.org/css2/#propdef-background-position',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'background-position',
    url: 'https://drafts.csswg.org/css2/#propdef-background-position',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'background-position',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-position',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-repeat',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-background-repeat',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'background-repeat',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-repeat',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-repeat',
    url: 'https://drafts.csswg.org/css2/#propdef-background-repeat',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'background-repeat',
    url: 'https://drafts.csswg.org/css2/#propdef-background-repeat',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'background-repeat',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-repeat',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-size',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-background-size',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'background-size',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-background-size',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'baseline-shift',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-baseline-shift',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'baseline-shift',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-baseline-shift',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'baseline-source',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-baseline-source',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'baseline-source',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-baseline-source',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'block-ellipsis',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-block-ellipsis',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'block-ellipsis',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-block-ellipsis',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'block-size',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-block-size',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'block-size',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-block-size',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'block-step',
    url: 'https://drafts.csswg.org/css-rhythm/#propdef-block-step',
    status: 'ED',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step',
    url: 'https://www.w3.org/TR/2017/WD-css-rhythm-1-20170302/#propdef-block-step',
    status: 'FPWD',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-align',
    url: 'https://drafts.csswg.org/css-rhythm/#propdef-block-step-align',
    status: 'ED',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-align',
    url: 'https://www.w3.org/TR/2017/WD-css-rhythm-1-20170302/#propdef-block-step-align',
    status: 'FPWD',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-insert',
    url: 'https://drafts.csswg.org/css-rhythm/#propdef-block-step-insert',
    status: 'ED',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-insert',
    url: 'https://www.w3.org/TR/2017/WD-css-rhythm-1-20170302/#propdef-block-step-insert',
    status: 'FPWD',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-round',
    url: 'https://drafts.csswg.org/css-rhythm/#propdef-block-step-round',
    status: 'ED',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-round',
    url: 'https://www.w3.org/TR/2017/WD-css-rhythm-1-20170302/#propdef-block-step-round',
    status: 'FPWD',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-size',
    url: 'https://drafts.csswg.org/css-rhythm/#propdef-block-step-size',
    status: 'ED',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'block-step-size',
    url: 'https://www.w3.org/TR/2017/WD-css-rhythm-1-20170302/#propdef-block-step-size',
    status: 'FPWD',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'bookmark-label',
    url: 'https://drafts.csswg.org/css-content-3/#propdef-bookmark-label',
    status: 'ED',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'bookmark-label',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-bookmark-label',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'bookmark-label',
    url: 'https://www.w3.org/TR/2019/WD-css-content-3-20190802/#propdef-bookmark-label',
    status: 'WD',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'bookmark-level',
    url: 'https://drafts.csswg.org/css-content-3/#propdef-bookmark-level',
    status: 'ED',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'bookmark-level',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-bookmark-level',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'bookmark-level',
    url: 'https://www.w3.org/TR/2019/WD-css-content-3-20190802/#propdef-bookmark-level',
    status: 'WD',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'bookmark-state',
    url: 'https://drafts.csswg.org/css-content-3/#propdef-bookmark-state',
    status: 'ED',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'bookmark-state',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-bookmark-state',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'bookmark-state',
    url: 'https://www.w3.org/TR/2019/WD-css-content-3-20190802/#propdef-bookmark-state',
    status: 'WD',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'border',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border',
    url: 'https://drafts.csswg.org/css2/#propdef-border',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border',
    url: 'https://drafts.csswg.org/css2/#propdef-border',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-block',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-color',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-color',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-color',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-color',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end-color',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-end-color',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end-color',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-end-color',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end-style',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-end-style',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end-style',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-end-style',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end-width',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-end-width',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-end-width',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-end-width',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start-color',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-start-color',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start-color',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-start-color',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start-style',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-start-style',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start-style',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-start-style',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start-width',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-start-width',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-start-width',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-start-width',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-style',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-style',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-style',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-style',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-width',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-block-width',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-block-width',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-block-width',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-bottom',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-bottom',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-bottom',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-bottom',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-bottom',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-bottom',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-bottom-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-bottom-color',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-bottom-color',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-bottom-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-bottom-color',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-bottom-color',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-left-radius',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-bottom-left-radius',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-left-radius',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-bottom-left-radius',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-right-radius',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-bottom-right-radius',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-right-radius',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-bottom-right-radius',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-bottom-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-bottom-style',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-bottom-style',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-bottom-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-bottom-style',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-bottom-style',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-bottom-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-bottom-width',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-bottom-width',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-bottom-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-bottom-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-bottom-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-bottom-width',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-bottom-width',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-boundary',
    url: 'https://drafts.csswg.org/css-round-display/#propdef-border-boundary',
    status: 'ED',
    title: 'CSS Round Display Level 1',
  },
  {
    property: 'border-boundary',
    url: 'https://www.w3.org/TR/2016/WD-css-round-display-1-20161222/#propdef-border-boundary',
    status: 'WD',
    title: 'CSS Round Display Level 1',
  },
  {
    property: 'border-collapse',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/tables.html#propdef-border-collapse',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-collapse',
    url: 'https://drafts.csswg.org/css-tables-3/#propdef-border-collapse',
    status: 'ED',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'border-collapse',
    url: 'https://drafts.csswg.org/css2/#propdef-border-collapse',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-collapse',
    url: 'https://drafts.csswg.org/css2/#propdef-border-collapse',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-collapse',
    url: 'https://www.w3.org/TR/2019/WD-css-tables-3-20190727/#propdef-border-collapse',
    status: 'WD',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'border-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-color',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-color',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-color',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-color',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-end-end-radius',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-end-end-radius',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-end-end-radius',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-end-end-radius',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-end-start-radius',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-end-start-radius',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-end-start-radius',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-end-start-radius',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-image',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-image',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-image',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-outset',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-image-outset',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-outset',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-image-outset',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-repeat',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-image-repeat',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-repeat',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-image-repeat',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-slice',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-image-slice',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-slice',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-image-slice',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-source',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-image-source',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-source',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-image-source',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-width',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-image-width',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-image-width',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-image-width',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-inline',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-color',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-color',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-color',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-color',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end-color',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-end-color',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end-color',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-end-color',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end-style',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-end-style',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end-style',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-end-style',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end-width',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-end-width',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-end-width',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-end-width',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start-color',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-start-color',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start-color',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-start-color',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start-style',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-start-style',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start-style',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-start-style',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start-width',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-start-width',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-start-width',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-start-width',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-style',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-style',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-style',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-style',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-width',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-inline-width',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-inline-width',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-inline-width',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-left',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-left',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-left',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-left',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-left',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-left',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-left',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-left-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-left-color',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-left-color',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-left-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-left-color',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-left-color',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-left-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-left-style',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-left-style',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-left-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-left-style',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-left-style',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-left-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-left-width',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-left-width',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-left-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-left-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-left-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-left-width',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-left-width',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-radius',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-radius',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-radius',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-radius',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-right',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-right',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-right',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-right',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-right',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-right',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-right-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-right-color',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-right-color',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-right-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-right-color',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-right-color',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-right-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-right-style',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-right-style',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-right-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-right-style',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-right-style',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-right-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-right-width',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-right-width',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-right-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-right-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-right-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-right-width',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-right-width',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-spacing',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/tables.html#propdef-border-spacing',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-spacing',
    url: 'https://drafts.csswg.org/css-tables-3/#propdef-border-spacing',
    status: 'ED',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'border-spacing',
    url: 'https://drafts.csswg.org/css2/#propdef-border-spacing',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-spacing',
    url: 'https://drafts.csswg.org/css2/#propdef-border-spacing',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-spacing',
    url: 'https://www.w3.org/TR/2019/WD-css-tables-3-20190727/#propdef-border-spacing',
    status: 'WD',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'border-start-end-radius',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-start-end-radius',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-start-end-radius',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-start-end-radius',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-start-start-radius',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-border-start-start-radius',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-start-start-radius',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-border-start-start-radius',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'border-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-style',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-style',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-style',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-style',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-top',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-top',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-top',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-top',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-top',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-top',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-top-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-top-color',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-top-color',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-top-color',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-top-color',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-top-color',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-left-radius',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-top-left-radius',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-left-radius',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-top-left-radius',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-right-radius',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-top-right-radius',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-right-radius',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-top-right-radius',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-top-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-top-style',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-top-style',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-top-style',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-top-style',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-top-style',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-top-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-top-width',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-top-width',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-top-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-top-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-top-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-top-width',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-top-width',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-border-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'border-width',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-border-width',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'border-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'border-width',
    url: 'https://drafts.csswg.org/css2/#propdef-border-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'border-width',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-border-width',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'bottom',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-bottom',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'bottom',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-bottom',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'bottom',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-bottom',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'box-decoration-break',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-box-decoration-break',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'box-decoration-break',
    url: 'https://drafts.csswg.org/css-break/#propdef-box-decoration-break',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'box-decoration-break',
    url: 'https://www.w3.org/TR/2018/CR-css-break-3-20181204/#propdef-box-decoration-break',
    status: 'CR',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'box-decoration-break',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-box-decoration-break',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'box-shadow',
    url: 'https://drafts.csswg.org/css-backgrounds/#propdef-box-shadow',
    status: 'ED',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'box-shadow',
    url: 'https://www.w3.org/TR/2023/CRD-css-backgrounds-3-20230214/#propdef-box-shadow',
    status: 'CRD',
    title: 'CSS Backgrounds and Borders Module Level 3',
  },
  {
    property: 'box-sizing',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-box-sizing',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'box-sizing',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-box-sizing',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'box-sizing',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-box-sizing',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'box-sizing',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-box-sizing',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'box-snap',
    url: 'https://drafts.csswg.org/css-line-grid/#propdef-box-snap',
    status: 'ED',
    title: 'CSS Line Grid Module Level 1',
  },
  {
    property: 'box-snap',
    url: 'https://www.w3.org/TR/2014/WD-css-line-grid-1-20140916/#propdef-box-snap',
    status: 'WD',
    title: 'CSS Line Grid Module Level 1',
  },
  {
    property: 'break-after',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-break-after',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'break-after',
    url: 'https://drafts.csswg.org/css-break/#propdef-break-after',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'break-after',
    url: 'https://www.w3.org/TR/2014/WD-css-regions-1-20141009/#propdef-break-after',
    status: 'WD',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'break-after',
    url: 'https://www.w3.org/TR/2018/CR-css-break-3-20181204/#propdef-break-after',
    status: 'CR',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'break-after',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-break-after',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'break-before',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-break-before',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'break-before',
    url: 'https://drafts.csswg.org/css-break/#propdef-break-before',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'break-before',
    url: 'https://www.w3.org/TR/2014/WD-css-regions-1-20141009/#propdef-break-before',
    status: 'WD',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'break-before',
    url: 'https://www.w3.org/TR/2018/CR-css-break-3-20181204/#propdef-break-before',
    status: 'CR',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'break-before',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-break-before',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'break-inside',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-break-inside',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'break-inside',
    url: 'https://drafts.csswg.org/css-break/#propdef-break-inside',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'break-inside',
    url: 'https://www.w3.org/TR/2014/WD-css-regions-1-20141009/#propdef-break-inside',
    status: 'WD',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'break-inside',
    url: 'https://www.w3.org/TR/2018/CR-css-break-3-20181204/#propdef-break-inside',
    status: 'CR',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'break-inside',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-break-inside',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'caption-side',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/tables.html#propdef-caption-side',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'caption-side',
    url: 'https://drafts.csswg.org/css-tables-3/#propdef-caption-side',
    status: 'ED',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'caption-side',
    url: 'https://drafts.csswg.org/css2/#propdef-caption-side',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'caption-side',
    url: 'https://drafts.csswg.org/css2/#propdef-caption-side',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'caption-side',
    url: 'https://www.w3.org/TR/2019/WD-css-tables-3-20190727/#propdef-caption-side',
    status: 'WD',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'caret',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-caret',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'caret',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-caret',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'caret-color',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-caret-color',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'caret-color',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-caret-color',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'caret-color',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-caret-color',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'caret-color',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-caret-color',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'caret-shape',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-caret-shape',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'caret-shape',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-caret-shape',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'chains',
    url: 'https://drafts.csswg.org/css-template/#chains0',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'chains',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#chains0',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'clear',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-clear',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'clear',
    url: 'https://drafts.csswg.org/css-page-floats/#propdef-clear',
    status: 'ED',
    title: 'CSS Page Floats',
  },
  {
    property: 'clear',
    url: 'https://drafts.csswg.org/css2/#propdef-clear',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'clear',
    url: 'https://drafts.csswg.org/css2/#propdef-clear',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'clear',
    url: 'https://www.w3.org/TR/2015/WD-css-page-floats-3-20150915/#propdef-clear',
    status: 'FPWD',
    title: 'CSS Page Floats',
  },
  {
    property: 'clip',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visufx.html#propdef-clip',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'clip',
    url: 'https://drafts.csswg.org/css2/#propdef-clip',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'clip',
    url: 'https://drafts.csswg.org/css2/#propdef-clip',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'clip',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-clip',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'clip',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-clip',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'clip-path',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-clip-path',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'clip-path',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-clip-path',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'clip-rule',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-clip-rule',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'clip-rule',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-clip-rule',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/colors.html#propdef-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'color',
    url: 'https://drafts.csswg.org/css-color-3/#color0',
    status: 'ED',
    title: 'CSS Color Module Level 3',
  },
  {
    property: 'color',
    url: 'https://drafts.csswg.org/css-color-3/#color01',
    status: 'ED',
    title: 'CSS Color Module Level 3',
  },
  {
    property: 'color',
    url: 'https://drafts.csswg.org/css-color/#propdef-color',
    status: 'ED',
    title: 'CSS Color Module Level 4',
  },
  {
    property: 'color',
    url: 'https://drafts.csswg.org/css2/#propdef-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'color',
    url: 'https://drafts.csswg.org/css2/#propdef-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'color',
    url: 'https://www.w3.org/TR/2022/CRD-css-color-4-20221101/#propdef-color',
    status: 'CRD',
    title: 'CSS Color Module Level 4',
  },
  {
    property: 'color',
    url: 'https://www.w3.org/TR/2022/REC-css-color-3-20220118/#color1',
    status: 'REC',
    title: 'CSS Color Module Level 3',
  },
  {
    property: 'color-adjust',
    url: 'https://drafts.csswg.org/css-color-adjust-1/#propdef-color-adjust',
    status: 'ED',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'color-adjust',
    url: 'https://www.w3.org/TR/2022/CRD-css-color-adjust-1-20220614/#propdef-color-adjust',
    status: 'CRD',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'color-interpolation-filters',
    url: 'https://drafts.fxtf.org/filter-effects-1/#propdef-color-interpolation-filters',
    status: 'ED',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'color-interpolation-filters',
    url: 'https://www.w3.org/TR/2018/WD-filter-effects-1-20181218/#propdef-color-interpolation-filters',
    status: 'WD',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'color-scheme',
    url: 'https://drafts.csswg.org/css-color-adjust-1/#propdef-color-scheme',
    status: 'ED',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'color-scheme',
    url: 'https://www.w3.org/TR/2022/CRD-css-color-adjust-1-20220614/#propdef-color-scheme',
    status: 'CRD',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'column-count',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-count',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-count',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-count',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-fill',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-fill',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-fill',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-fill',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-gap',
    url: 'https://drafts.csswg.org/css-align/#propdef-column-gap',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'column-gap',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-column-gap',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'column-rule',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-rule',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-rule',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule-color',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-rule-color',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule-color',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-rule-color',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule-style',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-rule-style',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule-style',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-rule-style',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule-width',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-rule-width',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-rule-width',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-rule-width',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-span',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-span',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-span',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-span',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-width',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-column-width',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'column-width',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-column-width',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'columns',
    url: 'https://drafts.csswg.org/css-multicol/#propdef-columns',
    status: 'ED',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'columns',
    url: 'https://www.w3.org/TR/2021/CR-css-multicol-1-20211012/#propdef-columns',
    status: 'CR',
    title: 'CSS Multi-column Layout Module Level 1',
  },
  {
    property: 'contain',
    url: 'https://drafts.csswg.org/css-contain-1/#propdef-contain',
    status: 'ED',
    title: 'CSS Containment Module Level 1',
  },
  {
    property: 'contain',
    url: 'https://drafts.csswg.org/css-contain-2/#propdef-contain',
    status: 'ED',
    title: 'CSS Containment Module Level 2',
  },
  {
    property: 'contain',
    url: 'https://www.w3.org/TR/2022/REC-css-contain-1-20221025/#propdef-contain',
    status: 'REC',
    title: 'CSS Containment Module Level 1',
  },
  {
    property: 'contain',
    url: 'https://www.w3.org/TR/2022/WD-css-contain-2-20220917/#propdef-contain',
    status: 'WD',
    title: 'CSS Containment Module Level 2',
  },
  {
    property: 'contain-intrinsic-block-size',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-contain-intrinsic-block-size',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-block-size',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-contain-intrinsic-block-size',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-height',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-contain-intrinsic-height',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-height',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-contain-intrinsic-height',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-inline-size',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-contain-intrinsic-inline-size',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-inline-size',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-contain-intrinsic-inline-size',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-size',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-contain-intrinsic-size',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-size',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-contain-intrinsic-size',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-width',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-contain-intrinsic-width',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'contain-intrinsic-width',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-contain-intrinsic-width',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'container',
    url: 'https://drafts.csswg.org/css-contain-3/#propdef-container',
    status: 'ED',
    title: 'CSS Containment Module Level 3',
  },
  {
    property: 'container',
    url: 'https://www.w3.org/TR/2022/WD-css-contain-3-20220818/#propdef-container',
    status: 'WD',
    title: 'CSS Containment Module Level 3',
  },
  {
    property: 'container-name',
    url: 'https://drafts.csswg.org/css-contain-3/#propdef-container-name',
    status: 'ED',
    title: 'CSS Containment Module Level 3',
  },
  {
    property: 'container-name',
    url: 'https://www.w3.org/TR/2022/WD-css-contain-3-20220818/#propdef-container-name',
    status: 'WD',
    title: 'CSS Containment Module Level 3',
  },
  {
    property: 'container-type',
    url: 'https://drafts.csswg.org/css-contain-3/#propdef-container-type',
    status: 'ED',
    title: 'CSS Containment Module Level 3',
  },
  {
    property: 'container-type',
    url: 'https://www.w3.org/TR/2022/WD-css-contain-3-20220818/#propdef-container-type',
    status: 'WD',
    title: 'CSS Containment Module Level 3',
  },
  {
    property: 'content',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-content',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'content',
    url: 'https://drafts.csswg.org/css-content-3/#propdef-content',
    status: 'ED',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'content',
    url: 'https://drafts.csswg.org/css2/#propdef-content',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'content',
    url: 'https://drafts.csswg.org/css2/#propdef-content',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'content',
    url: 'https://www.w3.org/TR/2019/WD-css-content-3-20190802/#propdef-content',
    status: 'WD',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'content-visibility',
    url: 'https://drafts.csswg.org/css-contain-2/#propdef-content-visibility',
    status: 'ED',
    title: 'CSS Containment Module Level 2',
  },
  {
    property: 'content-visibility',
    url: 'https://www.w3.org/TR/2022/WD-css-contain-2-20220917/#propdef-content-visibility',
    status: 'WD',
    title: 'CSS Containment Module Level 2',
  },
  {
    property: 'continue',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-continue',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'continue',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-continue',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'continue',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-continue',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'counter-increment',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-counter-increment',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'counter-increment',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-counter-increment',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'counter-increment',
    url: 'https://drafts.csswg.org/css2/#propdef-counter-increment',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'counter-increment',
    url: 'https://drafts.csswg.org/css2/#propdef-counter-increment',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'counter-increment',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-counter-increment',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'counter-reset',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-counter-reset',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'counter-reset',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-counter-reset',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'counter-reset',
    url: 'https://drafts.csswg.org/css2/#propdef-counter-reset',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'counter-reset',
    url: 'https://drafts.csswg.org/css2/#propdef-counter-reset',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'counter-reset',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-counter-reset',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'counter-set',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-counter-set',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'counter-set',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-counter-set',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'cue',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-cue',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'cue',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-cue',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'cue',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-cue',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'cue-after',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-cue-after',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'cue-after',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-cue-after',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'cue-after',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-cue-after',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'cue-before',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-cue-before',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'cue-before',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-cue-before',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'cue-before',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-cue-before',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'cursor',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/ui.html#propdef-cursor',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'cursor',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-cursor',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'cursor',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-cursor',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'cursor',
    url: 'https://drafts.csswg.org/css2/#propdef-cursor',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'cursor',
    url: 'https://drafts.csswg.org/css2/#propdef-cursor',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'cursor',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-cursor',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'cursor',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-cursor',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'direction',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-direction',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'direction',
    url: 'https://drafts.csswg.org/css-writing-modes-3/#propdef-direction',
    status: 'ED',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'direction',
    url: 'https://drafts.csswg.org/css-writing-modes-4/#propdef-direction',
    status: 'ED',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'direction',
    url: 'https://drafts.csswg.org/css2/#propdef-direction',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'direction',
    url: 'https://drafts.csswg.org/css2/#propdef-direction',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'direction',
    url: 'https://www.w3.org/TR/2019/CR-css-writing-modes-4-20190730/#propdef-direction',
    status: 'CR',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'direction',
    url: 'https://www.w3.org/TR/2019/REC-css-writing-modes-3-20191210/#propdef-direction',
    status: 'REC',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'display',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-display',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'display',
    url: 'https://drafts.csswg.org/css-display/#propdef-display',
    status: 'ED',
    title: 'CSS Display Module Level 3',
  },
  {
    property: 'display',
    url: 'https://drafts.csswg.org/css2/#propdef-display',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'display',
    url: 'https://drafts.csswg.org/css2/#propdef-display',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'display',
    url: 'https://www.w3.org/TR/2022/CRD-css-display-3-20221118/#propdef-display',
    status: 'CRD',
    title: 'CSS Display Module Level 3',
  },
  {
    property: 'dominant-baseline',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-dominant-baseline',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'dominant-baseline',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-dominant-baseline',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'elevation',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-elevation',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'empty-cells',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/tables.html#propdef-empty-cells',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'empty-cells',
    url: 'https://drafts.csswg.org/css-tables-3/#propdef-empty-cells',
    status: 'ED',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'empty-cells',
    url: 'https://drafts.csswg.org/css2/#propdef-empty-cells',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'empty-cells',
    url: 'https://drafts.csswg.org/css2/#propdef-empty-cells',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'empty-cells',
    url: 'https://www.w3.org/TR/2019/WD-css-tables-3-20190727/#propdef-empty-cells',
    status: 'WD',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'fill',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-break',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-break',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-break',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-break',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-color',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-color',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-color',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-color',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-image',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-image',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-image',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-image',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-opacity',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-opacity',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-opacity',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-opacity',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-origin',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-origin',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-origin',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-origin',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-position',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-position',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-position',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-position',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-repeat',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-repeat',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-repeat',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-repeat',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-rule',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-rule',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-rule',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-rule',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-size',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-fill-size',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'fill-size',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-fill-size',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'filter',
    url: 'https://drafts.fxtf.org/filter-effects-1/#propdef-filter',
    status: 'ED',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'filter',
    url: 'https://www.w3.org/TR/2018/WD-filter-effects-1-20181218/#propdef-filter',
    status: 'WD',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'flex',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-basis',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex-basis',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-basis',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex-basis',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-direction',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex-direction',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-direction',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex-direction',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-flow',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex-flow',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-flow',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex-flow',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-grow',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex-grow',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-grow',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex-grow',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-shrink',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex-shrink',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-shrink',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex-shrink',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-wrap',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-flex-wrap',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'flex-wrap',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-flex-wrap',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'float',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-float',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'float',
    url: 'https://drafts.csswg.org/css-page-floats/#propdef-float',
    status: 'ED',
    title: 'CSS Page Floats',
  },
  {
    property: 'float',
    url: 'https://drafts.csswg.org/css2/#propdef-float',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'float',
    url: 'https://drafts.csswg.org/css2/#propdef-float',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'float',
    url: 'https://www.w3.org/TR/2015/WD-css-page-floats-3-20150915/#propdef-float',
    status: 'FPWD',
    title: 'CSS Page Floats',
  },
  {
    property: 'float-defer',
    url: 'https://drafts.csswg.org/css-page-floats/#propdef-float-defer',
    status: 'ED',
    title: 'CSS Page Floats',
  },
  {
    property: 'float-defer',
    url: 'https://www.w3.org/TR/2015/WD-css-page-floats-3-20150915/#propdef-float-defer',
    status: 'FPWD',
    title: 'CSS Page Floats',
  },
  {
    property: 'float-offset',
    url: 'https://drafts.csswg.org/css-page-floats/#propdef-float-offset',
    status: 'ED',
    title: 'CSS Page Floats',
  },
  {
    property: 'float-offset',
    url: 'https://www.w3.org/TR/2015/WD-css-page-floats-3-20150915/#propdef-float-offset',
    status: 'FPWD',
    title: 'CSS Page Floats',
  },
  {
    property: 'float-reference',
    url: 'https://drafts.csswg.org/css-page-floats/#propdef-float-reference',
    status: 'ED',
    title: 'CSS Page Floats',
  },
  {
    property: 'float-reference',
    url: 'https://www.w3.org/TR/2015/WD-css-page-floats-3-20150915/#propdef-float-reference',
    status: 'FPWD',
    title: 'CSS Page Floats',
  },
  {
    property: 'flood-color',
    url: 'https://drafts.fxtf.org/filter-effects-1/#propdef-flood-color',
    status: 'ED',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'flood-color',
    url: 'https://www.w3.org/TR/2018/WD-filter-effects-1-20181218/#propdef-flood-color',
    status: 'WD',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'flood-opacity',
    url: 'https://drafts.fxtf.org/filter-effects-1/#propdef-flood-opacity',
    status: 'ED',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'flood-opacity',
    url: 'https://www.w3.org/TR/2018/WD-filter-effects-1-20181218/#propdef-flood-opacity',
    status: 'WD',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'flow',
    url: 'https://drafts.csswg.org/css-template/#flow0',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'flow',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#flow0',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'flow-from',
    url: 'https://drafts.csswg.org/css-regions/#propdef-flow-from',
    status: 'ED',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'flow-from',
    url: 'https://www.w3.org/TR/2014/WD-css-regions-1-20141009/#propdef-flow-from',
    status: 'WD',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'flow-into',
    url: 'https://drafts.csswg.org/css-regions/#propdef-flow-into',
    status: 'ED',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'flow-into',
    url: 'https://www.w3.org/TR/2014/WD-css-regions-1-20141009/#propdef-flow-into',
    status: 'WD',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'font',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/fonts.html#propdef-font',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'font',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font',
    url: 'https://drafts.csswg.org/css2/#propdef-font',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'font',
    url: 'https://drafts.csswg.org/css2/#propdef-font',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'font',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-family',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/fonts.html#propdef-font-family',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'font-family',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-family',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-family',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-family',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-family',
    url: 'https://drafts.csswg.org/css2/#propdef-font-family',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'font-family',
    url: 'https://drafts.csswg.org/css2/#propdef-font-family',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'font-family',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-family',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-family',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-family',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-feature-settings',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-feature-settings',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-feature-settings',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-feature-settings',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-feature-settings',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-feature-settings',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-feature-settings',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-feature-settings',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-kerning',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-kerning',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-kerning',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-kerning',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-kerning',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-kerning',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-kerning',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-kerning',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-language-override',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-language-override',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-language-override',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-language-override',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-optical-sizing',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-optical-sizing',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-optical-sizing',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-optical-sizing',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-palette',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-palette',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-palette',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-palette',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-size',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/fonts.html#propdef-font-size',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'font-size',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-size',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-size',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-size',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-size',
    url: 'https://drafts.csswg.org/css2/#propdef-font-size',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'font-size',
    url: 'https://drafts.csswg.org/css2/#propdef-font-size',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'font-size',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-size',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-size',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-size',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-size-adjust',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-size-adjust',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-size-adjust',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-size-adjust',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-size-adjust',
    url: 'https://drafts.csswg.org/css-fonts-5/#propdef-font-size-adjust',
    status: 'ED',
    title: 'CSS Fonts Module Level 5',
  },
  {
    property: 'font-size-adjust',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-size-adjust',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-size-adjust',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-size-adjust',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-size-adjust',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-5-20211221/#propdef-font-size-adjust',
    status: 'WD',
    title: 'CSS Fonts Module Level 5',
  },
  {
    property: 'font-stretch',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-stretch',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-stretch',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-stretch',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-stretch',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-stretch',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-stretch',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-stretch',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/fonts.html#propdef-font-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'font-style',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-style',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-style',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-style',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-style',
    url: 'https://drafts.csswg.org/css2/#propdef-font-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'font-style',
    url: 'https://drafts.csswg.org/css2/#propdef-font-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'font-style',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-style',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-style',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-style',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-synthesis',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-synthesis',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-synthesis',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-synthesis',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-synthesis',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-synthesis',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis-small-caps',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-synthesis-small-caps',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis-small-caps',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-synthesis-small-caps',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis-style',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-synthesis-style',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis-style',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-synthesis-style',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis-weight',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-synthesis-weight',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-synthesis-weight',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-synthesis-weight',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/fonts.html#propdef-font-variant',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'font-variant',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-variant',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant',
    url: 'https://drafts.csswg.org/css2/#propdef-font-variant',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'font-variant',
    url: 'https://drafts.csswg.org/css2/#propdef-font-variant',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'font-variant',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-variant',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-alternates',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-alternates',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-alternates',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-alternates',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-caps',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-variant-caps',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-caps',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-caps',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-caps',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-variant-caps',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-caps',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-caps',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-east-asian',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-variant-east-asian',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-east-asian',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-east-asian',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-east-asian',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-variant-east-asian',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-east-asian',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-east-asian',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-emoji',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-emoji',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-emoji',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-emoji',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-ligatures',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-variant-ligatures',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-ligatures',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-ligatures',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-ligatures',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-variant-ligatures',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-ligatures',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-ligatures',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-numeric',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-variant-numeric',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-numeric',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-numeric',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-numeric',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-variant-numeric',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-numeric',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-numeric',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-position',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-variant-position',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-position',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variant-position',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variant-position',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-variant-position',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-variant-position',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variant-position',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variation-settings',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-variation-settings',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-variation-settings',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-variation-settings',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-weight',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/fonts.html#propdef-font-weight',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'font-weight',
    url: 'https://drafts.csswg.org/css-fonts-3/#propdef-font-weight',
    status: 'ED',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-weight',
    url: 'https://drafts.csswg.org/css-fonts-4/#propdef-font-weight',
    status: 'ED',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'font-weight',
    url: 'https://drafts.csswg.org/css2/#propdef-font-weight',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'font-weight',
    url: 'https://drafts.csswg.org/css2/#propdef-font-weight',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'font-weight',
    url: 'https://www.w3.org/TR/2018/REC-css-fonts-3-20180920/#propdef-font-weight',
    status: 'REC',
    title: 'CSS Fonts Module Level 3',
  },
  {
    property: 'font-weight',
    url: 'https://www.w3.org/TR/2021/WD-css-fonts-4-20211221/#propdef-font-weight',
    status: 'WD',
    title: 'CSS Fonts Module Level 4',
  },
  {
    property: 'footnote-display',
    url: 'https://drafts.csswg.org/css-gcpm/#propdef-footnote-display',
    status: 'ED',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'footnote-display',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-footnote-display',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'footnote-policy',
    url: 'https://drafts.csswg.org/css-gcpm/#propdef-footnote-policy',
    status: 'ED',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'footnote-policy',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-footnote-policy',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'forced-color-adjust',
    url: 'https://drafts.csswg.org/css-color-adjust-1/#propdef-forced-color-adjust',
    status: 'ED',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'forced-color-adjust',
    url: 'https://www.w3.org/TR/2022/CRD-css-color-adjust-1-20220614/#propdef-forced-color-adjust',
    status: 'CRD',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'gap',
    url: 'https://drafts.csswg.org/css-align/#propdef-gap',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'gap',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-gap',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'glyph-orientation-vertical',
    url: 'https://drafts.csswg.org/css-writing-modes-3/#propdef-glyph-orientation-vertical',
    status: 'ED',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'glyph-orientation-vertical',
    url: 'https://drafts.csswg.org/css-writing-modes-4/#propdef-glyph-orientation-vertical',
    status: 'ED',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'glyph-orientation-vertical',
    url: 'https://www.w3.org/TR/2019/CR-css-writing-modes-4-20190730/#propdef-glyph-orientation-vertical',
    status: 'CR',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'glyph-orientation-vertical',
    url: 'https://www.w3.org/TR/2019/REC-css-writing-modes-3-20191210/#propdef-glyph-orientation-vertical',
    status: 'REC',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'grid',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid',
    url: 'https://drafts.csswg.org/css-template/#grid',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#grid',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-area',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-area',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-area',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-area',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-area',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-area',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-area',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-area',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-auto-columns',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-auto-columns',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-auto-columns',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-auto-columns',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-auto-columns',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-auto-columns',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-auto-columns',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-auto-columns',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-auto-flow',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-auto-flow',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-auto-flow',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-auto-flow',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-auto-flow',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-auto-flow',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-auto-flow',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-auto-flow',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-auto-rows',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-auto-rows',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-auto-rows',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-auto-rows',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-auto-rows',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-auto-rows',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-auto-rows',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-auto-rows',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-column',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-column',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-column',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-column',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-column',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-column',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-column',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-column',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-column-end',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-column-end',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-column-end',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-column-end',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-column-end',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-column-end',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-column-end',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-column-end',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-column-start',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-column-start',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-column-start',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-column-start',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-column-start',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-column-start',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-column-start',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-column-start',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-row',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-row',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-row',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-row',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-row',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-row',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-row',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-row',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-row-end',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-row-end',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-row-end',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-row-end',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-row-end',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-row-end',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-row-end',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-row-end',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-row-start',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-row-start',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-row-start',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-row-start',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-row-start',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-row-start',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-row-start',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-row-start',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-template',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-template',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template',
    url: 'https://drafts.csswg.org/css-template/#grid-template0',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#grid-template0',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-template',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-template',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template-areas',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-template-areas',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template-areas',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-template-areas',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template-areas',
    url: 'https://drafts.csswg.org/css-template/#grid-template-areas',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template-areas',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#grid-template-areas',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template-areas',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-template-areas',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template-areas',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-template-areas',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template-columns',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-template-columns',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template-columns',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-template-columns',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template-columns',
    url: 'https://drafts.csswg.org/css-template/#grid-template-columns',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template-columns',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#grid-template-columns',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template-columns',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-template-columns',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template-columns',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-template-columns',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template-rows',
    url: 'https://drafts.csswg.org/css-grid-2/#propdef-grid-template-rows',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'grid-template-rows',
    url: 'https://drafts.csswg.org/css-grid/#propdef-grid-template-rows',
    status: 'ED',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template-rows',
    url: 'https://drafts.csswg.org/css-template/#grid-template-rows',
    status: 'ED',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template-rows',
    url: 'https://www.w3.org/TR/2015/NOTE-css-template-3-20150326/#grid-template-rows',
    status: 'NOTE',
    title: 'CSS Template Layout Module',
  },
  {
    property: 'grid-template-rows',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-1-20201218/#propdef-grid-template-rows',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 1',
  },
  {
    property: 'grid-template-rows',
    url: 'https://www.w3.org/TR/2020/CRD-css-grid-2-20201218/#propdef-grid-template-rows',
    status: 'CRD',
    title: 'CSS Grid Layout Module Level 2',
  },
  {
    property: 'hanging-punctuation',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-hanging-punctuation',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'hanging-punctuation',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hanging-punctuation',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hanging-punctuation',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-hanging-punctuation',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'hanging-punctuation',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hanging-punctuation',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'height',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-height',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'height',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-height',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'height',
    url: 'https://drafts.csswg.org/css2/#propdef-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'height',
    url: 'https://drafts.csswg.org/css2/#propdef-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'height',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-height',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'hyphenate-character',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hyphenate-character',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-character',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hyphenate-character',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-chars',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hyphenate-limit-chars',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-chars',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hyphenate-limit-chars',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-last',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hyphenate-limit-last',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-last',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hyphenate-limit-last',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-lines',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hyphenate-limit-lines',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-lines',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hyphenate-limit-lines',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-zone',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hyphenate-limit-zone',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphenate-limit-zone',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hyphenate-limit-zone',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphens',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-hyphens',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'hyphens',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-hyphens',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'hyphens',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-hyphens',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'hyphens',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-hyphens',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'image-orientation',
    url: 'https://drafts.csswg.org/css-images-3/#propdef-image-orientation',
    status: 'ED',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'image-orientation',
    url: 'https://www.w3.org/TR/2020/CRD-css-images-3-20201217/#propdef-image-orientation',
    status: 'CRD',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'image-rendering',
    url: 'https://drafts.csswg.org/css-images-3/#propdef-image-rendering',
    status: 'ED',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'image-rendering',
    url: 'https://www.w3.org/TR/2020/CRD-css-images-3-20201217/#propdef-image-rendering',
    status: 'CRD',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'image-resolution',
    url: 'https://drafts.csswg.org/css-images-4/#propdef-image-resolution',
    status: 'ED',
    title: 'CSS Images Module Level 4',
  },
  {
    property: 'image-resolution',
    url: 'https://www.w3.org/TR/2023/WD-css-images-4-20230217/#propdef-image-resolution',
    status: 'WD',
    title: 'CSS Images Module Level 4',
  },
  {
    property: 'initial-letter',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-initial-letter',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'initial-letter',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-initial-letter',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'initial-letter-align',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-initial-letter-align',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'initial-letter-align',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-initial-letter-align',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'initial-letter-wrap',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-initial-letter-wrap',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'initial-letter-wrap',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-initial-letter-wrap',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'inline-size',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inline-size',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inline-size',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inline-size',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inline-sizing',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-inline-sizing',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'inline-sizing',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-inline-sizing',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'input-security',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-input-security',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'inset',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-block',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset-block',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-block',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset-block',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-block',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset-block',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-block',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset-block',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-block-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset-block-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-block-end',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset-block-end',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-block-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset-block-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-block-end',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset-block-end',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-block-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset-block-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-block-start',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset-block-start',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-block-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset-block-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-block-start',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset-block-start',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-inline',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset-inline',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-inline',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset-inline',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-inline',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset-inline',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-inline',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset-inline',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-inline-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset-inline-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-inline-end',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset-inline-end',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-inline-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset-inline-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-inline-end',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset-inline-end',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-inline-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-inset-inline-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-inline-start',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-inset-inline-start',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'inset-inline-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-inset-inline-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'inset-inline-start',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-inset-inline-start',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'isolation',
    url: 'https://drafts.fxtf.org/compositing-1/#propdef-isolation',
    status: 'ED',
    title: 'Compositing and Blending Level 1',
  },
  {
    property: 'isolation',
    url: 'https://www.w3.org/TR/2015/CR-compositing-1-20150113/#propdef-isolation',
    status: 'CR',
    title: 'Compositing and Blending Level 1',
  },
  {
    property: 'justify-content',
    url: 'https://drafts.csswg.org/css-align/#propdef-justify-content',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'justify-content',
    url: 'https://drafts.csswg.org/css-flexbox-1/#propdef-justify-content',
    status: 'ED',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'justify-content',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-justify-content',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'justify-content',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-justify-content',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'justify-items',
    url: 'https://drafts.csswg.org/css-align/#propdef-justify-items',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'justify-items',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-justify-items',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'justify-self',
    url: 'https://drafts.csswg.org/css-align/#propdef-justify-self',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'justify-self',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-justify-self',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'leading-trim',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-leading-trim',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'leading-trim',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-leading-trim',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'left',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-left',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'left',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-left',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'left',
    url: 'https://drafts.csswg.org/css2/#propdef-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'left',
    url: 'https://drafts.csswg.org/css2/#propdef-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'left',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-left',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'letter-spacing',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-letter-spacing',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'letter-spacing',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-letter-spacing',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'letter-spacing',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-letter-spacing',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'letter-spacing',
    url: 'https://drafts.csswg.org/css2/#propdef-letter-spacing',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'letter-spacing',
    url: 'https://drafts.csswg.org/css2/#propdef-letter-spacing',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'letter-spacing',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-letter-spacing',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'letter-spacing',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-letter-spacing',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'lighting-color',
    url: 'https://drafts.fxtf.org/filter-effects-1/#propdef-lighting-color',
    status: 'ED',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'lighting-color',
    url: 'https://www.w3.org/TR/2018/WD-filter-effects-1-20181218/#propdef-lighting-color',
    status: 'WD',
    title: 'Filter Effects Module Level 1',
  },
  {
    property: 'line-break',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-line-break',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'line-break',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-line-break',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'line-break',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-line-break',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'line-break',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-line-break',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'line-clamp',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-line-clamp',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'line-clamp',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-line-clamp',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'line-grid',
    url: 'https://drafts.csswg.org/css-line-grid/#propdef-line-grid',
    status: 'ED',
    title: 'CSS Line Grid Module Level 1',
  },
  {
    property: 'line-grid',
    url: 'https://www.w3.org/TR/2014/WD-css-line-grid-1-20140916/#propdef-line-grid',
    status: 'WD',
    title: 'CSS Line Grid Module Level 1',
  },
  {
    property: 'line-height',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-line-height',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'line-height',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-line-height',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'line-height',
    url: 'https://drafts.csswg.org/css2/#propdef-line-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'line-height',
    url: 'https://drafts.csswg.org/css2/#propdef-line-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'line-height',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-line-height',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'line-height-step',
    url: 'https://drafts.csswg.org/css-rhythm/#propdef-line-height-step',
    status: 'ED',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'line-height-step',
    url: 'https://www.w3.org/TR/2017/WD-css-rhythm-1-20170302/#propdef-line-height-step',
    status: 'FPWD',
    title: 'CSS Rhythmic Sizing',
  },
  {
    property: 'line-padding',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-line-padding',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'line-padding',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-line-padding',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'line-snap',
    url: 'https://drafts.csswg.org/css-line-grid/#propdef-line-snap',
    status: 'ED',
    title: 'CSS Line Grid Module Level 1',
  },
  {
    property: 'line-snap',
    url: 'https://www.w3.org/TR/2014/WD-css-line-grid-1-20140916/#propdef-line-snap',
    status: 'WD',
    title: 'CSS Line Grid Module Level 1',
  },
  {
    property: 'list-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-list-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'list-style',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-list-style',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'list-style',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'list-style',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-list-style',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style-image',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-list-style-image',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'list-style-image',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-list-style-image',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style-image',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style-image',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'list-style-image',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style-image',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'list-style-image',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-list-style-image',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style-position',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-list-style-position',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'list-style-position',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-list-style-position',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style-position',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style-position',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'list-style-position',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style-position',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'list-style-position',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-list-style-position',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style-type',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-list-style-type',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'list-style-type',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-list-style-type',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'list-style-type',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style-type',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'list-style-type',
    url: 'https://drafts.csswg.org/css2/#propdef-list-style-type',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'list-style-type',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-list-style-type',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'margin',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-margin',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'margin',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-margin',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-margin',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin',
    url: 'https://drafts.csswg.org/css2/#propdef-margin',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'margin',
    url: 'https://drafts.csswg.org/css2/#propdef-margin',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'margin',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-margin',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-margin',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-block',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-margin-block',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-block',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-margin-block',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-block-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-margin-block-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-block-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-margin-block-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-block-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-margin-block-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-block-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-margin-block-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-bottom',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-margin-bottom',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'margin-bottom',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-margin-bottom',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-bottom',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-margin-bottom',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'margin-bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'margin-bottom',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-margin-bottom',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-bottom',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-margin-bottom',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-break',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-margin-break',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'margin-break',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-margin-break',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'margin-inline',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-margin-inline',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-inline',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-margin-inline',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-inline-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-margin-inline-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-inline-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-margin-inline-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-inline-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-margin-inline-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-inline-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-margin-inline-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'margin-left',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-margin-left',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'margin-left',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-margin-left',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-left',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-margin-left',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-left',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'margin-left',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'margin-left',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-margin-left',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-left',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-margin-left',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-right',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-margin-right',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'margin-right',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-margin-right',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-right',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-margin-right',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-right',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'margin-right',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'margin-right',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-margin-right',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-right',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-margin-right',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-top',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-margin-top',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'margin-top',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-margin-top',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-top',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-margin-top',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-top',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'margin-top',
    url: 'https://drafts.csswg.org/css2/#propdef-margin-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'margin-top',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-margin-top',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-top',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-margin-top',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'margin-trim',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-margin-trim',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'margin-trim',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-margin-trim',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'marker',
    url: 'https://svgwg.org/specs/markers/#MarkerProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-end',
    url: 'https://svgwg.org/specs/markers/#MarkerEndProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-end',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerEndProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-knockout-left',
    url: 'https://svgwg.org/specs/markers/#MarkerKnockoutLeftProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-knockout-left',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerKnockoutLeftProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-knockout-right',
    url: 'https://svgwg.org/specs/markers/#MarkerKnockoutRightProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-knockout-right',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerKnockoutRightProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-mid',
    url: 'https://svgwg.org/specs/markers/#MarkerMidProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-mid',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerMidProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-pattern',
    url: 'https://svgwg.org/specs/markers/#MarkerPatternProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-pattern',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerPatternProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-segment',
    url: 'https://svgwg.org/specs/markers/#MarkerSegmentProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-segment',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerSegmentProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'marker-side',
    url: 'https://drafts.csswg.org/css-lists-3/#propdef-marker-side',
    status: 'ED',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'marker-side',
    url: 'https://www.w3.org/TR/2020/WD-css-lists-3-20201117/#propdef-marker-side',
    status: 'WD',
    title: 'CSS Lists and Counters Module Level 3',
  },
  {
    property: 'marker-start',
    url: 'https://svgwg.org/specs/markers/#MarkerStartProperty',
    status: 'ED',
    title: 'SVG Markers',
  },
  {
    property: 'marker-start',
    url: 'https://www.w3.org/TR/2015/WD-svg-markers-20150409/#MarkerStartProperty',
    status: 'FPWD',
    title: 'SVG Markers',
  },
  {
    property: 'mask',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-mode',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border-mode',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-mode',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border-mode',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-outset',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border-outset',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-outset',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border-outset',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-repeat',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border-repeat',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-repeat',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border-repeat',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-slice',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border-slice',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-slice',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border-slice',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-source',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border-source',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-source',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border-source',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-width',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-border-width',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-border-width',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-border-width',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-clip',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-clip',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-clip',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-clip',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-composite',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-composite',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-composite',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-composite',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-image',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-image',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-image',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-image',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-mode',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-mode',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-mode',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-mode',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-origin',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-origin',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-origin',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-origin',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-position',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-position',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-position',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-position',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-repeat',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-repeat',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-repeat',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-repeat',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-size',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-size',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-size',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-size',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-type',
    url: 'https://drafts.fxtf.org/css-masking-1/#propdef-mask-type',
    status: 'ED',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'mask-type',
    url: 'https://www.w3.org/TR/2021/CRD-css-masking-1-20210805/#propdef-mask-type',
    status: 'CRD',
    title: 'CSS Masking Module Level 1',
  },
  {
    property: 'max-block-size',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-max-block-size',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'max-block-size',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-max-block-size',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'max-height',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-max-height',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'max-height',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-max-height',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'max-height',
    url: 'https://drafts.csswg.org/css2/#propdef-max-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'max-height',
    url: 'https://drafts.csswg.org/css2/#propdef-max-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'max-height',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-max-height',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'max-inline-size',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-max-inline-size',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'max-inline-size',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-max-inline-size',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'max-lines',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-max-lines',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'max-lines',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-max-lines',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'max-lines',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-max-lines',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'max-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-max-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'max-width',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-max-width',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'max-width',
    url: 'https://drafts.csswg.org/css2/#propdef-max-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'max-width',
    url: 'https://drafts.csswg.org/css2/#propdef-max-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'max-width',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-max-width',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'min-block-size',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-min-block-size',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'min-block-size',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-min-block-size',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'min-height',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-min-height',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'min-height',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-min-height',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'min-height',
    url: 'https://drafts.csswg.org/css2/#propdef-min-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'min-height',
    url: 'https://drafts.csswg.org/css2/#propdef-min-height',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'min-height',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-min-height',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'min-inline-size',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-min-inline-size',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'min-inline-size',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-min-inline-size',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'min-intrinsic-sizing',
    url: 'https://drafts.csswg.org/css-sizing-4/#propdef-min-intrinsic-sizing',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'min-intrinsic-sizing',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-4-20210520/#propdef-min-intrinsic-sizing',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 4',
  },
  {
    property: 'min-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-min-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'min-width',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-min-width',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'min-width',
    url: 'https://drafts.csswg.org/css2/#propdef-min-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'min-width',
    url: 'https://drafts.csswg.org/css2/#propdef-min-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'min-width',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-min-width',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'mix-blend-mode',
    url: 'https://drafts.fxtf.org/compositing-1/#propdef-mix-blend-mode',
    status: 'ED',
    title: 'Compositing and Blending Level 1',
  },
  {
    property: 'mix-blend-mode',
    url: 'https://www.w3.org/TR/2015/CR-compositing-1-20150113/#propdef-mix-blend-mode',
    status: 'CR',
    title: 'Compositing and Blending Level 1',
  },
  {
    property: 'nav-down',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-nav-down',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-down',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-nav-down',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-left',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-nav-left',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-left',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-nav-left',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-right',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-nav-right',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-right',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-nav-right',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-up',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-nav-up',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'nav-up',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-nav-up',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'object-fit',
    url: 'https://drafts.csswg.org/css-images-3/#propdef-object-fit',
    status: 'ED',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'object-fit',
    url: 'https://drafts.csswg.org/css-images-4/#propdef-object-fit',
    status: 'ED',
    title: 'CSS Images Module Level 4',
  },
  {
    property: 'object-fit',
    url: 'https://www.w3.org/TR/2020/CRD-css-images-3-20201217/#propdef-object-fit',
    status: 'CRD',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'object-fit',
    url: 'https://www.w3.org/TR/2023/WD-css-images-4-20230217/#propdef-object-fit',
    status: 'WD',
    title: 'CSS Images Module Level 4',
  },
  {
    property: 'object-position',
    url: 'https://drafts.csswg.org/css-images-3/#propdef-object-position',
    status: 'ED',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'object-position',
    url: 'https://www.w3.org/TR/2020/CRD-css-images-3-20201217/#propdef-object-position',
    status: 'CRD',
    title: 'CSS Images Module Level 3',
  },
  {
    property: 'offset',
    url: 'https://drafts.fxtf.org/motion-1/#propdef-offset',
    status: 'ED',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset',
    url: 'https://www.w3.org/TR/2018/WD-motion-1-20181218/#propdef-offset',
    status: 'WD',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-anchor',
    url: 'https://drafts.fxtf.org/motion-1/#propdef-offset-anchor',
    status: 'ED',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-anchor',
    url: 'https://www.w3.org/TR/2018/WD-motion-1-20181218/#propdef-offset-anchor',
    status: 'WD',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-distance',
    url: 'https://drafts.fxtf.org/motion-1/#propdef-offset-distance',
    status: 'ED',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-distance',
    url: 'https://www.w3.org/TR/2018/WD-motion-1-20181218/#propdef-offset-distance',
    status: 'WD',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-path',
    url: 'https://drafts.fxtf.org/motion-1/#propdef-offset-path',
    status: 'ED',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-path',
    url: 'https://www.w3.org/TR/2018/WD-motion-1-20181218/#propdef-offset-path',
    status: 'WD',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-position',
    url: 'https://drafts.fxtf.org/motion-1/#propdef-offset-position',
    status: 'ED',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-position',
    url: 'https://www.w3.org/TR/2018/WD-motion-1-20181218/#propdef-offset-position',
    status: 'WD',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-rotate',
    url: 'https://drafts.fxtf.org/motion-1/#propdef-offset-rotate',
    status: 'ED',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'offset-rotate',
    url: 'https://www.w3.org/TR/2018/WD-motion-1-20181218/#propdef-offset-rotate',
    status: 'WD',
    title: 'Motion Path Module Level 1',
  },
  {
    property: 'opacity',
    url: 'https://drafts.csswg.org/css-color-3/#color01',
    status: 'ED',
    title: 'CSS Color Module Level 3',
  },
  {
    property: 'opacity',
    url: 'https://drafts.csswg.org/css-color-3/#opacity',
    status: 'ED',
    title: 'CSS Color Module Level 3',
  },
  {
    property: 'opacity',
    url: 'https://drafts.csswg.org/css-color/#propdef-opacity',
    status: 'ED',
    title: 'CSS Color Module Level 4',
  },
  {
    property: 'opacity',
    url: 'https://www.w3.org/TR/2022/CRD-css-color-4-20221101/#propdef-opacity',
    status: 'CRD',
    title: 'CSS Color Module Level 4',
  },
  {
    property: 'opacity',
    url: 'https://www.w3.org/TR/2022/REC-css-color-3-20220118/#opacity',
    status: 'REC',
    title: 'CSS Color Module Level 3',
  },
  {
    property: 'order',
    url: 'https://drafts.csswg.org/css-display/#propdef-order',
    status: 'ED',
    title: 'CSS Display Module Level 3',
  },
  {
    property: 'order',
    url: 'https://www.w3.org/TR/2018/CR-css-flexbox-1-20181119/#propdef-order',
    status: 'CR',
    title: 'CSS Flexible Box Layout Module Level 1',
  },
  {
    property: 'order',
    url: 'https://www.w3.org/TR/2022/CRD-css-display-3-20221118/#propdef-order',
    status: 'CRD',
    title: 'CSS Display Module Level 3',
  },
  {
    property: 'orphans',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/page.html#propdef-orphans',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'orphans',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-orphans',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'orphans',
    url: 'https://drafts.csswg.org/css-break/#propdef-orphans',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'orphans',
    url: 'https://drafts.csswg.org/css2/#propdef-orphans',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'orphans',
    url: 'https://drafts.csswg.org/css2/#propdef-orphans',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'orphans',
    url: 'https://www.w3.org/TR/2018/CR-css-break-3-20181204/#propdef-orphans',
    status: 'CR',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'orphans',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-orphans',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'outline',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/ui.html#propdef-outline',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'outline',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-outline',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-outline',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline',
    url: 'https://drafts.csswg.org/css2/#propdef-outline',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'outline',
    url: 'https://drafts.csswg.org/css2/#propdef-outline',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'outline',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-outline',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-outline',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-color',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/ui.html#propdef-outline-color',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'outline-color',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-outline-color',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-color',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-outline-color',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-color',
    url: 'https://drafts.csswg.org/css2/#propdef-outline-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'outline-color',
    url: 'https://drafts.csswg.org/css2/#propdef-outline-color',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'outline-color',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-outline-color',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-color',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-outline-color',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-offset',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-outline-offset',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-offset',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-outline-offset',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-offset',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-outline-offset',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-offset',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-outline-offset',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-style',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/ui.html#propdef-outline-style',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'outline-style',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-outline-style',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-style',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-outline-style',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-style',
    url: 'https://drafts.csswg.org/css2/#propdef-outline-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'outline-style',
    url: 'https://drafts.csswg.org/css2/#propdef-outline-style',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'outline-style',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-outline-style',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-style',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-outline-style',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/ui.html#propdef-outline-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'outline-width',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-outline-width',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-width',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-outline-width',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'outline-width',
    url: 'https://drafts.csswg.org/css2/#propdef-outline-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'outline-width',
    url: 'https://drafts.csswg.org/css2/#propdef-outline-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'outline-width',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-outline-width',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'outline-width',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-outline-width',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'overflow',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visufx.html#propdef-overflow',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'overflow',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-overflow',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow',
    url: 'https://drafts.csswg.org/css2/#propdef-overflow',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'overflow',
    url: 'https://drafts.csswg.org/css2/#propdef-overflow',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'overflow',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-overflow',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-anchor',
    url: 'https://drafts.csswg.org/css-scroll-anchoring/#propdef-overflow-anchor',
    status: 'ED',
    title: 'CSS Scroll Anchoring Module Level 1',
  },
  {
    property: 'overflow-anchor',
    url: 'https://www.w3.org/TR/2020/WD-css-scroll-anchoring-1-20201111/#propdef-overflow-anchor',
    status: 'WD',
    title: 'CSS Scroll Anchoring Module Level 1',
  },
  {
    property: 'overflow-block',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-overflow-block',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-block',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-overflow-block',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-clip-margin',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-overflow-clip-margin',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-clip-margin',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-overflow-clip-margin',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-clip-margin',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-block',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-block',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-block',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-block',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-block-end',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-block-end',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-block-end',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-block-end',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-block-start',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-block-start',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-block-start',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-block-start',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-bottom',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-bottom',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-bottom',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-bottom',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-inline',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-inline',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-inline',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-inline',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-inline-end',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-inline-end',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-inline-end',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-inline-end',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-inline-start',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-inline-start',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-inline-start',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-inline-start',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-left',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-left',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-left',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-left',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-right',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-right',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-right',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-right',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-top',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-overflow-clip-margin-top',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-clip-margin-top',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-overflow-clip-margin-top',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'overflow-inline',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-overflow-inline',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-inline',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-overflow-inline',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-wrap',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-overflow-wrap',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'overflow-wrap',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-overflow-wrap',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'overflow-wrap',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-overflow-wrap',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'overflow-wrap',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-overflow-wrap',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'overflow-x',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-overflow-x',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-x',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-overflow-x',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-y',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-overflow-y',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overflow-y',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-overflow-y',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'overscroll-behavior',
    url: 'https://drafts.csswg.org/css-overscroll-1/#propdef-overscroll-behavior',
    status: 'ED',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior',
    url: 'https://www.w3.org/TR/2019/WD-css-overscroll-1-20190606/#propdef-overscroll-behavior',
    status: 'FPWD',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-block',
    url: 'https://drafts.csswg.org/css-overscroll-1/#propdef-overscroll-behavior-block',
    status: 'ED',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-block',
    url: 'https://www.w3.org/TR/2019/WD-css-overscroll-1-20190606/#propdef-overscroll-behavior-block',
    status: 'FPWD',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-inline',
    url: 'https://drafts.csswg.org/css-overscroll-1/#propdef-overscroll-behavior-inline',
    status: 'ED',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-inline',
    url: 'https://www.w3.org/TR/2019/WD-css-overscroll-1-20190606/#propdef-overscroll-behavior-inline',
    status: 'FPWD',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-x',
    url: 'https://drafts.csswg.org/css-overscroll-1/#propdef-overscroll-behavior-x',
    status: 'ED',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-x',
    url: 'https://www.w3.org/TR/2019/WD-css-overscroll-1-20190606/#propdef-overscroll-behavior-x',
    status: 'FPWD',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-y',
    url: 'https://drafts.csswg.org/css-overscroll-1/#propdef-overscroll-behavior-y',
    status: 'ED',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'overscroll-behavior-y',
    url: 'https://www.w3.org/TR/2019/WD-css-overscroll-1-20190606/#propdef-overscroll-behavior-y',
    status: 'FPWD',
    title: 'CSS Overscroll Behavior Module Level 1',
  },
  {
    property: 'padding',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-padding',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'padding',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-padding',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-padding',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding',
    url: 'https://drafts.csswg.org/css2/#propdef-padding',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'padding',
    url: 'https://drafts.csswg.org/css2/#propdef-padding',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'padding',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-padding',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-padding',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-block',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-padding-block',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-block',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-padding-block',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-block-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-padding-block-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-block-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-padding-block-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-block-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-padding-block-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-block-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-padding-block-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-bottom',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-padding-bottom',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'padding-bottom',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-padding-bottom',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-bottom',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-padding-bottom',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'padding-bottom',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-bottom',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'padding-bottom',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-padding-bottom',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-bottom',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-padding-bottom',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-inline',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-padding-inline',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-inline',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-padding-inline',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-inline-end',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-padding-inline-end',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-inline-end',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-padding-inline-end',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-inline-start',
    url: 'https://drafts.csswg.org/css-logical-1/#propdef-padding-inline-start',
    status: 'ED',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-inline-start',
    url: 'https://www.w3.org/TR/2018/WD-css-logical-1-20180827/#propdef-padding-inline-start',
    status: 'WD',
    title: 'CSS Logical Properties and Values Level 1',
  },
  {
    property: 'padding-left',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-padding-left',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'padding-left',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-padding-left',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-left',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-padding-left',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-left',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'padding-left',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-left',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'padding-left',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-padding-left',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-left',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-padding-left',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-right',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-padding-right',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'padding-right',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-padding-right',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-right',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-padding-right',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-right',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'padding-right',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'padding-right',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-padding-right',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-right',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-padding-right',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-top',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/box.html#propdef-padding-top',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'padding-top',
    url: 'https://drafts.csswg.org/css-box-3/#propdef-padding-top',
    status: 'ED',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'padding-top',
    url: 'https://drafts.csswg.org/css-box-4/#propdef-padding-top',
    status: 'ED',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-top',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'padding-top',
    url: 'https://drafts.csswg.org/css2/#propdef-padding-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'padding-top',
    url: 'https://www.w3.org/TR/2022/WD-css-box-4-20221103/#propdef-padding-top',
    status: 'WD',
    title: 'CSS Box Model Module Level 4',
  },
  {
    property: 'padding-top',
    url: 'https://www.w3.org/TR/2023/PR-css-box-3-20230216/#propdef-padding-top',
    status: 'PR',
    title: 'CSS Box Model Module Level 3',
  },
  {
    property: 'page',
    url: 'https://drafts.csswg.org/css-page-3/#propdef-page',
    status: 'ED',
    title: 'CSS Paged Media Module Level 3',
  },
  {
    property: 'page',
    url: 'https://www.w3.org/TR/2018/WD-css-page-3-20181018/#propdef-page',
    status: 'WD',
    title: 'CSS Paged Media Module Level 3',
  },
  {
    property: 'page-break-after',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/page.html#propdef-page-break-after',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'page-break-after',
    url: 'https://drafts.csswg.org/css2/#propdef-page-break-after',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'page-break-after',
    url: 'https://drafts.csswg.org/css2/#propdef-page-break-after',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'page-break-before',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/page.html#propdef-page-break-before',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'page-break-before',
    url: 'https://drafts.csswg.org/css2/#propdef-page-break-before',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'page-break-before',
    url: 'https://drafts.csswg.org/css2/#propdef-page-break-before',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'page-break-inside',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/page.html#propdef-page-break-inside',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'page-break-inside',
    url: 'https://drafts.csswg.org/css2/#propdef-page-break-inside',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'page-break-inside',
    url: 'https://drafts.csswg.org/css2/#propdef-page-break-inside',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'pause',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-pause',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'pause',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-pause',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'pause',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-pause',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'pause-after',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-pause-after',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'pause-after',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-pause-after',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'pause-after',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-pause-after',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'pause-before',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-pause-before',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'pause-before',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-pause-before',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'pause-before',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-pause-before',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'perspective',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-perspective',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'perspective',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-perspective',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'perspective-origin',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-perspective-origin',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'perspective-origin',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-perspective-origin',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'pitch',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-pitch',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'pitch-range',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-pitch-range',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'place-content',
    url: 'https://drafts.csswg.org/css-align/#propdef-place-content',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'place-content',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-place-content',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'place-items',
    url: 'https://drafts.csswg.org/css-align/#propdef-place-items',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'place-items',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-place-items',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'place-self',
    url: 'https://drafts.csswg.org/css-align/#propdef-place-self',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'place-self',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-place-self',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'play-during',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-play-during',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'pointer-events',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-pointer-events',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'position',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-position',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'position',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-position',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'position',
    url: 'https://drafts.csswg.org/css2/#propdef-position',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'position',
    url: 'https://drafts.csswg.org/css2/#propdef-position',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'position',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-position',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'print-color-adjust',
    url: 'https://drafts.csswg.org/css-color-adjust-1/#propdef-print-color-adjust',
    status: 'ED',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'print-color-adjust',
    url: 'https://www.w3.org/TR/2022/CRD-css-color-adjust-1-20220614/#propdef-print-color-adjust',
    status: 'CRD',
    title: 'CSS Color Adjustment Module Level 1',
  },
  {
    property: 'property-name',
    url: 'https://drafts.csswg.org/css2/#propdef-property-name',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'property-name',
    url: 'https://drafts.csswg.org/css2/#propdef-property-name',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'quotes',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/generate.html#propdef-quotes',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'quotes',
    url: 'https://drafts.csswg.org/css-content-3/#propdef-quotes',
    status: 'ED',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'quotes',
    url: 'https://drafts.csswg.org/css2/#propdef-quotes',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'quotes',
    url: 'https://drafts.csswg.org/css2/#propdef-quotes',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'quotes',
    url: 'https://www.w3.org/TR/2019/WD-css-content-3-20190802/#propdef-quotes',
    status: 'WD',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'region-fragment',
    url: 'https://drafts.csswg.org/css-regions/#propdef-region-fragment',
    status: 'ED',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'region-fragment',
    url: 'https://www.w3.org/TR/2014/WD-css-regions-1-20141009/#propdef-region-fragment',
    status: 'WD',
    title: 'CSS Regions Module Level 1',
  },
  {
    property: 'resize',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-resize',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'resize',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-resize',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'resize',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-resize',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'resize',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-resize',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'rest',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-rest',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'rest',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-rest',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'rest-after',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-rest-after',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'rest-after',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-rest-after',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'rest-before',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-rest-before',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'rest-before',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-rest-before',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'richness',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-richness',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'right',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-right',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'right',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-right',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'right',
    url: 'https://drafts.csswg.org/css2/#propdef-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'right',
    url: 'https://drafts.csswg.org/css2/#propdef-right',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'right',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-right',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'rotate',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-rotate',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'rotate',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-rotate',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'row-gap',
    url: 'https://drafts.csswg.org/css-align/#propdef-row-gap',
    status: 'ED',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'row-gap',
    url: 'https://www.w3.org/TR/2023/WD-css-align-3-20230217/#propdef-row-gap',
    status: 'WD',
    title: 'CSS Box Alignment Module Level 3',
  },
  {
    property: 'ruby-align',
    url: 'https://drafts.csswg.org/css-ruby-1/#propdef-ruby-align',
    status: 'ED',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-align',
    url: 'https://www.w3.org/TR/2022/WD-css-ruby-1-20221231/#propdef-ruby-align',
    status: 'WD',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-merge',
    url: 'https://drafts.csswg.org/css-ruby-1/#propdef-ruby-merge',
    status: 'ED',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-merge',
    url: 'https://www.w3.org/TR/2022/WD-css-ruby-1-20221231/#propdef-ruby-merge',
    status: 'WD',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-overhang',
    url: 'https://drafts.csswg.org/css-ruby-1/#propdef-ruby-overhang',
    status: 'ED',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-overhang',
    url: 'https://www.w3.org/TR/2022/WD-css-ruby-1-20221231/#propdef-ruby-overhang',
    status: 'WD',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-position',
    url: 'https://drafts.csswg.org/css-ruby-1/#propdef-ruby-position',
    status: 'ED',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'ruby-position',
    url: 'https://www.w3.org/TR/2022/WD-css-ruby-1-20221231/#propdef-ruby-position',
    status: 'WD',
    title: 'CSS Ruby Annotation Layout Module Level 1',
  },
  {
    property: 'running',
    url: 'https://drafts.csswg.org/css-gcpm/#propdef-running',
    status: 'ED',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'running',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-running',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'scale',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-scale',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'scale',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-scale',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'scroll-behavior',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-scroll-behavior',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'scroll-behavior',
    url: 'https://www.w3.org/TR/2016/WD-cssom-view-1-20160317/#propdef-scroll-behavior',
    status: 'WD',
    title: 'CSSOM View Module',
  },
  {
    property: 'scroll-behavior',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-scroll-behavior',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'scroll-margin',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-block',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-block',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-block',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-block',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-block-end',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-block-end',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-block-end',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-block-end',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-block-start',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-block-start',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-block-start',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-block-start',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-bottom',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-bottom',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-bottom',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-bottom',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-inline',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-inline',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-inline',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-inline',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-inline-end',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-inline-end',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-inline-end',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-inline-end',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-inline-start',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-inline-start',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-inline-start',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-inline-start',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-left',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-left',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-left',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-left',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-right',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-right',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-right',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-right',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-top',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-margin-top',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-margin-top',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-margin-top',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-block',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-block',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-block',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-block',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-block-end',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-block-end',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-block-end',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-block-end',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-block-start',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-block-start',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-block-start',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-block-start',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-bottom',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-bottom',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-bottom',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-bottom',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-inline',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-inline',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-inline',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-inline',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-inline-end',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-inline-end',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-inline-end',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-inline-end',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-inline-start',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-inline-start',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-inline-start',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-inline-start',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-left',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-left',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-left',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-left',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-right',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-right',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-right',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-right',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-top',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-padding-top',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-padding-top',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-padding-top',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-snap-align',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-snap-align',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-snap-align',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-snap-align',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-snap-stop',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-snap-stop',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-snap-stop',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-snap-stop',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-snap-type',
    url: 'https://drafts.csswg.org/css-scroll-snap-1/#propdef-scroll-snap-type',
    status: 'ED',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-snap-type',
    url: 'https://www.w3.org/TR/2021/CR-css-scroll-snap-1-20210311/#propdef-scroll-snap-type',
    status: 'CR',
    title: 'CSS Scroll Snap Module Level 1',
  },
  {
    property: 'scroll-timeline',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-scroll-timeline',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'scroll-timeline',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-scroll-timeline',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'scroll-timeline-axis',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-scroll-timeline-axis',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'scroll-timeline-axis',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-scroll-timeline-axis',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'scroll-timeline-name',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-scroll-timeline-name',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'scroll-timeline-name',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-scroll-timeline-name',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'scrollbar-color',
    url: 'https://drafts.csswg.org/css-scrollbars/#propdef-scrollbar-color',
    status: 'ED',
    title: 'CSS Scrollbars Styling Module Level 1',
  },
  {
    property: 'scrollbar-color',
    url: 'https://www.w3.org/TR/2021/CR-css-scrollbars-1-20211209/#propdef-scrollbar-color',
    status: 'CR',
    title: 'CSS Scrollbars Styling Module Level 1',
  },
  {
    property: 'scrollbar-gutter',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-scrollbar-gutter',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'scrollbar-gutter',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-scrollbar-gutter',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'scrollbar-width',
    url: 'https://drafts.csswg.org/css-scrollbars/#propdef-scrollbar-width',
    status: 'ED',
    title: 'CSS Scrollbars Styling Module Level 1',
  },
  {
    property: 'scrollbar-width',
    url: 'https://www.w3.org/TR/2021/CR-css-scrollbars-1-20211209/#propdef-scrollbar-width',
    status: 'CR',
    title: 'CSS Scrollbars Styling Module Level 1',
  },
  {
    property: 'shape-image-threshold',
    url: 'https://drafts.csswg.org/css-shapes/#propdef-shape-image-threshold',
    status: 'ED',
    title: 'CSS Shapes Module Level 1',
  },
  {
    property: 'shape-image-threshold',
    url: 'https://www.w3.org/TR/2022/CRD-css-shapes-1-20221115/#propdef-shape-image-threshold',
    status: 'CRD',
    title: 'CSS Shapes Module Level 1',
  },
  {
    property: 'shape-inside',
    url: 'https://drafts.csswg.org/css-round-display/#propdef-shape-inside',
    status: 'ED',
    title: 'CSS Round Display Level 1',
  },
  {
    property: 'shape-inside',
    url: 'https://www.w3.org/TR/2016/WD-css-round-display-1-20161222/#propdef-shape-inside',
    status: 'WD',
    title: 'CSS Round Display Level 1',
  },
  {
    property: 'shape-margin',
    url: 'https://drafts.csswg.org/css-shapes/#propdef-shape-margin',
    status: 'ED',
    title: 'CSS Shapes Module Level 1',
  },
  {
    property: 'shape-margin',
    url: 'https://www.w3.org/TR/2022/CRD-css-shapes-1-20221115/#propdef-shape-margin',
    status: 'CRD',
    title: 'CSS Shapes Module Level 1',
  },
  {
    property: 'shape-outside',
    url: 'https://drafts.csswg.org/css-shapes/#propdef-shape-outside',
    status: 'ED',
    title: 'CSS Shapes Module Level 1',
  },
  {
    property: 'shape-outside',
    url: 'https://www.w3.org/TR/2022/CRD-css-shapes-1-20221115/#propdef-shape-outside',
    status: 'CRD',
    title: 'CSS Shapes Module Level 1',
  },
  {
    property: 'spatial-navigation-action',
    url: 'https://drafts.csswg.org/css-nav-1/#propdef-spatial-navigation-action',
    status: 'ED',
    title: 'CSS Spatial Navigation Level 1',
  },
  {
    property: 'spatial-navigation-action',
    url: 'https://www.w3.org/TR/2019/WD-css-nav-1-20191126/#propdef-spatial-navigation-action',
    status: 'WD',
    title: 'CSS Spatial Navigation Level 1',
  },
  {
    property: 'spatial-navigation-contain',
    url: 'https://drafts.csswg.org/css-nav-1/#propdef-spatial-navigation-contain',
    status: 'ED',
    title: 'CSS Spatial Navigation Level 1',
  },
  {
    property: 'spatial-navigation-contain',
    url: 'https://www.w3.org/TR/2019/WD-css-nav-1-20191126/#propdef-spatial-navigation-contain',
    status: 'WD',
    title: 'CSS Spatial Navigation Level 1',
  },
  {
    property: 'spatial-navigation-function',
    url: 'https://drafts.csswg.org/css-nav-1/#propdef-spatial-navigation-function',
    status: 'ED',
    title: 'CSS Spatial Navigation Level 1',
  },
  {
    property: 'spatial-navigation-function',
    url: 'https://www.w3.org/TR/2019/WD-css-nav-1-20191126/#propdef-spatial-navigation-function',
    status: 'WD',
    title: 'CSS Spatial Navigation Level 1',
  },
  {
    property: 'speak',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-speak',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'speak',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-speak',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'speak',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-speak',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'speak-as',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-speak-as',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'speak-as',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-speak-as',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'speak-header',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-speak-header',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'speak-numeral',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-speak-numeral',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'speak-punctuation',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-speak-punctuation',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'speech-rate',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-speech-rate',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'stress',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-stress',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'string-set',
    url: 'https://drafts.csswg.org/css-content-3/#propdef-string-set',
    status: 'ED',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'string-set',
    url: 'https://drafts.csswg.org/css-gcpm/#propdef-string-set',
    status: 'ED',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'string-set',
    url: 'https://www.w3.org/TR/2014/WD-css-gcpm-3-20140513/#propdef-string-set',
    status: 'WD',
    title: 'CSS Generated Content for Paged Media Module',
  },
  {
    property: 'string-set',
    url: 'https://www.w3.org/TR/2019/WD-css-content-3-20190802/#propdef-string-set',
    status: 'WD',
    title: 'CSS Generated Content Module Level 3',
  },
  {
    property: 'stroke',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke',
    url: 'https://svgwg.org/specs/strokes/#StrokeProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-align',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-align',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-align',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-align',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-alignment',
    url: 'https://svgwg.org/specs/strokes/#StrokeAlignmentProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-alignment',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeAlignmentProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-break',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-break',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-break',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-break',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-color',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-color',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-color',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-color',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dash-corner',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-dash-corner',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dash-corner',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-dash-corner',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dash-justify',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-dash-justify',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dash-justify',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-dash-justify',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dashadjust',
    url: 'https://svgwg.org/specs/strokes/#StrokeDashadjustProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dashadjust',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeDashadjustProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dasharray',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-dasharray',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dasharray',
    url: 'https://svgwg.org/specs/strokes/#StrokeDasharrayProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dasharray',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeDasharrayProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dasharray',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-dasharray',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dashcorner',
    url: 'https://svgwg.org/specs/strokes/#StrokeDashcornerProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dashcorner',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeDashcornerProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dashoffset',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-dashoffset',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-dashoffset',
    url: 'https://svgwg.org/specs/strokes/#StrokeDashoffsetProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dashoffset',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeDashoffsetProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-dashoffset',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-dashoffset',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-image',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-image',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-image',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-image',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-linecap',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-linecap',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-linecap',
    url: 'https://svgwg.org/specs/strokes/#StrokeLinecapProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-linecap',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeLinecapProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-linecap',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-linecap',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-linejoin',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-linejoin',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-linejoin',
    url: 'https://svgwg.org/specs/strokes/#StrokeLinejoinProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-linejoin',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeLinejoinProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-linejoin',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-linejoin',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-miterlimit',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-miterlimit',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-miterlimit',
    url: 'https://svgwg.org/specs/strokes/#StrokeMiterlimitProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-miterlimit',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeMiterlimitProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-miterlimit',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-miterlimit',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-opacity',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-opacity',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-opacity',
    url: 'https://svgwg.org/specs/strokes/#StrokeOpacityProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-opacity',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeOpacityProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-opacity',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-opacity',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-origin',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-origin',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-origin',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-origin',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-position',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-position',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-position',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-position',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-repeat',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-repeat',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-repeat',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-repeat',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-size',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-size',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-size',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-size',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-width',
    url: 'https://drafts.fxtf.org/fill-stroke/#propdef-stroke-width',
    status: 'ED',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'stroke-width',
    url: 'https://svgwg.org/specs/strokes/#StrokeWidthProperty',
    status: 'ED',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-width',
    url: 'https://www.w3.org/TR/2015/WD-svg-strokes-20150409/#StrokeWidthProperty',
    status: 'FPWD',
    title: 'SVG Strokes',
  },
  {
    property: 'stroke-width',
    url: 'https://www.w3.org/TR/2017/WD-fill-stroke-3-20170413/#propdef-stroke-width',
    status: 'FPWD',
    title: 'CSS Fill and Stroke Module Level 3',
  },
  {
    property: 'tab-size',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-tab-size',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'tab-size',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-tab-size',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'tab-size',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-tab-size',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'tab-size',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-tab-size',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'table-layout',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/tables.html#propdef-table-layout',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'table-layout',
    url: 'https://drafts.csswg.org/css-tables-3/#propdef-table-layout',
    status: 'ED',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'table-layout',
    url: 'https://drafts.csswg.org/css2/#propdef-table-layout',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'table-layout',
    url: 'https://drafts.csswg.org/css2/#propdef-table-layout',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'table-layout',
    url: 'https://www.w3.org/TR/2019/WD-css-tables-3-20190727/#propdef-table-layout',
    status: 'WD',
    title: 'CSS Table Module Level 3',
  },
  {
    property: 'text-align',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-text-align',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'text-align',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-text-align',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-align',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-align',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-align',
    url: 'https://drafts.csswg.org/css2/#propdef-text-align',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'text-align',
    url: 'https://drafts.csswg.org/css2/#propdef-text-align',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'text-align',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-text-align',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-align',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-align',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-align-all',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-text-align-all',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-align-all',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-align-all',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-align-all',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-text-align-all',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-align-all',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-align-all',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-align-last',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-text-align-last',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-align-last',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-align-last',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-align-last',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-text-align-last',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-align-last',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-align-last',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-autospace',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-autospace',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-autospace',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-autospace',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-combine-upright',
    url: 'https://drafts.csswg.org/css-writing-modes-3/#propdef-text-combine-upright',
    status: 'ED',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'text-combine-upright',
    url: 'https://drafts.csswg.org/css-writing-modes-4/#propdef-text-combine-upright',
    status: 'ED',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'text-combine-upright',
    url: 'https://www.w3.org/TR/2019/CR-css-writing-modes-4-20190730/#propdef-text-combine-upright',
    status: 'CR',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'text-combine-upright',
    url: 'https://www.w3.org/TR/2019/REC-css-writing-modes-3-20191210/#propdef-text-combine-upright',
    status: 'REC',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'text-decoration',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-text-decoration',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'text-decoration',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-decoration',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration',
    url: 'https://drafts.csswg.org/css2/#propdef-text-decoration',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'text-decoration',
    url: 'https://drafts.csswg.org/css2/#propdef-text-decoration',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'text-decoration',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-decoration',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-color',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-decoration-color',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration-color',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-color',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-color',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-decoration-color',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration-color',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-color',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-line',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-decoration-line',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration-line',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-line',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-line',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-decoration-line',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration-line',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-line',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-skip',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-skip',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-box',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-skip-box',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-box',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-skip-box',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-ink',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-skip-ink',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-ink',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-skip-ink',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-inset',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-skip-inset',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-self',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-skip-self',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-self',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-skip-self',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-spaces',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-skip-spaces',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-skip-spaces',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-skip-spaces',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-style',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-decoration-style',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration-style',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-style',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-style',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-decoration-style',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-decoration-style',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-style',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-thickness',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-thickness',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-thickness',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-decoration-thickness',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-decoration-trim',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-decoration-trim',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-edge',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-text-edge',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'text-edge',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-text-edge',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'text-emphasis',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-emphasis',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-emphasis',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-emphasis',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-emphasis',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-color',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-emphasis-color',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis-color',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-emphasis-color',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-color',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-emphasis-color',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis-color',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-emphasis-color',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-position',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-emphasis-position',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis-position',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-emphasis-position',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-position',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-emphasis-position',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis-position',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-emphasis-position',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-skip',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-emphasis-skip',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-skip',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-emphasis-skip',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-style',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-emphasis-style',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis-style',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-emphasis-style',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-emphasis-style',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-emphasis-style',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-emphasis-style',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-emphasis-style',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-group-align',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-group-align',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-group-align',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-group-align',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-indent',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-text-indent',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'text-indent',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-text-indent',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-indent',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-indent',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-indent',
    url: 'https://drafts.csswg.org/css2/#propdef-text-indent',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'text-indent',
    url: 'https://drafts.csswg.org/css2/#propdef-text-indent',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'text-indent',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-text-indent',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-indent',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-indent',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-justify',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-text-justify',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-justify',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-justify',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-justify',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-text-justify',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-justify',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-justify',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-orientation',
    url: 'https://drafts.csswg.org/css-writing-modes-3/#propdef-text-orientation',
    status: 'ED',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'text-orientation',
    url: 'https://drafts.csswg.org/css-writing-modes-4/#propdef-text-orientation',
    status: 'ED',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'text-orientation',
    url: 'https://www.w3.org/TR/2019/CR-css-writing-modes-4-20190730/#propdef-text-orientation',
    status: 'CR',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'text-orientation',
    url: 'https://www.w3.org/TR/2019/REC-css-writing-modes-3-20191210/#propdef-text-orientation',
    status: 'REC',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'text-overflow',
    url: 'https://drafts.csswg.org/css-overflow-3/#propdef-text-overflow',
    status: 'ED',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'text-overflow',
    url: 'https://drafts.csswg.org/css-overflow-4/#propdef-text-overflow',
    status: 'ED',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'text-overflow',
    url: 'https://drafts.csswg.org/css-ui-3/#propdef-text-overflow',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'text-overflow',
    url: 'https://www.w3.org/TR/2018/REC-css-ui-3-20180621/#propdef-text-overflow',
    status: 'REC',
    title: 'CSS Basic User Interface Module Level 3 (CSS3 UI)',
  },
  {
    property: 'text-overflow',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-3-20221231/#propdef-text-overflow',
    status: 'WD',
    title: 'CSS Overflow Module Level 3',
  },
  {
    property: 'text-overflow',
    url: 'https://www.w3.org/TR/2022/WD-css-overflow-4-20221231/#propdef-text-overflow',
    status: 'WD',
    title: 'CSS Overflow Module Level 4',
  },
  {
    property: 'text-shadow',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-shadow',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-shadow',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-shadow',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-shadow',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-shadow',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-shadow',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-shadow',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-space-trim',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-space-trim',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-space-trim',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-space-trim',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-spacing',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-spacing',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-spacing',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-spacing',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-spacing-trim',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-spacing-trim',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-spacing-trim',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-spacing-trim',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-transform',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-text-transform',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'text-transform',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-text-transform',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-transform',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-transform',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-transform',
    url: 'https://drafts.csswg.org/css2/#propdef-text-transform',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'text-transform',
    url: 'https://drafts.csswg.org/css2/#propdef-text-transform',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'text-transform',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-text-transform',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'text-transform',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-transform',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-underline-offset',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-underline-offset',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-underline-offset',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-underline-offset',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-underline-position',
    url: 'https://drafts.csswg.org/css-text-decor-3/#propdef-text-underline-position',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-underline-position',
    url: 'https://drafts.csswg.org/css-text-decor-4/#propdef-text-underline-position',
    status: 'ED',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-underline-position',
    url: 'https://www.w3.org/TR/2022/CRD-css-text-decor-3-20220505/#propdef-text-underline-position',
    status: 'CRD',
    title: 'CSS Text Decoration Module Level 3',
  },
  {
    property: 'text-underline-position',
    url: 'https://www.w3.org/TR/2022/WD-css-text-decor-4-20220504/#propdef-text-underline-position',
    status: 'WD',
    title: 'CSS Text Decoration Module Level 4',
  },
  {
    property: 'text-wrap',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-text-wrap',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'text-wrap',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-text-wrap',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'top',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-top',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'top',
    url: 'https://drafts.csswg.org/css-position-3/#propdef-top',
    status: 'ED',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'top',
    url: 'https://drafts.csswg.org/css2/#propdef-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'top',
    url: 'https://drafts.csswg.org/css2/#propdef-top',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'top',
    url: 'https://www.w3.org/TR/2023/WD-css-position-3-20230217/#propdef-top',
    status: 'WD',
    title: 'CSS Positioned Layout Module Level 3',
  },
  {
    property: 'transform',
    url: 'https://drafts.csswg.org/css-transforms/#propdef-transform',
    status: 'ED',
    title: 'CSS Transforms Module Level 1',
  },
  {
    property: 'transform',
    url: 'https://www.w3.org/TR/2019/CR-css-transforms-1-20190214/#propdef-transform',
    status: 'CR',
    title: 'CSS Transforms Module Level 1',
  },
  {
    property: 'transform-box',
    url: 'https://drafts.csswg.org/css-transforms/#propdef-transform-box',
    status: 'ED',
    title: 'CSS Transforms Module Level 1',
  },
  {
    property: 'transform-box',
    url: 'https://www.w3.org/TR/2019/CR-css-transforms-1-20190214/#propdef-transform-box',
    status: 'CR',
    title: 'CSS Transforms Module Level 1',
  },
  {
    property: 'transform-origin',
    url: 'https://drafts.csswg.org/css-transforms/#propdef-transform-origin',
    status: 'ED',
    title: 'CSS Transforms Module Level 1',
  },
  {
    property: 'transform-origin',
    url: 'https://www.w3.org/TR/2019/CR-css-transforms-1-20190214/#propdef-transform-origin',
    status: 'CR',
    title: 'CSS Transforms Module Level 1',
  },
  {
    property: 'transform-style',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-transform-style',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'transform-style',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-transform-style',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'transition',
    url: 'https://drafts.csswg.org/css-transitions/#propdef-transition',
    status: 'ED',
    title: 'CSS Transitions',
  },
  {
    property: 'transition',
    url: 'https://www.w3.org/TR/2018/WD-css-transitions-1-20181011/#propdef-transition',
    status: 'WD',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-delay',
    url: 'https://drafts.csswg.org/css-transitions/#propdef-transition-delay',
    status: 'ED',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-delay',
    url: 'https://www.w3.org/TR/2018/WD-css-transitions-1-20181011/#propdef-transition-delay',
    status: 'WD',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-duration',
    url: 'https://drafts.csswg.org/css-transitions/#propdef-transition-duration',
    status: 'ED',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-duration',
    url: 'https://www.w3.org/TR/2018/WD-css-transitions-1-20181011/#propdef-transition-duration',
    status: 'WD',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-property',
    url: 'https://drafts.csswg.org/css-transitions/#propdef-transition-property',
    status: 'ED',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-property',
    url: 'https://www.w3.org/TR/2018/WD-css-transitions-1-20181011/#propdef-transition-property',
    status: 'WD',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-timing-function',
    url: 'https://drafts.csswg.org/css-transitions/#propdef-transition-timing-function',
    status: 'ED',
    title: 'CSS Transitions',
  },
  {
    property: 'transition-timing-function',
    url: 'https://www.w3.org/TR/2018/WD-css-transitions-1-20181011/#propdef-transition-timing-function',
    status: 'WD',
    title: 'CSS Transitions',
  },
  {
    property: 'translate',
    url: 'https://drafts.csswg.org/css-transforms-2/#propdef-translate',
    status: 'ED',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'translate',
    url: 'https://www.w3.org/TR/2021/WD-css-transforms-2-20211109/#propdef-translate',
    status: 'WD',
    title: 'CSS Transforms Module Level 2',
  },
  {
    property: 'unicode-bidi',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-unicode-bidi',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'unicode-bidi',
    url: 'https://drafts.csswg.org/css-writing-modes-3/#propdef-unicode-bidi',
    status: 'ED',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'unicode-bidi',
    url: 'https://drafts.csswg.org/css-writing-modes-4/#propdef-unicode-bidi',
    status: 'ED',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'unicode-bidi',
    url: 'https://drafts.csswg.org/css2/#propdef-unicode-bidi',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'unicode-bidi',
    url: 'https://drafts.csswg.org/css2/#propdef-unicode-bidi',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'unicode-bidi',
    url: 'https://www.w3.org/TR/2019/CR-css-writing-modes-4-20190730/#propdef-unicode-bidi',
    status: 'CR',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'unicode-bidi',
    url: 'https://www.w3.org/TR/2019/REC-css-writing-modes-3-20191210/#propdef-unicode-bidi',
    status: 'REC',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'user-select',
    url: 'https://drafts.csswg.org/css-ui-4/#propdef-user-select',
    status: 'ED',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'user-select',
    url: 'https://www.w3.org/TR/2021/WD-css-ui-4-20210316/#propdef-user-select',
    status: 'WD',
    title: 'CSS Basic User Interface Module Level 4',
  },
  {
    property: 'vertical-align',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-vertical-align',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'vertical-align',
    url: 'https://drafts.csswg.org/css-inline-3/#propdef-vertical-align',
    status: 'ED',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'vertical-align',
    url: 'https://drafts.csswg.org/css2/#propdef-vertical-align',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'vertical-align',
    url: 'https://drafts.csswg.org/css2/#propdef-vertical-align',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'vertical-align',
    url: 'https://www.w3.org/TR/2022/WD-css-inline-3-20221114/#propdef-vertical-align',
    status: 'WD',
    title: 'CSS Inline Layout Module Level 3',
  },
  {
    property: 'view-timeline',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-view-timeline',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-view-timeline',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline-axis',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-view-timeline-axis',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline-axis',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-view-timeline-axis',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline-inset',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-view-timeline-inset',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline-inset',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-view-timeline-inset',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline-name',
    url: 'https://drafts.csswg.org/scroll-animations-1/#propdef-view-timeline-name',
    status: 'ED',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-timeline-name',
    url: 'https://www.w3.org/TR/2022/WD-scroll-animations-1-20221208/#propdef-view-timeline-name',
    status: 'WD',
    title: 'Scroll-linked Animations',
  },
  {
    property: 'view-transition-name',
    url: 'https://drafts.csswg.org/css-view-transitions-1/#propdef-view-transition-name',
    status: 'ED',
    title: 'CSS View Transitions Module Level 1',
  },
  {
    property: 'view-transition-name',
    url: 'https://www.w3.org/TR/2022/WD-css-view-transitions-1-20221124/#propdef-view-transition-name',
    status: 'WD',
    title: 'CSS View Transitions Module Level 1',
  },
  {
    property: 'visibility',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visufx.html#propdef-visibility',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'visibility',
    url: 'https://drafts.csswg.org/css-display/#propdef-visibility',
    status: 'ED',
    title: 'CSS Display Module Level 3',
  },
  {
    property: 'visibility',
    url: 'https://drafts.csswg.org/css2/#propdef-visibility',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'visibility',
    url: 'https://drafts.csswg.org/css2/#propdef-visibility',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'visibility',
    url: 'https://www.w3.org/TR/2022/CRD-css-display-3-20221118/#propdef-visibility',
    status: 'CRD',
    title: 'CSS Display Module Level 3',
  },
  {
    property: 'voice-balance',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-balance',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-balance',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-balance',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-duration',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-duration',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-duration',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-duration',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-family',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-voice-family',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'voice-family',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-family',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-family',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-family',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-pitch',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-pitch',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-pitch',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-pitch',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-range',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-range',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-range',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-range',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-rate',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-rate',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-rate',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-rate',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-stress',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-stress',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-stress',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-stress',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-volume',
    url: 'https://drafts.csswg.org/css-speech-1/#propdef-voice-volume',
    status: 'ED',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'voice-volume',
    url: 'https://www.w3.org/TR/2023/CRD-css-speech-1-20230214/#propdef-voice-volume',
    status: 'CRD',
    title: 'CSS Speech Module Level 1',
  },
  {
    property: 'volume',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/aural.html#propdef-volume',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'white-space',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-white-space',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'white-space',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-white-space',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'white-space',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-white-space',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'white-space',
    url: 'https://drafts.csswg.org/css2/#propdef-white-space',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'white-space',
    url: 'https://drafts.csswg.org/css2/#propdef-white-space',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'white-space',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-white-space',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'white-space',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-white-space',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'white-space-collapse',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-white-space-collapse',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'white-space-collapse',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-white-space-collapse',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'widows',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/page.html#propdef-widows',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'widows',
    url: 'https://drafts.csswg.org/css-break-4/#propdef-widows',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'widows',
    url: 'https://drafts.csswg.org/css-break/#propdef-widows',
    status: 'ED',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'widows',
    url: 'https://drafts.csswg.org/css2/#propdef-widows',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'widows',
    url: 'https://drafts.csswg.org/css2/#propdef-widows',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'widows',
    url: 'https://www.w3.org/TR/2018/CR-css-break-3-20181204/#propdef-widows',
    status: 'CR',
    title: 'CSS Fragmentation Module Level 3',
  },
  {
    property: 'widows',
    url: 'https://www.w3.org/TR/2018/WD-css-break-4-20181218/#propdef-widows',
    status: 'FPWD',
    title: 'CSS Fragmentation Module Level 4',
  },
  {
    property: 'width',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visudet.html#propdef-width',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'width',
    url: 'https://drafts.csswg.org/css-sizing-3/#propdef-width',
    status: 'ED',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'width',
    url: 'https://drafts.csswg.org/css2/#propdef-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'width',
    url: 'https://drafts.csswg.org/css2/#propdef-width',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'width',
    url: 'https://www.w3.org/TR/2021/WD-css-sizing-3-20211217/#propdef-width',
    status: 'WD',
    title: 'CSS Box Sizing Module Level 3',
  },
  {
    property: 'will-change',
    url: 'https://drafts.csswg.org/css-will-change/#propdef-will-change',
    status: 'ED',
    title: 'CSS Will Change Module Level 1',
  },
  {
    property: 'will-change',
    url: 'https://www.w3.org/TR/2022/CRD-css-will-change-1-20220505/#propdef-will-change',
    status: 'CRD',
    title: 'CSS Will Change Module Level 1',
  },
  {
    property: 'word-boundary-detection',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-word-boundary-detection',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-boundary-detection',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-word-boundary-detection',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-boundary-expansion',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-word-boundary-expansion',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-boundary-expansion',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-word-boundary-expansion',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-break',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-word-break',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'word-break',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-word-break',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-break',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-word-break',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'word-break',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-word-break',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-spacing',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/text.html#propdef-word-spacing',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'word-spacing',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-word-spacing',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'word-spacing',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-word-spacing',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-spacing',
    url: 'https://drafts.csswg.org/css2/#propdef-word-spacing',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'word-spacing',
    url: 'https://drafts.csswg.org/css2/#propdef-word-spacing',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
  {
    property: 'word-spacing',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-word-spacing',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'word-spacing',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-word-spacing',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-wrap',
    url: 'https://drafts.csswg.org/css-text-3/#propdef-word-wrap',
    status: 'ED',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'word-wrap',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-word-wrap',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'word-wrap',
    url: 'https://www.w3.org/TR/2023/CRD-css-text-3-20230213/#propdef-word-wrap',
    status: 'CRD',
    title: 'CSS Text Module Level 3',
  },
  {
    property: 'word-wrap',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-word-wrap',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-after',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-wrap-after',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-after',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-wrap-after',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-before',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-wrap-before',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-before',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-wrap-before',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-flow',
    url: 'https://drafts.csswg.org/css-exclusions/#propdef-wrap-flow',
    status: 'ED',
    title: 'CSS Exclusions Module Level 1',
  },
  {
    property: 'wrap-flow',
    url: 'https://www.w3.org/TR/2015/WD-css3-exclusions-20150115/#propdef-wrap-flow',
    status: 'WD',
    title: 'CSS Exclusions Module Level 1',
  },
  {
    property: 'wrap-inside',
    url: 'https://drafts.csswg.org/css-text-4/#propdef-wrap-inside',
    status: 'ED',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-inside',
    url: 'https://www.w3.org/TR/2023/WD-css-text-4-20230301/#propdef-wrap-inside',
    status: 'WD',
    title: 'CSS Text Module Level 4',
  },
  {
    property: 'wrap-through',
    url: 'https://drafts.csswg.org/css-exclusions/#propdef-wrap-through',
    status: 'ED',
    title: 'CSS Exclusions Module Level 1',
  },
  {
    property: 'wrap-through',
    url: 'https://www.w3.org/TR/2015/WD-css3-exclusions-20150115/#propdef-wrap-through',
    status: 'WD',
    title: 'CSS Exclusions Module Level 1',
  },
  {
    property: 'writing-mode',
    url: 'https://drafts.csswg.org/css-writing-modes-3/#propdef-writing-mode',
    status: 'ED',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'writing-mode',
    url: 'https://drafts.csswg.org/css-writing-modes-4/#propdef-writing-mode',
    status: 'ED',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'writing-mode',
    url: 'https://www.w3.org/TR/2019/CR-css-writing-modes-4-20190730/#propdef-writing-mode',
    status: 'CR',
    title: 'CSS Writing Modes Level 4',
  },
  {
    property: 'writing-mode',
    url: 'https://www.w3.org/TR/2019/REC-css-writing-modes-3-20191210/#propdef-writing-mode',
    status: 'REC',
    title: 'CSS Writing Modes Level 3',
  },
  {
    property: 'z-index',
    url: 'http://www.w3.org/TR/2011/REC-CSS2-20110607/visuren.html#propdef-z-index',
    status: 'REC',
    title: 'CSS 2.1',
  },
  {
    property: 'z-index',
    url: 'https://drafts.csswg.org/css2/#propdef-z-index',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 1 (CSS 2.1) Specification',
  },
  {
    property: 'z-index',
    url: 'https://drafts.csswg.org/css2/#propdef-z-index',
    status: 'ED',
    title: 'Cascading Style Sheets Level 2 Revision 2 (CSS 2.2) Specification',
  },
];

const cssCustomPropertiesList = [
  {
    property: 'align',
    title: 'Horizontal alignment of an element',
    type: 'style',
    cssProperty: 'text-align',
  },
  {
    property: 'show-for-',
    title: 'Media display',
    type: 'class',
  },
  {
    property: 'spm_responsive',
    title: 'Defines if an element should be responsive',
    type: 'class',
  },
  {
    property: POPIN_AUTO_CLOSE_ATTRIBUTE,
    title: 'Defines if a popin should auto close',
    type: 'attribute',
  },
  {
    property: 'spm_div_align_',
    title: 'Div alignment',
    type: 'class',
  },
  {
    property: 'background-position-x',
    title: 'Position x of an image in background',
    type: 'style',
    cssProperty: 'background-position-x',
  },
  {
    property: 'background-position-y',
    title: 'Position y of an image in background',
    type: 'style',
    cssProperty: 'background-position-y',
  },
];

/**
 * Check if a string is a valid css property
 * @param property
 */
export const isCssProperty = (property: string): boolean => cssPropertiesList.filter((cssProperty) => cssProperty.property === property).length !== 0;

/**
 * Check if a string is a valid custom property
 * @param property
 */
export const isCustomProperty = (property: string) => cssCustomPropertiesList.filter((customProperty) => customProperty.property === property);
