<template>
  <div class="flex flex-column mr-8 md:mr-8">
    <span class="inline-block font-medium text-base mr-2">
      {{ t('dataExplorer.orderFile.orderNumber', [order.idOrder]) }} - {{ t('dataExplorer.orderFile.orderReference', [order.orderReference]) }}
    </span>
    <span
      class="font-medium text-500 mt-3 text-sm"
    >
      {{ formatDate(order.date, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm:ss') }}
    </span>
  </div>
  <div class="mt-2 md:mt-0 ml-0 md:ml-8 flex flex-column align-items-center">
    <span
      class="mb-2 order-price"
    >
      {{ formatNumberToCurrency(order.amount, order.currency) }}
    </span>
    <SpmButton
      :label="t('dataExplorer.contactFile.fields.addresses.openAddressBtn')"
      icon="fa-light fa-circle-info"
      class-style="p-button p-button-secondary"
      @click="openOrderFile"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import {
  Order,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrderItem',

  components: {
    SpmButton,
  },

  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },

    keyName: {
      type: String,
      required: true,
    }
  },

  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const openOrderFile = () => {
      store.dispatch('dataExplorer/openOrderFile', { idOrder: props.order.idOrder, keyName: props.keyName });
    };

    return {
      t,

      formatDate,
      formatNumberToCurrency,
      openOrderFile,
    };
  }
})
</script>

<style lang="scss" scoped>
.order-price {
  color: $brand-color-primary;
  font-size: 1.5rem;
}
</style>