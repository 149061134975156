
import {
  defineComponent, onBeforeMount,
  PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import RadioButton from 'primevue/radiobutton';
import { useI18n } from 'vue-i18n';
import BaseButtonGroup from '@/components/fields/BaseButtonGroup.vue';
import VirtualScroller from 'primevue/virtualscroller';
import { DateFrequencyData } from '@/types/stats-editor-types';
import { TypeStatsEmailSendingFrequency } from '@/composables/Statistics/usersPagesConfiguration';
import { useStore } from '@/store';

export default defineComponent({
  name: 'DateFrequencies',

  components: {
    RadioButton,
    BaseButtonGroup,
    VirtualScroller,
  },

  props: {
    modelValue: {
      type: Object as PropType<DateFrequencyData>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DateFrequencyData }, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();

    const dateFrequencyData = ref(props.modelValue);

    const selectedTypeFrequency = ref('0');
    const optionsTypeFrequency = [
      { value: '0', label: t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesType.recurrent') },
      { value: '1', label: t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesType.triggerBulkCampaign') },
    ];

    /* If the selectedTypeFrequency equal to '0' */
    const selectedFrequencyRecurrent: Ref<string|null> = ref(null);
    const frequencyRecurrentOptions = ref([
      {
        name: t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesOptions.everyDay'),
        key: 'everyDay',
      },
      {
        name: t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesOptions.everyHalfMonth'),
        key: 'everyHalfMonth',
      },
      {
        name: t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesOptions.everyMonth'),
        key: 'everyMonth',
      },
    ]);

    const daysOfMonth = ref([
      { value: '1', selected: false },
      { value: '2', selected: false },
      { value: '3', selected: false },
      { value: '4', selected: false },
      { value: '5', selected: false },
      { value: '6', selected: false },
      { value: '7', selected: false },
      { value: '8', selected: false },
      { value: '9', selected: false },
      { value: '10', selected: false },
      { value: '11', selected: false },
      { value: '12', selected: false },
      { value: '13', selected: false },
      { value: '14', selected: false },
      { value: '15', selected: false },
      { value: '16', selected: false },
      { value: '17', selected: false },
      { value: '18', selected: false },
      { value: '19', selected: false },
      { value: '20', selected: false },
      { value: '21', selected: false },
      { value: '22', selected: false },
      { value: '23', selected: false },
      { value: '24', selected: false },
      { value: '25', selected: false },
      { value: '26', selected: false },
      { value: '27', selected: false },
      { value: '28', selected: false },
      { value: '29', selected: false },
      { value: '30', selected: false },
      { value: '31', selected: false },
    ]);

    const daysOfWeek = ref([
      { value: 'Lundi', selected: false },
      { value: 'Mardi', selected: false },
      { value: 'Mercredi', selected: false },
      { value: 'Jeudi', selected: false },
      { value: 'Vendredi', selected: false },
      { value: 'Samedi', selected: false },
      { value: 'Dimanche', selected: false },
    ]);

    const selectDaysOfWeekFrequency = (value: any) => {
      daysOfWeek.value = daysOfWeek.value.map((item: any) => {
        if (item.value === value) {
          // eslint-disable-next-line no-param-reassign
          item.selected = !item.selected;
        }
        return item;
      });

      // if no daysOfWeek selected, set selectedFrequencyRecurrent to null
      if (daysOfWeek.value.filter((item: any) => item.selected).length === 0) {
        selectedFrequencyRecurrent.value = null;
      } else {
        selectedFrequencyRecurrent.value = 'selectedDaysOfWeek';
      }
    };

    const selectDaysOfMonthFrequency = (value: any) => {
      daysOfMonth.value = daysOfMonth.value.map((item: any) => {
        if (item.value === value) {
          // eslint-disable-next-line no-param-reassign
          item.selected = !item.selected;
        }
        return item;
      });

      // if no daysOfMonth selected, set selectedFrequencyRecurrent to null
      if (daysOfMonth.value.filter((item: any) => item.selected).length === 0) {
        selectedFrequencyRecurrent.value = null;
      } else {
        selectedFrequencyRecurrent.value = 'selectedDaysOfMonth';
      }
    };

    /* If the selectedTypeFrequency equal to '1' */
    const frequencyBulkCampaignOptions = ref([
      { value: '1', selected: true },
      { value: '2', selected: false },
      { value: '3', selected: false },
      { value: '4', selected: false },
      { value: '5', selected: false },
      { value: '6', selected: false },
      { value: '7', selected: false },
      { value: '8', selected: false },
      { value: '9', selected: false },
      { value: '10', selected: false },
      { value: '11', selected: false },
      { value: '12', selected: false },
      { value: '13', selected: false },
      { value: '14', selected: false },
      { value: '15', selected: false },
      { value: '16', selected: false },
      { value: '17', selected: false },
      { value: '18', selected: false },
      { value: '19', selected: false },
      { value: '20', selected: false },
      { value: '21', selected: false },
      { value: '22', selected: false },
      { value: '23', selected: false },
      { value: '24', selected: false },
      { value: '25', selected: false },
      { value: '26', selected: false },
      { value: '27', selected: false },
      { value: '28', selected: false },
      { value: '29', selected: false },
      { value: '30', selected: false },
      { value: '31', selected: false },
    ]);
    const selectFrequencyBulkCampaignOption = (value: any) => {
      frequencyBulkCampaignOptions.value = frequencyBulkCampaignOptions.value.map((item: any) => {
        if (item.value === value) {
          // eslint-disable-next-line no-param-reassign
          item.selected = !item.selected;
        }
        return item;
      });
    };

    onBeforeMount(() => {
      if (dateFrequencyData.value.type === TypeStatsEmailSendingFrequency.RECURRENT) {
        selectedTypeFrequency.value = '0';
        if (dateFrequencyData.value.way) {
          selectedFrequencyRecurrent.value = dateFrequencyData.value.way;

          if (dateFrequencyData.value.way === 'selectedDaysOfWeek') {
            daysOfWeek.value = daysOfWeek.value.map((item: any) => {
              if (dateFrequencyData.value && dateFrequencyData.value.selectedDays && dateFrequencyData.value.selectedDays.includes(item.value)) {
                // eslint-disable-next-line no-param-reassign
                item.selected = true;
              }
              return item;
            });
          } else if (dateFrequencyData.value.way === 'selectedDaysOfMonth') {
            daysOfMonth.value = daysOfMonth.value.map((item: any) => {
              if (dateFrequencyData.value && dateFrequencyData.value.selectedDays && dateFrequencyData.value.selectedDays.includes(item.value)) {
                // eslint-disable-next-line no-param-reassign
                item.selected = true;
              }
              return item;
            });
          }
        }
      } else if (dateFrequencyData.value.type === TypeStatsEmailSendingFrequency.TRIGGER_BULK_CAMPAIGN) {
        selectedTypeFrequency.value = '1';
        frequencyBulkCampaignOptions.value = frequencyBulkCampaignOptions.value.map((item: any) => {
          if (dateFrequencyData.value && dateFrequencyData.value.selectedDays && dateFrequencyData.value.selectedDays.includes(item.value)) {
            // eslint-disable-next-line no-param-reassign
            item.selected = true;
          }
          return item;
        });
      } else {
        dateFrequencyData.value = {
          type: TypeStatsEmailSendingFrequency.RECURRENT,
        };
      }
    });

    watch(selectedFrequencyRecurrent, () => {
      if (selectedFrequencyRecurrent.value !== 'selectedDaysOfWeek') {
        daysOfWeek.value = daysOfWeek.value.map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.selected = false;
          return item;
        });
      }
      if (selectedFrequencyRecurrent.value !== 'selectedDaysOfMonth') {
        daysOfMonth.value = daysOfMonth.value.map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.selected = false;
          return item;
        });
      }
    });

    watch([selectedTypeFrequency, selectedFrequencyRecurrent, daysOfWeek, daysOfMonth, frequencyBulkCampaignOptions], () => {
      dateFrequencyData.value = {
        type: TypeStatsEmailSendingFrequency.RECURRENT,
      };

      if (selectedTypeFrequency.value === '0') {
        store.commit('statsEditor/setCanConfigureFilters', true);
        if (selectedFrequencyRecurrent.value) {
          dateFrequencyData.value.way = selectedFrequencyRecurrent.value;
          if (selectedFrequencyRecurrent.value === 'selectedDaysOfWeek') {
            dateFrequencyData.value.selectedDays = daysOfWeek.value.filter((item: any) => item.selected).map((item: any) => item.value);
          } else if (selectedFrequencyRecurrent.value === 'selectedDaysOfMonth') {
            dateFrequencyData.value.selectedDays = daysOfMonth.value.filter((item: any) => item.selected).map((item: any) => item.value);
          }
        }
      } else if (selectedTypeFrequency.value === '1') {
        store.commit('statsEditor/setCanConfigureFilters', false);
        dateFrequencyData.value.type = TypeStatsEmailSendingFrequency.TRIGGER_BULK_CAMPAIGN;
        dateFrequencyData.value.selectedDays = frequencyBulkCampaignOptions.value.filter((item: any) => item.selected).map((item: any) => item.value);
      }

      emit('update:modelValue', dateFrequencyData.value);
    });

    return {
      t,
      selectedTypeFrequency,
      optionsTypeFrequency,
      selectedFrequencyRecurrent,
      frequencyRecurrentOptions,
      daysOfMonth,
      daysOfWeek,
      selectDaysOfWeekFrequency,
      selectDaysOfMonthFrequency,
      frequencyBulkCampaignOptions,
      selectFrequencyBulkCampaignOption,
    };
  },
});
