import { helpers, requiredIf } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { ActionABTestData } from '@/components/automated-scenarios/metadata/actions/ActionABTestMetadata';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  voucherMethodsValues, voucherTypeValues,
} from '@/composables/automated-scenarios/AutomatedScenariosSendingChannel';
import { Moment } from 'moment';

let translation: any;
(async () => {
  translation = await i18n;
})();

export function locationGMapsValidator(): any {
  return helpers.withMessage(translation.global.t('customValidator.locationGMaps'), (value: any) => {
    const formattedValue = '0' in value ? value[0] : value;
    return !((formattedValue.radius === undefined || formattedValue.radius === '')
      && (formattedValue.lat === undefined || Number.isNaN(formattedValue.lat))
      && (formattedValue.long === undefined || Number.isNaN(formattedValue.long))
      && (formattedValue.address === undefined || formattedValue.address === ''));
  });
}
export function itemsPickerValidator(required = true): any {
  return helpers.withMessage(translation.global.t('customValidator.itemsPicker'), (value: any) => (
    (!required && !value)
      || (value.selection && value.selection.selected && ((required && value.selection.selected.length > 0) || (!required && value.selection.selected.length >= 0)))
  ));
}

export function includesValuesValidator(data: any[]): any {
  return helpers.withMessage(translation.global.t('customValidator.includesValues'), (value: any) => data.includes(value));
}

/*
 * Validator used in the validation of the "Visit a page" trigger configuration form && "Navigation history" Filter configuration form
 */
export function minNavigationCriterionValidator(type: string, products: object, categories: object, manufacturers: object, urls: object): any {
  return helpers.withMessage(translation.global.t('customValidator.minNavigationCriterion'), (value: any) => {
    if (type === 'trigger' && value === 1) {
      return true;
    }
    return !!(Object.keys(products).length || Object.keys(categories).length || Object.keys(manufacturers).length
      || Object.keys(urls).length);
  });
}

/*
 * Validator used in the validation of the "AB Test" action configuration form
 */
export function ABTestDecreaseOutputsValidator(data: ActionABTestData): any {
  return helpers.withMessage(translation.global.t('customValidator.ABTestDecreaseOutputs'), (value: any) => {
    let validateBranchesToDelete = true;
    if (typeof data.initial_number_outputs !== 'undefined'
      && data.initial_number_outputs !== null
      && typeof data.outputs_to_delete !== 'undefined'
      && data.outputs_to_delete !== null
      && data.initial_number_outputs > data.outputs_number_test) {
      validateBranchesToDelete = (data.initial_number_outputs - data.outputs_number_test) === data.outputs_to_delete.length;
    }

    return validateBranchesToDelete;
  });
}

/*
 * Validator used in the validation of the filters with segments "Inclusion/Exclusion"
 */
export function atLeastOneSegmentValidator(dataExclusion: object): any {
  return helpers.withMessage(translation.global.t('customValidator.atLeastOneSegment'), requiredIf(() => !Object.keys(dataExclusion).length));
}

/*
 * Validator used in the validation of the filters with segments "Inclusion/Exclusion"
 */
export async function voucherTemplateValidator(data: any, field: string) {
  let required = false;
  const mustConfigVoucher = true;

  // if (data.template_display_selector_id && data.template_display_selector_id > 0) {
  //   const idTemplate = asInt(data.template_display_selector_id);
  //   let templatesContainsVoucher = false;
  //   const listTemplate = await getTemplateAndRedirect(idTemplate);
  //   if (listTemplate !== null) {
  //     templatesContainsVoucher = checkIfTemplateOrRedirectContainsVoucher(listTemplate);
  //   }
  //   if (templatesContainsVoucher && data.reuse_a_previous_coupon === reusePreviousCouponValues.CONFIGURE_NEW_VOUCHER) {
  //     mustConfigVoucher = true;
  //   }
  // }

  if (mustConfigVoucher) {
    switch (field) {
      case 'method':
        required = true;
        break;
      case 'descript':
        required = false;
        break;
      case 'type':
        required = true;
        break;
      case 'identifiant':
        required = data.template_display_reduction_method === voucherMethodsValues.MANUAL;
        break;
      case 'amount':
        required = [voucherTypeValues.PERCENTAGE, voucherTypeValues.AMOUNT].includes(data.template_display_reduction_type);
        break;
      case 'duplicate':
        required = data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC && data.template_display_reduction_type === voucherTypeValues.DUPLICATED;
        break;
      case 'minorderamount':
        required = data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC;
        break;
      case 'validtime':
        required = data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC;
        break;
      default:
        required = false;
    }
  }
  return !required;
}

/*
  * Email format validator
 */
export function emailFormatValidator(): any {
  return helpers.withMessage(translation.global.t('customValidator.emailFormat'), (value: any) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(value);
  });
}

export function localizedTextInputValidator(type: string, mustVerify = true): any {
  if (!mustVerify) {
    return true;
  }

  return helpers.withMessage(translation.global.t('customValidator.localizedTextInput'), (value: any) => {
    // Determines if 'value' is a non-empty object with all it's properties are filled.
    const isFilled = typeof value === 'object' && value !== null && Object.keys(value).length > 0 && Object.values(value).every((val) => !!val);

    if (isFilled && (type === 'email' || type === 'url' || type === 'smsSenderName')) {
      const isEmailOrUrlValid = Object.values(value).every((val: any) => {
        if (type === 'email') {
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
        }

        if (type === 'url') {
          return /^(ftp|http|https):\/\/[^ "]+$/.test(val);
        }

        if (type === 'smsSenderName') {
          return val.length >= 3 && val.length <= 11 && !(new RegExp('^[0-9]+$')).test(val);
        }
        return false;
      });

      if (!isEmailOrUrlValid) {
        return false;
      }
    }

    return isFilled;
  });
}

export function dateBetweenValidator(dateMin: Moment | null, dateMax: Moment | null): any {
  let message = translation.global.t('customValidator.simpleDateTime.global');
  if (dateMin && dateMax) {
    message = translation.global.t('customValidator.simpleDateTime.between', { min: dateMin.format('DD/MM/YYYY HH:mm:ss'), max: dateMax.format('DD/MM/YYYY HH:mm:ss') });
  } else if (dateMin) {
    message = translation.global.t('customValidator.simpleDateTime.min', { min: dateMin.format('DD/MM/YYYY HH:mm:ss') });
  } else if (dateMax) {
    message = translation.global.t('customValidator.simpleDateTime.max', { max: dateMax.format('DD/MM/YYYY HH:mm:ss') });
  }

  return helpers.withMessage(message, (value: Moment) => {
    if (typeof value !== 'object') {
      return true;
    }

    if (dateMin && dateMax) {
      return value.isBetween(dateMin, dateMax);
    }
    if (dateMin) {
      return value.isAfter(dateMin);
    }
    if (dateMax) {
      return value.isBefore(dateMax);
    }
    return true;
  });
}

export function verifyCommercialUseCampaign(campaignContainMediaWithVoucher: boolean): any {
  return helpers.withMessage(translation.global.t('customValidator.campaignCommercialUse'), (value: any) => !(value === 0 && campaignContainMediaWithVoucher));
}

export function urlFormatValidator(): any {
  return helpers.withMessage(translation.global.t('customValidator.urlFormat'), (value: any) => /^(ftp|http|https):\/\/[^ "]+$/.test(value));
}

export function domaineNameInputValidator(): any {
  return helpers.withMessage(translation.global.t('shop.my_domains.add.inValid'), (value: any) => {
    if (!value.trim()) {
      return false;
    }

    const domainRegex = /^(?!:\/\/)(?!www\.|m\.)[a-zA-Z0-9-]{1,63}(\.[a-zA-Z]{2,})+$/;
    return domainRegex.test(value);
  });
}

export function voucherInputValidator(): any {
  return helpers.withMessage(translation.global.t('storeParameters.services.voucherServiceParameter.invalidFieldValue'), (value: any) => {
    if (!value) {
      return false;
    }

    return value > 0;
  });
}

export function importedHtmlValidator(): any {
  return helpers.withMessage(translation.global.t('templateSelector.import.noUnsubscribeUrlFound'), (value: string) => {
    const mandatoryVariableRegex = /\{var=template\.unsubscribe_url\}/gm;
    return mandatoryVariableRegex.test(value);
  });
}
