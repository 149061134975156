import {
  TemplateStructureEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TabItem,
  BackgroundImageOptionTypeEnum, ComponentNameEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  setBackgroundImage,
  setBackgroundImageProperty, setCssProperty, setCssPropertyAndRemoveIfZero,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getBackgroundImageValue,
  getCssPropertyByName,
} from '@/components/template-builder/getters';
import getBackgroundImageFieldConfig from '@/components/template-builder/config/fields/background-image-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';

export const rowTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.LINE,
  label: 'templateBuilder.configs.line',
  tabPanel: {
    label: 'templateBuilder.configs.lineEdit',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.line',
        selector: 'body #spm_body #{LINE_ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundImage',
            selector: 'body #spm_body #{LINE_ID}',
            properties: [
              { name: 'background-image', getter: getBackgroundImageValue, setters: [setBackgroundImage] },
              { name: 'background-size', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-x', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-position-y', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
              { name: 'background-repeat', getter: getCssPropertyByName, setters: [setBackgroundImageProperty] },
            ],
            ...getBackgroundImageFieldConfig(BackgroundImageOptionTypeEnum.FULL),
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: 'body #spm_body #{LINE_ID}',
            properties: [{
              name: 'background',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(undefined, true, true),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: 'body #spm_body #{LINE_ID}',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
        ],
      },
    ],
  },
};

const rowItemConfig: ConfigurationPanelStructure = {
  items: [
    rowTabItemConfig,
  ],
};

export default rowItemConfig;
