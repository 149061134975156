<template>
  <div>
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label
          class="block my-3"
          data-test-id="dropdown-label"
        >
          {{ t(configs.label, null) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <Dropdown
      v-model="selectedValue"
      class="p-0 mb-3"
      :options="options"
      option-label="label"
      option-value="value"
      :class="{ 'hidden': !displayProperties }"
      @change="handleSelected"
    >
      <template #value="slotProps">
        <img
          :src="`https://media.shopimind.io/img/icons/social/${(slotProps.value && slotProps.value) ?? '1'}/32/facebook.png`"
          alt="social-icon-style"
          style="max-height: 100%;"
        >
      </template>
      <template #option="slotProps">
        <img
          :src="`https://media.shopimind.io/img/icons/social/${slotProps.option.value}/32/facebook.png`"
          alt="social-icon-style"
          style="max-height: 24px;"
        >
      </template>
    </Dropdown>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, PropType, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { FieldConfig, ParserFieldObject, Property } from '@/types';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'SocialIconsStyle',

  components: {
    ToggleDisplaySettings,
    Dropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const selectedValue = ref(property.value.value ?? props.configs.options.defaultValue);
    const options = ref([]);

    const handleSelected = () => {
      if (property.value) {
        property.value.value = selectedValue.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };

    onBeforeMount(async () => {
      try {
        options.value = await props.configs.options.list;
      } catch (error) {
        options.value = [];
      }
    });

    return {
      t,
      displayProperties,
      selectedValue,
      options,
      handleSelected,
    };
  },

});
</script>
