<template>
  <Calendar
    v-model="value"
    date-format=""
    :show-time="true"
    :time-only="true"
    :show-icon="true"
    icon="far fa-clock"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  Ref, ref, SetupContext, watch,
} from 'vue';
import Calendar from 'primevue/calendar';
import { asInt } from '@/helpers';

export default defineComponent({
  name: 'Timepicker',

  components: {
    Calendar,
  },

  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: [
    'update:modelValue',
  ],

  setup(props: { modelValue: string }, { emit }: SetupContext) {
    const today = new Date();
    const [hours, minutes] = props.modelValue.split(':');
    today.setHours(asInt(hours));
    today.setMinutes(asInt(minutes));
    const value = ref(today) as Ref<Date>;
    watch([value], () => value.value && emit('update:modelValue', value.value.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })));

    return { value };
  },

});
</script>

<style lang="scss">
.p-calendar {
  text-align: center;

  .p-button {
    background-color: $secondaryButtonActiveBg !important;
    border: 1px solid $heather !important;
    border-left: none !important;
    color: $secondaryButtonTextColor !important;
    box-shadow: none !important;

    &:hover {
      background-color: $extra-light-grey !important;
    }
  }
}
</style>
