import { ChoosePlanState } from '@/types/store-types';

export default {
  namespaced: true,
  state: (): ChoosePlanState => ({
    isVisible: false,
  }),

  getters: {
    getIsVisible: (state: ChoosePlanState) => state.isVisible,
  },

  mutations: {
    setIsVisible: (state: ChoosePlanState, visible: boolean) => {
      state.isVisible = visible;
    },
  },

  actions: {},
};
