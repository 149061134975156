
import { useI18n } from 'vue-i18n';
import { Ref, ref } from 'vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import {
  Scalars, ShopsListsImportUnsubInputItem,
} from '@/types/generated-types/graphql';
import { queueState } from '@/types';
import { UserState } from '@/composables/User';
import { showToastError, showToastSuccess } from '@/helpers';
import moment from 'moment';
import InsertShopsListsImportUnsub from '@/composables/mes-listes/ListImport';
import Message from 'primevue/message';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import MultiSelect from 'primevue/multiselect';

interface UnsubItem {
  code: number;
  icon: string;
  name: string;
}

type ContentStructure = {
  email?: Scalars['String'];
  phone?: Scalars['String'];
}

export default {
  name: 'ListImportUnsub',

  components: {
    Textarea,
    Button,
    Message,
    MultiSelect,
  },

  setup() {
    const { t } = useI18n();
    const input = ref('');
    const idShop = UserState?.activeShop?.id ?? 0;
    const error = ref('');
    const selectedTypes = ref();

    const items = [
      {
        code: 1,
        icon: 'fas fa-cog fa-fw',
        name: t('myLists.unsub.icons.autoEmail'),
      },
      {
        code: 2,
        icon: 'fa fa-mobile-alt fa-fw',
        name: t('myLists.unsub.icons.autoSms'),
      },
      {
        code: 4,
        icon: 'fas fa-envelope fa-fw',
        name: t('myLists.unsub.icons.newsletter'),
      },
      {
        code: 8,
        icon: 'fa fa-mobile-alt fa-fw',
        name: t('myLists.unsub.icons.bulkSms'),
      },
      {
        code: 0,
        icon: 'fa-light fa-circle-xmark',
        name: t('myLists.unsub.icons.noSolicitation'),
      },
    ];

    const resetError = () => {
      error.value = '';
    };

    const executeAction = async () => {
      let unsubType = 0;
      if (selectedTypes.value.length > 0) {
        selectedTypes.value.forEach((options: {code: number; icon: string; name: string }) => {
          // eslint-disable-next-line no-bitwise
          unsubType |= options.code;
        });
      }

      const lines: string[] = input.value.split('\n');
      const contentArr: Record<string, string>[] = [];
      let contentLine: Record<string, string> = {};

      lines.forEach((line) => {
        const data: string[] = line.split(',');

        if (data[0] !== '') {
          contentLine = { email: data[0] };
          contentArr.push(contentLine);
        }

        if (data.length > 1) {
          contentLine = { phone: data[1] };
          contentArr.push(contentLine);
        }
      });

      if (contentArr.length === 0 || unsubType === 0) {
        error.value = t('myLists.importSub.missingInput');
        setTimeout(resetError, 5000);
        return false;
      }

      const importUnsub: ShopsListsImportUnsubInputItem = {
        id_shop: idShop,
        content: JSON.stringify(contentArr),
        unsub_type: unsubType,
        state: queueState.QUEUED,
        date_creation: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      const result = await InsertShopsListsImportUnsub([importUnsub]);

      if (result.err || !result.status) {
        return showToastError(t('myLists.importSub.insertError'));
      }

      await showToastSuccess(t('myLists.importSub.insertSuccess'));
      return router.push({ name: 'lists-manage' });
    };

    const handleSelected = (options: {code: number; icon: string; name: string }[]) => {
      options.forEach((option) => {
        if (option.code === 0) {
          selectedTypes.value = [];
          selectedTypes.value.push({
            code: 1,
            icon: 'fas fa-cog fa-fw',
            name: t('myLists.unsub.icons.autoEmail'),
          },
          {
            code: 2,
            icon: 'fa fa-mobile-alt fa-fw',
            name: t('myLists.unsub.icons.autoSms'),
          },
          {
            code: 4,
            icon: 'fas fa-envelope fa-fw',
            name: t('myLists.unsub.icons.newsletter'),
          },
          {
            code: 8,
            icon: 'fa fa-mobile-alt fa-fw',
            name: t('myLists.unsub.icons.bulkSms'),
          });
        }
      });
    };

    return {
      selectedTypes,
      handleSelected,
      t,
      input,
      items,
      executeAction,
      error,
    };
  },
};
