<template>
  <div class="field-wrapper">
    <BaseInformation message="automatedScenarios.informations.abandonPanier" />
  </div>
</template>

<script lang="ts">
import BaseInformation from '@/components/fields/BaseInformation.vue';
import DeclencheurAbandonPanierMetaData, {
  DeclencheurAbandonPanierData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurAbandonPanierMetadata';
import {
  defineComponent, onMounted, PropType, SetupContext,
} from 'vue';

export default defineComponent({
  name: 'DeclencheurAbandonPanier',
  components: {
    BaseInformation,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurAbandonPanierData>,
      required: true,
      default() {
        return DeclencheurAbandonPanierMetaData.Create();
      },
    },
  },

  emits: ['update:modelValue'],

  setup(_: any, context: SetupContext) {
    const update = () => {
      context.emit('update:modelValue', DeclencheurAbandonPanierMetaData.Create());
    };

    onMounted(update);

    return {};
  },
});
</script>
