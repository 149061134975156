import { GetShopsConfigurationList } from '@/composables/shop/ShopsConfiguration';
import { Logout, UserState } from '@/composables/User';
import fetchShopInformation from '@/composables/shop/Shops';
import * as semver from 'semver';

const ecommerceModules: Record<string, any> = {
  magento: {
    name: 'Magento',
    downloadLink: 'https://media.shopimind.io/modules/magento/last/shopymind-v3.tgz',
    downloadInstructions: [
    ],
    installationInstructions: [
      'complete.connectShop.connectModule.installationInstructions.magento.one',
      'complete.connectShop.connectModule.installationInstructions.magento.two',
    ],
  },
  magento2: {
    name: 'Magento 2',
    downloadLink: 'https://media.shopimind.io/modules/magento2/shopimind.zip',
    downloadInstructions: [],
    installationInstructions: [
      'complete.connectShop.connectModule.installationInstructions.magento2.one',
      'complete.connectShop.connectModule.installationInstructions.magento2.two',
    ],
  },
  shopify: {
    name: 'Shopify',
    downloadInstructions: [],
    installationInstructions: [],
  },
  prestashop: {
    name: 'Prestashop',
    downloadLink: 'https://media.shopimind.io/modules/prestashop/last/shopimind.zip',
    downloadInstructions: [
      'complete.connectShop.installModule.downloadInstructions.prestashop.one',
      'complete.connectShop.installModule.downloadInstructions.prestashop.two',
      'complete.connectShop.installModule.downloadInstructions.prestashop.three',
    ],
    installationInstructions: [
      'complete.connectShop.connectModule.installationInstructions.prestashop.one',
      'complete.connectShop.connectModule.installationInstructions.prestashop.two',
    ],
  },
  woocommerce: {
    name: 'Woocommerce',
    downloadLink: 'https://media.shopimind.io/modules/woocommerce/shopimind.zip',
    downloadInstructions: [],
    installationInstructions: [
      'complete.connectShop.connectModule.installationInstructions.woocommerce.one',
      'complete.connectShop.connectModule.installationInstructions.woocommerce.two',
    ],
  },
  thelia: {
    name: 'Thelia (Bêta)',
    downloadLink: null,
    downloadInstructions: [],
    installationInstructions: [
      'complete.connectShop.connectModule.installationInstructions.thelia.one',
      'complete.connectShop.connectModule.installationInstructions.thelia.two',
    ],
  },
};

export const getEcommerceModule = (ecommerce: string) => ecommerceModules[ecommerce];

export const checkIfDbCreated = async () => {
  try {
    const migration_pending = await GetShopsConfigurationList({
      shopId: UserState.activeShop?.id ?? 0,
      key: 'migration_pending',
      fields: 'value',
      limit: 0,
      offset: 0,
    });
    if (migration_pending === null) {
      await Logout();
      return true;
    }
    return migration_pending && migration_pending.length && migration_pending[0].value && migration_pending[0].value === '0';
  } catch (e) {
    await Logout();
  }
  return true;
};

export const checkIfShopConnected = async () => {
  const shopsInfosData = await fetchShopInformation({ shopId: UserState.activeShop?.id ?? 0 });
  return shopsInfosData?.urlClient !== '' && shopsInfosData?.currentBuild !== 0 && shopsInfosData?.currentVersion && shopsInfosData?.currentVersion !== ''
    && semver.gte(shopsInfosData?.currentVersion, '3.0.0');
};

export const getShopConfiguredData = async () => {
  const shopsInfosData = await fetchShopInformation({ shopId: UserState.activeShop?.id ?? 0 });
  return shopsInfosData?.urlClient !== '' && shopsInfosData?.currentBuild !== 0 && shopsInfosData?.currentVersion && shopsInfosData?.currentVersion !== ''
    && semver.gte(shopsInfosData?.currentVersion, '3.0.0');
};
