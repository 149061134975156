import {
  TemplateParentTypeEnum,
  WidgetTypeConfig,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import emailWidgetImageConfig from '@/components/template-builder/config/templates/email/widgets/widget-image-config';
// eslint-disable-next-line import/no-cycle
import facebookWidgetImageConfig from '@/components/template-builder/config/templates/facebookmessenger/widgets/widget-image-config';
// eslint-disable-next-line import/no-cycle
import popupWidgetImageConfig from '@/components/template-builder/config/templates/popup/widgets/widget-image-config';
// eslint-disable-next-line import/no-cycle
import embedWidgetImageConfig from '@/components/template-builder/config/templates/embed/widgets/widget-image-config';

const imageConfig = {
  [TemplateParentTypeEnum.FACEBOOKMESSENGER]: facebookWidgetImageConfig,
  [TemplateParentTypeEnum.EMAIL]: emailWidgetImageConfig,
  [TemplateParentTypeEnum.POPUP]: popupWidgetImageConfig,
  [TemplateParentTypeEnum.EMBED]: embedWidgetImageConfig,
};

const getWidgetImageConfig = (templateType: string): WidgetTypeConfig => {
  if (
    templateType === TemplateParentTypeEnum.FACEBOOKMESSENGER
    || templateType === TemplateParentTypeEnum.EMAIL
    || templateType === TemplateParentTypeEnum.POPUP
    || templateType === TemplateParentTypeEnum.EMBED
  ) {
    return imageConfig[templateType];
  }
  return {};
};

export default getWidgetImageConfig;
