
import { useI18n } from 'vue-i18n';
import Card from 'primevue/card';
import Button from 'primevue/button';
import {defineComponent} from "vue";

export default defineComponent({
  name: 'NotFoundComponent404',
  components: {
    Card,
    Button,
  },

  setup() {
    const { t } = useI18n();
    return { t };
  },
});
