
import {
  computed,
  defineComponent, PropType, reactive, ref, Ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { asInt } from '@/helpers';
import { AutomatedScenarioState as state } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';

export interface FilterGlobalInformations {
    custom_title: string;
    custom_description: string;
    outputs_number: number;
    initial_number_outputs?: null|number;
}

export default defineComponent({
  name: 'FilterGlobalInformations',

  components: {
    BaseTextInput,
    FieldErrors,
  },

  props: {
    modelValue: {
      type: Object as PropType<FilterGlobalInformations>,
      required: true,
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FilterGlobalInformations; formValidation: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const isEdit: Ref<boolean> = ref(state.selectedOperator.operatorAddMethod === null && state.selectedOperator.operatorId !== '');

    const initialNumberOutput = asInt(props.modelValue.outputs_number);
    const filters = reactive<FilterGlobalInformations>({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: asInt(props.modelValue.outputs_number),
      initial_number_outputs: isEdit.value ? initialNumberOutput : null,
    });

    const decreasingNumberOfBranches = computed(() => isEdit.value && (filters.outputs_number < initialNumberOutput));

    watch(filters, () => {
      emit('update:modelValue', filters);
    });

    const selectNumberOutputs = (nbrOutputs: number): void => {
      filters.outputs_number = asInt(nbrOutputs);
    };

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      filters,
      selectNumberOutputs,
      decreasingNumberOfBranches,
    };
  },
});
