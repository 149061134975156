
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

interface UnsubItem {
  icon: string;
  title: string;
}

export default defineComponent({
  name: 'UnsubRenderer',

  props: {
    data: {
      type: Number,
      required: true,
    },
  },

  setup(props: { data: number }) {
    /* eslint no-bitwise: ["error", { "allow": ["&"] }] */
    const { t } = useI18n();

    const items: UnsubItem[] = [];

    if ((props.data & 1) === 1) {
      items.push({
        icon: 'fas fa-cog fa-fw fa-lg',
        title: t('myLists.unsub.icons.autoEmail'),
      });
    }

    if ((props.data & 2) === 2) {
      items.push({
        icon: 'fa fa-mobile-alt fa-fw fa-lg',
        title: t('myLists.unsub.icons.autoSms'),
      });
    }

    if ((props.data & 4) === 4) {
      items.push({
        icon: 'fas fa-envelope fa-fw fa-lg',
        title: t('myLists.unsub.icons.newsletter'),
      });
    }

    if ((props.data & 8) === 8) {
      items.push({
        icon: 'fa fa-mobile-alt fa-fw fa-lg',
        title: t('myLists.unsub.icons.bulkSms'),
      });
    }

    if ((props.data & 16) === 16) {
      items.push({
        icon: 'far fa-times-circle fa-lg',
        title: t('myLists.unsub.icons.noSolicitation'),
      });
    }

    return {
      items,
    };
  },
});
