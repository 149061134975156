<template>
  <div
    class="flex gap-2"
    :class="className"
  >
    <i
      v-if="showIcon"
      class="fa-light fa-file-circle-info"
    />
    <span
      v-if="label"
    >
      {{ label }}
    </span>
    <span
      class="cursor-pointer underline"
      @click="openFile"
    >
      {{ value }}
    </span>
    <ProgressSpinner
      v-if="loading"
      style="width: 15px; height: 15px"
      stroke-width="8"
      animation-duration="1.0s"
      aria-label="Loading contact"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ProgressSpinner from 'primevue/progressspinner';
import { getCustomer, getOrder, getCart } from '@/composables/data-explorer/DataExplorer';
import { DataExplorerDataType } from '@/types';
import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { showToastError } from '@/helpers';

export default defineComponent({
  name: 'DataExplorerLink',

  components: {
    ProgressSpinner,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    value: {
      type: String,
      required: true,
    },

    className: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: DataExplorerDataType.CONTACT_FILE,
    },

    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },

    customKey: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const loading = ref(false);

    const openFile = async () => {
      try {
        loading.value = true;
        const openedDatas = store.getters['dataExplorer/getOpenedDatas'];

        const mapGet: Record<string, any> = {
          [DataExplorerDataType.CONTACT_FILE]: {
            compareData: (openedData: any) => openedData.generalInformation.data.idShopCustomer === props.id,
            getData: async () => getCustomer(props.id as number),
            storeAction: (data: any) => store.dispatch('dataExplorer/openContactFile', { customer: data }),
          },

          [DataExplorerDataType.ORDER_FILE]: {
            compareData: (openedData: any) => (props.customKey ? openedData[props.customKey] === props.id : openedData.idShopOrder === props.id),
            getData: async () => (props.customKey ? getOrder(props.id as number, props.customKey) : getOrder(props.id as number)),
            storeAction: (data: any) => store.dispatch('dataExplorer/openOrderFile', { order: data }),
          },

          [DataExplorerDataType.CART_FILE]: {
            compareData: (openedData: any) => openedData.idCart === props.id,
            getData: async () => getCart(props.id as string),
            storeAction: (data: any) => store.dispatch('dataExplorer/openCartFile', { cart: data }),
          },
        };

        let data = openedDatas
          .filter((openedData: any) => openedData.dataType === props.type)
          .find((openedData: any) => mapGet[props.type].compareData(openedData));

        if (!data) {
          data = await mapGet[props.type].getData();
        }

        if (data) {
          mapGet[props.type].storeAction(data);
        } else {
          await showToastError(t('dataExplorer.noDataForThisFile'));
        }
      } catch (err) {
        await showToastError(t('dataExplorer.noDataForThisFile'));
      } finally {
        loading.value = false;
      }
    };

    return {
      loading,
      openFile,
    };
  },
});
</script>

<style lang="scss" scoped>
.order-price {
  color: $brand-color-primary;
  font-size: 1.5rem;
}
</style>
