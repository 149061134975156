
import {
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import SelectButton from 'primevue/selectbutton';
import { StringMap } from '@/types';

export default defineComponent({
  name: 'BaseButtonGroup',

  components: {
    SelectButton,
  },

  props: {
    modelValue: {
      type: [Array, String, Number, Boolean] as PropType<string[] | string | number | boolean>,
      required: true,
    },

    options: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    let option: Ref;
    if (props.multiple && Array.isArray(props.modelValue)) {
      option = ref([]);
    } else {
      option = ref(props.modelValue);
    }

    watch([option], () => emit('update:modelValue', option.value));

    onMounted(() => {
      if (props.multiple && Array.isArray(props.modelValue)) {
        props.modelValue.forEach((value) => {
          option.value.push(value);
        });
      }
    });

    return {
      option,
    };
  },
});
