
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  Ref,
  ref,
} from 'vue';
import Button from 'primevue/button';
import FileUpload from 'primevue/fileupload';
import { isValidEmail } from '@/helpers/Email';
import { useToast } from 'primevue/usetoast';
import ColumnMappings from '@/views/mes-listes/customers/ImportCustomers/ColumnMappings.vue';
import Textarea from 'primevue/textarea';
import Badge from 'primevue/badge';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { store } from '@/store';
import { ManualListImportConfigData } from '@/types/lists';
import Tooltip from 'primevue/tooltip';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';

import { parse } from 'papaparse';
import chardet from 'chardet';
import iconv from 'iconv-lite';
import { read, utils } from 'xlsx';

export default defineComponent({
  name: 'ConfigManualFile',
  components: {
    SpmButton,
    Button,
    FileUpload,
    ColumnMappings,
    Textarea,
    Badge,
    ConfirmDialog,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t } = useI18n();
    const toast = useToast();
    const showMappings = ref(false);
    const fileStr = ref((store.getters['listImportEditor/getConfigData'] as ManualListImportConfigData).dataString);
    const confirm = useConfirm();

    const fileData = ref<any>([]);

    const files: Ref<File | undefined> = ref();

    const formatSize = (bytes: number) => {
      if (bytes === 0) {
        return '0 B';
      }

      const k = 1000;
      const dm = 3;
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
    };
    const onSelectedFiles = (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: t('myLists.importContacts.errors.maxSizeError', ['700000000']),
          life: 3000,
        });
      }
    };

    const parseData = (data: any) => new Promise((resolve) => {
      parse(data, {
        complete: (results: any) => {
          resolve(results.data);
        },
      });
    });

    const onTemplatedUpload = async (event: any) => {
      if (!event.files.length) {
        toast.add({
          severity: 'info',
          summary: 'Success',
          detail: 'Upload error',
          life: 3000,
        });
        return;
      }

      // eslint-disable-next-line prefer-destructuring
      files.value = event.files[0];

      let buffer = await event.files[0].arrayBuffer();
      buffer = Buffer.from(buffer);

      const detectedEncoding = chardet.detect(Buffer.from(buffer));
      let encodingName = 'UTF-8';
      if (detectedEncoding) {
        encodingName = detectedEncoding;
      }

      const extension = event.files[0].name.slice(event.files[0].name.lastIndexOf('.') + 1);
      if (extension === 'xls' || extension === 'xlsx') {
        const workbook = read(buffer);
        buffer = utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
        encodingName = 'UTF-8';
      }

      const decoded = iconv.decode(Buffer.from(buffer), encodingName);

      fileStr.value = iconv.encode(decoded, 'UTF-8').toString();

      fileData.value = await parseData(fileStr.value);

      store.commit('listImportEditor/setConfigData', {
        ...store.getters['listImportEditor/getConfigData'],
        dataArray: fileData.value,
      });
    };

    const onRemoveTemplatingFile = (event: any) => {
      files.value = undefined;
      fileStr.value = '';
    };

    const isValidatePhone = (phone: string) => {
      const re = new RegExp('^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$');
      return re.test(phone);
    };

    const checkIfValidEmailOrPhoneNoExists = () => fileData.value.some((row: any[]) => {
      const result = row.filter((field) => isValidEmail(field) || isValidatePhone(field));
      return result.length !== 0;
    });

    const validateFileInput = () => {
      confirm.require({
        message: t('myLists.importContacts.assignment.certification'),
        header: t('confirmation'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('Yes'),
        rejectLabel: t('No'),
        accept: async () => {
          const emailSuccess = checkIfValidEmailOrPhoneNoExists();

          if (!emailSuccess) {
            toast.add({
              severity: 'error', summary: 'Error', detail: t('myLists.importContacts.assignment.dataValidationError'), life: 3000,
            });
            return;
          }
          showMappings.value = true;
        },
        reject: () => {
          // do not go to next step
          showMappings.value = false;
        },
      });
    };

    return {
      t,
      showMappings,
      files,
      fileStr,
      fileData,

      onRemoveTemplatingFile,
      formatSize,
      onTemplatedUpload,
      onSelectedFiles,
      validateFileInput,
    };
  },
});
