
import {
  computed,
  defineComponent,
  ref,
  watch,
} from 'vue';
import { SpmTableState } from '@/types';
import MappingsPreview from '@/views/mes-listes/customers/ImportCustomers/MappingsPreview.vue';
import { store } from '@/store';
import ConfigManualCopyPaste
  from '@/views/mes-listes/customers/ImportCustomers/configs/manual/ConfigManualCopyPaste.vue';
import ConfigManualFile from '@/views/mes-listes/customers/ImportCustomers/configs/manual/ConfigManualFile.vue';
import { ManualListImportConfigData } from '@/types/lists';

export default defineComponent({
  name: 'ConfigManual',
  components: {
    ConfigManualFile,
    ConfigManualCopyPaste,
    MappingsPreview,
  },

  props: {
    displayPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props: { displayPreview: boolean }) {
    const typeImport = computed(() => store.getters['listImportEditor/getTypeImport']);
    const showPreview = ref(false);
    const configData = computed(() => (store.getters['listImportEditor/getConfigData'] as ManualListImportConfigData));
    const dynamicColumns = ref<string[]>([]);
    const previewTableData = ref<SpmTableState>({
      items: [],
      total: 0,
      error: '',
    });

    watch(() => props.displayPreview, async () => {
      if (props.displayPreview) {
        dynamicColumns.value = (store.getters['listImportEditor/getConfigData'] as ManualListImportConfigData).columnsMapping;

        const dataArr: Record<string, string>[] = [];

        if (configData.value.dataArray.length && dynamicColumns.value.length) {
          const slicedDataArray = configData.value.dataArray.slice(0, 50);
          slicedDataArray.forEach((row: any) => {
            const objRow: Record<string, string> = {};
            row.forEach((field: string, index: number) => {
              objRow[dynamicColumns.value[index]] = field;
            });
            dataArr.push(objRow);
          });
        }

        previewTableData.value.items = dataArr;
        previewTableData.value.total = dataArr.length;
        previewTableData.value.error = '';

        showPreview.value = true;
      } else {
        showPreview.value = false;
      }
    });

    return {
      typeImport,
      showPreview,
      dynamicColumns,
      previewTableData,
    };
  },
});
