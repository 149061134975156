<template>
  <SelectButton
    v-model="option"
    :options="options"
    option-label="label"
    option-value="value"
    :multiple="multiple"
    option-disabled="disabled"
    :unselectable="allowEmpty"
  >
    <template #option="slotProps">
      <i
        v-if="slotProps.option.icon"
        :title="slotProps.option.label"
        class="far"
        :class="slotProps.option.icon"
        data-test-id="button"
      />
      <img
        v-else-if="slotProps.option.src"
        :src="slotProps.option.src"
        class="spm_icon"
        data-test-id="button"
      >
      <span
        v-else
        class="p-button-label"
        data-test-id="button"
      >
        {{ slotProps.option.title ?? slotProps.option.label }}
      </span>
    </template>
  </SelectButton>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import SelectButton from 'primevue/selectbutton';
import { StringMap } from '@/types';

export default defineComponent({
  name: 'BaseButtonGroup',

  components: {
    SelectButton,
  },

  props: {
    modelValue: {
      type: [Array, String, Number, Boolean] as PropType<string[] | string | number | boolean>,
      required: true,
    },

    options: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    let option: Ref;
    if (props.multiple && Array.isArray(props.modelValue)) {
      option = ref([]);
    } else {
      option = ref(props.modelValue);
    }

    watch([option], () => emit('update:modelValue', option.value));

    onMounted(() => {
      if (props.multiple && Array.isArray(props.modelValue)) {
        props.modelValue.forEach((value) => {
          option.value.push(value);
        });
      }
    });

    return {
      option,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-selectbutton {
  display: flex;
  :deep() .p-button {
    font-size: 14px !important;
    background-color: $white;
    flex-grow: 1;
    color: $tuna !important;
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid $heather !important;
    border-left: none !important;
    height: $field-default-height;

    &:first-child {
      border-left: 1px solid $heather !important;
    }
    &:hover:not(.p-highlight) {
      background-color: #f2f2f2 !important;
    }
    &.p-highlight {
      background-color: $brand-color-primary;
      color: $white !important;

      &:hover {
        background-color: $brand-color-primary;
      }

      & > .spm_icon {
        filter: none !important;
      }
    }

    > .spm_icon {
      width: 1.4rem;
      min-height: 1.01rem;
      filter: $heather-filter-for-icons;
    }
  }
}
</style>
