// eslint-disable-next-line import/no-cycle
import {
  getSmartListHtmlELementFromState,
} from '@/composables/template-editor/TemplateEditor';

import { Maybe } from '@/types/generated-types/graphql';

// eslint-disable-next-line import/no-cycle
import {
  getTemplateIframeDocument,
} from '../utils/helpers';

const getContent = (selector: string, name: string, widgetId: string) => {
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);

  if (element) {
    // This getter can be involved by translation
    const content: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

    if (content) {
      return content;
    }

    return element.innerHTML;
  }
  return undefined;
};

export default getContent;
