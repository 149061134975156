
import {
  defineComponent, ref, Ref, SetupContext, watch,
} from 'vue';
import PanelMenu from 'primevue/panelmenu';
import SpmPanelMenuItemCommand from '@/components/spm-primevue/SpmPanelMenuItemCommand.vue';

export default defineComponent({
  name: 'SpmPanelMenu',

  components: {
    PanelMenu,
    SpmPanelMenuItemCommand,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },

    expandedKeys: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    data: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ['update:expandedKeys'],

  setup(props, { emit }: SetupContext) {
    const expandedKeysLocal: Ref<any> = ref(props.expandedKeys);

    watch(() => props.expandedKeys, () => {
      expandedKeysLocal.value = props.expandedKeys;
    });

    watch(expandedKeysLocal, () => {
      emit('update:expandedKeys', expandedKeysLocal.value);
    }, { deep: true });

    return {
      expandedKeysLocal,
    };
  },
});
