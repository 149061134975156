import { ComponentNameEnum, BaseButtonFieldConfig } from '@/types';

const defaultValue = 'all';
const property = 'show-for-';

const mediaDisplayFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    small: {
      title: 'templateBuilder.fields.displayOnMobile',
      iconClass: 'fa-mobile',
      property,
      defaultValue,
    },
    desktop: {
      title: 'templateBuilder.fields.displayOnDesktop',
      iconClass: 'fa-desktop',
      property,
      defaultValue,
    },
    all: {
      title: 'templateBuilder.fields.displayOnDesktop',
      iconClass: 'fa-redo',
      property,
      defaultValue,
    },
  },
};

export default mediaDisplayFieldConfig;
