// eslint-disable-next-line import/prefer-default-export,import/no-cycle
import Metadata, { getComponentMetadataById } from '@/components/automated-scenarios/metadata';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  BoxDependency, Dependencies, FilterSegment,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState,
  getAllParentsOfBoxToRoot,
  getOperatorParents,
  OperatorAddType,
  OperatorMeta,
} from '@/composables/AutomatedScenarios';

/* Box dependency check */
const satisfyOperatorsDependencies = (dependencies: any, directParentsId: string[], allParentsId: string[]): boolean => {
  let result = false;

  const directParents = directParentsId.map((item: string) => {
    const { id } = AutomatedScenarioState.scenario.getOperatorData<{ id: string }>(item) ?? { id: '' };
    return { box_id: id, operator_id: item };
  });
  const allParents = allParentsId.map((item: string) => {
    const { id } = AutomatedScenarioState.scenario.getOperatorData<{ id: string }>(item) ?? { id: '' };
    return { box_id: id, operator_id: item };
  });

  if (dependencies.direct_parent.length) {
    dependencies.direct_parent.forEach((dependency: string|BoxDependency) => {
      if (!result) {
        const boxId = (typeof dependency === 'string') ? dependency : dependency.boxId;
        const matched = directParents.filter((item: any) => item.box_id === boxId);
        if (matched.length) {
          if (typeof dependency === 'string') {
            // simple box dependency
            result = true;
          } else {
            // operator dependency with params
            matched.forEach((item: any) => {
              if (!result
                && AutomatedScenarioState.scenario.getOperatorData<any>(item.operator_id)[dependency.attribute] === dependency.value) {
                result = true;
              }
            });
          }
        }
      }
    });
  }

  if (!result && dependencies.any_parents.length) {
    dependencies.any_parents.forEach((dependency: string|BoxDependency) => {
      if (!result) {
        const boxId = (typeof dependency === 'string') ? dependency : dependency.boxId;
        const matched = allParents.filter((item: any) => item.box_id === boxId);

        if (matched.length) {
          if (typeof dependency === 'string') {
            // simple box dependency
            result = true;
          } else {
            // operator dependency with params
            matched.forEach((item: any) => {
              if (!result
                && AutomatedScenarioState.scenario.getOperatorData<any>(item.operator_id)[dependency.attribute] === dependency.value) {
                result = true;
              }
            });
          }
        }
      }
    });
  }

  return result;
};

/* Segment dependency check */
const satisfySegmentsDependencies = (dependencies: any, directParentsId: string[], allParentsId: string[], currentActiveSegments: string[] = []): boolean => {
  let result = false;

  if (dependencies.direct_parents_contain.length) {
    result = AutomatedScenarioState.operators
      // operator parents list
      .filter((operator) => directParentsId.includes(operator.operatorId))
      // keep currentCart, currentOrder, customer and purchaseHistory filters
      .filter((operator) => ['boxfiltre_1', 'boxfiltre_2', 'boxfiltre_3', 'boxfiltre_4'].includes(operator.id))
      // Build list with all segments in lineage
      .reduce((acc: string[], operator) => [
        ...acc,
        ...Object.keys(AutomatedScenarioState.scenario.data.operators[operator.operatorId].custom.inclusion),
        ...Object.keys(AutomatedScenarioState.scenario.data.operators[operator.operatorId].custom.exclusion),
      ], currentActiveSegments)
      // intersect with dependencies
      .filter((segmentId) => dependencies.direct_parents_contain.includes(segmentId))
      .length > 0;
  }

  if (!result && dependencies.any_parents_contain.length) {
    result = AutomatedScenarioState.operators
      // operator parents list
      .filter((operator) => allParentsId.includes(operator.operatorId))
      // keep currentCart, currentOrder, customer and purchaseHistory filters
      .filter((operator) => ['boxfiltre_1', 'boxfiltre_2', 'boxfiltre_3', 'boxfiltre_4'].includes(operator.id))
      // Build list with all segments in lineage
      .reduce((acc: string[], operator) => [
        ...acc,
        ...Object.keys(AutomatedScenarioState.scenario.data.operators[operator.operatorId].custom.inclusion),
        ...Object.keys(AutomatedScenarioState.scenario.data.operators[operator.operatorId].custom.exclusion),
      ], [])
      // intersect with dependencies
      .filter((segmentId) => dependencies.any_parents_contain.includes(segmentId))
      .length > 0;
  }

  return result;
};

/* Recovery of the parents of the box according to the mode of addition */
const getParentsData = () => {
  let directParentsId: string[] = [];
  let allParentsId: string[] = [];
  // TODO : REPLACE_CURRENT
  if (AutomatedScenarioState.selectedOperator.operatorAddMethod === null) {
    // Mode edit (only for segments)
    directParentsId = getOperatorParents(AutomatedScenarioState.selectedOperator.operatorId);
    allParentsId = getAllParentsOfBoxToRoot(AutomatedScenarioState.selectedOperator.operatorId);
  } else if (AutomatedScenarioState.selectedOperator.operatorAddMethod === OperatorAddType.ADD_ABOVE) {
    // Add above mode
    directParentsId = getOperatorParents(AutomatedScenarioState.selectedOperator.operatorId);
    allParentsId = getAllParentsOfBoxToRoot(AutomatedScenarioState.selectedOperator.operatorId);
  } else if (AutomatedScenarioState.selectedOperator.operatorAddMethod === OperatorAddType.ADD_BELOW) {
    // Add below mode
    directParentsId.push(AutomatedScenarioState.selectedOperator.operatorId);
    allParentsId.push(AutomatedScenarioState.selectedOperator.operatorId);
    allParentsId = [...new Set([...allParentsId, ...getAllParentsOfBoxToRoot(AutomatedScenarioState.selectedOperator.operatorId)])];

    if (AutomatedScenarioState.selectedOperator.closureBranchData) {
      // if it is a closing of branches, take into account all the parents
      AutomatedScenarioState.selectedOperator.closureBranchData.forEach((closureBranchData) => {
        directParentsId = [...new Set([...directParentsId, ...[closureBranchData.operatorId]])];
        allParentsId = [...new Set([...allParentsId, ...[closureBranchData.operatorId]])];
        allParentsId = [...new Set([...allParentsId, ...getAllParentsOfBoxToRoot(closureBranchData.operatorId)])];
      });
    }
  } else if (AutomatedScenarioState.selectedOperator.operatorAddMethod === OperatorAddType.REPLACE_CURRENT) {
    directParentsId = getOperatorParents(AutomatedScenarioState.selectedOperator.operatorId);
    allParentsId = getAllParentsOfBoxToRoot(AutomatedScenarioState.selectedOperator.operatorId);
  }

  return {
    directParentsId,
    allParentsId,
  };
};

/* Retrieve and format the dependencies of a box/segment according to the type of the campaign */
const getFormattedDependencies = (typeCampaign: TypeCampaignEnum, dependencies: Dependencies|null) => {
  const operatorsDependencies = (dependencies !== null && dependencies[typeCampaign] && dependencies[typeCampaign]?.operators)
    ? dependencies[typeCampaign]?.operators : false;
  const segmentsDependencies = (dependencies !== null && dependencies[typeCampaign] && dependencies[typeCampaign]?.segments)
    ? dependencies[typeCampaign]?.segments : false;

  return {
    operatorsDependencies: {
      direct_parent: (operatorsDependencies && operatorsDependencies?.direct_parents
        && operatorsDependencies?.direct_parents.length) ? operatorsDependencies?.direct_parents : [],
      any_parents: (operatorsDependencies && operatorsDependencies?.any_parents
        && operatorsDependencies?.any_parents.length) ? operatorsDependencies?.any_parents : [],
    },
    segmentsDependencies: {
      direct_parents_contain: (segmentsDependencies && segmentsDependencies?.direct_parents_contain
        && segmentsDependencies?.direct_parents_contain.length) ? segmentsDependencies?.direct_parents_contain : [],
      any_parents_contain: (segmentsDependencies && segmentsDependencies?.any_parents_contain
        && segmentsDependencies?.any_parents_contain.length) ? segmentsDependencies?.any_parents_contain : [],
    },
  };
};

/* Checking if the box / segment validates at least one dependency */
const satisfyDependencies = (dependencies: Dependencies|null,
  typeCampaign: TypeCampaignEnum,
  directParentsId: string[],
  allParentsId: string[],
  currentActiveSegments: string[] = []): boolean => {
  let result = true;
  const { operatorsDependencies, segmentsDependencies } = getFormattedDependencies(typeCampaign, dependencies);

  const hasOperatorsDependencies = operatorsDependencies.direct_parent.length || operatorsDependencies.any_parents.length;
  const hasSegmentsDependencies = segmentsDependencies.direct_parents_contain.length || segmentsDependencies.any_parents_contain.length;

  if (hasOperatorsDependencies && hasSegmentsDependencies) {
    result = satisfyOperatorsDependencies(operatorsDependencies, directParentsId, allParentsId)
      || satisfySegmentsDependencies(segmentsDependencies, directParentsId, allParentsId, currentActiveSegments);
  } else if (hasOperatorsDependencies) {
    result = satisfyOperatorsDependencies(operatorsDependencies, directParentsId, allParentsId);
  } else if (hasSegmentsDependencies) {
    result = satisfySegmentsDependencies(segmentsDependencies, directParentsId, allParentsId, currentActiveSegments);
  }

  return result;
};

/* Recovery of operators valid for addition depending on the type of campaign, compatibility and dependencies */
export const getaAvailableOperators = (typeCampaign: TypeCampaignEnum, onlyCheckDependencies: boolean) => {
  const { directParentsId, allParentsId } = getParentsData();

  let incompatiblesNextOperators: string[] = [];

  /* Checking compatibility between boxes */
  if (!onlyCheckDependencies) {
    directParentsId.forEach((operatorId) => {
      const { id } = AutomatedScenarioState.scenario.getOperatorData<{ id: string }>(operatorId) ?? { id: '' };
      if (id !== '') {
        const metadata = getComponentMetadataById(id);
        if (metadata && metadata?.Meta?.incompatibilities) {
          const getIncompatibilitiesNextOperators = (metadata?.Meta?.incompatibilities.hasOwnProperty(typeCampaign))
            ? metadata?.Meta?.incompatibilities[typeCampaign]?.next_operators : [];
          if (getIncompatibilitiesNextOperators?.length) {
            incompatiblesNextOperators = [...new Set([...incompatiblesNextOperators, ...getIncompatibilitiesNextOperators])];
          }
        }
      }
    });
  }

  return Object.values(Metadata)
    .filter((m) => m.Meta.availableInCampaign.includes(typeCampaign))
    .filter((m) => (onlyCheckDependencies ? true : !incompatiblesNextOperators.includes(m.Meta.id)))
    .filter((m) => satisfyDependencies(m.Meta?.dependencies ?? null, typeCampaign, directParentsId, allParentsId, []))
    .reduce((p, c) => {
      const kind: string = c.Meta?.kind ?? '';
      let ex: OperatorMeta[];

      switch (kind) {
        case 'action':
          ex = p.action;
          break;
        case 'declencheur':
          ex = p.declencheur;
          break;
        case 'filtre':
          ex = p.filtre;
          break;
        default:
          return p;
      }

      return {
        ...p,
        [c.Meta.kind]: [...ex, c],
      };
    }, {
      action: [] as OperatorMeta[],
      declencheur: [] as OperatorMeta[],
      filtre: [] as OperatorMeta[],
    });
};

/* Recovery of segments valid for addition depending on the type of campaign and dependencies */
export const getAvailableSegments = <T>(typeCampaign: TypeCampaignEnum, segments: FilterSegment<T>[], currentActiveSegments: string[]) => {
  const { directParentsId, allParentsId } = getParentsData();

  return segments
    .filter((segment: FilterSegment<T>) => segment.availableInCampaign.includes(typeCampaign))
    .filter((segment: FilterSegment<T>) => satisfyDependencies(segment?.dependencies ?? null,
      typeCampaign,
      directParentsId,
      allParentsId,
      currentActiveSegments));
};
