import { ActionTypeEnum, ComponentNameEnum } from '@/types';
import variables from '@/components/template-builder/utils/variables-list';

const getFacebookButtonsFieldConfig = (redirectTypes: Array<string> = [], defaultValue = ActionTypeEnum.REDIRECT, maxButtons = 3) => ({
  componentName: ComponentNameEnum.FACEBOOK_BUTTONS,
  options: {
    redirectTypes,
    defaultValue,
    maxButtons,
    redirectConfig: {
      label: 'templateBuilder.fields.webAddress',
      options: {
        defaultValue: '',
        variablesList: variables.allVariables,
        displayEmojis: false,
        displayVariables: true,
      },
    },
  },
});

export default getFacebookButtonsFieldConfig;
