import {
  TemplateStructureEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TabItem, ComponentNameEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { getCssPropertyByName } from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import { setCssProperty, setCssPropertyAndRemoveIfZero } from '@/components/template-builder/setters';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';

export const rowTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.LINE,
  label: 'templateBuilder.configs.line',
  tabPanel: {
    label: 'templateBuilder.configs.lineEdit',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.line',
        selector: 'body #spm_body table#{LINE_ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: 'body #spm_body table#{LINE_ID} > tbody > tr > td',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
        ],
      },
    ],
  },
};

const rowItemConfig: ConfigurationPanelStructure = {
  items: [
    rowTabItemConfig,
  ],
};

export default rowItemConfig;
