import { reactive, ref } from 'vue';
import { Get, List, ListResult } from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import {
  OperatorType, ShopsLists, ShopsListsImport, ShopsParticularLists,
} from '@/types/generated-types/graphql';
import { useI18n } from 'vue-i18n';

export enum TypeList {
  SYNC = 'sync',
  NEWSLETTER = 'newsletter',
  RETARGETING = 'retargeting',
  UNSUB = 'unsub',
  BOUNCES = 'bounces',
  SPAMS = 'spams',
}
enum ListImportParams {
  SEGMENTATION = 'segmentation',
}
enum ListImportStates {
  QUEUED = 'queued',
  PENDING = 'pending',
  PROCESSED = 'processed',
  CHECKING = 'checking',
  CHECKED = 'checked',
  REPORT = 'report',
}

export interface MyLists {
  ID: number;
  Name: string;
  TotalContacts: number;
  TotalSent: number;
  TotalUniqueViews: number;
  PercentUniqueViews: string;
  TotalUniqueClicks: number;
  PercentUniqueClicks: string;
  Subscribers: number;
  PercentSubscribers: string;
  NonSubscribers: number;
  PercentNonSubscribers: string;
  UnSubscribers: number;
  PercentUnSubscribers: string;
  SoftBounce: number;
  PercentSoftBounce: string;
  HardBounce: number;
  PercentHardBounce: string;
  Spams: number;
  PercentSpams: string;
  // utility variables
  typeList?: string;
  isValid?: boolean;
  autoUpdatedList?: boolean;
  idShopListImport?: number;
  type?: string;
}

interface MyListsArgs {
  id: number;
  name: string;
  entity: ShopsParticularLists | ShopsLists;
  autoUpdated?: boolean;
  idShopListImport?: number;
}

interface MyListsState {
  listing: MyLists[];
  totalItems: number;
  showImportPanel: boolean;
  insertedShopsListsImportId: number;
  header: string;
  idShopList: number;
  idShopListImportId: number;
  type: string;
  name: string;
  idCustomer: number| null;
  refreshComponent: number;
}

export const myListsState = reactive<MyListsState>({
  listing: [],
  totalItems: 0,
  showImportPanel: false,
  insertedShopsListsImportId: 0,
  header: '',
  idShopList: 0,
  idShopListImportId: 0,
  type: '',
  name: '',
  idCustomer: null,
  refreshComponent: 0,
});

export const listManageKey = ref(0);

export const myListMod = ref<MyLists>({
  HardBounce: 0,
  NonSubscribers: 0,
  PercentHardBounce: '',
  PercentNonSubscribers: '',
  PercentSoftBounce: '',
  PercentSpams: '',
  PercentSubscribers: '',
  PercentUnSubscribers: '',
  SoftBounce: 0,
  Spams: 0,
  Subscribers: 0,
  UnSubscribers: 0,
  ID: 0,
  Name: '',
  TotalContacts: 0,
  TotalSent: 0,
  TotalUniqueViews: 0,
  PercentUniqueViews: '0',
  TotalUniqueClicks: 0,
  PercentUniqueClicks: '0%',
});

function percentage(val: number): string {
  return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(val);
}

function mapMyLists(arg: MyListsArgs): MyLists {
  const NonSubscribers = arg.entity.total_contacts - (arg.entity.total_subscribers + arg.entity.total_unsubscribeds);

  let typeList = '';
  let isValid = true;
  let type = '';
  switch (arg?.entity.__typename) {
    case 'ShopsParticularLists':
      typeList = arg.entity.type_list;
      break;
    case 'ShopsLists':
      typeList = arg.entity.id_shop_list.toString();
      isValid = arg.entity.valid;
      type = arg.entity.type ?? '';
      break;
    default:
      break;
  }

  return {
    ID: arg.id,
    Name: arg.name,
    TotalContacts: arg.entity.total_contacts,
    TotalSent: arg.entity.total_sends,
    TotalUniqueViews: arg.entity.total_newsletters_viewed,
    PercentUniqueViews: percentage(arg.entity.total_newsletters_viewed / arg.entity.total_sends),
    TotalUniqueClicks: arg.entity.total_newsletters_clicked,
    PercentUniqueClicks: percentage(arg.entity.total_newsletters_clicked / arg.entity.total_newsletters_viewed),
    Subscribers: arg.entity.total_subscribers,
    PercentSubscribers: percentage(arg.entity.total_subscribers / arg.entity.total_contacts),
    NonSubscribers,
    PercentNonSubscribers: percentage(NonSubscribers / arg.entity.total_contacts),
    UnSubscribers: arg.entity.total_unsubscribeds,
    PercentUnSubscribers: percentage(arg.entity.total_unsubscribeds / arg.entity.total_contacts),
    SoftBounce: arg.entity.total_sends_soft_bounce,
    PercentSoftBounce: percentage(arg.entity.total_sends_soft_bounce / arg.entity.total_contacts),
    HardBounce: arg.entity.total_sends_hard_bounce,
    PercentHardBounce: percentage(arg.entity.total_sends_hard_bounce / arg.entity.total_contacts),
    Spams: arg.entity.total_sends_spam,
    PercentSpams: percentage(arg.entity.total_sends_spam / arg.entity.total_sends),
    typeList,
    isValid,
    autoUpdatedList: arg?.autoUpdated,
    idShopListImport: arg?.idShopListImport,
    type,
  };
}

export async function getAutoUpdatedRecord(idShopList: number) {
  const { items } = await List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        { field: 'id_shop_list', value: idShopList, operator: OperatorType.Equals },
        { field: 'params', value: ListImportParams.SEGMENTATION, operator: OperatorType.Equals },
        { field: 'state', value: ListImportStates.QUEUED, operator: OperatorType.Equals },
        { field: 'stay_updated', value: 1, operator: OperatorType.Equals },
      ],
    },
    fields: ['id_shop_list_import'],
  });
  return items.length;
}

async function getAutoUpdatedList() {
  const { items } = await List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'params',
          value: ListImportParams.SEGMENTATION,
          operator: OperatorType.Equals,
        },
        {
          field: 'state',
          value: ListImportStates.QUEUED,
          operator: OperatorType.Equals,
        },
        {
          field: 'stay_updated',
          value: 1,
          operator: OperatorType.Equals,
        },
      ],
    },
    fields: ['id_shop_list', 'id_shop_list_import'],
  });
  return items.map((val) => ({
    id_shop_list: val.id_shop_list,
    id_shop_list_import: val.id_shop_list_import,
  }));
}

async function getParticularLists(idShop: number, t: any) {
  const shopVersion = '3.0.0'; // todo: get from UserState
  let typeLists = [TypeList.SYNC, TypeList.RETARGETING].join(',');
  if (shopVersion >= '2.1.4') {
    typeLists = [TypeList.SYNC, TypeList.NEWSLETTER, TypeList.RETARGETING].join(',');
  }

  const particularLists = await List<ShopsParticularLists>({
    name: 'ShopsParticularLists',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'id_shop',
          value: idShop,
          operator: OperatorType.Equals,
        },
        {
          field: 'type_list',
          value: typeLists,
          operator: OperatorType.In,
        },
      ],
    },
    fields: [
      'id_shop_particular_list', 'type_list',
      'total_contacts', 'total_sends', 'total_newsletters_viewed', 'total_newsletters_clicked',
      'total_subscribers', 'total_unsubscribeds',
      'total_sends_soft_bounce', 'total_sends_hard_bounce', 'total_sends_spam',
    ],
  });
  myListsState.totalItems += particularLists.total;
  myListsState.listing = particularLists.items.map((val) => {
    let name = '';
    switch (val.type_list) {
      case TypeList.NEWSLETTER:
        name = t('myLists.manage.listNames.newsletter');
        break;
      case TypeList.RETARGETING:
        name = t('myLists.manage.listNames.retargeting');
        break;
      case TypeList.SYNC:
      default:
        name = t('myLists.manage.listNames.sync');
        break;
    }
    return mapMyLists({
      id: val.id_shop_particular_list,
      name,
      entity: val,
      autoUpdated: false,
      idShopListImport: 0,
    });
  });
}

export async function getShopsLists(idShop: number, fields: string[]) {
  return List<ShopsLists>({
    name: 'ShopsLists',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'id_shop',
          value: idShop,
          operator: OperatorType.Equals,
        },
        {
          field: 'valid',
          value: 1,
          operator: OperatorType.Equals,
        },
        {
          field: 'active',
          value: 1,
          operator: OperatorType.Equals,
        },
      ],
    },
    fields,
  });
}

export async function getShopsListsAndParticulars(idShop: number, fields: string[]) {
  const { t } = useI18n();

  const myLists: ListResult<ShopsLists> = {
    items: [],
    total: 0,
    err: '',
  };

  const shopVersion = '3.0.0'; // todo: get from UserState
  let typeLists = [TypeList.SYNC, TypeList.RETARGETING].join(',');
  if (shopVersion >= '2.1.4') {
    typeLists = [TypeList.SYNC, TypeList.NEWSLETTER, TypeList.RETARGETING].join(',');
  }
  const particularLists = await List<ShopsParticularLists>({
    name: 'ShopsParticularLists',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'id_shop',
          value: idShop,
          operator: OperatorType.Equals,
        },
        {
          field: 'type_list',
          value: typeLists,
          operator: OperatorType.In,
        },
      ],
    },
    fields: [
      'id_shop_particular_list', 'type_list',
    ],
  });

  myLists.err = particularLists.err;

  const shopsList = await getShopsLists(idShop, fields);

  myLists.err = shopsList.err;

  myLists.items = particularLists.items.map((pList) => {
    let name = '';
    switch (pList.type_list) {
      case TypeList.NEWSLETTER:
        name = t('myLists.manage.listNames.newsletter');
        break;
      case TypeList.RETARGETING:
        name = t('myLists.manage.listNames.retargeting');
        break;
      case TypeList.SYNC:
      default:
        name = t('myLists.manage.listNames.sync');
        break;
    }

    return {
      id_shop: idShop,
      id_shop_list: pList.id_shop_particular_list,
      name,
      total_contacts: 0,
      total_sends: 0,
      total_newsletters_viewed: 0,
      total_newsletters_clicked: 0,
      total_subscribers: 0,
      date_add: null,
      total_unsubscribeds: 0,
      date_modification: null,
      active: 1,
      total_sends_soft_bounce: 0,
      valid: true,
      total_sends_hard_bounce: 0,
      total_sends_spam: 0,
      whitelist: true,
    };
  });

  myLists.items = [...myLists.items, ...shopsList.items];
  return {
    items: myLists.items,
    total: myLists.total + shopsList.total,
    err: myLists.err,
  };
}

export async function getShopsListing(idShop: number, t: any) {
  const autoUpdatedList = await getAutoUpdatedList();
  const lists = await List<ShopsLists>({
    name: 'ShopsLists',
    settings: {
      limit: 0,
      offset: 0,
      order: [
        {
          field: 'date_modification',
          type: 'DESC'
        }
      ],
      filter: [
        {
          field: 'id_shop',
          value: idShop,
          operator: OperatorType.Equals,
        },
        {
          field: 'valid',
          value: 1,
          operator: OperatorType.Equals,
        },
        {
          field: 'active',
          value: 1,
          operator: OperatorType.Equals,
        },
      ],
    },
    fields: [
      'id_shop_list', 'name', 'type',
      'total_contacts', 'total_sends', 'total_newsletters_viewed', 'total_newsletters_clicked',
      'total_subscribers', 'total_unsubscribeds',
      'total_sends_soft_bounce', 'total_sends_hard_bounce', 'total_sends_spam', 'valid',
    ],
  });
  myListsState.totalItems += lists.total;

  myListsState.listing = [...myListsState.listing, ...lists.items.map((val) => mapMyLists({
    id: val.id_shop_list,
    name: val.name,
    entity: val,
    autoUpdated: !!autoUpdatedList.find((element) => val.id_shop_list === element.id_shop_list),
    idShopListImport: autoUpdatedList.find((element) => val.id_shop_list === element.id_shop_list)?.id_shop_list_import,
  }))];
}

export async function getMyLists(idShop: number, t: any) {
  if (!idShop) {
    return;
  }
  // await getParticularLists(idShop, t);
  await getShopsListing(idShop, t);
}

export async function getShopsListImportRecords(listId: number) {
  return List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'id_shop_list',
          value: listId,
          operator: OperatorType.Equals,
        },
        {
          field: 'state',
          value: [ListImportStates.QUEUED, ListImportStates.PENDING].join(','),
          operator: OperatorType.In,
        },
      ],
    },
    fields: ['id_shop_list', 'id_shop_list_import', 'stay_updated'],
  });
}

export async function checkShopsListImportStates(idShop: number, listId: Array<number>) {
  return List<ShopsListsImport>({
    name: 'ShopsListsImport',
    settings: {
      limit: 0,
      offset: 0,
      order: [],
      filter: [
        {
          field: 'id_shop',
          value: idShop,
          operator: OperatorType.Equals,
        },
        {
          field: 'id_shop_list',
          value: listId.join(','),
          operator: OperatorType.In,
        },
        {
          field: 'state',
          value: [ListImportStates.QUEUED, ListImportStates.PENDING].join(','),
          operator: OperatorType.In,
        },
      ],
    },
    fields: ['id_shop_list', 'id_shop_list_import'],
  });
}

export async function getShopsListRecord(idShopList: number, fields: string[]) {
  return Get<ShopsLists>({
    id: idShopList,
    name: 'ShopsLists',
    keyName: 'id_shop_list',
    fields,
  });
}
