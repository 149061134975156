<template>
  <Galleria
    v-model:visible="visibility"
    :value="listImages"
    :circular="true"
    :num-visible="4"
    container-style="max-width: 50%;"
    :full-screen="true"
    :show-item-navigators="true"
    :responsive-options="responsiveOptions"
  >
    <template #item="slotProps">
      <img
        :src="slotProps.item.itemImageSrc"
        :alt="slotProps.item.alt"
        style="width: 100%; display: block;"
      >
    </template>
    <template #thumbnail="slotProps">
      <img
        :src="slotProps.item.thumbnailImageSrc"
        :alt="slotProps.item.alt"
        style="height: 70px; width: 50px;"
      >
    </template>
    <template #caption="slotProps">
      <h4 style="margin-bottom: .5rem;">
        {{ slotProps.item.title }}
      </h4>
      <p>{{ slotProps.item.alt }}</p>
    </template>
  </Galleria>
</template>

<script lang="ts">
import Galleria from 'primevue/galleria';
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';

export default defineComponent({
  name: 'PreviewModal',
  components: {
    Galleria,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    images: {
      type: Object,
      required: true,
    },
  },

  emits: ['on-close-preview-modal'],

  setup(props: {visible: boolean; images: object}, context: SetupContext) {
    const responsiveOptions = [
      {
        breakpoint: '1500px',
        numVisible: 5,
      },
      {
        breakpoint: '1024px',
        numVisible: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
      },
    ];

    const visibility = ref(props.visible);
    const listImages = ref(props.images);
    watch(props, () => {
      visibility.value = props.visible;
      listImages.value = props.images;
    });

    watch(visibility, () => {
      if (!visibility.value) {
        context.emit('on-close-preview-modal');
      }
    });

    return {
      visibility,
      listImages,
      responsiveOptions,
    };
  },
});
</script>

<style scoped>

</style>
