import { Request } from '@/composables/GraphQL';
import { showToastError } from '@/helpers/index';
import { Maybe } from '@/types/generated-types/graphql';

/**
 * Check if a string is in valid email address format
 * @param email
 */
export function isValidEmail(email: string): boolean {
  const re = new RegExp('^([\\w-\\+]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$');
  return re.test(String(email).toLowerCase());
}

/**
 * Send HTML email
 * @param idShop
 * @param subject
 * @param fromName
 * @param fromEmail
 * @param to
 * @param html
 */
export async function sendEmail(idShop: number, subject: string, fromName: string, fromEmail: string, to: string, html: string): Promise<Maybe<boolean>> {
  if (idShop === 0 || subject === '' || fromName === '' || fromEmail === '' || html === '' || to === '') {
    return false;
  }

  const query = 'query ($idShop: Int!, $subject: String!, $fromName: String!, $fromEmail: String!, $to: String!, $html: String!) {'
    + 'SendSmtpEmail(id_shop: $idShop, subject: $subject, from_name: $fromName, from_email: $fromEmail, to: $to, html: $html)'
    + '}';

  // GraphQL variables
  const variables = {
    idShop,
    subject,
    fromName,
    fromEmail,
    to,
    html,
  };

  const { data, err } = await Request<boolean>({
    name: 'SendEmail',
    query,
    variables,
  });

  if (err) {
    showToastError(err);
    return null;
  }

  return data;
}
