<template>
  <div class="field flex align-items-center">
    <div>
      <label>{{ t(`storeParameters.services.displayIcon`) }}</label>
    </div>
    <SelectButton
      v-model="field.shopRequiredDataPushNotification_activeIcon"
      :options="yesno"
      option-label="name"
      option-value="value"
      class="ml-auto"
      :unselectable="false"
    />
  </div>
  <div
    v-if="field.shopRequiredDataPushNotification_activeIcon && field.shopRequiredDataPushNotification_activeIcon === '1'"
    class="grid"
  >
    <div class="p-fluid col-12 lg:col-6 p-lg-pr-5">
      <div class="field">
        <label>{{ t('storeParameters.services.iconBackgroundColor') }}</label>
        <SimpleColorPicker
          id="colorIcon"
          :selected-color="field.shopRequiredDataPushNotification_colorIcon ?? ''"
          @color-changed="(value) => field.shopRequiredDataPushNotification_colorIcon = value"
        />
      </div>
    </div>
    <div class="p-fluid col-12 lg:col-6 p-lg-pl-5">
      <div class="field">
        <label>{{ t('storeParameters.services.iconColor') }}</label>
        <SimpleColorPicker
          id="textColorIcon"
          :selected-color="field.shopRequiredDataPushNotification_textColorIcon ?? ''"
          @color-changed="(value) => field.shopRequiredDataPushNotification_textColorIcon= value"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  PropType,
} from 'vue';

import SelectButton from 'primevue/selectbutton';

import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Icon',

  components: {
    SelectButton,
    SimpleColorPicker,
  },

  props: {
    modelValue: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const yesno = [
      { name: t('yes'), value: '1' },
      { name: t('no'), value: '0' },
    ];

    const field = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    return {
      t,
      field,
      yesno,
    };
  },

});
</script>
