<template>
  <Dialog
    v-model:visible="displayDialog"
    :modal="true"
    :draggable="false"
    :closable="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '50vw' }"
    @hide="closePreview"
  >
    <template #header>
      <h3>
        {{ t('myLists.importContacts.segmentation.preview.title') }}
        <small>({{ value.total }} contacts) </small>
      </h3>
    </template>
    <div class="flex align-items-center justify-content-center">
      <SpmTable
        v-model="value"
        :table-columns="columns"
        header=""
        index="id_shop_customer"
        name=""
      >
        <template #newsletter="slotProps">
          <div v-if="slotProps.data.newsletter === '2'">
            <i
              class="far fa-check newsletter-2"
            />
          </div>
          <div v-else-if="slotProps.data.newsletter === '1'">
            <i
              class="far fa-check newsletter-1"
            />
          </div>
          <div v-else>
            <i
              class="far fa-ban"
            />
          </div>
        </template>
      </SpmTable>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent, PropType, reactive, ref, SetupContext,
} from 'vue';
import SpmTable from '@/components/table/SpmTable.vue';
import { SpmTableColumns, SpmTableState } from '@/types';
import { useI18n } from 'vue-i18n';
import Dialog from 'primevue/dialog';

export default defineComponent({
  name: 'MappingsPreview',

  components: {
    SpmTable,
    Dialog,
  },

  props: {
    tableData: {
      type: Object as PropType<SpmTableState>,
      required: true,
    },

    fieldOptions: {
      type: Array as PropType<string[] | undefined>,
      required: false,
      default: undefined,
    },
  },

  emits: ['close-preview'],

  setup(props: { tableData: SpmTableState; fieldOptions: string[] | undefined }, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayDialog = ref(true);
    const normalColumns: SpmTableColumns[] = [
      {
        field: 'date_modification',
        header: t('myShopData.customers.headers.date_modification'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:15%',
        type: 'text',
      },
      {
        field: 'email',
        header: t('myShopData.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'first_name',
        header: t('myShopData.customers.headers.first_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'last_name',
        header: t('myShopData.customers.headers.last_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'country',
        header: t('myShopData.customers.headers.country'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:5%',
        type: 'flag',
      },
      {
        field: 'lang',
        header: t('myShopData.customers.headers.lang'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:5%',
        type: 'language',
      },
      {
        field: 'newsletter',
        header: t('myShopData.customers.headers.newsletter'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'id_shop_customer',
        header: t('myShopData.customers.headers.actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:5%',
        type: 'text',
        hidden: true,
      },
    ];

    const flagFields = ['country', 'lang'];

    const dynamicColumns: SpmTableColumns[] = [];
    if (props.fieldOptions) {
      let type = 'text';
      props.fieldOptions.forEach((fieldOption) => {
        if (flagFields.includes(fieldOption)) {
          type = ' flag';
        }

        const column: SpmTableColumns = {
          field: fieldOption,
          header: t(`myLists.importContacts.assignment.${fieldOption}`),
          sortable: false,
          filterable: false,
          editable: false,
          type,
          style: '',
        };
        dynamicColumns.push(column);
      });
    }

    const value = reactive<SpmTableState>({ ...props.tableData });

    const columns = props.fieldOptions ? dynamicColumns : normalColumns;

    const closePreview = () => {
      emit('close-preview');
    };

    return {
      t,
      displayDialog,
      columns,
      value,
      closePreview,
    };
  },
});
</script>

<style lang="scss" scoped>
.stepbox-top-title {
  padding: 5px;
  background: #eeebeb;
  height: auto;
}

.segmentation-result-count {
  color: #ffa31a;
  font-weight: bold;
  font-size: 2em;
  line-height: 1em;
  display: inline-block;
  padding: 0 5px 0 0;
}

.newsletter-1 {
  color: $brand-orange;
}

.newsletter-2 {
  color: $brand-color-primary;
}
</style>
