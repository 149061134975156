import { ComponentNameEnum, DropdownOption } from '@/types';

const getDropdownFieldConfig = (options: DropdownOption[] | Promise<DropdownOption[]> = [], defaultValue = '') => ({
  componentName: ComponentNameEnum.DROPDOWN,
  options: {
    list: options,
    defaultValue,
  },
});

export default getDropdownFieldConfig;
