<template>
  <div class="field-wrapper">
    <BaseItemList
      v-model="selected"
      :title="config.mainLabel"
      :button-label="config.buttonLabel"
      :name="config.name"
      :fields="config.fields"
      :items="selected.map(product => product.spmId).join(',')"
      :default-filters="config.defaultFilters"
      :format="config.format"
    />
  </div>
  <div
    v-if="selected.length > 1 && showOperator"
    class="field-wrapper"
  >
    <ButtonGroup
      v-model="operator"
      :options="logicalOperators"
      :title="config.operatorLabel"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  SpmTableFilter, StringMap,
} from '@/types';
import BaseItemList from '@/components/fields/BaseItemList.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import { PickerData } from '@/types/automated-scenarios';

export interface ItemConfig {
    mainLabel: string;
    buttonLabel: string;
    operatorLabel: string;
    name: string;
    fields: StringMap;
    defaultFilters: SpmTableFilter[];
    format: Function;
    showOperator?: boolean;
}

export default defineComponent({
  name: 'ItemList',

  components: {
    BaseItemList,
    ButtonGroup,
  },

  props: {
    modelValue: {
      type: Object as PropType<{ selection: PickerData}>,
      required: true,
    },

    config: {
      type: Object as PropType<ItemConfig>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    // true by default
    const showOperator = ref(props.config.showOperator !== undefined ? props.config.showOperator : true);

    const selected = ref(props.modelValue ? props.modelValue.selection.selected : []);
    const operator = ref(props.modelValue ? props.modelValue.selection.operator : 'and');

    const logicalOperators = [
      { value: 'and', label: t('automatedScenarios.fields.allItems') },
      { value: 'or', label: t('automatedScenarios.fields.atLeastOne') },
    ];

    watch([selected, operator], () => {
      emit('update:modelValue', {
        selection: {
          operator: operator.value,
          selected: selected.value,
        },
      });
    });

    return {
      selected,
      logicalOperators,
      operator,
      showOperator,
    };
  },

});
</script>
