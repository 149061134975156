<template>
  <div
    :class="{ 'with-emoji': displayEmojis, 'with-variables': displayVariables }"
  >
    <div class="p-fluid">
      <Textarea
        ref="textareaRef"
        v-model="inputValue"
        :rows="10"
        :cols="30"
        :maxlength="maxLengthTextarea"
        class="input-textarea"
        @keyup="applyModifiers"
        @blur="(event) => { cursorPosition = event.target?.selectionStart }"
      />
    </div>
    <div class="grid">
      <div
        v-if="displayEmojis"
        class="emoji-container"
        :class="{ 'col-2': displayVariables, 'col-12': !displayVariables, 'pr-0': displayVariables }"
      >
        <EmojisButton @on-choose-emoji="insertContent" />
      </div>
      <div
        v-if="displayVariables"
        class="variables-container"
        :class="{ 'col-10': displayEmojis, 'col-12': !displayEmojis, 'pl-0': displayEmojis }"
      >
        <VariablesButton
          :variables-list="variablesList"
          @on-choose-variable="insertContent"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import Textarea from 'primevue/textarea';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import { br2nl, nl2br } from '@/helpers';
import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseTextarea',
  components: {
    Textarea,
    EmojisButton,
    VariablesButton,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    displayEmojis: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayVariables: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxLengthTextarea: {
      type: Number,
      required: false,
      default: -1,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    modifiers: {
      type: Array as PropType<Function[]> | null,
      required: false,
      default: null,
    },

    watchProps: {
      type: Boolean,
      default: false,
    }
  },

  emits: {
    'update:modelValue': String,
    click: String,
  },

  setup(props, { emit }: SetupContext) {
    const inputValue: Ref<string> = ref(br2nl(props.modelValue));
    const cursorPosition = ref(0);

    const insertContent = (value: string|number|undefined) => {
      inputValue.value = inputValue.value.substring(0, cursorPosition.value)
        + value
        + inputValue.value.substring(cursorPosition.value, inputValue.value.length);
    };

    const applyModifiers = (event: any) => {
      if (!event.ctrlKey && !event.metaKey) {
        if (Array.isArray(props.modifiers) && props.modifiers.length > 0) {
          props.modifiers.forEach((modifier: Function) => {
            inputValue.value = modifier(inputValue.value);
          });
        }
      }
    };

    watch(inputValue, () => {
      emit('update:modelValue', nl2br(inputValue.value));
    });

    watch(() => props.modelValue, () => {
      if (props.watchProps) {
        inputValue.value = br2nl(props.modelValue);
      }
    });

    const textareaRef = ref();

    const focus = () => {
      if (textareaRef.value) {
        textareaRef.value.$el.focus();
      }
    };

    return {
      inputValue,
      cursorPosition,
      insertContent,
      applyModifiers,
      textareaRef,
      focus,
    };
  },
});
</script>

<style scoped lang="scss">
:deep() .p-togglebutton.p-button.emoji-button {
  padding: 0.2rem 0.3rem;
  width: 2rem;
}

.input-textarea {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}

.p-inputtextarea {
  resize: none;
  height: 8rem !important;
}

:deep() .p-togglebutton.p-button.emoji-button {
  background: $white;
  margin: 0 !important;
  border: solid 1px $solitude;
  border-radius: 0 0 0.3rem 0.3rem;
  padding: 0 10px;
  width: 100%;
  height: 100%;

  & .p-button-icon-left {
    margin: 0 auto;
  }

  & .p-button-label {
    display: none;
  }

  & .p-button-icon {
    color: $heather !important;
  }

  &:hover,
  &.p-focus {
    border-color: $solitude !important;
  }
}

.with-variables:deep() {
  & .input-textarea {
    border-bottom: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & .variable-dropdown {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    & .p-inputtext {
      border-top-left-radius: 0 !important;
      color: $heather;
    }

    & .p-dropdown-trigger {
      border-top-right-radius: 0 !important;
    }
  }

  & .emoji-button {
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.with-emoji:deep() {
  & .input-textarea {
    border-bottom: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & .emoji-button {
    padding: 5px !important;
  }
}

.with-variables.with-emoji:deep() {
  & .variable-dropdown {
    border-bottom-left-radius: 0 !important;

    & .p-inputtext {
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
