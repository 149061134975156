import { InputGroupField, SharingTypeEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

const facebookPattern = /^(https:\/\/www.facebook.com\/sharer\/sharer.php\?u=)(.*)$/i;
const twitterPattern = /^(https:\/\/twitter.com\/intent\/tweet\?text=)(.*)(&[amp;]*url=)(.*)$/i;
const emailPattern = /^(mailto:\?subject=)(.*)(&[amp;]*body=)(.*)$/i;
const googlePattern = /^(https:\/\/plus.google.com\/share\?url=)(.*)$/i;

export const getSharingType = (selector: string) => {
  const template = getTemplateIframeDocument();
  const link: HTMLElement|null = template?.querySelector(`${selector} a`);
  return link?.getAttribute('href')?.includes('private_archive_url') ? SharingTypeEnum.ONLINE_VERSION : SharingTypeEnum.CUSTOM_URL;
};

export const getSocialNetworkIconStyle = (selector: string): string => {
  const pattern = /^https:\/\/media.shopimind.(?:com|io)\/img\/icons\/social\/([0-9]{1,2})\/[0-9]{2}\/.+$/i;
  const template = getTemplateIframeDocument();
  const matches = template.querySelector(selector)?.getAttribute('src')?.match(pattern);
  return (matches && matches[1]) ?? '';
};

export const getCustomUrl = (selector: string): string => {
  const template = getTemplateIframeDocument();
  // try with facebook
  const facebookLink: HTMLElement|null = template?.querySelector(`${selector} .spm_facebook a`);
  if (facebookLink) {
    const matches = facebookLink.getAttribute('href')?.match(facebookPattern);
    if (matches) {
      return matches[2];
    }
  }
  // try with twitter
  const twitterLink: HTMLElement|null = template?.querySelector(`${selector} .spm_twitter a`);
  if (twitterLink) {
    const matches = twitterLink.getAttribute('href')?.match(twitterPattern);
    if (matches) {
      return matches[4];
    }
  }
  // try with google
  const googleLink: HTMLElement|null = template?.querySelector(`${selector} .spm_google a`);
  if (googleLink) {
    const matches = googleLink.getAttribute('href')?.match(googlePattern);
    if (matches) {
      return matches[2];
    }
  }
  // try with email
  const emailLink: HTMLElement|null = template?.querySelector(`${selector} .spm_email a`);
  if (emailLink) {
    const matches = emailLink.getAttribute('href')?.match(emailPattern);
    if (matches) {
      return matches[4];
    }
  }
  return '';
};

export const getCustomDescription = (selector: string): string => {
  const template = getTemplateIframeDocument();
  // try with twitter
  const twitterLink: HTMLElement|null = template?.querySelector(`${selector} .spm_twitter a`);
  if (twitterLink) {
    const matches = twitterLink?.getAttribute('href')?.match(twitterPattern);
    if (matches) {
      return matches[2];
    }
  }
  // try with email
  const emailLink: HTMLElement|null = template?.querySelector(`${selector} .spm_email a`);
  if (emailLink) {
    const matches = emailLink?.getAttribute('href')?.match(emailPattern);
    if (matches) {
      return matches[2];
    }
  }
  return '';
};

export const getActiveSocialNetworks = (selector: string): string[] => {
  const template = getTemplateIframeDocument();
  const networks: Array<HTMLElement> = Array.from(template?.querySelectorAll(`${selector} .spm_social_icon_container`));
  return networks.reduce(
    (acc: string[], network: HTMLElement) => {
      const socialNetwork = network.getAttribute('class')?.replace('spm_social_icon_container', '').replace('spm_social_icon', '').replace(new RegExp('spm_hide', 'g'), '').trim();
      if (socialNetwork) {
        acc.push(socialNetwork);
      }

      return acc;
    },
    [],
  );
};

export const getLinkByNetwork = (selector: string, network: string): string => {
  const template = getTemplateIframeDocument();
  const networks: HTMLElement|null = template?.querySelector(selector);
  const link: HTMLAnchorElement = networks?.querySelector(`.${network} a`) as HTMLAnchorElement;
  const linkVariable = network.replace('spm_', '') === 'email' ? 'contact' : network.replace('spm_', '');
  return (link && link.getAttribute('href')) ?? `{var=social.${linkVariable}_url}`;
};

export const getSocialNetworksLinks = (selector: string) => {
  const template = getTemplateIframeDocument();
  const networks: Array<HTMLElement> = Array.from(template?.querySelectorAll(`${selector} .spm_social_icon_container`));
  return networks.reduce((acc: InputGroupField[], network) => {
    const name = network.getAttribute('class')?.replace('spm_social_icon_container', '').replace('spm_social_icon', '').replace(new RegExp('spm_hide', 'g'), '');
    if (name) {
      acc.push({
        key: name,
        label: `templateBuilder.fields.${name.replace('spm_', '').trim()}`,
        value: getLinkByNetwork(selector, name),
      });
    }
    return acc;
  },
  []);
};
