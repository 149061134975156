import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  LinkTypeEnum,
  ComponentNameEnum,
  AlignValueEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import imageFieldConfig from '@/components/template-builder/config/fields/image-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import {
  setLink,
  setAttribute,
  setCssProperty,
  setMediaDisplay,
  setCssPropertyAndRemoveIfZero,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName,
  getClassByName,
  getCssPropertyByName,
  getLinkType,
} from '@/components/template-builder/getters';
import getLinkFieldConfig from '@/components/template-builder/config/fields/link-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';

const linkOptions = [LinkTypeEnum.NONE, LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.EMAIL_ADDRESS, LinkTypeEnum.ANCHOR];
// if (isDisplayTemplate(templateType)) linkOptions.push(LinkTypeEnum.DISPLAY_TEMPLATE);

const emailWidgetImageConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.image',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.image',
            selector: '{SELECTOR} > tbody > tr > th img',
            properties: [
              { name: 'src', getter: getAttributeByName, setters: [setAttribute] },
              { name: 'alt', getter: getAttributeByName, setters: [setAttribute] },
            ],
            ...imageFieldConfig,
            options: {
              ...imageFieldConfig.options,
              description: true,
            },
          },
          {
            label: 'templateBuilder.fields.link',
            selector: '{SELECTOR} > tbody > tr > th',
            properties: [{ name: 'href', getter: getLinkType, setters: [setLink] }],
            ...getLinkFieldConfig(LinkTypeEnum.NONE, linkOptions, variables.allVariables),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.width',
            selector: '{SELECTOR} > tbody > tr > th img',
            properties: [{ name: 'max-width', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.alignment',
            selector: '{SELECTOR} > tbody > tr > th',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getElementAlignFieldConfig('text-align', AlignValueEnum.CENTER),
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR} > tbody > tr > th',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR} > tbody > tr > th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR}.spm_widget_image, {SELECTOR}.spm_widget_display_image',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}.spm_widget_image, {SELECTOR}.spm_widget_display_image',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default emailWidgetImageConfig;
