
import { defineComponent, computed } from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import {
  hasAccessToFeatures,
} from '@/composables/User';
import { PlanName } from '@/types/enums';
import { useStore } from '@/store';

export default defineComponent({
  name: 'SecureSpmButton',
  components: {
    SpmButton,
  },

  inheritAttrs: false,

  props: {
    onAuthorizedClick: {
      type: Function,
      required: true,
    },

    requiredFeature: {
      type: String,
      required: true,
    },

    bypassPermission: {
      type: Boolean,
      required: false,
      default: false,
    },

    containerClass: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const store = useStore();

    const featurePermission = computed(() => hasAccessToFeatures(props.requiredFeature));
    const minimumPlan = computed(() => {
      if (featurePermission.value && featurePermission.value.minimumPlan !== PlanName.STANDARD) {
        return featurePermission.value.minimumPlan;
      }
      return null;
    });

    const handlePermissionCheck = async () => {
      if (!props.bypassPermission) {
        if (featurePermission.value && !featurePermission.value.access) {
          store.commit('general/setIsFeatureUnavailableModalVisible', true);
          store.commit('general/setFeatureUnavailableMinPlan', featurePermission.value.minimumPlan);
          store.commit('general/setOnAuthorizedClickFunction', props.onAuthorizedClick);
        } else {
          await props.onAuthorizedClick();
        }
      } else {
        await props.onAuthorizedClick();
      }
    };

    return {
      minimumPlan,

      handlePermissionCheck,
    };
  },
});
