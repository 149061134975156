<template>
  <div
    ref="menubarRef"
    class="menubar-container flex flex-column"
  >
    <div class="col-12 p-0 flex flex-column">
      <Menubar
        :model="toolbar"
      />
    </div>
  </div>
  <div class="pl-3 pr-3" v-if="filteredToolbar.length">
    <div
      v-for="(panel, index) in filteredToolbar"
      :key="panel.panelId"
    >
      <div
        v-if="panel.panelId === currentPanelId"
        style="background: #ffffff;padding: 1rem;"
      >
        <draggable
          :list="panel.children"
          :disabled="!enabled"
          item-key="name"
          style="min-height: 4rem;"
          class="block grid mt-1"
          handle=".handle"
          v-bind="dragOptions"
          :data-menu-render="JSON.stringify({ rowId, columnId, panel })"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element }">
            <div
              class="root-row m-4"
              style="position: relative;"
              :class="{ 'not-draggable': !enabled }"
            >
              <StatsBuilderSubRow
                :row-parent-id="rowId"
                :column-parent-id="columnId"
                :panel-id="panel.panelId"
                :row-data="element"
              />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onBeforeMount, Ref, ref, SetupContext, watch,
  onMounted, nextTick,
} from 'vue';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import Menubar from 'primevue/menubar';
import draggable from 'vuedraggable';
import StatsBuilderSubRow from '@/components/stats-builder/StatsBuilderSubRow.vue';
import { setActiveMenubarItem } from '@/helpers';

export default defineComponent({
  name: 'StatsWidgetMenuRender',
  components: {
    draggable,
    StatsBuilderSubRow,
    Menubar,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowId: {
      type: String,
      required: true,
    },

    columnId: {
      type: String,
      required: true,
    },

    editMode: {
      type: Boolean,
      required: true,
    },

    widgetData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { editMode: boolean; widgetData: any}, { emit }: SetupContext) {
    const { locale } = useI18n();
    const widgetDataRef = ref(props.widgetData);
    const filteredToolbar: Ref<{ [key: string]: any }[]> = ref([]);
    const refreshFilteredToolbar = ref(true);
    const currentPanelId = ref('');
    const toolbar = ref([]);

    const menubarRef = ref();

    const selectActiveMenu = (activeMenu: string) => {
      currentPanelId.value = activeMenu;
    };

    const enabled = ref(true);
    const dragging = ref(false);
    const dragOptions = ref({
      animation: 200,
      group: { name: 'line_builder', pull: false, put: ['line_element', 'widget_element'] },
      disabled: false,
    });

    const formatToolbarOnView = (arr: any) => arr.reduce(
      async (a: any, item: any, index: any) => {
        await a;

        if (item.panelId) {
          // eslint-disable-next-line no-param-reassign
          item.command = (event: any) => {
            selectActiveMenu(item.panelId);
            setActiveMenubarItem(event.originalEvent.target);
          };
          filteredToolbar.value.push(JSON.parse(JSON.stringify(item)));
        } else if (item.items) {
          await formatToolbarOnView(item.items);
        }
      },
      Promise.resolve(),
    );

    const formatToolbarOnEdit = (arr: any) => arr.reduce(
      async (a: any, item: any) => {
        await a;

        if (item.items && !item.items.length) {
          // eslint-disable-next-line no-param-reassign
          delete item.items;
        } else if (item.items) {
          if (item.panelId) {
            // eslint-disable-next-line no-param-reassign
            delete item.panelId;
          }

          if (item.children) {
            // eslint-disable-next-line no-param-reassign
            delete item.children;
          }

          if (item.command) {
            // eslint-disable-next-line no-param-reassign
            delete item.command;
          }

          await formatToolbarOnEdit(item.items);
        }
      },
      Promise.resolve(),
    );

    const formatData = (arr: any) => arr.reduce(
      async (a: any, item: any) => {
        await a;

        item.label = typeof item.label === 'string' ? item.label : item.label[locale.value];

        if (item.items) {
          await formatData(item.items);
        }
      },
      Promise.resolve(),
    );


    const addPlaceholderRowToWidgetData = (data: any, concernedPanel: any) => {
      data.forEach((item: any) => {
        if (item.panelId && item.panelId === concernedPanel.panelId) {
          // eslint-disable-next-line no-param-reassign
          item.children = concernedPanel.children;
        }

        if (item.items && item.items.length) {
          addPlaceholderRowToWidgetData(item.items, concernedPanel);
        }
      });
    };

    const addClassToPanel = (menu: any, panelId: any) => {
      let found = false;

      menu.some((menuItem: any) => {
        // eslint-disable-next-line no-param-reassign
        menuItem.class = '';
        if (menuItem.panelId === panelId) {
          // eslint-disable-next-line no-param-reassign
          menuItem.class = 'current-active-menu';
          found = true;
          return true;
        }

        if (menuItem.items && menuItem.items.length > 0) {
          found = addClassToPanel(menuItem.items, panelId);

          if (found) {
            // eslint-disable-next-line no-param-reassign
            menuItem.class = 'current-active-menu';
            return true;
          }
        }
        return false;
      });

      return found;
    };

    const refreshActiveMenuClass = async () => {
      await nextTick();
      const menuItems = (menubarRef.value as HTMLElement).querySelectorAll('.current-active-menu, [active-item="true"]');
      menuItems.forEach((menuItem: any) => {
        menuItem.classList.remove('current-active-menu');
        menuItem.removeAttribute('active-item');
      });
      await nextTick();
      addClassToPanel(toolbar.value, currentPanelId.value);
    };

    watch(() => props.widgetData, async () => {
      await refreshActiveMenuClass();
      widgetDataRef.value = props.widgetData;
      if (refreshFilteredToolbar.value) {
        filteredToolbar.value = [];
        toolbar.value = JSON.parse(JSON.stringify(props.widgetData.toolbar));
        await formatData(toolbar.value);
        if (props.editMode) {
          await formatToolbarOnEdit(toolbar.value);
        } else {
          await formatToolbarOnView(toolbar.value);
          currentPanelId.value = currentPanelId.value && filteredToolbar.value.find((item: any) => (item.panelId === currentPanelId.value))
            ? currentPanelId.value : filteredToolbar.value[0].panelId;
        }
      } else {
        refreshFilteredToolbar.value = true;
      }
    }, { deep: true });

    watch(() => filteredToolbar.value, async () => {
      const concernedPanel: any = filteredToolbar.value.find((item: any) => item.children.find((child: any) => (child.type === 'placeholder')));
      if (concernedPanel) {
        refreshFilteredToolbar.value = false;
        addPlaceholderRowToWidgetData(widgetDataRef.value.toolbar, concernedPanel);
        await formatData(widgetDataRef.value.toolbar);
      }
      await refreshActiveMenuClass();
    }, { deep: true });

    watch(() => currentPanelId.value, async () => {
      await refreshActiveMenuClass();
    });

    onBeforeMount(async () => {
      filteredToolbar.value = [];
      toolbar.value = JSON.parse(JSON.stringify(props.widgetData.toolbar));
      await formatToolbarOnView(toolbar.value);
      await formatData(toolbar.value);
      currentPanelId.value = filteredToolbar.value[0].panelId;
    });

    onMounted(async () => {
      // Mark first menu item as active
      if (menubarRef.value) {
        const firstItem = (menubarRef.value as unknown as HTMLElement).querySelector('.p-menubar li.p-menuitem:first-child');

        if (firstItem) {
          firstItem.classList.add('current-active-menu');
        }
      }
    });

    return {
      enabled,
      dragging,
      dragOptions,
      filteredToolbar,
      currentPanelId,
      toolbar,
      menubarRef,
    };
  },
});
</script>
