
import {
  reactive, defineComponent, SetupContext, watch, computed, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import StatsBuilderFilters from '@/components/stats-builder/StatsBuilderFilters.vue';
import { useStore } from '@/store';
import moment from 'moment/moment';
import { formatDateToLocale } from '@/helpers/Date';

export default defineComponent({
  name: 'StatsFilterOnView',
  components: {
    StatsBuilderFilters,
    SpmButton,
    SpmOverlayPanel,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue', 'apply-filters'],

  setup(props: { modelValue: any }, { emit }: SetupContext) {
    const { t, locale } = useI18n();
    const store = useStore();
    const filtersData = reactive(props.modelValue);
    const appliedFilters = computed(() => store.getters['statsEditor/getPageFilters']);
    const nbOtherAppliedFilters = computed(() => Object.keys(appliedFilters.value)
      .filter((key) => key !== 'date_interval' && appliedFilters.value[key].value && appliedFilters.value[key].value.length > 0).length);
    const appliedDateInterval = computed(() => {
      let returnValue;

      if (appliedFilters.value.date_interval.value.interval === 'customDateRange') {
        const startDate = moment(appliedFilters.value.date_interval.value.customDateRange[0]);
        const endDate = moment(appliedFilters.value.date_interval.value.customDateRange[1]);
        returnValue = `${formatDateToLocale(locale.value, startDate.format('YYYY-MM-DD HH:mm:ss'))?.split(' ')[0]}
         - ${formatDateToLocale(locale.value, endDate.format('YYYY-MM-DD HH:mm:ss'))?.split(' ')[0]}`;
      } else {
        returnValue = t(`customDatePicker.${appliedFilters.value.date_interval.value.interval}`);
      }

      return returnValue;
    });

    watch(filtersData, () => {
      emit('update:modelValue', filtersData);
    });

    const applyFilters = () => {
      emit('apply-filters');
    };

    onMounted(() => {
      applyFilters();
    });

    return {
      t,
      filtersData,
      nbOtherAppliedFilters,
      appliedDateInterval,
      applyFilters,
    };
  },
});
