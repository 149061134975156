
import { defineComponent, ref, SetupContext } from 'vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'PropertyDeletePanel',

  components: {
    SpmButton,
    SpmOverlayPanel,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: '',
      required: false,
    },
  },

  emits: ['remove-callback'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const closeDeletePanel = ref(false);

    const resetVisibility = () => {
      closeDeletePanel.value = false;
    };

    const closeDeletionPanel = () => {
      closeDeletePanel.value = true;
    };

    const removeAction = () => {
      closeDeletionPanel();
      emit('remove-callback');
    };

    return {
      t,
      closeDeletePanel,
      resetVisibility,
      closeDeletionPanel,
      removeAction,
    };
  },
});
