// eslint-disable-next-line import/no-cycle
import { ListSettings } from '@/composables/GraphQL';
import { StatsWidgetDataConfiguration } from '@/components/stats/StatsWidgetData.vue';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

type struct = {
  row: number;
  column: number[];
}

/** Main.AdyenTokens */
export type AdyenTokens = {
  __typename?: 'AdyenTokens';
  /** Id */
  id: Scalars['Int'];
  /** IdUser */
  id_user: Scalars['Int'];
  /** PaymentMethod */
  payment_method: Scalars['String'];
  /** Brand */
  brand: Scalars['String'];
  /** BrandName */
  brand_name: Scalars['String'];
  /** OwnerName */
  owner_name: Scalars['String'];
  /** LastFour */
  last_four: Scalars['String'];
  /** Expiry */
  expiry: Scalars['DateTime'];
  /** IsDefault */
  is_default: Scalars['Boolean'];
  /** Deleted */
  deleted: Scalars['Boolean'];
  /** Json */
  json: Scalars['String'];
  users?: Maybe<Array<Maybe<Users>>>;
};

/** Main.AdyenTokens */
export type AdyenTokensInput = {
  input: Array<AdyenTokensInputItem>;
};

/** Main.AdyenTokens */
export type AdyenTokensInputItem = {
  /** IdUser */
  id_user: Scalars['Int'];
  /** PaymentMethod */
  payment_method: Scalars['String'];
  /** Brand */
  brand: Scalars['String'];
  /** BrandName */
  brand_name: Scalars['String'];
  /** OwnerName */
  owner_name: Scalars['String'];
  /** LastFour */
  last_four: Scalars['String'];
  /** Expiry */
  expiry: Scalars['DateTime'];
  /** IsDefault */
  is_default: Scalars['Boolean'];
  /** Deleted */
  deleted: Scalars['Boolean'];
  /** Json */
  json: Scalars['String'];
};

/** List of Main.AdyenTokens */
export type AdyenTokensItems = {
  __typename?: 'AdyenTokensItems';
  total: Scalars['Int'];
  items: Array<AdyenTokens>;
};

/** Main.AdyenTokens */
export type AdyenTokensUpdateInput = {
  input: Array<AdyenTokensUpdateInputItem>;
};

/** Main.AdyenTokens */
export type AdyenTokensUpdateInputItem = {
  __typename?: 'AdyenTokensUpdateInputItem';
  /** Id */
  id?: Maybe<Scalars['Int']>;
  /** IdUser */
  id_user?: Maybe<Scalars['Int']>;
  /** PaymentMethod */
  payment_method?: Maybe<Scalars['String']>;
  /** Brand */
  brand?: Maybe<Scalars['String']>;
  /** BrandName */
  brand_name?: Maybe<Scalars['String']>;
  /** OwnerName */
  owner_name?: Maybe<Scalars['String']>;
  /** LastFour */
  last_four?: Maybe<Scalars['String']>;
  /** Expiry */
  expiry?: Maybe<Scalars['DateTime']>;
  /** IsDefault */
  is_default?: Maybe<Scalars['Boolean']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** Json */
  json?: Maybe<Scalars['String']>;
};

/** Main.Alerts */
export type Alerts = {
  __typename?: 'Alerts';
  /** IdAlert */
  id_alert: Scalars['Int'];
  /** Key */
  key: Scalars['String'];
  /** Frequency */
  frequency?: Maybe<Scalars['String']>;
  /** FrequencyVarchar */
  frequency_varchar?: Maybe<Scalars['String']>;
  /** Editable */
  editable: Scalars['Int'];
  /** Service */
  service?: Maybe<Scalars['String']>;
  /** DefaultValue */
  default_value: Scalars['Boolean'];
  /** Cron */
  cron: Scalars['Boolean'];
  /** AlertConfiguration */
  alert_configuration?: Maybe<Scalars['String']>;
  shopsAlerts?: Maybe<Array<Maybe<ShopsAlerts>>>;
};

/** Main.Alerts */
export type AlertsInput = {
  input: Array<AlertsInputItem>;
};

/** Main.Alerts */
export type AlertsInputItem = {
  /** Key */
  key: Scalars['String'];
  /** Frequency */
  frequency?: Maybe<Scalars['String']>;
  /** FrequencyVarchar */
  frequency_varchar?: Maybe<Scalars['String']>;
  /** Editable */
  editable: Scalars['Int'];
  /** Service */
  service?: Maybe<Scalars['String']>;
  /** DefaultValue */
  default_value: Scalars['Boolean'];
  /** Cron */
  cron: Scalars['Boolean'];
  /** AlertConfiguration */
  alert_configuration?: Maybe<Scalars['String']>;
};

/** List of Main.Alerts */
export type AlertsItems = {
  __typename?: 'AlertsItems';
  total: Scalars['Int'];
  items: Array<Alerts>;
};

/** Main.Alerts */
export type AlertsUpdateInput = {
  input: Array<AlertsUpdateInputItem>;
};

/** Main.Alerts */
export type AlertsUpdateInputItem = {
  /** IdAlert */
  id_alert?: Maybe<Scalars['Int']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Frequency */
  frequency?: Maybe<Scalars['String']>;
  /** FrequencyVarchar */
  frequency_varchar?: Maybe<Scalars['String']>;
  /** Editable */
  editable?: Maybe<Scalars['Int']>;
  /** Service */
  service?: Maybe<Scalars['String']>;
  /** DefaultValue */
  default_value?: Maybe<Scalars['Boolean']>;
  /** Cron */
  cron?: Maybe<Scalars['Boolean']>;
  /** AlertConfiguration */
  alert_configuration?: Maybe<Scalars['String']>;
};

export enum BorderStyle {
  Round = 'round',
  Bevel = 'bevel',
  Miter = 'miter'
}

/** Main.Bundles */
export type Bundles = {
  __typename?: 'Bundles';
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** Label */
  label?: Maybe<Scalars['String']>;
  /** NumberPagesViewsMax */
  number_pages_views_max?: Maybe<Scalars['Int']>;
  /** NumberEmailMax */
  number_email_max: Scalars['Int'];
  /** NumberEmailNewsletterMax */
  number_email_newsletter_max: Scalars['Int'];
  /** Price */
  price: Scalars['Float'];
  /** PriceNewsletter */
  price_newsletter?: Maybe<Scalars['Float']>;
  /** PriceUsd */
  price_usd: Scalars['Float'];
  /** PriceNewsletterUsd */
  price_newsletter_usd?: Maybe<Scalars['Float']>;
  /** PriceAll */
  price_all?: Maybe<Scalars['Float']>;
  /** PriceOut */
  price_out?: Maybe<Scalars['Float']>;
  /** PriceOutNewsletter */
  price_out_newsletter?: Maybe<Scalars['Float']>;
  /** PriceRetargeting */
  price_retargeting?: Maybe<Scalars['Float']>;
  /** Duration */
  duration: Scalars['Int'];
  /** DurationUnit */
  duration_unit: Scalars['String'];
  /** Active */
  active: Scalars['Boolean'];
  /** TrialPeriod */
  trial_period: Scalars['Boolean'];
  /** IdPsProductBundle */
  id_ps_product_bundle?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleNewsletter */
  id_ps_product_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleAll */
  id_ps_product_bundle_all?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOut */
  id_ps_product_bundle_out?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOutNewsletter */
  id_ps_product_bundle_out_newsletter?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOutRetargeting */
  id_ps_product_bundle_out_retargeting?: Maybe<Scalars['Int']>;
  /** MaxCaEuro */
  max_ca_euro?: Maybe<Scalars['Int']>;
  /** SeuilCa */
  seuil_ca?: Maybe<Scalars['Int']>;
  /** PromoCode */
  promo_code?: Maybe<Scalars['String']>;
  /** Version */
  version: Scalars['Boolean'];
  shopsBundles?: Maybe<Array<Maybe<ShopsBundles>>>;
};

/** Main.Bundles */
export type BundlesInput = {
  input: Array<BundlesInputItem>;
};

/** Main.Bundles */
export type BundlesInputItem = {
  /** Label */
  label?: Maybe<Scalars['String']>;
  /** NumberPagesViewsMax */
  number_pages_views_max?: Maybe<Scalars['Int']>;
  /** NumberEmailMax */
  number_email_max: Scalars['Int'];
  /** NumberEmailNewsletterMax */
  number_email_newsletter_max: Scalars['Int'];
  /** Price */
  price: Scalars['Float'];
  /** PriceNewsletter */
  price_newsletter?: Maybe<Scalars['Float']>;
  /** PriceUsd */
  price_usd: Scalars['Float'];
  /** PriceNewsletterUsd */
  price_newsletter_usd?: Maybe<Scalars['Float']>;
  /** PriceAll */
  price_all?: Maybe<Scalars['Float']>;
  /** PriceOut */
  price_out?: Maybe<Scalars['Float']>;
  /** PriceOutNewsletter */
  price_out_newsletter?: Maybe<Scalars['Float']>;
  /** PriceRetargeting */
  price_retargeting?: Maybe<Scalars['Float']>;
  /** Duration */
  duration: Scalars['Int'];
  /** DurationUnit */
  duration_unit: Scalars['String'];
  /** Active */
  active: Scalars['Boolean'];
  /** TrialPeriod */
  trial_period: Scalars['Boolean'];
  /** IdPsProductBundle */
  id_ps_product_bundle?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleNewsletter */
  id_ps_product_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleAll */
  id_ps_product_bundle_all?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOut */
  id_ps_product_bundle_out?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOutNewsletter */
  id_ps_product_bundle_out_newsletter?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOutRetargeting */
  id_ps_product_bundle_out_retargeting?: Maybe<Scalars['Int']>;
  /** MaxCaEuro */
  max_ca_euro?: Maybe<Scalars['Int']>;
  /** SeuilCa */
  seuil_ca?: Maybe<Scalars['Int']>;
  /** PromoCode */
  promo_code?: Maybe<Scalars['String']>;
  /** Version */
  version: Scalars['Boolean'];
};

/** List of Main.Bundles */
export type BundlesItems = {
  __typename?: 'BundlesItems';
  total: Scalars['Int'];
  items: Array<Bundles>;
};

/** Main.Bundles */
export type BundlesUpdateInput = {
  input: Array<BundlesUpdateInputItem>;
};

/** Main.Bundles */
export type BundlesUpdateInputItem = {
  /** IdBundle */
  id_bundle?: Maybe<Scalars['Int']>;
  /** Label */
  label?: Maybe<Scalars['String']>;
  /** NumberPagesViewsMax */
  number_pages_views_max?: Maybe<Scalars['Int']>;
  /** NumberEmailMax */
  number_email_max?: Maybe<Scalars['Int']>;
  /** NumberEmailNewsletterMax */
  number_email_newsletter_max?: Maybe<Scalars['Int']>;
  /** Price */
  price?: Maybe<Scalars['Float']>;
  /** PriceNewsletter */
  price_newsletter?: Maybe<Scalars['Float']>;
  /** PriceUsd */
  price_usd?: Maybe<Scalars['Float']>;
  /** PriceNewsletterUsd */
  price_newsletter_usd?: Maybe<Scalars['Float']>;
  /** PriceAll */
  price_all?: Maybe<Scalars['Float']>;
  /** PriceOut */
  price_out?: Maybe<Scalars['Float']>;
  /** PriceOutNewsletter */
  price_out_newsletter?: Maybe<Scalars['Float']>;
  /** PriceRetargeting */
  price_retargeting?: Maybe<Scalars['Float']>;
  /** Duration */
  duration?: Maybe<Scalars['Int']>;
  /** DurationUnit */
  duration_unit?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** TrialPeriod */
  trial_period?: Maybe<Scalars['Boolean']>;
  /** IdPsProductBundle */
  id_ps_product_bundle?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleNewsletter */
  id_ps_product_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleAll */
  id_ps_product_bundle_all?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOut */
  id_ps_product_bundle_out?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOutNewsletter */
  id_ps_product_bundle_out_newsletter?: Maybe<Scalars['Int']>;
  /** IdPsProductBundleOutRetargeting */
  id_ps_product_bundle_out_retargeting?: Maybe<Scalars['Int']>;
  /** MaxCaEuro */
  max_ca_euro?: Maybe<Scalars['Int']>;
  /** SeuilCa */
  seuil_ca?: Maybe<Scalars['Int']>;
  /** PromoCode */
  promo_code?: Maybe<Scalars['String']>;
  /** Version */
  version?: Maybe<Scalars['Boolean']>;
};

export type ClientExportsData= {
  __typename?: 'ClientExportsData';
  id_exports_data: Scalars['Int'];
  id_export: Scalars['Int'];
  data: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  file_name: Scalars['String'];
  id_user: Scalars['Int'];
  user_type: Scalars['String'];
}

export type ClientExportsDataItem = {
  __typename?: 'ClientExportsDataItem';
  id_exports_data: Scalars['Int'];
  id_export: Scalars['Int'];
  data: Maybe<Scalars['String']>;
  date_created: Scalars['DateTime'];
  file_name: Scalars['String'];
  id_user: Scalars['Int'];
  user_type: Scalars['String'];
}

/** Main.ClientExportsData */
export type ClientExportsDataInput = {
  input: Array<ClientExportsDataItem>;
};

export type ChartData = {
  __typename?: 'ChartData';
  labels: Array<Maybe<Scalars['String']>>;
  datasets: Array<Maybe<DataSet>>;
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  borderColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  borderJoinStyle?: Maybe<BorderStyle>;
  borderWidth?: Maybe<Scalars['String']>;
};

export enum StatsWidgetType {
  TABLE = 'table',
  KPI = 'kpi',
  CHART = 'chart',
}

export enum ChartType {
  TABLE = 'table',
  BAR = 'bar',
  DOUGHNUT = 'doughnut',
  LINE = 'line',
  PIE = 'pie',
  POLARAREA = 'polarArea',
  RADAR = 'radar',
  KPI = 'kpi',
  SCATTER = 'scatter',
}

/** Main.Configuration */
export type Configuration = {
  __typename?: 'Configuration';
  /** IdConfiguration */
  id_configuration: Scalars['Int'];
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  shopsConfiguration?: Maybe<Array<Maybe<ShopsConfiguration>>>;
};

/** Main.Configuration */
export type ConfigurationInput = {
  input: Array<ConfigurationInputItem>;
};

/** Main.Configuration */
export type ConfigurationInputItem = {
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.Configuration */
export type ConfigurationItems = {
  __typename?: 'ConfigurationItems';
  total: Scalars['Int'];
  items: Array<Configuration>;
};

/** Main.Configuration */
export type ConfigurationUpdateInput = {
  input: Array<ConfigurationUpdateInputItem>;
};

/** Main.Configuration */
export type ConfigurationUpdateInputItem = {
  /** IdConfiguration */
  id_configuration?: Maybe<Scalars['Int']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.Credits */
export type Credits = {
  __typename?: 'Credits';
  /** IdCredit */
  id_credit: Scalars['Int'];
  /** IdPsProduct */
  id_ps_product: Scalars['Int'];
  /** Type */
  type: Scalars['String'];
  /** Amount */
  amount: Scalars['Float'];
  /** RealAmount */
  real_amount: Scalars['Float'];
  /** AmountUsd */
  amount_usd: Scalars['Float'];
  /** RealAmountUsd */
  real_amount_usd: Scalars['Float'];
  /** Reduction */
  reduction: Scalars['Float'];
  /** PercentageReduction */
  percentage_reduction: Scalars['Int'];
  /** NbCustomers */
  nb_customers: Scalars['Float'];
  /** Active */
  active: Scalars['Boolean'];
  /** Shops */
  shops?: Maybe<Scalars['String']>;
  shopsCredits?: Maybe<Array<Maybe<ShopsCredits>>>;
};

/** Main.Credits */
export type CreditsInput = {
  input: Array<CreditsInputItem>;
};

/** Main.Credits */
export type CreditsInputItem = {
  /** IdPsProduct */
  id_ps_product: Scalars['Int'];
  /** Type */
  type: Scalars['String'];
  /** Amount */
  amount: Scalars['Float'];
  /** RealAmount */
  real_amount: Scalars['Float'];
  /** AmountUsd */
  amount_usd: Scalars['Float'];
  /** RealAmountUsd */
  real_amount_usd: Scalars['Float'];
  /** Reduction */
  reduction: Scalars['Float'];
  /** PercentageReduction */
  percentage_reduction: Scalars['Int'];
  /** NbCustomers */
  nb_customers: Scalars['Float'];
  /** Active */
  active: Scalars['Boolean'];
  /** Shops */
  shops?: Maybe<Scalars['String']>;
};

/** List of Main.Credits */
export type CreditsItems = {
  __typename?: 'CreditsItems';
  total: Scalars['Int'];
  items: Array<Credits>;
};

/** Main.Credits */
export type CreditsUpdateInput = {
  input: Array<CreditsUpdateInputItem>;
};

/** Main.Credits */
export type CreditsUpdateInputItem = {
  /** IdCredit */
  id_credit?: Maybe<Scalars['Int']>;
  /** IdPsProduct */
  id_ps_product?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Amount */
  amount?: Maybe<Scalars['Float']>;
  /** RealAmount */
  real_amount?: Maybe<Scalars['Float']>;
  /** AmountUsd */
  amount_usd?: Maybe<Scalars['Float']>;
  /** RealAmountUsd */
  real_amount_usd?: Maybe<Scalars['Float']>;
  /** Reduction */
  reduction?: Maybe<Scalars['Float']>;
  /** PercentageReduction */
  percentage_reduction?: Maybe<Scalars['Int']>;
  /** NbCustomers */
  nb_customers?: Maybe<Scalars['Float']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Shops */
  shops?: Maybe<Scalars['String']>;
};

export type DataSet = {
  __typename?: 'DataSet';
  data?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<ChartType>;
  label?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  hoverBackgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  borderColor?: Maybe<Scalars['String']>;
  borderWidth?: Maybe<Scalars['Int']>;
  fill?: Maybe<Scalars['Boolean']>;
  yAxisID?: Maybe<Scalars['String']>;
};

export enum ResolverType {
  Query = 'query',
  Mutation = 'mutation',
}
export enum ActionType {
  All = 'all',
  Visible = 'visible',
  Select = 'select',
  List = 'list'
}

export enum ExportStatus {
  Scheduled = 'scheduled',
  Processed = 'processed',
}

/** Main.DatatablesExports */
export type DatatablesExports = {
  __typename?: 'DatatablesExports';
  /** IdExport */
  id_export: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Filters */
  filters?: Maybe<Scalars['String']>;
  /** Headers */
  headers: Scalars['String'];
  /** FileName */
  file_name?: Maybe<Scalars['String']>;
  /** IdUser */
  id_user: Scalars['Int'];
  /** UserType */
  user_type: Scalars['String'];
  /** ResolverName */
  resolver_name: Scalars['String'];
  /** Locale */
  locale: Scalars['String'];
  /** ResolverType */
  resolver_type: ResolverType;
  /** SelectedIds */
  selected_ids?: Maybe<Scalars['String']>;
  /** ActionType */
  action_type?: Maybe<ActionType>;
  /** ExportStatus */
  status: ExportStatus;
  /** DateAdded */
  date_added: Scalars['DateTime'];
  /** DateModified */
  date_modified?: Maybe<Scalars['DateTime']>;
  /** TableId */
  table_id: Scalars['String'];
};

/** Main.DatatableExports */
export type DatatableExportsInput = {
  input: Array<DatatablesExportsInputItem>;
};

/** Main.DatatableExports */
export type DatatablesExportsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Filters */
  filters: Maybe<Scalars['String']>;
  /** Headers */
  headers: Scalars['String'];
  /** FileName */
  file_name?: Maybe<Scalars['String']>;
  /** IdUser */
  id_user: Scalars['Int'];
  /** UserType */
  user_type: Scalars['String'];
  /** ResolverName */
  resolver_name: Scalars['String'];
  /** Locale */
  locale: Scalars['String'];
  /** ResolverType */
  resolver_type: ResolverType;
  /** SelectedIds */
  selected_ids?: Maybe<Scalars['String']>;
  /** TableId */
  table_id?: Maybe<Scalars['String']>;
  /** ActionType */
  action_type?: Maybe<ActionType>;
  /** ExportStatus */
  status: ExportStatus;
  /** DateAdded */
  date_added: Scalars['DateTime'];
  /** DateModified */
  date_modified?: Maybe<Scalars['DateTime']>;
};

/** List of Main.DatatableExports */
export type DatatableExportsItems = {
  __typename?: 'DatatableExportsItems';
  total: Scalars['Int'];
  items: Array<DatatablesExports>;
};

/** Main.DatatableExports */
export type DatatableExportsUpdateInput = {
  input: Array<DatatableExportsUpdateInputItem>;
};

/** Main.DatatableExports */
export type DatatableExportsUpdateInputItem = {
  /** IdExport */
  id_export: Scalars['Int'];
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Filters */
  filters?: Maybe<Scalars['String']>;
  /** Headers */
  headers: Scalars['String'];
  /** FileName */
  file_name?: Maybe<Scalars['String']>;
  /** IdUser */
  id_user?: Maybe<Scalars['Int']>;
  /** UserType */
  user_type?: Maybe<Scalars['String']>;
  /** ResolverName */
  resolver_name?: Maybe<Scalars['String']>;
  /** Locale */
  locale?: Maybe<Scalars['String']>;
  /** ResolverType */
  resolver_type?: Maybe<ResolverType>;
  /** SelectedIds */
  selected_ids?: Maybe<Scalars['String']>;
  /** TableId */
  table_id?: Maybe<Scalars['String']>;
  /** ActionType */
  action_type?: Maybe<ActionType>;
  /** ExportStatus */
  status?: Maybe<ExportStatus>;
  /** DateAdded */
  date_added?: Maybe<Scalars['DateTime']>;
  /** DateModified */
  date_modified?: Maybe<Scalars['DateTime']>;
};

/** Main.DisplayCampaigns */
export type DisplayCampaigns = {
  __typename?: 'DisplayCampaigns';
  /** IdDisplayCampaigns */
  id_display_campaigns: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Active */
  active: Scalars['Boolean'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** IdTemplateBase */
  id_template_base?: Maybe<Scalars['String']>;
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['String']>;
  /** Configuration */
  configuration?: Maybe<Scalars['String']>;
};

/** Main.DisplayCampaigns */
export type DisplayCampaignsInput = {
  input: Array<DisplayCampaignsInputItem>;
};

/** Main.DisplayCampaigns */
export type DisplayCampaignsInputItem = {
  __typename?: 'DisplayCampaignsInputItem';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Active */
  active: Scalars['Boolean'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** IdTemplateBase */
  id_template_base?: Maybe<Scalars['String']>;
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['String']>;
  /** Configuration */
  configuration?: Maybe<Scalars['String']>;
};

/** List of Main.DisplayCampaigns */
export type DisplayCampaignsItems = {
  __typename?: 'DisplayCampaignsItems';
  total: Scalars['Int'];
  items: Array<DisplayCampaigns>;
};

/** Main.DisplayCampaigns */
export type DisplayCampaignsUpdateInput = {
  input: Array<DisplayCampaignsUpdateInputItem>;
};

/** Main.DatatableExports */
export type DisplayCampaignsUpdateInputItem = {
  __typename?: 'DisplayCampaignsUpdateInputItem';
  /** IdDisplayCampaigns */
  id_display_campaigns: Scalars['Int'];
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** IdTemplateBase */
  id_template_base?: Maybe<Scalars['String']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['String']>;
  /** Configuration */
  configuration?: Maybe<Scalars['String']>;
};

/** Data.EmailsRejectsShops */
export type EmailsRejectsShops = {
  __typename?: 'EmailsRejectsShops';
  /** IdEmailReject */
  id_email_reject: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Email */
  email: Scalars['String'];
  /** RejectType */
  reject_type: Scalars['String'];
  /** RejectFrom */
  reject_from: Scalars['String'];
  /** RejectMessage */
  reject_message?: Maybe<Scalars['String']>;
  /** EmsCategory */
  ems_category?: Maybe<Scalars['String']>;
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Data.EmailsRejectsShops */
export type EmailsRejectsShopsItems = {
  __typename?: 'EmailsRejectsShopsItems';
  total: Scalars['Int'];
  items: Array<EmailsRejectsShops>;
};

export type FilterParameters = {
  field: Scalars['String'];
  value: Scalars['String'];
  operator: OperatorType;
};

export type ListingParameters = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<Array<Maybe<FilterParameters>>>;
  order?: Maybe<Array<Maybe<OrderParameters>>>;
};

/** Main.MarketingWorkflows */
export type MarketingWorkflows = {
  __typename?: 'MarketingWorkflows';
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdWorkflowBase */
  id_workflow_base: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** DataMarketingWorkflow */
  data_marketing_workflow: Scalars['String'];
  /** NameMarketingWorkflow */
  name_marketing_workflow: Scalars['String'];
  /** LegendMarketingWorkflow */
  legend_marketing_workflow: Scalars['String'];
  /** TypeWorkflow */
  type_workflow: Scalars['String'];
  /** Unique */
  unique: Scalars['Boolean'];
  /** TypeUnique */
  type_unique?: Maybe<Scalars['Boolean']>;
  /** TypeRetriggered */
  type_retriggered?: Maybe<Scalars['Boolean']>;
  /** TriggerTime */
  trigger_time?: Maybe<Scalars['Boolean']>;
  /** UnitTime */
  unit_time?: Maybe<Scalars['String']>;
  /** Checksum */
  checksum: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** Categories */
  categories: Scalars['String'];
  /** Icons */
  icons: Scalars['String'];
  /** InComplete */
  in_complete: Scalars['Boolean'];
  /** Priority */
  priority: Scalars['Int'];
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['DateTime'];
};

/** Main.MarketingWorkflows */
export type MarketingBaseWorkflows = {
  __typename?: 'MarketingBaseWorkflows';
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** NameMarketingWorkflow */
  name_marketing_workflow: Scalars['String'];
  /** LegendMarketingWorkflow */
  legend_marketing_workflow: Scalars['String'];
  /** Categories */
  categories: Scalars['String'];
  /** Icons */
  images_templates: Maybe<Array<Scalars['String']>>;
  /** DataMarketingWorkflow */
  data_marketing_workflow: Scalars['String'];
};

/** Main.MarketingWorkflowsHistory */
export type MarketingWorkflowsHistory = {
  __typename?: 'MarketingWorkflowsHistory';
  /** IdMarketingWorkflowHistory */
  id_marketing_workflow_history: Scalars['Int'];
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** History */
  history?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Main.MarketingWorkflowsHistory */
export type MarketingWorkflowsHistoryInput = {
  input: Array<MarketingWorkflowsHistoryInputItem>;
};

/** Main.MarketingWorkflowsHistory */
export type MarketingWorkflowsHistoryInputItem = {
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** History */
  history?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.MarketingWorkflowsHistory */
export type MarketingWorkflowsHistoryItems = {
  __typename?: 'MarketingWorkflowsHistoryItems';
  total: Scalars['Int'];
  items: Array<MarketingWorkflowsHistory>;
};

/** Main.MarketingWorkflowsHistory */
export type MarketingWorkflowsHistoryUpdateInput = {
  input: Array<MarketingWorkflowsHistoryUpdateInputItem>;
};

/** Main.MarketingWorkflowsHistory */
export type MarketingWorkflowsHistoryUpdateInputItem = {
  /** IdMarketingWorkflowHistory */
  id_marketing_workflow_history?: Maybe<Scalars['Int']>;
  /** IdMarketingWorkflow */
  id_marketing_workflow?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** History */
  history?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.MarketingWorkflows */
export type MarketingWorkflowsInput = {
  input: Array<MarketingWorkflowsInputItem>;
};

/** Main.MarketingWorkflows */
export type MarketingWorkflowsInputItem = {
  /** IdWorkflowBase */
  id_workflow_base: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** DataMarketingWorkflow */
  data_marketing_workflow: Scalars['String'];
  /** NameMarketingWorkflow */
  name_marketing_workflow: Scalars['String'];
  /** LegendMarketingWorkflow */
  legend_marketing_workflow: Scalars['String'];
  /** TypeWorkflow */
  type_workflow: Scalars['String'];
  /** Unique */
  unique: Scalars['Int'];
  /** TypeUnique */
  type_unique?: Maybe<Scalars['Int']>;
  /** TypeRetriggered */
  type_retriggered?: Maybe<Scalars['Int']>;
  /** TriggerTime */
  trigger_time?: Maybe<Scalars['Int']>;
  /** UnitTime */
  unit_time?: Maybe<Scalars['String']>;
  /** Checksum */
  checksum: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** Categories */
  categories: Scalars['String'];
  /** Icons */
  icons: Scalars['String'];
  /** InComplete */
  in_complete: Scalars['Int'];
  /** Priority */
  priority: Scalars['Int'];
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Main.MarketingWorkflows */
export type MarketingWorkflowsItems = {
  __typename?: 'MarketingWorkflowsItems';
  total: Scalars['Int'];
  items: Array<MarketingWorkflows>;
};

/** Main.MarketingWorkflowsParameters */
export type MarketingWorkflowsParameters = {
  __typename?: 'MarketingWorkflowsParameters';
  /** IdMarketingWorkflowsParameter */
  id_marketing_workflows_parameter: Scalars['Int'];
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step: Scalars['Int'];
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
};

/** Main.MarketingWorkflowsParameters */
export type MarketingWorkflowsParametersCustom = {
 id_shop: Scalars['Int'];

};

/** List of Main.MarketingWorkflowsParameters */
export type MarketingWorkflowsParametersItems = {
  __typename?: 'MarketingWorkflowsParametersItems';
  total: Scalars['Int'];
  items: Array<MarketingWorkflowsParameters>;
};

/** Main.MarketingWorkflowsScheduler */
export type MarketingWorkflowsScheduler = {
  __typename?: 'MarketingWorkflowsScheduler';
  /** IdMarketingWorkflowScheduler */
  id_marketing_workflow_scheduler: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdRestore */
  id_restore: Scalars['Int'];
  /** StartDate */
  start_date: Scalars['String'];
  /** EndDate */
  end_date: Scalars['String'];
  marketingWorkflows?: Maybe<Array<Maybe<MarketingWorkflows>>>;
};

/** Main.MarketingWorkflowsScheduler */
export type MarketingWorkflowsSchedulerInput = {
  input: Array<MarketingWorkflowsSchedulerInputItem>;
};

/** Main.MarketingWorkflowsScheduler */
export type MarketingWorkflowsSchedulerInputItem = {
  /** IdMarketingWorkflowScheduler */
  id_marketing_workflow_scheduler?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdRestore */
  id_restore: Scalars['Int'];
  /** StartDate */
  start_date: Scalars['String'];
  /** EndDate */
  end_date: Scalars['String'];
};

/** List of Main.MarketingWorkflowsScheduler */
export type MarketingWorkflowsSchedulerItems = {
  __typename?: 'MarketingWorkflowsSchedulerItems';
  total: Scalars['Int'];
  items: Array<MarketingWorkflowsScheduler>;
};

/** Main.MarketingWorkflowsScheduler */
export type MarketingWorkflowsSchedulerUpdateInput = {
  input: Array<MarketingWorkflowsSchedulerUpdateInputItem>;
};

/** Main.MarketingWorkflowsScheduler */
export type MarketingWorkflowsSchedulerUpdateInputItem = {
  /** IdMarketingWorkflowScheduler */
  id_marketing_workflow_scheduler?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdWorkflow */
  id_workflow?: Maybe<Scalars['Int']>;
  /** IdRestore */
  id_restore?: Maybe<Scalars['Int']>;
  /** StartDate */
  start_date?: Maybe<Scalars['String']>;
  /** EndDate */
  end_date?: Maybe<Scalars['String']>;
};

/** Main.MarketingWorkflowsSteps */
export type MarketingWorkflowsSteps = {
  __typename?: 'MarketingWorkflowsSteps';
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step: Scalars['Int'];
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Class */
  class: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Persistent */
  persistent?: Maybe<Scalars['String']>;
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output: Scalars['Int'];
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent: Scalars['Int'];
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output: Scalars['Int'];
  /** IdFlowchart */
  id_flowchart: Scalars['String'];
  marketingWorkflows?: Maybe<Array<Maybe<MarketingWorkflows>>>;
};

/** Main.MarketingWorkflowsSteps */
export type MarketingWorkflowsStepsInput = {
  input: Array<MarketingWorkflowsStepsInputItem>;
};

/** Main.MarketingWorkflowsSteps */
export type MarketingWorkflowsStepsInputItem = {
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step?: Maybe<Scalars['Int']>;
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Class */
  class: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Persistent */
  persistent?: Maybe<Scalars['String']>;
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output: Scalars['Int'];
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent: Scalars['Int'];
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output: Scalars['Int'];
  /** IdFlowchart */
  id_flowchart: Scalars['String'];
};

/** List of Main.MarketingWorkflowsSteps */
export type MarketingWorkflowsStepsItems = {
  __typename?: 'MarketingWorkflowsStepsItems';
  total: Scalars['Int'];
  items: Array<MarketingWorkflowsSteps>;
};

/** Main.MarketingWorkflowsStepsParentsOutputs */
export type MarketingWorkflowsStepsParentsOutputs = {
  __typename?: 'MarketingWorkflowsStepsParentsOutputs';
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output: Scalars['Int'];
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdMarketingWorkflowStep */
  id_marketing_workflow_step: Scalars['Int'];
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent: Scalars['Int'];
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output: Scalars['Int'];
};

/** List of Main.MarketingWorkflowsStepsParentsOutputs */
export type MarketingWorkflowsStepsParentsOutputsItems = {
  __typename?: 'MarketingWorkflowsStepsParentsOutputsItems';
  total: Scalars['Int'];
  items: Array<MarketingWorkflowsStepsParentsOutputs>;
};

/** Main.MarketingWorkflowsSteps */
export type MarketingWorkflowsStepsUpdateInput = {
  input: Array<MarketingWorkflowsStepsUpdateInputItem>;
};

/** Main.MarketingWorkflowsSteps */
export type MarketingWorkflowsStepsUpdateInputItem = {
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step?: Maybe<Scalars['Int']>;
  /** IdWorkflow */
  id_workflow?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Class */
  class?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Persistent */
  persistent?: Maybe<Scalars['String']>;
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output?: Maybe<Scalars['Int']>;
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent?: Maybe<Scalars['Int']>;
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output?: Maybe<Scalars['Int']>;
  /** IdFlowchart */
  id_flowchart?: Maybe<Scalars['String']>;
};

/** Main.MarketingWorkflows */
export type MarketingWorkflowsUpdateInput = {
  input: Array<MarketingWorkflowsUpdateInputItem>;
};

/** Main.MarketingWorkflows */
export type MarketingWorkflowsUpdateInputItem = {
  /** IdMarketingWorkflow */
  id_marketing_workflow?: Maybe<Scalars['Int']>;
  /** IdWorkflowBase */
  id_workflow_base?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** DataMarketingWorkflow */
  data_marketing_workflow?: Maybe<Scalars['String']>;
  /** NameMarketingWorkflow */
  name_marketing_workflow?: Maybe<Scalars['String']>;
  /** LegendMarketingWorkflow */
  legend_marketing_workflow?: Maybe<Scalars['String']>;
  /** TypeWorkflow */
  type_workflow?: Maybe<Scalars['String']>;
  /** Unique */
  unique?: Maybe<Scalars['Int']>;
  /** TypeUnique */
  type_unique?: Maybe<Scalars['Int']>;
  /** TypeRetriggered */
  type_retriggered?: Maybe<Scalars['Int']>;
  /** TriggerTime */
  trigger_time?: Maybe<Scalars['Int']>;
  /** UnitTime */
  unit_time?: Maybe<Scalars['String']>;
  /** Checksum */
  checksum?: Maybe<Scalars['String']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** Categories */
  categories?: Maybe<Scalars['String']>;
  /** Icons */
  icons?: Maybe<Scalars['String']>;
  /** InComplete */
  in_complete?: Maybe<Scalars['Int']>;
  /** Priority */
  priority?: Maybe<Scalars['Int']>;
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  InsertAdyenTokens?: Maybe<MutationResponse>;
  UpdateAdyenTokens?: Maybe<MutationResponse>;
  InsertAlerts?: Maybe<MutationResponse>;
  UpdateAlerts?: Maybe<MutationResponse>;
  InsertBundles?: Maybe<MutationResponse>;
  UpdateBundles?: Maybe<MutationResponse>;
  InsertConfiguration?: Maybe<MutationResponse>;
  UpdateConfiguration?: Maybe<MutationResponse>;
  InsertCredits?: Maybe<MutationResponse>;
  UpdateCredits?: Maybe<MutationResponse>;
  InsertMarketingWorkflowsHistory?: Maybe<MutationResponse>;
  UpdateMarketingWorkflowsHistory?: Maybe<MutationResponse>;
  InsertNewsletterCampaigns?: Maybe<MutationResponse>;
  UpdateNewsletterCampaigns?: Maybe<MutationResponse>;
  InsertNewsletterCampaignsConfig?: Maybe<MutationResponse>;
  UpdateNewsletterCampaignsConfig?: Maybe<MutationResponse>;
  InsertNewsletterCampaignsSegments?: Maybe<MutationResponse>;
  UpdateNewsletterCampaignsSegments?: Maybe<MutationResponse>;
  InsertShops?: Maybe<MutationResponse>;
  UpdateShops?: Maybe<MutationResponse>;
  InsertShopsAlerts?: Maybe<MutationResponse>;
  UpdateShopsAlerts?: Maybe<MutationResponse>;
  InsertShopsBills?: Maybe<MutationResponse>;
  UpdateShopsBills?: Maybe<MutationResponse>;
  InsertShopsBundles?: Maybe<MutationResponse>;
  UpdateShopsBundles?: Maybe<MutationResponse>;
  SaveShopsConfiguration?: Maybe<MutationResponse>;
  InsertShopsCredits?: Maybe<MutationResponse>;
  UpdateShopsCredits?: Maybe<MutationResponse>;
  InsertShopsLang?: Maybe<MutationResponse>;
  UpdateShopsLang?: Maybe<MutationResponse>;
  InsertShopsLists?: Maybe<MutationResponse>;
  UpdateShopsLists?: Maybe<MutationResponse>;
  DeleteShopsLists?: Maybe<MutationResponse>;
  InsertShopsListsImport?: Maybe<MutationResponse>;
  UpdateShopsListsImport?: Maybe<MutationResponse>;
  InsertShopsNotifications?: Maybe<MutationResponse>;
  UpdateShopsNotifications?: Maybe<MutationResponse>;
  InsertShopsNotificationsRead?: Maybe<MutationResponse>;
  UpdateShopsNotificationsRead?: Maybe<MutationResponse>;
  InsertShopsParticularLists?: Maybe<MutationResponse>;
  UpdateShopsParticularLists?: Maybe<MutationResponse>;
  InsertShopsSendDomains?: Maybe<MutationResponse>;
  UpdateShopsSendDomains?: Maybe<MutationResponse>;
  InsertShopsSyncs?: Maybe<MutationResponse>;
  UpdateShopsSyncs?: Maybe<MutationResponse>;
  InsertSmsCampaigns?: Maybe<MutationResponse>;
  UpdateSmsCampaigns?: Maybe<MutationResponse>;
  InsertSmsPrices?: Maybe<MutationResponse>;
  UpdateSmsPrices?: Maybe<MutationResponse>;
  InsertTemplates?: Maybe<MutationResponse>;
  UpdateTemplates?: Maybe<MutationResponse>;
  InsertTemplatesElements?: Maybe<MutationResponse>;
  UpdateTemplatesElements?: Maybe<MutationResponse>;
  InsertTemplatesElementsHistory?: Maybe<MutationResponse>;
  UpdateTemplatesElementsHistory?: Maybe<MutationResponse>;
  InsertTemplatesElementsHistoryGroup?: Maybe<MutationResponse>;
  UpdateTemplatesElementsHistoryGroup?: Maybe<MutationResponse>;
  InsertTemplatesLang?: Maybe<MutationResponse>;
  UpdateTemplatesLang?: Maybe<MutationResponse>;
  InsertTemplatesParts?: Maybe<MutationResponse>;
  UpdateTemplatesParts?: Maybe<MutationResponse>;
  InsertUsers?: Maybe<MutationResponse>;
  UpdateUsers?: Maybe<MutationResponse>;
  InsertMarketingWorkflows?: Maybe<MutationResponse>;
  UpdateMarketingWorkflows?: Maybe<MutationResponse>;
  InsertMarketingWorkflowsScheduler?: Maybe<MutationResponse>;
  UpdateMarketingWorkflowsScheduler?: Maybe<MutationResponse>;
  InsertMarketingWorkflowsSteps?: Maybe<MutationResponse>;
  UpdateMarketingWorkflowsSteps?: Maybe<MutationResponse>;
  InsertNewsletterSends?: Maybe<MutationResponse>;
  UpdateNewsletterSends?: Maybe<MutationResponse>;
  InsertShopsCarriers?: Maybe<MutationResponse>;
  UpdateShopsCarriers?: Maybe<MutationResponse>;
  InsertShopsCustomers?: Maybe<MutationResponse>;
  UpdateShopsCustomers?: Maybe<MutationResponse>;
  InsertShopsCustomersGroups?: Maybe<MutationResponse>;
  UpdateShopsCustomersGroups?: Maybe<MutationResponse>;
  InsertShopsCustomersGroupsList?: Maybe<MutationResponse>;
  UpdateShopsCustomersGroupsList?: Maybe<MutationResponse>;
  InsertShopsListsActions?: Maybe<MutationResponse>;
  UpdateShopsListsActions?: Maybe<MutationResponse>;
  InsertShopsManufacturers?: Maybe<MutationResponse>;
  UpdateShopsManufacturers?: Maybe<MutationResponse>;
  InsertShopsOrders?: Maybe<MutationResponse>;
  UpdateShopsOrders?: Maybe<MutationResponse>;
  InsertShopsOrdersStatus?: Maybe<MutationResponse>;
  UpdateShopsOrdersStatus?: Maybe<MutationResponse>;
  InsertShopsProducts?: Maybe<MutationResponse>;
  UpdateShopsProducts?: Maybe<MutationResponse>;
  InsertShopsProductsCategoriesList?: Maybe<MutationResponse>;
  UpdateShopsProductsCategoriesList?: Maybe<MutationResponse>;
  InsertShopsTags?: Maybe<MutationResponse>;
  UpdateShopsTags?: Maybe<MutationResponse>;
  DeleteShopsTags?: Maybe<MutationResponse>;
  InsertShopsVouchers?: Maybe<MutationResponse>;
  UpdateShopsVouchers?: Maybe<MutationResponse>;
  InsertShopMarketingWorkflows?: Maybe<MutationResponse>;
  UpdateShopMarketingWorkflows?: Maybe<MutationResponse>;
  InsertShopMarketingWorkflowsScheduler?: Maybe<MutationResponse>;
  UpdateShopMarketingWorkflowsScheduler?: Maybe<MutationResponse>;
  InsertShopMarketingWorkflowsSteps?: Maybe<MutationResponse>;
  UpdateShopMarketingWorkflowsSteps?: Maybe<MutationResponse>;
  InsertCampaignsBulkHistory?: Maybe<MutationResponse>;
  UpdateCampaignsBulkHistory?: Maybe<MutationResponse>;
};

export type MutationInsertAdyenTokensArgs = {
  args?: Maybe<AdyenTokensInput>;
};

export type MutationUpdateAdyenTokensArgs = {
  args?: Maybe<AdyenTokensUpdateInput>;
};

export type MutationInsertAlertsArgs = {
  args?: Maybe<AlertsInput>;
};

export type MutationUpdateAlertsArgs = {
  args?: Maybe<AlertsUpdateInput>;
};

export type MutationInsertBundlesArgs = {
  args?: Maybe<BundlesInput>;
};

export type MutationUpdateBundlesArgs = {
  args?: Maybe<BundlesUpdateInput>;
};

export type MutationInsertConfigurationArgs = {
  args?: Maybe<ConfigurationInputItem>;
};

export type MutationUpdateConfigurationArgs = {
  args?: Maybe<ConfigurationUpdateInputItem>;
};

export type MutationInsertCreditsArgs = {
  args?: Maybe<CreditsInput>;
};

export type MutationUpdateCreditsArgs = {
  args?: Maybe<CreditsUpdateInput>;
};

export type MutationInsertMarketingWorkflowsHistoryArgs = {
  args?: Maybe<MarketingWorkflowsHistoryInput>;
};

export type MutationUpdateMarketingWorkflowsHistoryArgs = {
  args?: Maybe<MarketingWorkflowsHistoryUpdateInput>;
};

export type MutationInsertNewsletterCampaignsArgs = {
  args?: Maybe<NewsletterCampaignsInput>;
};

export type MutationUpdateNewsletterCampaignsArgs = {
  args?: Maybe<NewsletterCampaignsUpdateInput>;
};

export type MutationInsertNewsletterCampaignsConfigArgs = {
  args?: Maybe<NewsletterCampaignsConfigInputItem>;
};

export type MutationUpdateNewsletterCampaignsConfigArgs = {
  args?: Maybe<NewsletterCampaignsConfigUpdateInputItem>;
};

export type MutationInsertNewsletterCampaignsSegmentsArgs = {
  args?: Maybe<NewsletterCampaignsSegmentsInputItem>;
};

export type MutationUpdateNewsletterCampaignsSegmentsArgs = {
  args?: Maybe<NewsletterCampaignsSegmentsUpdateInputItem>;
};

export type MutationInsertShopsArgs = {
  args?: Maybe<ShopsInput>;
};

export type MutationUpdateShopsArgs = {
  args?: Maybe<ShopsUpdateInput>;
};

export type MutationInsertShopsAlertsArgs = {
  args?: Maybe<ShopsAlertsInput>;
};

export type MutationUpdateShopsAlertsArgs = {
  args?: Maybe<ShopsAlertsUpdateInput>;
};

export type MutationInsertShopsBillsArgs = {
  args?: Maybe<ShopsBillsInput>;
};

export type MutationUpdateShopsBillsArgs = {
  args?: Maybe<ShopsBillsUpdateInput>;
};

export type MutationInsertShopsBundlesArgs = {
  args?: Maybe<ShopsBundlesInput>;
};

export type MutationUpdateShopsBundlesArgs = {
  args?: Maybe<ShopsBundlesUpdateInput>;
};

export type MutationSaveShopsConfigurationArgs = {
  args?: Maybe<ShopsConfigurationInput>;
};

export type MutationInsertShopsCreditsArgs = {
  args?: Maybe<ShopsCreditsInput>;
};

export type MutationUpdateShopsCreditsArgs = {
  args?: Maybe<ShopsCreditsUpdateInput>;
};

export type MutationInsertShopsLangArgs = {
  args?: Maybe<ShopsLangInput>;
};

export type MutationUpdateShopsLangArgs = {
  args?: Maybe<ShopsLangUpdateInput>;
};

export type MutationInsertShopsListsArgs = {
  args?: Maybe<ShopsListsInput>;
};

export type MutationUpdateShopsListsArgs = {
  args?: Maybe<ShopsListsUpdateInput>;
};

export type MutationDeleteShopsListsArgs = {
  args?: Maybe<ShopsListsDeleteInput>;
};

export type MutationInsertShopsListsImportArgs = {
  args?: Maybe<ShopsListsImportInput>;
};

export type MutationUpdateShopsListsImportArgs = {
  args?: Maybe<ShopsListsImportUpdateInput>;
};

export type MutationInsertShopsNotificationsArgs = {
  args?: Maybe<ShopsNotificationsInput>;
};

export type MutationUpdateShopsNotificationsArgs = {
  args?: Maybe<ShopsNotificationsUpdateInput>;
};

export type MutationInsertShopsNotificationsReadArgs = {
  args?: Maybe<ShopsNotificationsReadInput>;
};

export type MutationUpdateShopsNotificationsReadArgs = {
  args?: Maybe<ShopsNotificationsReadUpdateInput>;
};

export type MutationInsertShopsParticularListsArgs = {
  args?: Maybe<ShopsParticularListsInput>;
};

export type MutationUpdateShopsParticularListsArgs = {
  args?: Maybe<ShopsParticularListsUpdateInput>;
};

export type MutationInsertShopsSendDomainsArgs = {
  args?: Maybe<ShopsSendDomainsInput>;
};

export type MutationUpdateShopsSendDomainsArgs = {
  args?: Maybe<ShopsSendDomainsUpdateInput>;
};

export type MutationInsertShopsSyncsArgs = {
  args?: Maybe<ShopsSyncsInput>;
};

export type MutationUpdateShopsSyncsArgs = {
  args?: Maybe<ShopsSyncsUpdateInput>;
};

export type MutationInsertSmsCampaignsArgs = {
  args?: Maybe<SmsCampaignsInput>;
};

export type MutationUpdateSmsCampaignsArgs = {
  args?: Maybe<SmsCampaignsUpdateInput>;
};

export type MutationInsertSmsPricesArgs = {
  args?: Maybe<SmsPricesInput>;
};

export type MutationUpdateSmsPricesArgs = {
  args?: Maybe<SmsPricesUpdateInput>;
};

export type MutationInsertTemplatesArgs = {
  args?: Maybe<TemplatesInput>;
};

export type MutationUpdateTemplatesArgs = {
  args?: Maybe<TemplatesUpdateInput>;
};

export type MutationInsertTemplatesElementsArgs = {
  args?: Maybe<TemplatesElementsInput>;
};

export type MutationUpdateTemplatesElementsArgs = {
  args?: Maybe<TemplatesElementsUpdateInput>;
};

export type MutationInsertTemplatesElementsHistoryArgs = {
  args?: Maybe<TemplatesElementsHistoryInput>;
};

export type MutationUpdateTemplatesElementsHistoryArgs = {
  args?: Maybe<TemplatesElementsHistoryUpdateInput>;
};

export type MutationInsertTemplatesElementsHistoryGroupArgs = {
  args?: Maybe<TemplatesElementsHistoryGroupInput>;
};

export type MutationUpdateTemplatesElementsHistoryGroupArgs = {
  args?: Maybe<TemplatesElementsHistoryGroupUpdateInput>;
};

export type MutationInsertTemplatesLangArgs = {
  args?: Maybe<TemplatesLangInput>;
};

export type MutationUpdateTemplatesLangArgs = {
  args?: Maybe<TemplatesLangUpdateInput>;
};

export type MutationInsertTemplatesPartsArgs = {
  args?: Maybe<TemplatesPartsInput>;
};

export type MutationUpdateTemplatesPartsArgs = {
  args?: Maybe<TemplatesPartsUpdateInput>;
};

export type MutationInsertUsersArgs = {
  args?: Maybe<UsersInput>;
};

export type MutationUpdateUsersArgs = {
  args?: Maybe<UsersUpdateInput>;
};

export type MutationInsertMarketingWorkflowsArgs = {
  args?: Maybe<MarketingWorkflowsInput>;
};

export type MutationUpdateMarketingWorkflowsArgs = {
  args?: Maybe<MarketingWorkflowsUpdateInput>;
};

export type MutationInsertMarketingWorkflowsSchedulerArgs = {
  args?: Maybe<MarketingWorkflowsSchedulerInput>;
};

export type MutationUpdateMarketingWorkflowsSchedulerArgs = {
  args?: Maybe<MarketingWorkflowsSchedulerUpdateInput>;
};

export type MutationInsertMarketingWorkflowsStepsArgs = {
  args?: Maybe<MarketingWorkflowsStepsInput>;
};

export type MutationUpdateMarketingWorkflowsStepsArgs = {
  args?: Maybe<MarketingWorkflowsStepsUpdateInput>;
};

export type MutationInsertNewsletterSendsArgs = {
  args?: Maybe<NewsletterSendsInput>;
};

export type MutationUpdateNewsletterSendsArgs = {
  args?: Maybe<NewsletterSendsUpdateInput>;
};

export type MutationInsertShopsCarriersArgs = {
  args?: Maybe<ShopsCarriersInput>;
};

export type MutationUpdateShopsCarriersArgs = {
  args?: Maybe<ShopsCarriersUpdateInput>;
};

export type MutationInsertShopsCustomersArgs = {
  args?: Maybe<ShopsCustomersInput>;
};

export type MutationUpdateShopsCustomersArgs = {
  args?: Maybe<ShopsCustomersUpdateInput>;
};

export type MutationInsertShopsCustomersGroupsArgs = {
  args?: Maybe<ShopsCustomersGroupsInput>;
};

export type MutationUpdateShopsCustomersGroupsArgs = {
  args?: Maybe<ShopsCustomersGroupsUpdateInput>;
};

export type MutationInsertShopsCustomersGroupsListArgs = {
  args?: Maybe<ShopsCustomersGroupsListInput>;
};

export type MutationUpdateShopsCustomersGroupsListArgs = {
  args?: Maybe<ShopsCustomersGroupsListUpdateInput>;
};

export type MutationInsertShopsListsActionsArgs = {
  args?: Maybe<ShopsListsActionsInput>;
};

export type MutationUpdateShopsListsActionsArgs = {
  args?: Maybe<ShopsListsActionsUpdateInput>;
};

export type MutationInsertShopsManufacturersArgs = {
  args?: Maybe<ShopsManufacturersInput>;
};

export type MutationUpdateShopsManufacturersArgs = {
  args?: Maybe<ShopsManufacturersUpdateInput>;
};

export type MutationInsertShopsOrdersArgs = {
  args?: Maybe<ShopsOrdersInput>;
};

export type MutationUpdateShopsOrdersArgs = {
  args?: Maybe<ShopsOrdersUpdateInput>;
};

export type MutationInsertShopsOrdersStatusArgs = {
  args?: Maybe<ShopsOrdersStatusInput>;
};

export type MutationUpdateShopsOrdersStatusArgs = {
  args?: Maybe<ShopsOrdersStatusUpdateInput>;
};

export type MutationInsertShopsProductsArgs = {
  args?: Maybe<ShopsProductsInput>;
};

export type MutationUpdateShopsProductsArgs = {
  args?: Maybe<ShopsProductsUpdateInput>;
};

export type MutationInsertShopsProductsCategoriesListArgs = {
  args?: Maybe<ShopsProductsCategoriesListInput>;
};

export type MutationUpdateShopsProductsCategoriesListArgs = {
  args?: Maybe<ShopsProductsCategoriesListUpdateInput>;
};

export type MutationInsertShopsTagsArgs = {
  args?: Maybe<ShopsTagsInput>;
};

export type MutationUpdateShopsTagsArgs = {
  args?: Maybe<ShopsTagsUpdateInput>;
};

export type MutationDeleteShopsTagsArgs = {
  args?: Maybe<ShopsTagsDeleteInput>;
};

export type MutationInsertShopsVouchersArgs = {
  args?: Maybe<ShopsVouchersInput>;
};

export type MutationUpdateShopsVouchersArgs = {
  args?: Maybe<ShopsVouchersUpdateInput>;
};

export type MutationInsertShopMarketingWorkflowsArgs = {
  args?: Maybe<ShopMarketingWorkflowsInput>;
};

export type MutationUpdateShopMarketingWorkflowsArgs = {
  args?: Maybe<ShopMarketingWorkflowsUpdateInput>;
};

export type MutationSaveShopMarketingWorkflowsArgs = {
  args?: Maybe<ShopMarketingWorkflowsSaveInput>;
};

export type MutationDuplicateShopMarketingWorkflowsArgs = {
  args?: Maybe<ShopMarketingWorkflowsDuplicateInput>;
};

export type MutationInsertShopMarketingWorkflowsSchedulerArgs = {
  args?: Maybe<ShopMarketingWorkflowsSchedulerInput>;
};

export type MutationUpdateShopMarketingWorkflowsSchedulerArgs = {
  args?: Maybe<ShopMarketingWorkflowsSchedulerUpdateInput>;
};

export type MutationInsertShopMarketingWorkflowsStepsArgs = {
  args?: Maybe<ShopMarketingWorkflowsStepsInput>;
};

export type MutationUpdateShopMarketingWorkflowsStepsArgs = {
  args?: Maybe<ShopMarketingWorkflowsStepsUpdateInput>;
};

export type MutationSaveCampaignsBulkArgs = {
  args?: Maybe<CampaignsBulkSaveInput>;
};

export type MutationDuplicateCampaignsBulkArgs = {
  args?: Maybe<CampaignsBulkDuplicateInput>;
};

export type MutationInsertCampaignsBulkHistoryArgs = {
  args?: Maybe<CampaignsBulkHistoryInput>;
};

export type MutationUpdateCampaignsBulkHistoryArgs = {
  args?: Maybe<CampaignsBulkHistoryUpdateInput>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  id?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  messages?: Maybe<Array<Maybe<OutputMessage>>>;
};

/** Main.NewsletterCampaigns */
export type NewsletterCampaigns = {
  __typename?: 'NewsletterCampaigns';
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdList */
  id_list?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** State */
  state: Scalars['String'];
  /** NeededModulos */
  needed_modulos: Scalars['Int'];
  /** IsQueued */
  is_queued?: Maybe<Scalars['Int']>;
  /** FinishedModulos */
  finished_modulos?: Maybe<Scalars['Int']>;
  /** NbSubscribers */
  nb_subscribers?: Maybe<Scalars['Int']>;
  /** NbSent */
  nb_sent?: Maybe<Scalars['Int']>;
  /** NbOpens */
  nb_opens?: Maybe<Scalars['Int']>;
  /** NbClicks */
  nb_clicks?: Maybe<Scalars['Int']>;
  /** NbViews */
  nb_views?: Maybe<Scalars['Int']>;
  /** NbOrders */
  nb_orders?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** AzTestingPercent */
  az_testing_percent?: Maybe<Scalars['Float']>;
  /** AzTestingWinType */
  az_testing_win_type?: Maybe<Scalars['String']>;
  /** AzTestingWinTime */
  az_testing_win_time?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DateSend */
  date_send?: Maybe<Scalars['DateTime']>;
  /** IsFreeCampaign */
  is_free_campaign: Scalars['Boolean'];
  /** Retry */
  retry: Scalars['Int'];
  /** AddedToBundle */
  added_to_bundle: Scalars['Boolean'];
  /** TemporaryTablesCreated */
  temporary_tables_created: Scalars['Boolean'];
  newsletterCampaignsConfig?: Maybe<Array<Maybe<NewsletterCampaignsConfig>>>;
  newsletterCampaignsSegments?: Maybe<Array<Maybe<NewsletterCampaignsSegments>>>;
};

/** Main.NewsletterCampaignsConfig */
export type NewsletterCampaignsConfig = {
  __typename?: 'NewsletterCampaignsConfig';
  /** IdNewsletterCampaignsConfig */
  id_newsletter_campaigns_config: Scalars['Int'];
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  newsletterCampaigns?: Maybe<Array<Maybe<NewsletterCampaigns>>>;
};

/** Main.NewsletterCampaignsConfig */
export type NewsletterCampaignsConfigInput = {
  input: Array<NewsletterCampaignsConfigInputItem>;
};

/** Main.NewsletterCampaignsConfig */
export type NewsletterCampaignsConfigInputItem = {
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** List of Main.NewsletterCampaignsConfig */
export type NewsletterCampaignsConfigItems = {
  __typename?: 'NewsletterCampaignsConfigItems';
  total: Scalars['Int'];
  items: Array<NewsletterCampaignsConfig>;
};

/** Main.NewsletterCampaignsConfig */
export type NewsletterCampaignsConfigUpdateInput = {
  input: Array<NewsletterCampaignsConfigUpdateInputItem>;
};

/** Main.NewsletterCampaignsConfig */
export type NewsletterCampaignsConfigUpdateInputItem = {
  /** IdNewsletterCampaignsConfig */
  id_newsletter_campaigns_config?: Maybe<Scalars['Int']>;
  /** IdNewsletterCampaign */
  id_newsletter_campaign?: Maybe<Scalars['Int']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.NewsletterCampaigns */
export type NewsletterCampaignsInput = {
  input: Array<NewsletterCampaignsInputItem>;
};

/** Main.NewsletterCampaigns */
export type NewsletterCampaignsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdList */
  id_list?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** State */
  state: Scalars['String'];
  /** NeededModulos */
  needed_modulos: Scalars['Int'];
  /** IsQueued */
  is_queued?: Maybe<Scalars['Int']>;
  /** FinishedModulos */
  finished_modulos?: Maybe<Scalars['Int']>;
  /** NbSubscribers */
  nb_subscribers?: Maybe<Scalars['Int']>;
  /** NbSent */
  nb_sent?: Maybe<Scalars['Int']>;
  /** NbOpens */
  nb_opens?: Maybe<Scalars['Int']>;
  /** NbClicks */
  nb_clicks?: Maybe<Scalars['Int']>;
  /** NbViews */
  nb_views?: Maybe<Scalars['Int']>;
  /** NbOrders */
  nb_orders?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** AzTestingPercent */
  az_testing_percent?: Maybe<Scalars['Float']>;
  /** AzTestingWinType */
  az_testing_win_type?: Maybe<Scalars['String']>;
  /** AzTestingWinTime */
  az_testing_win_time?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DateSend */
  date_send?: Maybe<Scalars['DateTime']>;
  /** IsFreeCampaign */
  is_free_campaign: Scalars['Boolean'];
  /** Retry */
  retry: Scalars['Int'];
  /** AddedToBundle */
  added_to_bundle: Scalars['Boolean'];
  /** TemporaryTablesCreated */
  temporary_tables_created: Scalars['Boolean'];
};

/** List of Main.NewsletterCampaigns */
export type NewsletterCampaignsItems = {
  __typename?: 'NewsletterCampaignsItems';
  total: Scalars['Int'];
  items: Array<NewsletterCampaigns>;
};

/** Main.NewsletterCampaignsSegments */
export type NewsletterCampaignsSegments = {
  __typename?: 'NewsletterCampaignsSegments';
  /** IdNewsletterCampaignsSegment */
  id_newsletter_campaigns_segment: Scalars['Int'];
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  newsletterCampaigns?: Maybe<Array<Maybe<NewsletterCampaigns>>>;
};

/** Main.NewsletterCampaignsSegments */
export type NewsletterCampaignsSegmentsInput = {
  input: Array<NewsletterCampaignsSegmentsInputItem>;
};

/** Main.NewsletterCampaignsSegments */
export type NewsletterCampaignsSegmentsInputItem = {
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** List of Main.NewsletterCampaignsSegments */
export type NewsletterCampaignsSegmentsItems = {
  __typename?: 'NewsletterCampaignsSegmentsItems';
  total: Scalars['Int'];
  items: Array<NewsletterCampaignsSegments>;
};

/** Main.NewsletterCampaignsSegments */
export type NewsletterCampaignsSegmentsUpdateInput = {
  input: Array<NewsletterCampaignsSegmentsUpdateInputItem>;
};

/** Main.NewsletterCampaignsSegments */
export type NewsletterCampaignsSegmentsUpdateInputItem = {
  /** IdNewsletterCampaignsSegment */
  id_newsletter_campaigns_segment?: Maybe<Scalars['Int']>;
  /** IdNewsletterCampaign */
  id_newsletter_campaign?: Maybe<Scalars['Int']>;
  /** Key */
  key?: Maybe<Scalars['String']>;
  /** Value */
  value?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.NewsletterCampaigns */
export type NewsletterCampaignsUpdateInput = {
  input: Array<NewsletterCampaignsUpdateInputItem>;
};

/** Main.NewsletterCampaigns */
export type NewsletterCampaignsUpdateInputItem = {
  /** IdNewsletterCampaign */
  id_newsletter_campaign?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdList */
  id_list?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** NeededModulos */
  needed_modulos?: Maybe<Scalars['Int']>;
  /** IsQueued */
  is_queued?: Maybe<Scalars['Int']>;
  /** FinishedModulos */
  finished_modulos?: Maybe<Scalars['Int']>;
  /** NbSubscribers */
  nb_subscribers?: Maybe<Scalars['Int']>;
  /** NbSent */
  nb_sent?: Maybe<Scalars['Int']>;
  /** NbOpens */
  nb_opens?: Maybe<Scalars['Int']>;
  /** NbClicks */
  nb_clicks?: Maybe<Scalars['Int']>;
  /** NbViews */
  nb_views?: Maybe<Scalars['Int']>;
  /** NbOrders */
  nb_orders?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** AzTestingPercent */
  az_testing_percent?: Maybe<Scalars['Float']>;
  /** AzTestingWinType */
  az_testing_win_type?: Maybe<Scalars['String']>;
  /** AzTestingWinTime */
  az_testing_win_time?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DateSend */
  date_send?: Maybe<Scalars['DateTime']>;
  /** IsFreeCampaign */
  is_free_campaign?: Maybe<Scalars['Boolean']>;
  /** Retry */
  retry?: Maybe<Scalars['Int']>;
  /** AddedToBundle */
  added_to_bundle?: Maybe<Scalars['Boolean']>;
  /** TemporaryTablesCreated */
  temporary_tables_created?: Maybe<Scalars['Boolean']>;
};

/** Data.NewsletterSends */
export type NewsletterSends = {
  __typename?: 'NewsletterSends';
  /** IdNewsletterSend */
  id_newsletter_send: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** AzTesting */
  az_testing?: Maybe<Scalars['Int']>;
  /** VoucherUsed */
  voucher_used?: Maybe<Scalars['String']>;
  /** SendKey */
  send_key?: Maybe<Scalars['String']>;
  /** LastView */
  last_view: Scalars['DateTime'];
  /** LastClick */
  last_click: Scalars['DateTime'];
  /** LastOrder */
  last_order: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
  /** IdNewsletterSendsVariable */
  id_newsletter_sends_variable: Scalars['Int'];
  /** IdNewsletterSendsProduct */
  id_newsletter_sends_product: Scalars['Int'];
};

/** Data.NewsletterSends */
export type NewsletterSendsInput = {
  input: Array<NewsletterSendsInputItem>;
};

/** Data.NewsletterSends */
export type NewsletterSendsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdNewsletterCampaign */
  id_newsletter_campaign: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** AzTesting */
  az_testing?: Maybe<Scalars['Int']>;
  /** VoucherUsed */
  voucher_used?: Maybe<Scalars['String']>;
  /** SendKey */
  send_key?: Maybe<Scalars['String']>;
  /** LastView */
  last_view: Scalars['DateTime'];
  /** LastClick */
  last_click: Scalars['DateTime'];
  /** LastOrder */
  last_order: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** IdNewsletterSendsVariable */
  id_newsletter_sends_variable: Scalars['Int'];
  /** IdNewsletterSendsProduct */
  id_newsletter_sends_product: Scalars['Int'];
};

/** List of Data.NewsletterSends */
export type NewsletterSendsItems = {
  __typename?: 'NewsletterSendsItems';
  total: Scalars['Int'];
  items: Array<NewsletterSends>;
};

/** Data.NewsletterSends */
export type NewsletterSendsUpdateInput = {
  input: Array<NewsletterSendsUpdateInputItem>;
};

/** Data.NewsletterSends */
export type NewsletterSendsUpdateInputItem = {
  /** IdNewsletterSend */
  id_newsletter_send?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdNewsletterCampaign */
  id_newsletter_campaign?: Maybe<Scalars['Int']>;
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** AzTesting */
  az_testing?: Maybe<Scalars['Int']>;
  /** VoucherUsed */
  voucher_used?: Maybe<Scalars['String']>;
  /** SendKey */
  send_key?: Maybe<Scalars['String']>;
  /** LastView */
  last_view?: Maybe<Scalars['DateTime']>;
  /** LastClick */
  last_click?: Maybe<Scalars['DateTime']>;
  /** LastOrder */
  last_order?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** IdNewsletterSendsVariable */
  id_newsletter_sends_variable?: Maybe<Scalars['Int']>;
  /** IdNewsletterSendsProduct */
  id_newsletter_sends_product?: Maybe<Scalars['Int']>;
};

/** Operator for filtering */
export enum OperatorType {
  /** Field starts with value  */
  StartsWith = 'STARTS_WITH',
  /** Field contains value */
  Contains = 'CONTAINS',
  /** Field does not contain value */
  NotContains = 'NOT_CONTAINS',
  /** Field ends with value */
  EndsWith = 'ENDS_WITH',
  /** Field equals */
  Equals = 'EQUALS',
  /** Field does not equal */
  NotEquals = 'NOT_EQUALS',
  /** Field is less than value */
  LessThan = 'LESS_THAN',
  /** Field is less than or equal to value */
  LessThanOrEqualTo = 'LESS_THAN_OR_EQUAL_TO',
  /** Field is greater than value */
  GreaterThan = 'GREATER_THAN',
  /** Field is greater than or equal to value */
  GreaterThanOrEqualTo = 'GREATER_THAN_OR_EQUAL_TO',
  /** Field is one among the value(s). Value(s) separated by comma */
  In = 'IN',
  /** Field is like value, percent(%) sign can be used to find by part of a string */
  Like = 'LIKE',
  /** Field is null */
  IsNull = 'ISNULL',
  /** Field is not null */
  IsNotNull = 'ISNOTNULL',
  /** Field is already defined in query with value OR IS NULL */
  OrIsNull = 'ORISNULL',
  /** For bitwise operations */
  Ampersand='AMPERSAND',
  /** Not equals ampersand */
  NotEqualsAmpersand='NOTEQUALSAMPERSAND'
}

export type OrderParameters = {
  field: Scalars['String'];
  type: OrderType;
};

/** Sorting order */
export enum OrderType {
  /** A-Z */
  Asc = 'ASC',
  /** Z-A */
  Desc = 'DESC'
}

/** Message received as output from an operation, typically a mutation */
export type OutputMessage = {
  __typename?: 'OutputMessage';
  type?: Maybe<OutputMessageType>;
  message?: Maybe<Scalars['String']>;
};

export enum OutputMessageType {
  /** Error message */
  Error = 'ERROR',
  /** Warning message */
  Warning = 'WARNING',
  /** Info message */
  Info = 'INFO',
  /** Success message */
  Success = 'SUCCESS'
}

/** Parameters for Push Token */
export type PushTokenParams = {
  __typename?: 'PushTokenParams';
  /** Private key to send push notifications */
  privateKey?: Maybe<Scalars['String']>;
  /** Public key to send push notifications */
  publicKey?: Maybe<Scalars['String']>;
  /** URL of latest version of SPM Client Module */
  downloadURL?: Maybe<Scalars['String']>;
  /** Current version of the shop */
  shopCurrentVersion?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  AdyenTokensGet: AdyenTokens;
  AdyenTokensList: AdyenTokensItems;
  AlertsGet: Alerts;
  AlertsList: AlertsItems;
  BundlesGet: Bundles;
  BundlesList: BundlesItems;
  ConfigurationGet: Configuration;
  ConfigurationList: ConfigurationItems;
  CreditsGet: Credits;
  CreditsList: CreditsItems;
  MarketingWorkflowsStepsParentsOutputsGet: MarketingWorkflowsStepsParentsOutputs;
  MarketingWorkflowsStepsParentsOutputsList: MarketingWorkflowsStepsParentsOutputsItems;
  MarketingWorkflowsHistoryGet: MarketingWorkflowsHistory;
  MarketingWorkflowsHistoryList: MarketingWorkflowsHistoryItems;
  NewsletterCampaignsGet: NewsletterCampaigns;
  NewsletterCampaignsList: NewsletterCampaignsItems;
  NewsletterCampaignsConfigGet: NewsletterCampaignsConfig;
  NewsletterCampaignsConfigList: NewsletterCampaignsConfigItems;
  NewsletterCampaignsSegmentsGet: NewsletterCampaignsSegments;
  NewsletterCampaignsSegmentsList: NewsletterCampaignsSegmentsItems;
  ShopsGet: Shops;
  ShopsList: ShopsItems;
  ShopsAlertsGet: ShopsAlerts;
  ShopsAlertsList: ShopsAlertsItems;
  ShopsBillsGet: ShopsBills;
  ShopsBillsList: ShopsBillsItems;
  ShopsBundlesGet: ShopsBundles;
  ShopsBundlesList: ShopsBundlesItems;
  ShopsConfigurationGet: ShopsConfiguration;
  ShopsConfigurationList: ShopsConfigurationItems;
  ShopsCreditsGet: ShopsCredits;
  ShopsCreditsList: ShopsCreditsItems;
  ShopsExportsGet: ShopsExports;
  ShopsExportsList: ShopsExportsItems;
  ShopsHasBundleList: ShopsHasBundleItems;
  ShopsHasBundleActiveList: ShopsHasBundleActiveItems;
  ShopsLangGet: ShopsLang;
  ShopsLangList: ShopsLangItems;
  ShopsListsGet: ShopsLists;
  ShopsListsList: ShopsListsItems;
  ShopsListsImportGet: ShopsListsImport;
  ShopsListsImportList: ShopsListsImportItems;
  ShopsLogsActionsGet: ShopsLogsActions;
  ShopsLogsActionsList: ShopsLogsActionsItems;
  ShopsNotificationsGet: ShopsNotifications;
  ShopsNotificationsList: ShopsNotificationsItems;
  ShopsNotificationsReadGet: ShopsNotificationsRead;
  ShopsNotificationsReadList: ShopsNotificationsReadItems;
  ShopsParticularListsGet: ShopsParticularLists;
  ShopsParticularListsList: ShopsParticularListsItems;
  ShopsSendDomainsGet: ShopsSendDomains;
  ShopsSendDomainsList: ShopsSendDomainsItems;
  ShopsSyncsGet: ShopsSyncs;
  ShopsSyncsList: ShopsSyncsItems;
  SmsCampaignsGet: SmsCampaigns;
  SmsCampaignsList: SmsCampaignsItems;
  SmsPricesGet: SmsPrices;
  SmsPricesList: SmsPricesItems;
  TemplatesGet: Templates;
  TemplatesList: TemplatesItems;
  TemplatesElementsGet: TemplatesElements;
  TemplatesElementsList: TemplatesElementsItems;
  TemplatesElementsHistoryGet: TemplatesElementsHistory;
  TemplatesElementsHistoryList: TemplatesElementsHistoryItems;
  TemplatesElementsHistoryGroupGet: TemplatesElementsHistoryGroup;
  TemplatesElementsHistoryGroupList: TemplatesElementsHistoryGroupItems;
  TemplatesLangGet: TemplatesLang;
  TemplatesLangList: TemplatesLangItems;
  TemplatesPartsGet: TemplatesParts;
  TemplatesPartsList: TemplatesPartsItems;
  TemplatesShopWebsiteContent?: Maybe<Scalars['String']>;
  UsersGet: Users;
  UsersList: UsersItems;
  EmailsRejectsShopsGet: EmailsRejectsShops;
  EmailsRejectsShopsList: EmailsRejectsShopsItems;
  NewsletterSendsGet: NewsletterSends;
  NewsletterSendsList: NewsletterSendsItems;
  ShopsCarriersGet: ShopsCarriers;
  ShopsCarriersList: ShopsCarriersItems;
  ShopsCustomersGet: ShopsCustomers;
  ShopsCustomersList: ShopsCustomersItems;
  ShopsCustomersGroupsGet: ShopsCustomersGroups;
  ShopsCustomersGroupsList: ShopsCustomersGroupsItems;
  ShopsCustomersGroupsListGet: ShopsCustomersGroupsList;
  ShopsCustomersGroupsListList: ShopsCustomersGroupsListItems;
  ShopsCustomersPhonesList: ShopsCustomersPhonesItems;
  ShopsOrdersProductsList: ShopsOrdersProductsItems;
  ShopsCustomersTagsList: ShopsCustomersTagsItems;
  ShopsListsActionsGet: ShopsListsActions;
  ShopsListsActionsList: ShopsListsActionsItems;
  ShopsManufacturersGet: ShopsManufacturers;
  ShopsManufacturersList: ShopsManufacturersItems;
  ShopsOrdersGet: ShopsOrders;
  ShopsOrdersList: ShopsOrdersItems;
  ShopsOrdersStatusGet: ShopsOrdersStatus;
  ShopsOrdersStatusList: ShopsOrdersStatusItems;
  ShopsProductsGet: ShopsProducts;
  ShopsProductsList: ShopsProductsItems;
  ShopsProductsCategoriesListGet: ShopsProductsCategoriesList;
  ShopsProductsCategoriesListList: ShopsProductsCategoriesListItems;
  ShopsPushTokenGet: ShopsPushToken;
  ShopsPushTokenList: ShopsPushTokenItems;
  ShopsTagsGet: ShopsTags;
  ShopsTagsList: ShopsTagsItems;
  ShopsVouchersGet: ShopsVouchers;
  ShopsVouchersList: ShopsVouchersItems;
  StatsIncomeByPeriodGet: StatsIncomeByPeriod;
  StatsIncomeByPeriodList: StatsIncomeByPeriodItems;
  StatsPagesViewsByPeriodGet: StatsPagesViewsByPeriod;
  StatsPagesViewsByPeriodList: StatsPagesViewsByPeriodItems;
  StatsRegistrationsByPeriodGet: StatsRegistrationsByPeriod;
  StatsRegistrationsByPeriodList: StatsRegistrationsByPeriodItems;
  StatsSalesByPeriodGet: StatsSalesByPeriod;
  StatsSalesByPeriodList: StatsSalesByPeriodItems;
  MarketingWorkflowsGet: ShopMarketingWorkflows;
  MarketingWorkflowsList: ShopMarketingWorkflowsItems;
  MarketingWorkflowsSchedulerGet: ShopMarketingWorkflowsScheduler;
  MarketingWorkflowsSchedulerList: ShopMarketingWorkflowsSchedulerItems;
  MarketingWorkflowsStepsGet: ShopMarketingWorkflowsSteps;
  MarketingWorkflowsStepsList: ShopMarketingWorkflowsStepsItems;
  MarketingWorkflowsParametersGet: ShopMarketingWorkflowsParameters;
  MarketingWorkflowsParametersList: ShopMarketingWorkflowsParametersItems;
  TemplatesListCustom?: Maybe<Array<Maybe<TemplatesCustom>>>;
  TemplatesListRecursive?: Maybe<Array<Maybe<TemplatesCustom>>>;
  ShopsCustomersEmailsRejectsShopsList: ShopsCustomersEmailsRejectsShopsItems;
  PushTokenParamsGet: PushTokenParams;
  SmartProductList: Scalars['String'];
  MarketingWorkflowsHistoryByWorkflowGet: MarketingWorkflowsHistory;
  StatsChartGet?: Maybe<StatsResponse>;
  StatsKpiGet?: Maybe<StatsResponse>;
  StatsTableGet?: Maybe<StatsResponse>;
  ShopsCustomersManageList: ShopsCustomersManageItems;
  ShopsCustomersShopsListsList: ShopsCustomersShopsListsItems;
  ShopsListsShopParticularList: ShopsListsShopsParticularListsItems;
  ShopMonthlyPagesViewed: ShopMonthlyPagesViewedItem;
  CampaignsBulkGet: CampaignsBulk;
  CampaignsBulkList: CampaignsBulkItems;
  CampaignsBulkHistoryByCampaignGet: CampaignsBulkHistory;
};

export type QueryAdyenTokensGetArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type QueryAdyenTokensListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryAlertsGetArgs = {
  id_alert?: Maybe<Scalars['Int']>;
};

export type QueryAlertsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryBundlesGetArgs = {
  id_bundle?: Maybe<Scalars['Int']>;
};

export type QueryBundlesListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryConfigurationGetArgs = {
  id_configuration?: Maybe<Scalars['Int']>;
};

export type QueryConfigurationListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryCreditsGetArgs = {
  id_credit?: Maybe<Scalars['Int']>;
};

export type QueryCreditsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryMarketingWorkflowsStepsParentsOutputsGetArgs = {
  id_marketing_workflows_steps_parents_output?: Maybe<Scalars['Int']>;
};

export type QueryMarketingWorkflowsStepsParentsOutputsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryMarketingWorkflowsHistoryGetArgs = {
  id_marketing_workflow_history?: Maybe<Scalars['Int']>;
};

export type QueryMarketingWorkflowsHistoryListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryNewsletterCampaignsGetArgs = {
  id_newsletter_campaign?: Maybe<Scalars['Int']>;
};

export type QueryNewsletterCampaignsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryNewsletterCampaignsConfigGetArgs = {
  id_newsletter_campaigns_config?: Maybe<Scalars['Int']>;
};

export type QueryNewsletterCampaignsConfigListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryNewsletterCampaignsSegmentsGetArgs = {
  id_newsletter_campaigns_segment?: Maybe<Scalars['Int']>;
};

export type QueryNewsletterCampaignsSegmentsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
};

export type QueryShopsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsAlertsGetArgs = {
  id_shop_alert?: Maybe<Scalars['Int']>;
};

export type QueryShopsAlertsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsBillsGetArgs = {
  id_ps_bundle?: Maybe<Scalars['String']>;
};

export type QueryShopsBillsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsBundlesGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
};

export type QueryShopsBundlesListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsConfigurationGetArgs = {
  id_shop_configuration?: Maybe<Scalars['Int']>;
};

export type QueryShopsConfigurationListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsCreditsGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type QueryShopsCreditsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsExportsGetArgs = {
  id_user_export?: Maybe<Scalars['Int']>;
};

export type QueryShopsExportsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsHasBundleListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsHasBundleActiveListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsLangGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};

export type QueryShopsLangListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsListsGetArgs = {
  id_shop_list?: Maybe<Scalars['Int']>;
};

export type QueryShopsListsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsListsImportGetArgs = {
  id_shop_list_import?: Maybe<Scalars['Int']>;
};

export type QueryShopsListsImportListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsLogsActionsGetArgs = {
  id_shop_log_action?: Maybe<Scalars['Int']>;
};

export type QueryShopsLogsActionsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsNotificationsGetArgs = {
  id_shop_notification?: Maybe<Scalars['Int']>;
};

export type QueryShopsNotificationsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsNotificationsReadGetArgs = {
  id_shop_notification?: Maybe<Scalars['Int']>;
  id_shop?: Maybe<Scalars['Int']>;
};

export type QueryShopsNotificationsReadListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsParticularListsGetArgs = {
  id_shop_particular_list?: Maybe<Scalars['Int']>;
};

export type QueryShopsParticularListsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsSendDomainsGetArgs = {
  id_domain?: Maybe<Scalars['Int']>;
};

export type QueryShopsSendDomainsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsSyncsGetArgs = {
  id_shop_syncs?: Maybe<Scalars['Int']>;
};

export type QueryShopsSyncsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QuerySmsCampaignsGetArgs = {
  id_sms_campaign?: Maybe<Scalars['Int']>;
};

export type QuerySmsCampaignsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QuerySmsPricesGetArgs = {
  id_sms_price?: Maybe<Scalars['Int']>;
};

export type QuerySmsPricesListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesGetArgs = {
  id_template?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesElementsGetArgs = {
  id_template_elements?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesElementsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesElementsHistoryGetArgs = {
  id_template_elements_history?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesElementsHistoryListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesElementsHistoryGroupGetArgs = {
  id_template_elements_history_group?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesElementsHistoryGroupListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesLangGetArgs = {
  id_templates_lang?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesLangListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesPartsGetArgs = {
  id_template_parts?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesPartsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesShopWebsiteContentArgs = {
  shopUrl?: Maybe<Scalars['String']>;
};

export type QueryUsersGetArgs = {
  id_user?: Maybe<Scalars['Int']>;
};

export type QueryUsersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryEmailsRejectsShopsGetArgs = {
  id_email_reject?: Maybe<Scalars['Int']>;
  id_shop_customer?: Maybe<Scalars['Int']>;
  id_shop?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  reject_type?: Maybe<Scalars['String']>;
};

export type QueryEmailsRejectsShopsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryNewsletterSendsGetArgs = {
  id_newsletter_send?: Maybe<Scalars['Int']>;
};

export type QueryNewsletterSendsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsCarriersGetArgs = {
  id_shop_carrier?: Maybe<Scalars['Int']>;
};

export type QueryShopsCarriersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsCustomersGetArgs = {
  id_shop_customer?: Maybe<Scalars['Int']>;
};

export type QueryShopsCustomersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsCustomersGroupsGetArgs = {
  id_shop_customer_group?: Maybe<Scalars['Int']>;
};

export type QueryShopsCustomersGroupsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsCustomersGroupsListGetArgs = {
  id_shop_customer_group_list?: Maybe<Scalars['Int']>;
};

export type QueryShopsCustomersGroupsListListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsListsActionsGetArgs = {
  id_shop_list_action?: Maybe<Scalars['Int']>;
};

export type QueryShopsListsActionsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsManufacturersGetArgs = {
  id_shop_manufacturer?: Maybe<Scalars['Int']>;
};

export type QueryShopsManufacturersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsOrdersGetArgs = {
  id_shop_order?: Maybe<Scalars['Int']>;
};

export type QueryShopsOrdersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsOrdersStatusGetArgs = {
  id_shop_order_status?: Maybe<Scalars['Int']>;
};

export type QueryShopsOrdersStatusListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsProductsGetArgs = {
  id_shop_product?: Maybe<Scalars['Int']>;
};

export type QueryShopsProductsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsProductsCategoriesListGetArgs = {
  id_shop_product_category_list?: Maybe<Scalars['Int']>;
};

export type QueryShopsProductsCategoriesListListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsPushTokenGetArgs = {
  id_shop_push_token?: Maybe<Scalars['Int']>;
};

export type QueryShopsPushTokenListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsTagsGetArgs = {
  id_shop_tag?: Maybe<Scalars['Int']>;
};

export type QueryShopsTagsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsVouchersGetArgs = {
  id_shop_voucher?: Maybe<Scalars['Int']>;
};

export type QueryShopsVouchersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryStatsIncomeByPeriodGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  periode_year?: Maybe<Scalars['Int']>;
  periode_month?: Maybe<Scalars['Int']>;
  periode_day?: Maybe<Scalars['Int']>;
  periode_hour?: Maybe<Scalars['Int']>;
};

export type QueryStatsIncomeByPeriodListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryStatsPagesViewsByPeriodGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  periode_year?: Maybe<Scalars['Int']>;
  periode_month?: Maybe<Scalars['Int']>;
  periode_day?: Maybe<Scalars['Int']>;
  periode_hour?: Maybe<Scalars['Int']>;
};

export type QueryStatsPagesViewsByPeriodListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryStatsRegistrationsByPeriodGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  periode_year?: Maybe<Scalars['Int']>;
  periode_month?: Maybe<Scalars['Int']>;
  periode_day?: Maybe<Scalars['Int']>;
  periode_hour?: Maybe<Scalars['Int']>;
};

export type QueryStatsRegistrationsByPeriodListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryStatsSalesByPeriodGetArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  periode_year?: Maybe<Scalars['Int']>;
  periode_month?: Maybe<Scalars['Int']>;
  periode_day?: Maybe<Scalars['Int']>;
  periode_hour?: Maybe<Scalars['Int']>;
};

export type QueryStatsSalesByPeriodListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryMarketingWorkflowsGetArgs = {
  id_marketing_workflow?: Maybe<Scalars['Int']>;
};

export type QueryMarketingWorkflowsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryMarketingWorkflowsSchedulerGetArgs = {
  id_marketing_workflow_scheduler?: Maybe<Scalars['Int']>;
};

export type QueryMarketingWorkflowsSchedulerListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryMarketingWorkflowsStepsGetArgs = {
  id_marketing_workflows_step?: Maybe<Scalars['Int']>;
};

export type QueryMarketingWorkflowsStepsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryMarketingWorkflowsParametersGetArgs = {
  id_marketing_workflows_parameter?: Maybe<Scalars['Int']>;
};

export type QueryMarketingWorkflowsParametersListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryCampaignsBulkGetArgs = {
  id_campaign_bulk?: Maybe<Scalars['Int']>;
};

export type QueryCampaignsBulkListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryTemplatesListCustomArgs = {
  id_shop?: Maybe<Scalars['Int']>;
  id_template?: Maybe<Array<Maybe<Scalars['Int']>>>;
  category?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  campaign_type?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};

export type QueryTemplatesListRecursiveArgs = {
  id_template?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type QueryShopsCustomersEmailsRejectsShopsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryPushTokenParamsGetArgs = {
  id_shop: Scalars['Int'];
};

export type QuerySmartProductListArgs = {
  id_shop: Scalars['Int'];
  lang: Scalars['String'];
  template: Scalars['String'];
};

export type QueryMarketingWorkflowsHistoryByWorkflowGetArgs = {
  id_marketing_workflow?: Maybe<Scalars['Int']>;
  periode_year?: Maybe<Scalars['Int']>;
  periode_month?: Maybe<Scalars['Int']>;
  periode_day?: Maybe<Scalars['Int']>;
  periode_hour?: Maybe<Scalars['Int']>;
};

export type QueryCampaignsBulkHistoryByCampaignGetArgs = {
  id_campaign_bulk?: Maybe<Scalars['Int']>;
};

export type QueryStatsChartGetArgs = {
  params?: Maybe<StatsReqParams>;
};

export type QueryStatsTableGetArgs = {
  params?: Maybe<StatsReqParams>;
};

export type QueryShopsCustomersManageListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsCustomersShopsListsListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopsListsShopParticularListArgs = {
  params?: Maybe<ListingParameters>;
};

export type QueryShopMonthlyPagesViewed = {
  params?: Maybe<ListingParameters>;
};

/** Workflows.ShopMarketingWorkflows */
export type ShopMarketingWorkflows = {
  __typename?: 'ShopMarketingWorkflows';
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdWorkflowBase */
  id_workflow_base: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** DataMarketingWorkflow */
  data_marketing_workflow: Scalars['String'];
  /** NameMarketingWorkflow */
  name_marketing_workflow: Scalars['String'];
  /** LegendMarketingWorkflow */
  legend_marketing_workflow: Scalars['String'];
  /** TypeWorkflow */
  type_workflow: Scalars['String'];
  /** Unique */
  unique: Scalars['Boolean'];
  /** TypeUnique */
  type_unique?: Maybe<Scalars['Boolean']>;
  /** TypeRetriggered */
  type_retriggered?: Maybe<Scalars['Boolean']>;
  /** TriggerTime */
  trigger_time?: Maybe<Scalars['Boolean']>;
  /** UnitTime */
  unit_time?: Maybe<Scalars['String']>;
  /** Checksum */
  checksum: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** Categories */
  categories: Scalars['String'];
  /** Icons */
  icons: Scalars['String'];
  /** InComplete */
  in_complete: Scalars['Boolean'];
  /** Priority */
  priority: Scalars['Int'];
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['DateTime'];
};

/** Workflows.MarketingWorkflows */
export type ShopMarketingWorkflowsInput = {
  input: Array<ShopMarketingWorkflowsInputItem>;
};

/** Workflows.MarketingWorkflows */
export type ShopMarketingWorkflowsInputItem = {
  /** IdMarketingWorkflow */
  id_marketing_workflow?: Maybe<Scalars['Int']>;
  /** IdWorkflowBase */
  id_workflow_base: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** DataMarketingWorkflow */
  data_marketing_workflow: Scalars['String'];
  /** NameMarketingWorkflow */
  name_marketing_workflow: Scalars['String'];
  /** LegendMarketingWorkflow */
  legend_marketing_workflow: Scalars['String'];
  /** TypeWorkflow */
  type_workflow: Scalars['String'];
  /** Unique */
  unique: Scalars['Boolean'];
  /** TypeUnique */
  type_unique?: Maybe<Scalars['Boolean']>;
  /** TypeRetriggered */
  type_retriggered?: Maybe<Scalars['Boolean']>;
  /** TriggerTime */
  trigger_time?: Maybe<Scalars['Boolean']>;
  /** UnitTime */
  unit_time?: Maybe<Scalars['String']>;
  /** Checksum */
  checksum: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** Categories */
  categories: Scalars['String'];
  /** Icons */
  icons: Scalars['String'];
  /** InComplete */
  in_complete: Scalars['Boolean'];
  /** Priority */
  priority: Scalars['Int'];
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Workflows.MarketingWorkflows */
export type ShopMarketingWorkflowsItems = {
  __typename?: 'ShopMarketingWorkflowsItems';
  total: Scalars['Int'];
  items: Array<ShopMarketingWorkflows>;
};

/** Workflows.MarketingWorkflowsSave */
export type ShopMarketingWorkflowsSaveInput = {
  input: ShopMarketingWorkflowsSaveInputItem;
};

/** Workflows.MarketingWorkflowsSave args */
export type ShopMarketingWorkflowsSaveInputItem = {
  /** IdMarketingWorkflow */
  id_marketing_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** Name */
  data_workflow: Scalars['String'];
  /** Name */
  type_workflow: Scalars['String'];
  /** Name */
  unique: Scalars['Int'];
  /** Name */
  type_unique?: Maybe<Scalars['Int']>;
  /** Name */
  type_retrigger?: Maybe<Scalars['Int']>;
  /** Name */
  trigger_unit?: Maybe<Scalars['String']>;
  /** Name */
  trigger_time?: Maybe<Scalars['Int']>;
  /** Name */
  programmed_date_range: Scalars['Int'];
  /** Name */
  programmed_date_range_value?: Maybe<Scalars['String']>;
};

/** Workflows.MarketingWorkflowsDuplicate */
export type ShopMarketingWorkflowsDuplicateInput = {
  input: ShopMarketingWorkflowsDuplicateInputItem;
};

/** Workflows.MarketingWorkflowsDuplicate args */
export type ShopMarketingWorkflowsDuplicateInputItem = {
  /** IdMarketingWorkflow */
  id_marketing_workflow?: Maybe<Scalars['Int']>;
  /** IdShopDestination */
  id_shop_destination: Scalars['Int'];
  /** Name of new list */
  name?: Maybe<Scalars['String']>;
};

/** Workflows.ShopMarketingWorkflowsParameters */
export type ShopMarketingWorkflowsParameters = {
  __typename?: 'ShopMarketingWorkflowsParameters';
  /** IdMarketingWorkflowsParameter */
  id_marketing_workflows_parameter: Scalars['Int'];
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step: Scalars['Int'];
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
};

/** List of Workflows.MarketingWorkflowsParameters */
export type ShopMarketingWorkflowsParametersItems = {
  __typename?: 'ShopMarketingWorkflowsParametersItems';
  total: Scalars['Int'];
  items: Array<ShopMarketingWorkflowsParameters>;
};

/** Workflows.MarketingWorkflowsScheduler */
export type ShopMarketingWorkflowsScheduler = {
  __typename?: 'ShopMarketingWorkflowsScheduler';
  /** IdMarketingWorkflowScheduler */
  id_marketing_workflow_scheduler: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdRestore */
  id_restore: Scalars['Int'];
  /** StartDate */
  start_date: Scalars['String'];
  /** EndDate */
  end_date: Scalars['String'];
  marketingWorkflows?: Maybe<Array<Maybe<ShopMarketingWorkflows>>>;
};

/** Workflows.MarketingWorkflowsScheduler */
export type ShopMarketingWorkflowsSchedulerInput = {
  input: Array<ShopMarketingWorkflowsSchedulerInputItem>;
};

/** Workflows.MarketingWorkflowsScheduler */
export type ShopMarketingWorkflowsSchedulerInputItem = {
  /** IdMarketingWorkflowScheduler */
  id_marketing_workflow_scheduler?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdRestore */
  id_restore: Scalars['Int'];
  /** StartDate */
  start_date: Scalars['String'];
  /** EndDate */
  end_date: Scalars['String'];
};

/** List of Workflows.MarketingWorkflowsScheduler */
export type ShopMarketingWorkflowsSchedulerItems = {
  __typename?: 'ShopMarketingWorkflowsSchedulerItems';
  total: Scalars['Int'];
  items: Array<ShopMarketingWorkflowsScheduler>;
};

/** Workflows.MarketingWorkflowsScheduler */
export type ShopMarketingWorkflowsSchedulerUpdateInput = {
  input: Array<ShopMarketingWorkflowsSchedulerUpdateInputItem>;
};

/** Workflows.MarketingWorkflowsScheduler */
export type ShopMarketingWorkflowsSchedulerUpdateInputItem = {
  /** IdMarketingWorkflowScheduler */
  id_marketing_workflow_scheduler?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdWorkflow */
  id_workflow?: Maybe<Scalars['Int']>;
  /** IdRestore */
  id_restore?: Maybe<Scalars['Int']>;
  /** StartDate */
  start_date?: Maybe<Scalars['String']>;
  /** EndDate */
  end_date?: Maybe<Scalars['String']>;
};

/** Workflows.MarketingWorkflowsSteps */
export type ShopMarketingWorkflowsSteps = {
  __typename?: 'ShopMarketingWorkflowsSteps';
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step: Scalars['Int'];
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Class */
  class: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Persistent */
  persistent?: Maybe<Scalars['String']>;
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output: Scalars['Int'];
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent: Scalars['Int'];
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output: Scalars['Int'];
  /** IdFlowchart */
  id_flowchart: Scalars['String'];
  marketingWorkflows?: Maybe<Array<Maybe<ShopMarketingWorkflows>>>;
};

/** Workflows.MarketingWorkflowsSteps */
export type ShopMarketingWorkflowsStepsInput = {
  input: Array<ShopMarketingWorkflowsStepsInputItem>;
};

/** Workflows.MarketingWorkflowsSteps */
export type ShopMarketingWorkflowsStepsInputItem = {
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step?: Maybe<Scalars['Int']>;
  /** IdWorkflow */
  id_workflow: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Class */
  class: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Persistent */
  persistent?: Maybe<Scalars['String']>;
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output: Scalars['Int'];
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent: Scalars['Int'];
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output: Scalars['Int'];
  /** IdFlowchart */
  id_flowchart: Scalars['String'];
};

/** List of Workflows.MarketingWorkflowsSteps */
export type ShopMarketingWorkflowsStepsItems = {
  __typename?: 'ShopMarketingWorkflowsStepsItems';
  total: Scalars['Int'];
  items: Array<ShopMarketingWorkflowsSteps>;
};

/** Workflows.MarketingWorkflowsSteps */
export type ShopMarketingWorkflowsStepsUpdateInput = {
  input: Array<ShopMarketingWorkflowsStepsUpdateInputItem>;
};

/** Workflows.MarketingWorkflowsSteps */
export type ShopMarketingWorkflowsStepsUpdateInputItem = {
  /** IdMarketingWorkflowsStep */
  id_marketing_workflows_step?: Maybe<Scalars['Int']>;
  /** IdWorkflow */
  id_workflow?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Class */
  class?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Persistent */
  persistent?: Maybe<Scalars['String']>;
  /** IdMarketingWorkflowsStepsParentsOutput */
  id_marketing_workflows_steps_parents_output?: Maybe<Scalars['Int']>;
  /** IdMarketingWorkflowsStepParent */
  id_marketing_workflows_step_parent?: Maybe<Scalars['Int']>;
  /** IdMarketingWorkflowsStepParentOutput */
  id_marketing_workflows_step_parent_output?: Maybe<Scalars['Int']>;
  /** IdFlowchart */
  id_flowchart?: Maybe<Scalars['String']>;
};

/** Workflows.MarketingWorkflows */
export type ShopMarketingWorkflowsUpdateInput = {
  input: Array<ShopMarketingWorkflowsUpdateInputItem>;
};

/** Workflows.MarketingWorkflows */
export type ShopMarketingWorkflowsUpdateInputItem = {
  /** IdMarketingWorkflow */
  id_marketing_workflow?: Maybe<Scalars['Int']>;
  /** IdWorkflowBase */
  id_workflow_base?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** DataMarketingWorkflow */
  data_marketing_workflow?: Maybe<Scalars['String']>;
  /** NameMarketingWorkflow */
  name_marketing_workflow?: Maybe<Scalars['String']>;
  /** LegendMarketingWorkflow */
  legend_marketing_workflow?: Maybe<Scalars['String']>;
  /** TypeWorkflow */
  type_workflow?: Maybe<Scalars['String']>;
  /** Unique */
  unique?: Maybe<Scalars['Boolean']>;
  /** TypeUnique */
  type_unique?: Maybe<Scalars['Boolean']>;
  /** TypeRetriggered */
  type_retriggered?: Maybe<Scalars['Boolean']>;
  /** TriggerTime */
  trigger_time?: Maybe<Scalars['Boolean']>;
  /** UnitTime */
  unit_time?: Maybe<Scalars['String']>;
  /** Checksum */
  checksum?: Maybe<Scalars['String']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** Categories */
  categories?: Maybe<Scalars['String']>;
  /** Icons */
  icons?: Maybe<Scalars['String']>;
  /** InComplete */
  in_complete?: Maybe<Scalars['Boolean']>;
  /** Priority */
  priority?: Maybe<Scalars['Int']>;
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Workflows.ShortUrls */
export type ShortUrlsInputItem = {
  /** ShortCode */
  short_code?: Maybe<Scalars['String']>;
  /** Url */
  url: Scalars['String'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Workflows.ShortUrls */
export type ShortUrlsItems = {
  __typename?: 'ShortUrlsItems';
  total: Scalars['Int'];
  items: Array<ShortUrlsInputItem>;
};

/** Workflows.ShortUrls */
export type ShortUrlsUpdateInput = {
  input: Array<ShortUrlsUpdateInputItem>;
};

/** Workflows.ShortUrls */
export type ShortUrlsUpdateInputItem = {
  /** IdSHortUrl */
  id_short_url: Scalars['Int'];
  /** ShortCode */
  short_code: Maybe<Scalars['String']>;
  /** Url */
  url: Scalars['String'];
};

/** Workflows.CampaignsBulk */
export type CampaignsBulk = {
  __typename?: 'CampaignsBulk';
  /** id_campaign_bulk */
  id_campaign_bulk: Scalars['Int'];
  /** id_campaign_bulk_base */
  id_campaign_bulk_base: Scalars['Int'];
  /** id_shop */
  id_shop: Scalars['Int'];
  /** name */
  name: Scalars['String'];
  /** legend */
  legend: Scalars['String'];
  /** data_campaign */
  data_campaign: Scalars['String'];
  /** data_flowchart */
  data_flowchart: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** date_send */
  date_send: Scalars['DateTime'];
  /** date_creation */
  date_creation: Scalars['DateTime'];
  /** date_modification */
  date_modification: Scalars['DateTime'];
};

/** Workflows.CampaignsBulk */
export type CampaignsBulkInput = {
  input: Array<CampaignsBulkInputItem>;
};

/** Workflows.CampaignsBulk */
export type CampaignsBulkInputItem = {
  /** id_campaign_bulk */
  id_campaign_bulk?: Maybe<Scalars['Int']>;
  /** id_campaign_bulk_base */
  id_campaign_bulk_base: Scalars['Int'];
  /** id_shop */
  id_shop: Scalars['Int'];
  /** name */
  name: Scalars['String'];
  /** legend */
  legend: Scalars['String'];
  /** data_campaign */
  data_campaign: Scalars['String'];
  /** data_flowchart */
  data_flowchart: Scalars['String'];
  /** Status */
  status: Scalars['String'];
  /** date_send */
  date_send: Scalars['DateTime'];
  /** date_creation */
  date_creation: Scalars['DateTime'];
  /** date_modification */
  date_modification: Scalars['DateTime'];
};

/** Workflows.CampaignsBulk */
export type CampaignsBulkUpdateInput = {
  input: Array<CampaignsBulkUpdateInputItem>;
};

/** Workflows.CampaignsBulk */
export type CampaignsBulkUpdateInputItem = {
  /** id_campaign_bulk */
  id_campaign_bulk?: Maybe<Scalars['Int']>;
  /** id_campaign_bulk_base */
  id_campaign_bulk_base?: Maybe<Scalars['Int']>;
  /** id_shop */
  id_shop?: Maybe<Scalars['Int']>;
  /** name */
  name?: Maybe<Scalars['String']>;
  /** legend */
  legend?: Maybe<Scalars['String']>;
  /** data_campaign */
  data_campaign?: Maybe<Scalars['String']>;
  /** data_flowchart */
  data_flowchart?: Maybe<Scalars['String']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** date_send */
  date_send?: Maybe<Scalars['DateTime']>;
  /** date_creation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** date_modification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** List of Workflows.CampaignsBulk */
export type CampaignsBulkItems = {
  __typename?: 'CampaignsBulkItems';
  total: Scalars['Int'];
  items: Array<CampaignsBulk>;
};

export type CampaignsBulkBase = {
  __typename?: 'CampaignsBulkBase';
  /** id_campaign_bulk */
  id_campaign_bulk_base: Scalars['Int'];
  /** name */
  name: Scalars['String'];
  /** legend */
  legend: Scalars['String'];
  /** Categories */
  categories: Scalars['String'];
  /** images_templates */
  images_templates: Maybe<Array<Scalars['String']>>;
};

/** Workflows.CampaignsBulkSave */
export type CampaignsBulkSaveInput = {
  input: CampaignsBulkSaveInputItem;
};

/** Workflows.CampaignsBulkSave args */
export type CampaignsBulkSaveInputItem = {
  /** id_campaign_bulk */
  id_campaign_bulk: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** data_campaign */
  data_campaign: Scalars['String'];
  /** data_flowchart */
  data_flowchart: Scalars['String'];
  /** date_send */
  date_send?: Maybe<Scalars['String']>;
};

/** Workflows.CampaignsBulkDuplicate */
export type CampaignsBulkDuplicateInput = {
  input: CampaignsBulkDuplicateInputItem;
};

/** Workflows.CampaignsBulkDuplicate args */
export type CampaignsBulkDuplicateInputItem = {
  /** id_campaign_bulk */
  id_campaign_bulk?: Maybe<Scalars['Int']>;
  /** IdShopDestination */
  id_shop_destination: Scalars['Int'];
  /** base_campaign */
  base_campaign: Scalars['Int'];
  /** name of new campaign */
  name?: Maybe<Scalars['String']>;
};

/** Main.CampaignsBulkHistory */
export type CampaignsBulkHistory = {
  __typename?: 'CampaignsBulkHistory';
  /** id_campaign_bulk_history */
  id_campaign_bulk_history: Scalars['Int'];
  /** id_campaign_bulk */
  id_campaign_bulk: Scalars['Int'];
  /** id_shop */
  id_shop: Scalars['Int'];
  /** History */
  history?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Main.CampaignsBulkHistory */
export type CampaignsBulkHistoryInput = {
  input: Array<CampaignsBulkHistoryInputItem>;
};

/** Main.CampaignsBulkHistory */
export type CampaignsBulkHistoryInputItem = {
  /** id_campaign_bulk */
  id_campaign_bulk: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** History */
  history?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.CampaignsBulkHistory */
export type CampaignsBulkHistoryItems = {
  __typename?: 'CampaignsBulkHistoryItems';
  total: Scalars['Int'];
  items: Array<CampaignsBulkHistory>;
};

/** Main.CampaignsBulkHistory */
export type CampaignsBulkHistoryUpdateInput = {
  input: Array<CampaignsBulkHistoryUpdateInputItem>;
};

/** Main.CampaignsBulkHistory */
export type CampaignsBulkHistoryUpdateInputItem = {
  /** IdMarketingWorkflowHistory */
  id_campaign_bulk_history?: Maybe<Scalars['Int']>;
  /** IdMarketingWorkflow */
  id_campaign_bulk?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** History */
  history?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.Shops */
export type Shops = {
  __typename?: 'Shops';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** ShopIdShop */
  shop_id_shop?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Url */
  url: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active: Scalars['Boolean'];
  /** ApiKey */
  api_key?: Maybe<Scalars['String']>;
  /** Identifiant */
  identifiant?: Maybe<Scalars['String']>;
  /** ApiUserSmtp */
  api_user_smtp?: Maybe<Scalars['String']>;
  /** ApiPassSmtp */
  api_pass_smtp?: Maybe<Scalars['String']>;
  /** SolutionEcommerce */
  solution_ecommerce: Scalars['String'];
  /** SolutionEcommerceVersion */
  solution_ecommerce_version?: Maybe<Scalars['String']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** IdUser */
  id_user: Scalars['Int'];
  /** IdUserSalesService */
  id_user_sales_service: Scalars['Int'];
  /** IdUserCustomerService */
  id_user_customer_service: Scalars['Int'];
  /** UrlClient */
  url_client?: Maybe<Scalars['String']>;
  /** CurrentVersion */
  current_version?: Maybe<Scalars['String']>;
  /** ModuleVersion */
  module_version?: Maybe<Scalars['String']>;
  /** CurrentBuild */
  current_build?: Maybe<Scalars['Int']>;
  /** TimezoneCode */
  timezone_code?: Maybe<Scalars['String']>;
  /** TimezoneName */
  timezone_name?: Maybe<Scalars['String']>;
  /** TimezoneTime */
  timezone_time?: Maybe<Scalars['Int']>;
  /** LastUpdateTimezone */
  last_update_timezone?: Maybe<Scalars['DateTime']>;
  /** TimeDiffClient */
  time_diff_client?: Maybe<Scalars['Int']>;
  /** LastUpdateCustomersEmails */
  last_update_customers_emails?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCustomers */
  last_update_customers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateProducts */
  last_update_products?: Maybe<Scalars['DateTime']>;
  /** LastUpdateProductsCategories */
  last_update_products_categories?: Maybe<Scalars['DateTime']>;
  /** LastUpdateOrders */
  last_update_orders?: Maybe<Scalars['DateTime']>;
  /** LastUpdateNewsletters */
  last_update_newsletters?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCustomersGroups */
  last_update_customers_groups?: Maybe<Scalars['DateTime']>;
  /** LastUpdateManufacturers */
  last_update_manufacturers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCarriers */
  last_update_carriers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateVouchers */
  last_update_vouchers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateOrdersStatus */
  last_update_orders_status?: Maybe<Scalars['DateTime']>;
  /** CustomerNeedSync */
  customer_need_sync: Scalars['Boolean'];
  /** CategoryNeedSync */
  category_need_sync: Scalars['Boolean'];
  /** ProductNeedSync */
  product_need_sync: Scalars['Boolean'];
  /** OrderNeedSync */
  order_need_sync: Scalars['Boolean'];
  /** NewsletterNeedSync */
  newsletter_need_sync: Scalars['Boolean'];
  /** GroupNeedSync */
  group_need_sync: Scalars['Boolean'];
  /** ManufacturerNeedSync */
  manufacturer_need_sync: Scalars['Boolean'];
  /** CarrierNeedSync */
  carrier_need_sync: Scalars['Boolean'];
  /** VoucherNeedSync */
  voucher_need_sync: Scalars['Boolean'];
  /** StatusNeedSync */
  status_need_sync: Scalars['Boolean'];
  /** IdTemplatesBaseDefault */
  id_templates_base_default?: Maybe<Scalars['Int']>;
  /** TemplatesBaseBodyDefault */
  templates_base_body_default?: Maybe<Scalars['String']>;
  /** IdCategoryShop */
  id_category_shop?: Maybe<Scalars['Int']>;
  /** RelaunchEmailsExclusion */
  relaunch_emails_exclusion?: Maybe<Scalars['String']>;
  /** MultishopEnabled */
  multishop_enabled?: Maybe<Scalars['Boolean']>;
  /** BundleVersion */
  bundle_version: Scalars['Boolean'];
  /** BundleNewsletterVersion */
  bundle_newsletter_version: Scalars['Int'];
  /** OptincollectId */
  optincollect_id?: Maybe<Scalars['String']>;
  /** TestMode */
  test_mode?: Maybe<Scalars['Boolean']>;
  /** IsMigrate */
  is_migrate: Scalars['Boolean'];
  /** HasFivePercentRule */
  has_five_percent_rule: Scalars['Boolean'];
  /** Pool */
  pool?: Maybe<Scalars['String']>;
  /** DedicatedPools */
  dedicated_pools?: Maybe<Scalars['String']>;
  /** Rating */
  rating?: Maybe<Scalars['Float']>;
  /** RatingRetargeting */
  rating_retargeting?: Maybe<Scalars['Float']>;
  /** Services */
  services: Scalars['Boolean'];
  /** ExcludeFromDashboard */
  exclude_from_dashboard: Scalars['Boolean'];
  /** UseWorkflows */
  use_workflows: Scalars['Boolean'];
  /** WorkflowInvitationSended */
  workflow_invitation_sended: Scalars['Boolean'];
  /** RestrictedNewsletter */
  restricted_newsletter: Scalars['Boolean'];
  /** DisplayDisclaimer */
  display_disclaimer: Scalars['Boolean'];
  /** RestrictedDeliverability */
  restricted_deliverability?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<Maybe<Users>>>;
  shopsAlerts?: Maybe<Array<Maybe<ShopsAlerts>>>;
  shopsLang?: Maybe<Array<Maybe<ShopsLang>>>;
  shopsBundles?: Maybe<Array<Maybe<ShopsBundles>>>;
  shopsBills?: Maybe<Array<Maybe<ShopsBills>>>;
  shopsConfiguration?: Maybe<Array<Maybe<ShopsConfiguration>>>;
  shopsNotifications?: Maybe<Array<Maybe<ShopsNotifications>>>;
  shopsParticularLists?: Maybe<Array<Maybe<ShopsParticularLists>>>;
  shopsSendDomains?: Maybe<Array<Maybe<ShopsSendDomains>>>;
};

/** Main.ShopsAlerts */
export type ShopsAlerts = {
  __typename?: 'ShopsAlerts';
  /** IdShopAlert */
  id_shop_alert: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdAlert */
  id_alert: Scalars['Int'];
  /** Value */
  value: Scalars['Int'];
  /** Email */
  email: Scalars['String'];
  /** LastSended */
  last_sended?: Maybe<Scalars['DateTime']>;
  alerts?: Maybe<Array<Maybe<Alerts>>>;
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.ShopsAlerts */
export type ShopsAlertsInput = {
  input: Array<ShopsAlertsInputItem>;
};

/** Main.ShopsAlerts */
export type ShopsAlertsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdAlert */
  id_alert: Scalars['Int'];
  /** Value */
  value: Scalars['Int'];
  /** Email */
  email: Scalars['String'];
  /** LastSended */
  last_sended?: Maybe<Scalars['DateTime']>;
};

/** List of Main.ShopsAlerts */
export type ShopsAlertsItems = {
  __typename?: 'ShopsAlertsItems';
  total: Scalars['Int'];
  items: Array<ShopsAlerts>;
};

/** Main.ShopsAlerts */
export type ShopsAlertsUpdateInput = {
  input: Array<ShopsAlertsUpdateInputItem>;
};

/** Main.ShopsAlerts */
export type ShopsAlertsUpdateInputItem = {
  /** IdShopAlert */
  id_shop_alert?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdAlert */
  id_alert?: Maybe<Scalars['Int']>;
  /** Value */
  value?: Maybe<Scalars['Int']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** LastSended */
  last_sended?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsBills */
export type ShopsBills = {
  __typename?: 'ShopsBills';
  /** IdPsBundle */
  id_ps_bundle: Scalars['String'];
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Date */
  date: Scalars['DateTime'];
  /** AmountHt */
  amount_ht: Scalars['Float'];
  /** AmountTtc */
  amount_ttc: Scalars['Float'];
  /** AmountHtRelaunch */
  amount_ht_relaunch: Scalars['Float'];
  /** AmountHtNewsletter */
  amount_ht_newsletter: Scalars['Float'];
  /** AmountHtRetargeting */
  amount_ht_retargeting: Scalars['Float'];
  /** AmountHtSms */
  amount_ht_sms: Scalars['Float'];
  /** Valid */
  valid: Scalars['Int'];
  /** PaymentError */
  payment_error: Scalars['Int'];
  /** Refund */
  refund: Scalars['Boolean'];
  /** IdCart */
  id_cart?: Maybe<Scalars['Int']>;
  /** Products */
  products?: Maybe<Scalars['String']>;
  /** JsonOrder */
  json_order?: Maybe<Scalars['String']>;
  /** AdyenPspRef */
  adyen_psp_ref?: Maybe<Scalars['String']>;
  /** AdyenPaymentMethod */
  adyen_payment_method?: Maybe<Scalars['String']>;
  /** AdyenReason */
  adyen_reason?: Maybe<Scalars['String']>;
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.ShopsBills */
export type ShopsBillsInput = {
  input: Array<ShopsBillsInputItem>;
};

/** Main.ShopsBills */
export type ShopsBillsInputItem = {
  /** IdPsBundle */
  id_ps_bundle?: Maybe<Scalars['String']>;
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Date */
  date: Scalars['DateTime'];
  /** AmountHt */
  amount_ht: Scalars['Float'];
  /** AmountTtc */
  amount_ttc: Scalars['Float'];
  /** AmountHtRelaunch */
  amount_ht_relaunch: Scalars['Float'];
  /** AmountHtNewsletter */
  amount_ht_newsletter: Scalars['Float'];
  /** AmountHtRetargeting */
  amount_ht_retargeting: Scalars['Float'];
  /** AmountHtSms */
  amount_ht_sms: Scalars['Float'];
  /** Valid */
  valid: Scalars['Int'];
  /** PaymentError */
  payment_error: Scalars['Int'];
  /** Refund */
  refund: Scalars['Boolean'];
  /** IdCart */
  id_cart?: Maybe<Scalars['Int']>;
  /** Products */
  products?: Maybe<Scalars['String']>;
  /** JsonOrder */
  json_order?: Maybe<Scalars['String']>;
  /** AdyenPspRef */
  adyen_psp_ref?: Maybe<Scalars['String']>;
  /** AdyenPaymentMethod */
  adyen_payment_method?: Maybe<Scalars['String']>;
  /** AdyenReason */
  adyen_reason?: Maybe<Scalars['String']>;
};

/** List of Main.ShopsBills */
export type ShopsBillsItems = {
  __typename?: 'ShopsBillsItems';
  total: Scalars['Int'];
  items: Array<ShopsBills>;
};

/** Main.ShopsBills */
export type ShopsBillsUpdateInput = {
  input: Array<ShopsBillsUpdateInputItem>;
};

/** Main.ShopsBills */
export type ShopsBillsUpdateInputItem = {
  /** IdPsBundle */
  id_ps_bundle?: Maybe<Scalars['String']>;
  /** IdBundle */
  id_bundle?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** AmountHt */
  amount_ht?: Maybe<Scalars['Float']>;
  /** AmountTtc */
  amount_ttc?: Maybe<Scalars['Float']>;
  /** AmountHtRelaunch */
  amount_ht_relaunch?: Maybe<Scalars['Float']>;
  /** AmountHtNewsletter */
  amount_ht_newsletter?: Maybe<Scalars['Float']>;
  /** AmountHtRetargeting */
  amount_ht_retargeting?: Maybe<Scalars['Float']>;
  /** AmountHtSms */
  amount_ht_sms?: Maybe<Scalars['Float']>;
  /** Valid */
  valid?: Maybe<Scalars['Int']>;
  /** PaymentError */
  payment_error?: Maybe<Scalars['Int']>;
  /** Refund */
  refund?: Maybe<Scalars['Boolean']>;
  /** IdCart */
  id_cart?: Maybe<Scalars['Int']>;
  /** Products */
  products?: Maybe<Scalars['String']>;
  /** JsonOrder */
  json_order?: Maybe<Scalars['String']>;
  /** AdyenPspRef */
  adyen_psp_ref?: Maybe<Scalars['String']>;
  /** AdyenPaymentMethod */
  adyen_payment_method?: Maybe<Scalars['String']>;
  /** AdyenReason */
  adyen_reason?: Maybe<Scalars['String']>;
};

/** Main.ShopsBundles */
export type ShopsBundles = {
  __typename?: 'ShopsBundles';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Date */
  date: Scalars['DateTime'];
  /** NbDays */
  nb_days?: Maybe<Scalars['Int']>;
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** IdBundleNewsletter */
  id_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdNextBundleNewsletter */
  id_next_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** BundleOptions */
  bundle_options: Scalars['String'];
  /** Reconduction */
  reconduction: Scalars['Boolean'];
  /** MaxAmountOut */
  max_amount_out?: Maybe<Scalars['Float']>;
  /** MaxAmountOutRetargeting */
  max_amount_out_retargeting?: Maybe<Scalars['Float']>;
  /** AmountOutUnpaid */
  amount_out_unpaid: Scalars['Int'];
  /** IdUsersCreditCard */
  id_users_credit_card?: Maybe<Scalars['Int']>;
  /** MailSended */
  mail_sended: Scalars['Int'];
  /** FreeMails */
  free_mails: Scalars['Int'];
  /** MaxNewsletter */
  max_newsletter?: Maybe<Scalars['Int']>;
  /** MaxNewsletterBundle */
  max_newsletter_bundle?: Maybe<Scalars['Int']>;
  /** MaxNewsletterNextMonth */
  max_newsletter_next_month?: Maybe<Scalars['Int']>;
  /** NbSendsNewsletter */
  nb_sends_newsletter: Scalars['Int'];
  /** NbSendsSms */
  nb_sends_sms: Scalars['Int'];
  /** PaymentError */
  payment_error: Scalars['Int'];
  /** NextBundle */
  next_bundle: Scalars['Int'];
  /** Actif */
  actif: Scalars['Int'];
  shops?: Maybe<Array<Maybe<Shops>>>;
  bundles?: Maybe<Array<Maybe<Bundles>>>;
};

/** Main.ShopsBundles */
export type ShopsBundlesInput = {
  input: Array<ShopsBundlesInputItem>;
};

/** Main.ShopsBundles */
export type ShopsBundlesInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** NbDays */
  nb_days?: Maybe<Scalars['Int']>;
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** IdBundleNewsletter */
  id_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdNextBundleNewsletter */
  id_next_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** BundleOptions */
  bundle_options: Scalars['String'];
  /** Reconduction */
  reconduction: Scalars['Boolean'];
  /** MaxAmountOut */
  max_amount_out?: Maybe<Scalars['Float']>;
  /** MaxAmountOutRetargeting */
  max_amount_out_retargeting?: Maybe<Scalars['Float']>;
  /** AmountOutUnpaid */
  amount_out_unpaid: Scalars['Int'];
  /** IdUsersCreditCard */
  id_users_credit_card?: Maybe<Scalars['Int']>;
  /** MailSended */
  mail_sended: Scalars['Int'];
  /** FreeMails */
  free_mails: Scalars['Int'];
  /** MaxNewsletter */
  max_newsletter?: Maybe<Scalars['Int']>;
  /** MaxNewsletterBundle */
  max_newsletter_bundle?: Maybe<Scalars['Int']>;
  /** MaxNewsletterNextMonth */
  max_newsletter_next_month?: Maybe<Scalars['Int']>;
  /** NbSendsNewsletter */
  nb_sends_newsletter: Scalars['Int'];
  /** NbSendsSms */
  nb_sends_sms: Scalars['Int'];
  /** PaymentError */
  payment_error: Scalars['Int'];
  /** NextBundle */
  next_bundle: Scalars['Int'];
  /** Actif */
  actif: Scalars['Int'];
};

/** List of Main.ShopsBundles */
export type ShopsBundlesItems = {
  __typename?: 'ShopsBundlesItems';
  total: Scalars['Int'];
  items: Array<ShopsBundles>;
};

/** Main.ShopsBundles */
export type ShopsBundlesUpdateInput = {
  input: Array<ShopsBundlesUpdateInputItem>;
};

/** Main.ShopsBundles */
export type ShopsBundlesUpdateInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** NbDays */
  nb_days?: Maybe<Scalars['Int']>;
  /** IdBundle */
  id_bundle?: Maybe<Scalars['Int']>;
  /** IdBundleNewsletter */
  id_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdNextBundleNewsletter */
  id_next_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** BundleOptions */
  bundle_options?: Maybe<Scalars['String']>;
  /** Reconduction */
  reconduction?: Maybe<Scalars['Boolean']>;
  /** MaxAmountOut */
  max_amount_out?: Maybe<Scalars['Float']>;
  /** MaxAmountOutRetargeting */
  max_amount_out_retargeting?: Maybe<Scalars['Float']>;
  /** AmountOutUnpaid */
  amount_out_unpaid?: Maybe<Scalars['Int']>;
  /** IdUsersCreditCard */
  id_users_credit_card?: Maybe<Scalars['Int']>;
  /** MailSended */
  mail_sended?: Maybe<Scalars['Int']>;
  /** FreeMails */
  free_mails?: Maybe<Scalars['Int']>;
  /** MaxNewsletter */
  max_newsletter?: Maybe<Scalars['Int']>;
  /** MaxNewsletterBundle */
  max_newsletter_bundle?: Maybe<Scalars['Int']>;
  /** MaxNewsletterNextMonth */
  max_newsletter_next_month?: Maybe<Scalars['Int']>;
  /** NbSendsNewsletter */
  nb_sends_newsletter?: Maybe<Scalars['Int']>;
  /** NbSendsSms */
  nb_sends_sms?: Maybe<Scalars['Int']>;
  /** PaymentError */
  payment_error?: Maybe<Scalars['Int']>;
  /** NextBundle */
  next_bundle?: Maybe<Scalars['Int']>;
  /** Actif */
  actif?: Maybe<Scalars['Int']>;
};

/** Data.ShopsCarriers */
export type ShopsCarriers = {
  __typename?: 'ShopsCarriers';
  /** IdShopCarrier */
  id_shop_carrier: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdCarrier */
  id_carrier: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsCarriers */
export type ShopsCarriersInput = {
  input: Array<ShopsCarriersInputItem>;
};

/** Data.ShopsCarriers */
export type ShopsCarriersInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdCarrier */
  id_carrier: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Data.ShopsCarriers */
export type ShopsCarriersItems = {
  __typename?: 'ShopsCarriersItems';
  total: Scalars['Int'];
  items: Array<ShopsCarriers>;
};

/** Data.ShopsCarriers */
export type ShopsCarriersUpdateInput = {
  input: Array<ShopsCarriersUpdateInputItem>;
};

/** Data.ShopsCarriers */
export type ShopsCarriersUpdateInputItem = {
  /** IdShopCarrier */
  id_shop_carrier?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdCarrier */
  id_carrier?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsConfiguration */
export type ShopsConfiguration = {
  __typename?: 'ShopsConfiguration';
  /** IdShopConfiguration */
  id_shop_configuration: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Key */
  key: Scalars['String'];
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Value */
  value: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.ShopsConfigurationInput */
export type ShopsConfigurationInput = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Config array */
  configs?: Maybe<Array<Maybe<ShopsConfigurationInputItem>>>;
};

/** Main.ShopsConfigurationInputItems */
export type ShopsConfigurationInputItem = {
  /** Key */
  key: Scalars['String'];
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Value */
  value: Scalars['String'];
};

/** List of Main.ShopsConfiguration */
export type ShopsConfigurationItems = {
  __typename?: 'ShopsConfigurationItems';
  total: Scalars['Int'];
  items: Array<ShopsConfiguration>;
};

/** Main.ShopsCredits */
export type ShopsCredits = {
  __typename?: 'ShopsCredits';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Date */
  date: Scalars['DateTime'];
  /** Type */
  type: Scalars['String'];
  /** IdCredit */
  id_credit: Scalars['Int'];
  /** Amount */
  amount: Scalars['Float'];
  /** Renew */
  renew: Scalars['Int'];
  /** RenewData */
  renew_data?: Maybe<Scalars['String']>;
  /** Suspend */
  suspend?: Maybe<Scalars['DateTime']>;
  shops?: Maybe<Array<Maybe<Shops>>>;
  credits?: Maybe<Array<Maybe<Credits>>>;
};

/** Main.ShopsCredits */
export type ShopsCreditsInput = {
  input: Array<ShopsCreditsInputItem>;
};

/** Main.ShopsCredits */
export type ShopsCreditsInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** IdCredit */
  id_credit: Scalars['Int'];
  /** Amount */
  amount: Scalars['Float'];
  /** Renew */
  renew: Scalars['Int'];
  /** RenewData */
  renew_data?: Maybe<Scalars['String']>;
  /** Suspend */
  suspend?: Maybe<Scalars['DateTime']>;
};

/** List of Main.ShopsCredits */
export type ShopsCreditsItems = {
  __typename?: 'ShopsCreditsItems';
  total: Scalars['Int'];
  items: Array<ShopsCredits>;
};

/** Main.ShopsCredits */
export type ShopsCreditsUpdateInput = {
  input: Array<ShopsCreditsUpdateInputItem>;
};

/** Main.ShopsCredits */
export type ShopsCreditsUpdateInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** IdCredit */
  id_credit?: Maybe<Scalars['Int']>;
  /** Amount */
  amount?: Maybe<Scalars['Float']>;
  /** Renew */
  renew?: Maybe<Scalars['Int']>;
  /** RenewData */
  renew_data?: Maybe<Scalars['String']>;
  /** Suspend */
  suspend?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsCustomersAddresses */
export type ShopsCustomersAddresses = {
  __typename?: 'ShopsCustomersAddresses';
  /** IdShopCustomerAddress */
  id_shop_customer_address: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdAddressShop */
  id_address_shop?: Maybe<Scalars['Int']>;
  /** Firstname */
  firstname?: Maybe<Scalars['DateTime']>;
  /** LastName */
  lastname?: Maybe<Scalars['String']>;
  /** Phone1 */
  phone1?: Maybe<Scalars['String']>;
  /** Phone2 */
  phone2?: Maybe<Scalars['String']>;
  /** Company */
  company?: Maybe<Scalars['String']>;
  /** Address1 */
  address1?: Maybe<Scalars['String']>;
  /** Address2 */
  address2?: Maybe<Scalars['String']>;
  /** Postcode */
  postcode?: Maybe<Scalars['String']>;
  /** Region */
  region?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Other */
  other?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
  /** ShopsCustomers */
  shopsCustomers?: Maybe<ShopsCustomers>;

};

/** Main.ShopsCustomersAddresses */
export type ShopsCustomersAddressesInput = {
  input: Array<ShopsCustomersAddressesInputItem>;
};

/** Main.ShopsCustomersAddresses */
export type ShopsCustomersAddressesInputItem = {
  __typename?: 'ShopsCustomersAddressesInputItem';
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdAddressShop */
  id_address_shop?: Maybe<Scalars['Int']>;
  /** Firstname */
  firstname?: Maybe<Scalars['DateTime']>;
  /** LastName */
  lastname?: Maybe<Scalars['String']>;
  /** Phone1 */
  phone1?: Maybe<Scalars['String']>;
  /** Phone2 */
  phone2?: Maybe<Scalars['String']>;
  /** Company */
  company?: Maybe<Scalars['String']>;
  /** Address1 */
  address1?: Maybe<Scalars['String']>;
  /** Address2 */
  address2?: Maybe<Scalars['String']>;
  /** Postcode */
  postcode?: Maybe<Scalars['String']>;
  /** Region */
  region?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Other */
  other?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Main.ShopsCustomersAddresses */
export type ShopsCustomersAddressesItems = {
  __typename?: 'ShopsCustomersAddressesItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersAddresses>;
};

/** Main.ShopsCustomersAddresses */
export type ShopsCustomersAddressesUpdateInput = {
  input: Array<ShopsCustomersAddressesUpdateInputItem>;
};

/** Main.ShopsCustomersAddresses */
export type ShopsCustomersAddressesUpdateInputItem = {
  __typename?: 'ShopsCustomersAddressesUpdateInputItem';
  /** IdShopCustomerAddress */
  id_shop_customer_address?: Maybe<Scalars['Int']>;
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdAddressShop */
  id_address_shop?: Maybe<Scalars['Int']>;
  /** Firstname */
  firstname?: Maybe<Scalars['DateTime']>;
  /** LastName */
  lastname?: Maybe<Scalars['String']>;
  /** Phone1 */
  phone1?: Maybe<Scalars['String']>;
  /** Phone2 */
  phone2?: Maybe<Scalars['String']>;
  /** Company */
  company?: Maybe<Scalars['String']>;
  /** Address1 */
  address1?: Maybe<Scalars['String']>;
  /** Address2 */
  address2?: Maybe<Scalars['String']>;
  /** Postcode */
  postcode?: Maybe<Scalars['String']>;
  /** Region */
  region?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Other */
  other?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsCustomers */
export type ShopsCustomers = {
  __typename?: 'ShopsCustomers';
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Scalars['String'];
  /** Gender */
  gender: Scalars['String'];
  /** Birthday */
  birthday?: Maybe<Scalars['DateTime']>;
  /** OptIn */
  opt_in: Scalars['String'];
  /** Newsletter */
  newsletter: Scalars['String'];
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active: Scalars['String'];
  /** Type */
  type: Scalars['Int'];
  /** LastCheckBounce */
  last_check_bounce: Scalars['DateTime'];
  /** MasterRejectType */
  master_reject_type: Scalars['String'];
  /** LastDateReject */
  last_date_reject: Scalars['DateTime'];
  /** LastView */
  last_view: Scalars['DateTime'];
  /** LastClick */
  last_click: Scalars['DateTime'];
  /** LastOrder */
  last_order: Scalars['DateTime'];
  /** Custom1 */
  custom1?: Maybe<Scalars['String']>;
  /** Custom2 */
  custom2?: Maybe<Scalars['String']>;
  /** Custom3 */
  custom3?: Maybe<Scalars['String']>;
  /** Custom4 */
  custom4?: Maybe<Scalars['String']>;
  /** RatingInitial */
  rating_initial?: Maybe<Scalars['Int']>;
  /** RatingDynamic */
  rating_dynamic?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
  /** UnsubType */
  unsub_type: Scalars['Int'];
  /** PerfectTiming */
  perfect_timing?: Maybe<Scalars['String']>;
  emailsRejectsShops?: Maybe<Array<Maybe<EmailsRejectsShops>>>;
};

/** Data.ShopsCustomersActions */
export type ShopsCustomersActionsInput = {
  input: Array<ShopsCustomersActions>;
};

export type ShopsCustomersActions = {
  action_type: Scalars['String'];
  action_selection: Scalars['String'];
  id_shop: Scalars['Int'];
  params: ListSettings;
  id_list_to: Scalars['Int'];
  id_list_from: Scalars['Int'];
  name: Scalars['String'];
  locale: Scalars['String'];
  customer_ids: Array<Scalars['Int']>;

}

/** My Lists -> Bounces | Spams */
export type ShopsCustomersEmailsRejectsShops = {
  __typename?: 'ShopsCustomersEmailsRejectsShops';
  /** ShopsCustomers->IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** ShopsCustomers->FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** ShopsCustomers->LastName */
  last_name?: Maybe<Scalars['String']>;
  /** ShopsCustomers->Email */
  email: Scalars['String'];
  /** ShopsCustomers->Countryre */
  country?: Maybe<Scalars['String']>;
  /** ShopsCustomers->Lang */
  lang?: Maybe<Scalars['String']>;
  /** EmailsRejectsShops->IdEmailReject */
  id_email_reject: Scalars['Int'];
  /** EmailsRejectsShops->RejectType */
  reject_type: Scalars['String'];
  /** EmailsRejectsShops->RejectMessage */
  reject_message?: Maybe<Scalars['String']>;
  /** EmailsRejectsShops->Date */
  date?: Maybe<Scalars['String']>;
};

/** List of Data.ShopsCustomers and Data.EmailsRejectsShops (joined) */
export type ShopsCustomersEmailsRejectsShopsItems = {
  __typename?: 'ShopsCustomersEmailsRejectsShopsItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersEmailsRejectsShops>;
};

/** Data.ShopsCustomersGroups */
export type ShopsCustomersGroups = {
  __typename?: 'ShopsCustomersGroups';
  /** IdShopCustomerGroup */
  id_shop_customer_group: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Group */
  group: Scalars['String'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsCustomersGroups */
export type ShopsCustomersGroupsInput = {
  input: Array<ShopsCustomersGroupsInputItem>;
};

/** Data.ShopsCustomersGroups */
export type ShopsCustomersGroupsInputItem = {
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Group */
  group: Scalars['String'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** List of Data.ShopsCustomersGroups */
export type ShopsCustomersGroupsItems = {
  __typename?: 'ShopsCustomersGroupsItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersGroups>;
};

/** Data.ShopsCustomersGroupsList */
export type ShopsCustomersGroupsList = {
  __typename?: 'ShopsCustomersGroupsList';
  /** IdShopCustomerGroupList */
  id_shop_customer_group_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdGroup */
  id_group: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Data.ShopsCustomersGroupsList */
export type ShopsCustomersGroupsListInput = {
  input: Array<ShopsCustomersGroupsListInputItem>;
};

/** Data.ShopsCustomersGroupsList */
export type ShopsCustomersGroupsListInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdGroup */
  id_group: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** List of Data.ShopsCustomersGroupsList */
export type ShopsCustomersGroupsListItems = {
  __typename?: 'ShopsCustomersGroupsListItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersGroupsList>;
};

/** Data.ShopsCustomersGroupsList */
export type ShopsCustomersGroupsListUpdateInput = {
  input: Array<ShopsCustomersGroupsListUpdateInputItem>;
};

/** Data.ShopsCustomersGroupsList */
export type ShopsCustomersGroupsListUpdateInputItem = {
  /** IdShopCustomerGroupList */
  id_shop_customer_group_list?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdGroup */
  id_group?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsCustomersGroups */
export type ShopsCustomersGroupsUpdateInput = {
  input: Array<ShopsCustomersGroupsUpdateInputItem>;
};

/** Data.ShopsCustomersGroups */
export type ShopsCustomersGroupsUpdateInputItem = {
  /** IdShopCustomerGroup */
  id_shop_customer_group?: Maybe<Scalars['Int']>;
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Group */
  group?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsCustomers */
export type ShopsCustomersInput = {
  input: Array<ShopsCustomersInputItem>;
};

/** Data.ShopsCustomers */
export type ShopsCustomersInputItem = {
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Scalars['String'];
  /** Gender */
  gender: Scalars['String'];
  /** Birthday */
  birthday?: Maybe<Scalars['DateTime']>;
  /** OptIn */
  opt_in: Scalars['String'];
  /** Newsletter */
  newsletter: Scalars['String'];
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active: Scalars['String'];
  /** Type */
  type: Scalars['Int'];
  /** LastCheckBounce */
  last_check_bounce: Scalars['DateTime'];
  /** MasterRejectType */
  master_reject_type: Scalars['String'];
  /** LastDateReject */
  last_date_reject: Scalars['DateTime'];
  /** LastView */
  last_view: Scalars['DateTime'];
  /** LastClick */
  last_click: Scalars['DateTime'];
  /** LastOrder */
  last_order: Scalars['DateTime'];
  /** Custom1 */
  custom1?: Maybe<Scalars['String']>;
  /** Custom2 */
  custom2?: Maybe<Scalars['String']>;
  /** Custom3 */
  custom3?: Maybe<Scalars['String']>;
  /** Custom4 */
  custom4?: Maybe<Scalars['String']>;
  /** RatingInitial */
  rating_initial?: Maybe<Scalars['Int']>;
  /** RatingDynamic */
  rating_dynamic?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** UnsubType */
  unsub_type: Scalars['Int'];
  /** PerfectTiming */
  perfect_timing?: Maybe<Scalars['String']>;
};

/** List of Data.ShopsCustomers */
export type ShopsCustomersItems = {
  __typename?: 'ShopsCustomersItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomers>;
};

/** Data.ShopsCustomers */
export type ShopsCustomersManage = {
  __typename?: 'ShopsCustomersManage';
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Gender */
  gender?: Maybe<Scalars['String']>;
  /** Birthday */
  birthday?: Maybe<Scalars['DateTime']>;
  /** OptIn */
  opt_in?: Maybe<Scalars['String']>;
  /** Newsletter */
  newsletter?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active: Scalars['String'];
  /** Type */
  type: Scalars['Int'];
  /** LastCheckBounce */
  last_check_bounce?: Maybe<Scalars['DateTime']>;
  /** MasterRejectType */
  master_reject_type?: Maybe<Scalars['String']>;
  /** LastDateReject */
  last_date_reject?: Maybe<Scalars['DateTime']>;
  /** LastView */
  last_view?: Maybe<Scalars['DateTime']>;
  /** LastClick */
  last_click?: Maybe<Scalars['DateTime']>;
  /** LastOrder */
  last_order?: Maybe<Scalars['DateTime']>;
  /** Custom1 */
  custom1?: Maybe<Scalars['String']>;
  /** Custom2 */
  custom2?: Maybe<Scalars['String']>;
  /** Custom3 */
  custom3?: Maybe<Scalars['String']>;
  /** Custom4 */
  custom4?: Maybe<Scalars['String']>;
  /** RatingInitial */
  rating_initial?: Maybe<Scalars['Int']>;
  /** RatingDynamic */
  rating_dynamic?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** UnsubType */
  unsub_type?: Maybe<Scalars['Int']>;
  /** PerfectTiming */
  perfect_timing?: Maybe<Scalars['String']>;
  /** emails_rejects_shops.reject_type */
  reject_type?: Maybe<Scalars['String']>;
  /** emails_rejects_shops.reject_message */
  reject_message?: Maybe<Scalars['String']>;
  /** if there are shop_lists_imports records */
  listAutoUpdate?: Maybe<Scalars['Boolean']>;
  /** count of queued lists */
  countRequestListImport?: Maybe<Scalars['Int']>;
  /** id of shops lists, comma separated */
  id_shop_lists?: Maybe<Scalars['String']>;
  bounce_from?: Maybe<Scalars['String']>;
  spam_from?: Maybe<Scalars['String']>;
  reject_from?: Maybe<Scalars['String']>;
};

/** Data from data.shops_customers and data.email_rejects_shops */
export type ShopsCustomersManageItems = {
  __typename?: 'ShopsCustomersManageItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersManage>;
};

/** Data.ShopsCustomers */
export type ShopsCustomersShopsLists = {
  __typename?: 'ShopsCustomersShopsLists';
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Gender */
  gender?: Maybe<Scalars['String']>;
  /** Birthday */
  birthday?: Maybe<Scalars['DateTime']>;
  /** OptIn */
  opt_in?: Maybe<Scalars['String']>;
  /** Newsletter */
  newsletter?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active: Scalars['String'];
  /** Type */
  type: Scalars['Int'];
  /** LastCheckBounce */
  last_check_bounce?: Maybe<Scalars['DateTime']>;
  /** MasterRejectType */
  master_reject_type?: Maybe<Scalars['String']>;
  /** LastDateReject */
  last_date_reject?: Maybe<Scalars['DateTime']>;
  /** LastView */
  last_view?: Maybe<Scalars['DateTime']>;
  /** LastClick */
  last_click?: Maybe<Scalars['DateTime']>;
  /** LastOrder */
  last_order?: Maybe<Scalars['DateTime']>;
  /** Custom1 */
  custom1?: Maybe<Scalars['String']>;
  /** Custom2 */
  custom2?: Maybe<Scalars['String']>;
  /** Custom3 */
  custom3?: Maybe<Scalars['String']>;
  /** Custom4 */
  custom4?: Maybe<Scalars['String']>;
  /** RatingInitial */
  rating_initial?: Maybe<Scalars['Int']>;
  /** RatingDynamic */
  rating_dynamic?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** UnsubType */
  unsub_type?: Maybe<Scalars['Int']>;
  /** PerfectTiming */
  perfect_timing?: Maybe<Scalars['String']>;
  /** emails_rejects_shops.reject_type */
  reject_type?: Maybe<Scalars['String']>;
  /** emails_rejects_shops.reject_message */
  reject_message?: Maybe<Scalars['String']>;
  /** all list ids related to this customer, comma separated field */
  id_shop_lists?: Maybe<Scalars['String']>;
  /** all list names related to this customer, comma separated field */
  shop_lists_name?: Maybe<Scalars['String']>;
};

/** List of Data.ShopsCustomers and with list of names of lists from Main.ShopsLists in comma separated fashion */
export type ShopsCustomersShopsListsItems = {
  __typename?: 'ShopsCustomersShopsListsItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersShopsLists>;
};

/** Data.ShopsCustomers */
export type ShopsCustomersUpdateInput = {
  input: Array<ShopsCustomersUpdateInputItem>;
};

/** Data.ShopsCustomers */
export type ShopsCustomersUpdateInputItem = {
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Gender */
  gender?: Maybe<Scalars['String']>;
  /** Birthday */
  birthday?: Maybe<Scalars['DateTime']>;
  /** OptIn */
  opt_in?: Maybe<Scalars['String']>;
  /** Newsletter */
  newsletter?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['Int']>;
  /** LastCheckBounce */
  last_check_bounce?: Maybe<Scalars['DateTime']>;
  /** MasterRejectType */
  master_reject_type?: Maybe<Scalars['String']>;
  /** LastDateReject */
  last_date_reject?: Maybe<Scalars['DateTime']>;
  /** LastView */
  last_view?: Maybe<Scalars['DateTime']>;
  /** LastClick */
  last_click?: Maybe<Scalars['DateTime']>;
  /** LastOrder */
  last_order?: Maybe<Scalars['DateTime']>;
  /** Custom1 */
  custom1?: Maybe<Scalars['String']>;
  /** Custom2 */
  custom2?: Maybe<Scalars['String']>;
  /** Custom3 */
  custom3?: Maybe<Scalars['String']>;
  /** Custom4 */
  custom4?: Maybe<Scalars['String']>;
  /** RatingInitial */
  rating_initial?: Maybe<Scalars['Int']>;
  /** RatingDynamic */
  rating_dynamic?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** UnsubType */
  unsub_type?: Maybe<Scalars['Int']>;
  /** PerfectTiming */
  perfect_timing?: Maybe<Scalars['String']>;
};

/** Data.ShopsExports */
export type ShopsExports = {
  __typename?: 'ShopsExports';
  /** IdShopCarrier */
  id_user_export: Scalars['Int'];
  /** IdShop */
  id_user: Scalars['Int'];
  /** IdCarrier */
  path: Scalars['String'];
  /** Name */
  date_add: Scalars['DateTime'];
  /** Active */
  is_treated: Scalars['Boolean'];
};

/** Data.ShopsCustomersTags */
export type ShopsCustomersTags = {
  __typename?: 'ShopsCustomersTags';
  /** IdShopCustomerTag */
  id_shop_customer_tag: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdShopVisitor */
  id_shop_visitor?: Maybe<Scalars['Int']>;
  /** IdShopTag */
  id_shop_tag?: Maybe<Scalars['Int']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  shopsTags?: Maybe<Array<Maybe<ShopsTags>>>;
};

/** List of Data.ShopsCustomersTags */
export type ShopsCustomersTagsItems = {
  __typename?: 'ShopsCustomersTagsItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersTags>;
};

/** List of Data.ShopsExports */
export type ShopsExportsItems = {
  __typename?: 'ShopsExportsItems';
  total: Scalars['Int'];
  items: Array<ShopsExports>;
};

/** Main.ShopsHasBundle */
export type ShopsHasBundle = {
  __typename?: 'ShopsHasBundle';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** UseWorkflows */
  use_workflows: Scalars['Boolean'];
  /** CurrentVersion */
  current_version?: Maybe<Scalars['String']>;
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** IdBundleNewsletter */
  id_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdNextBundleNewsletter */
  id_next_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** MaxNewsletter */
  max_newsletter?: Maybe<Scalars['Int']>;
  /** MaxNewsletterNextMonth */
  max_newsletter_next_month?: Maybe<Scalars['Int']>;
  /** MaxNewsletterBundle */
  max_newsletter_bundle?: Maybe<Scalars['Int']>;
  /** NbSendsNewsletter */
  nb_sends_newsletter: Scalars['Int'];
  /** NbSendsSms */
  nb_sends_sms: Scalars['Int'];
  /** BundleOptions */
  bundle_options: Scalars['String'];
  /** DateStart */
  date_start: Scalars['DateTime'];
  /** DateFin */
  date_fin?: Maybe<Scalars['DateTime']>;
  /** Reconduction */
  reconduction: Scalars['Boolean'];
  /** Actif */
  actif: Scalars['Int'];
  /** MaxAmountOut */
  max_amount_out?: Maybe<Scalars['Float']>;
  /** MaxAmountOutRetargeting */
  max_amount_out_retargeting?: Maybe<Scalars['Float']>;
  /** AmountOutUnpaid */
  amount_out_unpaid: Scalars['Int'];
  /** PaymentError */
  payment_error: Scalars['Int'];
  /** NextBundle */
  next_bundle: Scalars['Int'];
  /** NbSends */
  nb_sends: Scalars['Int'];
  /** NbSendsRetargeting */
  nb_sends_retargeting: Scalars['Int'];
  /** NbSendsRetargetingDay */
  nb_sends_retargeting_day?: Maybe<Scalars['Int']>;
  /** AmountOut */
  amount_out: Scalars['Int'];
  /** AmountOutRetargeting */
  amount_out_retargeting: Scalars['Int'];
  /** AmountOutRetargetingDay */
  amount_out_retargeting_day?: Maybe<Scalars['Float']>;
  /** FreeMails */
  free_mails: Scalars['Int'];
  shops?: Maybe<Array<Maybe<Shops>>>;
  bundles?: Maybe<Array<Maybe<Bundles>>>;
};

/** Main.ShopsHasBundleActive */
export type ShopsHasBundleActive = {
  __typename?: 'ShopsHasBundleActive';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** UseWorkflows */
  use_workflows: Scalars['Boolean'];
  /** CurrentVersion */
  current_version?: Maybe<Scalars['String']>;
  /** IdBundle */
  id_bundle: Scalars['Int'];
  /** IdBundleNewsletter */
  id_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** IdNextBundleNewsletter */
  id_next_bundle_newsletter?: Maybe<Scalars['Int']>;
  /** BundleOptions */
  bundle_options: Scalars['String'];
  /** MaxNewsletter */
  max_newsletter?: Maybe<Scalars['Int']>;
  /** MaxNewsletterNextMonth */
  max_newsletter_next_month?: Maybe<Scalars['Int']>;
  /** MaxNewsletterBundle */
  max_newsletter_bundle?: Maybe<Scalars['Int']>;
  /** NbSendsNewsletter */
  nb_sends_newsletter: Scalars['Int'];
  /** NbSendsSms */
  nb_sends_sms: Scalars['Int'];
  /** DateStart */
  date_start: Scalars['DateTime'];
  /** DateFin */
  date_fin?: Maybe<Scalars['DateTime']>;
  /** Reconduction */
  reconduction: Scalars['Boolean'];
  /** MaxAmountOut */
  max_amount_out?: Maybe<Scalars['Float']>;
  /** MaxAmountOutRetargeting */
  max_amount_out_retargeting?: Maybe<Scalars['Float']>;
  /** NbSends */
  nb_sends: Scalars['Int'];
  /** NbSendsRetargeting */
  nb_sends_retargeting: Scalars['Int'];
  /** AmountOut */
  amount_out: Scalars['Int'];
  /** AmountOutRetargeting */
  amount_out_retargeting: Scalars['Int'];
  /** AmountOutRetargetingDay */
  amount_out_retargeting_day?: Maybe<Scalars['Float']>;
  /** AmountOutUnpaid */
  amount_out_unpaid: Scalars['Int'];
  /** PaymentError */
  payment_error: Scalars['Int'];
  /** NextBundle */
  next_bundle: Scalars['Int'];
  /** FreeMails */
  free_mails: Scalars['Int'];
};

/** List of Main.ShopsHasBundleActive */
export type ShopsHasBundleActiveItems = {
  __typename?: 'ShopsHasBundleActiveItems';
  total: Scalars['Int'];
  items: Array<ShopsHasBundleActive>;
};

/** List of Main.ShopsHasBundle */
export type ShopsHasBundleItems = {
  __typename?: 'ShopsHasBundleItems';
  total: Scalars['Int'];
  items: Array<ShopsHasBundle>;
};

/** Main.Shops */
export type ShopsInput = {
  input: Array<ShopsInputItem>;
};

/** Main.Shops */
export type ShopsInputItem = {
  /** ShopIdShop */
  shop_id_shop?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Url */
  url: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active: Scalars['Boolean'];
  /** ApiKey */
  api_key?: Maybe<Scalars['String']>;
  /** Identifiant */
  identifiant?: Maybe<Scalars['String']>;
  /** ApiUserSmtp */
  api_user_smtp?: Maybe<Scalars['String']>;
  /** ApiPassSmtp */
  api_pass_smtp?: Maybe<Scalars['String']>;
  /** SolutionEcommerce */
  solution_ecommerce: Scalars['String'];
  /** SolutionEcommerceVersion */
  solution_ecommerce_version?: Maybe<Scalars['String']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** IdUser */
  id_user: Scalars['Int'];
  /** IdUserSalesService */
  id_user_sales_service: Scalars['Int'];
  /** IdUserCustomerService */
  id_user_customer_service: Scalars['Int'];
  /** UrlClient */
  url_client?: Maybe<Scalars['String']>;
  /** CurrentVersion */
  current_version?: Maybe<Scalars['String']>;
  /** ModuleVersion */
  module_version?: Maybe<Scalars['String']>;
  /** CurrentBuild */
  current_build?: Maybe<Scalars['Int']>;
  /** TimezoneCode */
  timezone_code?: Maybe<Scalars['String']>;
  /** TimezoneName */
  timezone_name?: Maybe<Scalars['String']>;
  /** TimezoneTime */
  timezone_time?: Maybe<Scalars['Int']>;
  /** LastUpdateTimezone */
  last_update_timezone?: Maybe<Scalars['DateTime']>;
  /** TimeDiffClient */
  time_diff_client?: Maybe<Scalars['Int']>;
  /** LastUpdateCustomersEmails */
  last_update_customers_emails?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCustomers */
  last_update_customers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateProducts */
  last_update_products?: Maybe<Scalars['DateTime']>;
  /** LastUpdateProductsCategories */
  last_update_products_categories?: Maybe<Scalars['DateTime']>;
  /** LastUpdateOrders */
  last_update_orders?: Maybe<Scalars['DateTime']>;
  /** LastUpdateNewsletters */
  last_update_newsletters?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCustomersGroups */
  last_update_customers_groups?: Maybe<Scalars['DateTime']>;
  /** LastUpdateManufacturers */
  last_update_manufacturers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCarriers */
  last_update_carriers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateVouchers */
  last_update_vouchers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateOrdersStatus */
  last_update_orders_status?: Maybe<Scalars['DateTime']>;
  /** CustomerNeedSync */
  customer_need_sync: Scalars['Boolean'];
  /** CategoryNeedSync */
  category_need_sync: Scalars['Boolean'];
  /** ProductNeedSync */
  product_need_sync: Scalars['Boolean'];
  /** OrderNeedSync */
  order_need_sync: Scalars['Boolean'];
  /** NewsletterNeedSync */
  newsletter_need_sync: Scalars['Boolean'];
  /** GroupNeedSync */
  group_need_sync: Scalars['Boolean'];
  /** ManufacturerNeedSync */
  manufacturer_need_sync: Scalars['Boolean'];
  /** CarrierNeedSync */
  carrier_need_sync: Scalars['Boolean'];
  /** VoucherNeedSync */
  voucher_need_sync: Scalars['Boolean'];
  /** StatusNeedSync */
  status_need_sync: Scalars['Boolean'];
  /** IdTemplatesBaseDefault */
  id_templates_base_default?: Maybe<Scalars['Int']>;
  /** TemplatesBaseBodyDefault */
  templates_base_body_default?: Maybe<Scalars['String']>;
  /** IdCategoryShop */
  id_category_shop?: Maybe<Scalars['Int']>;
  /** RelaunchEmailsExclusion */
  relaunch_emails_exclusion?: Maybe<Scalars['String']>;
  /** MultishopEnabled */
  multishop_enabled?: Maybe<Scalars['Boolean']>;
  /** BundleVersion */
  bundle_version: Scalars['Boolean'];
  /** BundleNewsletterVersion */
  bundle_newsletter_version: Scalars['Int'];
  /** OptincollectId */
  optincollect_id?: Maybe<Scalars['String']>;
  /** TestMode */
  test_mode?: Maybe<Scalars['Boolean']>;
  /** IsMigrate */
  is_migrate: Scalars['Boolean'];
  /** HasFivePercentRule */
  has_five_percent_rule: Scalars['Boolean'];
  /** Pool */
  pool?: Maybe<Scalars['String']>;
  /** DedicatedPools */
  dedicated_pools?: Maybe<Scalars['String']>;
  /** Rating */
  rating?: Maybe<Scalars['Float']>;
  /** RatingRetargeting */
  rating_retargeting?: Maybe<Scalars['Float']>;
  /** Services */
  services: Scalars['Boolean'];
  /** ExcludeFromDashboard */
  exclude_from_dashboard: Scalars['Boolean'];
  /** UseWorkflows */
  use_workflows: Scalars['Boolean'];
  /** WorkflowInvitationSended */
  workflow_invitation_sended: Scalars['Boolean'];
  /** RestrictedNewsletter */
  restricted_newsletter: Scalars['Boolean'];
  /** DisplayDisclaimer */
  display_disclaimer: Scalars['Boolean'];
  /** RestrictedDeliverability */
  restricted_deliverability?: Maybe<Scalars['DateTime']>;
};

/** List of Main.Shops */
export type ShopsItems = {
  __typename?: 'ShopsItems';
  total: Scalars['Int'];
  items: Array<Shops>;
};

/** Main.ShopsLang */
export type ShopsLang = {
  __typename?: 'ShopsLang';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Lang */
  lang: Scalars['String'];
  /** Url */
  url?: Maybe<Scalars['String']>;
  /** UrlCart */
  url_cart?: Maybe<Scalars['String']>;
  /** ContactPage */
  contact_page?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Fb */
  fb?: Maybe<Scalars['String']>;
  /** Tw */
  tw?: Maybe<Scalars['String']>;
  /** Gp */
  gp?: Maybe<Scalars['String']>;
  /** Tel */
  tel?: Maybe<Scalars['String']>;
  /** Default */
  default: Scalars['Boolean'];
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.ShopsLang */
export type ShopsLangInput = {
  input: Array<ShopsLangInputItem>;
};

/** Main.ShopsLang */
export type ShopsLangInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
  /** UrlCart */
  url_cart?: Maybe<Scalars['String']>;
  /** ContactPage */
  contact_page?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Fb */
  fb?: Maybe<Scalars['String']>;
  /** Tw */
  tw?: Maybe<Scalars['String']>;
  /** Gp */
  gp?: Maybe<Scalars['String']>;
  /** Tel */
  tel?: Maybe<Scalars['String']>;
  /** Default */
  default: Scalars['Boolean'];
};

/** List of Main.ShopsLang */
export type ShopsLangItems = {
  __typename?: 'ShopsLangItems';
  total: Scalars['Int'];
  items: Array<ShopsLang>;
};

/** Main.ShopsLang */
export type ShopsLangUpdateInput = {
  input: Array<ShopsLangUpdateInputItem>;
};

/** Main.ShopsLang */
export type ShopsLangUpdateInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
  /** UrlCart */
  url_cart?: Maybe<Scalars['String']>;
  /** ContactPage */
  contact_page?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Fb */
  fb?: Maybe<Scalars['String']>;
  /** Tw */
  tw?: Maybe<Scalars['String']>;
  /** Gp */
  gp?: Maybe<Scalars['String']>;
  /** Tel */
  tel?: Maybe<Scalars['String']>;
  /** Default */
  default?: Maybe<Scalars['Boolean']>;
};

/** Main.ShopsLists */
export type ShopsLists = {
  __typename?: 'ShopsLists';
  /** IdShopList */
  id_shop_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
  /** type */
  type?: Scalars['String'];
  /** TotalContacts */
  total_contacts: Scalars['Int'];
  /** TotalSubscribers */
  total_subscribers: Scalars['Int'];
  /** TotalUnsubscribeds */
  total_unsubscribeds: Scalars['Int'];
  /** TotalSends */
  total_sends: Scalars['Int'];
  /** TotalSendsSoftBounce */
  total_sends_soft_bounce: Scalars['Int'];
  /** TotalSendsHardBounce */
  total_sends_hard_bounce: Scalars['Int'];
  /** TotalSendsSpam */
  total_sends_spam: Scalars['Int'];
  /** TotalNewslettersViewed */
  total_newsletters_viewed: Scalars['Int'];
  /** TotalNewslettersClicked */
  total_newsletters_clicked: Scalars['Int'];
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active: Scalars['Int'];
  /** Rating */
  rating?: Maybe<Scalars['Float']>;
  /** Valid */
  valid: Scalars['Boolean'];
  /** Whitelist */
  whitelist: Scalars['Boolean'];
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Data.ShopsListsActions */
export type ShopsListsActions = {
  __typename?: 'ShopsListsActions';
  /** IdShopListAction */
  id_shop_list_action: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type: Scalars['String'];
  /** IdListFrom */
  id_list_from?: Maybe<Scalars['String']>;
  /** IdListTo */
  id_list_to?: Maybe<Scalars['String']>;
  /** NameNewList */
  name_new_list?: Maybe<Scalars['String']>;
  /** GroupedActionsType */
  grouped_actions_type?: Maybe<Scalars['String']>;
  /** GroupedActionsSelection */
  grouped_actions_selection?: Maybe<Scalars['String']>;
  /** SearchFilters */
  search_filters?: Maybe<Scalars['String']>;
  /** ConcernedCustomers */
  concerned_customers?: Maybe<Scalars['String']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** IsTreated */
  is_treated: Scalars['Boolean'];
  /** DateAdd */
  date_add: Scalars['DateTime'];
};

/** Data.ShopsListsActions */
export type ShopsListsActionsInput = {
  input: Array<ShopsListsActionsInputItem>;
};

/** Data.ShopsListsActionsInput */
export type ShopsListsActionsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type: Scalars['String'];
  /** IdListFrom */
  id_list_from?: Maybe<Scalars['String']>;
  /** IdListTo */
  id_list_to?: Maybe<Scalars['String']>;
  /** NameNewList */
  name_new_list?: Maybe<Scalars['String']>;
  /** GroupedActionsType */
  grouped_actions_type?: Maybe<Scalars['String']>;
  /** GroupedActionsSelection */
  grouped_actions_selection?: Maybe<Scalars['String']>;
  /** SearchFilters */
  search_filters?: Maybe<Scalars['String']>;
  /** ConcernedCustomers */
  concerned_customers?: Maybe<Scalars['String']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** IsTreated */
  is_treated: Scalars['Int'];
  /** DateAdd */
  date_add: Scalars['DateTime'];
};

/** List of Data.ShopsListsActions */
export type ShopsListsActionsItems = {
  __typename?: 'ShopsListsActionsItems';
  total: Scalars['Int'];
  items: Array<ShopsListsActions>;
};

/** Data.ShopsTags */
export type ShopsListsDeleteInput = {
  input: Array<ShopsListsUpdateInputItem>;
};

/** Data.ShopsListsActionUpdateInput */
export type ShopsListsActionsUpdateInput = {
  input: Array<ShopsListsActionsUpdateInputItem>;
};

/** Data.ShopsListsActionsUpdateInput */
export type ShopsListsActionsUpdateInputItem = {
  /** IdShopListAction */
  id_shop_list_action?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** IdListFrom */
  id_list_from?: Maybe<Scalars['String']>;
  /** IdListTo */
  id_list_to?: Maybe<Scalars['String']>;
  /** NameNewList */
  name_new_list?: Maybe<Scalars['String']>;
  /** GroupedActionsType */
  grouped_actions_type?: Maybe<Scalars['String']>;
  /** GroupedActionsSelection */
  grouped_actions_selection?: Maybe<Scalars['String']>;
  /** SearchFilters */
  search_filters?: Maybe<Scalars['String']>;
  /** ConcernedCustomers */
  concerned_customers?: Maybe<Scalars['String']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** IsTreated */
  is_treated?: Maybe<Scalars['Boolean']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsListsImport */
export type ShopsListsImport = {
  __typename?: 'ShopsListsImport';
  /** IdShopListImport */
  id_shop_list_import: Scalars['Int'];
  /** IdShopList */
  id_shop_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Params */
  params: Scalars['String'];
  /** File */
  file: Scalars['String'];
  /** SegmentsConfig */
  segments_config: Scalars['String'];
  /** StayUpdated */
  stay_updated: Scalars['Boolean'];
  /** ForceUpdate */
  force_update: Scalars['Int'];
  /** Newsletter */
  newsletter?: Maybe<Scalars['Int']>;
  /** DoubleOptin */
  double_optin: Scalars['Boolean'];
  /** NbCustomers */
  nb_customers: Scalars['Int'];
  /** NbImportedCustomers */
  nb_imported_customers: Scalars['Int'];
  /** NbNonImportedCustomers */
  nb_non_imported_customers: Scalars['Int'];
  /** NbPartiallyImportedCustomers */
  nb_partially_imported_customers: Scalars['Int'];
  /** PathReportFolder */
  path_report_folder: Scalars['String'];
  /** NbCustomersChecked */
  nb_customers_checked: Scalars['Int'];
  /** NbCustomersValid */
  nb_customers_valid: Scalars['Int'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** State */
  state: Scalars['String'];
  shops?: Maybe<Shops>;
  shopsLists?: Maybe<ShopsLists>;
};

/** Main.ShopsListsImport */
export type ShopsListsImportInput = {
  input: Array<ShopsListsImportInputItem>;
};

/** Main.ShopsListsImport */
export type ShopsListsImportInputItem = {
  /** IdShopList */
  id_shop_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Params */
  params: Scalars['String'];
  /** File */
  file: Scalars['String'];
  /** SegmentsConfig */
  segments_config: Scalars['String'];
  /** StayUpdated */
  stay_updated: Scalars['Boolean'];
  /** ForceUpdate */
  force_update: Scalars['Int'];
  /** Newsletter */
  newsletter?: Maybe<Scalars['Int']>;
  /** DoubleOptin */
  double_optin: Scalars['Boolean'];
  /** NbCustomers */
  nb_customers: Scalars['Int'];
  /** NbImportedCustomers */
  nb_imported_customers: Scalars['Int'];
  /** NbNonImportedCustomers */
  nb_non_imported_customers: Scalars['Int'];
  /** NbPartiallyImportedCustomers */
  nb_partially_imported_customers: Scalars['Int'];
  /** PathReportFolder */
  path_report_folder: Scalars['String'];
  /** NbCustomersChecked */
  nb_customers_checked: Scalars['Int'];
  /** NbCustomersValid */
  nb_customers_valid: Scalars['Int'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** State */
  state: Scalars['String'];
};

/** List of Main.ShopsListsImport */
export type ShopsListsImportItems = {
  __typename?: 'ShopsListsImportItems';
  total: Scalars['Int'];
  items: Array<ShopsListsImport>;
};

/** Main.ShopsListsImport */
export type ShopsListsImportUpdateInput = {
  input: Array<ShopsListsImportUpdateInputItem>;
};

/** Main.ShopsListsImport */
export type ShopsListsImportUpdateInputItem = {
  /** IdShopListImport */
  id_shop_list_import?: Maybe<Scalars['Int']>;
  /** IdShopList */
  id_shop_list?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** File */
  file?: Maybe<Scalars['String']>;
  /** SegmentsConfig */
  segments_config?: Maybe<Scalars['String']>;
  /** StayUpdated */
  stay_updated?: Maybe<Scalars['Boolean']>;
  /** ForceUpdate */
  force_update?: Maybe<Scalars['Int']>;
  /** Newsletter */
  newsletter?: Maybe<Scalars['Int']>;
  /** DoubleOptin */
  double_optin?: Maybe<Scalars['Boolean']>;
  /** NbCustomers */
  nb_customers?: Maybe<Scalars['Int']>;
  /** NbImportedCustomers */
  nb_imported_customers?: Maybe<Scalars['Int']>;
  /** NbNonImportedCustomers */
  nb_non_imported_customers?: Maybe<Scalars['Int']>;
  /** NbPartiallyImportedCustomers */
  nb_partially_imported_customers?: Maybe<Scalars['Int']>;
  /** PathReportFolder */
  path_report_folder?: Maybe<Scalars['String']>;
  /** NbCustomersChecked */
  nb_customers_checked?: Maybe<Scalars['Int']>;
  /** NbCustomersValid */
  nb_customers_valid?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
  /** State */
  state?: Maybe<Scalars['String']>;
};

/** Main.ShopsListsImportUnsub */
export type ShopsListsImportUnsub = {
  __typename?: 'ShopsListsImportUnsub';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Content */
  content: Scalars['String'];
  /** UnsubType */
  unsub_type: Scalars['Int'];
  /** State */
  state: Scalars['String'];
  /** Date */
  date_creation?: Scalars['DateTime'];
};

/** Main.ShopsListsImportUnsub */
export type ShopsListsImportUnsubInput = {
  input: Array<ShopsListsImportUnsubInputItem>;
};

/** Main.ShopsListsImportUnsubInputItem */
export type ShopsListsImportUnsubInputItem = {
  __typename?: 'ShopsListsImportUnsubInputItem';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Content */
  content: Scalars['String'];
  /** UnsubType */
  unsub_type: Scalars['Int'];
  /** State */
  state: Scalars['String'];
  /** Date */
  date_creation: Scalars['DateTime'];
};

/** List of Main.ShopsListsImportUnsub */
export type ShopsListsImportUnsubItems = {
  __typename?: 'ShopsListsImportUnsubItems';
  total: Scalars['Int'];
  items: Array<ShopsListsImportUnsub>;
};

/** Main.ShopsListsImportUnsubUpdateInput */
export type ShopsListsImportUnsubUpdateInput = {
  input: Array<ShopsListsImportUnsubUpdateInputItem>;
};

/** Main.ShopsListsImportUnsubUpdateInputItem */
export type ShopsListsImportUnsubUpdateInputItem = {
  __typename?: 'ShopsListsImportUnsubUpdateInputItem';
  /** IdShopListImportUnsub  */
  id_shop_list_import_unsub?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Content */
  content?: Maybe<Scalars['String']>;
  /** UnsubType */
  unsub_type?: Maybe<Scalars['Int']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** Date */
  date_creation?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsLists */
export type ShopsListsInput = {
  input: Array<ShopsListsInputItem>;
};

/** Main.ShopsLists */
export type ShopsListsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
  /** TotalContacts */
  total_contacts: Scalars['Int'];
  /** TotalSubscribers */
  total_subscribers: Scalars['Int'];
  /** TotalUnsubscribeds */
  total_unsubscribeds: Scalars['Int'];
  /** TotalSends */
  total_sends: Scalars['Int'];
  /** TotalSendsSoftBounce */
  total_sends_soft_bounce: Scalars['Int'];
  /** TotalSendsHardBounce */
  total_sends_hard_bounce: Scalars['Int'];
  /** TotalSendsSpam */
  total_sends_spam: Scalars['Int'];
  /** TotalNewslettersViewed */
  total_newsletters_viewed: Scalars['Int'];
  /** TotalNewslettersClicked */
  total_newsletters_clicked: Scalars['Int'];
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active: Scalars['Int'];
  /** Rating */
  rating?: Maybe<Scalars['Float']>;
  /** Valid */
  valid: Scalars['Boolean'];
  /** Whitelist */
  whitelist: Scalars['Boolean'];
  type: Scalars['String'];
};

/** List of Main.ShopsLists */
export type ShopsListsItems = {
  __typename?: 'ShopsListsItems';
  total: Scalars['Int'];
  items: Array<ShopsLists>;
};

/** Main.ShopsLists */
export type ShopsListsCustomInput = {
  /** IdShopList */
  id_shop_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name: Scalars['String'];
};

/** Main.Shops lists and Shops particular lists merged */
export type ShopsListsShopsParticularLists = {
  __typename?: 'ShopsListsShopsParticularLists';
  ID?: Maybe<Scalars['Int']>;
  Name?: Maybe<Scalars['String']>;
  TotalContacts?: Maybe<Scalars['Int']>;
  TotalSent?: Maybe<Scalars['Int']>;
  TotalUniqueViews?: Maybe<Scalars['Int']>;
  PercentUniqueViews?: Maybe<Scalars['Int']>;
  TotalUniqueClicks?: Maybe<Scalars['Int']>;
  PercentUniqueClicks?: Maybe<Scalars['Int']>;
  Subscribers?: Maybe<Scalars['Int']>;
  PercentSubscribers?: Maybe<Scalars['Int']>;
  NonSubscribers?: Maybe<Scalars['Int']>;
  PercentNonSubscribers?: Maybe<Scalars['Int']>;
  UnSubscribers?: Maybe<Scalars['Int']>;
  PercentUnSubscribers?: Maybe<Scalars['Int']>;
  SoftBounce?: Maybe<Scalars['Int']>;
  PercentSoftBounce?: Maybe<Scalars['Int']>;
  HardBounce?: Maybe<Scalars['Int']>;
  PercentHardBounce?: Maybe<Scalars['Int']>;
  Spams?: Maybe<Scalars['Int']>;
  PercentSpams?: Maybe<Scalars['Int']>;
  typeList?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Int']>;
  autoUpdatedList?: Maybe<Scalars['Boolean']>;
};

/** List of Main.ShopsLists merged lists from Main.ShopsParticularLists */
export type ShopsListsShopsParticularListsItems = {
  __typename?: 'ShopsListsShopsParticularListsItems';
  total: Scalars['Int'];
  items: Array<ShopsListsShopsParticularLists>;
};

export type ShopMonthlyPagesViewedItem = {
  __typename?: 'ShopMonthlyPagesViewed';
  pages: Scalars['Int'];
}

/** Main.ShopsLists */
export type ShopsListsUpdateInput = {
  input: Array<ShopsListsUpdateInputItem>;
};

/** Main.ShopsLists */
export type ShopsListsUpdateInputItem = {
  /** IdShopList */
  id_shop_list?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** TotalContacts */
  total_contacts?: Maybe<Scalars['Int']>;
  /** TotalSubscribers */
  total_subscribers?: Maybe<Scalars['Int']>;
  /** TotalUnsubscribeds */
  total_unsubscribeds?: Maybe<Scalars['Int']>;
  /** TotalSends */
  total_sends?: Maybe<Scalars['Int']>;
  /** TotalSendsSoftBounce */
  total_sends_soft_bounce?: Maybe<Scalars['Int']>;
  /** TotalSendsHardBounce */
  total_sends_hard_bounce?: Maybe<Scalars['Int']>;
  /** TotalSendsSpam */
  total_sends_spam?: Maybe<Scalars['Int']>;
  /** TotalNewslettersViewed */
  total_newsletters_viewed?: Maybe<Scalars['Int']>;
  /** TotalNewslettersClicked */
  total_newsletters_clicked?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Int']>;
  /** Rating */
  rating?: Maybe<Scalars['Float']>;
  /** Valid */
  valid?: Maybe<Scalars['Boolean']>;
  /** Whitelist */
  whitelist?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** Main.ShopsLogsActions */
export type ShopsLogsActions = {
  __typename?: 'ShopsLogsActions';
  /** IdShopLogAction */
  id_shop_log_action: Scalars['Int'];
  /** Date */
  date: Scalars['DateTime'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** Email */
  email: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Ip */
  ip: Scalars['String'];
  /** Location */
  location: Scalars['String'];
  /** SourceUrl */
  source_url: Scalars['String'];
};

/** List of Main.ShopsLogsActions */
export type ShopsLogsActionsItems = {
  __typename?: 'ShopsLogsActionsItems';
  total: Scalars['Int'];
  items: Array<ShopsLogsActions>;
};

/** Data.ShopsManufacturers */
export type ShopsManufacturers = {
  __typename?: 'ShopsManufacturers';
  /** IdShopManufacturer */
  id_shop_manufacturer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdManufacturer */
  id_manufacturer: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsManufacturers */
export type ShopsManufacturersInput = {
  input: Array<ShopsManufacturersInputItem>;
};

/** Data.ShopsManufacturers */
export type ShopsManufacturersInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdManufacturer */
  id_manufacturer: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Data.ShopsManufacturers */
export type ShopsManufacturersItems = {
  __typename?: 'ShopsManufacturersItems';
  total: Scalars['Int'];
  items: Array<ShopsManufacturers>;
};

/** Data.ShopsManufacturers */
export type ShopsManufacturersUpdateInput = {
  input: Array<ShopsManufacturersUpdateInputItem>;
};

/** Data.ShopsManufacturers */
export type ShopsManufacturersUpdateInputItem = {
  /** IdShopManufacturer */
  id_shop_manufacturer?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdManufacturer */
  id_manufacturer?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsNotifications */
export type ShopsNotifications = {
  __typename?: 'ShopsNotifications';
  /** IdShopNotification */
  id_shop_notification: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type: Scalars['String'];
  /** Title */
  title: Scalars['String'];
  /** Message */
  message: Scalars['String'];
  /** Date */
  date: Scalars['DateTime'];
  shops?: Maybe<Array<Maybe<Shops>>>;
  shopsNotificationsRead?: Maybe<Array<Maybe<ShopsNotificationsRead>>>;
};

/** Main.ShopsNotifications */
export type ShopsNotificationsInput = {
  input: Array<ShopsNotificationsInputItem>;
};

/** Main.ShopsNotifications */
export type ShopsNotificationsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type: Scalars['String'];
  /** Title */
  title: Scalars['String'];
  /** Message */
  message: Scalars['String'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Main.ShopsNotifications */
export type ShopsNotificationsItems = {
  __typename?: 'ShopsNotificationsItems';
  total: Scalars['Int'];
  items: Array<ShopsNotifications>;
};

/** Main.ShopsNotificationsRead */
export type ShopsNotificationsRead = {
  __typename?: 'ShopsNotificationsRead';
  /** IdShopNotification */
  id_shop_notification: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Date */
  date: Scalars['DateTime'];
  shopsNotifications?: Maybe<Array<Maybe<ShopsNotifications>>>;
};

/** Main.ShopsNotificationsRead */
export type ShopsNotificationsReadInput = {
  input: Array<ShopsNotificationsReadInputItem>;
};

/** Main.ShopsNotificationsRead */
export type ShopsNotificationsReadInputItem = {
  /** IdShopNotification */
  id_shop_notification?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Main.ShopsNotificationsRead */
export type ShopsNotificationsReadItems = {
  __typename?: 'ShopsNotificationsReadItems';
  total: Scalars['Int'];
  items: Array<ShopsNotificationsRead>;
};

/** Main.ShopsNotificationsRead */
export type ShopsNotificationsReadUpdateInput = {
  input: Array<ShopsNotificationsReadUpdateInputItem>;
};

/** Main.ShopsNotificationsRead */
export type ShopsNotificationsReadUpdateInputItem = {
  /** IdShopNotification */
  id_shop_notification?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsNotifications */
export type ShopsNotificationsUpdateInput = {
  input: Array<ShopsNotificationsUpdateInputItem>;
};

/** Main.ShopsNotifications */
export type ShopsNotificationsUpdateInputItem = {
  /** IdShopNotification */
  id_shop_notification?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Message */
  message?: Maybe<Scalars['String']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsOrders */
export type ShopsOrders = {
  __typename?: 'ShopsOrders';
  /** IdShopOrder */
  id_shop_order: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdOrder */
  id_order: Scalars['String'];
  /** OrderReference */
  order_reference?: Maybe<Scalars['String']>;
  /** Status */
  status: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Amount */
  amount: Scalars['Float'];
  /** AmountWithoutTax */
  amount_without_tax: Scalars['Float'];
  /** Shipping */
  shipping: Scalars['Float'];
  /** ShippingWithoutTax */
  shipping_without_tax: Scalars['Float'];
  /** VoucherAmount */
  voucher_amount?: Maybe<Scalars['Float']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** SpmVoucherUsed */
  spm_voucher_used: Scalars['String'];
  /** Voucher */
  voucher?: Maybe<Scalars['String']>;
  /** CurrencyRate */
  currency_rate?: Maybe<Scalars['Float']>;
  /** CurrencyRateToEuro */
  currency_rate_to_euro: Scalars['Float'];
  /** IdCart */
  id_cart: Scalars['String'];
  /** IdAddressDelivery */
  id_address_delivery: Scalars['Int'];
  /** IdAddressInvoice */
  id_address_invoice: Scalars['Int'];
  /** IdCarrier */
  id_carrier: Scalars['Int'];
  /** DateCart */
  date_cart: Scalars['DateTime'];
  /** IsValid */
  is_valid: Scalars['String'];
  /** ShippingNumber */
  shipping_number?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
  /** FirstName */
  first_name: Maybe<Scalars['String']>;
  /** LastName */
  last_name: Maybe<Scalars['String']>;
  /** Email */
  email: Maybe<Scalars['String']>;
  /** IDCustomerShop */
  id_customer_shop: Maybe<Scalars['String']>;
  /** CarrierName */
  name?: Maybe<Scalars['String']>;
};

/** Data.ShopsOrders */
export type ShopsOrdersInput = {
  input: Array<ShopsOrdersInputItem>;
};

/** Data.ShopsOrders */
export type ShopsOrdersInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdOrder */
  id_order: Scalars['String'];
  /** OrderReference */
  order_reference?: Maybe<Scalars['String']>;
  /** Status */
  status: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Amount */
  amount: Scalars['Float'];
  /** AmountWithoutTax */
  amount_without_tax: Scalars['Float'];
  /** Shipping */
  shipping: Scalars['Float'];
  /** ShippingWithoutTax */
  shipping_without_tax: Scalars['Float'];
  /** VoucherAmount */
  voucher_amount?: Maybe<Scalars['Float']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** SpmVoucherUsed */
  spm_voucher_used: Scalars['String'];
  /** Voucher */
  voucher?: Maybe<Scalars['String']>;
  /** CurrencyRate */
  currency_rate?: Maybe<Scalars['Float']>;
  /** CurrencyRateToEuro */
  currency_rate_to_euro: Scalars['Float'];
  /** IdCart */
  id_cart: Scalars['String'];
  /** IdAddressDelivery */
  id_address_delivery: Scalars['Int'];
  /** IdAddressInvoice */
  id_address_invoice: Scalars['Int'];
  /** IdCarrier */
  id_carrier: Scalars['Int'];
  /** DateCart */
  date_cart: Scalars['DateTime'];
  /** IsValid */
  is_valid: Scalars['String'];
  /** ShippingNumber */
  shipping_number?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** List of Data.ShopsOrders */
export type ShopsOrdersItems = {
  __typename?: 'ShopsOrdersItems';
  total: Scalars['Int'];
  items: Array<ShopsOrders>;
};

/** Data.ShopsOrders */
export type ShopsOrdersCustom = {
  id_shop_order: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdOrder */
  id_order: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  deleted: Scalars['Boolean'];
  /** OrderReference */
  order_reference?: Maybe<Scalars['String']>;
  /** Status */
  status: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Amount */
  amount: Scalars['Float'];
  /** AmountWithoutTax */
  amount_without_tax: Scalars['Float'];
  /** Shipping */
  shipping: Scalars['Float'];
  /** ShippingWithoutTax */
  shipping_without_tax: Scalars['Float'];
  /** VoucherAmount */
  voucher_amount?: Maybe<Scalars['Float']>;
  id_address_delivery: Scalars['Int'];
  /** IdAddressInvoice */
  id_address_invoice: Scalars['Int'];
  /** IdCarrier */
  id_carrier: Scalars['Int'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** List of Data.ShopsOrdersCustom */
export type ShopsOrdersCustomItems = {
  __typename?: 'ShopsOrdersCustomItems';
  total: Scalars['Int'];
  items: Array<ShopsOrdersCustom>;
};

/** Data.ShopsOrdersStatus */
export type ShopsOrdersStatus = {
  __typename?: 'ShopsOrdersStatus';
  /** IdShopOrderStatus */
  id_shop_order_status: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdStatus */
  id_status: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** Deleted */
  deleted: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsOrdersStatus */
export type ShopsOrdersStatusInput = {
  input: Array<ShopsOrdersStatusInputItem>;
};

/** Data.ShopsOrdersStatus */
export type ShopsOrdersStatusInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdStatus */
  id_status: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** Deleted */
  deleted: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Data.ShopsOrdersStatus */
export type ShopsOrdersStatusItems = {
  __typename?: 'ShopsOrdersStatusItems';
  total: Scalars['Int'];
  items: Array<ShopsOrdersStatus>;
};

/** Data.ShopsOrdersStatus */
export type ShopsOrdersStatusUpdateInput = {
  input: Array<ShopsOrdersStatusUpdateInputItem>;
};

/** Data.ShopsOrdersStatus */
export type ShopsOrdersStatusUpdateInputItem = {
  /** IdShopOrderStatus */
  id_shop_order_status?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdStatus */
  id_status?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** Deleted */
  deleted?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsOrders */
export type ShopsOrdersUpdateInput = {
  input: Array<ShopsOrdersUpdateInputItem>;
};

/** Data.ShopsOrders */
export type ShopsOrdersUpdateInputItem = {
  /** IdShopOrder */
  id_shop_order?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdOrder */
  id_order?: Maybe<Scalars['String']>;
  /** OrderReference */
  order_reference?: Maybe<Scalars['String']>;
  /** Status */
  status?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Amount */
  amount?: Maybe<Scalars['Float']>;
  /** AmountWithoutTax */
  amount_without_tax?: Maybe<Scalars['Float']>;
  /** Shipping */
  shipping?: Maybe<Scalars['Float']>;
  /** ShippingWithoutTax */
  shipping_without_tax?: Maybe<Scalars['Float']>;
  /** VoucherAmount */
  voucher_amount?: Maybe<Scalars['Float']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** SpmVoucherUsed */
  spm_voucher_used?: Maybe<Scalars['String']>;
  /** Voucher */
  voucher?: Maybe<Scalars['String']>;
  /** CurrencyRate */
  currency_rate?: Maybe<Scalars['Float']>;
  /** CurrencyRateToEuro */
  currency_rate_to_euro?: Maybe<Scalars['Float']>;
  /** IdCart */
  id_cart?: Maybe<Scalars['String']>;
  /** IdAddressDelivery */
  id_address_delivery?: Maybe<Scalars['Int']>;
  /** IdAddressInvoice */
  id_address_invoice?: Maybe<Scalars['Int']>;
  /** IdCarrier */
  id_carrier?: Maybe<Scalars['Int']>;
  /** DateCart */
  date_cart?: Maybe<Scalars['DateTime']>;
  /** IsValid */
  is_valid?: Maybe<Scalars['String']>;
  /** ShippingNumber */
  shipping_number?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsParticularLists */
export type ShopsParticularLists = {
  __typename?: 'ShopsParticularLists';
  /** IdShopParticularList */
  id_shop_particular_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** TypeList */
  type_list: Scalars['String'];
  /** TotalContacts */
  total_contacts: Scalars['Int'];
  /** TotalSubscribers */
  total_subscribers: Scalars['Int'];
  /** TotalUnsubscribeds */
  total_unsubscribeds: Scalars['Int'];
  /** TotalSends */
  total_sends: Scalars['Int'];
  /** TotalSendsSoftBounce */
  total_sends_soft_bounce: Scalars['Int'];
  /** TotalSendsHardBounce */
  total_sends_hard_bounce: Scalars['Int'];
  /** TotalSendsSpam */
  total_sends_spam: Scalars['Int'];
  /** TotalNewslettersViewed */
  total_newsletters_viewed: Scalars['Int'];
  /** TotalNewslettersClicked */
  total_newsletters_clicked: Scalars['Int'];
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.ShopsParticularLists */
export type ShopsParticularListsInput = {
  input: Array<ShopsParticularListsInputItem>;
};

/** Main.ShopsParticularLists */
export type ShopsParticularListsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** TypeList */
  type_list: Scalars['String'];
  /** TotalContacts */
  total_contacts: Scalars['Int'];
  /** TotalSubscribers */
  total_subscribers: Scalars['Int'];
  /** TotalUnsubscribeds */
  total_unsubscribeds: Scalars['Int'];
  /** TotalSends */
  total_sends: Scalars['Int'];
  /** TotalSendsSoftBounce */
  total_sends_soft_bounce: Scalars['Int'];
  /** TotalSendsHardBounce */
  total_sends_hard_bounce: Scalars['Int'];
  /** TotalSendsSpam */
  total_sends_spam: Scalars['Int'];
  /** TotalNewslettersViewed */
  total_newsletters_viewed: Scalars['Int'];
  /** TotalNewslettersClicked */
  total_newsletters_clicked: Scalars['Int'];
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.ShopsParticularLists */
export type ShopsParticularListsItems = {
  __typename?: 'ShopsParticularListsItems';
  total: Scalars['Int'];
  items: Array<ShopsParticularLists>;
};

/** Main.ShopsParticularLists */
export type ShopsParticularListsUpdateInput = {
  input: Array<ShopsParticularListsUpdateInputItem>;
};

/** Main.ShopsParticularLists */
export type ShopsParticularListsUpdateInputItem = {
  /** IdShopParticularList */
  id_shop_particular_list?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** TypeList */
  type_list?: Maybe<Scalars['String']>;
  /** TotalContacts */
  total_contacts?: Maybe<Scalars['Int']>;
  /** TotalSubscribers */
  total_subscribers?: Maybe<Scalars['Int']>;
  /** TotalUnsubscribeds */
  total_unsubscribeds?: Maybe<Scalars['Int']>;
  /** TotalSends */
  total_sends?: Maybe<Scalars['Int']>;
  /** TotalSendsSoftBounce */
  total_sends_soft_bounce?: Maybe<Scalars['Int']>;
  /** TotalSendsHardBounce */
  total_sends_hard_bounce?: Maybe<Scalars['Int']>;
  /** TotalSendsSpam */
  total_sends_spam?: Maybe<Scalars['Int']>;
  /** TotalNewslettersViewed */
  total_newsletters_viewed?: Maybe<Scalars['Int']>;
  /** TotalNewslettersClicked */
  total_newsletters_clicked?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsProducts */
export type ShopsProducts = {
  __typename?: 'ShopsProducts';
  /** IdShopProduct */
  id_shop_product: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdProduct */
  id_product: Scalars['Int'];
  /** Reference */
  reference?: Maybe<Scalars['String']>;
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** DescriptionShort */
  description_short?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Link */
  link: Scalars['String'];
  /** ImageLink */
  image_link?: Maybe<Scalars['String']>;
  /** IdManufacturer */
  id_manufacturer?: Maybe<Scalars['String']>;
  /** Currency */
  currency: Scalars['String'];
  /** Price */
  price: Scalars['Float'];
  /** PriceDiscount */
  price_discount?: Maybe<Scalars['Float']>;
  /** Quantity */
  quantity: Scalars['Int'];
  /** Active */
  active: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsProductsCategoriesList */
export type ShopsProductsCategoriesList = {
  __typename?: 'ShopsProductsCategoriesList';
  /** IdShopProductCategoryList */
  id_shop_product_category_list: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdCategory */
  id_category: Scalars['Int'];
  /** IdCategoryParent */
  id_category_parent?: Maybe<Scalars['Int']>;
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Link */
  link: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsProductsCategoriesList */
export type ShopsProductsCategoriesListInput = {
  input: Array<ShopsProductsCategoriesListInputItem>;
};

/** Data.ShopsProductsCategoriesList */
export type ShopsProductsCategoriesListInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdCategory */
  id_category: Scalars['Int'];
  /** IdCategoryParent */
  id_category_parent?: Maybe<Scalars['Int']>;
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Link */
  link: Scalars['String'];
  /** Active */
  active: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** List of Data.ShopsProductsCategoriesList */
export type ShopsProductsCategoriesListItems = {
  __typename?: 'ShopsProductsCategoriesListItems';
  total: Scalars['Int'];
  items: Array<ShopsProductsCategoriesList>;
};

/** Data.ShopsProductsCategoriesList */
export type ShopsProductsCategoriesListUpdateInput = {
  input: Array<ShopsProductsCategoriesListUpdateInputItem>;
};

/** Data.ShopsProductsCategoriesList */
export type ShopsProductsCategoriesListUpdateInputItem = {
  /** IdShopProductCategoryList */
  id_shop_product_category_list?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdCategory */
  id_category?: Maybe<Scalars['Int']>;
  /** IdCategoryParent */
  id_category_parent?: Maybe<Scalars['Int']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Link */
  link?: Maybe<Scalars['String']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsProducts */
export type ShopsProductsInput = {
  input: Array<ShopsProductsInputItem>;
};

/** Data.ShopsProducts */
export type ShopsProductsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdProduct */
  id_product: Scalars['Int'];
  /** Reference */
  reference?: Maybe<Scalars['String']>;
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** DescriptionShort */
  description_short?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Link */
  link: Scalars['String'];
  /** ImageLink */
  image_link?: Maybe<Scalars['String']>;
  /** IdManufacturer */
  id_manufacturer?: Maybe<Scalars['String']>;
  /** Currency */
  currency: Scalars['String'];
  /** Price */
  price: Scalars['Float'];
  /** PriceDiscount */
  price_discount?: Maybe<Scalars['Float']>;
  /** Quantity */
  quantity: Scalars['Int'];
  /** Active */
  active: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** List of Data.ShopsProducts */
export type ShopsProductsItems = {
  __typename?: 'ShopsProductsItems';
  total: Scalars['Int'];
  items: Array<ShopsProducts>;
};

/** Data.ShopsProducts */
export type ShopsProductsUpdateInput = {
  input: Array<ShopsProductsUpdateInputItem>;
};

/** Data.ShopsProducts */
export type ShopsProductsUpdateInputItem = {
  /** IdShopProduct */
  id_shop_product?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdProduct */
  id_product?: Maybe<Scalars['Int']>;
  /** Reference */
  reference?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DescriptionShort */
  description_short?: Maybe<Scalars['String']>;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Link */
  link?: Maybe<Scalars['String']>;
  /** ImageLink */
  image_link?: Maybe<Scalars['String']>;
  /** IdManufacturer */
  id_manufacturer?: Maybe<Scalars['String']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Price */
  price?: Maybe<Scalars['Float']>;
  /** PriceDiscount */
  price_discount?: Maybe<Scalars['Float']>;
  /** Quantity */
  quantity?: Maybe<Scalars['Int']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsPushToken */
export type ShopsPushToken = {
  __typename?: 'ShopsPushToken';
  /** IdShopPushToken */
  id_shop_push_token: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopVisitor */
  id_shop_visitor: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** PushEndpoint */
  push_endpoint?: Maybe<Scalars['String']>;
  /** PushKey */
  push_key?: Maybe<Scalars['String']>;
  /** PushToken */
  push_token?: Maybe<Scalars['String']>;
  /** BrowserName */
  browser_name?: Maybe<Scalars['String']>;
  /** BrowserVersion */
  browser_version?: Maybe<Scalars['String']>;
  /** Device */
  device?: Maybe<Scalars['String']>;
  /** DeviceOs */
  device_os?: Maybe<Scalars['String']>;
  /** RegistrationMode */
  registration_mode: Scalars['String'];
};

/** List of Data.ShopsPushToken */
export type ShopsPushTokenItems = {
  __typename?: 'ShopsPushTokenItems';
  total: Scalars['Int'];
  items: Array<ShopsPushToken>;
};

/** Main.ShopsSendDomains */
export type ShopsSendDomains = {
  __typename?: 'ShopsSendDomains';
  /** IdDomain */
  id_domain: Scalars['Int'];
  /** Domain */
  domain: Scalars['String'];
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** DomainVerified */
  domain_verified: Scalars['Boolean'];
  /** DnsVerificationKey */
  dns_verification_key?: Maybe<Scalars['String']>;
  /** PublicKey */
  public_key?: Maybe<Scalars['String']>;
  /** DkimVerified */
  dkim_verified: Scalars['Boolean'];
  /** SpfVerified */
  spf_verified: Scalars['Boolean'];
  /** AuthenticationVerified */
  authentication_verified: Scalars['Boolean'];
  /** AlignmentDomain */
  alignment_domain?: Maybe<Scalars['String']>;
  /** AlignmentVerified */
  alignment_verified: Scalars['Boolean'];
  /** DkimSelector */
  dkim_selector: Scalars['String'];
  /** TrackingDomain */
  tracking_domain?: Maybe<Scalars['String']>;
  /** TrackingDomainVerified */
  tracking_domain_verified: Scalars['Boolean'];
  /** TrackingDomainDefaultMedias */
  tracking_domain_default_medias: Scalars['Boolean'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.ShopsSendDomains */
export type ShopsSendDomainsInput = {
  input: Array<ShopsSendDomainsInputItem>;
};

/** Main.ShopsSendDomains */
export type ShopsSendDomainsInputItem = {
  /** Domain */
  domain: Scalars['String'];
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** DomainVerified */
  domain_verified: Scalars['Boolean'];
  /** DnsVerificationKey */
  dns_verification_key?: Maybe<Scalars['String']>;
  /** PublicKey */
  public_key?: Maybe<Scalars['String']>;
  /** DkimVerified */
  dkim_verified: Scalars['Boolean'];
  /** SpfVerified */
  spf_verified: Scalars['Boolean'];
  /** AuthenticationVerified */
  authentication_verified: Scalars['Boolean'];
  /** AlignmentDomain */
  alignment_domain?: Maybe<Scalars['String']>;
  /** AlignmentVerified */
  alignment_verified: Scalars['Boolean'];
  /** DkimSelector */
  dkim_selector: Scalars['String'];
  /** TrackingDomain */
  tracking_domain?: Maybe<Scalars['String']>;
  /** TrackingDomainVerified */
  tracking_domain_verified: Scalars['Boolean'];
  /** TrackingDomainDefaultMedias */
  tracking_domain_default_medias: Scalars['Boolean'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
};

/** List of Main.ShopsSendDomains */
export type ShopsSendDomainsItems = {
  __typename?: 'ShopsSendDomainsItems';
  total: Scalars['Int'];
  items: Array<ShopsSendDomains>;
};

/** Main.ShopsSendDomains */
export type ShopsSendDomainsUpdateInput = {
  input: Array<ShopsSendDomainsUpdateInputItem>;
};

/** List of Main.ShopsSendDomainsToken */
export type ShopsSendDomainsTokenInput = {
  /** SHA1(IdDomain+-+Domain) */
  token: Scalars['String'];
  /** IdShop */
  id_shop: Scalars['Int'];
};

/** Main.ShopsSendDomains */
export type ShopsSendDomainsUpdateInputItem = {
  /** IdDomain */
  id_domain?: Maybe<Scalars['Int']>;
  /** Domain */
  domain?: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** DomainVerified */
  domain_verified?: Maybe<Scalars['Boolean']>;
  /** DnsVerificationKey */
  dns_verification_key?: Maybe<Scalars['String']>;
  /** PublicKey */
  public_key?: Maybe<Scalars['String']>;
  /** DkimVerified */
  dkim_verified?: Maybe<Scalars['Boolean']>;
  /** SpfVerified */
  spf_verified?: Maybe<Scalars['Boolean']>;
  /** AuthenticationVerified */
  authentication_verified?: Maybe<Scalars['Boolean']>;
  /** AlignmentDomain */
  alignment_domain?: Maybe<Scalars['String']>;
  /** AlignmentVerified */
  alignment_verified?: Maybe<Scalars['Boolean']>;
  /** DkimSelector */
  dkim_selector?: Maybe<Scalars['String']>;
  /** TrackingDomain */
  tracking_domain?: Maybe<Scalars['String']>;
  /** TrackingDomainVerified */
  tracking_domain_verified?: Maybe<Scalars['Boolean']>;
  /** TrackingDomainDefaultMedias */
  tracking_domain_default_medias?: Maybe<Scalars['Boolean']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsSyncs */
export type ShopsSyncs = {
  __typename?: 'ShopsSyncs';
  /** IdShopSyncs */
  id_shop_syncs: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** State */
  state: Scalars['String'];
  /** Owner */
  owner: Scalars['String'];
  /** IsFirstSync */
  is_first_sync?: Maybe<Scalars['Int']>;
  /** Progress */
  progress: Scalars['Int'];
  /** Start */
  start: Scalars['Int'];
  /** Error */
  error: Scalars['Int'];
  /** CurrentType */
  current_type?: Maybe<Scalars['String']>;
  /** DateReference */
  date_reference?: Maybe<Scalars['DateTime']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.ShopsSyncs */
export type ShopsSyncsInput = {
  input: Array<ShopsSyncsInputItem>;
};

/** Main.ShopsSyncs */
export type ShopsSyncsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** State */
  state: Scalars['String'];
  /** Owner */
  owner: Scalars['String'];
  /** IsFirstSync */
  is_first_sync?: Maybe<Scalars['Int']>;
  /** Progress */
  progress: Scalars['Int'];
  /** Start */
  start: Scalars['Int'];
  /** Error */
  error: Scalars['Int'];
  /** CurrentType */
  current_type?: Maybe<Scalars['String']>;
  /** DateReference */
  date_reference?: Maybe<Scalars['DateTime']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** List of Main.ShopsSyncs */
export type ShopsSyncsItems = {
  __typename?: 'ShopsSyncsItems';
  total: Scalars['Int'];
  items: Array<ShopsSyncs>;
};

/** Main.ShopsSyncs */
export type ShopsSyncsUpdateInput = {
  input: Array<ShopsSyncsUpdateInputItem>;
};

/** Main.ShopsSyncs */
export type ShopsSyncsUpdateInputItem = {
  /** IdShopSyncs */
  id_shop_syncs?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** Owner */
  owner?: Maybe<Scalars['String']>;
  /** IsFirstSync */
  is_first_sync?: Maybe<Scalars['Int']>;
  /** Progress */
  progress?: Maybe<Scalars['Int']>;
  /** Start */
  start?: Maybe<Scalars['Int']>;
  /** Error */
  error?: Maybe<Scalars['Int']>;
  /** CurrentType */
  current_type?: Maybe<Scalars['String']>;
  /** DateReference */
  date_reference?: Maybe<Scalars['DateTime']>;
  /** Params */
  params?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

export type ProgramShopsSyncs = {
  id_shop_syncs: Scalars['Int'];
  total: Scalars['Int'];
  error: Scalars['String'];
}

/** Data.ShopsTags */
export type ShopsTags = {
  __typename?: 'ShopsTags';
  /** IdShopTag */
  id_shop_tag: Scalars['Int'];
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Tag */
  tag?: Maybe<Scalars['String']>;
};

/** Data.ShopsTags */
export type ShopsTagsDeleteInput = {
  input: Array<ShopsTagsUpdateInput>;
};

/** Data.ShopsTags */
export type ShopsTagsInput = {
  input: Array<ShopsTagsInputItem>;
};

/** Data.ShopsTags */
export type ShopsTagsInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Tag */
  tag?: Maybe<Scalars['String']>;
};

/** List of Data.ShopsTags */
export type ShopsTagsItems = {
  __typename?: 'ShopsTagsItems';
  total: Scalars['Int'];
  items: Array<ShopsTags>;
};

/** Data.ShopsTags */
export type ShopsTagsUpdateInput = {
  input: Array<ShopsTagsUpdateInputItem>;
};

/** Data.ShopsTags */
export type ShopsTagsUpdateInputItem = {
  /** IdShopTag */
  id_shop_tag?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Tag */
  tag?: Maybe<Scalars['String']>;
};

/** Main.Shops */
export type ShopsUpdateInput = {
  input: Array<ShopsUpdateInputItem>;
};

/** Main.Shops */
export type ShopsUpdateInputItem = {
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** ShopIdShop */
  shop_id_shop?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Url */
  url?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** ApiKey */
  api_key?: Maybe<Scalars['String']>;
  /** Identifiant */
  identifiant?: Maybe<Scalars['String']>;
  /** ApiUserSmtp */
  api_user_smtp?: Maybe<Scalars['String']>;
  /** ApiPassSmtp */
  api_pass_smtp?: Maybe<Scalars['String']>;
  /** SolutionEcommerce */
  solution_ecommerce?: Maybe<Scalars['String']>;
  /** SolutionEcommerceVersion */
  solution_ecommerce_version?: Maybe<Scalars['String']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** IdUser */
  id_user?: Maybe<Scalars['Int']>;
  /** IdUserSalesService */
  id_user_sales_service?: Maybe<Scalars['Int']>;
  /** IdUserCustomerService */
  id_user_customer_service?: Maybe<Scalars['Int']>;
  /** UrlClient */
  url_client?: Maybe<Scalars['String']>;
  /** CurrentVersion */
  current_version?: Maybe<Scalars['String']>;
  /** ModuleVersion */
  module_version?: Maybe<Scalars['String']>;
  /** CurrentBuild */
  current_build?: Maybe<Scalars['Int']>;
  /** TimezoneCode */
  timezone_code?: Maybe<Scalars['String']>;
  /** TimezoneName */
  timezone_name?: Maybe<Scalars['String']>;
  /** TimezoneTime */
  timezone_time?: Maybe<Scalars['Int']>;
  /** LastUpdateTimezone */
  last_update_timezone?: Maybe<Scalars['DateTime']>;
  /** TimeDiffClient */
  time_diff_client?: Maybe<Scalars['Int']>;
  /** LastUpdateCustomersEmails */
  last_update_customers_emails?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCustomers */
  last_update_customers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateProducts */
  last_update_products?: Maybe<Scalars['DateTime']>;
  /** LastUpdateProductsCategories */
  last_update_products_categories?: Maybe<Scalars['DateTime']>;
  /** LastUpdateOrders */
  last_update_orders?: Maybe<Scalars['DateTime']>;
  /** LastUpdateNewsletters */
  last_update_newsletters?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCustomersGroups */
  last_update_customers_groups?: Maybe<Scalars['DateTime']>;
  /** LastUpdateManufacturers */
  last_update_manufacturers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateCarriers */
  last_update_carriers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateVouchers */
  last_update_vouchers?: Maybe<Scalars['DateTime']>;
  /** LastUpdateOrdersStatus */
  last_update_orders_status?: Maybe<Scalars['DateTime']>;
  /** CustomerNeedSync */
  customer_need_sync?: Maybe<Scalars['Boolean']>;
  /** CategoryNeedSync */
  category_need_sync?: Maybe<Scalars['Boolean']>;
  /** ProductNeedSync */
  product_need_sync?: Maybe<Scalars['Boolean']>;
  /** OrderNeedSync */
  order_need_sync?: Maybe<Scalars['Boolean']>;
  /** NewsletterNeedSync */
  newsletter_need_sync?: Maybe<Scalars['Boolean']>;
  /** GroupNeedSync */
  group_need_sync?: Maybe<Scalars['Boolean']>;
  /** ManufacturerNeedSync */
  manufacturer_need_sync?: Maybe<Scalars['Boolean']>;
  /** CarrierNeedSync */
  carrier_need_sync?: Maybe<Scalars['Boolean']>;
  /** VoucherNeedSync */
  voucher_need_sync?: Maybe<Scalars['Boolean']>;
  /** StatusNeedSync */
  status_need_sync?: Maybe<Scalars['Boolean']>;
  /** IdTemplatesBaseDefault */
  id_templates_base_default?: Maybe<Scalars['Int']>;
  /** TemplatesBaseBodyDefault */
  templates_base_body_default?: Maybe<Scalars['String']>;
  /** IdCategoryShop */
  id_category_shop?: Maybe<Scalars['Int']>;
  /** RelaunchEmailsExclusion */
  relaunch_emails_exclusion?: Maybe<Scalars['String']>;
  /** MultishopEnabled */
  multishop_enabled?: Maybe<Scalars['Boolean']>;
  /** BundleVersion */
  bundle_version?: Maybe<Scalars['Boolean']>;
  /** BundleNewsletterVersion */
  bundle_newsletter_version?: Maybe<Scalars['Int']>;
  /** OptincollectId */
  optincollect_id?: Maybe<Scalars['String']>;
  /** TestMode */
  test_mode?: Maybe<Scalars['Boolean']>;
  /** IsMigrate */
  is_migrate?: Maybe<Scalars['Boolean']>;
  /** HasFivePercentRule */
  has_five_percent_rule?: Maybe<Scalars['Boolean']>;
  /** Pool */
  pool?: Maybe<Scalars['String']>;
  /** DedicatedPools */
  dedicated_pools?: Maybe<Scalars['String']>;
  /** Rating */
  rating?: Maybe<Scalars['Float']>;
  /** RatingRetargeting */
  rating_retargeting?: Maybe<Scalars['Float']>;
  /** Services */
  services?: Maybe<Scalars['Boolean']>;
  /** ExcludeFromDashboard */
  exclude_from_dashboard?: Maybe<Scalars['Boolean']>;
  /** UseWorkflows */
  use_workflows?: Maybe<Scalars['Boolean']>;
  /** WorkflowInvitationSended */
  workflow_invitation_sended?: Maybe<Scalars['Boolean']>;
  /** RestrictedNewsletter */
  restricted_newsletter?: Maybe<Scalars['Boolean']>;
  /** DisplayDisclaimer */
  display_disclaimer?: Maybe<Scalars['Boolean']>;
  /** RestrictedDeliverability */
  restricted_deliverability?: Maybe<Scalars['DateTime']>;
};

/** Data.ShopsVouchers */
export type ShopsVouchers = {
  __typename?: 'ShopsVouchers';
  /** IdShopVoucher */
  id_shop_voucher: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShopVisitor */
  id_shop_visitor: Scalars['Int'];
  /** IdVoucher */
  id_voucher: Scalars['String'];
  /** IdCustomerShop */
  id_customer_shop: Scalars['String'];
  /** Code */
  code: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** TypeVoucher */
  type_voucher: Scalars['Boolean'];
  /** Amount */
  amount: Scalars['Float'];
  /** ReductionTax */
  reduction_tax: Scalars['Boolean'];
  /** ReductionCurrency */
  reduction_currency?: Maybe<Scalars['String']>;
  /** MinimumAmount */
  minimum_amount: Scalars['Float'];
  /** MinimumAmountCurrency */
  minimum_amount_currency?: Maybe<Scalars['String']>;
  /** DateFrom */
  date_from: Scalars['DateTime'];
  /** DateTo */
  date_to?: Maybe<Scalars['DateTime']>;
  /** Active */
  active: Scalars['String'];
  /** Used */
  used?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** Data.ShopsVouchers */
export type ShopsVouchersInput = {
  input: Array<ShopsVouchersInputItem>;
};

/** Data.ShopsVouchers */
export type ShopsVouchersInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShopVisitor */
  id_shop_visitor: Scalars['Int'];
  /** IdVoucher */
  id_voucher: Scalars['String'];
  /** IdCustomerShop */
  id_customer_shop: Scalars['String'];
  /** Code */
  code: Scalars['String'];
  /** Lang */
  lang: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** TypeVoucher */
  type_voucher: Scalars['Boolean'];
  /** Amount */
  amount: Scalars['Float'];
  /** ReductionTax */
  reduction_tax: Scalars['Boolean'];
  /** ReductionCurrency */
  reduction_currency?: Maybe<Scalars['String']>;
  /** MinimumAmount */
  minimum_amount: Scalars['Float'];
  /** MinimumAmountCurrency */
  minimum_amount_currency?: Maybe<Scalars['String']>;
  /** DateFrom */
  date_from: Scalars['DateTime'];
  /** DateTo */
  date_to?: Maybe<Scalars['DateTime']>;
  /** Active */
  active: Scalars['String'];
  /** Used */
  used?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

/** List of Data.ShopsVouchers */
export type ShopsVouchersItems = {
  __typename?: 'ShopsVouchersItems';
  total: Scalars['Int'];
  items: Array<ShopsVouchers>;
};

/** Data.ShopsVouchers */
export type ShopsVouchersUpdateInput = {
  input: Array<ShopsVouchersUpdateInputItem>;
};

/** Data.ShopsVouchers */
export type ShopsVouchersUpdateInputItem = {
  /** IdShopVoucher */
  id_shop_voucher?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdShopCustomer */
  id_shop_customer?: Maybe<Scalars['Int']>;
  /** IdShopVisitor */
  id_shop_visitor?: Maybe<Scalars['Int']>;
  /** IdVoucher */
  id_voucher?: Maybe<Scalars['String']>;
  /** IdCustomerShop */
  id_customer_shop?: Maybe<Scalars['String']>;
  /** Code */
  code?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** TypeVoucher */
  type_voucher?: Maybe<Scalars['Boolean']>;
  /** Amount */
  amount?: Maybe<Scalars['Float']>;
  /** ReductionTax */
  reduction_tax?: Maybe<Scalars['Boolean']>;
  /** ReductionCurrency */
  reduction_currency?: Maybe<Scalars['String']>;
  /** MinimumAmount */
  minimum_amount?: Maybe<Scalars['Float']>;
  /** MinimumAmountCurrency */
  minimum_amount_currency?: Maybe<Scalars['String']>;
  /** DateFrom */
  date_from?: Maybe<Scalars['DateTime']>;
  /** DateTo */
  date_to?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
  /** Used */
  used?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Date */
  date?: Maybe<Scalars['DateTime']>;
};

/** Main.SmsCampaigns */
export type SmsCampaigns = {
  __typename?: 'SmsCampaigns';
  /** IdSmsCampaign */
  id_sms_campaign: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** State */
  state: Scalars['String'];
  /** IsQueued */
  is_queued: Scalars['Int'];
  /** FinishedModulos */
  finished_modulos: Scalars['Int'];
  /** NbSubscribers */
  nb_subscribers?: Maybe<Scalars['Int']>;
  /** NbSent */
  nb_sent?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DateSend */
  date_send?: Maybe<Scalars['DateTime']>;
  /** IsFreeCampaign */
  is_free_campaign: Scalars['Boolean'];
  /** Price */
  price: Scalars['Float'];
  /** Retry */
  retry: Scalars['Boolean'];
  /** TemporaryTablesCreated */
  temporary_tables_created: Scalars['Boolean'];
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.SmsCampaigns */
export type SmsCampaignsInput = {
  input: Array<SmsCampaignsInputItem>;
};

/** Main.SmsCampaigns */
export type SmsCampaignsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** State */
  state: Scalars['String'];
  /** IsQueued */
  is_queued: Scalars['Int'];
  /** FinishedModulos */
  finished_modulos: Scalars['Int'];
  /** NbSubscribers */
  nb_subscribers?: Maybe<Scalars['Int']>;
  /** NbSent */
  nb_sent?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DateSend */
  date_send?: Maybe<Scalars['DateTime']>;
  /** IsFreeCampaign */
  is_free_campaign: Scalars['Boolean'];
  /** Price */
  price: Scalars['Float'];
  /** Retry */
  retry: Scalars['Boolean'];
  /** TemporaryTablesCreated */
  temporary_tables_created: Scalars['Boolean'];
};

/** List of Main.SmsCampaigns */
export type SmsCampaignsItems = {
  __typename?: 'SmsCampaignsItems';
  total: Scalars['Int'];
  items: Array<SmsCampaigns>;
};

/** Main.SmsCampaigns */
export type SmsCampaignsUpdateInput = {
  input: Array<SmsCampaignsUpdateInputItem>;
};

/** Main.SmsCampaigns */
export type SmsCampaignsUpdateInputItem = {
  /** IdSmsCampaign */
  id_sms_campaign?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** IsQueued */
  is_queued?: Maybe<Scalars['Int']>;
  /** FinishedModulos */
  finished_modulos?: Maybe<Scalars['Int']>;
  /** NbSubscribers */
  nb_subscribers?: Maybe<Scalars['Int']>;
  /** NbSent */
  nb_sent?: Maybe<Scalars['Int']>;
  /** DateAdd */
  date_add?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** DateSend */
  date_send?: Maybe<Scalars['DateTime']>;
  /** IsFreeCampaign */
  is_free_campaign?: Maybe<Scalars['Boolean']>;
  /** Price */
  price?: Maybe<Scalars['Float']>;
  /** Retry */
  retry?: Maybe<Scalars['Boolean']>;
  /** TemporaryTablesCreated */
  temporary_tables_created?: Maybe<Scalars['Boolean']>;
};

/** Main.SmsPrices */
export type SmsPrices = {
  __typename?: 'SmsPrices';
  /** IdSmsPrice */
  id_sms_price: Scalars['Int'];
  /** CountryCode */
  country_code: Scalars['String'];
  /** CountryName */
  country_name: Scalars['String'];
  /** Price */
  price: Scalars['Float'];
  /** PriceUsd */
  price_usd: Scalars['Float'];
};

/** Main.SmsPrices */
export type SmsPricesInput = {
  input: Array<SmsPricesInputItem>;
};

/** Main.SmsPrices */
export type SmsPricesInputItem = {
  /** CountryCode */
  country_code: Scalars['String'];
  /** CountryName */
  country_name: Scalars['String'];
  /** Price */
  price: Scalars['Float'];
  /** PriceUsd */
  price_usd: Scalars['Float'];
};

/** List of Main.SmsPrices */
export type SmsPricesItems = {
  __typename?: 'SmsPricesItems';
  total: Scalars['Int'];
  items: Array<SmsPrices>;
};

/** Main.SmsPrices */
export type SmsPricesUpdateInput = {
  input: Array<SmsPricesUpdateInputItem>;
};

/** Main.SmsPrices */
export type SmsPricesUpdateInputItem = {
  /** IdSmsPrice */
  id_sms_price?: Maybe<Scalars['Int']>;
  /** CountryCode */
  country_code?: Maybe<Scalars['String']>;
  /** CountryName */
  country_name?: Maybe<Scalars['String']>;
  /** Price */
  price?: Maybe<Scalars['Float']>;
  /** PriceUsd */
  price_usd?: Maybe<Scalars['Float']>;
};

export type SpmChart = {
  __typename?: 'SpmChart';
  type: ChartType;
  data?: Maybe<ChartData>;
  options?: any;
  plugins?: Maybe<Array<Maybe<Scalars['String']>>>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type SpmKpi = {
  __typename?: 'SpmKpi';
  type: Scalars['String'];
  color?: Scalars['String'];
  icon?: Scalars['String'];
  label?: Scalars['String'];
  value: Scalars['String'];
};

/** Data.StatsIncomeByPeriod */
export type StatsIncomeByPeriod = {
  __typename?: 'StatsIncomeByPeriod';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** PeriodeYear */
  periode_year: Scalars['Int'];
  /** PeriodeMonth */
  periode_month: Scalars['Int'];
  /** PeriodeDay */
  periode_day: Scalars['Int'];
  /** PeriodeHour */
  periode_hour: Scalars['Int'];
  /** Value */
  value: Scalars['Float'];
  /** ValueSpm */
  value_spm: Scalars['Float'];
  /** ValueProspects */
  value_prospects: Scalars['Float'];
  /** ValueCustomers */
  value_customers: Scalars['Float'];
};

/** List of Data.StatsIncomeByPeriod */
export type StatsIncomeByPeriodItems = {
  __typename?: 'StatsIncomeByPeriodItems';
  total: Scalars['Int'];
  items: Array<StatsIncomeByPeriod>;
};

/** Data.StatsPagesViewsByPeriod */
export type StatsPagesViewsByPeriod = {
  __typename?: 'StatsPagesViewsByPeriod';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** PeriodeYear */
  periode_year: Scalars['Int'];
  /** PeriodeMonth */
  periode_month: Scalars['Int'];
  /** PeriodeDay */
  periode_day: Scalars['Int'];
  /** PeriodeHour */
  periode_hour: Scalars['Int'];
  /** SingleVisits */
  single_visits: Scalars['Int'];
  /** Sessions */
  sessions: Scalars['Int'];
  /** ViewedPages */
  viewed_pages: Scalars['Int'];
  /** TimeOnPages */
  time_on_pages: Scalars['Int'];
};

/** List of Data.StatsPagesViewsByPeriod */
export type StatsPagesViewsByPeriodItems = {
  __typename?: 'StatsPagesViewsByPeriodItems';
  total: Scalars['Int'];
  items: Array<StatsPagesViewsByPeriod>;
};

/** Data.StatsRegistrationsByPeriod */
export type StatsRegistrationsByPeriod = {
  __typename?: 'StatsRegistrationsByPeriod';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** PeriodeYear */
  periode_year: Scalars['Int'];
  /** PeriodeMonth */
  periode_month: Scalars['Int'];
  /** PeriodeDay */
  periode_day: Scalars['Int'];
  /** PeriodeHour */
  periode_hour: Scalars['Int'];
  /** Count */
  count: Scalars['Int'];
  /** CountSpm */
  count_spm: Scalars['Int'];
};

/** List of Data.StatsRegistrationsByPeriod */
export type StatsRegistrationsByPeriodItems = {
  __typename?: 'StatsRegistrationsByPeriodItems';
  total: Scalars['Int'];
  items: Array<StatsRegistrationsByPeriod>;
};

export type StatsReqParams = {
  /** What stats you want? */
  stat?: Scalars['String'];
  /** The type of chart */
  type: StatsWidgetType;
  /** Configuration of the widget */
  configuration?: StatsWidgetDataConfiguration;
  /** Persistent filters on the page (shop, dates, ...) */
  persistent_filters?: Maybe<Scalars['String']>;
  /** Custom filters on the page/widget */
  filters?: Maybe<Scalars['String']>;
};

export type StatsResponse = {
  __typename?: 'StatsResponse';
  chart?: Maybe<SpmChart>;
  kpi?: Maybe<Array<SpmKpi>>;
  table?: Maybe<Scalars['String']>;
};

/** Data.StatsSalesByPeriod */
export type StatsSalesByPeriod = {
  __typename?: 'StatsSalesByPeriod';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** PeriodeYear */
  periode_year: Scalars['Int'];
  /** PeriodeMonth */
  periode_month: Scalars['Int'];
  /** PeriodeDay */
  periode_day: Scalars['Int'];
  /** PeriodeHour */
  periode_hour: Scalars['Int'];
  /** Count */
  count: Scalars['Int'];
  /** CountSpm */
  count_spm: Scalars['Int'];
  /** CountProspects */
  count_prospects: Scalars['Int'];
  /** CountCustomers */
  count_customers: Scalars['Int'];
};

/** List of Data.StatsSalesByPeriod */
export type StatsSalesByPeriodItems = {
  __typename?: 'StatsSalesByPeriodItems';
  total: Scalars['Int'];
  items: Array<StatsSalesByPeriod>;
};

/** Main.Templates */
export type Templates = {
  __typename?: 'Templates';
  /** IdTemplate */
  id_template: Scalars['Int'];
  /** Label */
  label: Scalars['String'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Version */
  version: Scalars['Int'];
  /** CreatedFromCampaign */
  created_from_campaign?: Maybe<Scalars['String']>;
  /** IdCampaign */
  id_campaign?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdRelaunch */
  id_relaunch: Scalars['Int'];
  /** IdTemplatesBase */
  id_templates_base?: Maybe<Scalars['Int']>;
  /** IdTemplatesContent */
  id_templates_content?: Maybe<Scalars['Int']>;
  /** IdTemplateReference */
  id_template_reference?: Maybe<Scalars['Int']>;
  /** TemplateConfig */
  template_config?: Maybe<Scalars['String']>;
  /** Deleted */
  deleted: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Main.TemplatesCustom */
export type TemplatesCustom = {
  __typename?: 'TemplatesCustom';
  /** IdTemplate */
  id_template: Scalars['Int'];
  /** Label */
  label: Scalars['String'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** CreatedFromCampaign */
  created_from_campaign?: Maybe<Scalars['String']>;
  /** TemplateConfig */
  template_config?: Maybe<Scalars['String']>;
  /** IdTemplatesLang */
  id_templates_lang?: Maybe<Scalars['Int']>;
  /** subject */
  subject?: Maybe<Scalars['String']>;
  /** FromEmail */
  from_email?: Maybe<Scalars['String']>;
  /** FromName */
  from_name?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Config */
  config?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
};

/** Main.TemplatesElements */
export type TemplatesElements = {
  __typename?: 'TemplatesElements';
  /** IdTemplateElements */
  id_template_elements: Scalars['Int'];
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Data */
  data?: Maybe<Scalars['String']>;
  /** Html */
  html?: Maybe<Scalars['String']>;
  /** Css */
  css?: Maybe<Scalars['String']>;
  /** Translations */
  translations?: Maybe<Scalars['String']>;
  /** HtmlBeforeSave */
  html_before_save?: Maybe<Scalars['String']>;
  /** CssBeforeSave */
  css_before_save?: Maybe<Scalars['String']>;
  /** TranslationsBeforeSave */
  translations_before_save?: Maybe<Scalars['String']>;
  /** TemplateElementHash */
  template_element_hash?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Main.TemplatesElementsHistory */
export type TemplatesElementsHistory = {
  __typename?: 'TemplatesElementsHistory';
  /** IdTemplateElementsHistory */
  id_template_elements_history: Scalars['Int'];
  /** IdTemplateElementsHistoryGroup */
  id_template_elements_history_group: Scalars['Int'];
  /** IdTemplateElements */
  id_template_elements: Scalars['Int'];
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** HtmlAfter */
  html_after?: Maybe<Scalars['String']>;
  /** CssAfter */
  css_after?: Maybe<Scalars['String']>;
  /** TranslationsAfter */
  translations_after?: Maybe<Scalars['String']>;
  /** SectionsOrderAfter */
  sections_order_after?: Maybe<Scalars['String']>;
  /** HtmlBefore */
  html_before?: Maybe<Scalars['String']>;
  /** CssBefore */
  css_before?: Maybe<Scalars['String']>;
  /** TranslationsBefore */
  translations_before?: Maybe<Scalars['String']>;
  /** SectionsOrderBefore */
  sections_order_before?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Main.TemplatesElementsHistoryGroup */
export type TemplatesElementsHistoryGroup = {
  __typename?: 'TemplatesElementsHistoryGroup';
  /** IdTemplateElementsHistoryGroup */
  id_template_elements_history_group: Scalars['Int'];
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** ActionDetail */
  action_detail?: Maybe<Scalars['String']>;
  /** SectionsOrderAfter */
  sections_order_after?: Maybe<Scalars['String']>;
  /** SectionsOrderBefore */
  sections_order_before?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Main.TemplatesElementsHistoryGroup */
export type TemplatesElementsHistoryGroupInput = {
  input: Array<TemplatesElementsHistoryGroupInputItem>;
};

/** Main.TemplatesElementsHistoryGroup */
export type TemplatesElementsHistoryGroupInputItem = {
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** ActionDetail */
  action_detail?: Maybe<Scalars['String']>;
  /** SectionsOrderAfter */
  sections_order_after?: Maybe<Scalars['String']>;
  /** SectionsOrderBefore */
  sections_order_before?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.TemplatesElementsHistoryGroup */
export type TemplatesElementsHistoryGroupItems = {
  __typename?: 'TemplatesElementsHistoryGroupItems';
  total: Scalars['Int'];
  items: Array<TemplatesElementsHistoryGroup>;
};

/** Main.TemplatesElementsHistoryGroup */
export type TemplatesElementsHistoryGroupUpdateInput = {
  input: Array<TemplatesElementsHistoryGroupUpdateInputItem>;
};

/** Main.TemplatesElementsHistoryGroup */
export type TemplatesElementsHistoryGroupUpdateInputItem = {
  /** IdTemplateElementsHistoryGroup */
  id_template_elements_history_group?: Maybe<Scalars['Int']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** ActionDetail */
  action_detail?: Maybe<Scalars['String']>;
  /** SectionsOrderAfter */
  sections_order_after?: Maybe<Scalars['String']>;
  /** SectionsOrderBefore */
  sections_order_before?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.TemplatesElementsHistory */
export type TemplatesElementsHistoryInput = {
  input: Array<TemplatesElementsHistoryInputItem>;
};

/** Main.TemplatesElementsHistory */
export type TemplatesElementsHistoryInputItem = {
  /** IdTemplateElementsHistoryGroup */
  id_template_elements_history_group: Scalars['Int'];
  /** IdTemplateElements */
  id_template_elements: Scalars['Int'];
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** HtmlAfter */
  html_after?: Maybe<Scalars['String']>;
  /** CssAfter */
  css_after?: Maybe<Scalars['String']>;
  /** TranslationsAfter */
  translations_after?: Maybe<Scalars['String']>;
  /** SectionsOrderAfter */
  sections_order_after?: Maybe<Scalars['String']>;
  /** HtmlBefore */
  html_before?: Maybe<Scalars['String']>;
  /** CssBefore */
  css_before?: Maybe<Scalars['String']>;
  /** TranslationsBefore */
  translations_before?: Maybe<Scalars['String']>;
  /** SectionsOrderBefore */
  sections_order_before?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.TemplatesElementsHistory */
export type TemplatesElementsHistoryItems = {
  __typename?: 'TemplatesElementsHistoryItems';
  total: Scalars['Int'];
  items: Array<TemplatesElementsHistory>;
};

/** Main.TemplatesElementsHistory */
export type TemplatesElementsHistoryUpdateInput = {
  input: Array<TemplatesElementsHistoryUpdateInputItem>;
};

/** Main.TemplatesElementsHistory */
export type TemplatesElementsHistoryUpdateInputItem = {
  /** IdTemplateElementsHistory */
  id_template_elements_history?: Maybe<Scalars['Int']>;
  /** IdTemplateElementsHistoryGroup */
  id_template_elements_history_group?: Maybe<Scalars['Int']>;
  /** IdTemplateElements */
  id_template_elements?: Maybe<Scalars['Int']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** HtmlAfter */
  html_after?: Maybe<Scalars['String']>;
  /** CssAfter */
  css_after?: Maybe<Scalars['String']>;
  /** TranslationsAfter */
  translations_after?: Maybe<Scalars['String']>;
  /** SectionsOrderAfter */
  sections_order_after?: Maybe<Scalars['String']>;
  /** HtmlBefore */
  html_before?: Maybe<Scalars['String']>;
  /** CssBefore */
  css_before?: Maybe<Scalars['String']>;
  /** TranslationsBefore */
  translations_before?: Maybe<Scalars['String']>;
  /** SectionsOrderBefore */
  sections_order_before?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.TemplatesElements */
export type TemplatesElementsInput = {
  input: Array<TemplatesElementsInputItem>;
};

/** Main.TemplatesElements */
export type TemplatesElementsInputItem = {
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Data */
  data?: Maybe<Scalars['String']>;
  /** Html */
  html?: Maybe<Scalars['String']>;
  /** Css */
  css?: Maybe<Scalars['String']>;
  /** Translations */
  translations?: Maybe<Scalars['String']>;
  /** HtmlBeforeSave */
  html_before_save?: Maybe<Scalars['String']>;
  /** CssBeforeSave */
  css_before_save?: Maybe<Scalars['String']>;
  /** TranslationsBeforeSave */
  translations_before_save?: Maybe<Scalars['String']>;
  /** TemplateElementHash */
  template_element_hash?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.TemplatesElements */
export type TemplatesElementsItems = {
  __typename?: 'TemplatesElementsItems';
  total: Scalars['Int'];
  items: Array<TemplatesElements>;
};

/** Main.TemplatesElements */
export type TemplatesElementsUpdateInput = {
  input: Array<TemplatesElementsUpdateInputItem>;
};

/** Main.TemplatesElements */
export type TemplatesElementsUpdateInputItem = {
  /** IdTemplateElements */
  id_template_elements?: Maybe<Scalars['Int']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Data */
  data?: Maybe<Scalars['String']>;
  /** Html */
  html?: Maybe<Scalars['String']>;
  /** Css */
  css?: Maybe<Scalars['String']>;
  /** Translations */
  translations?: Maybe<Scalars['String']>;
  /** HtmlBeforeSave */
  html_before_save?: Maybe<Scalars['String']>;
  /** CssBeforeSave */
  css_before_save?: Maybe<Scalars['String']>;
  /** TranslationsBeforeSave */
  translations_before_save?: Maybe<Scalars['String']>;
  /** TemplateElementHash */
  template_element_hash?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Main.Templates */
export type TemplatesInput = {
  input: Array<TemplatesInputItem>;
};

/** Main.Templates */
export type TemplatesInputItem = {
  /** Label */
  label: Scalars['String'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Version */
  version: Scalars['Int'];
  /** CreatedFromCampaign */
  created_from_campaign?: Maybe<Scalars['String']>;
  /** IdCampaign */
  id_campaign?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdRelaunch */
  id_relaunch: Scalars['Int'];
  /** IdTemplatesBase */
  id_templates_base?: Maybe<Scalars['Int']>;
  /** IdTemplatesContent */
  id_templates_content?: Maybe<Scalars['Int']>;
  /** IdTemplateReference */
  id_template_reference?: Maybe<Scalars['Int']>;
  /** TemplateConfig */
  template_config?: Maybe<Scalars['String']>;
  /** Deleted */
  deleted: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Main.Templates */
export type TemplatesItems = {
  __typename?: 'TemplatesItems';
  total: Scalars['Int'];
  items: Array<Templates>;
};

/** Main.TemplatesLang */
export type TemplatesLang = {
  __typename?: 'TemplatesLang';
  /** IdTemplatesLang */
  id_templates_lang: Scalars['Int'];
  /** Subject */
  subject?: Maybe<Scalars['String']>;
  /** FromEmail */
  from_email?: Maybe<Scalars['String']>;
  /** FromName */
  from_name?: Maybe<Scalars['String']>;
  /** Body */
  body: Scalars['String'];
  /** BodySaved */
  body_saved?: Maybe<Scalars['String']>;
  /** Lang */
  lang: Scalars['String'];
  /** Config */
  config?: Maybe<Scalars['String']>;
  /** IdTemplate */
  id_template: Scalars['Int'];
  templates?: Maybe<Array<Maybe<Templates>>>;
};

/** Main.TemplatesLang */
export type TemplatesLangInput = {
  input: Array<TemplatesLangInputItem>;
};

/** Main.TemplatesLang */
export type TemplatesLangInputItem = {
  /** Subject */
  subject?: Maybe<Scalars['String']>;
  /** FromEmail */
  from_email?: Maybe<Scalars['String']>;
  /** FromName */
  from_name?: Maybe<Scalars['String']>;
  /** Body */
  body: Scalars['String'];
  /** BodySaved */
  body_saved?: Maybe<Scalars['String']>;
  /** Lang */
  lang: Scalars['String'];
  /** Config */
  config?: Maybe<Scalars['String']>;
  /** IdTemplate */
  id_template: Scalars['Int'];
};

/** List of Main.TemplatesLang */
export type TemplatesLangItems = {
  __typename?: 'TemplatesLangItems';
  total: Scalars['Int'];
  items: Array<TemplatesLang>;
};

/** Main.TemplatesLang */
export type TemplatesLangUpdateInput = {
  input: Array<TemplatesLangUpdateInputItem>;
};

/** Main.TemplatesLang */
export type TemplatesLangUpdateInputItem = {
  /** IdTemplatesLang */
  id_templates_lang?: Maybe<Scalars['Int']>;
  /** Subject */
  subject?: Maybe<Scalars['String']>;
  /** FromEmail */
  from_email?: Maybe<Scalars['String']>;
  /** FromName */
  from_name?: Maybe<Scalars['String']>;
  /** Body */
  body?: Maybe<Scalars['String']>;
  /** BodySaved */
  body_saved?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Config */
  config?: Maybe<Scalars['String']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
};

/** Main.TemplatesParts */
export type TemplatesParts = {
  __typename?: 'TemplatesParts';
  /** IdTemplateParts */
  id_template_parts: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** TemplateType */
  template_type?: Maybe<Scalars['String']>;
  /** PartType */
  part_type?: Maybe<Scalars['String']>;
  /** Data */
  data?: Maybe<Scalars['String']>;
  /** Html */
  html?: Maybe<Scalars['String']>;
  /** Css */
  css?: Maybe<Scalars['String']>;
  /** Translations */
  translations?: Maybe<Scalars['String']>;
  /** TemplatePartHash */
  template_part_hash?: Maybe<Scalars['String']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdTemplateFrom */
  id_template_from?: Maybe<Scalars['Int']>;
  shops?: Maybe<Array<Maybe<Shops>>>;
};

/** Main.TemplatesParts */
export type TemplatesPartsInput = {
  input: Array<TemplatesPartsInputItem>;
};

/** Main.TemplatesParts */
export type TemplatesPartsInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** TemplateType */
  template_type?: Maybe<Scalars['String']>;
  /** PartType */
  part_type?: Maybe<Scalars['String']>;
  /** Data */
  data?: Maybe<Scalars['String']>;
  /** Html */
  html?: Maybe<Scalars['String']>;
  /** Css */
  css?: Maybe<Scalars['String']>;
  /** Translations */
  translations?: Maybe<Scalars['String']>;
  /** TemplatePartHash */
  template_part_hash?: Maybe<Scalars['String']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdTemplateFrom */
  id_template_from?: Maybe<Scalars['Int']>;
};

/** List of Main.TemplatesParts */
export type TemplatesPartsItems = {
  __typename?: 'TemplatesPartsItems';
  total: Scalars['Int'];
  items: Array<TemplatesParts>;
};

/** Main.TemplatesParts */
export type TemplatesPartsUpdateInput = {
  input: Array<TemplatesPartsUpdateInputItem>;
};

/** Main.TemplatesParts */
export type TemplatesPartsUpdateInputItem = {
  /** IdTemplateParts */
  id_template_parts?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** TemplateType */
  template_type?: Maybe<Scalars['String']>;
  /** PartType */
  part_type?: Maybe<Scalars['String']>;
  /** Data */
  data?: Maybe<Scalars['String']>;
  /** Html */
  html?: Maybe<Scalars['String']>;
  /** Css */
  css?: Maybe<Scalars['String']>;
  /** Translations */
  translations?: Maybe<Scalars['String']>;
  /** TemplatePartHash */
  template_part_hash?: Maybe<Scalars['String']>;
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdTemplateFrom */
  id_template_from?: Maybe<Scalars['Int']>;
};

/** Main.Templates */
export type TemplatesUpdateInput = {
  input: Array<TemplatesUpdateInputItem>;
};

/** Main.Templates */
export type TemplatesUpdateInputItem = {
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** Label */
  label?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Version */
  version?: Maybe<Scalars['Int']>;
  /** CreatedFromCampaign */
  created_from_campaign?: Maybe<Scalars['String']>;
  /** IdCampaign */
  id_campaign?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** IdRelaunch */
  id_relaunch?: Maybe<Scalars['Int']>;
  /** IdTemplatesBase */
  id_templates_base?: Maybe<Scalars['Int']>;
  /** IdTemplatesContent */
  id_templates_content?: Maybe<Scalars['Int']>;
  /** IdTemplateReference */
  id_template_reference?: Maybe<Scalars['Int']>;
  /** TemplateConfig */
  template_config?: Maybe<Scalars['String']>;
  /** Deleted */
  deleted?: Maybe<Scalars['Boolean']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

export type TemplatesDuplicateInput = {
  input: TemplatesDuplicateInputItem;
};

/** Main.DuplicateTemplate */
export type TemplatesDuplicateInputItem = {
  /** IdTemplate */
  id_template?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** OriginCall */
  origin_call?: Maybe<Scalars['String']>;
  /** IdCampaign */
  id_campaign?: Maybe<Scalars['Int']>;
  /** Name of new template */
  label?: Maybe<Scalars['String']>;
};

export type TemplateAndTemplateElementsUpdateInput = {
  id_shop: Scalars['Int'];
  template: Scalars['String'];
};

export type ReplaceRedirectionTemplateInput = {
  input: ReplaceRedirectionTemplateInputItem;
};

/** Main.ReplaceRedirectionTemplate */
export type ReplaceRedirectionTemplateInputItem = {
  /** id_templates_parent */
  id_templates_parent: Maybe<Scalars['String']>;
  /** IdShop */
  id_shop: Maybe<Scalars['Int']>;
  /** old_id_template */
  old_id_template: Maybe<Scalars['Int']>;
  /** new_id_template */
  new_id_template: Maybe<Scalars['Int']>;
};

/** Main.Users */
export type Users = {
  __typename?: 'Users';
  /** IdUser */
  id_user: Scalars['Int'];
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Scalars['String'];
  /** Password */
  password: Scalars['String'];
  /** Societe */
  societe?: Maybe<Scalars['String']>;
  /** VatNumber */
  vat_number?: Maybe<Scalars['String']>;
  /** PhoneMobile */
  phone_mobile?: Maybe<Scalars['String']>;
  /** PhoneFixe */
  phone_fixe?: Maybe<Scalars['String']>;
  /** Complement */
  complement?: Maybe<Scalars['String']>;
  /** Rue */
  rue?: Maybe<Scalars['String']>;
  /** Bp */
  bp?: Maybe<Scalars['String']>;
  /** ZipCode */
  zip_code?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active: Scalars['Boolean'];
  /** Role */
  role: Scalars['String'];
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** PromoCode */
  promo_code: Scalars['String'];
  /** DisplayDisclaimer */
  display_disclaimer: Scalars['Boolean'];
  /** DisplayWelcomeScreen */
  display_welcome_screen: Scalars['Boolean'];
  adyenTokens?: Maybe<Array<Maybe<AdyenTokens>>>;
};

/** Main.Users */
export type UsersInput = {
  input: Array<UsersInputItem>;
};

/** Main.Users */
export type UsersInputItem = {
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Scalars['String'];
  /** Password */
  password: Scalars['String'];
  /** Societe */
  societe?: Maybe<Scalars['String']>;
  /** VatNumber */
  vat_number?: Maybe<Scalars['String']>;
  /** PhoneMobile */
  phone_mobile?: Maybe<Scalars['String']>;
  /** PhoneFixe */
  phone_fixe?: Maybe<Scalars['String']>;
  /** Complement */
  complement?: Maybe<Scalars['String']>;
  /** Rue */
  rue?: Maybe<Scalars['String']>;
  /** Bp */
  bp?: Maybe<Scalars['String']>;
  /** ZipCode */
  zip_code?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active: Scalars['Boolean'];
  /** Role */
  role: Scalars['String'];
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** PromoCode */
  promo_code: Scalars['String'];
  /** DisplayDisclaimer */
  display_disclaimer: Scalars['Boolean'];
  /** DisplayWelcomeScreen */
  display_welcome_screen: Scalars['Boolean'];
};

/** List of Main.Users */
export type UsersItems = {
  __typename?: 'UsersItems';
  total: Scalars['Int'];
  items: Array<Users>;
};

/** Main.Users */
export type UsersUpdateInput = {
  input: Array<UsersUpdateInputItem>;
};

/** Main.Users */
export type UsersUpdateInputItem = {
  /** IdUser */
  id_user?: Maybe<Scalars['Int']>;
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Password */
  password?: Maybe<Scalars['String']>;
  /** Societe */
  societe?: Maybe<Scalars['String']>;
  /** VatNumber */
  vat_number?: Maybe<Scalars['String']>;
  /** PhoneMobile */
  phone_mobile?: Maybe<Scalars['String']>;
  /** PhoneFixe */
  phone_fixe?: Maybe<Scalars['String']>;
  /** Complement */
  complement?: Maybe<Scalars['String']>;
  /** Rue */
  rue?: Maybe<Scalars['String']>;
  /** Bp */
  bp?: Maybe<Scalars['String']>;
  /** ZipCode */
  zip_code?: Maybe<Scalars['String']>;
  /** City */
  city?: Maybe<Scalars['String']>;
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** State */
  state?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Role */
  role?: Maybe<Scalars['String']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** PromoCode */
  promo_code?: Maybe<Scalars['String']>;
  /** DisplayDisclaimer */
  display_disclaimer?: Maybe<Scalars['Boolean']>;
  /** DisplayWelcomeScreen */
  display_welcome_screen?: Maybe<Scalars['Boolean']>;
};

/** Main.ShopsUsers */
export type ShopsUsers = {
  __typename?: 'ShopsUsers';
  /** IdShopsUser */
  id_shops_user?: Maybe<Scalars['Int']>;
  /** IdPsCustomer */
  id_ps_customer: Scalars['Int'];
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Scalars['String'];
  /** Password */
  password?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** UsersPermissions */
  user_permissions?: Maybe<Array<Maybe<UsersPermissions>>>;
  /** Shops */
  shops?: Maybe<Array<Scalars['Int']>>;
};

/** Main.ShopsUsers */
export type ShopsUsersInput = {
  input: Array<ShopsUsersInputItem>;
};

/** Main.ShopsUsers */
export type ShopsUsersInputItem = {
  /** IdPsCustomer */
  id_ps_customer: Scalars['Int'];
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email: Scalars['String'];
  /** Password */
  password?: Maybe<Scalars['String']>;
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Shops : list of shop's id to send email to user */
  shops?: Maybe<Array<Scalars['Int']>>;
};

/** List of Main.ShopsUsers */
export type ShopsUsersItems = {
  __typename?: 'UsersItems';
  total: Scalars['Int'];
  items: Array<ShopsUsers>;
};

/** Main.ShopsUsers */
export type ShopsUsersUpdateInputItem = {
  /** IdShopsUser */
  id_shops_user: Scalars['Int'];
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Password */
  password?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;
  /** Shops : list of shop's id to send email to user */
  shops?: Maybe<Array<Scalars['Int']>>;
};

/** Main.ShopsUsers */
export type ShopsUsersUpdateInput = {
  input: Array<ShopsUsersUpdateInputItem>;
};

/** Main.ShopsUsers */
export type ShopsUsersDeleteInput = {
  /** IdShopsUser */
  id_shops_user: Scalars['Int'];
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** LastName */
  last_name?: Maybe<Scalars['String']>;
  /** FirstName */
  first_name?: Maybe<Scalars['String']>;
  /** Email */
  email?: Maybe<Scalars['String']>;
  /** Password */
  password?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Boolean']>;
  /** Lang */
  lang?: Maybe<Scalars['String']>;

};

/** Main.ShopsUsers */
export type ShopsUsersDeleteInputItem = {
  input: Array<ShopsUsersDeleteInput>;
};

/** Main.UsersPermissions */
export type UsersPermissions = {
  __typename?: 'UsersPermissions';
  /** IdShopsUser */
  id_shops_user: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** Permissions */
  period?: Maybe<Scalars['String']>;
  /** Permissions */
  permissions?: Maybe<Scalars['String']>;
  /** ExpiryDate */
  expiry_date?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Int']>;
};

/** Main.UsersPermissions */
export type UsersPermissionsInput = {
  input: Array<UsersPermissionsInputItem>;
};

/** Main.UsersPermissions */
export type UsersPermissionsInputItem = {
  /** IdShopsUser */
  id_shops_user: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** Permissions */
  period?: Maybe<Scalars['String']>;
  /** Permissions */
  permissions?: Maybe<Scalars['String']>;
  /** ExpiryDate */
  expiry_date?: Maybe<Scalars['DateTime']>;
  /** Active */
  active?: Maybe<Scalars['Int']>;
};

/** List of Main.UsersPermissions */
export type UsersPermissionsItems = {
  __typename?: 'UsersPermissionsItems';
  total: Scalars['Int'];
  items: Array<UsersPermissions>;
};

/** Main.UsersPermissions */
export type UsersPermissionsUpdateInputItem = {
  /** IdUser */
  id_shops_user?: Maybe<Scalars['Int']>;
  /** IdShop */
  id_shop?: Maybe<Scalars['Int']>;
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** Permissions */
  period?: Maybe<Scalars['String']>;
  /** Permissions */
  permissions?: Maybe<Scalars['String']>;
  /** ExpiryDate */
  expiry_date?: Maybe<Scalars['DateTime']>;
};

/** List of Main.UsersPermissions */
export type UsersPermissionsUpdateInput = {
  __typename?: 'UsersPermissionsUpdateInput';
  total: Scalars['Int'];
  items: Array<UsersPermissionsUpdateInputItem>;
};

/** List of Main.UsersPermissions */
export type UsersPermissionsUpsertInput = {
  __typename?: 'UsersPermissionsUpsertInput';
  total: Scalars['Int'];
  items: Array<UsersPermissionsUpsertInputItem>;
};

/** Main.UsersPermissions */
export type UsersPermissionsUpsertInputItem = {
  /** IdUser */
  id_user: Scalars['Int'];
  /** Array of shopId */
  shop_ids: Array<Scalars['Int']>;
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
  /** Permissions */
  period?: Maybe<Scalars['String']>;
  /** Permissions */
  permissions?: Maybe<Scalars['String']>;
  /** ExpiryDate */
  expiry_date?: Maybe<Scalars['DateTime']>;
};

/** Main.UsersPermissions */
export type UsersPermissionsDeleteInputItem = {
  /** IdShopsUser */
  id_shops_user: Scalars['Int'];
  /** IdPsCustomer */
  id_ps_customer?: Maybe<Scalars['Int']>;
};

/** Configuration.UsersPagesConfiguration */
export type UsersPagesConfiguration = {
  __typename?: 'UsersPagesConfiguration';
  /** IdUsersPagesConfiguration */
  id_users_pages_configuration: Scalars['Int'];
  /** PageName */
  page_name: Scalars['String'];
  /** NavigationName */
  navigation_name: Scalars['String'];
  /** AdminUserId */
  admin_user_id: Scalars['Int'];
  /** CreatedByUserId */
  created_by_user_id: Scalars['Int'];
  /** CreatedByUserType */
  created_by_user_type: Scalars['String'];
  /** Configuration */
  configuration: Scalars['String'];
  /** ExtraData */
  extra_data?: Maybe<Scalars['String']>;
  /** Type */
  type: Scalars['String'];
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
  /** Priority */
  deleted?: Maybe<Scalars['Boolean']>;
  /** structure */
  structure?: Array<struct>;
  /** owner */
  owner: Scalars['String'];
};

/** Configuration.UsersPagesConfiguration */
export type UsersPagesConfigurationInput = {
  input: Array<UsersPagesConfigurationInputItem>;
};

/** Configuration.UsersPagesConfiguration */
export type UsersPagesConfigurationInputItem = {
  /** Onwer */
  owner: Scalars['String'];
  /** PageName */
  page_name: Scalars['String'];
  /** NavigationName */
  navigation_name: Scalars['String'];
  /** AdminUserId */
  admin_user_id: Maybe<Scalars['Int']>;
  /** CreatedByUserId */
  created_by_user_id: Scalars['Int'];
  /** CreatedByUserType */
  created_by_user_type: Scalars['String'];
  /** Configuration */
  configuration: Scalars['String'];
  /** ExtraData */
  extra_data?: Maybe<Scalars['String']>;
  /** Type */
  type: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Configuration.UsersPagesConfiguration */
export type UsersPagesConfigurationItems = {
  __typename?: 'UsersPagesConfigurationItems';
  total: Scalars['Int'];
  items: Array<UsersPagesConfiguration>;
};

/** Configuration.UsersPagesConfiguration */
export type UsersPagesConfigurationUpdateInput = {
  input: Array<UsersPagesConfigurationUpdateInputItem>;
};

/** Configuration.UsersPagesConfiguration */
export type UsersPagesConfigurationUpdateInputItem = {
  /** IdUsersPagesConfiguration */
  id_users_pages_configuration: Scalars['Int'];
  /** PageName */
  page_name?: Maybe<Scalars['String']>;
  /** NavigationName */
  navigation_name?: Maybe<Scalars['String']>;
  /** Configuration */
  configuration?: Maybe<Scalars['String']>;
  /** ExtraData */
  extra_data?: Maybe<Scalars['String']>;
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Priority */
  deleted?: Maybe<Scalars['Boolean']>;
};

/** Configuration.UsersWidgetsConfiguration */
export type UsersWidgetsConfiguration = {
  __typename?: 'UsersWidgetsConfiguration';
  /** IdUsersWidgetsConfiguration */
  id_users_widgets_configuration: Scalars['Int'];
  /** Configuration */
  configuration: Scalars['String'];
  /** DateCreation */
  date_creation?: Maybe<Scalars['DateTime']>;
  /** DateModification */
  date_modification?: Maybe<Scalars['DateTime']>;
};

/** Configuration.UsersWidgetsConfiguration */
export type UsersWidgetsConfigurationInput = {
  input: Array<UsersWidgetsConfigurationInputItem>;
};

/** Configuration.UsersWidgetsConfiguration */
export type UsersWidgetsConfigurationInputItem = {
  /** Configuration */
  configuration: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['DateTime'];
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** List of Configuration.UsersWidgetsConfiguration */
export type UsersWidgetsConfigurationItems = {
  __typename?: 'UsersWidgetsConfigurationItems';
  total: Scalars['Int'];
  items: Array<UsersWidgetsConfiguration>;
};

/** Configuration.UsersWidgetsConfiguration */
export type UsersWidgetsConfigurationUpdateInput = {
  input: Array<UsersWidgetsConfigurationUpdateInputItem>;
};

/** Configuration.UsersWidgetsConfiguration */
export type UsersWidgetsConfigurationUpdateInputItem = {
  /** IdUsersWidgetsConfiguration */
  id_users_widgets_configuration: Scalars['Int'];
  /** Configuration */
  configuration?: Maybe<Scalars['String']>;
  /** DateModification */
  date_modification: Scalars['DateTime'];
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptions = {
  __typename?: 'UsersPagesSubscriptions';
  /** IdUsersPagesSubscription */
  id_users_pages_subscription: Scalars['Int'];
  /** IdUsersPagesConfiguration */
  id_users_pages_configuration: Scalars['Int'];
  /** IdUser */
  id_user: Scalars['Int'];
  /** UserType */
  user_type: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Priority */
  priority: Scalars['Int'];
  page?: Maybe<UsersPagesConfiguration>;
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsInput = {
  input: Array<UsersPagesSubscriptionsInputItem>;
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsInputItem = {
  /** IdUsersPagesConfiguration */
  id_users_pages_configuration: Scalars['Int'];
  /** IdUser */
  id_user: Scalars['Int'];
  /** UserType */
  user_type: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Priority */
  priority: Scalars['Int'];
};

/** List of Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsItems = {
  __typename?: 'UsersPagesSubscriptionsItems';
  total: Scalars['Int'];
  items: Array<UsersPagesSubscriptions>;
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsUpdateInput = {
  input: Array<UsersPagesSubscriptionsUpdateInputItem>;
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsUpdateInputItem = {
  /** IdUsersPagesSubscription */
  id_users_pages_subscription: Scalars['Int'];
  /** Priority */
  priority: Scalars['Int'];
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsDeleteInput = {
  /** IdUsersPagesConfiguration */
  id_users_pages_configuration?: Scalars['Int'];
  /** IdUser */
  id_user: Scalars['Int'];
  /** UserType */
  user_type: Scalars['String'];
};

/** Configuration.UsersPagesSubscriptions */
export type UsersPagesSubscriptionsDeleteInputItem = {
  input: Array<UsersPagesSubscriptionsDeleteInput>;
};

/** Main.ShopsPlans */
export type ShopsPlans = {
  __typename?: 'ShopsPlans';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Plan */
  plan?: Maybe<Scalars['String']>;
  /** IsActive */
  is_active?: Scalars['Boolean'];
  /** DateCreation */
  date_creation: Scalars['String'];
  /** DateModification */
  date_modification: Scalars['String'];
};

/** Main.ShopsPlans */
export type ShopsPlansInput = {
  input: Array<ShopsPlansInputItem>;
};

/** Main.ShopsPlans */
export type ShopsPlansInputItem = {
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Plan */
  plan: Scalars['String'];
  /** DateCreation */
  date_creation: Scalars['String'];
  /** DateModification */
  date_modification: Scalars['String'];
};

/** List of Main.ShopsPlans */
export type ShopsPlansItems = {
  __typename?: 'ShopsPlansItems';
  total: Scalars['Int'];
  items: Array<ShopsPlans>;
};

/** Main.ShopsPlans */
export type ShopsPlansUpdateInput = {
  input: Array<ShopsPlansUpdateInputItem>;
};

/** Main.ShopsPlans */
export type ShopsPlansUpdateInputItem = {
  __typename?: 'ShopsPlans';
  /** IdShop */
  id_shop: Scalars['Int'];
  /** Plan */
  plan: Scalars['String'];
  /** DateModification */
  date_modification: Scalars['String'];
};

/** Main.ShopsCustomersPhones */
export type ShopsCustomersPhones = {
  __typename?: 'ShopsCustomersPhones';
  /** IdShopCustomerPhone */
  id_shop_customer_phone: Scalars['Int'];
  /** IdShopCustomerAddress */
  id_shop_customer_address: Scalars['Int'];
  /** IdShopCustomer */
  id_shop_customer: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdAddressShop */
  id_address_shop?: Maybe<Scalars['Int']>;
  /** Phone1 */
  mobile1?: Scalars['String'];
  /** Phone2 */
  mobile2?: Maybe<Scalars['String']>;
  /** Company */
  mobile_count?: Scalars['Int'];
  /** Country */
  country?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Active */
  active?: Maybe<Scalars['String']>;
};

/** List of Data.ShopsCustomersPhonesItem */
export type ShopsCustomersPhonesItems = {
  __typename?: 'ShopsCustomersPhonesItems';
  total: Scalars['Int'];
  items: Array<ShopsCustomersPhones>;
};

export type ShopsOrdersProducts = {
  /** IdShopOrderProduct */
  id_shop_order_product: Scalars['Int'];
  /** IdShopOrder */
  id_shop_order: Scalars['Int'];
  /** IdShopProduct */
  id_shop_product: Scalars['Int'];
  /** IdShopProductAttribute */
  id_shop_product_attribute: Scalars['Int'];
  /** IdShop */
  id_shop: Scalars['Int'];
  /** IdProduct */
  id_product?: Maybe<Scalars['String']>;
  /** IdCombination */
  id_combination?: Maybe<Scalars['String']>;
  /** IdManufacturer */
  id_manufacturer?: Maybe<Scalars['String']>;
  /** Price */
  price?: Maybe<Scalars['Float']>;
  /** Qty */
  qty?: Maybe<Scalars['Int']>;
  /** DateModification */
  date_modification: Scalars['DateTime'];
  /** Date */
  date: Scalars['DateTime'];
};

export type ShopsOrdersProductsItems = {
  __typename?: 'ShopsOrdersProductsItems';
  total: Scalars['Int'];
  items: Array<ShopsOrdersProducts>;
};
