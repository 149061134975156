
import {
  computed,
  defineComponent, onMounted, PropType, ref, Ref, SetupContext, watch,
} from 'vue';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import { Range } from '@/types/automated-scenarios';
import { useI18n } from 'vue-i18n';
import { asInt } from '@/helpers';
import { UserState } from '@/composables/User';
import { getCurrencySymbol } from '@/helpers/Number';

export default defineComponent({
  name: 'SegmentNumberBetween',

  components: {
    BaseInputNumber,
  },

  props: {
    modelValue: {
      type: Object as PropType<Range>,
      required: true,
    },

    suffix: {
      type: String,
      required: false,
      default: ' €',
    },

    min: {
      type: Number,
      required: false,
      default: 1,
    },

    max: {
      type: Number,
      required: false,
      default: 100000,
    },

    hasCurrency: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const { t, locale } = useI18n();
    const range: Ref<Range> = ref({
      min: ((props.modelValue && props.modelValue.min && asInt(props.modelValue.min)) ?? 1),
      max: ((props.modelValue && props.modelValue.max && asInt(props.modelValue.max)) ?? 100000),
    });

    const rulesMinMax = computed(() => ({
      left: {
        min: props.min,
        max: range.value.max ? (range.value.max - 1) : props.max,
      },
      right: {
        min: range.value.min + 1,
        max: props.max,
      },
    }));

    watch(range.value, () => {
      emit('update:modelValue', {
        min: range.value.min,
        max: range.value.max,
      });
    });

    const inputSuffix = ref(props.suffix);

    // emit event if user don't change default values
    onMounted(() => {
      emit('update:modelValue', range.value);
      if (props.hasCurrency) {
        inputSuffix.value = ` ${getCurrencySymbol(UserState.activeShop?.currency ?? 'EUR', locale.value)}`;
      }
    });

    return {
      t,
      range,
      rulesMinMax,
      inputSuffix,
    };
  },
});
