<template>
  <label
    v-if="title"
    class="block mb-2"
  >
    {{ t(title) }}
    <i
      v-if="tooltipDesc"
      v-tooltip="{ value: t(tooltipDesc), class: 'tooltip-icons-outside' }"
      class="fas fa-question-circle"
      data-html="true"
      data-toggle="tooltip"
      aria-hidden="true"
    />
  </label>
  <Dropdown
    v-model="val"
    class="p-0"
    :class="classes"
    :options="options"
    option-label="label"
    option-value="value"
    :placeholder="placeholder"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import { StringMap } from '@/types';

export default defineComponent({
  name: 'BaseDropdown2',

  components: {
    Dropdown,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: [Array, String, Number] as PropType<string[] | string | number>,
      required: true,
    },

    classes: {
      type: String,
      required: false,
      default: '',
    },

    options: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    tooltipDesc: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: string[] | string | number}, { emit }: SetupContext) {
    const { t } = useI18n();

    const val = ref(props.modelValue);

    watch([val], () => emit('update:modelValue', val.value));

    return {
      t,
      val,
    };
  },
});
</script>
