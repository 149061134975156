
import { defineComponent, PropType } from 'vue';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import {
  Cart,
} from '@/types/data-explorer-types';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CartItem',

  components: {
    SpmButton,
  },

  props: {
    cart: {
      type: Object as PropType<Cart>,
      required: true,
    },

    keyName: {
      type: String,
      required: true,
    }
  },

  setup (props) {
    const { t } = useI18n();
    const store = useStore();

    const openCartFile = () => {
      store.dispatch('dataExplorer/openCartFile', { idCart: props.cart.idCart, keyName: props.keyName });
    };

    return {
      t,

      formatDate,
      formatNumberToCurrency,
      openCartFile,
    };
  }
})
