<template>
  <ConfirmPopup />
  <Dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :draggable="false"
    :closable="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    style="width: 35vw"
    @update:visible="updateDialogVisibility"
  >
    <template #header>
      <div>
        <h3>{{ translation('storeParameters.storeInformations.languages.title') }}</h3>
      </div>
    </template>
    <Loader v-if="showLoader" />
    <div class="mt-2 flex justify-content-end">
      <Button
        v-if="false"
        @click="synchronize"
      >
        {{ translation('storeParameters.storeInformations.languages.synch') }}
      </Button>
      <Button
        class="ml-3"
        @click="openNewlangModal"
      >
        {{ translation('storeParameters.storeInformations.languages.addLang') }}
      </Button>
    </div>
    <div class="flex mt-2">
      <div class="col-12 col-fixed">
        <SpmTable
          :key="componentKey"
          v-model="tableData"
          :id-shop="idShop"
          name=""
          :paginator="false"
          :table-columns="tableColumns"
          index=""
        >
          <template #flag="columnData">
            <img
              :src="`/images/flags/${columnData.data.lang}.png`"
              alt="language-flag"
            >
          </template>
          <template #lang="columnData">
            {{ columnData.data.lang.toUpperCase() }}
          </template>
          <template #default="columnData">
            <div v-if="columnData.data.default == true">
              <i class="fa-solid fa-check green" />
            </div>
            <div v-else>
              <i class="fa-solid fa-xmark" />
            </div>
          </template>
          <template #actions="columnData">
            <i
              v-if="!columnData.data.default"
              class="fa-solid fa-trash-can"
              style="cursor: pointer; color: #ea553d;"
              aria-hidden="true"
              @click="(event)=>confirmDeletion(event, columnData.data.lang)"
            />
          </template>
        </SpmTable>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent, PropType, Ref, ref,
} from 'vue';
import Button from 'primevue/button';
import { SpmTableColumns, SpmTableState } from '@/types';
import { ShopsLang, ShopsLangUpdateInputItem } from '@/types/generated-types/graphql';
import SpmTable from '@/components/table/SpmTable.vue';
import Dialog from 'primevue/dialog';
import { deleteLanguage, synchronizeLanguages } from '@/composables/shop/MyShopParameters';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { showToastError, showToastSuccess } from '@/helpers';
import Loader from '@/components/layout/Loader.vue';

export default defineComponent({
  name: 'ManageLanguages',

  components: {
    Loader,
    Button,
    SpmTable,
    Dialog,
    ConfirmPopup,
  },

  props: {
    translation: {
      type: Function,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    data: {
      type: Array as PropType<ShopsLang[]>,
      required: true,
    },
  },

  emits: [
    'close-action-modal',
    'reload-data',
    'open-new-lang',
  ],

  setup(props, context) {
    const dialogVisible = ref(true);
    const needsToBeReloaded = ref(false);
    const componentKey = ref(0);
    const manageLangData = ref<ShopsLang[]>(props.data);
    const confirm = useConfirm();
    const tableColumns: SpmTableColumns[] = [
      {
        field: 'flag',
        header: props.translation('storeParameters.storeInformations.languages.lang'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:25%',
        type: 'custom',
      },
      {
        field: 'lang',
        header: props.translation('storeParameters.storeInformations.languages.codeIso'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:25%',
        type: 'text',
      },
      {
        field: 'default',
        header: props.translation('storeParameters.storeInformations.languages.default'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:25%',
        type: 'custom',
      },
      {
        field: 'actions',
        header: props.translation('storeParameters.storeInformations.languages.actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:25%',
        type: 'custom',
      },
    ];
    const toast = useToast();
    const showLoader = ref(false);

    manageLangData.value.sort((first, second) => {
      if (first.default) {
        return -1;
      }
      if (second.default) {
        return 1;
      }
      return 0;
    });

    const tableData: Ref<SpmTableState | null> = ref({
      items: manageLangData.value,
      isLoading: false,
      total: manageLangData.value.length,
      error: '',
    });

    const removeLangFromData = (lang: string) => {
      manageLangData.value = manageLangData.value.filter((record: ShopsLang) => record.lang !== lang);
      if (manageLangData.value) {
        tableData.value = {
          items: manageLangData.value,
          isLoading: false,
          total: props.data.length,
          error: '',
        };
      }
    };

    const deleteLang = async (value: any) => {
      const shopsLang: ShopsLangUpdateInputItem = {
        id_shop: props.idShop,
        lang: value,
      };
      const result = await deleteLanguage([shopsLang]);
      if (result) {
        needsToBeReloaded.value = true;
        componentKey.value += 1;
        removeLangFromData(value);
        context.emit('reload-data');
      }
    };

    const confirmDeletion = (event: any, lang: string) => {
      confirm.require({
        target: event.currentTarget,
        message: props.translation('storeParameters.storeInformations.languages.deleteModal.message'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          showLoader.value = true;
          deleteLang(lang).then(async (response) => {
            showLoader.value = false;
            await showToastSuccess(props.translation('storeParameters.storeInformations.languages.deleteModal.success'));
          }).catch(async (error) => {
            await showToastError(props.translation('storeParameters.storeInformations.languages.deleteModal.error'));
          });
        },
      });
    };

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', needsToBeReloaded.value);
    };

    const openNewlangModal = () => {
      dialogVisible.value = false;
      context.emit('open-new-lang');
    };

    const synchronize = async () => {
      const result = await synchronizeLanguages(props.idShop);
      if (result === 'true') {
        toast.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'Deletion complete',
          life: 3000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occured, please try again later',
          life: 3000,
        });
      }
    };

    return {
      updateDialogVisibility, dialogVisible, tableColumns, tableData, openNewlangModal, componentKey, synchronize, confirmDeletion, showLoader,
    };
  },
});
</script>

<style scoped>

</style>
