<template>
  <router-view />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AutomatedScenarioMain',
});
</script>

<style scoped>

</style>
