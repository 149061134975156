<template>
  <div
    class="section"
  >
    <Button
      class="section-overlay section-insert p-button-success"
      icon="far fa-check"
      label="Insérer cette section"
      @click="handleSelection(item)"
    />
    <Button
      v-if="item.type !== 'empty_section'"
      class="section-overlay section-delete p-button-danger"
      icon="far fa-fw fa-trash"
      @click="(e) => handleDeletion(item, e)"
    />
    <div
      class="section-name"
    >
      {{ item.name }}
    </div>
    <div
      class="section-image"
    >
      <ProgressSpinner
        style="width: 25px; height: 25px"
        stroke-width="8"
        fill="var(--surface-ground)"
        animation-duration="1.0s"
        aria-label="Custom ProgressSpinner"
        :class="{ block: loadingPreview, hidden: !loadingPreview }"
      />
      <img
        :src="backgroundImageUrl"
        :key="backgroundImageUrl"
        :class="{ block: !loadingPreview, hidden: loadingPreview }"
        @load="onImageLoaded"
        @error="onImageError"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  SetupContext,
  watch,
  ref,
  onMounted,
} from 'vue';

import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

import { MEDIA_URL_PART_THUMB_GENERATION } from '@/components/template-builder/utils/constants';

import {
  TemplateEditorState,
  removeItemFromSectionsToRefresh,
} from '@/composables/template-editor/TemplateEditor';

import { generateSha1Hash } from '@/helpers';

import {
  TemplatePart,
} from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LoadSectionPreview',

  components: {
    Button,
    ProgressSpinner,
  },

  props: {
    item: {
      type: Object as PropType<TemplatePart>,
      required: true,
    },
  },

  emits: ['handle-selection', 'handle-deletion'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const getBackgoundImageUrl = () => {
      return props.item.type === 'empty_section'
      ? '/images/template-builder/new_empty_section.png'
        : `${MEDIA_URL_PART_THUMB_GENERATION.replace('{SHA1_KEY}', generateSha1Hash(props.item.id_template_parts))}&t=${new Date().getTime()}`
    };

    const backgroundImageUrl = ref(getBackgoundImageUrl());

    const maxRetries = ref(30);
    const retryCount = ref(0);
    const loadingPreview = ref(false);

    const reRenderPreview = () => {
      backgroundImageUrl.value = getBackgoundImageUrl();
    };

    const onImageLoaded = () => {
      loadingPreview.value = false;
    };

    const onImageError = () => {
      loadingPreview.value = true;
      // Retry loading image
      if (retryCount.value < maxRetries.value) {
        retryCount.value++;
        setTimeout(() => {
          reRenderPreview();
        }, 1000);
      } else {
        loadingPreview.value = false;
      }
    };

    const handleSelection = async (item: TemplatePart) => {
      emit('handle-selection', item);
    };

    const handleDeletion = (item: TemplatePart, event: any) => {
      emit('handle-deletion', item, event);
    };

    const checkRefreshPreview = () => {
      if (TemplateEditorState.sectionsToRefresh.find((section) => section === props.item.id_template_parts)) {
        loadingPreview.value = true;
        setTimeout(() => {
          reRenderPreview();
          removeItemFromSectionsToRefresh(props.item.id_template_parts);
        }, 1000);
      }
    };

    watch(() => TemplateEditorState.sectionsToRefresh, () => {
      checkRefreshPreview();
    }, { deep: true });

    onMounted(() => {
      checkRefreshPreview();
    });

    return {
      t,
      backgroundImageUrl,
      loadingPreview,

      handleSelection,
      handleDeletion,
      onImageLoaded,
      onImageError,
    };
  },
});
</script>

<style lang="scss" scoped>
.section {
  border: $template-builder-panels-sections-section-border;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    .section-overlay {
      display: block;
    }
  }

  .section-overlay {
    display: none;
    position: absolute;

    &.section-insert {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      font-size: 0.875rem;
      padding: 0.25rem 0.875rem;
    }

    &.section-delete {
      top: 5px;
      right: 5px;
      font-size: 0.875rem;
      padding: 0.3rem 0.3rem;
      width: auto;

      :deep() .p-button-label {
        display: none;
      }
    }
  }

  .section-name {
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;
    padding: 5px 0px;
  }

  .section-image {
    width: 100%;
    height: 150px;
    overflow: hidden;
    justify-content: center;
    align-items: flex-start;
    display: flex;

    img {
      height: auto;
      width: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
</style>
