
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Dialog from 'primevue/dialog';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

export default defineComponent({
  name: 'ReloadNotice',

  components: {
    Dialog,
    SpmButton,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const displayModal = computed(() => props.displayDialog);
    const reloadPage = () => {
      window.location.reload();
    };

    return {
      t,
      displayModal,
      reloadPage,
    };
  },
});
