// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, ShopsListsMin,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import {
  ShopsListsInputItem, ShopsListsUpdateInputItem,
} from '@/types/generated-types/graphql';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { DeleteShopsLists, InsertShopsLists } from '@/composables/shop/ShopsLists';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface ActionListData {
  id_list: ShopsListsMin[];
  multiplebox_type: number; // 0: ajouter, 1: retirer
}

const ActionListMetadata: ComponentMetadata<ActionListData> = {
  Meta: {
    kind: 'action',
    id: 'boxlist',
    label: 'Ajout/Suppression liste',
    icon: 'fa-list',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching',
        ],
      },
      bulk: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxchoosebulklist',
        ],
      },
    },
    component: 'ActionList',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create({
    id_list,
    multiplebox_type,
  }: ActionListData = {
    id_list: [],
    multiplebox_type: 0,
  }): ComponentData<ActionListData> {
    return {
      id: 'boxlist',
      form_name: 'boxlist',
      logo_box: 'fa-list',
      id_list,
      multiplebox_type,
    };
  },
  async Validate(data: ActionListData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      id_list: {
        required,
      },
      multiplebox_type: {
        required,
        includesValuesValidator: includesValuesValidator([0, 1]),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: ActionListData): number {
    return 1;
  },
  Label(data: ActionListData): string {
    return 'Ajout/Suppression liste';
  },
  BeforeInsertData(data: ActionListData): ActionListData {
    let dataToReturn: ActionListData = data;
    if (data.id_list.length > 0) {
      const formatData = data.id_list.reduce((accumulator: any, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        accumulator[currentValue.id_shop_list] = currentValue.name;
        return accumulator;
      }, {});
      dataToReturn = {
        ...dataToReturn,
        id_list: formatData,
      };
    }
    return dataToReturn;
  },
};

export default ActionListMetadata;
