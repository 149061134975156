
import {
  defineComponent,
  computed,
  ref,
} from 'vue';

import Card from 'primevue/card';
import Chip from 'primevue/chip';

import DataExplorerLink from '@/components/data-explorer/fields/DataExplorerLink.vue';
import ProductItem from '@/components/data-explorer/fields/ProductItem.vue';
import List from '@/components/data-explorer/fields/List.vue';
import SectionTitle from '@/components/data-explorer/fields/SectionTitle.vue';

import {
  getCartProducts,
} from '@/composables/data-explorer/DataExplorer';

import { formatDate } from '@/helpers/Date';
import { formatNumberToCurrency } from '@/helpers/Number';

import { DataExplorerDataType } from '@/types';

import { CartProduct } from '@/types/data-explorer-types';

import { useStore } from '@/store';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CartFile',

  components: {
    Card,
    Chip,
    DataExplorerLink,
    List,
    SectionTitle,
    ProductItem,
  },

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();

    const selectedData = computed(() => store.getters['dataExplorer/getSelectedData']);

    const title = computed(() => {
      if (selectedData.value) {
        return t(`dataExplorer.tabs.${selectedData.value.dataType}`, [selectedData.value.idCart]);
      }
      return '';
    });

    const vouchers = computed(() => {
      if (selectedData.value.voucher) {
        return selectedData.value.voucher.split(',');
      }
      return [];
    });

    const dataExplorerType = ref(DataExplorerDataType);

    const handleRetrieveProducts = (cartProducts: CartProduct[]) => {
      store.dispatch(
        'dataExplorer/setAdditionnalData',
        {
          dataId: selectedData.value.dataId,
          newLists: cartProducts,
          dataKey: 'products',
        },
      );
    };

    return {
      t,
      locale,
      title,
      selectedData,
      vouchers,
      dataExplorerType,

      formatDate,
      formatNumberToCurrency,
      getCartProducts,
      handleRetrieveProducts,
    };
  },
});
