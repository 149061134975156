import { ServiceParameter } from '@/types/store-parameters-types';
import { GleapChecklistEvent } from '@/types/enums';

const services: ServiceParameter[] = [
  {
    name: 'important_links',
    title: 'storeParameters.services.importantLinksServiceParameter.title',
    subtitle: 'storeParameters.services.importantLinksServiceParameter.subtitle',
    component: 'ImportantLinksServiceParameter',
    icon: 'fal fa-link',
    modalWidth: '50rem',
  },
  {
    name: 'email',
    title: 'storeParameters.services.emailServiceParameter.title',
    subtitle: 'storeParameters.services.emailServiceParameter.subtitle',
    component: 'EmailServiceParameter',
    icon: 'fal fa-envelope',
    modalWidth: '100rem',
  },
  {
    name: 'social_network',
    title: 'storeParameters.services.socialNetworkServiceParameter.title',
    subtitle: 'storeParameters.services.socialNetworkServiceParameter.subtitle',
    component: 'SocialNetworkServiceParameter',
    icon: 'fal fa-share-nodes',
    modalWidth: '75rem',
  },
  {
    name: 'sms',
    title: 'storeParameters.services.smsServiceParameter.title',
    subtitle: 'storeParameters.services.smsServiceParameter.subtitle',
    component: 'SmsServiceParameter',
    icon: 'fal fa-message-sms',
    modalWidth: '100rem',
  },
  {
    name: 'facebook',
    title: 'storeParameters.services.facebookServiceParameter.title',
    subtitle: 'storeParameters.services.facebookServiceParameter.subtitle',
    component: 'FacebookServiceParameter',
    icon: 'fa-brands fa-facebook-f',
    modalWidth: '50rem',
  },
  {
    name: 'push_notification',
    title: 'storeParameters.services.pushNotificationServiceParameter.title',
    subtitle: 'storeParameters.services.pushNotificationServiceParameter.subtitle',
    component: 'PushNotificationServiceParameter',
    icon: 'fal fa-bell',
    modalWidth: '100rem',
  },
  {
    name: 'vouchers',
    title: 'storeParameters.services.voucherServiceParameter.title',
    subtitle: 'storeParameters.services.voucherServiceParameter.subtitle',
    component: 'VoucherServiceParameter',
    icon: 'fal fa-ticket',
    modalWidth: '50rem',
  },
  {
    name: 'ai_setting',
    title: 'storeParameters.services.aiServiceParameter.title',
    subtitle: 'storeParameters.services.aiServiceParameter.subtitle',
    component: 'AIServiceParameter',
    icon: 'fa-light fa-microchip-ai',
    modalWidth: '50rem',
  },
];

export const servicesRequiredFieldsPerActions: Record<string, any> = {
  boxsendemail: {
    shopRequiredDataEmail_senderEmail: {},
    shopRequiredDataEmail_senderName: {},
    shopRequiredDataRgpd_dataManagerEmail: {},
  },
  boxsendsms: {
    shopRequiredDataSms_senderName: {},
  },
  boxsendpushnotification: {
    shopRequiredDataPushNotification_textNotif: {},
    shopRequiredDataPushNotification_fullTextButtonAccept: {},
    shopRequiredDataPushNotification_fullTextButtonRefuse: {},
  },
  boxsendfbmessenger: {
    fbLoggedUser: '',
    fbLinkedPage: '',
  },
  createScenarioCampaign: {
    shopRequiredDataRgpd_privacyPolicyUrl: {},
  },
  editScenarioCampaign: {
    shopRequiredDataRgpd_privacyPolicyUrl: {},
  },
};

export const servicesPerActions: Record<string, any> = {
  boxsendemail: ['EmailServiceParameter', 'SocialNetworkServiceParameter'],
  boxsendsms: ['SmsServiceParameter'],
  boxsendpushnotification: ['PushNotificationServiceParameter'],
  boxsendfbmessenger: ['FacebookServiceParameter'],
  createScenarioCampaign: ['ImportantLinksServiceParameter'],
  editScenarioCampaign: ['ImportantLinksServiceParameter'],
};

export const getServicesToVerify = (meta: any, operators: any) => {
  const actionIds = ['boxsendemail', 'boxsendsms', 'boxsendpushnotification'];
  const servicesToVerify = new Set<string>();
  servicesToVerify.add(meta.Meta.id);
  if (Array.isArray(operators)) {
    operators.map((operator) => operator.id).filter((operator) => actionIds.includes(operator)).forEach((operator) => {
      servicesToVerify.add(operator);
    });
  }

  return Array.from(servicesToVerify);
};

export default services;

export const servicesPerGleapChecklist: Record<string, string> = {
  ImportantLinksServiceParameter: GleapChecklistEvent.GLEAP_CHECKLIST_IMPORTANT_LINKS_SERVICE_CONFIGURED,
  EmailServiceParameter: GleapChecklistEvent.GLEAP_CHECKLIST_EMAIL_SERVICE_CONFIGURED,
  SmsServiceParameter: GleapChecklistEvent.GLEAP_CHECKLIST_SMS_SERVICE_CONFIGURED,
  PushNotificationServiceParameter: GleapChecklistEvent.GLEAP_CHECKLIST_PUSH_NOTIFICATION_SERVICE_CONFIGURED,
  VoucherServiceParameter: GleapChecklistEvent.GLEAP_CHECKLIST_VOUCHER_SERVICE_CONFIGURED,
  SocialNetworkServiceParameter: GleapChecklistEvent.GLEAP_CHECKLIST_SOCIAL_NETWORK_SERVICE_CONFIGURED,
};
