
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { findPermission, UserState } from '@/composables/User';
import Synchronization from '@/components/header/Synchronization.vue';
import ShopsSyncsInProgress from '@/components/header/ShopsSyncsInProgress.vue';
import { ListResult } from '@/composables/GraphQL';
import { ShopsSyncs } from '@/types/generated-types/graphql';
import ShopsSyncsList from '@/composables/shop/ShopSyncs';
import { SpmTableColumns } from '@/types';
import Button from 'primevue/button';
import { getShopCustomerLanguages } from '@/types/country-language-options';
import { FilterMatchMode } from 'primevue/api';
import { getUserLocale } from '@/composables/user/Users';

export default defineComponent({
  name: 'Products',
  components: {
    Synchronization,
    SpmTable,
    ShopsSyncsInProgress,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const synchroData = ref<ListResult<ShopsSyncs>>();
    const idShop = UserState.activeShop?.id ?? 0;
    const launchSynchro = ref(false);
    const userLocale = ref();
    const idUser = UserState.user?.id;
    const userType = UserState.user?.userType;
    const amtMatchModes = [{ value: FilterMatchMode.EQUALS, label: t('filters.equals') },
      { value: FilterMatchMode.NOT_EQUALS, label: t('filters.notEquals') },
      { value: FilterMatchMode.GREATER_THAN, label: t('filters.greaterThan') },
      { value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO, label: t('filters.greaterThanOrEquals') },
      { value: FilterMatchMode.LESS_THAN, label: t('filters.lessThan') },
      { value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO, label: t('filters.lessThanOrEquals') },
    ];

    const formatAmount = (amount: number, currency: string) => amount.toLocaleString(userLocale.value, {
      style: 'currency',
      currency,
    });

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_product',
        header: t('myShopData.products.headers.date_modification'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date_modification',
        header: t('myShopData.products.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'id_product',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
      },
      {
        field: 'reference',
        header: t('myShopData.products.headers.reference'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
      },
      {
        field: 'name',
        header: t('myShopData.products.headers.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'link',
        header: t('myShopData.products.headers.link'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:5%;text-align:center',
        type: 'text',
      },
      {
        field: 'image_link',
        header: t('myShopData.products.headers.image_link'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:100px',
        type: 'text',
      },
      {
        field: 'price',
        header: t('myShopData.products.headers.price'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
        matchModes: amtMatchModes,
      },
      {
        field: 'price_discount',
        header: t('myShopData.products.headers.price_discount'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:10%',
        type: 'text',
        matchModes: amtMatchModes,
      },
      {
        field: 'lang',
        header: t('myShopData.products.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:5%',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'active',
        header: t('myShopData.products.headers.active'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:5%',
        type: 'active-status-icon',
        filterSettings: {
          type: 'multiSelect',
          options: [{ value: 1, label: t('active') }, { value: 0, label: t('inactive') }],
          hideFilterMenu: true,
          showFilterInput: false,
        },
      },
      {
        field: 'currency',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
    ];

    onMounted(async () => {
      synchroData.value = await ShopsSyncsList('products,all', idShop, 'user');

      userLocale.value = getUserLocale(idUser, userType);
    });

    const synchroAction = (launch: boolean) => {
      launchSynchro.value = launch;
    };

    return {
      t,
      columns,
      findPermission,
      synchroData,
      idShop,
      launchSynchro,
      synchroAction,
      formatAmount,
    };
  },
});
