
import {
  defineComponent, onBeforeMount,
  ref,
} from 'vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Tooltip from 'primevue/tooltip';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import useVuelidate from '@vuelidate/core';
import { maxLength, minLength, required } from '@vuelidate/validators';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import { useI18n } from 'vue-i18n';
import categories from '@/configs/shop-categories';
import currencies from '@/configs/currencies';
import fetchShopInformation, { updateShop } from '@/composables/shop/Shops';
import { Me, UserState } from '@/composables/User';
import { fetchShopConfigurations } from '@/composables/shop/MyShopParameters';
import updateShopsLang from '@/composables/shop/ShopsLang';
import { showToastError } from '@/helpers';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';
import { useStore } from '@/store';

interface StoreInfoState {
  name: string;
  shopCategory: any;
  currency: string;
  defaultLang: any;
  privacyPolicyUrl: any;
  dataManagerEmail: any;
}

export default defineComponent({
  name: 'ConfigureShop',

  components: {
    InputText,
    Dropdown,
    SpmButton,
    FieldErrors,
    LocalizedTextInput,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['next'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const isLoading = ref(false);
    const formValidationErrors = ref({});
    const componentFieldErrorsKey = ref(0);

    const shopLanguages: any = ref([]);

    const field = ref<StoreInfoState>({
      name: '',
      shopCategory: null,
      currency: '',
      defaultLang: null,
      privacyPolicyUrl: {},
      dataManagerEmail: {},
    });

    const validateShopInfo = async (): Promise<ErrorConfigForm> => {
      const rules = {
        name: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(25),
        },

        shopCategory: {
          required,
        },

        currency: {
          required,
        },

        defaultLang: {
          required,
        },

        privacyPolicyUrl: {
          localizedTextInputValidator: localizedTextInputValidator('url'),
        },

        dataManagerEmail: {
          localizedTextInputValidator: localizedTextInputValidator('email'),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleNext = async () => {
      isLoading.value = true;
      const validation = await validateShopInfo();
      if (!validation.success) {
        formValidationErrors.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        isLoading.value = false;
      } else {
        updateShop([{
          id_shop: UserState.activeShop?.id ?? 0,
          name: field.value.name,
          id_category_shop: field.value.shopCategory,
          currency: field.value.currency,
        }])
          .then(() => {
            updateShopsLang(
              shopLanguages.value.map((item: any) => ({
                id_shop: UserState.activeShop?.id ?? 0,
                lang: item.value,
                default: item.value === field.value.defaultLang,
              })),
            ).then(() => {
              const configToSave = Object.keys(field.value.privacyPolicyUrl).map((key) => ({
                key: 'shopRequiredDataRgpd_privacyPolicyUrl',
                lang: key,
                value: field.value.privacyPolicyUrl[key],
              })).concat(Object.keys(field.value.dataManagerEmail).map((key) => ({
                key: 'shopRequiredDataRgpd_dataManagerEmail',
                lang: key,
                value: field.value.dataManagerEmail[key],
              })));

              SaveShopsConfiguration({
                id_shop: UserState.activeShop?.id ?? 0,
                configs: configToSave,
              }).then(async () => {
                await saveShopParamsOnRedis(UserState.activeShop?.id ?? 0);
                isLoading.value = false;
                emit('next');
              }).catch(async () => {
                isLoading.value = false;
                await showToastError('GENERIC_ERROR');
              });
            })
              .catch(async () => {
                isLoading.value = false;
                await showToastError('GENERIC_ERROR');
              });
          })
          .catch(async (err: any) => {
            isLoading.value = false;
            await showToastError('GENERIC_ERROR');
          });
      }
    };

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      await Me(false);
      const shopsInfosData = await fetchShopInformation({ shopId: UserState.activeShop?.id ?? 0 });
      if (shopsInfosData) {
        shopLanguages.value = shopsInfosData.langs.filter((item) => !item.default).map((item) => ({
          label: item.lang,
          value: item.lang,
        }));
        shopLanguages.value.unshift({
          label: shopsInfosData.defaultLang ?? 'fr',
          value: shopsInfosData.defaultLang ?? 'fr',
        });

        const shopsRgpdConfiguration = await fetchShopConfigurations({ shopId: UserState.activeShop?.id ?? 0 }, 'shopRequiredDataRgpd');
        let privacyPolicyUrl = shopsRgpdConfiguration?.filter((item) => item.key === 'shopRequiredDataRgpd_privacyPolicyUrl');
        privacyPolicyUrl = privacyPolicyUrl?.length ? privacyPolicyUrl.reduce((acc: any, current: any) => {
          acc[current.lang] = current.value;
          return acc;
        }, {}) : '';
        let dataManagerEmail = shopsRgpdConfiguration?.filter((item) => item.key === 'shopRequiredDataRgpd_dataManagerEmail');
        dataManagerEmail = dataManagerEmail?.length ? dataManagerEmail.reduce((acc: any, current: any) => {
          acc[current.lang] = current.value;
          return acc;
        }, {}) : '';

        field.value = {
          name: shopsInfosData.name,
          shopCategory: shopsInfosData.idCategoryShop,
          currency: shopsInfosData.currency,
          defaultLang: shopsInfosData.defaultLang ?? 'fr',
          privacyPolicyUrl,
          dataManagerEmail,
        };
      }
      store.commit('general/hideTheSpinner');
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      categories,
      shopLanguages,
      currencies,
      field,
      handleNext,
      isLoading,
    };
  },

});
