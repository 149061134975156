<template>
  <div class="textarea-variables-block">
    <div class="flex flex-column gap-2">
      <label v-if="translation">
        {{ getTranslationLabel(t, translation) }}
      </label>
      <div class="p-fluid">
        <Editor
          ref="editorRef"
          v-model="inputValue"
          editor-style="height: 320px"
          :modules="quillModules"
          @text-change="handleValueChange"
          @editor-change="handleValueChange"
          @load="handleEditorLoaded"
        >
          <template #toolbar>
            <span class="ql-formats">
              <select class="ql-size">
                <option value="10px">Small</option>
                <option selected>Normal</option>
                <option value="18px">Large</option>
                <option value="32px">Huge</option>
              </select>
            </span>
            <span class="ql-formats">
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <EmojisButton @on-choose-emoji="insertContent" />
              <VariablesButton
                :variables-list="variablesList"
                placeholder="T"
                @on-choose-variable="insertContent"
              />
            </span>
            <span class="ql-formats">
              <span
                class="ql-custom-button"
                @click="toggleColorPicker"
              >
                <span
                  class="qt-custom-button-icon"
                >
                  <svg viewBox="0 0 18 18">
                    <line
                      class="ql-color-label ql-stroke ql-transparent"
                      x1="3"
                      x2="15"
                      y1="15"
                      y2="15"
                    />
                    <polyline
                      class="ql-stroke"
                      points="5.5 11 9 3 12.5 11"
                    />
                    <line
                      class="ql-stroke"
                      x1="11.63"
                      x2="6.38"
                      y1="9"
                      y2="9"
                    />
                  </svg>
                </span>
              </span>
              <span
                class="ql-custom-button"
                @click="toggleColorPickerBackground"
              >
                <span
                  class="qt-custom-button-icon"
                >
                  <svg viewBox="0 0 18 18">
                    <g class="ql-fill ql-color-label"> <polygon points="6 6.868 6 6 5 6 5 7 5.942 7 6 6.868" /> <rect
                      height="1"
                      width="1"
                      x="4"
                      y="4"
                    /> <polygon points="6.817 5 6 5 6 6 6.38 6 6.817 5" /> <rect
                      height="1"
                      width="1"
                      x="2"
                      y="6"
                    /> <rect
                      height="1"
                      width="1"
                      x="3"
                      y="5"
                    /> <rect
                      height="1"
                      width="1"
                      x="4"
                      y="7"
                    /> <polygon points="4 11.439 4 11 3 11 3 12 3.755 12 4 11.439" /> <rect
                      height="1"
                      width="1"
                      x="2"
                      y="12"
                    /> <rect
                      height="1"
                      width="1"
                      x="2"
                      y="9"
                    /> <rect
                      height="1"
                      width="1"
                      x="2"
                      y="15"
                    /> <polygon points="4.63 10 4 10 4 11 4.192 11 4.63 10" /> <rect
                      height="1"
                      width="1"
                      x="3"
                      y="8"
                    /> <path d="M10.832,4.2L11,4.582V4H10.708A1.948,1.948,0,0,1,10.832,4.2Z" /> <path d="M7,4.582L7.168,4.2A1.929,1.929,0,0,1,7.292,4H7V4.582Z" /> <path d="M8,13H7.683l-0.351.8a1.933,1.933,0,0,1-.124.2H8V13Z" /> <rect
                      height="1"
                      width="1"
                      x="12"
                      y="2"
                    /> <rect
                      height="1"
                      width="1"
                      x="11"
                      y="3"
                    /> <path d="M9,3H8V3.282A1.985,1.985,0,0,1,9,3Z" /> <rect
                      height="1"
                      width="1"
                      x="2"
                      y="3"
                    /> <rect
                      height="1"
                      width="1"
                      x="6"
                      y="2"
                    /> <rect
                      height="1"
                      width="1"
                      x="3"
                      y="2"
                    /> <rect
                      height="1"
                      width="1"
                      x="5"
                      y="3"
                    /> <rect
                      height="1"
                      width="1"
                      x="9"
                      y="2"
                    /> <rect
                      height="1"
                      width="1"
                      x="15"
                      y="14"
                    /> <polygon points="13.447 10.174 13.469 10.225 13.472 10.232 13.808 11 14 11 14 10 13.37 10 13.447 10.174" /> <rect
                      height="1"
                      width="1"
                      x="13"
                      y="7"
                    /> <rect
                      height="1"
                      width="1"
                      x="15"
                      y="5"
                    /> <rect
                      height="1"
                      width="1"
                      x="14"
                      y="6"
                    /> <rect
                      height="1"
                      width="1"
                      x="15"
                      y="8"
                    /> <rect
                      height="1"
                      width="1"
                      x="14"
                      y="9"
                    /> <path d="M3.775,14H3v1H4V14.314A1.97,1.97,0,0,1,3.775,14Z" /> <rect
                      height="1"
                      width="1"
                      x="14"
                      y="3"
                    /> <polygon points="12 6.868 12 6 11.62 6 12 6.868" /> <rect
                      height="1"
                      width="1"
                      x="15"
                      y="2"
                    /> <rect
                      height="1"
                      width="1"
                      x="12"
                      y="5"
                    /> <rect
                      height="1"
                      width="1"
                      x="13"
                      y="4"
                    /> <polygon points="12.933 9 13 9 13 8 12.495 8 12.933 9" /> <rect
                      height="1"
                      width="1"
                      x="9"
                      y="14"
                    /> <rect
                      height="1"
                      width="1"
                      x="8"
                      y="15"
                    /> <path d="M6,14.926V15H7V14.316A1.993,1.993,0,0,1,6,14.926Z" /> <rect
                      height="1"
                      width="1"
                      x="5"
                      y="15"
                    /> <path d="M10.668,13.8L10.317,13H10v1h0.792A1.947,1.947,0,0,1,10.668,13.8Z" /> <rect
                      height="1"
                      width="1"
                      x="11"
                      y="15"
                    /> <path d="M14.332,12.2a1.99,1.99,0,0,1,.166.8H15V12H14.245Z" /> <rect
                      height="1"
                      width="1"
                      x="14"
                      y="15"
                    /> <rect
                      height="1"
                      width="1"
                      x="15"
                      y="11"
                    /> </g> <polyline
                      class="ql-stroke"
                      points="5.5 13 9 5 12.5 13"
                    /> <line
                      class="ql-stroke"
                      x1="11.63"
                      x2="6.38"
                      y1="11"
                      y2="11"
                    /> </svg>
                </span>
              </span>
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                value="ordered"
              />
              <button
                class="ql-list"
                value="bullet"
              />
              <select class="ql-align" />
            </span>
            <span class="ql-formats">
              <button class="ql-link" />
              <button class="ql-image" />
              <button class="ql-code-block" />
            </span>
          </template>
        </Editor>
        <OverlayPanel
          ref="overlayPanelRef"
        >
          <CustomColorPicker
            data-test-id="color-picker"
            color="#000000"
            :color-format="colorFormat"
            title="Color Picker"
            @color-changed="changeColor"
          />
        </OverlayPanel>
        <OverlayPanel
          ref="overlayPanelBackgroundRef"
        >
          <CustomColorPicker
            color="#000000"
            :color-format="colorFormat"
            title="Color Picker"
            @color-changed="changeColorBackground"
          />
        </OverlayPanel>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line max-classes-per-file
import {
  defineComponent,
  onMounted,
  onUpdated,
  PropType,
  Ref,
  ref,
  SetupContext,
} from 'vue';

import { useI18n } from 'vue-i18n';

import Editor from 'primevue/editor';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import Quill from 'quill';
import CustomColorPicker from '@/components/fields/partials/CustomColorPicker.vue';
import OverlayPanel from 'primevue/overlaypanel';

import { ColorFormatEnum, Translation } from '@/types';

// eslint-disable-next-line import/no-cycle
import {
  getTranslationLabel,
} from '@/components/template-builder/utils/helpers';

import { TemplateEditorState as state } from '@/composables/template-editor/TemplateEditor';
import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseWysiwygEditor',
  components: {
    OverlayPanel,
    CustomColorPicker,
    VariablesButton,
    EmojisButton,
    Editor,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },

  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    // this part is mandatory to use inline styles instead of Quill classes (problems could occur if we use classes in emails)
    const DirectionAttribute = Quill.import('attributors/attribute/direction');
    Quill.register(DirectionAttribute, true);
    const AlignStyle = Quill.import('attributors/style/align');
    Quill.register(AlignStyle, true);
    const BackgroundStyle = Quill.import('attributors/style/background');
    Quill.register(BackgroundStyle, true);
    const ColorStyle = Quill.import('attributors/style/color');
    Quill.register(ColorStyle, true);
    const DirectionStyle = Quill.import('attributors/style/direction');
    Quill.register(DirectionStyle, true);
    const FontStyle = Quill.import('attributors/style/font');
    Quill.register(FontStyle, true);
    const SizeStyle = Quill.import('attributors/style/size');
    Quill.register(SizeStyle, true);

    // Prevent Quill from updating <br> to ''
    const Parchment = Quill.import('parchment');
    const Break = Quill.import('blots/break');
    class FixedBreak extends Break {
      insertInto(parent: any, reference: any) {
        Parchment.Embed.prototype.insertInto.call(this, parent, reference);
      }

      // eslint-disable-next-line class-methods-use-this
      length() {
        return 1;
      }

      // eslint-disable-next-line class-methods-use-this
      value() {
        return '\n';
      }
    }
    Quill.register(FixedBreak, true);

    // Keep all attributes in block element like "p"
    const Block = Quill.import('blots/block');
    class FixedBlock extends Block {
      static create(value: any) {
        const node = super.create(value);
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in value) {
          node.setAttribute(key, value[key]);
        }
        return node;
      }

      static formats(domNode: any) {
        const attributes: Record<string, any> = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const attr of domNode.attributes) {
          attributes[attr.name] = attr.value;
        }
        return attributes;
      }
    }
    FixedBlock.allowedChildren.push(FixedBreak);

    FixedBlock.blotName = 'fixed_block';
    Quill.register(FixedBlock, true);

    // Added to avoid blot.domNode[Registry.DATA_KEY] error. See https://github.com/slab/quill/issues/4224. The bug occurs when the widget editing quill is launched before the translation panel.
    if (state.template?.informations.imported) {
      // Keep all attributes in inline element like "a"
      const Inline = Quill.import('blots/inline');

      class FixedInline extends Inline {
        static create(value: any) {
          const node = super.create(value);
          // eslint-disable-next-line no-restricted-syntax, guard-for-in
          for (const key in value) {
            node.setAttribute(key, value[key]);
          }
          return node;
        }

        static formats(domNode: any) {
          const attributes: Record<string, any> = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const attr of domNode.attributes) {
            attributes[attr.name] = attr.value;
          }
          return attributes;
        }
      }

      FixedInline.blotName = 'fixed_inline';
      Quill.register(FixedInline, true);
    }
    const inputValue: Ref<string> = ref('');
    const editorRef = ref();
    const overlayPanelRef = ref();
    const overlayPanelBackgroundRef = ref();
    const colorFormat: Ref<string> = ref(ColorFormatEnum.HEX);

    const handleValueChange = () => {
      inputValue.value = editorRef.value.quill.root.innerHTML;
      emit('update:modelValue', inputValue.value);
    };

    const insertContent = (value: string | number | undefined) => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.insertText(editorRef.value.quill.selection.savedRange.index, value);
        handleValueChange();
      }
    };

    const toggleColorPicker = (event: MouseEvent) => {
      overlayPanelRef.value.toggle(event);
    };

    const toggleColorPickerBackground = (event: MouseEvent) => {
      overlayPanelBackgroundRef.value.toggle(event);
    };

    const changeColor = (color: string) => {
      editorRef.value.quill.format('color', color);
      handleValueChange();
    };

    const changeColorBackground = (color: string) => {
      editorRef.value.quill.format('background-color', color);
      handleValueChange();
    };

    onMounted(() => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.disable();
      }

      inputValue.value = props.modelValue;

      // Avoids having an empty history point
      const checkInterval = window.setInterval(() => {
        if (editorRef.value && editorRef.value.quill) {
          editorRef.value.quill.history.clear();
          window.clearInterval(checkInterval);
        }
      }, 200);
    });

    onUpdated(() => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.enable();
      }
    });

    const Delta = Quill.import('delta');
    const lineBreakMatcher = () => {
      const newDelta = new Delta();
      newDelta.insert({ break: '' });
      return newDelta;
    };

    // Update Quill behavior when using 'enter' key
    const handleEnter = (range: any, context: any) => {
      const nextCharacter = editorRef.value.quill.getText(range.index + 1, 1);
      editorRef.value.quill.insertEmbed(range.index, 'break', true, 'user');
      if (nextCharacter.length === 0) {
        editorRef.value.quill.insertEmbed(range.index, 'break', true, 'user');
      }
      editorRef.value.quill.setSelection(range.index + 1, Quill.sources.SILENT);
    };

    const quillModules = ref({
      clipboard: {
        matchVisual: false,
        matchers: [
          ['BR', lineBreakMatcher], // Quill use clipboard when initializing the first value
        ],
      },
      keyboard: {
        bindings: {
          handleEnter: {
            key: 13,
            handler: handleEnter,
          },
          linebreak: {
            key: 13,
            shiftKey: true,
            handler: handleEnter,
          },
        },
      },
    });

    const focus = () => {
      if (editorRef.value && editorRef.value.quill) {
        editorRef.value.quill.blur();
        editorRef.value.quill.focus();
      }
    };

    const handleEditorLoaded = () => {
      editorRef.value.quill.root.addEventListener('blur', () => {
        if (props.translation) {
          emit('deactivate-group', props.translation.groupKey);
        }
      });
      editorRef.value.quill.root.addEventListener('focus', () => {
        if (props.translation) {
          emit('activate-group', props.translation.groupKey);
        }
      });
    };

    return {
      t,
      inputValue,
      editorRef,
      overlayPanelRef,
      overlayPanelBackgroundRef,
      handleValueChange,
      insertContent,
      toggleColorPicker,
      toggleColorPickerBackground,
      changeColor,
      changeColorBackground,
      colorFormat,
      quillModules,
      focus,
      getTranslationLabel,
      handleEditorLoaded,
    };
  },
});
</script>

<style scoped lang="scss">
:deep() .p-togglebutton.p-button.emoji-button,
:deep() .variable-dropdown {
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block !important;
  float: left;
  height: 24px;
  padding: 1px 0 !important;
  width: 28px;
  filter: grayscale(100%);
  .p-dropdown-trigger {
    display: none;
  }
  .p-dropdown-label {
    display: inline;
  }
}
</style>
