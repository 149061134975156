
import {
  defineComponent, PropType, ref, SetupContext, toRefs, computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  MyLists, myListsState, TypeList,
} from '@/composables/mes-listes/ListsManage';
import router from '@/router';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { useStore } from '@/store';
import { findPermission } from '@/composables/User';
import { getCustomer } from '@/composables/data-explorer/DataExplorer';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import { ShopsListsUpdateInputItem } from '@/types/generated-types/graphql';
import { UpdateShopsLists } from '@/composables/shop/ShopsLists';
import moment from 'moment/moment';
import { showToastError, showToastSuccess } from '@/helpers';
import { DataExplorerDataType, ListType } from '@/types';

export default defineComponent({
  name: 'ListsManageActions',
  components: {
    SpmButton,
    Dialog,
    Button,
    SpmPanelMenu,
    SpmOverlayPanel,
    OverlayPanel,
    InputText,
  },

  props: {
    idShop: {
      type: Number,
      required: true,
    },

    func: {
      type: Function,
      required: true,
    },

    data: {
      type: Object as PropType<MyLists>,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: '',
    },

    idCustomer: {
      type: Number || null,
      required: false,
      default: null,
    },

    idList: {
      type: String,
      required: false,
      default: null,
    },

    searchWindow: {
      type: Boolean,
      required: false,
      default: false,
    },

    autoUpdatedList: {
      type: Boolean,
      required: false,
      dafault: false,
    },

  },

  emits: [
    'show-import-method',
    'show-overlay-panel',
  ],

  setup(props: { data: MyLists; func: Function; idShop: number; type: string; idCustomer: number | null; idList: string; searchWindow: boolean; autoUpdatedList: boolean },
    { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const renameOverlayRef = ref();
    const newListName = ref();
    const currentRowData = ref();

    const inArray = (needle: string, hayStack: string[]) => hayStack.some((s) => s === needle);
    const menuSeparator = { separator: true };
    const displayDialog = ref(false);
    const menu = ref();
    const {
      data,
      type,
      idList,
    } = toRefs(props);

    myListsState.idShopList = data.value.ID;
    myListsState.name = data.value.Name;
    myListsState.type = type.value;
    myListsState.idShopListImportId = Number(idList.value);

    switch (props.type) {
      case 'file':
        myListsState.header = `${props.data.Name}: ${t('myLists.importContacts.files.header')}`;
        break;
      case 'copyPaste':
        myListsState.header = `${props.data.Name}: ${t('myLists.importContacts.copyPaste.header')}`;
        break;
      case 'segmentation':
        myListsState.header = `${props.data.Name}: ${t('myLists.importContacts.segmentation.header')}`;
        break;
      default:
        myListsState.header = `${props.data.Name}: ${t('myLists.importContacts.files.header')}`;
        break;
    }

    let stringId = '';
    if (props.type) {
      stringId = props.type ?? props.data.ID.toString();
    } else {
      // eslint-disable-next-line vue/no-setup-props-destructure
      stringId = props.idList;
    }

    const showImportCustomers = async (idShopList: number, name: string, typeImport: string, listType: string) => {
      await store.dispatch('listImportEditor/importCustomersAction', {
        idShopList,
        typeImport,
        listName: name,
        listType
      });
      displayDialog.value = false;
    };

    const showRenameOverlay = (myList: MyLists, event: any) => {
      // emit('show-overlay-panel', myList, event);
      currentRowData.value = myList;
      newListName.value = myList.Name;
      renameOverlayRef.value.toggle(event.originalEvent);
    };

    const btnClientAddManage = {
      icon: 'fas fa-pencil-alt',
      label: t('myLists.manage.actions.clientAddManage'),
      command: () => {
        router.push({
          name: 'lists.manage-customers',
          params: {
            id: props.data.ID,
            type: props.data.typeList ?? '',
          },
        });
      },

      visible: () => findPermission('list_actions.add_lists'),
    };

    const btnClientManage = {
      icon: 'fas fa-pencil-alt',
      label: t('myLists.manage.actions.clientManage'),
      command: () => {
        router.push({ name: 'lists.manage-customers', params: { id: props.data.ID } });
      },

      visible: () => findPermission('lists-segments.manage_lists'),
    };

    const btnClientExport = {
      icon: 'fas fa-file-export',
      label: t('myLists.manage.actions.clientExport'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.export.text'),
          props.idCustomer ? t('myLists.manage.bulkActions.export.clientTitle') : t('myLists.manage.bulkActions.export.formTitle'),
          'fas fa-file-export',
          t('myLists.manage.bulkActions.export.acceptLabel'),
          t('myLists.manage.bulkActions.export.rejectLabel'),
          'export',
          props.idCustomer);
      },

      visible: () => findPermission('lists-segments.export'),
    };

    const btnClientMove = {
      icon: 'far fa-copy',
      label: t('myLists.manage.actions.listMove'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.move.text'),
          props.idCustomer ? t('myLists.manage.bulkActions.move.clientTitle') : t('myLists.manage.bulkActions.move.formTitle'),
          'fa fa-level-down',
          t('myLists.manage.bulkActions.move.acceptLabel'),
          t('myLists.manage.bulkActions.move.rejectLabel'),
          'move',
          props.idCustomer);
      },

      visible: () => findPermission('lists-segments.export'),
    };

    const btnClientCopy = {
      icon: 'far fa-copy',
      label: t('myLists.manage.actions.clientCopy'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.copy.text'),
          props.idCustomer ? t('myLists.manage.bulkActions.copy.clientTitle') : t('myLists.manage.bulkActions.copy.formTitle'),
          'fa fa-level-down',
          t('myLists.manage.bulkActions.copy.acceptLabel'),
          t('myLists.manage.bulkActions.copy.rejectLabel'),
          'copy',
          props.idCustomer);
      },

      visible: () => findPermission('lists-segments.copy'),
    };

    const btnClientClone = {
      icon: 'far fa-clone',
      label: t('myLists.manage.actions.clientClone'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.duplicate.text'),
          t('myLists.manage.bulkActions.duplicate.formTitle'),
          'far fa-info-circle',
          t('myLists.manage.bulkActions.duplicate.acceptLabel'),
          t('myLists.manage.bulkActions.duplicate.rejectLabel'),
          'duplicate',
          props.idCustomer,
          props.data.Name);
      },

      visible: () => findPermission('lists-segments.clone'),
    };

    const btnClientImport = {
      icon: data.value.type === ListType.SEGMENT ? 'fa fa-cog' : 'fa fa-upload',
      label: data.value.type === ListType.SEGMENT ? t('myLists.manage.actions.handleListSegment') : t('myLists.manage.actions.clientImport'),
      command: async () => {
        if (data.value.type === ListType.SEGMENT) {
          const segmentConfig = await store.dispatch('segmentationsEditor/getConfiguration', {
            idShopList: data.value.ID,
            idShop: props.idShop
          });
          if (segmentConfig) {
            showImportCustomers(props.data.ID, props.data.Name, 'segmentation', props.data.type ?? '');
            store.commit('listImportEditor/setKeepUpdated', segmentConfig.stay_updated);
          } else {
            showToastError(t('errorMessages.GENERIC_ERROR'));
          }
        } else {
          displayDialog.value = true;
        }
      },

      visible: () => findPermission('lists-segments.import_clients'),
    };

    const btnListRename = (myList: MyLists) => ({
      icon: 'fa-light fa-input-text',
      label: t('myLists.manage.actions.listRename.menuItem'),
      command: (event: any) => (event ? showRenameOverlay(myList, event) : false),
      visible: () => findPermission('lists-segments.rename'),
    });

    const btnClientCreate = {
      icon: 'far fa-plus',
      label: t('myLists.manage.bulkActions.create.menuItem'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.create.text'),
          props.idCustomer ? t('myLists.manage.customers.actions.createList') : t('myLists.manage.bulkActions.create.formTitle'),
          'far fa-info-circle',
          t('myLists.manage.bulkActions.create.acceptLabel'),
          t('myLists.manage.bulkActions.create.rejectLabel'),
          'newlist',
          props.idCustomer);
      },

      visible: () => findPermission('list_actions.create'),
    };

    const btnClientUnsub = {
      icon: 'far fa-minus-circle',
      label: t('myLists.manage.bulkActions.unsub.menuItem'),
      command: () => {
        props.func.call(this,
          null,
          t('myLists.manage.bulkActions.unsub.text'),
          props.idCustomer ? t('myLists.manage.customers.actions.unsubClient') : t('myLists.manage.bulkActions.unsub.formTitle'),
          'far fa-info-circle',
          t('myLists.manage.bulkActions.unsub.acceptLabel'),
          t('myLists.manage.bulkActions.unsub.rejectLabel'),
          'unsub_newsletter',
          props.idCustomer);
      },

      visible: () => findPermission('list_actions.unsub'),
    };

    const btnListMove = {
      icon: 'far fa-clipboard',
      label: t('myLists.manage.actions.listMove'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.move.text'),
          props.idCustomer ? t('myLists.manage.customers.actions.createList') : t('myLists.manage.bulkActions.move.formTitle'),
          'fa fa-level-down',
          t('myLists.manage.bulkActions.move.acceptLabel'),
          t('myLists.manage.bulkActions.move.rejectLabel'),
          'move',
          props.idCustomer);
      },

      visible: () => findPermission('lists-segments.move'),
    };

    const btnClientDeleteAll = {
      icon: 'far fa-trash',
      label: t('myLists.manage.actions.clientDeleteAll'),
      command: () => {
        props.func.call(this,
          null,
          t('myLists.manage.bulkActions.deleteFromAll.text'),
          props.idCustomer ? t('myLists.manage.customers.actions.deleteAll') : t('myLists.manage.bulkActions.deleteFromAll.formTitle'),
          'far fa-exclamation-triangle',
          t('myLists.manage.bulkActions.deleteFromAll.acceptLabel'),
          t('myLists.manage.bulkActions.deleteFromAll.rejectLabel'),
          'delete_from_all_lists',
          props.idCustomer);
      },

      visible: () => findPermission('lists-segments.delete_all'),
    };

    const btnListDelete = {
      icon: 'far fa-trash',
      label: props.idCustomer ? t('myLists.manage.actions.clientDelete') : t('myLists.manage.actions.listDelete.menuItem'),
      command: () => {
        props.func.call(this,
          stringId,
          t('myLists.manage.bulkActions.delete.text'),
          props.idCustomer ? t('myLists.manage.customers.actions.clientDelete') : t('myLists.manage.bulkActions.delete.formTitle'),
          'far fa-exclamation-triangle',
          t('myLists.manage.bulkActions.delete.acceptLabel'),
          t('myLists.manage.bulkActions.delete.rejectLabel'),
          'delete',
          props.idCustomer);
      },

      visible: () => findPermission('lists-segments.delete'),
    };

    const btnOpenClient = {
      icon: 'far fa-circle-info',
      label: t('myLists.manage.actions.openContactFile'),
      command: async () => {
        if (props.idCustomer) {
          const openedContactFiles = store.getters['dataExplorer/getOpenedDatas'];
          let customer = openedContactFiles
            .filter((openedContact: any) => openedContact.dataType === DataExplorerDataType.CONTACT_FILE)
            .find((openedContact: any) => openedContact.generalInformation.data.idShopCustomer === props.idCustomer);
          if (!customer) {
            customer = await getCustomer(props.idCustomer);
          }
          if (customer) {
            store.dispatch('dataExplorer/openContactFile', { customer });
          }
        }
      },
    };

    const shopListButtons = (listData: MyLists) => {
      let buttons: Array<{ icon: string; label: any; command: (event: any) => void } | { separator: boolean }> = [btnClientManage];
      buttons = [...buttons, btnClientImport];

      buttons = [...buttons/*, btnClientExport*/, menuSeparator, btnClientCopy, btnListMove, btnListRename(listData), btnClientClone, btnListDelete];

      return buttons;
    };

    let items: any = [
      btnClientManage,
      btnClientImport,
      /*btnClientExport,*/
      btnListRename,
      menuSeparator,
      btnClientCopy,
      btnListMove,
      btnClientClone,
      btnListDelete,
    ];

    if (data.value.isValid) {
      items = shopListButtons(data.value);
    }

    if (inArray(props.data.typeList ?? '', [TypeList.SYNC, TypeList.RETARGETING, TypeList.NEWSLETTER])) {
      items = [
        btnClientAddManage,
        /*btnClientExport,*/
        menuSeparator,
        btnClientCopy,
        btnClientClone,
      ];
    }

    if (props.searchWindow) {
      items = [
        /*btnClientExport,*/
        btnClientCopy,
        btnClientCreate,
        btnClientUnsub,
        btnClientDeleteAll,
      ];
    }

    if (props.idCustomer) {
      items = [
        /*btnClientExport, */
        btnOpenClient,
        btnClientCopy,
        btnClientMove,
        btnClientCreate,
        btnClientUnsub,
        btnListDelete,
        btnClientDeleteAll,
      ];
    }

    const closeRenameOverlay = () => {
      renameOverlayRef.value.hide();
    };

    const renameList = async () => {
      currentRowData.value.Name = newListName.value;
      const record: ShopsListsUpdateInputItem = {
        id_shop_list: currentRowData.value.ID,
        id_shop: props.idShop,
        name: newListName.value,
        date_modification: moment().format('YYYY-MM-DD HH:mm:ss'),
      };
      UpdateShopsLists([record]).then((result) => {
        if (result.status && result.id) {
          showToastSuccess(t('myLists.manage.actions.listRename.success'));
        } else {
          showToastError(t('myLists.manage.actions.listRename.error'));
        }
      }).catch((err: any) => {
        showToastError(err.message);
      });
      closeRenameOverlay();
    };

    const allListTypes = ref([
      {
        label: t('myLists.importContacts.files.option'),
        action: () => showImportCustomers(props.data.ID, props.data.Name, 'file', props.data.type ?? ''),
        type: ListType.LIST,
      },
      {
        label: t('myLists.importContacts.copyPaste.option'),
        action: () => showImportCustomers(props.data.ID, props.data.Name, 'copyPaste', props.data.type ?? ''),
        type: ListType.LIST,
      },
      {
        label: t('myLists.importContacts.segmentation.option'),
        action: () => showImportCustomers(props.data.ID, props.data.Name, 'segmentation', props.data.type ?? ''),
        type: ListType.SEGMENT,
        disabled: false, // Disable segmentation
      },
    ]);

    const listTypes = computed(() => {
      if (!props.data.type || props.data.type === ListType.NOT_DEFINED) {
        return allListTypes.value;
      }
      return allListTypes.value.filter((list) => list.type === props.data.type);
    });

    return {
      items,
      displayDialog,
      showImportCustomers,
      t,
      newListName,
      closeRenameOverlay,
      renameList,
      renameOverlayRef,
      listTypes,
    };
  },
});
