
import {
  defineComponent, Ref, ref, SetupContext, watch,
} from 'vue';
import InputText from 'primevue/inputtext';
import { useI18n } from 'vue-i18n';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import Password from 'primevue/password';

export default defineComponent({
  name: 'BaseTextInput',
  components: {
    InputText,
    Password,
    FieldErrors,
  },

  props: {
    fieldId: {
      type: String,
      required: false,
      default: '',
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: 'text',
    },

    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    inputStyle: {
      type: String,
      required: false,
      default: '',
    },

    inputType: {
      type: String,
      required: false,
      default: '',
    },

    modelValue: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: {
    'update:modelValue': String,
  },

  setup(props: { modelValue: string; errors: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const inputValue: Ref<string> = ref(props.modelValue);
    const formValidationErrors = ref(props.errors);
    const componentFieldErrorsKey = ref(0);

    watch(() => props.errors, () => {
      formValidationErrors.value = props.errors;
      componentFieldErrorsKey.value += 1;
    });

    watch(inputValue, () => {
      emit('update:modelValue', inputValue.value);
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      inputValue,
    };
  },
});
