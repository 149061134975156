import { Get, Update } from '@/composables/GraphQL';
import {
  ShopsUsers, Users, UsersUpdateInputItem,
} from '@/types/generated-types/graphql';

export interface MyProfileInterface {
  id_user: number;
  lang: string;
  last_name: string;
  first_name: string;
  email: string;
  password: string;
  societe: string;
  vat_number: string;
  phone_mobile: string;
  phone_fixe: string;
  complement: string;
  rue: string;
  bp: string;
  zip_code: string;
  city: string;
  country: string;
  state: string;
}

export interface ProfileErrorsInterface {
  email?: boolean;
  password?: boolean;
  country?: boolean;
  state?: boolean;
  lang?: boolean;
  advisorEmail?: boolean;
}

export const getUserProfile = async (id: number) => Get<Users>({
  name: 'Users',
  id,
  keyName: 'id_user',
  fields: [
    'id_user',
    'last_name',
    'first_name',
    'email',
    'societe',
    'vat_number',
    'phone_mobile',
    'phone_fixe',
    'complement',
    'rue',
    'bp',
    'zip_code',
    'city',
    'country',
    'state',
    'lang',
  ],
});

export const getSubUserProfile = async (id: number) => Get<ShopsUsers>({
  name: 'ShopsUsers',
  id,
  keyName: 'id_shops_user',
  fields: [
    'id_shops_user',
    'last_name',
    'first_name',
    'email',
    'password',
    'lang',
  ],
});

export const saveMyProfile = async (userUpdateInput: UsersUpdateInputItem) => Update<UsersUpdateInputItem>({
  name: 'Users',
  input: [userUpdateInput],
  type: 'UsersUpdateInput',
});
