import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum, ActionTypeEnum,
} from '@/types';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import {
  setContent,
  setFacebookButtons,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getContent,
  getFacebookButtons,
} from '@/components/template-builder/getters';
import getInputVariablesFieldConfig from '@/components/template-builder/config/fields/input-variables-config';
import getFacebookButtonsFieldConfig from '@/components/template-builder/config/fields/facebook-buttons-config';

const redirectTypes = [ActionTypeEnum.REDIRECT, ActionTypeEnum.DISPLAY_TEMPLATE];

const facebookWidgetTextConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.fbTextButtons',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.fbText',
            selector: '{SELECTOR} .fb_text .text',
            properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
            ...getInputVariablesFieldConfig('Texte du bouton', variables.allVariables, true, true),
          },
          {
            label: 'templateBuilder.fields.fbButtons.header',
            selector: '{SELECTOR} .fb_text .buttons',
            properties: [
              { name: 'buttons', getter: getFacebookButtons, setters: [setFacebookButtons] },
            ],
            ...getFacebookButtonsFieldConfig(redirectTypes),
          },
        ],
      },
    ],
  },
};

export default facebookWidgetTextConfig;
