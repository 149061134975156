import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  setHtmlContent,
} from '@/components/template-builder/setters';

// eslint-disable-next-line import/no-cycle
import getContent from '@/components/template-builder/getters/import-zip';

// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';

import getImportZipFieldConfig from '../fields/import-zip-config';

const widgetImportZipConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.importZip',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.htmlCode',
            selector: '{SELECTOR}.spm_widget_import_zip',
            properties: [
              {
                name: 'content',
                getter: getContent,
                setters: [setHtmlContent],
                callbacks: [refreshSmartProductList],
              },
            ],
            ...getImportZipFieldConfig('', false),
          },
        ],
      },
    ],
  },
};

export default widgetImportZipConfig;
