// Get coordinates of an element in window
export const getOffsetOfElement = (iframe: any, el: any) => {
  let element = el;
  let x = 0;
  let y = 0;

  if (element.getAttribute('coords')) {
    const coords = element.getAttribute('coords').split(',');
    x = parseInt(coords[0], 10) + ((parseInt(coords[2], 10) - parseInt(coords[0], 10)) / 2);
    y = parseInt(coords[1], 10) + ((parseInt(coords[3], 10) - parseInt(coords[1], 10)) / 2);

    element = element.offsetParent;
    while (element && !Number.isNaN(element.offsetLeft) && !Number.isNaN(element.offsetTop)) {
      x += element.offsetLeft - element.scrollLeft;
      y += element.offsetTop - element.scrollTop;
      element = element.offsetParent;
    }
  } else {
    if (element.style.display === '') {
      element.style.display = 'inline-block';
    }

    const rect = element.getBoundingClientRect();
    x += Math.round(rect.left + iframe.contentDocument.querySelector('html').scrollLeft
      + iframe.contentDocument.querySelector('body').scrollLeft + (rect.width / 2));
    y += Math.round((rect.top + iframe.contentDocument.querySelector('html').scrollTop
      + iframe.contentDocument.querySelector('body').scrollTop) + (rect.height / 2));
  }

  return { x, y };
};

export const round5 = (x: number) => Math.ceil(x / 5) * 5;
