import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';

import general from '@/store/modules/general';
// eslint-disable-next-line import/no-cycle
import liveEditor from '@/store/modules/live-editor';
import { State } from '@/types';
import statsEditor from '@/store/modules/stats-editor';
import listImportEditor from '@/store/modules/list-import-editor';
import dataViewer from '@/store/modules/data-viewer';
import choosePlan from '@/store/modules/choose-plan';
import templateEditor from '@/store/modules/template-editor';
import dataExplorer from '@/store/modules/data-explorer';
import segmentationsEditor from '@/store/modules/segmentations-editor';

export const key: InjectionKey<Store<State>> = Symbol('store-key');

export const store = createStore<State>({
  modules: {
    general,
    liveEditor,
    statsEditor,
    listImportEditor,
    dataViewer,
    choosePlan,
    templateEditor,
    dataExplorer,
    segmentationsEditor,
  },
});

export function useStore() {
  return baseUseStore(key);
}
