<template>
  <label class="block mb-2">
    {{ t(title) }}
  </label>
  <div class="flex align-items-center">
    <InputText
      v-model="value"
      class="input-text"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, SetupContext, watch} from 'vue';
import { useI18n } from 'vue-i18n';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'Input',

  components: {
    InputText,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: string }, { emit }: SetupContext) {
    const { t } = useI18n();
    const value = ref(props.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    return {
      t,
      value,
    };
  },
});
</script>

<style lang="scss" scoped>
.input-text {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}
</style>
