<template>
  <InputNumber
    v-model="inputValue"
    show-buttons
    :button-layout="layout"
    :suffix="suffix"
    :min="min"
    :max="max"
    :step="step"
    :use-grouping="false"
    :class="{ 'stepper-input--focused': isInputNumberFocused }"
    increment-button-icon="far fa-plus"
    increment-button-class="increment-btn"
    decrement-button-icon="far fa-minus"
    decrement-button-class="decrement-btn"
    input-class="stepper-input"
    :disabled="disabled"
    :input-style="inputWidth ? { width: inputWidth } : {}"
    @focus="isInputNumberFocused = true;"
    @blur="isInputNumberFocused = false;"
    @input="updateModelValue"
    @keyup.enter="updateModelValue"
  />
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import InputNumber from 'primevue/inputnumber';

export default defineComponent({
  name: 'BaseInputNumber',
  components: { InputNumber },
  props: {
    suffix: {
      type: String,
      required: false,
      default: '',
    },

    layout: {
      type: String,
      required: false,
      default: 'horizontal',
    },

    min: {
      type: Number,
      required: false,
      default: -1000000,
    },

    max: {
      type: Number,
      required: false,
      default: 1000000,
    },

    step: {
      type: Number,
      required: false,
      default: 1,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: Number,
      default: 0,
    },

    inputWidth: {
      type: String,
      required: false,
      default: ''
    }
  },

  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const isInputNumberFocused = ref(false);
    const inputValue = ref(props.modelValue);

    const updateModelValue = (event) => {
      if (event && event.value !== null && event.value !== undefined) {
        inputValue.value = event.value;
        emit('update:modelValue', inputValue.value);
      }
    };

    watch(props, () => {
      inputValue.value = props.modelValue;
    });

    return { isInputNumberFocused, inputValue, updateModelValue };
  },
});
</script>

<style lang="scss">
.p-inputnumber {
  border: none;
  border-radius: $field-border-radius;
  outline: none !important;
  box-shadow: none !important;
  height: $field-default-height;

  > .stepper-input {
    border-top: 1px solid #BABFC3 !important;
    border-bottom: 1px solid #BABFC3 !important;
    border-radius: 0 !important;
    padding-left: 0.4rem !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:hover {
    border-color: $brand-color-primary;
  }

  &.stepper-input--focused {
    box-shadow: $box-shadow;
  }

  .increment-btn,
  .decrement-btn {
    background-color: #f2f2f2 !important;
    color: $heather !important;
    height: 100% !important;
    font-size: $field-icon-up-down-font-size;
    border: 1px solid $heather !important;

    &:hover {
      background-color: $extra-light-grey !important;
    }

    &:enabled:active {
      background-color: $extra-light-grey !important;
    }
  }

  .increment-btn {
    border-left: none !important;

    .p-button-icon::before {
      bottom: $field-icon-up-down-offset;
    }
  }

  .decrement-btn {
    border-right: none !important;

    .p-button-icon::before {
      top: $field-icon-up-down-offset;
    }
  }
}
</style>
