import { FilterSegment, FilterField } from '@/types/automated-scenarios';

export interface ActiveSegment {
  name: string;
  label: string;
  number?: number;
  show: boolean;
}

export const addInArraySegment = <T>(segment: FilterSegment<T>, filter: any|undefined) => {
  let updatedFilter = { ...filter };
  if (!filter) {
    updatedFilter = {
      segment_number: 0,
      litteral_title: {},
      ...segment.fields.reduce((acc, field) => ({ ...acc, [field.id]: [] }), {}),
    };
  }
  if (segment.fields) {
    segment.fields.forEach((field: FilterField) => {
      updatedFilter = {
        ...updatedFilter,
        [field.id]: [
          ...updatedFilter[field.id],
          field.data,
        ],
        litteral_title: {
          ...updatedFilter.litteral_title,
          [field.id]: field.litteral_title,
        },
      };
      updatedFilter.litteral_title[field.id] = field.litteral_title;
    });
  }
  updatedFilter.segment_number += 1;
  return updatedFilter;
};

export const addInSegment = <T>(segment: FilterSegment<T>, filter: any) => {
  let updatedFilter = { ...filter };
  if (!filter) {
    updatedFilter = {
      segment_number: 0,
      litteral_title: {},
      ...segment.fields.reduce((acc, field) => ({ ...acc, [field.id]: [] }), {}),
    };
  }
  if (segment.fields) {
    segment.fields.forEach((field: FilterField) => {
      updatedFilter = {
        ...updatedFilter,
        [field.id]: field.data,
        litteral_title: {
          ...updatedFilter.litteral_title,
          [field.id]: field.litteral_title,
        },
      };
      updatedFilter.litteral_title[field.id] = field.litteral_title;
    });
  }
  updatedFilter.segment_number += 1;
  return updatedFilter;
};

export const removeInSegment = <T>(segments: any, segmentName: keyof T, number: number) => {
  let segmentToUpdate: any = {};
  if (segments[segmentName].segment_number === 1) { // delete entire segment if number is 1
    // eslint-disable-next-line no-param-reassign
    delete segments[segmentName];
  } else {
    segmentToUpdate = {
      segment_number: segments[segmentName].segment_number - 1,
      litteral_title: segments[segmentName].litteral_title,
    };
    Object.keys(segments[segmentName])
      .filter((key: string) => !['segment_number', 'litteral_title'].includes(key)) // get keys to update in object
      .forEach((key: string) => { // for each field
        segmentToUpdate[key] = [...segments[segmentName][key]];
        segmentToUpdate[key].splice(number, 1); // remove index
      });
    // eslint-disable-next-line no-param-reassign
    segments = {
      ...segments,
      [segmentName]: segmentToUpdate,
    };
  }
  return segments;
};

export const getActiveSegments = <T>(filters: T, segments: FilterSegment<T>[]) => (Object.entries(filters)
  .reduce((acc: ActiveSegment[], element: any[]) => {
    const activeSegments = [];
    for (let i = 0; i < element[1].segment_number; i++) {
      const segment: FilterSegment<T> = segments.filter((curent: FilterSegment<T>) => curent.id === element[0])[0];
      if (segment) {
        activeSegments.push({
          name: element[0], label: segment.option.label, number: i, show: segment.fields.filter((field) => !field.hidden).length > 0,
        });
      }
    }
    acc.push(...activeSegments);
    return acc;
  }, []));
