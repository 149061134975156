<template>
  <Dialog
    v-model:visible="displayModal"
    data-test-id="column-widget-dialog"
    position="center"
    :header="t('templateBuilder.modals.columnWidget.header')"
    :modal="true"
    :breakpoints="{ '960px': '60vw', '640px': '100vw' }"
    :style="{ width: '40vw' }"
    :dismissable-mask="true"
    @update:visible="handleClose"
  >
    <div class="grid text-left">
      <div class="col-12">
        {{ t('templateBuilder.modals.columnWidget.columnsNumber') }}
      </div>
      <div class="col-12">
        <SelectButton
          v-model="chosenColumns"
          :options="availableColumns"
        />
      </div>
      <div class="col-12">
        {{ t('templateBuilder.modals.columnWidget.columnsStructure') }}
      </div>
      <div class="col-12">
        <div class="grid">
          <div
            v-for="(columnLayout, index) in columnsLayout"
            :key="index"
            class="col-4 text-center"
          >
            <div
              class="layout"
              :class="{ 'active': layoutIndex === index }"
              @click="layoutIndex = index;"
            >
              <div class="grid">
                <div class="col-8 flex column-structure">
                  <div
                    v-for="(size, gridIndex) in columnLayout.grid"
                    :key="gridIndex"
                    class="mb-2 column-structure-drawing"
                    :class="`${size} ${(gridIndex < columnLayout.grid.length) ? 'mr-1' : ''}`"
                  />
                </div>
              </div>
              {{ columnLayout.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <Button
        data-test-id="input-text-button-cancel"
        :label="t('templateBuilder.modals.cancel')"
        icon="far fa-times"
        class="p-button-secondary"
        @click="handleClose"
      />
      <Button
        data-test-id="input-text-button-ok"
        :label="t('templateBuilder.modals.validate')"
        icon="far fa-check"
        class="p-button-primary"
        @click="handleValidation"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  ComputedRef,
  ref,
  Ref,
  watch,
} from 'vue';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { hideColumnWidgetModal, TemplateEditorState as state, updateSectionsInState } from '@/composables/template-editor/TemplateEditor';
import { HistoryType } from '@/types';
import { checkIfSavePointNeeded, createHistory } from '@/composables/template-editor/History';
import { resetListeners } from '@/components/template-builder/utils/listeners';
import { getTemplateIframeDocument, getTemplateParentTypeByType } from '@/components/template-builder/utils/helpers';
import { getColumnsWidgetLayouts } from '@/components/template-builder/utils/raw-html-templates';

interface ColumnsLayout {
  name: string;
  percentages: number[];
  grid: string[];
}

export default defineComponent({
  name: 'ColumnWidgetModal',

  components: {
    Dialog,
    SelectButton,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const displayModal: ComputedRef<boolean> = computed(() => state.isColumnWidgetModalVisible);
    // Number of columns buttons
    const availableColumns: Ref<number[]> = ref([1, 2, 3, 4]);
    const chosenColumns: Ref<number> = ref(1);

    // Columns layouts buttons
    const availableColumnsLayouts: ColumnsLayout[][] = [
      [
        { name: t('templateBuilder.modals.columnWidget.fullWidth'), percentages: [100], grid: ['csd-four'] },
      ],
      [
        { name: '50% / 50%', percentages: [50, 50], grid: ['csd-two', 'csd-two'] },
        { name: '33% / 67%', percentages: [33, 67], grid: ['csd-one-big', 'csd-three-little'] },
        { name: '67% / 33%', percentages: [67, 33], grid: ['csd-three-little', 'csd-one-big'] },
        { name: '25% / 75%', percentages: [25, 75], grid: ['csd-one', 'csd-three'] },
        { name: '75% / 25%', percentages: [75, 25], grid: ['csd-three', 'csd-one'] },
      ],
      [
        { name: '33% / 33% / 33%', percentages: [33, 33, 33], grid: ['csd-one-big', 'csd-one-big', 'csd-one-big'] },
        { name: '50% / 25% / 25%', percentages: [50, 25, 25], grid: ['csd-two', 'csd-one', 'csd-one'] },
        { name: '25% / 50% / 25%', percentages: [25, 50, 25], grid: ['csd-one', 'csd-two', 'csd-one'] },
        { name: '25% / 25% / 50%', percentages: [25, 25, 50], grid: ['csd-one', 'csd-one', 'csd-two'] },
      ],
      [
        { name: t('templateBuilder.modals.columnWidget.equalWidth'), percentages: [25, 25, 25, 25], grid: ['csd-one', 'csd-one', 'csd-one', 'csd-one'] },
      ],
    ];
    const columnsLayout: ComputedRef<ColumnsLayout[]> = computed(() => availableColumnsLayouts[chosenColumns.value - 1]);
    const layoutIndex: Ref<number> = ref(0);
    const chosenLayout: ComputedRef<ColumnsLayout> = computed(() => availableColumnsLayouts[chosenColumns.value - 1][layoutIndex.value]);

    const templateParentType: ComputedRef<string> = computed(() => getTemplateParentTypeByType(state.template?.type));
    const columnWidgetStructure = computed(() => getColumnsWidgetLayouts()[templateParentType.value]);

    const handleClose = () => {
      // Reset values
      chosenColumns.value = 1;
      layoutIndex.value = 0;

      hideColumnWidgetModal();
    };

    const handleValidation = () => {
      const htmlToInsert = columnWidgetStructure.value(chosenLayout.value.percentages);

      // Get placeholder element in template
      const template = getTemplateIframeDocument();
      const placeholder = template?.querySelector('.sortable-group-line.sortable-placeholder') as HTMLElement;

      if (placeholder) {
        checkIfSavePointNeeded().then(() => {
          placeholder.insertAdjacentHTML('afterend', htmlToInsert);

          // Remove placeholder from template
          placeholder.remove();

          // Update state
          updateSectionsInState();

          // Create history entry
          createHistory(HistoryType.ADD_COLUMN);

          // Reset all listeners
          resetListeners();
          // Close modal
          handleClose();
        });
      } else {
        // Close modal
        handleClose();
      }


    };

    watch(chosenColumns, () => {
      // Reset layout on columns number change
      layoutIndex.value = 0;
    });

    return {
      t,
      displayModal,
      handleClose,
      handleValidation,
      chosenColumns,
      availableColumns,
      columnsLayout,
      layoutIndex,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-selectbutton:deep() {
  .p-button.p-highlight,
  .p-button.p-highlight:hover {
    background: $brand-color-primary;
    border-color: $brand-color-primary;
  }
}

.layout {
  width: 100%;
  border: $columnsWidgetLayoutBorder;
  color: $columnsWidgetLayoutColor;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: $columnsWidgetLayoutHoverBackground;

    & .column-structure {
      & .column-structure-drawing {
        background: $columnsWidgetLayoutIconBackgroundHover;
      }
    }
  }

  & .column-structure {
    margin: 0 auto;

    & .column-structure-drawing {
      height: 40px;
      background: $columnsWidgetLayoutIconBackground;
    }
  }

  &.active {
    border-color: $brand-color-primary;
    background-color: $brand-color-primary;
    color: $white;

    & .column-structure {
      & .column-structure-drawing {
        background: $white;
      }
    }
  }
}
</style>
