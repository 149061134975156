<template>
  <Dialog
    v-model:visible="dialogVisible"
    :header="idUserToDelete === 0 ? t('user.subUser.deleteMultiple.formTitle'): t('user.subUser.delete.formTitle')"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '25vw' }"
    :content-style="{ textAlign: 'left' }"
    :modal="true"
    @show="getSubUserList"
    @hide="hideDialog"
  >
    <div class="pb-2">
      <label>
        <i
          class="far fa-info-circle"
          aria-hidden="true"
        />

        {{ idUserToDelete === 0 ? t('user.subUser.deleteMultiple.text'): t('user.subUser.delete.text') }}
      </label>
    </div>
    <template #footer>
      <Button
        :label="t('user.subUser.delete.rejectLabel')"
        icon="far fa-times"
        class="p-button p-button-secondary"
        @click="hideDialog"
      />
      <Button
        :label="t('user.subUser.delete.acceptLabel')"
        icon="far fa-lock"
        class="p-button p-button-primary"
        @click="initiateAction"
      />
    </template>
  </Dialog>
  <div class="flex justify-content-between flex-wrap mb-3">
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t('user.subUser.title') }}
      </h3>
    </div>
    <div class="flex align-items-center justify-content-center mr-2">
      <SecureSpmButton
        :label="t('user.subUser.create')"
        icon="fa fa-plus-square"
        class-style="p-button p-button-success"
        :on-authorized-click="handleCreateUser"
        required-feature="user.manage-sub-users"
      />
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <SpmTable
        ref="childComponentRef"
        name="ShopsUsers"
        index="su.id_shops_user"
        :table-columns="columns"
        :custom-selector="true"
        :persistent-filters="persistentFilters"
        grouped-actions-key="id_shops_user"
        :id-shop="idShop"
      />
    </div>
  </div>
</template>

<script lang="ts">
import SpmTable from '@/components/table/SpmTable.vue';
import {
  SpmTableAction,
  SpmTableColumns,
  SpmTableFilter,
  UserTypeEnum,
  StatsType,
} from '@/types';
import { useI18n } from 'vue-i18n';
import { UserState } from '@/composables/User';
import { defineComponent, ref } from 'vue';
import { ListResult } from '@/composables/GraphQL';
import {
  OperatorType,
  ShopsUsers,
  UsersPagesSubscriptionsDeleteInput,
} from '@/types/generated-types/graphql';
import { DeleteUsersPermissions, shopUserList } from '@/composables/user/ShopsUsers';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { showToastError, showToastSuccess } from '@/helpers';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SecureSpmButton from '@/components/spm-primevue/SecureSpmButton.vue';
import {
  DeleteUsersPagesSubscriptions,
  getUserStatsPages,
} from '@/composables/Statistics/usersPagesSubscriptions';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

export default defineComponent({
  name: 'SubUserList',

  components: {
    SpmTable,
    Button,
    Dialog,
    SpmButton,
    SecureSpmButton,
  },

  setup() {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const dialogVisible = ref<boolean>(false);
    const lists = ref<ShopsUsers[]>();
    const childComponentRef = ref();
    const idUserToDelete = ref(0);

    const showDialog = async (idUser: number) => {
      idUserToDelete.value = idUser;
      dialogVisible.value = true;
    };

    const actions: SpmTableAction[] = [
      {
        icon: 'fas fa-pencil-alt',
        label: t('user.subUser.modify.menuItem'),
        callback: (data: any, event: {originalEvent: Event; item: any; navigate: undefined}) => {
          if (event) {
            router.push({
              name: 'user.manage-sub-users',
              params: { userId: data.id_shops_user },
            });
          }
        },

        show: () => true,
      },
      {
        icon: 'fa-regular fa-trash-can',
        label: t('myLists.manage.bulkActions.delete.menuItem'),
        callback: (data: any, event: {originalEvent: Event; item: any; navigate: undefined}) => {
          if (event) {
            dialogVisible.value = true;
            showDialog(data.id_shops_user);
          }
        },

        show: () => true,
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shops_user',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'first_name',
        header: t('myLists.manage.customers.headers.firstname'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'last_name',
        header: t('myLists.manage.customers.headers.lastname'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'email',
        header: t('myLists.manage.customers.headers.email'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'lang',
        header: t('myLists.manage.customers.headers.lang'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'language',
      },
      {
        field: '',
        header: t('myLists.manage.actions.placeholder'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'action',
        actions,
      },
    ];

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'su.active',
        value: 1,
        operator: OperatorType.Equals,
      },
      {
        field: 'up.id_ps_customer',
        value: UserState.user.idPsCustomer,
        operator: OperatorType.Equals,
      },
    ];

    const reloadDataTable = () => {
      childComponentRef.value.reloadData();
    };

    const getSubUserList = async () => {
      const result: ListResult<ShopsUsers> = await shopUserList();
      lists.value = result.items ?? [];
      childComponentRef.value.reloadData();
    };

    const deleteSpecificUser = async () => {
      const result = await DeleteUsersPermissions([{
        id_shops_user: idUserToDelete.value,
        id_ps_customer: UserState.user.idPsCustomer,
      }]);

      const usersPagesSubscriptionsDelete: UsersPagesSubscriptionsDeleteInput[] = [];

      const dashboards = await getUserStatsPages(idUserToDelete.value, UserTypeEnum.USER, StatsType.DASHBOARD, UserState.activeShop?.idUser);
      if (dashboards && dashboards.err === '' && dashboards.items.length) {
        dashboards.items.forEach((otherDashboard) => {
          usersPagesSubscriptionsDelete.push({
            id_users_pages_configuration: otherDashboard.id_users_pages_configuration,
            id_user: idUserToDelete.value,
            user_type: UserTypeEnum.USER,
          });
        });
      }
      const stats = await getUserStatsPages(idUserToDelete.value, UserTypeEnum.USER, StatsType.STATS, UserState.activeShop?.idUser);
      if (stats && stats.err === '' && stats.items.length) {
        stats.items.forEach((otherStatsPage) => {
          usersPagesSubscriptionsDelete.push({
            id_users_pages_configuration: otherStatsPage.id_users_pages_configuration,
            id_user: idUserToDelete.value,
            user_type: UserTypeEnum.USER,
          });
        });
      }
      const emails = await getUserStatsPages(idUserToDelete.value, UserTypeEnum.USER, StatsType.EMAIL, UserState.activeShop?.idUser);
      if (emails && emails.err === '' && emails.items.length) {
        emails.items.forEach((otherEmailPage) => {
          usersPagesSubscriptionsDelete.push({
            id_users_pages_configuration: otherEmailPage.id_users_pages_configuration,
            id_user: idUserToDelete.value,
            user_type: UserTypeEnum.USER,
          });
        });
      }

      await DeleteUsersPagesSubscriptions(usersPagesSubscriptionsDelete);

      if (result.err || !result.status) {
        await showToastError(t('user.subUser.delete.deleteError'));
      } else {
        reloadDataTable();
        await showToastSuccess(t('user.subUser.delete.deleteSuccess'));
      }

      dialogVisible.value = false;
    };

    const initiateAction = async (item: { event: Event; value: string }) => {
      if (idUserToDelete.value !== 0) {
        await deleteSpecificUser();
        idUserToDelete.value = 0;
        return true;
      }
      childComponentRef.value.sendSelectedRows();
      return true;
    };

    const hideDialog = async () => {
      idUserToDelete.value = 0;
      dialogVisible.value = false;
    };

    const handleCreateUser = async () => {
      await router.push({
        name: 'user.manage-sub-users',
      });
    };

    return {
      t,
      columns,
      persistentFilters,
      idShop,
      dialogVisible,
      getSubUserList,
      initiateAction,
      childComponentRef,
      idUserToDelete,
      hideDialog,
      handleCreateUser,
    };
  },
});
</script>

<style scoped>

</style>
