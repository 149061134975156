
import Button from 'primevue/button';
import {
  defineComponent, onMounted, Ref, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { MarketingWorkflowsCategoryEnum, ToolbarItemsModel, TypeCampaignEnum } from '@/types';
import EditorModal from '@/components/modals/EditorModal.vue';
import SelectButton from 'primevue/selectbutton';
import { MarketingBaseWorkflows, ShopMarketingWorkflowsDuplicateInputItem } from '@/types/generated-types/graphql';
import Loader from '@/components/layout/Loader.vue';
import { duplicateWorkflow, fetchBaseWorkflows } from '@/composables/workflows/Workflow';
import { asInt, showToastError, showToastSuccess } from '@/helpers';
import { UserState } from '@/composables/User';
import { activateCampaign, setRefreshScenariosList } from '@/composables/AutomatedScenarios';
import PreviewModal from '@/components/modals/PreviewModal.vue';
import { store } from '@/store';
import { getNowFormattedByTimezone } from '@/helpers/Date';
import { getNotConfiguredServices } from '@/composables/shop/MyShopParameters';

export default defineComponent({
  name: 'WorkflowSelector',

  components: {
    PreviewModal,
    Loader,
    EditorModal,
    SelectButton,
    Button,
  },

  emits: {
    'on-close-dialog': null,
  },

  setup(props: { }, context: SetupContext) {
    const { t } = useI18n();
    const idActiveShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const isListLoading = ref(false);
    const initialList: Ref<MarketingBaseWorkflows[]> = ref([]);
    const list: Ref<MarketingBaseWorkflows[]> = ref([]);
    const displayPreviewModal = ref(false);
    const images = ref([{}]);
    const createLoading: Ref<Record<number, boolean>> = ref({});

    const workflowGoalSelected = ref({ value: MarketingWorkflowsCategoryEnum.ALL, title: t('automatedScenarios.workflowSelector.goals.options.all') });
    const workflowGoalsOptions = ref([
      { value: MarketingWorkflowsCategoryEnum.ALL, label: t('automatedScenarios.workflowSelector.goals.options.all') },
      { value: MarketingWorkflowsCategoryEnum.CONVERSION, label: t('automatedScenarios.workflowSelector.goals.options.conversion') },
      { value: MarketingWorkflowsCategoryEnum.LOYALTY, label: t('automatedScenarios.workflowSelector.goals.options.loyalty') },
      { value: MarketingWorkflowsCategoryEnum.RETENTION, label: t('automatedScenarios.workflowSelector.goals.options.retention') },
      { value: MarketingWorkflowsCategoryEnum.ACQUISITION, label: t('automatedScenarios.workflowSelector.goals.options.acquisition') },
    ]);

    const workflowIncludeDiscountSelected = ref({ value: 'yes', label: t('yes') });
    const workflowIncludeDiscountOptions = ref([
      { value: 'yes', label: t('yes') },
      { value: 'no', label: t('no') },
    ]);

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const filterListWorkflows = () => {
      const emptyWorkflow = initialList.value.filter((baseWorkflow) => baseWorkflow.categories === 'Empty');
      if (emptyWorkflow.length) {
        emptyWorkflow[0].images_templates = ['https://proxima.shopimind.com/img/new_empty_workflow.png'];
      }

      if (initialList.value.length) {
        list.value = emptyWorkflow.concat(initialList.value.filter((baseWorkflow) => {
          const cats: string[] = baseWorkflow.categories.split(',');
          const matchedGoals = (workflowGoalSelected.value.value === MarketingWorkflowsCategoryEnum.ALL) ? true : cats.includes(workflowGoalSelected.value.value);
          let matchedVouchers = true;
          if (typeof workflowIncludeDiscountSelected.value !== 'undefined') {
            matchedVouchers = (workflowIncludeDiscountSelected.value.value === 'yes') ? cats.includes('Voucher') : !cats.includes('Voucher');
          }

          return matchedGoals && matchedVouchers && baseWorkflow.categories !== MarketingWorkflowsCategoryEnum.EMPTY;
        }));
      }
    };

    watch([workflowGoalSelected, workflowIncludeDiscountSelected], () => {
      filterListWorkflows();
    });

    const handleChoose = async (workflowName: string, idWorkflow: number, index: number) => {
      const serviceParameterSuccessAction = () => {
        store.commit('general/showTheSpinner');

        /**
         * If the workflow is the welcome workflow, name is prefix - date
         * Else, we keep the workflow name - date
         */

        let name = '';
        if (index === 0) {
          name = `${t('automationPrefix')} - ${getNowFormattedByTimezone()}`;
        } else {
          name = `${workflowName} - ${getNowFormattedByTimezone()}`;
        }

        const workflowToDuplicateInput: ShopMarketingWorkflowsDuplicateInputItem = {
          id_marketing_workflow: idWorkflow,
          id_shop_destination: idActiveShop.value,
          name,
        };

        duplicateWorkflow(workflowToDuplicateInput).then((result) => {
          if (result.success && result.id) {
            const id = asInt(result.id);
            showToastSuccess(t('automatedScenarios.create_action.success'));
            setRefreshScenariosList(true);
            activateCampaign(id, TypeCampaignEnum.AUTOMATION).then(() => { // TODO : activer en fonction du type de campagne
              context.emit('on-close-dialog');
              store.commit('general/hideTheSpinner');
            });
          } else {
            showToastError(t('automatedScenarios.create_action.error'));
            store.commit('general/hideTheSpinner');
          }
        }).catch(() => {
          showToastError(t('automatedScenarios.create_action.error'));
          store.commit('general/hideTheSpinner');
        });
      };

      createLoading.value[idWorkflow] = true;
      const scenario = initialList.value.find((item) => item.id_marketing_workflow === idWorkflow);
      if (scenario) {
        let formattedDataWorkflow = JSON.parse(scenario.data_marketing_workflow ?? '{}');
        if (formattedDataWorkflow.operators === 'undefined' || !Object.keys(formattedDataWorkflow.operators).length) {
          formattedDataWorkflow = {
            links: {}, operators: {}, operatorTypes: {},
          };
        }

        const operators = new Set<string>();
        Object.keys(formattedDataWorkflow.operators).forEach((operatorId) => {
          const customDataOperators = formattedDataWorkflow.operators[operatorId].custom;
          operators.add(customDataOperators.id);
        });

        const operatorsArray = Array.from(operators);
        const notConfiguredServices = await getNotConfiguredServices(idActiveShop.value, ['createScenarioCampaign', ...operatorsArray]);
        if (notConfiguredServices.length) {
          store.commit('general/setNotConfiguredServices', notConfiguredServices);
          store.commit('general/setIsServicesParametersModalVisible', true);
          store.commit('general/setServiceParameterSuccessAction', serviceParameterSuccessAction);
        } else {
          serviceParameterSuccessAction();
        }
      }
      createLoading.value[idWorkflow] = false;
    };

    const handlePreview = (idWorkflow: number) => {
      const wfToPreview: MarketingBaseWorkflows[] = list.value.filter((baseWorkflow) => baseWorkflow.id_marketing_workflow === idWorkflow);

      images.value = [];
      if (wfToPreview[0].images_templates !== null && wfToPreview[0].images_templates.length) {
        wfToPreview[0].images_templates.forEach((image) => {
          images.value.push({
            itemImageSrc: image,
            thumbnailImageSrc: image,
            alt: wfToPreview[0].legend_marketing_workflow,
            title: wfToPreview[0].name_marketing_workflow,
          });
        });
      }

      displayPreviewModal.value = true;
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    onMounted(async () => {
      isListLoading.value = true;
      const result = await fetchBaseWorkflows();

      if (result) {
        initialList.value = result;
        filterListWorkflows();
        isListLoading.value = false;
      }
    });

    return {
      t,
      list,
      images,
      MarketingWorkflowsCategoryEnum,
      displayPreviewModal,
      isListLoading,
      workflowGoalSelected,
      workflowGoalsOptions,
      workflowIncludeDiscountSelected,
      workflowIncludeDiscountOptions,
      handleChoose,
      handleClosePreview,
      handlePreview,
      closeModal,
      createLoading,
    };
  },
});
