import {
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TabItem,
  TemplateParentTypeEnum,
  TemplateStructureEnum,
  WidgetTypeConfig,
  WidgetTypeEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import getWidgetTextConfig from '@/components/template-builder/config/widgets/widget-text-config';
// eslint-disable-next-line import/no-cycle
import getWidgetImageConfig from '@/components/template-builder/config/widgets/widget-image-config';
// eslint-disable-next-line import/no-cycle
import getWidgetSeparatorConfig from '@/components/template-builder/config/widgets/widget-separator-config';
// eslint-disable-next-line import/no-cycle
import getWidgetButtonConfig from '@/components/template-builder/config/widgets/widget-button-config';
// eslint-disable-next-line import/no-cycle
import widgetCodeConfig from '@/components/template-builder/config/widgets/widget-code-config';
// eslint-disable-next-line import/no-cycle
import widgetTitleConfig from '@/components/template-builder/config/widgets/widget-title-config';
// eslint-disable-next-line import/no-cycle
import widgetShareSocialMediaConfig from '@/components/template-builder/config/widgets/widget-share-social-media-config';
// eslint-disable-next-line import/no-cycle
import getWidgetSocialMediaConfig from '@/components/template-builder/config/widgets/widget-social-media-config';
// eslint-disable-next-line import/no-cycle
import widgetVoucherConfig from '@/components/template-builder/config/widgets/widget-voucher-config';
// eslint-disable-next-line import/no-cycle
import getWidgetSmartProductListConfig from '@/components/template-builder/config/widgets/widget-smart-product-list-config';
// eslint-disable-next-line import/no-cycle
import widgetCreateAccountConfig from '@/components/template-builder/config/widgets/widget-create-account-config';
// eslint-disable-next-line import/no-cycle
import widgetNewsletterSubscriptionConfig from '@/components/template-builder/config/widgets/widget-newsletter-subscription';
// eslint-disable-next-line import/no-cycle
import widgetNewsletterUnsubscriptionConfig from '@/components/template-builder/config/widgets/widget-newsletter-unsubscription';
// eslint-disable-next-line import/no-cycle
import widgetFacebookMessengerConfig from '@/components/template-builder/config/widgets/widget-facebook-messenger';
// eslint-disable-next-line import/no-cycle
import widgetImportZipConfig from '@/components/template-builder/config/widgets/widget-import-zip-config';

import deepcopy from 'deepcopy';

type WidgetConfigMap = {
  [key: string]: WidgetTypeConfig;
};

const getWidgetConfigMap = (templateType: TemplateParentTypeEnum): WidgetConfigMap => ({
  [WidgetTypeEnum.SEPARATOR]: getWidgetSeparatorConfig(templateType),
  [WidgetTypeEnum.BUTTON]: getWidgetButtonConfig(templateType),
  [WidgetTypeEnum.GDPR_BUTTON]: getWidgetButtonConfig(templateType),
  [WidgetTypeEnum.TEXT]: getWidgetTextConfig(templateType),
  [WidgetTypeEnum.TITLE]: widgetTitleConfig,
  [WidgetTypeEnum.SMART_PRODUCT_LIST]: getWidgetSmartProductListConfig(templateType),
  [WidgetTypeEnum.IMAGE]: getWidgetImageConfig(templateType),
  [WidgetTypeEnum.CODE]: widgetCodeConfig,
  [WidgetTypeEnum.VOUCHER]: widgetVoucherConfig,
  [WidgetTypeEnum.SHARE_SOCIAL_MEDIA]: widgetShareSocialMediaConfig,
  [WidgetTypeEnum.SOCIAL_MEDIA]: getWidgetSocialMediaConfig(templateType),
  [WidgetTypeEnum.CREATE_ACCOUNT]: widgetCreateAccountConfig,
  [WidgetTypeEnum.NEWSLETTER_SUBSCRIPTION]: widgetNewsletterSubscriptionConfig,
  [WidgetTypeEnum.NEWSLETTER_UNSUBSCRIPTION]: widgetNewsletterUnsubscriptionConfig,
  [WidgetTypeEnum.FACEBOOK_MESSENGER]: widgetFacebookMessengerConfig,
  [WidgetTypeEnum.IMPORT_ZIP]: widgetImportZipConfig,
});

const getWidgetConfig = (
  templateType: TemplateParentTypeEnum, widgetType: WidgetTypeEnum, columnTabItemConfig: TabItem, sectionTabItemConfig?: TabItem, lineTabItemConfig?: TabItem,
): ConfigurationPanelStructure => {
  const widgetTypeConfig = deepcopy(getWidgetConfigMap(templateType)[widgetType]);

  const items: Array<TabItem> = [
    {
      type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
      tabItemType: TemplateStructureEnum.WIDGET,
      label: 'templateBuilder.configs.widget',
      ...widgetTypeConfig,
    },
  ];

  if (columnTabItemConfig && widgetType !== WidgetTypeEnum.IMPORT_ZIP) {
    if (items.length && columnTabItemConfig.tabPanel) {
      // eslint-disable-next-line no-unused-expressions
      items[0].tabPanel?.items.push(
        ...columnTabItemConfig.tabPanel?.items,
      );
    }
  }

  if (lineTabItemConfig && widgetType !== WidgetTypeEnum.IMPORT_ZIP) {
    if (items.length && lineTabItemConfig.tabPanel) {
      // eslint-disable-next-line no-unused-expressions
      items[0].tabPanel?.items.push(
        ...lineTabItemConfig.tabPanel?.items,
      );
    }
  }

  // In case of widget edition, we do not display the section's settings
  if (!widgetType && sectionTabItemConfig) {
    if (items.length && sectionTabItemConfig.tabPanel) {
      // eslint-disable-next-line no-unused-expressions
      items[0].tabPanel?.items.push(
        ...sectionTabItemConfig.tabPanel?.items,
      );
    }
  }

  return { items };
};

export default getWidgetConfig;
