
import { defineComponent, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { SpmTableAction, SpmTableColumns, SpmTableState } from '@/types';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { Get } from '@/composables/GraphQL';
import { Shops } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import { formatMysqlDateToLocale } from '@/helpers/Date';
import Badge from 'primevue/badge';
import Tooltip from 'primevue/tooltip';
import Button from 'primevue/button';

export default defineComponent({
  name: 'SyncList',
  components: {
    SpmTable,
    Badge,
    Button,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const { t, locale } = useI18n();

    const actions: SpmTableAction[] = [
      {
        icon: 'far fa-eye',
        label: t('myShopData.syncs.viewData'),
        show: () => true,
        callback: (sync: Record<string, string>) => router.push({ name: `shop.syncs.${sync.type}` }),
      },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'type',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'name',
        header: t('myShopData.syncs.name'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'last_date_sync',
        header: t('myShopData.syncs.lastDateSync'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: '',
        header: t('templates.actions'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:10%',
        type: 'action',
        actions,
      },
    ];

    // We are only interested with "selectedItems" here, unless "items" are to be transformed ...
    const tableData = ref<SpmTableState>({
      items: [],
      total: 1,
      error: '',
      selectedItems: [],
    });

    onBeforeMount(async () => {
      // Get date for last synchronizations
      const { item, err } = await Get<Shops>({
        id: UserState.activeShop?.id ?? 0,
        name: 'Shops',
        keyName: 'id_shop',
        fields: [
          'last_update_customers',
          'last_update_customers_groups',
          'last_update_products',
          'last_update_products_categories',
          'last_update_vouchers',
          'last_update_carriers',
          'last_update_manufacturers',
          'last_update_orders_status',
          'last_update_orders',
        ],
      });

      if (err) {
        return null;
      }

      if (!item) {
        return null;
      }

      Object.keys(item).forEach((key: string) => {
        if (key !== '__typename') {
          const syncName = key.replace('last_update_', '');
          tableData.value.items.push({
            type: syncName,
            name: t(`myShopData.syncs.${syncName}`),
            last_date_sync: formatMysqlDateToLocale(locale.value, item[key as keyof Shops]) ?? '',
          });
        }
      });

      return null;
    });

    return {
      t,
      columns,
      tableData,
    };
  },
});
