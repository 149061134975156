
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import { WidgetTitleData } from '@/components/stats-builder/metadata/WidgetTitleMetadata';
import { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';
import StatsWidgetTitle from '@/components/stats/StatsWidgetTitle.vue';

export default defineComponent({
  name: 'StatsWidgetTitleRender',
  components: {
    StatsWidgetTitle,
  },

  props: {
    widgetData: {
      type: Object as PropType<WidgetTitleData>,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { widgetData: WidgetTitleData}) {
    const config = ref(props.widgetData);
    const updateWidgetRender = ref(0);

    watch(() => props.widgetData, async () => {
      const meta = getComponentStatsWidgetMetadata('StatsWidgetTitle');
      if (!meta) {
        return;
      }
      let updateRender = false;
      if (meta.Validate) {
        const valid = await meta.Validate(props.widgetData);
        updateRender = valid.success;
      }

      if (updateRender) {
        /* Si les données sont valides, on rafraichi le composant */
        config.value = props.widgetData;
        updateWidgetRender.value += 1;
      }
    });

    return {
      config,
      updateWidgetRender,
    };
  },
});
