<template>
  <div v-if="synchroData && synchroData.total > 0">
    <SpmOverlayPanel
      class-trigger="flex align-items-center justify-content-center"
      class-header="flex justify-content-between flex-wrap header-content"
      class-content="flex flex-column"
      min-panel-width="380px"
      max-panel-width="400px"
      @onclose-panel="clearInterval(handle.value)"
    >
      <template #trigger>
        <SpmButton
          :label="t('myLists.synchronize.inProgress')"
          :badge-text="synchroData.total.toString(10)"
          class-style="p-button p-button-secondary badge"
          @click="launchSync"
        />
      </template>

      <SpmTable
        v-model="synchroData"
        :id-shop="idShop"
        :paginator="false"
        :show-grid-lines="true"
        class="p-badge"
        :table-columns="synchroColumns"
        index="id_shop_syncs"
        name=""
      >
        <template #progress="columnProps">
          <ProgressBar :value="columnProps.data.progress" />
        </template>
      </SpmTable>
    </SpmOverlayPanel>
  </div>
  <div v-else>
    <SpmButton
      :label="t('myLists.synchronize.noneInProgress')"
      class-style="p-button p-button-secondary"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onUnmounted, PropType, ref, watch,
} from 'vue';
import ProgressBar from 'primevue/progressbar';
import SpmTable from '@/components/table/SpmTable.vue';
import { ShopsSyncs } from '@/types/generated-types/graphql';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { ListResult } from '@/composables/GraphQL';
import { SpmTableColumns } from '@/types';
import ShopsSyncsList from '@/composables/shop/ShopSyncs';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ShopsSyncsInProgress',

  components: {
    SpmOverlayPanel,
    SpmButton,
    SpmTable,
    ProgressBar,
  },

  props: {
    tableData: {
      type: Object as PropType<ListResult<ShopsSyncs>>,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    launchFetch: {
      type: Boolean,
      required: false,
      default: false,
    },

    syncType: {
      type: String,
      required: true,
    },
  },

  setup(props: {tableData: ListResult<ShopsSyncs>; idShop: number; launchFetch: boolean; syncType: string}) {
    const synchroData = ref<ListResult<ShopsSyncs>>(props.tableData);
    const handle = ref();
    const progressKey = ref(0);
    const { t } = useI18n();

    const synchroColumns: SpmTableColumns[] = [
      {
        field: 'date_creation',
        header: 'Date',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'progress',
        header: 'Progress',
        sortable: false,
        filterable: false,
        editable: false,
        style: 'width:50%',
        type: 'text',
      },
    ];

    const insertShopsSyncs = async () => {
      synchroData.value = await ShopsSyncsList(props.syncType, props.idShop, 'user');
      if (synchroData.value.total === 0) {
        clearInterval(handle.value);
      }
    };

    const launchSync = async () => {
      handle.value = setInterval(async () => insertShopsSyncs(),
        3000);
      progressKey.value = +1;
    };

    watch(props, () => {
      if (props.launchFetch) {
        launchSync();
      }
    });

    onUnmounted(() => {
      clearInterval(handle.value);
    });

    return {
      t, synchroData, synchroColumns, launchSync, handle,
    };
  },
});
</script>

<style scoped lang="scss">
.badge {
  color: #ffffff;
  background-color: #4CAF50;
}
</style>
