<template>
  <div class="flex align-items-center">
    <Calendar
      v-model="value"
      date-format="dd/mm/yy"
      selection-mode="single"
      :hide-on-range-selection="true"
      :manual-input="false"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, ref, SetupContext, watch,
} from 'vue';
import Calendar from 'primevue/calendar';

export default defineComponent({
  name: 'SegmentDateInput',

  components: { Calendar },

  props: {
    modelValue: {
      type: Date,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: Date }, { emit }: SetupContext) {
    const value = ref(props.modelValue);

    watch(value, () => {
      emit('update:modelValue', value.value);
    });

    return {
      value,
    };
  },
});
</script>

<style scoped>

</style>
