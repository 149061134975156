// eslint-disable-next-line import/no-cycle
import {
  ComponentData, ComponentMetadata, ErrorConfigForm, Period,
} from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import { decreaseNumberOfBranchesOfAnOperator } from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import {
  integer, maxLength, minLength, required, requiredIf,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';

export interface FiltreClicklinkData {
  custom_title: string;
  custom_description: string;
  outputs_number: number;
  initial_number_outputs?: null|number; // for updating outputs
  grant_additional_delay: string;
  extra_time: Period;
  type: string;
  linked_box: string;
}

const FiltreClicklinkMetadata: ComponentMetadata<FiltreClicklinkData> = {
  Meta: {
    kind: 'filtre',
    id: 'clicklink',
    label: 'automatedScenarios.filters.clickLink',
    icon: 'fa-link',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxvisitpage',
        ],
      },
      bulk: {
        next_operators: [
          'boxchoosebulklist',
        ],
      },
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: ['boxsendemail', 'boxsendfbmessenger', 'boxdisplayfreemodel', 'boxdisplayfreepopup', 'boxsendsms'],
        },
      },
      bulk: {
        operators: {
          any_parents: ['boxsendemail', 'boxsendfbmessenger', 'boxdisplayfreemodel', 'boxdisplayfreepopup', 'boxsendsms'],
        },
      },
    },
    component: 'FiltreClicklink',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
  },
  Create(data: FiltreClicklinkData = {
    custom_title: '',
    custom_description: '',
    outputs_number: 1,
    grant_additional_delay: '1',
    extra_time: {
      value: 1,
      unit: 'DAY',
    },
    type: 'click',
    linked_box: '[]',
  }): ComponentData<FiltreClicklinkData> {
    return {
      id: 'clicklink',
      form_name: 'clicklink',
      logo_box: 'fa-link',
      ...data,
    };
  },
  async Validate(data: FiltreClicklinkData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      custom_title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      outputs_number: {
        required,
        includesValuesValidator: includesValuesValidator([1, 2]),
      },
      grant_additional_delay: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      extra_time: {
        value: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          integer,
        },
        unit: {
          required: requiredIf(() => data.grant_additional_delay === '1'),
          includesValuesValidator: includesValuesValidator(['SECOND', 'MINUTE', 'HOUR', 'DAY']),
        },
      },
      type: {
        required,
        includesValuesValidator: includesValuesValidator(['click', 'noclick']),
      },
      linked_box: {
        required,
        minLength: minLength(4),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  BeforeInsertData(data: FiltreClicklinkData): FiltreClicklinkData {
    const dataToReturn: FiltreClicklinkData = data;
    const { initial_number_outputs } = dataToReturn;

    if (typeof dataToReturn.initial_number_outputs !== 'undefined') {
      delete dataToReturn.initial_number_outputs;
    }

    if (initial_number_outputs !== null && initial_number_outputs !== undefined
      && data.outputs_number < initial_number_outputs) {
      /* En cas de dimunition du nombre de branches, on supprime la branche "non" */
      decreaseNumberOfBranchesOfAnOperator(['output_2']);
    }

    return dataToReturn;
  },
  Outputs(data: FiltreClicklinkData): number {
    return data.outputs_number;
  },
  Label(data: FiltreClicklinkData): string {
    return data.custom_title;
  },
};

export default FiltreClicklinkMetadata;
