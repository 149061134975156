import { Property } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

/* eslint-disable import/prefer-default-export */
export const setDivAlignmentClass = (selector: string, property: Property) => {
  if (undefined !== property.value) {
    const { name, value } = property;
    if (value) {
      const template = getTemplateIframeDocument();
      const elements = template?.querySelectorAll(selector);

      elements.forEach((currentElement) => {
        const element = currentElement as HTMLElement;
        if (element) {
          element.classList.forEach((className) => {
            // Remove old classes
            if (new RegExp(`^${name}`).test(className)) {
              element.classList.remove(className);
            }
          });

          element.classList.add(`${name}${value}`);
          const { parentElement } = element;

          if (parentElement) {
            parentElement.classList.forEach((className) => {
              // Remove old classes
              if (new RegExp(`^${name}`).test(className)) {
                parentElement.classList.remove(className);
              }
            });

            parentElement.classList.add(`${name}${value}_parent`);
          }
        }
      });
    }
  }
};
