<template>
  <Button
    class="p-button-sm p-button-plain"
    :class="selected ? 'p-button-raised' : 'p-button-outlined'"
    data-test-id="button"
    @click="handleClick"
  >
    <span>{{ t(label) }}</span>
  </Button>
</template>

<script lang="ts">
import {
  computed,
  ComputedRef,
  defineComponent, onBeforeUnmount, PropType, ref, Ref,
} from 'vue';
import Button from 'primevue/button';
import { ParserFieldObject, FieldConfig } from '@/types';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TriggerButton',
  components: { Button },
  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props: {parserValues: ParserFieldObject; configs: FieldConfig}) {
    const { t } = useI18n();

    const selected: Ref<boolean> = ref(false);

    const label: ComputedRef<string> = computed(() => (selected.value ? props.configs.options.disableLabel : props.configs.options.activateLabel));

    const handleClick = () => {
      selected.value = !selected.value;
      props.configs.options.callback(props.parserValues.selector);
    };

    onBeforeUnmount(() => {
      props.configs.options.restore(props.parserValues.selector);
    });

    return {
      t,
      label,
      selected,
      handleClick,
    };
  },
});
</script>
