<template>
  <div>
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label
          class="block my-3"
          data-test-id="dropdown-label"
        >
          {{ t(configs.label, null) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <BaseDropdown
      :model-value="selectedValue"
      :options="options"
      class="unit-input"
      :class="{ 'hidden': !displayProperties }"
      @update:model-value="handleSelected"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, PropType, Ref, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { FieldConfig, ParserFieldObject, Property } from '@/types';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'Dropdown',

  components: {
    ToggleDisplaySettings,
    BaseDropdown,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const property: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[0]);
    const selectedValue: Ref<string> = ref(property.value.value ?? props.configs.options.defaultValue);
    const options = ref([]);

    const handleSelected = (value: string) => {
      selectedValue.value = value;
      if (property.value) {
        property.value.value = selectedValue.value;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [property.value],
        });
      }
    };

    onBeforeMount(async () => {
      try {
        options.value = await props.configs.options.list;
      } catch (error) {
        options.value = [];
      }
    });

    return {
      t,
      displayProperties,
      selectedValue,
      options,
      handleSelected,
    };
  },

});
</script>
