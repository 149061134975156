import { ComponentNameEnum, TextStylesValueEnum, BaseButtonFieldConfig } from '@/types';

const textStylesFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  multiple: true,
  options: {
    [TextStylesValueEnum.UPPERCASE]: {
      title: 'templateBuilder.fields.uppercase',
      iconClass: 'fa-text-height',
      property: 'text-transform',
      defaultValue: 'none',
    },
    [TextStylesValueEnum.BOLD]: {
      title: 'templateBuilder.fields.bold',
      iconClass: 'fa-bold',
      property: 'font-weight',
      defaultValue: 'normal',
    },
    [TextStylesValueEnum.ITALIC]: {
      title: 'templateBuilder.fields.italic',
      iconClass: 'fa-italic',
      property: 'font-style',
      defaultValue: 'normal',
    },
    [TextStylesValueEnum.UNDERLINE]: {
      title: 'templateBuilder.fields.underline',
      iconClass: 'fa-underline',
      property: 'text-decoration-line',
      defaultValue: '',
      multipleChoice: true,
    },
    [TextStylesValueEnum.STRIKETHROUGH]: {
      title: 'templateBuilder.fields.strikethrough',
      iconClass: 'fa-strikethrough',
      property: 'text-decoration-line',
      defaultValue: '',
      multipleChoice: true,
    },
  },
};

export default textStylesFieldConfig;
