// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioAnalyticsDateInterval,
  AutomatedScenarioState,
  setAnalyticsWorkflowData,
} from '@/composables/AutomatedScenarios';
// eslint-disable-next-line import/no-cycle
import { TypeCampaignEnum } from '@/types';
import { toRaw } from 'vue';
import moment from 'moment';
import { nestGet } from '@/composables/nestApi';

export enum typeCampaignAnalyticsEnum {
  DELIVERED = 'delivered',
  STOPPED = 'stopped',
  PAUSED = 'paused',
  BLOCKED_BY_MARKETING_PRESSURE = 'blockedByMarketingPressure',
  FINISHED = 'finished',
  SEND = 'send',
  SALES = 'sales',
  OPEN = 'open',
  CLICK = 'click',
  BOUNCE = 'bounce',
  UNSUB = 'unsub',
  SPAM = 'spam',
}

function getDateRange(dateInterval: AutomatedScenarioAnalyticsDateInterval) {
  let dateFrom;
  const { interval } = dateInterval;
  let dateTo = moment().format('YYYYMMDD23');

  switch (interval) {
    case 'lastDay':
      dateFrom = moment().subtract(1, 'days').format('YYYYMMDD00');
      break;
    case 'last3Days':
      dateFrom = moment().subtract(3, 'days').format('YYYYMMDD00');
      break;
    case 'last7Days':
      dateFrom = moment().subtract(7, 'days').format('YYYYMMDD00');
      break;
    case 'last14Days':
      dateFrom = moment().subtract(14, 'days').format('YYYYMMDD00');
      break;
    case 'last30Days':
      dateFrom = moment().subtract(30, 'days').format('YYYYMMDD00');
      break;
    case 'last3Months':
      dateFrom = moment().subtract(3, 'months').format('YYYYMMDD00');
      break;
    case 'last12Months':
      dateFrom = moment().subtract(12, 'months').format('YYYYMMDD00');
      break;
    case 'customDateRange':
      if (dateInterval.customDateRange && dateInterval.customDateRange.length === 2) {
        dateFrom = moment(dateInterval.customDateRange[0]).format('YYYYMMDD00');
        dateTo = moment(dateInterval.customDateRange[1]).format('YYYYMMDD23');
      } else {
        dateFrom = moment().subtract(14, 'days').format('YYYYMMDD00');
      }
      break;
    default:
      dateFrom = moment().subtract(14, 'days').format('YYYYMMDD00');
      break;
  }

  return {
    dateFrom,
    dateTo,
  };
}

function getDateRangeForBulk(dateInterval: AutomatedScenarioAnalyticsDateInterval, dateSend: string) {
  let from = moment();
  if (dateSend) {
    from = moment(dateSend, 'YYYYMMDDHHmmss');
  }

  let dateFrom = from.clone().format('YYYYMMDDHH');

  const { interval } = dateInterval;
  let dateTo;

  switch (interval) {
    case 'nextDay':
      dateTo = from.clone().add(1, 'days').format('YYYYMMDDHH');
      break;
    case 'next3Days':
      dateTo = from.clone().add(3, 'days').format('YYYYMMDDHH');
      break;
    case 'next7Days':
      dateTo = from.clone().add(7, 'days').format('YYYYMMDDHH');
      break;
    case 'next14Days':
      dateTo = from.clone().add(14, 'days').format('YYYYMMDDHH');
      break;
    case 'next30Days':
      dateTo = from.clone().add(30, 'days').format('YYYYMMDDHH');
      break;
    case 'next3Months':
      dateTo = from.clone().add(3, 'months').format('YYYYMMDDHH');
      break;
    case 'next12Months':
      dateTo = from.clone().add(12, 'months').format('YYYYMMDDHH');
      break;
    case 'customDateRange':
      if (dateInterval.customDateRange && dateInterval.customDateRange.length === 2) {
        dateFrom = moment(dateInterval.customDateRange[0]).format('YYYYMMDDHH');
        dateTo = moment(dateInterval.customDateRange[1]).format('YYYYMMDD23');
      } else {
        dateFrom = moment().subtract(14, 'days').format('YYYYMMDDHH');
      }
      break;
    default:
      dateFrom = moment().subtract(14, 'days').format('YYYYMMDDHH');
      break;
  }

  return {
    dateFrom,
    dateTo,
  };
}

export const setWorkflowAnalyticsData = async (idShop: number, typeCampaign: TypeCampaignEnum, idCampaign: number) => {
  const postData: any = {
    idCampaign,
    idShop,
    typeCampaign,
    filters: typeCampaign === TypeCampaignEnum.AUTOMATION ? getDateRange(AutomatedScenarioState.scenario.analyticsMode.dateInterval)
      : getDateRangeForBulk(AutomatedScenarioState.scenario.analyticsMode.dateInterval, AutomatedScenarioState.scenario.dateSend || ''),
  };

  if (postData.typeCampaign === TypeCampaignEnum.AUTOMATION && (!postData.filters || !postData.filters.dateFrom
     || postData.filters.dateFrom === 'Invalid date')) {
    return;
  }

  if (postData.typeCampaign === TypeCampaignEnum.BULK && (!postData.filters || !postData.filters.dateFrom || !postData.filters.dateTo
      || postData.filters.dateTo === 'Invalid date' || postData.filters.dateFrom === 'Invalid date')) {
    return;
  }

  const dataQuery = JSON.stringify(postData);

  let data = null;
  try {
    const result = await nestGet('v4', '/workflow/getStats', {}, `?data=${dataQuery}`);

    if (result && result.success && result.statistics) {
      data = result.statistics;
    }
  } catch (error) {
    data = null;
  }

  setAnalyticsWorkflowData(data);
};

export const getOperatorAnalyticsData = async (idOperator: string) => {
  let data = null;
  const workflowAnalyticsData = AutomatedScenarioState.scenario.analyticsMode.data;
  if (workflowAnalyticsData && Object.prototype.hasOwnProperty.call(workflowAnalyticsData, idOperator)) {
    data = toRaw(workflowAnalyticsData[idOperator]);
  }

  return data;
};
