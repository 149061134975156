<template>
  <div
    class="flex flex-column justify-content-center"
    style="margin: auto; width: 70%"
  >
    <div class="flex auth-logo-icon">
      <img
        src="@/assets/images/logo-sm-green.svg"
        width="100%"
        alt="logo Icon"
      >
    </div>
    <h3 class="flex">
      {{ t('user.register.welcome') }}
    </h3>
    <p class="flex text-page my-2">
      {{ t('user.register.title') }}
    </p>

    <div class="flex flex-column">
      <div class="flex flex-column">
        <BaseTextInput
          v-model="userInfo.email"
          class="mb-3"
          field-id="email"
          label="user.register.email"
          input-style="width: 100%;"
          :errors="formValidation"
        />
        <BaseTextInput
          v-model="userInfo.password"
          class="mb-3"
          type="password"
          input-type="password"
          field-id="password"
          label="user.register.password"
          input-style="width: 100%;"
          :errors="formValidation"
        />
        <BaseTextInput
          v-model="userInfo.confirmPassword"
          class="mb-3"
          type="password"
          input-type="password"
          field-id="confirmPassword"
          label="user.register.confirmPassword"
          input-style="width: 100%;"
          :errors="formValidation"
        />
        <BaseTextInput
          v-model="userInfo.promoCode"
          class="mb-3"
          field-id="promoCode"
          label="user.register.promoCode"
          input-style="width: 100%;"
          :errors="formValidation"
        />
        <BaseTextInput
          v-model="userInfo.shopUrl"
          class="mb-3"
          field-id="shopUrl"
          label="user.register.shopUrl"
          input-style="width: 100%;"
          placeholder="https://..."
          :errors="formValidation"
        />
        <div class="field mb-5">
          <span class="field-wrapper">
            <label
              class="block field-label mb-2"
            >
              {{ t('user.register.ecommerce') }}
            </label>

            <Dropdown
              v-model="userInfo.solutionEcommerce"
              :options="solutions"
              option-label="label"
              option-value="code"
              :placeholder="t('user.register.ecommerce')"
            />
          </span>
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidation"
            field="solutionEcommerce"
          />
        </div>
        <div class="mb-3">
          <div class="flex">
            <Checkbox
              id="binary"
              v-model="userInfo.acceptTAC"
              binary
            />
            <p class="text-page ml-2">
              {{ t('user.register.acceptText') }}
              <a
                href="#"
                data-toggle="modal"
                data-target="#modalCGV"
                class="tc-primary font-bold"
                @click="showTACModal = true"
              >{{ t('user.register.TAC') }}</a>
            </p>
          </div>
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidation"
            field="acceptTAC"
          />
        </div>
      </div>

      <div class="flex flex-column">
        <SpmButton
          :label="t('user.register.submit')"
          :loading="isLoadingForm"
          style="width: 100%"
          class="flex p-button p-button-success"
          @click="validateRegisterForm"
        />
      </div>

      <div class="flex align-items-center justify-content-center text-page mt-3">
        {{ t('user.register.accountPresent') }}
        <router-link
          :to="{ name: 'login' }"
          class="text-page ml-1"
        >
          {{ t('user.register.logIn') }}
        </router-link>
      </div>
    </div>

    <TermsAndConditions
      v-if="showTACModal"
      @on-close-modal="showTACModal = false"
    />
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import { defineComponent, reactive, ref } from 'vue';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import {
  helpers, maxLength, minLength, required, sameAs,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import solutions from '@/configs/ecommerce-solutions';
import { emailFormatValidator, urlFormatValidator } from '@/helpers/CustomValidator';
import { nestPost } from '@/composables/nestApi';
import { useRouter } from 'vue-router';
import { getItemFromLocalStorageWithExpiry, showToastError, showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'SpeedRegistration',
  components: {
    FieldErrors,
    TermsAndConditions,
    Dropdown,
    SpmButton,
    Checkbox,
    BaseTextInput,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const isLoadingForm = ref(false);
    const formValidation = ref<any>({});
    const componentFieldErrorsKey = ref(0);
    const showTACModal = ref(false);
    const promoCodePartner = ref(getItemFromLocalStorageWithExpiry('promoCodePartner') ?? '');
    const userInfo = reactive({
      email: '',
      password: '',
      confirmPassword: '',
      promoCode: promoCodePartner.value,
      shopUrl: '',
      solutionEcommerce: '',
      acceptTAC: false,
    });

    const validateRegisterFormData = async (): Promise<ErrorConfigForm> => {
      const rules = {
        email: {
          emailFormatValidator: emailFormatValidator(),
        },

        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(40),
        },

        confirmPassword: {
          required,
          sameAsPassword: sameAs(userInfo.password),
        },

        shopUrl: {
          urlFormatValidator: urlFormatValidator(),
        },

        solutionEcommerce: {
          required,
        },

        acceptTAC: {
          isChecked: helpers.withMessage(t('customValidator.acceptTAC'), (value: any) => value),
        },
      };

      const v$ = useVuelidate(rules, userInfo);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const validateRegisterForm = async () => {
      isLoadingForm.value = true;
      const validation = await validateRegisterFormData();
      if (!validation.success) {
        formValidation.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        isLoadingForm.value = false;
      } else {
        try {
          const result = await nestPost('v4', '/user/create', {}, {
            email: userInfo.email,
            password: userInfo.password,
            shopUrl: userInfo.shopUrl,
            solutionEcommerce: userInfo.solutionEcommerce,
            promoCode: userInfo.promoCode,
          });

          if (result && result.success) {
            await showToastSuccess(t('user.register.actionRegistration.success'));
            await router.push('/auth/login');
          } else {
            const reasonError = result.reason ?? '';
            switch (reasonError) {
              case 'data_incorrect':
                await showToastError(t('user.register.actionRegistration.dataIncorrect'));
                break;
              case 'email_already_exist':
                await showToastError(t('user.register.actionRegistration.emailAlreadyExist'));
                break;
              default:
                await showToastError(t('errorMessages.GENERIC_ERROR'));
            }
          }
        } catch (e) {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        isLoadingForm.value = false;
      }
    };

    return {
      t,
      formValidation,
      componentFieldErrorsKey,
      userInfo,
      isLoadingForm,
      solutions,
      showTACModal,
      validateRegisterForm,
    };
  },
});
</script>

<style scoped lang="scss">
.label {
  display: inline-block;
  margin-bottom: .5rem;
}
</style>

<style scoped lang="scss">
.text-page {
  font-size: 14px;
  line-height: 21px;
  color: rgb(107, 114, 128);
}
.auth-logo-icon {
  width: 6rem;
  margin-bottom:20px;
}
</style>
