<template>
  <div v-if="getInitialized">
    <div class="flex justify-content-between flex-wrap mb-4">
      <div class="flex align-items-center justify-content-center">
        <h3>
          {{ t('segmentation.panelTitle') }}
        </h3>
      </div>
      <div class="flex align-items-center justify-content-center gap-1">
        <Tag
          class="contact-number-container"
          severity="info"
        >
          <div class="flex gap-1 justify-content-center align-items-center">
            <span>Total de la cible:</span>
            <span v-if="!loadContactsNumber">
              {{ formatNumbersInString(totalContactsNumber.toString()) }} contacts
            </span>
            <Skeleton
              v-else
              height="0.75rem"
              width="4rem"
            />
          </div>
        </Tag>
        <Button
          icon="far fa-arrows-rotate"
          class="p-button-text p-button-secondary p-button-xs"
          :loading="loadContactsNumber"
          @click="handleContactsNumber"
        />
      </div>
    </div>
    <div class="flex flex-column gap-2 flex-grow-1 rule-list relative">
      <div
        v-for="(blockAND, index) in segmentsConfiguration"
        :key="`blockAND-${index}-${cleanedDataKey}`"
        class="rule-container relative"
      >
        <span v-if="index === 0" class="first-rule"></span>
        <Tag
          v-else
          :value="t('automatedScenarios.fields.logicalOperators.and')"
          class="other-rule px-2"
        />
        <SegmentationBlockAND
          :key="`blockAND-${index}-${refreshComponents}`"
          :index-block-and="index"
          :block-and="blockAND"
        />
      </div>
      <div
        class="add-block-container flex align-items-center gap-2 mb-2"
      >
        <div
          class="and-tag"
          v-if="segmentsConfiguration.length"
        >
          <Tag
            :value="t('automatedScenarios.fields.logicalOperators.and')"
            class="px-2"
          />
        </div>
        <Button
          :label="t('segmentation.addBlock')"
          class="p-button-secondary"
          icon="far fa-plus"
          @click="addBlockAND"
        />
      </div>
    </div>
  </div>
  <Loader v-else />
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onBeforeMount, watch, ref,
} from 'vue';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import SegmentationBlockAND from '@/components/segmentations/SegmentationBlockAND.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import Loader from '@/components/layout/Loader.vue';
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import axios from "axios";
import {UserState} from "@/composables/User";
import {showToastError} from "@/helpers";
import { formatNumbersInString } from '@/helpers/Number';

export default defineComponent({
  name: 'SegmentationsPanel',
  components: {
    Loader,
    SegmentationBlockAND,
    Button,
    SpmButton,
    Tag,
    Skeleton,
  },

  props: {
    origin: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props: { origin: string}) {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const cleanedDataKey = ref(0);
    const segmentsConfiguration: any = computed(() => store.getters['segmentationsEditor/getConfiguration']);

    const refreshComponents: any = computed(() => store.getters['segmentationsEditor/getRefreshComponents']);

    const getInitialized: any = computed(() => store.getters['segmentationsEditor/getInitialized']);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const refreshContactsNumber = computed(() => store.getters['segmentationsEditor/getRefreshContactsNumber']);

    const loadContactsNumber = ref(false);

    const totalContactsNumber = ref(0);

    const addBlockAND = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/addBlockAND');
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
    };

    const cleanData = (data: any) => {
      const cleanedData = data.map((subArray: any) => subArray.filter((obj: any) => Object.keys(obj).length !== 0));
      return cleanedData.filter((subArray: any) => subArray.length > 0);
    };

    watch([segmentsConfiguration], () => {
      if (!segmentsConfiguration.value.length || !segmentsConfiguration.value) {
        store.commit('segmentationsEditor/initConfiguration');
      }
    }, { deep: true });

    const handleContactsNumber = async () => {
      loadContactsNumber.value = true;
      const tmpCleanedData = cleanData(segmentsConfiguration.value);
      try {
        const result = await axios.post(
          `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/count`,
          {
            idShop,
            config: tmpCleanedData,
          },
        );

        if (result && (result.status === 200 || result.status === 201)) {
          totalContactsNumber.value = result.data;
        } else {
          totalContactsNumber.value = 0;
          await showToastError('GENERIC_ERROR');
        }
      } catch (e) {
        totalContactsNumber.value = 0;
        await showToastError('GENERIC_ERROR');
      }


      loadContactsNumber.value = false;
      store.commit('segmentationsEditor/setRefreshContactsNumber', false);
    }

    watch([refreshContactsNumber], async () => {
      if (refreshContactsNumber.value) {
        await handleContactsNumber();
      }
    });

    onBeforeMount(async () => {
      await store.dispatch('segmentationsEditor/initialization',
        { originCall: props.origin });
      await handleContactsNumber();
    });

    return {
      t,
      cleanedDataKey,
      getInitialized,
      refreshComponents,
      segmentsConfiguration,
      loadContactsNumber,
      totalContactsNumber,
      addBlockAND,
      handleContactsNumber,
    };
  },

  methods: { formatNumbersInString }
});
</script>

<style lang="scss" scoped>
.rule-list {
  margin-left: calc(10px - 2rem);

  .rule-container {
    padding-left: calc(2 * 2rem - 10px - 2px);
  }

  >:not(:last-child)::before {
    border-color: #dee2e6;
    border-style: solid;
    content: "";
    left: 25px;
    position: absolute;
    width: 25px;
    border-width: 0 0 3px 3px;
  }

  >:first-child::before {
    top: -0.2rem;
  }

  >.rule-container::before {
    height: calc(50% + 0.4rem);
  }

  >.rule-container::after {
    height: calc(50% + 0.8rem);
    top: calc(50% - 0.25rem);
  }

  >:not(:last-child)::after {
    border-color: #dee2e6;
    border-width: 0 0 0 3px;
    border-style: solid;
    content: "";
    left: 25px;
    position: absolute;
    width: 25px;
  }

  .first-rule::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: 26.5px;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border-color: #dee2e6;
    border-style: solid;
    background: #dee2e6;
  }

  .other-rule {
    position: absolute;
    top: calc(50% - 0.4rem);
    left: 25px;
    transform: translateX(-50%);
    z-index: 1;
    background-color: #e9ecef;
    color: black;
  }

  .add-block-container {
    margin-left: calc(1.5rem - 10px - 3px);
  }
}
.contact-number-container {
  background-color: #f8f9fa !important;
  color: #495057 !important;
  border: 1px solid #dee2e6;
}
</style>

<style lang="scss">
.and-tag .p-tag {
  background-color: #e9ecef;
  color: black;
}
</style>
