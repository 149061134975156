<template>
  <Message
    data-test-id="information-message"
    :closable="false"
    class="information-message"
    :severity="severity"
    v-html="t(message)"
  />
</template>

<script lang="ts">
import Message from 'primevue/message';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BaseInformation',

  components: {
    Message,
  },

  props: {
    message: {
      type: String,
      required: true,
    },

    severity: {
      type: String,
      required: false,
      default: 'info',
    },
  },

  setup() {
    const { t } = useI18n();

    return { t };
  },
});
</script>

<style scoped lang="scss">
.information-message {
  padding: 0.5rem;
  margin: 0;
  :deep() .p-message-wrapper {
     padding: 1rem 1rem;
     .p-message-icon {
       display: none;
     }
   }
}
</style>
