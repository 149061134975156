import { Update } from '@/composables/GraphQL';
import {
  ShopsLangUpdateInputItem,
} from '@/types/generated-types/graphql';

export default async function updateShopsLang(shopsLangInput: ShopsLangUpdateInputItem[]) {
  const {
    id, status, messages, err,
  } = await Update<ShopsLangUpdateInputItem>({
    name: 'ShopsLang',
    input: shopsLangInput,
    type: 'ShopsLangUpdateInput',
  });

  if (err === '') {
    return id;
  }
  return messages;
}
