import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  LinkTypeEnum,
  TemplateParentTypeEnum,
  TabItem,
  TemplateStructureEnum,
} from '@/types';
import getInputVariablesFieldConfig from '@/components/template-builder/config/fields/input-variables-config';
import getLinkFieldConfig from '@/components/template-builder/config/fields/link-config';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import emailButtonFieldsConfig from '@/components/template-builder/config/templates/email/widgets/widget-button-fields-config';
// eslint-disable-next-line import/no-cycle
import popupButtonFieldsConfig from '@/components/template-builder/config/templates/popup/widgets/widget-button-fields-config';
// eslint-disable-next-line import/no-cycle
import embedButtonFieldsConfig from '@/components/template-builder/config/templates/embed/widgets/widget-button-fields-config';
// eslint-disable-next-line import/no-cycle
import {
  setAttribute,
  setContent,
  setDataRedirectAttribute,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName,
  getContent,
} from '@/components/template-builder/getters';
// eslint-disable-next-line import/no-cycle
import { isDisplayTemplate } from '../../utils/helpers';

const getWidgetButtonConfig = (templateType: TemplateParentTypeEnum): WidgetTypeConfig => {
  let fieldItems: TabItem = {
    label: '',
    type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
    tabItemType: TemplateStructureEnum.GROUP,
  };

  switch (templateType) {
    case TemplateParentTypeEnum.POPUP:
    case TemplateParentTypeEnum.DISPLAY:
      fieldItems = popupButtonFieldsConfig;
      break;
    case TemplateParentTypeEnum.EMBED:
      fieldItems = embedButtonFieldsConfig;
      break;
    default:
      fieldItems = emailButtonFieldsConfig;
  }

  const linkOptions = [LinkTypeEnum.NONE, LinkTypeEnum.WEB_ADDRESS, LinkTypeEnum.EMAIL_ADDRESS, LinkTypeEnum.ANCHOR, LinkTypeEnum.DISPLAY_TEMPLATE];

  if (isDisplayTemplate(templateType)) {
    linkOptions.push(LinkTypeEnum.CLOSE_POPIN);
  }

  return {
    tabPanel: {
      label: 'templateBuilder.widgetsHeaders.button',
      items: [
        {
          type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
          label: 'templateBuilder.configs.widgetConfiguration',
          selector: 'body #spm_body #{ID}',
          items: [
            {
              label: 'templateBuilder.fields.buttonText',
              selector: '{SELECTOR}.spm_call_to_action > tbody > tr > th table.button > tbody > tr > th > a, {SELECTOR}.spm_display_call_to_action a.spm_button',
              properties: [{ name: 'content', getter: getContent, setters: [setContent] }],
              ...getInputVariablesFieldConfig('Texte du bouton', variables.allVariables, false, true),
            },
            {
              label: 'templateBuilder.fields.link',
              selector: '{SELECTOR}.spm_call_to_action > tbody > tr > th table.button > tbody > tr > th > a, {SELECTOR}.spm_display_call_to_action a.spm_button',
              properties: [{ name: 'href', getter: getAttributeByName, setters: [setAttribute, setDataRedirectAttribute] }],
              ...getLinkFieldConfig(LinkTypeEnum.NONE, linkOptions, variables.allVariables),
            },
          ],
        },
        {
          ...fieldItems,
        },
      ],
    },
  };
};

export default getWidgetButtonConfig;
