
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FBDisconnect',
  props: {
    translation: {
      type: Function,
      required: true,
    },
  },

  emits: [
    'close-action-modal',
    'disconnect',
  ],

  setup(props, context) {
    const dialogVisible = ref(true);

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', false);
    };

    const requestDisconnect = () => {
      context.emit('disconnect');
    };

    return { dialogVisible, updateDialogVisibility, requestDisconnect };
  },
});
