<template>
  <div class="unit-box p-2">
    <div
      class="blockOR-list flex flex-column gap-2 pt-2 pr-2 pb-2 relative"
    >
      <div
        v-for="(blockOR, index) in dataBlockAND"
        :key="`blockOR-${index}`"
        class="blockOR-container relative"
      >
        <span v-if="index === 0" class="first-rule"></span>
        <Tag
          v-else
          :value="t('automatedScenarios.fields.logicalOperators.or')"
          class="other-rule px-2"
        />
        <SegmentationBlockOR
          :key="`blockOR-${index}`"
          :index-block-and="indexBlockAnd"
          :index-block-or="index"
          :block-or="blockOR"
          :length-block-and="dataBlockAND.length"
        />
      </div>
      <div class="add-block-container flex align-items-center gap-2">
        <div
          class="or-tag"
        >
          <Tag
            :value="t('automatedScenarios.fields.logicalOperators.or')"
            class="px-2"
          />
        </div>
        <Button
          :label="t('segmentation.addBlock')"
          class="p-button-secondary"
          icon="far fa-plus"
          @click="addBlockOR"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, reactive, computed,
} from 'vue';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SegmentationBlockOR from '@/components/segmentations/SegmentationBlockOR.vue';
import { store } from '@/store';
import { useI18n } from 'vue-i18n';
import validateSegmentProperty from '@/components/segmentations/utils/utils';

export default defineComponent({
  name: 'SegmentationBlockAND',
  components: {
    SegmentationBlockOR,
    SpmButton,
    Button,
    Tag,
  },

  props: {
    blockAnd: {
      type: Array,
      required: false,
      default: () => [],
    },

    indexBlockAnd: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const dataBlockAND = reactive(props.blockAnd);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const addBlockOR = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      const { indexBlockAnd } = props;
      store.commit('segmentationsEditor/addBlockOR', indexBlockAnd);
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
    };

    return {
      t,
      dataBlockAND,
      addBlockOR,
    };
  },
});
</script>

<style lang="scss" scoped>
.unit-box {
  border-radius: 8px;
  background-color: white;
  border: 1px solid #e9ecef;
}

.blockOR-container {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-left: 2.5rem;
}

.blockOR-list {
  >:not(:last-child)::before {
    border-color: #dee2e6;
    border-style: solid;
    content: "";
    left: -25px;
    position: absolute;
    width: 22px;
    border-width: 0 0 2px 2px;
  }

  >:first-child::before {
    top: -0.4rem;
  }

  >.blockOR-container::before {
    height: 50%;
  }

  >.blockOR-container::after {
    height: calc(50% + 0.9rem);
    top: calc(50% - 0.3rem);
  }

  >:not(:last-child)::after {
    border-color: #dee2e6;
    border-width: 0 0 0 2px;
    border-style: solid;
    content: "";
    left: -25px;
    position: absolute;
    width: 22px;
  }

  .first-rule::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: -23.5px;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border-color: #dee2e6;
    border-style: solid;
    background: #dee2e6;
  }

  .other-rule {
    position: absolute;
    top: calc(50% - 0.8rem);
    left: -25px;
    transform: translateX(-50%);
    z-index: 1;
    background-color: #e9ecef;
    color: black;
  }

  .add-block-container {
    margin-left: 0;
  }
}

</style>

<style lang="scss">
.or-tag .p-tag {
  background-color: #e9ecef;
  color: black;
}
</style>