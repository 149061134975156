
import {
  computed, defineComponent,
  Ref,
  ref, SetupContext, nextTick,
} from 'vue';
import { StatsBuilderColumnLayoutEnum, StatsEditorPanelEnum, StatsWidgets } from '@/types';
import StatsWidgetMetadata, { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';
import { StatsBuilderSelectedWidget } from '@/types/stats-editor-types';
import { useStore } from '@/store';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';
import draggable from 'vuedraggable';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import StatsBuilderSubColItem from '@/components/stats-builder/StatsBuilderSubColItem.vue';

import { removePlaceholderClass } from '@/helpers';

export default defineComponent({
  name: 'StatsBuilderSubCol',

  components: {
    draggable,
    ConfirmDialog,
    StatsBuilderSubColItem,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    rowParentId: {
      type: String,
      required: true,
    },

    columnParentId: {
      type: String,
      required: true,
    },

    panelId: {
      type: String,
      required: true,
    },

    rowId: {
      type: String,
      required: true,
    },

    columnData: {
      type: Object,
      required: true,
    },

    rowHasColumnWithMultipleWidgets: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['mouseover-col', 'mouseleave-col'],

  setup(props: { rowParentId: string; columnParentId: string; panelId: string; rowId: string; columnData: any}, { emit }: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const confirm = useConfirm();

    const selectedWidgetState: Ref<StatsBuilderSelectedWidget|null> = computed(() => store.getters['statsEditor/getSelectedWidget']);
    const hoverCol = ref(false);

    const pageTypeState = computed(() => store.getters['statsEditor/getPageType']);
    const availableElements: any = computed(() => Object.values(StatsWidgetMetadata)
      .filter((item: any) => item.StatsWidgetMeta.availability.includes(pageTypeState.value))
      .map((item: any) => ({
        type: 'placeholder',
        widgetType: item.StatsWidgetMeta.component,
        iconClass: item.StatsWidgetMeta.icon,
        label: item.StatsWidgetMeta.label,
        group: 'widget',
        minColumnsRequired: item.StatsWidgetMeta.min_columns_required,
      })));

    const dragOnEmptyColumnOptions = ref({
      animation: 200,
      sort: false,
      group: { name: 'empty_column', pull: false, put: ['widget_element', 'statsWidget'] },
      disabled: false,
      onAdd: async (evt: any) => {
        evt.preventDefault();
        if (evt.from.getAttribute('data-from-widget')) {
          const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          const dataWidgetLocation = JSON.parse(evt.clone.getAttribute('data-widget-location'));
          if ([StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type)) {
            store.dispatch('statsEditor/removeWidget', {
              location: {
                rowId: dataWidgetLocation.rowId,
                columnId: dataWidgetLocation.columnId,
                widgetIndex: evt.oldIndex,
              },
            });
            await nextTick();
            store.dispatch('statsEditor/undoMoveWidget', {
              dataWidget,
              dataWidgetLocation,
            });
          } else {
            store.dispatch('statsEditor/addWidgetToEmptySubCol', {
              dataWidget,
              dataLocation,
            });
          }
        } else if (evt.from.getAttribute('data-from-subwidget')) {
          const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          const dataLastLocation = JSON.parse(evt.clone.getAttribute('data-location'));
          store.dispatch('statsEditor/addWidgetToEmptySubCol', {
            dataWidget,
            dataLocation,
            dataLastLocation,
          });
        }
      },
    });

    const mouseoverCol = (e: any) => {
      hoverCol.value = true;
      emit('mouseover-col');
    };

    const mouseleaveCol = (e: any) => {
      hoverCol.value = false;
      emit('mouseleave-col');
    };

    const editWidget = (index: number) => {
      const meta = getComponentStatsWidgetMetadata(props.columnData?.widgets[index]?.type);
      if (!meta) {
        return;
      }

      const selectedWidget: StatsBuilderSelectedWidget = {
        component: props.columnData?.widgets[index]?.type,
        data: JSON.parse(JSON.stringify(props.columnData?.widgets[index])),
        location: {
          rowId: props.rowParentId,
          columnId: props.columnParentId,
          panelData: {
            panelId: props.panelId,
            subRowId: props.rowId,
            subColumnId: props.columnData.id,
          },

          widgetIndex: index,
          columnLayout: props.columnData.layout,
        },
      };

      store.commit('statsEditor/setSelectedWidget', selectedWidget);
      store.commit('statsEditor/showLeftToolbar', StatsEditorPanelEnum.WIDGET_EDIT_PANEL);
    };

    const removeWidget = (index: number) => {
      confirm.require({
        message: t('statsEditor.confirmationMessages.removeWidget'),
        header: 'Confirmation',
        group: `removeWidget_${props.panelId}_${props.rowId}_${props.columnData.id}`,
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        rejectLabel: t('no'),
        accept: () => {
          store.dispatch('statsEditor/removeWidget', {
            location: {
              rowId: props.rowParentId,
              columnId: props.columnParentId,
              panelData: {
                panelId: props.panelId,
                subRowId: props.rowId,
                subColumnId: props.columnData.id,
              },
              widgetIndex: index,
            },
          });
        },
      });
    };

    const dragElementsOptions = ref({
      animation: 200,
      sort: true,
      group: { name: 'statsWidget', pull: true, put: true },
      disabled: false,
      onAdd: (evt: any) => {
        const dataWidget = JSON.parse(evt.clone.getAttribute('data-widget'));
        const dataWidgetLocation = JSON.parse(evt.clone.getAttribute('data-widget-location'));
        if (dataWidget) {
          if ([StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type)) {
            store.dispatch('statsEditor/undoMoveWidget', {
              dataWidget,
              dataWidgetLocation,
            });
          } else {
            const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
            let dataLastLocation = null;
            if (evt.from.getAttribute('data-from-subwidget')) {
              dataLastLocation = JSON.parse(evt.item.getAttribute('data-location'));
            }
            store.dispatch('statsEditor/addWidgetToSubCol', {
              dataWidget,
              dataLocation: {
                ...dataLocation,
                index: evt.newIndex,
              },
              dataLastLocation,
            });
          }
        }
      },
      onSort: (evt: any) => {
        // Prevent sorting when a new widget is added into a sub column because there is a delay with adding and sorting
        if (!evt.pullMode) {
          const dataLocation = JSON.parse(evt.to.getAttribute('data-location'));
          store.dispatch('statsEditor/sortWidgetInSubCol', {
            dataLocation: {
              ...dataLocation,
              oldIndex: evt.oldIndex,
              newIndex: evt.newIndex,
            },
          });
        }
      },
      onMove: async (evt: any) => {
        evt.preventDefault();
        if (evt.dragged.getAttribute('data-location')) {
          const dataLocation = JSON.parse(evt.dragged.getAttribute('data-location'));
          const dataWidget = JSON.parse(evt.dragged.getAttribute('data-widget'));

          const choosedWidgetForDrag: Record<string, any> = {
            rowId: dataLocation.rowId,
            columnId: dataLocation.columnId,
            index: dataLocation?.widgetIndex,
          };
          if (dataLocation.panelData) {
            choosedWidgetForDrag.index = dataLocation.panelData.widgetIndex;
            choosedWidgetForDrag.panelData = {
              panelId: dataLocation.panelData.panelId,
              subRowId: dataLocation.panelData.subRowId,
              subColumnId: dataLocation.panelData.subColumnId,
            };
          }

          store.commit('statsEditor/setChoosedWidgetForDrag', choosedWidgetForDrag);

          // Remove previous chosenSlot or not compatibleSlot class
          await nextTick();
          removePlaceholderClass(evt.dragged);

          if (evt.from !== evt.to) {
            const columnDestinationLayout = evt.to.getAttribute('data-column-layout');
            const destination = evt.to.getAttribute('data-from-widget') || evt.to.getAttribute('data-from-subwidget');

            const widgetType = availableElements.value.find((item: any) => item.widgetType === dataWidget.type);

            // Check if destination is empty column
            if (columnDestinationLayout) {
              const isOnPanel = evt.to.getAttribute('data-is-on-panel');

              await nextTick();
              if ((parseInt(widgetType.minColumnsRequired, 10) > parseInt(columnDestinationLayout, 10))
                || (isOnPanel && [StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type))) {
                evt.to.classList.add('notCompatibleSlot');
              } else {
                evt.to.classList.add('chosenSlot');
              }
            } else if (destination) {
              // Check if destination is inside column with widgets
              await nextTick();
              const location = evt.to.getAttribute('data-location');
              const dataHasMenu = evt.to.getAttribute('data-has-menu');

              if (location) {
                const locationObject = JSON.parse(location);
                if ((parseInt(widgetType.minColumnsRequired, 10) > parseInt(locationObject.columnLayout, 10))
                  || ([StatsWidgets.MENU, StatsWidgets.TABS].includes(dataWidget.type) && locationObject.panelData) || dataHasMenu === 'true') {
                  // eslint-disable-next-line no-param-reassign
                  evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--disallowed', 'm-4');
                } else {
                  // eslint-disable-next-line no-param-reassign
                  evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
                }
              }
            }
          } else {
            await nextTick();
            evt.dragged.classList.add('placeholder-on-add-widget', 'placeholder-on-add-widget--allowed', 'm-4');
          }
        }
        return true;
      },
      onUnchoose: (evt: any) => {
        removePlaceholderClass(evt.item);
        store.commit('statsEditor/resetChoosedWidgetForDrag');
      },
    });

    return {
      dragOnEmptyColumnOptions,
      selectedWidgetState,
      hoverCol,
      StatsBuilderColumnLayoutEnum,
      dragElementsOptions,

      mouseoverCol,
      mouseleaveCol,
      editWidget,
      removeWidget,
    };
  },
});
