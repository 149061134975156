export const colors = (t: any) => [
  { value: 'red', label: t('fileManager.library.filters.colors.red') },
  { value: 'blue', label: t('fileManager.library.filters.colors.blue') },
  { value: 'green', label: t('fileManager.library.filters.colors.green') },
  { value: 'yellow', label: t('fileManager.library.filters.colors.yellow') },
  { value: 'black', label: t('fileManager.library.filters.colors.black') },
  { value: 'white', label: t('fileManager.library.filters.colors.white') },
  { value: 'gray', label: t('fileManager.library.filters.colors.gray') },
  { value: 'pink', label: t('fileManager.library.filters.colors.pink') },
  { value: 'orange', label: t('fileManager.library.filters.colors.orange') },
  { value: 'brown', label: t('fileManager.library.filters.colors.brown') },
];

export const extensions = {
  'image/*': '.tiff,.jfif,.bmp,.gif,.svg,.png,.jpeg,.svgz,.jpg,.webp,.ico,.xbm,.dib,.pjp,.apng,.tif,.pjpeg,.avif',
};

export const iconsByType: Record<string, any> = {
  text: 'fal fa-file-lines',
  word: 'fal fa-file-word',
  pdf: 'fal fa-file-pdf',
  spreadsheet: 'fal fa-file-excel',
  presentation: 'fal fa-file-powerpoint',
  archive: 'fal fa-file-zipper',
  audio: 'fal fa-file-audio',
  video: 'fal fa-file-video',
  binary: 'fal fa-file',
};

export default colors;
