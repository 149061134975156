<template>
  <StatsWidgetData
    :key="updateWidgetRender"
    :configuration="config.widgetConfiguration"
    :name="config.widgetName"
  />
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, watch,
} from 'vue';
import { WidgetDataData } from '@/components/stats-builder/metadata/WidgetDataMetadata';
import StatsWidgetData from '@/components/stats/StatsWidgetData.vue';
import { getComponentStatsWidgetMetadata } from '@/components/stats-builder/metadata';

export default defineComponent({
  name: 'StatsWidgetDataRender',
  components: {
    StatsWidgetData,
  },

  props: {
    widgetData: {
      type: Object as PropType<WidgetDataData>,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { widgetData: WidgetDataData}) {
    const config = ref(props.widgetData);
    const updateWidgetRender = ref(0);

    watch(() => props.widgetData, async () => {
      const meta = getComponentStatsWidgetMetadata('StatsWidgetData');
      if (!meta) {
        return;
      }
      let updateRender = false;
      if (meta.Validate) {
        const valid = await meta.Validate(props.widgetData);
        updateRender = valid.success;
      }

      if (updateRender) {
        /* Si les données sont valides, on rafraichi le composant */
        config.value = props.widgetData;
        updateWidgetRender.value += 1;
      }
    });

    return {
      config,
      updateWidgetRender,
    };
  },
});
</script>

<style lang="scss">
.titleChart {
  text-align: center;
  margin-bottom: 1rem;
}
</style>
