<template>
  <div class="marketing-pressure">
    <div class="flex justify-content-between flex-wrap mb-3">
      <div class="flex align-items-center justify-content-center">
        <span class="p-buttonset mr-3">
          <router-link
            :to="{ name: 'workflow' }"
          >
              <Button
                :label="t('return')"
                type="button"
                icon="fa fa-arrow-left"
                class="p-button p-button-secondary"
              />
          </router-link>
        </span>
        <h3>
          {{ t('automatedScenarios.actions.marketingPressure.params') }}
        </h3>
      </div>
    </div>
    <div class="flex justify-content-between flex-wrap mb-3">
      <p class="alert-secondary" style="width: 100%;">
        <span>
          <i class="fa fa-lg fa-exclamation-triangle pr-3" />
          {{ t('automatedScenarios.actions.marketingPressure.interval.desc1') }}
          <br/>
          <br/>
          {{ t('automatedScenarios.actions.marketingPressure.interval.desc2') }}
        </span>
      </p>
    </div>

    <div
      class="flex flex-column gap-3 w-max md:w-max xl:w-6"
    >
      <div
        v-for="config in delayConfig"
        class="flex align-items-center justify-content-between bg-white border-1 border-100 border-round-md p-2"
      >
        <div class="flex align-items-center gap-3">
          <div class="flex align-items-center justify-content-center w-4rem h-4rem border-circle bg-gray-100">
            <i
              class="text-2xl fa-fw"
              :class="config.icon"
            />
          </div>
          <div>
            <label
              class="text-md font-semibold"
              for="notion"
            >
              {{ config.label }}
            </label>
          </div>
        </div>
        <div class="periodSelectorConfig">
          <PeriodSelector
            v-model="delayData[config.key]"
            :units="delayUnits"
            title=""
          />
        </div>
      </div>
      <div class="flex justify-content-end  mt-6">
        <SpmButton
          :label="t('templateBuilder.fields.validate')"
          :loading="isLoading"
          class-style="p-button p-button-success"
          @click="updateConfig"
        />
      </div>
    </div>


  </div>
</template>

<script lang="ts">
import Button from 'primevue/button';
import {defineComponent, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, reactive, ref,} from 'vue';
import { useI18n } from 'vue-i18n';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import {
  ShopsConfiguration,
  ShopsConfigurationInput,
} from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import {
  SaveShopsConfiguration,
  GetShopsConfigurationList,
} from '@/composables/shop/ShopsConfiguration';
import { Period } from '@/types/automated-scenarios';
import { TimeUnits } from '@/types';
import { showToastError, showToastSuccess } from '@/helpers';
import SpmButton from "@/components/spm-primevue/SpmButton.vue";
import { store } from '@/store';

export default defineComponent({
  name: 'MarketingPressure',
  components: {
    SpmButton,
    PeriodSelector,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const isLoading = ref(false);
    const idShop = ref(UserState.activeShop?.id ?? 0);

    interface DelayData {
      email: Period;
      sms: Period;
      popUp: Period;
      push: Period;
      facebook: Period;
    }

    const delayData = reactive<DelayData>({
      email: { value: 0, unit: TimeUnits.MINUTE },
      sms: { value: 0, unit: TimeUnits.MINUTE },
      popUp: { value: 0, unit: TimeUnits.MINUTE },
      push: { value: 0, unit: TimeUnits.MINUTE },
      facebook: { value: 0, unit: TimeUnits.MINUTE },
    })

    const delayConfig = ref([
      {
        key: 'email',
        label: t('templateBuilder.fields.email'),
        value: delayData.email,
        icon: 'fa fa-envelope',
      },
      {
        key: 'sms',
        label: t('automatedScenarios.actions.marketingPressure.input.sms'),
        value: delayData.sms,
        icon: 'fa fa-mobile',
      },
      {
        key: 'popUp',
        label: t('automatedScenarios.actions.marketingPressure.input.display'),
        value: delayData.popUp,
        icon: 'fa fa-list-alt',
      },
      {
        key: 'push',
        label: t('automatedScenarios.actions.marketingPressure.scenarios.push'),
        value: delayData.push,
        icon: 'fa fa-bell',
      },
      {
        key: 'facebook',
        label: t('templateBuilder.fields.facebook'),
        value: delayData.facebook,
        icon: 'fab fa-facebook',
      },
    ])

    const delayUnits = [
      { value: TimeUnits.MINUTE, label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: TimeUnits.HOUR, label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: TimeUnits.DAY, label: t('automatedScenarios.fields.durations.day(s)') },
    ];


    const updateConfig = async() => {
      isLoading.value = true;
      const input = ref<ShopsConfigurationInput>({
        id_shop: idShop.value,
        configs: [
          {
            key: 'workflow_marketing_pressure_email_delay',
            value: JSON.stringify({ value: delayData.email.value, unit: delayData.email.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_facebook_delay',
            value: JSON.stringify({ value: delayData.facebook.value, unit: delayData.facebook.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_popup_delay',
            value: JSON.stringify({ value: delayData.popUp.value, unit: delayData.popUp.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_push_delay',
            value: JSON.stringify({ value: delayData.push.value, unit: delayData.push.unit}),
            lang:'',
          },
          {
            key: 'workflow_marketing_pressure_sms_delay',
            value: JSON.stringify({ value: delayData.sms.value, unit: delayData.sms.unit}),
            lang:'',
          }
        ]
      })
      SaveShopsConfiguration(input.value).then(() => {
        showToastSuccess('savedSuccessful')
        isLoading.value = false;
      })
      .catch(() => {
        showToastError('noSave');
        isLoading.value = false;
      });
    }

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      const currentConfig = await GetShopsConfigurationList({
        shopId: idShop.value,
        key: '%_delay',
        fields: 'id_shop_configuration, key, value',
        limit: 0,
        offset: 0,
      });
      if (currentConfig) {
        // eslint-disable-next-line no-unused-expressions
        currentConfig?.forEach((record: ShopsConfiguration) => {
          const jsonValue = <Period>JSON.parse(record.value);
          switch (record.key) {
            case 'workflow_marketing_pressure_email_delay':
              delayData.email = jsonValue;
              break;
            case 'workflow_marketing_pressure_facebook_delay':
              delayData.facebook = jsonValue;
              break;
            case 'workflow_marketing_pressure_popup_delay':
              delayData.popUp = jsonValue;
              break;
            case 'workflow_marketing_pressure_push_delay':
              delayData.push = jsonValue;
              break;
            case 'workflow_marketing_pressure_sms_delay':
              delayData.sms = jsonValue;
              break;
            default:
              break;
          }
          return null;
        });
      }

      store.commit('general/hideTheSpinner');
    });

    return {
      t,
      isLoading,
      delayConfig,
      delayData,
      delayUnits,
      updateConfig,
    };
  },
});
</script>

<style>
.marketing-pressure {
  .periodSelectorConfig {
    width: 17rem !important;

    .p-inputgroup {
      .p-inputnumer {
        width: 0 !important;
      }
    }

    .period-selector-grouped .p-inputgroup .value-input {
      width: 0rem !important;
    }
  }
}
.alert-secondary {
  color: #666;
  background-color: #f6f6f6;
  border: 0 #eee;
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: .25rem;
}

</style>
