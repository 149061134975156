<template>
  <SectionTitle
    v-if="title"
    :title="title"
  />
  <div
    v-if="!useVirtualScroller"
    class="w-full"
  >
    <div v-if="!pageLoading && records.length">
      <ul
        class="list-none p-0 m-0"
      >
        <li
          v-for="(item, index) in records"
          :key="item[keyName]"
          class="flex md:align-items-center justify-content-between mb-4 border-1 border-dashed surface-border border-round p-3"
          :class="!noColumn ? 'flex-column md:flex-row' : ''"
        >
          <slot name="content" :item="item"></slot>
        </li>
      </ul>
      <Paginator
        v-if="totalRecords > LIST_ROWS"
        v-model:first="firstPage"
        :rows="LIST_ROWS"
        :totalRecords="totalRecords"
        @page="handleNextPage"
      />
    </div>
    <ul
      v-else-if="!pageLoading && !records.length"
    >
      <li
        class="flex align-items-center justify-content-center mb-4 border-1 border-dashed surface-border border-round p-3"
      >
        {{ t('dataExplorer.noData') }}
      </li>
    </ul>
    <Skeleton
      v-else-if="pageLoading"
      height="3rem"
    />
  </div>
  <VirtualScroller
    v-else-if="useVirtualScroller && dataLoaded && list.length"
    :items="list"
    :itemSize="list.length + 20"
    class="border-1 surface-border border-round"
    style="width: 100%; height: 400px"
  >
    <template v-slot:item="{ item, options }">
      <div :class="['flex align-items-center p-2', { 'surface-hover': options.odd }]" style="height: 50px">{{ item }}</div>
    </template>
  </VirtualScroller>
  <div
    v-else-if="useVirtualScroller && dataLoaded && !list.length"
    class="flex align-items-center justify-content-center border-1 surface-border border-round"
    style="width: 100%; height: 400px"
  >
    {{ t('dataExplorer.noData') }}
  </div>
  <Skeleton
    v-else-if="useVirtualScroller && !dataLoaded"
    class="w-full"
    height="12rem"
  />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import Chip from 'primevue/chip';
import Skeleton from 'primevue/skeleton';
import VirtualScroller from 'primevue/virtualscroller';
import Paginator from 'primevue/paginator';


import SectionTitle from '@/components/data-explorer/fields/SectionTitle.vue';
import LIST_ROWS from '@/components/data-explorer/utils/constants';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'List',

  components: {
    Chip,
    Skeleton,
    VirtualScroller,
    SectionTitle,
    Paginator,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    list: {
      type: Array,
      required: true
    },

    keyName: {
      type: String,
      required: true
    },

    useVirtualScroller: {
      type: Boolean,
      required: false,
      default: false,
    },

    noColumn: {
      type: Boolean,
      required: false,
      default: false,
    },

    retrieveData: {
      type: Function,
      required: true,
    },

    retrieveDataArguments: {
      type: Array,
      required: true,
    },

    dataLoaded: {
      type: Boolean,
      required: true,
    },

    total: {
      type: Number,
      required: false,
      default: 0,
    },

    currentPage: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  emits: ['loaded', 'updatePage'],

  setup (props, { emit }) {
    const { t } = useI18n();

    const firstPage = ref(props.currentPage);

    const records = ref(props.list);

    const totalRecords = ref(props.total);

    const pageLoading = ref(false);

    const retrieve = async (offset = 0, emitNextPage = false) => {
      try {
        pageLoading.value = true;
        const { total, lists } = await props.retrieveData(...props.retrieveDataArguments, offset);
        records.value = lists;
        totalRecords.value = total;
        emit('loaded', lists, total);
        if (emitNextPage) {
          emit('updatePage', firstPage.value);
        }
      } catch (error) {
        records.value = [];
        emit('loaded', [], 0);
      } finally {
        pageLoading.value = false;
      }
    }

    const handleNextPage = async () => {
      await retrieve(firstPage.value, true);
    };

    onMounted(async () => {
      if (!props.dataLoaded) {
        await retrieve();
      }
    });

    return {
      t,
      firstPage,
      records,
      totalRecords,
      LIST_ROWS,
      pageLoading,

      handleNextPage
    };
  }
})
</script>
