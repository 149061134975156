<template>
  <SpmOverlayPanel
    class-trigger="flex align-items-center justify-content-center mr-2"
    class-header="flex justify-content-between flex-wrap p-3 header-content spm-overlay-panel-notification-header"
    class-content="flex flex-column spm-overlay-panel-filter-stats-content"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-search-list-footer"
    min-panel-width="320px"
    max-panel-width="320px"
  >
    <template #trigger>
      <SpmButton
        icon="far fa-filter"
        :label="`${t('statsEditor.sidebarPanel.filtersButton')}`"
        :badge-text="`${appliedDateInterval}${nbOtherAppliedFilters > 0 ? ` + ${nbOtherAppliedFilters} ${t('statsEditor.sidebarPanel.filtersNumbers')}` : ''}`"
        badge-class="p-badge-success"
        class-style="p-button p-button-secondary"
      />
    </template>
    <template #header>
      <div class="flex align-items-center justify-content-center ml-2">
        <h4>
          <i class="fa-regular fa-filters mr-1" /> {{ t('statsEditor.sidebarPanel.filtersTitle', null) }}
        </h4>
      </div>
    </template>

    <StatsBuilderFilters
      :default-filters="filtersData"
      @filters-changed="applyFilters"
    />
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  reactive, defineComponent, SetupContext, watch, computed, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import StatsBuilderFilters from '@/components/stats-builder/StatsBuilderFilters.vue';
import { useStore } from '@/store';
import moment from 'moment/moment';
import { formatDateToLocale } from '@/helpers/Date';

export default defineComponent({
  name: 'StatsFilterOnView',
  components: {
    StatsBuilderFilters,
    SpmButton,
    SpmOverlayPanel,
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:modelValue', 'apply-filters'],

  setup(props: { modelValue: any }, { emit }: SetupContext) {
    const { t, locale } = useI18n();
    const store = useStore();
    const filtersData = reactive(props.modelValue);
    const appliedFilters = computed(() => store.getters['statsEditor/getPageFilters']);
    const nbOtherAppliedFilters = computed(() => Object.keys(appliedFilters.value)
      .filter((key) => key !== 'date_interval' && appliedFilters.value[key].value && appliedFilters.value[key].value.length > 0).length);
    const appliedDateInterval = computed(() => {
      let returnValue;

      if (appliedFilters.value.date_interval.value.interval === 'customDateRange') {
        const startDate = moment(appliedFilters.value.date_interval.value.customDateRange[0]);
        const endDate = moment(appliedFilters.value.date_interval.value.customDateRange[1]);
        returnValue = `${formatDateToLocale(locale.value, startDate.format('YYYY-MM-DD HH:mm:ss'))?.split(' ')[0]}
         - ${formatDateToLocale(locale.value, endDate.format('YYYY-MM-DD HH:mm:ss'))?.split(' ')[0]}`;
      } else {
        returnValue = t(`customDatePicker.${appliedFilters.value.date_interval.value.interval}`);
      }

      return returnValue;
    });

    watch(filtersData, () => {
      emit('update:modelValue', filtersData);
    });

    const applyFilters = () => {
      emit('apply-filters');
    };

    onMounted(() => {
      applyFilters();
    });

    return {
      t,
      filtersData,
      nbOtherAppliedFilters,
      appliedDateInterval,
      applyFilters,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-customers-primary-filter {margin:0!important;text-align:center;float:left;}
#list-customers-secondary-filters {background-color: #f6f6f6;padding: 10px; border: 1px solid #ccc; }
.list-customers-secondary-filter {margin-top:6px;}
.spm_advanced_search_link{margin-top: 5px; text-align: center;}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .country-item-value {
    padding: .25rem 0.3125rem .5rem 0;
    display: inline-flex;
  }
}

#list-customers-filters {
  background-color: #f8f8f8;
  border: 1px solid #e9ecef;
  .header-content {
    border-bottom: 1px solid #e9ecef;
  }
}
</style>

<style lang="scss">
.spm-overlay-panel-filter-stats-content {
  overflow-y: auto;
  max-height: 20rem;
  padding: 1rem;

  & .filter-content {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.spm-overlay-panel-search-list-footer {
  padding: .7rem 1rem;
}
</style>
