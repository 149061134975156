
import {
  defineComponent,
  ref,
  reactive,
} from 'vue';

import InputText from 'primevue/inputtext';

import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { showToastError, showToastSuccess } from '@/helpers';
import { UserState } from '@/composables/User';
import { InsertShopsSendDomains } from '@/composables/shop/ShopsSendDomains';
import { ShopsSendDomainsInputItem } from '@/types/generated-types/graphql';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { domaineNameInputValidator } from '@/helpers/CustomValidator';

import { useI18n } from 'vue-i18n';
import moment from 'moment/moment';
import * as sha1 from 'sha1';

export default defineComponent({
  name: 'CreateDomain',

  components: {
    SpmOverlayPanel,
    SpmButton,
    InputText,
    FieldErrors,
  },

  emits: ['refresh-data-table'],

  setup(_, context) {
    const { t } = useI18n();
    const closePanel = ref(false);
    const idShop = UserState.activeShop?.id;

    const field = reactive({
      domainName: '',
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const loadingInsert = ref(false);

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules = {
        domainName: {
          domaineNameInputValidator: domaineNameInputValidator(),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleInsert = async () => {
      try {
        loadingInsert.value = true;
        const validation = await validate();
        if (!validation.success) {
          error.value = validation.validate;
          componentFieldErrorsKey.value += 1;
          return;
        }

        const date = moment().format('YYYY-MM-DD HH:mm:ss');

        const shopDomain: ShopsSendDomainsInputItem = {
          id_shop: idShop,
          domain: field.domainName,
          dns_verification_key: sha1(`${field.domainName}_${date.replace(new RegExp('[\\s-:]', 'g'), '')}_${UserState.activeShop?.id ?? 0}`),
          domain_verified: false,
          dkim_verified: false,
          spf_verified: false,
          dkim_selector: process.env.VUE_APP_DNS_DEFAULT_DOMAIN_DKIM_SELECTOR ?? '',
          authentication_verified: false,
          alignment_verified: false,
          tracking_domain_verified: false,
          tracking_domain_default_medias: false,
          date_add: date,
          date_modification: date,
        };

        const result = await InsertShopsSendDomains([shopDomain]);

        if (!result || result.err !== '' || !result.status) {
          if (result.messages) {
            //console.log(result.messages);
          }
          await showToastError(t('shop.my_domains.saveError'));
        }
        context.emit('refresh-data-table');
        await showToastSuccess(t('shop.my_domains.saveSuccess'));
        closePanel.value = true;
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        loadingInsert.value = false;
      }
    };

    return {
      t,
      closePanel,
      field,
      componentFieldErrorsKey,
      error,
      loadingInsert,

      handleInsert,
    };
  },
});
