<template>
  <SpmOverlayPanel
    class-trigger="flex align-items-center justify-content-center mr-2"
    class-header="flex justify-content-between flex-wrap p-3 header-content spm-overlay-panel-notification-header"
    class-content="flex flex-column spm-overlay-panel-content"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-search-list-footer"
    min-panel-width="540px"
    max-panel-width="540px"
    :close-panel="closePanel"
    @onclose-panel="closePanel = false"
  >
    <template #trigger>
      <SpmButton
        :label="t('shop.my_domains.add.btnText')"
        icon="far fa-plus-circle"
        class-style="p-button p-button-success"
      />
    </template>
    <template #header>
      <div class="flex align-items-center justify-content-center ml-2">
        <h2>
          {{ t('shop.my_domains.add.title') }}
        </h2>
      </div>
    </template>
    <div class="flex mt-2 mb-2">
      <div>
        {{ t('shop.my_domains.add.subTitle') }}
      </div>
    </div>
    <div class="flex mt-3">
      <div class="field w-full">
        <InputText
          v-model="field.domainName"
          class="w-full"
          name="domainName"
          input-id="confirmDomain"
          :placeholder="t('shop.my_domains.add.placeholder')"
        />
        <FieldErrors
          :key="componentFieldErrorsKey"
          :errors="error"
          field="domainName"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex flex-column align-items-center justify-content-center mt-1">
        <div class="flex mb-1">
          <SpmButton
            :label="t('validate')"
            class-style="p-button p-button-success"
            :loading="loadingInsert"
            @click="handleInsert"
          />
        </div>
      </div>
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  reactive,
} from 'vue';

import InputText from 'primevue/inputtext';

import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';

import { showToastError, showToastSuccess } from '@/helpers';
import { UserState } from '@/composables/User';
import { InsertShopsSendDomains } from '@/composables/shop/ShopsSendDomains';
import { ShopsSendDomainsInputItem } from '@/types/generated-types/graphql';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { domaineNameInputValidator } from '@/helpers/CustomValidator';

import { useI18n } from 'vue-i18n';
import moment from 'moment/moment';
import * as sha1 from 'sha1';

export default defineComponent({
  name: 'CreateDomain',

  components: {
    SpmOverlayPanel,
    SpmButton,
    InputText,
    FieldErrors,
  },

  emits: ['refresh-data-table'],

  setup(_, context) {
    const { t } = useI18n();
    const closePanel = ref(false);
    const idShop = UserState.activeShop?.id;

    const field = reactive({
      domainName: '',
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const loadingInsert = ref(false);

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules = {
        domainName: {
          domaineNameInputValidator: domaineNameInputValidator(),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const handleInsert = async () => {
      try {
        loadingInsert.value = true;
        const validation = await validate();
        if (!validation.success) {
          error.value = validation.validate;
          componentFieldErrorsKey.value += 1;
          return;
        }

        const date = moment().format('YYYY-MM-DD HH:mm:ss');

        const shopDomain: ShopsSendDomainsInputItem = {
          id_shop: idShop,
          domain: field.domainName,
          dns_verification_key: sha1(`${field.domainName}_${date.replace(new RegExp('[\\s-:]', 'g'), '')}_${UserState.activeShop?.id ?? 0}`),
          domain_verified: false,
          dkim_verified: false,
          spf_verified: false,
          dkim_selector: process.env.VUE_APP_DNS_DEFAULT_DOMAIN_DKIM_SELECTOR ?? '',
          authentication_verified: false,
          alignment_verified: false,
          tracking_domain_verified: false,
          tracking_domain_default_medias: false,
          date_add: date,
          date_modification: date,
        };

        const result = await InsertShopsSendDomains([shopDomain]);

        if (!result || result.err !== '' || !result.status) {
          if (result.messages) {
            //console.log(result.messages);
          }
          await showToastError(t('shop.my_domains.saveError'));
        }
        context.emit('refresh-data-table');
        await showToastSuccess(t('shop.my_domains.saveSuccess'));
        closePanel.value = true;
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        loadingInsert.value = false;
      }
    };

    return {
      t,
      closePanel,
      field,
      componentFieldErrorsKey,
      error,
      loadingInsert,

      handleInsert,
    };
  },
});
</script>

<style lang="scss" scoped>
.spm-overlay-panel-content {
  overflow-y: auto;
  padding: 1rem;
}
</style>
