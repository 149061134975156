
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import {
  defineComponent,
  ref,
  Ref,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  watch,
} from 'vue';
import { COLOR_PICKER_KEY } from '@/components/template-builder/utils/constants';
import { ColorFormatEnum } from '@/types';
import { getLocalStorageElement, setLocalStorageElement } from '@/helpers/LocalStorage';
import { UserState } from '@/composables/User';
import '@cyhnkckali/vue3-color-picker/dist/style.css';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CustomColorPicker',
  components: { Vue3ColorPicker },
  props: {
    color: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    colorFormat: {
      type: String as PropType<ColorFormatEnum>,
      required: true,
    },

    showAlpha: {
      type: Boolean,
      required: false,
      default: false,
    },

    showGradient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: {
    'color-changed': String,
  },

  setup(props, context) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const initialColor = props.color;
    const colorMode = ref(initialColor.match('gradient') ? 'gradient' : 'solid');
    const showAlphaValue: Ref<boolean> = ref(props.showAlpha);
    const showGradientValue = ref(props.showGradient);
    const colorType = props.showGradient || props.showAlpha ? 'RGBA' : 'HEX';
    const newColor: Ref<string> = ref(props.color);
    const colorsDefault: Ref<string[]> = ref([]);
    const isShow: Ref<boolean> = ref(true);
    const currentColorPickerKey = `${COLOR_PICKER_KEY}-${UserState.activeShop ? UserState.activeShop.id : 0}${showGradientValue.value ? '-gradient' : ''}`;
    const lastGradientUsedKey = `${COLOR_PICKER_KEY}-${UserState.activeShop ? UserState.activeShop.id : 0}-lastgradient`;
    const lastSolidUsedKey = `${COLOR_PICKER_KEY}-${UserState.activeShop ? UserState.activeShop.id : 0}-lastsolid`;
    const maxColorHistory = 18;
    const { t } = useI18n();
    const VALID_COLOR_REGEX_RULE = /^rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d+\.?\d*)\)$/;
    const isValidColor = (color: string) => VALID_COLOR_REGEX_RULE.test(color);

    // eslint-disable-next-line vue/no-setup-props-destructure
    let lastColorUsed = props.color;
    const handleChange = () => {
      if (lastColorUsed && !lastColorUsed.match('gradient') && newColor.value && newColor.value.match('gradient')) {
        newColor.value = getLocalStorageElement(lastGradientUsedKey);
      } else if (lastColorUsed && lastColorUsed.match('gradient') && newColor.value && !newColor.value.match('gradient')) {
        newColor.value = getLocalStorageElement(lastSolidUsedKey);
      }
      lastColorUsed = newColor.value;
    };

    const rgbaToHex = (r: number, g: number, b: number, a: number) => {
      const toHex = (num: number) => {
        const hex = num.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      };

      const rHex = toHex(r);
      const gHex = toHex(g);
      const bHex = toHex(b);
      const aHex = toHex(Math.round(a * 255));

      return `#${rHex}${gHex}${bHex}${aHex}`;
    };

    // eslint-disable-next-line consistent-return
    const convertRgbaStringToHex = (rgbaInput: string) => {
      if (isValidColor(rgbaInput)) {
        const matches = rgbaInput.match(VALID_COLOR_REGEX_RULE);
        if (matches) {
          const r = parseInt(matches[1], 10);
          const g = parseInt(matches[2], 10);
          const b = parseInt(matches[3], 10);
          const a = parseFloat(matches[4]);
          return rgbaToHex(r, g, b, a);
        }
      } else {
        return rgbaInput;
      }
    };
    const setLastUsedColor = (color: string | undefined) => {
      let colorToSave;
      if (color && !color.match('gradient')) {
        if (color.match('rgba')) {
          colorToSave = convertRgbaStringToHex(color);
        } else {
          colorToSave = color;
        }
        setLocalStorageElement(lastSolidUsedKey, colorToSave);
      } else {
        colorToSave = color;
        setLocalStorageElement(lastGradientUsedKey, colorToSave);
      }
    };

    onBeforeMount(() => {
      if (getLocalStorageElement(currentColorPickerKey)) {
        setLocalStorageElement('ck-cp-local-color-list', getLocalStorageElement(currentColorPickerKey), false, null, false);
        colorsDefault.value = JSON.parse(getLocalStorageElement('ck-cp-local-color-list', false, false));
      }
      setLastUsedColor(newColor.value);
    });

    const addColorToHistory = () => {
      // Retrieve the current list of colors from localStorage and parse it as JSON
      colorsDefault.value = JSON.parse(getLocalStorageElement('ck-cp-local-color-list', false, false));
      if(!colorsDefault.value) {
        colorsDefault.value = [];
      }
      // Check if the new color is not already in the list and is not a gradient
      if (!colorsDefault.value.includes(newColor.value) && !newColor.value.match('gradient')) {
        let colorToSave;

        // If the new color is in RGBA format, convert it to Hex
        if (newColor.value.match('rgba')) {
          colorToSave = convertRgbaStringToHex(newColor.value);
        } else {
          // Otherwise, use the new color as is
          colorToSave = newColor.value;
        }
        setLastUsedColor(colorToSave);

        // If there is a color to save, add it to the list
        if (colorToSave) colorsDefault.value.push(colorToSave);

        // Ensure the color history doesn't exceed the maximum limit
        if (colorsDefault.value.length > maxColorHistory) {
          // Remove the oldest color (second element) to keep the list within the limit
          colorsDefault.value.splice(1, 1);
        }

        // Save the updated color list back to localStorage
        setLocalStorageElement('ck-cp-local-color-list', JSON.stringify(colorsDefault.value), false, null, false);
      } else if (newColor.value.match('gradient')) {
        // If the new color is a gradient, save it separately
        setLastUsedColor(newColor.value);
      }
    };

    onBeforeUnmount(() => {
      addColorToHistory();
      if (getLocalStorageElement('ck-cp-local-color-list', false, false)) {
        setLocalStorageElement(currentColorPickerKey, getLocalStorageElement('ck-cp-local-color-list', false, false));
      }
    });

    watch(() => newColor.value, () => {
      if (newColor.value !== props.color) {
        context.emit('color-changed', newColor.value);
      }
    });

    return {
      newColor,
      colorMode,
      showAlphaValue,
      colorType,
      isShow,
      showGradientValue,
      handleChange,
      t,
    };
  },
});
