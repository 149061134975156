
import {
  defineComponent,
  PropType, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import DeclencheurScrollPageMetaData, {
  DeclencheurScrollPageData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurScrollPageMetadata';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'DeclencheurScrollPage',
  components: {
    FieldErrors,
    BaseInputNumber,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurScrollPageData>,
      required: true,
      default() {
        return DeclencheurScrollPageMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurScrollPageData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });
    const percent = ref(props.modelValue.percent_scroll);

    const update = () => context.emit(
      'update:modelValue',
      DeclencheurScrollPageMetaData.Create({
        percent_scroll: percent.value,
      }),
    );

    watch([percent], update);

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      percent,
    };
  },
});
