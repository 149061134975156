<template>
  <ConfirmDialog group="settingsPanelBulk" />
  <div class="configuration-wrapper">
    <div
      class="field-group pt-2"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <label class="block mb-2">
              {{ t('automatedScenarios.globalBulkSettings.name.label') }}
            </label>
            <div class="p-fluid flex">
              <InputText
                v-model="settingsFields.name"
                class="input-text"
              />
            </div>
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="name"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="field-group pt-3"
    >
      <div>
        <div class="field-group-content">
          <div class="field-wrapper">
            <ButtonGroup
              :key="commercialCampaignKey"
              v-model="settingsFields.commercial_campaign"
              :options="optionsFields.commercial_campaign"
              title="automatedScenarios.globalBulkSettings.commercial_campaign.label"
              tooltip-desc="automatedScenarios.globalBulkSettings.commercial_campaign.description"
            />
            <FieldErrors
              :key="componentFieldErrorsKey"
              :errors="formValidationErrors"
              field="commercial_campaign"
              silent="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import InputText from 'primevue/inputtext';
import {
  defineComponent,
  reactive, ref, watch,
} from 'vue';
import {
  GlobalBulkSettingsData,
} from '@/types/automated-scenarios';
import {
  AutomatedScenarioState as state, coreDrawUI, flowchartTick,
  setGlobalSettingsData,
} from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';

export default defineComponent({
  name: 'SettingsPanelBulk',

  components: {
    InputText,
    FieldErrors,
    ButtonGroup,
    ConfirmDialog,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: {settingPanelValidationErrors: any }) {
    const { t } = useI18n();
    const confirm = useConfirm();

    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    const commercialCampaignKey = ref(0);
    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const settingsFields: GlobalBulkSettingsData = reactive({
      name: (state.scenario.settingsData as GlobalBulkSettingsData).name,
      time_sending: (state.scenario.settingsData as GlobalBulkSettingsData).time_sending,
      perfect_timing: (state.scenario.settingsData as GlobalBulkSettingsData).perfect_timing,
      slowdown: (state.scenario.settingsData as GlobalBulkSettingsData).slowdown,
      interval_between_sends: (state.scenario.settingsData as GlobalBulkSettingsData).interval_between_sends,
      commercial_campaign: (state.scenario.settingsData as GlobalBulkSettingsData).commercial_campaign,
    });

    const optionsFields = {
      commercial_campaign: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],
    };

    watch([settingsFields], () => {
      setGlobalSettingsData(settingsFields);
    });

    watch(() => settingsFields.commercial_campaign, (newValue) => {
      if (settingsFields.commercial_campaign === 0) {
        confirm.require({
          group: 'settingsPanelBulk',
          message: t('automatedScenarios.globalBulkSettings.commercial_campaign.dialogConfirm.message'),
          header: 'Confirmation',
          icon: 'far fa-exclamation-triangle',
          acceptLabel: t('yes'),
          rejectLabel: t('no'),
          reject: () => {
            settingsFields.commercial_campaign = 1;
            commercialCampaignKey.value += 1;
          },
          onHide: () => {
            settingsFields.commercial_campaign = 1;
            commercialCampaignKey.value += 1;
          },
        });
      }
      coreDrawUI();
      flowchartTick();
    });

    return {
      t,
      commercialCampaignKey,
      formValidationErrors,
      componentFieldErrorsKey,
      settingsFields,
      optionsFields,
    };
  },
});
</script>

<style lang="scss">

</style>
