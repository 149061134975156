<template>
  <div v-if="type==='title'">
    <div class="flex align-items-center py-3">
      <Checkbox
        :id="value"
        :key="value"
        v-model="groupElementArr"
        :data-group="group"
        :value="value"
        class="groups"
        @change="(event)=>groupClicked(event)"
      />
      <h4 class="title">
        <span class="title-span">&nbsp; {{ t(label) }}</span>
      </h4>
    </div>
    <div
      class="card-parent"
    >
      <div v-if="nodes!==undefined && nodes.length">
        <Permissions
          v-for="(permission, index) in nodes"
          :key="`${permission.value}-${index}`"
          :nodes="permission.nodes"
          :label="permission.label"
          :value="permission.value"
          :group="permission.group"
          :type="permission.type"
          :depth="2"
          :hidden="permission.hidden"
        />
      </div>
    </div>
  </div>
  <div
    v-else-if="type==='sub-title'"
    class="col-3 mb-2 align-items-center border-round"
    style="min-height:150px"
  >
    <div
      class="p-card"
    >
      <div
        v-show="!hidden"
        class="p-card-title card-subtitle"
      >
        <div class="flex align-items-center mb-2">
          <Checkbox
            :id="value"
            :key="value"
            v-model="subGroupElementArr"
            class="mr-2 sub-group"
            :data-group="group"
            :value="value"
            @change="(event)=>subGroupClicked(event)"
          />
          <label>
            {{ t(label) }}
          </label>
        </div>
      </div>
      <div class="p-card-body">
        <div
          v-for="(permission, index) in nodes"
          :key="`${t(value)}-${index}`"
          class="field-checkbox"
        >
          <Checkbox
            :id="permission.value"
            :key="`${value}.${index}`"
            v-model="permissionsArr"
            :datasrc="value"
            :data-group="group"
            class="perms"
            :value="permission.value"
            @change="checkboxClicked"
          />

          <label
            class="checkbox-set"
            :for="uniqueId"
          >{{ t(permission.label) }}</label>
        </div>
      </div>
    </div>
  </div>

  <div v-if="type==='root'">
    <Permissions
      v-for="(permission, index) in nodes"
      :key="`${permission.value}-${index}`"
      :nodes="permission.nodes"
      :label="permission.label"
      :value="permission.value"
      :type="permission.type"
      :group="permission.group"
      :depth="1"
      :hidden="permission.hidden"
    />
  </div>
</template>

<script lang="ts">
import Checkbox from 'primevue/checkbox';
import {
  defineComponent, PropType,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { groupElementArr, permissionsArr, subGroupElementArr } from '@/configs/permissions';

export default defineComponent({
  name: 'Permissions',

  components: {
    Checkbox,
  },

  props: {
    nodes: {
      type: Array as PropType<{ [key: string]: any }[]>,
      required: false,
      default: () => [],
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    value: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: '',
    },

    group: {
      type: String,
      required: true,
    },

    depth: {
      type: Number,
      required: false,
      default: 0,
    },

    hidden: {
      type: Boolean,
      required: true,
    },

  },

  setup(props: {nodes: object[]; label: string; value: string; type: string; group: string; depth: number }) {
    const { t } = useI18n();

    const uniqueId = `${t(props.label)}-${props.depth}`;

    const addToExistingPermissions = (elementId: string) => {
      const elements = document.querySelectorAll(`div[datasrc^="${elementId}"]`);
      elements.forEach((element) => {
        if (permissionsArr.value.indexOf(element.id) === -1) {
          permissionsArr.value.push(element.id);
        }
      });
    };

    const removeElementFromGroups = (groupElementId: string) => {
      groupElementArr.value = groupElementArr.value.filter((gValue) => gValue !== groupElementId);
    };

    const removeElementFromSubGroupsBySubStr = (subGroupElementId: string) => {
      subGroupElementArr.value = subGroupElementArr.value.filter((sGValue) => sGValue.split('.')[1] !== subGroupElementId);
    };

    const removeSubgroupsByGroupStr = (subGroupElementId: string) => {
      subGroupElementArr.value = subGroupElementArr.value.filter((sGValue) => sGValue.split('.')[0] !== subGroupElementId);
    };

    const removeElementFromSubGroups = (subGroupElementId: string) => {
      subGroupElementArr.value = subGroupElementArr.value.filter((subPValue) => subPValue !== subGroupElementId);
    };

    const getSubGroupElements = (elementId: string) => document.querySelectorAll(`div[data-group^="${elementId}"][class$=sub-group]`);

    const getPermissionElements = (groupId: string) => document.querySelectorAll(`div[datasrc^="${groupId}"][class$=perms]`);

    const clickGroup = (groupValue: string) => {
      const subGroupElements = getSubGroupElements(groupValue);
      const filteredSubGroups = subGroupElementArr.value.filter((pValue) => groupValue === pValue.split('.')[0]);
      if (filteredSubGroups.length === subGroupElements.length) {
        groupElementArr.value.push(groupValue);
      }
    };

    const clickSubGroups = (subGroups: NodeListOf<Element>) => {
      subGroups.forEach((subGroupElement) => {
        if (subGroupElementArr.value.indexOf(subGroupElement.id) === -1) {
          subGroupElementArr.value.push(subGroupElement.id);
        }
      });
    };

    const clickAllPermissionsForGroup = (groupId: string) => {
      const elements = getPermissionElements(groupId);

      Object.values(elements).forEach((element) => {
        if (permissionsArr.value.indexOf(element.id) === -1) {
          permissionsArr.value.push(element.id);
        }
      });
    };

    const subGroupClicked = (event: any) => {
      const elementObj = event.target.offsetParent.id;
      const subGroupElementId = elementObj.split('.')[1];
      const groupElementId = elementObj.split('.')[0];
      const { className } = event.target;

      if (event) {
        // lack of p-checkbox-icon class means its clicked
        if (!className.includes('p-checkbox-icon') && !className.includes('p-highlight')) {
          clickAllPermissionsForGroup(elementObj);
          // click group if all subgroups are clicked
          clickGroup(groupElementId);
        } else {
          removeElementFromGroups(groupElementId);
          removeElementFromSubGroupsBySubStr(subGroupElementId);

          permissionsArr.value = permissionsArr.value.filter((pValue) => pValue.split('.')[0] !== subGroupElementId);
        }
      }
    };

    const groupClicked = (event: any) => {
      if (event) {
        const elementId = event.target.offsetParent.id;
        const subGroups = getSubGroupElements(elementId);
        const { className } = event.target;
        // grouped clicked => click all subgroups
        if (!className.includes('p-checkbox-icon') && !className.includes('p-highlight')) {
          clickSubGroups(subGroups);

          addToExistingPermissions(elementId);
        } else {
          removeSubgroupsByGroupStr(elementId);

          // remove all permission for each subgroup
          permissionsArr.value = permissionsArr.value.filter((pValue) => {
            let found = true;
            subGroups.forEach((subGroup) => {
              if (pValue.split('.')[0] === subGroup.id.split('.')[1]) {
                found = false;
              }
            });
            return found;
          });
        }
      }
    };

    const checkboxClicked = (event: any) => {
      if (event) {
        const { attributes } = event.target.offsetParent;
        const subGroupElementStr = attributes.datasrc.value;
        const elements = getPermissionElements(subGroupElementStr);
        const groupValue = attributes['data-group'].value;

        const filteredSubGroups = permissionsArr.value.filter((pValue) => subGroupElementStr.split('.')[1] === pValue.split('.')[0]);
        if (filteredSubGroups.length === elements.length) {
          subGroupElementArr.value.push(subGroupElementStr);
        } else {
          removeElementFromSubGroups(subGroupElementStr);
          removeElementFromGroups(groupValue);
        }

        // click the group
        clickGroup(groupValue);
      }
    };

    return {
      permissionsArr,
      t,
      uniqueId,
      groupClicked,
      checkboxClicked,
      subGroupClicked,
      subGroupElementArr,
      groupElementArr,
    };
  },
});
</script>

<style lang="scss" scoped>
div.card-parent > div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.checkbox-set {
  margin-left: .5rem;
  line-height: 1;
}

.title {
  font-size: 1.1rem;
  font-weight: normal;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #d9d9d9;
  line-height: 1.2rem;
}

.title-span {
  background:#fff;
}

.p-card {
  width:100%;
  height: 100%;
  box-shadow: none !important;
  border: solid 1px $shuttle-light-grey;

  & .p-card-title {
    font-size: 1.2rem;
    font-weight: normal;
    border-bottom: none !important;
    padding: 0 0 10px;

    &.card-subtitle {
      background: $shuttle-light-grey;
      padding: 10px;

      & label {
        font-size: 1rem;
      }
    }
  }
}
</style>
