import { ComponentNameEnum, BaseStepperPxPercentSliderFieldConfig } from '@/types';

const getWidthFieldConfig = (defaultValue = 100): BaseStepperPxPercentSliderFieldConfig => ({
  componentName: ComponentNameEnum.STEPPER_PX_PERCENT_SLIDER,
  options: {
    '%': {
      min: 0,
      max: 100,
      step: 1,
      defaultValue,
      isDefaultUnitSelected: true,
    },
    px: {
      min: 0,
      max: 'PARENT_WIDTH',
      step: 1,
      defaultValue,
      isDefaultUnitSelected: false,
    },
  },
});

export default getWidthFieldConfig;
