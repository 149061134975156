<template>
  <label class="block my-3">
    {{ t('templateBuilder.fields.elementsConfiguration', null) }}
  </label>
  <div class="elements-configuration">
    <draggable
      v-model="inputs"
      item-key="id"
      :disabled="!configs.options.draggable"
      @change="handleChangeValue"
    >
      <template #item="{ element }">
        <div class="field-wrapper mb-5 draggable-field relative">
          <label class="block mb-3">
            <i
              v-if="configs.options.draggable"
              data-test-id="drag-icon"
              class="fas fa-arrows-alt-v mr-2 drag-icon"
            />

            {{ t(element.label, null) }}

          </label>
          <div
            v-if="element.hasOwnProperty('required')"
            class="mandatory"
          >
            <Checkbox
              v-model="element.required"
              :input-id="`mandatoryCheckbox-${element.key}`"
              binary
              @change="handleChangeRequired(element)"
            />
            <label
              :for="`mandatoryCheckbox-${element.key}`"
            >
              {{ t('templateBuilder.mandatory', null) }}
            </label>
          </div>
          <div
            v-if="!Object.prototype.hasOwnProperty.call(configs.options, 'displayFields') || configs.options.displayFields"
            class="p-fluid flex"
          >
            <InputText
              v-model="element.value"
              data-test-id="input-text"
              @input="handleChangeValue"
            />
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import {
  FieldConfig, InputGroupField, ParserFieldObject, Property,
} from '@/types';
import {
  defineComponent, PropType, Ref, ref, watch,
} from 'vue';
import draggable from 'vuedraggable';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'InputGroup',
  components: {
    InputText,
    Checkbox,
    draggable,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props: {parserValues: ParserFieldObject; configs: FieldConfig}, context) {
    const { t } = useI18n();
    const property: Ref<Property> = ref(props.parserValues.properties[0]);
    const inputs = ref(property.value.value);

    const handleChangeRequired = (input: InputGroupField) => {
      property.value.value = inputs.value.map((field: InputGroupField) => [input].find((o) => o.key === field.key) || field);
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };

    const handleChangeValue = () => {
      property.value.value = inputs.value;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value],
      });
    };

    watch(() => property.value.value, (values, oldValues) => {
      if (values !== oldValues) {
        inputs.value = property.value.value;
      }
    }, { immediate: true });

    return {
      t,
      inputs,
      handleChangeValue,
      handleChangeRequired,
    };
  },
});
</script>

<style scoped lang="scss">
.elements-configuration {
  padding: 15px;
  border: solid 1px $solitude;
  border-radius: 3px;

  > div {
    margin-bottom: 0 !important;
  }

  & .draggable-field:last-child {
    margin-bottom: 0 !important;
  }
}

.p-inputtext {
  font-size: 1.25rem !important;
  padding: 0.625rem 0.625rem !important;
}

.mandatory {
  font-size: 0.8em;
  top: 0px;
  position: absolute;
  right: 0;

  > .p-checkbox {
    width: 15px !important;
    height: 15px !important;
    > :deep() .p-checkbox-box {
      width: 15px !important;
      height: 15px !important;
    }
  }

  & label {
    font-size: 0.9rem !important;
    padding-left: 3px;
  }
}

.drag-icon {
  color: $heather;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}
</style>
