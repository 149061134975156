import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { DaysOfWeek, TypeCampaignEnum } from '@/types';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import { required, requiredIf } from '@vuelidate/validators';
// eslint-disable-next-line import/no-cycle
import { includesValuesValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';

export interface DeclencheurPeriodicLaunchingData {
  time: string;
  multiplebox_trigger_timezone_clients: string;
  multiplebox_trigger_type: string;
  multiplebox_trigger_days: {
    [key: number]: DaysOfWeek;
  };
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurPeriodicLaunchingMetadata: ComponentMetadata<DeclencheurPeriodicLaunchingData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxperiodiclaunching',
    label: 'automatedScenarios.triggers.periodicLaunching.label',
    icon: 'fa-calendar',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxsitepasstime', 'boxquitsite',
          'boxperiodiclaunching', 'boxscrollpage', 'boxstatuscommand',
          'boxcustomjs', 'registeronsite', 'subscribetonewsletter',
          'boxtag', 'boxlist', 'boxsendemail',
          'boxsendsms', 'boxabtest', 'boxdisplayfreepopup',
          'boxdisplayfreemodel', 'boxsendfbmessenger', 'boxsendpushnotification',
          'openemail', 'clicklink', 'boxvisitpage',
          'boxdelaismax', 'boxnavigationtrigger', 'boxwait',
        ],
      },
    },
    component: 'DeclencheurPeriodicLaunching',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({
    time,
    multiplebox_trigger_timezone_clients,
    multiplebox_trigger_type,
    multiplebox_trigger_days,
  }: DeclencheurPeriodicLaunchingData = {
    time: '20:00',
    multiplebox_trigger_timezone_clients: '1',
    multiplebox_trigger_type: '0',
    multiplebox_trigger_days: {},
  }): ComponentData<DeclencheurPeriodicLaunchingData> {
    return {
      id: 'boxperiodiclaunching',
      form_name: 'boxperiodiclaunching',
      logo_box: 'fa-calendar',
      time,
      multiplebox_trigger_timezone_clients,
      multiplebox_trigger_type,
      multiplebox_trigger_days,
    };
  },
  async Validate(data: DeclencheurPeriodicLaunchingData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      multiplebox_trigger_type: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
      multiplebox_trigger_days: {
        required: requiredIf(() => data.multiplebox_trigger_type === '1'),
      },
      time: {
        required,
      },
      multiplebox_trigger_timezone_clients: {
        required,
        includesValuesValidator: includesValuesValidator(['0', '1']),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: DeclencheurPeriodicLaunchingData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurPeriodicLaunchingData): string {
    return translation.global.t('automatedScenarios.triggers.periodicLaunching.label');
  },
};

export default DeclencheurPeriodicLaunchingMetadata;
