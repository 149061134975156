
import {
  computed, defineComponent, PropType, Ref, ref,
} from 'vue';
import { MenuItemsModel, ToolbarItemsModel } from '@/types';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import Menu from 'primevue/menu';

export default defineComponent({
  name: 'MenuRenderer',

  components: {
    Button,
    Menu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    actions: {
      type: Object as PropType<MenuItemsModel>,
      required: false,
      default: () => ({
        menuItems: [],
      }),
    },

    data: {
      type: Object,
      required: true,
    },
  },

  setup(props: { actions: MenuItemsModel; data: any }) {
    const menuActions = computed(() => props.actions);

    const menuRefs: Ref<any []> = ref([]);

    const toggleMenu = (event: Event, index: string) => {
      if (menuRefs.value) {
        menuRefs.value[index as keyof object].toggle(event);
      }
    };
    return {
      menuActions, menuRefs, toggleMenu,
    };
  },

});
