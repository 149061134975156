
import {
  defineComponent,
  ref,
  computed,
} from 'vue';

import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';

import { uploadFile } from '@/composables/shop/MyShopParameters';
import { showToastError, showToastSuccess } from '@/helpers';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ImageUploader',

  components: {
    FileUpload,
    ProgressBar,
    Button,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    emptyLabel: {
      type: String,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue', 'on-loading', 'uploaded'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const isLoading = ref(false);
    const resizedFile: any = ref();
    const data = ref('');

    const imagePath = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    const resizeImage = async (file: File) => new Promise((resolve, reject) => {
      let tempResizedFile: File;
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        const image = new Image();
        image.src = imageUrl;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
          if (!ctx) reject();

          const ratio = image.width / image.height;
          let newWidth = image.width;
          let newHeight = image.height;

          if (newWidth > 300) {
            newWidth = 300;
            newHeight = newWidth / ratio;
          }

          if (newHeight > 300) {
            newHeight = 300;
            newWidth = newHeight * ratio;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          if (ctx !== null) {
            ctx.drawImage(image, 0, 0, newWidth, newHeight);
          }

          canvas.toBlob((blob: any) => {
            tempResizedFile = new File([blob], file.name, {
              type: file.type,
            });

            resizedFile.value = tempResizedFile;
            resolve(resizedFile.value);
          }, file.type);
        };
      }
    });

    const convertImageToBase64 = async (file: any) => new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const base64Data = e.target.result;
        data.value = base64Data;
        resolve(base64Data);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      // Read the image file as a data URL
      reader.readAsDataURL(file);
    });

    const handleUpload = async (imageField: any, event: any) => {
      if (event.files.length === 0) {
        await showToastError('GENERIC_ERROR');
        return;
      }

      const file = event.files[0];
      isLoading.value = true;
      emit('on-loading');
      await resizeImage(file);
      await convertImageToBase64(resizedFile.value);
      uploadFile(data.value, props.shopId, 'logoShop').then((response: any) => {
        imagePath.value = `${response.data.replace('\\', '')}?v=${new Date().getTime()}`;
        isLoading.value = false;
        emit('uploaded');
      }).catch(async (err: any) => {
        await showToastError('FILE_UPLOAD_FAILED');
        isLoading.value = false;
        emit('uploaded');
      });
    };

    return {
      t,
      isLoading,
      imagePath,
      handleUpload,
    };
  },

});
