import { ComponentNameEnum, BaseStepperPxPercentSliderFieldConfig } from '@/types';

const minimalHeightFieldConfig: BaseStepperPxPercentSliderFieldConfig = {
  componentName: ComponentNameEnum.STEPPER_PX_PERCENT_SLIDER,
  options: {
    px: {
      min: 10,
      max: 950,
      step: 1,
      defaultValue: 10,
      isDefaultUnitSelected: true,
    },
  },
};

export default minimalHeightFieldConfig;
