<template>
  <SubPanelConfigContent
    v-if="!templateSelectorIsOpen"
    :key="panelsKeys"
    :panels="panels"
  >
    <template #template_preview>
      <TabView
        style="text-align: center"
        :scrollable="true"
        @tab-change="handleActivePreviewTemplate"
      >
        <TabPanel
          v-for="(tab, indexTab) in configSendingChannelBox.listTemplates.value"
          :key="tab.id_template"
        >
          <template #header>
            <span
              v-tooltip="{ value: tab.label, class: 'tooltip-icons-outside' }"
              class="p-tabview-title"
            >
              {{ tab.label.length > 21 ? tab.label.substring(0, 21) + '...' : tab.label }}
            </span>
          </template>
          <div>
            <div class="toggle-screen-responsive">
              <div class="tsr_actions">
                <div style="float:left;">
                  <span class="p-buttonset toolbar-buttons-container">
                    <Button
                      v-if="templateMediaPreview.desktop.includes(tab.type)"
                      :class="{ 'active': activePreviewMedia === 'desktop' }"
                      icon="far fa-desktop"
                      class="tsr-button-item"
                      @click="selectMedia('desktop')"
                    />
                    <Button
                      v-if="templateMediaPreview.mobile.includes(tab.type)"
                      :class="{ 'active': activePreviewMedia === 'mobile' }"
                      icon="far fa-mobile"
                      class="tsr-button-item"
                      @click="selectMedia('mobile')"
                    />
                  </span>
                </div>
                <div style="float:right;">
                  <Button
                    type="button"
                    class="tsr-button-item"
                    aria-haspopup="true"
                    icon="far fa-ellipsis-v"
                    icon-pos="right"
                    @click="(e)=>{ toggleMenuTemplateActions(e) }"
                  />
                  <Menu
                    ref="menuTemplateActions"
                    :model="itemsTemplateActions"
                    popup
                  />
                </div>
              </div>
              <div class="tsr_content">
                <img
                  :key="tmpImgRefreshKey"
                  :src="`${baseImagePreviewUrl + tab.imageKey}${indexTab > 0 ? '-isdisplay' : ''}.png&w=600&${new Date().getTime()}`"
                  style="display: none"
                  @load="imageLoaded(tab.id_template)"
                  @error="imageFailed(tab.id_template)"
                >
                <div
                  v-if="templateMediaPreview.desktop.includes(tab.type) && activePreviewMedia === 'desktop'"
                  class="tsr_content_desktop template_preview_container"
                  :style="`background-image:url(${baseImagePreviewUrl}${tab.imageKey}${indexTab > 0 ? '-isdisplay' : ''}.png&w=600&${imageLoadTime[tab.id_template]});`"
                />
                <div
                  v-if="templateMediaPreview.mobile.includes(tab.type) && activePreviewMedia === 'mobile'"
                  class="tsr_content_mobile template_preview_container"
                  :style="`background-image:url(${baseImagePreviewUrl}${tab.imageKey}${indexTab > 0 ? '-isdisplay' : ''}-responsive.png&w=300&${imageLoadTime[tab.id_template]});`"
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </template>
    <template #template_config>
      <div
        v-if="reusePreviousCouponField"
        class="field-group pt-2"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.template_config.reuse_a_previous_coupon.label', null) }}
              </label>
              <div class="p-fluid flex">
                <Dropdown
                  v-model="data.reuse_a_previous_coupon"
                  class="col-12 p-0"
                  :options="reusePreviousCouponOptions"
                  option-label="label"
                  option-value="value"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="reuse_a_previous_coupon"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #voucher_config>
      <div
        class="field-group pt-2"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.method.label', null) }}
                <i
                  v-tooltip="{ value: t('automatedScenarios.actions.sending_channel.vouchers_config.method.tooltip'),
                               class: 'tooltip-icons-outside' }"
                  class="fas fa-question-circle"
                  data-html="true"
                  data-toggle="tooltip"
                  aria-hidden="true"
                />
              </label>
              <div class="p-fluid flex">
                <Dropdown
                  v-model="data.template_display_reduction_method"
                  class="col-12 p-0"
                  :options="voucherMethodOptions"
                  option-label="label"
                  option-value="value"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_method"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_method === voucherMethodsValues.MANUAL"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.manual_code.label', null) }}
                <i
                  v-tooltip="{ value: t('automatedScenarios.actions.sending_channel.vouchers_config.manual_code.tooltip', null), class: 'tooltip-icons-outside' }"
                  class="fas fa-question-circle"
                  data-html="true"
                  data-toggle="tooltip"
                  aria-hidden="true"
                />
              </label>
              <div class="p-fluid flex">
                <InputText
                  v-model="data.template_display_reduction_identifiant"
                  type="text"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_identifiant"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.description.label', null) }}
              </label>
              <div class="p-fluid flex">
                <LocalizedTextInput
                  id="template_display_reduction_descript"
                  v-model="data.template_display_reduction_descript"
                  class="w-full"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_descript"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.type.label', null) }}
                <i
                  v-tooltip="{ value: t('automatedScenarios.actions.sending_channel.vouchers_config.type.tooltip', null), class: 'tooltip-icons-outside' }"
                  class="fas fa-question-circle"
                  data-html="true"
                  data-toggle="tooltip"
                  aria-hidden="true"
                />
              </label>
              <div class="p-fluid flex">
                <Dropdown
                  v-model="data.template_display_reduction_type"
                  class="col-12 p-0"
                  :options="voucherTypeOptions"
                  option-label="label"
                  option-value="value"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_type"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_type === voucherTypeValues.PERCENTAGE"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.amount.label_percentage', null) }}
              </label>
              <div class="p-fluid flex">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">%</span>
                  <InputNumber
                    v-model="data.template_display_reduction_amount"
                    :min="1"
                    :max="100"
                  />
                </div>
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_amount"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_type === voucherTypeValues.AMOUNT"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.amount.label_amount', null) }}
              </label>
              <div class="p-fluid flex">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">€</span>
                  <InputNumber
                    v-model="data.template_display_reduction_amount"
                    :min="1"
                    :max="1000000"
                  />
                </div>
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_amount"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC
          && data.template_display_reduction_type === voucherTypeValues.DUPLICATED"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.duplicate_code.label', null) }}
              </label>
              <div class="p-fluid flex">
                <InputText
                  v-model="data.template_display_reduction_duplicate"
                  type="text"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_duplicate"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.minimum_amount.label', null) }}
                <i
                  v-tooltip="{ value: t('automatedScenarios.actions.sending_channel.vouchers_config.minimum_amount.tooltip', null), class: 'tooltip-icons-outside' }"
                  class="fas fa-question-circle"
                  data-html="true"
                  data-toggle="tooltip"
                  aria-hidden="true"
                />
              </label>
              <div class="p-fluid flex">
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">Eur</span>
                  <InputNumber
                    v-model="data.template_display_reduction_minorderamount"
                    show-buttons
                    :min="0"
                  />
                </div>
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_minorderamount"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.label', null) }}
                <i
                  v-tooltip="{ value: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.tooltip', null), class: 'tooltip-icons-outside' }"
                  class="fas fa-question-circle"
                  data-html="true"
                  data-toggle="tooltip"
                  aria-hidden="true"
                />
              </label>
              <div class="p-fluid flex">
                <Dropdown
                  v-model="data.template_display_reduction_validtime"
                  class="col-12 p-0"
                  :options="periodOfValidityOptions"
                  option-label="label"
                  option-value="value"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_validtime"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.vouchers_config.prefix.label', null) }}
                <i
                  v-tooltip="{ value: t('automatedScenarios.actions.sending_channel.vouchers_config.prefix.tooltip', null), class: 'tooltip-icons-outside' }"
                  class="fas fa-question-circle"
                  data-html="true"
                  data-toggle="tooltip"
                  aria-hidden="true"
                />
              </label>
              <div class="p-fluid flex">
                <InputText
                  v-model="data.template_display_reduction_prefix"
                  type="text"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_display_reduction_prefix"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #message_config>
      <div
        v-if="data.hasOwnProperty('template_message_subject')"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.message_config.subject.label', null) }}
              </label>
              <div class="p-fluid flex">
                <LocalizedTextInput
                  id="template_message_subject"
                  v-model="data.template_message_subject"
                  :display-emojis="true"
                  :display-variables="true"
                  :display-translate="true"
                  :display-ai-button="true"
                  :ai-params="{ aiContentType: 2, idTemplate: activePreviewTemplate.id_template }"
                  :variables-list="variables.allVariables"
                  class="w-full"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_message_subject"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.hasOwnProperty('template_message_expname')"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.message_config.expname.label', null) }}
              </label>
              <div class="p-fluid flex">
                <LocalizedTextInput
                  id="template_message_expname"
                  v-model="data.template_message_expname"
                  type="auto_complete"
                  :display-emojis="true"
                  :complete="(query) => searchValuesForAutocompletion('senderName', query, myDataAutoCompletion)"
                  class="w-full"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_message_expname"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.hasOwnProperty('template_message_expmail')"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.message_config.expmail.label', null) }}
              </label>
              <div class="p-fluid flex">
                <LocalizedTextInput
                  id="template_message_expmail"
                  v-model="data.template_message_expmail"
                  type="auto_complete"
                  :complete="(query) => searchValuesForAutocompletion('senderAddress', query, myDataAutoCompletion)"
                  class="w-full"
                  :continue-reloading="true"
                />
              </div>
              <span style="font-size: 12px; color: #495057;">
                {{ t('emailDomainValid.message', null) }}
                <span
                  style="cursor: pointer; text-decoration: underline; color: blue;"
                  @click="goToDomainPage"
                >
                  {{ t('emailDomainValid.click', null) }}
                </span></span>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_message_expmail"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="data.hasOwnProperty('template_message_replytomail')"
        class="field-group pt-3"
      >
        <div>
          <div class="field-group-content">
            <div class="field-wrapper">
              <label class="block mb-2">
                {{ t('automatedScenarios.actions.sending_channel.message_config.replytomail.label', null) }}
              </label>
              <div class="p-fluid flex">
                <LocalizedTextInput
                  id="template_message_replytomail"
                  v-model="data.template_message_replytomail"
                  type="auto_complete"
                  :complete="(query) => searchValuesForAutocompletion('senderAddress', query, myDataAutoCompletion)"
                  class="w-full"
                  :continue-reloading="true"
                />
              </div>
              <FieldErrors
                :key="componentFieldErrorsKey"
                :errors="formValidationErrors"
                field="template_message_replytomail"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </SubPanelConfigContent>
  <TemplateSelector
    v-else
    :type-templates="typeTemplateSelector"
    :origin-call="originCall"
    :id-campaign="idCampaign"
    :duplicate="true"
    @on-choose-template="selectTemplate"
    @on-close-dialog="closeTemplateSelector"
  />
  <SendTestModal
    v-if="showSendTestModal"
    :type-of-input="activePreviewTemplate.type"
    @on-validate="(emitParameters) => sendTestTemplate(emitParameters)"
  />
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  computed, defineComponent, onMounted, PropType, reactive, Ref, ref, SetupContext, watch,
} from 'vue';
import { OperatorType, TemplatesCustom } from '@/types/generated-types/graphql';
import { asInt } from '@/helpers';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import { UserState } from '@/composables/User';
import { getComponentMetadata } from '@/components/automated-scenarios/metadata';
import { ActionSendEmailData } from '@/components/automated-scenarios/metadata/actions/ActionSendEmailMetadata';
import SubPanelConfigContent from '@/components/automated-scenarios/SubPanelConfigContent.vue';
import variables from '@/components/template-builder/utils/variables-list';
import {
  getConfigSendingChannelBox,
  reusePreviousCouponValues,
  voucherMethodsValues,
  voucherTypeValues,
} from '@/composables/automated-scenarios/AutomatedScenariosSendingChannel';
import Menu from 'primevue/menu';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import { ActionSendSmsData } from '@/components/automated-scenarios/metadata/actions/ActionSendSmsMetadata';
import TemplateSelector from '@/components/automated-scenarios/fields/TemplateSelector.vue';
import {
  AutomatedScenarioState,
  backOperatorSelect,
  EditingTemplate,
  EditingTemplateStatusEnum,
  EditingTemplateTypeEnum,
  getEditingTemplateFromCampaign,
  resetDataFromTemplateBuilder,
  setEditingTemplateFromCampaign,
} from '@/composables/AutomatedScenarios';
import {
  ActionDisplayFreeModelData,
} from '@/components/automated-scenarios/metadata/actions/ActionDisplayFreeModelMetadata';
import {
  ActionSendFbMessengerData,
} from '@/components/automated-scenarios/metadata/actions/ActionSendFbMessengerMetadata';
import {
  ActionDisplayFreePopupData,
} from '@/components/automated-scenarios/metadata/actions/ActionDisplayFreePopupMetadata';
import {
  ActionSendPushNotificationData,
} from '@/components/automated-scenarios/metadata/actions/ActionSendPushNotificationMetadata';
import { activate as activateTemplate } from '@/composables/template-editor/TemplateEditor';
import { replaceRedirectionTemplate, TemplateTypeEnum } from '@/composables/shop/Templates';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import { store } from '@/store';
import getShopsDomains from '@/composables/shop/ShopsSendDomains';
import { TypeCampaignEnum } from '@/types';
import {
  loadSenderAddresses,
  loadSenderNames,
  searchValuesForAutocompletion,
} from '@/composables/autoCompletionValuesMedia';
import SendTestModal, { Suggestion } from '@/components/modals/SendTestModal.vue';
import { useRouter } from 'vue-router';
import { nestGet } from '@/composables/nestApi';
import { showToastError, showToastSuccess } from '@/helpers';

export default defineComponent({
  name: 'ActionConfig',

  components: {
    LocalizedTextInput,
    TemplateSelector,
    FieldErrors,
    SubPanelConfigContent,
    TabView,
    TabPanel,
    Button,
    InputText,
    Dropdown,
    Menu,
    InputNumber,
    SendTestModal,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: true,
    },

    originCall: {
      type: String,
      required: true,
      default: 'automation',
    },

    idCampaign: {
      type: Number,
      required: true,
    },

    media: {
      type: String,
      default: 'email',
      required: true,
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },

    selectedComponent: {
      type: String,
      required: false,
      default: '',
    },

    showSendMessage: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: any; originCall: string; idCampaign: number; media: string; formValidation: any; selectedComponent: string; showSendMessage: boolean }, context: SetupContext) {
    const { t } = useI18n();
    const router = useRouter();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const panelsKeys = ref(0);

    /* Handling form errors */
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const templateSelectorIsOpen = ref(false);
    const typeTemplateSelector = ref(props.media);

    /* Preview configuration */
    const baseImagePreviewUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;
    const tmpImgRefreshKey = ref(0);
    const imageLoadTime = ref<Record<number, number>>({});
    const imagesLoaded = ref<Record<number, boolean>>({});
    const imageLoaded = (id_template: number) => {
      // verifier si l'image est déjà marquée comme chargée
      if (!imagesLoaded.value[id_template]) {
        imagesLoaded.value[id_template] = true;
        imageLoadTime.value[id_template] = new Date().getTime();
      }
    };

    const imageFailed = (id_template: number) => {
      // Vérifier si l'image est déjà marquée comme chargée
      if (!imagesLoaded.value[id_template]) {
        setTimeout(() => {
          imageLoadTime.value[id_template] = new Date().getTime();
        }, 1000); // Tente de recharger l'image toutes les 3 secondes
      }
    };

    const activePreviewTemplate = ref({
      id_template: 0,
      type: typeTemplateSelector.value,
      label: '',
    });
    const activePreviewMedia = ref('desktop');
    const templateMediaPreview = {
      desktop: ['email', 'popup', 'popupVoucher', 'popupNlForm', 'embed', 'embedNlForm', 'xSellsEmbed'],
      mobile: ['email', 'sms', 'facebookmessenger', 'pushnotifications', 'popup', 'popupVoucher', 'popupNlForm', 'embed', 'embedNlForm', 'xSellsEmbed'],
    };

    let data: any = null;
    if (props.media === 'email') {
      data = reactive<ActionSendEmailData>({
        template_display_selector_id: props.modelValue.template_display_selector_id,
        reuse_a_previous_coupon: asInt(props.modelValue.reuse_a_previous_coupon),
        display_popup_on_site: asInt(props.modelValue.display_popup_on_site),
        template_display_reduction_method: asInt(props.modelValue.template_display_reduction_method),
        template_display_reduction_descript: props.modelValue.template_display_reduction_descript,
        template_display_reduction_type: asInt(props.modelValue.template_display_reduction_type),
        template_display_reduction_identifiant: props.modelValue.template_display_reduction_identifiant,
        template_display_reduction_amount: asInt(props.modelValue.template_display_reduction_amount),
        template_display_reduction_duplicate: props.modelValue.template_display_reduction_duplicate,
        template_display_reduction_minorderamount: asInt(props.modelValue.template_display_reduction_minorderamount),
        template_display_reduction_validtime: asInt(props.modelValue.template_display_reduction_validtime),
        template_display_reduction_prefix: props.modelValue.template_display_reduction_prefix,
        template_display_message_langs: props.modelValue.template_display_message_langs,
        template_message_subject: props.modelValue.template_message_subject,
        template_message_expname: props.modelValue.template_message_expname,
        template_message_expmail: props.modelValue.template_message_expmail,
        template_message_replytomail: props.modelValue.template_message_replytomail,
      });
    } else if (props.media === 'sms') {
      data = reactive<ActionSendSmsData>({
        template_display_selector_id: props.modelValue.template_display_selector_id,
        reuse_a_previous_coupon: asInt(props.modelValue.reuse_a_previous_coupon),
        display_popup_on_site: asInt(props.modelValue.display_popup_on_site),
        template_display_reduction_method: asInt(props.modelValue.template_display_reduction_method),
        template_display_reduction_descript: props.modelValue.template_display_reduction_descript,
        template_display_reduction_type: asInt(props.modelValue.template_display_reduction_type),
        template_display_reduction_identifiant: props.modelValue.template_display_reduction_identifiant,
        template_display_reduction_amount: asInt(props.modelValue.template_display_reduction_amount),
        template_display_reduction_duplicate: props.modelValue.template_display_reduction_duplicate,
        template_display_reduction_minorderamount: asInt(props.modelValue.template_display_reduction_minorderamount),
        template_display_reduction_validtime: asInt(props.modelValue.template_display_reduction_validtime),
        template_display_reduction_prefix: props.modelValue.template_display_reduction_prefix,
        template_display_message_langs: props.modelValue.template_display_message_langs,
        template_message_expname: props.modelValue.template_message_expname,
      });
    } else if (['embed', 'facebookmessenger', 'pushnotifications', 'popup'].includes(props.media)) {
      data = reactive<ActionDisplayFreeModelData | ActionSendFbMessengerData | ActionSendPushNotificationData | ActionDisplayFreePopupData>({
        template_display_selector_id: props.modelValue.template_display_selector_id,
        reuse_a_previous_coupon: asInt(props.modelValue.reuse_a_previous_coupon),
        display_popup_on_site: asInt(props.modelValue.display_popup_on_site),
        template_display_reduction_method: asInt(props.modelValue.template_display_reduction_method),
        template_display_reduction_descript: props.modelValue.template_display_reduction_descript,
        template_display_reduction_type: asInt(props.modelValue.template_display_reduction_type),
        template_display_reduction_identifiant: props.modelValue.template_display_reduction_identifiant,
        template_display_reduction_amount: asInt(props.modelValue.template_display_reduction_amount),
        template_display_reduction_duplicate: props.modelValue.template_display_reduction_duplicate,
        template_display_reduction_minorderamount: asInt(props.modelValue.template_display_reduction_minorderamount),
        template_display_reduction_validtime: asInt(props.modelValue.template_display_reduction_validtime),
        template_display_reduction_prefix: props.modelValue.template_display_reduction_prefix,
        template_display_message_langs: props.modelValue.template_display_message_langs,
      });
    }

    const listTemplates: Ref<TemplatesCustom[]> = ref([]);
    const configSendingChannelBox = {
      listTemplates,
      templatesContainsVoucher: ref(false),
      parentsOperatorAlreadyConfiguredVoucher: ref(false),
      parentsFilterHaveExpiredVoucherSegment: ref(false),
    };

    const reusePreviousCouponField = computed(() => configSendingChannelBox.templatesContainsVoucher.value
      && (configSendingChannelBox.parentsOperatorAlreadyConfiguredVoucher.value || configSendingChannelBox.parentsFilterHaveExpiredVoucherSegment.value));

    /* List of panels */
    const displayPanels = {
      template_config: (): boolean => reusePreviousCouponField.value,

      voucher_config: (): boolean => {
        if (reusePreviousCouponField.value && data.reuse_a_previous_coupon === reusePreviousCouponValues.CONFIGURE_NEW_VOUCHER) {
          return true;
        }
        return configSendingChannelBox.templatesContainsVoucher.value && !reusePreviousCouponField.value;
      },

      // eslint-disable-next-line no-prototype-builtins
      message_config: (): boolean => data.hasOwnProperty('template_message_subject')
        // eslint-disable-next-line no-prototype-builtins
        || data.hasOwnProperty('template_message_expname')
        // eslint-disable-next-line no-prototype-builtins
        || data.hasOwnProperty('template_message_expmail')
        // eslint-disable-next-line no-prototype-builtins
        || data.hasOwnProperty('template_message_replytomail'),
    };
    const panels = computed(() => ([
      {
        name: 'template_preview',
        label: 'automatedScenarios.actions.sending_channel.template_preview.title',
        show: true,
      },
      {
        name: 'template_config',
        label: 'automatedScenarios.actions.sending_channel.template_config.title',
        show: displayPanels.template_config(),
      },
      {
        name: 'voucher_config',
        label: 'automatedScenarios.actions.sending_channel.vouchers_config.title',
        show: displayPanels.voucher_config(),
      },
      {
        name: 'message_config',
        label: 'automatedScenarios.actions.sending_channel.message_config.title',
        show: displayPanels.message_config(),
      },
    ]));

    /* Voucher configuration
        Method dropdown options
        Type dropdown options
        ValidTime dropdown options
     */
    const allowAutomaticMethodOption = ref(true);
    const voucherMethodOptions = computed(() => {
      if (allowAutomaticMethodOption.value) {
        return [
          {
            label: t('automatedScenarios.actions.sending_channel.vouchers_config.method.options.automatic'),
            value: voucherMethodsValues.AUTOMATIC,
          },
          {
            label: t('automatedScenarios.actions.sending_channel.vouchers_config.method.options.manual'),
            value: voucherMethodsValues.MANUAL,
          },
        ];
      }

      return [
        {
          label: t('automatedScenarios.actions.sending_channel.vouchers_config.method.options.manual'),
          value: voucherMethodsValues.MANUAL,
        },
      ];
    });

    const computedVoucherTypeOptions = computed(() => {
      if (data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC) {
        return [
          {
            label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.percentage'),
            value: voucherTypeValues.PERCENTAGE,
          },
          {
            label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.amount'),
            value: voucherTypeValues.AMOUNT,
          },
          {
            label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.shipping_fees'),
            value: voucherTypeValues.SHIPPING_FEES,
          },
          {
            label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.duplicated'),
            value: voucherTypeValues.DUPLICATED,
          },
        ];
      }

      if (data.template_display_reduction_type === voucherTypeValues.DUPLICATED) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        data.template_display_reduction_type = voucherTypeValues.PERCENTAGE;
      }

      return [
        {
          label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.percentage'),
          value: voucherTypeValues.PERCENTAGE,
        },
        {
          label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.amount'),
          value: voucherTypeValues.AMOUNT,
        },
        {
          label: t('automatedScenarios.actions.sending_channel.vouchers_config.type.options.shipping_fees'),
          value: voucherTypeValues.SHIPPING_FEES,
        },
      ];
    });
    const voucherTypeOptions = ref(computedVoucherTypeOptions);

    const periodOfValidityOptions = ref([
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.one_day'),
        value: 1,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.two_day'),
        value: 2,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.three_day'),
        value: 3,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.four_day'),
        value: 4,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.five_day'),
        value: 5,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.six_day'),
        value: 6,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.one_week'),
        value: 7,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.two_week'),
        value: 14,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.three_week'),
        value: 21,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.four_week'),
        value: 28,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.five_week'),
        value: 35,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.six_week'),
        value: 42,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.seven_week'),
        value: 49,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.eight_week'),
        value: 56,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.ten_week'),
        value: 70,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.twelve_week'),
        value: 84,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.fourteen_week'),
        value: 98,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.sixteen_week'),
        value: 112,
      },
      {
        label: t('automatedScenarios.actions.sending_channel.vouchers_config.period_of_validity.options.eighteen_week'),
        value: 126,
      },
    ]);

    /* Template configuration
        Reuse previous coupon dropdown options
     */
    const computedReusePreviousCouponOptions = computed(() => {
      const arrayToReturn = [
        {
          label: t('automatedScenarios.actions.sending_channel.template_config.reuse_a_previous_coupon.options.option_1'),
          value: reusePreviousCouponValues.CONFIGURE_NEW_VOUCHER,
        },
      ];
      if (configSendingChannelBox.parentsOperatorAlreadyConfiguredVoucher.value) {
        arrayToReturn.push(
          {
            label: t('automatedScenarios.actions.sending_channel.template_config.reuse_a_previous_coupon.options.option_2'),
            value: reusePreviousCouponValues.REUSE_FROM_PREVIOUS_BOX,
          },
        );
      }
      if (configSendingChannelBox.parentsFilterHaveExpiredVoucherSegment.value) {
        arrayToReturn.push(
          {
            label: t('automatedScenarios.actions.sending_channel.template_config.reuse_a_previous_coupon.options.option_3'),
            value: reusePreviousCouponValues.USE_EXPIRING_VOUCHER,
          },
        );
      }

      return arrayToReturn;
    });
    const reusePreviousCouponOptions = ref(computedReusePreviousCouponOptions);

    /* Action to execute when closing the template selector */
    const closeTemplateSelector = () => {
      templateSelectorIsOpen.value = false;
      typeTemplateSelector.value = activePreviewTemplate.value.type;
      if (data.template_display_selector_id === null) {
        /* If we have not yet chosen a primary template, we return to the choice of configuration boxes. */
        backOperatorSelect(false);
      }

      setEditingTemplateFromCampaign(null);
    };

    /* Action to execute when you want to change a template */
    const changeTemplate = (idTemplate: number, typeTemplate: string) => {
      const isRedirect = idTemplate !== data.template_display_selector_id;
      templateSelectorIsOpen.value = true;
      typeTemplateSelector.value = typeTemplate;

      const parentsRedirect: number[] = [];
      if (isRedirect) {
        configSendingChannelBox.listTemplates.value.forEach((item) => {
          if (item?.template_config) {
            const templateConfig = JSON.parse(item?.template_config);
            if (templateConfig.nextIdTemplate) {
              Object.keys(templateConfig.nextIdTemplate).forEach((lang) => {
                const next2 = templateConfig.nextIdTemplate[lang] ?? [];
                const next = next2.map((item2: string) => asInt(item2));

                if (next.includes(idTemplate) && !parentsRedirect.includes(idTemplate)) {
                  parentsRedirect.push(item.id_template);
                  return false;
                }
                return true;
              });
            }
          }
        });
      }

      const dataEditingTemplate: EditingTemplate = {
        status: EditingTemplateStatusEnum.IN_TEMPLATE_CHOOSER,
        id_campaign: props.idCampaign,
        type: EditingTemplateTypeEnum.CHANGE,
        oldIdTemplate: idTemplate,
        isRedirect,
        parentsRedirect,
      };
      setEditingTemplateFromCampaign(dataEditingTemplate);
    };

    /* Action to execute when you want to edit a template */
    const editTemplate = async (idTemplate: number, typeTemplate: string, labelTemplate: string) => {
      store.commit('general/showTheSpinner');
      const dataEditingTemplate: EditingTemplate = {
        status: EditingTemplateStatusEnum.IN_TEMPLATE_EDITOR,
        id_campaign: props.idCampaign,
        idTemplate,
        type: EditingTemplateTypeEnum.EDIT,
      };
      setEditingTemplateFromCampaign(dataEditingTemplate);

      await activateTemplate(idTemplate, labelTemplate, typeTemplate).then(() => {
        store.commit('general/hideTheSpinner');
      }).catch((error) => {
        setEditingTemplateFromCampaign(null);
        // todo: decide what action to take
      });
    };

    const showSendTestModal = ref(false);
    const menuTemplateActions = ref();
    const itemsTemplateActions = ref([
      {
        label: t('automatedScenarios.actions.stepper.changeModel'),
        icon: 'fa fa-backward',
        command: () => {
          changeTemplate(activePreviewTemplate.value.id_template, activePreviewTemplate.value.type);
        },
      },
      {
        label: t('automatedScenarios.actions.stepper.editModel'),
        icon: 'fa fa-edit',
        command: () => {
          editTemplate(activePreviewTemplate.value.id_template, activePreviewTemplate.value.type, activePreviewTemplate.value.label);
        },
      },
      {
        label: t('campaigns.common.operatorActions.sendTestMessage'),
        icon: 'far fa-fw fa-paper-plane',
        command: async () => {
          const meta = getComponentMetadata(props.selectedComponent);
          if (meta && meta.Validate) {
            try {
              const validation = await meta.Validate(data);
              if (validation.success) {
                showSendTestModal.value = true;
                store.commit('general/showSendTestModal');
              } else {
                formValidationErrors.value = validation.validate;
                componentFieldErrorsKey.value += 1;
                showToastError(t('errorMessages.FORM_ERROR'));
              }
            } catch (error) {
              return;
            }
          }
        },
        visible: props.showSendMessage,
      },
    ]);
    const toggleMenuTemplateActions = (event: Event) => {
      if (menuTemplateActions.value) {
        menuTemplateActions.value.toggle(event);
      }
    };

    const selectMedia = (type: string) => {
      activePreviewMedia.value = type;
      tmpImgRefreshKey.value += 1;
    };

    /*
    * Event triggered when changing the active tab in the template preview.
    * it is used to update the id and the type of the current template displayed in the preview in order to use it in editing/changing a template.
    *  */
    const handleActivePreviewTemplate = (event: any) => {
      const index = event.index ?? 0;
      const typeTemplateEvent = configSendingChannelBox.listTemplates.value[index].type ?? 'email';
      activePreviewTemplate.value = {
        id_template: configSendingChannelBox.listTemplates.value[index].id_template,
        type: typeTemplateEvent,
        label: configSendingChannelBox.listTemplates.value[index].label ?? '',
      };

      if (activePreviewMedia.value === 'mobile' && !templateMediaPreview.mobile.includes(typeTemplateEvent)) {
        selectMedia('desktop');
      } else if (activePreviewMedia.value === 'desktop' && !templateMediaPreview.desktop.includes(typeTemplateEvent)) {
        selectMedia('mobile');
      }
    };

    /* Action to perform to select a template when editing a box that has already configured a template or when selecting from the template selector. */
    const updateTemplateConfigurationsInCampaign = async (idTemplate: number, media: string) => {
      data.template_display_selector_id = idTemplate;
      templateSelectorIsOpen.value = false;
      const gettingConfigSendingChannelBox = await getConfigSendingChannelBox(idTemplate, media);

      configSendingChannelBox.templatesContainsVoucher.value = gettingConfigSendingChannelBox.templatesContainsVoucher ?? false;
      configSendingChannelBox.parentsOperatorAlreadyConfiguredVoucher.value = gettingConfigSendingChannelBox.parentsOperatorAlreadyConfiguredVoucher ?? false;
      configSendingChannelBox.parentsFilterHaveExpiredVoucherSegment.value = gettingConfigSendingChannelBox.parentsFilterHaveExpiredVoucherSegment ?? false;
      configSendingChannelBox.listTemplates.value = gettingConfigSendingChannelBox.listTemplate ?? [];
      // get the primary template
      const primaryTemplate = configSendingChannelBox.listTemplates.value[0];

      activePreviewTemplate.value = {
        id_template: primaryTemplate.id_template,
        type: primaryTemplate.type ?? typeTemplateSelector.value,
        label: primaryTemplate.label ?? '',
      };

      const verifyReusePreviousCouponOption = reusePreviousCouponOptions.value.findIndex((object) => object.value === data.reuse_a_previous_coupon);
      /* If the reusePreviousCoupon option is no longer available in the current state of the scenario => we initialize data.reuse_a_previous_coupon */
      if (verifyReusePreviousCouponOption === -1) {
        data.reuse_a_previous_coupon = reusePreviousCouponValues.CONFIGURE_NEW_VOUCHER;
      }

      if (primaryTemplate) {
        const primaryTemplateConfig = JSON.parse(primaryTemplate.template_config ?? '{}');
        const primaryTemplateHasVoucher = primaryTemplateConfig?.hasVoucher ?? false;
        /**
         * If the primary template contains a voucher, we cannot choose the automatic method in the case of a bulk
         */
        if (primaryTemplateHasVoucher && AutomatedScenarioState.scenario.type === TypeCampaignEnum.BULK) {
          if (data.template_display_reduction_method === voucherMethodsValues.AUTOMATIC) {
            data.template_display_reduction_method = voucherMethodsValues.MANUAL;
          }
          allowAutomaticMethodOption.value = false;
        } else {
          allowAutomaticMethodOption.value = true;
        }
      }

      if (templateMediaPreview.desktop.includes(activePreviewTemplate.value.type)) {
        selectMedia('desktop');
      } else {
        selectMedia('mobile');
      }
      panelsKeys.value += 1;
    };

    const selectTemplate = async (idTemplate: number, label: string) => {
      const editingTemplateFromCampaign = getEditingTemplateFromCampaign();
      if (editingTemplateFromCampaign === null && data.template_display_selector_id === null) {
        await updateTemplateConfigurationsInCampaign(idTemplate, typeTemplateSelector.value);
        await editTemplate(idTemplate, typeTemplateSelector.value, '');
        store.commit('general/hideTheSpinner');
      } else if (editingTemplateFromCampaign !== null
          && editingTemplateFromCampaign.status === EditingTemplateStatusEnum.IN_TEMPLATE_CHOOSER
          && editingTemplateFromCampaign.type === EditingTemplateTypeEnum.CHANGE) {
        await activateTemplate(idTemplate, '', typeTemplateSelector.value).then(() => {
          editingTemplateFromCampaign.status = EditingTemplateStatusEnum.IN_TEMPLATE_EDITOR;
          editingTemplateFromCampaign.idTemplate = idTemplate;
          setEditingTemplateFromCampaign(editingTemplateFromCampaign);
          store.commit('general/hideTheSpinner');
        }).catch((error) => {
          store.commit('general/hideTheSpinner');
          // todo: decide what action to take
        });
      }
    };

    const goToDomainPage = () => {
      const routePath = router.resolve({ path: '/shop/send-domains' }).href;
      window.open(routePath, '_blank');
    };

    const myDataAutoCompletion: Ref<any> = ref({
      senderNames: [],
      senderAddresses: [],
      domainsOfShop: [],
    });

    onMounted(async () => {
      myDataAutoCompletion.value.senderNames = await loadSenderNames(props.media);

      if (props.media === 'email') {
        myDataAutoCompletion.value.senderAddresses = await loadSenderAddresses();
        myDataAutoCompletion.value.domainsOfShop = await getShopsDomains(0, [{
          field: 'domain_verified',
          operator: OperatorType.Equals,
          value: true,
        }]);
      }

      if (data.template_display_selector_id) {
        templateSelectorIsOpen.value = false;
        await updateTemplateConfigurationsInCampaign(asInt(data.template_display_selector_id), props.media);
      } else {
        templateSelectorIsOpen.value = true;
      }

      if (Number(data.template_display_reduction_method) === 1) {
        data.template_display_reduction_method = 3;
      }
    });

    /* Update data */
    watch(data, () => {
      context.emit('update:modelValue', data);
    });

    watch(() => AutomatedScenarioState.selectedOperator.editingTemplate, async () => {
      const editingTemplateFromCampaign = getEditingTemplateFromCampaign();

      // reset images templates preview
      imagesLoaded.value = {};
      imageLoadTime.value = {};
      tmpImgRefreshKey.value += 1;
      if (editingTemplateFromCampaign !== null) {
        if ([EditingTemplateStatusEnum.SAVE_QUIT_TEMPLATE_EDITOR, EditingTemplateStatusEnum.SAVE_STAY_TEMPLATE_EDITOR].includes(editingTemplateFromCampaign.status)) {
          if (editingTemplateFromCampaign.type === EditingTemplateTypeEnum.EDIT) {
            /* Action to perform after editing a template. */
            await updateTemplateConfigurationsInCampaign(asInt(data.template_display_selector_id), props.media);
          } else if (editingTemplateFromCampaign.type === EditingTemplateTypeEnum.CHANGE) {
            /* Action to perform after changing a template. */
            if (editingTemplateFromCampaign.isRedirect) {
              await updateTemplateConfigurationsInCampaign(asInt(data.template_display_selector_id), props.media);
              if (editingTemplateFromCampaign.parentsRedirect && editingTemplateFromCampaign.oldIdTemplate && editingTemplateFromCampaign.idTemplate) {
                await replaceRedirectionTemplate(idShop.value, editingTemplateFromCampaign.parentsRedirect,
                  editingTemplateFromCampaign.oldIdTemplate, editingTemplateFromCampaign.idTemplate);
              }
            } else if (!editingTemplateFromCampaign.isRedirect && typeof editingTemplateFromCampaign.idTemplate === 'number') {
              await updateTemplateConfigurationsInCampaign(asInt(editingTemplateFromCampaign.idTemplate), props.media);
            }
          }

          if (editingTemplateFromCampaign.status === EditingTemplateStatusEnum.SAVE_QUIT_TEMPLATE_EDITOR) {
            setEditingTemplateFromCampaign(null);
          } else if (editingTemplateFromCampaign.status === EditingTemplateStatusEnum.SAVE_STAY_TEMPLATE_EDITOR) {
            editingTemplateFromCampaign.status = EditingTemplateStatusEnum.IN_TEMPLATE_EDITOR;
            setEditingTemplateFromCampaign(editingTemplateFromCampaign);
          }
        } else if (editingTemplateFromCampaign.status === EditingTemplateStatusEnum.QUIT_TEMPLATE_EDITOR) {
          setEditingTemplateFromCampaign(null);
        }
      }
    }, { deep: true });

    // Watch data from template builder in AutomatedScenarioState to change form data
    watch(() => AutomatedScenarioState.dataFromTemplateBuilder, (newValue) => {
      if (Object.keys(newValue).length > 0) {
        if (Object.prototype.hasOwnProperty.call(newValue, 'template_message_subject') && Object.keys(newValue.template_message_subject).length > 0) {
          data.template_message_subject = { ...newValue.template_message_subject };
        }

        if (Object.prototype.hasOwnProperty.call(newValue, 'template_message_expmail') && Object.keys(newValue.template_message_expmail).length > 0) {
          data.template_message_expmail = { ...newValue.template_message_expmail };
          data.template_message_replytomail = { ...newValue.template_message_replytomail };
        }

        if (Object.prototype.hasOwnProperty.call(newValue, 'template_message_expname') && Object.keys(newValue.template_message_expname).length > 0) {
          data.template_message_expname = { ...newValue.template_message_expname };
        }

        resetDataFromTemplateBuilder();
      }
    }, { deep: true });

    const sendTestTemplate = async (params: Suggestion[]) => {
      if (activePreviewTemplate.value.id_template && activePreviewTemplate.value.type) {
        store.commit('general/showTheSpinner');

        // Send query to NestJS
        const getData: Record<string, any> = {
          type: activePreviewTemplate.value.type,
          params,
        };

        if (activePreviewTemplate.value.type === TemplateTypeEnum.EMAIL || activePreviewTemplate.value.type === TemplateTypeEnum.SMS) {
          getData.informations = {
            fromName: data.template_message_expname,
          };

          if (activePreviewTemplate.value.type === TemplateTypeEnum.EMAIL) {
            getData.informations.fromEmail = data.template_message_expmail;
            getData.informations.subject = data.template_message_subject;
          }
        }

        const dataQuery = btoa(encodeURIComponent(JSON.stringify(getData)));
        const result = await nestGet('v4', `/template/test/${activePreviewTemplate.value.id_template}/${dataQuery}`, {}, '');

        if (result && result.success) {
          if (activePreviewTemplate.value.type === TemplateTypeEnum.EMAIL) {
            await showToastSuccess('emailSent');
          } else if (activePreviewTemplate.value.type === TemplateTypeEnum.SMS) {
            await showToastSuccess('smsSent');
          }
        } else {
          await showToastError(t('errorMessages.GENERIC_ERROR'));
        }

        store.commit('general/hideTheSpinner');
        showSendTestModal.value = false;
      }
    };

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      panels,
      data,
      selectTemplate,
      closeTemplateSelector,
      templateSelectorIsOpen,
      typeTemplateSelector,
      handleActivePreviewTemplate,
      activePreviewTemplate,
      baseImagePreviewUrl,
      selectMedia,
      activePreviewMedia,
      configSendingChannelBox,
      reusePreviousCouponField,
      reusePreviousCouponOptions,
      voucherMethodsValues,
      voucherTypeValues,
      voucherMethodOptions,
      voucherTypeOptions,
      periodOfValidityOptions,
      variables,
      templateMediaPreview,
      menuTemplateActions,
      itemsTemplateActions,
      toggleMenuTemplateActions,
      searchValuesForAutocompletion,
      imageLoadTime,
      imageLoaded,
      imageFailed,
      tmpImgRefreshKey,
      myDataAutoCompletion,
      goToDomainPage,
      panelsKeys,
      sendTestTemplate,
      showSendTestModal,
    };
  },
});
</script>

<style scoped lang="scss">
.template_preview_container {
  //width: 100%;
  background-size: 100% auto;
  //height: 450px;
  text-align: center;
  padding: 2em;
  -webkit-flex: 0 0 16%;
  -ms-flex: 0 0 16%;
  flex: 0 0 16%;
  background-position: center top;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  &:hover {
    webkit-animation: UPANDDOWN 10s infinite; /* Safari 4+ */
    -moz-animation: UPANDDOWN 10s infinite; /* Fx 5+ */
    -o-animation: UPANDDOWN 10s infinite; /* Opera 12+ */
    animation: UPANDDOWN 10s infinite; /* IE 10+, Fx 29+ */
  }
}

.pbutton {
  background-color: #edf0f2;
  border-color: #edf0f2;
  color: #3b3d3e;
  height:1.9rem;
}

.center{
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

p {
  text-align: center;
  margin: 10px auto 10px auto;
  padding: 10px 0 10px 0;
}

.tsr_actions {
  height: 3rem;
  .tsr-button-item {
    background-color: white;
    color: $tuna;
    border: 1px solid $heather;
    box-shadow: none !important;

    &:hover {
      color: #94c840;
      border: 1px solid $heather;
    }

    &:active:not(.disabled) {
      border-color: $heather;
      background-color: #94c840;
      color: white;
    }

    &:focus {
      border-color: $heather;
    }

    &.active {
      border-color: $heather;
      background-color: #94c840;
      color: white;
    }

    &.disabled {
      border-color: $heather;
      color: $heather;
    }
  }

  .p-buttonset {
    .tsr-button-item {
      border-left: none;
      border-right: none;

      &:first-child {
        border-left: 1px solid $heather;
      }

      &:last-child {
        border-right: 1px solid $heather;
      }
    }
  }
}
</style>
