
import { defineComponent, PropType, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ShopsManufacturers } from '@/types/generated-types/graphql';
import Listbox from 'primevue/listbox';
import InputText from 'primevue/inputtext';
import ShopsManufacturersList from '@/composables/shops-manufacturers/ShopsManufacturers';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'SupplierSelection',

  components: {
    Dialog,
    Button,
    Listbox,
    InputText,
    Skeleton,
  },

  props: {
    translation: {
      type: Function,
      require: true,
      default: () => ({}),
    },

    availableManufacturersArr: {
      type: Array as PropType<ShopsManufacturers[]>,
      required: true,
    },

    chosenManufacturersArr: {
      type: Array as PropType<ShopsManufacturers[]>,
      required: true,
    },

    manufacturerSearchStr: {
      type: String,
      required: true,
    },
  },

  emits: ['close-action-modal'],

  setup(props, context) {
    const dialogVisible = ref(true);
    const suppliers = ref(props.manufacturerSearchStr);
    const suppliersAvailableList = ref<ShopsManufacturers[]>(props.availableManufacturersArr);
    const suppliersChosenList = ref<ShopsManufacturers[]>(props.chosenManufacturersArr);
    const chosenSuppliers = ref<ShopsManufacturers[]>([]);
    const availableSuppliers = ref<ShopsManufacturers[]>([]);
    let timer: any = null;

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', suppliersChosenList.value, suppliersAvailableList.value, suppliers);
    };

    const handleSearch = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await ShopsManufacturersList(suppliers.value, 10000);
        if (result !== null) {
          availableSuppliers.value = [];
          suppliersAvailableList.value = result;
        }
      }, 1000);
    };

    const moveItemsToChosen = () => {
      suppliersChosenList.value = chosenSuppliers.value.concat(suppliersChosenList.value);

      suppliersChosenList.value.forEach((record: ShopsManufacturers) => {
        availableSuppliers.value = availableSuppliers.value.filter((chosenRecord: ShopsManufacturers) => chosenRecord.id_shop_manufacturer !== record.id_shop_manufacturer);
      });
      suppliersChosenList.value = suppliersChosenList.value.concat(availableSuppliers.value);

      suppliersChosenList.value.forEach((chosenSupplier: ShopsManufacturers) => {
        suppliersAvailableList.value = suppliersAvailableList.value.filter(
          (supplierAvailable: ShopsManufacturers) => supplierAvailable.id_shop_manufacturer !== chosenSupplier.id_shop_manufacturer,
        );
      });
      availableSuppliers.value = [];
      chosenSuppliers.value = [];
    };

    const moveItemsToAvailable = () => {
      suppliersAvailableList.value = suppliersAvailableList.value.concat(chosenSuppliers.value);

      chosenSuppliers.value.forEach((chosenSupplier: ShopsManufacturers) => {
        suppliersChosenList.value = suppliersChosenList.value.filter(
          (listSupplier: ShopsManufacturers) => listSupplier.id_shop_manufacturer !== chosenSupplier.id_shop_manufacturer,
        );
      });
      chosenSuppliers.value = [];
    };

    return {
      dialogVisible,
      updateDialogVisibility,
      suppliers,
      handleSearch,
      suppliersAvailableList,
      suppliersChosenList,
      chosenSuppliers,
      availableSuppliers,
      moveItemsToChosen,
      moveItemsToAvailable,
    };
  },
});
