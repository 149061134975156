
import { useI18n } from 'vue-i18n';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Message from 'primevue/message';
import {
  defineComponent, ref, onMounted,
} from 'vue';
import {
  fetchNotifications, markNotificationsAsRead,
} from '@/composables/shop/Notifications';
import { UserState } from '@/composables/User';
import {
  ShopsNotifications,
  ShopsNotificationsReadInputItem,
} from '@/types/generated-types/graphql';

export default defineComponent({
  name: 'MyNotifications',
  components: {
    Card,
    Button,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const notifications: any = ref<ShopsNotifications[]>([]);
    const shopId = UserState?.activeShop?.id ?? 0;
    const paging = ref<number>(0);
    const hasMore = ref(true);
    const loadingHasMore = ref(false);

    const getNotifications = async () => {
      loadingHasMore.value = true;
      const getNotificationsItems = await fetchNotifications({ shopId, paging: paging.value });
      loadingHasMore.value = false;

      if (getNotificationsItems && getNotificationsItems.length === 0) {
        hasMore.value = false;
        return;
      }

      notifications.value = notifications.value.concat(getNotificationsItems);
      //console.log(notifications.value);
    };

    const fetchMore = async () => {
      paging.value += 1;
      await getNotifications();
    };

    const markAllAsRead = async () => {
      const notificationsReadRecords: ShopsNotificationsReadInputItem[] = [];
      notifications.value.forEach((item: ShopsNotifications) => {
        if (item.shopsNotificationsRead?.length === 0) {
          notificationsReadRecords.push({
            id_shop_notification: item.id_shop_notification,
            id_shop: shopId,
            date: new Date(),
          });
        }
      });

      if (notificationsReadRecords.length > 0) {
        await markNotificationsAsRead(notificationsReadRecords);
      }
    };

    onMounted(async () => {
      await getNotifications();
      await markAllAsRead();
    });

    return {
      t,
      notifications,
      fetchMore,
      hasMore,
      loadingHasMore,
    };
  },
});
