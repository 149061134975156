import { InputGroupField } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

export const getFields = (selector: string): string[] => {
  const template = getTemplateIframeDocument();
  const fields: HTMLElement[] = Array.from(template?.querySelectorAll(selector));

  return fields
    .filter((field: HTMLElement) => !field.classList.contains('spm_hidden')
      || field.querySelector('.spm_form_input')?.getAttribute('id') === 'spm_form_registration_password') // keep not hidden fields and password
    .map((field: HTMLElement) => field.querySelector('.spm_form_input')?.getAttribute('id') as string); // return ids
};

export const getFieldLabels = (selector: string): InputGroupField[] => {
  const template = getTemplateIframeDocument();
  const fields: HTMLElement[] = Array.from(template?.querySelectorAll(selector));

  return fields
    .filter((field: HTMLElement) => !field.classList.contains('spm_hidden')
      || field.querySelector('.spm_form_input')?.getAttribute('id') === 'spm_form_registration_password') // keep not hidden fields and password
    .map((field: HTMLElement) => {
      const placeholder: string = field.querySelector('.spm_form_input')?.getAttribute('placeholder')?.replace('*', '') as string;
      const label = `templateBuilder.fields.${field.querySelector('.spm_form_input')?.getAttribute('id')?.replace('spm_form_registration_', '').replace('_', '')}`;
      const result: InputGroupField = {
        key: field.querySelector('.spm_form_input')?.getAttribute('id') ?? '',
        label,
        value: placeholder,
      };
      if (!field.classList.contains('spm_form_field_base')
        && field.querySelector('.spm_form_input')?.getAttribute('id') !== 'spm_form_registration_password') { // Display required checkbox only on not mandatory fields
        result.required = field.querySelector('.spm_form_input')?.classList.contains('spm_form_required');
      }
      return result;
    });
};

export const getMessage = (selector: string): string => {
  const template = getTemplateIframeDocument();
  const container: HTMLElement = template?.querySelector(selector) as HTMLElement;
  return container.querySelector('.spm_step2')?.innerHTML ?? '';
};
