import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { TypeCampaignEnum } from '@/types';

export interface DeclencheurSubscribeToNewsletterData {
  nothing: string;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurSubscribeToNewsletterMetadata: ComponentMetadata<DeclencheurSubscribeToNewsletterData> = {
  Meta: {
    kind: 'declencheur',
    id: 'subscribetonewsletter',
    label: 'automatedScenarios.triggers.subscribeToNewsletter.label',
    icon: 'fa-envelope-open',
    hasConfiguration: false,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxperiodiclaunching', 'boxscrollpage',
          'boxstatuscommand', 'registeronsite', 'subscribetonewsletter',
          'boxtag', 'boxlist', 'openemail',
          'clicklink',
        ],
      },
    },
    component: 'DeclencheurSubscribeToNewsletter',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({ nothing }: DeclencheurSubscribeToNewsletterData = {
    nothing: '',
  }): ComponentData<DeclencheurSubscribeToNewsletterData> {
    return {
      id: 'subscribetonewsletter',
      form_name: 'subscribetonewsletter',
      logo_box: 'fa-envelope-open',
      nothing,
    };
  },
  async Validate(data: DeclencheurSubscribeToNewsletterData): Promise<ErrorConfigForm> {
    return {
      success: true,
    };
  },
  Outputs(data: DeclencheurSubscribeToNewsletterData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurSubscribeToNewsletterData): string {
    return translation.global.t('automatedScenarios.triggers.subscribeToNewsletter.label');
  },
};

export default DeclencheurSubscribeToNewsletterMetadata;
