// eslint-disable-next-line import/no-cycle
import {
  ShopsPlans,
  ShopsPlansInputItem,
  ShopsPlansUpdateInputItem,
} from '@/types/generated-types/graphql';
// eslint-disable-next-line import/no-cycle
import {
  Get,
  Insert,
  Update,
} from '@/composables/GraphQL';
// eslint-disable-next-line import/no-cycle
import { DropdownOption } from '@/types';
// eslint-disable-next-line import/no-cycle
import { UserState } from '@/composables/User';
import { ref } from 'vue';
// eslint-disable-next-line import/no-cycle
import { DEFAULT_CURRENCY } from '@/components/template-builder/utils/constants';
import { formatNumberToCurrency } from '@/helpers/Number';
import { BuyableElementType } from '@/types/enums';
import { SmsPricing } from '@/types/offer-types';

export interface BuyableElement {
  type: BuyableElementType;
  name: string;
  header: string;
  legend: string;
  productId: number;
  productTitle: string;
  displayCountries?: boolean;
  countryDropdownConfiguration?: Record<string, any>;
  countryCallback?: Function;
  optionsPlaceholder?: string;
  options?: DropdownOption[];
  displayAutoRenew?: boolean;
  callback: Function;
  pendingDescription?: string;
  availableForOffers: string[];
}

/**
 * Get SMS unit price for specific country
 * @param selectedCountry
 * @param pricing
 */
const getSmsUnitPriceByCountry = (selectedCountry: Record<string, any>, pricing: Record<string, SmsPricing[]>) => {
  let returnValue = 0;
  let unitPrice: any = [];
  let defaultPrice: any = [];

  if (UserState.hasOfferV3) {
    // Calculate unit price and default price for old offers
    unitPrice = pricing.v3.filter((pricingCountry: SmsPricing) => pricingCountry.value === selectedCountry.country);

    if (unitPrice.length === 0) {
      defaultPrice = pricing.v3.filter((pricingCountry: SmsPricing) => pricingCountry.value === 'DEF');
    }
  } else {
    // Calculate unit price and default price for offers V14
    const currentPlanConfiguration = UserState.activeOffer;

    if (currentPlanConfiguration) {
      let activePlan: string | null = null;

      if ('trialPlan' in currentPlanConfiguration && currentPlanConfiguration.trialPlan) {
        activePlan = currentPlanConfiguration.trialPlan;
      } else if ('forcedPlan' in currentPlanConfiguration && currentPlanConfiguration.forcedPlan) {
        activePlan = currentPlanConfiguration.forcedPlan;
      } else if ('currentPlan' in currentPlanConfiguration && currentPlanConfiguration.currentPlan) {
        activePlan = currentPlanConfiguration.currentPlan;
      }

      if (activePlan) {
        unitPrice = pricing[activePlan].filter((pricingCountry: SmsPricing) => pricingCountry.value === selectedCountry.country);

        if (unitPrice.length === 0) {
          defaultPrice = pricing[activePlan].filter((pricingCountry: SmsPricing) => pricingCountry.value === 'DEF');
        }
      }
    }
  }

  if (unitPrice.length > 0) {
    returnValue = unitPrice[0].smsPrice;
  } else if (defaultPrice.length > 0) {
    returnValue = defaultPrice[0].smsPrice;
  } else {
    returnValue = 0;
  }

  return returnValue;
};

/**
 * Calculate total price for SMS credits according to number of messages and country
 * @param selectedValue
 * @param selectedCountry
 * @param pricing
 */
const calculateSmsPrice = (selectedValue: any, selectedCountry: Record<string, any>, pricing: Record<string, SmsPricing[]>) => {
  const unitPriceForCountry = getSmsUnitPriceByCountry(selectedCountry, pricing);
  return unitPriceForCountry > 0 ? Math.round(selectedValue * unitPriceForCountry * 100) / 100 : 0;
};

/**
 * Calculate SMS price for specific country
 * @param buyableElement
 * @param selectedCountry
 * @param selectedValue
 * @param pricing
 */
const calculateSmsPriceForCountry = (buyableElement: BuyableElement, selectedCountry: Record<string, any>, selectedValue: any, pricing: Record<string, SmsPricing[]>) => {
  if (
    buyableElement
    && 'options' in buyableElement
    && buyableElement.options
  ) {
    const currency = ref(UserState.activeShop?.currency ?? DEFAULT_CURRENCY);
    currency.value = UserState.activeShop?.solutionEcommerce === 'shopify' ? 'USD' : currency.value;

    // Update pricing in dropdown legend
    (buyableElement.options || []).forEach((option) => {
      const unitPriceForCountry = getSmsUnitPriceByCountry(selectedCountry, pricing);
      const optionValue = parseFloat(option.value.toString());
      // eslint-disable-next-line no-param-reassign
      option.legend = unitPriceForCountry > 0 && optionValue > 0 ? formatNumberToCurrency(Math.round(optionValue * unitPriceForCountry * 100) / 100, currency.value) : null;
    });
  }

  return calculateSmsPrice(selectedValue, selectedCountry, pricing);
};

/**
 * List of buyable elements
 */
export const buyableElements: BuyableElement[] = [
  {
    type: BuyableElementType.CREDITS,
    name: 'smsV4',
    availableForOffers: ['v4'],
    header: 'offers.buySmsCredits.header',
    legend: 'offers.buySmsCredits.legend',
    productId: process.env.VUE_APP_SMS_CREDITS_PRODUCT_ID ? +process.env.VUE_APP_SMS_CREDITS_PRODUCT_ID : 20,
    productTitle: 'offers.buySmsCredits.buyableElementTitle',
    displayCountries: true,
    pendingDescription: 'offers.buySmsCredits.pendingDescription',
    countryDropdownConfiguration: {
      showStates: false,
      showLabels: false,
      showFlags: true,
      showPhonesCodes: true,
      showOnlyPhoneCodeForSelection: false,
    },
    countryCallback: (
      buyableElement: BuyableElement,
      selectedCountry: Record<string, any>,
      selectedValue: any,
      pricing: Record<string, SmsPricing[]>,
    ) => calculateSmsPriceForCountry(buyableElement, selectedCountry, selectedValue, pricing),
    options: [
      {
        label: '0 SMS',
        value: 0,
      },
      {
        label: '100 SMS',
        value: 100,
      },
      {
        label: '200 SMS',
        value: 200,
      },
      {
        label: '500 SMS',
        value: 500,
      },
      {
        label: '1 000 SMS',
        value: 1000,
      },
      {
        label: '1 500 SMS',
        value: 1500,
      },
      {
        label: '2 000 SMS',
        value: 2000,
      },
      {
        label: '2 500 SMS',
        value: 2500,
      },
      {
        label: '3 000 SMS',
        value: 3000,
      },
      {
        label: '3 500 SMS',
        value: 3500,
      },
      {
        label: '4 000 SMS',
        value: 4000,
      },
      {
        label: '4 500 SMS',
        value: 4500,
      },
      {
        label: '5 000 SMS',
        value: 5000,
      },
      {
        label: '10 000 SMS',
        value: 10000,
      },
      {
        label: '20 000 SMS',
        value: 20000,
      },
      {
        label: '30 000 SMS',
        value: 30000,
      },
      {
        label: '40 000 SMS',
        value: 40000,
      },
      {
        label: '50 000 SMS',
        value: 50000,
      },
      {
        label: '60 000 SMS',
        value: 60000,
      },
      {
        label: '70 000 SMS',
        value: 70000,
      },
      {
        label: '80 000 SMS',
        value: 80000,
      },
      {
        label: '90 000 SMS',
        value: 90000,
      },
      {
        label: '100 000 SMS',
        value: 100000,
      },
      {
        label: '110 000 SMS',
        value: 110000,
      },
      {
        label: '120 000 SMS',
        value: 120000,
      },
      {
        label: '130 000 SMS',
        value: 130000,
      },
      {
        label: '140 000 SMS',
        value: 140000,
      },
      {
        label: '150 000 SMS',
        value: 150000,
      },
      {
        label: '160 000 SMS',
        value: 160000,
      },
      {
        label: '170 000 SMS',
        value: 170000,
      },
    ],
    displayAutoRenew: true,
    callback: (
      selectedValue: any,
      selectedCountry: Record<string, any>,
      pricing: Record<string, SmsPricing[]>,
    ) => calculateSmsPrice(selectedValue, selectedCountry, pricing),
  },
  /* {
    type: BuyableElementType.CREDITS,
    name: 'sms',
    availableForOffers: ['v3'],
    header: 'offers.buySmsCredits.header',
    legend: 'offers.buySmsCredits.legend',
    productId: process.env.VUE_APP_SMS_CREDITS_PRODUCT_ID ? +process.env.VUE_APP_SMS_CREDITS_PRODUCT_ID : 20,
    productTitle: 'offers.buySmsCredits.buyableElementTitle',
    displayCountries: true,
    pendingDescription: 'offers.buySmsCredits.pendingDescription',
    countryDropdownConfiguration: {
      showStates: false,
      showLabels: false,
      showFlags: true,
      showPhonesCodes: true,
      showOnlyPhoneCodeForSelection: false,
    },
    countryCallback: (
      buyableElement: BuyableElement,
      selectedCountry: Record<string, any>,
      selectedValue: any,
      pricing: Record<string, SmsPricing[]>,
    ) => calculateSmsPriceForCountry(buyableElement, selectedCountry, selectedValue, pricing),
    options: [
      {
        label: '0 SMS',
        value: 0,
      },
      {
        label: '100 SMS',
        value: 100,
      },
      {
        label: '200 SMS',
        value: 200,
      },
      {
        label: '500 SMS',
        value: 500,
      },
      {
        label: '1 000 SMS',
        value: 1000,
      },
      {
        label: '1 500 SMS',
        value: 1500,
      },
      {
        label: '2 000 SMS',
        value: 2000,
      },
      {
        label: '2 500 SMS',
        value: 2500,
      },
      {
        label: '3 000 SMS',
        value: 3000,
      },
      {
        label: '3 500 SMS',
        value: 3500,
      },
      {
        label: '4 000 SMS',
        value: 4000,
      },
      {
        label: '4 500 SMS',
        value: 4500,
      },
      {
        label: '5 000 SMS',
        value: 5000,
      },
      {
        label: '10 000 SMS',
        value: 10000,
      },
      {
        label: '20 000 SMS',
        value: 20000,
      },
      {
        label: '30 000 SMS',
        value: 30000,
      },
      {
        label: '40 000 SMS',
        value: 40000,
      },
      {
        label: '50 000 SMS',
        value: 50000,
      },
      {
        label: '60 000 SMS',
        value: 60000,
      },
      {
        label: '70 000 SMS',
        value: 70000,
      },
      {
        label: '80 000 SMS',
        value: 80000,
      },
      {
        label: '90 000 SMS',
        value: 90000,
      },
      {
        label: '100 000 SMS',
        value: 100000,
      },
      {
        label: '110 000 SMS',
        value: 110000,
      },
      {
        label: '120 000 SMS',
        value: 120000,
      },
      {
        label: '130 000 SMS',
        value: 130000,
      },
      {
        label: '140 000 SMS',
        value: 140000,
      },
      {
        label: '150 000 SMS',
        value: 150000,
      },
      {
        label: '160 000 SMS',
        value: 160000,
      },
      {
        label: '170 000 SMS',
        value: 170000,
      },
    ],
    displayAutoRenew: true,
    callback: (
      selectedValue: any,
      selectedCountry: Record<string, any>,
      pricing: Record<string, SmsPricing[]>,
    ) => calculateSmsPrice(selectedValue, selectedCountry, pricing),
  }, */
];

export const InsertShopsPlans = async (shopsPlansInput: ShopsPlansInputItem[]) => Insert<ShopsPlansInputItem>({
  name: 'ShopsPlans',
  input: shopsPlansInput,
  type: 'ShopsPlansInput',
});

export const UpdateShopsPlans = async (ShopsPlansUpdateInput: ShopsPlansUpdateInputItem[]) => Update<ShopsPlansUpdateInputItem>({
  name: 'ShopsPlans',
  input: ShopsPlansUpdateInput,
  type: 'ShopsPlansUpdateInput',
});

export const getShopPlan = async (idShop: number) => Get<ShopsPlans>({
  name: 'ShopsPlans',
  id: idShop,
  keyName: 'id_shop',
  fields: ['plan', 'is_active'],
});
