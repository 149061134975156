<template>
  <SpmOverlayPanel
    class-trigger="flex align-items-center justify-content-center icon-action-app-header"
    class-header="flex align-items-center store-info justify-content-left"
    class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-content"
  >
    <template #trigger>
      <i class="fa-regular fa-store" />
    </template>
    <template #header>
      <img
        class="avatar"
        data-test-id="profile-btn"
        :alt="UserState?.activeShop?.name"
        :src="`/images/ecommerce-solutions/${(UserState?.activeShop?.solutionEcommerce) ?? 'undefined'}.svg`"
      >
      <div class="text ml-1">
        <div
          class="font-bold text-left"
        >
          {{ UserState?.activeShop?.name }}
        </div>
        <div
          class="text-xs text-left  "
        >
          {{ UserState?.activeShop?.solutionEcommerce }}
        </div>
      </div>
    </template>
    <SpmPanelMenu
      :items="profileMenuItems"
    />
    <template #footer>
      <SpmPanelMenu
        :items="logoutItem"
      />
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { PrimvueMenuModel } from '@/types';
import { findPermission, UserState } from '@/composables/User';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import { HasTerminatedOldNewsletters } from '@/composables/shop/Shops';

export default defineComponent({
  name: 'MyStoreMenu',
  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  setup() {
    const { t } = useI18n();
    const hasOldNewsletterStatistics = ref(false);

    const profileMenuItems: Ref<PrimvueMenuModel[]> = ref([
      {
        key: '0',
        label: t('myStoreMenu.storeParams'),
        to: { name: 'shop.ShopEdit' },
        visible: () => findPermission('store_params.edit'),
      },
      {
        key: '1',
        label: t('myStoreMenu.myOffer'),
        to: { name: 'user.profile', params: { tab: 'offer' } },
        visible: () => findPermission('my_offer.view'),
      },
      {
        key: '2',
        label: t('myStoreMenu.myNotifications'),
        to: { name: 'shop.MyNotifications' },
        visible: () => findPermission('notifications.view'),
      },
      {
        key: '3',
        label: t('myStoreMenu.myDomains'),
        to: { name: 'shop.MyDomains' },
        visible: () => findPermission('my_domains.view'),
      },
      {
        key: '4',
        label: t('profileMenu.exports'),
        to: { name: 'users.exports' },
      },
    ]);

    const logoutItem: Ref<PrimvueMenuModel[]> = ref([
      {
        label: t('myStoreMenu.gdpr'),
        to: { name: 'shop.gdpr-logs' },
        visible: () => findPermission('my_bills.gdpr.view'),
      },
      {
        label: t('myStoreMenu.oldNewsletterStatistics'),
        to: { name: 'shop.MyOldNewslettersStatistics' },
        visible: () => hasOldNewsletterStatistics.value,
      },
    ]);

    onMounted(async () => {
      try {
        const oldNewsletterStatisticsData = await HasTerminatedOldNewsletters();
        if (oldNewsletterStatisticsData && oldNewsletterStatisticsData.data) {
          hasOldNewsletterStatistics.value = true;
        }
      } catch (e) {
        console.error(e);
      }
    });

    return {
      UserState,
      profileMenuItems,
      logoutItem,
    };
  },
});
</script>

<style lang="scss" scoped>
.my-store-logo {
  width: 100%;
}

.profile-btn {
  cursor: pointer;

  .avatar {
    padding: 2px;
    border-radius: 50%;
    width: 2.3rem;
    height: 2.3rem;
    background-color: $white;
  }

  .text {
    color: #6b7280;
  }
}
</style>

<style lang="scss">
.store-info {
  padding: 1rem 0.5rem;
  .avatar {
    padding: 2px;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    background-color: $white;
  }

  .text {
    :first-child {
      color: #1f2937;
    }
    color: #6b7280;
  }
}
</style>
