<template>
  <div class="field-wrapper">
    <div
      class="mb-2"
      style="display: flex; justify-content: space-between;"
    >
      <label
        class="block"
      >
        {{ t('automatedScenarios.declencheur.choose_bulk_list.label_lists') }}
      </label>
      <!--      <Button-->
      <!--        class="p-button-sm p-button-secondary"-->
      <!--        :label="t('automatedScenarios.declencheur.choose_bulk_list.create_list')"-->
      <!--        icon="far fa-plus"-->
      <!--      />-->
      <ListCreatePanel />
    </div>
    <Listbox
      v-model="lists"
      :options="listsOptions"
      option-label="name"
      :filter-placeholder="t('automatedScenarios.declencheur.choose_bulk_list.search_list')"
      :filter="true"
      :multiple="true"
      list-style="max-height:250px"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      field="lists"
    />
  </div>
  <div
    v-if="lists.length"
    class="field-wrapper"
  >
    <div
      class="mb-2"
      style="display: flex; justify-content: space-between;"
    >
      <label
        class="block"
      >
        Listes selectionnées :
      </label>
    </div>
    <div class="flex flex-wrap">
      <div
        v-for="(list, index) in lists"
        :key="index"
      >
        <Chip
          :label="list.name"
          class="mr-2 mb-2"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, Ref, ref, SetupContext, watch, } from 'vue';
import { useI18n } from 'vue-i18n';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import DeclencheurChooseBulkListMetadata, {
  DeclencheurChooseBulkListData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurChooseBulkListMetadata';
import Listbox from 'primevue/listbox';
import { List } from '@/composables/GraphQL';
import { OperatorType, ShopsLists } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import ListCreatePanel from '@/components/mes-lists/ListCreatePanel.vue';
import { myListsState } from '@/composables/mes-listes/ListsManage';
import { asInt } from '@/helpers';
import Chip from 'primevue/chip';

export default defineComponent({
  name: 'DeclencheurChooseBulkList',
  components: {
    Chip,
    FieldErrors,
    ListCreatePanel,
    Listbox,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurChooseBulkListData>,
      required: true,
      default() {
        return DeclencheurChooseBulkListMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurChooseBulkListData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const listsOptions = ref();

    const lists: Ref<any> = ref([]);

    const update = () => {
      context.emit('update:modelValue', DeclencheurChooseBulkListMetadata.Create({
        lists: lists.value.map((item: any) => item.code), /* Format the data by creating an array containing the ids of the lists to store them in the box data */
      }));
    };

    const fetchLists = async () => {
      const { items, err } = await List<ShopsLists>({
        name: 'ShopsLists',
        settings: {
          filter: [
            {
              field: 'id_shop',
              operator: OperatorType.Equals,
              value: idShop.value,
            },
            {
              field: 'active',
              operator: OperatorType.Equals,
              value: 1,
            },
            {
              field: 'valid',
              operator: OperatorType.Equals,
              value: 1,
            }
          ],
          order: [
            { field: 'id_shop_list', type: 'ASC' },
          ],
          limit: 0,
          offset: 0,
        },
        fields: ['id_shop_list', 'name'],
      });

      if (err === '') {
        const result: { name: string; code: string | number }[] = items.map((item) => ({
          name: item.name,
          code: item.id_shop_list,
        }));

        // Add fake list for push tokens
        result.unshift({
          name: t('myLists.pushToken.title'),
          code: 'push_tokens',
        });

        return result;
      }

      return [];
    };

    watch([lists], update);

    watch(() => myListsState.refreshComponent, async () => {
      if (myListsState.refreshComponent !== 0) {
        const newListId = myListsState.refreshComponent;
        listsOptions.value = await fetchLists();
        myListsState.refreshComponent = 0;
        const matchingCurrentLists = listsOptions.value.filter((item: any) => item.code === asInt(newListId));
        if (matchingCurrentLists.length) {
          const { name, code } = matchingCurrentLists[0];
          lists.value.push({
            name,
            code,
          });
        }

        update();
      }
    });

    onMounted(async () => {
      const formattedLists: { name: string; code: any }[] = [];
      listsOptions.value = await fetchLists();

      /* Format data by creating an array containing objects { name: string; code: string }
      * to be used by the MultiSelect component */
      props.modelValue.lists.forEach((list) => {
        const matchingCurrentLists = listsOptions.value.filter((item: any) => item.code === list);
        if (matchingCurrentLists.length) {
          const { name, code } = matchingCurrentLists[0];
          formattedLists.push({
            name,
            code,
          });
        }
      });

      lists.value = formattedLists;
      update();
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      lists,
      listsOptions,
    };
  },
});
</script>

<style lang="scss" scoped>
.field-wrapper {
  .p-multiselect {
    width: 100%;
  }
}
</style>
