import { ComponentNameEnum, ItemsVerticalAlignValueEnum, BaseButtonFieldConfig } from '@/types';

const property = 'align-items';

const itemsVerticalAlignFieldConfig: BaseButtonFieldConfig = {
  componentName: ComponentNameEnum.BUTTON_GROUP,
  options: {
    [ItemsVerticalAlignValueEnum.START]: {
      title: 'templateBuilder.fields.alignTop',
      iconClass: 'fa-arrow-up',
      property,
      defaultValue: ItemsVerticalAlignValueEnum.START,
    },
    [ItemsVerticalAlignValueEnum.CENTER]: {
      title: 'templateBuilder.fields.alignMiddle',
      iconClass: 'fa-arrow-right',
      property,
      defaultValue: ItemsVerticalAlignValueEnum.START,
    },
    [ItemsVerticalAlignValueEnum.END]: {
      title: 'templateBuilder.fields.alignBottom',
      iconClass: 'fa-arrow-down',
      property,
      defaultValue: ItemsVerticalAlignValueEnum.START,
    },
  },
};

export default itemsVerticalAlignFieldConfig;
