
import Button from 'primevue/button';
import {
  defineComponent, onMounted, Ref, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { MarketingWorkflowsCategoryEnum, ToolbarItemsModel, TypeCampaignEnum } from '@/types';
import EditorModal from '@/components/modals/EditorModal.vue';
import {
  CampaignsBulkBase, CampaignsBulkDuplicateInputItem,
} from '@/types/generated-types/graphql';
import Loader from '@/components/layout/Loader.vue';
import { asInt, showToastError, showToastSuccess } from '@/helpers';
import { UserState } from '@/composables/User';
import { activateCampaign, setRefreshScenariosList } from '@/composables/AutomatedScenarios';
import PreviewModal from '@/components/modals/PreviewModal.vue';
import { duplicateBulkCampaign, fetchBaseCampaignsBulk } from '@/composables/workflows/CampaignBulk';
import { store } from '@/store';
import { getNowFormattedByTimezone } from '@/helpers/Date';

export default defineComponent({
  name: 'CampaignsBulkBaseSelector',

  components: {
    PreviewModal,
    Loader,
    EditorModal,
    Button,
  },

  emits: {
    'on-close-dialog': null,
  },

  setup(props: { }, context: SetupContext) {
    const { t } = useI18n();
    const idActiveShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const isListLoading = ref(false);
    const list: Ref<CampaignsBulkBase[]> = ref([]);
    const displayPreviewModal = ref(false);
    const images = ref([{}]);

    const closeModal = () => {
      context.emit('on-close-dialog');
    };

    const handleChoose = (idCampaignBase: number) => {
      store.commit('general/showTheSpinner');

      const name = `${t('campaignPrefix')} - ${getNowFormattedByTimezone()}`;

      const workflowToDuplicateInput: CampaignsBulkDuplicateInputItem = {
        id_campaign_bulk: idCampaignBase,
        id_shop_destination: idActiveShop.value,
        base_campaign: 1,
        name,
      };

      duplicateBulkCampaign(workflowToDuplicateInput).then((result) => {
        if (result.success && result.id) {
          const id = asInt(result.id);
          showToastSuccess(t('automatedScenarios.create_action.success'));
          setRefreshScenariosList(true);
          activateCampaign(id, TypeCampaignEnum.BULK).then(() => {
            context.emit('on-close-dialog');
            store.commit('general/hideTheSpinner');
          });
        } else {
          showToastError(t('automatedScenarios.duplicate.error'));
          store.commit('general/hideTheSpinner');
        }
      }).catch(() => {
        showToastError(t('automatedScenarios.duplicate.error'));
        store.commit('general/hideTheSpinner');
      });
    };

    const handlePreview = (idCampaignBase: number) => {
      const wfToPreview: CampaignsBulkBase[] = list.value.filter((baseWorkflow) => baseWorkflow.id_campaign_bulk_base === idCampaignBase);

      images.value = [];
      if (wfToPreview[0].images_templates !== null && wfToPreview[0].images_templates.length) {
        wfToPreview[0].images_templates.forEach((image) => {
          images.value.push({
            itemImageSrc: image,
            thumbnailImageSrc: image,
            alt: wfToPreview[0].legend,
            title: wfToPreview[0].name,
          });
        });
      }

      displayPreviewModal.value = true;
    };

    const handleClosePreview = () => {
      displayPreviewModal.value = false;
    };

    onMounted(async () => {
      isListLoading.value = true;
      const result = await fetchBaseCampaignsBulk();
      if (result) {
        list.value = result;
        isListLoading.value = false;
      }
    });

    return {
      t,
      list,
      images,
      MarketingWorkflowsCategoryEnum,
      displayPreviewModal,
      isListLoading,
      closeModal,
      handleChoose,
      handleClosePreview,
      handlePreview,
    };
  },
});
