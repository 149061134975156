// eslint-disable-next-line import/no-cycle
import {
  addSmartListOriginalCode,
  getSmartListHtmlELementFromState,
  restoreSmartListsOriginalCode,
} from '@/composables/template-editor/TemplateEditor';
import { Maybe } from '@/types/generated-types/graphql';

// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';
// eslint-disable-next-line import/no-cycle
import { sanitizeTranslationMarkup } from '../utils/translate';

/**
 * Get specific smart list property from original HTML code
 * @param selector
 * @param name
 * @param widgetId
 */
export const getSmartListProperty = (selector: string, name: string, widgetId: string): string => {
  let returnValue = '';
  const element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  if (element) {
    const regExp = new RegExp(`.*s-${name}="([^"]*)"`, 'is');
    const matches = element.match(regExp);
    returnValue = (matches && matches[1]) ?? '';
  }

  return returnValue;
};

export const getSmartListHtmlCode = (selector: string, name: string, widgetId: string) => {
  const element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  if (element) {
    return element;
  }

  return '';
};

export const getTruncateLimit = (variable: string, name: string, widgetId: string) => {
  let returnValue = '';
  const element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  if (element) {
    const regExp = new RegExp(`\\{var="?${variable}"?[^}]*truncate="?([0-9]+)"?[^}]*}`, 'is');
    const matches = element.match(regExp);
    returnValue = (matches && matches[1]) ?? '';
  }

  return returnValue;
};

export const getDisplayedElements = (selector: string, name: string, widgetId: string): string[] => {
  const element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);

  // Elements are hidden by adding "spm_hide_on_builder" class
  const allElements = [
    'spm_product_list_element_button',
    'spm_product_list_element_description',
    'spm_product_list_element_price',
    'spm_product_list_element_price_strike',
    'spm_product_list_element_image',
    'spm_product_list_element_title',
    'spm_product_list_title',
  ];

  const displayedElements: string[] = [];

  if (element) {
    allElements.forEach((displayableElement) => {
      // We check if class exists or not
      const regExp = new RegExp(`"([^"]*${displayableElement}(?:\\s[^"]+)*)"`, 'is');
      const matches = element?.match(regExp);

      if (matches) {
        const [, match] = matches;
        const classList = match.split(' ');

        if (!classList.includes('spm_hide_on_builder')) {
          // If we need to display the item, we need to remove the class
          displayedElements.push(displayableElement);
        }
      }
    });
  }

  return displayedElements;
};

export const getListLayout = (selector: string, name: string, widgetId: string): string => {
  const element: Maybe<string> = getSmartListHtmlELementFromState(widgetId);
  let returnValue = '';

  if (element) {
    const regExp = new RegExp('"([^"]*spm_product_list(?:\\s[^"]+)*)"', 'is');
    const matches = element?.match(regExp);

    if (matches) {
      const [, match] = matches;
      const classList = match.split(' ');
      const layout = classList.filter((className) => className.startsWith(name, 0)).map((className) => className.replace(name, ''));
      if (layout) {
        [returnValue] = layout;
      }
    }
  }

  return returnValue;
};

// eslint-disable-next-line consistent-return
export const getSmartListTitle = (selector: string, name: string, widgetId: string) => {
  // We restore original HTML code for that widget
  restoreSmartListsOriginalCode(widgetId);
  let returnValue;
  const template = getTemplateIframeDocument();
  const regex = /#spm_widget/;

  if (regex.test(selector)) {
    const element: HTMLElement | null = template?.querySelector(selector);

    if (element) {
      // This getter can be involved by translation
      returnValue = sanitizeTranslationMarkup(element).innerHTML;

      // We get the complete widget element, and we store the original HTML code in the state
      const widget: HTMLElement | null = template?.querySelector(`#${widgetId}`);
      if (widget) {
        addSmartListOriginalCode(widgetId, widget.innerHTML);
      }
    }
  }
  // Refresh smart product list
  refreshSmartProductList(widgetId);
  return returnValue;
};

export const getSmartListContent = (selector: string, name: string, widgetId: string) => {
  // We restore original HTML code for that widget
  restoreSmartListsOriginalCode(widgetId);
  let returnValue = '';
  // We get widget inside the template
  const template = getTemplateIframeDocument();
  const element: HTMLElement | null = template?.querySelector(selector);

  if (element) {
    // This getter can be involved by translation
    returnValue = sanitizeTranslationMarkup(element).innerHTML;
    // We get the complete widget element, and we store the original HTML code in the state
    const widget: HTMLElement | null = template?.querySelector(`#${widgetId}`);
    if (widget) {
      addSmartListOriginalCode(widgetId, widget.innerHTML);
    }
  }
  return returnValue;
};
