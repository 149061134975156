
import {
  computed,
  defineComponent, onBeforeMount, PropType, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { FieldConfig, ParserFieldObject, Property } from '@/types';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import ItemList from '@/components/template-builder/fields/ItemList.vue';
import Stepper from './Stepper.vue';

export default defineComponent({
  name: 'SelectionType',

  components: {
    BaseDropdown,
    ItemList,
    Stepper,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: ['on-change-properties'],

  setup(props, context) {
    const { t } = useI18n();

    /** Main property (engine choice) */
    const property: Ref<Property> = computed(() => props.parserValues.properties && props.parserValues.properties[0]);
    const selectedValue: Ref<string> = ref(property.value.value ?? props.configs.options.defaultValue);
    const options = ref([{}]);

    /** Search period property (nb_weeks) */
    const periodProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[1]);
    const periodConfigs: Ref<FieldConfig> = ref({
      ...props.configs.options.periodConfigs,
      selector: props.configs.selector,
      properties: [periodProperty.value],
      displayToggle: false,
    });
    const periodParserValues: Ref<ParserFieldObject> = ref({
      selector: props.parserValues.selector,
      properties: [periodProperty.value],
    });

    /** Selected ids property (ids) */
    const selectedIdsProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties[2]);
    const selectedIdsConfigs: Ref<FieldConfig> = computed(() => ({
      ...props.configs.options.selectedIdsConfigs(selectedValue.value),
      selector: props.configs.selector,
      properties: [selectedIdsProperty.value],
    }));
    const selectedIdsParserValues: Ref<ParserFieldObject> = computed(() => ({
      selector: props.parserValues.selector,
      properties: [selectedIdsProperty.value],
    }));

    // On change Engine
    const handleChangeSelection = (value: string) => {
      selectedValue.value = value;
      property.value.value = selectedValue.value;
      // Remove selected ids if we change selection type
      const emptySelectedIdsProperty = { ...selectedIdsProperty.value };
      emptySelectedIdsProperty.value = '';
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [property.value, emptySelectedIdsProperty],
      });
    };

    // On change search period
    const handleChangePeriod = (newProperty: Property) => {
      context.emit('on-change-properties', newProperty);
    };

    // On change selected ids
    const handleChangeSelectedIds = () => {
      context.emit('on-change-properties', selectedIdsParserValues.value);
    };

    onBeforeMount(async () => {
      try {
        options.value = await props.configs.options.list;
      } catch (error) {
        options.value = [{}];
      }
    });

    watch(() => property.value.value, (values, oldValues) => {
      if (values !== oldValues) {
        selectedValue.value = property.value.value;
      }
    }, { immediate: true });

    return {
      t,
      selectedValue,
      options,
      periodConfigs,
      periodParserValues,
      selectedIdsConfigs,
      selectedIdsParserValues,
      handleChangeSelection,
      handleChangeSelectedIds,
      handleChangePeriod,
    };
  },
});
