<template>
  <div class="field-wrapper">
    <label class="block mb-2">
      {{ t('automatedScenarios.fields.numberOfTests') }}
    </label>
    <BaseInputNumber
      v-model="value"
      :min="2"
      :max="6"
      class="value-input"
    />
    <FieldErrors
      :key="componentFieldErrorsKey"
      :errors="formValidationErrors"
      field="outputs_number_test"
    />
  </div>
  <div v-if="isEdit && value < initialValue">
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t('automatedScenarios.actions.abTest.chooseBranchesToDelete', { nb: nbOutputsMustBeDeleted }) }}
      </label>
      <div class="flex flex-column">
        <div
          v-for="(item, index) in outputsCanBeDeleted"
          :key="index"
          class="flex flex-row pb-2"
          @click="selectOutputToDelete(item.output)"
        >
          <div
            class="display-box-container-abtest"
            :class="{ 'display-box-declencheur-abtest': item.classOperator === 'declencheur', 'display-box-action-abtest': item.classOperator === 'action',
                      'display-box-filtre-abtest': item.classOperator === 'filtre', 'display-box-disabled-abtest': item.classOperator === 'empty_branch' }"
          >
            <div
              class="display-box-container-alphabet-branch-abtest"
              :class="{ 'selected': outputsToDelete.includes(item.output) }"
            >
              {{ alphabetBranches[index] }}
            </div>
            <div class="display-box-container-icon-box-abtest">
              <i
                class="far"
                :class="item.logoOperator"
              />
            </div>
            <div class="display-box-container-label-box-abtest">
              <span>{{ t(item.titleOperator) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed, defineComponent,
  PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ActionABTestMetada, { ActionABTestData } from '@/components/automated-scenarios/metadata/actions/ActionABTestMetadata';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import { showToastError, asInt } from '@/helpers';
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
} from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'ActionABTest',
  components: {
    FieldErrors,
    BaseInputNumber,
  },

  props: {
    modelValue: {
      type: Object as PropType<ActionABTestData>,
      required: true,
      default() {
        return ActionABTestMetada.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: ActionABTestData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const alphabetBranches = ['A', 'B', 'C', 'D', 'E', 'F'];
    const value: Ref<number> = ref(asInt(props.modelValue.outputs_number_test));
    const outputsToDelete: Ref<string[]> = ref([]);
    const initialValue = asInt(props.modelValue.outputs_number_test);

    const isEdit: Ref<boolean> = ref(state.selectedOperator.operatorAddMethod === null && state.selectedOperator.operatorId !== '');
    const nbOutputsMustBeDeleted = computed(() => initialValue - value.value);
    const outputsCanBeDeleted = [];
    if (isEdit.value && initialValue) {
      const currentOperatorId = state.selectedOperator.operatorId;
      for (let i = 1; i <= initialValue; i++) {
        const childOperator = getOperatorChildByOutput(currentOperatorId, `output_${i}`);

        if (childOperator !== null) {
          const childOperatorData = state.scenario.data.operators[childOperator];
          outputsCanBeDeleted.push({
            output: `output_${i}`,
            titleOperator: childOperatorData.properties.title,
            classOperator: childOperatorData.properties.class,
            logoOperator: childOperatorData.custom.logo_box,
          });
        } else {
          outputsCanBeDeleted.push({
            output: `output_${i}`,
            titleOperator: t('automatedScenarios.actions.abTest.emptyBranch'),
            classOperator: 'empty_branch',
            logoOperator: 'fa-times',
          });
        }
      }
    }

    const update = () => {
      let initial_number_outputs: number|null = null;
      let outputs_to_delete: string[]|null = null;

      if (isEdit.value && value.value < initialValue) {
        initial_number_outputs = initialValue;
        outputs_to_delete = outputsToDelete.value;
      }

      context.emit('update:modelValue', ActionABTestMetada.Create({
        ...props.modelValue,
        outputs_number_test: value.value,
        initial_number_outputs,
        outputs_to_delete,
      }));
    };

    const selectOutputToDelete = (output: string): void => {
      if (outputsToDelete.value.includes(output)) {
        const index = outputsToDelete.value.indexOf(output);
        if (index > -1) {
          outputsToDelete.value.splice(index, 1);
        }
      } else if (outputsToDelete.value.length < nbOutputsMustBeDeleted.value) {
        outputsToDelete.value.push(output);
      } else {
        let errorMessage = t('automatedScenarios.actions.abTest.errorNbBranchToDelete');
        if (nbOutputsMustBeDeleted.value > 1) {
          errorMessage = t('automatedScenarios.actions.abTest.errorNbBranchesToDelete', { nb: nbOutputsMustBeDeleted.value });
        }
        showToastError(errorMessage);
      }
    };

    watch(value, () => {
      update();
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      value,
      alphabetBranches,
      initialValue,
      outputsCanBeDeleted,
      nbOutputsMustBeDeleted,
      selectOutputToDelete,
      outputsToDelete,
      isEdit,
    };
  },
});
</script>

<style lang="scss" scoped>
.display-box-container-abtest {
  height: 50px;
  background-color: #fff;
  border-radius: 0.4em;
  display: flex;
  cursor: pointer;

  .display-box-container-alphabet-branch-abtest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-radius: 0.3em 0 0 0.3em;
    padding: 0 10px;
    color: white;
    background-color: $shuttle-grey;
    position: relative;

    &.selected:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.3 !important;
      font-size: 50px;
      content: "\274c";
      line-height: 50px;
      text-align: center;
    }
  }

  .display-box-container-icon-box-abtest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    border-radius: 0 0.3em 0.3em 0;
    color: #fff;
    padding: 0 10px;
  }

  .display-box-container-label-box-abtest {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 160px;
    font-size: 12px;
    padding: 0 10px;
    color: #000;
  }

  &.display-box-declencheur-abtest {
    border: solid 1px #ffa31a;
    .display-box-container-icon-box-abtest {
      background-color: #ffa31a;
    }
  }

  &.display-box-action-abtest {
    border: solid 1px #90d51c;

    .display-box-container-icon-box-abtest {
      background-color: #90d51c;
    }
  }

  &.display-box-filtre-abtest {
    border: solid 1px #91c4f2;

    .display-box-container-icon-box-abtest {
      background-color: #91c4f2;
    }
  }

  &.display-box-disabled-abtest {
    border: solid 1px #E9E9E9;

    .display-box-container-icon-box-abtest {
      background-color: #E9E9E9;
    }
    .display-box-container-label-box-abtest {
      color: #979797;
    }
  }
}
</style>
