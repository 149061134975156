<template>
  <Dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :show-header="false"
    :draggable="false"
    :closable="false"
    style="width: 20vw"
    @update:visible="updateDialogVisibility"
  >
    <div v-html="translation('storeParameters.services.facebook.logout')" />
    <template #footer>
      <div class="flex justify-content-center mb-4">
        <Button
          class="p-button p-button-success mr-2"
          @click="requestDisconnect"
        >
          {{ translation('yes') }}
        </Button>
        <Button
          class="p-button p-button-danger"
          @click="updateDialogVisibility"
        >
          {{ translation('no') }}
        </Button>
      </div>
    </template>
  </dialog>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FBDisconnect',
  props: {
    translation: {
      type: Function,
      required: true,
    },
  },

  emits: [
    'close-action-modal',
    'disconnect',
  ],

  setup(props, context) {
    const dialogVisible = ref(true);

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', false);
    };

    const requestDisconnect = () => {
      context.emit('disconnect');
    };

    return { dialogVisible, updateDialogVisibility, requestDisconnect };
  },
});
</script>

<style scoped>

</style>
