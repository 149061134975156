<template>
  <div class="flex align-items-center toolbar-items-container">
    <div
      v-for="(btn, index) in menuButtons"
      :key="index"
      class="mx-1"
      :class="{
        flex: noShrink,
        'flex-shrink-0': noShrink
      }"
    >
      <template v-if="btn.items && btn.items.length">
        <template v-if="btn.multipleType === 'buttons'">
          <span
            v-if="!btn.hide"
            class="p-buttonset toolbar-buttons-container"
          >
            <template
              v-for="(buttonItem, index2) in btn.items"
              :key="index2"
            >
              <Button
                v-if="(!buttonItem.items || !buttonItem.items.length) && !buttonItem.hide"
                v-tooltip.bottom="buttonItem.tooltip"
                class="toolbar-button-item"
                :label="buttonItem.label"
                :icon="buttonItem.icon"
                :icon-pos="buttonItem.iconPos ?? 'right'"
                :class="typeof buttonItem.class === 'function' ? buttonItem.class() : buttonItem.class"
                @click="buttonItem.command()"
              />
              <template v-if="buttonItem.items && buttonItem.items.length">
                <Button
                  v-if="!buttonItem.hide"
                  v-tooltip.bottom="buttonItem.tooltip"
                  class="toolbar-button-item toolbar-button-dropdown"
                  :label="buttonItem.label"
                  :icon="buttonItem.icon"
                  :class="typeof buttonItem.class === 'function' ? buttonItem.class() : buttonItem.class"
                  @click="(e) => { ('command' in buttonItem && buttonItem.command !== undefined) ? buttonItem.command() : toggleMenu(e, index + '_' + index2) }"
                >
                  <span
                    v-if="buttonItem.hasOwnProperty('icon') && buttonItem.icon"
                    class="p-button-icon"
                    :class="`${buttonItem.icon} p-button-icon-${buttonItem.iconPos ?? 'right'}`"
                  />
                  <span
                    class="p-button-label"
                  >
                    {{ buttonItem.label ?? '&nbsp;' }}
                  </span>
                  <span
                    class="p-button-icon p-button-icon-right caret fas fa-caret-down"
                  />
                </Button>
                <Menu
                  :ref="el => { if (el) menuRefs[index + '_' + index2] = el}"
                  :model="buttonItem.items"
                  class="toolbarbuttons-submenu"
                  popup
                />
              </template>
            </template>
          </span>
        </template>
        <template v-else>
          <div class="toolbar-dropdown-container">
            <Button
              v-if="!btn.hide"
              v-tooltip.bottom="btn.tooltip"
              :label="btn.label"
              :icon="btn.icon"
              :class="typeof btn.class === 'function' ? btn.class() : btn.class"
              aria-haspopup="true"
              aria-controls="split_menu"
              @click="(e) => { ('command' in btn && btn.command !== undefined) ? btn.command() : toggleMenu(e, index) }"
            >
              <span
                v-if="btn.hasOwnProperty('icon') && btn.icon"
                class="p-button-icon"
                :class="`${btn.icon} p-button-icon-${btn.iconPos ?? 'right'}`"
              />
              <span
                class="p-button-label"
              >
                {{ btn.label ?? '&nbsp;' }}
              </span>
              <span
                class="p-button-icon p-button-icon-right caret fas fa-caret-down"
              />
            </Button>
            <Menu
              :ref="el => { if (el) menuRefs[index] = el}"
              :model="btn.items"
              class="toolbarbuttons-submenu"
              popup
            />
          </div>
        </template>
      </template>
      <template
        v-else-if="btn.btnType === 'calendar'"
      >
        <CustomDatePicker
          v-model="btn.data"
          calendar-position="right"
          :default-value="btn.defaultValue"
          :custom-options-items="btn.customOptions || []"
          @update:model-value="btn.command($event)"
        />
      </template>
      <template v-else>
        <Button
          v-tooltip.bottom="btn.tooltip"
          class="toolbar-button-item"
          :v-show="!btn.hide"
          :class="typeof btn.class === 'function' ? btn.class() : btn.class"
          :icon="btn.icon"
          :label="btn.label"
          @click="btn.command"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, computed, PropType, Ref, ref,
} from 'vue';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Tooltip from 'primevue/tooltip';
import { ToolbarItemsModel } from '@/types';
import CustomDatePicker from '@/components/fields/CustomDatePicker.vue';

export default defineComponent({
  name: 'ToolbarButtons',

  components: {
    CustomDatePicker,
    Button,
    Menu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    buttons: {
      type: Array as PropType<ToolbarItemsModel[]>,
      required: false,
      default: () => [],
    },

    noShrink: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  setup(props: { buttons: ToolbarItemsModel[] }) {
    const menuRefs: Ref<any []> = ref([]);

    const toggleMenu = (event: Event, index: string) => {
      if (menuRefs.value) {
        menuRefs.value[index as keyof object].toggle(event);
      }
    };

    const menuButtons = computed(() => props.buttons.filter((button: ToolbarItemsModel) => !button.hide));

    return {
      menuRefs,
      menuButtons,
      toggleMenu,
    };
  },

});
</script>

<style>
.p-menu.toolbarbuttons-submenu {
  width: auto;
}
</style>

<style scoped lang="scss">
.content-header-btn {
  background-color: $white;
  border: 1px solid $heather;
  border-radius: 2px;
  color: $shuttle-grey;
  padding: 0.5rem;

  &:enabled:hover,
  &.selected {
    background-color: $heather;
    border-color: $heather;
    color: $shuttle-grey;
  }
}

.toolbar-items-container {
  .toolbar-button-item {
    background-color: white;
    color: $tuna;
    border: 1px solid $light-grey;
    box-shadow: none !important;

    &:hover {
      color: $brand-color-primary;
      border: 1px solid $light-grey;
    }

    &:active:not(.disabled) {
      border-color: $light-grey;
      color: $brand-color-primary;
    }

    &:focus {
      border-color: $light-grey;
    }

    &.active {
      border-color: $light-grey;
      color: $brand-color-primary;
    }

    &.disabled {
      border-color: $light-grey;
      color: $heather;
    }
  }

  .toolbar-buttons-container {
    .toolbar-button-item {
      border-left: none;
      border-right: none;

      &:first-child {
        border-left: 1px solid $light-grey;
      }

      &:last-child {
        border-right: 1px solid $light-grey;
      }
    }
  }

  .toolbar-dropdown-container {

  }

  .p-button.p-button-icon-only {
    &.toolbar-button-dropdown {
      padding: 0.5rem;
      width: auto;
    }

    & .p-button-icon-right.caret {
      margin-left: 0.2rem;
    }
  }
}

.overflow-ellipsis{
  display: block;
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis .p-button-icon {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
