import { DropdownOption } from '@/types';

export const countriesPhonesCodes: Record<string, string> = {
  AF: '+93',
  AX: '+358',
  AL: '+355',
  DZ: '+213',
  AS: '+1684',
  AD: '+376',
  AO: '+244',
  AI: '+1264',
  AQ: '+672',
  AG: '+1268',
  AR: '+54',
  AM: '+374',
  AW: '+297',
  AU: '+61',
  AT: '+43',
  AZ: '+994',
  BB: '+1246',
  BY: '+375',
  BE: '+32',
  BZ: '+501',
  BJ: '+229',
  BM: '+1441',
  BT: '+975',
  BO: '+591',
  BA: '+387',
  BW: '+267',
  BV: '+47',
  BR: '+55',
  BQ: '+599',
  IO: '+246',
  VG: '+1284',
  BN: '+673',
  BG: '+359',
  BF: '+226',
  BI: '+257',
  KH: '+855',
  CM: '+237',
  CA: '+1',
  CV: '+238',
  KY: '+1345',
  CF: '+236',
  TD: '+235',
  CL: '+56',
  CN: '+86',
  CX: '+61',
  CC: '+61',
  CO: '+57',
  KM: '+269',
  CG: '+242',
  CD: '+243',
  CK: '+682',
  CR: '+506',
  CI: '+225',
  HR: '+385',
  CU: '+53',
  CY: '+357',
  CZ: '+420',
  DK: '+45',
  DJ: '+253',
  DM: '+1767',
  DO: '+1809',
  EC: '+593',
  EG: '+20',
  SV: '+503',
  GQ: '+240',
  ER: '+291',
  EE: '+372',
  ET: '+251',
  FK: '+500',
  FO: '+298',
  FJ: '+679',
  FI: '+358',
  FR: '+33',
  GF: '+594',
  PF: '+689',
  TF: '+262',
  GA: '+241',
  GM: '+220',
  GE: '+995',
  DE: '+49',
  GH: '+233',
  GI: '+350',
  GR: '+30',
  GL: '+299',
  GD: '+1473',
  GP: '+590',
  GU: '+1671',
  GT: '+502',
  GG: '+44',
  GN: '+224',
  GW: '+245',
  GY: '+592',
  HT: '+509',
  HN: '+504',
  HK: '+852',
  HU: '+36',
  IS: '+354',
  IN: '+91',
  ID: '+62',
  IR: '+98',
  IQ: '+964',
  IE: '+353',
  IM: '+44',
  IL: '+972',
  IT: '+39',
  JM: '+1876',
  JP: '+81',
  JE: '+44',
  JO: '+962',
  KZ: '+7',
  KE: '+254',
  KI: '+686',
  KW: '+965',
  KG: '+996',
  LA: '+856',
  LV: '+371',
  LB: '+961',
  LS: '+266',
  LR: '+231',
  LY: '+218',
  LI: '+423',
  LT: '+370',
  LU: '+352',
  MO: '+853',
  MK: '+389',
  MG: '+261',
  MW: '+265',
  MY: '+60',
  MV: '+960',
  ML: '+223',
  MT: '+356',
  MH: '+692',
  MQ: '+596',
  MR: '+222',
  MU: '+230',
  YT: '+262',
  MX: '+52',
  FM: '+691',
  MD: '+373',
  MC: '+377',
  MN: '+976',
  ME: '+382',
  MS: '+1664',
  MA: '+212',
  MZ: '+258',
  MM: '+95',
  NA: '+264',
  NR: '+674',
  NP: '+977',
  NL: '+31',
  AN: '+599',
  NC: '+687',
  NZ: '+64',
  NI: '+505',
  NE: '+227',
  NG: '+234',
  NU: '+683',
  NF: '+672',
  MP: '+1670',
  KP: '+850',
  NO: '+47',
  OM: '+968',
  PK: '+92',
  PW: '+680',
  PS: '+970',
  PA: '+507',
  PG: '+675',
  PY: '+595',
  PE: '+51',
  PH: '+63',
  PN: '+870',
  PL: '+48',
  PT: '+351',
  PR: '+1',
  QA: '+974',
  RE: '+262',
  RO: '+40',
  RU: '+7',
  RW: '+250',
  BL: '+590',
  SH: '+290',
  KN: '+1869',
  LC: '+1758',
  MF: '+590',
  PM: '+508',
  VC: '+1784',
  WS: '+685',
  SM: '+378',
  ST: '+239',
  SA: '+966',
  SN: '+221',
  RS: '+381',
  SC: '+248',
  SL: '+232',
  SG: '+65',
  SK: '+421',
  SI: '+386',
  SB: '+677',
  SO: '+252',
  ZA: '+27',
  GS: '+500',
  KR: '+82',
  ES: '+34',
  LK: '+94',
  SD: '+249',
  SR: '+597',
  SJ: '+47',
  SZ: '+268',
  SE: '+46',
  CH: '+41',
  SY: '+963',
  TW: '+886',
  TJ: '+992',
  TZ: '+255',
  TH: '+66',
  TL: '+670',
  TG: '+228',
  TK: '+690',
  TO: '+676',
  TT: '+1868',
  TN: '+216',
  TR: '+90',
  TM: '+993',
  TC: '+1649',
  TV: '+688',
  UG: '+256',
  UA: '+380',
  AE: '+971',
  GB: '+44',
  US: '+1',
  UY: '+598',
  VI: '+1',
  UZ: '+998',
  VU: '+678',
  VA: '+39',
  VE: '+58',
  VN: '+84',
  WF: '+681',
  EH: '+212',
  YE: '+967',
  ZM: '+260',
  ZW: '+263',
};

const getCountries = (t: Function): DropdownOption[] => ([
  { value: 'AF', label: t('countries.codes.AF') },
  { value: 'AX', label: t('countries.codes.AX') },
  { value: 'AL', label: t('countries.codes.AL') },
  { value: 'DZ', label: t('countries.codes.DZ') },
  { value: 'AS', label: t('countries.codes.AS') },
  { value: 'AD', label: t('countries.codes.AD') },
  { value: 'AO', label: t('countries.codes.AO') },
  { value: 'AI', label: t('countries.codes.AI') },
  { value: 'AQ', label: t('countries.codes.AQ') },
  { value: 'AG', label: t('countries.codes.AG') },
  { value: 'AR', label: t('countries.codes.AR') },
  { value: 'AM', label: t('countries.codes.AM') },
  { value: 'AW', label: t('countries.codes.AW') },
  { value: 'AU', label: t('countries.codes.AU') },
  { value: 'AT', label: t('countries.codes.AT') },
  { value: 'AZ', label: t('countries.codes.AZ') },
  { value: 'BB', label: t('countries.codes.BB') },
  { value: 'BY', label: t('countries.codes.BY') },
  { value: 'BE', label: t('countries.codes.BE') },
  { value: 'BZ', label: t('countries.codes.BZ') },
  { value: 'BJ', label: t('countries.codes.BJ') },
  { value: 'BM', label: t('countries.codes.BM') },
  { value: 'BT', label: t('countries.codes.BT') },
  { value: 'BO', label: t('countries.codes.BO') },
  { value: 'BA', label: t('countries.codes.BA') },
  { value: 'BW', label: t('countries.codes.BW') },
  { value: 'BV', label: t('countries.codes.BV') },
  { value: 'BR', label: t('countries.codes.BR') },
  { value: 'BQ', label: t('countries.codes.BQ') },
  { value: 'IO', label: t('countries.codes.IO') },
  { value: 'VG', label: t('countries.codes.VG') },
  { value: 'BN', label: t('countries.codes.BN') },
  { value: 'BG', label: t('countries.codes.BG') },
  { value: 'BF', label: t('countries.codes.BF') },
  { value: 'BI', label: t('countries.codes.BI') },
  { value: 'KH', label: t('countries.codes.KH') },
  { value: 'CM', label: t('countries.codes.CM') },
  { value: 'CA', label: t('countries.codes.CA') },
  { value: 'CV', label: t('countries.codes.CV') },
  { value: 'KY', label: t('countries.codes.KY') },
  { value: 'CF', label: t('countries.codes.CF') },
  { value: 'TD', label: t('countries.codes.TD') },
  { value: 'CL', label: t('countries.codes.CL') },
  { value: 'CN', label: t('countries.codes.CN') },
  { value: 'CX', label: t('countries.codes.CX') },
  { value: 'CC', label: t('countries.codes.CC') },
  { value: 'CO', label: t('countries.codes.CO') },
  { value: 'KM', label: t('countries.codes.KM') },
  { value: 'CG', label: t('countries.codes.CG') },
  { value: 'CD', label: t('countries.codes.CD') },
  { value: 'CK', label: t('countries.codes.CK') },
  { value: 'CR', label: t('countries.codes.CR') },
  { value: 'CI', label: t('countries.codes.CI') },
  { value: 'HR', label: t('countries.codes.HR') },
  { value: 'CU', label: t('countries.codes.CU') },
  { value: 'CY', label: t('countries.codes.CY') },
  { value: 'CZ', label: t('countries.codes.CZ') },
  { value: 'DK', label: t('countries.codes.DK') },
  { value: 'DJ', label: t('countries.codes.DJ') },
  { value: 'DM', label: t('countries.codes.DM') },
  { value: 'DO', label: t('countries.codes.DO') },
  { value: 'EC', label: t('countries.codes.EC') },
  { value: 'EG', label: t('countries.codes.EG') },
  { value: 'SV', label: t('countries.codes.SV') },
  { value: 'GQ', label: t('countries.codes.GQ') },
  { value: 'ER', label: t('countries.codes.ER') },
  { value: 'EE', label: t('countries.codes.EE') },
  { value: 'ET', label: t('countries.codes.ET') },
  { value: 'FK', label: t('countries.codes.FK') },
  { value: 'FO', label: t('countries.codes.FO') },
  { value: 'FJ', label: t('countries.codes.FJ') },
  { value: 'FI', label: t('countries.codes.FI') },
  { value: 'FR', label: t('countries.codes.FR') },
  { value: 'GF', label: t('countries.codes.GF') },
  { value: 'PF', label: t('countries.codes.PF') },
  { value: 'TF', label: t('countries.codes.TF') },
  { value: 'GA', label: t('countries.codes.GA') },
  { value: 'GM', label: t('countries.codes.GM') },
  { value: 'GE', label: t('countries.codes.GE') },
  { value: 'DE', label: t('countries.codes.DE') },
  { value: 'GH', label: t('countries.codes.GH') },
  { value: 'GI', label: t('countries.codes.GI') },
  { value: 'GR', label: t('countries.codes.GR') },
  { value: 'GL', label: t('countries.codes.GL') },
  { value: 'GD', label: t('countries.codes.GD') },
  { value: 'GP', label: t('countries.codes.GP') },
  { value: 'GU', label: t('countries.codes.GU') },
  { value: 'GT', label: t('countries.codes.GT') },
  { value: 'GG', label: t('countries.codes.GG') },
  { value: 'GN', label: t('countries.codes.GN') },
  { value: 'GW', label: t('countries.codes.GW') },
  { value: 'GY', label: t('countries.codes.GY') },
  { value: 'HT', label: t('countries.codes.HT') },
  { value: 'HN', label: t('countries.codes.HN') },
  { value: 'HK', label: t('countries.codes.HK') },
  { value: 'HU', label: t('countries.codes.HU') },
  { value: 'IS', label: t('countries.codes.IS') },
  { value: 'IN', label: t('countries.codes.IN') },
  { value: 'ID', label: t('countries.codes.ID') },
  { value: 'IR', label: t('countries.codes.IR') },
  { value: 'IQ', label: t('countries.codes.IQ') },
  { value: 'IE', label: t('countries.codes.IE') },
  { value: 'IM', label: t('countries.codes.IM') },
  { value: 'IL', label: t('countries.codes.IL') },
  { value: 'IT', label: t('countries.codes.IT') },
  { value: 'JM', label: t('countries.codes.JM') },
  { value: 'JP', label: t('countries.codes.JP') },
  { value: 'JE', label: t('countries.codes.JE') },
  { value: 'JO', label: t('countries.codes.JO') },
  { value: 'KZ', label: t('countries.codes.KZ') },
  { value: 'KE', label: t('countries.codes.KE') },
  { value: 'KI', label: t('countries.codes.KI') },
  { value: 'KW', label: t('countries.codes.KW') },
  { value: 'KG', label: t('countries.codes.KG') },
  { value: 'LA', label: t('countries.codes.LA') },
  { value: 'LV', label: t('countries.codes.LV') },
  { value: 'LB', label: t('countries.codes.LB') },
  { value: 'LS', label: t('countries.codes.LS') },
  { value: 'LR', label: t('countries.codes.LR') },
  { value: 'LY', label: t('countries.codes.LY') },
  { value: 'LI', label: t('countries.codes.LI') },
  { value: 'LT', label: t('countries.codes.LT') },
  { value: 'LU', label: t('countries.codes.LU') },
  { value: 'MO', label: t('countries.codes.MO') },
  { value: 'MK', label: t('countries.codes.MK') },
  { value: 'MG', label: t('countries.codes.MG') },
  { value: 'MW', label: t('countries.codes.MW') },
  { value: 'MY', label: t('countries.codes.MY') },
  { value: 'MV', label: t('countries.codes.MV') },
  { value: 'ML', label: t('countries.codes.ML') },
  { value: 'MT', label: t('countries.codes.MT') },
  { value: 'MH', label: t('countries.codes.MH') },
  { value: 'MQ', label: t('countries.codes.MQ') },
  { value: 'MR', label: t('countries.codes.MR') },
  { value: 'MU', label: t('countries.codes.MU') },
  { value: 'YT', label: t('countries.codes.YT') },
  { value: 'MX', label: t('countries.codes.MX') },
  { value: 'FM', label: t('countries.codes.FM') },
  { value: 'MD', label: t('countries.codes.MD') },
  { value: 'MC', label: t('countries.codes.MC') },
  { value: 'MN', label: t('countries.codes.MN') },
  { value: 'ME', label: t('countries.codes.ME') },
  { value: 'MS', label: t('countries.codes.MS') },
  { value: 'MA', label: t('countries.codes.MA') },
  { value: 'MZ', label: t('countries.codes.MZ') },
  { value: 'MM', label: t('countries.codes.MM') },
  { value: 'NA', label: t('countries.codes.NA') },
  { value: 'NR', label: t('countries.codes.NR') },
  { value: 'NP', label: t('countries.codes.NP') },
  { value: 'NL', label: t('countries.codes.NL') },
  { value: 'AN', label: t('countries.codes.AN') },
  { value: 'NC', label: t('countries.codes.NC') },
  { value: 'NZ', label: t('countries.codes.NZ') },
  { value: 'NI', label: t('countries.codes.NI') },
  { value: 'NE', label: t('countries.codes.NE') },
  { value: 'NG', label: t('countries.codes.NG') },
  { value: 'NU', label: t('countries.codes.NU') },
  { value: 'NF', label: t('countries.codes.NF') },
  { value: 'MP', label: t('countries.codes.MP') },
  { value: 'KP', label: t('countries.codes.KP') },
  { value: 'NO', label: t('countries.codes.NO') },
  { value: 'OM', label: t('countries.codes.OM') },
  { value: 'PK', label: t('countries.codes.PK') },
  { value: 'PW', label: t('countries.codes.PW') },
  { value: 'PS', label: t('countries.codes.PS') },
  { value: 'PA', label: t('countries.codes.PA') },
  { value: 'PG', label: t('countries.codes.PG') },
  { value: 'PY', label: t('countries.codes.PY') },
  { value: 'PE', label: t('countries.codes.PE') },
  { value: 'PH', label: t('countries.codes.PH') },
  { value: 'PN', label: t('countries.codes.PN') },
  { value: 'PL', label: t('countries.codes.PL') },
  { value: 'PT', label: t('countries.codes.PT') },
  { value: 'PR', label: t('countries.codes.PR') },
  { value: 'QA', label: t('countries.codes.QA') },
  { value: 'RE', label: t('countries.codes.RE') },
  { value: 'RO', label: t('countries.codes.RO') },
  { value: 'RU', label: t('countries.codes.RU') },
  { value: 'RW', label: t('countries.codes.RW') },
  { value: 'BL', label: t('countries.codes.BL') },
  { value: 'SH', label: t('countries.codes.SH') },
  { value: 'KN', label: t('countries.codes.KN') },
  { value: 'LC', label: t('countries.codes.LC') },
  { value: 'MF', label: t('countries.codes.MF') },
  { value: 'PM', label: t('countries.codes.PM') },
  { value: 'VC', label: t('countries.codes.VC') },
  { value: 'WS', label: t('countries.codes.WS') },
  { value: 'SM', label: t('countries.codes.SM') },
  { value: 'ST', label: t('countries.codes.ST') },
  { value: 'SA', label: t('countries.codes.SA') },
  { value: 'SN', label: t('countries.codes.SN') },
  { value: 'RS', label: t('countries.codes.RS') },
  { value: 'SC', label: t('countries.codes.SC') },
  { value: 'SL', label: t('countries.codes.SL') },
  { value: 'SG', label: t('countries.codes.SG') },
  { value: 'SK', label: t('countries.codes.SK') },
  { value: 'SI', label: t('countries.codes.SI') },
  { value: 'SB', label: t('countries.codes.SB') },
  { value: 'SO', label: t('countries.codes.SO') },
  { value: 'ZA', label: t('countries.codes.ZA') },
  { value: 'GS', label: t('countries.codes.GS') },
  { value: 'KR', label: t('countries.codes.KR') },
  { value: 'ES', label: t('countries.codes.ES') },
  { value: 'LK', label: t('countries.codes.LK') },
  { value: 'SD', label: t('countries.codes.SD') },
  { value: 'SR', label: t('countries.codes.SR') },
  { value: 'SJ', label: t('countries.codes.SJ') },
  { value: 'SZ', label: t('countries.codes.SZ') },
  { value: 'SE', label: t('countries.codes.SE') },
  { value: 'CH', label: t('countries.codes.CH') },
  { value: 'SY', label: t('countries.codes.SY') },
  { value: 'TW', label: t('countries.codes.TW') },
  { value: 'TJ', label: t('countries.codes.TJ') },
  { value: 'TZ', label: t('countries.codes.TZ') },
  { value: 'TH', label: t('countries.codes.TH') },
  { value: 'TL', label: t('countries.codes.TL') },
  { value: 'TG', label: t('countries.codes.TG') },
  { value: 'TK', label: t('countries.codes.TK') },
  { value: 'TO', label: t('countries.codes.TO') },
  { value: 'TT', label: t('countries.codes.TT') },
  { value: 'TN', label: t('countries.codes.TN') },
  { value: 'TR', label: t('countries.codes.TR') },
  { value: 'TM', label: t('countries.codes.TM') },
  { value: 'TC', label: t('countries.codes.TC') },
  { value: 'TV', label: t('countries.codes.TV') },
  { value: 'UG', label: t('countries.codes.UG') },
  { value: 'UA', label: t('countries.codes.UA') },
  { value: 'AE', label: t('countries.codes.AE') },
  { value: 'GB', label: t('countries.codes.GB') },
  { value: 'US', label: t('countries.codes.US') },
  { value: 'UY', label: t('countries.codes.UY') },
  { value: 'VI', label: t('countries.codes.VI') },
  { value: 'UZ', label: t('countries.codes.UZ') },
  { value: 'VU', label: t('countries.codes.VU') },
  { value: 'VA', label: t('countries.codes.VA') },
  { value: 'VE', label: t('countries.codes.VE') },
  { value: 'VN', label: t('countries.codes.VN') },
  { value: 'WF', label: t('countries.codes.WF') },
  { value: 'EH', label: t('countries.codes.EH') },
  { value: 'YE', label: t('countries.codes.YE') },
  { value: 'ZM', label: t('countries.codes.ZM') },
  { value: 'ZW', label: t('countries.codes.ZW') },
]);

export default getCountries;
