<template>
  <Button
    :label="label"
    :loading="loading"
    :type="type"
    :icon="icon"
    :class="classStyle"
    style="border-radius: 0.3rem;"
    :badge="badgeText"
    :badge-class="badgeClass"
    :disabled="disabled"
  />
</template>

<script lang="ts">
import {
  defineComponent,
} from 'vue';
import Button from 'primevue/button';

export default defineComponent({
  name: 'SpmButton',

  components: {
    Button,
  },

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },

    type: {
      type: String,
      required: false,
      default: 'button',
    },

    icon: {
      type: String,
      required: false,
      default: '',
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    classStyle: {
      type: String,
      required: false,
      default: '',
    },

    badgeText: {
      type: String,
      required: false,
      default: '',
    },

    badgeClass: {
      type: String,
      required: false,
      default: '',
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    return {
    };
  },
});
</script>

<style lang="scss">
  .p-button {
    .p-badge {
      color: $brand-color-primary;

      &.p-badge-success {
        background: $brand-color-primary;
        color: $white;
      }
    }
  }
</style>
