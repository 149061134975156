import {
  OperatorType,
  UsersPagesSubscriptions,
  UsersPagesSubscriptionsDeleteInput,
  UsersPagesSubscriptionsInputItem,
  UsersPagesSubscriptionsUpdateInputItem,
  UsersPagesConfiguration,
} from '@/types/generated-types/graphql';
import {
  Delete,
  Insert,
  List,
  Update,
} from '@/composables/GraphQL';
import { StatsType } from '@/types';

export const InsertUsersPagesSubscriptions = async (usersPagesConfInput: UsersPagesSubscriptionsInputItem[]) => Insert<UsersPagesSubscriptionsInputItem>({
  name: 'UsersPagesSubscriptions',
  input: usersPagesConfInput,
  type: 'UsersPagesSubscriptionsInput',
});

export const UpdateUsersPagesSubscriptions = async (usersPagesConfUpdateInput: UsersPagesSubscriptionsUpdateInputItem[]) => Update<UsersPagesSubscriptionsUpdateInputItem>({
  name: 'UsersPagesSubscriptions',
  input: usersPagesConfUpdateInput,
  type: 'UsersPagesSubscriptionsUpdateInput',
});

export const DeleteUsersPagesSubscriptions = async (shopUsersPagesDeleteInput: UsersPagesSubscriptionsDeleteInput[]) => Delete<UsersPagesSubscriptionsDeleteInput>({
  name: 'UsersPagesSubscriptions',
  input: shopUsersPagesDeleteInput,
  type: 'UsersPagesSubscriptionsDeleteInput',
});

// Get user's custom stats pages from database
export const getUserStatsPages = async (idUser: number, typeUser: string, type: StatsType | null = null, idAdminUser: number | null = null, otherUserStats = false) => {
  const filter = [
    { field: 'id_user', value: idUser, operator: OperatorType.Equals },
    { field: 'user_type', value: typeUser, operator: OperatorType.Equals },
  ];

  if (type) {
    filter.push({ field: 'type', value: type, operator: OperatorType.Equals });
  }

  const list = await List<UsersPagesSubscriptions>({
    name: 'UsersPagesSubscriptions',
    settings: {
      limit: 0,
      offset: 0,
      order: [
        {
          field: 'priority',
          type: 'ASC',
        },
      ],
      filter,
    },
    fields: {
      id_users_pages_subscription: null,
      id_users_pages_configuration: null,
      type: null,
      page: {
        page_name: null,
        deleted: null,
      },
    },
  });

  if (idAdminUser !== null && list.items.length > 0 && idUser !== idAdminUser) {
    const idUsersPagesConfiguration = list.items.map((item: any) => item.id_users_pages_configuration).join(',');

    const adminFilter = [
      { field: 'admin_user_id', value: idAdminUser, operator: OperatorType.In },
      { field: 'id_users_pages_configuration', value: idUsersPagesConfiguration, operator: OperatorType.In },
    ];

    const listUsersPagesConfigurations = await List<UsersPagesConfiguration>({
      name: 'UsersPagesConfiguration',
      settings: {
        limit: 0,
        offset: 0,
        order: [],
        filter: adminFilter,
      },
      fields: ['id_users_pages_configuration', 'type', 'page_name', 'navigation_name', 'date_creation', 'date_modification'],
    });

    if (listUsersPagesConfigurations && listUsersPagesConfigurations.err === '' && listUsersPagesConfigurations.items.length > 0) {
      list.items = list.items.filter((item: any) => {
        const condition = (itemList: any) => (itemList.id_users_pages_configuration === item.id_users_pages_configuration);

        const foundItem = listUsersPagesConfigurations.items.find((itemList: any) => condition(itemList));

        if (foundItem && !otherUserStats) {
          return true;
        }
        if (foundItem && otherUserStats) {
          return false;
        }
        if (!foundItem && otherUserStats) {
          return true;
        }
        return false;
      });
    } else {
      list.items = !otherUserStats ? [] : list.items;
    }
  }

  return list;
};
