import ActionWaitMetadata from '@/components/automated-scenarios/metadata/actions/ActionWaitMetadata';
import ActionDelaisMaxMetadata from '@/components/automated-scenarios/metadata/actions/ActionDelaisMaxMetadata';
import ActionTagMetadata from '@/components/automated-scenarios/metadata/actions/ActionTagMetadata';
import ActionListMetadata from '@/components/automated-scenarios/metadata/actions/ActionListMetadata';
import ActionSendEmailMetadata from '@/components/automated-scenarios/metadata/actions/ActionSendEmailMetadata';
import ActionSendSmsMetadata from '@/components/automated-scenarios/metadata/actions/ActionSendSmsMetadata';
import ActionDisplayFreePopupMetadata
  from '@/components/automated-scenarios/metadata/actions/ActionDisplayFreePopupMetadata';
import ActionDisplayFreeModelMetadata
  from '@/components/automated-scenarios/metadata/actions/ActionDisplayFreeModelMetadata';
import ActionSendFbMessengerMetadata
  from '@/components/automated-scenarios/metadata/actions/ActionSendFbMessengerMetadata';
import ActionSendPushNotificationMetadata
  from '@/components/automated-scenarios/metadata/actions/ActionSendPushNotificationMetadata';
import ActionABTestMetadata from '@/components/automated-scenarios/metadata/actions/ActionABTestMetadata';
import DeclencheurAbandonPanierMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurAbandonPanierMetadata';
import DeclencheurCollectAnonymousVisitorMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurCollectAnonymousVisitorMetadata';
import DeclencheurStatusCommandMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurStatusCommandMetadata';
import DeclencheurVisitPageMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurVisitPageMetadata';
import DeclencheurSitePassTimeMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurSitePassTimeMetadata';
import DeclencheurRegisterOnSiteMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurRegisterOnSiteMetadata';
import DeclencheurSubscribeToNewsletterMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurSubscribeToNewsletterMetadata';
import DeclencheurScrollPageMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurScrollPageMetadata';
import DeclencheurQuitSiteMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurQuitSiteMetadata';
import DeclencheurPeriodicLaunchingMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurPeriodicLaunchingMetadata';
import DeclencheurChooseBulkListMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurChooseBulkListMetadata';
import DeclencheurCustomJSMetadata
  from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurCustomJSMetadata';
import FiltreCurrentCartMetadata from '@/components/automated-scenarios/metadata/filtres/FiltreCurrentCartMetadata';
import FiltreCurrentOrderMetadata from '@/components/automated-scenarios/metadata/filtres/FiltreCurrentOrderMetadata';
import FiltreCustomerMetadata from '@/components/automated-scenarios/metadata/filtres/FiltreCustomerMetadata';
import FiltrePurchaseHistoryMetadata from '@/components/automated-scenarios/metadata/filtres/FiltrePurchaseHistoryMetadata';
import FiltreBoxNavigationTriggerMetadata
  from '@/components/automated-scenarios/metadata/filtres/FiltreBoxNavigationTriggerMetadata';
import FiltreOpenEmailMetadata from '@/components/automated-scenarios/metadata/filtres/FiltreOpenEmailMetadata';
import FiltreClicklinkMetadata from '@/components/automated-scenarios/metadata/filtres/FiltreClicklinkMetadata';
import FiltreFilledoutFormMetadata from '@/components/automated-scenarios/metadata/filtres/FiltreFilledoutFormMetadata';
import FiltreActionOnCampaignMetadata
  from '@/components/automated-scenarios/metadata/filtres/FiltreActionOnCampaignMetadata';
import { ComponentMetadata } from '@/types/automated-scenarios';

const Metadata = {
  ActionWaitMetadata,
  ActionDelaisMaxMetadata,
  ActionTagMetadata,
  ActionListMetadata,
  ActionSendEmailMetadata,
  ActionSendSmsMetadata,
  ActionDisplayFreePopupMetadata,
  ActionDisplayFreeModelMetadata,
  ActionSendFbMessengerMetadata,
  ActionSendPushNotificationMetadata,
  ActionABTestMetadata,
  DeclencheurAbandonPanierMetadata,
  DeclencheurCollectAnonymousVisitorMetadata,
  DeclencheurStatusCommandMetadata,
  DeclencheurVisitPageMetadata,
  DeclencheurSitePassTimeMetadata,
  DeclencheurRegisterOnSiteMetadata,
  DeclencheurSubscribeToNewsletterMetadata,
  DeclencheurScrollPageMetadata,
  DeclencheurQuitSiteMetadata,
  DeclencheurPeriodicLaunchingMetadata,
  DeclencheurCustomJSMetadata,
  DeclencheurChooseBulkListMetadata,
  FiltreCurrentCartMetadata,
  FiltreCurrentOrderMetadata,
  FiltreCustomerMetadata,
  FiltrePurchaseHistoryMetadata,
  FiltreBoxNavigationTriggerMetadata,
  FiltreOpenEmailMetadata,
  FiltreClicklinkMetadata,
  FiltreFilledoutFormMetadata,
  FiltreActionOnCampaignMetadata,
};

export default Metadata;

export function getComponentById(id: string) {
  switch (id) {
    case 'boxabtest':
      return 'ActionABTest';
    case 'boxdelaismax':
      return 'ActionDelaisMax';
    case 'boxdisplayfreemodel':
      return 'ActionDisplayFreeModel';
    case 'boxdisplayfreepopup':
      return 'ActionDisplayFreePopup';
    case 'boxlist':
      return 'ActionList';
    case 'boxsendemail':
      return 'ActionSendEmail';
    case 'boxsendfbmessenger':
      return 'ActionSendFbMessenger';
    case 'boxsendpushnotification':
      return 'ActionSendPushNotification';
    case 'boxsendsms':
      return 'ActionSendSms';
    case 'boxtag':
      return 'ActionTag';
    case 'boxwait':
      return 'ActionWait';

    case 'boxabandonpanier':
      return 'DeclencheurAbandonPanier';
    case 'boxcollectanonymousvisitordata':
      return 'DeclencheurCollectAnonymousVisitor';
    case 'boxcustomjs':
      return 'DeclencheurCustomJS';
    case 'boxperiodiclaunching':
      return 'DeclencheurPeriodicLaunching';
    case 'boxquitsite':
      return 'DeclencheurQuitSite';
    case 'registeronsite':
      return 'DeclencheurRegisterOnSite';
    case 'boxscrollpage':
      return 'DeclencheurScrollPage';
    case 'boxsitepasstime':
      return 'DeclencheurSitePassTime';

    case 'boxstatuscommand':
      return 'DeclencheurStatusCommand';
    case 'subscribetonewsletter':
      return 'DeclencheurSubscribeToNewsletter';
    case 'boxvisitpage':
      return 'DeclencheurVisitPage';
    case 'boxchoosebulklist':
      return 'DeclencheurChooseBulkList';

    case 'actionOnCampaign':
      return 'FiltreActionOnCampaign';
    case 'boxfiltre_1':
      return 'FiltreCurrentCart';
    case 'boxfiltre_2':
      return 'FiltreCurrentOrder';
    case 'boxfiltre_3':
      return 'FiltreCustomer';
    case 'boxfiltre_4':
      return 'FiltrePurchaseHistory';
    case 'boxnavigationtrigger':
      return 'FiltreBoxNavigationTrigger';
    case 'clicklink':
      return 'FiltreClicklink';
    case 'filledoutform':
      return 'FiltreFilledoutForm';
    case 'openemail':
      return 'FiltreOpenEmail';

    default:
      return '';
  }
}

export function getComponentMetadata(component: string): ComponentMetadata<any> | null {
  switch (component) {
    case 'ActionWait':
      return ActionWaitMetadata;
    case 'ActionDelaisMax':
      return ActionDelaisMaxMetadata;
    case 'ActionTag':
      return ActionTagMetadata;
    case 'ActionList':
      return ActionListMetadata;
    case 'ActionSendEmail':
      return ActionSendEmailMetadata;
    case 'ActionSendSms':
      return ActionSendSmsMetadata;
    case 'ActionDisplayFreePopup':
      return ActionDisplayFreePopupMetadata;
    case 'ActionDisplayFreeModel':
      return ActionDisplayFreeModelMetadata;
    case 'ActionSendFbMessenger':
      return ActionSendFbMessengerMetadata;
    case 'ActionSendPushNotification':
      return ActionSendPushNotificationMetadata;
    case 'ActionABTest':
      return ActionABTestMetadata;
    case 'DeclencheurAbandonPanier':
      return DeclencheurAbandonPanierMetadata;
    case 'DeclencheurCollectAnonymousVisitor':
      return DeclencheurCollectAnonymousVisitorMetadata;
    case 'DeclencheurStatusCommand':
      return DeclencheurStatusCommandMetadata;
    case 'DeclencheurVisitPage':
      return DeclencheurVisitPageMetadata;
    case 'DeclencheurSitePassTime':
      return DeclencheurSitePassTimeMetadata;
    case 'DeclencheurRegisterOnSite':
      return DeclencheurRegisterOnSiteMetadata;
    case 'DeclencheurSubscribeToNewsletter':
      return DeclencheurSubscribeToNewsletterMetadata;
    case 'DeclencheurScrollPage':
      return DeclencheurScrollPageMetadata;
    case 'DeclencheurQuitSite':
      return DeclencheurQuitSiteMetadata;
    case 'DeclencheurPeriodicLaunching':
      return DeclencheurPeriodicLaunchingMetadata;
    case 'DeclencheurCustomJS':
      return DeclencheurCustomJSMetadata;
    case 'DeclencheurChooseBulkList':
      return DeclencheurChooseBulkListMetadata;
    case 'FiltreCurrentCart':
      return FiltreCurrentCartMetadata;
    case 'FiltreCurrentOrder':
      return FiltreCurrentOrderMetadata;
    case 'FiltreCustomer':
      return FiltreCustomerMetadata;
    case 'FiltrePurchaseHistory':
      return FiltrePurchaseHistoryMetadata;
    case 'FiltreBoxNavigationTrigger':
      return FiltreBoxNavigationTriggerMetadata;
    case 'FiltreOpenEmail':
      return FiltreOpenEmailMetadata;
    case 'FiltreClicklink':
      return FiltreClicklinkMetadata;
    case 'FiltreFilledoutForm':
      return FiltreFilledoutFormMetadata;
    case 'FiltreActionOnCampaign':
      return FiltreActionOnCampaignMetadata;
    default:
      return null;
  }
}

export function getComponentMetadataById(id: string): ComponentMetadata<any> | null {
  const component = getComponentById(id);
  return getComponentMetadata(component);
}
