import {
  ComponentNameEnum,
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TemplateStructureEnum,
  TabItem,
  StepperSignEnum,
} from '@/types';
import textStylesFieldConfig from '@/components/template-builder/config/fields/text-styles-config';
import getTextAlignFieldConfig from '@/components/template-builder/config/fields/text-align-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import modelWidthFieldConfig from '@/components/template-builder/config/fields/model-width-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
// eslint-disable-next-line import/no-cycle
import {
  cleanCssByProperty,
  recalculateLineHeightValue,
  setAttribute, setCssButtonWidthProperty,
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setCssPropertyWithImportantAndRemoveIfZero,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getAttributeByName, getCssPropertyByName } from '@/components/template-builder/getters';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import lineHeightFieldConfig from '@/components/template-builder/config/fields/line-height-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';

export const pageTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.PAGE,
  label: 'templateBuilder.configs.page',
  tabPanel: {
    label: 'templateBuilder.configs.page',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageStyles',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: 'body table#spm_body',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.modelWidth',
            selector: 'body table#spm_body table.container',
            properties: [
              { name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'min-width', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...modelWidthFieldConfig,
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: 'body table#spm_body',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageMargins',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '#spm_body table.spm_draggable_row > tbody > tr > td',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.columnInnerMargins',
            selector: '#spm_body th.spm_droppable_widget, #spm_body td.spm_droppable_widget',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
          {
            label: 'templateBuilder.fields.widgetInnerMargins',
            selector: '#spm_body table.spm_draggable_widget > tbody > tr > th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.pageTextsAndTitles',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.pageTexts',
              selector: 'body table#spm_body',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.textAlign',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [cleanCssByProperty, setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR}, {SELECTOR} td, {SELECTOR} th',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.pageLinks',
              selector: 'body table#spm_body',
              items: [
                {
                  label: 'templateBuilder.fields.color',
                  selector: '{SELECTOR} a, {SELECTOR} a:hover, {SELECTOR} a:visited, {SELECTOR} a:active',
                  properties: [{ name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#333333'),
                  options: {
                    defaultValue: '#2795b6',
                  },
                },
                {
                  label: 'templateBuilder.fields.style',
                  selector: '{SELECTOR} a, {SELECTOR} a:hover, {SELECTOR} a:visited, {SELECTOR} a:active',
                  properties: [
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...textStylesFieldConfig,
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title1',
              selector: 'body table#spm_body',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h1, {SELECTOR} td h1, {SELECTOR} th h1',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),

                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title2',
              selector: 'body table#spm_body',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h2, {SELECTOR} td h2, {SELECTOR} th h2',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),

                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title3',
              selector: 'body table#spm_body',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h3, {SELECTOR} td h3, {SELECTOR} th h3',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),

                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.title4',
              selector: 'body table#spm_body',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.titleMarginVertical',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [
                    { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getVerticalSpacesFieldConfig('margin', 10),
                },
                {
                  label: 'templateBuilder.fields.titleMarginHorizontal',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [
                    { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...getHorizontalSpacesFieldConfig('margin'),
                },
                {
                  label: 'templateBuilder.fields.lineHeight.label',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...lineHeightFieldConfig,
                  displayToggle: false,
                },
                {
                  label: 'templateBuilder.fields.spaceBetweenChars',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.titleAlign',
                  selector: '{SELECTOR} h4, {SELECTOR} td h4, {SELECTOR} th h4',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(),
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.pageButtons',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.contours',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getBorderRadiusFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th,'
              + ' {SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited,'
              + ' {SELECTOR} table.button th a:hover, {SELECTOR} table.button th a:active',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
            options: {
              defaultValue: '#E0E0E0',
            },
          },
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, {SELECTOR} '
              + 'table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, {SELECTOR} table.button '
              + 'th a:active',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.textAlign',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, '
              + '{SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, '
              + '{SELECTOR} table.button th a:active',
            properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
          {
            label: 'templateBuilder.fields.buttonWidth',
            selector: '{SELECTOR} table.button',
            properties: [{
              name: 'width',
              getter: getCssPropertyByName,
              setters: [setCssButtonWidthProperty],
            }],
            ...getButtonWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.spaceWithinButton',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig().options,
            },
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.separators',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.style',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th table.spm_separator th',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.width',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th table.spm_separator',
            properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.marginVertical',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
            ],
            ...getVerticalSpacesFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.marginHorizontal',
            selector: '{SELECTOR} .spm_widget_separator > tbody > tr > th',
            properties: [
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyWithImportantAndRemoveIfZero] },
            ],
            ...getHorizontalSpacesFieldConfig(),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.mobileVersion',
        selector: 'body table#spm_body',
        items: [
          {
            label: 'templateBuilder.fields.increaseTextSizeWith',
            selector: '#spm_body',
            properties: [{ name: 'data-mobilefontsizeincrease', getter: getAttributeByName, setters: [setAttribute] }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
        ],
      },
    ],
  },
};

const pageItemConfig: ConfigurationPanelStructure = {
  items: [
    pageTabItemConfig,
  ],
};

export default pageItemConfig;
