
import { defineComponent, PropType } from 'vue';
import Button from 'primevue/button';
import { ButtonFieldOption, Property } from '@/types';

export default defineComponent({
  name: 'BaseButton',
  components: { Button },

  props: {
    button: {
      type: Object as PropType<ButtonFieldOption>,
      required: true,
    },

    properties: {
      type: Object as PropType<Array<Property>>,
      required: true,
    },
  },

  emits: {
    'on-click': Object,
  },

  setup(props: {properties: Array<Property>}, context) {
    const isButtonSelected = (button: ButtonFieldOption): boolean => {
      if (props.properties && props.properties.length > 0) {
        const currentProperty = props.properties.filter((property: Property) => property.name === button.property)[0];
        if (currentProperty && currentProperty.value !== undefined) {
          if (button.multipleChoice) {
            return currentProperty.value.split(' ')?.includes(button.value);
          }
          return (currentProperty.value.toString() === button.value);
        }
      }
      return button.value === button.defaultValue;
    };

    const handleMultipleChoiceProperty = (button: ButtonFieldOption, currentProperty: Property): string => {
      const currentValue = currentProperty.value?.split(' ') || [];
      const index = currentValue.indexOf(button.value);
      if (isButtonSelected(button)) {
        currentValue.splice(index, 1);
      } else {
        currentValue.push(button.value);
      }
      return currentValue.join(' ');
    };

    const handleClick = (button: ButtonFieldOption) => {
      let value: string;
      const currentProperty = props.properties.filter((property: Property) => property.name === button.property)[0];
      if (button.multipleChoice) {
        value = handleMultipleChoiceProperty(button, currentProperty);
      } else {
        value = isButtonSelected(button) ? button.defaultValue : button.value;
      }
      currentProperty.value = value;
      context.emit('on-click', currentProperty);
    };

    return {
      isButtonSelected,
      handleClick,
    };
  },
});
