import { ComponentNameEnum, BaseStepperPxPercentSliderFieldConfig } from '@/types';

const modelWidthFieldConfig: BaseStepperPxPercentSliderFieldConfig = {
  componentName: ComponentNameEnum.STEPPER_PX_PERCENT_SLIDER,
  options: {
    px: {
      min: 500,
      max: 800,
      step: 1,
      defaultValue: 600,
      isDefaultUnitSelected: true,
    },
  },
};

export default modelWidthFieldConfig;
