import { FiltrePurchaseHistoryFilters } from '@/components/automated-scenarios/metadata/filtres/FiltrePurchaseHistoryMetadata';
import { i18n } from '@/i18n';
import { ErrorConfigForm, FilterSegment } from '@/types/automated-scenarios';
import {
  OperatorType, ShopsManufacturers, ShopsProducts, ShopsProductsCategoriesList,
} from '@/types/generated-types/graphql';
import { FilterOperatorsEnum, TypeCampaignEnum } from '@/types';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { includesValuesValidator, itemsPickerValidator } from '@/helpers/CustomValidator';

let translation: any;
(async () => {
  translation = await i18n;
})();

export default () => ([
  {
    id: 'filterhasordered',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filterhasordered', label: translation.global.t('automatedScenarios.fields.segments.hasOrdered') },
    fields: [
      {
        id: 'check_if_order_valid',
        component: 'ButtonGroup',
        data: '0',
        props: {
          title: 'automatedScenarios.fields.onlyValidOrders',
          options: [
            { value: '0', label: translation.global.t('no') },
            { value: '1', label: translation.global.t('yes') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.checkIfOrderValid'),
      },
      {
        id: 'hidden_has_ordered',
        component: 'Hidden',
        data: '1',
        hidden: true,
        litteral_title: '',
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        check_if_order_valid: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
    group: 'purchase',
  },
  {
    id: 'filternoorderfrom',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filternoorderfrom', label: translation.global.t('automatedScenarios.fields.segments.placedAValidOrderInTheLast') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY', operator: '' },
        props: {
          title: 'automatedScenarios.fields.placedAValidOrderInTheLast',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
          operators: [
            { value: FilterOperatorsEnum.EQUALS, label: translation.global.t('filters.equals') },
            { value: FilterOperatorsEnum.NOT_EQUALS, label: translation.global.t('filters.notEquals') },
            { value: FilterOperatorsEnum.LESS_THAN, label: translation.global.t('filters.lessThan') },
            { value: FilterOperatorsEnum.LESS_THAN_OR_EQUAL_TO, label: translation.global.t('filters.lessThanOrEquals') },
            { value: FilterOperatorsEnum.GREATER_THAN, label: translation.global.t('filters.greaterThan') },
            { value: FilterOperatorsEnum.GREATER_THAN_OR_EQUAL_TO, label: translation.global.t('filters.greaterThanOrEquals') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.timeSinceLastOrder'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    group: 'purchase',
  },
  {
    id: 'filtertimesincelastorder',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filtertimesincelastorder', label: translation.global.t('automatedScenarios.fields.segments.timeSinceLastOrder') },
    fields: [
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.timeSinceLastOrder',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.timeSinceLastOrder'),
      },
      {
        id: 'check_if_order_valid',
        component: 'ButtonGroup',
        data: '0',
        props: {
          title: 'automatedScenarios.fields.onlyValidOrders',
          options: [
            { value: '0', label: translation.global.t('no') },
            { value: '1', label: translation.global.t('yes') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.checkIfOrderValid'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
        check_if_order_valid: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'filterdateordercount',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterdateordercount', label: translation.global.t('automatedScenarios.fields.segments.numberOfOrdersOnPeriod') },
    fields: [
      {
        id: 'range_slider_date_order_count_time',
        component: 'NumberRange',
        data: { min: 1, max: 9 },
        props: {
          title: 'automatedScenarios.fields.customersOrdered',
          suffix: ` ${translation.global.t('automatedScenarios.fields.times')}`,
          min: 1,
          max: 100,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.hasOrderedBetween'),
      },
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.timeSinceLastOrder',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.inTheLast'),
      },
      {
        id: 'check_if_order_valid',
        component: 'ButtonGroup',
        data: '0',
        props: {
          title: 'automatedScenarios.fields.onlyValidOrders',
          options: [
            { value: '0', label: translation.global.t('no') },
            { value: '1', label: translation.global.t('yes') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.checkIfOrderValid'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        range_slider_date_order_count_time: {
          required,
        },
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
        check_if_order_valid: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterdateorderamountcurrency',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterdateorderamountcurrency', label: translation.global.t('automatedScenarios.fields.segments.amountOfOrdersOnPeriod') },
    fields: [
      {
        id: 'date_order_amount_currency',
        component: 'NumberRange',
        data: { min: 1, max: 100000 },
        props: {
          title: 'automatedScenarios.fields.customersOrdered',
          suffix: ' €',
          min: 1,
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.hasOrderedBetween'),
      },
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.timeSinceLastOrder',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.inTheLast'),
      },
      {
        id: 'check_if_order_valid',
        component: 'ButtonGroup',
        data: '0',
        props: {
          title: 'automatedScenarios.fields.onlyValidOrders',
          options: [
            { value: '0', label: translation.global.t('no') },
            { value: '1', label: translation.global.t('yes') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.checkIfOrderValid'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        date_order_amount_currency: {
          required,
        },
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
        check_if_order_valid: {
          required,
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterdateorderproductcount',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK],
    option: { value: 'filterdateorderproductcount', label: translation.global.t('automatedScenarios.fields.segments.productsOrderedOnPeriod') },
    fields: [
      {
        id: 'product_picker_date_order_product_count',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.productList',
            buttonLabel: 'automatedScenarios.fields.chooseProducts',
            operatorLabel: 'automatedScenarios.fields.visitorMustHavePurchased',
            name: 'ShopsProducts',
            fields: { id: 'id_shop_product', label: 'name', productId: 'id_product' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
            format: (product: ShopsProducts) => ({
              id: product.id_product, spmId: product.id_shop_product, name: product.name, combinations: {},
            }),
          },
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedProducts'),
      },
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.inTheLastFew',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.inTheLast'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_date_order_product_count: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
  },
  {
    id: 'filterdateordercategorycount',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filterdateordercategorycount', label: translation.global.t('automatedScenarios.fields.segments.categoriesOfProductsOrderedOnPeriod') },
    fields: [
      {
        id: 'product_picker_date_order_category_count',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.categoryList',
            buttonLabel: 'automatedScenarios.fields.chooseCategories',
            operatorLabel: 'automatedScenarios.fields.visitorMustHavePurchased',
            name: 'ShopsProductsCategoriesList',
            fields: { id: 'id_shop_product_category_list', label: 'name', categoryId: 'id_category' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }],
            format: (category: ShopsProductsCategoriesList) => ({
              id: category.id_category, spmId: category.id_shop_product_category_list, name: category.name, combinations: {},
            }),
          },
          operators: [
            { value: FilterOperatorsEnum.EQUALS, label: translation.global.t('filters.equals') },
            { value: FilterOperatorsEnum.NOT_EQUALS, label: translation.global.t('filters.notEquals') },
            { value: FilterOperatorsEnum.LESS_THAN, label: translation.global.t('filters.lessThan') },
            { value: FilterOperatorsEnum.LESS_THAN_OR_EQUAL_TO, label: translation.global.t('filters.lessThanOrEquals') },
            { value: FilterOperatorsEnum.GREATER_THAN, label: translation.global.t('filters.greaterThan') },
            { value: FilterOperatorsEnum.GREATER_THAN_OR_EQUAL_TO, label: translation.global.t('filters.greaterThanOrEquals') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedCategories'),
      },
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.inTheLastFew',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.inTheLast'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_date_order_category_count: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
    group: 'purchase',
  },
  {
    id: 'filterdateordermanufacturercounttime',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION, TypeCampaignEnum.BULK, TypeCampaignEnum.CUSTOMER_IMPORT],
    option: { value: 'filterdateordermanufacturercounttime', label: translation.global.t('automatedScenarios.fields.segments.manufacturersOfProductsOrderedOnPeriod') },
    fields: [
      {
        id: 'product_picker_date_order_manufacturer_count',
        component: 'ItemList',
        data: { selection: { selected: [], operator: 'and' } },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.manufacturerList',
            buttonLabel: 'automatedScenarios.fields.chooseManufacturers',
            operatorLabel: 'automatedScenarios.fields.visitorMustHavePurchased',
            name: 'ShopsManufacturers',
            fields: { id: 'id_shop_manufacturer', label: 'name', manufacturerId: 'id_manufacturer' },
            defaultFilters: [],
            format: (manufacturer: ShopsManufacturers) => ({
              id: manufacturer.id_manufacturer, spmId: manufacturer.id_shop_manufacturer, name: manufacturer.name, combinations: {},
            }),
          },
          operators: [
            { value: FilterOperatorsEnum.EQUALS, label: translation.global.t('filters.equals') },
            { value: FilterOperatorsEnum.NOT_EQUALS, label: translation.global.t('filters.notEquals') },
            { value: FilterOperatorsEnum.LESS_THAN, label: translation.global.t('filters.lessThan') },
            { value: FilterOperatorsEnum.LESS_THAN_OR_EQUAL_TO, label: translation.global.t('filters.lessThanOrEquals') },
            { value: FilterOperatorsEnum.GREATER_THAN, label: translation.global.t('filters.greaterThan') },
            { value: FilterOperatorsEnum.GREATER_THAN_OR_EQUAL_TO, label: translation.global.t('filters.greaterThanOrEquals') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.numberOfSelectedManufacturers'),
      },
      {
        id: 'time_unit_select',
        component: 'PeriodSelector',
        data: { value: 1, unit: 'DAY' },
        props: {
          title: 'automatedScenarios.fields.inTheLastFew',
          units: [
            { value: 'DAY', label: translation.global.t('automatedScenarios.fields.durations.day(s)') },
            { value: 'MONTH', label: translation.global.t('automatedScenarios.fields.durations.month(s)') },
          ],
        },
        litteral_title: translation.global.t('automatedScenarios.tooltips.inTheLast'),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        product_picker_date_order_manufacturer_count: {
          required,
          itemsPickerValidator: itemsPickerValidator(),
        },
        time_unit_select: {
          value: {
            required,
            integer,
            between: between(1, 1000),
          },
          unit: {
            required,
            includesValuesValidator: includesValuesValidator([
              'DAY',
              'MONTH',
            ]),
          },
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
    dependencies: {
      automation: {
        operators: {
          any_parents: [
            'boxabandonpanier',
            'boxstatuscommand',
            'boxvisitpage',
            'boxsitepasstime',
            'registeronsite',
            'subscribetonewsletter',
            'boxscrollpage',
            'boxquitsite',
            'boxcustomjs',
          ],
        },
        segments: {
          any_parents_contain: [
            'filterbirthdate',
            'filtercreationdate',
            'filtercreateaccountfrom',
            'filtercreatecartfrom',
            'filterhasvouchernearexpiration',
            'filternoorderfrom',
            'filtertimesincelastorder',
          ],
        },
      },
      bulk: {},
    },
    group: 'order',
  },
]) as FilterSegment<FiltrePurchaseHistoryFilters>[];
