
import {
  defineComponent, onMounted, PropType, ref, SetupContext,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import { DropdownOption, StringMap } from '@/types';
import { asInt } from '@/helpers';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SegmentMultiDropdown',

  components: {
    MultiSelect,
  },

  props: {
    modelValue: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    options: {
      type: Function,
      required: true,
    },

    optionValue: {
      type: String,
      required: false,
      default: 'value',
    },

    optionLabel: {
      type: String,
      required: false,
      default: 'label',
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: StringMap; options: Function; optionValue: string; optionLabel: string }, { emit }: SetupContext) {
    const { t } = useI18n();
    const selectedValues = ref<any>([]);
    const listObjects = ref<any>([]);

    const handleSelected = (value: any) => {
      selectedValues.value = value;
      emit('update:modelValue', selectedValues.value.reduce((acc: StringMap, current: any) => ({ ...acc, [current[props.optionValue]]: current[props.optionLabel] }), {}));
    };

    onMounted(async () => {
      listObjects.value = await props.options();

      if (listObjects.value) {
        listObjects.value = listObjects.value.map((item: any) => ({
          ...item,
          [props.optionLabel]: t(item[props.optionLabel]),
        }));
      }
      if (props.modelValue && listObjects.value) {
        selectedValues.value = Object.entries(props.modelValue)
          .map((item) => ({
            [props.optionValue]: asInt(item[0]),
            [props.optionLabel]: listObjects.value.filter((option: any) => asInt(option[props.optionValue]) === asInt(item[0]))[0][props.optionLabel],
          }));
      }
    });

    return {
      t,
      selectedValues,
      handleSelected,
      listObjects,
    };
  },
});
