<template>
  <div class="field-group-content">
    <div ref="panelHeader">
      <div class="field-wrapper">
        <span class="p-inputtext-icon p-input-icon-left">
          <i class="far fa-search" />
          <InputText
            v-model="search"
            type="text"
            :placeholder="t('search')"
          />
        </span>
      </div>
    </div>

    <ScrollPanel
      id="loadSectionsScrollPanel"
    >
      <LoadSectionPreview 
        v-for="(item, key) in displayedSections"
        :key="item.id_template_parts"
        :item="item"
        @handle-selection="handleSelection"
        @handle-deletion="handleDeletion"
      />
      <ConfirmPopup/>
    </ScrollPanel>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
  computed,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ScrollPanel from 'primevue/scrollpanel';
import ConfirmPopup from 'primevue/confirmpopup';

import LoadSectionPreview from '@/components/template-builder/LoadSectionPreview.vue';

import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import {
  LoadSectionPayload,
  TemplatePart,
} from '@/types';
import { MEDIA_URL_PART_THUMB_GENERATION } from '@/components/template-builder/utils/constants';
import {
  TemplateEditorState,
  getTemplatesParts,
  deleteSavedSection,
  insertSavedSection,
} from '@/composables/template-editor/TemplateEditor';
import { RAW_SECTION_STRUCTURE_MAP } from '@/components/template-builder/utils/raw-html-templates';
import { Maybe } from '@/types/generated-types/graphql';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { generateSha1Hash } from '@/helpers';
import { getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';
import { UserState } from '@/composables/User';

export default defineComponent({
  name: 'LoadSection',

  components: {
    Button,
    InputText,
    ScrollPanel,
    ConfirmPopup,
    LoadSectionPreview,
  },

  props: {
    payload: {
      type: Object as PropType<LoadSectionPayload>,
      required: true,
    },
  },

  emits: ['insert-section'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const toast = useToast();
    const insertSelector: Ref<string> = ref(props.payload.selector);
    const insertPosition: Ref<string> = ref(props.payload.position);

    const panelHeader: Ref<Maybe<HTMLElement>> = ref(null);
    const search: Ref<string> = ref('');
    const savedSectionsList = computed<TemplatePart[]>(() => store.getters['templateEditor/getSections']);
    const displayedSections: Ref<TemplatePart[]> = ref([]);

    const getSavedSections = async () => {
      let sections: Maybe<TemplatePart[]>;

      // Sections loading
      sections = await getTemplatesParts({
        shopId: UserState.activeShop?.id ?? 0,
        type: 'section',
        templateType: TemplateEditorState.template?.type ?? '',
        limit: 0,
        offset: 0,
      });

      if (!sections) {
        sections = [];
      }

      const emptySection: TemplatePart = {
        id_template_parts: 0,
        name: 'Section vide',
        type: 'empty_section',
        html: RAW_SECTION_STRUCTURE_MAP[TemplateEditorState.template?.type ?? ''](),
      };

      sections.unshift(emptySection);
      store.commit('templateEditor/setSections', sections);
    };

    const handleSelection = async (item: TemplatePart) => {
      // We check if the new section is a synchronized section, and if it already exists in the current template
      const matchSyncedSection = item.html?.match(new RegExp('data-spm_element_sync="([^"]+)"'));
      const template = getTemplateIframeDocument();

      if (matchSyncedSection && template.querySelector(`[data-spm_element_sync="${matchSyncedSection[1]}"]`)) {
        // New section is synced and already exists in the current template
        toast.add({
          severity: 'error',
          summary: t('templateBuilder.confirm.insertSavedSection.error.header'),
          detail: t('templateBuilder.confirm.insertSavedSection.error.message'),
          life: 3000,
        });
      } else {
        await insertSavedSection(item, insertSelector.value, insertPosition.value);
        store.commit('liveEditor/resetSelectedStructure');

        // Emit event to let parent components execute actions
        emit('insert-section');
      }
    };

    const handleDeletion = (item: TemplatePart, event: any) => {
      // Confirm deletion
      confirm.require({
        target: event.currentTarget,
        message: t('templateBuilder.confirm.deleteSavedSection.message'),
        icon: 'fal fa-info-circle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: () => {
          deleteSavedSection(item).then((deleted: boolean | string) => {
            if (deleted === true) {
              store.dispatch('templateEditor/removeSection', { id: item.id_template_parts });

              toast.add({
                severity: 'success',
                summary: t('templateBuilder.confirm.deleteSavedSection.success.header'),
                detail: t('templateBuilder.confirm.deleteSavedSection.success.message'),
                life: 3000,
              });
            } else {
              toast.add({
                severity: 'error',
                summary: t('templateBuilder.confirm.deleteSavedSection.error.header'),
                detail: t('templateBuilder.confirm.deleteSavedSection.error.message'),
                life: 3000,
              });
            }
          });
        },
      });
    };

    onMounted(async () => {
      if (panelHeader.value) {
        const scrollPanel = document.getElementById('loadSectionsScrollPanel');

        if (scrollPanel) {
          scrollPanel.style.height = `calc(100% - ${panelHeader.value?.offsetHeight}px - 15px)`;
        }
      }

      await getSavedSections();

      const regexpSearch = new RegExp(search.value ?? '', 'i');
      displayedSections.value = savedSectionsList.value.filter((section: TemplatePart) => section.name?.match(regexpSearch));
    });

    watch([savedSectionsList, search], ([newSectionsList, newSearch]) => {
      const regexpSearch = new RegExp(newSearch ?? '', 'i');
      displayedSections.value = newSectionsList.filter((section: TemplatePart) => section.name?.match(regexpSearch));
    });

    return {
      t,
      panelHeader,
      search,
      displayedSections,
      handleSelection,
      handleDeletion,
      generateSha1Hash,
      MEDIA_URL_PART_THUMB_GENERATION,
    };
  },
});
</script>

<style lang="scss" scoped>
.field-group-content {
  height: 100%;
  padding: 1rem 1.5rem;

  & .field-wrapper {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &.buttons {
      display: block;
      text-align: center;

      .p-button {
        margin-left: 0;

        &:not(:last-child) {
          margin-left: 1rem;
        }
      }
    }

    .p-inputtext-icon {
      width: 100%;

      .p-inputtext {
        width: 100%;
      }
    }
  }
}

#loadSectionsScrollPanel:deep() .p-scrollpanel-wrapper {
  margin-top: 10px;
}

#loadSectionsScrollPanel:deep() .p-scrollpanel-bar {
  background: #666 !important;

  &.p-scrollpanel-bar-y {
    left: calc(100% + 5px);
    right: auto !important;
    border-radius: 0;
    width: 4px;
  }
}
</style>
