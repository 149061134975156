export const loadImage = (url: string) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = resolve;
  img.onerror = reject;
  img.src = url;
});

export const loadImageUntilSuccess = async (tmpImage: string) => {
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      await loadImage(tmpImage);
      return; // Image loaded successfully, exit the loop
    } catch (error) {
      console.error('Error loading image, retrying in 1 second...');
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};
