
import {
  defineComponent, computed, PropType, Ref, ref,
} from 'vue';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Tooltip from 'primevue/tooltip';
import { ToolbarItemsModel } from '@/types';
import CustomDatePicker from '@/components/fields/CustomDatePicker.vue';

export default defineComponent({
  name: 'ToolbarButtons',

  components: {
    CustomDatePicker,
    Button,
    Menu,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    buttons: {
      type: Array as PropType<ToolbarItemsModel[]>,
      required: false,
      default: () => [],
    },

    noShrink: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  setup(props: { buttons: ToolbarItemsModel[] }) {
    const menuRefs: Ref<any []> = ref([]);

    const toggleMenu = (event: Event, index: string) => {
      if (menuRefs.value) {
        menuRefs.value[index as keyof object].toggle(event);
      }
    };

    const menuButtons = computed(() => props.buttons.filter((button: ToolbarItemsModel) => !button.hide));

    return {
      menuRefs,
      menuButtons,
      toggleMenu,
    };
  },

});
