
import {
  defineComponent, PropType, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';

import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import {
  BooleanMap, FieldConfig, ParserFieldObject, Property, SpacesFieldOption,
} from '@/types';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'Spaces',
  components: {
    ToggleDisplaySettings,
    Button,
    BaseInputNumber,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const SIGN = 'px';
    const isLocked: Ref<BooleanMap> = ref({});
    const baseProperty: Ref<string> = ref(props.configs.options.property);
    const properties: Ref<Array<Property>> = ref(props.parserValues.properties);

    const buildInputList = (): SpacesFieldOption[] => Object.entries(props.configs.options).filter((data) => data[0] !== 'property')
      .map((data, index: number) => {
        const currentPropertyName = baseProperty.value === 'border-radius' ? `border-${data[0]}-radius` : `${baseProperty.value}-${data[0]}`;
        const parserValue = props.parserValues.properties.filter((property) => property.name === currentPropertyName)[0].value?.replace(new RegExp(SIGN, 'g'), '');
        const { defaultValue, title } = data[1];
        const value = !Number.isNaN(Number(parserValue)) ? Number(parserValue) : Number(defaultValue);
        if (index % 2 === 0 && Object.entries(props.configs.options).length > 1) isLocked.value[index] = true;
        return {
          title: t(title),
          property: currentPropertyName,
          defaultValue,
          value,
        };
      });
    const inputList: Ref<SpacesFieldOption[]> = ref(buildInputList());

    const handleInput = (input: SpacesFieldOption, index: number) => {
      const { value, property, defaultValue } = input;
      const newValue: number = value === null ? Number(defaultValue) : value;
      if (isLocked.value[index] || isLocked.value[index - 1]) {
        const startedIndex = isLocked.value[index] ? index : index - 1;
        inputList.value[startedIndex].value = newValue;
        inputList.value[startedIndex + 1].value = newValue;

        const updatedProperties = properties.value
          .filter((prop: Property) => (prop.name === inputList.value[startedIndex].property || prop.name === inputList.value[startedIndex + 1].property))
          .map((prop: Property) => {
            const updatedProperty = prop;
            updatedProperty.value = `${newValue}${SIGN}`;
            return updatedProperty;
          });
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: updatedProperties,
        });
      } else {
        const updatedProperty: Property = properties.value.filter((prop: Property) => prop.name === property)[0];
        updatedProperty.value = `${newValue}${SIGN}`;
        context.emit('on-change-properties', {
          selector: props.parserValues.selector,
          properties: [updatedProperty],
        });
      }
    };

    const changeLockedState = (index: number) => {
      isLocked.value[index] = !isLocked.value[index];

      if (isLocked.value[index]) {
        // Déterminer l'index de l'élément pair à verrouiller/déverrouiller
        const relatedIndex = index % 2 === 0 ? index : index - 1;
        const item = inputList.value[relatedIndex];
        // Simuler le changement d'entrée
        handleInput(item, relatedIndex);
      }
    };

    return {
      t,
      isLocked,
      inputList,
      SIGN,
      displayProperties,
      changeLockedState,
      handleInput,
    };
  },
});
