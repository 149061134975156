import { createClient, gql } from '@urql/core';
// eslint-disable-next-line import/no-cycle
import { SpmTableFilter, SpmTableSort } from '@/types';
// eslint-disable-next-line import/no-cycle
import {
  ShopsConfiguration,
  ShopsConfigurationInput,
} from '@/types/generated-types/graphql';
import { TypedDocumentNode } from '@urql/vue';
import Gleap from 'gleap';

const clientUrl = '/api/graphql';

const client = createClient({
  url: clientUrl,
  requestPolicy: 'cache-and-network',
});

export interface ListSettings {
  limit: number;
  offset: number;
  order: SpmTableSort[];
  filter: SpmTableFilter[];
}

export interface ListParameters {
  name: string;
  settings: ListSettings;
  fields: Array<string> | Record<string, any>;
}

export interface CustomParams<T> {
  name: string;
  input: T;
  type: string;
}

export interface InsertParams<T> {
  name: string;
  input: Array<T>;
  type: string;
}

export interface UpdateParams<T> {
  name: string;
  input: Array<T>;
  type: string;
}

export interface SaveParams<T> {
  name: string;
  input: ShopsConfigurationInput;
  type: string;
}

export interface InsertInput<T> {
  m: {
    input: Array<T>;
  };
}

export interface CustomInput<T> {
  m: {
    input: T;
  };
}

export interface UpdateInput<T> {
  m: Record<string, any>;
}

export interface SaveResult<T> {
  id: number;
  status: boolean;
  messages: Array<Message>;
  err: string;
}

interface Message {
  message: string;
}

export interface ListResult<T> {
  items: Array<T>;
  total: number;
  err: string;
}

interface ListResponse<T> {
  items: Array<T>;
  total: number;
}

export interface SaveInput{
  m: {
    id_shop: number;
    configs: ShopsConfiguration[];
  };
}

export interface KeyValueRecord {
  key: string;
  lang: string;
  value: string;
}

function getFieldsNames(fields: Record<string, any>): string {
  let returnString = '';

  Object.keys(fields).forEach((key: string) => {
    if (fields[key]) {
      // Key has subkeys
      returnString += ` ${key} { ${getFieldsNames(fields[key])} }`;
    } else {
      returnString += ` ${key}`;
    }
  });

  return returnString;
}

/**
 * Fetch multiple entity instances from the graphql endpoint based on provided settings.
 * Usage:
 *  const { items, err } = List<MarketingWorkflows>({
 *    name: 'MarketingWorkflows',
 *    settings: {
 *      limit: 10,
 *      offset: 20,
 *      order: [
 *        { field: 'fieldA', type: 'ASC'},
 *        { field: 'fieldB', type: 'DESC'},
 *      ],
 *      filter: [
 *        { field: 'id_shop', value: 1293, operator: 'EQUALS' }
 *      ]
 *    }
 *  })
 * @param name
 * @param settings
 * @param fields
 * @constructor
 */
export async function List<T>({ name, settings, fields }: ListParameters): Promise<ListResult<T>> {
  const fname = `${name}List`;
  const fieldsName = Array.isArray(fields) ? fields.reduce((acc: string, current: string) => `${acc} ${current}`) : getFieldsNames(fields);
  const query = gql<T, ListSettings>`
    query ($limit: Int!, $offset: Int!, $order: [OrderParameters], $filter: [FilterParameters]) {
      ${fname} (params: {limit: $limit, offset: $offset, order: $order, filter: $filter}) {
        total
        items {
          ${fieldsName}
        }
      }
    }
  `;

  const result = await client.query(query, { ...settings }).toPromise();
  const data: {
    [key: string]: ListResponse<T>;
  } = result.data ?? {};
  const res: ListResponse<T> = data[fname] ?? {
    items: [],
    total: 0,
  };
  const { items, total } = res;
  const err = result.error?.message ?? '';

  if (err !== '') {
    Gleap.sendSilentCrashReport(
      `Problème détecté avec la requête List ${fname}`,
      'MEDIUM',
    );
  }

  return { items, total, err };
}

export enum KeyType {
  Integer = 'Int',
  String = 'String'
}

export interface GetParameters {
  name: string;
  id: number | string;
  keyName: string;
  keyType?: KeyType;
  fields: Array<string> | Record<string, any>;
}

export interface GetResult<T> {
  item: T | null;
  err: string;
}

export interface RequestParameters {
  name: string;
  query: string;
  variables: object;
}

export interface RequestResult<T> {
  data: T | null;
  err: string;
}

/**
 * Fetch a single entity instance by id from the graphql endpoint
 * Usage example:
 * const { item, err } = await Get<MarketingWorkflows>({
 *  name: 'MarketingWorkflows',
 *  id: 1,
 *  keyName: 'id_marketing_workflow',
 *  fields: ['name_marketing_workflow', 'data_marketing_workflow']
 * });
 * Warning: Do not display the error directly. It may be a technical message more suitable for console.error
 * If necessary use a translated user-friendly message.
 * @param name object to load, e.g: 'MarketingWorkflows'
 * @param id object id
 * @param keyName primary key of object, e.g: id_marketing_workflow
 * @param fields field names to load, e.g ['name_marketing_workflow', 'data_marketing_workflow']
 * @param keyType type of primary used by the object, Int or String, Int by default
 * @constructor
 */
export async function Get<T>(
  {
    name,
    id,
    keyName,
    fields,
    keyType = KeyType.Integer,
  }: GetParameters,
): Promise<GetResult<T>> {
  const fname = `${name}Get`;
  const fieldsName = Array.isArray(fields) ? fields.reduce((acc: string, current: string) => `${acc} ${current}`) : getFieldsNames(fields);
  const query = gql<T, { id: number | string }>`
  query ($id: ${keyType}!) {
    ${fname}(${keyName}: $id) {
      ${fieldsName}
    }
  }
  `;

  const result = await client.query(query, { id }).toPromise();
  const data: {
    [key: string]: T;
  } = result.data ?? {};
  const item: T | null = data[fname] ?? null;
  const err = result.error?.message ?? '';

  if (err !== '') {
    Gleap.sendSilentCrashReport(
      `Problème détecté avec la requête Get ${fname}`,
      'MEDIUM',
    );
  }

  return { item, err };
}

/**
 * Execute a basic graphql request
 * Usage example:
 * const { data, err } = await Request<string>({
 *  name: 'TemplatesShopWebsiteContent',
 *  query: 'query ( $url: String) { TemplatesShopWebsiteContent(shopUrl: $url) }',
 *  variables: { url: 'http://www.url.com' },
 * });
 * Warning: Do not display the error directly. It may be a technical message more suitable for console.error
 * If necessary use a translated user-friendly message.
 * @param name object to load, e.g: 'TemplatesShopWebsiteContent'
 * @param query the query to execute
 * @param variables the variables to send to request
 * @constructor
 */
export async function Request<T>(
  {
    name,
    query,
    variables,
  }: RequestParameters,
): Promise<RequestResult<T>> {
  const request = gql<T>`${query}`;

  const result = await client.query(request, variables).toPromise();
  const response: {
    [key: string]: T;
  } = result.data ?? {};
  const data: T | null = response[name] ?? null;
  const err = result.error?.message ?? '';

  if (err !== '') {
    Gleap.sendSilentCrashReport(
      `Problème détecté avec une requête Request ${name}`,
      'MEDIUM',
    );
  }

  return { data, err };
}

async function processMutation<T>(mutation: TypedDocumentNode, input: object, fname: string) {
  const result = await client.mutation(mutation, input).toPromise();
  const data: {
    [key: string]: SaveResult<T>;
  } = result.data ?? {};

  const res: SaveResult<T> = data[fname] ?? {
    id: 0,
    status: 'error',
    messages: [],
  };

  const { id, status, messages } = res;
  const err = result.error?.message ?? '';

  if (err !== '') {
    Gleap.sendSilentCrashReport(
      `Problème détecté avec une requête de mutation ${fname}`,
      'HIGH',
    );
  }

  return {
    id, status, messages, err,
  };
}

/**
 * Use navigator.sendBeacon function to execute a POST query instead of using GraphQL.
 * Useful if we need to send a query without being interrupted by page reload
 * @param name
 * @param mutation
 * @param m
 */
function sendBeacon(name: string, mutation: string, m: Record<string, any>): boolean {
  const beaconData: Record<string, any> = {
    operationName: name,
    query: mutation,
    variables: {
      m,
    },
  };

  // Create FromData object
  const fd = new FormData();
  Object.keys(beaconData).forEach((key) => {
    let value = beaconData[key];

    // If value is an object, we need to transform into string
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }

    // Store key/value pair in FormData
    fd.append(key, value);
  });

  // Send beacon
  return navigator.sendBeacon(clientUrl, fd);
}

/*
* Update single record
* Usage:
*  const { items, err } = Update<MarketingWorkflows>({
  *    name: 'MarketingWorkflows',
  *    settings: {
    *      limit: 10,
    *      offset: 20,
    *      order: [
      *        { field: 'fieldA', type: 'ASC'},
  *        { field: 'fieldB', type: 'DESC'},
  *      ],
*      filter: [
*        { field: 'id_shop', value: 1293, operator: 'EQUALS' }
*      ]
*    }
*  })
* @param name
* @param input
* @constructor
*/

export async function Update<T>({ name, input, type }: UpdateParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = `Update${name}`;
  const m = { input };
  const mutationAsString = `
    mutation ${fname}($m: ${type}) {
      ${fname} (args: $m) {
        id,
        status
        messages {
          message
        }
      }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, UpdateInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, fname);
}

export async function Upsert<T>({ name, input, type }: CustomParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = `Upsert${name}`;
  const m = input;
  const mutationAsString = `
    mutation ${fname}($m: ${type}) {
      ${fname} (args: $m) {
        id,
        status
        messages {
          message
        }
      }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, UpdateInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, fname);
}

export async function UpdateMultiple<T>({ name, input, type }: UpdateParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = `Update${name}`;
  const m = { input };
  const mutationAsString = `
    mutation ${fname}($m: ${type}) {
      ${fname} (args: $m) {
        id,
        status
        messages {
          message
        }
      }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, UpdateInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, fname);
}

export async function Insert<T>({ name, input, type }: InsertParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = `Insert${name}`;
  const m = { input };
  const mutationAsString = `
    mutation ${fname}($m: ${type}) {
      ${fname} (args: $m) {
      id,
      status
      messages {
        message
      }
    }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, InsertInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, fname);
}

/*
* Update single record
* Usage:
*  const { items, err } = Update<MarketingWorkflows>({
  *    name: 'MarketingWorkflows',
  *    settings: {
    *      limit: 10,
    *      offset: 20,
    *      order: [
      *        { field: 'fieldA', type: 'ASC'},
  *        { field: 'fieldB', type: 'DESC'},
  *      ],
*      filter: [
*        { field: 'id_shop', value: 1293, operator: 'EQUALS' }
*      ]
*    }
*  })
* @param name
* @param input
* @constructor
*/

export async function SaveKeyPair<T>({ name, input, type }: SaveParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = `Save${name}`;
  const m = { input };
  const mutationAsString = `
    mutation ${fname}($m: ${type}) {
      ${fname} (args: $m) {
      id,
      status
      messages {
        message
      }
    }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, SaveInput>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, fname);
}

export async function Delete<T>({ name, input, type }: UpdateParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = `Delete${name}`;
  const m = { input };
  const mutationAsString = `
    mutation ${fname}($m: ${type}) {
      ${fname} (args: $m) {
        id,
        status
        messages {
          message
        }
      }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, UpdateInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, fname);
}

export async function CustomMutation<T>({ name, input, type }: CustomParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = name;
  const m = { input };
  const mutationAsString = `
    mutation ${name}($m: ${type}) {
      ${name} (args: $m) {
      id,
      status
      messages {
        message
      }
    }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, CustomInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, name);
}

export async function CustomSingleMutation<T>({ name, input, type }: CustomParams<T>, useBeacon = false): Promise<SaveResult<T>> {
  const fname = name;
  const m = { input };
  const mutationAsString = `
    mutation ${name}($m: ${type}) {
      ${name} (args: $m) {
      id,
      status
      messages {
        message
      }
    }
    }
  `;

  if (useBeacon && typeof navigator.sendBeacon === 'function') {
    sendBeacon(fname, mutationAsString, m);
    return {
      id: 0,
      status: true,
      messages: [],
      err: '',
    };
  }

  const mutation = gql<T, CustomInput<T>>`${mutationAsString}`;
  return processMutation<T>(mutation, { m }, name);
}
