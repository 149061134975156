import {
  dateNumberLogicalOperators,
  dateStringLogicalOperators,
  selectionBasedLogicalOperators, numberLogicalOperators,
  stringLogicalOperators, anniversaryNumberLogicalOperators,
} from '@/composables/segmentations/logicalOperators';
import getOrderPropertiesSegments from '@/composables/segmentations/themes/common-segments/orderPropertiesSegment';
import {
  getProductCategoriesPropertiesSegments,
  getProductPropertiesSegments,
} from '@/composables/segmentations/themes/common-segments/productPropertiesSegment';
import {
  fetchCountries,
  fetchGroups,
  fetchLists,
  fetchShopsLang,
  fetchTags,
} from '@/composables/segmentations/helpers/fetchData';

/**
 * Get segments definition for attributes / can do / has done
 */
const getAttributesSegments = async (t: Function) => {
  const tags = await fetchTags();
  const lists = await fetchLists();
  const groups = await fetchGroups();
  const shopsLang = await fetchShopsLang();
  const countries = await fetchCountries(t);

  return {
    id: 'attributes',
    list: [
      {
        id: 'first_name',
        label: 'segmentations.attributes.list.first_name',
        logical_operators: stringLogicalOperators('first_name'),
      },
      {
        id: 'last_name',
        label: 'segmentations.attributes.list.last_name',
        logical_operators: stringLogicalOperators('last_name'),
      },
      {
        id: 'email',
        label: 'segmentations.attributes.list.email',
        logical_operators: stringLogicalOperators('email'),
      },
      {
        id: 'gender',
        label: 'segmentations.attributes.list.gender',
        logical_operators: selectionBasedLogicalOperators('gender', [{
          label: 'gender.unknown',
          value: 0,
        }, {
          label: 'gender.male',
          value: 1,
        },
        {
          label: 'gender.female',
          value: 2,
        }]),
      },
      {
        id: 'opt_in',
        label: 'segmentations.attributes.list.opt_in.label',
        logical_operators: selectionBasedLogicalOperators('opt_in', [
          { label: 'segmentations.attributes.list.opt_in.none', value: 0 },
          { label: 'segmentations.attributes.list.opt_in.simple', value: 1 },
          { label: 'segmentations.attributes.list.opt_in.double', value: 2 },
        ]),
      },
      {
        id: 'lang',
        label: 'segmentations.attributes.list.lang',
        logical_operators: selectionBasedLogicalOperators('lang', shopsLang),
      },
      {
        id: 'country',
        label: 'segmentations.attributes.list.country',
        logical_operators: selectionBasedLogicalOperators('country', countries),
      },
      {
        id: 'contact_address',
        label: 'segmentations.attributes.list.contact_address.label',
        sub_properties: [
          {
            id: 'address1',
            label: 'segmentations.attributes.list.contact_address.list.address1',
            logical_operators: stringLogicalOperators('address1'),
          },
          {
            id: 'address2',
            label: 'segmentations.attributes.list.contact_address.list.address2',
            logical_operators: stringLogicalOperators('address2'),
          },
          {
            id: 'postcode',
            label: 'segmentations.attributes.list.contact_address.list.postcode',
            logical_operators: [
              {
                type: 'string',
                data: stringLogicalOperators('postcode'),
              },
              {
                type: 'number',
                data: numberLogicalOperators('postcode'),
              },
            ],
          },
          {
            id: 'region',
            label: 'segmentations.attributes.list.contact_address.list.region',
            logical_operators: stringLogicalOperators('region'),
          },
          {
            id: 'city',
            label: 'segmentations.attributes.list.contact_address.list.city',
            logical_operators: stringLogicalOperators('city'),
          },
          {
            id: 'country',
            label: 'segmentations.attributes.list.contact_address.list.country',
            logical_operators: stringLogicalOperators('country'),
          },
          {
            id: 'first_name',
            label: 'segmentations.attributes.list.contact_address.list.first_name',
            logical_operators: stringLogicalOperators('first_name'),
          },
          {
            id: 'last_name',
            label: 'segmentations.attributes.list.contact_address.list.last_name',
            logical_operators: stringLogicalOperators('last_name'),
          },
          {
            id: 'company',
            label: 'segmentations.attributes.list.contact_address.list.company',
            logical_operators: stringLogicalOperators('company'),
          },
          {
            id: 'date',
            label: 'segmentations.attributes.list.contact_address.list.date',
            logical_operators: [
              {
                type: 'dateString',
                data: dateStringLogicalOperators('date'),
              },
              {
                type: 'dateNumber',
                data: dateNumberLogicalOperators('date'),
              },
            ],
          },
        ],
      },
      {
        id: 'birthday',
        label: 'segmentations.attributes.list.birthday',
        logical_operators: [
          {
            type: 'dateString',
            data: dateStringLogicalOperators('birthday'),
          },
          {
            type: 'dateNumber',
            data: dateNumberLogicalOperators('birthday'),
          },
          {
            type: 'anniversaryNumber',
            data: anniversaryNumberLogicalOperators('birthday'),
          },
        ],
      },
      {
        id: 'date',
        label: 'segmentations.attributes.list.date_creation',
        logical_operators: [
          {
            type: 'dateString',
            data: dateStringLogicalOperators('date'),
          },
          {
            type: 'dateNumber',
            data: dateNumberLogicalOperators('date'),
          },
          {
            type: 'anniversaryNumber',
            data: anniversaryNumberLogicalOperators('date'),
          },
        ],
      },
      {
        id: 'has_a_tag',
        label: 'segmentations.attributes.list.has_a_tag',
        logical_operators: selectionBasedLogicalOperators('has_a_tag', tags),
      },
      {
        id: 'present_in_lists',
        label: 'segmentations.attributes.list.present_in_lists',
        logical_operators: selectionBasedLogicalOperators('present_in_lists', lists),
      },
      {
        id: 'present_in_groups',
        label: 'segmentations.attributes.list.present_in_groups',
        logical_operators: selectionBasedLogicalOperators('present_in_groups', groups),
      },
      {
        id: 'last_view',
        label: 'segmentations.attributes.list.last_view',
        logical_operators: [
          {
            type: 'dateString',
            data: dateStringLogicalOperators('last_view'),
          },
          {
            type: 'dateNumber',
            data: dateNumberLogicalOperators('last_view'),
          },
        ],
      },
      {
        id: 'last_click',
        label: 'segmentations.attributes.list.last_click',
        logical_operators: [
          {
            type: 'dateString',
            data: dateStringLogicalOperators('last_click'),
          },
          {
            type: 'dateNumber',
            data: dateNumberLogicalOperators('last_click'),
          },
        ],
      },
      {
        id: 'last_order',
        label: 'segmentations.attributes.list.last_order',
        logical_operators: [
          {
            type: 'dateString',
            data: dateStringLogicalOperators('last_order'),
          },
          {
            type: 'dateNumber',
            data: dateNumberLogicalOperators('last_order'),
          },
        ],
      },
    ],
  };
};

const getCanDoSegments = async () => ({
  id: 'canDo',
  list: [
    {
      id: 'receive_sms',
      label: 'segmentations.canDo.list.receive_sms',
      options: [
        { label: 'yes', value: true },
        { label: 'no', value: false },
      ],
      data: true,
    },
    {
      id: 'receive_notification_push',
      label: 'segmentations.canDo.list.receive_notification_push',
      options: [
        { label: 'yes', value: true },
        { label: 'no', value: false },
      ],
      data: true,
    },
  ],
});

const getHasDoneSegments = async () => {
  const orderProperties = await getOrderPropertiesSegments();
  const productProperties = await getProductPropertiesSegments();
  const productsCategoriesProperties = await getProductCategoriesPropertiesSegments();

  return {
    id: 'hasDone',
    list: [
      {
        id: 'created_account',
        label: 'segmentations.hasDone.list.created_account',
        options: [
          { label: 'yes', value: true },
          { label: 'no', value: false },
        ],
        data: true,
      },
      // {
      //   id: 'opened_an_email_bulk',
      //   label: 'segmentations.hasDone.list.opened_an_email_bulk.label',
      //   sub_properties: [
      //     {
      //       id: 'date_open',
      //       label: 'segmentations.hasDone.list.opened_an_email_bulk.list.date_open',
      //       logical_operators: [
      //         {
      //           type: 'dateString',
      //           data: dateStringLogicalOperators('date_open'),
      //         },
      //         {
      //           type: 'dateNumber',
      //           data: dateNumberLogicalOperators('date_open'),
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        id: 'opened_an_email_automation',
        label: 'segmentations.hasDone.list.opened_an_email_automation.label',
        sub_properties: [
          {
            id: 'date_open',
            label: 'segmentations.hasDone.list.opened_an_email_automation.list.date_open',
            logical_operators: [
              {
                type: 'dateString',
                data: dateStringLogicalOperators('date_open'),
              },
              {
                type: 'dateNumber',
                data: dateNumberLogicalOperators('date_open'),
              },
            ],
          },
        ],
      },
      // {
      //   id: 'clicked_in_email_bulk',
      //   label: 'segmentations.hasDone.list.clicked_in_email_bulk.label',
      //   sub_properties: [
      //     {
      //       id: 'date_click_email_bulk',
      //       label: 'segmentations.hasDone.list.clicked_in_email_bulk.list.date_click',
      //       logical_operators: [
      //         {
      //           type: 'dateString',
      //           data: dateStringLogicalOperators('date_click'),
      //         },
      //         {
      //           type: 'dateNumber',
      //           data: dateNumberLogicalOperators('date_click'),
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        id: 'clicked_in_email_automation',
        label: 'segmentations.hasDone.list.clicked_in_email_automation.label',
        sub_properties: [
          {
            id: 'date_click',
            label: 'segmentations.hasDone.list.clicked_in_email_automation.list.date_click',
            logical_operators: [
              {
                type: 'dateString',
                data: dateStringLogicalOperators('date_click'),
              },
              {
                type: 'dateNumber',
                data: dateNumberLogicalOperators('date_click'),
              },
            ],
          },
        ],
      },
      {
        id: 'placed_order',
        label: 'segmentations.hasDone.list.placed_order.label',
        sub_properties: [
          {
            id: 'orders_attributes',
            label: 'segmentations.hasDone.list.placed_order.list.orders_attributes.label',
            sub_properties: orderProperties,
          },
          {
            id: 'id_shop_order',
            label: 'segmentations.hasDone.list.placed_order.list.id_order_count',
            logical_operators: numberLogicalOperators('id_shop_order'),
            aggregate: 'count',
          },
          {
            id: 'amount',
            label: 'segmentations.hasDone.list.placed_order.list.amount_sum',
            logical_operators: numberLogicalOperators('amount'),
            aggregate: 'sum',
            hasCurrency: true,
          },
        ],
      },
      {
        id: 'visited_products',
        label: 'segmentations.hasDone.list.visited_products.label',
        sub_properties: [
          {
            id: 'product_visited_attributes',
            label: 'segmentations.hasDone.list.visited_products.list.product_visited_attributes.label',
            sub_properties: productProperties,
          },
          {
            id: 'shops_visitors_pages_id',
            label: 'segmentations.hasDone.list.visited_products.list.visited_products_count',
            logical_operators: numberLogicalOperators('shops_visitors_pages_id'),
            aggregate: 'count',
          },
          {
            id: 'products_visited_date',
            label: 'segmentations.hasDone.list.visited_products.list.products_visited_date',
            logical_operators: [
              {
                type: 'dateString',
                data: dateStringLogicalOperators('products_visited_date'),
              },
              {
                type: 'dateNumber',
                data: dateNumberLogicalOperators('products_visited_date'),
              },
            ],
          },
        ],
      },
      {
        id: 'visited_categories',
        label: 'segmentations.hasDone.list.visited_categories.label',
        sub_properties: [
          {
            id: 'category_visited_attributes',
            label: 'segmentations.hasDone.list.visited_categories.list.category_visited_attributes.label',
            sub_properties: productsCategoriesProperties,
          },
          {
            id: 'shops_visitors_pages_id',
            label: 'segmentations.hasDone.list.visited_categories.list.visited_categories_count',
            logical_operators: numberLogicalOperators('shops_visitors_pages_id'),
            aggregate: 'count',
          },
          {
            id: 'categories_visited_date',
            label: 'segmentations.hasDone.list.visited_categories.list.categories_visited_date',
            logical_operators: [
              {
                type: 'dateString',
                data: dateStringLogicalOperators('categories_visited_date'),
              },
              {
                type: 'dateNumber',
                data: dateNumberLogicalOperators('categories_visited_date'),
              },
            ],
          },
        ],
      },
      {
        id: 'visited_urls',
        label: 'segmentations.hasDone.list.visited_urls.label',
        sub_properties: [
          {
            id: 'url_visited',
            label: 'segmentations.hasDone.list.visited_urls.list.url_visited.label',
            logical_operators: stringLogicalOperators('url_visited'),
          },
          {
            id: 'shops_visitors_pages_id',
            label: 'segmentations.hasDone.list.visited_urls.list.visited_url_count',
            logical_operators: numberLogicalOperators('shops_visitors_pages_id'),
            aggregate: 'count',
          },
          {
            id: 'url_visited_date',
            label: 'segmentations.hasDone.list.visited_urls.list.url_visited_date',
            logical_operators: [
              {
                type: 'dateString',
                data: dateStringLogicalOperators('url_visited_date'),
              },
              {
                type: 'dateNumber',
                data: dateNumberLogicalOperators('url_visited_date'),
              },
            ],
          },
        ],
      },
    ],
  };
};

/**
 * Get theme contacts definition
 */
const getThemeContactsDefinition = async (t: Function) => {
  try {
    const [attributes, canDo, hasDone] = await Promise.all([
      getAttributesSegments(t),
      getCanDoSegments(),
      getHasDoneSegments(),
    ]);
    return [attributes, canDo, hasDone];
  } catch (error) {
    console.error('An error occurred while fetching segments: re', error);
  }
  return [];
};

export default getThemeContactsDefinition;
