
import {
  computed,
  ComputedRef,
  defineComponent,
  isProxy,
  Ref,
  ref,
  toRaw,
  watch,
  onBeforeMount,
  PropType,
  reactive,
  onMounted,
} from 'vue';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Chips from 'primevue/chips';
import Textarea from 'primevue/textarea';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import MultiSelect from 'primevue/multiselect';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import Image from 'primevue/image';
import Card from 'primevue/card';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import Message from 'primevue/message';
import Tooltip from 'primevue/tooltip';

import { useI18n } from 'vue-i18n';

import {
  GetReadableShopDefaultLang, hideAIModal, MakeSPMAIRequest, ShopimindAIState as state, isStyleConfigured,
} from '@/composables/services/ShopimindAI';
import { getShopDefaultLang, UserState } from '@/composables/User';
import { getBase64FromUrl, getTemplateIframeDocument } from '@/components/template-builder/utils/helpers';
import VariablesList from '@/components/template-builder/utils/variables-list';
import { AI_IMAGE_MAX_HEIGHT, AI_IMAGE_MAX_WIDTH } from '@/components/template-builder/utils/constants';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import Loader from '@/components/layout/Loader.vue';
import { showToastSuccess, showToastError } from '@/helpers';
import { File } from '@/types';
import { uploadFileToMedia, createNewFileInMedia } from '@/composables/configs/configuration';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { MenuItem } from 'primevue/menuitem';
import sha1 from 'sha1';
import { SHOPIMIND_SHA1_SALT } from '@/configs';

import { useStore } from '@/store';
import { getAIServiceParameter } from '@/composables/shop/MyShopParameters';
import { sanitizeTranslationMarkup, setTranslationInState } from '@/components/template-builder/utils/translate';
import moment from 'moment/moment';
import ChooseDirectoryModal from '@/components/file-manager/ChooseDirectoryModal.vue';
import { addUrlParameter } from '@/helpers/helpers';

interface Field {
  name: string;
  component: string;
}

interface AIInterfaceParamsType {
  AIContentType: number;
  AIContextContent: string;
  AIContextContentText: string;
  AIContextContentHTML: string;
  AIApplyContentParams: { selector: string; type: string; target: string; attr: string; imageWidth: number; imageHeight: number; idTemplate: number};
}

interface AIContentType {
  name: string;
  tooltip: string;
  icon: string[];
  api_route: string;
  hidden_fields: Record<string, any>;
  fields: Record<string, any>;
  result_config: Record<string, any>;
}

export default defineComponent({
  name: 'AIInterface',

  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
    Button,
    Accordion,
    AccordionTab,
    Dropdown,
    Chips,
    Textarea,
    MultiSelect,
    InputNumber,
    AutoComplete,
    Image,
    Card,
    Skeleton,
    Tag,
    Message,
    Loader,
    ChooseDirectoryModal,
  },

  methods: { },

  props: {
    aiInterfaceParams: {
      type: Object as PropType<AIInterfaceParamsType>,
      required: false,
    },
  },

  directives: {
    tooltip: Tooltip,
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const store = useStore();

    const AIInterfaceParams: ComputedRef = computed(() => props.aiInterfaceParams ?? state.AIInterfaceParams);
    const currentAIContentType: ComputedRef = computed(() => AIInterfaceParams.value.AIContentType);
    const currentAIContextContent: ComputedRef = computed(() => AIInterfaceParams.value.AIContextContent);
    const currentAIContextContentText: ComputedRef = computed(() => AIInterfaceParams.value.AIContextContentText);
    const currentAIContextFullContentText: ComputedRef = computed(() => AIInterfaceParams.value.AIContextFullContentText);
    const currentAIContextContentHTML: ComputedRef = computed(() => AIInterfaceParams.value.AIContextContentHTML);

    const currentAIApplyContentParams: ComputedRef = computed(() => AIInterfaceParams.value.AIApplyContentParams);
    const currentAILabel = ref('');
    const AIgenerateButtonLabel = ref('');
    const AIResultInProgress = ref(false);
    const AIChooseContentInProgress = ref(false);
    const AIFormIsValid = ref(true);
    const active = ref(0);
    const valuePrompt = ref();
    const valueThematic = ref();
    const valueVariables = ref([]);
    const valueSimilarityLevel = ref(4);
    const valueImageWidth = ref(0);
    const valueTexteLength = ref(0);
    const valueScaleFactor = ref(1);
    const valueImageHeight = ref(0);
    const valueSize = ref();
    const itemsAIKeywordsContext = ref();
    const AIResult = ref({});
    const contentTypeIndex: Ref<number> = ref(0);
    let itemsAIKeywordsContextCache: string | any[] = [];
    let itemsAIKeywordsContextCacheInProgress = false;

    const isLoading = ref(false);

    const displayChooseDirectoryModal = ref(false);

    const serviceParameterIsCanceled = computed(() => store.getters['general/getServiceParameterIsCanceled']);

    const idShop: ComputedRef<number> = computed(() => (UserState.activeShop ? UserState.activeShop.id : 0));

    const hasCorrectOffer = ref(false);

    function getBestImageSize(originalWidth: any, originalHeight: any) {
      const maxWidth = AI_IMAGE_MAX_WIDTH;
      const maxHeight = AI_IMAGE_MAX_HEIGHT;

      let newWidth = originalWidth;
      let newHeight = originalHeight;

      // Vérification des dimensions et redimensionnement en maintenant les proportions
      if (newWidth > maxWidth || newHeight > maxHeight) {
        const widthRatio = maxWidth / newWidth;
        const heightRatio = maxHeight / newHeight;
        const bestRatio = Math.min(widthRatio, heightRatio);
        newWidth = Math.floor(newWidth * bestRatio);
        newHeight = Math.floor(newHeight * bestRatio);
      } else if (newWidth < maxWidth && newHeight < maxHeight) {
        const widthRatio = maxWidth / newWidth;
        const heightRatio = maxHeight / newHeight;
        const bestRatio = Math.min(widthRatio, heightRatio);
        newWidth = Math.floor(newWidth * bestRatio);
        newHeight = Math.floor(newHeight * bestRatio);

        // Vérifiez que les nouvelles dimensions n'excèdent pas les dimensions maximales
        if (newWidth > maxWidth) {
          const ratio = maxWidth / newWidth;
          newWidth = maxWidth;
          newHeight = Math.floor(newHeight * ratio);
        }
        if (newHeight > maxHeight) {
          const ratio = maxHeight / newHeight;
          newHeight = maxHeight;
          newWidth = Math.floor(newWidth * ratio);
        }
      }

      return { width: newWidth, height: newHeight };
    }

    hasCorrectOffer.value = true;
    const watchUserState = () => {
      if (UserState.activeOffer) {
        if (UserState.activeOffer.trialPlan) {
          if (UserState.activeOffer.trialPlanLimit) {
            hasCorrectOffer.value = moment().isBefore(moment(UserState.activeOffer.trialPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasCorrectOffer.value = true;
          }
        } else if (UserState.activeOffer.forcedPlan) {
          if (UserState.activeOffer.forcedPlanLimit) {
            hasCorrectOffer.value = moment().isBefore(moment(UserState.activeOffer.forcedPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasCorrectOffer.value = true;
          }
        } else if (UserState.activeOffer.currentPlan) {
          hasCorrectOffer.value = true;
        } else {
          hasCorrectOffer.value = false;
        }
      } else if (!UserState.activeOffer && UserState.hasOfferV3) {
        hasCorrectOffer.value = false;
      }
    };
    watchUserState();

    watch(() => UserState.activeOffer, () => {
      watchUserState();
    }, { deep: true });

    const updatePlan = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    // Listen image size calculation (to avoid await from FileManager.vue)
    watch(AIInterfaceParams, async () => {
      const originalWidth = AIInterfaceParams.value.AIApplyContentParams.imageWidth;
      const originalHeight = AIInterfaceParams.value.AIApplyContentParams.imageHeight;

      const newDimensions = getBestImageSize(originalWidth, originalHeight);

      valueImageWidth.value = newDimensions.width;
      valueImageHeight.value = newDimensions.height;
    });

    let globalKeywordsQuery = '';

    const aiVariablesTranslated = VariablesList.aiVariables.map((variable: any) => ({
      value: variable.value,
      label: t(variable.label),
    }));

    const similarityLevelOptions = [
      { label: t('templateBuilder.modals.AI.fields.label.similarity_level_options.level_4'), value: 4 },
      { label: t('templateBuilder.modals.AI.fields.label.similarity_level_options.level_3'), value: 3 },
      { label: t('templateBuilder.modals.AI.fields.label.similarity_level_options.level_2'), value: 2 },
      { label: t('templateBuilder.modals.AI.fields.label.similarity_level_options.level_1'), value: 1 },
    ];

    // Utility function to create common fields with translations
    function createField(field: any): any {
      return {
        ...field,
        label: t(`templateBuilder.modals.AI.fields.label.${field.name}`),
        tooltip: field.tooltip ? t(`templateBuilder.modals.AI.fields.label.${field.name}_tooltip`) : undefined,
      };
    }

    // Utility function to create common configuration for AI content types
    function createConfig(
      type: string,
      route: string,
      action: string,
      hiddenFields: any,
      fieldsConfig: any[],
      resultConfig: any,
      icon: string[],
    ): AIContentType {
      return {
        name: t(`templateBuilder.modals.AI.contentTypes.${type}.${action}`),
        tooltip: t(`templateBuilder.modals.AI.contentTypes.${type}.${action}Tooltip`),
        icon,
        api_route: route,
        hidden_fields: hiddenFields,
        fields: fieldsConfig.map(createField),
        result_config: resultConfig,
      };
    }

    const commonFields = {
      prompt: {
        name: 'prompt', col: 'col-12', component: 'Text', ref: valuePrompt, required: true, invalid: false,
      },

      prompt_special_instruct: {
        name: 'prompt_special_instruct', col: 'col-12', component: 'Text', ref: valuePrompt, tooltip: true,
      },

      thematic: {
        name: 'thematic', component: 'AutoComplete', ref: valueThematic, tooltip: true,
      },

      variables: {
        name: 'variables', component: 'Multiselect', options: aiVariablesTranslated, ref: valueVariables, limit: 5, tooltip: true,
      },

      width: {
        name: 'width', component: 'InputNumber', ref: valueImageWidth, min: 256, max: AI_IMAGE_MAX_WIDTH, suffix: 'px',
      },

      height: {
        name: 'height', component: 'InputNumber', ref: valueImageHeight, min: 256, max: AI_IMAGE_MAX_HEIGHT, suffix: 'px',
      },

      preview_image: {
        name: 'preview_image', col: 'col-12', component: 'PreviewImage', ref: currentAIContextContent,
      },

      similarity_level: {
        name: 'similarity_level', component: 'Dropdown', options: similarityLevelOptions, ref: valueSimilarityLevel, tooltip: true,
      },

      scale_factor: {
        name: 'scale_factor', component: 'InputNumber', ref: valueScaleFactor, min: 1, max: 3, tooltip: true,
      },
    };

    const resultConfigs = {
      text: {
        result_path: 'content', result_type: 'object', result_display_result_component: 'display1',
      },

      textWithMerge: {
        result_path: 'content', result_type: 'object', result_display_result_component: 'display1', result_add_mode: 'merge',
      },

      image: {
        result_path: 'content', result_type: 'object', result_display_result_component: 'images', result_add_mode: 'merge',
      },

      textWithExplanation: { result_path: 'content', result_type: 'object', result_display_result_component: 'text_with_explanation' },
    };

    const availableAIContentTypes: AIContentType[][] = [
      [
        // eslint-disable-next-line max-len
        createConfig('texts', '/text-generator/content-adapt-editorial-style/', 'modelAdapt', { content: currentAIContextContentHTML }, [commonFields.prompt_special_instruct], resultConfigs.text, ['fa-light', 'fa-head-side-brain']),
        createConfig('texts', '/text-generator/content-generate/', 'modelCreate', { language: GetReadableShopDefaultLang() }, [
          commonFields.prompt,
          commonFields.thematic,
          commonFields.variables,
          {
            name: 'size', component: 'InputNumber', ref: valueTexteLength, min: 100, max: 3000, suffix: ' caractères',
          },
        ], resultConfigs.textWithMerge, ['fa-light', 'fa-wand-magic-sparkles']),
        // eslint-disable-next-line max-len
        createConfig('texts', '/text-generator/content-correction/', 'modelCorrect', { content: currentAIContextContentHTML, language: GetReadableShopDefaultLang() }, [], resultConfigs.textWithExplanation, ['fa-light', 'fa-spell-check']),
      ],
      [
        // eslint-disable-next-line max-len
        createConfig('subjects', '/text-generator/get-email-subjects-inspiration/', 'modelCreate', { content: currentAIContextFullContentText, language: GetReadableShopDefaultLang() }, [commonFields.thematic, { ...commonFields.variables, limit: 2 }], resultConfigs.text, ['fa-light', 'fa-wand-magic-sparkles']),
        // eslint-disable-next-line max-len
        createConfig('subjects', '/text-generator/content-correction/', 'modelCorrect', { content: currentAIContextContentText, language: GetReadableShopDefaultLang() }, [], resultConfigs.textWithExplanation, ['fa-light', 'fa-spell-check']),
      ],
      [
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/model1/', 'modelCreate', { language_iso: getShopDefaultLang() }, [commonFields.prompt, commonFields.thematic, commonFields.width, commonFields.height], resultConfigs.image, ['fa-light', 'fa-wand-magic-sparkles']),
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/create-image-from-another/', 'modelReplace', { image_url: currentAIContextContent, language_iso: getShopDefaultLang() }, [commonFields.thematic, commonFields.width, commonFields.height, commonFields.preview_image], resultConfigs.image, ['fa-light', 'fa-wand-magic']),
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/image-reproduction-enhancement/', 'modelReproductionEnhancement', { image_url: currentAIContextContent }, [commonFields.similarity_level, commonFields.scale_factor, commonFields.preview_image], resultConfigs.image, ['fa-light', 'fa-layer-minus']),
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/background-remover/', 'modelRemoveBackground', { image_url: currentAIContextContent }, [], resultConfigs.image, ['fa-light', 'fa-layer-minus']),
      ],
      [
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/model1/', 'modelCreate', { language_iso: getShopDefaultLang() }, [commonFields.prompt, commonFields.thematic, commonFields.width, commonFields.height], resultConfigs.image, ['fa-light', 'fa-wand-magic-sparkles']),
      ],
      [
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/create-image-from-another/', 'modelReplace', { image_url: currentAIContextContent, language_iso: getShopDefaultLang() }, [commonFields.thematic, commonFields.width, commonFields.height, commonFields.preview_image], resultConfigs.image, ['fa-light', 'fa-wand-magic']),
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/image-reproduction-enhancement/', 'modelReproductionEnhancement', { image_url: currentAIContextContent }, [commonFields.similarity_level, commonFields.scale_factor, commonFields.preview_image], resultConfigs.image, ['fa-light', 'fa-layer-minus']),
        // eslint-disable-next-line max-len
        createConfig('images', '/image-generator/background-remover/', 'modelRemoveBackground', { image_url: currentAIContextContent }, [], resultConfigs.image, ['fa-light', 'fa-layer-minus']),
      ],
    ];

    const AIContentType: ComputedRef<AIContentType[]> = computed(() => availableAIContentTypes[currentAIContentType.value - 1]);

    const handleClose = () => {
      hideAIModal();
    };
    const getValueByPath = (object: object, path: string) => path.split('.').reduce((acc: any, part) => acc && acc[part], object);
    const handleValidation = async () => {
      // eslint-disable-next-line no-shadow
      const currentAIContentType = AIContentType.value[contentTypeIndex.value];

      // récupérer config form et hidden file et merger dans object data pour envoyer dans la requete()
      const SPMAIdata: { [key: string]: any } = {};

      const hidden_fields_keys = Object.keys(currentAIContentType.hidden_fields);
      hidden_fields_keys.forEach((key) => {
        SPMAIdata[key] = (typeof currentAIContentType.hidden_fields[key] === 'string' ? currentAIContentType.hidden_fields[key] : currentAIContentType.hidden_fields[key].value);
      });
      AIFormIsValid.value = true;
      currentAIContentType.fields.forEach((field: any, index: any) => {
        if (isProxy(field.ref.value)) {
          SPMAIdata[field.name as string] = toRaw(field.ref.value);
        } else {
          SPMAIdata[field.name as string] = field.ref.value;
        }
        if (field.required && !SPMAIdata[field.name]) {
          AIFormIsValid.value = false;
          // eslint-disable-next-line no-param-reassign
          field.invalid = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          field.invalid = false;
        }
      });
      if (AIFormIsValid.value) {
        AIResultInProgress.value = true;
        if (typeof currentAIContentType.result_config.result_add_mode === 'undefined' || currentAIContentType.result_config.result_add_mode === 'replace') {
          AIResult.value = {};
        }
        AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateWaitButtonLabel');
        const result = await MakeSPMAIRequest(`${currentAIContentType.api_route + idShop.value}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop.value}`)}`, SPMAIdata);
        if (result?.data?.success && typeof result?.data?.result === 'object') {
          const currentAIContentTypeResultConfig = currentAIContentType.result_config;
          let current_result = getValueByPath(result.data.result, currentAIContentTypeResultConfig.result_path);
          if (typeof currentAIContentType.result_config.result_add_mode === 'undefined' || currentAIContentType.result_config.result_add_mode === 'replace') {
            AIResult.value = current_result;
          } else if (currentAIContentType.result_config.result_add_mode === 'merge') {
            // Récupérer les valeurs actuelles dans AIResult.value.<target>
            const currentValues = Object.values(AIResult.value);
            // Fusionner les valeurs actuelles avec current_result
            // Mettre à jour AIResult.value.<target> avec les nouvelles valeurs fusionnées
            if (typeof current_result === 'object') {
              current_result = Object.values(current_result);
            }
            AIResult.value = current_result.concat(currentValues);
          }

          AIResultInProgress.value = false;
          AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateAgainButtonLabel');
        } else {
          AIResultInProgress.value = false;
          AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateAgainButtonLabel');
        }
      } else {
        AIResultInProgress.value = false;
        AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateAgainButtonLabel');
      }
    };

    const needChooseButton = () => {
      if (currentAIApplyContentParams.value.selector) {
        return true;
      }
      return false;
    };
    const handleChooseContent = async (result: any) => {
      let closeAtEnd = true;
      AIChooseContentInProgress.value = true;
      const currentDocument: Document = (currentAIApplyContentParams.value.target === 'template' ? getTemplateIframeDocument() : document);
      const element = currentDocument.querySelector(currentAIApplyContentParams.value.selector);
      switch (currentAIApplyContentParams.value.type) {
        case 'html':
          if (element && undefined !== result) {
            if (currentAIApplyContentParams.value.target === 'template') {
              // This setter can be involved by translation
              sanitizeTranslationMarkup(element).innerHTML = result;
              setTranslationInState(element, 'markup');
            } else {
              element.innerHTML = result;
            }
          }
          break;
        case 'attr':
          if (element && undefined !== result) {
            // Move image to shop folder
            if (currentAIApplyContentParams.value.attr === 'src') {
              try {
                const base64 = await getBase64FromUrl(result);
                const upload = await uploadFileToMedia(base64, idShop.value);
                if (upload && upload.data && upload.status === 200) {
                  // eslint-disable-next-line no-param-reassign
                  result = upload.data;
                }
              } catch (error) {
                closeAtEnd = false;
                showToastError(t('errorMessages.GENERIC_ERROR'));
              }
            }
            element.setAttribute(currentAIApplyContentParams.value.attr, result);

            if (currentAIApplyContentParams.value.updateFieldWithValue) {
              const element2 = document.querySelector(currentAIApplyContentParams.value.updateFieldWithValue.selector);
              if (element2) {
                element2.value = result;
                // To force Vue.js to recognize the change, trigger an 'input' event
                const event = new Event('input', { bubbles: true });
                element2.dispatchEvent(event);
                const event2 = new Event('blur', { bubbles: true });
                element2.dispatchEvent(event2);
              }
            }

            if (currentAIApplyContentParams.value.target === 'template') {
              // This setter can be involved by translation
              setTranslationInState(element, 'attribute', currentAIApplyContentParams.value.attr);
            }
          }
          break;
        case 'value':
          // Assuming currentDocument is the current DOM document
          // Check if the element is found
          if (element) {
            element.value = result;
            // To force Vue.js to recognize the change, trigger an 'input' event
            const event = new Event('input', { bubbles: true });
            element.dispatchEvent(event);
            const event2 = new Event('blur', { bubbles: true });
            element.dispatchEvent(event2);
          }
          break;
        case 'callback':
          if (currentAIApplyContentParams.value.callback) {
            currentAIApplyContentParams.value.callback(result);
          }
          break;
        default:
          break;
      }
      AIChooseContentInProgress.value = false;
      if (closeAtEnd) {
        handleClose();
      }
    };

    const fileBase64 = ref();

    const handleTransferToMyFiles = async (file: any) => {
      const base64 = await getBase64FromUrl(file.result);
      fileBase64.value = base64;
      displayChooseDirectoryModal.value = true;
    };

    const loadAIKeywordsContext = async (event: { query: any }) => {
      const { query } = event;
      globalKeywordsQuery = query;
      const finalKeywords: any[] = [];
      if (!itemsAIKeywordsContextCacheInProgress) {
        if (!itemsAIKeywordsContextCache.length) {
          itemsAIKeywordsContextCacheInProgress = true;

          // Lancez la requête asynchrone, mais ne l'attendez pas ici/
          const aiRequestPromise = MakeSPMAIRequest(`text-generator/content-keywords-summary/${idShop.value}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop.value}`)}`, {
            content: currentAIContextFullContentText.value,
            language: GetReadableShopDefaultLang(),
          });

          // Gérer le résultat de la requête asynchrone
          aiRequestPromise.then((result) => {
            itemsAIKeywordsContextCacheInProgress = false;
            if (result?.data?.success && typeof result.data.result === 'object' && typeof result.data.result.content === 'object') {
              itemsAIKeywordsContextCache = Object.values(result.data.result.content);
              if (globalKeywordsQuery && globalKeywordsQuery.length) {
                finalKeywords.push(globalKeywordsQuery);
              }
              // Ajouter les mots-clés récupérés aux mots-clés finaux
              itemsAIKeywordsContextCache.forEach((keyword) => finalKeywords.push(keyword));
              // Mettre à jour la valeur de itemsGptKeywordsContext
              itemsAIKeywordsContext.value = finalKeywords;
            }
          }).catch(() => {
            itemsAIKeywordsContextCacheInProgress = false;
          });
        }
      }
      // Si le cache existe déjà, utilisez-le immédiatement
      if (globalKeywordsQuery && globalKeywordsQuery.length) {
        finalKeywords.push(globalKeywordsQuery);
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const keyword of itemsAIKeywordsContextCache) {
        finalKeywords.push(keyword);
      }
      itemsAIKeywordsContext.value = finalKeywords;
    };

    const itemsSubmenuImageType: Ref<MenuItem[]> = ref([
      {
        label: t('fileManager.actions.download'),
        icon: 'fa-thin fa-file-arrow-down',
        callback: handleTransferToMyFiles,
      },
    ]);

    const resetVars = (onlyResult: boolean) => {
      AIResult.value = {};
      AIResultInProgress.value = false;
      AIFormIsValid.value = true;
      valuePrompt.value = '';
      valueThematic.value = '';
      valueSimilarityLevel.value = 4;
      valueSize.value = '';
      active.value = 0;
      valueScaleFactor.value = 1;
      valueVariables.value = [];
      globalKeywordsQuery = '';
      AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateButtonLabel');
      if (!onlyResult) {
        itemsAIKeywordsContext.value = [];
        currentAILabel.value = AIContentType.value[0].name;
        contentTypeIndex.value = 0;
        const originalWidth = AIInterfaceParams.value.AIApplyContentParams.imageWidth;
        const originalHeight = AIInterfaceParams.value.AIApplyContentParams.imageHeight;

        const newDimensions = getBestImageSize(originalWidth, originalHeight);

        valueImageWidth.value = newDimensions.width;
        valueImageHeight.value = newDimensions.height;

        valueTexteLength.value = currentAIContextContentText.value.length;
        itemsAIKeywordsContextCache = [];
        itemsAIKeywordsContextCacheInProgress = false;
      }
    };

    const onCloseChooseDirectoryModal = () => {
      displayChooseDirectoryModal.value = false;
    };

    const onChooseFolder = async (folderPath: string, callback: Function) => {
      try {
        if (fileBase64.value) {
          await createNewFileInMedia([fileBase64.value], idShop.value, folderPath);
          showToastSuccess(t('fileManager.imageDownloaded'));
          fileBase64.value = '';
          displayChooseDirectoryModal.value = false;
          callback();
        }
      } catch (error) {
        showToastError(t('errorMessages.GENERIC_ERROR'));
        callback();
      }
    };

    watch(() => serviceParameterIsCanceled.value, () => {
      if (serviceParameterIsCanceled.value) {
        store.commit('general/setServiceParameterIsCanceled', false);
        emit('close');
      }
    });

    onBeforeMount(async () => {
      resetVars(false);
      loadAIKeywordsContext({ query: '' });
    });

    onMounted(async () => {
      // Ajouter un paramètre à l'URL afin de pouvoir charger gleap via url
      addUrlParameter('cp', 'aiinterface');
      if (['1', '2'].includes(currentAIContentType.value.toString())) {
        isLoading.value = true;
        const isConfigured = await isStyleConfigured(idShop.value);
        if (!isConfigured) {
          store.commit('general/setNotConfiguredServices', getAIServiceParameter());
          store.commit('general/setIsServicesParametersModalVisible', true);
        }
        isLoading.value = false;
      }
    });

    return {
      t,
      handleValidation,
      handleChooseContent,
      AIContentType,
      contentTypeIndex,
      active,
      AIResult,
      loadAIKeywordsContext,
      itemsAIKeywordsContext,
      AIResultInProgress,
      AIChooseContentInProgress,
      currentAILabel,
      resetVars,
      AIgenerateButtonLabel,
      needChooseButton,
      itemsSubmenuImageType,
      // eslint-disable-next-line vue/no-unused-properties
      handleTransferToMyFiles,
      isLoading,
      AIFormIsValid,
      hasCorrectOffer,
      updatePlan,
      displayChooseDirectoryModal,
      onCloseChooseDirectoryModal,
      onChooseFolder,
    };
  },
});
