
import {
  defineComponent, PropType, Ref, ref,
} from 'vue';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import FileManager from '@/components/file-manager/FileManager.vue';
import EditImageModal from '@/components/modals/EditImageModal.vue';
import {
  FieldConfig,
  File,
  ParserFieldObject,
  Property,
} from '@/types';
import { useI18n } from 'vue-i18n';
import { removeImage, showImage } from '../callbacks';
import AIButton from "@/components/fields/AIButton.vue";

export default defineComponent({
  name: 'Image',

  components: {
    FileManager,
    AIButton,
    EditImageModal,
    Button,
    InputText,
  },

  props: {
    configs: {
      type: Object as PropType<FieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const displayImageModal = ref(false);
    const editImageModal = ref(false);
    const srcProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === 'src')[0]);
    const selectedImage: Ref<string> = ref(srcProperty.value.value ?? props.configs.options.defaultImageUrl);
    const altProperty: Ref<Property> = ref(props.parserValues.properties && props.parserValues.properties.filter((property) => property.name === 'alt')[0]);
    const description: Ref<string> = ref((altProperty.value && altProperty.value.value) ?? '');
    const isRemoved: Ref<boolean> = ref(false);

    const handleChange = () => {
      srcProperty.value.value = selectedImage.value;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [srcProperty.value],
      });
    };

    const handleChangeDescription = () => {
      altProperty.value.value = description.value;
      context.emit('on-change-properties', {
        selector: props.parserValues.selector,
        properties: [altProperty.value],
      });
    };

    const handleRemove = () => {
      selectedImage.value = '';
      removeImage(props.parserValues.selector);
      isRemoved.value = true;
      handleChange();
    };

    const onChooseImage = (image: File) => {
      selectedImage.value = image.url;
      handleChange();
      if (props.configs.options.remove) {
        isRemoved.value = false;
        showImage(props.parserValues.selector);
      }
    };

    const onSaveImage = (imageUrl: string) => {
      selectedImage.value = imageUrl;
      handleChange();
    };
    const createUniqueString = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36);
    return {
      t,
      displayImageModal,
      editImageModal,
      isRemoved,
      description,
      onChooseImage,
      handleChangeDescription,
      onSaveImage,
      handleChange,
      handleRemove,
      selectedImage,
      inputUniqueId: `unique-input-${createUniqueString()}`,
    };
  },
});
