
import {
  defineComponent, PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import Textarea from 'primevue/textarea';
import VariablesButton from '@/components/fields/partials/VariablesButton.vue';
import EmojisButton from '@/components/fields/partials/EmojisButton.vue';
import { br2nl, nl2br } from '@/helpers';
import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseTextarea',
  components: {
    Textarea,
    EmojisButton,
    VariablesButton,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    displayEmojis: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayVariables: {
      type: Boolean,
      required: false,
      default: false,
    },

    maxLengthTextarea: {
      type: Number,
      required: false,
      default: -1,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    modifiers: {
      type: Array as PropType<Function[]> | null,
      required: false,
      default: null,
    },

    watchProps: {
      type: Boolean,
      default: false,
    }
  },

  emits: {
    'update:modelValue': String,
    click: String,
  },

  setup(props, { emit }: SetupContext) {
    const inputValue: Ref<string> = ref(br2nl(props.modelValue));
    const cursorPosition = ref(0);

    const insertContent = (value: string|number|undefined) => {
      inputValue.value = inputValue.value.substring(0, cursorPosition.value)
        + value
        + inputValue.value.substring(cursorPosition.value, inputValue.value.length);
    };

    const applyModifiers = (event: any) => {
      if (!event.ctrlKey && !event.metaKey) {
        if (Array.isArray(props.modifiers) && props.modifiers.length > 0) {
          props.modifiers.forEach((modifier: Function) => {
            inputValue.value = modifier(inputValue.value);
          });
        }
      }
    };

    watch(inputValue, () => {
      emit('update:modelValue', nl2br(inputValue.value));
    });

    watch(() => props.modelValue, () => {
      if (props.watchProps) {
        inputValue.value = br2nl(props.modelValue);
      }
    });

    const textareaRef = ref();

    const focus = () => {
      if (textareaRef.value) {
        textareaRef.value.$el.focus();
      }
    };

    return {
      inputValue,
      cursorPosition,
      insertContent,
      applyModifiers,
      textareaRef,
      focus,
    };
  },
});
