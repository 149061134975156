
import {
  computed,
  defineComponent, onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from 'vue';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useI18n } from 'vue-i18n';
import {
  DropdownOption,
  LinkTypeEnum,
  Translation,
  ActionTypeEnum,
} from '@/types';
import BaseInputText from '@/components/fields/BaseInputText.vue';
import BaseDropdown from '@/components/fields/partials/BaseDropdown.vue';
import { Templates } from '@/types/generated-types/graphql';
import { Get } from '@/composables/GraphQL';
import { getTemplateIframeDocument, highlightWidgetOnTranslationClicked } from '@/components/template-builder/utils/helpers';
import TemplateSelector from '@/components/automated-scenarios/fields/TemplateSelector.vue';
import {
  activate as activateTemplate,
  setRefreshTemplatesList, TemplateEditorState,
  updateTemplateConfiguration,
} from '@/composables/template-editor/TemplateEditor';
import { store } from '@/store';
import ProgressSpinner from 'primevue/progressspinner';
import CryptoJS from 'crypto-js';
import { loadImageUntilSuccess } from '@/composables/loadImage';
import { Variable } from '../template-builder/utils/variables-list';
import LocalizedTextInput from "@/components/fields/LocalizedTextInput.vue";
import {
  TRANSLATION_ATTRIBUTE,
} from '@/components/template-builder/utils/constants';

export default defineComponent({
  name: 'BaseLink',

  components: {
    LocalizedTextInput,
    ProgressSpinner,
    TemplateSelector,
    BaseInputText,
    BaseDropdown,
    Dropdown,
    Button,
    Dialog,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    linkTypeOptions: {
      type: Array as PropType<LinkTypeEnum[]>,
      required: true,
    },

    originCall: {
      type: String,
      required: false,
      default: null,
    },

    idCampaign: {
      type: Number,
      required: false,
      default: null,
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },
  },

  emits: {
    'update:modelValue': Object,
    click: String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, context) {
    const { t } = useI18n();
    const baseUrl = `${process.env.VUE_APP_URL_MEDIAL_IMAGE_PREFIX_WITH_RESIZE}`;

    const imageUrl = ref('');
    const mailToPattern = /^mailto:(.*)\?subject=(.*)&[amp;]*body=(.*)$/i;
    const displayTemplateModal = ref(false);
    const selectedTemplate: Ref<Templates | undefined> = ref();
    const displayTemplatePreview = ref(false);
    const hrefValue: Ref<string> = ref(props.modelValue);
    const originCall: Ref<string | null> = ref(props.originCall);
    const addressValue: Ref<string> = ref('');
    const subjectValue: Ref<string> = ref('');
    const bodyValue: Ref<string> = ref('');
    const options: DropdownOption[] = props.linkTypeOptions
      .map((key: string) => ({ value: key, label: t(`templateBuilder.fields.linkOptions.${key}`) }));

    const getAnchors = (): DropdownOption[] => {
      const anchors: DropdownOption[] = [];
      const template = getTemplateIframeDocument();

      if (template) {
        if (template.anchors && template.anchors.length) {
          Array.from(template.anchors).forEach((anchor) => {
            const anchorName = anchor.getAttribute('name');

            if (anchorName) {
              const dropdownOption: DropdownOption = { label: anchorName, value: `#${anchorName}` };
              anchors.push(dropdownOption);
            }
          });
        }
      }

      return anchors;
    };

    const baseLinkData: any = computed(() => {
      const namePrimaryTemplate = TemplateEditorState?.template?.informations.name;
      const translationsPrimaryTemplate = TemplateEditorState?.template?.translations ?? [];
      const uniqueRedirects = new Set();

      translationsPrimaryTemplate.forEach((translation) => {
        const { value } = translation;

        const match = value.match(new RegExp('#redirect_'));
        if (match) {
          const idRedirect = window.atob(value.replace('#redirect_', ''));
          if (!idRedirect.match(new RegExp('[a-zA-Z]'))) {
            uniqueRedirects.add(idRedirect);
          }
        }
      });

      const countRedirectTemplate = uniqueRedirects.size;

      return {
        namePrimaryTemplate,
        countRedirectTemplate,
      };
    });

    let anchorConfig: DropdownOption[] = [];
    const availableAnchors: string[] = [];

    if (props.linkTypeOptions.includes(LinkTypeEnum.ANCHOR)) {
      // We get the current anchors in the template
      anchorConfig = getAnchors();

      if (anchorConfig.length) {
        anchorConfig.forEach((anchor) => {
          availableAnchors.push(anchor.value.toString());
        });
      }
    }

    const getLinkType = () => {
      if (hrefValue.value) {
        if (hrefValue.value.match(/^mailto:/g)) return LinkTypeEnum.EMAIL_ADDRESS;
        if (hrefValue.value.match(/^#redirect_/g)) return LinkTypeEnum.DISPLAY_TEMPLATE;
        if (hrefValue.value.match(/^#spmClosePopin/g)) return LinkTypeEnum.CLOSE_POPIN;
        if (hrefValue.value.match(/^#/g)) return LinkTypeEnum.ANCHOR;
        return LinkTypeEnum.WEB_ADDRESS;
      }
      return LinkTypeEnum.NONE;
    };

    const editTemplateRedirection = async (id: number, name: string, typeTemplate: string) => {
      await updateTemplateConfiguration();
      await activateTemplate(id, name, typeTemplate);
    };

    const linkType: Ref<LinkTypeEnum> = ref(getLinkType());

    const isBase64String = ref(false);

    const handleChange = () => {
      if (linkType.value === LinkTypeEnum.WEB_ADDRESS && isBase64String.value) {
        context.emit('update:modelValue', window.btoa(hrefValue.value));
      } else {
        context.emit('update:modelValue', hrefValue.value);
      }
    };

    const selectTemplate = async (idTemplate: number, label: string, type: string) => {
      const { item } = await Get<Templates>({
        name: 'Templates',
        id: idTemplate,
        keyName: 'id_template',
        fields: ['id_template', 'label', 'type'],
      });

      if (item) {
        selectedTemplate.value = item;
        hrefValue.value = `#redirect_${btoa(idTemplate as unknown as string)}`;
        handleChange();

        if (originCall.value === 'templateBuilder') {
          setRefreshTemplatesList(true);
        }
      }

      displayTemplateModal.value = false;
      store.commit('general/hideTheSpinner');
    };

    const handleChangeMailToLink = () => {
      hrefValue.value = `mailto:${addressValue.value}?subject=${subjectValue.value}&body=${bodyValue.value}`;
      handleChange();
    };

    const refreshImagePreview = async () => {
      if (linkType.value === LinkTypeEnum.DISPLAY_TEMPLATE) {
        let encodedDisplayedTemplate = '';
        if (hrefValue.value) {
          const matchResult = hrefValue.value.match(/#redirect_(.+)/);
          encodedDisplayedTemplate = matchResult && matchResult[1] ? matchResult[1] : '';
        }
        const decodedIdTemplate = Number(atob(encodedDisplayedTemplate));
        if (decodedIdTemplate > 0) {
          imageUrl.value = '';
          const cryptedImageKey = CryptoJS.SHA1(`salt-${decodedIdTemplate}`).toString();

          const potentialImageUrl = `${baseUrl + cryptedImageKey}-isdisplay.png&w=400&${new Date().getTime()}`;
          try {
            await loadImageUntilSuccess(potentialImageUrl);
            imageUrl.value = potentialImageUrl;
          } catch (error) {
            console.error("Erreur lors du chargement de l'image", error);
          }
        }
      }
    };

    watch(async () => hrefValue.value, async () => {
      await refreshImagePreview();
    }, { deep: true });

    watch(() => hrefValue, async (values, oldValues) => {
      if (values !== oldValues) {
        if (hrefValue.value) {
          linkType.value = getLinkType();
        }
      }
    }, { immediate: true });

    watch(() => linkType.value, async (values, oldValues) => {
      if (values !== oldValues) {
        if (linkType.value === LinkTypeEnum.CLOSE_POPIN) {
          hrefValue.value = '#spmClosePopin';
        } else if (linkType.value === LinkTypeEnum.EMAIL_ADDRESS) {
          const matches: RegExpExecArray|null = mailToPattern.exec(hrefValue.value ?? '');
          if (matches) {
            // eslint-disable-next-line
            addressValue.value = matches[1];
            // eslint-disable-next-line
            subjectValue.value = matches[2];
            // eslint-disable-next-line
            bodyValue.value = matches[3];
          }
        } else if (linkType.value === LinkTypeEnum.DISPLAY_TEMPLATE) {
          try {
            const id = atob(hrefValue.value.replace('#redirect_', '') ?? ''); // Extract and decode template id
            // Retrieve template informations
            const { item, err } = await Get<Templates>({
              name: 'Templates',
              id,
              keyName: 'id_template',
              fields: ['id_template', 'label', 'type'],
            });
            if (item) {
              selectedTemplate.value = item;
            }
          } catch (e) { /* unable to decode template */ }
        } else if (linkType.value === LinkTypeEnum.ANCHOR) {
          if (availableAnchors.length && !availableAnchors.includes(hrefValue.value)) {
            // If the link was not an anchor, we set the first anchor as the href value
            [hrefValue.value] = availableAnchors;
          }
        } else if (oldValues && linkType.value === LinkTypeEnum.NONE) {
          hrefValue.value = '';
          selectedTemplate.value = undefined;
        }

        if (oldValues) {
          // We emit only if it's not the initialization of the field
          handleChange();
        }
      }
    }, { immediate: true });

    onMounted(async () => {
      // Check if value need to be translated into base64 or not
      if (props.translation && (props.translation.fieldType === 'data-redirect' || props.translation.attributeName === 'data-redirect')) {
        const template = getTemplateIframeDocument();
        if (template && props.translation) {
          const element = template.body.querySelector(`[${TRANSLATION_ATTRIBUTE}-data-redirect="LANG_${props.translation.key}"]`);
          if (element) {
            const actionTypes = Object.values(ActionTypeEnum).map((actionType) => `spm_action_${actionType}`);
            // eslint-disable-next-line no-restricted-syntax
            for (const actionType of actionTypes) {
              if (element.classList.contains(actionType)) {
                isBase64String.value = true;
                break;
              }
            }
          }
        }
      }
      if (linkType.value === LinkTypeEnum.WEB_ADDRESS && hrefValue.value.trim() && isBase64String.value) {
        hrefValue.value = window.atob(hrefValue.value);
      }
      await refreshImagePreview();
    });

    const handleHighlight = () => {
      if (props.translation) {
        highlightWidgetOnTranslationClicked(props.translation);
      }
    };

    const baseInputTextRef = ref();
    const dropdownRef = ref();

    const focus = () => {
      if (baseInputTextRef.value) {
        baseInputTextRef.value.focus();
      }
    };

    const scrollIntoView = () => {
      if (dropdownRef.value) {
        dropdownRef.value.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };

    const handleActivateGroup = () => {
      if (props.translation) {
        context.emit('activate-group', props.translation.groupKey);
      }
    };

    const handleDeactivateGroup = () => {
      if (props.translation) {
        context.emit('deactivate-group', props.translation.groupKey);
      }
    };

    return {
      t,
      options,
      anchorConfig,
      hrefValue,
      imageUrl,
      linkType,
      displayTemplateModal,
      selectedTemplate,
      addressValue,
      subjectValue,
      bodyValue,
      selectTemplate,
      handleChange,
      handleChangeMailToLink,
      editTemplateRedirection,
      displayTemplatePreview,
      LinkTypeEnum,
      baseLinkData,
      handleHighlight,
      baseInputTextRef,
      focus,
      scrollIntoView,
      handleActivateGroup,
      handleDeactivateGroup,
    };
  },
});
