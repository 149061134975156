
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Flag',

  props: {
    country: {
      type: String,
      required: false,
      default: '',
    },

    alt: {
      type: String,
      required: false,
      default: null,
    },
  },

  setup(props: { country: string; alt: string}) {
    const { country } = props;

    let flag = '';
    if (props.country && props.country !== '' && props.country !== '**') {
      flag = country.toLowerCase();
    }

    return {
      flag,
      altText: props.alt ?? props.country,
    };
  },
});
