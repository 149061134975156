
import {
  computed, defineComponent,
  PropType, Ref, ref, SetupContext, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import ActionABTestMetada, { ActionABTestData } from '@/components/automated-scenarios/metadata/actions/ActionABTestMetadata';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import { showToastError, asInt } from '@/helpers';
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
} from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';

export default defineComponent({
  name: 'ActionABTest',
  components: {
    FieldErrors,
    BaseInputNumber,
  },

  props: {
    modelValue: {
      type: Object as PropType<ActionABTestData>,
      required: true,
      default() {
        return ActionABTestMetada.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: ActionABTestData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const alphabetBranches = ['A', 'B', 'C', 'D', 'E', 'F'];
    const value: Ref<number> = ref(asInt(props.modelValue.outputs_number_test));
    const outputsToDelete: Ref<string[]> = ref([]);
    const initialValue = asInt(props.modelValue.outputs_number_test);

    const isEdit: Ref<boolean> = ref(state.selectedOperator.operatorAddMethod === null && state.selectedOperator.operatorId !== '');
    const nbOutputsMustBeDeleted = computed(() => initialValue - value.value);
    const outputsCanBeDeleted = [];
    if (isEdit.value && initialValue) {
      const currentOperatorId = state.selectedOperator.operatorId;
      for (let i = 1; i <= initialValue; i++) {
        const childOperator = getOperatorChildByOutput(currentOperatorId, `output_${i}`);

        if (childOperator !== null) {
          const childOperatorData = state.scenario.data.operators[childOperator];
          outputsCanBeDeleted.push({
            output: `output_${i}`,
            titleOperator: childOperatorData.properties.title,
            classOperator: childOperatorData.properties.class,
            logoOperator: childOperatorData.custom.logo_box,
          });
        } else {
          outputsCanBeDeleted.push({
            output: `output_${i}`,
            titleOperator: t('automatedScenarios.actions.abTest.emptyBranch'),
            classOperator: 'empty_branch',
            logoOperator: 'fa-times',
          });
        }
      }
    }

    const update = () => {
      let initial_number_outputs: number|null = null;
      let outputs_to_delete: string[]|null = null;

      if (isEdit.value && value.value < initialValue) {
        initial_number_outputs = initialValue;
        outputs_to_delete = outputsToDelete.value;
      }

      context.emit('update:modelValue', ActionABTestMetada.Create({
        ...props.modelValue,
        outputs_number_test: value.value,
        initial_number_outputs,
        outputs_to_delete,
      }));
    };

    const selectOutputToDelete = (output: string): void => {
      if (outputsToDelete.value.includes(output)) {
        const index = outputsToDelete.value.indexOf(output);
        if (index > -1) {
          outputsToDelete.value.splice(index, 1);
        }
      } else if (outputsToDelete.value.length < nbOutputsMustBeDeleted.value) {
        outputsToDelete.value.push(output);
      } else {
        let errorMessage = t('automatedScenarios.actions.abTest.errorNbBranchToDelete');
        if (nbOutputsMustBeDeleted.value > 1) {
          errorMessage = t('automatedScenarios.actions.abTest.errorNbBranchesToDelete', { nb: nbOutputsMustBeDeleted.value });
        }
        showToastError(errorMessage);
      }
    };

    watch(value, () => {
      update();
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      value,
      alphabetBranches,
      initialValue,
      outputsCanBeDeleted,
      nbOutputsMustBeDeleted,
      selectOutputToDelete,
      outputsToDelete,
      isEdit,
    };
  },
});
