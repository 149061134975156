// eslint-disable-next-line import/no-cycle
import { showForm, switchFormAndMessage } from '@/components/template-builder/callbacks';
import { ActionTypeEnum, ComponentNameEnum } from '@/types';
import getTriggerButtonFieldConfig from './trigger-button-config';
import getWysiwygEditorFieldConfig from './wysiwyg-editor-config';

const getActionTypeFieldConfig = (defaultValue = ActionTypeEnum.DISPLAY_MESSAGE) => ({
  componentName: ComponentNameEnum.ACTION_TYPE,
  options: {
    defaultValue,
    triggerButtonConfig: getTriggerButtonFieldConfig('templateBuilder.fields.showMessage', 'templateBuilder.fields.hideMessage', switchFormAndMessage, showForm),
    wysiwygEditorConfig: {
      label: 'templateBuilder.fields.wysiwygEditor',
      ...getWysiwygEditorFieldConfig('', []),
    },
    redirectConfig: {
      label: 'templateBuilder.fields.webAddress',
      options: {
        defaultValue: '',
        variablesList: [],
        displayEmojis: false,
        displayVariables: false,
      },
    },
    executeJSConfig: {
      label: 'templateBuilder.fields.javascriptCode',
      options: {
        defaultValue: '',
        variablesList: [],
        displayVariables: false,
      },
    },
  },
});

export default getActionTypeFieldConfig;
