import { ComponentNameEnum, StepperSignEnum, BaseStepperFieldConfig } from '@/types';

const getStepperFieldConfig = (sign = StepperSignEnum.NONE, step = 1): BaseStepperFieldConfig => ({
  componentName: ComponentNameEnum.STEPPER,
  options: {
    sign,
    step,
  },
});

export default getStepperFieldConfig;
