import {
  TemplateParentTypeEnum,
  WidgetTypeConfig,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import emailWidgetTextConfig from '@/components/template-builder/config/templates/email/widgets/widget-text-config';
// eslint-disable-next-line import/no-cycle
import facebookWidgetTextConfig from '@/components/template-builder/config/templates/facebookmessenger/widgets/widget-text-config';
// eslint-disable-next-line import/no-cycle
import popupWidgetTextConfig from '@/components/template-builder/config/templates/popup/widgets/widget-text-config';
// eslint-disable-next-line import/no-cycle
import embedWidgetTextConfig from '@/components/template-builder/config/templates/embed/widgets/widget-text-config';

const textConfig = {
  [TemplateParentTypeEnum.FACEBOOKMESSENGER]: facebookWidgetTextConfig,
  [TemplateParentTypeEnum.EMAIL]: emailWidgetTextConfig,
  [TemplateParentTypeEnum.POPUP]: popupWidgetTextConfig,
  [TemplateParentTypeEnum.EMBED]: embedWidgetTextConfig,
};

const getWidgetTextConfig = (templateType: string): WidgetTypeConfig => {
  if (
    templateType === TemplateParentTypeEnum.FACEBOOKMESSENGER
    || templateType === TemplateParentTypeEnum.EMAIL
    || templateType === TemplateParentTypeEnum.POPUP
    || templateType === TemplateParentTypeEnum.EMBED
  ) {
    return textConfig[templateType];
  }
  return {};
};

export default getWidgetTextConfig;
