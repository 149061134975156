<template>
  <div
    v-if="!pageLoaded"
    class="grid pt-3 pb-1"
  >
    <div class="col-12">
      <Skeleton
        width="10rem"
        height="1.5rem"
        class="mb-2 skeleton-title"
      />
    </div>
    <div class="col-3">
      <div class="p-card card-skeleton p-3">
        <Skeleton
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          class="mb-2"
        />
        <Skeleton
          width="5rem"
          class="mb-2"
        />
        <Skeleton
          height="2rem"
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          height="3rem"
        />
      </div>
    </div>
    <div class="col-3">
      <div class="p-card card-skeleton p-3">
        <Skeleton
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          class="mb-2"
        />
        <Skeleton
          width="5rem"
          class="mb-2"
        />
        <Skeleton
          height="2rem"
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          height="3rem"
        />
      </div>
    </div>
    <div class="col-3">
      <div class="p-card card-skeleton p-3">
        <Skeleton
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          class="mb-2"
        />
        <Skeleton
          width="5rem"
          class="mb-2"
        />
        <Skeleton
          height="2rem"
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          height="3rem"
        />
      </div>
    </div>
    <div class="col-3">
      <div class="p-card card-skeleton p-3">
        <Skeleton
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          class="mb-2"
        />
        <Skeleton
          width="5rem"
          class="mb-2"
        />
        <Skeleton
          height="2rem"
          class="mb-2"
        />
        <Skeleton
          width="10rem"
          height="3rem"
        />
      </div>
    </div>
  </div>
  <div v-if="pageLoaded">
    <div
      v-if="featurePermissionView && !featurePermissionView.access && !isSystem"
      class="grid h-full"
    >
      <div class="col-12 flex flex-column">
        <div class="flex justify-content-center align-items-center mb-3 mt-3">
          <div>
            <span class="font-semibold">
              {{ t('featureUnavailable') }}
            </span>
            <i
              class="fas fa-star ml-2 text-sm border-circle border-1 p-1"
              :class="`star--${featurePermissionView.minimumPlan}`"
            />
          </div>
        </div>
        <div class="flex justify-content-center align-items-center mb-3">
          {{ t('pricingPlanLimitation.minimumPlan', [t(`offers.plans.${featurePermissionView.minimumPlan}`)]) }}
        </div>
        <div
          v-if="!trialAvailable"
          class="flex justify-content-center align-items-center mb-2"
        >
          <SpmButton
            :label="t('upgradePlanButton')"
            class-style="p-button p-button-primary"
            @click="handleUpgradeButton"
          />
        </div>
        <div
          v-else
          class="flex flex-column justify-content-center align-items-center mb-2"
        >
          <div class="flex align-items-center mb-2">
            <Checkbox
              key="checkbox-acceptTTC"
              v-model="acceptTTC"
              binary
            />
            <label
              class="ml-1"
              for="checkbox-acceptTTC"
            >
              {{ t('payments.acceptTTC') }}
              <a
                href="#"
                data-toggle="modal"
                data-target="#modalCGV"
                class="tc-primary font-bold"
                @click="showTACModal = true"
              >
                {{ t('payments.TTC') }}
              </a>
            </label>
          </div>
          <SpmButton
            :label="t('tryPlan', [t(`offers.plans.${featurePermissionView.minimumPlan}`)])"
            :disabled="!acceptTTC"
            class-style="p-button p-button-primary"
            @click="handleTryPlanButton"
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="isSystem || !featurePermissionView || (featurePermissionView && featurePermissionView.access)"
    >
      <div
        v-if="!predefined"
        class="flex justify-content-between flex-wrap mb-3"
      >
        <div class="flex align-items-center justify-content-center">
          <h3>
            {{ pageName }}
          </h3>
        </div>
        <div class="flex align-items-center justify-content-center">
          <StatsFilterOnView
            v-if="hasActiveFilters"
            v-model="pageFiltersData"
            @apply-filters="applyStatsFilters"
          />
          <SpmButton
            icon="far fa-arrows-rotate"
            :label="t('statsEditor.sidebarPanel.reload')"
            class-style="p-button p-button-secondary mr-2"
            @click="reloadStatsPage"
          />
          <SecureSpmButton
            v-if="findPermission('stats_report.edit')"
            icon="far fa-edit"
            :label="t('statsEditor.sidebarPanel.edit')"
            class-style="p-button p-button-secondary"
            required-feature="stats_report.edit"
            :bypass-permission="isSystem"
            :on-authorized-click="editStatPage"
          />
        </div>
      </div>

      <div
        v-if="pageLoaded"
        class="grid"
      >
        <div class="col-12">
          <StatsWidgetRenderer
            v-for="(item, index) in pageStructure"
            :key="index"
            :configuration="item"
            :default-filters="pageFiltersData"
            :page-name="pageName"
          />
        </div>
      </div>
    </div>
  </div>
  <Loader v-if="isLoading" />
  <TermsAndConditions
    v-if="showTACModal"
    @on-close-modal="showTACModal = false"
  />
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
  computed,
} from 'vue';

import Skeleton from 'primevue/skeleton';
import Checkbox from 'primevue/checkbox';
import StatsWidgetRenderer from '@/components/stats/StatsWidgetRenderer.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { Request } from '@/composables/GraphQL';
import {
  Maybe,
  UsersPagesConfiguration,
} from '@/types/generated-types/graphql';
import { useRouter } from 'vue-router';
import { showToastError } from '@/helpers';
import { getUsersPagesConfigurationMain } from '@/composables/Statistics/usersPagesConfiguration';
import { useStore } from '@/store';
import StatsFilterOnView from '@/components/stats/StatsFilterOnView.vue';
import { defaultPageStateFilters } from '@/store/modules/stats-editor';
import { useI18n } from 'vue-i18n';
import {
  UserState,
  findPermission,
  hasAccessToFeatures,
  tryPlan,
} from '@/composables/User';
import Loader from '@/components/layout/Loader.vue';
import SecureSpmButton from '@/components/spm-primevue/SecureSpmButton.vue';
import TermsAndConditions from '@/views/auth/TermsAndConditions.vue';
import deepcopy from 'deepcopy';

export default defineComponent({
  name: 'StatsCustoms',
  components: {
    StatsFilterOnView,
    Skeleton,
    StatsWidgetRenderer,
    SpmButton,
    Loader,
    SecureSpmButton,
    TermsAndConditions,
    Checkbox,
  },

  props: {
    pageId: {
      type: Number,
      required: true,
    },

    predefined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const { t, locale } = useI18n();

    const store = useStore();
    const pageStructure: Ref<{[key: string]: any}[]> = ref([]);
    const pageId: Ref<number> = ref(props.pageId);
    const pageName: Ref<any> = ref();
    const pageLoaded: Ref<boolean> = ref(false);
    const pageFiltersData: Ref<any> = ref({});
    const hasActiveFilters: Ref<boolean> = ref(false);
    const isLoading = ref(false);

    const featurePermissionView = ref(hasAccessToFeatures('stats_report.view'));
    const isSystem = ref(false);

    const router = useRouter();

    // Get page configuration from configuration database
    const getPageConfiguration = async (id: number): Promise<Maybe<{[key: string]: any}>> => {
      const { err, data } = await Request<UsersPagesConfiguration>({
        name: 'UsersPagesConfigurationGet',
        variables: { id },
        query: `
          query ($id: Int!) {
            UsersPagesConfigurationGet(id_users_pages_configuration: $id) {
              page_name
              deleted
              configuration
              extra_data
              owner
            }
          }
        `,
      });

      if (err) {
        return null;
      }

      if (data) {
        return data;
      }

      return null;
    };

    const getMainPageConfiguration = async (id: number): Promise<Maybe<{[key: string]: any}>> => {
      const { err, item } = await getUsersPagesConfigurationMain(id, ['id_users_pages_configuration', 'page_name', 'active', 'deleted', 'configuration', 'owner']);

      if (err || !item?.id_users_pages_configuration) {
        await showToastError('GENERIC_ERROR');
      }

      if (item) {
        return item;
      }

      return null;
    };

    // Open the stat builder component to edit the layout of that page
    const editStatPage = async () => {
      isLoading.value = true;
      await store.dispatch('statsEditor/editPage',
        { pageId: pageId.value });
      isLoading.value = false;
    };

    const loadPageContent = async (idPage: number) => {
      pageLoaded.value = false;
      const result = props.predefined ? await getMainPageConfiguration(idPage) : await getPageConfiguration(idPage);

      if (!result || result.deleted) {
        // Redirect to 404
        await router.push({ name: 'not-found' });
      } else {
        pageName.value = result.page_name;
        try {
          pageName.value = JSON.parse(pageName.value);
          if (locale.value in pageName.value) {
            pageName.value = pageName.value[locale.value];
          } else {
            pageName.value = result.page_name;
          }
        } catch (error) {
          pageName.value = result.page_name;
        }
        pageStructure.value = JSON.parse(result.configuration);

        if (!props.predefined) {
          const extra_data = result.extra_data ? JSON.parse(result.extra_data) : {};
          pageFiltersData.value = extra_data && extra_data.defaultFilters ? extra_data.defaultFilters : defaultPageStateFilters;
          if (pageFiltersData.value.id_campaign_bulk && pageFiltersData.value.id_campaign_bulk.value && Array.isArray(pageFiltersData.value.id_campaign_bulk.value)) {
            if (pageFiltersData.value.id_campaign_bulk.value.length) {
              pageFiltersData.value.id_campaign_bulk.value = pageFiltersData.value.id_campaign_bulk.value[0];
            } else {
              pageFiltersData.value.id_campaign_bulk.value = '';
            }
          } else if (pageFiltersData.value.id_campaign_bulk && pageFiltersData.value.id_campaign_bulk.value === null) {
            pageFiltersData.value.id_campaign_bulk.value = '';
          }
          hasActiveFilters.value = Object.keys(pageFiltersData.value).some((filterKey: any) => pageFiltersData.value[filterKey].visibleOnView);
        }

        pageLoaded.value = true;
        isSystem.value = result.owner === 'system';
      }
    };

    const applyStatsFilters = async () => {
      const newFilters = deepcopy(pageFiltersData.value);
      store.commit('statsEditor/setPageFilters', newFilters);
    };

    const reloadStatsPage = () => {
      const currentCount = store.getters['statsEditor/getRefreshWidgetsCount'];
      store.commit('statsEditor/setRefreshWidgetsCount', currentCount + 1);
    };

    const trialAvailable = computed(() => {
      if (UserState.activeOffer) {
        if (!UserState.activeOffer.trials) {
          return true;
        }
        if (featurePermissionView.value) {
          if (!UserState.activeOffer.trials[featurePermissionView.value.minimumPlan]) {
            return true;
          }
        }
        return false;
      }
      return false;
    });

    const acceptTTC = ref(false);
    const showTACModal = ref(false);

    const handleUpgradeButton = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    const handleTryPlanButton = async () => {
      if (featurePermissionView.value) {
        store.commit('general/showTheSpinner');
        await tryPlan(featurePermissionView.value.minimumPlan);
        store.commit('general/hideTheSpinner');
      }
    };

    watch(() => props.pageId, async (newValue) => {
      if (newValue) {
        pageId.value = newValue;
        await loadPageContent(newValue);
      }
    });

    watch(() => store.getters['statsEditor/getRefreshComponents'], async () => {
      if (store.getters['statsEditor/getRefreshComponents']) {
        if (pageId.value) {
          await loadPageContent(pageId.value);
        }
        store.commit('statsEditor/setRefreshComponents', false);
      }
    });

    onMounted(async () => {
      if (pageId.value) {
        await loadPageContent(pageId.value);
      }
    });

    return {
      t,
      pageLoaded,
      pageName,
      pageStructure,
      pageFiltersData,
      hasActiveFilters,
      editStatPage,
      applyStatsFilters,
      reloadStatsPage,
      findPermission,
      isLoading,
      featurePermissionView,
      isSystem,
      trialAvailable,
      acceptTTC,
      showTACModal,
      handleUpgradeButton,
      handleTryPlanButton,
    };
  },
});
</script>

<style scoped lang="scss">
.p-card {
  box-shadow: none;

  &.card-skeleton {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
  }
}

.skeleton-title {
  background-color: $shuttle-grey;
}

.star--premium {
  color: $silver;
  width: 25px;
  height: 25px;
}
.star--ultimate {
  color: $gold;
  width: 25px;
  height: 25px;
}
</style>
