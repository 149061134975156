import {
  ComponentNameEnum, ConfigurationPanelStructureItemEnum, StepperSignEnum, TabItem, TemplateStructureEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
// eslint-disable-next-line import/no-cycle
import {
  recalculateLineHeightValue,
  setAttribute, setCssButtonWidthProperty,
  setCssProperty,
  setCssPropertyAndRemoveIfZero,
  setMediaDisplay,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getAttributeByName, getClassByName, getCssPropertyByName } from '@/components/template-builder/getters';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';

const widgetButtonFieldsConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.GROUP,
  label: 'templateBuilder.configs.textAndButton',
  tabPanel: {
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.button',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.buttonBackgroundColor',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, '
              + '{SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, '
              + '{SELECTOR} table.button th a:active',
            properties: [{
              name: 'background-color',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig('#ECECEC'),
          },
          {
            label: 'templateBuilder.fields.buttonWidth',
            selector: '{SELECTOR} table.button',
            properties: [{
              name: 'width',
              getter: getCssPropertyByName,
              setters: [setCssButtonWidthProperty],
            }],
            ...getButtonWidthFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.buttonAlignment',
            selector: '{SELECTOR} table.button',
            properties: [{
              name: 'align',
              getter: getAttributeByName,
              setters: [setAttribute],
            }],
            ...getElementAlignFieldConfig('align'),
          },
          {
            label: 'templateBuilder.fields.contours',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [{
              name: 'border-radius',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getBorderRadiusFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.borders',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [
              {
                name: 'border-color',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-style',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-width',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'border-top-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-bottom-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-bottom-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-bottom-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-left-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-right-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th',
            properties: [
              {
                name: 'padding-top',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-bottom',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-left',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-right',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{
              name: 'show-for-',
              getter: getClassByName,
              setters: [setMediaDisplay],
            }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.text',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, '
              + '{SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, '
              + '{SELECTOR} table.button th a:active',
            properties: [
              {
                name: 'font-family',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'font-size',
                getter: getCssPropertyByName,
                setters: [recalculateLineHeightValue, setCssProperty],
              },
              {
                name: 'font-weight',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'font-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'text-decoration-line',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'text-transform',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'color',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '{SELECTOR} table.button th, {SELECTOR} table.button:hover th, {SELECTOR} table.button:visited th, {SELECTOR} table.button:active th, '
              + '{SELECTOR} table.button:hover th a, {SELECTOR} table.button th a, {SELECTOR} table.button th a:visited, {SELECTOR} table.button th a:hover, '
              + '{SELECTOR} table.button th a:active',
            properties: [{
              name: 'letter-spacing',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
        ],
      },
    ],
  },
};

export default widgetButtonFieldsConfig;
