
import Dialog from 'primevue/dialog';
import { GlobalBulkSettingsData } from '@/types/automated-scenarios';
import {
  defineComponent, onMounted, ref, SetupContext, watch,
} from 'vue';
import { AutomatedScenarioState as state, setGlobalSettingsData } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import BaseDropdown2 from '@/components/fields/BaseDropdown2.vue';
import Tooltip from 'primevue/tooltip';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import Dropdown from 'primevue/dropdown';
import { UserState } from '@/composables/User';
import { Get } from '@/composables/GraphQL';
import { Shops } from '@/types/generated-types/graphql';

export default defineComponent({
  name: 'ModalScheduledBulkCampaign',

  components: {
    BaseDropdown2,
    ButtonGroup,
    SpmButton,
    Dialog,
    Calendar,
    ConfirmPopup,
    Dropdown,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['on-hide-modal', 'on-save-scheduled'],

  setup(props: {settingPanelValidationErrors: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const displayBasic = ref(true);
    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const loadingSaveScheduled = ref(false);
    const typeSending = ref(0); // 0 = scheduled, 1 = now
    const selectedDate = ref(moment().format('DD/MM/YYYY'));
    const selectedTime = ref('08:00');

    const timeOptions: any = ref([]);
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        const hours = String(i).padStart(2, '0');
        const minutes = String(j).padStart(2, '0');
        const timeString = `${hours}:${minutes}`;
        timeOptions.value.push({ value: timeString, label: timeString });
      }
    }

    const selectedTimezone = ref();
    const timezoneOptions: any = ref([]);

    const selectedPerfectTiming = ref(0);
    const perfectTimingOptions = ref([
      { value: 1, label: t('automatedScenarios.globalBulkSettings.perfect_timing.options.activate') },
      { value: 0, label: t('automatedScenarios.globalBulkSettings.perfect_timing.options.deactivate') },
    ]);

    const selectedSlowdown = ref(0);
    const selectedIntervalBetweenSends = ref('15');
    const slowdownOptions = {
      slowdown: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],

      interval_between_sends: [
        { value: '15', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.15') },
        { value: '30', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.30') },
        { value: '60', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.60') },
        { value: '90', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.90') },
        { value: '120', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.120') },
        { value: '150', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.150') },
        { value: '180', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.180') },
        { value: '210', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.210') },
        { value: '240', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.240') },
        { value: '270', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.270') },
        { value: '300', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.300') },
      ],
    };

    const onHideEvent = (event: any) => {
      emit('on-hide-modal');
    };

    const onSaveScheduled = (event: any) => {
      loadingSaveScheduled.value = true;
      const settingsFields: GlobalBulkSettingsData = {
        name: (state.scenario.settingsData as GlobalBulkSettingsData).name,
        time_sending: (state.scenario.settingsData as GlobalBulkSettingsData).time_sending,
        perfect_timing: (state.scenario.settingsData as GlobalBulkSettingsData).perfect_timing,
        slowdown: (state.scenario.settingsData as GlobalBulkSettingsData).slowdown,
        interval_between_sends: (state.scenario.settingsData as GlobalBulkSettingsData).interval_between_sends,
        commercial_campaign: (state.scenario.settingsData as GlobalBulkSettingsData).commercial_campaign,
      };

      if (typeSending.value === 0) {
        let scheduledStringDate = moment(selectedDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        scheduledStringDate = `${scheduledStringDate} ${selectedTime.value}:00`;
        const clientMoment = moment.tz(scheduledStringDate, selectedTimezone.value);
        const parisMoment = clientMoment.clone().tz('Europe/Paris');
        settingsFields.time_sending = parisMoment.format('YYYYMMDDHHmmss');
      } else {
        const nowInParis = moment.tz('Europe/Paris');
        settingsFields.time_sending = nowInParis.format('YYYYMMDDHHmmss');
      }

      if (selectedPerfectTiming.value === 1) {
        settingsFields.perfect_timing = 1;
      } else {
        settingsFields.perfect_timing = 0;
        /* Gestion de l'étalement de l'envoi dans le temps */
        if (selectedSlowdown.value === 1) {
          settingsFields.slowdown = 1;
          settingsFields.interval_between_sends = selectedIntervalBetweenSends.value;
        } else {
          settingsFields.slowdown = 0;
          settingsFields.interval_between_sends = slowdownOptions.interval_between_sends[0].value;
        }
      }

      confirm.require({
        target: event.currentTarget,
        group: 'sendCampaignBulk',
        header: typeSending.value === 0
          ? t('automatedScenarios.globalBulkSettings.action_sending.confirm_later.header')
          : t('automatedScenarios.globalBulkSettings.action_sending.confirm_now.header'),
        message: typeSending.value === 0
          ? t('automatedScenarios.globalBulkSettings.action_sending.confirm_later.message')
          : t('automatedScenarios.globalBulkSettings.action_sending.confirm_now.message'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: async () => {
          setGlobalSettingsData(settingsFields);
          emit('on-save-scheduled');
          loadingSaveScheduled.value = false;
        },
        reject: () => {
          loadingSaveScheduled.value = false;
        },
        onHide: () => {
          loadingSaveScheduled.value = false;
        },
      });
    };

    /**
     * Populate timezone options
     */
    const populateTimezoneOptions = async () => {
      const allowedContinents = [
        'US', 'Europe', 'Africa', 'UTC', 'INDIAN', 'Canada', 'Brazil', 'Australia', 'Atlantic', 'Asia',
      ];

      // get timezones grouped by continent
      const getTimezonesGroupedByContinent = () => {
        const zones = moment.tz.names();
        const grouped: any = {
          Europe: [],
        };

        zones.forEach((zone) => {
          const parts = zone.split('/');
          const continent = parts[0];

          if (!allowedContinents.includes(continent)) {
            return;
          }

          if (!grouped[continent]) {
            grouped[continent] = [];
          }

          grouped[continent].push(zone);
        });

        return grouped;
      };
      const groupedTimezones = getTimezonesGroupedByContinent();

      // populate timezone options
      Object.keys(groupedTimezones).forEach((continent) => {
        const continentTimezones = groupedTimezones[continent];
        const continentTimezonesOptions = continentTimezones.map((timezone: string) => {
          const timezoneLabel = timezone.replace(/_/g, ' ');
          return { value: timezone, label: timezoneLabel };
        });

        timezoneOptions.value.push({
          label: continent,
          code: continent,
          items: continentTimezonesOptions,
        });
      });

      // get shop timezone and suggest it as first option
      const { item, err } = await Get<Shops>({
        id: UserState.activeShop?.id ?? 0,
        name: 'Shops',
        keyName: 'id_shop',
        fields: [
          'timezone_name',
        ],
      });

      let suggestTimezone = [{ value: 'Europe/Paris', label: 'Europe/Paris' }];
      const clientTimezone = moment.tz.guess();
      if (item && item.timezone_name && item.timezone_name !== '') {
        suggestTimezone = [{ value: item.timezone_name, label: item.timezone_name }];
        if (clientTimezone && clientTimezone !== '' && clientTimezone !== item.timezone_name) {
          suggestTimezone.push({ value: clientTimezone, label: clientTimezone });
        }
      } else if (clientTimezone && clientTimezone !== '') {
        suggestTimezone = [{ value: clientTimezone, label: clientTimezone }];
      }

      timezoneOptions.value.unshift({
        label: t('timezone_suggestion'),
        value: 'Suggestion',
        items: suggestTimezone,
      });
    };

    onMounted(async () => {
      await populateTimezoneOptions();
      selectedTimezone.value = timezoneOptions.value[0].items[0].value;
      // const date = moment();
      // const remainder = 15 - (date.minute() % 15);
      // date.add(remainder, 'minutes');
      // date.seconds(0);
      // selectedTime.value = date.format('HH:mm');
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      displayBasic,
      onHideEvent,
      onSaveScheduled,
      loadingSaveScheduled,
      typeSending,
      selectedDate,
      selectedTime,
      timeOptions,
      selectedTimezone,
      timezoneOptions,
      selectedPerfectTiming,
      perfectTimingOptions,
      selectedSlowdown,
      selectedIntervalBetweenSends,
      slowdownOptions,
    };
  },
});
