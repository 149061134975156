
import {
  defineComponent,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
  onUnmounted,
  onMounted,
} from 'vue';

import { useI18n } from 'vue-i18n';

import ToggleButton from 'primevue/togglebutton';
import InputText from 'primevue/inputtext';
import Emoji from '@/components/fields/partials/Emojis.vue';
import Translate from '@/components/fields/partials/Translate.vue';
import Variables from '@/components/fields/partials/Variables.vue';
import { MultiLangInput } from '@/types/store-parameters-types';
import {
  Translation,
} from '@/types';
import Button from 'primevue/button';
import TieredMenu from 'primevue/tieredmenu';
import AIButton from '@/components/fields/AIButton.vue';

// eslint-disable-next-line import/no-cycle
import {
  getTranslationLabel,
} from '@/components/template-builder/utils/helpers';

import { trimExcessSpaces } from '@/helpers';

import { Variable } from '../template-builder/utils/variables-list';

export default defineComponent({
  name: 'BaseInputText',
  components: {
    InputText,
    Variables,
    Emoji,
    Translate,
    AIButton,
    TieredMenu,
    Button,
  },

  props: {
    modelValue: {
      type: String,
      required: true,
    },

    displayEmojis: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayTranslate: {
      type: Boolean,
      required: false,
      default: false,
    },

    displayAiButton: {
      type: Boolean,
      required: false,
      default: false,
    },

    aiParams: {
      type: Object,
      required: false,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },

    displayVariables: {
      type: Boolean,
      required: false,
      default: false,
    },

    variablesList: {
      type: Array as PropType<Variable[]>,
      required: false,
      default: () => [],
    },

    allMultiLangInput: {
      type: Object as PropType<MultiLangInput>,
      required: false,
    },

    selectedLanguage: {
      type: String,
      required: false,
    },

    defaultLanguage: {
      type: String,
      required: false,
    },

    parentRendered: {
      type: Boolean,
      required: false,
      default: false,
    },

    translation: {
      type: Object as PropType<Translation>,
      required: false,
      default: null,
    },
  },

  emits: {
    'update:modelValue': String,
    'activate-group': String,
    'deactivate-group': String,
  },

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();
    const displayInputDialogOptions = ref(false);
    const inputValue: Ref<string> = ref(props.modelValue);
    const cursorPosition = ref(0);
    const tieredMenuRef = ref();
    const parentElementRef = ref(null);
    const toggle = (event: Event) => tieredMenuRef.value.toggle(event);
    const timeoutId = ref();

    const insertContent = (value: string|number|undefined) => {
      inputValue.value = inputValue.value.substring(0, cursorPosition.value)
        + value
        + inputValue.value.substring(cursorPosition.value, inputValue.value.length);
      cursorPosition.value += value ? (value.toString().length) : 0;
    };

    const replaceContent = (value: string) => {
      if (value !== undefined) {
        inputValue.value = value;
      }
    };

    watch(() => props.modelValue, (newValue) => {
      if (newValue !== undefined) {
        inputValue.value = newValue;
      }
    });

    watch(inputValue, () => {
      // Implements a debounce to prevent some list to not be refreshed
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
      timeoutId.value = setTimeout(() => {
        emit('update:modelValue', inputValue.value);
      }, 500);
    });

    onUnmounted(() => {
      if (timeoutId.value) {
        clearTimeout(timeoutId.value);
      }
    });

    onMounted(() => {
      inputValue.value = trimExcessSpaces(inputValue.value);
    });

    const dataMenu: any[] = [
    ];

    const addDataMenu = (value: any) => {
      // Utilisation de l'opérateur push pour ajouter une nouvelle entrée à dataMenu
      dataMenu.push(value);
    };

    const createUniqueString = () => Math.random().toString(36).substring(2, 15) + Date.now().toString(36);

    const inputTextRef = ref();

    // Used when selecting widget from the editor
    const focus = () => {
      if (inputTextRef.value) {
        inputTextRef.value.$el.blur();
        inputTextRef.value.$el.focus();
      }
    };

    const handleFocus = () => {
      if (props.translation) {
        emit('activate-group', props.translation.groupKey);
      }
    };

    const handleBlur = (event: any) => {
      cursorPosition.value = event.target.selectionStart;
      if (props.translation) {
        emit('deactivate-group', props.translation.groupKey);
      }
    };

    return {
      t,
      inputValue,
      insertContent,
      replaceContent,
      displayInputDialogOptions,
      toggle,
      tieredMenuRef,
      dataMenu,
      addDataMenu,
      parentElementRef,
      inputTextRef,
      getTranslationLabel,
      handleFocus,
      handleBlur,
      inputUniqueId: `unique-input-${createUniqueString()}`,
      focus,
    };
  },
});
