
import {
  defineComponent,
  computed,
  ref,
  Ref,
} from 'vue';

import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';

import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import ComponentsServiceParameters from '@/views/shop/tabs/ServicesParameters/services';

import { fetchStoreParamsConfigurations } from '@/composables/shop/MyShopParameters';
import { UserState } from '@/composables/User';

import { showToastError, showToastSuccess } from '@/helpers';
import { ShopsConfigurationInput, ShopsConfigurationInputItem } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';

import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ServicesParametersModal',
  components: {
    Dialog,
    TabView,
    TabPanel,
    SpmButton,
    Message,
    ...ComponentsServiceParameters,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const shopId = UserState.activeShop?.id ?? 0;
    const shopUrl = UserState.activeShop?.url ?? '';

    const serviceRef = ref({});
    const saveLoading = ref(false);
    const errors: Ref<Record<string, number>> = ref({});

    const isNotAccessibleDialogVisible = computed(() => store.getters['general/getIsServicesParametersModalVisible']);
    const notConfiguredServices = computed(() => store.getters['general/getNotConfiguredServices']);
    const serviceParameterSuccessAction = computed(() => store.getters['general/getServiceParameterSuccessAction']);

    const handleHideDialog = async (canceled = false) => {
      store.commit('general/setIsServicesParametersModalVisible', false);
      store.commit('general/setNotConfiguredServices', []);
      store.commit('general/setServiceParameterSuccessAction', null);
      if (canceled) {
        store.commit('general/setServiceParameterIsCanceled', true);
      }
    };

    const handleSave = async () => {
      errors.value = {};

      const input: ShopsConfigurationInput = {
        id_shop: shopId,
        configs: [],
      };

      const additionnalSaves = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const service of Object.values<any>(serviceRef.value)) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const { err, configs, additionnalSave } = await service.handleSave();
          if (err) {
            errors.value[service.name] = err;
          } else {
            configs.forEach((config: ShopsConfigurationInputItem) => {
              if (input.configs) {
                input.configs.push(config);
              }
            });
            if (additionnalSave) {
              additionnalSaves.push(additionnalSave);
            }
          }
        } catch (error) {
          showToastError('GENERIC_ERROR');
        }
      }

      if (Object.values(errors.value).reduce((a, b) => a + b, 0) === 0) {
        try {
          saveLoading.value = true;
          if (input.configs && input.configs.length) {
            await SaveShopsConfiguration(input);
            await saveShopParamsOnRedis(shopId);
          }
          additionnalSaves.forEach(async (additionnalSave) => {
            await additionnalSave();
          });
          showToastSuccess('savedSuccessful');
          if (serviceParameterSuccessAction.value) {
            serviceParameterSuccessAction.value();
          }
          handleHideDialog();
        } catch (err) {
          showToastError('GENERIC_ERROR');
        } finally {
          saveLoading.value = false;
        }
      }
    };

    const retrieveServicesData = async (field: any) => {
      const {
        data,
        err,
      } = await fetchStoreParamsConfigurations(shopId);
      const defaultValuesKeys = Object.keys(field);
      const exclusions = ['shopRequiredDataSocialNetworks_facebook',
        'shopRequiredDataSocialNetworks_googleplus',
        'shopRequiredDataSocialNetworks_instagram',
        'shopRequiredDataSocialNetworks_pinterest',
        'shopRequiredDataSocialNetworks_snapchat',
        'shopRequiredDataSocialNetworks_tumblr',
        'shopRequiredDataSocialNetworks_twitter',
        'shopRequiredDataSocialNetworks_whatsapp',
        'shopRequiredDataSocialNetworks_youtube'];

      if (data === null || data.length === 0) {
        return;
      }

      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        for (let j = 0; j < defaultValuesKeys.length; j++) {
          if (record.key === defaultValuesKeys[j]) {
            if (record.lang && typeof field[defaultValuesKeys[j]] === 'object') {
              let objectValue: any = {};
              objectValue = field[defaultValuesKeys[j]];
              objectValue[record.lang] = record.value;
              // eslint-disable-next-line no-param-reassign
              field[defaultValuesKeys[j]] = objectValue;
            } else if (!exclusions.includes(record.key)) {
              // eslint-disable-next-line no-param-reassign
              field[defaultValuesKeys[j]] = record.value;
            }
          }
        }
      }
    };

    return {
      t,
      isNotAccessibleDialogVisible,
      notConfiguredServices,
      shopId,
      shopUrl,
      saveLoading,
      serviceRef,
      errors,

      handleHideDialog,
      handleSave,
      retrieveServicesData,
    };
  },
});
