import { ComponentNameEnum, DropdownOption } from '@/types';

const getCodeFieldConfig = (defaultValue = '', displayVariables = false, variablesList: DropdownOption[] = []) => ({
  componentName: ComponentNameEnum.CODE,
  options: {
    defaultValue,
    variablesList,
    displayVariables,
  },
});

export default getCodeFieldConfig;
