
import {
  computed,
  defineComponent, onBeforeMount, watch, ref,
} from 'vue';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import SegmentationBlockAND from '@/components/segmentations/SegmentationBlockAND.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import Loader from '@/components/layout/Loader.vue';
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import axios from "axios";
import {UserState} from "@/composables/User";
import {showToastError} from "@/helpers";
import { formatNumbersInString } from '@/helpers/Number';

export default defineComponent({
  name: 'SegmentationsPanel',
  components: {
    Loader,
    SegmentationBlockAND,
    Button,
    SpmButton,
    Tag,
    Skeleton,
  },

  props: {
    origin: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props: { origin: string}) {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const cleanedDataKey = ref(0);
    const segmentsConfiguration: any = computed(() => store.getters['segmentationsEditor/getConfiguration']);

    const refreshComponents: any = computed(() => store.getters['segmentationsEditor/getRefreshComponents']);

    const getInitialized: any = computed(() => store.getters['segmentationsEditor/getInitialized']);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const refreshContactsNumber = computed(() => store.getters['segmentationsEditor/getRefreshContactsNumber']);

    const loadContactsNumber = ref(false);

    const totalContactsNumber = ref(0);

    const addBlockAND = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/addBlockAND');
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
    };

    const cleanData = (data: any) => {
      const cleanedData = data.map((subArray: any) => subArray.filter((obj: any) => Object.keys(obj).length !== 0));
      return cleanedData.filter((subArray: any) => subArray.length > 0);
    };

    watch([segmentsConfiguration], () => {
      if (!segmentsConfiguration.value.length || !segmentsConfiguration.value) {
        store.commit('segmentationsEditor/initConfiguration');
      }
    }, { deep: true });

    const handleContactsNumber = async () => {
      loadContactsNumber.value = true;
      const tmpCleanedData = cleanData(segmentsConfiguration.value);
      try {
        const result = await axios.post(
          `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/count`,
          {
            idShop,
            config: tmpCleanedData,
          },
        );

        if (result && (result.status === 200 || result.status === 201)) {
          totalContactsNumber.value = result.data;
        } else {
          totalContactsNumber.value = 0;
          await showToastError('GENERIC_ERROR');
        }
      } catch (e) {
        totalContactsNumber.value = 0;
        await showToastError('GENERIC_ERROR');
      }


      loadContactsNumber.value = false;
      store.commit('segmentationsEditor/setRefreshContactsNumber', false);
    }

    watch([refreshContactsNumber], async () => {
      if (refreshContactsNumber.value) {
        await handleContactsNumber();
      }
    });

    onBeforeMount(async () => {
      await store.dispatch('segmentationsEditor/initialization',
        { originCall: props.origin });
      await handleContactsNumber();
    });

    return {
      t,
      cleanedDataKey,
      getInitialized,
      refreshComponents,
      segmentsConfiguration,
      loadContactsNumber,
      totalContactsNumber,
      addBlockAND,
      handleContactsNumber,
    };
  },

  methods: { formatNumbersInString }
});
