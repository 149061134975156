
import {
  computed, defineComponent, ref, Ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import { PrimvueMenuModel } from '@/types';
import { findPermission, Logout, UserState } from '@/composables/User';
import { getErrorMessage, showToastError } from '@/helpers';
import { useRouter } from 'vue-router';
import SpmPanelMenu from '@/components/spm-primevue/SpmPanelMenu.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';

export default defineComponent({
  name: 'MyProfileMenu',
  components: {
    SpmOverlayPanel,
    SpmPanelMenu,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    // eslint-disable-next-line max-len
    const initials = computed(() => ((UserState.user?.firstName && UserState.user?.lastName) ? `${UserState.user.firstName.substring(0, 1)}${UserState.user.lastName.substring(0, 1)}` : ''));

    const logout = async () => {
      store.commit('general/showTheSpinner');
      try {
        await Logout();
      } catch (error) {
        await showToastError(getErrorMessage(error));
      } finally {
        await router.push({ name: 'login' });
        window.location.reload();
      }
    };

    const profileMenuItems: Ref<PrimvueMenuModel[]> = ref([
      {
        icon: 'fa-regular fa-user',
        label: t('profileMenu.myInformation'),
        to: { name: 'user.profile' },
      },
      {
        icon: 'fa-regular fa-chart-pie',
        label: t('profileMenu.statsListing'),
        to: { name: 'users.stats' },
      },
      {
        icon: 'fa-regular fa-users-gear',
        label: t('profileMenu.permissions'),
        to: { name: 'user.sub-user-list' },
        visible: () => findPermission('admin'),
      },
      // {
      //   icon: 'fa-regular fa-gear',
      //   label: t('profileMenu.manageMyAccount'),
      //   to: { name: 'user.manage-my-account' },
      // },
    ]);

    const logoutItem: Ref<PrimvueMenuModel[]> = ref([
      {
        label: t('profileMenu.logout'),
        icon: 'fa-regular fa-arrow-right-from-bracket',
        command: logout,
        class: 'data-test-logout-btn',
      },
    ]);

    return {
      t,
      UserState,
      profileMenuItems,
      logoutItem,
      initials,
    };
  },
});
