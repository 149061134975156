import { StoreCategory } from '@/types/store-parameters-types';

const categories: StoreCategory[] = [
  { code: 1, label: 'storeParameters.storeInformations.categories.food' },
  { code: 2, label: 'storeParameters.storeInformations.categories.vehicle' },
  { code: 3, label: 'storeParameters.storeInformations.categories.culture' },
  { code: 4, label: 'storeParameters.storeInformations.categories.appliances' },
  { code: 5, label: 'storeParameters.storeInformations.categories.gifts' },
  { code: 6, label: 'storeParameters.storeInformations.categories.hifi' },
  { code: 7, label: 'storeParameters.storeInformations.categories.technology' },
  { code: 8, label: 'storeParameters.storeInformations.categories.home' },
  { code: 9, label: 'storeParameters.storeInformations.categories.nature' },
  { code: 10, label: 'storeParameters.storeInformations.categories.health' },
  { code: 11, label: 'storeParameters.storeInformations.categories.individuals' },
  { code: 12, label: 'storeParameters.storeInformations.categories.professionals' },
  { code: 13, label: 'storeParameters.storeInformations.categories.sport' },
  { code: 14, label: 'storeParameters.storeInformations.categories.communication' },
  { code: 15, label: 'storeParameters.storeInformations.categories.travel' },
  { code: 16, label: 'storeParameters.storeInformations.categories.clothes' },
  { code: 17, label: 'storeParameters.storeInformations.categories.brand' },
  { code: 18, label: 'storeParameters.storeInformations.categories.children' },
  { code: 19, label: 'storeParameters.storeInformations.categories.dematerialized' },
  { code: 20, label: 'storeParameters.storeInformations.categories.other' },
];

export default categories;
