<template>
  <div class="connect-shop mx-auto">
    <div class="flex flex-column p-4 justify-content-center align-items-center">
      <div class="w-6rem">
        <img
          src="@/assets/images/logo-sm-green.svg"
          alt="logo Icon"
        >
      </div>
      <h3 class="text-2xl font-semibold">
        {{ title }}
      </h3>
      <p class="text-sm">
        {{ subTitle }}
      </p>
    </div>
    <div class="flex mx-auto flex-column">
      <img
        src="@/assets/images/loader.svg"
        alt="Loader Icon"
        height="150"
      >
      <div class="flex p-4 align-items-center justify-content-center text-center flex-column">
        <p class="flex">
          {{ t('complete.welcome.waiting1') }}
        </p>
        <p class="flex">
          {{ t('complete.welcome.waiting2') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { checkIfDbCreated } from '@/configs/complete';

export default defineComponent({
  name: 'Welcome',

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup() {
    const { t } = useI18n();

    onMounted(async () => {
      const interval = setInterval(async () => {
        try {
          const dbCreated = await checkIfDbCreated();
          if (dbCreated) {
            clearInterval(interval);
            window.location.reload();
          }
          // eslint-disable-next-line no-empty
        } catch (error) {
        }
      }, 5000);
    });

    return {
      t,
    };
  },

});
</script>

<style lang="scss">
.connect-shop {
  .p-card {
    box-shadow: none !important;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  .p-card-body {
    padding: 0 !important;
    .p-card-content {
      padding: 1rem;
      padding-top: 0rem;
    }
  }
  .p-card-title {
    border-bottom: none !important;
    padding: 10px 15px;
  }
  .instruction-number {
    width: 1rem;
  }
}
</style>
