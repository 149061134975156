
import {
  defineComponent,
  SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import MenuItemsNestedDraggable from '@/components/stats-builder/fields/MenuItemsNestedDraggable.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'MenuItemsManage',

  components: {
    MenuItemsNestedDraggable,
    Button,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  setup(props: { list: any[]; type: string }, context: SetupContext) {
    const { t } = useI18n();
    const addItem = () => {
      let newItem: any;
      if (props.type === 'menu') {
        newItem = {
          label: Object.assign(
            {},
            ...defaultLanguages.map((lang) => ({ [lang]: 'Menu' })),
          ),

          children: [],
          items: [],
        };
      } else {
        newItem = {
          name: 'Onglet',
          children: [],
        };
      }

      // eslint-disable-next-line vue/no-mutating-props
      props.list.push(newItem);
    };

    return {
      t,
      addItem,
    };
  },
});
