<template>
  <div class="grid py-3">
    <div class="col-6">
      <h3>
        {{ t('myLists.importReports.title') }}
      </h3>
    </div>
    <div class="col-6 text-right">
      <Button
        :label="t('myLists.importReports.return')"
        type="button"
        icon="fa fa-arrow-left"
        class="p-button p-button-secondary"
        @click="navigate('manage')"
      />
    </div>
  </div>
  <div class="dataTable">
    <SpmTable
      name="ShopsListsImport"
      index="ID"
      :persistent-filters="persistentFilters"
      :table-columns="columns"
      :id-shop="idShop"
    >
      <template #shopsLists{name},date="slotProps">
        {{ t('myLists.importReports.headers.nameText', [slotProps.data.shopsLists.name, slotProps.data.date]) }}
      </template>
      <template #nb_customers="slotProps">
        <h1>
          {{ slotProps.data.nb_customers }}
        </h1>
        <span style="font-weight: bold;">TOTAL</span>
      </template>
      <template #nb_imported_customers="slotProps">
        <h1>
          {{ slotProps.data.nb_imported_customers }}
        </h1>
        <span style="font-weight: bold;">TOTAL</span>
      </template>
      <template #nb_partially_imported_customers="slotProps">
        <h1>
          {{ slotProps.data.nb_partially_imported_customers }}
        </h1>
        <span>{{ (slotProps.data.nb_partially_imported_customers/ slotProps.data.nb_customers).toFixed(2) * 100 }}%</span>
      </template>
      <template #nb_non_imported_customers="slotProps">
        <h1>
          {{ slotProps.data.nb_non_imported_customers }}
        </h1>
        <span>{{ (slotProps.data.nb_non_imported_customers/ slotProps.data.nb_customers).toFixed(2) * 100 }}%</span>
      </template>
      <template #Actions="slotProps">
        <SpmOverlayPanel
          class-trigger="flex align-items-center justify-content-center"
          class-content="flex align-items-center justify-content-center spm-overlay-panel-content"
        >
          <template #trigger>
            <i class="far fa-ellipsis-v fa-2x" />
          </template>
          <PanelMenu
            :model="options"
            class="spm-panel-menu"
            :disabled="!slotProps.data.path_report_folder"
          >
            <template #item="{ item }">
              <a
                v-if="item.label === t('myLists.importReports.download.importedContacts')"
                :href="slotProps.data.path_report_folder ? baseUrl + slotProps.data.path_report_folder + 'report_success.csv': 'javascript:void(0);'"
                :target="slotProps.data.path_report_folder ? '_blank': ''"
              >{{ item.label }}</a>
              <a
                v-else-if="item.label === t('myLists.importReports.download.partially') "
                :href="slotProps.data.path_report_folder ? baseUrl + slotProps.data.path_report_folder + 'report_non_blocking.csv': 'javascript:void(0);'"
                :target="slotProps.data.path_report_folder ? '_blank': ''"
              >{{ item.label }}</a>
              <a
                v-else-if="item.label === t('myLists.importReports.download.notImported') "
                :href="slotProps.data.path_report_folder ? baseUrl + slotProps.data.path_report_folder + 'report_blocking.csv': 'javascript:void(0);'"
                :target="slotProps.data.path_report_folder ? '_blank': ''"
              >{{ item.label }}</a>
            </template>
          </PanelMenu>
        </SpmOverlayPanel>
      </template>
    </SpmTable>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import { UserState } from '@/composables/User';
import { defineComponent, ref } from 'vue';
import { SpmTableColumns } from '@/types';
import { OperatorType } from '@/types/generated-types/graphql';
import Button from 'primevue/button';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';
import PanelMenu from 'primevue/panelmenu';

export default defineComponent({
  name: 'ListsImportReports',

  components: {
    SpmTable,
    Button,
    SpmOverlayPanel,
    PanelMenu,
  },

  setup() {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const navigate = (where: string) => {
      router.push({ path: where });
    };

    const persistentFilters = [
      {
        field: 'id_shop',
        operator: OperatorType.Equals,
        value: idShop,
      },
      {
        field: 'state',
        operator: OperatorType.In,
        value: 'processed,report',
      },
      {
        field: 'date',
        operator: OperatorType.IsNotNull,
        value: 'null',
      },
    ];

    const baseUrl = `http://${process.env.VUE_APP_DOMAIN_EXPORT_LIST}/`;

    const options = ref([
      { label: t('myLists.importReports.download.importedContacts') },
      { label: t('myLists.importReports.download.partially') },
      { label: t('myLists.importReports.download.notImported') },
    ]);

    const columns: SpmTableColumns[] = [
      {
        field: 'shopsLists{name},date',
        header: t('myLists.importReports.headers.name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle; width: 20%',
        type: 'text',
      },
      {
        field: 'nb_customers',
        header: t('myLists.importReports.headers.noContacts'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'nb_imported_customers',
        header: t('myLists.importReports.headers.imported'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'nb_partially_imported_customers',
        header: t('myLists.importReports.headers.partiallyImported'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'nb_non_imported_customers',
        header: t('myLists.importReports.headers.unImported'),
        sortable: true,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'Actions',
        header: t('myLists.importReports.headers.action'),
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
        custom: true,
      },
    ];

    return {
      t, navigate, columns, options, baseUrl, persistentFilters, idShop,
    };
  },
});
</script>

<style scoped>
tbody td {
  text-align: center;
  vertical-align: middle;
}

.btn-info {
  background-color: #EFAC06;
  border: 1px solid #EFAC06;
}
</style>
