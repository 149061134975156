<template>
  <label class="block mb-2">
    {{ t(title) }}
  </label>
  <div class="flex align-items-center">
    <div class="col-6">
      <label class="block mb-1">
        {{ t('automatedScenarios.fields.between') }}
      </label>
      <BaseInputNumber
        v-model="range.min"
        :min="rulesMinMax.left.min"
        :max="rulesMinMax.left.max"
        class="value-input"
        :suffix=" suffix"
      />
    </div>
    <div
      v-if="range.noMax !== 'on'"
      class="col-6"
    >
      <label class="block mb-1">
        {{ t('automatedScenarios.fields.and') }}
      </label>
      <BaseInputNumber
        v-model="range.max"
        :min="rulesMinMax.right.min"
        :max="rulesMinMax.right.max"
        class="value-input"
        :suffix=" suffix"
      />
    </div>
  </div>
  <div class="text-right">
    <span
      class="checkbox inline-block mb-3"
    >
      <Checkbox
        v-model="range.noMax"
        binary
        true-value="on"
      />
      {{ t('automatedScenarios.fields.noMaxLimit') }}
    </span>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent, onMounted, PropType, ref, Ref, SetupContext, watch,
} from 'vue';
import Checkbox from 'primevue/checkbox';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import { Range } from '@/types/automated-scenarios';
import { useI18n } from 'vue-i18n';
import { asInt } from '@/helpers';

export default defineComponent({
  name: 'NumberRange',

  components: {
    BaseInputNumber,
    Checkbox,
  },

  props: {
    modelValue: {
      type: Object as PropType<Range>,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    suffix: {
      type: String,
      required: false,
      default: ' €',
    },

    min: {
      type: Number,
      required: false,
      default: 1,
    },

    max: {
      type: Number,
      required: false,
      default: 100000,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const range: Ref<Range> = ref({
      min: (asInt(props.modelValue.min) ?? 1),
      max: ((props.modelValue.max && asInt(props.modelValue.max)) ?? 100000),
      noMax: props.modelValue.noMax,
    });

    const rulesMinMax = computed(() => ({
      left: {
        min: props.min,
        max: (range.value.max && range.value.noMax !== 'on') ? (range.value.max - 1) : props.max,
      },
      right: {
        min: range.value.min + 1,
        max: props.max,
      },
    }));

    watch(range.value, () => {
      if (range.value.noMax && range.value.noMax === 'on') {
        if (range.value.max && range.value.max <= range.value.min) {
          range.value.max = range.value.min + 1;
        }
        emit('update:modelValue', {
          min: range.value.min,
          noMax: range.value.noMax,
        });
      } else {
        emit('update:modelValue', {
          min: range.value.min,
          max: range.value.max,
        });
      }
    });

    // emit event if user don't change default values
    onMounted(() => emit('update:modelValue', range.value));

    return {
      t,
      range,
      rulesMinMax,
    };
  },
});
</script>

<style lang="scss" scoped>
.value-input{
  margin-right: 1rem;
  flex-grow: 1;
}

.value-input :deep() .p-inputnumber-input.p-inputtext {
  width: 6rem;
}

.checkbox {
  font-size: 0.8em;
  > .p-checkbox {
    width: 15px !important;
    height: 15px !important;
    > :deep() .p-checkbox-box {
      width: 15px !important;
      height: 15px !important;
    }
  }
}
</style>
