<template>
  {{ translatable ? (content ? t(content, null) : '') : (content ?? '') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TextRenderer',

  props: {
    content: {
      type: [String, Number],
      required: false,
      default: '',
    },

    translatable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },

});
</script>
