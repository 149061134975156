import moment from 'moment';

export default function useDateFormat() {
  const toLocaleDateString = (date: string) => new Date(date).toLocaleDateString();

  /**
   * @param date
   * @param locale
   * @param options
   *
   * Usage:
   *  - datetimeFormatted('', 'fr_FR', {})
   */
  const datetimeFormatted = (date: string, locale: string, options: object) => {
    const dtFormat = new Intl.DateTimeFormat(locale, options);
    return dtFormat.format(new Date(date));
  };

  /**
   * Compare 2 dates
   * - If date1 = date2 => returns zero
   * - If date1 > date2 => returns +ve value
   * - If date1 < date2 => returns -ve value
   * @param date
   */
  interface CompareDatesArgs {
    date1: string;
    date2: string;
  }
  const compareDates = ({ date1, date2 }: CompareDatesArgs) => new Date(date1).getTime() - new Date(date2).getTime();

  const toLocaleDateBigInt = (bigIntDate: any) => {
    const year = bigIntDate.substring(0, 4);
    const month = bigIntDate.substring(4, 6);
    const day = bigIntDate.substring(6, 8);
    const hour = bigIntDate.substring(8, 10);
    const minute = bigIntDate.substring(10, 12);
    const second = bigIntDate.substring(12, 14);
    const mysqlDateStr = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    const date = new Date(mysqlDateStr);
    return moment(date).format('L LT');
  };

  return {
    toLocaleDateString, datetimeFormatted, compareDates, toLocaleDateBigInt,
  };
}
