<template>
  <div class="field-wrapper">
    <label class="block mb-2">
      {{ t(title) }}
    </label>
    <AutoComplete
      v-model="inputs"
      :multiple="true"
      :auto-highlight="false"
      :suggestions="filteredItems"
      class="spm-auto-complete"
      :field="field"
      @complete="handleComplete($event)"
      @update:model-value="handleUpdateModelValue"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onUpdated,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import AutoComplete from 'primevue/autocomplete';
import { DomHandler } from 'primevue/utils';

export default defineComponent({
  name: 'EditAutocomplete',

  components: {
    AutoComplete,
  },

  props: {
    modelValue: {
      type: Object as PropType<string[]>,
      required: true,
    },

    complete: {
      type: Function,
      required: true,
    },

    field: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    addToList: {
      type: Function,
      required: false,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: string[]; complete: Function; addToList?: Function }, { emit }: SetupContext) {
    const { t } = useI18n();
    const filteredItems = ref();
    const inputs: Ref<string[]> = ref(props.modelValue ?? []);

    const handleComplete = async ({ originalEvent, query }: {originalEvent: InputEvent; query: string}) => {
      filteredItems.value = (await props.complete(query));
    };

    const handleUpdateModelValue = async (value: any) => {
      if (props.addToList) {
        inputs.value = await props.addToList(value);
      }
    };

    watch(inputs, () => {
      emit('update:modelValue', inputs.value);
    });

    // Bug in primevue autoHighlight functionality, select first element manually
    onUpdated(() => {
      const firstItem: HTMLElement = document.querySelector('.p-autocomplete-items > li') as HTMLElement;
      if (firstItem) {
        DomHandler.addClass(firstItem, 'p-highlight');
      }
    });

    return {
      t,
      filteredItems,
      inputs,
      handleComplete,
      handleUpdateModelValue,
    };
  },
});
</script>

<style lang="scss">
.spm-auto-complete {
  .p-inputtext {
    box-shadow: none !important;
    border-color: $heather !important;
    height: unset !important;
    min-height: $field-default-height !important;
  }

  .p-autocomplete-token {

  }
}
</style>
