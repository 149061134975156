
import {
  defineComponent,
  computed,
  PropType,
} from 'vue';

import SelectButton from 'primevue/selectbutton';

import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Icon',

  components: {
    SelectButton,
    SimpleColorPicker,
  },

  props: {
    modelValue: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const yesno = [
      { name: t('yes'), value: '1' },
      { name: t('no'), value: '0' },
    ];

    const field = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    return {
      t,
      field,
      yesno,
    };
  },

});
