
import {
  defineComponent, onBeforeMount, onMounted, ref,
} from 'vue';
import ComponentsComplete from '@/components/complete';
import { Logout, Me, UserState } from '@/composables/User';
import Loader from '@/components/layout/Loader.vue';
import { UserInfosData } from '@/components/complete/metadata/UserInfosMetadata';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
import Header from '@/components/layout/Header.vue';
import MyProfileMenu from '@/components/header/MyProfileMenu.vue';
import MySitesMenu from '@/components/header/MySitesMenu.vue';
import MyNotificationsMenu from '@/components/header/MyNotificationsMenu.vue';
import MyStoreMenu from '@/components/header/MyStoreMenu.vue';
import { getErrorMessage, showToastError } from '@/helpers';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { useI18n } from 'vue-i18n';
import { checkIfShopConnected } from '@/configs/complete';
import { nestPost } from '@/composables/nestApi';
import { getLocalStorageElement } from '@/helpers/LocalStorage';
import ReloadNotice from '@/views/layout/ReloadNotice.vue';

export default defineComponent({
  name: 'Complete',

  components: {
    ReloadNotice,
    SpmButton,
    MyStoreMenu,
    MyNotificationsMenu,
    MySitesMenu,
    MyProfileMenu,
    Header,
    Loader,
    ...ComponentsComplete,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const showReloadNotice = ref(false);

    const steps: any = ref([
      {
        title: t('complete.connectShop.step.title'),
        subtitle: t('complete.connectShop.step.subtitle'),
        icon: t('complete.connectShop.step.icon'),
        component: 'ConnectShop',
      },
      {
        title: t('complete.configureShop.step.title'),
        subtitle: t('complete.configureShop.step.subtitle'),
        icon: t('complete.configureShop.step.icon'),
        component: 'ConfigureShop',
      },
      {
        title: t('complete.welcome.step.title'),
        subtitle: t('complete.welcome.step.subtitle'),
        icon: t('complete.welcome.step.icon'),
        component: 'Welcome',
      },
    ]);

    const activeStep: any = ref(false);
    const activeStepIndex = ref(0);

    const handleNext = () => {
      activeStepIndex.value += 1;
      activeStep.value = steps.value[activeStepIndex.value];
    };

    const logout = async () => {
      store.commit('general/showTheSpinner');
      try {
        await Logout();
      } catch (error) {
        await showToastError(getErrorMessage(error));
      } finally {
        await router.push({ name: 'login' });
        window.location.reload();
      }
    };

    const checkIfSameShopInUse = () => {
      const activeShopIdFromLocalStorage = getLocalStorageElement('spm_auth_current_shop_id', false, false);

      if ((!UserState.activeShop || UserState.activeShop.id.toString() !== activeShopIdFromLocalStorage) && !UserState.changingShop) {
        showReloadNotice.value = true;
      }
    };

    onMounted(() => {
      // Check for changes every 5 seconds
      setInterval(checkIfSameShopInUse, 5000);
    });

    onBeforeMount(async () => {
      store.commit('general/showTheSpinner');
      await Me(false);
      const userInfosData: UserInfosData = {
        firstName: UserState.user?.firstName ?? '',
        lastName: UserState.user?.lastName ?? '',
        societe: UserState.user?.societe ?? '',
        vatNumber: UserState.user?.vatNumber ?? '',
        rue: UserState.user?.rue ?? '',
        complement: UserState.user?.complement ?? '',
        zipCode: UserState.user?.zipCode ?? '',
        city: UserState.user?.city ?? '',
        country: UserState.user?.country,
        phoneMobile: UserState.user?.phoneMobile ?? '',
        phoneFixe: UserState.user?.phoneFixe ?? '',
      };

      const hasAllUserInfos = UserState.user?.idPsCustomer
        && Object.values(userInfosData).every((value) => value !== '');

      // if the user has not all the infos, we add the user infos step
      if (!hasAllUserInfos) {
        steps.value.unshift({
          title: t('complete.userInfos.step.title'),
          subtitle: t('complete.userInfos.step.subtitle'),
          icon: t('complete.userInfos.step.icon'),
          component: 'UserInfos',
          data: userInfosData,
        });
      }

      if (await checkIfShopConnected()) {
        await nestPost('v4', '/shop/createEnvironment', {}, {
          idUser: UserState.user.id ?? 0,
          idShop: UserState.activeShop?.id ?? 0,
        });
        steps.value = steps.value.filter((step: any) => step.component !== 'ConnectShop');
      }

      if (UserState.activeShop?.name && UserState.activeShop?.name !== '') {
        steps.value = steps.value.filter((step: any) => step.component !== 'ConfigureShop');
      }

      const [firstStep] = steps.value;
      activeStep.value = firstStep;
      store.commit('general/hideTheSpinner');
    });

    return {
      t,
      UserState,
      steps,
      activeStep,
      handleNext,
      logout,
      showReloadNotice,
    };
  },
});
