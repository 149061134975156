
import { defineComponent, onMounted, ref } from 'vue';
import Button from 'primevue/button';
import ProductSelection from '@/views/shop/tabs/modals/ProductSelection.vue';
import {
  Maybe, ShopsManufacturers, ShopsProducts, ShopsProductsCategoriesList,
} from '@/types/generated-types/graphql';
import CategorySelection from '@/views/shop/tabs/modals/CategorySelection.vue';
import SupplierSelection from '@/views/shop/tabs/modals/SupplierSelection.vue';
import {
  GetProductSellingsList,
  saveExclusions,
} from '@/composables/shop/ShopsConfiguration';
import { showToastSuccess } from '@/helpers';
import { unserialize } from 'php-serialize';

interface Exclusions {
  shopRequiredDataCrosselling_product_picker_categories_crosselling_exclude: {};
  shopRequiredDataCrosselling_product_picker_crosselling_exclude: {};
  shopRequiredDataCrosselling_product_picker_manufacturers_crosselling_exclude: {};
}

interface SelectedItem {
  combinations?: string[];
  id: string;
  name: string;
  spmId: number;
  image?: string;
}

interface SelectedRecord {
  operator: string;
  selected: SelectedItem[];
}

interface Selection {
  selection: string;
}

export default defineComponent({
  name: 'ProductRecommendations',

  components: {
    Button,
    ProductSelection,
    CategorySelection,
    SupplierSelection,
  },

  props: {
    translation: {
      type: Function,
      required: true,
    },

    defaultLang: {
      type: String,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const showProductSelectionModal = ref(false);
    const showProductCategoryListSelectionModal = ref(false);
    const showManufacturerSelectionModal = ref(false);
    const shopsProductsCategories = ref<ShopsProductsCategoriesList[]>([]);
    const availableCategories = ref<ShopsProductsCategoriesList[]>([]);
    const categorySearchStr = ref('');
    const manufacturers = ref<ShopsManufacturers[]>([]);
    const availableManufacturers = ref<ShopsManufacturers[]>([]);
    const manufacturerSearchStr = ref('');
    const products = ref<ShopsProducts[]>([]);
    const availableProducts = ref<ShopsProducts[]>([]);
    const productSearchStr = ref('');

    const closeProductSelectionModal = (list: ShopsProducts[], productsAvailable: ShopsProducts[], searchStr: string) => {
      showProductSelectionModal.value = false;
      products.value = list;
      availableProducts.value = productsAvailable;
      productSearchStr.value = searchStr;
    };

    const closeProductCategoryListSelectionModal = (list: ShopsProductsCategoriesList[], categoriesAvailable: ShopsProductsCategoriesList[], searchStr: string) => {
      showProductCategoryListSelectionModal.value = false;
      shopsProductsCategories.value = list;
      availableCategories.value = categoriesAvailable;
      categorySearchStr.value = searchStr;
    };

    const closeManufacturerSelectionModal = (list: ShopsManufacturers[], manufacturersAvailable: ShopsManufacturers[], searchStr: string) => {
      showManufacturerSelectionModal.value = false;
      manufacturers.value = list;
      availableManufacturers.value = manufacturersAvailable;
      manufacturerSearchStr.value = searchStr;
    };

    const saveRecommendationExclusion = async () => {
      const exclusionObject: Exclusions = {
        shopRequiredDataCrosselling_product_picker_categories_crosselling_exclude: {},
        shopRequiredDataCrosselling_product_picker_crosselling_exclude: {},
        shopRequiredDataCrosselling_product_picker_manufacturers_crosselling_exclude: {},
      };
      if (products.value && products.value.length > 0) {
        const selectedProducts: { id: number; name: string; image: Maybe<string> | undefined; spmId: number; combinations: {} }[] = [];
        products.value.forEach((product) => {
          selectedProducts.push({
            id: product.id_product,
            name: product.name,
            image: product.image_link,
            spmId: product.id_shop_product,
            combinations: {},
          });
        });

        exclusionObject.shopRequiredDataCrosselling_product_picker_crosselling_exclude = {
          selection: JSON.stringify({
            operator: 'or',
            selected: selectedProducts,
          }),
        };
      }

      if (shopsProductsCategories.value && shopsProductsCategories.value.length > 0) {
        const selectedProductsCategories: { id: number; spmId: number; name: string; combinations: {} }[] = [];
        shopsProductsCategories.value.forEach((category) => {
          selectedProductsCategories.push({
            id: category.id_category,
            name: category.name,
            spmId: category.id_shop_product_category_list,
            combinations: {},
          });
        });

        exclusionObject.shopRequiredDataCrosselling_product_picker_categories_crosselling_exclude = {
          selection: JSON.stringify({
            operator: 'or',
            selected: selectedProductsCategories,
          }),
        };
      }

      if (manufacturers.value && manufacturers.value.length > 0) {
        const selectedManufacturers: { id: string; spmId: number; name: string; combinations: {} }[] = [];
        manufacturers.value.forEach((manufacturer) => {
          selectedManufacturers.push({
            id: manufacturer.id_manufacturer,
            name: manufacturer.name,
            spmId: manufacturer.id_shop_manufacturer,
            combinations: {},
          });
        });

        exclusionObject.shopRequiredDataCrosselling_product_picker_manufacturers_crosselling_exclude = {
          selection: JSON.stringify({
            operator: 'or',
            selected: selectedManufacturers,
          }),
        };
      }

      const result = await saveExclusions(exclusionObject, props.idShop);
      if (result) {
        await showToastSuccess('savedSuccessful');
      }
    };

    onMounted(async () => {
      let unserializedRecord: Selection = {
        selection: JSON.stringify({
          operator: 'or',
          selected: [
            {
              id: '',
              name: '',
              spmId: 0,
              image: '',
            },
          ],
        }),
      };
      const key = 'shopRequiredDataCrosselling_product_picker_categories_crosselling_exclude,'
        + 'shopRequiredDataCrosselling_product_picker_crosselling_exclude,'
        + 'shopRequiredDataCrosselling_product_picker_manufacturers_crosselling_exclude';
      const result = await GetProductSellingsList(props.idShop, key, 'key, value');

      (result ?? []).forEach((record) => {
        unserializedRecord = unserialize(record.value);
        if (unserializedRecord.selection && unserializedRecord.selection !== '') {
          const jsonRecord: SelectedRecord = JSON.parse(unserializedRecord.selection);
          if (record.key === 'shopRequiredDataCrosselling_product_picker_categories_crosselling_exclude') {
            (jsonRecord.selected ?? []).forEach((selectedRecord: SelectedItem) => {
              const productCategories = {
                id_shop_product_category_list: selectedRecord.spmId,
                id_shop: props.idShop,
                id_category: Number(selectedRecord.id),
                lang: '',
                name: selectedRecord.name,
                link: '',
                active: '1',
                date_creation: '',
                date_modification: '',
                date: '',
              };
              shopsProductsCategories.value.push(productCategories);
            });
          } else if (record.key === 'shopRequiredDataCrosselling_product_picker_crosselling_exclude') {
            (jsonRecord.selected ?? []).forEach((selectedRecord: SelectedItem) => {
              const productRecord = {
                id_shop_product: selectedRecord.spmId,
                id_shop: props.idShop,
                id_product: Number(selectedRecord.id),
                lang: '',
                name: selectedRecord.name,
                link: '',
                image_link: selectedRecord.image ?? '',
                currency: '',
                price: 0,
                quantity: 0,
                active: true,
                date_creation: '',
                date_modification: '',
                date: '',
              };
              products.value.push(productRecord);
            });
          } else if (record.key === 'shopRequiredDataCrosselling_product_picker_manufacturers_crosselling_exclude') {
            (jsonRecord.selected ?? []).forEach((selectedRecord: SelectedItem) => {
              const manufacturer = {
                id_shop_manufacturer: selectedRecord.spmId,
                id_shop: props.idShop,
                id_manufacturer: selectedRecord.id,
                name: selectedRecord.name,
                active: '1',
                date_creation: '',
                date_modification: '',
                date: '',
              };
              manufacturers.value.push(manufacturer);
            });
          }
        }
      });
    });

    return {
      showProductSelectionModal,
      closeProductSelectionModal,
      showProductCategoryListSelectionModal,
      closeProductCategoryListSelectionModal,
      showManufacturerSelectionModal,
      closeManufacturerSelectionModal,
      products,
      shopsProductsCategories,
      manufacturers,
      saveRecommendationExclusion,
      availableProducts,
      productSearchStr,
      availableCategories,
      categorySearchStr,
      availableManufacturers,
      manufacturerSearchStr,
    };
  },
});
