<template>
  <div>
    <div class="flex justify-content-between flex-wrap">
      <div class="flex justify-content-center">
        <label
          class="block my-3"
          data-test-id="stepper-label"
        >
          {{ t(configs.label) }}
        </label>
      </div>
      <div class="flex justify-content-center align-items-center">
        <ToggleDisplaySettings
          v-if="!Object.prototype.hasOwnProperty.call(configs, 'displayToggle') || configs.displayToggle"
          :configs="configs"
          :parser-values="parserValues"
          @display-settings="(value) => displayProperties = value"
        />
      </div>
    </div>
    <BaseInputNumber
      v-model="currentValue"
      :suffix="configs.options.sign"
      :step="configs.options.step ?? undefined"
      class="w-full"
      :class="{ 'hidden': !displayProperties }"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  watch,
  Ref,
  onBeforeMount,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  ParserFieldObject, Property, StepperFieldConfig, StepperSignEnum,
} from '@/types';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import ToggleDisplaySettings from '@/components/template-builder/fields/partials/ToggleDisplaySettings.vue';

export default defineComponent({
  name: 'Stepper',

  components: {
    ToggleDisplaySettings,
    BaseInputNumber,
  },

  props: {
    configs: {
      type: Object as PropType<StepperFieldConfig>,
      required: true,
    },

    parserValues: {
      type: Object as PropType<ParserFieldObject>,
      required: true,
    },
  },

  emits: {
    'on-change-properties': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const configs = ref(props.configs);
    const displayProperties = ref(!(!Object.prototype.hasOwnProperty.call(configs.value, 'displayToggle') || configs.value.displayToggle === true));
    const defaultValue = 0;
    const currentValue = ref(defaultValue);
    const properties: Ref<Property[]> = ref(props.parserValues.properties);
    const isInitialized = ref(false);
    const handleInput = ({ value }: { value: number | null }) => {
      if (value === null) {
        currentValue.value = defaultValue;
      }
    };

    onBeforeMount(() => {
      if (properties.value) {
        let parserValue = properties.value[0].value;
        if (parserValue) {
          const replacement = new RegExp(`[${Object.values(StepperSignEnum).toString()}]`, 'g');
          parserValue = parserValue.replace(replacement, '');
          if (!Number.isNaN(Number(parserValue))) {
            currentValue.value = Number(parserValue);
          }
        }
      }
    });

    watch(currentValue, (values, oldValues) => {
      if (Number(values) !== Number(oldValues)) {
        const propertiesToEmit = properties.value.reduce(
          (acc: Property[], property: Property) => {
            const propertyClone = { ...property };
            // eslint-disable-next-line no-param-reassign
            propertyClone.value = `${currentValue.value ?? defaultValue}${props.configs.options.sign}`;
            acc.push(propertyClone);
            return acc;
          },
          [],
        );

        if (isInitialized.value) {
          context.emit('on-change-properties', {
            selector: props.parserValues.selector,
            properties: propertiesToEmit,
          });
        }

        isInitialized.value = true;
      }
    });

    return {
      displayProperties,
      currentValue,
      handleInput,
      t,
    };
  },
});
</script>
