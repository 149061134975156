
import {
  defineComponent,
  SetupContext,
} from 'vue';
import { showAIModal } from '@/composables/services/ShopimindAI';
import Button from 'primevue/button';
import {TemplateEditorState as state} from '@/composables/template-editor/TemplateEditor';

export default defineComponent({
  name: 'AIButton',

  components: {
    Button,
  },

  props: {
    aiSelector: String,
    aiContentType: Number,
    aiParams: Object,
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const prepareShowAIModal = (event: Event) => {
      const { aiContentType, aiSelector, aiParams } = props;
      let currentIdtemplate = aiParams?.idTemplate;
      if (!currentIdtemplate && state.template) {
        currentIdtemplate = state.template.id;
      }
      if (aiContentType && aiSelector) {
        const AIApplyContentParams = {
          idTemplate: (currentIdtemplate || 0),
          selector: aiSelector,
          type: 'value',
          target: '',
          attr: '',
          imageWidth: 0,
          imageHeight: 0,
          ...aiParams, // Merge aiParams into AIApplyContentParams
        };
        showAIModal(event, aiContentType, AIApplyContentParams);
      }
    };

    return {
      prepareShowAIModal,
    };
  },
});
