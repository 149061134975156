import { ShopsListsImportUnsubInputItem } from '@/types/generated-types/graphql';
import { Insert } from '@/composables/GraphQL';

export default async function InsertShopsListsImportUnsub(shopsListsImportUnsub: ShopsListsImportUnsubInputItem[]) {
  return Insert<ShopsListsImportUnsubInputItem>({
    name: 'ShopsListsImportUnsub',
    input: shopsListsImportUnsub,
    type: 'ShopsListsImportUnsubInput',
  });
}
