<template>
  <draggable
    id="pageRowsList"
    :list="pageRowsState"
    :disabled="!enabled"
    style="height: 100%;"
    class="block grid ml-4 mr-4"
    handle=".handle"
    v-bind="dragOptions"
    :data-stats-builder="true"
    @start="dragging = true"
    @end="dragging = false"
  >
    <template #item="{ element }">
      <div v-if="element.type === 'placeholder'" class="m-4 placeholder-on-add-row" />
      <div
        v-else
        class="root-row m-4"
        :class="{ 'not-draggable': !enabled }"
      >
        <StatsBuilderRow
          :row-data="element"
        />
      </div>
    </template>
  </draggable>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
} from 'vue';
import draggable from 'vuedraggable';
import StatsBuilderRow from '@/components/stats-builder/StatsBuilderRow.vue';
import { StatsBuilderRowConfig } from '@/types/stats-editor-types';
import Tooltip from 'primevue/tooltip';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'StatsBuilder',

  components: {
    StatsBuilderRow,
    draggable,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    pageRowsState: {
      type: Array as PropType<StatsBuilderRowConfig[]>,
      required: true,
    },
  },

  setup(props: { pageRowsState: StatsBuilderRowConfig[] }) {
    const enabled = ref(true);
    const dragging = ref(false);
    const dragOptions = ref({
      animation: 200,
      group: { name: 'line_builder', pull: false, put: ['line_element', 'widget_element'] },
      disabled: false,
    });

    return {
      enabled,
      dragging,
      dragOptions,
    };
  },
});
</script>

<style lang="scss">
.root-row {
  position: relative;
}

.p-overlaypanel-content {
  padding: 0 !important;
}

.placeholder-on-add-row {
  height: 0;
  border-bottom: dashed 2px #94c840;
  position: relative;
}

.placeholder-on-add-row:before {
  position: absolute;
  top: -50%;
  left: 50%;
  content: 'Insérer ici';
  transform: translate(-50%, -50%);
  padding: 6px 10px 3px;
  color: #ffffff;
  background: #94c840;
  line-height: 1rem;
  border-radius: 3px;
  font-size: 0.8rem !important;
}

.placeholder-on-add-widget {
  height: 0;
  position: relative;

  &--allowed {
    border-bottom: dashed 2px #94c840;
    &:before {
      position: absolute;
      top: -50%;
      left: 50%;
      content: 'Insérer ici';
      transform: translate(-50%, -50%);
      padding: 6px 10px 3px;
      color: #ffffff;
      background: #94c840;
      line-height: 1rem;
      border-radius: 3px;
      font-size: 0.8rem !important;
    }
  }

  &--disallowed {
    border-bottom: dashed 2px $error-red;
  }
}
</style>

<style scoped lang="scss">
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
