<template>
  <div class="mt-2">
    <Button
      v-if="showOldOfferPage"
      :label="t('return')"
      class="p-button p-button-secondary mb-3"
      icon="far fa-arrow-left"
      @click="showOldOfferPage = false"
    />
    <h3 class="mb-4">
      {{ t('shop.my_offer.title') }}
    </h3>
    <div class="grid">
      <div class="col-12">
        <div
          v-if="!showOldOfferPage"
        >
          <SummaryPlanV14
            :has-offer-v3="hasOfferV3"
            @show-old-offer-page="handleShowOldOfferPage"
          />
        </div>
        <div
          v-else
          class="iframe-container"
        >
          <Button
            :label="t('offers.discoverNewOffersButton')"
            icon="far fa-arrow-right"
            class="p-button mb-3 discover-new-offers-btn"
            icon-pos="right"
            @click="updatePlan"
          />
          <iframe
            ref="iframeRef"
            :src="iframeSrc"
            sandbox="allow-scripts allow-same-origin"
            class="full-size-iframe"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import { useStore } from '@/store';
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import { UserState } from '@/composables/User';
import CryptoJS from 'crypto-js';
import SummaryPlanV14 from '@/components/my-offer/MyOffer14/SummaryPlanV14.vue';

import Button from 'primevue/button';

// External library
import moment from 'moment';

export default defineComponent({
  name: 'MyOffer',

  components: {
    SummaryPlanV14,
    Button,
  },

  props: {
    checkUserState: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const iframeSrc = ref('');
    const query = ref('/advisor/logAsShop/');
    const iframeRef = ref(null);
    const hasOfferV4 = ref(false);
    const hasOfferV3 = ref(false);

    const showOldOfferPage = ref(false);

    const watchUserState = () => {
      if (UserState.activeOffer) {
        if (UserState.activeOffer.trialPlan) {
          if (UserState.activeOffer.trialPlanLimit) {
            hasOfferV4.value = moment().isBefore(moment(UserState.activeOffer.trialPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasOfferV4.value = true;
          }
        } else if (UserState.activeOffer.forcedPlan) {
          if (UserState.activeOffer.forcedPlanLimit) {
            hasOfferV4.value = moment().isBefore(moment(UserState.activeOffer.forcedPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasOfferV4.value = true;
          }
        } else if (UserState.activeOffer.currentPlan) {
          hasOfferV4.value = true;
        } else {
          hasOfferV4.value = false;
        }
        hasOfferV3.value = false;
        showOldOfferPage.value = false;
      } else if (!UserState.activeOffer && UserState.hasOfferV3) {
        hasOfferV3.value = true;
      }
      if (!hasOfferV4.value && !hasOfferV3.value) {
        store.commit('choosePlan/setIsVisible', true);
      }
    };

    const updatePlan = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    const handleShowOldOfferPage = () => {
      showOldOfferPage.value = true;
    };

    onMounted(() => {
      if (props.checkUserState) {
        watchUserState();
      }

      const cryptedKey = CryptoJS.SHA1(`remoteplanZn26687S2682MF2ZV4Ag2g9JGbhF686X${idShop.value}`).toString();
      query.value += `${idShop.value}/remoteplan/${cryptedKey}`;
      iframeSrc.value = `${process.env.VUE_APP_ZEND_URL}${query.value}`;
    });

    watch(() => UserState.activeOffer, () => {
      watchUserState();
    }, { deep: true });

    watch(() => props.checkUserState, () => {
      if (props.checkUserState) {
        watchUserState();
      }
    });

    return {
      t,
      iframeSrc,
      iframeRef,
      hasOfferV3,
      showOldOfferPage,

      updatePlan,
      handleShowOldOfferPage,
    };
  },
});
</script>

<style lang="scss">
.iframe-container {
  width: 100%;
  height: 100vh;
}

.full-size-iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>

<style scoped lang="scss">
.discover-new-offers-btn {
  background: rgb(164,200,102);
  background: -moz-linear-gradient(106deg, rgba(164,200,102,1) 0%, rgba(148,200,64,1) 51%);
  background: -webkit-linear-gradient(106deg, rgba(164,200,102,1) 0%, rgba(148,200,64,1) 51%);
  background: linear-gradient(106deg, rgba(164,200,102,1) 0%, rgba(148,200,64,1) 51%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a4c866",endColorstr="#94c840",GradientType=1);
}
</style>
