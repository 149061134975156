import { OperatorType, ShopsProductsCategoriesList } from '@/types/generated-types/graphql';
import { Request } from '@/composables/GraphQL';
import { UserState } from '@/composables/User';

export default async function ShopsProductsCategoriesListList(name: string, lang: string, limit = 0): Promise<Array<ShopsProductsCategoriesList>|null> {
  const filters = [
    {
      field: 'id_shop',
      operator: OperatorType.Equals,
      value: UserState.activeShop?.id,
    },
    {
      field: 'lang',
      operator: OperatorType.Equals,
      value: lang,
    },
    {
      field: 'name',
      operator: OperatorType.Like,
      value: `%${name}%`,
    },
  ];

  const fields = ['id_shop_product_category_list', 'id_category', 'name'];
  const fieldsName = fields.reduce((acc: string, current: string) => `${acc} ${current}`);

  const result = await Request<{ [key: string]: any}>({
    name: 'ShopsProductsCategoriesSelection',
    variables: {
      limit,
      offset: 0,
      order: [
        { field: 'name', type: 'ASC' },
      ],
      filter: filters,
    },
    query: `query ($limit: Int!, $offset: Int!, $order: [OrderParameters], $filter: [FilterParameters]) {
      ShopsProductsCategoriesSelection (params: {limit: $limit, offset: $offset, order: $order, filter: $filter}) {
        items {
          ${fieldsName}
        }
      }
    }`,
  });

  return result.data?.items ?? [];
}
