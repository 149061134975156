import {
  ConfigurationPanelStructure,
  ConfigurationPanelStructureItemEnum,
  TemplateStructureEnum,
  TabItem, ComponentNameEnum,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import { setContent } from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getContent } from '@/components/template-builder/getters';
import { removeCharactersForSms } from '@/components/template-builder/modifiers';
import variables from '@/components/template-builder/utils/variables-list';
import getTextareaVariablesFieldConfig from '@/components/template-builder/config/fields/textarea-variables-config';

export const pageTabItemConfig: TabItem = {
  type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
  tabItemType: TemplateStructureEnum.PAGE,
  label: 'templateBuilder.configs.sms',
  tabPanel: {
    label: 'templateBuilder.configs.sms',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.smsContent',
        selector: 'body #spm_body',
        items: [
          {
            label: 'templateBuilder.informations.smsInformationMessage',
            selector: '',
            properties: [],
            componentName: ComponentNameEnum.INFORMATION,
            options: {
              severity: 'warn',
            },
          },
          {
            label: 'templateBuilder.fields.smsText',
            selector: '{SELECTOR} .sms-bubble',
            properties: [{
              name: 'content', getter: getContent, setters: [setContent], refreshWidgetFields: true,
            }],
            ...getTextareaVariablesFieldConfig('Contenu du SMS', variables.allVariables, false),
            options: {
              ...getTextareaVariablesFieldConfig('Contenu du SMS', variables.allVariables, false).options,
              countCharactersFor: 'sms',
              modifiers: [removeCharactersForSms],
            },
          },
          {
            label: 'templateBuilder.informations.smsInformationMaxCharacters',
            selector: '',
            properties: [],
            componentName: ComponentNameEnum.INFORMATION,
            options: {},
          },
        ],
      },
    ],
  },
};

const pageItemConfig: ConfigurationPanelStructure = {
  items: [
    pageTabItemConfig,
  ],
};

export default pageItemConfig;
