import { Property, StringMap } from '@/types';
// eslint-disable-next-line import/no-cycle
import { getTemplateIframeDocument } from '../utils/helpers';

/* eslint-disable import/prefer-default-export */
export const setMediaDisplay = (selector: string, property: Property) => {
  const extraClasses: StringMap = { small: 'hide-for-desktop', desktop: 'hide-for-small' };
  const template = getTemplateIframeDocument();
  const element: HTMLElement|null = template?.querySelector(selector);
  if (element && property.value !== undefined) {
    let classes = element.getAttribute('class')?.split(' ').filter((className: string) => !className.startsWith(property.name));
    if (classes) {
      classes = classes.filter((className: string) => !className.startsWith('hide-for-')); // remove hide-for class
      if (extraClasses[property.value] && extraClasses[property.value] !== 'show-for-all') {
        classes.push(extraClasses[property.value]); // add extra class
      }
      if (`${property.name}${property.value}` && `${property.name}${property.value}` !== 'show-for-all') {
        classes.push(`${property.name}${property.value}`);
      }
      element.setAttribute('class', (classes.join(' ').replace(/\s+/g, ' ').trim() ?? ''));
    }
  }
};
