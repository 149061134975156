// eslint-disable-next-line import/no-cycle
import emailPageConfig from '@/components/template-builder/config/templates/email/page-item-config';
// eslint-disable-next-line import/no-cycle
import emailColumnConfig from '@/components/template-builder/config/templates/email/column-item-config';
// eslint-disable-next-line import/no-cycle
import emailLineConfig from '@/components/template-builder/config/templates/email/line-item-config';
// eslint-disable-next-line import/no-cycle
import emailSectionConfig from '@/components/template-builder/config/templates/email/section-item-config';
// eslint-disable-next-line import/no-cycle
import emailCustomPageConfig from '@/components/template-builder/config/templates/email/custom-page-item-config';

// eslint-disable-next-line import/no-cycle
import popupPageConfig from '@/components/template-builder/config/templates/popup/page-item-config';
// eslint-disable-next-line import/no-cycle
import popupColumnConfig from '@/components/template-builder/config/templates/popup/column-item-config';
// eslint-disable-next-line import/no-cycle
import popupLineConfig from '@/components/template-builder/config/templates/popup/line-item-config';
// eslint-disable-next-line import/no-cycle
import embedPageConfig from '@/components/template-builder/config/templates/embed/page-item-config';
// eslint-disable-next-line import/no-cycle
import embedColumnConfig from '@/components/template-builder/config/templates/embed/column-item-config';
// eslint-disable-next-line import/no-cycle
import embedLineConfig from '@/components/template-builder/config/templates/embed/line-item-config';

// eslint-disable-next-line import/no-cycle
import pushNotificationsPageConfig from '@/components/template-builder/config/templates/pushnotifications/page-item-config';

// eslint-disable-next-line import/no-cycle
import smsPageConfig from '@/components/template-builder/config/templates/sms/page-item-config';

import { ConfigurationPanelStructure, TemplateParentTypeEnum, WidgetTypeEnum } from '@/types';
// eslint-disable-next-line import/no-cycle
import getWidgetConfig from './widgets-config';

interface TemplateConfiguration {
  [key: string]: ConfigurationPanelStructure;
}

const getTemplateConfiguration = (templateType: TemplateParentTypeEnum, widgetType: WidgetTypeEnum|null, imported = false): TemplateConfiguration => {
  let config: TemplateConfiguration;
  switch (templateType) {
    case TemplateParentTypeEnum.EMAIL:
      config = {
        page: imported ? emailCustomPageConfig : emailPageConfig,
        section: emailSectionConfig,
        column: emailColumnConfig,
        line: emailLineConfig,
      };
      break;
    case TemplateParentTypeEnum.PUSHNOTIFICATIONS:
      config = {
        page: pushNotificationsPageConfig,
      };
      break;
    case TemplateParentTypeEnum.SMS:
      config = {
        page: smsPageConfig,
      };
      break;
    case TemplateParentTypeEnum.FACEBOOKMESSENGER:
      config = {}; // No page configuration for Facebook Messenger
      break;
    case TemplateParentTypeEnum.POPUP:
      config = {
        page: popupPageConfig,
        column: popupColumnConfig,
        line: popupLineConfig,
      };
      break;
    case TemplateParentTypeEnum.EMBED:
      config = {
        page: embedPageConfig,
        column: embedColumnConfig,
        line: embedLineConfig,
      };
      break;
    default:
      config = {
        page: popupPageConfig,
        column: popupColumnConfig,
      };
  }

  if (widgetType) {
    config.widget = getWidgetConfig(templateType, widgetType, config.column?.items[0], config.section?.items[0], config.line?.items[0]);
  }

  return config;
};

export default getTemplateConfiguration;
