import { AlignValueEnum, FieldConfig } from '@/types';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
// eslint-disable-next-line import/no-cycle
import { setCssProperty, setCssPropertyAndRemoveIfZero, setMediaDisplay } from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import { getClassByName, getCssPropertyByName } from '@/components/template-builder/getters';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';

const widgetSeparatorFieldsConfig: Array<FieldConfig> = [
  {
    label: 'templateBuilder.fields.style',
    selector: '{SELECTOR} hr.spm_display_separator',
    properties: [
      { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
      { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
      { name: 'border-style', getter: getCssPropertyByName, setters: [] },
      { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
      { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
      { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
    ],
    ...bordersFieldConfig,
  },
  {
    label: 'templateBuilder.fields.width',
    selector: '{SELECTOR} hr.spm_display_separator',
    properties: [{ name: 'width', getter: getCssPropertyByName, setters: [setCssProperty] }],
    ...getWidthFieldConfig(),
  },
  {
    label: 'templateBuilder.fields.alignment',
    selector: '{SELECTOR}',
    properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
    ...getElementAlignFieldConfig('text-align', AlignValueEnum.CENTER),
  },
  {
    label: 'templateBuilder.fields.mediaDisplay',
    selector: '{SELECTOR} ',
    properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
    ...mediaDisplayFieldConfig,
  },
];

export default widgetSeparatorFieldsConfig;
