
import {
  computed,
  defineComponent, onMounted, PropType, ref, Ref, SetupContext, watch,
} from 'vue';
import Checkbox from 'primevue/checkbox';
import BaseInputNumber from '@/components/fields/partials/BaseInputNumber.vue';
import { Range } from '@/types/automated-scenarios';
import { useI18n } from 'vue-i18n';
import { asInt } from '@/helpers';

export default defineComponent({
  name: 'NumberRange',

  components: {
    BaseInputNumber,
    Checkbox,
  },

  props: {
    modelValue: {
      type: Object as PropType<Range>,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    suffix: {
      type: String,
      required: false,
      default: ' €',
    },

    min: {
      type: Number,
      required: false,
      default: 1,
    },

    max: {
      type: Number,
      required: false,
      default: 100000,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const range: Ref<Range> = ref({
      min: (asInt(props.modelValue.min) ?? 1),
      max: ((props.modelValue.max && asInt(props.modelValue.max)) ?? 100000),
      noMax: props.modelValue.noMax,
    });

    const rulesMinMax = computed(() => ({
      left: {
        min: props.min,
        max: (range.value.max && range.value.noMax !== 'on') ? (range.value.max - 1) : props.max,
      },
      right: {
        min: range.value.min + 1,
        max: props.max,
      },
    }));

    watch(range.value, () => {
      if (range.value.noMax && range.value.noMax === 'on') {
        if (range.value.max && range.value.max <= range.value.min) {
          range.value.max = range.value.min + 1;
        }
        emit('update:modelValue', {
          min: range.value.min,
          noMax: range.value.noMax,
        });
      } else {
        emit('update:modelValue', {
          min: range.value.min,
          max: range.value.max,
        });
      }
    });

    // emit event if user don't change default values
    onMounted(() => emit('update:modelValue', range.value));

    return {
      t,
      range,
      rulesMinMax,
    };
  },
});
