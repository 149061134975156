<template>
  <Loader
    v-if="isLoading"
    style="z-index:1102"
  />
  <div class="mt-2">
    <div class="grid">
      <div class="col-12">
        <h4>
          {{ t('shop.menu.params', { shopName: UserState.activeShop?.name }) }}
        </h4>
      </div>
      <div class="col-12">
        <TabView
          ref="tabview"
          :cache="true"
          lazy
        >
          <TabPanel :header="t('storeParameters.informations')">
            <StoreInformations
              :key="updateKey"
              :id-shop="shopId"
              @default-language="changeDefaultLang"
              @refresh-component="refreshComponents"
            />
          </TabPanel>
          <TabPanel :header="t('storeParameters.servicesParameters')">
            <NewServicesParameters
              :key="updateKey"
              :shop-id="shopId"
              :shop-url="shopUrl"
            />
          </TabPanel>
          <TabPanel :header="t('storeParameters.productRecommendation')">
            <ProductRecommendations
              :translation="t"
              :default-lang="defaultLang"
              :id-shop="shopId"
            />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent, onMounted, ref,
} from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { loadShopsLang, UserState } from '@/composables/User';
import { fetchStoreParamsConfigurations } from '@/composables/shop/MyShopParameters';
import Loader from '@/components/layout/Loader.vue';
import ProductRecommendations from '@/views/shop/tabs/ProductRecommendations.vue';
import StoreInformations from './tabs/StoreInformations.vue';
import NewServicesParameters from './tabs/ServicesParameters/NewServicesParameters.vue';

export default defineComponent({
  name: 'ShopEdit',
  components: {
    ProductRecommendations,
    TabView,
    TabPanel,
    StoreInformations,
    Loader,
    NewServicesParameters,
  },

  emits: {
    'default-language': String,
  },

  setup() {
    const { t } = useI18n();
    const tabview = ref<Record<string, number>>({});
    const defaultLang = ref(UserState.user.lang);
    const updateKey = ref(0);
    const isLoading = ref(false);

    const shopId = UserState.activeShop?.id ?? 0;
    const shopUrl = UserState.activeShop?.url ?? '';

    const services: any = {
      shopRequiredDataDisplay_activeConfig: '0',
      shopRequiredDataUtm_activeConfig: '0',
      shopRequiredDataPushNotification_titleNotif: {},
      shopRequiredDataSms_senderName: {},
      shopRequiredDataPushNotification_textNotif: {},
      shopRequiredDataPushNotification_fullTextButtonAccept: {},
      shopRequiredDataPushNotification_fullTextButtonRefuse: {},
      shopRequiredDataPushNotification_textPopup: {},
      shopRequiredDataPushNotification_textButtonPopup: {},
      shopRequiredDataEmail_logoPath: '',
      shopRequiredDataEmail_senderName: {},
      shopRequiredDataEmail_senderEmail: {},
      shopRequiredDataSms_activeConfig: '0',
      shopRequiredDataFacebook_activeConfig: '0',
      shopRequiredDataFacebook_chatActiveConfig: '0',
      shopRequiredDataFacebook_colorPlugin: '',
      shopRequiredDataFacebook_sizePlugin: '0',
      shopRequiredDataRetargetingEmail_activeConfig: '0',
      shopRequiredDataPushNotification_activeNotification: '0',
      shopRequiredDataPushNotification_titleNotifColor: '0',
      shopRequiredDataPushNotification_activeImgNotifThumbnail: '0',
      shopRequiredDataPushNotification_displayImgInMobile: '0',
      shopRequiredDataPushNotification_imgNotifThumbnailPath: '',
      shopRequiredDataPushNotification_displayDescriptionInMobile: '0',
      shopRequiredDataPushNotification_appearEffects: 'none',
      shopRequiredDataPushNotification_delayHide: '1',
      shopRequiredDataPushNotification_backgroundNotifColor: '',
      shopRequiredDataPushNotification_textNotifColor: '',
      shopRequiredDataPushNotification_widthOfNotification: 0,
      shopRequiredDataPushNotification_positionTopOfNotification: 20,
      shopRequiredDataPushNotification_positionLeftOfNotification: 50,
      shopRequiredDataPushNotification_colorNotification: '',
      shopRequiredDataPushNotification_textColorNotification: '',
      shopRequiredDataPushNotification_backgroundButtonRefuse: '',
      shopRequiredDataPushNotification_textButtonRefuse: '',
      shopRequiredDataPushNotification_activeIcon: '0',
      shopRequiredDataPushNotification_colorIcon: '',
      shopRequiredDataPushNotification_textColorIcon: '',
      shopRequiredDataPushNotification_backgroundColorPopup: '',
      shopRequiredDataPushNotification_textColorPopup: '',
      shopRequiredDataPushNotification_buttonColorPopup: '',
      shopRequiredDataPushNotification_textButtonColorPopup: '',
      shopRequiredDataPushNotification_activeConfig: '0',
      shopRequiredDataPushNotification_logoPath: '',
      shopRequiredDataSocialNetworks_contactPage: {},
      shopRequiredDataSocialNetworks_facebook: {},
      shopRequiredDataSocialNetworks_twitter: {},
      shopRequiredDataSocialNetworks_googleplus: {},
      shopRequiredDataSocialNetworks_pinterest: {},
      shopRequiredDataSocialNetworks_tumblr: {},
      shopRequiredDataSocialNetworks_instagram: {},
      shopRequiredDataSocialNetworks_youtube: {},
      shopRequiredDataSocialNetworks_whatsapp: {},
      shopRequiredDataSocialNetworks_snapchat: {},
      shopRequiredDataSocialNetworks_socialNetwork: '',
      shopRequiredDataCouponing_max_percentage_of_reduction: '',
      shopRequiredDataCouponing_min_purchase_amount_for_discount: '',
      shopRequiredDataRgpd_privacyPolicyUrl: {},
      shopRequiredDataRgpd_dataManagerEmail: {},
      fbLinkedPage: {},
      fbLoggedUser: {},
    };

    const changeDefaultLang = (lang: string) => {
      defaultLang.value = lang;
    };

    const retrieveServices = async () => {
      isLoading.value = true;
      const {
        data,
        err,
      } = await fetchStoreParamsConfigurations(shopId);
      const defaultValuesKeys = Object.keys(services);
      const exclusions = ['shopRequiredDataSocialNetworks_facebook',
        'shopRequiredDataSocialNetworks_googleplus',
        'shopRequiredDataSocialNetworks_instagram',
        'shopRequiredDataSocialNetworks_pinterest',
        'shopRequiredDataSocialNetworks_snapchat',
        'shopRequiredDataSocialNetworks_tumblr',
        'shopRequiredDataSocialNetworks_twitter',
        'shopRequiredDataSocialNetworks_whatsapp',
        'shopRequiredDataSocialNetworks_youtube'];

      if (data === null || data.length === 0) {
        isLoading.value = false;
        return;
      }

      for (let i = 0; i < data.length; i++) {
        const record = data[i];
        for (let j = 0; j < defaultValuesKeys.length; j++) {
          if (record.key === defaultValuesKeys[j]) {
            if (record.lang && typeof services[defaultValuesKeys[j]] === 'object') {
              let objectValue: any = {};
              objectValue = services[defaultValuesKeys[j]];
              objectValue[record.lang] = record.value;
              services[defaultValuesKeys[j]] = objectValue;
            } else if (!exclusions.includes(record.key)) {
              services[defaultValuesKeys[j]] = record.value;
            }
          }
        }
      }

      isLoading.value = false;
    };

    const refreshComponents = async () => {
      await loadShopsLang(shopId);
      updateKey.value += 1;
    };

    onMounted(async () => {
      await retrieveServices();
    });

    return {
      t,
      changeDefaultLang,
      tabview,
      UserState,
      services,
      shopId,
      updateKey,
      refreshComponents,
      shopUrl,
      isLoading,
      defaultLang,
    };
  },
});
</script>

<style scoped lang="scss">
 :deep() .p-tabview-nav {
  display: flex;
  li {
    z-index: 0;
    line-height: 2rem;
    font-size: 0.95rem;
    position: relative;
    text-align: center;
    flex-grow: 1;
    .p-tabview-nav-link {
      box-shadow: none !important;
    }
    .p-tabview-title {
      width: 100%
    }
  }
}
</style>
