<template>
  <ToggleButton
    ref="buttonDisplayRef"
    class="emoji-button"
    on-icon="fal fa-grin"
    off-icon="fal fa-grin"
    @click="computeDialogPosition"
  />
  <OverlayPanel
    ref="displayEmojiPicker"
    :show-close-icon="false"
    class="inputOverlayPanel"
  >
    <VuemojiPicker
      class="emoji-picker"
      @emoji-click="handleEmojiClicked"
    />
  </OverlayPanel>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ToggleButton from 'primevue/togglebutton';
import OverlayPanel from 'primevue/overlaypanel';
import { VuemojiPicker, EmojiClickEventDetail } from 'vuemoji-picker';

export default defineComponent({
  name: 'EmojisButton',

  components: {
    VuemojiPicker,
    ToggleButton,
    OverlayPanel,
  },

  emits: {
    'on-choose-emoji': Object,
  },

  setup(_, context) {
    const displayEmojiPicker = ref();
    const buttonDisplayRef = ref();

    const computeDialogPosition = (event: any) => {
      displayEmojiPicker.value.show(event);
    };

    const handleEmojiClicked = (detail: EmojiClickEventDetail) => {
      context.emit('on-choose-emoji', detail.unicode);
      displayEmojiPicker.value.hide();
    };
    return {
      buttonDisplayRef,
      computeDialogPosition,
      displayEmojiPicker,
      handleEmojiClicked,
    };
  },

});
</script>
<style lang="scss">
</style>
<style scoped lang="scss">
:deep() .emoji-picker {
  width: 100%;
  --num-columns: 6;
  --emoji-size: 1.5rem;
  --category-emoji-size: 1rem;
}
.p-togglebutton.p-button.emoji-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 1px 0 !important;
  margin: auto 0;
  width: 2rem;
  font-size: 1.5rem;
  box-shadow: none;
  &:hover {
    background: none;
  }
  :deep() .p-button-icon {
    color: $shuttle-grey;
  }
}
</style>
