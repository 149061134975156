
import { useI18n } from 'vue-i18n';
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import { SpmTableColumns, SpmTableFilter } from '@/types';
import { OperatorType, PushTokenParams } from '@/types/generated-types/graphql';
import SpmTable from '@/components/table/SpmTable.vue';
import { Request } from '@/composables/GraphQL';
import { findPermission, UserState } from '@/composables/User';
import Message from 'primevue/message';
import { getShopCustomerLanguages } from '@/types/country-language-options';

export default defineComponent({
  name: 'ListsPushTokens',
  components: {
    Card,
    Button,
    SpmTable,
    Message,
  },

  setup() {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;

    const browserList = [
      { value: 'Firefox', label: 'Firefox' },
      { value: 'Chrome', label: 'Chrome' },
      { value: 'Edge', label: 'Edge' },
      { value: 'Opera', label: 'Opera' },
      { value: 'Chromium', label: 'Chromium' },
      { value: 'Konqueror', label: 'Konqueror' },
      { value: 'Safari', label: 'Safari' },
    ];

    const platformList = [
      { value: 'Desktop', label: 'Desktop' },
      { value: 'Mobile', label: 'Mobile' },
      { value: 'Tablet', label: 'Tablet' },
    ];

    const columns: SpmTableColumns[] = [
      {
        field: 'id_shop_push_token',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'date_modification',
        header: t('myLists.pushToken.headers.date_modification'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'id_shop_customer',
        header: t('id'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'browser_name',
        header: t('myLists.pushToken.headers.browser_name'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: browserList, hideFilterMenu: true },
      },
      {
        field: 'device',
        header: t('myLists.pushToken.headers.device'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'multiSelect', options: platformList, hideFilterMenu: true },
      },
      {
        field: 'push_endpoint',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'push_key',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
      {
        field: 'push_token',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: '',
        hidden: true,
      },
    ];

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'id_shop',
        value: UserState?.activeShop?.id ?? 0,
        operator: OperatorType.Equals,
      },
    ];

    const params = ref({
      privateKey: '',
      publicKey: '',
      downloadURL: '',
    });
    const privateKey = computed(() => params.value?.privateKey ?? '');
    const publicKey = computed(() => params.value?.publicKey ?? '');
    const downloadURL = computed(() => params.value?.downloadURL ?? '');
    const shopCurrentVersionOK = computed(() => (UserState.activeShop?.currentVersion ?? '') >= '3.1.0');

    const getParams = async () => {
      const { data, err } = await Request<PushTokenParams>({
        name: 'PushTokenParamsGet',
        variables: { id_shop: UserState?.activeShop?.id ?? 0 },
        query: `query ($id_shop: Int!)
          {
            PushTokenParamsGet(id_shop: $id_shop) {
              privateKey
              publicKey
              downloadURL
            }
          }
        `,
      });

      if (err === '') {
        params.value = {
          privateKey: data?.privateKey ?? '',
          publicKey: data?.publicKey ?? '',
          downloadURL: data?.downloadURL ?? '',
        };
      }
    };

    onMounted(async () => {
      await getParams();
    });

    return {
      t,
      columns,
      persistentFilters,
      shopCurrentVersionOK,
      downloadURL,
      privateKey,
      publicKey,
      idShop,
    };
  },

  methods: { findPermission },
});
