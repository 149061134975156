<template>
  <Dialog
    v-model:visible="displayBasic"
    :header="t('automatedScenarios.globalBulkSettings.action_sending.planning_header')"
    :modal="true"
    :style="{ width: '50rem' }"
    :breakpoints="{ '1200px': '60vw', '700px': '100vw' }"
    class="modal-scheduled-bulk-campaign"
    position="top"
    :draggable="false"
    @hide="onHideEvent"
  >
    <div class="grid">
      <div class="col">
        <div
          class="spm-bulk-type-sending"
          :class="{ 'active': typeSending === 0 }"
          @click="typeSending = 0"
        >
          <div class="sbts-header">
            <div class="sbtsh-left">
              <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center ">
                  <i class="flex far fa-calendar-alt" />
                </div>
                <div class="flex align-items-center justify-content-center font-bold ml-2">
                  <span>
                    {{ t('automatedScenarios.globalBulkSettings.moment_sending.later.label') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="sbtsh-right">
              <i
                class="far"
                :class="{ 'fa-circle': typeSending === 1, 'fa-circle-dot': typeSending === 0 }"
              />
            </div>
          </div>
          <div class="sbts-desc">
            <small>
              {{ t('automatedScenarios.globalBulkSettings.moment_sending.later.desc') }}
            </small>
          </div>
        </div>
      </div>
      <div class="col">
        <div
          class="spm-bulk-type-sending"
          :class="{ 'active': typeSending === 1 }"
          @click="typeSending = 1"
        >
          <div class="sbts-header">
            <div class="sbtsh-left">
              <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center ">
                  <i class="flex far fa-paper-plane-top" />
                </div>
                <div class="flex align-items-center justify-content-center font-bold ml-2">
                  <span>
                    {{ t('automatedScenarios.globalBulkSettings.moment_sending.now.label') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="sbtsh-right">
              <i
                class="far"
                :class="{ 'fa-circle': typeSending === 0, 'fa-circle-dot': typeSending === 1 }"
              />
            </div>
          </div>
          <div class="sbts-desc">
            <small>
              {{ t('automatedScenarios.globalBulkSettings.moment_sending.now.desc') }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="typeSending === 0"
      class="grid mt-1"
    >
      <div class="col">
        <label
          for="date"
          class="block mb-2 text-left"
        >
          {{ t('automatedScenarios.globalBulkSettings.time_sending.date.label') }}
        </label>
        <div class="p-fluid flex">
          <Calendar
            id="date"
            v-model="selectedDate"
            :min-date="new Date()"
            date-format="dd/mm/yy"
            class="w-full"
            show-icon
          />
        </div>
      </div>
    </div>

    <div
      v-if="typeSending === 0"
      class="grid mt-1"
    >
      <div class="col">
        <label class="block mb-2 text-left">
          {{ t('automatedScenarios.globalBulkSettings.time_sending.time.label') }}
        </label>
        <div class="p-fluid flex">
          <BaseDropdown2
            v-model="selectedTime"
            :options="timeOptions"
            classes="w-full"
          />
        </div>
      </div>
      <div class="col">
        <label class="block mb-2 text-left">
          {{ t('automatedScenarios.globalBulkSettings.time_sending.timezone.label') }}
        </label>
        <div class="p-fluid flex">
          <Dropdown
            v-model="selectedTimezone"
            class="p-0 w-full"
            :options="timezoneOptions"
            option-label="label"
            option-value="value"
            option-group-children="items"
            option-group-label="label"
            :filter="true"
          />
        </div>
      </div>
    </div>

    <div class="grid mt-1">
      <div class="col">
        <label class="block mb-2 text-left">
          {{ t('automatedScenarios.globalBulkSettings.perfect_timing.label') }}
          <i
            v-tooltip="{ value: t('automatedScenarios.globalBulkSettings.perfect_timing.description'), class: 'tooltip-icons-outside' }"
            class="fas fa-question-circle"
            data-html="true"
            data-toggle="tooltip"
            aria-hidden="true"
          />
        </label>
        <div class="p-fluid flex">
          <ButtonGroup
            v-model="selectedPerfectTiming"
            :options="perfectTimingOptions"
            custom-class="w-full"
          />
        </div>
      </div>
      <div class="col" />
    </div>

    <div
      v-if="selectedPerfectTiming === 0"
      class="grid mt-1"
    >
      <div class="col">
        <label class="block mb-2 text-left">
          {{ t('automatedScenarios.globalBulkSettings.slowdown.label') }}
        </label>
        <div class="p-fluid flex">
          <ButtonGroup
            v-model="selectedSlowdown"
            :options="slowdownOptions.slowdown"
            custom-class="w-full"
          />
        </div>
      </div>
      <div
        class="col"
      >
        <div v-if="selectedSlowdown === 1">
          <label class="block mb-2 text-left">
            {{ t('automatedScenarios.globalBulkSettings.interval_between_sends.label') }}
          </label>
          <div class="p-fluid flex">
            <BaseDropdown2
              v-model="selectedIntervalBetweenSends"
              classes="col-12"
              :options="slowdownOptions.interval_between_sends"
            />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <SpmButton
        label="Annuler"
        class-style="p-button p-button-secondary"
        @click="onHideEvent"
      />
      <SpmButton
        label="Programmer"
        class-style="p-button p-button-success"
        :loading="loadingSaveScheduled"
        @click="onSaveScheduled"
      />
    </template>
  </Dialog>
  <ConfirmPopup
    group="sendCampaignBulk"
    class="small-spm-confirm-popup"
  />
</template>

<script lang="ts">
import Dialog from 'primevue/dialog';
import { GlobalBulkSettingsData } from '@/types/automated-scenarios';
import {
  defineComponent, onMounted, ref, SetupContext, watch,
} from 'vue';
import { AutomatedScenarioState as state, setGlobalSettingsData } from '@/composables/AutomatedScenarios';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Calendar from 'primevue/calendar';
import { useI18n } from 'vue-i18n';
import moment from 'moment-timezone';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import BaseDropdown2 from '@/components/fields/BaseDropdown2.vue';
import Tooltip from 'primevue/tooltip';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from 'primevue/useconfirm';
import Dropdown from 'primevue/dropdown';
import { UserState } from '@/composables/User';
import { Get } from '@/composables/GraphQL';
import { Shops } from '@/types/generated-types/graphql';

export default defineComponent({
  name: 'ModalScheduledBulkCampaign',

  components: {
    BaseDropdown2,
    ButtonGroup,
    SpmButton,
    Dialog,
    Calendar,
    ConfirmPopup,
    Dropdown,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['on-hide-modal', 'on-save-scheduled'],

  setup(props: {settingPanelValidationErrors: any }, { emit }: SetupContext) {
    const { t } = useI18n();
    const confirm = useConfirm();
    const displayBasic = ref(true);
    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const loadingSaveScheduled = ref(false);
    const typeSending = ref(0); // 0 = scheduled, 1 = now
    const selectedDate = ref(moment().format('DD/MM/YYYY'));
    const selectedTime = ref('08:00');

    const timeOptions: any = ref([]);
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        const hours = String(i).padStart(2, '0');
        const minutes = String(j).padStart(2, '0');
        const timeString = `${hours}:${minutes}`;
        timeOptions.value.push({ value: timeString, label: timeString });
      }
    }

    const selectedTimezone = ref();
    const timezoneOptions: any = ref([]);

    const selectedPerfectTiming = ref(0);
    const perfectTimingOptions = ref([
      { value: 1, label: t('automatedScenarios.globalBulkSettings.perfect_timing.options.activate') },
      { value: 0, label: t('automatedScenarios.globalBulkSettings.perfect_timing.options.deactivate') },
    ]);

    const selectedSlowdown = ref(0);
    const selectedIntervalBetweenSends = ref('15');
    const slowdownOptions = {
      slowdown: [
        { value: 1, label: t('yes') },
        { value: 0, label: t('no') },
      ],

      interval_between_sends: [
        { value: '15', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.15') },
        { value: '30', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.30') },
        { value: '60', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.60') },
        { value: '90', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.90') },
        { value: '120', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.120') },
        { value: '150', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.150') },
        { value: '180', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.180') },
        { value: '210', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.210') },
        { value: '240', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.240') },
        { value: '270', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.270') },
        { value: '300', label: t('automatedScenarios.globalBulkSettings.interval_between_sends.options.300') },
      ],
    };

    const onHideEvent = (event: any) => {
      emit('on-hide-modal');
    };

    const onSaveScheduled = (event: any) => {
      loadingSaveScheduled.value = true;
      const settingsFields: GlobalBulkSettingsData = {
        name: (state.scenario.settingsData as GlobalBulkSettingsData).name,
        time_sending: (state.scenario.settingsData as GlobalBulkSettingsData).time_sending,
        perfect_timing: (state.scenario.settingsData as GlobalBulkSettingsData).perfect_timing,
        slowdown: (state.scenario.settingsData as GlobalBulkSettingsData).slowdown,
        interval_between_sends: (state.scenario.settingsData as GlobalBulkSettingsData).interval_between_sends,
        commercial_campaign: (state.scenario.settingsData as GlobalBulkSettingsData).commercial_campaign,
      };

      if (typeSending.value === 0) {
        let scheduledStringDate = moment(selectedDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD');
        scheduledStringDate = `${scheduledStringDate} ${selectedTime.value}:00`;
        const clientMoment = moment.tz(scheduledStringDate, selectedTimezone.value);
        const parisMoment = clientMoment.clone().tz('Europe/Paris');
        settingsFields.time_sending = parisMoment.format('YYYYMMDDHHmmss');
      } else {
        const nowInParis = moment.tz('Europe/Paris');
        settingsFields.time_sending = nowInParis.format('YYYYMMDDHHmmss');
      }

      if (selectedPerfectTiming.value === 1) {
        settingsFields.perfect_timing = 1;
      } else {
        settingsFields.perfect_timing = 0;
        /* Gestion de l'étalement de l'envoi dans le temps */
        if (selectedSlowdown.value === 1) {
          settingsFields.slowdown = 1;
          settingsFields.interval_between_sends = selectedIntervalBetweenSends.value;
        } else {
          settingsFields.slowdown = 0;
          settingsFields.interval_between_sends = slowdownOptions.interval_between_sends[0].value;
        }
      }

      confirm.require({
        target: event.currentTarget,
        group: 'sendCampaignBulk',
        header: typeSending.value === 0
          ? t('automatedScenarios.globalBulkSettings.action_sending.confirm_later.header')
          : t('automatedScenarios.globalBulkSettings.action_sending.confirm_now.header'),
        message: typeSending.value === 0
          ? t('automatedScenarios.globalBulkSettings.action_sending.confirm_later.message')
          : t('automatedScenarios.globalBulkSettings.action_sending.confirm_now.message'),
        icon: 'far fa-exclamation-triangle',
        acceptLabel: t('yes'),
        acceptClass: 'p-button-danger',
        rejectLabel: t('no'),
        rejectClass: 'p-button-secondary',
        accept: async () => {
          setGlobalSettingsData(settingsFields);
          emit('on-save-scheduled');
          loadingSaveScheduled.value = false;
        },
        reject: () => {
          loadingSaveScheduled.value = false;
        },
        onHide: () => {
          loadingSaveScheduled.value = false;
        },
      });
    };

    /**
     * Populate timezone options
     */
    const populateTimezoneOptions = async () => {
      const allowedContinents = [
        'US', 'Europe', 'Africa', 'UTC', 'INDIAN', 'Canada', 'Brazil', 'Australia', 'Atlantic', 'Asia',
      ];

      // get timezones grouped by continent
      const getTimezonesGroupedByContinent = () => {
        const zones = moment.tz.names();
        const grouped: any = {
          Europe: [],
        };

        zones.forEach((zone) => {
          const parts = zone.split('/');
          const continent = parts[0];

          if (!allowedContinents.includes(continent)) {
            return;
          }

          if (!grouped[continent]) {
            grouped[continent] = [];
          }

          grouped[continent].push(zone);
        });

        return grouped;
      };
      const groupedTimezones = getTimezonesGroupedByContinent();

      // populate timezone options
      Object.keys(groupedTimezones).forEach((continent) => {
        const continentTimezones = groupedTimezones[continent];
        const continentTimezonesOptions = continentTimezones.map((timezone: string) => {
          const timezoneLabel = timezone.replace(/_/g, ' ');
          return { value: timezone, label: timezoneLabel };
        });

        timezoneOptions.value.push({
          label: continent,
          code: continent,
          items: continentTimezonesOptions,
        });
      });

      // get shop timezone and suggest it as first option
      const { item, err } = await Get<Shops>({
        id: UserState.activeShop?.id ?? 0,
        name: 'Shops',
        keyName: 'id_shop',
        fields: [
          'timezone_name',
        ],
      });

      let suggestTimezone = [{ value: 'Europe/Paris', label: 'Europe/Paris' }];
      const clientTimezone = moment.tz.guess();
      if (item && item.timezone_name && item.timezone_name !== '') {
        suggestTimezone = [{ value: item.timezone_name, label: item.timezone_name }];
        if (clientTimezone && clientTimezone !== '' && clientTimezone !== item.timezone_name) {
          suggestTimezone.push({ value: clientTimezone, label: clientTimezone });
        }
      } else if (clientTimezone && clientTimezone !== '') {
        suggestTimezone = [{ value: clientTimezone, label: clientTimezone }];
      }

      timezoneOptions.value.unshift({
        label: t('timezone_suggestion'),
        value: 'Suggestion',
        items: suggestTimezone,
      });
    };

    onMounted(async () => {
      await populateTimezoneOptions();
      selectedTimezone.value = timezoneOptions.value[0].items[0].value;
      // const date = moment();
      // const remainder = 15 - (date.minute() % 15);
      // date.add(remainder, 'minutes');
      // date.seconds(0);
      // selectedTime.value = date.format('HH:mm');
    });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      displayBasic,
      onHideEvent,
      onSaveScheduled,
      loadingSaveScheduled,
      typeSending,
      selectedDate,
      selectedTime,
      timeOptions,
      selectedTimezone,
      timezoneOptions,
      selectedPerfectTiming,
      perfectTimingOptions,
      selectedSlowdown,
      selectedIntervalBetweenSends,
      slowdownOptions,
    };
  },
});
</script>

<style lang="scss">
.modal-scheduled-bulk-campaign {
  .spm-bulk-type-sending {
    position: relative;
    border: solid 1px #BABFC3;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
    height: 5rem;
    cursor: pointer;

    &.active {
      background-color: $brand-color-primary;
      color: #fff;
    }

    &:hover {
      border-color: $brand-color-primary;
    }

    .sbts-header {
      position: relative;
      .sbtsh-left {
        position: absolute;
        top: 0.6rem;
        left: 0.8rem;
        i {
          font-size: 1.5rem;
        }
      }

      .sbtsh-right {
        position: absolute;
        top: 0.6rem;
        right: 0.8rem;
        i {
          font-size: 1rem;
        }
      }
    }

    .sbts-desc {
      position: absolute;
      bottom: 0.6rem;
      left: 0.8rem;
      font-size: 0.8rem;
      text-align: left;
    }
  }

  .grid {
    label {
      font-weight: normal;
      color: #6c757d;
      font-size: 0.95rem;
      font-family: "Source Sans Pro", sans-serif;
    }
  }

}
</style>
