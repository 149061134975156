<template>
  <InlineMessage
    style="width: 100%;"
    class="mb-3"
    severity="info"
  >
    {{ t('statsEditor.sidebarPanel.settings.email.infoSending') }}
  </InlineMessage>
  <div class="configuration-wrapper">
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t('statsEditor.sidebarPanel.settings.email.nameLabel') }}
      </label>
      <LocalizedTextInput
        id="StatsSettingsPanelSettingName"
        v-model="settingsFields.name"
        class="input-text"
        style="width: 100%;"
        :default-languages="defaultLanguages"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidationErrors"
        field="name"
        silent="false"
      />
    </div>
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t('statsEditor.sidebarPanel.settings.email.subjectLabel') }}
      </label>
      <LocalizedTextInput
        id="StatsSettingsPanelMailSubject"
        v-model="settingsFields.mail_subject"
        :display-translate="true"
        class="input-text"
        style="width: 100%;"
        :default-languages="defaultLanguages"
        :display-variables="true"
        :variables-list="subjectVariables"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidationErrors"
        field="mail_subject"
        silent="false"
      />
    </div>
    <div class="field-wrapper">
      <label class="block mb-2">
        {{ t('statsEditor.sidebarPanel.settings.email.mailingFrequenciesLabel') }}
      </label>
      <DateFrequencies
        v-model="settingsFields.mailing_frequencies"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="formValidationErrors"
        field="mailing_frequencies"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  defineComponent,
  reactive, ref, watch,
} from 'vue';
import {
  GlobalEmailSettingsData,
} from '@/types/stats-editor-types';
import { useStore } from '@/store';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import DateFrequencies from '@/components/stats-builder/fields/DateFrequencies.vue';
import InlineMessage from 'primevue/inlinemessage';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'EmailSettingsPanel',

  components: {
    LocalizedTextInput,
    FieldErrors,
    DateFrequencies,
    InlineMessage,
  },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { settingPanelValidationErrors: any }) {
    const { t } = useI18n();
    const store = useStore();

    const formValidationErrors = ref(props.settingPanelValidationErrors);
    const componentFieldErrorsKey = ref(0);
    const subjectVariables = ref([
      { value: '{var=campaign.name}', label: 'templateBuilder.variables.campaignNameSubject' },
      { value: '{var=system.date}', label: 'templateBuilder.variables.systemDateSubject' },
      // { value: '{var=template.subject}', label: 'templateBuilder.variables.emailSubject' },
    ]);

    watch(() => props.settingPanelValidationErrors, () => {
      formValidationErrors.value = props.settingPanelValidationErrors;
      componentFieldErrorsKey.value += 1;
    });

    const settingsFields: GlobalEmailSettingsData = reactive({
      name: (store.getters['statsEditor/getPageSettings'] as GlobalEmailSettingsData).name,
      mailing_frequencies: (store.getters['statsEditor/getPageSettings'] as GlobalEmailSettingsData).mailing_frequencies,
      mail_subject: (store.getters['statsEditor/getPageSettings'] as GlobalEmailSettingsData).mail_subject,
    });

    if (typeof settingsFields.name === 'string') {
      settingsFields.name = Object.assign(
        {},
        ...defaultLanguages.map((lang) => ({ [lang]: settingsFields.name })),
      );
    }
    if (typeof settingsFields.mail_subject === 'string') {
      settingsFields.mail_subject = Object.assign(
        {},
        ...defaultLanguages.map((lang) => ({ [lang]: settingsFields.mail_subject })),
      );
    }

    watch([settingsFields], () => {
      store.commit('statsEditor/setPageSettings', settingsFields);
    }, { deep: true });

    return {
      t,
      formValidationErrors,
      componentFieldErrorsKey,
      settingsFields,
      defaultLanguages,
      subjectVariables,
    };
  },
});
</script>
