import { List } from '@/composables/GraphQL';
import {
  CampaignsBulk,
  MarketingWorkflows,
  OperatorType,
} from '@/types/generated-types/graphql';
import { CampaignsBulkStatusEnum, IntervalDateEnum, MarketingWorkflowStatusEnum } from '@/types';
import { UserState } from '@/composables/User';
import moment, { DurationInputArg1 } from 'moment/moment';
import DurationConstructor = moment.unitOfTime.DurationConstructor;

export interface StatsPageFiltersMetaData {
  date_interval: {
    label: string;
    component: string;
  };
  id_marketing_workflow: {
    label: string;
    component: string;
    options: () => Promise<{ name: string; id: number }[]>;
  };
  id_campaign_bulk: {
    label: string;
    component: string;
    options: () => Promise<{ name: string; id: number }[]>;
  };
}

export const StatsPageFiltersMetaData: any = {
  date_interval: {
    label: 'statsEditor.sidebarPanel.filters.dateInterval',
    component: 'CustomDatePicker',
  },
  id_marketing_workflow: {
    label: 'statsEditor.sidebarPanel.filters.automationCampaigns',
    component: 'MultiSelect',
    defaultData: {
      visibleOnView: true,
      value: [],
    },
    getOptions: async () => {
      let result: any = [];
      const idShop = UserState.activeShop?.id ?? 0;

      const data = await List<MarketingWorkflows>({
        name: 'MarketingWorkflows',
        settings: {
          limit: 0,
          offset: 0,
          order: [
            {
              field: 'date',
              type: 'ASC',
            },
          ],
          filter: [
            { field: 'status', value: MarketingWorkflowStatusEnum.ARCHIVED, operator: OperatorType.NotEquals },
            { field: 'status', value: MarketingWorkflowStatusEnum.DELETED, operator: OperatorType.NotEquals },
            { field: 'id_shop', value: idShop, operator: OperatorType.Equals },
          ],
        },
        fields: ['id_marketing_workflow', 'name_marketing_workflow'],
      });

      if (data && data.items && data.items.length) {
        result = data.items.map((item) => ({
          name: item.name_marketing_workflow,
          id: item.id_marketing_workflow,
        }));
      }

      return result;
    },
  },
  id_campaign_bulk: {
    label: 'statsEditor.sidebarPanel.filters.bulkCampaigns',
    component: 'Dropdown',
    defaultData: {
      visibleOnView: true,
      value: [],
    },
    getOptions: async (currentFilters: any) => {
      // We calculate the minimum date according to current filter
      let minimumDate;
      const dateInterval = currentFilters.date_interval.value.interval;
      if (dateInterval === IntervalDateEnum.CUSTOM_DATE_RANGE) {
        // Custom range
        const { customDateRange } = currentFilters.date_interval.value;
        minimumDate = moment(customDateRange[0]);
      } else {
        // Interval from now
        let intervalValue: DurationInputArg1;
        let intervalUnit: DurationConstructor;

        switch (dateInterval) {
          case IntervalDateEnum.LAST_DAY:
            intervalValue = 1;
            intervalUnit = 'days';
            break;
          case IntervalDateEnum.LAST_3_DAYS:
            intervalValue = 3;
            intervalUnit = 'days';
            break;
          case IntervalDateEnum.LAST_7_DAYS:
            intervalValue = 7;
            intervalUnit = 'days';
            break;
          case IntervalDateEnum.LAST_14_DAYS:
            intervalValue = 14;
            intervalUnit = 'days';
            break;
          case IntervalDateEnum.LAST_30_DAYS:
            intervalValue = 30;
            intervalUnit = 'days';
            break;
          case IntervalDateEnum.LAST_3_MONTHS:
            intervalValue = 3;
            intervalUnit = 'months';
            break;
          case IntervalDateEnum.LAST_12_MONTHS:
            intervalValue = 12;
            intervalUnit = 'months';
            break;
          default:
            intervalValue = 30;
            intervalUnit = 'days';
            break;
        }

        minimumDate = moment().subtract(intervalValue, intervalUnit);
      }

      let result: any = [];
      const idShop = UserState.activeShop?.id ?? 0;

      const data = await List<CampaignsBulk>({
        name: 'CampaignsBulk',
        settings: {
          limit: 0,
          offset: 0,
          order: [
            {
              field: 'date_send',
              type: 'DESC',
            },
          ],
          filter: [
            { field: 'id_shop', value: idShop, operator: OperatorType.Equals },
            {
              field: 'status',
              value: [
                CampaignsBulkStatusEnum.SENDING,
                CampaignsBulkStatusEnum.SENT,
                CampaignsBulkStatusEnum.SUSPENDED,
                CampaignsBulkStatusEnum.SUSPENDED_BY_USER,
              ].join(','),
              operator: OperatorType.In,
            },
            {
              field: 'date_send',
              value: minimumDate.format('YYYY-MM-DD 00:00:00'),
              operator: OperatorType.GreaterThanOrEqualTo,
            },
          ],
        },
        fields: ['id_campaign_bulk', 'name', 'date_send'],
      });

      if (data && data.items && data.items.length) {
        result = data.items.map((item) => ({
          name: item.name,
          id: item.id_campaign_bulk,
          dateSend: item.date_send,
        }));
      }

      return result;
    },
  },
};
