
import {
  defineComponent,
  PropType,
  SetupContext,
  watch,
  ref,
  onMounted,
} from 'vue';

import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';

import { MEDIA_URL_PART_THUMB_GENERATION } from '@/components/template-builder/utils/constants';

import {
  TemplateEditorState,
  removeItemFromSectionsToRefresh,
} from '@/composables/template-editor/TemplateEditor';

import { generateSha1Hash } from '@/helpers';

import {
  TemplatePart,
} from '@/types';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'LoadSectionPreview',

  components: {
    Button,
    ProgressSpinner,
  },

  props: {
    item: {
      type: Object as PropType<TemplatePart>,
      required: true,
    },
  },

  emits: ['handle-selection', 'handle-deletion'],

  setup(props, { emit }: SetupContext) {
    const { t } = useI18n();

    const getBackgoundImageUrl = () => {
      return props.item.type === 'empty_section'
      ? '/images/template-builder/new_empty_section.png'
        : `${MEDIA_URL_PART_THUMB_GENERATION.replace('{SHA1_KEY}', generateSha1Hash(props.item.id_template_parts))}&t=${new Date().getTime()}`
    };

    const backgroundImageUrl = ref(getBackgoundImageUrl());

    const maxRetries = ref(30);
    const retryCount = ref(0);
    const loadingPreview = ref(false);

    const reRenderPreview = () => {
      backgroundImageUrl.value = getBackgoundImageUrl();
    };

    const onImageLoaded = () => {
      loadingPreview.value = false;
    };

    const onImageError = () => {
      loadingPreview.value = true;
      // Retry loading image
      if (retryCount.value < maxRetries.value) {
        retryCount.value++;
        setTimeout(() => {
          reRenderPreview();
        }, 1000);
      } else {
        loadingPreview.value = false;
      }
    };

    const handleSelection = async (item: TemplatePart) => {
      emit('handle-selection', item);
    };

    const handleDeletion = (item: TemplatePart, event: any) => {
      emit('handle-deletion', item, event);
    };

    const checkRefreshPreview = () => {
      if (TemplateEditorState.sectionsToRefresh.find((section) => section === props.item.id_template_parts)) {
        loadingPreview.value = true;
        setTimeout(() => {
          reRenderPreview();
          removeItemFromSectionsToRefresh(props.item.id_template_parts);
        }, 1000);
      }
    };

    watch(() => TemplateEditorState.sectionsToRefresh, () => {
      checkRefreshPreview();
    }, { deep: true });

    onMounted(() => {
      checkRefreshPreview();
    });

    return {
      t,
      backgroundImageUrl,
      loadingPreview,

      handleSelection,
      handleDeletion,
      onImageLoaded,
      onImageError,
    };
  },
});
