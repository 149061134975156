import { StringMap } from '@/types';

const activeColor = '#629D03';
const sectionActiveColor = '#CACACA';

export const boxShadowedItemResetStyles = {
  position: '',
  'box-shadow': '',
  'min-height': '',
};

export const borderedItemResetStyles = {
  position: '',
  border: '',
};

const commonButtonStyles = {
  cursor: 'pointer',
  fontSize: '35px',
  lineHeight: '35px',
  height: '35px',
  width: '35px',
  textAlign: 'center',
  marginBottom: '2px',
};

const commonButtonGroupStyles = {
  display: 'none',
  position: 'absolute',
  zIndex: '100000000',
};

const commonActionsButtonGroupStyles = {
  ...commonButtonGroupStyles,
  top: '0',
};

export const addButtonGroupStyles: { [type: string]: { [position: string]: StringMap } } = {
  email: {
    top: {
      ...commonButtonGroupStyles,
      top: '0px',
      transform: 'translate(-50%, calc(-100% + 2px))',
    },
    bottom: {
      ...commonButtonGroupStyles,
      bottom: '0px',
      transform: 'translate(-50%, calc(100% - 2px))',
    },
  },
  default: {
    top: {
      ...commonButtonGroupStyles,
      top: '0px',
      transform: 'translate(-50%, -100%)',
    },
    bottom: {
      ...commonButtonGroupStyles,
      bottom: '0px',
      transform: 'translate(-50%, 100%)',
    },
  },
};

export const actionsButtonGroupStyles = {
  ...commonActionsButtonGroupStyles,
  left: '0px',
  paddingLeft: '8px',
  paddingTop: '8px',
};

export const columnActionsButtonGroupStyles = {
  ...commonActionsButtonGroupStyles,
  left: '0px',
  paddingLeft: '8px',
  paddingTop: '8px',
};

export const sectionActionsButtonGroupStyles = {
  ...commonActionsButtonGroupStyles,
  left: '0px',
  paddingLeft: '8px',
  paddingTop: '8px',
};

export const primaryButtonStyles = {
  ...commonButtonStyles,
  borderRadius: '2px',
  color: '#FFFFFF',
};

export const addWidgetButtonStyle = {
  ...primaryButtonStyles,
  background: activeColor,
  padding: '0px',
  left: '50%',
};

export const addSectionButtonStyle = {
  ...primaryButtonStyles,
  background: sectionActiveColor,
  padding: '0px',
  left: '50%',
};

export const secondaryButtonStyles = {
  ...commonButtonStyles,
  border: '1px solid #BABFC4',
  borderRadius: '5px',
  background: '#FFFFFF',
  color: '#BABFC4',
  boxSizing: 'initial',
};
export const AIButtonStyles = {
  cursor: 'pointer',
  fontSize: '33px',
  lineHeight: '33px',
  height: '33px',
  width: '33px',
  textAlign: 'center',
  marginBottom: '2px',
  border: '3px solid transparent',
  borderRadius: '5px',
  background: 'linear-gradient(#fff, #fff) padding-box,'
    + 'linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%) border-box',
  color: '#BABFC4',
  boxSizing: 'initial',
};

export const hoverActiveItemResetStyles = {
  backgroundColor: '',
};

export const hoverResetStyles = {
  ...hoverActiveItemResetStyles,
  border: '',
  position: '',
};
