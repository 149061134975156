<template>
  <GMapMap
    :center="coordinate"
    :zoom="13"
    :class="className"
    map-type-id="terrain"
  >
    <GMapCluster>
      <GMapMarker
        v-if="coordinate.lat !== 0 && coordinate.lng !== 0"
        :position="coordinate"
        :clickable="false"
        :draggable="false"
      />
    </GMapCluster>
  </GMapMap>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  PropType,
  ref,
  onMounted,
} from 'vue';
import { showToastError } from '@/helpers';
import { countries } from '@/types/country-language-options';
import {
  ContactAddress,
} from '@/types/data-explorer-types';
import { useI18n } from 'vue-i18n';

interface LatLng {
  lat: number;
  lng: number;
}

export default defineComponent({
  name: 'AddressMap',

  props: {
    address: {
      type: Object as PropType<ContactAddress>,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: '',
    }
  },

  setup (props) {
    const { t } = useI18n();

    const coordinate = ref<LatLng>({
      lat: 0,
      lng: 0,
    });

    const fullAddress = computed(() => {
      const address = [];
      if (props.address.address1) {
        address.push(props.address.address1);
      }
      if (props.address.city && props.address.postcode) {
        address.push(`${props.address.city} ${props.address.postcode}`);
      } else if (props.address.city && !props.address.postcode) {
        address.push(props.address.city);
      } else if (!props.address.city && props.address.postcode) {
        address.push(props.address.postcode);
      }
      if (props.address.country) {
        const country = countries.find((countryItem) => countryItem.code.toLowerCase() === props.address.country?.toLowerCase());
        if (country) {
          address.push(country.label);
        }
      }
      return address.join(', ');
    });

    const loadGoogleMapsApi = (): Promise<void> => {
      return new Promise((resolve) => {
        const checkIfGoogleMapsIsLoaded = setInterval(() => {
          if (window.google && window.google.maps) {
            clearInterval(checkIfGoogleMapsIsLoaded);
            resolve();
          }
        }, 100);
      });
    };

    const geocodeAddress = (address: string): Promise<LatLng> => {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results.length) {
            const location = results[0].geometry.location;
            resolve({
              lat: location.lat(),
              lng: location.lng(),
            });
          } else {
            reject(status);
          }
        });
      });
    };

    onMounted(async () => {
      await loadGoogleMapsApi();
      try {
        const addressCoordinate = await geocodeAddress(fullAddress.value);
        if (addressCoordinate) {
          coordinate.value = addressCoordinate;
        } else {
          await showToastError(t('dataExplorer.noCoordinateFound'));
        }
      } catch (error) {
        await showToastError(t('dataExplorer.noCoordinateFound'));
      }
    });

    return {
      t,
      coordinate,
    };
  }
})
</script>