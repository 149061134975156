<template>
  <Dialog
    v-model:visible="dialog"
    :header="data.Name + t('myLists.importContacts.header')"
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ maxWidth: '35vw', width: '100%' }"
    :content-style="{ textAlign: 'center' }"
    :modal="true"
  >
    <div class="grid col-12">
      <div class="col-4">
        <Button
          :label="t('myLists.importContacts.files.option')"
          class="p-button-raised p-button-secondary p-button-text"
          style="width: 200px; height: 100px;border-block-color: #f3cc9e;"
          type="Button"
          @click="showImportCustomers(data.ID, data.Name, 'file')"
        />
      </div>
      <div class="col-4">
        <Button
          :label="t('myLists.importContacts.copyPaste.option')"
          class="p-button-raised p-button-secondary p-button-text"
          style="width: 200px; height: 100px;border-block-color: #f3cc9e;"
          type="Button"
          @click="showImportCustomers(data.ID, data.Name, 'copyPaste')"
        />
      </div>
      <div class="col-4">
        <Button
          :label="t('myLists.importContacts.segmentation.option')"
          class="p-button-raised p-button-secondary p-button-text"
          style="width: 200px; height: 100px;border-block-color: #f3cc9e;"
          type="Button"
          @click="showImportCustomers(data.ID, data.Name, 'segmentation')"
        />
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('rejectLabel')"
        icon="far fa-times"
        @click="closeDisplay"
      />
    </template>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent, PropType, ref, SetupContext, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import {
  MyLists, myListsState,
} from '@/composables/mes-listes/ListsManage';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useStore} from "@/store";

export default defineComponent({
  name: 'ImportActions',
  components: {
    Dialog,
    Button,
  },

  props: {
    data: {
      type: Object as PropType<MyLists>,
      required: true,
    },

    displayDialog: {
      type: Boolean,
      required: true,
    },
  },

  emits: [
    'close-import-dialog',
  ],

  setup(props: { data: MyLists; displayDialog: boolean}, context: SetupContext) {
    const { t } = useI18n();
    const store = useStore();
    const dialog = ref(props.displayDialog);

    const closeDisplay = () => {
      context.emit('close-import-dialog', false);
    };

    const showImportCustomers = async (idShopList: number, name: string, typeImport: string) => {
      // await store.dispatch('listImportEditor/importCustomersAction',
      //   { idShopList, typeImport, listName: props.data.Name });
      //
      // dialog.value = false;
      // closeDisplay();
    };

    return {
      showImportCustomers,
      closeDisplay,
      dialog,
      t,
    };
  },
});
</script>

<style scoped lang="scss">
</style>
