<template>
  <Dialog
    :visible="displayModal"
    modal
    :header="t('fileManager.chooseDirectoryModal.title')"
    :style="{ width: '45vw' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :draggable="false"
    @update:visible="handleHideDialog"
  >
    <div class="flex">
      <Tree
        v-model:selection-keys="selectedFolder"
        :expanded-keys="expandedFolders"
        :value="folders"
        :loading="loading"
        selectionMode="single"
        class="w-full">
      </Tree>
    </div>
    <div
      class="flex justify-content-center mt-2"
    >
      <Button
        :label="t('fileManager.chooseDirectoryModal.chooseBtn')"
        :loading="loadingButton"
        class="p-button-success"
        @click="handleChooseDirectory"
      />
    </div>
  </Dialog>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
} from 'vue';

import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Tree from 'primevue/tree';

import { UserState } from '@/composables/User';
import { listFolders } from '@/composables/configs/configuration';

import { showToastError } from '@/helpers';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ChooseDirectoryModal',

  components: {
    Button,
    Dialog,
    Tree,
  },

  props: {
    displayModal: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['on-close-dialog', 'on-choose-folder'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const idShop: number = UserState.activeShop ? UserState.activeShop.id : 0;

    const selectedFolder = ref();

    const folders = ref([]);
    const expandedFolders = ref<Record<string, any>>({});
    const loading = ref(false);
    const loadingButton = ref(false);

    const handleHideDialog = () => {
      emit('on-close-dialog');
    };

    const handleChooseDirectory = async () => {
      if (!selectedFolder.value) {
        await showToastError(t('fileManager.chooseDirectoryModal.chooseDirectoryErrorMessage'));
      } else {
        loadingButton.value = true;
        const choosedFolder = Object.keys(selectedFolder.value)[0];
        emit('on-choose-folder', choosedFolder, () => {
          loadingButton.value = false;
        });
      }
    };

    const collectKeys = (array: any[]) => {
      let keys: Record<string, any> = {};

      const traverse = (node: any) => {
        keys[node.key] = true;
        if (node.children && node.children.length > 0) {
          node.children.forEach((child: any) => traverse(child));
        }
      }

      array.forEach((item) => traverse(item));

      return keys;
    }

    onMounted(async () => {
      try {
        loading.value = true;
        const folderLists = await listFolders(idShop);
        folders.value = folderLists.data;

        expandedFolders.value = collectKeys(folders.value);
      } catch (error) {
        await showToastError(t('errorMessages.GENERIC_ERROR'));
      } finally {
        loading.value = false;
      }
    });

    return {
      t,
      selectedFolder,
      folders,
      loading,
      loadingButton,
      expandedFolders,

      handleHideDialog,
      handleChooseDirectory,
    };
  },
});
</script>

<style lang="scss" scoped>
</style>
