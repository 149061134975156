<template>
  <SpmOverlayPanel
    class-trigger="flex align-items-center justify-content-center mr-2"
    class-header="flex justify-content-between flex-wrap p-3 header-content spm-overlay-panel-notification-header"
    class-content="flex flex-column spm-overlay-panel-search-list-content"
    class-footer="flex align-items-center justify-content-center spm-overlay-panel-search-list-footer"
    min-panel-width="540px"
    max-panel-width="540px"
    :close-panel="closePanel"
    @onclose-panel="closePanel = false"
  >
    <template #trigger>
      <SpmButton
        icon="fa-regular fa-search"
        class-style="p-button-text p-button-secondary"
      />
    </template>
    <template #header>
      <div class="flex align-items-center justify-content-center ml-2">
        <h4>
          <i class="fa-regular fa-filters mr-1" /> {{ t('myLists.searchComponent.title') }}
        </h4>
      </div>
    </template>
    <div class="flex mb-2">
      <div
        class="p-inputgroup"
        style="width: 60%;"
      >
        <MultiSelect
          v-model="filters.gender"
          class="multiselect-custom"
          :options="genders"
          option-label="label"
          :placeholder="t('myLists.searchComponent.gender')"
        >
          <template #value="slotProps">
            <div
              v-for="(option, index) of slotProps.value"
              :key="option.code"
              class="country-item country-item-value"
            >
              <i :class="option.icon" />
              {{ option.label }}
              <span v-if="(slotProps.value.length > 1) && (index < slotProps.value.length-1)">
                ,
              </span>
            </div>
            <div v-if="!slotProps.value || slotProps.value.length === 0">
              {{ slotProps.placeholder }}
            </div>
          </template>
          <template #option="slotProps">
            <div>
              <span><i :class="slotProps.option.icon" />
                {{ slotProps.option.label }}
              </span>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>
    <div class="flex flex-row mb-2">
      <div class="flex align-items-center justify-content-center mr-1">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="fa-regular fa-user" /></span>
          <InputText
            v-model="filters.last_name"
            type="text"
            class="form-control"
            :placeholder="t('myLists.searchComponent.name')"
          />
        </div>
      </div>
      <div class="flex align-items-center justify-content-center">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="fa-regular fa-user" />
          </span>
          <InputText
            v-model="filters.first_name"
            type="text"
            class="form-control"
            :placeholder="t('myLists.searchComponent.firstName')"
          />
        </div>
      </div>
    </div>

    <div class="flex mb-2">
      <div
        class="p-inputgroup"
        style="width: 60%;"
      >
        <MultiSelect
          v-model="filters.newsletter"
          class="selectpicker form-control"
          :placeholder="t('myLists.searchComponent.optinEmail')"
          :options="clientTypeOptions"
          option-label="label"
        />
      </div>
    </div>
    <div class="flex align-items-center justify-content-center mb-2">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="fa-regular fa-envelope" /></span>
        <InputText
          v-model="filters.email"
          type="text"
          class="form-control"
          :placeholder="t('myLists.searchComponent.email')"
        />
      </div>
    </div>

    <div class="flex flex-row mb-2">
      <div class="flex align-items-center justify-content-center mr-1">
        <div class="p-inputgroup">
          <Calendar
            id="dateMin"
            v-model="filters.birthday_min"
            :show-icon="true"
            :show-button-bar="true"
            :placeholder="t('myLists.searchComponent.birthdayMin')"
            date-format="yy-mm-dd"
            :max-date="todaysDate"
          />
        </div>
      </div>
      <div class="flex align-items-center justify-content-center">
        <div class="p-inputgroup">
          <Calendar
            id="dateMax"
            v-model="filters.birthday_max"
            :show-icon="true"
            :show-button-bar="true"
            :placeholder="t('myLists.searchComponent.birthdayMax')"
            date-format="yy-mm-dd"
            :max-date="todaysDate"
          />
        </div>
      </div>
    </div>

    <div class="flex align-items-center justify-content-center mb-2">
      <div class="p-inputgroup">
        <MultiSelect
          v-model="filters.languages"
          class="selectpicker form-control"
          :placeholder="t('myLists.searchComponent.langChoice')"
          :options="languages"
          option-label="label"
          :filter="true"
        />
      </div>
    </div>

    <div class="flex align-items-center justify-content-center mb-2">
      <div class="p-inputgroup">
        <MultiSelect
          v-model="filters.countries"
          class="multiselect-custom"
          :placeholder="t('myLists.searchComponent.countryChoice')"
          :options="countries"
          option-label="label"
          :filter="true"
        >
          <template #value="slotProps">
            <div
              v-for="(option, index) of slotProps.value"
              :key="option.code"
              class="country-item country-item-value"
            >
              <img
                v-if="option.icon"
                :src="option.icon"
                alt=""
                class="mr-2"
                width="18"
              >
              {{ option.label }}
              <span v-if="(slotProps.value.length > 1) && (index < slotProps.value.length-1)">
                ,
              </span>
            </div>
            <template v-if="!slotProps.value || slotProps.value.length === 0">
              {{ slotProps.placeholder }}
            </template>
          </template>
          <template #option="slotProps">
            <div>
              <span>
                <img
                  :src="slotProps.option.label"
                  alt=""
                  class="mr-2"
                  width="18"
                >
                {{ slotProps.option.label }}
              </span>
            </div>
          </template>
        </MultiSelect>
      </div>
    </div>

    <template #footer>
      <div class="flex align-items-center justify-content-center mt-1">
        <Message
          v-if="errMessage"
          severity="error"
          style="margin-top: 10px; text-align: center;"
          @close="resetErrorMessage"
        >
          {{ errMessage }}
        </Message>
      </div>

      <div class="flex flex-column align-items-center justify-content-center mt-1">
        <div class="flex mb-1">
          <SpmButton
            id="submit-filtres-search"
            :label="t('search')"
            icon="fa fa-search fa-lg"
            class-style="p-button p-button-success"
            @click="searchCustomers"
          />
        </div>
        <div class="flex">
          <SpmButton
            v-if="reinitialise"
            :label="t('myLists.searchComponent.reInitialise')"
            class-style="p-button p-button-secondary"
            icon="fa fa-undo"
            @click="showReInit"
          />
        </div>
      </div>
    </template>
  </SpmOverlayPanel>
</template>

<script lang="ts">
import {
  reactive, ref, defineComponent, SetupContext, PropType,
} from 'vue';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import { countries, languages } from '@/types/country-language-options';
import { SpmTableFilter } from '@/types';
import { OperatorType } from '@/types/generated-types/graphql';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import Message from 'primevue/message';
import { useI18n } from 'vue-i18n';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import SpmOverlayPanel from '@/components/spm-primevue/SpmOverlayPanel.vue';

export default defineComponent({
  name: 'SearchCustomers',
  components: {
    MultiSelect,
    InputText,
    Calendar,
    Message,
    SpmButton,
    SpmOverlayPanel,
  },

  props: {
    idShopList: {
      type: String,
      required: false,
      default: '',
    },

    originalFilters: {
      type: Array as PropType<SpmTableFilter[]>,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: '',
    },

  },

  emits: {
    'search-customers': Array,
    'close-panel': Boolean,
  },

  setup(props: {idShopList: string; originalFilters: SpmTableFilter[]; type: string}, { emit }: SetupContext) {
    const { t } = useI18n();
    const persistentFilter = ref<SpmTableFilter[]>([]);
    const errMessage = ref('');
    const todaysDate = new Date();
    const originalFilters = ref<SpmTableFilter[]>(props.originalFilters);
    const reinitialise = ref(false);

    interface Values{
      code: string| number;
      label: string;
      icon: string;
    }

    interface AllFilters {
      gender: Values[];
      last_name: string;
      first_name: string;
      email: string;
      newsletter: Values[];
      birthday_min: string;
      birthday_max: string;
      countries: Values[];
      languages: Values[];
    }

    const filters = reactive<AllFilters>({
      gender: [],
      last_name: '',
      first_name: '',
      email: '',
      newsletter: [],
      birthday_min: '',
      birthday_max: '',
      countries: [],
      languages: [],
    });

    const genders = ref([
      { label: t('myLists.searchComponent.man'), code: 1, icon: 'fa fa-mars' },
      { label: t('myLists.searchComponent.woman'), code: 2, icon: 'fa fa-venus' },
      { label: t('myLists.searchComponent.unknown'), code: 0, icon: 'fa fa-neuter' },
    ]);

    const clientTypeOptions = ref([
      { label: t('myLists.searchComponent.notRegistered.text'), code: 0, icon: 'fa fa-registered' },
      { label: t('myLists.searchComponent.optIn.text'), code: 1, icon: 'fa fa-check' },
      { label: t('myLists.searchComponent.doubleOptIn.text'), code: 2, icon: 'fa fa-check-double' },
      { label: t('myLists.searchComponent.unsubscribed.text'), code: 100, icon: 'fa fa-neuter' },
    ]);

    const resetErrorMessage = () => {
      errMessage.value = '';
    };

    const initAllFilters = () => {
      filters.gender = [];
      filters.last_name = '';
      filters.first_name = '';
      filters.email = '';
      filters.newsletter = [];
      filters.birthday_min = '';
      filters.birthday_max = '';
      filters.countries = [];
      filters.languages = [];
    };

    const showReInit = () => {
      persistentFilter.value = originalFilters.value;
      reinitialise.value = !reinitialise.value;
      emit('search-customers', originalFilters);
      initAllFilters();
    };

    const closePanel = ref(false);

    const searchCustomers = () => {
      closePanel.value = true;
      const filterCopy: SpmTableFilter[] = [];

      if (filters.gender.length > 0) {
        const gender: SpmTableFilter = {
          value: filters.gender.map((genderStr) => genderStr.code).join(','),
          operator: OperatorType.In,
          field: 'sc.gender',
        };
        filterCopy.push(gender);
      }

      if (filters.last_name) {
        const lastName: SpmTableFilter = {
          value: `%${filters.last_name}%`,
          operator: OperatorType.Like,
          field: 'sc.last_name',
        };
        filterCopy.push(lastName);
      }

      if (filters.first_name) {
        const firstName: SpmTableFilter = {
          value: `%${filters.first_name}%`,
          operator: OperatorType.Like,
          field: 'sc.first_name',
        };
        filterCopy.push(firstName);
      }

      if (filters.email) {
        const email: SpmTableFilter = {
          value: filters.email,
          operator: OperatorType.Equals,
          field: 'sc.email',
        };
        filterCopy.push(email);
      }

      if (filters.newsletter.length > 0) {
        const containsOnlyUnsubscribed = filters.newsletter.find((filter) => filter.code === 100) !== undefined && filters.newsletter.length === 1;
        if (containsOnlyUnsubscribed) {
          const unsubType: SpmTableFilter[] = [
            {
              value: '1',
              operator: OperatorType.Ampersand,
              field: 'sc.unsub_type',
              operation: 'or',
            },
            {
              value: '4',
              operator: OperatorType.Ampersand,
              field: 'sc.unsub_type',
              operation: 'or',
            },
            {
              value: '16',
              operator: OperatorType.Ampersand,
              field: 'sc.unsub_type',
              operation: 'or',
            },
          ];
          filterCopy.push(...unsubType);
        } else {
          const containsUnsubscribed = filters.newsletter.find((filter) => filter.code === 100) !== undefined;
          if (containsUnsubscribed) {
            const newsletter: SpmTableFilter[] = [
              {
                value: filters.newsletter.filter((newsletterStr) => newsletterStr.code !== 100).map((item) => item.code).join(','),
                operator: OperatorType.In,
                field: 'sc.newsletter',
              },
            ];
            filterCopy.push(...newsletter);
          } else {
            const newsletter: SpmTableFilter[] = [
              {
                value: filters.newsletter.filter((newsletterStr) => newsletterStr.code !== 100).map((item) => item.code).join(','),
                operator: OperatorType.In,
                field: 'sc.newsletter',
              },
              {
                value: '1',
                operator: OperatorType.NotEqualsAmpersand,
                field: 'sc.unsub_type',
              },
              {
                value: '4',
                operator: OperatorType.NotEqualsAmpersand,
                field: 'sc.unsub_type',
              },
              {
                value: '16',
                operator: OperatorType.NotEqualsAmpersand,
                field: 'sc.unsub_type',
              },
            ];
            filterCopy.push(...newsletter);
          }
        }
      }

      if (filters.birthday_min) {
        const birthday_min: SpmTableFilter = {
          value: moment(filters.birthday_min).format('YYYY-MM-DD'),
          operator: OperatorType.GreaterThan,
          field: 'sc.birthday_min',
        };
        filterCopy.push(birthday_min);
      }

      if (filters.birthday_max) {
        const birthday_min: SpmTableFilter = {
          value: moment(filters.birthday_max).format('YYYY-MM-DD'),
          operator: OperatorType.LessThan,
          field: 'sc.birthday_max',
        };

        filterCopy.push(birthday_min);
      }

      if (filters.birthday_min > filters.birthday_max) {
        errMessage.value = 'BirthDay cannot be less';
      }

      if (filters.countries.length > 0) {
        const country: SpmTableFilter = {
          value: filters.countries.map((countryStr) => countryStr.code).join(','),
          operator: OperatorType.In,
          field: 'sc.country',
        };
        filterCopy.push(country);
      }

      if (filters.languages.length > 0) {
        const lang: SpmTableFilter = {
          value: filters.languages.map((languageStr) => languageStr.code).join(','),
          operator: OperatorType.In,
          field: 'sc.lang',
        };
        filterCopy.push(lang);
      }

      if (filterCopy.length === 0) {
        emit('search-customers', originalFilters);
      } else {
        if (props.type) {
          filterCopy.push({
            value: props.type,
            operator: OperatorType.Equals,
            field: 'sc.type',
          });
        }
        const operationType: SpmTableFilter = {
          value: 'search',
          operator: OperatorType.Equals,
          field: 'list_type',
        };
        filterCopy.push(operationType);

        persistentFilter.value = [...originalFilters.value, ...filterCopy];
        emit('search-customers', persistentFilter);
      }
      reinitialise.value = true;
    };

    return {
      t,
      genders,
      clientTypeOptions,
      countries,
      languages,
      filters,
      searchCustomers,
      errMessage,
      todaysDate,
      resetErrorMessage,
      showReInit,
      reinitialise,
      closePanel,
    };
  },
});
</script>

<style lang="scss" scoped>
.list-customers-primary-filter {margin:0!important;text-align:center;float:left;}
#list-customers-secondary-filters {background-color: #f6f6f6;padding: 10px; border: 1px solid #ccc; }
.list-customers-secondary-filter {margin-top:6px;}
.spm_advanced_search_link{margin-top: 5px; text-align: center;}

::v-deep(.multiselect-custom) {
  .p-multiselect-label:not(.p-placeholder) {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .country-item-value {
    padding: .25rem 0.3125rem .5rem 0;
    display: inline-flex;
  }
}

#list-customers-filters {
  background-color: #f8f8f8;
  border: 1px solid #e9ecef;
  .header-content {
    border-bottom: 1px solid #e9ecef;
  }
}
</style>

<style lang="scss">
.spm-overlay-panel-search-list-content {
  overflow-y: auto;
  min-height: 21rem;
  max-height: 21rem;
  padding: 1rem;
}

.spm-overlay-panel-search-list-footer {
  padding: .7rem 1rem;
}
</style>
