
import { defineComponent, PropType, ref } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ShopsProductsCategoriesList } from '@/types/generated-types/graphql';
import Listbox from 'primevue/listbox';
import InputText from 'primevue/inputtext';
import ShopsProductsCategoriesListList from '@/composables/shops-products-categories-list/ShopsProductsCategories';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'CategorySelection',

  components: {
    Dialog,
    Button,
    Listbox,
    InputText,
    Skeleton,
  },

  props: {
    translation: {
      type: Function,
      require: true,
      default: () => ({}),
    },

    defaultLang: {
      type: String,
      required: true,
    },

    availableCategoriesArr: {
      type: Array as PropType<ShopsProductsCategoriesList[]>,
      required: true,
    },

    chosenCategoriesArr: {
      type: Array as PropType<ShopsProductsCategoriesList[]>,
      required: true,
    },

    categorySearchStr: {
      type: String,
      required: true,
    },
  },

  emits: ['close-action-modal'],

  setup(props, context) {
    const dialogVisible = ref(true);
    const category = ref(props.categorySearchStr);
    const productsCategoriesAvailableList = ref<ShopsProductsCategoriesList[]>(props.availableCategoriesArr);
    const productsCategoriesChosenList = ref<ShopsProductsCategoriesList[]>(props.chosenCategoriesArr);
    const chosenProductsCategoriesList = ref<ShopsProductsCategoriesList[]>([]);
    const availableProductsCategoriesList = ref<ShopsProductsCategoriesList[]>([]);
    let timer: any = null;

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', productsCategoriesChosenList.value, productsCategoriesAvailableList.value, category);
    };

    const handleSearch = async () => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await ShopsProductsCategoriesListList(category.value, props.defaultLang, 10000);
        if (result !== null) {
          availableProductsCategoriesList.value = [];
          productsCategoriesAvailableList.value = result;
        }
      }, 1000);
    };

    const moveItemsToChosen = () => {
      productsCategoriesChosenList.value = chosenProductsCategoriesList.value.concat(productsCategoriesChosenList.value);

      productsCategoriesChosenList.value.forEach((chosenCategory: ShopsProductsCategoriesList) => {
        availableProductsCategoriesList.value = availableProductsCategoriesList.value.filter(
          (productCategoryAvailable: ShopsProductsCategoriesList) => productCategoryAvailable.id_shop_product_category_list !== chosenCategory.id_shop_product_category_list,
        );
      });

      productsCategoriesChosenList.value = productsCategoriesChosenList.value.concat(availableProductsCategoriesList.value);

      productsCategoriesChosenList.value.forEach((shopsProductsCat: ShopsProductsCategoriesList) => {
        productsCategoriesAvailableList.value = productsCategoriesAvailableList.value.filter(
          (shopsProductsAvailable: ShopsProductsCategoriesList) => shopsProductsAvailable.id_shop_product_category_list !== shopsProductsCat.id_shop_product_category_list,
        );
      });

      availableProductsCategoriesList.value = [];
      chosenProductsCategoriesList.value = [];
    };

    const moveItemsToAvailable = () => {
      productsCategoriesAvailableList.value = productsCategoriesAvailableList.value.concat(chosenProductsCategoriesList.value);

      chosenProductsCategoriesList.value.forEach((shopsProductsCategory: ShopsProductsCategoriesList) => {
        productsCategoriesChosenList.value = productsCategoriesChosenList.value.filter(
          (listShopsProducts: ShopsProductsCategoriesList) => listShopsProducts.id_shop_product_category_list !== shopsProductsCategory.id_shop_product_category_list,
        );
      });

      chosenProductsCategoriesList.value = [];
    };

    return {
      dialogVisible,
      updateDialogVisibility,
      category,
      handleSearch,
      productsCategoriesAvailableList,
      productsCategoriesChosenList,
      chosenProductsCategoriesList,
      availableProductsCategoriesList,
      moveItemsToChosen,
      moveItemsToAvailable,
    };
  },
});
