import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Pixie {
  resetAndOpenEditor: Function;
  resetEditor: Function;
  open: Function;
  close: Function;
  init: Function;
  setConfig: Function;
}

export default function usePixieEditor() {
  const { t } = useI18n();
  const image = ref('');

  const pixieTranslations = {
    'Loading Image': t('pixieEditor.loading'),
    'Processing Image': t('pixieEditor.processing'),
    Filter: t('pixieEditor.filter'),
    Resize: t('pixieEditor.resize'),
    Crop: t('pixieEditor.crop'),
    Transform: t('pixieEditor.transform'),
    Draw: t('pixieEditor.draw'),
    Drawing: t('pixieEditor.drawing'),
    Text: t('pixieEditor.text'),
    Shape: t('pixieEditor.shape'),
    Shapes: t('pixieEditor.shapes'),
    Sticker: t('pixieEditor.sticker'),
    Stickers: t('pixieEditor.stickers'),
    Frame: t('pixieEditor.frame'),
    Corners: t('pixieEditor.corners'),
    Background: t('pixieEditor.background'),
    Merge: t('pixieEditor.merge'),
    History: t('pixieEditor.history'),
    Objects: t('pixieEditor.objects'),
    Image: t('pixieEditor.image'),
    mainImage: t('pixieEditor.mainImage'),
    'Main Image': t('pixieEditor.mainImage'),
    'Canvas Background': t('pixieEditor.canvasBackground'),
    Width: t('pixieEditor.width'),
    Height: t('pixieEditor.height'),
    'Brush Color': t('pixieEditor.brushColor'),
    'Brush Type': t('pixieEditor.brushType'),
    'Brush Size': t('pixieEditor.brushSize'),
    Cancel: t('pixieEditor.cancel'),
    Close: t('pixieEditor.close'),
    Apply: t('pixieEditor.apply'),
    Size: t('pixieEditor.size'),
    'Maintain Aspect Ratio': t('pixieEditor.maintainAspectRatio'),
    'Use Percentages': t('pixieEditor.usePercentages'),
    Radius: t('pixieEditor.radius'),
    'Align Text': t('pixieEditor.alignText'),
    'Format Text': t('pixieEditor.formatText'),
    'Color Picker': t('pixieEditor.colorPicker'),
    'Add Text': t('pixieEditor.addText'),
    Font: t('pixieEditor.font'),
    Upload: t('pixieEditor.upload'),
    'Google Fonts': t('pixieEditor.googleFonts'),
    Shadow: t('pixieEditor.shadow'),
    Color: t('pixieEditor.color'),
    Outline: t('pixieEditor.outline'),
    Texture: t('pixieEditor.texture'),
    Gradient: t('pixieEditor.gradient'),
    'Text Style': t('pixieEditor.textStyle'),
    Delete: t('pixieEditor.delete'),
    'Background Color': t('pixieEditor.backgroundColor'),
    'Outline Width': t('pixieEditor.outlineWidth'),
    Blur: t('pixieEditor.blur'),
    'Offset X': t('pixieEditor.offsetX'),
    'Offset Y': t('pixieEditor.offsetY'),
    Open: t('pixieEditor.open'),
    Done: t('pixieEditor.done'),
    Save: t('pixieEditor.save'),
    Zoom: t('pixieEditor.zoom'),
    Editor: t('pixieEditor.editor'),
    'Applied: filter': t('pixieEditor.appliedFilter'),
    'Applied: resize': t('pixieEditor.appliedResize'),
    'Applied: crop': t('pixieEditor.appliedCrop'),
    'Applied: transform': t('pixieEditor.appliedTransform'),
    'Added: drawing': t('pixieEditor.addedDrawing'),
    'Added: text': t('pixieEditor.addedText'),
    'Added: shape': t('pixieEditor.addedShape'),
    'Added: sticker': t('pixieEditor.addedSticker'),
    'Added: frame': t('pixieEditor.addedFrame'),
    'Objects: merged': t('pixieEditor.objectsMerged'),
    'Applied: rounded corners': t('pixieEditor.appliedRoundedCorners'),
    'Changed: background': t('pixieEditor.changedBackground'),
    'Changed: background image': t('pixieEditor.changedBackgroundImage'),
    'Added: overlay image': t('pixieEditor.addedOverlayImage'),
    Initial: t('pixieEditor.initial'),
    'Loaded=> state': t('pixieEditor.loadedState'),
    'Changed=> style': t('pixieEditor.changedStyle'),
    'New Text': t('pixieEditor.newText'),
    Opacity: t('pixieEditor.opacity'),
  };

  let pixie: Pixie | null;
  const showPixieEditor = (iframe: Window & { Pixie?: any }, imageUrl: string, saveCallback: Function) => {
    image.value = imageUrl;

    pixie = new iframe.Pixie({
      selector: '#editor-container',
      crossOrigin: true,
      baseUrl: '/libs/pixiev2/assets',
      image: image.value,
      activeLanguage: 'current',
      languages: {
        current: pixieTranslations,
      },

      onLoad: () => {
        if (pixie) {
          pixie.resetEditor({ image: image.value });
        }
      },

      onSave: (data: File, img: string) => {
        saveCallback(data, img);
      },

      ui: {
        activeTheme: 'Shopimind',
        themes: [
          {
            name: 'Shopimind',
            isDark: false,
            colors: {
              '--be-primary-light': '#94c840',
              '--be-primary': '#94c840',
              '--be-primary-dark': '#94c840',
            },
          },
        ],
      },
    });
  };

  const destroyPixieEditor = () => {
    pixie = null;
  };

  return {
    pixieTranslations,
    showPixieEditor,
    destroyPixieEditor,
  };
}
