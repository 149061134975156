import {
  TemplateParentTypeEnum,
  WidgetTypeConfig,
} from '@/types';
// eslint-disable-next-line import/no-cycle
import popupWidgetSmartProductListConfig from '@/components/template-builder/config/templates/popup/widgets/widget-smart-product-list-fields-config';
// eslint-disable-next-line import/no-cycle
import embedWidgetSmartProductListConfig from '@/components/template-builder/config/templates/embed/widgets/widget-smart-product-list-fields-config';
// eslint-disable-next-line import/no-cycle
import emailWidgetSmartProductListConfig from '../templates/email/widgets/widget-smart-product-list-fields-config';
// eslint-disable-next-line import/no-cycle
import facebookMessengerWidgetSmartProductListConfig from '../templates/facebookmessenger/widgets/widget-smart-product-list-fields-config';

const smartProductListConfig = {
  [TemplateParentTypeEnum.POPUP]: popupWidgetSmartProductListConfig,
  [TemplateParentTypeEnum.EMBED]: embedWidgetSmartProductListConfig,
  [TemplateParentTypeEnum.EMAIL]: emailWidgetSmartProductListConfig,
  [TemplateParentTypeEnum.FACEBOOKMESSENGER]: facebookMessengerWidgetSmartProductListConfig,
};

const getWidgetSmartProductListConfig = (templateType: string): WidgetTypeConfig => {
  if (
    templateType === TemplateParentTypeEnum.POPUP
    || templateType === TemplateParentTypeEnum.EMBED
    || templateType === TemplateParentTypeEnum.EMAIL
    || templateType === TemplateParentTypeEnum.FACEBOOKMESSENGER
  ) {
    return smartProductListConfig[templateType];
  }
  return {};
};

export default getWidgetSmartProductListConfig;
