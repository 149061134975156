
import {
  defineComponent,
  PropType,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import BaseButtonGroup from '@/components/fields/BaseButtonGroup.vue';
import { StringMap } from '@/types';
import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'ButtonGroup',

  components: {
    BaseButtonGroup,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: [Array, String, Number, Boolean] as PropType<string[] | string | number| boolean>,
      required: true,
    },

    options: {
      type: Object as PropType<StringMap>,
      required: true,
      default() {
        return {};
      },
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    tooltipDesc: {
      type: String,
      required: false,
      default: '',
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: boolean|number|string|string[]}, { emit }: SetupContext) {
    const { t } = useI18n();

    const option = ref(props.modelValue);

    watch([option], () => emit('update:modelValue', option.value));

    return {
      t,
      option,
    };
  },
});
