
import {
  computed, ComputedRef, defineComponent, onMounted, PropType, ref, Ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import { List, ListSettings } from '@/composables/GraphQL';
import { SpmTableFilter, StringMap } from '@/types';
import { showToastError } from '@/helpers';
import { OperatorType } from '@/types/generated-types/graphql';
import VirtualScroller from 'primevue/virtualscroller';
import Skeleton from 'primevue/skeleton';

export default defineComponent({
  name: 'ChooseItemsModal',

  components: {
    Dialog,
    InputText,
    Button,
    VirtualScroller,
    Skeleton,
  },

  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },

    header: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    fields: {
      type: Object as PropType<StringMap>,
      required: true,
    },

    items: {
      type: String,
      required: false,
      default: '',
    },

    defaultFilters: {
      type: Array as PropType<SpmTableFilter[]>,
    },
  },

  emits: {
    'on-close-dialog': null,
    'on-choose-items': Object,
  },

  setup(props, context) {
    const { t } = useI18n();
    const virtualScrollersKey = ref(0);
    const displayModal: ComputedRef<boolean> = computed(() => props.displayDialog);
    const searchValue: Ref<string> = ref('');
    const settings: Ref<ListSettings> = ref({
      limit: 100,
      offset: 0,
      order: [],
      filter: [],
    });

    const availableList: Ref<StringMap[]> = ref([]);
    const selectedList: Ref<StringMap[]> = ref([]);

    const fetchData = async (): Promise<StringMap[]> => {
      const res = await List(
        {
          name: props.name,
          settings: settings.value,
          fields: [...Object.values(props.fields)],
        },
      );
      if (res.err) {
        showToastError('GENERIC_ERROR');
      } else {
        return res.items as StringMap[];
      }
      return [];
    };

    const handleValueChange = async () => {
      if (searchValue.value.length > 0) {
        settings.value.filter = props.defaultFilters ? [...props.defaultFilters] : []; // Some itemLists have default filters (lang, active, ...)
        settings.value.filter.push({ field: props.fields.label, value: searchValue.value, operator: OperatorType.Contains });
        availableList.value = await fetchData();
      } else {
        availableList.value = [];
      }
    };

    const handleClose = () => {
      context.emit('on-close-dialog');
    };

    const selectItem = (element: StringMap) => {
      selectedList.value.push(element);
      // remove from available
      availableList.value = availableList.value.filter((el: StringMap) => el[props.fields.id] !== element[props.fields.id]);
      virtualScrollersKey.value += 1;
    };

    const removeItem = (element: StringMap) => {
      availableList.value.push(element);
      // remove from selected
      selectedList.value = selectedList.value.filter((el: StringMap) => el[props.fields.id] !== element[props.fields.id]);
      virtualScrollersKey.value += 1;
    };

    const handleChooseItems = () => {
      context.emit('on-choose-items', selectedList.value);
      handleClose();
    };

    onMounted(async () => {
      if (props.items && props.items !== '') { // If default items, put them in selected list on mount
        settings.value.filter = [
          { field: props.fields.id, value: props.items, operator: OperatorType.In },
        ];
        selectedList.value = await fetchData();
      }
    });

    return {
      t,
      searchValue,
      availableList,
      selectedList,
      displayModal,
      virtualScrollersKey,
      selectItem,
      removeItem,
      handleClose,
      handleValueChange,
      handleChooseItems,
    };
  },
});
