import { i18n } from '@/i18n';
import { ErrorConfigForm, FilterField, FilterSegment } from '@/types/automated-scenarios';
import { DeclencheurVisitPageFilters } from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurVisitPageMetadata';

import {
  OperatorType, ShopsProducts, ShopsProductsCategoriesList, ShopsManufacturers,
} from '@/types/generated-types/graphql';
import { TypeCampaignEnum } from '@/types';
import { computed } from 'vue';
import {
  or, required, requiredIf, url,
} from '@vuelidate/validators';
import { itemsPickerValidator } from '@/helpers/CustomValidator';
import useVuelidate from '@vuelidate/core';

let translation: any;
(async () => {
  translation = await i18n;
})();

export default () => ([
  {
    id: 'products',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'products', label: translation.global.t('automatedScenarios.fields.segments.products') },
    fields: [
      {
        id: 'all',
        component: 'ButtonGroup',
        data: 1,
        props: {
          title: 'automatedScenarios.fields.segments.triggerAfterVisiting',
          options: [
            { value: 1, label: translation.global.t('automatedScenarios.fields.segments.anyProduct') },
            { value: 0, label: translation.global.t('automatedScenarios.fields.segments.selectedProducts') },
          ],
        },
      },
      {
        id: 'product_picker_product',
        component: 'ItemList',
        data: {
          selection: {
            selected: [],
            operator: 'or',
          },
        },
        props: {
          config: {
            name: 'ShopsProducts',
            mainLabel: 'automatedScenarios.fields.productList',
            buttonLabel: 'automatedScenarios.fields.chooseProducts',
            fields: { id: 'id_shop_product', label: 'name', productId: 'id_product' },
            defaultFilters: [
              { field: 'lang', value: 'fr', operator: OperatorType.Equals },
              { field: 'active', value: true, operator: OperatorType.Equals },
            ],
            format: (product: ShopsProducts) => ({
              id: product.id_product,
              spmId: product.id_shop_product,
              name: product.name,
              combinations: {},
            }),
            showOperator: false,
            operatorLabel: '',
          },
        },
        litteral_title: {
          all: '',
          product_picker_product: 'product_picker_product',
        },
        show: (fields: FilterField[]) => (fields[0].data === 0),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        all: {
          required,
        },
        product_picker_product: {
          shouldBeChecked: or(() => data.all === 1, itemsPickerValidator()),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'categories',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'categories', label: translation.global.t('automatedScenarios.fields.segments.categories') },
    fields: [
      {
        id: 'all',
        component: 'ButtonGroup',
        data: 1,
        props: {
          title: 'automatedScenarios.fields.segments.triggerAfterVisiting',
          options: [
            { value: 1, label: translation.global.t('automatedScenarios.fields.segments.anyCategory') },
            { value: 0, label: translation.global.t('automatedScenarios.fields.segments.selectedCategories') },
          ],
        },
      },
      {
        id: 'product_picker_category',
        component: 'ItemList',
        data: {
          selection: {
            selected: [],
            operator: 'or',
          },
        },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.categoryList',
            buttonLabel: 'automatedScenarios.fields.chooseCategories',
            name: 'ShopsProductsCategoriesList',
            fields: { id: 'id_shop_product_category_list', label: 'name', categoryId: 'id_category' },
            defaultFilters: [{ field: 'lang', value: 'fr', operator: OperatorType.Equals }],
            format: (category: ShopsProductsCategoriesList) => ({
              id: category.id_category,
              spmId: category.id_shop_product_category_list,
              name: category.name,
              combinations: {},
            }),
            showOperator: false,
            operatorLabel: '',
          },
        },
        show: (fields: FilterField[]) => (fields[0].data === 0),
      },
    ],
  },
  {
    id: 'manufacturers',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'manufacturers', label: translation.global.t('automatedScenarios.fields.segments.manufacturers') },
    fields: [
      {
        id: 'all',
        component: 'ButtonGroup',
        data: 1,
        props: {
          title: 'automatedScenarios.fields.segments.triggerAfterVisiting',
          options: [
            { value: 1, label: translation.global.t('automatedScenarios.fields.segments.anyManufacturer') },
            { value: 0, label: translation.global.t('automatedScenarios.fields.segments.selectedManufacturers') },
          ],
        },
      },
      {
        id: 'product_picker_manufacturer',
        component: 'ItemList',
        data: {
          selection: {
            selected: [],
            operator: 'or',
          },
        },
        props: {
          config: {
            mainLabel: 'automatedScenarios.fields.manufacturerList',
            buttonLabel: 'automatedScenarios.fields.chooseManufacturers',
            name: 'ShopsManufacturers',
            fields: { id: 'id_shop_manufacturer', label: 'name', manufacturerId: 'id_manufacturer' },
            defaultFilters: [],
            format: (manufacturer: ShopsManufacturers) => ({
              id: manufacturer.id_manufacturer,
              spmId: manufacturer.id_shop_manufacturer,
              name: manufacturer.name,
              combinations: {},
            }),
            showOperator: false,
            operatorLabel: '',
          },
        },
        show: (fields: FilterField[]) => (fields[0].data === 0),
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        all: {
          required,
        },
        product_picker_manufacturer: {
          shouldBeChecked: or(() => data.all === 1, itemsPickerValidator()),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
  {
    id: 'urls',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
    option: { value: 'urls', label: translation.global.t('automatedScenarios.fields.segments.urls') },
    fields: [
      {
        id: 'included',
        component: 'InputList',
        data: [],
        props: {
          label: 'automatedScenarios.fields.segments.includedUrl',
          validate: async (data: any): Promise<ErrorConfigForm> => {
            const rules = computed(() => ({
              value: { required, url },
            }));

            const v$ = useVuelidate(rules, data);
            const success = await v$.value.$validate();
            return {
              success,
              validate: v$,
            };
          },
          callback: (data: any) => data.replace(new RegExp('^http(s):'), ''),
        },
      },
      {
        id: 'excluded',
        component: 'InputList',
        data: [],
        props: {
          label: 'automatedScenarios.fields.segments.excludedUrl',
          validate: async (data: any): Promise<ErrorConfigForm> => {
            const rules = computed(() => ({
              value: { required, url },
            }));

            const v$ = useVuelidate(rules, data);
            const success = await v$.value.$validate();
            return {
              success,
              validate: v$,
            };
          },
          callback: (data: any) => data.replace(new RegExp('^http(s):'), ''),
        },
      },
    ],
    validate: async (data: any): Promise<ErrorConfigForm> => {
      const rules = computed(() => ({
        included: {
          required: requiredIf(() => !data.excluded.length),
        },
      }));

      const v$ = useVuelidate(rules, data);
      const success = await v$.value.$validate();
      return {
        success,
        validate: v$,
      };
    },
  },
]) as FilterSegment<DeclencheurVisitPageFilters>[];
