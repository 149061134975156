import { i18n } from '@/i18n';
import { ComponentData, ComponentMetadata, ErrorConfigForm } from '@/types/automated-scenarios';
// eslint-disable-next-line import/no-cycle
import {
  AutomatedScenarioState as state,
  getOperatorChildByOutput,
  isRootOfTree,
} from '@/composables/AutomatedScenarios';
import { computed } from 'vue';
import { between, integer, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { TypeCampaignEnum } from '@/types';

export interface DeclencheurScrollPageData {
  percent_scroll: number;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const DeclencheurScrollPageMetadata: ComponentMetadata<DeclencheurScrollPageData> = {
  Meta: {
    kind: 'declencheur',
    id: 'boxscrollpage',
    label: 'automatedScenarios.triggers.scrollPage.shortLabel',
    icon: 'fa-arrows-v',
    hasConfiguration: true,
    incompatibilities: {
      automation: {
        next_operators: [
          'boxabandonpanier', 'boxquitsite', 'boxperiodiclaunching',
          'boxscrollpage', 'boxstatuscommand', 'registeronsite',
          'subscribetonewsletter', 'boxtag', 'boxlist',
          'boxsendemail', 'boxsendsms', 'boxsendfbmessenger',
          'boxsendpushnotification', 'openemail', 'clicklink',
          'boxvisitpage',
        ],
      },
    },
    component: 'DeclencheurScrollPage',
    availableInCampaign: [TypeCampaignEnum.AUTOMATION],
  },
  Create({ percent_scroll }: DeclencheurScrollPageData = {
    percent_scroll: 10,
  }): ComponentData<DeclencheurScrollPageData> {
    return {
      id: 'boxscrollpage',
      form_name: 'boxscrollpage',
      logo_box: 'fa-arrows-v',
      percent_scroll,
    };
  },
  async Validate(data: DeclencheurScrollPageData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      percent_scroll: {
        required,
        integer,
        between: between(10, 100),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
  Outputs(data: DeclencheurScrollPageData): number {
    return 1;
  },
  CanBeDeleted(operatorId: string): boolean|string {
    const isRoot = isRootOfTree(operatorId);

    if (isRoot) {
      const childOperator = getOperatorChildByOutput(operatorId, 'output_1');
      if (childOperator !== null && typeof (state.scenario.data.operators[childOperator]) !== undefined
        && state.scenario.data.operators[childOperator].properties.class !== 'declencheur') {
        return 'automatedScenarios.cant_remove_operator';
      }
    }

    return true;
  },
  Label(data: DeclencheurScrollPageData): string {
    return translation.global.t('automatedScenarios.triggers.scrollPage.fullLabel', { percent: `${data.percent_scroll}%` });
  },
};

export default DeclencheurScrollPageMetadata;
