<template>
  <FilterGlobalInformations
    v-model="commonFields"
    v-model:form-validation="formValidationErrors"
  />

  <div
    class="field-group pt-2"
  >
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <ButtonGroup
            v-model="grantAdditionalDelay"
            :options="options"
            title="automatedScenarios.fields.grantAdditionalDelay"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="grant_additional_delay"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="grantAdditionalDelay === '1'"
    class="field-group pt-2"
  >
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <PeriodSelector
            v-model="delay"
            :units="units"
            title="automatedScenarios.fields.duration"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="extra_time"
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="field-group pt-2"
  >
    <div>
      <div class="field-group-content">
        <div class="field-wrapper">
          <ButtonGroup
            v-model="type"
            :options="types"
            title="automatedScenarios.fields.customerAction"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="formValidationErrors"
            field="type"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="field-wrapper">
    <FilterLinkedOperators />
  </div>
  <div
    v-for="op in linkableOperators"
    :key="op.operatorId"
  >
    <teleport :to="op.targetDiv">
      <OperatorSelectForm
        :operator-id="op.operatorId"
      />
    </teleport>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  Ref,
  ref,
  SetupContext,
  watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import FiltreOpenEmailMetaData, {
  FiltreOpenEmailData,
} from '@/components/automated-scenarios/metadata/filtres/FiltreOpenEmailMetadata';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import PeriodSelector from '@/components/automated-scenarios/fields/PeriodSelector.vue';
import { Period } from '@/types/automated-scenarios';
import OperatorSelectForm from '@/components/automated-scenarios/OperatorSelectForm.vue';
import {
  AutomatedScenarioState as state,
  getAllParentsOfBoxToRoot,
  linkOperator,
  OperatorBox,
  resetlinkedOperators,
  setDisableOperator, setSelectableOperator,
} from '@/composables/AutomatedScenarios';
import FilterLinkedOperators from '@/components/automated-scenarios/FilterLinkedOperators.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import FilterGlobalInformations from '../../fields/FilterGlobalInformations.vue';

export default defineComponent({
  name: 'FiltreOpenEmail',
  components: {
    FieldErrors,
    FilterLinkedOperators,
    FilterGlobalInformations,
    ButtonGroup,
    PeriodSelector,
    OperatorSelectForm,
  },

  props: {
    modelValue: {
      type: Object as PropType<FiltreOpenEmailData>,
      required: true,
      default() {
        return FiltreOpenEmailMetaData.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: FiltreOpenEmailData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const linkableTypeBoxes: string[] = ['boxsendemail', 'boxsendfbmessenger'];
    const commonFields = ref({
      custom_title: props.modelValue.custom_title,
      custom_description: props.modelValue.custom_description,
      outputs_number: props.modelValue.outputs_number,
    });
    const grantAdditionalDelay = ref(props.modelValue.grant_additional_delay);
    const type = ref(props.modelValue.type);
    const delay = ref<Period>({
      unit: props.modelValue.extra_time ? props.modelValue.extra_time.unit : 'DAY',
      value: props.modelValue.extra_time ? props.modelValue.extra_time.value : 1,
    });
    const linkableOperators: Ref<OperatorBox[]> = ref([]);

    const options = [
      { value: '0', label: t('no') },
      { value: '1', label: t('yes') },
    ];
    const types = [
      { value: 'open', label: t('automatedScenarios.fields.actions.opened') },
      { value: 'noopen', label: t('automatedScenarios.fields.actions.notOpened') },
    ];

    const units = [
      { value: 'SECOND', label: t('automatedScenarios.fields.durations.second(s)') },
      { value: 'MINUTE', label: t('automatedScenarios.fields.durations.minute(s)') },
      { value: 'HOUR', label: t('automatedScenarios.fields.durations.hour(s)') },
      { value: 'DAY', label: t('automatedScenarios.fields.durations.day(s)') },
    ];

    const currentOperatorId = state.selectedOperator.operatorId;
    const parentsOfThatBox = getAllParentsOfBoxToRoot(currentOperatorId);
    parentsOfThatBox.push(currentOperatorId);
    const getLinkableOperators = () => state.operators
      .filter((operator: OperatorBox) => linkableTypeBoxes.includes(operator.id) && parentsOfThatBox.includes(operator.operatorId));

    onMounted(() => {
      if (props.modelValue.linked_box) {
        try {
          JSON.parse(props.modelValue.linked_box).forEach((operatorId: string) => {
            linkOperator(operatorId);
          });
        } catch (e) {
          // not a json string
          linkOperator(props.modelValue.linked_box);
        }
      }
      linkableOperators.value = getLinkableOperators();

      // Disable not linkable operators
      state.operators
        // If we are in edit mode do not disable active box
        .filter((operator: OperatorBox) => !(state.leftToolbar.show.operatorEditForm && currentOperatorId === operator.operatorId))
        .filter((operator: OperatorBox) => !linkableOperators.value.includes(operator))
        .forEach((operator: OperatorBox) => {
          setDisableOperator(operator.operatorId, true);
        });

      linkableOperators.value.forEach((operator: OperatorBox) => {
        setSelectableOperator(operator.operatorId, true);
      });
    });

    onUnmounted(() => {
      resetlinkedOperators();
      // Reset disabled operators
      state.operators
        .forEach((operator: OperatorBox) => {
          setDisableOperator(operator.operatorId, false);
        });

      linkableOperators.value.forEach((operator: OperatorBox) => {
        setSelectableOperator(operator.operatorId, false);
      });
    });

    const update = () => context.emit('update:modelValue', FiltreOpenEmailMetaData.Create({
      ...commonFields.value,
      grant_additional_delay: grantAdditionalDelay.value,
      extra_time: delay.value,
      type: type.value,
      linked_box: JSON.stringify([...state.linkedOperators]),
    }));

    watch([commonFields, grantAdditionalDelay, delay, type, state.linkedOperators], update, { deep: true });

    return {
      formValidationErrors,
      componentFieldErrorsKey,
      commonFields,
      grantAdditionalDelay,
      options,
      type,
      types,
      delay,
      units,
      linkableOperators,
    };
  },
});
</script>
