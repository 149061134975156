<template>
  <Loader
    v-if="isLoading"
    style="z-index:1102"
  />
  <div class="grid">
    <div class="col-12 lg:col-6 flex flex-column">
      <div class="flex flex-column">
        <div class="field">
          <label
            for="shopRequiredDataSms[senderName]"
          >
            {{ t('storeParameters.services.senderName') }}
          </label>
          <LocalizedTextInput
            id="shopRequiredDataSms[senderName]"
            v-model="field.shopRequiredDataSms_senderName"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="error"
            field="shopRequiredDataSms_senderName"
          />
        </div>
        <SpmButton
          v-if="!globalSave"
          :label="t('save')"
          :loading="saveLoading"
          class="p-button p-button-primary"
          @click="handleSave"
        />
      </div>
    </div>
    <div
      ref="smsServiceParameterPreviewRef"
      class="smsserviceparameter-preview col-12 lg:col-6 flex flex-column gap-2 border-1 border-gray-200 relative"
    >
      <div
        ref="senderRef"
        class="flex flex-column align-items-center smsservice-preview__sender absolute"
        :style="{
          top: senderPosition.top + 'px' || '0px',
          left: senderPosition.left + 'px' || '0px',
        }"
      >
        <div
          v-if="senderName"
          class="flex align-items-center justify-content-center w-2rem h-2rem border-circle bg-gray-500"
        >
          <i
            class="text-md fas fa-user text-white"
          />
        </div>
        <span class="text-sm">{{ senderName }}</span>
      </div>
    </div>
  </div>
  <div
    v-if="globalSave"
    class="flex align-content-center justify-content-center align-items-center"
  >
    <slot name="save" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  computed,
  onMounted,
  onUnmounted,
  nextTick,
  watch,
} from 'vue';

import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Loader from '@/components/layout/Loader.vue';

import useVuelidate from '@vuelidate/core';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { showToastError, showToastSuccess } from '@/helpers';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import { ShopsConfigurationInput, ShopsConfigurationInputItem } from '@/types/generated-types/graphql';
import { saveShopParamsOnRedis, SaveShopsConfiguration } from '@/composables/shop/ShopsConfiguration';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'SmsServiceParameter',

  components: {
    LocalizedTextInput,
    FieldErrors,
    SpmButton,
    Loader,
  },

  props: {
    retrieveData: {
      type: Function,
      required: true,
    },

    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t, locale } = useI18n();

    const isLoading = ref(false);
    const saveLoading = ref(false);

    const field = reactive<Record<string, any>>({
      shopRequiredDataSms_senderName: {},
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();

    const smsServiceParameterPreviewRef = ref();
    const senderRef = ref();
    const senderPosition = reactive({
      top: 0,
      left: 0,
    });

    const senderName = computed(() => {
      if (typeof field.shopRequiredDataSms_senderName !== 'string') {
        if (locale.value in field.shopRequiredDataSms_senderName) {
          return field.shopRequiredDataSms_senderName[locale.value];
        }
        return field.shopRequiredDataSms_senderName.fr;
      }
      return field.shopRequiredDataSms_senderName;
    });

    const calculatePosition = () => {
      if (smsServiceParameterPreviewRef.value && senderRef.value) {
        const containerRect = smsServiceParameterPreviewRef.value.getBoundingClientRect();
        const senderRect = senderRef.value.getBoundingClientRect();

        const left = (containerRect.width * 50) / 100 - (senderRect.width / 2);
        const top = (containerRect.height * 68) / 100 - (senderRect.height / 2);

        // Ensure that the notification div does not go beyond the container's edges
        senderPosition.left = Math.max(0, Math.min(left, containerRect.width - senderRect.width));
        senderPosition.top = Math.max(0, Math.min(top, containerRect.height - senderRect.height));
      }
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules: Record<string, any> = {
        shopRequiredDataSms_senderName: {
          localizedTextInputValidator: localizedTextInputValidator('text'),
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();
      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      const smsInfoState = {
        shopRequiredDataSms_activeConfig: '1',
        ...field,
      };
      const shopsConfigArray: ShopsConfigurationInputItem[] = [];
      let key = '';
      let value = '';
      Object.entries(smsInfoState).forEach((keyValuePair: any) => {
        [key, value] = [...keyValuePair];
        const shopsConfigRecord: ShopsConfigurationInputItem = {
          key: '',
          value: '',
          lang: '',
        };

        if (typeof value === 'object' && !Array.isArray(value)) {
          let language = '';
          let inputValue = '';
          Object.entries(value).forEach((entry: any) => {
            [language, inputValue] = [...entry];

            const multiRecord: ShopsConfigurationInputItem = {
              key,
              value: inputValue,
              lang: language,
            };
            shopsConfigArray.push(multiRecord);
          });
        } else {
          shopsConfigRecord.key = key;
          shopsConfigRecord.value = value;
          shopsConfigRecord.lang = '';

          shopsConfigArray.push(shopsConfigRecord);
        }
      });

      if (props.globalSave) {
        return {
          err: null,
          configs: shopsConfigArray,
        };
      }

      const input: ShopsConfigurationInput = {
        id_shop: props.shopId,
        configs: [],
      };
      input.id_shop = props.shopId;
      input.configs = shopsConfigArray;

      saveLoading.value = true;

      try {
        await SaveShopsConfiguration(input);
        await saveShopParamsOnRedis(props.shopId);
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        await props.retrieveData(field);
        window.addEventListener('resize', calculatePosition);
        await nextTick();
        calculatePosition();
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', calculatePosition);
    });

    watch(
      () => [
        field.shopRequiredDataSms_senderName,
      ],
      calculatePosition,
      {
        deep: true,
      },
    );

    return {
      t,
      isLoading,
      saveLoading,
      field,
      componentFieldErrorsKey,
      error,
      smsServiceParameterPreviewRef,
      senderName,
      senderRef,
      senderPosition,
      handleSave,
    };
  },
});
</script>

<style lang="scss" scoped>
.smsserviceparameter-preview {
  background-image: url("/images/template-builder/phone_bg_top.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 137px;
}
</style>
