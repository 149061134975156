<template>
  <Message
    v-if="value.error"
    :closable="false"
    severity="error"
  >
    {{ value.error }}
  </Message>
  <ConfirmDialog
    :group="confirmDialogGroup"
    :breakpoints="{ '640px': '75vw', '380px': '100vw' }"
    :style="{ width: '40vw' }"
  />
  <ActionModal
    v-if="showCustomDialog && (rows || idShopList || dialogData.idCustomer)"
    :message="dialogData.message"
    :header="dialogData.header"
    :icon="dialogData.icon"
    :accept-label="dialogData.acceptLabel"
    :reject-label="dialogData.rejectLabel"
    :action-type="dialogData.actionType"
    :action-selection="groupSelection"
    :rows="rows"
    :id-shop="idShop"
    :show-custom-dialog="showCustomDialog"
    :settings="JSON.stringify(settings)"
    :unselected-rows="unselectedRows"
    :id-shop-list="idShopList"
    :id-customer="dialogData.idCustomer"
    :list-name="dialogData.listName"
    @close-action-modal="showCustomDialog=false"
    @reload-data="reloadData"
  />

  <DataTable
    :key="spmTableKey"
    v-model:filters="filterParams"
    v-model:selection="selectedRows"
    v-model:expandedRows="expandedRows"
    :current-page-report-template="`${t('results')} {first} ${t('to')} {last} ${t('of')} {totalRecords}`"
    :data-key="index"
    :lazy="lazyLoading"
    :loading="value.isLoading || props.isGlobalLoading"
    :rows="limit"
    :rows-per-page-options="[10, 25, 50, 100]"
    :total-records="value.total"
    :value="value.items"
    class="editable-cells-table spm-data-table"
    edit-mode="cell"
    :filter-display="showFiltersRow ? 'menu' : 'row'"
    loading-icon=""
    :paginator="props.paginator"
    :paginator-template="paginatorTemplate"
    responsive-layout="scroll"
    row-hover
    :reorderable-columns="reorderableRows"
    @row-reorder="onRowReorder"
    @page="onPageChange"
    @sort="onSortChange"
    @cell-edit-complete="onCellEditingComplete"
    @cell-edit-init="onCellEditInit"
    @row-select="rowClicked"
    @value-change="rowClicked"
    @filter="onFilterChange"
  >
    <template
      v-if="summary !== '' || reloadDataTable"
      #header
    >
      <div
        v-if="summary !== ''"
        class="summary"
      >
        <slot name="summary">
          <span>{{ summary }}</span>
        </slot>
      </div>
      <slot name="header">
        <div
          class="flex justify-content-between flex-wrap mb-2"
        >
          <div class="flex align-items-center justify-content-center">
            <div v-if="showExportMenuItem">
              <SecureSpmButton
                v-if="!freeTableExport"
                :label="t('exportAll')"
                icon="fa-regular fa-file-export"
                class-style="p-button p-button-secondary"
                :on-authorized-click="exportConfirmDialog"
                required-feature="data_csv.export"
              />
              <Button
                v-else
                :label="t('exportAll')"
                icon="fa-regular fa-file-export"
                class="p-button p-button-secondary"
                @click="exportConfirmDialog"
              />
            </div>
            <div
              v-if="reloadDataTable && name && name !== ''"
            >
              <Button
                :label="t('reloadData')"
                icon="fa-solid fa-arrows-rotate mr-1"
                class="p-button p-button-secondary"
                :class="customSelector ? 'ml-3': ''"
                type="button"
                @click="reloadData"
              />
            </div>
          </div>
        </div>
      </slot>
    </template>
    <template #empty>
      <slot name="empty">
        <p class="text-center">
          {{ t('noRecords') }}
        </p>
      </slot>
    </template>
    <template #loading>
      <Loader />
    </template>
    <Column
      v-if="reorderableRows"
      :row-reorder="true"
      header-style="width: 3rem"
      :reorderable-column="false"
    />
    <Column
      v-if="selectionMode === 'single' || selectionMode === 'multiple'"
      :selection-mode="selectionMode"
      header-style="width:3rem"
    />
    <Column
      v-if="customSelector && !selectionMode"
      header-style="width:3rem"
    >
      <template #header>
        <div
          class="flex justify-content-between flex-wrap mb-2"
        >
          <div
            class="flex align-items-center justify-content-center mb-2"
          >
            <Dropdown
              v-model="groupSelection"
              :options="selection"
              option-value="code"
              option-label="label"
              :placeholder="t('bulkActions.selection.placeholder')"
              @change="selectAllRows"
            />
          </div>

          <div v-if="props.groupedActionsOptions.length>0">
            <Button
              type="button"
              aria-haspopup="true"
              aria-controls="overlay_menu"
              style="padding:0"
              @click="toggle"
            >
              <div
                class="p-dropdown p-component p-inputwrapper"
              >
                <span class="p-dropdown-label p-inputtext p-placeholder">
                  {{ t('bulkActions.placeholder') }}
                </span>
                <div
                  class="p-dropdown-trigger"
                  role="button"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                >
                  <span class="p-dropdown-trigger-icon far fa-chevron-down" />
                </div>
              </div>
            </Button>

            <Menu
              id="overlay_menu"
              ref="menu"
              :model="groupedActions"
              popup
            />
          </div>
        </div>
      </template>
      <template #body="slot">
        <Checkbox
          :id="slot.data[props.groupedActionsKey]"
          :key="slot.data[props.groupedActionsKey]"
          v-model="rows"
          :input-id="(props.groupedActionsKey && slot.data[props.groupedActionsKey]) ? slot.data[props.groupedActionsKey].toString(): ''"
          name="choice"
          :value="slot.data[props.groupedActionsKey]"
          @change="rowClicked"
        />
      </template>
    </Column>
    <Column
      v-if="expandRows"
      :expander="expandRows"
      header-style="width:3rem"
    />
    <Column
      v-for="col of columns"
      :key="col[index]"
      :ref="col.field"
      :field="col.field"
      :filter-field="col.prefix? `${col.prefix}.${col.field}`: col.field"
      :header-class="`p-column-header-${col.type} ${getFilterStatus(col.field)}`"
      :show-filter-match-modes="(!col.filterSettings || !col.filterSettings.hideFilterMenu) && col?.type !== 'datetime'"
      :sortable="col.sortable"
      :style="col.style"
      :sort-field="col.prefix? `${col.prefix}.${col.field}`: col.field"
      :filter-match-mode-options="col.matchModes ?? null"
    >
      <template
        v-if="col.filterable"
        #filter="{ filterModel, filterCallback }"
      >
        <Filter
          :col="col"
          :filter-callback="filterCallback"
          :filter-model="filterModel"
          :custom-options-items="col.filterSettings?.customOptionsItems"
          :update-value="(value) => { filterModel.value = value; }"
        />
      </template>
      <template #filterclear="{ filterModel, filterCallback }">
        <SpmButton
          :label="t('clear')"
          class-style="p-button p-button-secondary"
          @click="clearFilter(filterModel, filterCallback, col.field)"
        />
      </template>
      <template #filterapply="{ filterModel, filterCallback }">
        <SpmButton
          :label="t('apply')"
          class-style="p-button p-button-success"
          @click="applyFilter(filterModel, filterCallback, col.field)"
        />
      </template>
      <template #header>
        <div
          class="flex-1"
          style="text-align: left"
        >
          <div>
            {{ col.header }}

            <i
              v-tooltip="col.tooltip"
              :class="col.tooltipIcon"
              style="float: right"
            />
          </div>
        </div>
      </template>

      <template #body="slotProps">
        <slot
          :column="slotProps.column"
          :data="slotProps.data"
          :name="col.field"
        >
          <Renderer
            :key="col.field + '-' + index"
            :col="col"
            :index="slotProps.data[index] ? slotProps.data[index].toString() : ''"
            :slot-props="slotProps"
          />
        </slot>
      </template>

      <template
        v-if="col.editable"
        #editor="slotProps"
      >
        <InputText
          v-model="slotProps.data[slotProps.column.props.field]"
          class="p-column-edit"
        />
      </template>
    </Column>
    <template
      v-if="expandRows"
      #expansion="slotProps"
    >
      <slot
        name="expandedDetails"
        :data="slotProps.data"
      >
        <pre>
          &#x3C;template #expandedDetails=&#x22;slotProps&#x22;&#x3E;
            Replace me with your expanded details...
          &#x3C;/template&#x3E;
        </pre>
      </slot>
    </template>
  </DataTable>

  <div style="padding-bottom: 10px" />
</template>

<script lang="ts">
import {
  computed, defineComponent, onMounted, PropType, reactive, Ref, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { usePrimeVue } from 'primevue/config';
import DataTable, { DataTableFilterMeta } from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import { DataTableEvent, SpmTableFilter } from '@/types';
import { SpmTableColumns, SpmTableSort, SpmTableState } from '@/types/table-types';
import { FilterOperatorsEnum, SortOrderEnum } from '@/types/enums';
import { getEnumKeyByStringValue, showToastError, showToastSuccess } from '@/helpers';
import { List, ListSettings, SaveResult } from '@/composables/GraphQL';
import Loader from '@/components/layout/Loader.vue';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import { useConfirm } from 'primevue/useconfirm';
import {
  ActionType,
  DatatablesExportsInputItem,
  ResolverType,
  OperatorType,
} from '@/types/generated-types/graphql';
import InsertDatatablesExport from '@/composables/datatables-export/HandleCron';
import ActionModal from '@/components/table/ActionModal.vue';
import ConfirmDialog from 'primevue/confirmdialog';
import Tooltip from 'primevue/tooltip';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import { hasAccessToFeatures, UserState } from '@/composables/User';
import { useStore } from '@/store';
import { MenuItem } from 'primevue/menuitem';
import SecureSpmButton from '@/components/spm-primevue/SecureSpmButton.vue';
import Filter from './Filter.vue';
import Renderer from './Renderer.vue';

const updateValue = 'update:modelValue';

export default defineComponent({
  name: 'SpmTable',
  components: {
    SecureSpmButton,
    ActionModal,
    Loader,
    DataTable,
    Column,
    InputText,
    Message,
    Filter,
    Renderer,
    Checkbox,
    Dropdown,
    Button,
    Menu,
    ConfirmDialog,
    SpmButton,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    modelValue: {
      type: Object as PropType<SpmTableState>,
      required: false,
      default: (): SpmTableState => ({
        items: [],
        total: 0,
        error: '',
        isLoading: true,
        selectedItems: [],
      }),
    },

    name: {
      type: String,
      required: true,
    },

    index: {
      type: String,
      required: true,
    },

    isGlobalLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    summary: {
      type: String,
      default: '',
      required: false,
    },

    tableColumns: {
      type: Array as PropType<Array<SpmTableColumns>>,
      required: true,
    },

    limit: {
      type: Number,
      required: false,
      default: 10,
    },

    persistentFilters: {
      type: Array as PropType<Array<SpmTableFilter>>,
      required: false,
      default: () => [],
    },

    initialSorting: {
      type: Array as PropType<Array<SpmTableSort>>,
      required: false,
      default: () => [],
    },

    selectionMode: {
      type: String,
      required: false,
      default: '',
    },

    expandRows: {
      type: Boolean,
      required: false,
      default: false,
    },

    paginator: {
      type: Boolean,
      required: false,
      default: true,
    },

    customSelector: {
      type: Boolean,
      required: false,
      default: false,
    },

    idShop: {
      type: Number,
      required: false,
      default: 0,
    },

    groupedActionsOptions: {
      type: Array as PropType<MenuItem[]>,
      required: false,
      default: () => [],
    },

    groupedActionsKey: {
      type: String,
      required: false,
      default: '',
    },

    showExportMenuItem: {
      type: Boolean,
      required: false,
      default: false,
    },

    reorderableRows: {
      type: Boolean,
      required: false,
      default: false,
    },

    scrollable: {
      type: Boolean,
      required: false,
      default: false,
    },

    dynamicConfiguration: {
      type: Boolean,
      required: false,
      default: false,
    },

    reloadDataTable: {
      type: Boolean,
      required: false,
      default: true,
    },

    excludeName: {
      type: String,
      required: false,
      default: '',
    },

    excludeField: {
      type: String,
      required: false,
      default: '',
    },

    excludeFilters: {
      type: Array as PropType<Array<SpmTableFilter>>,
      required: false,
      default: () => [],
    },

    exportFileName: {
      type: String,
      required: false,
      default: '',
    },

    customSettings: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },
  },

  emits: [
    updateValue,
    'cell-edit-init',
    'cell-edit-complete',
    'row-selection-complete',
    'row-reorder',
    'reload-data',
    'filters-change',
  ],

  setup(props, context) {
    const {
      t,
      locale,
    } = useI18n();
    const store = useStore();
    const uniqueIdS6Generator = () => Math.random().toString(36).substr(2, 6);
    const confirmDialogGroup = ref(`group_${uniqueIdS6Generator()}`);

    const freeTableExport: any = ref(['ShopsCustomersManage', 'ShopsPushToken'].includes(props.name));

    const menu = ref();
    const confirm = useConfirm();
    const showCustomDialog = ref(false);
    const unselectedRows: Ref<number[]> = ref([]);
    const clearEvent = ref(false);
    const mounting = ref(true);
    const dialogData: Ref<{
      message: string;
      header: string;
      icon: string;
      acceptLabel: string;
      rejectLabel: string;
      actionType: string;
      idCustomer: number| null;
      idUser: number|null;
      listName?: string;
    }> = ref({
      message: '',
      header: '',
      icon: '',
      acceptLabel: '',
      rejectLabel: '',
      actionType: '',
      idCustomer: null,
      idUser: null,
      listName: undefined,
    });
    const spmTableKey = ref(0);

    const groupSelection = ref<string|null>(null);
    const rows = ref();
    const selection = [
      {
        code: 'unselect',
        label: t('bulkActions.selection.unselect'),
      },
      {
        code: 'visible',
        label: t('bulkActions.selection.visible'),
      },
      {
        code: 'all',
        label: t('bulkActions.selection.all'),
      },
    ];
    const idShopList = ref<string|null>(null);

    const filterParams = ref<Record<string, any>>(props.tableColumns
      .filter((column) => column.filterable)
      .reduce<object>((acc, current) => {
        let key: string = current.field;

        if (current.prefix) {
          key = `${current.prefix}.${current.field}`;
        }

        let matchMode = (current.filterSettings && current.filterSettings.defaultMatchMode) ? current.filterSettings.defaultMatchMode : 'contains';
        if (current.matchModes) {
          matchMode = current.matchModes[0]?.value;
        } else if (current.filterSettings) {
          if (current.filterSettings.type === 'date') {
            matchMode = 'interval';
          } else if (current.filterSettings.type === 'multiSelect' || current.filterSettings.type === 'language'
            || current.filterSettings.type === 'country') {
            matchMode = 'in';
          }
        }
        return ({
          ...acc,
          [key]: {
            operator: null,
            constraints: [{ value: '', matchMode }],
          },
        });
      },
      {}));

    const showFiltersRow = computed(() => props.tableColumns
      .filter((column) => column.filterable)
      .length > 0);

    const toggle = (event: Event) => {
      menu.value.toggle(event);
    };

    const sendSelectedRows = () => {
      context.emit('row-selection-complete', rows.value);
      rows.value = [];
    };

    const settings = reactive<ListSettings>({
      limit: props.limit,
      offset: 0,
      order: [...props.initialSorting],
      filter: [...props.persistentFilters],
    });

    const paginatorTemplate: Ref<string> = ref('FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown');

    const getHeaders = () => props.tableColumns.filter((column) => !column.custom && !column.hidden)
      .map((column) => ({ [column.field]: column.header }));

    const toggleDialog = (listId: string, message: string, header: string, icon: string, acceptLabel: string, rejectLabel: string,
      actionType: string, customerId: number| null, listName?: string) => {
      if ((rows.value === undefined || rows.value.length === 0) && !customerId && !listId) {
        return showToastError(t('bulkActions.noData'));
      }

      idShopList.value = listId === '' ? null : listId;

      showCustomDialog.value = true;

      dialogData.value = {
        header,
        message,
        icon,
        acceptLabel,
        rejectLabel,
        actionType,
        idCustomer: customerId,
        listName,
        idUser: UserState.user?.id,
      };

      return true;
    };

    const exportConfirmDialog = async (exportAll = true) => {
      const accessExport = hasAccessToFeatures('data_csv.export');
      if (!exportAll) {
        if (groupSelection.value === null && (rows.value === undefined || rows.value.length === 0)) {
          return showToastError(t('bulkActions.cron.errorSelect'));
        }
        if (groupSelection.value !== ActionType.All && (rows.value === undefined || rows.value.length === 0)) {
          return showToastError(t('bulkActions.cron.errorSelect'));
        }
      }

      const exportConfirm = () => {
        confirm.require({
          group: confirmDialogGroup.value,
          message: t('myLists.manage.bulkActions.export.text'),
          header: t('myLists.manage.bulkActions.export.formTitle'),
          icon: 'far fa-info-circle',
          acceptLabel: t('myLists.manage.bulkActions.export.acceptLabel'),
          rejectLabel: t('myLists.manage.bulkActions.export.rejectLabel'),
          accept: async () => {
            let actionType: ActionType| null = null;
            let concernedIds: number[] = [];

            if (!exportAll) {
              if (groupSelection.value === ActionType.All) {
                actionType = ActionType.All;
                concernedIds = unselectedRows.value;
              } else {
                actionType = ActionType.Select;
                concernedIds = rows.value;
              }
            } else {
              actionType = ActionType.All;
              concernedIds = [];
            }

            // settings for stats are different
            const finalSettings = Object.entries(props.customSettings).length === 0 ? settings : props.customSettings;
            const fileName = props.summary ? `${props.exportFileName}_${props.summary}` : props.exportFileName;
            const resolverName = props.name === 'StatsTableGet' ? props.name : `${props.name}List`;
            const result: SaveResult<DatatablesExportsInputItem> = await InsertDatatablesExport(props.idShop,
              finalSettings, resolverName, getHeaders(), fileName, locale.value, ResolverType.Query,
              concernedIds, actionType, props.index);

            if (result.err) {
              return showToastError(t('bulkActions.cron.error'));
            }
            return showToastSuccess(t('bulkActions.cron.success'));
          },
          reject: () => {
            // callback to execute when user rejects the action
          },
        });
      };

      if (!freeTableExport.value && accessExport && !accessExport.access) {
        store.commit('general/setIsFeatureUnavailableModalVisible', true);
        store.commit('general/setFeatureUnavailableMinPlan', accessExport.minimumPlan);
        store.commit('general/setOnAuthorizedClickFunction', exportConfirm);
        return true;
      }

      return exportConfirm();
    };
    const groupedActions = ref(props.groupedActionsOptions);

    if (props.showExportMenuItem) {
      groupedActions.value.push({
        icon: !freeTableExport.value ? 'fas fa-star' : '',
        label: t('myLists.manage.bulkActions.export.menuItem'),
        command: async () => {
          await exportConfirmDialog(false);
        },
      });
    }

    const value = reactive<SpmTableState>({ ...props.modelValue });
    const selectedRows = ref([]); // list of selected rows
    const expandedRows = ref([]); // list of expanded rows

    const selectAllRows = () => {
      if (groupSelection.value === 'unselect') {
        rows.value = [];
        unselectedRows.value = [];
      }
      if (groupSelection.value === 'all') {
        unselectedRows.value = [];
        rows.value = value.items.map((u: any) => u[props.groupedActionsKey]);
      }
      if (groupSelection.value === 'visible') {
        rows.value = value.items.map((u: any) => u[props.groupedActionsKey]);
      }
    };

    const onCellEditInit: Function = (event: any) => {
      const {
        data,
        newValue,
        field,
      } = event;

      if (newValue !== '' && newValue) {
        data[field] = newValue;
      }
      context.emit('cell-edit-init', field, data, event);
    };

    const onCellEditingComplete: Function = (event: any) => {
      const {
        data,
        newValue,
        field,
      } = event;

      if (newValue !== '') {
        data[field] = newValue;
        context.emit('cell-edit-complete', field, data);
      }
    };

    const rowClicked = (event: any) => {
      if (groupSelection.value === 'all' && unselectedRows.value) {
        // eslint-disable-next-line no-underscore-dangle
        const { id } = event.target.__vueParentComponent.attrs;
        if (event.target.childElementCount === 0) {
          unselectedRows.value.push(id);
        } else if (unselectedRows.value.indexOf(id) > -1) {
          unselectedRows.value = unselectedRows.value.filter((e) => e !== id);
        }
      }
    };

    watch(() => props.modelValue, (v: SpmTableState) => {
      value.items = v.items;
      value.total = v.total;
      value.error = v.error;
      value.isLoading = v.isLoading;
      value.selectedItems = selectedRows.value;

      paginatorTemplate.value = value.total <= settings.limit ? 'CurrentPageReport RowsPerPageDropdown'
        : 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';
    }, { deep: true });

    watch(selectedRows, () => {
      const updatedValue: SpmTableState = {
        items: value.items,
        total: value.total,
        error: value.error,
        isLoading: value.isLoading,
        selectedItems: selectedRows.value,
      };

      context.emit(updateValue, updatedValue);
    });

    const columns = computed(() => props.tableColumns.filter((c) => !c.hidden));

    const translatePrimeVueFilters = () => {
      const primevue = usePrimeVue();
      primevue.config.locale.startsWith = t('filters.startsWith');
      primevue.config.locale.contains = t('filters.contains');
      primevue.config.locale.notContains = t('filters.notContains');
      primevue.config.locale.endsWith = t('filters.endsWith');
      primevue.config.locale.equals = t('filters.equals');
      primevue.config.locale.notEquals = t('filters.notEquals');
      primevue.config.locale.noFilter = t('filters.noFilter');
    };

    onMounted(translatePrimeVueFilters);

    const filterClientSideTableData = () => {
      value.items = props.modelValue.items.filter((item: any) => Object.entries(filterParams.value).every(([column, filter]) => {
        const { matchMode: filterMatchMode, value: filterConstraintValue } = filter.constraints[0];
        const columnValue = String(item[column]);
        if (
          filterConstraintValue === '' ||
          (Array.isArray(filterConstraintValue) && filterConstraintValue.length === 0)
        ) return true

        if (filterMatchMode === FilterOperatorsEnum.NOT_CONTAINS) {
          return !columnValue.includes(filterConstraintValue);
        }

        if (filterMatchMode === FilterOperatorsEnum.STARTS_WITH) {
          return columnValue.startsWith(filterConstraintValue);
        }

        if (filterMatchMode === FilterOperatorsEnum.ENDS_WITH) {
          return columnValue.endsWith(filterConstraintValue);
        }

        if (filterMatchMode === FilterOperatorsEnum.EQUALS) {
          return columnValue === filterConstraintValue;
        }

        if (filterMatchMode === FilterOperatorsEnum.NOT_EQUALS) {
          return columnValue !== filterConstraintValue;
        }

        if (filterMatchMode === FilterOperatorsEnum.IN) {
          if (Array.isArray(filterConstraintValue) && filterConstraintValue.length) {
            return filterConstraintValue.join(',').includes(columnValue);
          }
          return filterConstraintValue.includes(columnValue);
        }

        return columnValue.includes(filterConstraintValue);
      }));
      value.total = value.items.length;
    };

    const clearClientSideTableData = (filterCallback: Function, field: string) => {
      // filterParams.value[field].constraints[0].value = '';
      removeFromFilterParams(field);
      removeFromActiveFilters(field);

      filterClientSideTableData();
      filterCallback();
    };

    const sanitizeFilter = () => {
      const originalArray = settings.filter;
      const filteredArray = originalArray.filter((element: any) => !(element.values && Object.keys(element.values)[0] === 'interval' && element.values.interval === 'empty'));

      if (originalArray.length !== filteredArray.length) {
        settings.filter = filteredArray;
      }
    };

    const fetchData = async () => {
      if (props.dynamicConfiguration) {
        value.items = [];
        value.total = 0;
        value.isLoading = true;
        context.emit('reload-data', settings);
        return;
      }

      // if no name provided which means it's a client side datatable so we cant reload the data
      // Or if a name and the value of modelValue is provided which means It's still a client side dataTable
      // and the name is provided because of the export functionality
      if (!props.name || (props.name && props.modelValue.total !== 0)) {
        return;
      }

      value.isLoading = true;
      if (props.name !== '') {
        const res = await List(
          {
            name: props.name,
            settings,
            fields: props.tableColumns.filter((column) => !column.custom)
              .map((column) => column.field),
          },
        );

        // If the list need to be filtered with another table
        if (props.excludeName && res && res.err === '' && res.items.length) {
          const toIncludes = res.items.map((item: any) => item[props.excludeField]).join(',');
          const newFilters = [...props.excludeFilters];
          newFilters.push({ field: props.excludeField, value: toIncludes, operator: OperatorType.In });

          const excludedLists = await List({
            name: props.excludeName,
            settings: {
              limit: 0,
              offset: 0,
              order: [],
              filter: newFilters,
            },
            fields: [props.excludeField],
          });

          if (excludedLists && excludedLists.err === '' && excludedLists.items.length > 0) {
            res.items = res.items.filter((item: any) => {
              if (excludedLists.items.find((itemList: any) => itemList[props.excludeField] === item[props.excludeField])) {
                return true;
              }
              return false;
            });
          }
        }

        if (settings.offset && settings.offset > 0) {
          if (groupSelection.value === 'all') {
            rows.value = [...rows.value, ...res.items.map((u: any) => u[props.groupedActionsKey])];
          }
        }

        const updatedValue: SpmTableState = {
          items: res.items,
          total: res.total,
          error: res.err,
          selectedItems: selectedRows.value,
          isLoading: false,
        };

        value.items = updatedValue.items;
        value.total = updatedValue.total;
        value.error = updatedValue.error;
        value.selectedItems = updatedValue.selectedItems;
        value.isLoading = updatedValue.isLoading;

        paginatorTemplate.value = value.total <= settings.limit ? 'CurrentPageReport RowsPerPageDropdown'
          : 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown';

        context.emit(updateValue, updatedValue);
      } else {
        value.isLoading = false;
      }
    };

    const reloadData = async () => {
      await fetchData();
      spmTableKey.value += 1;
    };

    const removeFromFilterParams = (field: string) => {
      Object.entries(filterParams.value).forEach(([key, filterParam]) => {
        if (key.includes(field)) {
          // eslint-disable-next-line no-param-reassign
          filterParam.constraints[0].value = '';
        }
      });
    };

    const activeFilters = ref<Set<string>>(new Set());

    const getFilterStatus = (field: string) => (activeFilters.value.has(field) ? 'column-filter-active' : '');

    const removeFromActiveFilters = (field: string) => {
      activeFilters.value.delete(field);
    };

    const clearFilter = (filterValue: any, filterCallback: Function, field: any) => {
      clearEvent.value = true;

      if (props.name === '') {
        clearClientSideTableData(filterCallback, field);
        return;
      }

      // find the index in the filter by the field and the value
      const index = settings.filter.findIndex((filter) => {
        const resultingStr = filter.field.replace(/^[^.]+?\./, '');

        return resultingStr === field;
      });
      // remove the filter from the settings
      if (index !== -1) {
        settings.filter.splice(index, 1);
      }

      // add the filters which may have been deleted from the persistent filters again
      // speeding the lookup through a map
      const map = new Map(settings.filter.map((obj) => [JSON.stringify(obj), obj]));

      // Iterate over persistentFilters and add items that are not in  settings.filter or differ
      // eslint-disable-next-line no-restricted-syntax
      for (const obj of props.persistentFilters) {
        if (!map.has(JSON.stringify(obj)) || (map.get(JSON.stringify(obj)) && map.get(JSON.stringify(obj))?.value !== obj.value)) {
          settings.filter.push(obj);
        }
      }

      removeFromFilterParams(field);
      removeFromActiveFilters(field);
      reloadData();
      filterCallback();
    };

    const applyFilter = (filterValue: any, filterCallback: Function, field: string) => {
      if (props.name === '') {
        filterClientSideTableData();
      }

      const filterConstraintValue = filterValue.constraints[0].value;
      if (filterConstraintValue !== '' && !('length' in Object.getPrototypeOf(filterConstraintValue) && filterConstraintValue.length === 0)) {
        activeFilters.value.add(field);
      } else {
        clearFilter(filterValue, filterCallback, field);
      }

      filterCallback();
    };

    const onPageChange = (event: DataTableEvent) => {
      settings.limit = event.rows;
      settings.offset = event.first;
    };

    const onSortChange = (event: DataTableEvent) => {
      const sortList: SpmTableSort = {
        field: event.sortField,
        type: event.sortOrder < 0 ? SortOrderEnum.DESC : SortOrderEnum.ASC,
      };

      settings.order = [sortList];
    };

    const onFilterChange = (filterMeta: DataTableFilterMeta) => {
      settings.offset = 0;

      if (clearEvent.value) {
        context.emit('filters-change', settings);
        clearEvent.value = false;
        return;
      }

      // extracting filtering parameters with user's input
      const filteredParams = Object.entries(filterParams.value)
        .filter(([, parameters]) => parameters.constraints[0].value !== '')
        .map(([column, parameters]) => {
          let finalValue = parameters.constraints[0].value;

          if (parameters.constraints[0].matchMode === 'interval') {
            finalValue = parameters.constraints[0].value.interval === 'customDateRange' ? parameters.constraints[0].value.customDateRange
              : parameters.constraints[0].value;
            return {
              field: column,
              values: finalValue,
              operator: getEnumKeyByStringValue(FilterOperatorsEnum, parameters.constraints[0].matchMode),
            };
          }

          if (parameters.constraints[0].matchMode === 'in' || parameters.constraints[0].matchMode === 'notIn') {
            return {
              field: column,
              values: finalValue.join(','),
              operator: getEnumKeyByStringValue(FilterOperatorsEnum, parameters.constraints[0].matchMode),
            };
          }

          return {
            field: column,
            value: finalValue,
            operator: getEnumKeyByStringValue(FilterOperatorsEnum, parameters.constraints[0].matchMode),
          };
        });
      const newPersistentFilters = props.persistentFilters.filter((filter1) => !filteredParams.some((filter2) => filter1.field === filter2.field));
      settings.filter = [...newPersistentFilters, ...filteredParams];

      context.emit('filters-change', settings);
    };

    context.expose({
      toggleDialog, exportConfirmDialog, sendSelectedRows, reloadData,
    });

    const searchByFilter = () => {
      settings.offset = 0;
      settings.filter = [...props.persistentFilters];
    };

    watch(() => settings, () => {
      sanitizeFilter();
      fetchData();
    }, { deep: true });
    watch(() => props.persistentFilters, searchByFilter, { deep: true });

    const lazyLoading = computed(() => props.name !== '' || (props.name && props.modelValue.total !== 0)
      || props.dynamicConfiguration);

    const onRowReorder = (event: any) => {
      context.emit('row-reorder', event.value);
    };

    onMounted(async () => {
      await fetchData();
      mounting.value = false;
    });

    return {
      value,
      columns,
      selectedRows,
      expandedRows,
      filterParams,
      onSortChange,
      onPageChange,
      onFilterChange,
      selection,
      onCellEditingComplete,
      props,
      t,
      groupSelection,
      selectAllRows,
      rows,
      rowClicked,
      toggle,
      menu,
      showCustomDialog,
      dialogData,
      settings,
      unselectedRows,
      idShopList,
      reloadData,
      onRowReorder,
      showFiltersRow,
      paginatorTemplate,
      lazyLoading,
      groupedActions,
      onCellEditInit,
      clearFilter,
      applyFilter,
      getFilterStatus,
      spmTableKey,
      exportConfirmDialog,
      confirmDialogGroup,
      freeTableExport,
    };
  },
});
</script>

<style lang="scss">
.spm-data-table {
  .p-datatable-header {
    background-color: initial !important;
    padding: 0 !important;
    border: none !important;

  }

  .p-datatable-wrapper {
    .p-datatable-thead {
      font-size:0.9rem;
      .field {
        margin-bottom:0;
      }
      .CountriesList {padding:0;}
    }
    .p-datatable-tbody {
      .p-editable-column:not(.p-cell-editing)::after {
        content: none;
      }

      td {
        font-size: 0.9rem;
      }

      tr:hover, tr:not(.p-highlight):hover {
        background-color: #f9f9fa !important;
      }
      tr:focus {
        outline: none !important;
      }

      tr:last-child {
        td {
          border-bottom-width: 0px !important;
        }
      }
    }
  }

  .p-paginator-bottom {
    .p-paginator-page {
      &.p-highlight {
        background: #94c84038 !important;
        border-color: #94c840 !important;
        color: #94c840 !important;
        font-weight: 600;

        &:focus {
          box-shadow: none !important;
        }
      }

      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
</style>

<style lang="scss">
.summary {
  font-size: 1.2rem;
  height: 40px;
}
.custom_datepicker_spm .custom_datepicker_input span {
  font-weight: normal;
}
:deep() .p-column-filter-row {
  width: 100% !important;
}

:deep() .p-column-header-action {
  text-align: center !important;
}

.p-column-edit {
  width: 100%;
  @media screen and (max-width: $tablet-portrait) {
    width: 80%;
  }
}

:deep() .p-editable-column:not(.p-cell-editing) {
  &::after {
    content: '\f304';
    font-family: 'Font Awesome 6 Pro';
    font-size: 1rem;
    margin-left: 1rem;
    font-weight: lighter;
  }

  @media screen and (max-width: $tablet-portrait) {
    position: relative;
    padding-right: 3rem;
    &::after {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

:deep() .p-paginator {
  padding: 0.5rem 0;
  font-size: 0.9rem;

  & .p-paginator-current {
    margin-left: auto;
  }

  & .p-paginator-pages {
    & .p-link {
      font-size: 0.9rem !important;
    }
  }

  & .p-dropdown {
    margin-right: 0;

    & .p-dropdown-label {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: $sm-max) {
    display: block;
    text-align: center;
    position: relative;
    .p-paginator-current {
      display: block;
      text-align: left;
      line-height: 2rem;
      margin-bottom: 1rem;
    }

    .p-dropdown {
      position: absolute;
      right: 2rem;
      top: .5rem;
    }
  }
}

.column-filter-active .p-column-filter-menu-button{
  background: #ecfdf5 !important;
  color: #047857 !important;
}
</style>
