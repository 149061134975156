import { i18n } from '@/i18n';
import { StatsWidgetComponentData, StatsWidgetComponentMetadata } from '@/types/stats-editor-types';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { StatsBuilderColumnLayoutEnum, StatsType, StringMap } from '@/types';
import defaultLanguages from '@/configs/languages';

export interface WidgetDataData {
  widgetName: StringMap;
  widgetConfiguration: Record<string, any> | null;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const WidgetDataMetadata: StatsWidgetComponentMetadata<WidgetDataData> = {
  StatsWidgetMeta: {
    type: 'widgetGraph',
    label: 'Data',
    icon: 'far fa-chart-bar',
    min_columns_required: StatsBuilderColumnLayoutEnum.THREE,
    component: 'StatsWidgetData',
    availability: [StatsType.STATS, StatsType.EMAIL],
  },
  Create(data: WidgetDataData = {
    widgetName: Object.assign(
      {},
      ...defaultLanguages.map((lang) => ({ [lang]: '' })),
    ),
    widgetConfiguration: null,
  }): StatsWidgetComponentData<WidgetDataData> {
    return {
      type: 'StatsWidgetData',
      ...data,
    };
  },
  async Validate(data: WidgetDataData): Promise<ErrorConfigForm> {
    const rules = computed(() => {
      const chartsValidators: any = {};
      const filtersKpiAndTableValidators: any = {};

      if (data.widgetConfiguration) {
        // Parcourir chaque élément du tableau "charts" et ajouter une validation pour chaque élément
        if (data.widgetConfiguration.charts) {
          chartsValidators.requiredCharts = helpers.withMessage('Veuillez selectionner au moins une catégorie', helpers.withParams({ type: 'requiredChart' }, (value: any) => {
            if (data.widgetConfiguration && data.widgetConfiguration.widgetType === 'chart') {
              return value && value.length;
            }
            return true;
          }));

          data.widgetConfiguration.charts.forEach((chartCategoryData: any, index: number) => {
            chartsValidators[index] = {
              type: {
                required,
              },
              filters: {
                channel: {
                  requiredChannelFilter: helpers.withMessage('Veuillez selectionner des canaux', helpers.withParams({ type: 'requiredChannelFilter' }, () => {
                    if (chartCategoryData.type && chartCategoryData.filters && Object.keys(chartCategoryData.filters).includes('channel')) {
                      return chartCategoryData.filters.channel && chartCategoryData.filters.channel.length;
                    }
                    return true;
                  })),
                },
                type: {
                  requiredTypeFilter: helpers.withMessage('Veuillez selectionner des canaux', helpers.withParams({ type: 'requiredTypeFilter' }, () => {
                    if (chartCategoryData.type && chartCategoryData.filters && Object.keys(chartCategoryData.filters).includes('type')) {
                      return chartCategoryData.filters.type && chartCategoryData.filters.type.length;
                    }
                    return true;
                  })),
                },
              },
            };
          });
        }

        if (['kpi', 'table'].includes(data.widgetConfiguration.widgetType)) {
          if (data.widgetConfiguration.filters && Object.keys(data.widgetConfiguration.filters).includes('channel')) {
            filtersKpiAndTableValidators.channel = {
              requiredChannelFilter: helpers.withMessage('Veuillez selectionner des canaux',
                helpers.withParams({ type: 'requiredChannelFilter' }, (value: any) => value && value.length)),
            };
          }

          if (data.widgetConfiguration.filters && Object.keys(data.widgetConfiguration.filters).includes('type')) {
            filtersKpiAndTableValidators.type = {
              requiredTypeFilter: helpers.withMessage('Veuillez selectionner des canaux',
                helpers.withParams({ type: 'requiredTypeFilter' }, (value: any) => value && value.length)),
            };
          }
        }
      }

      return {
        widgetConfiguration: {
          category: {
            required,
          },
          group: {
            required,
          },
          metric: {
            required,
          },
          widgetType: {
            required,
          },
          filters: filtersKpiAndTableValidators,
          charts: chartsValidators,
        },
      };
    });

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
};

export default WidgetDataMetadata;
