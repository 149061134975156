
import {
  defineComponent,
  onBeforeMount,
  PropType, ref, watch,
} from 'vue';
import WidgetMenuMetadata, { WidgetMenuData } from '@/components/stats-builder/metadata/WidgetMenuMetadata';
import { useI18n } from 'vue-i18n';
import MenuItemsManage from '@/components/stats-builder/fields/MenuItemsManage.vue';
import defaultLanguages from '@/configs/languages';

export default defineComponent({
  name: 'StatsWidgetMenuConfig',
  components: {
    MenuItemsManage,
  },

  props: {
    modelValue: {
      type: Object as PropType<WidgetMenuData>,
      required: true,
      default() {
        return WidgetMenuMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup(props: { modelValue: WidgetMenuData; formValidation: any }) {
    const { t, locale } = useI18n();
    const list = ref(props.modelValue.toolbar);

    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const formatData = (arr: any) => arr.reduce(
      async (a: any, item: any) => {
        await a;

        if (typeof item.label === 'string') {
          item.label = Object.assign(
            {},
            ...defaultLanguages.map((lang) => ({ [lang]: item.label })),
          );
        }

        if (!item.items) {
          // eslint-disable-next-line no-param-reassign
          item.items = [];
        } else if (item.items) {
          await formatData(item.items);
        }
      },
      Promise.resolve(),
    );

    onBeforeMount(async () => {
      formatData(list.value);
    });

    return {
      t,
      list,
    };
  },
});
