<template>
  <div class="field flex align-items-center">
    <div>
      <label>{{ t(`storeParameters.services.displayNotification`) }}</label>
    </div>
    <SelectButton
      v-model="field.shopRequiredDataPushNotification_activeNotification"
      :options="yesno"
      :unselectable="false"
      option-label="name"
      option-value="value"
      class="ml-auto"
    />
  </div>
  <div v-if="field.shopRequiredDataPushNotification_activeNotification && field.shopRequiredDataPushNotification_activeNotification === '1'">
    <div class="grid">
      <div class="p-fluid col-12 lg:col-12 xl:col-5 pr-xl-5">
        <div
          v-if="field.shopRequiredDataPushNotification_titleNotif"
          class="field"
        >
          <label>{{ t('storeParameters.services.notificationTitle') }}</label>
          <LocalizedTextInput
            id="shopRequiredDataPushNotification_titleNotif"
            v-model="field.shopRequiredDataPushNotification_titleNotif"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="errors"
            field="shopRequiredDataPushNotification_titleNotif"
          />
        </div>
      </div>
      <div class="p-fluid col-12 lg:col-12 xl:col-7 pl-xl-5">
        <div class="field">
          <label>{{ t('storeParameters.services.notificationTitleColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_titleNotifColor"
            :selected-color="field.shopRequiredDataPushNotification_titleNotifColor"
            @color-changed="(color) => { field.shopRequiredDataPushNotification_titleNotifColor = color }"
          />
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="p-fluid col-12 lg:col-6 pr-xl-5">
        <div class="field">
          <label>{{ t('storeParameters.services.notificationBackgroundColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_backgroundNotifColor"
            :selected-color="field.shopRequiredDataPushNotification_backgroundNotifColor"
            @color-changed="(value) => { field.shopRequiredDataPushNotification_backgroundNotifColor = value }"
          />
        </div>
      </div>
      <div class="p-fluid col-12 lg:col-6 pl-xl-5">
        <div class="field">
          <label>{{ t('storeParameters.services.notificationTextColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_textNotifColor"
            :selected-color="field.shopRequiredDataPushNotification_textNotifColor"
            @color-changed="(value) => { field.shopRequiredDataPushNotification_textNotifColor= value }"
          />
        </div>
      </div>
    </div>
    <div
      v-if="field.shopRequiredDataPushNotification_textNotif"
      class="field"
    >
      <label>{{ t('storeParameters.services.notificationText') }}</label>
      <LocalizedTextInput
        id="shopRequiredDataPushNotification_textNotif"
        v-model="field.shopRequiredDataPushNotification_textNotif"
      />
      <FieldErrors
        :key="componentFieldErrorsKey"
        :errors="errors"
        field="shopRequiredDataPushNotification_textNotif"
      />
    </div>
    <div class="field flex align-items-center">
      <div>
        <label>{{ t(`storeParameters.services.displayNotificationTextOnMobile`) }}</label>
      </div>
      <SelectButton
        v-model="field.shopRequiredDataPushNotification_displayDescriptionInMobile"
        :options="yesno"
        :unselectable="false"
        option-label="name"
        option-value="value"
        class="ml-auto"
      />
    </div>
    <div class="field flex align-items-center">
      <div>
        <label>{{ t(`storeParameters.services.activeThumbnail`) }}</label>
      </div>
      <SelectButton
        v-model="field.shopRequiredDataPushNotification_activeImgNotifThumbnail"
        :options="yesno"
        :unselectable="false"
        option-label="name"
        option-value="value"
        class="ml-auto"
      />
    </div>
    <div
      v-if="field.shopRequiredDataPushNotification_activeImgNotifThumbnail === '1'"
      class="field"
    >
      <label>{{ t('storeParameters.services.thumbnail') }}</label>
      <div class="flex justify-content-between flex-wrap mb-3">
        <div class="flex w-full">
          <ImageUploader
            v-model="field.shopRequiredDataPushNotification_imgNotifThumbnailPath"
            :empty-label="t('storeParameters.services.pushNotificationServiceParameter.fields.dragAndDropThumbnail')"
            :shop-id="idShop"
          />
        </div>
      </div>
    </div>
    <div
      v-if="field.shopRequiredDataPushNotification_activeImgNotifThumbnail"
      class="field flex align-items-center"
    >
      <div>
        <label>{{ t(`storeParameters.services.displayThumbnailOnMobile`) }}</label>
      </div>
      <SelectButton
        v-model="field.shopRequiredDataPushNotification_displayImgInMobile"
        :options="yesno"
        :unselectable="false"
        option-label="name"
        option-value="value"
        class="ml-auto"
      />
    </div>
    <div class="field flex flex-column gap-1">
      <label>{{ t('storeParameters.services.effectType') }}</label>
      <Dropdown
        id="shopRequiredDataPushNotification_appearEffects"
        v-model="field.shopRequiredDataPushNotification_appearEffects"
        :options="effects"
        option-label="name"
        option-value="name"
        :placeholder="t('storeParameters.services.effectType')"
      />
    </div>
    <div class="field flex align-items-center">
      <label>{{ t('storeParameters.services.delayBetweenNotifications') }}</label>
      <InputNumber
        id="shopRequiredDataPushNotification_delayHide"
        v-model="field.shopRequiredDataPushNotification_delayHide"
        show-buttons
        button-layout="horizontal"
        increment-button-icon="far fa-plus"
        decrement-button-icon="far fa-minus"
        :suffix="` ${t('days')}`"
        :min="0"
        class="ml-auto"
      />
    </div>
    <div class="field mb-5">
      <label>{{ t('storeParameters.services.notificationSize') }} : {{ field.shopRequiredDataPushNotification_widthOfNotification }} px</label>
      <Slider
        :model-value="Number(field.shopRequiredDataPushNotification_widthOfNotification)"
        :step="1"
        :min="300"
        :max="500"
        @change="(value) => { field.shopRequiredDataPushNotification_widthOfNotification= Number(value) }"
      />
    </div>
    <div class="field mb-5">
      <label>{{ t('storeParameters.services.notificationTopPosition') }} : {{ field.shopRequiredDataPushNotification_positionTopOfNotification }} %</label>
      <Slider
        :model-value="Number(field.shopRequiredDataPushNotification_positionTopOfNotification)"
        :step="1"
        :min="0"
        :max="100"
        @change="(value) => { field.shopRequiredDataPushNotification_positionTopOfNotification= Number(value) }"
      />
    </div>
    <div class="field mb-5">
      <label>{{ t('storeParameters.services.notificationLeftPosition') }} : {{ field.shopRequiredDataPushNotification_positionLeftOfNotification }} %</label>
      <Slider
        :model-value="Number(field.shopRequiredDataPushNotification_positionLeftOfNotification)"
        :step="1"
        :min="0"
        :max="100"
        @change="(value) => { field.shopRequiredDataPushNotification_positionLeftOfNotification= Number(value) }"
      />
    </div>
    <div class="grid">
      <div class="p-fluid col-12 lg:col-12 xl:col-6 pr-xl-5">
        <h3 class="mb-2">
          {{ t('storeParameters.services.acceptButton') }}
        </h3>
        <div
          v-if="field.shopRequiredDataPushNotification_fullTextButtonAccept"
          class="field"
        >
          <label>{{ t('storeParameters.services.buttonText') }}</label>
          <LocalizedTextInput
            id="shopRequiredDataPushNotification_fullTextButtonAccept"
            v-model="field.shopRequiredDataPushNotification_fullTextButtonAccept"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="errors"
            field="shopRequiredDataPushNotification_fullTextButtonAccept"
          />
        </div>
        <div class="field">
          <label>{{ t('storeParameters.services.buttonBackgroundColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_colorNotification"
            :selected-color="field.shopRequiredDataPushNotification_colorNotification"
            @color-changed="(value) => { field.shopRequiredDataPushNotification_colorNotification = value }"
          />
        </div>
        <div class="field">
          <label>{{ t('storeParameters.services.buttonTextColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_textColorNotification"
            :selected-color="field.shopRequiredDataPushNotification_textColorNotification"
            @color-changed="(value) => { field.shopRequiredDataPushNotification_textColorNotification = value }"
          />
        </div>
      </div>
      <div class="p-fluid col-12 lg:col-12 xl:col-6 pl-xl-5">
        <h3 class="mb-2">
          {{ t('storeParameters.services.declineButton') }}
        </h3>
        <div
          v-if="field.shopRequiredDataPushNotification_fullTextButtonRefuse"
          class="field"
        >
          <label>{{ t('storeParameters.services.buttonText') }}</label>
          <LocalizedTextInput
            id="shopRequiredDataPushNotification_fullTextButtonRefuse"
            v-model="field.shopRequiredDataPushNotification_fullTextButtonRefuse"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="errors"
            field="shopRequiredDataPushNotification_fullTextButtonRefuse"
          />
        </div>
        <div class="field">
          <label>{{ t('storeParameters.services.buttonBackgroundColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_backgroundButtonRefuse"
            :selected-color="field.shopRequiredDataPushNotification_backgroundButtonRefuse"
            @color-changed="(value) => { field.shopRequiredDataPushNotification_backgroundButtonRefuse = value }"
          />
        </div>
        <div class="field">
          <label>{{ t('storeParameters.services.buttonTextColor') }}</label>
          <SimpleColorPicker
            id="shopRequiredDataPushNotification_textButtonRefuse"
            :selected-color="field.shopRequiredDataPushNotification_textButtonRefuse"
            @color-changed="(value) => { field.shopRequiredDataPushNotification_textButtonRefuse = value }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';

import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Slider from 'primevue/slider';
import SelectButton from 'primevue/selectbutton';

import SimpleColorPicker from '@/components/fields/SimpleColorPicker.vue';
import LocalizedTextInput from '@/components/fields/LocalizedTextInput.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ImageUploader from '@/components/fields/ImageUploader.vue';

import effects from '@/configs/effects';

import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Notification',

  components: {
    LocalizedTextInput,
    SelectButton,
    SimpleColorPicker,
    Dropdown,
    InputNumber,
    Slider,
    FieldErrors,
    ImageUploader,
  },

  props: {
    modelValue: {
      type: Object as PropType<Record<string, any>>,
      required: true,
    },

    idShop: {
      type: Number,
      required: true,
    },

    errors: {
      type: Object,
      required: true,
    },

    componentFieldErrorsKey: {
      type: Number,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const field = computed({
      get() {
        return props.modelValue;
      },

      set(newValue) {
        emit('update:modelValue', newValue);
      },
    });

    const yesno = [
      { name: t('yes'), value: '1' },
      { name: t('no'), value: '0' },
    ];

    return {
      t,
      field,
      effects,
      yesno,
    };
  },
});
</script>

<style scoped lang="scss">
.logo {
  border-width: 1px;
  border-style: solid;
  background-color: #f5f5f5;
  border-color: #ffffff;
}

.logo-container {
  text-align: center;
  align-items: center;
}

.picture {
  text-align: center;
  max-height: 60px;
  max-width: 80px;
}
</style>
