import { i18n } from '@/i18n';
import { StatsWidgetComponentData, StatsWidgetComponentMetadata } from '@/types/stats-editor-types';
import { ErrorConfigForm } from '@/types/automated-scenarios';
import { computed } from 'vue';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { StatsBuilderColumnLayoutEnum, StatsType, StringMap } from '@/types';
import { localizedTextInputValidator } from '@/helpers/CustomValidator';
import defaultLanguages from '@/configs/languages';

export interface WidgetTitleData {
  widgetName: any;
}

let translation: any;
(async () => {
  translation = await i18n;
})();

const WidgetTitleMetadata: StatsWidgetComponentMetadata<WidgetTitleData> = {
  StatsWidgetMeta: {
    type: 'widgetTitle',
    label: 'Title',
    icon: 'far fa-heading',
    min_columns_required: StatsBuilderColumnLayoutEnum.THREE,
    component: 'StatsWidgetTitle',
    availability: [StatsType.STATS, StatsType.EMAIL],
  },
  Create(data: WidgetTitleData = {
    widgetName: Object.assign(
      {},
      ...defaultLanguages.map((lang) => ({ [lang]: '' })),
    ),
  }): StatsWidgetComponentData<WidgetTitleData> {
    return {
      type: 'StatsWidgetTitle',
      ...data,
    };
  },
  async Validate(data: WidgetTitleData): Promise<ErrorConfigForm> {
    const rules = computed(() => ({
      widgetName: {
        localizedTextInputValidator: localizedTextInputValidator('text'),
      },
    }));

    const v$ = useVuelidate(rules, data);
    const success = await v$.value.$validate();
    return {
      success,
      validate: v$,
    };
  },
};

export default WidgetTitleMetadata;
