// eslint-disable-next-line import/no-cycle
import { GeneralState, LastRoute, PlanName } from '@/types';
import { ServiceParameter } from '@/types/store-parameters-types';

export default {
  namespaced: true,
  state: (): GeneralState => ({
    isTheSpinnerVisible: false,
    isSidebarExpanded: true,
    isFeatureUnavailableModalVisible: false,
    lastRoute: {
      to: null,
      from: null,
    },
    featureUnavailableMinPlan: null,
    onAuthorizedClickFunction: null,
    gleapAction: null,
    isOpenAccessToSupportModalVisible: false,
    sendTestModalVisible: false,
    isServicesParametersModalVisible: false,
    notConfiguredServices: [],
    serviceParameterSuccessAction: null,
    serviceParameterIsCanceled: false,
  }),

  getters: {
    getIsFeatureUnavailableModalVisible: (state: GeneralState) => state.isFeatureUnavailableModalVisible,
    getLastRoute: (state: GeneralState) => state.lastRoute,
    getFeatureUnavailableMinPlan: (state: GeneralState) => state.featureUnavailableMinPlan,
    getOnAuthorizedClickFunction: (state: GeneralState) => state.onAuthorizedClickFunction,
    getGleapAction: (state: GeneralState) => state.gleapAction,
    getIsOpenAccessToSupportModalVisible: (state: GeneralState) => state.isOpenAccessToSupportModalVisible,
    getIsServicesParametersModalVisible: (state: GeneralState) => state.isServicesParametersModalVisible,
    getNotConfiguredServices: (state: GeneralState) => state.notConfiguredServices,
    getServiceParameterSuccessAction: (state: GeneralState) => state.serviceParameterSuccessAction,
    getServiceParameterIsCanceled: (state: GeneralState) => state.serviceParameterIsCanceled,
  },

  mutations: {
    showTheSpinner: (state: GeneralState) => {
      state.isTheSpinnerVisible = true;
    },

    hideTheSpinner: (state: GeneralState) => {
      state.isTheSpinnerVisible = false;
    },

    setIsSidebarExpanded: (state: GeneralState, expanded: boolean) => {
      state.isSidebarExpanded = expanded;
    },

    setIsFeatureUnavailableModalVisible: (state: GeneralState, visible: boolean) => {
      state.isFeatureUnavailableModalVisible = visible;
    },

    setLastRoute: (state: GeneralState, lastRoute: LastRoute) => {
      state.lastRoute = lastRoute;
    },

    setFeatureUnavailableMinPlan: (state: GeneralState, planName: PlanName) => {
      state.featureUnavailableMinPlan = planName;
    },

    setOnAuthorizedClickFunction: (state: GeneralState, onAuthorizedClickFunction: Function) => {
      state.onAuthorizedClickFunction = onAuthorizedClickFunction;
    },

    setGleapAction: (state: GeneralState, gleapAction: string) => {
      state.gleapAction = gleapAction;
    },

    setIsOpenAccessToSupportModalVisible: (state: GeneralState, visible: boolean) => {
      state.isOpenAccessToSupportModalVisible = visible;
    },

    showSendTestModal: (state: GeneralState) => {
      state.sendTestModalVisible = true;
    },

    hideSendTestModal: (state: GeneralState) => {
      state.sendTestModalVisible = false;
    },

    setIsServicesParametersModalVisible: (state: GeneralState, visible: boolean) => {
      state.isServicesParametersModalVisible = visible;
    },

    setNotConfiguredServices: (state: GeneralState, payload: ServiceParameter[]) => {
      state.notConfiguredServices = payload;
    },

    setServiceParameterSuccessAction: (state: GeneralState, payload: Function) => {
      state.serviceParameterSuccessAction = payload;
    },

    setServiceParameterIsCanceled: (state: GeneralState, payload: boolean) => {
      state.serviceParameterIsCanceled = payload;
    },
  },
};
