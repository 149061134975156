
import {
  computed, defineComponent, onMounted, ref,
} from 'vue';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import { checkIfShopConnected, getEcommerceModule } from '@/configs/complete';
import { showToastError, showToastSuccess } from '@/helpers';
import { useI18n } from 'vue-i18n';
import { generateApiIdentifiant, generateApiKey, updateShop } from '@/composables/shop/Shops';
import { UserState } from '@/composables/User';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Badge from 'primevue/badge';
import ProgressBar from 'primevue/progressbar';
import { nestPost } from '@/composables/nestApi';
import CryptoJS from 'crypto-js';

export default defineComponent({
  name: 'ConnectShop',

  components: {
    SpmButton,
    Card,
    Button,
    Dropdown,
    Badge,
    ProgressBar,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    subTitle: {
      type: String,
      required: false,
      default: '',
    },
  },

  emits: ['next'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const activeEcommerce = ref(UserState.activeShop?.solutionEcommerce);
    const ecommerceModule = computed(() => {
      if (activeEcommerce.value) {
        return getEcommerceModule(activeEcommerce.value);
      }
      return {};
    });

    const apiKeys = ref(
      [
        {
          label: t('complete.connectShop.connectModule.apiIdentifiantLabel'),
          value: generateApiIdentifiant(),
        },
        {
          label: t('complete.connectShop.connectModule.apiKeylabel'),
          value: generateApiKey(),
        },
      ],
    );

    const ecommerceOptions = [
      { label: 'Prestashop', code: 'prestashop' },
      { label: 'Magento', code: 'magento' },
      { label: 'Magento 2', code: 'magento2' },
      { label: 'WooCommerce', code: 'woocommerce' },
      { label: 'Shopify', code: 'shopify' },
      { label: 'Thelia (Bêta)', code: 'thelia' },
    ];

    const copyToClipboard = (value: string) => {
      navigator.clipboard.writeText(value);
      showToastSuccess(t('complete.connectShop.apiKeyCopiedToClipboard'));
    };

    const downloadModule = () => {
      window.open(ecommerceModule.value.downloadLink, '_blank');
    };

    const goNextStep = async () => {
      // trigger creation database for this shop
      await nestPost('v4', '/shop/createEnvironment', {}, {
        idUser: UserState.user.id ?? 0,
        idShop: UserState.activeShop?.id ?? 0,
      });
      emit('next');
    };

    onMounted(async () => {
      if (await checkIfShopConnected()) {
        await goNextStep();
        return;
      }
      // save api keys on db
      if (UserState.activeShop?.solutionEcommerce !== 'shopify') {
        try {
          const cryptedApiKey = CryptoJS.SHA1(apiKeys.value[1].value).toString();
          await updateShop([{
            id_shop: UserState.activeShop?.id ?? 0,
            identifiant: apiKeys.value[0].value,
            api_key: cryptedApiKey,
          }]);
        } catch (error) {
          await showToastError('GENERIC_ERROR');
        }
      }


      const interval = setInterval(async () => {
        try {
          if (await checkIfShopConnected()) {
            clearInterval(interval);
            await goNextStep();
          }
          // eslint-disable-next-line no-empty
        } catch (error) {
        }
      }, 5000);
    });

    return {
      t,
      activeEcommerce,
      ecommerceModule,
      downloadModule,
      apiKeys,
      ecommerceOptions,
      copyToClipboard,
    };
  },

});
